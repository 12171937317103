import { calcDateFromDuration, isDuration, parseDate, type Duration } from '@talos/kyoko';
import { camelCase, isNil } from 'lodash-es';
import type { OMSForm } from 'providers/OMSContext.types';
import { useCallback, useEffect, useState } from 'react';
import { ParameterKeysEnum } from 'tokens/orderStrategy';

/**
 * Calculate the start time of an order, updating if the start time is specified as a Duration.
 *
 * @param order OMS Form for a new or existing order
 * @returns Order start time (existing orders) or expected start time (new orders)
 */
export function useOrderStartTime(order: OMSForm) {
  const { parameters, startTime: formStartTime, submitTime: formSubmitTime } = order;
  const [orderStartTime, setOrderStartTime] = useState<Date>(parseDate(null));

  const recalcStartTime = useCallback(
    function recalcStartTime() {
      if (!isNil(formStartTime)) {
        return setOrderStartTime(parseDate(formStartTime));
      } else if (!isNil(parameters[camelCase(ParameterKeysEnum.StartTime)])) {
        const paramValue = parameters[camelCase(ParameterKeysEnum.StartTime)] as Date | Duration | null;
        if (isDuration(paramValue)) {
          setOrderStartTime(calcDateFromDuration(paramValue, parseDate()));
        } else {
          return setOrderStartTime(parseDate(parameters[camelCase(ParameterKeysEnum.StartTime)] as Date));
        }
      } else if (!isNil(formSubmitTime)) {
        return setOrderStartTime(parseDate(formSubmitTime));
      } else {
        setOrderStartTime(parseDate());
      }
    },
    [formStartTime, formSubmitTime, parameters]
  );
  useEffect(() => {
    recalcStartTime();
    const timer = setInterval(recalcStartTime, 1000);
    return () => clearInterval(timer);
  }, [recalcStartTime]);
  return orderStartTime;
}

import { Fragment, useMemo } from 'react';
import { Divider } from '../../components/Divider';
import { Text } from '../../components/Text';
import { useSecuritiesContext } from '../../contexts';
import { useMarketsContext } from '../../contexts/MarketsContext';
import { useDynamicCallback } from '../../hooks';
import {
  MarketTypeEnum,
  WARNING_SEVERITY_TO_ALERT_VARIANT,
  type Order,
  type OrderMarketWarningItem,
} from '../../types';
import { Alert } from '../Alert';
import { HStack, VStack } from '../Core';
import { IndicatorBadge, IndicatorBadgeSizes } from '../IndicatorBadge';
import { Table, TableSize, Tbody, Td, Tr } from '../Table';

type MarketGroup = MarketTypeEnum | 'Other';

interface OrderWarningsProps {
  warning?: Order['warning'];
}

export function OrderWarnings({ warning }: OrderWarningsProps) {
  const { marketsByName } = useMarketsContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const groupCompareFn = useGroupCompareFn();

  const getMarketLabel = useDynamicCallback((item: OrderMarketWarningItem) => {
    return marketsByName.get(item.market)?.DisplayName ?? item.market;
  });

  const getSymbolLabel = useDynamicCallback((item: OrderMarketWarningItem) => {
    if (!item.symbol) {
      return undefined;
    }

    return securitiesBySymbol.get(item.symbol)?.DisplaySymbol ?? item.symbol;
  });

  // Item => "Coinbase (ETH-USD)"
  const getCombinedItemLabel = useDynamicCallback((item: OrderMarketWarningItem) => {
    let label = getMarketLabel(item);
    const symbolLabel = getSymbolLabel(item);
    if (symbolLabel != null) {
      label = `${label} (${symbolLabel})`;
    }

    return label;
  });

  const warningGroups = useMemo(() => {
    if (warning == null) {
      return [];
    }
    const marketGroupsMap = new Map<string, OrderMarketWarningItem[]>();

    // Put warnings into groups (Exchange / Dealer).
    for (const item of warning.warningItems) {
      const marketType = marketsByName.get(item.market)?.Type ?? 'Other';
      const items = marketGroupsMap.get(marketType) ?? [];
      items.push(item);
      marketGroupsMap.set(marketType, items);
    }
    // Sort warnings.
    marketGroupsMap.forEach(arr => arr.sort((a, b) => getCombinedItemLabel(a).localeCompare(getCombinedItemLabel(b))));

    const typeGroupTuples: [string, OrderMarketWarningItem[]][] = Array.from(marketGroupsMap.entries());

    // Sort groups by their type
    return typeGroupTuples.sort(([aType], [bType]) => groupCompareFn(aType, bType));
  }, [groupCompareFn, marketsByName, getCombinedItemLabel, warning]);

  if (warning == null) {
    return null;
  }

  const alertVariant = WARNING_SEVERITY_TO_ALERT_VARIANT[warning.severity];

  return (
    <VStack gap="spacingMedium" w="100%" p="spacingDefault" maxWidth="70vw">
      <Alert w="100%" variant={alertVariant} dismissable={false} justifyContent="space-between" alignItems="center">
        <VStack alignItems="flex-start" gap="spacingSmall" w="100%">
          {warning.topLevelWarnings.map((warningItem, idx) => (
            <HStack
              key={warningItem.label + warningItem.severity + idx}
              gap="spacingDefault"
              justifyContent="space-between"
              w="100%"
            >
              <Text size="fontSizeMd" color="gray.100">
                {warningItem.label}
              </Text>
              {warningItem.value && (
                <IndicatorBadge noWrap size={IndicatorBadgeSizes.Small} children={warningItem.value} />
              )}
            </HStack>
          ))}
        </VStack>
      </Alert>
      {warningGroups.length > 0 && (
        <Table w="100%" size={TableSize.Small}>
          <Tbody>
            {warningGroups.map(([type, items], idx) => (
              <Fragment key={type}>
                <Tr>
                  <Td>
                    <Text size="fontSizeSm" color="gray.080">
                      {`${type}s`}
                    </Text>
                  </Td>
                </Tr>
                {items.map(item => {
                  const marketLabel = getMarketLabel(item);
                  const symbolLabel = getSymbolLabel(item);

                  return (
                    <Tr key={`$${item.market}-${item.symbol}`}>
                      <Td verticalAlign="top">
                        <HStack
                          gap="spacingSmall"
                          fontSize="fontSizeMd"
                          justifyContent="flex-start"
                          flexWrap="wrap"
                          data-testid="order-market-warning-label"
                        >
                          <Text color="colorTextImportant">{marketLabel}</Text>
                          {symbolLabel && <Text color="colorTextSubtle">{symbolLabel}</Text>}
                        </HStack>
                      </Td>
                      <Td align="right" minWidth="50%">
                        <Text size="fontSizeMd" color="gray.090" data-testid="order-market-warning-value">
                          {item.value}
                        </Text>
                      </Td>
                    </Tr>
                  );
                })}
                {warningGroups.length - 1 !== idx && (
                  <Tr>
                    <Td colSpan={2}>
                      <Divider my="spacingDefault" orientation="horizontal" />
                    </Td>
                  </Tr>
                )}
              </Fragment>
            ))}
          </Tbody>
        </Table>
      )}
    </VStack>
  );
}

const useGroupCompareFn = () => {
  const MARKET_GROUP_PRIORITY: Record<MarketGroup, number> = useMemo(
    () => ({
      [MarketTypeEnum.Exchange]: -10,
      [MarketTypeEnum.Dealer]: -9,
      [MarketTypeEnum.Custodian]: -8,
      [MarketTypeEnum.External]: -7,
      [MarketTypeEnum.InternalAlgo]: -6,
      [MarketTypeEnum.Dark]: -5,
      Other: -4,
    }),
    []
  );

  return useMemo(
    () =>
      function groupCompareFn(a: string, b: string) {
        return (MARKET_GROUP_PRIORITY[a as MarketGroup] || 0) - (MARKET_GROUP_PRIORITY[b as MarketGroup] || 0);
      },
    [MARKET_GROUP_PRIORITY]
  );
};

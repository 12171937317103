import type Big from 'big.js';
import { isNil } from 'lodash-es';
import type { ExpectTrue } from '../../tests';
import { BID_ASK_SPREADS_DATAPOINTS } from '../../tokens';
import { parseDate, toBigWithDefault, type OptionalProperties } from '../../utils';
import type {
  AggregationWindowEnum,
  AnalyticsSpreadTypeEnum,
  IBidAskSpreadsDatapoint,
  UpdateActionEnum,
} from '../types';
import type { DatapointProps } from './types';

export class BidAskSpreadsDatapoint {
  public static readonly Stream_Name = BID_ASK_SPREADS_DATAPOINTS;
  public readonly StartTime: Date;
  public readonly SpreadBps: Big | undefined;
  public readonly SizeBucket: Big | undefined;
  public readonly SpreadType: AnalyticsSpreadTypeEnum;
  public readonly Market: string | undefined;
  public readonly MarketAccount: string | undefined;
  public readonly Asset: string | undefined;
  public readonly ReleaseRevision: string | undefined;
  public readonly AggregationWindow: AggregationWindowEnum | undefined;
  public readonly UpdateAction: UpdateActionEnum | undefined;

  constructor(data: BidAskSpreadsDatapoint) {
    this.StartTime = parseDate(data.StartTime);
    this.SpreadBps = isNil(data.SpreadBps) ? undefined : toBigWithDefault(data.SpreadBps, 0);
    this.SizeBucket = isNil(data.SizeBucket) ? undefined : toBigWithDefault(data.SizeBucket, 0);
    this.SpreadType = data.SpreadType;
    // EDX needs to be displayed as 2 separate markets, based on the Market Account.
    // The backend handles this for us everywhere else in the codebase, but not in Analytics.
    // So we need to use the MarketAccount as the market name if specified, otherwise the Market name.
    // If we didn't do this here, we'd need to update a lot of other analytics code to handle this.
    this.Market = data.MarketAccount ?? data.Market;
    this.MarketAccount = data.MarketAccount;
    this.Asset = data.Asset;
    this.ReleaseRevision = data.ReleaseRevision;
    this.UpdateAction = data.UpdateAction;
    this.AggregationWindow = data.AggregationWindow;
    return this;
  }
}

type _BidAskSpreadsDatapoint = OptionalProperties<
  DatapointProps<IBidAskSpreadsDatapoint>,
  'Asset' | 'Market' | 'MarketAccount' | 'UpdateAction'
>;

// TYPE LEVEL TESTS
type _Expect_BidAskSpreadDatapoint_To_Only_Have_Keys_From_IBidAskSpreadDatapoint = ExpectTrue<
  {
    [K in keyof BidAskSpreadsDatapoint & string]: K extends keyof _BidAskSpreadsDatapoint ? true : K;
  }[Exclude<keyof BidAskSpreadsDatapoint, never>]
>;
type _Expect_All_Keys_In_IBidAskSpreadDatapoint_To_Be_In_BidAskSpreadDatapoint = ExpectTrue<
  {
    [K in keyof _BidAskSpreadsDatapoint & string]: K extends keyof BidAskSpreadsDatapoint ? true : K;
  }[Exclude<keyof _BidAskSpreadsDatapoint, never>]
>;

import Big, { type BigSource } from 'big.js';
import { isNil, toNumber } from 'lodash-es';
import Sugar from 'sugar';
import { DECIMAL, GROUP } from '../tokens/number';
import { logger } from './logger';

Sugar.Number.setOption('decimal', '.');
Sugar.Number.setOption('thousands', ',');

/**
 * "Capitalize" some of the digits in a number. For example, if an asset is
 * trading between 100 and 101, it might useful to highlight only the decimals
 * of the price.
 *
 * @returns An object containing the numerical value and the indices of the digits
 *          that should be highlighted.
 */
export function highlight({ number, specification = 'M.m' }: { number: string; specification: string }): {
  value: string;
  highlight: (number | null)[];
} {
  const specificationDecimalIndex = specification.indexOf('.');
  if (number === '0') {
    if (specification[specificationDecimalIndex - 1] === 'M') {
      return { value: number, highlight: [0, 0] };
    } else {
      return { value: number, highlight: [null, null] };
    }
  }
  let numberDecimalIndex = number.indexOf('.');
  if (numberDecimalIndex === -1) {
    numberDecimalIndex = number.length;
  }
  let highlightStart: number | null = null,
    highlightEnd: number | null = null;
  let highlighting = false;
  for (let i = 1; i <= numberDecimalIndex; i++) {
    const c = specification.charAt(specificationDecimalIndex - i);
    if (c === 'M') {
      highlighting = true;
      highlightStart = numberDecimalIndex - i;
      if (highlightEnd == null) {
        highlightEnd = highlightStart;
      }
    } else if (c === 'm') {
      highlighting = false;
    } else {
      if (highlighting) {
        highlightStart = numberDecimalIndex - i;
      }
    }
  }

  highlighting = false;
  for (let i = 1; numberDecimalIndex + i < number.length; i++) {
    const c = specification.charAt(specificationDecimalIndex + i);
    if (c === 'M') {
      highlighting = true;
      highlightEnd = numberDecimalIndex + i;
      if (highlightStart == null) {
        highlightStart = highlightEnd;
      }
    } else if (c === 'm') {
      highlighting = false;
    } else {
      if (highlighting) {
        highlightEnd = numberDecimalIndex + i;
      }
    }
  }

  return { value: number, highlight: [highlightStart, highlightEnd] };
}

/**
 * Turn a very long number, say 10 000 000, into something shorter, like 10M
 */
const LOWEST_THRESHOLD = 0.1;
const DECIMAL_THRESHOLD = 10;
export type AbbreviateOptions = {
  decimalThreshold?: number;
  lowestThreshold?: number;
  precision?: number;
};
export function abbreviate(
  number?: string | number | null,
  options: AbbreviateOptions = {
    decimalThreshold: DECIMAL_THRESHOLD,
    lowestThreshold: LOWEST_THRESHOLD,
    precision: 1,
  }
): string {
  const { decimalThreshold = DECIMAL_THRESHOLD, lowestThreshold = LOWEST_THRESHOLD, precision = 1 } = options;
  if (number == null) {
    return '';
  }

  const parsed = parseFloat(number.toString());
  if (isNaN(parsed)) {
    return '';
  }

  if (parsed === 0) {
    return '0';
  }

  if (parsed < lowestThreshold) {
    return `<${lowestThreshold}`;
  }

  if (parsed < decimalThreshold) {
    return Big(number).toFixed(1).replace(/\.0+$/, '');
  }

  return Sugar.Number.abbr(Math.floor(parsed), precision).toUpperCase();
}

/** Abbreviate with support for negative values
 * @see abbreviate
 */
export function unboundedAbbreviate(value: number, options?: AbbreviateOptions) {
  return `${value < 0 ? '-' : ''}${abbreviate(Math.abs(value), options)}`;
}

/**
 * Abbreviate a large number, into a number and a suffix (K, M, B, T, Q), e.g. 123 000 000 => { number: 1.23 suffix: "M" }
 */
export function abbr(value: number, precision = 1) {
  let newValue = value;
  const suffixes = ['K', 'M', 'B', 'T', 'q', 'Q', 's', 'S'];
  let suffixNum = -1;
  while (Math.abs(Math.round(newValue)) >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }
  const result = parseFloat(newValue.toFixed(precision));
  return {
    number: result,
    suffix: suffixes[suffixNum] ?? '',
  };
}

/**
 * Format numbers for display...
 * e.g. e.g. '1200000.1200123' => '1,200,000.12'
 *
 * @param s String raw number
 * @returns String
 */
export function format(s: BigSource | undefined | null, options: FormatOptions = {}): string {
  if (s == null || (typeof s === 'number' && isNaN(s)) || s === '') {
    return '';
  }
  let raw: BigSource;
  try {
    // Note: we remove any commas in the input string here - we were previously getting a large number of
    // errors from this function due to input strings that already included commas for grouping.
    raw = Big(typeof s === 'string' ? s.replaceAll(',', '') : s);
  } catch (e) {
    logger.error(e as Error, {
      extra: {
        string: s,
      },
    });
    return `${s}`;
  }

  // if we dont want to round, the fixed number has to always be rounded down when its created in toFixed.
  // roundHalfUp is big's default rounding for toFixed()
  const fixedRoundingMode = options.round ? Big.roundHalfUp : Big.roundDown;
  let number = raw.toFixed();
  const isExpNotation = parseInt(number) >= 1e21;
  let precision = 0;
  const spec = options.spec;
  if (!isExpNotation) {
    if (spec != null) {
      precision = spec.includes('.') ? spec.length - 2 : -parseInt(spec).toString().length + 1;
    } else {
      precision = calculatePrecision(s);
    }
  }

  const fixed = raw.toFixed(precision < 0 ? 0 : precision, fixedRoundingMode);

  if (options.round) {
    number = Big(fixed)
      .round(precision, fixedRoundingMode)
      .toFixed(precision < 0 ? 0 : precision);
  } else if (options.truncate) {
    number = Big(fixed)
      .round(precision, Big.roundDown)
      .toFixed(precision < 0 ? 0 : precision);
  } else {
    if (fixed.length < number.length) {
      number = number.replace(/0+$/, '');
    } else {
      number = fixed;
    }
  }

  if (options.removeTrailingZeros) {
    number = toNumber(number).toString();
  }

  if (options.pretty === false || isExpNotation) {
    return number;
  } else {
    const [int, dec] = number.split('.');
    let sign = '';
    if (options.showSign !== false) {
      // Number(-1) is fine, but Number(-0) === 0
      // so for this edge case we add a sign ourselves
      sign = raw.lt(0) ? '-' : options.showSign && raw.gt(0) ? '+' : '';
    }
    return sign + Sugar.Number(Math.abs(toNumber(int))).format(0) + (dec ? '.' + dec : '');
  }
}

export type FormatOptions = {
  /**
   * The output will use comma as thousands separator.
   * @default true
   */
  pretty?: boolean;
  /**
   * The spec is only a hint. If we have number with more precision we show the full number.
   * Having round = true will round the number based on the spec.
   * @default false
   */
  round?: boolean;
  /**
   * Number format spec e.g. 1.000 signifying 3 decimal places by default.
   * @default null
   */
  spec?: string;
  /**
   * Truncates the number, without rounding, to the number specified by spec. If the number
   * has more significant digits than the spec, it will be floored down (123 with spec 10 -> 120).
   * @default false
   */
  truncate?: boolean;
  /**
   * When set to true the output will include +/- sign for all numbers except 0.
   * When undefined only negative number will have a - sign.
   * @default undefined
   */
  showSign?: boolean;
  /**
   * When set to true, the output will remove all decimal trailing zeros
   * e.g. 1.230000 will become 1.23
   * @default false
   */
  removeTrailingZeros?: boolean;
};

export function toString(n?: string | number): string {
  if (isNil(n)) {
    return '';
  }
  try {
    return Big(n).toFixed();
  } catch (e) {
    return '';
  }
}

/**
 * A safety function to go from some maybe-undefined BigSource to a Big.
 * If the function is unable to create a Big from the provided value, it will return undefined.
 */
export function toBig(value: BigSource | undefined | null): Big | undefined {
  if (value == null || value === '') {
    return undefined;
  }

  try {
    return Big(value);
  } catch (e) {
    return undefined;
  }
}

export function toBigWithDefault(value: BigSource | undefined | null, defaultValue: number): Big {
  return toBig(value) ?? Big(defaultValue);
}

export function bigMin(...values: Array<BigSource | undefined | null>): Big | undefined {
  let minValue: Big | undefined;
  let v: Big;
  return values.reduce((acc: Big | undefined, value: BigSource | undefined | null) => {
    if (value === undefined) {
      return acc;
    } else {
      try {
        v = Big(value ?? 0);
      } catch {
        return acc;
      }
    }
    minValue = minValue === undefined || v.lt(minValue) ? v : acc;
    acc = minValue;
    return acc;
  }, minValue);
}

export function bigMax(...values: Array<BigSource | undefined | null>): Big | undefined {
  let maxValue: Big | undefined;
  let v: Big;
  return values.reduce((acc: Big | undefined, value: BigSource | undefined | null) => {
    if (value == null) {
      return acc;
    } else {
      try {
        v = Big(value);
      } catch {
        return acc;
      }
    }
    maxValue = maxValue === undefined || v.gt(maxValue) ? v : acc;
    acc = maxValue;
    return acc;
  }, maxValue);
}

/**
 * Converts a number from percentage terms to basis points
 * @param n {string|number|Big}
 * @param precision {string|undefined}
 * @returns string
 */
export function percentToBps(n?: string | number | Big | null, precision = 2) {
  try {
    if (n == null) {
      return '';
    }
    return Big(n).times(10000).toFixed(precision);
  } catch (e) {
    return '';
  }
}

/**
 * Converts a number from basis points to percentage terms
 * @param n {string|number|Big}
 * @returns string
 */
export function bpsToPercent(n?: string | number | Big | null): string {
  try {
    if (n == null) {
      return '';
    } else {
      return Big(n).div(10000).toFixed();
    }
  } catch (e) {
    return '';
  }
}

export const parseNumber = (number?: string | null, decimal = DECIMAL, group = GROUP) => {
  if (number == null || number === '') {
    return null;
  }
  try {
    const _bigNumber = Big(number);
  } catch {
    return null;
  }
  const [integers, decimals] = number.split(decimal);
  return parseFloat(`${integers.replace(RegExp(group, 'g'), '')}.${decimals}`);
};

export const isNumberNegative = (number: Big | string | undefined) => {
  return toBigWithDefault(number, 0).lt(0);
};

export const calculatePrecision = (value: BigSource): number => {
  return `${value}`.split('.')[1]?.length || 0;
};

/**
 * Compares two numeric values. Regards a nullish value to be less than a non-nullish value.
 */
export function numericComparator(a: BigSource, b: BigSource) {
  const bigA = toBig(a);
  const bigB = toBig(b);

  if (isNil(bigA) && isNil(bigB)) {
    return 0;
  }
  if (isNil(bigA)) {
    return -1;
  }
  if (isNil(bigB)) {
    return 1;
  }
  return bigA.cmp(bigB);
}

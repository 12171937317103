import {
  HamburgerMenu,
  filterByCellValueMenuItem,
  getShowJSONContextItem,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  type CareExecutionReport,
  type Column,
  type ColumnDef,
  type FilterableProperty,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { compact } from 'lodash-es';
import { useMemo, type ReactNode } from 'react';
import { colIDToFilterBuilderKey } from './useCareExecutionReportsFilter';

export function useCareExecutionReportsMenu({
  openClause,
  filterableProperties,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
}): {
  columns: Column[];
  getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} {
  const mixpanel = useMixpanel();
  const { handleClickJson, jsonModal } = useJsonModal();

  const defaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const data = params?.node?.data;
    const items = compact([
      ...filterByCellValueMenuItem({ params, filterableProperties, openClause, colIDToFilterBuilderKey, mixpanel }),
      ...(data ? [getShowJSONContextItem({ params, handleClickJson }), 'separator'] : []),
      ...defaultContextMenuItems(params),
    ]);
    return items;
  });

  const dialogs = useMemo(() => <>{jsonModal}</>, [jsonModal]);

  const columns = useMemo(
    () =>
      compact([
        {
          type: 'hamburgerMenu',
          id: 'rowMenu',
          params: {
            renderItems: params => <HamburgerMenu {...params} onShowJson={handleClickJson} />,
          },
        },
      ] satisfies ColumnDef<CareExecutionReport>[]),
    [handleClickJson]
  );

  return {
    columns,
    getContextMenuItems,
    dialogs,
  };
}

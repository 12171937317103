import { VStack } from '@talos/kyoko';
import { DealerSettlement } from 'containers/Portfolio/DealerSettlement';
import { PerformanceStandalone } from 'containers/Portfolio/Performance/PerformanceStandalone';
import { OperationsReports } from 'containers/Portfolio/TreasuryManagement/OperationsReports';
import { OperationsOverview } from '../../OperationsOverview/OperationsOverview';
import { PortfolioMargin } from '../../PortfolioMargin/PortfolioMargin';
import { PortfolioReconPMSLayout } from '../../Reconciliation';
import { PortfolioOverview } from '../components/PortfolioOverview';
import { PortfolioPerformanceBlotterWrapper } from '../components/PortfolioPerformanceBlotter/PortfolioPerformanceBlotterWrapper';
import { PortfolioRiskBlotterWrapper } from '../components/PortfolioRiskBlotter/PortfolioRiskBlotterWrapper';

export type LayoutOptionComponentType = keyof typeof LAYOUT_OPTIONS_FIXED_COMPONENT_FACTORY;
/** For each fixed layout dashboard tab, configure the tab to show; the keys used will drive the available settings in the config  */
export const LAYOUT_OPTIONS_FIXED_COMPONENT_FACTORY = {
  'PM-Overview': <PortfolioOverview />,
  'PM-Margin': <PortfolioMargin />,
  'PM-Performance': <PortfolioPerformanceBlotterWrapper />,
  'PM-Reports': <PerformanceStandalone />,

  'Risk-Exposures': <PortfolioRiskBlotterWrapper />,

  'Operations-Overview': <OperationsOverview />,
  'Operations-Reconciliation': <PortfolioReconPMSLayout />,
  'Operations-Settlement': <DealerSettlement />,
  'Operations-Reports': <OperationsReports />,
} as const satisfies Record<string, React.ReactNode>;

export const LAYOUT_OPTIONS_WRAPPER_COMPONENT = {
  PMWrapper: VStack,
  OperationsWrapper: VStack,
} as const satisfies Record<string, React.ReactNode>;

import {
  BubbleChart,
  renderToHTML,
  unboundedAbbreviate,
  useCallbackRef,
  useDynamicCallback,
  useHighchartsRef,
} from '@talos/kyoko';
import type Big from 'big.js';
import type { PointOptionsObject } from 'highcharts/highcharts';
import { compact, groupBy, map, sumBy } from 'lodash-es';
import { parseToRgb, rgb } from 'polished';
import { useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { getPointCustomAttrs, type OpsEMRChartPointCustom } from './types';

export interface OpsEMRChartProps<T> {
  entities: T[] | undefined;
  /** Get the value to be used for representing the size of the item's bubble */
  getSize: (item: T) => Big;
  /** Get the EMR. Shown in the tooltip. */
  getNLV: (item: T) => Big;
  /** Get the NLV. Y axis value. */
  getEMR: (item: T) => Big;
  /** Get the equity. X axis value. */
  getEquity: (item: T) => Big;
  /** Get the label for the item. */
  getLabel: (item: T) => string;
  /** Get the property of the item to group on. */
  getGroupBy: (item: T) => string;
  /** Get the tooltip to show given a chart point. Does *not* need to be a stable callback. */
  getTooltip: (point: Highcharts.Point) => React.ReactElement;
  /** Called when one of the points are clicked */
  onPointClick?: (key: string) => void;
}

export const OpsEMRChart = <T,>({
  entities,
  getSize,
  getNLV,
  getEMR,
  getEquity,
  getLabel,
  getGroupBy,
  getTooltip,
  onPointClick,
}: OpsEMRChartProps<T>) => {
  const { homeCurrency } = useDisplaySettings();

  const theme = useTheme();

  const { chartRef, setChartObject, isLoaded } = useHighchartsRef();

  const chartData = useMemo(() => {
    if (!entities) {
      return undefined;
    }

    return getChartData({ entities, getSize, getEMR, getNLV, getEquity, getLabel, getGroupBy });
  }, [entities, getSize, getEMR, getEquity, getLabel, getNLV, getGroupBy]);

  useEffect(() => {
    if (!isLoaded || !chartData) {
      return;
    }

    const dataSeries = chartRef.current?.series.at(0);
    dataSeries?.setData(chartData.dataPoints, true, true, false);

    // Only include padding data if there are actual chart data points
    // We build and include invisible padding points in order to give the user a smoother zooming and panning experience.
    // Try removing them and see how it behaves otherwise. Setting axis padding does not work as well as this hack.
    const paddingData = chartData.dataPoints.length > 0 ? chartData.paddingPoints : [];
    const paddingSeries = chartRef.current?.series.at(1);
    paddingSeries?.setData(paddingData, true, true, false);
  }, [isLoaded, chartData, chartRef]);

  // Create a stable function to get the tooltip. This allows us to keep the tooltip option in the highcharts option stable,
  // while also allowing us to pass up to date component-level state into the tooltip (when its created)
  const getTooltipHTML = useDynamicCallback((point: Highcharts.Point) => {
    return renderToHTML(getTooltip(point), theme);
  });

  const handleClick = useCallbackRef((event: Highcharts.SeriesClickEventObject) => {
    const point = event.point;
    if (!point) {
      return;
    }

    const key = getPointCustomAttrs(point)?.key;
    key && onPointClick?.(key);
  });

  const options = useMemo(() => {
    return {
      chart: {
        type: 'bubble',
        marginBottom: 55, // aligns the bottom axis title nicely with the outer border of the component
        plotBorderWidth: 1,
        plotBorderColor: theme.borderColorChartAxis,

        zooming: {
          type: 'xy',
        },
      },
      plotOptions: {
        bubble: {
          stickyTracking: false,
          dataLabels: {
            inside: false,
            shadow: true,
            y: -12, // moves the data label to just above the top of the circle
          },
        },
      },
      tooltip: {
        useHTML: true,
        padding: 0,
        outside: false,
        backgroundColor: 'transparent',
        formatter: function () {
          return getTooltipHTML(this.point);
        },
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        minorGridLineWidth: 0,
        title: {
          margin: 10,
          text: `Equity (${homeCurrency})`,
          style: {
            color: theme.colorTextMuted,
          },
        },
      },
      yAxis: {
        minorGridLineWidth: 0,
        title: {
          margin: 10,
          text: 'Equity Margin Ratio',
          style: {
            color: theme.colorTextMuted,
          },
        },

        labels: {
          formatter: function () {
            if (typeof this.value === 'string') {
              return '';
            }
            const style = this.value < 0 ? `color: ${theme.colorTextNegative}` : '';
            return `<span style="${style}">${unboundedAbbreviate(this.value, {
              precision: 2,
            })}</span>`;
          },
        },
      },
      series: [
        {
          type: 'bubble',
          id: 'main',
          index: 0,
          events: {
            click: function (event) {
              handleClick(event);
            },
          },
        },
        {
          type: 'bubble',
          id: 'padding',
          index: 1,
          dataLabels: {
            enabled: false,
          },
          enableMouseTracking: false,
          color: 'transparent',
          borderColor: 'transparent',
        },
      ],
    } satisfies Highcharts.Options;
  }, [theme, homeCurrency, getTooltipHTML, handleClick]);

  return <BubbleChart onChartCreated={setChartObject} options={options} throttleResizing={0} />;
};

function getChartData<T>({
  entities,
  getSize,
  getEMR,
  getNLV,
  getEquity,
  getLabel,
  getGroupBy,
}: {
  entities: T[];
  getSize: OpsEMRChartProps<T>['getSize'];
  getEMR: OpsEMRChartProps<T>['getEMR'];
  getNLV: OpsEMRChartProps<T>['getNLV'];
  getEquity: OpsEMRChartProps<T>['getEquity'];
  getLabel: OpsEMRChartProps<T>['getLabel'];
  getGroupBy: OpsEMRChartProps<T>['getGroupBy'];
}) {
  const groups = groupBy(entities, entity => getGroupBy(entity));
  const values = compact(
    map(groups, (entities, key) => {
      const size = sumBy(entities, e => getSize(e).toNumber());
      // dont include data points if the size is 0
      if (size === 0) {
        return undefined;
      }

      return {
        key, // the grouping key
        label: getLabel(entities[0]), // getLabel will return the same for all entities, so just use the first one
        z: size,
        nlv: sumBy(entities, e => getNLV(e).toNumber()),
        x: sumBy(entities, e => getEquity(e).toNumber()),
        y: sumBy(entities, e => getEMR(e).toNumber()), // probably wrong but satisfies our dev purposes for now
      };
    })
  );

  const extremes = getExtremes(values);

  const dataPoints = values.map(value => {
    const color = getColor(value, extremes);
    return {
      id: value.key,
      x: value.x,
      y: value.y,
      z: value.z,
      color,
      custom: {
        // put these named nicely into this custom namespace too just to make things simpler down the line
        key: value.key,
        equity: value.x,
        emr: value.y,
        nlv: value.nlv,
        size: value.z,
      } satisfies OpsEMRChartPointCustom,
      name: value.label,
      // this below lets us find points by id in cypress tests and interact with them
      // Also, we cant grab classnames with / in cypress. So replace any / with - here for ease of testing.
      className: value.key.replaceAll('/', '-'),
    } satisfies PointOptionsObject;
  });

  return { dataPoints, paddingPoints: extremesToPaddingPoints(extremes) };
}

// Returns four points outside of the extremes we found in the actual data. The padding is done to enable a smoother zooming and panning experience when near the edges of the chart.
function extremesToPaddingPoints({ maxX, maxY, minX, minY }: ReturnType<typeof getExtremes>): PointOptionsObject[] {
  return [
    { x: maxX * 1.1, y: 0, z: 0 },
    { x: minX * 0.9, y: 0, z: 0 },
    { x: 0, y: maxY * 1.1, z: 0 },
    { x: 0, y: minY * 0.9, z: 0 },
  ];
}

// Simply finds the min and max x and y values given an array of points of type (x, y)
function getExtremes(values: { x: number; y: number }[]) {
  let maxX = 0;
  let minX = 0;
  let maxY = 0;
  let minY = 0;

  for (const value of values) {
    if (value.x > maxX) {
      maxX = value.x;
    }
    if (value.x < minX) {
      minX = value.x;
    }
    if (value.y > maxY) {
      maxY = value.y;
    }
    if (value.y < minY) {
      minY = value.y;
    }
  }

  return {
    maxX,
    minX,
    minY,
    maxY,
  };
}

/* Below is code to calculate the colors for a given point */
// These colors are taken from the figma Data Colors section: sequential blue.
const { red: startingR, green: startingG, blue: startingB } = parseToRgb('#AEBBDF'); // sequential blue 000
const { red: endingR, green: endingG, blue: endingB } = parseToRgb('#2C59D0'); // sequential blue 100

// "travel" or "distance" from starting value to ending value
const travelR = endingR - startingR;
const travelG = endingG - startingG;
const travelB = endingB - startingB;

function getColor(value: { x: number; y: number }, extremes: ReturnType<typeof getExtremes>): string {
  // Starting color is "top left", ending color is bottom right.
  // Points are given colors within this color range according to their x and y position relative to the found min and max x, y points.

  // for each point, we calculate where along the x and y axis they are. either of these distances represent 50% of the color traversal
  // if min is (1, 30) and max is (2, 200) then a 50% darkness will be for example (2, 30) or (1.5, 125), or (1, 200)
  // the x, y values of the point are normalized from (1.5, 125) to (0.5, 0.5) for example. We then do (0.5+0.5)/2 -> 0.5 -> 50%.
  const relativeXPosition = relativeAxisPosition(value.x, extremes.minX, extremes.maxX);
  // we do 1- here on the y axis because we want to flip the direction from darker upwards to darker downwards
  const relativeYPosition = 1 - relativeAxisPosition(value.y, extremes.minY, extremes.maxY);
  const colorMultiplier = (relativeXPosition + relativeYPosition) / 2; // result is a number 0-1 representing how far to go from brightest to darkest

  // multiply the maximum travel by how our color multiplier (some fraction 0-1)
  const r = startingR + colorMultiplier * travelR;
  const g = startingG + colorMultiplier * travelG;
  const b = startingB + colorMultiplier * travelB;

  // Converts the individual rgb numbers to a hex color
  return rgb(Math.round(r), Math.round(g), Math.round(b));
}

// Calculates a value 0-1 describing where along the axisMin-axisMax we are (0.5 = midpoint, 1 = max, etc)
function relativeAxisPosition(value: number, axisMin: number, axisMax: number): number {
  const range = axisMax - axisMin;
  const valueRelativeToRange = value - axisMin;
  if (range === 0) {
    return 1;
  }

  return valueRelativeToRange / range;
}

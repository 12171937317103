export const Logo = ({ fill }: React.SVGProps<SVGPathElement>) => (
  <>
    <path
      d="M83.7967 148.277C77.9221 142.846 74.9847 136.093 74.9847 128.017C74.9847 120.081 77.8529 113.363 83.5894 107.932C89.3258 102.467 96.4099 99.7172 104.773 99.7172C108.539 99.7172 112.514 100.379 116.66 101.632L119.252 91.7109C114.172 90.2141 109.196 89.4483 104.324 89.4483C93.3343 89.4483 83.935 93.2077 76.1251 100.727C68.3498 108.28 64.4449 117.331 64.4449 127.878C64.4449 138.634 68.4189 147.824 76.3325 155.447C83.0019 161.853 90.8118 165.508 99.762 166.552L102.458 156.248C95.0968 155.622 88.8766 152.976 83.7967 148.277Z"
      fill={fill}
    />
    <path
      d="M172.919 91.7455H172.297L150.215 145.004L137.913 115.486L134.008 130.454L149.075 166.551H151.425L170.811 120.289L182.768 166.551H186.466H192.409L172.919 91.7455Z"
      fill={fill}
    />
    <path
      d="M107.883 166.551H117.386L133.49 104.869L127.995 91.7455H127.408L107.883 166.551Z"
      fill={fill}
      fillOpacity="0.6"
    />
  </>
);

import {
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
} from '@talos/kyoko';
import { isEqual } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import type { SettlementMonitoringFilter } from '../types';
import type { SettleableTrade, TradeSettlementInternalFilter } from './types';

interface UseTradeSettlementFilterParams {
  initialInternalFilter?: TradeSettlementInternalFilter;
  saveInternalFilter: (newFilter: TradeSettlementInternalFilter) => void;
  externalFilter?: SettlementMonitoringFilter;
}

export const useTradeSettlementFilter = ({
  initialInternalFilter,
  saveInternalFilter,
  externalFilter,
}: UseTradeSettlementFilterParams) => {
  // Whats tricky about the filtering for this blotter is that we are taking in the top-level page filter (SettlementMonitoringFilter)
  // and then also have our own internal filtering (just a date range filter essentially)
  // We need to marry these two concepts

  const [internalFilter, setInternalFilter] = useState(cleanupInitialFilterDateRange(initialInternalFilter));

  const mergedFilter = useMemo(
    () => ({
      ...externalFilter,
      ...internalFilter,
    }),
    [externalFilter, internalFilter]
  );

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = internalFilter;
      const newFilter = action instanceof Function ? action(internalFilter) : action;
      if (!isEqual(priorFilter, newFilter)) {
        setInternalFilter(newFilter);
        saveInternalFilter(newFilter);
      }
    },
    [internalFilter, saveInternalFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<SettleableTrade>>(
    row => {
      if (filterExistsAndExcludes(mergedFilter, 'MarketAccounts', row.data, 'MarketAccount')) {
        return false;
      }

      // We have disabled symbol filtering by now, todo backend
      // if (filterExistsAndExcludes(mergedFilter, 'Symbols', row.data, 'Symbol')) {
      //   return false;
      // }

      if (filterExistsAndExcludes(mergedFilter, 'Statuses', row.data, 'SettleStatus')) {
        return false;
      }

      return true;
    },
    [mergedFilter]
  );

  return {
    filter: mergedFilter,
    changeFilter,
    clientSideFilter,
  };
};

import { ACTION } from '@talos/kyoko';
import type { SidebarSubNavItem, SidebarSubTitle } from '@talos/kyoko/src/routed';
import { useRoleAuth, useUser } from 'hooks';
import { useEffect, useMemo, useState } from 'react';

export const useAdminSubNavItems = () => {
  const [adminSubNavItems, setAdminSubNavItems] = useState<(SidebarSubNavItem | SidebarSubTitle)[]>([]);
  const { isAuthorized } = useRoleAuth();
  const user = useUser();
  useEffect(() => {
    const allowAdminNav = user.IsMorphed || isAuthorized(ACTION.VIEW_TALOS_ADMIN_SETTINGS);
    if (allowAdminNav) {
      import('../../index').then(module => setAdminSubNavItems(module.ADMIN_SUB_NAV_ITEMS));
    }
  }, [user.IsMorphed, isAuthorized]);

  return useMemo(() => adminSubNavItems, [adminSubNavItems]);
};

import { useMemo, type LegacyRef } from 'react';
import { useTheme } from 'styled-components';
import { v1 as uuid } from 'uuid';
import { FormControlSizes, type FormControlProps } from '../types';

import { Icon, IconName } from '../../Icons';
import { CheckBox, CheckBoxLabel, CheckboxBox, CheckboxWrapper } from './styles';

export { CheckBoxLabel } from './styles';

export function Checkbox({
  id,
  indeterminate,
  disabled,
  name,
  children,
  onChange,
  className,
  checked,
  wrapperRef,
  size = FormControlSizes.Default,
  inputRef,
  ...props
}: CheckboxProps) {
  const { baseSize } = useTheme();
  const forId = useMemo(() => `${id ? id : name ?? ''}${uuid()}`, [id, name]);
  const tickSize = (size * baseSize * 0.875) / 2;
  return (
    <CheckboxWrapper ref={wrapperRef} disabled={disabled} checked={checked}>
      <CheckBox isDisabled={disabled} isIndeterminate={indeterminate} isChecked={checked && !indeterminate} size={size}>
        <input
          data-checked={checked}
          data-indeterminate={indeterminate}
          checked={checked}
          disabled={disabled}
          type="checkbox"
          onChange={onChange}
          value=""
          id={forId}
          name={name}
          ref={inputRef}
          {...props}
        />
        <CheckboxBox>
          {indeterminate ? (
            <Icon size={tickSize} icon={IconName.Minus} />
          ) : checked ? (
            <Icon size={tickSize} icon={IconName.Check} />
          ) : null}
        </CheckboxBox>
      </CheckBox>
      {children && (
        <CheckBoxLabel htmlFor={forId} isDisabled={disabled} isChecked={checked} size={size}>
          {children}
        </CheckBoxLabel>
      )}
    </CheckboxWrapper>
  );
}

type CheckboxProps = {
  indeterminate?: boolean;
  inputRef?: LegacyRef<HTMLInputElement>;
} & Omit<FormControlProps<HTMLInputElement>, 'prefix'>;

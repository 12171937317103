import { type Column, Flex, IconName, InlineFormattedNumber, Text, toBigWithDefault } from '@talos/kyoko';
import type { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { LegendIndicator } from './styles';
import type { MarketDistributionItem } from './types';

export const MarketDistributionMarketColumn = {
  type: 'custom',
  field: 'market',
  title: 'Market',
  pinned: 'left',
  params: {
    cellRenderer: (props: ICellRendererParams<MarketDistributionItem>) => {
      return (
        <Flex gap="spacingSmall">
          {props.data?.color && <LegendIndicator background={props.data.color} />}
          <Text>{props.value}</Text>
        </Flex>
      );
    },
  },
} satisfies Column;

export const MarketDistributionAveragePriceColumn = ({ tab }: { tab: 'open' | 'filled' }) =>
  ({
    type: 'custom',
    id: 'avgPx',
    title: 'Average Price',
    headerClass: 'ag-right-aligned-cell',
    cellClass: 'ag-right-aligned-cell',
    params: {
      valueGetter: (props: ValueGetterParams<MarketDistributionItem>) => {
        const market = props.data!;
        const avgPx = market.avgPxAllIn ? market.avgPxAllIn : market.avgPx;
        if (tab === 'open' && toBigWithDefault(market.leavesQty, 0).eq(0)) {
          return '-';
        }
        return avgPx;
      },
      cellRenderer: (props: ICellRendererParams<MarketDistributionItem>) => {
        const market = props.data!;
        const feeIncludedInPrice = !!market.avgPxAllIn;
        const avgPx = props.value;

        if (props.value === '-') {
          return '-';
        }

        return (
          <InlineFormattedNumber
            number={avgPx}
            startIcon={feeIncludedInPrice ? IconName.Fees : undefined}
            currency={market.priceCurrency}
            increment={market.priceIncrement}
          />
        );
      },
    },
  } satisfies Column);

export const MarketDistributionPriceColumn = {
  type: 'custom',
  id: 'avgPx',
  field: 'price',
  title: 'Average Price',
  headerClass: 'ag-right-aligned-cell',
  cellClass: 'ag-right-aligned-cell',
  params: {
    cellRenderer: (props: ICellRendererParams<MarketDistributionItem>) => {
      const market = props.data;

      if (!market?.leavesQty || market?.leavesQty === '0') {
        return '-';
      }

      return (
        <InlineFormattedNumber number={props.value} currency={market.priceCurrency} increment={market.priceIncrement} />
      );
    },
  },
} satisfies Column;

export const MarketDistributionSymbolColumn = ({ pinned, showColor }: { showColor?: boolean; pinned?: boolean }) =>
  ({
    type: 'custom',
    field: 'symbol',
    title: 'Symbol',
    pinned: pinned ? 'left' : undefined,
    params: {
      cellRenderer: (props: ICellRendererParams<MarketDistributionItem>) => {
        return (
          <Flex gap="spacingSmall">
            {showColor && props.data?.color && (
              <>
                <LegendIndicator background={props.data.color} />
              </>
            )}
            <Text>{props.value}</Text>
          </Flex>
        );
      },
    },
  } satisfies Column);

import {
  Alert,
  AlertVariants,
  ButtonGroup,
  Dialog,
  Divider,
  FormControlSizes,
  FormGroup,
  HStack,
  Icon,
  ICON_SIZES,
  IconName,
  Input,
  NotificationVariants,
  runValidation,
  SearchSelect,
  Text,
  TextArea,
  Toggle,
  useCallbackRef,
  useGlobalToasts,
  VStack,
} from '@talos/kyoko';
import { identity } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { type BaseSchema, object, string } from 'yup';
import { useFeatureFlag } from '../../hooks';
import { Link } from '../OMS/styles';
import type { CreateSupportTicketResponse } from './types';
import {
  availableRequestTypes,
  getAvailablePriorities,
  type SupportFormArg,
  type SupportTicketDialogProps,
  type SupportTicketForm,
} from './types';
import { useSupportTicketDialog } from './useSupportTicketDialog';

const schema: Partial<Record<keyof SupportTicketForm, BaseSchema>> = {
  summary: string().required('Summary is required'),
  description: string().required('Description is required'),
};

const initialForm: SupportTicketForm = {
  summary: '',
  requestType: availableRequestTypes[0],
  description: '',
  priority: 'Medium',
  pagerDutyAlert: false,
};

export function SupportTicketDialog(props: SupportTicketDialogProps) {
  const { dialog } = props;
  const { isOpen } = dialog;
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<CreateSupportTicketResponse | undefined>(undefined);
  const [form, setForm] = useState<SupportTicketForm>(initialForm);
  const { createTicket } = useSupportTicketDialog();
  const [errors, setErrors] = useState<Partial<SupportTicketForm>>(validate(form));
  const { add: addToast } = useGlobalToasts();
  const [confirmInput, setConfirmInput] = useState<string>('');

  const needToConfirm = form.pagerDutyAlert;
  const isValid = useMemo(
    () =>
      Object.keys(errors).length === 0 &&
      ((needToConfirm && confirmInput.toLocaleLowerCase() === 'confirm') || !needToConfirm),
    [errors, needToConfirm, confirmInput]
  );
  const isDirty = useMemo((): boolean => {
    return form.summary !== '' || form.description !== '';
  }, [form]);
  const { enableSupportModalPagerDuty } = useFeatureFlag();

  const availablePriorities = useMemo(() => getAvailablePriorities(form.requestType), [form.requestType]);

  const updateForm = useCallbackRef(({ key, value }: SupportFormArg) => {
    const newForm = { ...form, [key]: value };
    const newAvailablePriorities = getAvailablePriorities(newForm.requestType);
    if (!newAvailablePriorities.includes(newForm.priority)) {
      newForm.priority = 'Medium';
    }
    setForm(newForm);
    setErrors(validate(newForm));
  });

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    createTicket(form)
      .then((response: CreateSupportTicketResponse) => {
        setResponse(response);
      })
      .catch((e: ErrorEvent) => {
        addToast({
          variant: NotificationVariants.Negative,
          text: `Unable to create Support Ticket: ${e.message}`,
        });
      })
      .finally(() => setLoading(false));
  }, [createTicket, form, addToast]);

  // Reset form on dialog open
  useEffect(() => {
    if (isOpen) {
      setForm(initialForm);
      setErrors({});
      setResponse(undefined);
      setConfirmInput('');
    }
  }, [isOpen]);

  return (
    <Dialog
      headerIcon={IconName.Chat}
      title="Support Ticket"
      {...dialog}
      confirmLabel="Send Support Ticket"
      confirmDisabled={!isDirty || !isValid || loading}
      confirmLoading={loading}
      cancelLabel={response ? 'Close' : 'Cancel'}
      onConfirm={handleSubmit}
      closeOnConfirm={false}
      showClose
      showConfirm={!response}
      stretchButtons
      width={600}
    >
      {!response && (
        <>
          <FormGroup label="Summary" controlId="ticket-summary" error={errors.summary}>
            <Input
              id="ticket-summary"
              value={form.summary}
              onChange={e => updateForm({ key: 'summary', value: e.target.value })}
              inputType="text"
              data-testid="ticket-summary"
              disabled={loading}
            />
          </FormGroup>

          <FormGroup label="Type" flex="1">
            <SearchSelect
              options={availableRequestTypes}
              getLabel={identity}
              initialSortByLabel={false}
              showDropdownSearch={false}
              selection={form.requestType}
              onChange={value => updateForm({ key: 'requestType', value: value || '' })}
              data-testid="ticket-type"
              disabled={loading}
            />
          </FormGroup>

          <FormGroup label="Priority" flex="1">
            <SearchSelect
              options={availablePriorities}
              getLabel={identity}
              initialSortByLabel={false}
              showDropdownSearch={false}
              selection={form.priority}
              onChange={value => updateForm({ key: 'priority', value: value || '' })}
              data-testid="ticket-priority"
              disabled={loading}
            />
          </FormGroup>

          <FormGroup label="Description" error={errors.description}>
            <TextArea
              rows={3}
              value={form.description}
              onChange={e => updateForm({ key: 'description', value: e.target.value })}
              style={{ textAlign: 'left' }}
              data-testid="ticket-description"
              disabled={loading}
            />
          </FormGroup>

          <HStack
            mb="spacingMedium"
            gap="spacingSmall"
            alignItems="flex-start"
            justifyContent="flext-start"
            textAlign="left"
          >
            <Icon icon={IconName.InformationCircle} />
            <Text>In case of urgent issues, please contact Talos Support on {import.meta.env.VITE_SUPPORT_PHONE}</Text>
          </HStack>

          {enableSupportModalPagerDuty && form.priority === 'Highest' && (
            <>
              <Divider />
              <FormGroup
                mt="spacingDefault"
                mb="0"
                inline
                label="Escalate"
                alignItems="center"
                tooltip="Escalate your issue to on call support"
              >
                <ButtonGroup justifyContent="flex-end" flex="1">
                  <Toggle
                    data-testid="ticket-pagerduty-toggle"
                    size={FormControlSizes.Default}
                    checked={form.pagerDutyAlert}
                    onChange={(val: boolean) =>
                      updateForm({
                        key: 'pagerDutyAlert',
                        value: val,
                      })
                    }
                  />
                </ButtonGroup>
              </FormGroup>
              {form.pagerDutyAlert && (
                <VStack gap="spacingDefault">
                  <Alert mt="spacingDefault" dismissable={false} variant={AlertVariants.Warning}>
                    You are about to page a member of the support staff for a matter of emergency. Would you like to
                    proceed?
                  </Alert>
                  <FormGroup label="To confirm, type “confirm” below" style={{ marginBottom: 0 }} w="100%">
                    <Input
                      data-testid="confirm-input"
                      value={confirmInput}
                      onChange={e => setConfirmInput(e.target.value)}
                    />
                  </FormGroup>
                </VStack>
              )}
            </>
          )}
        </>
      )}
      {response && (
        <>
          {form.pagerDutyAlert && (
            <Alert mb="spacingLarge" dismissable={false} variant={AlertVariants.Warning}>
              A member of the support staff has been paged.
            </Alert>
          )}
          <VStack gap="spacingMedium" justifyContent="center" h="100%">
            <Icon icon={IconName.CheckCircle} color="colors.green.lighten" size={ICON_SIZES.MEDIUM * 2} />
            <Text fontSize="fontSizeHuge" weight="500" color="colorTextImportant">
              Support Ticket Sent
            </Text>
            <Text size="fontSizeLarge">
              We have received your support ticket and will get back to you as soon as possible.
            </Text>
            <Link href={response.supportLink} target="_blank">
              {response.supportLink}
            </Link>
          </VStack>
        </>
      )}
    </Dialog>
  );
}

const validate = function (form: Partial<SupportTicketForm>) {
  return runValidation(object().shape(schema), form);
};

import styled from 'styled-components';
import { getIconButtonSize } from '../Button';
import { HStack } from '../Core';
import type { FormControlSizes } from '../Form';

export const SwitchWrapper = styled(HStack)<{ size: FormControlSizes }>`
  background: ${({ theme }) => theme.backgroundToggle};
  border-radius: ${({ theme, size }) => (size / 1.5) * theme.borderRadiusDefault}px;
  padding: ${({ theme }) => theme.spacingTiny}px;
  height: ${({ theme, size }) => getIconButtonSize(theme, size) + theme.spacingTiny * 2}px;
  align-items: stretch;
`;

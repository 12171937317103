import type { UseDisclosureReturn } from '@talos/kyoko';

export interface SupportTicketDialogProps {
  dialog: UseDisclosureReturn;
}

export interface CreateSupportTicketRequest {
  Summary: string;
  Description: string;
  RequestType: 'GeneralQuery' | 'OrderIssue';
  Priority: 'Highest' | 'High' | 'Medium' | 'Low';
  Escalate: boolean;
}

export interface CreateSupportTicketResponse {
  supportLink: string;
}

export interface SupportTicketForm {
  summary: string;
  requestType: string;
  priority: string;
  description: string;
  pagerDutyAlert: boolean;
}

export type SupportFormArg = {
  [K in keyof Required<SupportTicketForm>]: { key: K; value: SupportTicketForm[K] };
}[keyof SupportTicketForm];

export const availableRequestTypes = ['General query or request', 'Order Issue'];
export const getAvailablePriorities = (requestType: string): string[] => {
  const result = ['High', 'Medium', 'Low'];
  if (requestType === 'Order Issue') {
    result.unshift('Highest');
  }
  return result;
};

import { invariant } from '@epic-web/invariant';
import type { createApi } from '@reduxjs/toolkit/query';
import { Get, isErrorResponse, Post, type RestResponse } from '@talos/kyoko';
import type { AppState } from './types';

/**
 * Base Query for RTK Query createApi for Ava GET and POST requests (add more as you need)
 */
export const restEndpointBaseQuery: Parameters<typeof createApi>[0]['baseQuery'] = async (
  { url, method, body, headers },
  api,
  _extraOptions
) => {
  const currentState = api.getState() as AppState;
  invariant(currentState.userInfo.user, "User Should be known before any api's are called");
  const orgApiEndpoint = `${window.location.protocol}//` + currentState.userInfo.user.OrgApiEndpoint + '/v1';
  switch (method) {
    case 'GET':
      try {
        const result = await Get<RestResponse<unknown>>(orgApiEndpoint, url, {
          method,
          headers,
          body,
          credentials: 'include',
        });
        if (isErrorResponse(result)) {
          return { error: new Error(result.error_msg) };
        } else {
          return { data: result.data };
        }
      } catch (error: unknown) {
        return { error: new Error('An error occurred during the GET request: ' + (error as Error).message) };
      }
    case 'POST':
      try {
        const postResult = await Post<RestResponse<unknown>>(orgApiEndpoint, url, {
          method: method || 'POST',
          headers,
          body,
          credentials: 'include',
        });
        if (isErrorResponse(postResult)) {
          return { error: new Error(postResult.error_msg) };
        }
        return { data: postResult.data };
      } catch (error) {
        return { error: new Error('An error occurred during the POST request: ' + (error as Error).message) };
      }
    default:
      return { error: new Error(`Unsupported method: ${method}`) };
  }
};

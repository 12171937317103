import { useGlobalToasts } from 'hooks/Toast/useGlobalToasts';
import { useCallback, useState } from 'react';
import { Button, ButtonVariants } from '../../Button';
import { Box, VStack } from '../../Core';
import { DrawerContent, DrawerFooter } from '../../Drawer';
import { TextArea } from '../../Form/TextArea';
import { NotificationVariants } from '../../Notification';
import type { EntityAdminRecord } from '../types';
import type { InputsAndDropdownsDrawerProps } from './InputsAndDropdownsDrawer';

export function ModifyJsonDrawer<TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord>({
  selectedEntity,
  allowDeleteEntity,
  onSaveEntity,
  onDeleteEntity,
}: Omit<InputsAndDropdownsDrawerProps<TRecord, TDrawerRecord>, 'isEditing' | 'drawerOptions' | 'addingChildEntity'>) {
  const { add: addToast } = useGlobalToasts();

  const [modifiedEntityString, setModifiedEntityString] = useState<string>(JSON.stringify(selectedEntity, null, 4));

  const handleDelete = useCallback(() => {
    onDeleteEntity([selectedEntity!]);
  }, [onDeleteEntity, selectedEntity]);

  const handleOnSave = useCallback(() => {
    try {
      const modifiedEntity = JSON.parse(modifiedEntityString) as TDrawerRecord;
      onSaveEntity(modifiedEntity);
    } catch {
      addToast({
        text: 'Failed to save entity. Please check the JSON format.',
        variant: NotificationVariants.Negative,
      });
    }
  }, [addToast, modifiedEntityString, onSaveEntity]);

  const showDeleteButton = allowDeleteEntity && selectedEntity != null;

  return (
    <VStack data-testid="entity-admin-page-json-drawer" h="100%">
      <DrawerContent overflow="overlay" w="100%">
        <Box>
          <TextArea value={modifiedEntityString} rows={15} onChange={e => setModifiedEntityString(e.target.value)} />
        </Box>
      </DrawerContent>
      <DrawerFooter w="100%">
        {showDeleteButton && (
          <Button
            variant={ButtonVariants.Negative}
            onClick={handleDelete}
            data-testid="modify-json-drawer-delete-button"
          >
            Delete
          </Button>
        )}
        <Button onClick={handleOnSave} variant={ButtonVariants.Primary} data-testid="modify-json-drawer-save-button">
          Save
        </Button>
      </DrawerFooter>
    </VStack>
  );
}

import type { IAggFuncParams, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { get } from 'lodash-es';
import { Order } from '../../../types/Order';
import { WarningSeverity, prettyWarningSeverity } from '../../../types/WarningSeverity';
import type { AgGridWarningProps } from '../../AgGrid/types';
import { OrderWarnings } from '../../OrderWarnings';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';
import { WARNING_COLID, type WarningColumnValue } from './warning.types';

export interface WarningColumnParams {
  tooltipWidth?: string;
}

const WARNING_COL_WIDTH = 22;
export const warning: ColDefFactory<Column<WarningColumnParams>> = column => ({
  ...baseColumn(column),
  colId: column.id ?? WARNING_COLID,
  width: WARNING_COL_WIDTH,
  maxWidth: WARNING_COL_WIDTH,
  minWidth: WARNING_COL_WIDTH,
  cellClass: 'ag-cell-warning',
  headerClass: 'ag-header-no-title',
  headerComponent: 'warningHeader',
  resizable: false,
  // If null is provided, render warning cell not pinned to the left.
  pinned: column.pinned === null ? column.pinned : 'left',
  cellRenderer: 'warningColumn',
  sortable: true,
  sortingOrder: [null, 'desc', 'asc'],
  menuTabs: [],
  cellRendererParams: (params: ICellRendererParams<unknown, WarningColumnValue | undefined>): AgGridWarningProps => {
    if (params.data instanceof Order && params.data.warning) {
      return {
        ...params,
        tooltipWidth: column.params?.tooltipWidth,
        tooltip: <OrderWarnings warning={params.data.warning} />,
      };
    }

    // The base case where we just have a severity and no data specific data type
    if (params.value?.severity != null) {
      return params;
    }

    // If there exists no severity on the value, explicitly set value to completely undefined
    return {
      ...params,
      value: undefined,
    };
  },
  aggFunc: !column.aggregate
    ? undefined
    : ({
        values,
        rowNode,
        api,
        column,
      }: IAggFuncParams<unknown, WarningColumnValue | undefined>): WarningColumnValue | undefined => {
        // This aggFunc just reduces a list of warning severities into the one highest severity.

        // myValue exists to support tree blotters where group rows have their own data. Recall that aggFuncs are only called when rowNode is a group node,
        // so at this point we are either a regular group row or a tree group row. We distinguish between the two by checking if this groupRow has any data of
        // its own, in which case this aggFunc needs to take it into consideration.
        const isTreeGroupRow = rowNode.data != null;
        const myValue: WarningColumnValue | undefined = isTreeGroupRow
          ? api.getCellValue({ colKey: column.getColId(), rowNode }) ?? undefined
          : undefined;

        const highestChildValue = values.reduce((aggregate: WarningColumnValue | undefined, value) => {
          if (value == null) {
            return aggregate;
          }

          // If nothing has been instantiated yet, set the memory as this first value
          if (aggregate?.severity == null) {
            return value;
          }

          if (aggregate.severity < value.severity) {
            return value;
          }

          return aggregate;
        }, undefined as WarningColumnValue | undefined);

        // Based on the severity, we return the _complete_ values.
        return (myValue?.severity ?? WarningSeverity.NONE) > (highestChildValue?.severity ?? WarningSeverity.NONE)
          ? myValue
          : highestChildValue;
      },
  valueFormatter: (params: ValueFormatterParams<unknown, WarningColumnValue | undefined>) => {
    return params.value == null ? '' : prettyWarningSeverity(params.value.severity, params.context.current.intl);
  },
  /**
   * The point of this value getter is just to do normal value getting (based off some provided field), but also attach
   * the node id to the value. (This is typed into the WarningColumnValue). This is done such that when warnings are aggregated
   * (think "bubbled up to the top"), a higher-level cell renderer can be much more flexible about what to render given the knowledge
   * of what node's warning has been aggregated to the top.
   */
  valueGetter: (params: ValueGetterParams<unknown>): WarningColumnValue | undefined => {
    const data = params.node?.data;
    if (!data || !column.field) {
      return undefined;
    }

    return {
      ...get(data, column.field),
      nodeId: params.node?.id,
    };
  },
  comparator: (a: WarningColumnValue | undefined, b: WarningColumnValue | undefined) => {
    return (a?.severity || 0) - (b?.severity || 0);
  },
});

import type { UnifiedLiquidityResponse } from '@talos/kyoko';
import { groupBy } from 'lodash-es';

export function getUnifiedLiquidityLegParams(
  symbol: string | undefined,
  unifiedLiquidityTokens: Map<string, UnifiedLiquidityResponse> | undefined,
  marketAccounts?: string[]
) {
  if (symbol == null) {
    return undefined;
  }
  const bySymbol = groupBy(unifiedLiquidityTokens?.get(symbol)?.Tokens, 'Symbol');
  const legParams = Object.entries(bySymbol).map(([Symbol]) => {
    return {
      Symbol,
      Markets: marketAccounts ?? [],
      Initiating: false,
      LegIndex: undefined,
    };
  });
  return legParams;
}

import type { GroupPanelViewState, SerializedDockview } from 'dockview';
import { uniq } from 'lodash-es';
import { LEAF, MAIN_GROUP_ID } from '../../../components/Layout/tokens';
import { getAddedPanels } from './getAddedPanels';
import { getLargestLayoutGroup } from './getLargestGroup';
import { getLayoutGroupById } from './getLayoutGroupById';

export function resetLayoutToDefault(
  currentLayout: SerializedDockview,
  defaultLayout: SerializedDockview
): SerializedDockview {
  const newLayout = structuredClone(defaultLayout);

  // Get all panels that's not in the initial layout
  const addedPanels = getAddedPanels(defaultLayout, currentLayout);

  // Find the main group and add the panels
  const mainGroup = getLayoutGroupById(newLayout, MAIN_GROUP_ID) ?? getLargestLayoutGroup(newLayout);
  if (mainGroup && mainGroup.type === LEAF) {
    const mainGroupData = mainGroup.data as GroupPanelViewState;
    const defaultPanels = mainGroupData.views.map(view => defaultLayout.panels[view]);
    const currentPanels = new Map(Object.values(currentLayout.panels).map(panel => [panel.contentComponent, panel]));

    // Remove default panels with contentTypes that are already in the current layout
    const defaultPanelsToRestore = defaultPanels.filter(defaultPanel => {
      const currentPanel = currentPanels.get(defaultPanel.contentComponent);
      return currentPanel == null || currentPanel.id === defaultPanel.id;
    });
    const defaultPanelsToRemove = defaultPanels.filter(defaultPanel => !defaultPanelsToRestore.includes(defaultPanel));
    const defaultViews = defaultPanelsToRestore.map(panel => panel.id);
    mainGroupData.views = uniq([...defaultViews, ...addedPanels]);

    newLayout.panels = { ...defaultLayout.panels, ...currentLayout.panels };
    defaultPanelsToRemove.forEach(panel => delete newLayout.panels[panel.id]);
    return newLayout;
  }
  return defaultLayout;
}

import { ConnectionType, useMarketAccountsContext, useUnifiedLiquidity } from '@talos/kyoko';
import { compact, uniq } from 'lodash-es';
import { useMemo } from 'react';
import { useTradableMarketAccountNames, type UseTradableMarketAccountNamesParams } from '../../../hooks';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';
import { useBuildMarketSelectorItems } from '../utils/useBuildMarketSelectorItems';
import { useOrderMarketSelectorConditions } from './useOrderMarketSelectorConditions';

// We have some async MSS conditions which we want to give a second to load before we send out our initial set of
// market selector items. This helps us avoid the case where we default the order form to items which a second later
// turn out to be disabled in the org secmaster, for example
export const ORDER_FORM_WAIT_FOR_CONDITIONS_MS = 1500;

export type UseOrderMarketSelectorItemsParams = Omit<
  UseTradableMarketAccountNamesParams,
  'connectionType' | 'includeOfflineMarkets' | 'combineAllMarketAccounts'
>;

export const useOrderMarketSelectorItems = (params: UseOrderMarketSelectorItemsParams) => {
  const { enableNewMarketSelector } = useTradingSettings();
  const { marketAccountsByName } = useMarketAccountsContext();

  const availableMarketAccountNames = useTradableMarketAccountNames({
    ...params,
    connectionType: ConnectionType.Orders,
  });

  const marketAccounts = useMemo(
    () => compact(availableMarketAccountNames.map(name => marketAccountsByName.get(name))),
    [availableMarketAccountNames, marketAccountsByName]
  );

  const ulResponse = useUnifiedLiquidity(params.isUnifiedLiquidityEnabled ? params.security?.Symbol : undefined);
  const extraULSymbols = useMemo(() => ulResponse?.Tokens.map(token => token.Symbol) ?? [], [ulResponse]);

  // There's usually just one Symbol relevant for an order. However, in the UL case, we might have to extend it to several.
  const symbols = useMemo(
    () => uniq(compact([params.security?.Symbol, ...extraULSymbols])),
    [params.security?.Symbol, extraULSymbols]
  );

  const { conditions } = useOrderMarketSelectorConditions({
    symbols,
    unifiedLiquidityEnabled: params.isUnifiedLiquidityEnabled,
  });

  return useBuildMarketSelectorItems({
    marketAccounts,
    conditions,
    // This is new market selector behavior
    waitForConditionsMs: enableNewMarketSelector ? ORDER_FORM_WAIT_FOR_CONDITIONS_MS : undefined,
  });
};

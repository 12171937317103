import {
  abbreviateId,
  ACTION,
  getRowNodesToOperateOn,
  getShowJSONContextItem,
  HedgeCommandEnum,
  HedgeControlStatusEnum,
  IconName,
  LedgerAccountTypeEnum,
  MixpanelEventSource,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
} from '@talos/kyoko';
import type { GetContextMenuItems, GetContextMenuItemsParams, IRowNode, MenuItemDef } from 'ag-grid-enterprise';
import { getOrderDetailsRoute } from 'containers/Routes/routes';
import { useRoleAuth } from 'hooks/useRoleAuth';
import { compact } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { from, mergeMap } from 'rxjs';
import type { HedgeCommandResponse, SingleHedgeCommandPayload } from '../../../components/AutoHedging/types';
import { useFeatureFlag } from '../../../hooks';
import { useNavigateToOrderDetails } from '../../../hooks/useNavigateToOrderDetails';
import type { IHedgePositionStatusRow } from '../../../providers/HedgePositionStatusProvider';
import { useGetOpenTransactionsMenuItems } from '../../Trading/Markets/AccountLedgerEvents/useGetOpenTransactionsMenuItems';
import { useHedgeRuleCommandMutation } from '../../Trading/Markets/PositionAutoHedgingRules/queries';
import { openDetailsMenuItem } from '../Orders/useOrderMenu';

export const useHedgePositionStatusMenu = (): {
  getContextMenuItems: GetContextMenuItems<IHedgePositionStatusRow>;
  modals: React.ReactNode[];
} => {
  const { isAuthorized } = useRoleAuth();

  const { enableAccountLedgerEventsPage } = useFeatureFlag();

  const openDetailsItem = useDynamicCallback((rows: IRowNode<IHedgePositionStatusRow>[]) => {
    const orderIds = rows
      .map(row => row.data?.HedgeOrderID)
      .filter<string>((maybeString): maybeString is string => maybeString !== undefined);

    if (orderIds.length === 0) {
      return null;
    }

    return openDetailsMenuItem({
      orders: orderIds.map(OrderID => ({ OrderID })),
      onOpenDetails,
      text: 'Open Hedge Order Details',
    });
  });

  const hedgeRuleCommandMutation = useHedgeRuleCommandMutation();
  const sendHedgeCommand = useCallback(
    (payload: SingleHedgeCommandPayload) => {
      return hedgeRuleCommandMutation.mutateAsync(payload);
    },
    [hedgeRuleCommandMutation]
  );

  const getOpenTransactionsMenuItems = useGetOpenTransactionsMenuItems();
  const getMenuItems = useDynamicCallback((rows: IRowNode<IHedgePositionStatusRow>[]) => {
    const items: (MenuItemDef | null)[] = [];

    if (isAuthorized(ACTION.VIEW_ORDER)) {
      items.push(openDetailsItem(rows));
    }
    items.push(enableHedgePositionMenuItem({ rows, sendHedgeCommand }));
    items.push(disableHedgePositionMenuItem({ rows, sendHedgeCommand }));

    const hedgeRule = rows?.[0]?.data;
    if (isAuthorized(ACTION.VIEW_LEDGER_EVENTS) && enableAccountLedgerEventsPage && hedgeRule) {
      items.push(
        ...getOpenTransactionsMenuItems({
          asset: hedgeRule.Asset,
          account: hedgeRule.AccountID,
          type: LedgerAccountTypeEnum.SubAccount,
          source: MixpanelEventSource.PositionAutoHedgingBlotter,
          name: `Open transactions for: ${abbreviateId(hedgeRule.HedgeRuleID)}`,
        })
      );
    }
    return compact(items);
  });
  const onOpenDetails = useNavigateToOrderDetails(getOrderDetailsRoute);

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const { handleClickJson, jsonModal } = useJsonModal();

  const getContextMenuItems: GetContextMenuItems<IHedgePositionStatusRow> = useDynamicCallback(
    (params: GetContextMenuItemsParams<IHedgePositionStatusRow>) => {
      const menuItems = getMenuItems(getRowNodesToOperateOn(params));

      return compact([
        ...menuItems,
        ...getDefaultContextMenuItems(params),
        params?.node?.data ? getShowJSONContextItem({ params, handleClickJson }) : null,
      ]);
    }
  );

  const modals = useMemo(() => [jsonModal], [jsonModal]);

  return {
    getContextMenuItems,
    modals,
  };
};

const enableHedgePositionMenuItem = ({
  rows,
  sendHedgeCommand,
}: {
  rows: IRowNode<IHedgePositionStatusRow>[];
  sendHedgeCommand: (payload: SingleHedgeCommandPayload) => Promise<HedgeCommandResponse>;
}) => {
  const disabledRowIds = compact(
    rows
      .filter(row => row.data?.HedgeControlStatus === HedgeControlStatusEnum.Disabled)
      .map(row => row.data?.HedgeRuleID)
  );

  if (disabledRowIds.length === 0) {
    return null;
  }

  return {
    name: `Enable ${disabledRowIds.length} Hedge Position${disabledRowIds.length > 1 ? 's' : ''}`,
    action: () => {
      return from(disabledRowIds)
        .pipe(
          mergeMap(
            hedgeRuleId =>
              sendHedgeCommand({
                Command: HedgeCommandEnum.Activate,
                HedgeRuleID: hedgeRuleId,
                HedgeControlType: 'Position',
              }),
            5 // make sure at most 5 requests are sent at a time
          )
        )
        .subscribe();
    },
    icon: `<i class="ag-icon ${IconName.CheckCircleSolid}"/>`,
  };
};
const disableHedgePositionMenuItem = ({
  rows,
  sendHedgeCommand,
}: {
  rows: IRowNode<IHedgePositionStatusRow>[];
  sendHedgeCommand: (payload: SingleHedgeCommandPayload) => Promise<HedgeCommandResponse>;
}) => {
  const enabledRowIds = compact(
    rows
      .filter(
        row =>
          row.data?.HedgeControlStatus !== HedgeControlStatusEnum.Disabled &&
          row.data?.HedgeControlStatus !== HedgeControlStatusEnum.Disabling
      )
      .map(row => row.data?.HedgeRuleID)
  );

  if (enabledRowIds.length === 0) {
    return null;
  }

  return {
    name: `Disable ${enabledRowIds.length} Hedge Position${enabledRowIds.length > 1 ? 's' : ''}`,
    action: () => {
      return from(enabledRowIds)
        .pipe(
          mergeMap(
            hedgeRuleId =>
              sendHedgeCommand({
                Command: HedgeCommandEnum.Kill,
                HedgeRuleID: hedgeRuleId,
                HedgeControlType: 'Position',
              }),
            5 // make sure at most 5 requests are sent at a time
          )
        )
        .subscribe();
    },
    icon: `<i class="ag-icon ${IconName.CheckCircleSolid}"/>`,
  };
};

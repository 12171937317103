import type { ApplyColumnStateParams, ColumnState, GridApi, GridOptions } from 'ag-grid-community';
import { useCallback, useMemo, useRef } from 'react';
import { useCallbackRef } from '../../hooks/useCallbackRef';
import {
  blotterColumnStateToColumnState,
  columnStateToBlotterColumnState,
  useBlotterTableStorageById,
} from './BlotterTableStorageContext';
import { getAgGridColId } from './columns/getAgGridColId';
import type { Column } from './columns/types';
import { isColumn, type BlotterTableFilter } from './types';
import type { UseBlotterTableProps } from './useBlotterTable/types';

export type UsePersistedColumnsChangedParams<R = any> = {
  columns: Column<R>[];
  api: GridApi<R>;
};
export type UsePersistedSortChangedParams<R = any> = {
  api: GridApi<R>;
};
export type UsePersistedOnApplyInitialStateParams<R> = {
  api: GridApi<R>;
  /** is this a first time application, or a blotter reset */
  isFirstTimeApply: boolean;
};
export type UsePersistedBlotterTable<R, RSort = R> = Pick<UseBlotterTableProps<R>, 'columns'> & {
  /** Filtered-out Columns  */
  columnsOnly: Column<R>[];
  initialFilter?: BlotterTableFilter;
  initialSort?: UseBlotterTableProps<RSort>['initialSort'];
  onSortChanged: (params: UsePersistedSortChangedParams) => void;
  onColumnsChanged: (params: UsePersistedColumnsChangedParams) => void;
  onFilterChanged(filters?: BlotterTableFilter): void;
  gridOptionsOverlay: Pick<GridOptions<R>, 'onFirstDataRendered' | 'onGridReady'>;
  onBlotterColumnReset: (params: { api: GridApi<R> }) => void;
};

type PersistenceProps = {
  persistSort?: boolean;
  persistFilter?: boolean;
  persistColumns?: boolean;
};

type ApplyInitialStateArg<R> = {
  api: GridApi<R>;
  applyColumnStateParams: ApplyColumnStateParams;
};

export type UsePersistedBlotterTableProps<R, RSort = R> = {
  /** Columns and Column-Group related inputs */
  columns: UseBlotterTableProps<R>['columns'];
  sort?: UseBlotterTableProps<RSort>['initialSort'];
  filter?: BlotterTableFilter;
  /** Callback to adjust initial state during grid startup, prior to applying */
  onApplyInitialState?: (params: UsePersistedOnApplyInitialStateParams<R>) => void;
} & PersistenceProps;

/**
 * Store blotter state in a persistent storage. Returns an initial state that is re-read only when `blotterID` changes.
 * @param blotterID Key to use in the persistent storage.
 * @param columns Default list of columns to use if persisted value is missing or. If a persisted state exists this list is used to construct proper Columns.
 * @param sort Default sorting to use if persisted value is missing.
 * @param filter Default filters to use if persisted value is missing.
 * @param persistSort Specify if Sort should be persisted, defaults to true
 * @param persistFilter Specify if Filter should be persisted, defaults to true
 * @param persistColumns Specify if Columns should be persisted, defaults to true
 *
 */
export function usePersistedBlotterTable<R, RSort = R>(
  /** If blotterId is not sent in (e.g. possible for EntityAdminPage), persistence is disabled */
  blotterID: string | null,
  // TODO rename to defaultColumns, defaultSort, defaultFilter
  {
    persistSort = true,
    persistFilter = true,
    persistColumns = true,
    columns,
    sort: initialSort,
    filter,
    onApplyInitialState,
  }: UsePersistedBlotterTableProps<R, RSort>
): UsePersistedBlotterTable<R, RSort> {
  const { getState, setColumnState, setFilterState, resetColumnState } = useBlotterTableStorageById(blotterID);

  // don't allow column updates until the first data rendered event, to avoid unwanted state saving updates.
  // - TODO: likely unnecessary if we migrate to use GridState (where we don't need to worry about firstdatarendered)
  const allowColumnUpdatesRef = useRef(false);
  const onColumnsChanged = useCallback<UsePersistedBlotterTable<R>['onColumnsChanged']>(
    params => {
      if (!allowColumnUpdatesRef.current) {
        return;
      }
      const columnState = params.api.getColumnState();
      const persistedColumns = columnState.map(columnStateToBlotterColumnState);
      if (blotterID != null && persistColumns) {
        setColumnState(persistedColumns);
      }
    },
    [blotterID, persistColumns, setColumnState]
  );

  // TODO figure out if filters should be persisted through a useEffect or by calling onFilterChanged...
  const onFilterChanged = useCallback<UsePersistedBlotterTable<R>['onFilterChanged']>(
    (filter?: BlotterTableFilter) => {
      if (blotterID != null && persistFilter) {
        setFilterState(filter);
      }
    },
    [blotterID, setFilterState, persistFilter]
  );
  const onSortChanged = useCallback<UsePersistedBlotterTable<R>['onSortChanged']>(
    params => {
      if (!allowColumnUpdatesRef.current) {
        return;
      }
      const columnState = params.api.getColumnState();
      const persistedColumns = columnState.map(columnStateToBlotterColumnState);
      if (blotterID != null && persistSort) {
        setColumnState(persistedColumns);
      }
    },
    [blotterID, persistSort, setColumnState]
  );

  const applyState = useRef<ApplyInitialStateArg<R> | null>(null);

  const onApplyInitialStateCall = useCallbackRef(onApplyInitialState);
  const isFirstTimeApply = useRef(true);
  const onGridReadyApplyState = useCallbackRef<NonNullable<GridOptions['onGridReady']>>((params: { api: GridApi }) => {
    const api = params.api;
    if (blotterID == null) {
      return;
    }
    const persistedColumnState = getState()?.columns;

    if (!persistedColumnState) {
      // nothing persisted, apply any custom initial state setup and return
      onApplyInitialStateCall?.({ api, isFirstTimeApply: true });
      return;
    }

    isFirstTimeApply.current = false;
    const allColumns: Map<string, ColumnState> = new Map();
    // 1. Add specified columns
    if (persistedColumnState) {
      for (const specifiedCol of persistedColumnState) {
        allColumns.set(getAgGridColId(specifiedCol), blotterColumnStateToColumnState(specifiedCol));
      }
    }
    const applyInitialStateArg: ApplyInitialStateArg<R> = {
      api,
      applyColumnStateParams: {
        state: Array.from(allColumns.values()),
        applyOrder: true,
      },
    };
    applyState.current = applyInitialStateArg;
    api.applyColumnState(applyInitialStateArg.applyColumnStateParams);
  });

  const columnsOnly = useMemo(() => {
    return columns.filter(isColumn);
  }, [columns]);

  const gridOptionsOverlay = useMemo(() => {
    return {
      onGridReady: params => {
        // perform the apply column state on startup, but well before the first data render
        // - performing this on onFirstDataRender is too late, as the columns are already rendered and it causes a flicker
        // - performing this outside a setTimeout does not engage the column state properly
        setTimeout(() => {
          onGridReadyApplyState(params);
        }, 100);
      },
      onFirstDataRendered: ({ api }) => {
        if (applyState.current) {
          api.applyColumnState(applyState.current.applyColumnStateParams);
          applyState.current = null;
        }
        // nothing persisted, apply any custom initial state setup and return
        onApplyInitialStateCall?.({ api, isFirstTimeApply: isFirstTimeApply.current });
        allowColumnUpdatesRef.current = true;
      },
    } satisfies GridOptions<R>;
  }, [onApplyInitialStateCall, onGridReadyApplyState]);

  const onBlotterColumnReset = useCallbackRef(({ api }: { api: GridApi }) => {
    if (blotterID != null) {
      resetColumnState?.();
      api.resetColumnState();
      onApplyInitialStateCall?.({ api, isFirstTimeApply: true });
      onColumnsChanged({ columns: [], api });
    }
  });

  const { initialFilter } = useMemo(() => {
    if (blotterID == null) {
      return { initialFilter: filter };
    }
    const persistedState = getState();
    return {
      initialFilter: !persistFilter ? filter : persistedState?.filter ?? filter,
    };
  }, [blotterID, filter, getState, persistFilter]);

  return {
    columns,
    columnsOnly,
    initialSort,
    initialFilter,
    gridOptionsOverlay,
    onColumnsChanged,
    onFilterChanged,
    onSortChanged,
    onBlotterColumnReset,
  };
}

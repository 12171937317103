import {
  MarketAccountTypeEnum,
  useCurrenciesFilter,
  useDynamicCallback,
  type FilterClause,
  type FilterableProperty,
  type MarketAccount,
  type UseFilterBuilderProps,
} from '@talos/kyoko';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import { useMarketAccountsFilter } from '../../../../../hooks';
import type { SettlementMonitoringFilter } from '../types';
import { SettlementMonitoringActionType, useSettlementMonitoringState } from './SettlementMonitoringStateProvider';

export const SettlementMonitoringFiltersContext = createContext<SettlementMonitoringFiltersContextProps | undefined>(
  undefined
);

export type SettlementMonitoringFiltersContextProps = {
  filterableProperties: FilterableProperty[];
  onFilterClausesChanged: UseFilterBuilderProps['onFilterClausesChanged'];
};

export function useSettlementMonitoringFilters() {
  const context = useContext(SettlementMonitoringFiltersContext);
  if (context === undefined) {
    throw new Error(
      'Missing SettlementMonitoringFiltersContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

function isCustomerMarketAccount(ma: MarketAccount): boolean {
  return ma.Type === MarketAccountTypeEnum.Customer;
}

/**
 * This provider allows anyone in the page to have access to the filterable properties for example
 */
export const SettlementMonitoringFiltersProvider = function SettlementMonitoringFiltersProvider({
  children,
}: PropsWithChildren) {
  const { dispatch } = useSettlementMonitoringState();

  const currenciesFilter = useCurrenciesFilter();
  const customerMarketAccountsFilter = useMarketAccountsFilter({ customOptionsFilter: isCustomerMarketAccount });

  const filterableProperties: FilterableProperty<keyof SettlementMonitoringFilter>[] = useMemo(
    () => [{ ...currenciesFilter, label: 'Asset', key: 'Symbols' }, customerMarketAccountsFilter],
    [currenciesFilter, customerMarketAccountsFilter]
  );

  const onFilterClausesChanged: UseFilterBuilderProps['onFilterClausesChanged'] = useDynamicCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>) => {
      const newFilter: SettlementMonitoringFilter = {
        Symbols: filterClausesByPropertyKey.get('Symbols')?.selections,
        MarketAccounts: filterClausesByPropertyKey.get('MarketAccounts')?.selections,
      };
      dispatch({
        type: SettlementMonitoringActionType.FilterChange,
        payload: {
          filter: newFilter,
        },
      });
    }
  );

  const value = useMemo(() => {
    return {
      filterableProperties,
      onFilterClausesChanged,
    };
  }, [filterableProperties, onFilterClausesChanged]);

  return (
    <SettlementMonitoringFiltersContext.Provider value={value}>{children}</SettlementMonitoringFiltersContext.Provider>
  );
};

import {
  Drawer,
  EntityEditingDrawerContent,
  useMarketAccountsContext,
  type DrawerProps,
  type MarketAccount,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { useMarketAccountGeneralTab } from './EditMarketAccountGeneralTab';

type EditMarketAccountsDrawerProps = {
  marketAccountName?: string;
} & DrawerProps;

export function EditMarketAccountsDrawer({ marketAccountName, ...drawerProps }: EditMarketAccountsDrawerProps) {
  const { marketAccountsByName } = useMarketAccountsContext();
  const marketAccount = marketAccountName ? marketAccountsByName.get(marketAccountName) : undefined;

  return (
    <Drawer {...drawerProps} data-testid="edit-market-accounts-drawer">
      {/* We set a unique key on the inner drawer content, and only render it when the drawer is in the opened state, in order to easily get 
      the inner forms to reset properly whenever the user starts moving around and selecting different accounts, opening and closing the drawer, etc */}
      {marketAccount && drawerProps.isOpen && (
        <EditMarketAccountsDrawerInner key={marketAccount.Name} marketAccount={marketAccount} {...drawerProps} />
      )}
    </Drawer>
  );
}

type EditMarketAccountsDrawerInnerParams = Omit<EditMarketAccountsDrawerProps, 'marketAccountName'> & {
  marketAccount: MarketAccount;
};

function EditMarketAccountsDrawerInner({ marketAccount, ...drawerProps }: EditMarketAccountsDrawerInnerParams) {
  const generalTab = useMarketAccountGeneralTab({ marketAccount });
  const tabs = useMemo(() => [generalTab], [generalTab]);

  return (
    <EntityEditingDrawerContent
      drawerTitle={`Edit ${marketAccount.DisplayName ?? marketAccount.Name}`}
      tabs={tabs}
      {...drawerProps}
    />
  );
}

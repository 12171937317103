import type { Currency } from '../types/Currency';
import { AddressRoutingTypeEnum, CurrencyTagEnum } from '../types/types';
import type { ValueOf } from './types';

export const currencySymbols = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  SGD: 'S$',
  BTC: '₿',
} as const;
export type CurrencySymbol = ValueOf<typeof currencySymbols>;

export const getAddressRoutingTypeOfCurrency = (currencyObj?: Currency) =>
  currencyObj?.Tags?.includes(CurrencyTagEnum.Fiat) ? AddressRoutingTypeEnum.Fiat : AddressRoutingTypeEnum.Crypto;

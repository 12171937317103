import { ICON_SIZES, Icon, IconName, Tooltip, isTimeActive, type ColumnDef } from '@talos/kyoko';
import type { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';

export const isActiveRuleColumn: ColumnDef<{ StartTime: string; EndTime: string }> = {
  type: 'custom',
  id: 'Active',
  sortable: true,
  suppressHeaderMenuButton: true,
  title: 'Active',
  width: 90,
  params: {
    suppressColumnsToolPanel: true,
    headerClass: 'ag-left-aligned-cell',
    valueGetter: ({ data }: ValueGetterParams<{ StartTime: string; EndTime: string }>) => {
      return isTimeActive(data?.StartTime, data?.EndTime);
    },
    cellRenderer: ({
      data,
      value: isActive,
    }: ICellRendererParams<{ StartTime?: string; EndTime?: string }, boolean>) => {
      return (
        <Tooltip
          tooltip={
            isActive
              ? data?.StartTime && data.EndTime
                ? `Active until ${data.EndTime} UTC`
                : 'Always active'
              : data?.StartTime
              ? `Inactive until ${data.StartTime} UTC`
              : 'Inactive'
          }
        >
          <Icon icon={isActive ? IconName.CheckCircleSolid : IconName.StopCircle} size={ICON_SIZES.LARGE} />
        </Tooltip>
      );
    },
    cellStyle: ({ value }: { value: boolean }) => {
      return { opacity: value ? 1 : 0.5 };
    },
  },
};

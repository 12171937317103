import type { UsePersistedBlotterTableProps } from './usePersistedBlotterTable';
import { isGridApiReady } from './utils';

/** Utility function to move an AgGrid column into the pinned section prior to the group col
 * - for use with onApplyInitialState prop of usePersistedBlotterTable
 * - (By default Ag-Grid always places the auto-group to the first pinned col)
 */
export const buildMoveColumnToFirstColumn = <R = any>(
  colId: string
): NonNullable<UsePersistedBlotterTableProps<R>['onApplyInitialState']> => {
  return event => {
    // only perform this operation is this is the first time the column state is applied
    if (!event.isFirstTimeApply) {
      return;
    }
    if (!isGridApiReady(event.api)) {
      return;
    }
    // move column with colId to the first position
    const columnState = event.api.getColumnState();
    const columnIndex = columnState.findIndex(col => col.colId === colId);
    if (columnIndex !== -1) {
      const movedColumn = columnState.splice(columnIndex, 1)[0];
      columnState.unshift(movedColumn);
      event.api.applyColumnState({
        state: columnState.map(col => ({ colId: col.colId })),
        applyOrder: true,
      });
    }
  };
};

export const Logo = ({ fill }: React.SVGProps<SVGPathElement>) => (
  <>
    <path
      d="M78.0775642,147.716068 L128.027776,147.717492 L95.2532466,180.490597 C103.994926,189.535678 115.314808,196.070927 128.027776,198.915498 L128.027776,166.917279 L134.430554,166.917279 L147.230411,179.717136 L134.430554,192.518418 L145.025406,192.516994 C148.046605,190.868937 150.922514,188.9887 153.63034,186.903346 L153.63034,166.917279 L147.230411,166.917279 L147.230411,160.51735 L134.430554,160.51735 L147.230411,147.717492 L134.430554,134.91621 L134.426281,134.91621 L153.63034,134.91621 L153.63034,166.917279 L160.030269,166.917279 L166.653832,173.540842 C169.588142,169.521122 172.075183,165.155268 174.043734,160.515925 L160.030269,160.51735 L172.830126,147.717492 L160.030269,134.91621 L179.228631,134.91621 L179.228631,122.116353 L128.027776,122.116353 L179.228631,70.9154981 L128.027776,70.9154981 L128.027776,102.915142 L121.629272,102.915142 L108.82799,90.1167089 L121.627848,77.3154269 L108.82799,77.3154269 L108.82799,70.9154981 L102.428061,70.9154981 L102.428061,102.915142 L108.82799,102.915142 L108.82799,109.316495 L121.629272,109.316495 L108.82799,122.116353 L121.629272,134.91621 L102.428061,134.91621 L102.428061,102.915142 L96.0295566,102.915142 L83.2282748,90.1152844 L96.0295566,77.3154269 L83.2282748,77.3154269 L83.2282748,70.9154981 L76.828346,70.9154981 L76.828346,102.915142 L83.2282748,102.915142 L83.2282748,109.316495 L96.0295566,109.316495 L83.2282748,122.116353 L96.0295566,134.91621 L96.0324057,134.91621 L76.828346,134.91621 C76.828346,139.297733 77.2585215,143.575273 78.0775642,147.716068"
      id="Fill-3"
      fill={fill}
      fillRule="nonzero"
    />
    <path
      d="M185.600071,134.91621 C185.600071,169.650745 160.836492,198.599276 128.000712,205.073275 C95.1649326,198.599276 70.3999288,169.650745 70.3999288,134.91621 L70.3999288,64.5155694 L185.600071,64.5155694 L185.600071,134.91621 Z M64,58.1156406 L64,134.91621 C64,144.166408 65.6067482,153.224308 68.7760875,161.833516 C71.8385952,170.150717 76.2756702,177.838323 81.9648123,184.684096 C87.6069485,191.471468 94.2988838,197.216162 101.852594,201.751523 C109.568689,206.385168 117.948565,209.615757 126.762889,211.352128 L128.000712,211.597129 L129.238535,211.352128 C138.051435,209.615757 146.432735,206.385168 154.147406,201.751523 C161.702541,197.216162 168.393051,191.471468 174.036612,184.684096 C179.72433,177.838323 184.161405,170.150717 187.223913,161.833516 C190.393252,153.224308 192,144.166408 192,134.91621 L192,58.1156406 L64,58.1156406 L64,58.1156406 Z"
      id="Fill-5"
      fill={fill}
      fillRule="nonzero"
    />
  </>
);

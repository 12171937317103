import { createContext } from 'react';
import type { IntlShape, MessageDescriptor } from 'react-intl';
import type { IntlFormatCell } from '../components/BlotterTable/columns';

export interface IntlContextProps {
  isLoaded: boolean;
  locale: string;
  setLocale: (locale: string) => void;
  preferServerEnglishText: boolean;
  formatCell: IntlFormatCell;
}

export const IntlContext = createContext<IntlContextProps | undefined>(undefined);

export const DEFAULT_LOCALE = 'en';

// {displayName} is required --> <Display Name> is required.
function replacePlaceholders(defaultMessage: MessageDescriptor['defaultMessage'], maybeObj?: Record<string, any>) {
  if (typeof defaultMessage !== 'string') {
    throw new Error('defaultMessage must be a string');
  }
  if (maybeObj == null) {
    return defaultMessage;
  }
  return defaultMessage.replace(/{(\w+)}/g, (match, key) => maybeObj[key] || match);
}

export type IntlWithFormatter = Pick<IntlShape, 'formatMessage' | 'locale'> & {
  formatCell: IntlFormatCell;
  preferServerEnglishText: boolean;
};

// The Intl Default Formatter can safely be used in lieu of react-intl in apps and tests where translations aren't utilized.
export const intlDefaultFormatter: IntlWithFormatter = {
  formatMessage: (descriptor: MessageDescriptor, maybeObj?: Record<string, any>) => {
    return replacePlaceholders(descriptor.defaultMessage, maybeObj || {});
  },
  formatCell: ({ value }) => value,
  preferServerEnglishText: true,
  locale: DEFAULT_LOCALE,
};

const INTL_KEY_PROTECTED_WORDS = ['ID', 'EID', 'OID', 'TWAP', 'RFQID', 'RFQTTL', 'QuoteTTL'];
export function getIntlKey(label: string = ''): string {
  return label
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/&/g, 'And')
    .trim()
    .split(' ')
    .map((word, index) => {
      if (INTL_KEY_PROTECTED_WORDS.includes(word)) {
        return word;
      }
      return index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
}

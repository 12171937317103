import { Popover, usePopoverState } from 'components/Popover';
import { useCallback } from 'react';
import { Button } from '../Button';
import { Flex } from '../Core';
import { Icon, IconName } from '../Icons';
import { TabButton } from './TabButton';
import type { TabAppearance, TabSize } from './types';

interface UseAddTabPopoverParams<T> {
  /** The selectable tab type options. */
  options: T[];
  /** Get a label for each of your tab type options. */
  getLabel: (item: T) => string;
  /** Called when an option is selected. The handler for this should then invoke tabs.onAdd(...). */
  onSelection: (item: T) => void;
  appearance?: TabAppearance;
  size?: TabSize;
}

/**
 * A hook encapsulating how to override default <TabList> add tab behavior. Hook this up to allow the user to select which type of tab
 * they would like to add when creating a new tab.
 */
export const useAddTabPopover = <T,>({
  options,
  getLabel,
  onSelection,
  appearance,
  size,
}: UseAddTabPopoverParams<T>) => {
  const customAddButtonPopover = usePopoverState({
    trigger: '',
    placement: 'bottom-start',
    usePortal: true,
    closeOnClickOutside: true,
  });

  const renderAddTab = useCallback(
    () => (
      <Popover {...customAddButtonPopover}>
        <TabButton
          appearance={appearance}
          size={size}
          onClick={() => customAddButtonPopover.open()}
          suffix={<Icon icon={IconName.Plus} />}
          data-testid="add-tab-button"
          isSelected={customAddButtonPopover.isOpen}
        />
        <Flex w="250px" flexDirection="column" gap="spacingSmall">
          <Flex flexDirection="column" gap="spacingTiny">
            {options.map(option => (
              <Button
                key={getLabel(option)} // assume unique should be fine
                dim
                onClick={() => onSelection(option)}
                endIcon={IconName.ArrowRightCircle}
                style={{ justifyContent: 'space-between' }}
                data-testid="add-tab-popover-button"
              >
                {getLabel(option)}
              </Button>
            ))}
          </Flex>
        </Flex>
      </Popover>
    ),
    [customAddButtonPopover, onSelection, options, getLabel, appearance, size]
  );

  return {
    renderAddTab,
    close: customAddButtonPopover.close,
  };
};

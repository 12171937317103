import {
  ACTION,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  BlotterDensity,
  BlotterTable,
  BlotterTableFilters,
  Box,
  Button,
  ButtonVariants,
  FormControlSizes,
  HStack,
  IconName,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  Text,
  filterExistsAndExcludes,
  isTimeActive,
  useAccordionFilterBuilder,
  useDrawer,
  useDynamicCallback,
  usePersistedBlotterTable,
  usePortal,
  useRestBlotterTable,
  useWSFilterPipe,
  type ColumnDef,
  type ICustomerOrderExecutionRule,
} from '@talos/kyoko';
import type { IRowNode, RowClassParams } from 'ag-grid-community';
import { useRoleAuth } from 'hooks';
import { keys } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { isActiveRuleColumn } from '../../columns';
import { CustomerExecutionRuleDrawer } from './CustomerExecutionRulesDrawer';
import { KillSwitch } from './KillSwitch';
import { useCustomerExecutionRulesFilter } from './useCustomerExecutionRulesFilter';

function getCustomerExecutionRuleKey(ct: ICustomerOrderExecutionRule) {
  return ct.RuleID;
}

function getExecutionRulesColumns(
  editCustomerOrderExecution: (executionRule?: ICustomerOrderExecutionRule) => void
): ColumnDef<ICustomerOrderExecutionRule>[] {
  return [
    isActiveRuleColumn,
    {
      type: 'counterparty',
      field: 'Counterparty',
      title: 'Counterparty',
      width: 170,
      sortable: true,
      sort: '+',
      description: 'Counterparty this rule applies to',
    },
    {
      type: 'security',
      field: 'Symbol',
      width: 170,
      sortable: true,
      sort: '-',
      params: { showAsteriskOnEmpty: true },
    },
    {
      type: 'text',
      field: 'ExecutionStrategy',
      width: 170,
      description:
        'OrderAcceptance means orders matching this rule will not be execute in the market before being confirmed to the client.',
    },
    {
      type: 'text',
      field: 'TimeInForce',
      width: 130,
    },
    {
      type: 'text',
      field: 'OrdType',
      width: 130,
    },
    {
      type: 'number',
      field: 'Priority',
      width: 120,
      sortable: true,
      sort: '-',
      description:
        'If multiple rules with the same granularity apply to an order, priority order will determine which takes precedence',
    },
    {
      type: 'time',
      field: 'StartTime',
      width: 150,
    },
    {
      type: 'time',
      field: 'EndTime',
      width: 150,
    },
    // Hidden by default columns
    {
      type: 'size',
      field: 'MinQuantityThreshold',
      width: 150,
      description: `Minimum quantity (in units of base currency) this rule will apply to`,
      hide: true,
    },
    {
      type: 'size',
      field: 'MaxQuantityThreshold',
      width: 150,
      description: `Maximum quantity (in units of base currency) this rule will apply to`,
      hide: true,
    },
    {
      type: 'size',
      field: 'MinNotionalThreshold',
      width: 175,
      description: `Minimum amount ( in units of quote currency) this rule will apply to`,
      hide: true,
    },
    {
      type: 'size',
      field: 'MaxNotionalThreshold',
      width: 175,
      description: 'Maximum amount (in units of quote currency) this rule will apply to',
      hide: true,
    },
    { type: 'filler', id: 'filler' },
    {
      type: 'iconButton',
      id: 'edit-button',
      pinned: 'right',
      width: 50,
      params: {
        onClick: ({ node }: { node: IRowNode<ICustomerOrderExecutionRule> }) => {
          editCustomerOrderExecution(node.data);
        },
        icon: IconName.Pencil,
      },
    },
  ];
}

/**
 * @deprecated Use CustomerExecutionRules instead
 */
export function CustomerExecutionRulesLEGACY() {
  const { isAuthorized } = useRoleAuth();
  const [selectedOrderExecutionRule, setSelectedOrderExecutionRule] = useState<ICustomerOrderExecutionRule>();

  const customerExecutionRuleDrawer = useDrawer({
    position: 'relative',
    width: 500,
    placement: 'right',
    closeOnEscape: true,
  });

  const editCustomerOrderExecution = useCallback(
    (executionRule?: ICustomerOrderExecutionRule) => {
      setSelectedOrderExecutionRule(executionRule);
      customerExecutionRuleDrawer.open();
    },
    [customerExecutionRuleDrawer]
  );

  const columns = useMemo(() => getExecutionRulesColumns(editCustomerOrderExecution), [editCustomerOrderExecution]);

  const handleOnRowDoubleClicked = useDynamicCallback(({ node }: { node: IRowNode<ICustomerOrderExecutionRule> }) => {
    editCustomerOrderExecution(node.data);
  });

  const getRowStyle = (params: RowClassParams<ICustomerOrderExecutionRule>) => {
    return isTimeActive(params.data?.StartTime, params.data?.EndTime) ? undefined : { opacity: '0.5' };
  };

  const persistedBlotterTable = usePersistedBlotterTable<ICustomerOrderExecutionRule>('dealer/execution-rules', {
    columns,
  });

  const filterResults = useCustomerExecutionRulesFilter({
    persistedBlotterTable,
  });

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: keys(filterResults.filter).length > 0 },
    filterBuilderProps: filterResults.filterBuilderProps,
  });

  const rxjsFilterFunc = useCallback(
    (data: ICustomerOrderExecutionRule) => {
      if (filterExistsAndExcludes(filterResults.filter, 'Symbols', data, 'Symbol')) {
        return false;
      }

      if (filterExistsAndExcludes(filterResults.filter, 'Counterparties', data, 'Counterparty')) {
        return false;
      }

      if (filterExistsAndExcludes(filterResults.filter, 'ExecutionStrategies', data, 'ExecutionStrategy')) {
        return false;
      }

      if (filterExistsAndExcludes(filterResults.filter, 'TimeInForces', data, 'TimeInForce')) {
        return false;
      }

      if (filterExistsAndExcludes(filterResults.filter, 'OrdTypes', data, 'OrdType')) {
        return false;
      }

      return true;
    },
    [filterResults.filter]
  );

  const filterPipe = useWSFilterPipe({ getUniqueKey: getCustomerExecutionRuleKey, filterFunc: rxjsFilterFunc });

  const [quickFilterText, setQuickFilterText] = useState<string>('');

  const blotterTable = useRestBlotterTable({
    columns: persistedBlotterTable.columns,
    persistence: persistedBlotterTable,
    request: {
      path: `/customer/order-execution-rules`,
    },
    rowID: 'RuleID',
    density: BlotterDensity.Comfortable,
    pipe: filterPipe,
    quickSearchParams: {
      filterText: quickFilterText,
    },
    gridOptions: { onRowDoubleClicked: handleOnRowDoubleClicked, getRowStyle },
  });

  const handleOnSave = useCallback(
    (forceRefresh?: boolean) => {
      blotterTable.refresh(forceRefresh);
      customerExecutionRuleDrawer.close();
    },
    [blotterTable, customerExecutionRuleDrawer]
  );

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  return (
    <HStack h="100%" w="100%" gap="spacingTiny" overflow="hidden">
      <Panel>
        <PanelHeader alignItems="end">
          <Box>
            <h2>Execution Rules</h2>
            <Box mt="spacingDefault">
              <Text whiteSpace="break-spaces">
                These rules determine how client orders will be executed. If post-trade hedging is desired, you can set
                those rules up here.
              </Text>
            </Box>
          </Box>
          <PanelActions>
            <Box ref={filtersContainerRef} />
            <Button
              startIcon={IconName.Plus}
              onClick={() => editCustomerOrderExecution()}
              variant={ButtonVariants.Default}
              disabled={!isAuthorized(ACTION.DEALER_TRADING)}
              data-testid="order-execution-rules-new-rule-button"
              size={FormControlSizes.Small}
            >
              New Execution Rule
            </Button>
            <KillSwitch size={FormControlSizes.Small} />
          </PanelActions>
        </PanelHeader>
        <PanelContent>
          <BlotterTableFilters {...filterBuilderAccordion} onQuickFilterTextChanged={setQuickFilterText} />
          <BlotterTable {...blotterTable} />
        </PanelContent>
      </Panel>
      <CustomerExecutionRuleDrawer
        {...customerExecutionRuleDrawer}
        onSave={handleOnSave}
        orderExecutionRule={selectedOrderExecutionRule}
      />
    </HStack>
  );
}

export const BLOTTER_HEADER_HEIGHT = 40;
export const SUB_HEADER_HEIGHT = 25;
export const OMS_WIDTH = 370;
export const OMS_DIALOG_WIDTH = 320;
export const ANALYTICS_WIDTH = 560;
export const COLUMN_WIDTH = 310;
export const SLIDER_HEIGHT = 160;
export const INFO_BAR_HEIGHT = 10;
export const SETTINGS_ROW_MAX_WIDTH = 600;
export const ADMIN_HEADER_HEIGHT = 30;
export const NAVIGATION_SIDEBAR_WIDTH = 200;
export const LAYOUT_HEADER_HEIGHT = 32;

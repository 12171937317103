import {
  FilterClauseType,
  IconName,
  OrdTypeEnum,
  cleanupInitialFilterDateRange,
  filterExcludesOrderStatus,
  filterExistsAndExcludes,
  orderStatusFilterTextToBackendStatus,
  removeEmptyFilters,
  useDateRangeFilter,
  useExecTypeFilter,
  useOrderStatusFilter,
  useSymbolsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type CustomerExecutionReport,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type OrdStatusEnum,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { compact, isEqual, keys, startCase, values } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useCounterpartyFilter, useIDFilter } from '../../../hooks';
import { useMarketAccountsFilter } from '../../../hooks/filters/useMarketAccountsFilter';

export interface CustomerExecutionReportsTableFilter extends DateRangeFilter {
  ExecTypes?: string[];
  Counterparty?: string[];
  Symbols?: string[];
  /** These are the Ord Status texts we are filtering by on the client side */
  _statuses?: string[];
  /** These are the Ord Statuses that we are filtering by on the server */
  Statuses?: OrdStatusEnum[];
  MarketAccounts?: string[];
  OrdType?: string[];
  OrderID?: string;
  RFQID?: string;
}

export interface UseCustomerExecutionReportsFilterParams<TData extends CustomerExecutionReport> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}
export function useCustomerExecutionReportsFilter<TData extends CustomerExecutionReport>({
  persistedBlotterTable,
}: UseCustomerExecutionReportsFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [initialFilter] = useState(() => cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter));
  const [filter, setFilter] = useState<CustomerExecutionReportsTableFilter>(
    (initialFilter as CustomerExecutionReportsTableFilter) || {}
  );

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<CustomerExecutionReport>>(
    row => {
      const { data } = row;
      if (filterExistsAndExcludes(filter, 'ExecTypes', data, 'ExecType')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExcludesOrderStatus(filter._statuses, data)) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'MarketAccounts', data, 'MarketAccount')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'OrdType', data, 'OrdType')) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const counterpartyFilter = useCounterpartyFilter();
  const symbolFilter = useSymbolsFilter();
  const marketAccountsFilter = useMarketAccountsFilter();
  const execTypeFilter = useExecTypeFilter();
  const orderStatusFilter = useOrderStatusFilter();
  const idFilter = useIDFilter();

  const filterableProperties = useMemo(
    () =>
      compact<FilterableProperty<keyof CustomerExecutionReportsTableFilter>>([
        execTypeFilter,
        { ...counterpartyFilter, label: 'Customer' },
        symbolFilter,
        orderStatusFilter,
        marketAccountsFilter,
        {
          key: 'OrdType',
          label: 'Ord Type',
          icon: IconName.CheckCircle,
          options: values(OrdTypeEnum),
          getOptionLabel: (option: string) => startCase(option),
        },
        { ...idFilter, key: 'OrderID', label: 'Order ID' },
        { ...idFilter, key: 'RFQID', label: 'RFQ ID' },
      ]),
    [execTypeFilter, counterpartyFilter, symbolFilter, marketAccountsFilter, orderStatusFilter, idFilter]
  );
  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof CustomerExecutionReportsTableFilter)[]).forEach(
        (key: keyof CustomerExecutionReportsTableFilter) => {
          switch (key) {
            case '_start':
            case 'StartDate':
            case 'EndDate':
            case 'TimestampField':
            case 'Statuses':
              return;
            default:
              clauses.push({
                key: key,
                type: FilterClauseType.INCLUSIVE,
                selections: filter[key] as string[],
              });
          }
        }
      );
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: CustomerExecutionReportsTableFilter = removeEmptyFilters<CustomerExecutionReportsTableFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies CustomerExecutionReportsTableFilter),
          // below are exact search and only one value is supported by backend
          OrderID: filterClausesByPropertyKey.get('OrderID')?.selections?.[0],
          RFQID: filterClausesByPropertyKey.get('RFQID')?.selections?.[0],
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
}

const colIDToFilterBuilderKeyMap = {
  Counterparty: 'Counterparty',
  ExecType: 'ExecTypes',
  Symbol: 'Symbols',
  OrdStatus: '_statuses',
  MarketAccount: 'MarketAccounts',
  OrdType: 'OrdType',
  OrderID: 'OrderID',
  RFQID: 'RFQID',
} satisfies Partial<Record<keyof CustomerExecutionReport, keyof CustomerExecutionReportsTableFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof CustomerExecutionReportsTableFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}

export function onlyServerFilterKeys(filter: CustomerExecutionReportsTableFilter | undefined) {
  if (!filter) {
    return filter;
  }

  const serverFilter = {
    StartDate: filter.StartDate,
    EndDate: filter.EndDate,
    Statuses: orderStatusFilterTextToBackendStatus(filter._statuses),
    OrderID: filter.OrderID,
    RFQID: filter.RFQID,
    Counterparty: filter.Counterparty?.at(0),
  };

  return serverFilter;
}

import type { UseDisclosureReturn } from '@talos/kyoko';
import { ButtonVariants, Dialog, Flex } from '@talos/kyoko';
import type { IDockviewDefaultTabProps } from 'dockview';
import { closeTabText } from '../utils/utils';

export function RemoveTabDialog({
  panel,
  ...props
}: {
  panel: IDockviewDefaultTabProps;
  onConfirm: () => void;
  portalElement: HTMLElement;
} & UseDisclosureReturn) {
  return (
    <Dialog
      title={`Removing "${panel.api.title}"-tab`}
      variant={ButtonVariants.Negative}
      width={360}
      confirmLabel="Remove tab"
      cancelLabel="Cancel"
      dataTestId="remove-tab-dialog"
      stretchButtons={true}
      {...props}
    >
      <Flex flexDirection="column" gap="spacingComfortable">
        <div>{closeTabText(panel)}</div>
        <div>Would you like to proceed?</div>
      </Flex>
    </Dialog>
  );
}

import {
  ACTION,
  BlotterDensity,
  BlotterTable,
  Flex,
  IconName,
  MarketAccountTypeEnum,
  useBlotterTable,
  useMarketAccountsContext,
  useObservable,
  useSyncedRef,
  type Column,
  type MarketCredential,
  type SubscriptionResponse,
} from '@talos/kyoko';
import type { IRowNode } from 'ag-grid-community';
import { compact } from 'lodash-es';
import { useMemo } from 'react';
import { map, of } from 'rxjs';
import { useFeatureFlag, useRoleAuth } from '../../../../hooks';
import { useAllTradableMarketAccountNames } from '../../TradingControls/useAllTradableMarketAccountNames';
import type { ICredentialDrawerTab, MarketAccountBlotterEntity } from './types';

interface MarketAccountsTabProps extends UseMarketAccountsTabProps {}

export function MarketAccountsTab({ credential, handleMarketAccountClick }: MarketAccountsTabProps) {
  const { isAuthorized } = useRoleAuth();
  const { enableTradingControls } = useFeatureFlag();
  const allTradableMarketAccountNames = useAllTradableMarketAccountNames();
  const { marketAccountsByName } = useMarketAccountsContext();
  const marketAccountsByNameRef = useSyncedRef(marketAccountsByName);

  const columns: Column[] = useMemo(() => {
    return compact([
      {
        type: 'text',
        field: 'Name',
        sortable: true,
      },
      {
        type: 'text',
        field: 'DisplayName',
        sortable: true,
      },
      {
        type: 'marketAccountTradingStatus',
        field: 'Status',
        title: 'Trading',
      },
      handleMarketAccountClick && enableTradingControls && isAuthorized(ACTION.VIEW_TRADING_CONTROLS)
        ? {
            type: 'iconButton',
            id: 'details-button',
            pinned: 'right',
            width: 60,
            params: {
              onClick: ({ node }: { node: IRowNode<MarketAccountBlotterEntity> }) => {
                if (node.data) {
                  handleMarketAccountClick(node.data.Name);
                }
              },
              icon: IconName.ArrowRight,
            },
          }
        : null,
    ]);
  }, [enableTradingControls, isAuthorized, handleMarketAccountClick]);

  const dataObservable = useObservable<SubscriptionResponse<MarketAccountBlotterEntity>>(
    () =>
      of(allTradableMarketAccountNames).pipe(
        map(marketAccountNames => {
          const data = compact(
            marketAccountNames.map(marketAccountName => {
              const marketAccount = marketAccountsByNameRef.current.get(marketAccountName);
              if (
                !marketAccount ||
                marketAccount.CredentialID !== credential.CredentialID ||
                marketAccount.Type !== MarketAccountTypeEnum.Trading
              ) {
                return null;
              }
              return {
                Name: marketAccountName,
                DisplayName: marketAccount.DisplayName,
                Status: marketAccount.Status,
              };
            })
          );
          return { data, initial: true, type: 'MarketAccount', ts: '0' };
        })
      ),
    [credential, allTradableMarketAccountNames, marketAccountsByNameRef]
  );

  const blotterTable = useBlotterTable<MarketAccountBlotterEntity>({
    dataObservable,
    rowID: 'Name',
    density: BlotterDensity.Comfortable,
    initialSort: '+Name',
    columns,
  });

  return (
    <Flex flexDirection="column" w="100%" h="100%">
      <BlotterTable {...blotterTable} />
    </Flex>
  );
}

interface UseMarketAccountsTabProps {
  credential: MarketCredential;
  handleMarketAccountClick?: (marketAccountName: string) => void;
}

export function useMarketAccountsTab(props: UseMarketAccountsTabProps): ICredentialDrawerTab {
  return {
    name: 'Market Accounts',
    component: <MarketAccountsTab {...props} />,
    isDirty: false,
    viewable: true,
    save: () => Promise.resolve(),
    id: 'credential-market-accounts-tab',
  };
}

import { configureStore, createAction, createReducer, type EnhancedStore } from '@reduxjs/toolkit';
import type { ToastAppInboxProps, ToastProps } from '../../hooks/Toast/useToasts';

export type GlobalToastStoreType = {
  toasts: {
    uiToasts: ToastProps[];
    appInboxToasts: ToastAppInboxProps[];
  };
};
type GlobalToastsReduxState = {
  uiToasts: ToastProps[];
  appInboxToasts: ToastAppInboxProps[];
};

const initialState: GlobalToastsReduxState = {
  uiToasts: [],
  appInboxToasts: [],
};

type AddActionPayload =
  | { type: 'ui'; value: ToastProps & { id: string } }
  | { type: 'appInbox'; value: ToastAppInboxProps };
// Redux Actions
const add = createAction<AddActionPayload>('toasts/add');
const remove = createAction<{ type: 'ui' | 'appInbox'; id: string }>('toasts/remove');
const clear = createAction<{ type: 'ui' | 'appInbox' }>('toasts/clear');

const globalToastsReducer = createReducer(initialState, builder => {
  builder.addCase(add, (state, action) => {
    if (action.payload.type === 'ui') {
      const toastValue = action.payload.value;
      state.uiToasts.push(toastValue);
    } else {
      const toastValue = action.payload.value;
      state.appInboxToasts.push(toastValue);
    }
  });
  builder.addCase(remove, (state, action) => {
    if (action.payload.type === 'ui') {
      state.uiToasts = state.uiToasts.filter(toast => toast.id !== action.payload.id);
    } else {
      state.appInboxToasts = state.appInboxToasts.filter(toast => toast.id !== action.payload.id);
    }
  });
  builder.addCase(clear, (state, action) => {
    if (action.payload.type === 'ui') {
      state.uiToasts = [];
    } else {
      state.appInboxToasts = [];
    }
  });
});

// Function to Add Toast with Timeout
export const addToastWithTimeout = (payload: AddActionPayload) => (dispatch: GlobalToastDispatch) => {
  let toastValue: ToastProps | ToastAppInboxProps;
  if (payload.type === 'ui') {
    toastValue = {
      dismissable: true,
      timeout: payload.value.variant === 'NEGATIVE' ? 5000 : 3000,
      ...payload.value,
    };
  } else {
    toastValue = {
      dismissable: true,
      timeout: 30_000,
      ...payload.value,
    };
  }

  const newPayload = {
    type: payload.type,
    value: toastValue,
  } as AddActionPayload;

  dispatch(add(newPayload));

  if (newPayload.value.timeout !== null) {
    setTimeout(() => {
      dispatch(remove({ type: newPayload.type, id: newPayload.value.id }));
      payload.value.onRemove?.(newPayload.value.id);
    }, newPayload.value.timeout);
  }
};

// Store solely for Toasts -
export const globalToastStore = configureStore<GlobalToastStoreType>({
  reducer: {
    toasts: globalToastsReducer,
  },
});

export type ReduxSelectorTypes<TStore extends EnhancedStore, TDispatch> = {
  storeType: TStore;
  dispatchType: TDispatch;
  selectorType: ReturnType<TStore['getState']>;
};

export type GlobalToastDispatch = typeof globalToastStore.dispatch;

export const globalToastsDispatch = globalToastStore.dispatch;
export const getGlobalToastsState = globalToastStore.getState;

// Redux Reducer
export const globalToastActions = {
  addToastWithTimeout,
  remove,
  clear,
};

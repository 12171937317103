import { cloneDeep, merge } from 'lodash-es';
import { hsl, hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { DarkTheme, mapDarkTheme } from './DarkTheme';

const customcolors: Partial<Colors> = {
  gray: {
    main: '#151515',

    '000': '#050505',
    '010': hsla(0, 0, 0.11, 1),
    '020': hsla(0, 0, 0.14, 1),
    '030': hsla(0, 0, 0.16, 1),
    '040': hsla(0, 0, 0.2, 1),
    '050': hsla(0, 0, 0.24, 1),
    '060': hsla(0, 0, 0.28, 1),
    '070': hsla(0, 0, 0.36, 1),
    '080': hsla(0, 0, 0.57, 1),
    '090': '#F2F2F2',
    '100': hsla(0, 0, 0.85, 1),
  },

  primary: {
    mute: hsla(46, 0.75, 0.6, 0.1),
    dim: hsla(46, 0.75, 0.6, 0.7),
    default: hsl(46, 0.75, 0.45),
    lighten: hsl(46, 0.75, 0.6),
  },

  green: {
    mute: hsla(130, 0.63, 0.32, 0.1),
    dim: hsla(130, 0.63, 0.32, 0.4),
    default: hsl(130, 0.63, 0.32),
    lighten: hsl(130, 0.61, 0.4),
  },

  red: {
    mute: hsla(350, 0.86, 0.38, 0.1),
    dim: hsla(350, 0.86, 0.38, 0.4),
    default: hsl(350, 0.86, 0.38),
    lighten: hsl(350, 0.87, 0.48),
  },
};
const colors: Colors = merge(cloneDeep(DarkTheme.colors), customcolors);

export const DarkSDMTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkSDM',

  backgroundSelectDropdown: colors.gray['010'],

  backgroundInput: 'transparent',
  backgroundInputInvalid: 'transparent',
  backgroundInputDisabled: 'transparent',

  backgroundSelect: 'transparent',
  backgroundSelectHover: 'transparent',
  backgroundSelectFocus: 'transparent',
  backgroundSelectDisabled: 'transparent',
  backgroundSelectReadOnly: 'transparent',
  backgroundSelectInvalid: 'transparent',

  colorTextDefault: colors.gray['090'],
  colorTextImportant: colors.gray['100'],
  colorTextAttention: colors.gray['100'],

  backgroundBody: colors.gray['000'],
  backgroundContent: colors.gray.main,

  borderColorPopover: colors.gray.main,
  backgroundPopover: colors.gray.main,
  backgroundModal: colors.gray.main,

  backgroundPopoverHighlight: colors.gray['030'],

  backgroundHeader: colors.gray.main,
  borderColorHeader: colors.gray.main,

  backgroundPriorityButton: colors.gray['060'],
  backgroundPriorityButtonHover: colors.gray['070'],
  backgroundPriorityButtonFocus: colors.gray['070'],
  backgroundPriorityButtonActive: colors.gray['060'],

  backgroundDefaultButton: colors.gray['030'],
  backgroundDefaultButtonHover: colors.gray['040'],
  backgroundDefaultButtonFocus: colors.gray['040'],
  backgroundDefaultButtonActive: colors.gray['030'],

  backgroundToggle: colors.gray['050'],

  backgroundCheckbox: colors.gray['010'],
  backgroundCheckboxHover: colors.gray['020'],

  borderColorCheckbox: colors.gray['050'],
  borderColorCheckboxFocus: colors.gray['070'],
  borderColorCheckboxHover: colors.gray['070'],
  borderColorCheckboxChecked: colors.gray['070'],
  borderColorCheckboxCheckedFocus: colors.gray['070'],

  backgroundRadiobutton: colors.gray['010'],
  backgroundRadiobuttonHover: colors.gray['020'],

  borderColorRadiobutton: colors.gray['050'],
  borderColorRadiobuttonFocus: colors.gray['070'],
  borderColorRadiobuttonHover: colors.gray['070'],
  borderColorRadiobuttonChecked: colors.gray['070'],
  borderColorRadiobuttonCheckedFocus: colors.gray['070'],

  borderColorInput: colors.gray['060'],
  borderColorInputHover: colors.gray['070'],
  borderColorInputFocus: colors.gray['080'],
  borderColorInputDisabled: colors.gray['040'],

  borderColorSelect: colors.gray['060'],
  borderColorSelectHover: colors.gray['070'],
  borderColorSelectFocus: colors.gray['080'],
  borderColorSelectDisabled: colors.gray['040'],
  borderColorSelectReadOnly: colors.gray['040'],

  backgroundDropdownResults: colors.gray.main,
  backgroundDropdownInput: colors.gray.main,
  backgroundDropdownSelected: colors.gray['010'],
  backgroundDropdownHovered: colors.gray['020'],
});

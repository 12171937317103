import { createContext, type CSSProperties, type ReactNode } from 'react';
import type { BoxProps } from '../Core';
import { Wrapper } from './styles';
import { TabAppearance, TabSize } from './types';

export interface TabsContextProps {
  selectedIndex: number;
  onSelect?(index: number): void;
  onCancel?(index: number): void;
  onRemove?(index: number): void;
  onAdd?(): void;
  onRename?(index: number, text: string): void;
  onReorder?(startIndex: number, endIndex: number): void;
  showAddTab?: boolean;
  allowClosingLastTab: boolean;
  appearance: TabAppearance;
  size: TabSize;
}

export const TabsContext = createContext<TabsContextProps | undefined>(undefined);
TabsContext.displayName = 'TabsContext';

export interface TabsProps<T = any> extends Omit<BoxProps, 'onSelect' | 'onCancel'> {
  children?: ReactNode;
  selectedIndex: number;
  onSelect?(index: number): void;
  onCancel?(index: number): void;
  onRemove?(index: number): void;
  onAdd?(tab?: Partial<T>): string | void;
  onRename?(index: number, text: string): void;
  onReorder?(startIndex: number, endIndex: number): void;
  style?: CSSProperties;
  showAddTab?: boolean;
  allowClosingLastTab?: boolean;
  appearance?: TabAppearance;
  size?: TabSize;
}

export function Tabs({
  children,
  selectedIndex,
  onSelect,
  onRename,
  onCancel,
  onRemove,
  onReorder,
  onAdd,
  showAddTab = false,
  allowClosingLastTab = false,
  appearance = TabAppearance.Underlined,
  size = TabSize.Default,
  ...props
}: TabsProps) {
  return (
    <TabsContext.Provider
      value={{
        selectedIndex,
        onSelect,
        onRename,
        onCancel,
        onRemove,
        onReorder,
        onAdd,
        showAddTab,
        allowClosingLastTab,
        appearance,
        size,
      }}
    >
      <Wrapper {...props}>{children}</Wrapper>
    </TabsContext.Provider>
  );
}

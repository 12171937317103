import {
  IconName,
  MarketTypeEnum,
  PositionUpdateSourceEnum,
  ProductTypeEnum,
  isTalosUser,
  removeEmptyFilters,
  useDerivativesSymbolsFilter,
  useDynamicCallback,
  useMarketsContext,
  useProductTypesFilter,
  type FilterClause,
  type FilterableProperty,
  type MarketAccount,
  type Position,
  type UseFilterBuilderProps,
} from '@talos/kyoko';
import { compact, isEqual, startCase } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useUser } from '../../../../hooks';
import { useDerivativeExpiriesFilter } from '../../../../hooks/filters/useDerivativeExpiriesFilter';
import { useMarketAccountsFilter } from '../../../../hooks/filters/useMarketAccountsFilter';
import type { PositionsTableFilter } from '../types';
import { usePositionsBaseFilter, type UsePositionsBaseFilterParams } from '../usePositionsBaseFilter';

interface UsePositionsDerivativesFilterParams extends UsePositionsBaseFilterParams<Position> {
  defaultFilter: PositionsTableFilter;
}

export const DERIVATIVES_PRODUCT_TYPES = [
  ProductTypeEnum.Future,
  ProductTypeEnum.PerpetualSwap,
  ProductTypeEnum.Option,
  ProductTypeEnum.CFD,
];

export const usePositionsDerivativesFilter = ({ defaultFilter, ...params }: UsePositionsDerivativesFilterParams) => {
  const { marketsByName } = useMarketsContext();
  const user = useUser();

  const baseFilter = usePositionsBaseFilter(params);

  const derivativesSymbolsFilter = useDerivativesSymbolsFilter();
  const expiriesFilter = useDerivativeExpiriesFilter();
  const productTypesFilter = useProductTypesFilter();

  const isMarketAccountInExchange = useDynamicCallback((ma: MarketAccount) => {
    return marketsByName.get(ma.Market)?.Type === MarketTypeEnum.Exchange;
  });
  const accountsFilter = useMarketAccountsFilter({ customOptionsFilter: isMarketAccountInExchange });

  const filterableProperties = useMemo(
    () =>
      compact<FilterableProperty<keyof PositionsTableFilter>>([
        { ...derivativesSymbolsFilter, key: 'Symbols' },
        accountsFilter,
        expiriesFilter,
        {
          ...productTypesFilter,
          options: DERIVATIVES_PRODUCT_TYPES,
        },
        // Its helpful for engineers to be able to flip this Source switch, but clients shouldn't be able to see it.
        isTalosUser(user) && {
          key: 'Source',
          label: 'Source',
          icon: IconName.CubeTransparent,
          control: 'single-select',
          options: Object.keys(PositionUpdateSourceEnum),
          getOptionLabel: (option: string) => startCase(option),
        },
      ]),
    [derivativesSymbolsFilter, accountsFilter, expiriesFilter, user, productTypesFilter]
  );

  const { changeFilter, initialFilterClauses } = baseFilter;

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters<PositionsTableFilter>({
          ...curr,
          // Talos users can set this field, end users cannot.
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies PositionsTableFilter),
          Source: isTalosUser(user)
            ? (filterClausesByPropertyKey.get('Source')?.selections?.[0] as PositionUpdateSourceEnum)
            : PositionUpdateSourceEnum.Gateway,
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter, user]
  );

  const filterBuilderProps = useMemo(
    () =>
      ({
        initialFilterClauses,
        properties: filterableProperties,
        onFilterClausesChanged: handleFilterClausesChanged,
      } satisfies UseFilterBuilderProps),
    [initialFilterClauses, filterableProperties, handleFilterClausesChanged]
  );

  return {
    filterBuilderProps,
    ...baseFilter,
  };
};

import {
  Alert,
  AlertVariants,
  type Allocation,
  AllocationValueTypeEnum,
  Button,
  ButtonVariants,
  DateTimePicker,
  type DateTimePickerProps,
  DrawerFooter,
  EMPTY_ARRAY,
  Flex,
  formattedDateForSubscription,
  FormGroup,
  getTypedKeys,
  Input,
  NotificationVariants,
  NOW,
  SearchSelect,
  SubAccountReconMatchStatusEnum,
  toBigWithDefault,
  useGlobalToasts,
  VStack,
} from '@talos/kyoko';
import { compact, isEmpty, noop } from 'lodash-es';
import { type ChangeEvent, useCallback, useMemo, useState } from 'react';
import { AllocationsSelector } from '../../../../components/AllocationsSelector';
import { useSubAccountReconRequests } from '../useSubAccountReconRequests';
import { isMatchBreak } from './MatchHistoryTab';
import type { ReconBreak, ResolutionType, ResolveBreakForm } from './types';
import { useResolutionValidation } from './useResolutionValidation';

type BreakResolutionTabProps = {
  reconBreak: ReconBreak;
  closeDrawer: () => void;
};

function getResolutionTypeLabel(type: ResolutionType) {
  switch (type) {
    case 'adjust':
      return 'Adjust Sub Account(s) by';
    case 'ignore':
      return 'Ignore';
    default:
      return type;
  }
}

const timestampPickerShortcuts: DateTimePickerProps['shortcuts'] = {
  [NOW]: 'Now',
};

const resolvableMatchStatusEnums: Set<string> = new Set([
  SubAccountReconMatchStatusEnum.Ignored,
  SubAccountReconMatchStatusEnum.Unmatched,
  SubAccountReconMatchStatusEnum.UnmatchedMarketAccount,
  SubAccountReconMatchStatusEnum.UnmatchedSubAccount,
]);
function shouldDisableForm(reconBreak: ReconBreak): boolean {
  if (isMatchBreak(reconBreak)) {
    return !resolvableMatchStatusEnums.has(reconBreak.status);
  }

  // we're a checkpoint-based "adjustment", we never disable all
  return false;
}

export const BreakResolutionTab = ({ reconBreak, closeDrawer }: BreakResolutionTabProps) => {
  const disableForm = shouldDisableForm(reconBreak);
  const { add: addToast } = useGlobalToasts();
  const { resolveBreak } = useSubAccountReconRequests();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<ResolveBreakForm>(() => {
    // Our suggestion is simple: allocate the entire break to the first sub account.
    // The break amount is sub account amount - market account amount. In order to resolve the break, the user must
    // apply the inverse of this break (diff) to the sub account amount, hence the -1.
    // Eg: SubAccAmt: 1.0, MktAccAmt: 1.5. Diff: 1.0-1.5=-0.5. Resolution: +0.5 to the SubAccAmt.
    const suggestedAllocation: Allocation[] =
      reconBreak.subAccounts?.map((subAccount, i) => ({
        subAccount,
        value: i === 0 ? toBigWithDefault(reconBreak.breakAmount, 0).times(-1).toFixed() : '0',
      })) ?? [];

    // For checkpoints, default to now. Otherwise, for matches, default to the timestamp provided (will be Match.TransactTime).
    const initialTimestamp =
      reconBreak.type === 'Checkpoint'
        ? new Date()
        : reconBreak.timestamp != null
        ? new Date(reconBreak.timestamp)
        : undefined;

    return {
      resolutionType: 'adjust',
      subAccountAllocations: suggestedAllocation,
      asset: reconBreak.asset,
      timestamp: initialTimestamp,
      comments: '',
    };
  });

  const { touched, setTouched, setAllTouched, errors } = useResolutionValidation(form, reconBreak.breakAmount);

  // We can only set our own timestamp if we're resolving a Checkpoint.
  const timestampFieldEditable = reconBreak.type === 'Checkpoint';

  const resolutionTypeOptions: ResolutionType[] = useMemo(() => {
    // We can always select adjust. We can only select ignore for the match cases (!Checkpoint)
    return compact(['adjust', reconBreak.type !== 'Checkpoint' && 'ignore']);
  }, [reconBreak.type]);

  const updateForm = useCallback(
    (update: Partial<ResolveBreakForm>) => {
      setForm(prev => ({ ...prev, ...update }));

      // Set touched after updates run to not hit any weird annoying timing ux issues (just put at back of queue)
      setTimeout(() => {
        setTouched(prev => {
          // Map each update to just true, then apply to the touched state
          const update: Partial<Record<keyof ResolveBreakForm, boolean>> = {};
          getTypedKeys(update).forEach(key => {
            update[key] = true;
          });
          return { ...prev, ...update };
        });
      }, 0);
    },
    [setTouched]
  );

  const handleChangeResolutionType = useCallback(
    (resolutionType: ResolutionType | undefined) => {
      updateForm({ resolutionType });
    },
    [updateForm]
  );

  const subAccountOptions = reconBreak.subAccounts ?? EMPTY_ARRAY;

  const handleAllocationsChange = useCallback(
    (newAllocations: Allocation[]) => {
      updateForm({ subAccountAllocations: newAllocations });
    },
    [updateForm]
  );

  const handleChangeComments = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      updateForm({ comments: event.target.value });
    },
    [updateForm]
  );

  const handleTimestampChange = useCallback(
    (newTimestamp: Date | null) => {
      updateForm({ timestamp: newTimestamp ?? undefined });
    },
    [updateForm]
  );

  const anyError = !isEmpty(errors);

  // If we select ignore, we will not be utilizing allocations in any way. Hide selector, and dont send on the request.
  const includeAllocations = form.resolutionType !== 'ignore';

  const handleResolve = useCallback(() => {
    const canMakeRequest = reconBreak.checkpointID != null;
    if (!canMakeRequest) {
      return;
    }

    setIsLoading(true);

    resolveBreak({
      CheckpointID: reconBreak.checkpointID,
      MatchID: reconBreak.matchID,
      Allocation: includeAllocations ? form.subAccountAllocations : undefined,
      Comments: form.comments,
      TransactTime: formattedDateForSubscription(form.timestamp),
    })
      .then(() => {
        addToast({
          variant: NotificationVariants.Positive,
          text: 'Successfully resolved break.',
        });
      })
      .catch((e: Error) => {
        addToast({
          variant: NotificationVariants.Negative,
          text: `Failed to resolve break: ${e.message}`,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [addToast, reconBreak, form, resolveBreak, includeAllocations]);

  return (
    <VStack h="100%" w="100%" justifyContent="space-between" flex="1">
      <Flex flexDirection="column" h="100%" w="100%" justifyContent="flex-start">
        <Flex flexDirection="column" p="spacingMedium">
          <FormGroup
            label="Resolution Type"
            error={touched.resolutionType && errors.resolutionType ? errors.resolutionType : undefined}
          >
            <SearchSelect
              selection={form.resolutionType}
              options={resolutionTypeOptions}
              onChange={handleChangeResolutionType}
              getLabel={getResolutionTypeLabel}
              disabled={resolutionTypeOptions.length < 2 || disableForm}
              data-testid="resolve-break-resolution-type"
            />
          </FormGroup>

          {includeAllocations && (
            <AllocationsSelector
              subAccountAllocations={form.subAccountAllocations ?? EMPTY_ARRAY}
              allocationValueType={AllocationValueTypeEnum.Quantity}
              subAccountOptions={subAccountOptions}
              touched={touched}
              errors={errors}
              onAllocationsChange={handleAllocationsChange}
              onAllocationsValueTypeChange={noop} // not allowed...
              hideTypeToggleButtons // ...because this is disabled and we hard-code to Qty
              quantityCurrency={reconBreak.asset}
              allowEditingSingleAllocationValue
              useAllocations
              disabled={disableForm}
            />
          )}

          <FormGroup label="Transact Time" error={touched.timestamp && errors.timestamp ? errors.timestamp : undefined}>
            <DateTimePicker
              value={form.timestamp ?? null}
              disabled={!timestampFieldEditable || disableForm}
              onChange={handleTimestampChange}
              showMilliseconds
              showShortcuts
              shortcuts={timestampPickerShortcuts}
              data-testid="resolve-break-transact-time"
              invalid={!!(touched.timestamp && errors.timestamp)}
            />
          </FormGroup>

          <FormGroup
            mb="spacingMedium"
            label="Comments"
            error={touched.comments && errors.comments ? errors.comments : undefined}
          >
            <Input
              value={form.comments ?? ''}
              autoComplete="off"
              onChange={handleChangeComments}
              data-testid="resolve-break-comments-input"
              disabled={disableForm}
            />
          </FormGroup>

          {disableForm && (
            <Alert variant={AlertVariants.Info} dismissable={false}>
              This break is already resolved.
            </Alert>
          )}
        </Flex>
      </Flex>

      <DrawerFooter w="100%">
        <Button onClick={closeDrawer}>Close</Button>
        <Button
          variant={ButtonVariants.Primary}
          disabled={anyError || isLoading || disableForm}
          onMouseOver={setAllTouched}
          loading={isLoading}
          onClick={handleResolve}
          data-testid="resolve-break-resolve-button"
        >
          {form.resolutionType === 'ignore' ? 'Ignore' : 'Resolve'}
        </Button>
      </DrawerFooter>
    </VStack>
  );
};

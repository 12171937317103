import {
  BlotterTable,
  BlotterTableFilters,
  type BlotterTableFiltersProps,
  type UseBlotterTable,
} from '../../BlotterTable';
import { PanelContent } from '../../Panel';
import type { EntityAdminClass, EntityAdminRecord } from '../types';

export const EntityAdminBlotter = <TRecord extends EntityAdminRecord>({
  jsonModal,
  blotterTable,
  blotterTableFilter,
}: {
  /** The blotter table props. */
  blotterTable: UseBlotterTable<EntityAdminClass<TRecord>>;

  /** The blotter table filters. */
  blotterTableFilter: BlotterTableFiltersProps;

  /** The json modal for Show JSON. */
  jsonModal?: React.ReactNode;
}) => {
  return (
    <PanelContent px="1.25rem">
      <BlotterTableFilters {...blotterTableFilter} />
      <BlotterTable {...blotterTable} />
      {jsonModal}
    </PanelContent>
  );
};

import { useDynamicCallback } from '@talos/kyoko';
import { useCallback, type RefObject } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMarketTabs, useMonitoringTabs } from '../../../../providers';
import { useAnalyticsTabs } from '../../../../providers/useAnalyticsTabs';
import {
  isCurrentRouteAnalyticsOrderDetails,
  isCurrentRouteCustomerOrderDetails,
  isCurrentRouteDealerMonitoringOrderDetails,
  isCurrentRouteMonitoringOrderDetails,
} from '../../../Routes/routes';
import { OrderDetails } from './OrderDetails';
import type { OrderDetailsPath } from './types';

/* Component used as a Market, Analytics and MonitoringTab */
export function OrderDetailsTab({ widthRef }: { widthRef: RefObject<HTMLDivElement> }) {
  const params = useParams<{ orderID: string; tab: OrderDetailsPath }>();
  const location = useLocation();
  const isOrderIDCustomerOrder = isCurrentRouteCustomerOrderDetails(location.pathname);
  const customerOrderID = isOrderIDCustomerOrder ? params.orderID : undefined;
  const orderID = isOrderIDCustomerOrder ? undefined : params.orderID;

  const marketTabs = useMarketTabs();
  const monitoringTabs = useMonitoringTabs();
  const analyticsTabs = useAnalyticsTabs();

  const navigate = useNavigate();

  const onSelectTab = useCallback(
    (path: OrderDetailsPath | undefined) => {
      if (isCurrentRouteMonitoringOrderDetails(location.pathname) && path) {
        monitoringTabs.updateOrderDetailsMonitoringTab(monitoringTabs.selectedIndex, path);
      } else if (path) {
        marketTabs.updateOrderDetailsMarketTab(marketTabs.selectedIndex, path);
      }
    },
    [location.pathname, marketTabs, monitoringTabs]
  );

  const handleClose = useDynamicCallback(() => {
    if (isCurrentRouteDealerMonitoringOrderDetails(location.pathname)) {
      // TODO add proper close support in dealer monitoring after mixed tabs is implemented
      navigate('/dealer/monitoring');
    } else if (isCurrentRouteMonitoringOrderDetails(location.pathname)) {
      monitoringTabs.removeActiveTab();
    } else if (isCurrentRouteAnalyticsOrderDetails(location.pathname)) {
      analyticsTabs.removeActiveTab();
    } else {
      marketTabs.removeActiveTab();
    }
  });

  if (customerOrderID == null && orderID == null) {
    return null;
  }

  return (
    (orderID || customerOrderID) && (
      <OrderDetails
        key={orderID}
        widthRef={widthRef}
        orderID={orderID}
        customerOrderID={customerOrderID}
        selectedTab={params.tab}
        onSelectTab={onSelectTab}
        onClose={handleClose}
        useLink={true}
      />
    )
  );
}

import type { ValueFormatterParams } from 'ag-grid-community';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import { isPlainObject } from 'lodash-es';
import { logger } from '../../../utils';
import { Text } from '../../Text';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column, TextColumnParams } from './types';

export const text: ColDefFactory<Column<TextColumnParams>> = column => ({
  ...baseColumn(column),
  cellEditor: 'input',
  valueFormatter: (params: ValueFormatterParams): string => {
    if (column.params?.getLabel) {
      return column.params?.getLabel(params.value, params);
    }

    if (params.value == null) {
      return '';
    }

    if (isPlainObject(params.value) || Array.isArray(params.value)) {
      try {
        return JSON.stringify(params.value);
      } catch (e) {
        logger.error(e as Error);
        return '';
      }
    }
    return `${params.value}`;
  },
  cellRenderer: (params: ICellRendererParams) => {
    if (params?.column?.isCellEditable(params.node!) && column.params?.placeholder != null && !params.valueFormatted) {
      return <Text color={column?.params?.color ?? 'colorTextSubtle'}>{column.params.placeholder}</Text>;
    }
    return <Text color={column?.params?.color}>{params.valueFormatted ?? ''}</Text>;
  },
});

import {
  type EditingDrawerTab,
  Field,
  Flex,
  FormGroup,
  HIDDEN_MARKET_ACCOUNT_GROUP_PREFIX,
  Input,
  type MarketAccount,
  NotificationVariants,
  Toggle,
  logger,
  shouldHideMarketAccount,
  useDynamicCallback,
  useGlobalToasts,
} from '@talos/kyoko';
import { useCallback, useMemo, useState } from 'react';
import { Autocomplete } from '../../../components/Form';
import { useMarketAccountRequests } from '../../../hooks/useMarketAccountRequests';
import { GROUP_CLIENT_HIDDEN } from './types';
import { useMarketAccountGroups } from './useMarketAccountGroups';
import { VALIDATION_RULES } from './validation';

export function useMarketAccountGeneralTab({ marketAccount }: { marketAccount: MarketAccount }): EditingDrawerTab {
  const { add: addToast } = useGlobalToasts();

  const { updateMarketAccount } = useMarketAccountRequests();
  const marketAccountGroups = useMarketAccountGroups();
  const groupOptions = useMemo(() => {
    return marketAccountGroups.map(group => ({ id: group, label: group }));
  }, [marketAccountGroups]);

  const update = useDynamicCallback((...params: Parameters<typeof updateMarketAccount>) => {
    updateMarketAccount(...params)
      .then(() => {
        addToast({
          text: `Market account ${marketAccount.DisplayName} updated.`,
          variant: NotificationVariants.Positive,
        });
      })
      .catch(e => {
        logger.error(e);
        addToast({
          text: `Could not update market account${e?.message ? `: ${e.message}` : ''}.`,
          variant: NotificationVariants.Negative,
        });
      });
  });

  const [displayName, setDisplayName] = useState(new Field<string>({ value: marketAccount.DisplayName }));
  const [group, setGroup] = useState(new Field<string>({ value: marketAccount.Group ?? '', isRequired: false }));
  const [hide, setHide] = useState(shouldHideMarketAccount(marketAccount));

  // Annoying: if we set Group to "", backend responds with Group: undefined. So handle that here in the dirty check. MarketAccount.Group will never be "".
  const groupChanged = marketAccount.Group == null ? group.value !== '' : marketAccount.Group !== group.value;

  // We treat display name as required so dont need to do the same logic
  const displayNameChanged = displayName.value !== marketAccount.DisplayName;

  const isDirty = displayNameChanged || groupChanged;

  const handleSaveChanges = useCallback(async () => {
    if (isDirty) {
      update(marketAccount.Name, marketAccount.Counterparty, { Group: group.value, DisplayName: displayName.value });
    }
  }, [isDirty, update, displayName, group, marketAccount]);

  const handleTouchAll = useCallback(() => {
    [setDisplayName, setGroup].forEach(setter => setter(curr => curr.setTouched(true)));
  }, []);

  const handleGroupChange = useCallback((value: string | undefined) => {
    setGroup(curr => curr.updateValue(value).validate(VALIDATION_RULES.group));
  }, []);

  const handleHideToggleChanged = useCallback(
    (checked: boolean) => {
      if (checked) {
        setGroup(curr => curr.updateValue(GROUP_CLIENT_HIDDEN)); // dont validate
      } else {
        // Set the group back to whatever it was before (or empty)
        const resetToEmpty = marketAccount.Group?.startsWith(HIDDEN_MARKET_ACCOUNT_GROUP_PREFIX);
        handleGroupChange(resetToEmpty ? '' : marketAccount.Group ?? '');
      }
      setHide(checked);
    },
    [marketAccount, handleGroupChange]
  );

  return {
    name: 'General',
    save: handleSaveChanges,
    hasError: displayName.hasError || group.hasError,
    isDirty,
    touchAll: handleTouchAll,
    viewable: true,
    component: (
      <Flex flexDirection="column" w="100%">
        <FormGroup label="Name" w="100%" error={displayName.errorString}>
          <Input
            onChange={e => setDisplayName(curr => curr.updateValue(e.target.value).validate())}
            value={displayName.value}
            invalid={!!displayName.errorString}
            data-testid="market-account-display-name-input"
          />
        </FormGroup>

        {/* Only show the Group control to the user if it is not being controlled by the Hide toggle */}
        {!hide && (
          <FormGroup label="Group" w="100%" error={group.errorString}>
            <Autocomplete
              isCentered
              getItemValue={item => item.label}
              inputProps={{
                id: 'group',
                autoFocus: false,
                type: 'text',
                'data-testid': 'market-account-group-input',
              }}
              items={groupOptions}
              shouldItemRender={(item, value) => item.label.indexOf(value) !== -1}
              value={group.value}
              sortItems={(a, b) => a.label.localeCompare(b.label)}
              onChange={(e, val) => {
                handleGroupChange(val);
              }}
              onSelect={val => {
                handleGroupChange(val);
              }}
              invalid={!!group.errorString}
            />
          </FormGroup>
        )}

        <FormGroup
          label="Hide Market Account"
          w="100%"
          tooltip="Enable to hide this Market Account from other parts of the application. Hiding puts the Market Account into a hidden group, meaning that you no longer can specify a group of your own."
          inline
          alignItems="center"
          justifyContent="space-between"
        >
          <Toggle onChange={handleHideToggleChanged} checked={hide} data-testid="market-account-hide-toggle" />
        </FormGroup>
      </Flex>
    ),
  };
}

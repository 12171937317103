import {
  DepthTypeEnum,
  FeeModeEnum,
  LiquidityTypeEnum,
  MARKET_DATA_SNAPSHOT,
  MARKET_DATA_STATISTICS,
  useSubscription,
  type MarketDataSnapshot,
  type MarketDataStatistics,
} from '@talos/kyoko';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { useEffect, useMemo } from 'react';
import { asyncScheduler } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import {
  selectMultilegRows,
  selectResolvedSecurities,
  updateMarketData,
  updateStatistics,
} from '../MultilegComboSlice';

// Given a list of securities, i.e. each leg in an Option strategy
// Subscribe to MarketStatistics and MarketDataSnapshot in order to enrich the rows to display more info
export const useRowEnricher = ({ tag, panelID }: { tag: string; panelID: string }): void => {
  const dispatch = useAppStateDispatch();
  const { showAllInPrices } = useDisplaySettings();
  const securities = useAppStateSelector(state => selectResolvedSecurities(state, panelID));
  const multilegRows = useAppStateSelector(state => selectMultilegRows(state, panelID));

  const statisticsRequest = useMemo(() => {
    const onlyResolved = securities.filter(x => !!x);
    if (!onlyResolved.length) {
      return [];
    }

    return multilegRows.flatMap((row, idx) => {
      const resolvedSecurity = onlyResolved.at(idx);
      // If row.markets is empty, all is implied, we need to get it from the underlying leg security
      const markets = row.markets?.length ? row.markets : resolvedSecurity?.Markets;

      return [
        {
          name: MARKET_DATA_STATISTICS,
          tag: `${tag}/useRowEnricher/leg/${row.leg}`,
          Markets: markets,
          Symbol: resolvedSecurity?.Symbol ?? row.symbol,
        },
      ];
    });
  }, [securities, multilegRows, tag]);

  const snapshotRequest = useMemo(() => {
    const onlyResolved = securities.filter(x => !!x);
    if (!onlyResolved.length) {
      return [];
    }

    return multilegRows.flatMap((row, idx) => {
      const resolvedSecurity = onlyResolved.at(idx);
      // If row.markets is empty, all is implied, we need to get it from the underlying leg security
      const markets = row.markets?.length ? row.markets : resolvedSecurity?.Markets;
      return [
        {
          name: MARKET_DATA_SNAPSHOT,
          DepthType: DepthTypeEnum.Price,
          tag: `${tag}/useRowEnricher/leg/${row.leg}`,
          Markets: markets,
          Symbol: resolvedSecurity?.Symbol ?? row.symbol,
          LiquidityType: LiquidityTypeEnum.Indicative,
          UnifiedLiquidity: 'Disabled',
          Depth: 1,
          FeeMode: showAllInPrices ? FeeModeEnum.Taker : undefined,
        },
      ];
    });
  }, [securities, multilegRows, tag, showAllInPrices]);

  const { data: statisticsSubscription } = useSubscription<MarketDataStatistics>(statisticsRequest);
  const { data: snapshotsSubscription } = useSubscription<MarketDataSnapshot>(snapshotRequest);

  useEffect(() => {
    // note: this is still a single subscription on websocket, we simply then branch out to individuals based on Symbol
    // this allows us to immediately get the first response for each symbol and then throttle the rest going forward
    const individualSubscriptions = securities.map(s => {
      return statisticsSubscription
        .pipe(throttleTime(5_000, asyncScheduler, { leading: true, trailing: true }))
        .subscribe(statisticResponse => {
          const leg = statisticResponse.tag?.split('/').at(-1);
          let legNumber: number = -1;
          if (!isNaN(Number(leg))) {
            legNumber = Number(leg);
          }
          dispatch(updateStatistics({ statistics: statisticResponse.data?.[0], panelID, legNumber }));
        });
    });
    return () => {
      individualSubscriptions.forEach(s => s.unsubscribe());
    };
  }, [statisticsSubscription, dispatch, securities, panelID]);

  useEffect(() => {
    const individualSubscriptions = securities.map(s => {
      return snapshotsSubscription
        .pipe(throttleTime(5_000, asyncScheduler, { leading: true, trailing: true }))
        .subscribe(snapshotResponse => {
          const leg = snapshotResponse.tag?.split('/').at(-1);
          let legNumber: number = -1;
          if (!isNaN(Number(leg))) {
            legNumber = Number(leg);
          }
          dispatch(updateMarketData({ snapshot: snapshotResponse.data?.[0], panelID, legNumber }));
        });
    });
    return () => {
      individualSubscriptions.forEach(s => s.unsubscribe());
    };
  }, [snapshotsSubscription, dispatch, securities, panelID]);
};

import type { createBrowserRouter, MemoryRouterProps, RouterProviderProps } from 'react-router-dom';

/** React-Router 6->7 Future Flags.  Once migration to RR7 is complete, this should be deleted */

export const MEMORY_ROUTER_FUTURE_FLAGS: MemoryRouterProps['future'] = {
  v7_relativeSplatPath: true,
  v7_startTransition: true,
};

export const ROUTER_PROVIDER_FUTURE_FLAGS: RouterProviderProps['future'] = {
  v7_startTransition: true,
};

// All Router creators use the same set of flags, and the RouterProvider handles the other
export const ROUTER_CREATE_FUTURE_FLAGS: NonNullable<Parameters<typeof createBrowserRouter>[1]>['future'] = {
  v7_fetcherPersist: true,
  v7_normalizeFormMethod: true,
  v7_partialHydration: true,
  v7_relativeSplatPath: true,
  v7_skipActionErrorRevalidation: true,
};

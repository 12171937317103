import { addDays, startOfDay, subMinutes } from 'date-fns';
import { identity } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DateUtils } from 'react-day-picker';
import { defineMessages } from 'react-intl';
import { useTheme } from 'styled-components';
import { getIntlKey, type IntlWithFormatter } from '../../contexts/IntlContext';
import { useConstant } from '../../hooks/useConstant';
import { useDynamicCallback } from '../../hooks/useDynamicCallback';
import { useIntl } from '../../hooks/useIntl';
import { useIntlContext } from '../../hooks/useIntlContext';
import { beginningOfDay, endOfDay, readableDay } from '../../utils/date';
import { isKeyIn } from '../../utils/types';
import { Button, ButtonVariants } from '../Button';
import { Box, Flex, HStack, VStack } from '../Core';
import { DayPicker } from '../DayPicker';
import { Divider } from '../Divider';
import { FormGroup } from '../Form/FormGroup';
import {
  AutocompleteResults,
  BaseSelect,
  Dropdown,
  FuseAutocompleteResult,
  Input,
  flattenAutocompleteGroupItems,
  useDropdownPopper,
  useSearchSelect,
  type AutocompleteItemsProp,
  type BaseSelectProps,
  type FormControlProps,
  type UseAutocompleteProps,
} from '../Form/index';
import { FormControlSizes } from '../Form/types';
import { Icon, IconName } from '../Icons';
import { FormattedMessage } from '../Intl';
import { TimePicker, TimePickerPrecision, type TimePickerProps } from '../TimePicker';
import { TimeSelector } from '../TimeSelector';
import {
  dateRangeMessages,
  isDateRange,
  isLookbackWindow,
  type DateRange,
  type DateRangeOrLookback,
  type LookbackOption,
} from './dateRange';

const messages = defineMessages({
  clear: {
    defaultMessage: 'Clear',
    id: 'DateRangePicker.clear',
  },
  selectDates: {
    defaultMessage: 'Select Dates',
    id: 'DateRangePicker.selectDates',
  },
  submit: {
    defaultMessage: 'Submit',
    id: 'DateRangePicker.submit',
  },
  sinceDate: {
    defaultMessage: 'Since {date}',
    id: 'DateRangePicker.sinceDate',
  },
  dateRange: {
    defaultMessage: '{from} to {to}',
    id: 'DateRangePicker.dateRange',
  },
  custom: {
    defaultMessage: 'Custom',
    id: 'DateRangePicker.custom',
  },
});

export {
  DEFAULT_LOOKBACK_OPTIONS,
  LOOKBACKS,
  LookbackOption,
  isDateRange,
  isLookbackWindow,
  lookbackOptionToDate,
  type DateRange,
  type DateRangeOrLookback,
  type LookbackWindow,
} from './dateRange';
export { deserializeDateRange, deserializeDateRangeOrLookback } from './deserializeDateRange';

const { addDayToRange } = DateUtils;

function getShortcutLabel(option: ShortcutOption, intl: IntlWithFormatter) {
  if (option.value === 'Custom') {
    return intl.formatMessage(messages.custom);
  }
  const intlKey = getIntlKey(option.value);
  if (isKeyIn(intlKey, dateRangeMessages)) {
    return intl.formatMessage(dateRangeMessages[intlKey]);
  }
  return option.value;
}

export interface ShortcutOption {
  value: LookbackOption | 'Custom';
}
const CUSTOM_OPTION: ShortcutOption = { value: 'Custom' };

export type DateRangePickerProps = {
  value: DateRangeOrLookback;
  onChange: (datesOrLookback: DateRangeOrLookback) => void;
  shortcuts?: ShortcutOption[];
  // If true allows this control to set value to an empty DateRange. Otherwise the control will never call onChange with a 'null' DateRange
  clearable?: boolean;
  canSubmitCleared?: boolean;
  maxTimePickerPrecision?: TimePickerProps['maxPrecision'];
  /**
   * Pass a getLabel callback in in order to be able to define your own labels to be shown in the selector.
   * Optional, and if not provided, the component will use its default label logic.
   * If provided, you can return undefined to fallback to the default label logic in certain cases, for example if you want
   * the DateRangePicker to use its own label logic for DateRange values, but not LookbackWindow values.
   */
  getLabel?: (value: DateRangeOrLookback) => string | undefined;

  /**
   * What type of control you want for setting times (hours, minutes, seconds). Defaults to input.
   */
  timePickerVariant?: 'input' | 'selector';
  /** If using timePickerVariant: "selector", this is the intervals you can select in that selector. Defaults to 60. */
  timeSelectorIntervalMinutes?: number;
  'data-testid'?: string;
} & Omit<FormControlProps<HTMLInputElement>, 'onChange' | 'value'> &
  Pick<BaseSelectProps<unknown>, 'prefix'> &
  Pick<UseAutocompleteProps<ShortcutOption>, 'getGroup'>;

// This essentially disables the default sorting/filtering of the shortcut list
const noopShortcutFilter: AutocompleteItemsProp<ShortcutOption>['sortFilterOverride'] = fullList => fullList;

export function DateRangePicker({
  value,
  onChange,
  disabled,
  invalid,
  width,
  shortcuts,
  size,
  canSubmitCleared = false,
  clearable = true,
  maxTimePickerPrecision = TimePickerPrecision.MILLISECOND,
  prefix,
  getLabel,
  getGroup,
  timePickerVariant = 'input',
  timeSelectorIntervalMinutes = 60,
  ...props
}: DateRangePickerProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const fromTimeSelectorDropdownContentRef = useRef<HTMLDivElement>(null);
  const toTimeSelectorDropdownContentRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const intl = useIntl();
  const { locale } = useIntlContext();

  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLLabelElement>(null);
  const [initialMonth] = useState(Sugar.Date.create('1 month ago'));
  // Internal state of selector separate from the value passed in from the parent. The value here should not be communicated to the parent if
  // the user clicks out of the control without selecting a shortcut or explicitly hitting submit.
  const [selectedDatesOrLookback, setSelectedDatesOrLookback] = useState<DateRangeOrLookback>({
    from: null,
    to: null,
  });

  const dropdown = useDropdownPopper({
    dropdownPlacement: 'bottom-start',
    isOpen,
    referenceElement: selectRef.current,
    dropdownWidth: 'fit-content',
    onClickOutside: () => setIsOpen(false),
  });

  const handleShortcutChange = useDynamicCallback((shortcutOption: ShortcutOption | undefined) => {
    if (shortcutOption && shortcutOption.value !== 'Custom') {
      setSelectedDatesOrLookback({ lookback: shortcutOption.value });

      // On selecting a shortcut, emit an on change and then close.
      onChange({ lookback: shortcutOption.value });
      setIsOpen(false);
    }
  });

  const shortcutOptions = useMemo(() => {
    if (!shortcuts) {
      return [];
    }

    return [CUSTOM_OPTION, ...shortcuts];
  }, [shortcuts]);

  const selectedShortcutOption: ShortcutOption = useMemo(() => {
    if (isLookbackWindow(selectedDatesOrLookback)) {
      // If its not a date range, then the value is set by using some shortcut. See if we can find it...
      const usedShortcutOption = flattenAutocompleteGroupItems<ShortcutOption>(shortcutOptions).find(
        option => option.value === selectedDatesOrLookback.lookback
      );

      // If we cant find any used shortcut option, fallback to custom
      return usedShortcutOption ?? CUSTOM_OPTION;
    }

    return CUSTOM_OPTION;
  }, [selectedDatesOrLookback, shortcutOptions]);

  const getAutoCompleteLabel = useConstant((option: ShortcutOption) => getShortcutLabel(option, intl));

  const autocomplete = useSearchSelect<ShortcutOption>({
    selectedItem: selectedShortcutOption,
    items: shortcutOptions,
    getLabel: getAutoCompleteLabel,
    inputRef,
    onChange: handleShortcutChange,
    closeDropdownOnItemSelection: false,
    inputValueChangeOnItemSelection: 'clear',
    initialSortByLabel: false,
    enableTabSelect: false,
    sortFilterOverride: noopShortcutFilter,
    getGroup,
  });

  const handleSelectClick = useDynamicCallback(() => {
    setIsOpen(open => !open);
  });

  useEffect(() => {
    // When date selector is not open, set what is shown in the input field to match the parent's specified value.
    // In this case we want to show the 'true' value from the form and not local state in the control.
    if (!isOpen) {
      setSelectedDatesOrLookback(value);
    }
  }, [value, isOpen]);

  const handleSelectDay = useDynamicCallback((day: Date) => {
    // A day in the calendar was clicked. In this function we decide what the output state should be.
    // Create a new dates object (note: effectively also resetting the "lookback" field)
    const newDates: DateRange = { from: null, to: null };

    // If the current (previous, pre-click) state is a date range (.from and .to exist), do some math (addDayToRange) to
    // apply the clicked date to the existing selectedDates
    if (isDateRange(selectedDatesOrLookback)) {
      const newRange = addDayToRange(day, selectedDatesOrLookback);

      // We clicked on some day, and that means we get new from and to dates. Depending on if we're using
      // an input or select for time selection, we need to adhere to different rules.
      // For input, you can select any time you wish. For select, there's some interval you need to follow.

      // Assume that starting on 00:00:00 is always fine in both cases
      newDates.from = beginningOfDay(newRange.from);

      if (newRange.to != null) {
        if (timePickerVariant === 'input') {
          newDates.to = endOfDay(newRange.to); // 23:59:59.999
        } else {
          const startOfNextDay = startOfDay(addDays(newRange.to, 1));
          // Subtract by one interval length
          newDates.to = subMinutes(startOfNextDay, timeSelectorIntervalMinutes); // for example 15 min interval: 23:45:00.000
        }
      }
    } else {
      // We're a lookback window, set the clicked date to be the from date
      newDates.from = beginningOfDay(day);
    }
    setSelectedDatesOrLookback(newDates);
  });

  const handleChangeTime = useCallback(
    (key: 'from' | 'to', time: Date) => {
      let newDate;
      if (isDateRange(selectedDatesOrLookback)) {
        newDate = new Date(selectedDatesOrLookback[key]!);
      } else {
        throw new Error('Should not be able to modify time unless a day has been selected');
      }
      newDate.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
      setSelectedDatesOrLookback(prev => ({ ...prev, [key]: newDate }));
    },
    [selectedDatesOrLookback]
  );

  const handleClear = useCallback(
    (closeAfterClear = false) => {
      // If we are not clearable then null local state but don't communicate any change in state to parent
      if (clearable) {
        onChange({ from: null, to: null });
      }
      setSelectedDatesOrLookback({
        from: null,
        to: null,
      });
      if (closeAfterClear) {
        setIsOpen(false);
      }
    },
    [clearable, onChange]
  );

  const handleSubmit = useCallback(() => {
    onChange(selectedDatesOrLookback);
    setIsOpen(false);
  }, [onChange, selectedDatesOrLookback]);

  const text = dateRangeOrLookbackToString(intl, locale, selectedDatesOrLookback, getLabel);

  const { from, to, lookback } = { from: null, to: null, lookback: undefined, ...selectedDatesOrLookback };
  const canSubmit = from != null || lookback != null || canSubmitCleared;

  return (
    <Box data-testid={props['data-testid'] ?? 'date-range-picker'}>
      <BaseSelect
        placeholder={intl.formatMessage(messages.selectDates)}
        onClick={handleSelectClick}
        onClearClick={() => handleClear(true)}
        value={text}
        getLabel={identity}
        disabled={disabled}
        invalid={invalid}
        size={size}
        wrapperRef={selectRef}
        isDropdownOpened={isOpen}
        clearable={clearable}
        suffix={
          <HStack mx="spacingTiny">
            <Icon icon={IconName.Calendar} />
          </HStack>
        }
        prefix={prefix}
      >
        <Dropdown {...dropdown} portalize isParentDropdown>
          <Box display="grid" gridTemplateColumns="min-content min-content max-content" gridTemplateRows="min-content">
            <Flex w="180px" h="100%" position="relative" overflow="auto">
              {/**
               * `suppressRefError` is used here not because of the ref failing to be set,
               * rather, it is because of the dropdown being portalized using `useTopLevelPortalElement` which
               * leverages `useEffect` to render the content one render cycle after the component is mounted.
               * downshift then complains about ref being set incorrectly, which we suppress.
               */}
              <Box
                {...autocomplete.getMenuProps({}, { suppressRefError: true })}
                overflow="auto"
                position="absolute"
                top="0"
                right="0"
                bottom="0"
                left="0"
              >
                <Input
                  {...autocomplete.getInputProps(
                    { style: { display: 'none' }, ref: inputRef },
                    { suppressRefError: true }
                  )}
                />
                <AutocompleteResults
                  {...autocomplete}
                  renderResult={FuseAutocompleteResult}
                  isOpen // always true
                  maxHeight={1000} // this number just has to be higher than the dynamic height of the date range picker part
                  groupMaxHeight={1000} // some arbitrarily very high number so we never show double scroll bars in this component
                />
              </Box>
            </Flex>
            <Divider orientation="vertical" my="0" color={theme.colors.gray['000']} />
            <VStack>
              <HStack alignItems="flex-start">
                <VStack>
                  <DayPicker
                    initialMonth={initialMonth}
                    numberOfMonths={2}
                    selectedDays={[from ?? undefined, { from, to }]}
                    modifiers={{
                      start: from ?? undefined,
                      end: to ?? undefined,
                    }}
                    onDayClick={handleSelectDay}
                    locale={locale}
                  />

                  <HStack gap="spacingLarge" w="100%" pb="spacingComfortable" px="spacingComfortable">
                    <FormGroup label="From" mb={0} w="100%">
                      {timePickerVariant === 'input' ? (
                        <TimePicker
                          onChange={value => handleChangeTime('from', value)}
                          date={from ?? null}
                          maxPrecision={maxTimePickerPrecision}
                          disabled={from == null}
                        />
                      ) : (
                        <TimeSelector
                          w="100%"
                          selection={from ?? undefined}
                          intervalMinutes={timeSelectorIntervalMinutes}
                          onChange={date => handleChangeTime('from', date)}
                          dropdownContentRef={fromTimeSelectorDropdownContentRef}
                          size={FormControlSizes.Small}
                          disabled={from == null}
                          portalize
                        />
                      )}
                    </FormGroup>
                    <FormGroup label="To" mb={0} w="100%">
                      {timePickerVariant === 'input' ? (
                        <TimePicker
                          onChange={value => handleChangeTime('to', value)}
                          date={to ?? null}
                          maxPrecision={maxTimePickerPrecision}
                          disabled={to == null}
                        />
                      ) : (
                        <TimeSelector
                          w="100%"
                          selection={to ?? undefined}
                          disabled={to == null}
                          intervalMinutes={timeSelectorIntervalMinutes}
                          onChange={date => handleChangeTime('to', date)}
                          dropdownContentRef={toTimeSelectorDropdownContentRef}
                          size={FormControlSizes.Small}
                          portalize
                        />
                      )}
                    </FormGroup>
                  </HStack>
                  <HStack w="100%" gap="spacingLarge" background="colors.gray['020']" p="spacingComfortable">
                    <Box flex="1">
                      <Button
                        width="100%"
                        size={FormControlSizes.Small}
                        onClick={() => handleClear(false)}
                        disabled={!from && !to}
                      >
                        <FormattedMessage {...messages.clear} />
                      </Button>
                    </Box>
                    <Box flex="1">
                      <Button
                        width="100%"
                        variant={ButtonVariants.Primary}
                        size={FormControlSizes.Small}
                        onClick={handleSubmit}
                        disabled={!canSubmit}
                      >
                        <FormattedMessage {...messages.submit} />
                      </Button>
                    </Box>
                  </HStack>
                </VStack>
              </HStack>
            </VStack>
          </Box>
        </Dropdown>
      </BaseSelect>
    </Box>
  );
}

function dateRangeOrLookbackToString(
  intl: IntlWithFormatter,
  locale: string,
  dateRangeOrLookback?: DateRangeOrLookback,
  getLabel?: (value: DateRangeOrLookback) => string | undefined
): string {
  if (!dateRangeOrLookback) {
    return '';
  }

  const maybeCustomLabel = getLabel?.(dateRangeOrLookback);
  if (maybeCustomLabel != null) {
    return maybeCustomLabel;
  }

  if ('lookback' in dateRangeOrLookback && dateRangeOrLookback.lookback != null) {
    const intlKey = getIntlKey(dateRangeOrLookback.lookback);
    if (isKeyIn(intlKey, dateRangeMessages)) {
      return intl.formatMessage(dateRangeMessages[intlKey]);
    }
    return dateRangeOrLookback.lookback;
  }
  if (isDateRange(dateRangeOrLookback) && dateRangeOrLookback.from != null) {
    if (dateRangeOrLookback.to == null) {
      return intl.formatMessage(messages.sinceDate, { date: readableDay(dateRangeOrLookback.from) });
    }
    return intl.formatMessage(messages.dateRange, {
      from: readableDay(dateRangeOrLookback.from, false, locale),
      to: readableDay(dateRangeOrLookback.to, false, locale),
    });
  }
  return '';
}

import type Popper from '@popperjs/core';
import { detectOverflow } from '@popperjs/core';
import { noop } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { type Modifier, usePopper } from 'react-popper';
import { useTheme } from 'styled-components';
import { useDeviceType } from '../../hooks/useDeviceType';
import { useOverridePopoverProps } from '../../providers/OverridePopoverPropsProvider';
import { EMPTY_ARRAY } from '../../utils/empty';
import type { PopoverState, PopoverStateProps } from './types';

export function usePopoverState({
  closeOnClickOutside = false,
  onClickOutside = noop,
  trigger = 'click',
  isSmall = false,
  usePortal = false,
  onOpen = noop,
  onClose = noop,
  placement,
  modifiers = EMPTY_ARRAY,
  delay,
  strategy = 'absolute',
  preventOverflow = true,
  noPaddingAndBorder = false,
  popoverDocument,
}: PopoverStateProps): PopoverState {
  const { spacingSmall } = useTheme();
  const [targetRef, setTargetRef] = useState<HTMLSpanElement | null>(null);
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);

  const overridePopoverProps = useOverridePopoverProps();
  // If the context overrides the trigger, use that instead.
  trigger = overridePopoverProps.trigger ?? trigger;

  const [isOpen, setOpen] = useState(false);
  const [overflow, setOverflow] = useState<Popper.SideObject>({} as any);

  const overflowModifier: Modifier<string, object> = useMemo(
    () => ({
      name: 'maxHeight',
      enabled: true,
      phase: 'main',
      requiresIfExists: ['offset'],
      fn({ state }) {
        setOverflow(detectOverflow(state));
      },
    }),
    []
  );

  const popper = usePopper(targetRef, contentRef, {
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          padding: spacingSmall,
        },
      },
      overflowModifier,
      ...modifiers,
    ],
    placement,
    strategy,
  });
  const { styles, attributes, state, update } = popper;

  const onClickTarget = () => {
    if (trigger === 'click') {
      toggle();
    }
  };

  const toggle = () => (isOpen ? close() : open());

  const close = useCallback(() => {
    onClose && onClose();
    setOpen(false);
  }, [onClose]);

  const open = useCallback(() => {
    onOpen && onOpen();
    setOpen(true);
  }, [onOpen]);

  // e.target contains the element that is being leaved
  const isLeavingContent = (e: React.MouseEvent) =>
    e.target === window || !contentRef || contentRef.contains(e.target as HTMLElement);

  // if leaving target, close unless the next element is the content
  const isLeavingPopover = (e: React.MouseEvent) =>
    e.relatedTarget === window ||
    e.target === window ||
    !targetRef ||
    !contentRef ||
    (targetRef.contains(e.target as Node) && !contentRef.contains(e.relatedTarget as Node));

  const isClickingOutsidePopover = useCallback(
    (e: MouseEvent | TouchEvent) => {
      return (
        !targetRef || !contentRef || (!targetRef.contains(e.target as Node) && !contentRef.contains(e.target as Node))
      );
    },
    [contentRef, targetRef]
  );

  let openTimer: ReturnType<typeof setTimeout> | null;
  let closeTimer: ReturnType<typeof setTimeout> | null;
  const onMouseEnterTarget = () => {
    if (trigger === 'hover') {
      closeTimer && clearTimeout(closeTimer);
      delay
        ? (openTimer = setTimeout(() => {
            open();
            openTimer = null;
          }, delay))
        : open();
    }
  };

  const onMouseLeave = (e: React.MouseEvent) => {
    if (trigger === 'hover') {
      if (isLeavingContent(e) || isLeavingPopover(e)) {
        openTimer && clearTimeout(openTimer);
        delay
          ? (closeTimer = setTimeout(() => {
              close();
              closeTimer = null;
            }, 150))
          : close();
      }
    }
  };

  const onFocusTarget = () => {
    if (trigger === 'hover') {
      open();
    }
  };
  const onBlurTarget = () => {
    if (trigger === 'hover') {
      close();
    }
  };

  const targetDocument = popoverDocument ?? document;

  const isTouchScreen = useDeviceType() === 'mobile';
  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = (e: MouseEvent | TouchEvent) => {
        if (contentRef && isClickingOutsidePopover(e)) {
          if (closeOnClickOutside) {
            close();
          }

          onClickOutside && onClickOutside(e);
        }
      };
      targetDocument.addEventListener('mousedown', handleClickOutside);
      if (isTouchScreen) {
        targetDocument.addEventListener('touchstart', handleClickOutside);
      }
      return () => {
        targetDocument.removeEventListener('mousedown', handleClickOutside);
        if (isTouchScreen) {
          targetDocument.removeEventListener('touchstart', handleClickOutside);
        }
      };
    }
  }, [
    targetDocument,
    isTouchScreen,
    isOpen,
    close,
    closeOnClickOutside,
    contentRef,
    isClickingOutsidePopover,
    onClickOutside,
  ]);

  return {
    onClickTarget,
    onMouseEnterTarget,
    onMouseLeave,
    onFocusTarget,
    onBlurTarget,
    targetRef,
    contentRef,
    setTargetRef,
    setContentRef,
    isOpen,
    close,
    open,
    toggle,
    placement,
    isSmall,
    usePortal,
    styles: { popper: styles.popper },
    attributes,
    state,
    update,
    spacing: spacingSmall,
    overflow,
    preventOverflow,
    noPaddingAndBorder,
  };
}

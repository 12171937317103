import {
  ACTION,
  EntityAdminREST,
  FormControlSizes,
  ModeEnum,
  useConstant,
  useSymbolsFilter,
  type FilterableProperty,
  type ICustomerOrderExecutionRule,
} from '@talos/kyoko';
import { useCounterpartiesFilter } from 'hooks/filters/useCounterpartyFilter';
import { useExecutionStrategiesFilter } from 'hooks/filters/useExecutionStrategiesFilter';
import { useOrdTypeFilter } from 'hooks/filters/useOrdTypeFilter';
import { useTimeInForceFilter } from 'hooks/filters/useTimeInForceFilter';
import { useCustomers } from 'hooks/useCustomer';
import { useRoleAuth } from 'hooks/useRoleAuth';
import { useCustomerSecurities } from 'providers';
import { useMemo } from 'react';
import { KillSwitch } from './KillSwitch';
import { CUSTOMER_EXECUTION_RULES_COLUMNS, getCustomerOrderExecutionRulesDrawerOptions } from './utils';

export function CustomerExecutionRules() {
  const { isAuthorized } = useRoleAuth();

  const customers = useCustomers();
  const customerSecurities = useCustomerSecurities();
  const effectiveSecurities = useMemo(
    () => customerSecurities?.filter(sec => sec.Mode === ModeEnum.Enabled),
    [customerSecurities]
  );

  const counterpartiesFilter = useCounterpartiesFilter();
  const symbolsFilter = useSymbolsFilter(effectiveSecurities);
  const timeInForceFilter = useTimeInForceFilter();
  const executionStrategiesFilter = useExecutionStrategiesFilter();
  const ordTypeFilter = useOrdTypeFilter();

  const filterableProperties: FilterableProperty[] = useMemo(() => {
    return [counterpartiesFilter, symbolsFilter, timeInForceFilter, executionStrategiesFilter, ordTypeFilter];
  }, [counterpartiesFilter, executionStrategiesFilter, ordTypeFilter, symbolsFilter, timeInForceFilter]);

  const drawerOptions = useMemo(() => {
    if (!customers || !effectiveSecurities) {
      return undefined;
    }
    return getCustomerOrderExecutionRulesDrawerOptions({ customers: customers, securities: effectiveSecurities });
  }, [customers, effectiveSecurities]);

  const panelActions = useConstant([<KillSwitch key="kill-switch" size={FormControlSizes.Small} />]);

  return (
    <EntityAdminREST<ICustomerOrderExecutionRule>
      title="Execution Rules"
      subtitle="These rules determine how client orders will be executed. If post-trade hedging is desired, you can set those rules up here."
      entityName="Execution Rule"
      path="/customer/order-execution-rules"
      entityIDField="RuleID"
      columns={CUSTOMER_EXECUTION_RULES_COLUMNS}
      drawerOptions={drawerOptions}
      filterableProperties={filterableProperties}
      allowAddEntity={isAuthorized(ACTION.DEALER_TRADING)}
      allowEditEntity={isAuthorized(ACTION.DEALER_TRADING)}
      allowDeleteEntity={isAuthorized(ACTION.DEALER_TRADING)}
      panelActions={panelActions}
      persistKey="dealer/execution-rules"
      allowBulkEdit={true}
      bulkEditFields={[
        'ExecutionStrategy',
        'TimeInForce',
        'Priority',
        'StartTime',
        'EndTime',
        'OrdType',
        'MinNotionalThreshold',
        'MaxNotionalThreshold',
        'MinQuantityThreshold',
        'MaxQuantityThreshold',
      ]}
      sendEmptyStringForNulledValues={true}
    />
  );
}

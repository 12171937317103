import styled, { type DefaultTheme } from 'styled-components';
import { modeColor } from '../../styles';
import type { ValueOf } from '../../utils/types';
import { Box } from '../Core';
import { Icon } from '../Icons';
import type { NotificationAppInboxVariants, NotificationSizes } from './types';

const defaultNotificationShade = '040';
export const NotificationWrapper = styled(Box)<{ size: NotificationSizes }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  min-width: min(20rem, 100%);
  color: ${({ theme }) => theme.colorTextImportant};

  ${({ theme, size }) => `
    gap: ${theme.spacing8}px;
    padding: ${size === 'Default' ? theme.spacing16 : theme.spacing8}px;
    background: ${modeColor(theme, theme.colors.gray.main, theme.colors.gray[defaultNotificationShade])};
    font-size: ${theme.fontSizeDefault}rem;
  `}

  ${Icon} {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colorTextDefault};
    }
  }
`;

function getBorderTopColor(theme: DefaultTheme, variant?: ValueOf<typeof NotificationAppInboxVariants>) {
  let borderTopColor: string | undefined = undefined;
  switch (variant) {
    case 'HIGH_PRIORITY':
      borderTopColor = theme.colors.red.lighten;
      break;
    case 'MEDIUM_PRIORITY':
      borderTopColor = theme.colors.yellow.lighten;
      break;
    default:
      borderTopColor = undefined;
      break;
  }
  return borderTopColor;
}

export const NotificationAppInboxWrapper = styled(NotificationWrapper)<{
  variant?: ValueOf<typeof NotificationAppInboxVariants>;
}>`
  ${({ theme, variant }: { theme: DefaultTheme; variant?: ValueOf<typeof NotificationAppInboxVariants> }) => `
     padding: 10px;
     border-radius: ${theme.borderRadiusMedium}px;
     border: 2px solid ${theme.colors.gray[defaultNotificationShade]};
     border-top: 2px solid ${getBorderTopColor(theme, variant) ?? theme.colors.gray[defaultNotificationShade]};
  `}
`;

import { HStack, HelpIcon, MultiSelect, Text, useCurrenciesContext } from '@talos/kyoko';
import { identity } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { FormHeader, FormRow } from '../../../components/Form';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';

export const BulkClosePositionQuoteCurrenciesSetting = () => {
  const { bulkClosePositionQuoteCurrencies, setBulkClosePositionQuoteCurrencies } = useTradingSettings();

  const { currenciesList } = useCurrenciesContext();
  const currencyOptions = useMemo(() => {
    return currenciesList.map(ccy => ccy.Symbol);
  }, [currenciesList]);

  const handleChange = useCallback(
    (newSelections: string[]) => {
      setBulkClosePositionQuoteCurrencies(newSelections);
    },
    [setBulkClosePositionQuoteCurrencies]
  );

  // We don't allow the user to delete the last selection. This simple implementation of this mechanism saves us a lot of headache.
  const isSelectionLocked = useCallback(
    (value: string) => {
      return bulkClosePositionQuoteCurrencies.length === 1;
    },
    [bulkClosePositionQuoteCurrencies]
  );

  return (
    <>
      <FormHeader>Bulk Close Position</FormHeader>
      <FormRow>
        <HStack gap="spacingDefault">
          <HStack gap="spacingTiny">
            <Text color="colorTextImportant">Bulk Close Currencies</Text>
            <HelpIcon tooltip="This setting lets you determine what currencies to use as quote currencies when bulk closing spot positions. The order of the currencies in this list determines the priority for defaulting in the Bulk Close Position preview modal. This setting cannot be left empty." />
          </HStack>
          <MultiSelect
            selections={bulkClosePositionQuoteCurrencies}
            options={currencyOptions}
            getLabel={identity}
            onChange={handleChange}
            isSelectionLocked={isSelectionLocked}
          />
        </HStack>
      </FormRow>
    </>
  );
};

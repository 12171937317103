import { immerable } from 'immer';

import {
  BaseField,
  FieldValidationLevel,
  FieldValidationType,
  type FieldData,
  type FieldValidationResult,
  type FieldValidationRule,
} from './BaseField';
import { fieldsMessages } from './messages';

export interface MultiSelectorFieldData<T> extends FieldData<T[]> {
  availableItems: T[];
  idProperty?: keyof T; // for Object types, semantic key (ID, Name, Symbol, etc)
}

export class MultiSelectorField<T> extends BaseField<MultiSelectorFieldData<T>, T[]> {
  [immerable] = true;
  constructor(initial?: Partial<MultiSelectorFieldData<T>>) {
    super({
      name: 'MultiSelectorField',
      value: [],
      isRequired: true,
      placeholder: 'Type here',
      isTouched: false,
      isDisabled: false,
      isVisible: true,
      errors: [],
      availableItems: [],
      ...initial,
    });
  }

  public get availableItems(): T[] {
    return this.data.availableItems;
  }

  public get hasAvailableItems(): boolean {
    return this.data.availableItems.length > 0;
  }

  public get value(): T[] {
    return this.data.value || [];
  }

  public override get hasValue(): boolean {
    return this.value.length > 0;
  }

  public override updateValue(selectedItem: T[] = [], isSystemOverride = false): MultiSelectorField<T> {
    const updatedData = {
      value: selectedItem,
      isTouched: isSystemOverride ? false : true,
    };

    const updated = this.updateData(updatedData);
    return updated.invariantCheck();
  }

  public setTouched(isTouched: boolean): MultiSelectorField<T> {
    const updated = this.updateData({ isTouched });
    return updated.invariantCheck();
  }

  public setIsRequired(isRequired: boolean): MultiSelectorField<T> {
    const updated = this.updateData({ isRequired });
    return updated.invariantCheck();
  }

  public setIsVisible(isVisible: boolean): MultiSelectorField<T> {
    const updated = this.updateData({ isVisible });
    return updated.invariantCheck();
  }

  public validate<C>(
    rules: FieldValidationRule<MultiSelectorField<T>, C, T[]>[] = [],
    context?: C
  ): MultiSelectorField<T> {
    const checked = this.invariantCheck();
    const errors = checked.data.errors.filter(e => e.type !== FieldValidationType.Rule);

    rules.forEach(rule => {
      const result = rule(this, context);
      if (result) {
        errors.unshift({ ...result, type: FieldValidationType.Rule });
      }
    });

    return this.updateData({ errors });
  }

  public override setDisabled(isDisabled: boolean): MultiSelectorField<T> {
    return this.updateData({ isDisabled });
  }

  public updateAvailableItems(availableItems: T[]): MultiSelectorField<T> {
    const existingValues = this.value;

    const value = existingValues
      .map(existingValue => {
        return availableItems.find(item => {
          if (this.data.idProperty) {
            return item[this.data.idProperty] === existingValue?.[this.data.idProperty];
          }
          return item === existingValue;
        });
      })
      .filter(x => !!x) as T[];

    return this.updateData({
      availableItems,
      value,
      isTouched: value ? this.data.isTouched : false,
    });
  }

  private invariantCheck() {
    const errors: FieldValidationResult[] = this.data.errors.filter(e => e.type === FieldValidationType.Rule);

    const missingValue = this.data.value == null || !this.data.value?.length;
    if (this.data.isRequired && missingValue) {
      errors.push({
        message: fieldsMessages.dataNameIsRequired,
        values: { dataName: this.data.name },
        level: FieldValidationLevel.Error,
      });
    }

    return this.updateData({ errors });
  }

  private updateData(data: Partial<MultiSelectorFieldData<T>>): MultiSelectorField<T> {
    const newData = {
      ...this.data,
      ...data,
    };
    return new MultiSelectorField(newData);
  }
}

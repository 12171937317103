import {
  BaseStyle,
  Button,
  ButtonVariants,
  Crossmark,
  DarkTalosTheme,
  GlobalStyle,
  HStack,
  Text,
  VStack,
} from '@talos/kyoko';
import styled, { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from '../../components/ErrorBoundary';

export const Content = styled.div`
  background: ${({ theme }) => theme.backgroundCard};
  flex: 1 1 0;
  overflow: hidden;
  display: flex;
  gap: ${({ theme }) => theme.spacingTiny}px;
  background: ${({ theme }) => theme.backgroundBody};
`;

export const Unauthorized = () => {
  return (
    <ThemeProvider theme={DarkTalosTheme}>
      <BaseStyle />
      <GlobalStyle />
      <ErrorBoundary>
        <VStack h="100%" alignItems="stretch">
          <Content>
            <VStack w="100%" gap="spacingDefault">
              <Crossmark />
              <Text fontSize="fontSizeLarge">You are not authorized to view this page</Text>
              <Text>
                If this issue persists, please get in touch with support and we will resolve the problem as soon as
                possible.
              </Text>
              <HStack pt="spacingLarge" gap="spacingDefault">
                <Button onClick={() => window.history.back()}>Go back</Button>
                <Button variant={ButtonVariants.Primary} onClick={() => (window.location.href = '/')}>
                  Home
                </Button>
              </HStack>
            </VStack>
          </Content>
        </VStack>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

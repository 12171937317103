import {
  Card,
  CopyButton,
  FormControlSizes,
  HStack,
  Text,
  VStack,
  type ExportDataAsCsvParams,
  type SettlementPreview,
} from '@talos/kyoko';
import { compact } from 'lodash-es';
import * as Papa from 'papaparse';
import { useCallback } from 'react';
import type { SettlementOption } from '../../useTradeSettlementRequests';
import { useCustomerSettlementDetails } from './useCustomerSettlementDetails';

interface SettlementOverviewProps {
  preview: SettlementPreview;
  settlementOption: SettlementOption;
  getSettlementInstructionsCSV: (params: ExportDataAsCsvParams) => string | undefined;
}

export const SettlementOverviewCard = ({
  preview,
  settlementOption,
  getSettlementInstructionsCSV,
}: SettlementOverviewProps) => {
  const { gridDetails } = useCustomerSettlementDetails({ preview, settlementOption });

  const getCopyButtonText = useCallback(() => {
    // Start off by converting the array of tuples [string, string][] to just an array of space-separated strings
    // [["Counterparty", "Customer 1"], ...] => ["Counterparty Customer 1", ....]
    const detailsCSVString = gridDetails.map(tuple => tuple.join(' ')).join('\n');
    const instructionsCSVString = parseInstructionsCSV(getSettlementInstructionsCSV({ skipColumnHeaders: true }));

    const parts = compact([detailsCSVString, instructionsCSVString]);
    const combinedString = parts.join('\n');
    return combinedString;
  }, [gridDetails, getSettlementInstructionsCSV]);

  return (
    <Card>
      <Card.Subtitle>
        <HStack w="100%" justifyContent="flex-start" gap="spacingDefault">
          <Text>Settlement Overview</Text>
          <CopyButton size={FormControlSizes.Tiny} value={getCopyButtonText}>
            Copy
          </CopyButton>
        </HStack>
      </Card.Subtitle>

      {/* Details */}
      <VStack w="100%" gap="spacingSmall" data-testid="settlement-overview-section">
        {gridDetails.map(([title, text], index) => (
          <SettlementDetail title={title} text={text} key={index} />
        ))}
      </VStack>
    </Card>
  );
};

/**
 * Attempts to parse the given instructionsCSV into text string we can copy to the clipboard
 *
 * "Text","Text","Text"
 *
 * "Text","Text","Text"
 *
 * becomes
 *
 * Text Text Text
 *
 * Text Text Text
 */
function parseInstructionsCSV(instructionsCSV: string | undefined): string | undefined {
  if (instructionsCSV == null) {
    return undefined;
  }

  try {
    const parsingResult = Papa.parse(instructionsCSV);
    if (parsingResult.errors.length > 0) {
      return undefined;
    }

    const data = parsingResult.data as string[][];
    return data.map(row => row.join(' ')).join('\n');
  } catch {
    return undefined;
  }
}

function SettlementDetail({ title, text }: { title: string; text: string }) {
  return (
    <HStack w="100%" justifyContent="space-between">
      <Text>{title}</Text>
      <Text color="colorTextAttention">{text}</Text>
    </HStack>
  );
}

import {
  ACTION,
  ApiPermissionActionEnum,
  MarketAccountsProvider as KyokoMarketAccountsProvider,
  LoaderWrapper,
} from '@talos/kyoko';
import { Loader } from 'components/Loader';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { isUserAuthorized } from 'hooks/useRoleAuth';
import { useUser } from 'hooks/useUser';
import type { PropsWithChildren } from 'react';

export const AvaMarketAccountsProvider = ({ children }: PropsWithChildren) => {
  const {
    isLoaded: isFeatureFlagsLoaded,
    filterMarketAccountsByPermissionActionAll,
    enableAccountSegregation,
    enableAccountSegregationShowAllMarketAccounts,
  } = useFeatureFlag();
  const user = useUser();

  if (!isFeatureFlagsLoaded) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  /** Two Jira's affect the below permission:
   * - [PORT-16073] If Account Segregation (enableAccountSegregation) is enabled, we need to filter the Market Accounts by permission action "Read".
   * - [DEAL-4518] Allow Filtering the Market Accounts subscription by providing a Permission Action.
   * If filterMarketAccountsByPermissionActionAll is true, then we will filter the market accounts by permission action "All".
   * This makes it so that Customer and Custodian Market Accounts are not returned on the MarketAccount subscription.
   * */

  // if user has Dealer permissions then don't perform any filtering (because .Read currently blocks the return of Customer accountss)
  // - Upcoming work with Dealer/EntityAdmin should reduce the need for this restriction (Jira to close it is PORT-16372) - at that time remove this and the action from the codebase
  const applyAccountSegregationPermission =
    enableAccountSegregation &&
    !enableAccountSegregationShowAllMarketAccounts &&
    !isUserAuthorized(user, ACTION.VIEW_ALL_MARKET_ACCOUNTS_IN_ACCOUNT_SEG);

  const apiPermission = applyAccountSegregationPermission
    ? ApiPermissionActionEnum.Read
    : filterMarketAccountsByPermissionActionAll
    ? ApiPermissionActionEnum.All
    : undefined;
  return <KyokoMarketAccountsProvider permissionAction={apiPermission}>{children}</KyokoMarketAccountsProvider>;
};

import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { IconName } from '../components/Icons/IconNames';

// Can't move to @talos/kyoko import due to circular dependency
// - Once Enum's are refactored we can consider fixing

/** Easy access to Expand/Collapse right click menu options */
export function getExpandCollapseMenus(params: GetContextMenuItemsParams): Array<MenuItemDef> {
  return [
    {
      icon: `<i class="ag-icon ${IconName.ListExpand}"/>`,
      name: `Expand All`,
      action: () => params.api.expandAll(),
    },
    {
      icon: `<i class="ag-icon ${IconName.ListCollapse}"/>`,
      name: `Collapse All`,
      action: () => params.api.collapseAll(),
    },
  ];
}

import { useMarketAccountsContext, useMarketsContext, type MultilegMarketDetails } from '@talos/kyoko';
import { useMemo } from 'react';

type ValueAndState = [string, ValueState];

enum ValueState {
  Added,
  Removed,
  Existing,
}

export function useValueAndState(
  markets: MultilegMarketDetails[] | undefined,
  overrideMarkets: string[] | undefined,
  showChanges: boolean | undefined
) {
  const { marketsByName } = useMarketsContext();
  const { marketAccountsByName } = useMarketAccountsContext();

  // code adapted from apps/ava/src/components/Multileg/MultilegDetails.tsx
  // the idea is MultilegDetails will no longer be necessary and can be deleted when we replace it with this component
  return useMemo<ValueAndState[]>(() => {
    if (overrideMarkets === undefined || overrideMarkets.length === 0) {
      return [];
    }

    const valueToState = new Map<string, ValueState>();
    markets?.forEach(market => {
      const state =
        overrideMarkets.find(m => m === market.Market || m === market.MarketAccount) === undefined
          ? ValueState.Removed
          : ValueState.Existing;

      if (showChanges) {
        valueToState.set(
          marketAccountsByName.get(market.MarketAccount)?.DisplayName ??
            marketsByName.get(market.Market)?.DisplayName ??
            market.Market,
          state
        );
      }
    });

    overrideMarkets.forEach(market => {
      const key = marketAccountsByName.get(market)?.DisplayName ?? marketsByName.get(market)?.DisplayName ?? market;
      if (valueToState.has(key)) {
        return;
      }
      const state =
        // If Markets===null, all markets are allowed and selected markets can be viewed as "existing"
        markets === null || !showChanges
          ? ValueState.Existing
          : markets?.find(m => m.Market === market || m.MarketAccount === market) === undefined
          ? ValueState.Added
          : ValueState.Existing;

      valueToState.set(key, state);
    });

    return [...valueToState.entries()].sort(ValueAndOverrideSortFn);
  }, [markets, marketAccountsByName, marketsByName, overrideMarkets, showChanges]);
}

function ValueAndOverrideSortFn(a: ValueAndState, b: ValueAndState) {
  if (a[1] === b[1]) {
    return a[0].localeCompare(b[0]);
  }
  return a[1] - b[1];
}

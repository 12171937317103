import {
  FilterClauseType,
  removeEmptyFilters,
  useCurrenciesFilter,
  type CustomerTransaction,
  type FilterClause,
  type FilterableProperty,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { compact, isArray, isEqual, keys } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useCounterpartiesFilter, useFeatureFlag } from '../../../hooks';
import type { CustomerBalancesFilter } from './types';

export interface UseCustomerBalancesFilterParams<CustomerTransaction> {
  persistedBlotterTable: UsePersistedBlotterTable<CustomerTransaction>;
}
export function useCustomerBalancesFilter<CustomerTransaction>({
  persistedBlotterTable,
}: UseCustomerBalancesFilterParams<CustomerTransaction>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [filter, setFilter] = useState<CustomerBalancesFilter>(
    (persistedBlotterTable.initialFilter as CustomerBalancesFilter) || {}
  );

  const changeFilter = useCallback(
    (action: SetStateAction<CustomerBalancesFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const currenciesFilter = useCurrenciesFilter();

  const counterpartiesFilter = useCounterpartiesFilter();
  const { requireCustomerBalancesCounterpartyFilter } = useFeatureFlag();

  const filterableProperties: FilterableProperty<keyof CustomerBalancesFilter>[] = useMemo(
    () => [currenciesFilter, { ...counterpartiesFilter, alwaysPresent: requireCustomerBalancesCounterpartyFilter }],
    [currenciesFilter, counterpartiesFilter, requireCustomerBalancesCounterpartyFilter]
  );

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters<CustomerBalancesFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies CustomerBalancesFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const initialFilterClauses = useMemo(() => {
    type FilterKeys = keyof CustomerBalancesFilter;
    const filteredKeys = keys(filter) as FilterKeys[];
    const clauses: FilterClause<FilterKeys>[] = [];
    filteredKeys.forEach(key => {
      clauses.push({
        key: key,
        type: FilterClauseType.INCLUSIVE,
        selections: (isArray(filter[key]) ? filter[key] : compact([filter[key]])) as string[],
      });
    });
    return clauses;
  }, [filter]);

  const filterBuilderProps = useMemo(
    () =>
      ({
        initialFilterClauses,
        properties: filterableProperties,
        onFilterClausesChanged: handleFilterClausesChanged,
      } satisfies UseFilterBuilderProps),
    [initialFilterClauses, filterableProperties, handleFilterClausesChanged]
  );

  return {
    initialFilter: persistedBlotterTable.initialFilter,
    filter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
  };
}

const colIDToFilterBuilderKeyMap = {
  Counterparty: 'Counterparties',
  Currency: 'Currencies',
} satisfies Partial<Record<keyof CustomerTransaction, keyof CustomerBalancesFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof CustomerBalancesFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}

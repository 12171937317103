import {
  type InputsAndDropdownsDrawerOption,
  type Security,
  type SymbolGroup,
  getSecurityDrawerOptions,
  getTierDrawerOptions,
} from '@talos/kyoko';

export function getSymbolGroupsPostPath(entity: SymbolGroup): string {
  return entity.Symbol == null ? `/symbol-groups` : `/symbol-groups/${encodeURIComponent(entity.Tier)}/symbols`;
}

export function getSymbolGroupsPatchDeletePath(entity: SymbolGroup): string {
  return entity.Symbol == null
    ? `/symbol-groups/${encodeURIComponent(entity.Tier)}`
    : `/symbol-groups/${encodeURIComponent(entity.Tier)}/symbols/${entity.Symbol}`;
}

export function getSymbolGroupsDrawerOptions(): InputsAndDropdownsDrawerOption<SymbolGroup>[] {
  return [
    {
      field: 'Tier',
      type: 'input',
      getIsRequired: () => true,
      disabledWhenEditing: true,
      title: 'Name',
    },
  ];
}

export function getSymbolGroupsOverridesDrawerOptions(
  symbolGroups: SymbolGroup[],
  filteredSecurities: Security[]
): InputsAndDropdownsDrawerOption<SymbolGroup>[] {
  return [
    {
      field: 'Tier',
      type: 'dropdown',
      getIsRequired: () => true,
      disabledWhenEditing: true,
      title: 'Group Name',
      getDropdownOptions: () => getTierDrawerOptions(symbolGroups),
      placeholder: 'Select Group...',
    },
    {
      field: 'Symbol',
      type: 'dropdown',
      disabledWhenEditing: true,
      getIsRequired: () => true,
      placeholder: 'Select Symbol...',
      getDropdownOptions: () => getSecurityDrawerOptions(filteredSecurities),
    },
  ];
}

export const Logo = ({ fill }: React.SVGProps<SVGPathElement>) => (
  <>
    <path d="M132 159.378L152.924 128L189 182H147.152L132 159.378Z" fill={fill} />
    <path
      d="M109.909 182L147 126.541L112.091 74H69.1818L104.818 126.541L67 182H109.909Z"
      fillOpacity="0.6"
      fill={fill}
    />
    <path d="M132 93.6714L153.091 125L188 74H145.091L132 93.6714Z" fill={fill} />
  </>
);

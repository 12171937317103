import { isString } from 'lodash-es';
import type { CSSProperties } from 'react';
import styled, { type DefaultTheme } from 'styled-components';
import type { KeysBeginningWith } from '../../utils/types';
import { Box } from '../Core';

function lineHeightFor(
  theme: DefaultTheme,
  fontSize: number | KeysBeginningWith<DefaultTheme, 'fontSize'> | undefined
): CSSProperties['lineHeight'] | undefined {
  if (!isString(fontSize)) {
    return undefined;
  }
  if (!fontSize.startsWith('fontSize')) {
    return undefined;
  }
  const lineHeightProp = fontSize.replace('fontSize', 'lineHeight') as keyof DefaultTheme;
  return lineHeightProp in theme ? `line-height: ${theme[lineHeightProp]}rem` : undefined;
}

export const Text = styled(Box).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})<{
  size?: number | KeysBeginningWith<DefaultTheme, 'fontSize'>;
  weight?: CSSProperties['fontWeight'];
  transform?: CSSProperties['textTransform'];
  fontStyle?: CSSProperties['fontStyle'];
}>`
  ${({ theme, size }) => size != null && `font-size: ${theme[size as keyof DefaultTheme] ?? size}rem`};
  ${({ theme, size }) => lineHeightFor(theme, size)};
  ${({ transform }) => transform != null && `text-transform: ${transform}`};
  ${({ theme, weight }) => weight != null && `font-weight: ${theme[weight as keyof DefaultTheme] ?? weight}`};
  ${({ fontStyle }) => fontStyle != null && `font-style: ${fontStyle}`};
`;

// TODO TS doesn't seem to think the `as` prop exists, see https://stackoverflow.com/q/69430472
(Text as any).defaultProps = {
  as: 'span',
};

// From https://stackoverflow.com/a/78053761
import { lazy, type ComponentType, type LazyExoticComponent } from 'react';

type PrefetchableComponent<T extends ComponentType<any>> = LazyExoticComponent<T> & {
  prefetch: () => Promise<void>;
};

function lazyWithPrefetch<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): PrefetchableComponent<T> {
  const Component: Partial<PrefetchableComponent<T>> = lazy(factory);

  Component.prefetch = async () => {
    await factory();
  };

  return Component as PrefetchableComponent<T>;
}

export default lazyWithPrefetch;

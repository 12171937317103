import {
  getAllChildTreesFromSubAccount,
  getSubAccountsMappingToMarketAccount,
  type MarketAccountApiPermissionFilter,
  type RollupMembership,
  type SubAccount,
} from '@talos/kyoko';
import { compact } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useMarketAccountPermissionFilters, useSubAccountRollupMemberships, useSubAccounts } from '../providers';

/**
 * This function will attempt to calculate a path between the provided market account name and sub account id. It will
 * traverse between sub accounts using the provided rollup memberships data set, and between market account <-> book using
 * the market account permissions.
 *
 * There can be more than one path between a given sub account and market account. This function will return the first valid path
 * it finds, which is arbitrary.
 *
 * If no path is found, undefined will be returned.
 */
export function getPathFromMarketAccountToSubAccount({
  marketAccountName,
  subAccountID,
  rollupMembershipsByParentChild,
  marketAccountPermissions,
  subAccountsByID,
}: {
  marketAccountName: string;
  subAccountID: number;
  rollupMembershipsByParentChild: Map<number, Map<number, RollupMembership>>;
  marketAccountPermissions: MarketAccountApiPermissionFilter[];
  subAccountsByID: Map<number, SubAccount>;
}) {
  const branches = getAllChildTreesFromSubAccount(subAccountID, rollupMembershipsByParentChild);
  const subAccountsMappedToMarketAccount = getSubAccountsMappingToMarketAccount(
    marketAccountName,
    marketAccountPermissions
  );

  // A "compatible branch" is a branch which leaf node (book) is mapped to the provided market account
  const compatibleBranch = branches.find(branch => {
    const leafID = branch.at(-1)!; // every branch must have a leaf
    const leafName = subAccountsByID.get(leafID)?.Name;
    return leafName != null && subAccountsMappedToMarketAccount.has(leafName);
  });

  if (!compatibleBranch) {
    return undefined;
  }

  const branchNames = compact(compatibleBranch.map(id => subAccountsByID.get(id)?.Name));
  return {
    subAccountPath: branchNames,
    marketAccount: marketAccountName,
  };
}

/**
 *  A wrapper for `getPathFromMarketAccountToSubAccount`, providing the data sets for you.
 *
 * The returned function is unstable. For some use cases where performance is a problem,
 * you might want to wrap the returned function in `useCallbackRef`.
 */
export const useGetPathFromMarketAccountToSubAccount = () => {
  const { subAccountsByID } = useSubAccounts();
  const { rollupMembershipsByParentChild } = useSubAccountRollupMemberships();

  const { marketAccountPermissionFiltersByFilterID } = useMarketAccountPermissionFilters();
  const marketAccountPermissions = useMemo(() => {
    if (!marketAccountPermissionFiltersByFilterID) {
      return [];
    }

    return [...marketAccountPermissionFiltersByFilterID.values()];
  }, [marketAccountPermissionFiltersByFilterID]);

  return useCallback(
    (marketAccountName: string, subAccountID: number) => {
      return getPathFromMarketAccountToSubAccount({
        marketAccountName,
        subAccountID,
        marketAccountPermissions,
        subAccountsByID,
        rollupMembershipsByParentChild,
      });
    },
    [marketAccountPermissions, subAccountsByID, rollupMembershipsByParentChild]
  );
};

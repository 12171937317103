import { useMarketAccountsContext } from '../../contexts';
import type { SideEnum } from '../../types';
import { Alert, AlertVariants } from '../Alert';
import { Flex, HStack, VStack } from '../Core';
import { Divider } from '../Divider';
import { InlineFormattedNumber, NumberVariants } from '../FormattedNumber';
import { Icon, IconName, type IconProps } from '../Icons';
import { Text } from '../Text';
import { Tooltip, type TooltipProps } from '../Tooltip';
import type { BuyingPowerWarning } from './useBuyingPowerWarnings';

interface BuyingPowerWarningTooltipProps {
  warnings: BuyingPowerWarning[];
  totalWarning?: BuyingPowerWarning;

  currency: string;
  side: SideEnum;

  tooltipProps?: Partial<Omit<TooltipProps, 'tooltip'>>;
  iconProps?: Partial<IconProps>;
}

export const BuyingPowerWarningTooltip = ({
  warnings,
  totalWarning,
  currency,
  side,
  tooltipProps,
  iconProps,
}: BuyingPowerWarningTooltipProps) => {
  const { marketAccountsByName } = useMarketAccountsContext();

  const plural = warnings.length > 1;

  return (
    <Tooltip
      tooltip={
        <VStack
          w="100%"
          p="spacingDefault"
          fontSize="fontSizeDefault"
          gap="spacingComfortable"
          data-testid="buying-power-warning-tooltip"
        >
          <Alert variant={AlertVariants.Warning} dismissable={false}>
            {/* Change the text a bit depending on what warnings we are displaying */}
            {totalWarning
              ? `Order Qty exceeds total Max ${side} for the selected Market Accounts`
              : plural
              ? `Order Qty exceeds Max ${side} for some selected Market Accounts`
              : `Order Qty exceeds Max ${side} for the selected Market Account`}
          </Alert>

          <Flex w="100%" flexDirection="column" gap="spacingSmall">
            <HStack justifyContent="space-between" color="colorTextSubtle" fontSize="fontSizeSm" fontWeight={500}>
              <Text>Market Account</Text>
              <Text>Exceeds By</Text>
            </HStack>

            {warnings.map(({ account, excess }) => (
              <HStack key={account} justifyContent="space-between">
                <Text color="colorTextAttention">{marketAccountsByName.get(account)?.DisplayName ?? account}</Text>
                <InlineFormattedNumber
                  number={excess}
                  currency={currency}
                  align="right"
                  variant={NumberVariants.Warning}
                />
              </HStack>
            ))}

            {totalWarning && (
              <>
                <Divider orientation="horizontal" my="spacingSmall" />
                <HStack justifyContent="space-between">
                  <Text color="colorTextAttention">Total</Text>
                  <InlineFormattedNumber
                    number={totalWarning.excess}
                    currency={currency}
                    align="right"
                    variant={NumberVariants.Warning}
                  />
                </HStack>
              </>
            )}
          </Flex>
        </VStack>
      }
      {...tooltipProps}
    >
      <Icon
        icon={IconName.ExclamationSolid}
        color="colorTextWarning"
        data-testid={`buying-power-warning-${side}-icon`}
        {...iconProps}
      />
    </Tooltip>
  );
};

export const Logo = ({ fill }: React.SVGProps<SVGPathElement>) => (
  <g transform="translate(37.500000, 73.000000)" fill={fill} fillRule="nonzero">
    <path
      d="M61.0062035,0 L127.5,0 C127.460328,0.878686187 127.156346,1.72500598 126.627747,2.42845462 C124.376772,6.28475061 122.350895,10.2984464 120.139312,14.1547424 C116.987948,19.6749796 114.08419,25.3301308 110.904688,30.8447465 C108.890066,34.813471 106.616581,38.6585241 104.4669,42.5597915 L65.7051134,42.5597915 C65.029821,42.5597915 64.360156,42.59352 63.6848635,42.63287 C63.1221198,44.1169256 62.1879653,45.4435814 61.4338887,46.8095871 C60.4490872,48.4060711 59.6499911,50.1093622 58.6764445,51.7058463 C57.6466235,53.8532298 56.222882,55.7757563 55.1874335,57.8894113 C53.0490074,61.5152289 51.0512673,65.1972608 48.9578607,68.8624285 C46.7744151,72.6100436 44.6397406,76.4101254 42.5538373,80.2626738 C41.6703297,81.6286795 40.8656062,83.0396566 40.117157,84.4843622 C35.7446384,92.1126329 31.5128057,99.7802535 27.1346597,107.369174 C26.762811,108.185277 26.2736948,108.942786 25.6827809,109.617743 C18.1982896,109.56715 10.7137983,109.617743 3.22930698,109.617743 C2.30744621,109.634888 1.39103609,109.763237 0.5,110 C0.5,107.931316 1.88434952,106.278618 2.81850408,104.558463 C3.94399149,102.225572 5.44651719,100.061325 6.57763205,97.7228128 C15.5477667,81.6905151 24.422235,65.6020033 33.3642325,49.5584628 C35.3901099,46.0731807 37.1289879,42.5148201 39.1661202,39.0969951 C41.7097217,34.5998569 44.0788727,30.0015331 46.7293956,25.5549877 C47.5735112,23.6942968 48.7102535,21.9910057 49.6387806,20.1752862 C52.9364587,14.3121423 56.172235,8.41526983 59.4586583,2.55212592 C59.8462275,1.63010941 60.3676754,0.77016644 61.0062035,0 Z"
      fillOpacity="0.8"
    />
    <path
      d="M138.204494,23.1864773 C142.638592,15.4744777 146.920567,7.67834723 151.416641,0 L175.463311,0 C175.813673,0.0176386573 176.161173,0.072160326 176.5,0.162653082 C175.699947,1.36852938 175.02948,2.64731913 174.325207,3.90367397 C168.52576,14.2648623 162.743216,24.6316593 156.977574,35.004065 C154.554878,39.2274365 152.301207,43.5461562 149.844705,47.7470927 L149.602436,47.8312236 L149.557362,48.358444 C138.251446,68.5498611 126.98309,88.7562348 115.752296,108.977565 C93.6775875,108.992522 71.5934889,109 49.5,109 C50.7564216,106.386333 52.2438445,103.89045 53.6411206,101.349696 C59.9513997,89.812545 66.4025332,78.4043944 72.6001287,66.8952866 C86.1597164,66.8728517 99.7230602,66.8728517 113.29016,66.8952866 C121.668182,52.3518576 129.865911,37.7299063 138.181957,23.1864773 L138.204494,23.1864773 Z"
      id="Path"
    />
  </g>
);

import type { RequestStream } from '@talos/kyoko';
import {
  CARE_ORDER,
  ORDER,
  QUOTE,
  useSubscription,
  wsScanToMap,
  type CareOrder,
  type MinimalSubscriptionResponse,
  type Order,
  type Quote,
} from '@talos/kyoko';
import Big from 'big.js';
import { minBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { combineLatest, map, shareReplay, type Observable } from 'rxjs';
import {
  createCareOrderBlotterEntity,
  type CareOrderBlotterEntity,
  type OrderWithParentOrderID,
  type QuoteRow,
  type QuoteWithParentRFQID,
} from './types';

export const useCareOrderBlotterDataObs = () => {
  const { data: careOrdersObs } = useSubscription<CareOrder>({
    name: CARE_ORDER,
    tag: 'CareOrder blotter',
  });

  // First we need to get all care orders by ID...
  const careOrdersByIDObs = useMemo(
    () =>
      careOrdersObs.pipe(
        wsScanToMap({
          getUniqueKey: order => order.OrderID,
          newMapEachUpdate: false,
          waitForAllPagesToLoad: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [careOrdersObs]
  );

  const [quotesRequest, setQuotesRequest] = useState<RequestStream | null>(null);
  const { data: quotesObs } = useSubscription<Quote>(quotesRequest);

  const [ordersRequest, setOrdersRequest] = useState<RequestStream | null>(null);
  const { data: ordersObs } = useSubscription<Order>(ordersRequest);

  useEffect(() => {
    const subscription = careOrdersByIDObs.subscribe(careOrdersByID => {
      const startDate = minBy(Array.from(careOrdersByID.values()), 'SubmitTime')?.SubmitTime;
      setQuotesRequest({
        name: QUOTE,
        tag: 'CareOrder blotter',
        StartDate: startDate,
      });
      setOrdersRequest({
        name: ORDER,
        tag: 'CareOrder blotter',
        StartDate: startDate,
      });
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [careOrdersByIDObs]);

  const quotesByRFQIDObs = useMemo(
    () => quotesObs.pipe(wsScanToMap({ getUniqueKey: d => d.RFQID, newMapEachUpdate: false })),
    [quotesObs]
  );
  const ordersByOrderIDObs = useMemo(
    () => ordersObs.pipe(wsScanToMap({ getUniqueKey: d => d.OrderID, newMapEachUpdate: false })),
    [ordersObs]
  );

  // Finally, merge the care orders and the OMS orders
  return useMemo<Observable<MinimalSubscriptionResponse<CareOrderBlotterEntity>>>(
    () =>
      combineLatest([careOrdersByIDObs, quotesByRFQIDObs, ordersByOrderIDObs]).pipe(
        map(([careOrdersByID, quotesByRFQID, ordersByOrderID]) => {
          const blotterEntitiesByID = new Map();

          // The order of loops here is important!

          // First, we add all care orders to the map
          for (const careOrder of careOrdersByID.values()) {
            blotterEntitiesByID.set(careOrder.OrderID, createCareOrderBlotterEntity(careOrder));
          }

          // Then, we add quotes that are children of any care order
          for (const quote of quotesByRFQID.values()) {
            if (quote.ParentRFQID != null && blotterEntitiesByID.has(quote.ParentRFQID)) {
              blotterEntitiesByID.set(quote.RFQID, createCareOrderBlotterEntity(quote as QuoteWithParentRFQID));

              // TODO fhqvst Also manually compute the WorkingQty.
              //
              // This is a temporary solution until the backend provides the WorkingQty directly.
              // The solution in streamingDataSlice doesn't work for us here, since we're listing out
              // _all_ care orders and not just a single one.
              //
              // Again, this code is all temporary and should go away soon enough.
              const careOrder = blotterEntitiesByID.get(quote.ParentRFQID) as CareOrder;
              if (!quote.isTerminal && quote.OrderQty != null) {
                careOrder.WorkingQty = Big(careOrder.WorkingQty ?? 0)
                  .plus(quote.OrderQty)
                  .toFixed();
              }
            }
          }

          // Then, we add orders that are children of a care order (and possibly also a quote for RFQ orders)
          for (const order of ordersByOrderID.values()) {
            // Check whether this order belongs to a quote
            if (order.RFQID != null) {
              if (!blotterEntitiesByID.has(order.RFQID)) {
                // If we got here, the RFQ doesn't exist in the UI yet for some reason.
                // and the order row will not be visible until we receive that RFQ.
                continue;
              }
              const quoteRow = blotterEntitiesByID.get(order.RFQID) as QuoteRow;
              blotterEntitiesByID.set(quoteRow.RFQID, quoteRow!.enrichWith(order as OrderWithParentOrderID));
              continue;
            }

            if (order.ParentOrderID != null && blotterEntitiesByID.has(order.ParentOrderID)) {
              blotterEntitiesByID.set(order.OrderID, createCareOrderBlotterEntity(order as OrderWithParentOrderID));
            }
          }

          return {
            initial: false,
            data: Array.from(blotterEntitiesByID.values()),
          };
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [careOrdersByIDObs, quotesByRFQIDObs, ordersByOrderIDObs]
  );
};

import { DEFAULT_LAYOUT_SPEC } from 'components/AppLayout/Layouts/DefaultLayoutSpec';
import type { SerializedDockview } from 'dockview';
import { v1 as uuid } from 'uuid';
import { DEFAULT_GRID, WATCHLIST_PANELS } from '../../components/AppLayout/Layouts/Default';
import { BLOTTER_PANELS } from '../../components/AppLayout/Layouts/shared';
import type { LayoutSpec, TypedGroupviewPanelConfigState } from '../../types/LayoutConfig';
import type { MarketTab } from '../MarketTabs.types';
import { mapTabToPanel } from './mapTabToPanel';

export const convertMarketTabsToDockviewLayout = (marketTabs: MarketTab[]): LayoutSpec => {
  const dockViewLayout: SerializedDockview = {
    grid: structuredClone<SerializedDockview['grid']>(DEFAULT_GRID),
    panels: {
      ...WATCHLIST_PANELS,
      ...BLOTTER_PANELS,
      ...marketTabs.reduce((acc: Record<string, TypedGroupviewPanelConfigState>, tab) => {
        tab.id = tab.id ?? uuid();
        acc[tab.id] = mapTabToPanel(tab);
        return acc;
      }, {}),
    },
  };
  if (
    Array.isArray(dockViewLayout.grid.root.data) &&
    Array.isArray(dockViewLayout.grid.root.data[0].data) &&
    !Array.isArray(dockViewLayout.grid.root.data[0].data[1].data)
  ) {
    dockViewLayout.grid.root.data[0].data[1].data.views = marketTabs.map(tab => tab.id);
  }

  return {
    ...DEFAULT_LAYOUT_SPEC,
    dockViewLayout,
  };
};

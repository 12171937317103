import type { PropsWithChildren } from 'react';
import styled, { useTheme } from 'styled-components';
import { modeColor } from '../../styles';
import { Box, type BoxProps } from '../Core/Box';
import { VStack } from '../Core/VStack';
import { NotificationAppInboxToast, type NotificationAppInboxToastProps } from './NotificationAppInboxToast';
import { NotificationAppInboxWrapper } from './styles';

export function AppInboxToast({
  onRemove,
  onClick,
  wrapperWidth, // Default width for the wrapper
  ...toast
}: NotificationAppInboxToastProps & Pick<BoxProps, 'onClick'>) {
  return (
    <ToastWrapper data-testid="toast-wrapper" w={wrapperWidth}>
      <NotificationAppInboxToast onRemove={onRemove} {...toast} />
    </ToastWrapper>
  );
}

const ToastWrapper = styled(Box)`
  box-shadow: 0 5px 40px ${({ theme }) => modeColor(theme, 'hsla(0, 0%, 0%, 0.1)', theme.colors.black.dim)};
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  div {
    word-break: break-word;
  }
`;

export function ToastSimple({ children, ...boxProps }: PropsWithChildren & BoxProps) {
  const theme = useTheme();
  return (
    <NotificationAppInboxWrapper size="Default" variant="DEFAULT" cursor="pointer" {...boxProps}>
      <VStack gap="spacing4" alignItems="center" w="100%" fontSize={theme.fontSizeSm} lineHeight="16px">
        {children}
      </VStack>
    </NotificationAppInboxWrapper>
  );
}

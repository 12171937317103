import { createSlice, freeze, type PayloadAction } from '@reduxjs/toolkit';
import { CustomerOrderStrategiesEnum } from '../../../../tokens';
import {
  OrderFormSides,
  SideEnum,
  WLParameterKeysEnum,
  type CustomerMarketAccount,
  type CustomerOrder,
  type CustomerQuote,
  type WLOrderStrategy,
} from '../../../../types';
import { EMPTY_ARRAY, isKeyIn, toBigWithDefault } from '../../../../utils';
import type { OMSReferenceDataState } from '../OMSReferenceDataSlice/types';
import type { CleanStatePayload, OrderFormDependencies, OrderState, OrderStepAction } from './types';
import {
  cleanFormState,
  getInitialState,
  handleCleanMarketAccount,
  handleStrategyChange,
  handleSymbolChange,
  handleViewTypeChange,
  initializeFieldsFromOrder,
  setFieldsDisabled,
  setTouchedValueForAllOrderFields,
  validateForm,
  validateStrategyParams,
} from './utils';
import { validateQuantity } from './validation';

export const orderSlice = createSlice({
  name: 'orders',
  initialState: getInitialState(),
  reducers: {
    setReferenceData: (state, action: PayloadAction<OMSReferenceDataState>) => {
      state.referenceData = freeze(action.payload);

      state.form.symbolField = state.form.symbolField.updateAvailableItems(action.payload.securities.securitiesList);
      if (state.form.symbolField.value) {
        const strategiesList =
          action.payload.strategiesBySymbol.get(state.form.symbolField.value.Symbol) || EMPTY_ARRAY;
        state.form.strategyField = state.form.strategyField.updateAvailableItems(strategiesList);
      }

      state.form.marketAccountField = state.form.marketAccountField.updateAvailableItems(
        action.payload.marketAccounts.customerMarketAccountsList
      );
      if (state.form.marketAccountField.availableItems.length > 0 && state.form.symbolField.availableItems.length > 0) {
        handleCleanMarketAccount(state, true);
        state.initialized = true;
      }
    },
    setSide: (state, action: PayloadAction<OrderFormSides>) => {
      state.form.sideField = state.form.sideField.updateValue(action.payload);
    },
    setQuantity: (state, action: PayloadAction<string | undefined>) => {
      state.form.quantityField = state.form.quantityField.updateValue(action.payload);
      validateQuantity(state);
    },
    setLimitPrice: (state, action: PayloadAction<string | undefined>) => {
      if (state.form.parameters?.['LimitPrice']) {
        state.form.parameters['LimitPrice'] = state.form.parameters['LimitPrice'].updateValue(action.payload);
      }
    },
    setOrderCurrency: (state, action: PayloadAction<string>) => {
      state.form.orderCurrencyField = state.form.orderCurrencyField.updateValue(action.payload);
      state.form.quantityField = state.form.quantityField.updateValue(undefined, false);

      // Since we change the currency, there are parameters that might need clearing out
      if (state.form.parameters[WLParameterKeysEnum.ClipSize]) {
        state.form.parameters[WLParameterKeysEnum.ClipSize] = state.form.parameters[
          WLParameterKeysEnum.ClipSize
        ].updateValue(undefined, true);
      }
    },
    setStrategy: (state, action: PayloadAction<WLOrderStrategy | undefined>) => {
      state.form.strategyField = state.form.strategyField.updateValue(action.payload);
      handleStrategyChange(state, state.form.strategyField.value);
    },

    modifyOrder: (state, action: PayloadAction<CustomerOrder>) => {
      state.isOpen = true;
      handleViewTypeChange(state, 'order');
      cleanFormState(state);
      state.focusedOrderID = null;
      state.orderBeingModified = action.payload;
      initializeFieldsFromOrder(state, action.payload, true);
      setTouchedValueForAllOrderFields(state, true);
      validateForm(state);
    },

    setOpenForm: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },

    resubmitOrderForm: (state, action: PayloadAction<CustomerOrder>) => {
      state.isOpen = true;
      state.orderStep = 'idle';
      handleViewTypeChange(state, 'order');
      cleanFormState(state);
      state.focusedOrderID = null;
      initializeFieldsFromOrder(state, action.payload, false);
      setTouchedValueForAllOrderFields(state, true);
      validateForm(state);
    },

    primeOrderForm: (
      state,
      action: PayloadAction<
        Partial<Pick<CustomerOrder, 'Symbol' | 'Side' | 'MarketAccount' | 'Strategy' | 'Price' | 'OrderQty'>>
      >
    ) => {
      switch (state.form.viewType.value) {
        case 'rfq':
          cleanFormState(state);
      }
      setFieldsDisabled(state, false);
      state.orderBeingModified = undefined;
      state.orderStep = 'idle';
      state.focusedOrderID = null;
      state.quote = null;
      state.quoteReqID = null;

      state.isOpen = true;
      handleViewTypeChange(state, 'order');

      const { MarketAccount, OrderQty, Side, Symbol, Price, Strategy: primedStrategy, ...rest } = action.payload;
      const _typecheck: EmptyObject = rest;

      // If missing symbol, return
      if (!Symbol) {
        return;
      } else if (Symbol !== state.form.symbolField.value?.Symbol) {
        cleanFormState(state);
        handleSymbolChange(state, Symbol);
      }

      const currentStrategySupportsLimitPrice = state.form.strategyField.value?.Parameters.find(
        p => p.Name === 'LimitPrice'
      );
      let Strategy = primedStrategy;
      if (!Strategy) {
        if (currentStrategySupportsLimitPrice) {
          Strategy = state.form.strategyField.value?.Name;
        } else {
          Strategy = Price ? CustomerOrderStrategiesEnum.Limit : CustomerOrderStrategiesEnum.Market;
        }
      }
      const primePayloadStrategy = state.form.strategyField.availableItems.find(s => s.Name === Strategy);
      const isPrimedStrategyDifferentFromCurrent = state.form.strategyField.value?.Name !== primePayloadStrategy?.Name;
      if (isPrimedStrategyDifferentFromCurrent) {
        handleStrategyChange(state, primePayloadStrategy);
      }

      if (Side === SideEnum.Sell) {
        state.form.sideField = state.form.sideField.updateValue(OrderFormSides.Sell);
      } else if (Side === SideEnum.Buy) {
        state.form.sideField = state.form.sideField.updateValue(OrderFormSides.Buy);
      }

      if (MarketAccount) {
        state.form.marketAccountField = state.form.marketAccountField.updateValueFromID(MarketAccount);
      }

      if (Price && state.form.parameters['LimitPrice']) {
        // A price was provided, let us put it on the LimitPx if supported
        state.form.parameters['LimitPrice'] = state.form.parameters['LimitPrice'].updateValue(Price);
      }

      if (OrderQty) {
        state.form.quantityField = state.form.quantityField.updateValue(toBigWithDefault(OrderQty, 0).toFixed());
      }

      setTouchedValueForAllOrderFields(state, false);
    },

    setMarketAccount: (state, action: PayloadAction<CustomerMarketAccount | undefined>) => {
      state.form.marketAccountField = state.form.marketAccountField.updateValue(action.payload);
      validateQuantity(state);
    },
    setStrategyParams: (state, { payload }: PayloadAction<{ key: string; value?: any }>) => {
      const parameterField = state.form.parameters[payload.key];
      if (parameterField) {
        state.form.parameters[payload.key] = parameterField.updateValue(payload.value);
        validateStrategyParams(state);
      }
    },
    triggerFormValidation: (state, action: PayloadAction<{ touchAll?: boolean }>) => {
      if (action.payload.touchAll) {
        setTouchedValueForAllOrderFields(state, true);
      }
      validateForm(state);
    },
    /**
     * This action is used to reset the state of the order form, not the whole state.
     * Probably want to consider using cleanState instead.
     */
    resetState: state => {
      const newState = getInitialState();
      state.form = newState.form;
      state.initialized = true;
    },
    cleanState: (state, action: PayloadAction<CleanStatePayload | undefined>) => {
      cleanFormState(state, action?.payload);
    },

    setOrderStep: (state, action: PayloadAction<OrderStepAction>) => {
      state.orderStep = action.payload.step;
      if ('orderID' in action.payload && action.payload.orderID) {
        state.focusedOrderID = action.payload.orderID;
      }

      if (action.payload.step === 'loading' || action.payload.step === 'preview') {
        return;
      }

      state.error = null;
      if (action.payload.step === 'rfqRequested') {
        state.quoteReqID = action.payload.quoteReqID;
        setFieldsDisabled(state, true);
        return;
      }
      if (action.payload.step === 'rfq_cancelled' || action.payload.step === 'rfq_expired') {
        state.quoteReqID = null;
      }
      if (action.payload.step === 'rfq_cancelled') {
        state.quote = null;
      }
    },

    setFocusedOrderID: (state, action: PayloadAction<string | null>) => {
      state.focusedOrderID = action.payload;
    },
    setError: (state, action: PayloadAction<OrderState['error']>) => {
      state.error = action.payload;
    },
    setSymbol: (state, action: PayloadAction<string | undefined>) => {
      handleSymbolChange(state, action.payload);
      state.isOpen = true;
    },
    touchAll: state => {
      setTouchedValueForAllOrderFields(state, true);
    },
    touchField: (state, action: PayloadAction<string>) => {
      const path = action.payload;
      // figure out if parameter or top level field
      if (path in state.form.parameters) {
        state.form.parameters[path] = state.form.parameters[path].setTouched(true);
      } else if (isKeyIn(path, state.form) && path !== 'parameters') {
        const maybeField = state.form[path];
        const newField = maybeField.setTouched(true);
        (state.form[path] as typeof newField) = newField;
      }
    },
    setDependencies: (state, action: PayloadAction<Partial<OrderFormDependencies>>) => {
      const prevDependencies = state.dependencies;
      state.dependencies = { ...prevDependencies, ...action.payload };
      // Need to validate quantity again since it depends on dependencies.
      validateQuantity(state);
    },
    closeOrderForm: state => {
      state.isOpen = false;
      state.error = null;
      // The order form gets closed, and the state should get reset. And it will, by the order form closing
      // and dispatching the resetState action, please view the `onDidDismiss` on the Order Form.
      // This is to prevent a blank form when closing the modal.
    },

    /**
     * The content of this function, should be moved to an util func, since
     * the same logic is expected to run whenever the viewType is needed to change.
     */
    setViewType: (state, action: PayloadAction<'order' | 'rfq'>) => {
      handleViewTypeChange(state, action.payload);
    },
    unsetQuoteReqID: state => {
      state.quoteReqID = null;
      state.quote = null;
    },
    setQuote: (state, action: PayloadAction<CustomerQuote | null>) => {
      state.quote = action.payload;
    },
    cleanRFQ: state => {
      if (state.form.viewType.value === 'rfq') {
        // After an RFQ is done, we need to clean the form
        // based on the orderStep, we decide if we reset values, or not
        if (state.orderStep !== 'rfq_expired') {
          state.form.quantityField = state.form.quantityField.updateValue(undefined).setTouched(false);
          handleCleanMarketAccount(state);
        }
        setFieldsDisabled(state, false);
        handleViewTypeChange(state, 'rfq');
      }
      state.quote = null;
      state.orderStep = 'idle';
      state.quoteReqID = null;
    },
  },
});

export const {
  setReferenceData,
  setSide,
  setQuantity,
  setOrderCurrency,
  setLimitPrice,
  setStrategy,
  setMarketAccount,
  modifyOrder,
  primeOrderForm,
  setOpenForm,
  resubmitOrderForm,
  resetState,
  setStrategyParams,
  setSymbol,
  cleanState,
  setDependencies,
  setOrderStep,
  setError,
  closeOrderForm,
  triggerFormValidation,
  touchAll,
  touchField,
  setFocusedOrderID,
  setViewType,
  unsetQuoteReqID,
  setQuote,
  cleanRFQ,
} = orderSlice.actions;

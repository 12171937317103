import {
  Button,
  FormControlSizes,
  HamburgerMenu,
  MixpanelEvent,
  PricingModeEnum,
  QuoteStatusEnum,
  filterByCellValueMenuItem,
  getRowNodesToOperateOn,
  getShowJSONContextItem,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  type Column,
  type ColumnDef,
  type CustomerQuote,
  type FilterableProperty,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, ICellRendererParams, MenuItemDef } from 'ag-grid-community';
import { OMSView } from 'components/OMS/OMSView';
import { compact, isNil } from 'lodash-es';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useMemo, useRef, type ReactNode } from 'react';
import { prime } from '../../../components/OMS/ManualRFQPricingView/ManualRFQPricingSlice';
import { openView } from '../../../components/OMS/OMSSlice';
import { useFeatureFlag } from '../../../hooks';
import { useCopyCustomerOrderTextMenuItem } from '../CustomerOrders/useCopyCustomerOrderTextMenuItem';
import { colIDToFilterBuilderKey } from './useCustomerQuotesFilter';

function isSendQuoteEnabled(data?: CustomerQuote) {
  if (data == null) {
    return false;
  }
  const isOpen = [QuoteStatusEnum.PendingNew, QuoteStatusEnum.Open].includes(data.QuoteStatus);
  return data.PricingMode === PricingModeEnum.SpreadToFixing && isOpen;
}

function CustomerQuotesMenu({
  data,
  onShowJson,
  onSendQuote,
  ...params
}: ICellRendererParams & {
  data: CustomerQuote;
  onSendQuote?(data: CustomerQuote): void;
  onShowJson(data: CustomerQuote): void;
}) {
  return (
    <>
      {onSendQuote && (
        <Button
          disabled={!isSendQuoteEnabled(data)}
          size={FormControlSizes.Small}
          width="100%"
          ghost={true}
          onClick={() => onSendQuote(data)}
        >
          Send Quote
        </Button>
      )}
      <HamburgerMenu {...params} onShowJson={onShowJson} data={data} />
    </>
  );
}

export function useCustomerQuotesMenu({
  openClause,
  filterableProperties,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
}): {
  columns: Column[];
  getContextMenuItems: (params: GetContextMenuItemsParams<CustomerQuote>) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} {
  const selectedNodesRef = useRef<CustomerQuote[]>([]);

  const mixpanel = useMixpanel();
  const { handleClickJson, jsonModal } = useJsonModal();
  const { enableMakerMarketplaceETFRFQFlow } = useFeatureFlag();

  const defaultContextMenuItems = useGetDefaultContextMenuItems();
  const copyCustomerOrderExecutionTextMenuItem = useCopyCustomerOrderTextMenuItem();

  const dispatch = useAppStateDispatch();
  const handleSendQuote = useDynamicCallback((entity?: CustomerQuote) => {
    if (entity == null) {
      return;
    }
    mixpanel.track(MixpanelEvent.OpenManualPricingForm);
    dispatch(openView(OMSView.ManualRFQPricingForm));
    dispatch(prime(entity));
  });

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams<CustomerQuote>) => {
    selectedNodesRef.current = getRowNodesToOperateOn(params).map(node => node.data!);

    const data = params?.node?.data;

    if (data == null) {
      return [];
    }

    const groupData = params?.node?.groupData;
    if (isNil(data) && isNil(groupData)) {
      return [];
    }

    const items = compact([
      ...filterByCellValueMenuItem({ params, filterableProperties, openClause, colIDToFilterBuilderKey, mixpanel }),
      copyCustomerOrderExecutionTextMenuItem(data),
      enableMakerMarketplaceETFRFQFlow
        ? ({
            name: 'Send Quote',
            action: () => handleSendQuote(data),
            disabled: params.api.getSelectedRows().length > 1 || !isSendQuoteEnabled(data),
          } satisfies MenuItemDef)
        : undefined,
      ...(data ? [getShowJSONContextItem({ params, handleClickJson }), 'separator'] : []),
      ...defaultContextMenuItems(params),
    ]);
    return items;
  });

  const dialogs = useMemo(() => <>{jsonModal}</>, [jsonModal]);
  const columns = useMemo(
    () =>
      compact([
        enableMakerMarketplaceETFRFQFlow
          ? {
              type: 'button',
              id: 'sendQuote',
              width: 86,
              frozen: true,
              pinned: 'right',
              suppressColumnsToolPanel: true,
              exportable: false,
              params: {
                onClick: (params: ICellRendererParams<CustomerQuote>) => handleSendQuote(params.node.data),
                disabled: params => !isSendQuoteEnabled(params.node.data),
                size: FormControlSizes.Small,
                children: 'Send Quote',
              },
            }
          : undefined,
        {
          type: 'hamburgerMenu',
          id: 'rowMenu',
          params: {
            renderItems: params => <CustomerQuotesMenu {...params} onShowJson={handleClickJson} />,
          },
        },
      ]) satisfies ColumnDef<CustomerQuote>[],
    [handleClickJson, handleSendQuote, enableMakerMarketplaceETFRFQFlow]
  );

  return {
    columns,
    getContextMenuItems,
    dialogs,
  };
}

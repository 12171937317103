import { without } from 'lodash-es';
import { useMemo, type ReactNode } from 'react';
import { ToggleButton, type ToggleButtonProps } from '../../Button';
import { ToggleButtonFilterWrapper } from './styles';

export type ToggleButtonFilterProps<T> = Omit<ToggleButtonProps, 'selected' | 'onClick'> & {
  options: T[];
  selections: T[];
  getKey: (obj: T) => string;
  renderContent: (obj: T) => ReactNode;
  onToggleChange: (obj: T[]) => void;
  /**
   * If true (the default), multiple options can be selected.
   * If false, selecting an option will unselect all other options.
   */
  multiSelect?: boolean;
};

export const ToggleButtonFilter = <T,>({
  options,
  selections,
  getKey,
  renderContent,
  onToggleChange,
  multiSelect = true,
  ...props
}: ToggleButtonFilterProps<T>) => {
  const selectedFilters: Map<T, boolean> = useMemo(() => {
    const selectionSet = new Set(selections);
    return new Map<T, boolean>(options.map(option => [option, selectionSet.has(option)]));
  }, [options, selections]);

  const handleClick = (option: T) => {
    let newSelection: T[];
    if (multiSelect) {
      newSelection = selectedFilters.get(option) ? without(selections, option) : [...selections, option];
    } else {
      newSelection = selectedFilters.get(option) ? [] : [option];
    }
    return onToggleChange(newSelection);
  };

  return (
    <ToggleButtonFilterWrapper>
      {options.map(option => {
        const selected = selectedFilters.get(option) ?? false;
        return (
          <ToggleButton
            {...props}
            data-testid={`toggle-button-${getKey(option)}`}
            role="button"
            selected={selected}
            onClick={() => handleClick(option)}
            key={getKey(option)}
          >
            {renderContent(option)}
          </ToggleButton>
        );
      })}
    </ToggleButtonFilterWrapper>
  );
};

import { useMemo } from 'react';
import { useEffectOnce } from 'react-use';
import { useRestBlotterTable, useWsBlotterTable, type WebsocketRequest } from '../../BlotterTable';
import { LoaderTalos, LoaderWrapper } from '../../LoaderTalos';
import { Panel } from '../../Panel';
import { RR6Prompt } from '../../Prompt';
import { EntityAdminBlotter, EntityAdminDrawer, EntityAdminHeader, EntityAdminTabsAndFilters } from '../components';
import { EntityAdminConfirmationDialog } from '../components/EntityAdminConfirmationDialog';
import type { BaseEntityAdminProps, EntityAdminClass, EntityAdminContentProps, EntityAdminRecord } from '../types';
import { useEntityAdmin } from '../useEntityAdmin';
import { useEntityAdminBlotterFilter } from '../useEntityAdminBlotterFilter';
import { useEntityAdminBlotterProps } from '../useEntityAdminBlotterProps';
import { EntityAdminTabsWrapper, useEntityAdminTabsContext } from '../wrappers/EntityAdminTabsWrapper';
import type { EntityAdminRESTProps } from './EntityAdminREST';
import type { EntityAdminWSProps } from './EntityAdminWS';

/**
 * Talos Developer - Do not use this component directly.
 * Use EntityAdminWS or EntityAdminREST instead.
 */
export const EntityAdmin = <TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord = TRecord>(
  props: BaseEntityAdminProps<TRecord, TDrawerRecord> & {
    path?: EntityAdminRESTProps<TRecord>['path'];
    wsRequest?: EntityAdminWSProps<TRecord>['wsRequest'];
  }
) => {
  const entityAdminProps = useEntityAdmin<TRecord, TDrawerRecord>({
    ...props,
    renderDrawer: entityDrawer => (
      <EntityAdminDrawer<TRecord, TDrawerRecord>
        {...entityDrawer}
        drawerType={props.drawerType}
        allowAddEntity={props.allowAddEntity}
        allowEditEntity={props.allowEditEntity}
        allowDeleteEntity={props.allowDeleteEntity}
      />
    ),
  });

  const EntityAdminContentPanel = useMemo(
    () => (
      <Panel background="colors.gray.000" gap="1px">
        <RR6Prompt
          when={entityAdminProps.isEntityAdminDrawerOpen}
          message="You have unsaved changes. Are you sure you want to leave?"
          handleOnConfirmed={entityAdminProps.closeEntityDrawer}
        />
        <EntityAdminConfirmationDialog
          context={entityAdminProps.confirmDialog.context}
          isOpen={entityAdminProps.confirmDialog.isOpen}
          close={entityAdminProps.confirmDialog.close}
        />
        <EntityAdminHeader {...entityAdminProps} />
        <EntityAdminTabsAndFilters />
        {props.filterableProperties == null ? (
          <LoaderWrapper background="backgroundContent">
            <LoaderTalos />
          </LoaderWrapper>
        ) : props.wsRequest ? (
          <EntityAdminWSContent<TRecord, TDrawerRecord>
            {...entityAdminProps}
            wsRequest={props.wsRequest!}
            filterableProperties={props.filterableProperties!}
          />
        ) : (
          <EntityAdminRESTContent<TRecord, TDrawerRecord>
            {...entityAdminProps}
            baseUrl={props.baseUrl}
            path={props.path!}
            filterableProperties={props.filterableProperties!}
          />
        )}
      </Panel>
    ),
    [entityAdminProps, props.filterableProperties, props.path, props.baseUrl, props.wsRequest]
  );

  const entityAdminWrappers: Array<(children: React.ReactNode) => JSX.Element> = [];

  /**
   * EntityAdminTabsWrapper
   * Manages the tab items and the tabs context.
   * Due to the way this wrapper works (relies on array of EntityAdmin children), it must be the first wrapper in the list.
   */
  const existingTabsContext = useEntityAdminTabsContext();
  if (entityAdminProps.useTabs) {
    /**
     * It is possible that the EntityAdminTabsWrapper is already present in the tree.
     * This is the case for /dealer/tiers for exmple, where the tabs context is shared between two pages.
     */
    if (existingTabsContext != null) {
      throw new Error('EntityAdminTabsContext is already present in the tree.');
    }
    entityAdminWrappers.push(children => (
      <EntityAdminTabsWrapper persistKey={`${props.persistKey}/tabs`} allowAddTabs={true}>
        {children}
      </EntityAdminTabsWrapper>
    ));
  }

  /** If more wrappers are added in the future, they should be added here.
   * entityAdminWrappers.push(children => <Wrapper>{children}</Wrapper>);
   */

  return entityAdminWrappers.reduce((content, wrap) => wrap(content), EntityAdminContentPanel);
};

const EntityAdminWSContent = <TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord = TRecord>(
  props: EntityAdminWSProps<TRecord, TDrawerRecord> & EntityAdminContentProps<TRecord, TDrawerRecord>
) => {
  const { blotterTableProps, sharedFiltersProps, jsonModal } = useEntityAdminBlotterProps<TRecord, TDrawerRecord>(
    props
  );

  const blotterTableWS = useWsBlotterTable<WebsocketRequest, EntityAdminClass<TRecord>, TRecord>({
    initialRequest: props.wsRequest,
    ...blotterTableProps,
  });

  const blotterTableFilter = useEntityAdminBlotterFilter<TRecord, TDrawerRecord>({
    ...sharedFiltersProps,
    blotterTableFiltersProps: blotterTableWS.blotterTableFiltersProps,
    getSelectedRows: blotterTableWS.getSelectedRows,
    collapseAllGroups: blotterTableWS.collapseAllGroups,
    expandAllGroups: blotterTableWS.expandAllGroups,
  });

  return useMemo(
    () => (
      <EntityAdminBlotter<TRecord>
        blotterTable={blotterTableWS}
        blotterTableFilter={blotterTableFilter}
        jsonModal={jsonModal}
      />
    ),
    [blotterTableWS, blotterTableFilter, jsonModal]
  );
};

const EntityAdminRESTContent = <TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord = TRecord>({
  blotterRowCount,
  paginationSize,
  onColumnsReady,
  ...props
}: EntityAdminRESTProps<TRecord, TDrawerRecord> & EntityAdminContentProps<TRecord, TDrawerRecord>) => {
  const { blotterTableProps, sharedFiltersProps, jsonModal } = useEntityAdminBlotterProps<TRecord, TDrawerRecord>(
    props
  );

  const blotterTableREST = useRestBlotterTable<EntityAdminClass<TRecord>, TRecord>({
    // "limit" here is misleading - it's actually the number of records to fetch at a time
    request: { baseUrl: props.baseUrl, path: props.path, limit: paginationSize },
    // "blotterRowCount" is the number of records we will fetch until, and then we stop even if there is a "next".
    blotterRowCount,
    onColumnsReady,
    ...blotterTableProps,
  });

  const blotterTableFilter = useEntityAdminBlotterFilter<TRecord, TDrawerRecord>({
    ...sharedFiltersProps,
    blotterTableFiltersProps: blotterTableREST.blotterTableFiltersProps,
    getSelectedRows: blotterTableREST.getSelectedRows,
    collapseAllGroups: blotterTableREST.collapseAllGroups,
    expandAllGroups: blotterTableREST.expandAllGroups,
  });

  useEffectOnce(() => {
    /**
     * This is a reference to the blotter table's refresh function.
     * We need it to propagate the drawer changes to the table.
     * We only need to do this for REST tables.
     */
    props.blotterTableApiRef.current.refresh = blotterTableREST.refresh;
  });

  return useMemo(
    () => (
      <EntityAdminBlotter<TRecord>
        blotterTable={blotterTableREST}
        blotterTableFilter={blotterTableFilter}
        jsonModal={jsonModal}
      />
    ),
    [blotterTableREST, blotterTableFilter, jsonModal]
  );
};

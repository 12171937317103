import {
  ConnectionStatusEnum,
  EMPTY_ARRAY,
  FeeModeEnum,
  FixingIndex,
  InstrumentCompositionEnum,
  LegDirectionEnum,
  MarketAccountStatusEnum,
  MarketAccountTypeEnum,
  MarketTypeEnum,
  OrdTypeEnum,
  OrderStrategy,
  PresenceEnum,
  ProductTypeEnum,
  SyntheticProductTypeEnum,
  UpdateActionEnum,
  getExpirationByMarketByCurrencyIdentity,
  getFuturesByCurrencyPair,
  getFuturesByMarketByCurrencyPair,
  getFuturesFromSecurities,
  getPerpsByCurrencyPair,
  getPerpsFromSecurities,
  getStrikesByOptionKey,
  type Currency,
  type Customer,
  type CustomerConfiguration,
  type CustomerSpecificSecurity,
  type IWebSocketClient,
  type JSON_OrderStrategy,
  type JSON_StrategyParameter,
  type Market,
  type MarketAccount,
  type Security,
} from '@talos/kyoko';
import { compact } from 'lodash-es';
import { initialValues } from 'providers/AppConfigProvider/initialValues';
import { createAppConfigSlice } from 'providers/AppConfigRedux/AppConfigSlice';
import { getAppStateStore } from 'providers/AppStateProvider';
import type { AvailabilityResult, MarketSelectorItem } from '../../MarketSelector/types';
import { buildMarketSelectorItem } from '../../MarketSelector/utils/items';
import { setReferenceData as setOrderFormReferenceData } from '../NewOrder/OrderSlice';
import { setReferenceData as setRFQReferenceData } from '../NewRFQ/RFQSlice';
import { initialRefDataState, setReferenceData } from '../OMSReferenceDataSlice';
import { setReferenceData as setSalesOrderReferenceData } from '../SalesOrder/SalesOrderSlice';
import { setReferenceData as setSalesRFQReferenceData } from '../SalesRFQView/SalesRFQSlice';
import type { OMSReferenceDataState } from '../types';
import { futures } from './futures';
import { options } from './options';
import { perps } from './perps';

const SUPPORTED_STRATEGIES = [
  {
    Name: 'Market',
    DisplayName: 'Market',
    Description: 'Place an order to be executed at the current market price.',
    Group: 'All',
    Parameters: [],
  },
  {
    Name: 'Limit',
    DisplayName: 'Limit',
    Description:
      'For marketable orders, the algorithm will sweep the available liquidity up to the limit price by routing to those market(s) offering the best prices. The remaining quantity will be split equally among the selected market(s) at the limit price.',
    Group: 'All',
    Parameters: [
      {
        Name: 'LimitPrice',
        DisplayName: 'Limit Price',
        Type: 'Price',
        Presence: PresenceEnum.Required,
        Description: '',
      },
      {
        Name: 'StartTime',
        DisplayName: 'Start Time',
        Type: 'Date',
        Presence: PresenceEnum.Optional,
        Description: 'Time at which order will be placed on the market.',
      },
      {
        Name: 'EndTime',
        DisplayName: 'End Time',
        Type: 'Date',
        Presence: PresenceEnum.Optional,
        Description: 'Expire time for the order.',
      },
    ] as JSON_StrategyParameter[],
  },
] satisfies Partial<JSON_OrderStrategy>[] as JSON_OrderStrategy[];

export const AVAILABLE_SECURITIES = [
  {
    MinPriceIncrement: '0.00001', // 5,
    MinSizeIncrement: '0.001', // 3
    MinimumSize: '0.01',
    BaseCurrency: 'BTC',
    PositionCurrency: 'BTC',
    QuoteCurrency: 'USD',
    Symbol: 'BTC-USD',
    NormalSize: '5',
    Composition: InstrumentCompositionEnum.Native,
    ProductType: ProductTypeEnum.Spot,
    NotionalMultiplier: '1',
    Markets: ['bitmex', 'coinbase', 'okx'],
    SupportedStrategies: SUPPORTED_STRATEGIES,
  },
  {
    MinPriceIncrement: '0.00001', // 5,
    MinSizeIncrement: '0.001', // 3
    BaseCurrency: 'ETH',
    PositionCurrency: 'ETH',
    QuoteCurrency: 'USD',
    Symbol: 'ETH-USD',
    NormalSize: '5',
    MinimumSize: '0.01',
    ProductType: ProductTypeEnum.Spot,
    Composition: InstrumentCompositionEnum.Native,
    NotionalMultiplier: '1',
    Markets: ['bitmex', 'coinbase', 'okx'],
    SupportedStrategies: SUPPORTED_STRATEGIES,
  },
  {
    MinPriceIncrement: '0.0001', // 4,
    MinSizeIncrement: '0.01', // 2
    BaseCurrency: 'BTC',
    PositionCurrency: 'BTC',
    QuoteCurrency: 'EUR',
    Symbol: 'BTC-EUR via USD',
    MinimumSize: '0.01',
    NormalSize: '4',
    Composition: InstrumentCompositionEnum.Synthetic,
    ProductType: ProductTypeEnum.Synthetic,
    NotionalMultiplier: '1',
    MultilegDetails: {
      Legs: [
        {
          Direction: LegDirectionEnum.Same,
          Index: 0,
          Markets: [],
          Symbol: 'BTC-USD',
        },
        {
          Direction: LegDirectionEnum.Same,
          Index: 1,
          Markets: [],
          Symbol: 'EUR-USD',
        },
      ],
      FeeMode: FeeModeEnum.Net,
      SyntheticProductType: SyntheticProductTypeEnum.Cross,
      MaxMarketDataDepth: 50,
      Parameters: {
        LegParams: [
          {
            Initiating: true,
          },
        ],
      },
    },
  },
  {
    Symbol: 'USD-USD via BTC',
    Timestamp: '2024-08-22T08:57:19.401983Z',
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '0.00000001',
    MinimumSize: '0.01',
    MaximumSize: '100000000',
    QuoteCurrency: 'USD',
    BaseCurrency: 'USD',
    DefaultPriceIncrement: '0.01',
    DefaultSizeIncrement: '0.0001',
    PriceDisplaySpec: 'M.m',
    SizeDisplaySpec: 'M.m',
    NormalSize: '1',
    ProductType: ProductTypeEnum.Synthetic,
    PositionCurrency: 'USD',
    SettlementCurrency: 'USD',
    NotionalMultiplier: '1',
    Composition: InstrumentCompositionEnum.Synthetic,
    StrikePrice: '0',
    DisplaySymbol: 'USD-USD via BTC',
    SizeBuckets: ['0.5', '1.0', '5.0', '10.0', '25.0'],
    Markets: ['multileg'],
    Rank: 1,
    MultilegDetails: {
      FeeMode: FeeModeEnum.Net,
      Legs: [
        {
          Direction: LegDirectionEnum.Opposite,
          Index: 0,
          Markets: [
            {
              Market: 'cumberland',
              MarketAccount: 'cumberland/cumberland-0',
            },
          ],
          Symbol: 'BTC-USD',
        },
        {
          Direction: LegDirectionEnum.Same,
          Index: 1,
          Markets: [
            {
              Market: 'galaxy',
              MarketAccount: 'galaxy/galaxy-0',
            },
          ],
          Symbol: 'BTC-USD',
        },
      ],
      MaxMarketDataDepth: 50,
      Parameters: {
        LegParams: [
          {
            CrossingAllowed: true,
            Initiating: true,
            MaxRestingLevels: 1,
          },
          {
            CrossingAllowed: true,
            Initiating: true,
            MaxRestingLevels: 1,
          },
        ],
      },
      SyntheticProductType: SyntheticProductTypeEnum.Cross,
    },
  },
  {
    Timestamp: '2023-09-06T06:23:38.950448Z',
    Symbol: 'TEST-CS-SYMBOL',
    MinPriceIncrement: '0.00000001',
    MinSizeIncrement: '0.000001',
    MinimumSize: '2',
    MaximumSize: '100000000',
    QuoteCurrency: 'BTC',
    BaseCurrency: 'USD',
    DefaultPriceIncrement: '0.0001',
    DefaultSizeIncrement: '0.01',
    PriceDisplaySpec: 'M.MMMMm',
    SizeDisplaySpec: 'M.m',
    NormalSize: '1',
    ProductType: ProductTypeEnum.Synthetic,
    Composition: InstrumentCompositionEnum.Synthetic,
    PositionCurrency: 'USD',
    SettlementCurrency: 'USD',
    NotionalMultiplier: '1',
    StrikePrice: '0',
    DisplaySymbol: 'TEST-CS-SYMBOL',
    SizeBuckets: ['10000', '25000', '50000', '100000', '200000'],
    Markets: ['coinbase'],
    MultilegDetails: {
      SyntheticProductType: SyntheticProductTypeEnum.CallSpread,
      FeeMode: FeeModeEnum.Net,
      MaxMarketDataDepth: 50,
      Legs: [
        {
          Symbol: 'Option1',
          Index: 0,
          Markets: [{ MarketAccount: 'coinbase/coinbase', Market: 'coinbase' }],
          Direction: LegDirectionEnum.Same,
        },
        {
          Symbol: 'Option6',
          Index: 1,
          Markets: [{ MarketAccount: 'coinbase/coinbase', Market: 'coinbase' }],
          Direction: LegDirectionEnum.Opposite,
        },
      ],
      Parameters: {
        LegParams: [
          {
            Initiating: true,
          },
          {
            Initiating: true,
          },
        ],
      },
    },
    Rank: 1,
  },
  ...futures,
  ...perps,
  ...options,
] satisfies Partial<Security & CustomerSpecificSecurity>[] as (Security & CustomerSpecificSecurity)[];

export const availableCurrencies = [
  {
    Timestamp: '2023-11-13T16:07:35.437857Z',
    Symbol: 'USD',
    MinIncrement: '0.01',
    DefaultIncrement: '0.01',
    Description: 'U.S. Dollar',
  },
] satisfies Partial<Currency>[] as Currency[];

export const customers = [
  {
    Name: 'John',
    DisplayName: 'John',
    CounterpartyID: 1,
  },
  {
    Name: 'Pete',
    DisplayName: 'Pete',
    CounterpartyID: 2,
  },
] satisfies Partial<Customer>[] as Customer[];

export const customerMarketAccountsList = [
  {
    Status: MarketAccountStatusEnum.Active,
    Counterparty: 'John',
    Name: 'John Acc Default',
    Type: MarketAccountTypeEnum.Customer,
    SourceAccountID: '1',
  },
  {
    Status: MarketAccountStatusEnum.Active,
    Counterparty: 'John',
    Name: 'John Acc Alternative',
    Type: MarketAccountTypeEnum.Customer,
    SourceAccountID: '2',
  },
] satisfies Partial<MarketAccount>[] as MarketAccount[];

export const TEST_STRATEGIES: OrderStrategy[] = [
  {
    Name: 'Limit',
    DisplayName: 'Limit',
    Group: 'All',
    Parameters: [
      {
        Name: 'StartTime',
        Type: 'Date',
        Presence: 'Optional',
        DisplayName: 'Start Time',
      },
      {
        Name: 'EndTime',
        Type: 'Date',
        Presence: 'Optional',
        DisplayName: 'End Time Limit',
      },
      {
        Name: 'PriceProtection',
        Type: 'PriceProtection',
        Presence: 'Optional',
        DisplayName: 'Price Protection',
      },
      {
        Name: 'UnifiedLiquidity',
        Type: 'UnifiedLiquidity',
        Presence: 'Optional',
        DisplayName: 'Unified Liquidity',
      },
      {
        Name: 'ReduceOnly',
        Type: 'ReduceOnly',
        Presence: 'Optional',
        DisplayName: 'Reduce Only',
      },
      {
        Name: 'ReduceFirst',
        Type: 'ReduceFirst',
        Presence: 'Optional',
        DisplayName: 'Reduce First',
      },
    ],
  },
  {
    Name: 'MultilegLimit',
    DisplayName: 'Multileg Limit',
    Group: 'All',
    InstrumentScope: 'Synthetic',
    Parameters: [
      {
        Name: 'StartTime',
        Type: 'Date',
        Presence: 'Optional',
        DisplayName: 'Start Time',
      },
      {
        Name: 'EndTime',
        Type: 'Date',
        Presence: 'Optional',
        DisplayName: 'End Time',
      },
      {
        Name: 'MaxImbalanceAmt',
        Type: 'Qty',
        Presence: 'Optional',
        DisplayName: 'Max Imbalance',
      },
      {
        Name: 'AlgoInstructions',
        Type: 'JSON',
        Presence: 'Hidden',
        Description: 'Algo Instructions.',
        DisplayName: 'Algo Instructions',
        DefaultValue:
          '{"LegCrossingToInitiate":false,"LegRestingToInitiate":true,"LegRestPegged":true,"LegHedgeAggressiveAtMarket":false}',
      },
      {
        Name: 'UnifiedLiquidity',
        Type: 'UnifiedLiquidity',
        Presence: 'Optional',
        DisplayName: 'Unified Liquidity',
      },
    ],
  },
  {
    Name: 'TWAP',
    DisplayName: 'TWAP',
    Group: 'Exchange',
    Parameters: [
      {
        Name: 'EndTime',
        Type: 'Date',
        Presence: 'Required',
        DisplayName: 'End Time TWAP',
      },
      {
        Name: 'StartTime',
        Type: 'Date',
        Presence: 'Optional',
        DisplayName: 'Start Time',
      },
      {
        Name: 'BoundsControl',
        Type: 'Enum',
        Presence: 'Optional',
        EnumValues: [
          {
            Index: 1,
            Name: 'Narrow',
          },
          {
            Index: 2,
            Name: 'Standard',
          },
          {
            Index: 3,
            Name: 'Wide',
          },
        ],
        DisplayName: 'Bounds Control',
        DefaultValue: '3',
      },
      {
        Name: 'PriceProtection',
        Type: 'PriceProtection',
        Presence: 'Optional',
        DisplayName: 'Price Protection',
      },
      {
        Name: 'UnifiedLiquidity',
        Type: 'UnifiedLiquidity',
        Presence: 'Optional',
        DisplayName: 'Unified Liquidity',
      },
      {
        Name: 'Urgency',
        Type: 'Enum',
        Presence: 'Hidden',
        EnumValues: [
          {
            Index: 1,
            Name: '1',
          },
          {
            Index: 2,
            Name: '2',
          },
          {
            Index: 3,
            Name: '3',
          },
          {
            Index: 4,
            Name: '4',
          },
          {
            Index: 5,
            Name: '5',
          },
        ],
        DisplayName: 'Urgency',
        DefaultValue: '3',
      },
    ],
  },
  {
    Name: 'EnclaveCross',
    DisplayName: 'Enclave Cross',
    Group: 'Dark',
    Parameters: [
      {
        Name: 'OrdType',
        Type: 'OrdType',
        Presence: 'TopLevelHidden',
        DisplayName: 'OrdType',
        DefaultValue: OrdTypeEnum.Market,
      },
    ],
  },
  {
    Name: 'SteadyPace',
    DisplayName: 'Steady Pace',
    Group: 'Exchange',
    Parameters: [
      {
        Name: 'ClipSize',
        Type: 'Qty',
        Presence: 'Optional',
        DisplayName: 'Clip Size',
      },
      {
        Name: 'ClipInterval',
        Type: 'Interval',
        Presence: 'Required',
        Description: 'Time interval between individual clips.',
        DisplayName: 'Clip Interval',
      },
    ],
  },
  {
    Name: 'PercentOfVolume',
    DisplayName: 'POV',
    Group: 'Dark',
    Parameters: [
      {
        Name: 'TargetParticipationRate',
        Type: 'Percent',
        Presence: 'Required',
        DisplayName: 'Target Rate',
      },
      {
        Name: 'HedgePayExtraBps',
        Type: 'Bps',
        Presence: 'Optional',
        DisplayName: 'Hedge Pay Extra Bps',
      },
      {
        Name: 'BoundsControl',
        Type: 'Enum',
        Presence: 'Optional',
        EnumValues: [
          {
            Index: 1,
            Name: 'Narrow',
          },
          {
            Index: 2,
            Name: 'Standard',
          },
          {
            Index: 3,
            Name: 'Wide',
          },
        ],
        DisplayName: 'Bounds Control',
        DefaultValue: '3',
      },
      {
        Name: 'Urgency',
        Type: 'Enum',
        Presence: 'Hidden',
        EnumValues: [
          {
            Index: 2,
            Name: '2',
          },
        ],
        DisplayName: 'Urgency',
        DefaultValue: '2',
      },
    ],
  },
  {
    Name: 'Iceberg',
    DisplayName: 'Iceberg',
    Group: 'All',
    Parameters: [
      {
        Name: 'ShowQty',
        Type: 'Qty',
        Presence: 'Optional',
        DisplayName: 'Show Qty',
      },
    ],
  },
  {
    Name: 'Market',
    DisplayName: 'Market',
    Group: 'All',
    Parameters: [],
  },
  {
    Name: 'OpportunisticLimit',
    Group: 'All',
    AlgoType: 'Base',
    InstrumentScope: 'Synthetic',
    Parameters: [
      {
        Name: 'MaxImbalanceAmt',
        Type: 'Qty',
        Presence: 'Optional',
        Description: 'Maximum allowed imbalance in position between legs.',
        DisplayName: 'Max Leg Imbalance Amt',
        DefaultValue: '100000',
      },
      {
        Name: 'HedgePassiveTimeout',
        Type: 'Duration',
        Presence: 'Optional',
        Description:
          'If the order has hit the MaxImbalance threshold while trying to passively hedge and it has been longer than HedgePassiveTimeout since the last pending fill, then the algo will try to hedge aggressively.',
        DisplayName: 'Hedge Passive Timeout',
        DefaultValue: '30s',
      },
      {
        Name: 'HedgePayExtra',
        Type: 'Bps',
        Presence: 'Optional',
        Description: 'Max basis points beyond the limit price the algo is willing to go in order to hedge.',
        DisplayName: 'Hedge Pay Extra Bps',
        DefaultValue: '0.0002',
      },
      {
        Name: 'StartTime',
        Type: 'Date',
        Presence: 'Optional',
        Description: 'Time at which this order will activate and begin sending orders to the market.',
        DisplayName: 'Start Time',
      },
      {
        Name: 'EndTime',
        Type: 'Date',
        Presence: 'Optional',
        Description: 'Expire time for the order.',
        DisplayName: 'End Time',
      },
      {
        Name: 'AlgoInstructions',
        Type: 'JSON',
        Presence: 'Hidden',
        Description: 'Algo Instructions.',
        DisplayName: 'Algo Instructions',
        DefaultValue:
          '{"LegCrossingToInitiate":true,"LegRestingToInitiate":true,"LegRestPegged":true,"LegHedgePassivePegged":true,"LegRestingAgainstPassiveExit":false}',
      },
      {
        Name: 'MaxImbalanceAmtCurrency',
        Type: 'Currency',
        Presence: 'Hidden',
        Description: 'Currency in which to define the maximum allowed imbalance in position between legs.',
        DisplayName: 'Currency for Max Leg Imbalance Amt',
        DefaultValue: 'USD',
      },
    ],
    Description:
      'The algorithm will cross on the initiating legs if possible, otherwise it will rest. Once filled, it will try to passively hedge on the other leg until the HedgePassiveTimeout at which point it will cross to hedge.',
    DisplayName: 'Multileg Opportunistic',
  },
  {
    Name: 'MarketDDH',
    Group: 'All',
    AlgoType: 'Base',
    InstrumentScope: 'Native',
    StrategyScope: 'DDHSubStrategy',
    Parameters: [
      {
        Name: 'OrdType',
        Type: 'OrdType',
        Presence: 'TopLevelHidden',
        Description: 'The type of the order.',
        DisplayName: 'OrdType',
        DefaultValue: 'Market',
      },
      {
        Name: 'DynamicDeltaHedgeSecurity',
        Type: 'Security',
        Presence: 'Optional',
        Description: 'Dynamic Delta Hedge security for options orders.',
        DisplayName: 'Dynamic Delta Hedge Security',
        Metadata: '{"InstrumentScope": ["Option"]}',
      },
      {
        Name: 'PriceProtection',
        Type: 'PriceProtection',
        Presence: 'Optional',
        Description:
          'Enables price protection by excluding markets that violates a threshold based on fair price, as well as rejecting aggressive SOR orders that would result in off-market fills',
        DisplayName: 'Price Protection',
      },
    ],
    Description: 'Place an order to be executed at the current market price.',
    DisplayName: 'Market',
  },
  {
    Name: 'MarketPeggedDDH',
    Group: 'Exchange',
    AlgoType: 'Pegged',
    InstrumentScope: 'Native',
    StrategyScope: 'DDHSubStrategy',
    Parameters: [
      {
        Name: 'OrdType',
        Type: 'OrdType',
        Presence: 'TopLevelHidden',
        Description: 'The type of the order.',
        DisplayName: 'OrdType',
        DefaultValue: 'Market',
      },
      {
        Name: 'Urgency',
        Type: 'Enum',
        Presence: 'Optional',
        EnumValues: [
          {
            Index: 1,
            Name: '1',
            Description: '~4.5% Participation',
          },
          {
            Index: 2,
            Name: '2',
            Description: '~10.0% Participation',
          },
          {
            Index: 3,
            Name: '3',
            Description: '~17.5% Participation',
          },
          {
            Index: 4,
            Name: '4',
            Description: '~35.0% Participation',
          },
          {
            Index: 5,
            Name: '5',
            Description: '~55.0% Participation',
          },
        ],
        Description: 'Urgency that will drive aggressiveness of algo behavior',
        DisplayName: 'Urgency',
        DefaultValue: '3',
      },
      {
        Name: 'DynamicDeltaHedgeSecurity',
        Type: 'Security',
        Presence: 'Optional',
        Description: 'Dynamic Delta Hedge security for options orders.',
        DisplayName: 'Dynamic Delta Hedge Security',
        Metadata: '{"InstrumentScope": ["Option"]}',
      },
      {
        Name: 'PriceProtection',
        Type: 'PriceProtection',
        Presence: 'Optional',
        Description:
          'Enables price protection by excluding markets that violate a threshold (defaults to 10%) based on Talos Reference Rate. For algo orders, price protection offers additional protection on child orders sent to markets by limiting them according to proprietary Fair Price Model. For SOR orders, very aggressive orders with potentially high loss will be rejected.',
        DisplayName: 'Price Protection',
      },
      {
        Name: 'UnifiedLiquidity',
        Type: 'UnifiedLiquidity',
        Presence: 'Optional',
        Description: 'Includes liquidity from symbols considered as equivalent in order routing',
        DisplayName: 'Unified Liquidity',
      },
      {
        Name: 'ReduceFirst',
        Type: 'ReduceFirst',
        Presence: 'Optional',
        Description: 'Prioritizes routing orders to markets with offsetting positions',
        DisplayName: 'Reduce-First',
        DefaultValue: 'Disabled',
      },
    ],
    Description:
      'A passive algorithm tracking a reference price (best bid or best offer per market) as it moves with or without an offset.',
    DisplayName: 'MarketPegged',
  },
].map(
  s =>
    // This would be better as more of a create function with fallbacks for missing fields
    new OrderStrategy(s as JSON_OrderStrategy)
);

const markets = [
  {
    Name: 'okx',
    DisplayName: 'OKX',
    Flags: { SupportsMarginCost: false },
    Type: MarketTypeEnum.Exchange,
  },
  {
    Name: 'coinbase',
    DisplayName: 'Coinbase',
    Flags: { SupportsMarginCost: true, SupportsReduceOnly: true },
    Type: MarketTypeEnum.Exchange,
  },
  {
    Name: 'cme',
    DisplayName: 'CME',
    Flags: { SupportsMarginCost: true },
    Type: MarketTypeEnum.Exchange,
  },
  {
    Name: 'bitmex',
    DisplayName: 'BitMEX',
    Orders: { Status: ConnectionStatusEnum.Unavailable },
    Type: MarketTypeEnum.Exchange,
  },
  {
    Name: 'enigma',
    DisplayName: 'Enigma',
    Type: MarketTypeEnum.Dealer,
  },
  {
    Name: 'binance_futures',
    DisplayName: 'Binance Futures',
    Type: MarketTypeEnum.Exchange,
  },
  {
    Name: 'cumberland',
    DisplayName: 'Cumberland',
    Type: MarketTypeEnum.Dealer,
    Orders: { Status: ConnectionStatusEnum.Online },
  },
] satisfies Partial<Market>[] as Market[];

const marketsAccounts = [
  {
    Name: 'okx/okx',
    DisplayName: 'okx/okx',
    Market: 'okx',
    MarketAccountID: 1,
  },
  {
    Name: 'coinbase/coinbase',
    DisplayName: 'coinbase/coinbase',
    Market: 'coinbase',
    MarketAccountID: 2,
  },
  {
    Name: 'cme/cme',
    DisplayName: 'cme/cme',
    Market: 'cme',
    MarketAccountID: 3,
  },
  {
    Name: 'enigma/enigma',
    DisplayName: 'enigma/enigma',
    Market: 'enigma',
    MarketAccountID: 4,
  },
  {
    Name: 'bitmex/bitmex',
    DisplayName: 'bitmex/bitmex',
    Market: 'bitmex',
    MarketAccountID: 5,
  },
  {
    Name: 'okx/okx2',
    DisplayName: 'okx/okx2',
    Market: 'okx',
    MarketAccountID: 6,
  },
  {
    Name: 'binance_futures/binance_futures',
    DisplayName: 'binance_futures/binance_futures',
    Market: 'binance_futures',
    MarketAccountID: 7,
  },
  {
    Name: 'cumberland/cumberland-0',
    DisplayName: 'Cumberland',
    Market: 'cumberland',
    MarketAccountID: 8,
    Type: MarketAccountTypeEnum.Trading,
    Status: MarketAccountStatusEnum.Active,
  },
] satisfies Partial<MarketAccount>[] as MarketAccount[];

const fixingIndices: FixingIndex[] = [
  new FixingIndex({
    Timestamp: '2024-06-24T13:05:09.956881Z',
    UpdateAction: UpdateActionEnum.Update,
    Revision: 0,
    IndexID: '1HXAFN1KZHG00',
    Name: 'BRRNY',
    DisplayName: 'BRRNY',
    DefaultSpreadIncrement: '0.000001',
    MinSpreadIncrement: '0.00000001',
    SpreadDisplaySpec: 'M.MMmm',
    ValidNominalIndexTime: 160000,
    NominalIndexTimeZone: 'America/New_York',
    Description: 'CME CF Bitcoin Reference Rate - New York Variant',
  }),
  new FixingIndex({
    Timestamp: '2024-06-24T13:05:09.983197Z',
    UpdateAction: UpdateActionEnum.Update,
    Revision: 0,
    IndexID: '1HXANN83QHG00',
    Name: 'ECX',
    DisplayName: 'ECX',
    DefaultSpreadIncrement: '0.000001',
    MinSpreadIncrement: '0.00000001',
    SpreadDisplaySpec: 'M.MMmm',
    ValidNominalIndexTime: 160000,
    NominalIndexTimeZone: 'America/New_York',
    Description: 'CoinDesk Ether Classic Price Index',
  }),
];

export function scanToMap<T>(items: Array<T>, keySelector: (value: T) => string): Map<string, T> {
  return items.reduce((acc, value) => {
    acc.set(keySelector(value), value);
    return acc;
  }, new Map());
}

function scanToMapArray<T>(items: Array<T>, keySelector: (value: T) => string): Map<string, T[]> {
  return items.reduce((acc, value) => {
    const key = keySelector(value);
    if (acc.has(key)) {
      const array = acc.get(key).slice();
      array.push(value);
      acc.set(key, array);
    } else {
      acc.set(key, [value]);
    }
    return acc;
  }, new Map());
}

export function getTestAppStateStore(wsClient?: IWebSocketClient<unknown>) {
  const appStateDefaultValue = initialValues('{}');
  const appConfigSlice = createAppConfigSlice(appStateDefaultValue);
  return getAppStateStore({ wsClient, appConfigSlice });
}

export const setupTestStore = ({
  wsClient,
  customerConfigurations = EMPTY_ARRAY,
}: {
  wsClient?: IWebSocketClient<unknown>;
  customerConfigurations?: CustomerConfiguration[];
} = {}) => {
  const store = getTestAppStateStore(wsClient);
  const perps = getPerpsFromSecurities(AVAILABLE_SECURITIES);
  const futures = getFuturesFromSecurities(AVAILABLE_SECURITIES);

  const refDataState: OMSReferenceDataState = {
    ...initialRefDataState,
    defaultSubAccount: 'TestDefaultSubAcc',
    customers,
    customerMarketAccounts: customerMarketAccountsList,
    customerConfigurations: customerConfigurations,
    securities: {
      securitiesList: AVAILABLE_SECURITIES,
      securitiesBySymbol: scanToMap(AVAILABLE_SECURITIES, sec => sec.Symbol),
      perps,
      perpsByCurrencyPair: getPerpsByCurrencyPair(perps),
      futures,
      futuresByCurrencyPair: getFuturesByCurrencyPair(futures),
      futuresByMarketByCurrency: getFuturesByMarketByCurrencyPair(futures),
      isReady: true,
    },
    currencies: {
      currenciesBySymbol: scanToMap(availableCurrencies, c => c.Symbol),
      isReady: true,
    },
    options: {
      options,
      expirationByMarketByCurrencyIdentity: getExpirationByMarketByCurrencyIdentity(options),
      strikesByOptionKey: getStrikesByOptionKey(options),
      optionSecurityBySymbol: scanToMap(options, o => o.Symbol),
      isReady: true,
    },
    strategies: {
      strategiesList: TEST_STRATEGIES,
      strategiesByName: scanToMap(TEST_STRATEGIES, strat => strat.Name),
      ddhStrategiesList: TEST_STRATEGIES.filter(strat => strat.StrategyScope === 'DDHSubStrategy'),
      isReady: true,
    },
    markets: {
      marketsList: markets,
      marketsByName: scanToMap(markets, market => market.Name),
      isReady: true,
    },
    marketAccounts: {
      marketAccountsList: marketsAccounts,
      marketAccountsByName: scanToMap(marketsAccounts, account => account.Name),
      marketAccountsByMarket: scanToMapArray(marketsAccounts, account => account.Market),
      isReady: true,
    },
    unifiedLiquidityTokens: new Map([
      [
        'BTC-EUR via USD',
        {
          Symbol: 'BTC-EUR via USD',
          Tokens: [
            {
              Market: 'market1',
              Symbol: 'BTC-EUR',
            },
          ],
        },
      ],
    ]),
    fixingIndices: {
      fixingIndicesList: fixingIndices,
      fixingIndicesByName: scanToMap(fixingIndices, fixingIndex => fixingIndex.Name),
      fixingIndicesBySymbol: new Map<string, FixingIndex[]>([
        ['BTC-USD', [fixingIndices[0]]],
        ['ETH-USD', [fixingIndices[1]]],
      ]),
      marketsByFixingIndex: new Map<string, string[]>([
        ['BRRNY', ['coinbase']],
        ['ECX', ['coinbase']],
      ]),
      isReady: true,
    },
  };

  store.dispatch(setSalesOrderReferenceData(refDataState));
  store.dispatch(setOrderFormReferenceData(refDataState));
  store.dispatch(setSalesRFQReferenceData(refDataState));
  store.dispatch(setReferenceData(refDataState));
  store.dispatch(setRFQReferenceData(refDataState));
  return store;
};

/**
 * Helps you build MarketSelectorItems
 */
export function createMarketSelectorItemBuilder({
  marketsFixture = markets,
  marketAccountsFixture = marketsAccounts,
}: {
  marketsFixture?: Market[];
  marketAccountsFixture?: MarketAccount[];
}) {
  const buildItem = ({
    marketName,
    marketAccountName,
    availability,
  }: {
    marketName: string;
    marketAccountName?: string;
    /** Set the AvailabilityResult on the resulting item explicitly */
    availability?: AvailabilityResult;
  }): MarketSelectorItem | undefined => {
    const market = marketsFixture.find(m => m.Name === marketName);
    const account = marketAccountsFixture.find(ma => ma.Name === marketAccountName);
    if (!market) {
      return undefined;
    }

    const item = buildMarketSelectorItem({ market, marketAccount: account, conditions: [] });
    if (availability) {
      item.availability = availability;
    }

    return item;
  };

  // Just a plural version of buildItem above
  const buildItems = (buildParams: Parameters<typeof buildItem>[0][]) => compact(buildParams.map(buildItem));

  const marketAccountNamesToItems = (marketAccountNames: string[]) => {
    return compact(
      marketAccountNames.map(marketAccountName => {
        const marketName = marketAccountName.split('/')[0];
        return buildItem({ marketName, marketAccountName });
      })
    );
  };

  return { buildItem, buildItems, marketAccountNamesToItems };
}

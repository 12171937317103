import { MixpanelEvent } from '@talos/kyoko';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';
import { TradingSettingCheckbox } from './TradingSettingCheckbox';

export function ResetOrderAccountsOnSubAccountChangeSetting() {
  const { resetOrderAccountsOnSubAccountChange, setResetOrderAccountsOnSubAccountChange } = useTradingSettings();

  return (
    <TradingSettingCheckbox
      id="resetOrderAccountsOnSubAccountChange"
      isEnabled={resetOrderAccountsOnSubAccountChange}
      onChecked={setResetOrderAccountsOnSubAccountChange}
      mixPanelEvent={MixpanelEvent.ResetOrderAccountsOnSubAccountChange}
      toastDisplayName="Select Market Accounts based on Sub Account: "
      displayName="Select Market Accounts based on Sub Account"
      displayDescription="If enabled, selecting a Sub Account will override the selected market accounts on the New Order form. Does not apply to Sub Accounts which can trade All Markets."
    />
  );
}

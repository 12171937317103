import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

import { BlotterTableWrapper } from './styles';
import type { BlotterTableProps } from './types';

import type { BodyScrollEvent, ColDef, GridOptions } from 'ag-grid-community';
import { noop } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useEvent } from 'react-use';
import { useDynamicCallback } from '../../hooks';
import { useIntlContext } from '../../hooks/useIntlContext';
import { PORTAL_PARENT_CLASS } from '../../hooks/useTopLevelPortalElement';
import { cypressGridSettings } from '../../utils/envCypress';
import {
  AG_GRID_CURRENCY_CELL_RENDERER_KEY,
  AG_GRID_OPTION_GROUP_KEY,
  AG_GRID_OPTION_MARK_KEY,
  AG_GRID_OPTION_PRICE_QUOTE_KEY,
  AG_GRID_OPTION_STRIKE_PRICE_KEY,
  AgGridAmountInput,
  AgGridButton,
  AgGridCheckbox,
  AgGridCurrency,
  AgGridFormattedNumber,
  AgGridGroupToggleHeader,
  AgGridHamburgerMenu,
  AgGridIconButton,
  AgGridInput,
  AgGridLoadingOverlay,
  AgGridMeter,
  AgGridMultiSelectDropdown,
  AgGridNoRowsOverlay,
  AgGridOptionGroupCell,
  AgGridOptionMark,
  AgGridOptionPriceQuote,
  AgGridOptionStrikePrice,
  AgGridPrice,
  AgGridProcessStep,
  AgGridSearchSelectDropdown,
  AgGridSecurity,
  AgGridSize,
  AgGridToggle,
  AgGridTooltipHeader,
  AgGridWarning,
  AgGridWarningHeader,
} from '../AgGrid';
import { AG_GRID_FULL_WIDTH_GROUP_ROW, AgGridFullWidthGroupRow } from '../AgGrid/AgGridFullWidthGroupRow';
import { AgGridDateFilter } from './../AgGrid/AgGridDateFilter';
import { defaultGetQuickFilterText, getAgGridLocaleText } from './utils';

// Important that this is stable
export const DEFAULT_BLOTTER_COLDEF: ColDef = {
  resizable: true,
  cellDataType: false,
  filterParams: {
    buttons: ['reset', 'apply'],
  },
  getQuickFilterText: defaultGetQuickFilterText,
};

export function BlotterTable({
  gridOptions,
  density,
  background,
  extraComponents,
  hidePopupMenu,
  hidden,
  rowEndSpacing,
}: BlotterTableProps) {
  const { locale } = useIntlContext();
  const [pinnedLeftOverlapping, setPinnedLeftOverlapping] = useState(false);
  const handleBodyScroll = useCallback((e: BodyScrollEvent) => {
    setPinnedLeftOverlapping(e.left > 0);
  }, []);

  const memoizedGridOptions = useMemo(() => {
    return {
      suppressRowVirtualisation: cypressGridSettings.isRowVirtSuppressed(),
      suppressColumnVirtualisation: cypressGridSettings.isColVirtSuppressed(),
      ...gridOptions,
    } satisfies GridOptions;
  }, [gridOptions]);

  const { ref } = usePortalContextMenuClosing(hidePopupMenu ?? noop);

  const components = useMemo(() => {
    return {
      buttonColumn: AgGridButton,
      checkbox: AgGridCheckbox,
      dateFilter: AgGridDateFilter,
      hamburgerMenuColumn: AgGridHamburgerMenu,
      iconButtonColumn: AgGridIconButton,
      loadingOverlay: AgGridLoadingOverlay,
      noRowsOverlay: AgGridNoRowsOverlay,
      meterColumn: AgGridMeter,
      priceColumn: AgGridPrice,
      formattedNumberColumn: AgGridFormattedNumber,
      processStepColumn: AgGridProcessStep,
      sizeColumn: AgGridSize,
      iconButton: AgGridIconButton,
      input: AgGridInput,
      amountInput: AgGridAmountInput,
      toggle: AgGridToggle,
      searchSelectDropdown: AgGridSearchSelectDropdown,
      multiSelectDropdown: AgGridMultiSelectDropdown,
      [AG_GRID_CURRENCY_CELL_RENDERER_KEY]: AgGridCurrency,
      securityColumn: AgGridSecurity,
      warningColumn: AgGridWarning,
      warningHeader: AgGridWarningHeader,
      groupToggleHeader: AgGridGroupToggleHeader,
      [AG_GRID_OPTION_STRIKE_PRICE_KEY]: AgGridOptionStrikePrice,
      [AG_GRID_OPTION_PRICE_QUOTE_KEY]: AgGridOptionPriceQuote,
      [AG_GRID_OPTION_MARK_KEY]: AgGridOptionMark,
      [AG_GRID_OPTION_GROUP_KEY]: AgGridOptionGroupCell,
      [AG_GRID_FULL_WIDTH_GROUP_ROW]: AgGridFullWidthGroupRow,
      tooltipHeader: AgGridTooltipHeader,
      ...extraComponents,
    };
  }, [extraComponents]);

  return (
    <BlotterTableWrapper
      background={background}
      rowEndSpacing={rowEndSpacing}
      className="ag-theme-balham-dark"
      density={density}
      pinnedLeftOverlapping={pinnedLeftOverlapping}
      data-testid="blotter-table-wrapper"
      hidden={hidden}
      ref={ref}
    >
      <AgGridReact
        localeText={getAgGridLocaleText(locale)}
        defaultColDef={DEFAULT_BLOTTER_COLDEF}
        /** Defaulted to true. AgGrid changed the default in v29 from true to false, but we keep it as it was after upgrading. */
        groupAllowUnbalanced={true}
        {...memoizedGridOptions}
        onBodyScroll={handleBodyScroll}
        columnMenu="legacy"
        components={components}
        tooltipShowDelay={300}
        loadingOverlayComponent="loadingOverlay"
        noRowsOverlayComponent="noRowsOverlay"
        data-testid="blotter-table"
        suppressMultiSort={false}
        suppressColumnMoveAnimation={true}
      />
    </BlotterTableWrapper>
  );
}

/**
 * For some reason, when we render blotters in React Portals, the context menus stop closing when you click outside of them. Impact was that
 * you couldn't close context menus if you were rendering a Blotter inside one of our Modals, for example.
 *
 * This hook hacks-in this behavior for all our blotters. If we are within a portal, we start listening to _all_ click events on the body. If any click event
 * occurs outside of any ag-popup, we close all our context menus. This is simple but very effective.
 *
 * Note: if we are not within a portal, we will not start listening to click events.
 */
const usePortalContextMenuClosing = (hidePopupMenu: () => void) => {
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
  const blotterRenderedInPortal = useMemo(() => {
    if (wrapperRef == null) {
      return false; // we don't know
    }

    return wrapperRef.closest(`.${PORTAL_PARENT_CLASS}`) != null;
  }, [wrapperRef]);

  const handleDocumentClick = useDynamicCallback((e: Event) => {
    if (e.target instanceof Element) {
      const clickIsWithinAgPopup = e.target.closest('.ag-popup') != null;
      if (!clickIsWithinAgPopup) {
        hidePopupMenu();
      }
    }
  });

  useEvent('mousedown', blotterRenderedInPortal ? handleDocumentClick : undefined, document);

  return {
    ref: setWrapperRef,
  };
};

import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  QUOTE,
  Quote,
  columnToColumnState,
  createCSVFileName,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useDynamicCallback,
  useMixpanel,
  usePersistedBlotterTable,
  useQuoteColumns,
  useWsBlotterTable,
  type BlotterColumnState,
  type BlotterTableFilter,
  type Column,
} from '@talos/kyoko';
import { pick } from 'lodash-es';
import { useMemo } from 'react';

import type { RowDoubleClickedEvent } from 'ag-grid-community';
import { useQuotesFilter, type QuotesTableFilter } from './useQuotesFilter';
import { useQuotesMenu } from './useQuotesMenu';

export interface FilteredQuotesParams {
  blotterID: string;
  defaultColumns?: Column[];
  defaultFilter?: QuotesTableFilter;
  initialIsOpen?: boolean;

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab?: (filter: BlotterTableFilter, columns: BlotterColumnState[]) => void;
  onRowDoubleClicked?: (event: RowDoubleClickedEvent<Quote>) => void;
}

export function QuotesBlotterTable({
  blotterID,
  defaultColumns,
  defaultFilter,
  initialIsOpen,
  onCloneTab,
  onRowDoubleClicked,
}: FilteredQuotesParams) {
  const mixpanel = useMixpanel();

  const defaultColumnDefinitions = useQuoteColumns({ defaultColumns });

  const persistedBlotterTable = usePersistedBlotterTable<Quote>(blotterID, {
    columns: defaultColumnDefinitions,
    filter: defaultFilter,
    sort: '-SubmitTime',
  });

  const filterResults = useQuotesFilter({
    persistedBlotterTable,
  });
  const { clientSideFilter: clientLocalFilter, blotterTableFilterProps, filterBuilderProps } = filterResults;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });

  const quotesMenu = useQuotesMenu({
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterBuilderProps.properties,
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...quotesMenu.columns],
    [quotesMenu.columns, persistedBlotterTable.columns]
  );

  const blotterTable = useWsBlotterTable({
    initialRequest: {
      tag: blotterID,
      name: QUOTE,
    },
    rowID: Quote.rowID,
    filter: onlyServerFilterKeys(filterResults.filter),
    clientLocalFilter,
    persistence: persistedBlotterTable,
    columns: columnsWithMenu,
    gridOptions: {
      onRowDoubleClicked,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems: quotesMenu.getContextMenuItems,
    },
  });

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(filterResults.filter, blotterTable.getColumns().map(columnToColumnState));
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Quotes',
      }),
    });
    extrasMenuPopover.close();
  });

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTableFilterProps}
        {...blotterTable.blotterTableFiltersProps}
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>
            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
          </BlotterTableExtrasMenu>
        }
      />
      <BlotterTable {...blotterTable} />
      {quotesMenu.dialogs}
    </>
  );
}

function onlyServerFilterKeys(filter: QuotesTableFilter | undefined) {
  if (!filter) {
    return filter;
  }
  const serverFilter = pick(filter, [
    'StartDate',
    'EndDate',
    'Users',
    'Statuses',
    'Symbols',
    'RFQID',
    'QuoteID',
    'ParentRFQID',
  ]);
  return serverFilter;
}

import {
  Box,
  Flex,
  getCurrencyColor,
  HStack,
  IndicatorBadgeSizes,
  LoaderTalos,
  PieChart,
  setAlpha,
  Text,
  toBigWithDefault,
  useDynamicCallback,
  useHighchartsRef,
  VStack,
} from '@talos/kyoko';
import Big from 'big.js';
import type { Chart, Options } from 'highcharts';
import { groupBy, map as lodashMap, sortBy, sumBy } from 'lodash-es';
import type React from 'react';
import { type ReactNode, useEffect, useMemo, useState } from 'react';

import { type DefaultTheme, useTheme } from 'styled-components';
import { ThemeProvider } from 'styles/ThemeProvider';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { AsOfDateBadge } from '../../components/AsOfDateBadge';
import { portfolioAbbreviation } from '../../portfolioAbbreviation';
import { type BalancesPieDirection, type BalancesPiePointCustom, getPointCustomAttrs } from './types';

type ShowByKeys = 'market' | 'asset';

interface OperationsPieProps<T> {
  entities: T[] | undefined;
  /** Grab the property to group on from the balance. If undefined is returned, the value will not be included in any resulting groupings. */
  getGroupBy: (item: T) => string | undefined;
  /** Get the label of the item. Should be the label corresponding to the key we're grouping by */
  getLabel: (item: T) => string;
  /** Get the numeric value of the entity to be summed into the group's aggregate value */
  getValue: (item: T) => Big;

  /** Show by is just categorized into market or asset */
  showBy: ShowByKeys;
  /** Event called with the key of the slice */
  onSliceClick?: (key: string) => void;
  /** What time to show that the data is for. If the data is live, pass null. */
  asOf: string | null;
  /** The center label when no element is hovered */
  centerLabel: string;
  /**
   * Whether or not to divide entities by sign (positive or negative) before aggregating and building slices
   *
   * Used when you want to show entire negative _and_ positive values, and not just the net of positive and negative.
   */
  showing: 'equities' | 'balances';
}

export const OperationsPie = <T,>({
  entities,
  getGroupBy,
  getValue,
  getLabel,
  showBy,
  onSliceClick,
  asOf,
  centerLabel,
  showing,
}: OperationsPieProps<T>) => {
  const { homeCurrency } = useDisplaySettings();
  const [hoveredPoint, setHoveredPoint] = useState<Highcharts.Point>();

  const { chartRef, setChartObject, isLoaded } = useHighchartsRef();
  const theme = useTheme();

  const chartData = useMemo(() => {
    if (!entities) {
      return undefined;
    }

    if (showing === 'balances') {
      return getSplitData({ entities, showBy, theme, getValue, getGroupBy, getLabel });
    } else {
      // equities
      const result = getNetData({ entities, showBy, theme, getValue, getGroupBy, getLabel });
      // For equities, if the total is lt 0, then we dont render any data in the chart.
      return {
        total: result.total,
        data: result.total.lt(0) ? [] : result.data,
      };
    }
  }, [entities, showBy, theme, getValue, getGroupBy, getLabel, showing]);

  useEffect(() => {
    if (!isLoaded || !chartData) {
      return;
    }

    const series = chartRef.current?.series?.at(0);
    if (series) {
      // updatePoints below is false so that the blotter applies our data as we provide it and does not try to be smart with delta updates. Delta updates break our pre-determined sort
      series.setData(chartData.data, true, true, false);
    }
  }, [isLoaded, chartData, chartRef]);

  const [centerStyle, setCenterStyle] = useState<React.CSSProperties | null>(null);

  // We reset the the position of the center component programmatically whenever the chart Redraws at all
  const handleRedraw = useDynamicCallback((chart: Chart) => {
    const x = chart.plotLeft + chart.series[0].center[0];
    const y = chart.plotTop + chart.series[0].center[1];
    const innerDiameter = chart.series[0].center[3];
    const innerRadius = innerDiameter / 2;

    setCenterStyle({
      width: `${innerDiameter}px`,
      height: `${innerDiameter}px`,
      justifyContent: 'center',
      alignItems: 'center',
      left: x - innerRadius,
      top: y - innerRadius,
    });
  });

  const handleClick = useDynamicCallback((event: Highcharts.SeriesClickEventObject) => {
    const point = event.point;
    if (!point) {
      return;
    }

    const key = getPointCustomAttrs(point)?.key;
    key && onSliceClick?.(key);
  });

  const handleMouseOver = useDynamicCallback((point: Highcharts.Point) => {
    if (!point) {
      return;
    }

    setHoveredPoint(point);
  });

  const handleMouseOut = useDynamicCallback((point: Highcharts.Point) => {
    setHoveredPoint(undefined);
  });

  const options: Options = useMemo(
    () => ({
      type: 'pie',
      tooltip: {
        enabled: false,
      },
      chart: {
        animation: false,
        events: {
          redraw: function (event) {
            handleRedraw(this);
          },
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          type: 'pie',
          id: 'main',
          index: 0,
          borderRadius: 0,
          borderWidth: 1,
          borderColor: theme.backgroundBody,
          fillColor: 'transparent',
          color: 'transparent',
          events: {
            click: function (event) {
              handleClick(event);
            },
          },
          point: {
            events: {
              mouseOver: function (event) {
                handleMouseOver(this);
              },
              mouseOut: function (event) {
                handleMouseOut(this);
              },
            },
          },
        },
      ],
      plotOptions: {
        pie: {
          animation: false,
          innerSize: '85%',
          center: ['50%', '50%'],
          minSize: 200,
          size: '85%', // pie diameter size hardcoded so the labels dont decide how much room is left for the pie

          states: {
            hover: {
              halo: {
                size: 0,
              },
            },
          },

          dataLabels: {
            enabled: true,
            style: {
              textOutline: '0',
              color: theme.colorTextAttention,
              fontWeight: '400',
              textOverflow: 'none',
              fontSize: '14px',
            },
            formatter: function () {
              const percentageString = getPointCustomAttrs(this.point)?.percentageString ?? '';
              return `${this.point.name} ${percentageString}`;
            },
            connectorColor: theme.colorTextMuted,
          },
        },
      },
    }),
    [theme, handleRedraw, handleClick, handleMouseOut, handleMouseOver]
  );

  const chartCenterDetails: { label: string; component: ReactNode } = useMemo(() => {
    if (entities?.length === 0) {
      return { label: centerLabel, component: <Text my="spacingDefault">No data found</Text> };
    }

    if (showing === 'equities' && chartData?.total.lt(0)) {
      return {
        label: 'Total Equity',
        component: <Text my="spacingDefault">Total negative equity - not displayed</Text>,
      };
    }

    if (hoveredPoint) {
      return {
        label: hoveredPoint.name,
        component: (
          <CenterNumericValue
            value={getPointCustomAttrs(hoveredPoint)?.nonAbsValue?.toString() ?? '0'}
            currency={homeCurrency}
          />
        ),
      };
    }

    return {
      label: centerLabel,
      component: <CenterNumericValue value={chartData?.total.toFixed() ?? '0'} currency={homeCurrency} />,
    };
  }, [hoveredPoint, centerLabel, chartData?.total, showing, homeCurrency, entities?.length]);

  return (
    <Box w="100%" h="100%" position="relative" maxHeight="500px">
      {entities == null ? (
        <LoaderTalos />
      ) : (
        <>
          {/* magic number 30 throttle resize makes it look like its perfectly fluid but we still get a bit of help with perf so its not gigaspammed */}
          <PieChart onChartCreated={setChartObject} options={options} throttleResizing={30} />
          {/* Wait to render the center bit until the chart itself has mounted and we've figured out how to place the center bit */}
          {centerStyle != null && (
            <Flex position="absolute" style={{ ...centerStyle, pointerEvents: 'none' }}>
              <Center
                theme={theme}
                snapshotDate={asOf}
                label={chartCenterDetails.label}
                centerComponent={chartCenterDetails.component}
              />
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

const Center = ({
  snapshotDate,
  theme,
  centerComponent,
  label,
}: {
  snapshotDate: string | null;
  theme: DefaultTheme;
  centerComponent: ReactNode;
  label: string;
}) => {
  return (
    <ThemeProvider theme={theme}>
      <VStack data-testid="operations-pie-chart-center">
        <Text color="colorTextSubtle">{label}</Text>
        {centerComponent}
        <AsOfDateBadge size={IndicatorBadgeSizes.Small} snapshotDate={snapshotDate} />
      </VStack>
    </ThemeProvider>
  );
};

const CenterNumericValue = ({ value, currency }: { value: string; currency: string }) => {
  const valueIsNegative = toBigWithDefault(value, 0).lt(0);
  return (
    <HStack alignItems="baseline" gap="spacingSmall" mb="spacingDefault">
      <Text
        weight="fontWeightMedium"
        fontSize="24px"
        color={valueIsNegative ? 'colorTextNegative' : 'colorTextImportant'}
      >
        {value ? portfolioAbbreviation(value) : '--'}
      </Text>
      <Text weight="fontWeightMedium" color="colorTextSubtle" fontSize="18px">
        {currency}
      </Text>
    </HStack>
  );
};

/**
 * getNetData, in contrast to getSplitData, does not split entities by sign. It aggregates within grouping and displays returns net values.
 */
function getNetData<T>({
  entities,
  showBy,
  theme,
  getValue,
  getGroupBy,
  getLabel,
}: {
  entities: T[];
  showBy: ShowByKeys;
  theme: DefaultTheme;
  getValue: OperationsPieProps<T>['getValue'];
  getGroupBy: OperationsPieProps<T>['getGroupBy'];
  getLabel: OperationsPieProps<T>['getLabel'];
}) {
  // Group and sum all together, calculating net values for each grouping
  const groups = groupBy(entities, entity => getGroupBy(entity));
  const dataPoints = lodashMap(groups, (entities, key) => ({
    key,
    // the label of the grouping can be grabbed from any member of the grouped array since that's what they're grouped on
    label: getLabel(entities[0]),
    value: sumBy(entities, b => getValue(b).toNumber()),
  }));

  // Then divide these net values into positives and negatives before mapping into highcharts points
  const negativeNets = dataPoints.filter(dp => dp.value < 0);
  const positiveNets = dataPoints.filter(dp => dp.value > 0);

  const total = dataPoints.reduce((acc, entity) => acc + entity.value, 0);
  const totalBig = toBigWithDefault(total, 0);

  const anyNegativeNetValuePresent = negativeNets.length > 0;

  const positivePoints = getDataPoints({
    dataPoints: positiveNets,
    total: anyNegativeNetValuePresent ? undefined : totalBig,
    showBy,
    direction: 'positive',
    theme,
  });

  const negativePoints = getDataPoints({
    dataPoints: negativeNets,
    total: undefined,
    showBy,
    direction: 'negative',
    theme,
  });

  return {
    data: [...positivePoints, ...negativePoints],
    total: totalBig,
  };
}

/**
 * getSplitData splits the provided entities by value sign (positive and negative) before aggregating within the provided grouping.
 *
 * This means that each group, if it has some amount of positive and negative contributors, will be present twice in the chart. The effect
 * of this is that we do not show net values for any given grouping, we instead show the complete negative and complete positive values.
 */
function getSplitData<T>({
  entities,
  showBy,
  theme,
  getValue,
  getGroupBy,
  getLabel,
}: {
  entities: T[];
  showBy: ShowByKeys;
  theme: DefaultTheme;
  getValue: OperationsPieProps<T>['getValue'];
  getGroupBy: OperationsPieProps<T>['getGroupBy'];
  getLabel: OperationsPieProps<T>['getLabel'];
}) {
  const positiveEntities: T[] = [];
  const negativeEntities: T[] = [];

  for (const entity of entities) {
    const value = getValue(entity);
    if (value.lt(0)) {
      negativeEntities.push(entity);
    } else if (value.gt(0)) {
      positiveEntities.push(entity);
    }
  }

  // Only do relative calcs (percentages) if there are _no negative entities_
  const anyNegativeEntities = negativeEntities.length > 0;

  const total = entities.reduce((acc, entity) => acc.plus(getValue(entity)), Big(0));

  const positiveGroups = groupBy(positiveEntities, entity => getGroupBy(entity));
  const positiveDataPoints = lodashMap(positiveGroups, (entities, key) => ({
    key,
    // the label of the grouping can be grabbed from any member of the grouped array since that's what they're grouped on
    label: getLabel(entities[0]),
    value: sumBy(entities, b => getValue(b).toNumber()),
  }));

  const positiveData = getDataPoints({
    dataPoints: positiveDataPoints,
    total: anyNegativeEntities ? undefined : total,
    showBy,
    direction: 'positive',
    theme,
  });

  const negativeGroups = groupBy(negativeEntities, entity => getGroupBy(entity));
  const negativeDataPoints = lodashMap(negativeGroups, (entities, key) => ({
    key,
    // the label of the grouping can be grabbed from any member of the grouped array since that's what they're grouped on
    label: getLabel(entities[0]),
    value: sumBy(entities, b => getValue(b).toNumber()),
  }));

  const negativeData = getDataPoints({
    dataPoints: negativeDataPoints,
    total: undefined, // we never want to do anything with the total for the negative value case
    showBy,
    direction: 'negative',
    theme,
  });

  return {
    data: [...positiveData, ...negativeData],
    total,
  };
}

function getDataPoints({
  dataPoints,
  total,
  showBy,
  direction,
  theme,
}: {
  dataPoints: { key: string; label: string; value: number }[];
  total: Big | undefined;
  showBy: ShowByKeys;
  direction: BalancesPieDirection;
  theme: DefaultTheme;
}) {
  const sortedDataPoints = sortBy(dataPoints, dp => dp.value).reverse();

  const dataPointsCount = sortedDataPoints.length;
  const startingColor = direction === 'positive' ? theme.colorDataBlue : theme.colors.red.lighten;
  const totalOpacityReduction = 0.6;
  const opacityReductionPerIndex = dataPointsCount === 0 ? 0 : totalOpacityReduction / dataPointsCount;

  const data = sortedDataPoints.map((dp, i) => {
    let color = '';

    // colors
    if (showBy === 'asset' && direction === 'positive') {
      color = getCurrencyColor(dp.key) ?? theme.colorTextDefault;
    } else {
      // show by market _and_ negative direction assets
      // For negative coloring, we color them in the opposite direction. This is because theyre like sorted in opposite directions. Hence this inversion logic
      const colorIndex = direction === 'positive' ? i : sortedDataPoints.length - i - 1;
      color = setAlpha(1 - colorIndex * opacityReductionPerIndex, startingColor);
    }

    return {
      id: `${direction}-${dp.key}`,
      y: Math.abs(dp.value),
      custom: {
        key: dp.key,
        nonAbsValue: dp.value,
        absValue: Math.abs(dp.value),
        percentageString: total ? getSafePercentage(total, dp.value) : undefined,
        direction,
      } satisfies BalancesPiePointCustom,
      color,
      name: dp.label,
    };
  });

  return data;
}

function getSafePercentage(total: Big, value: number): string | undefined {
  const valueBig = toBigWithDefault(value, 0);
  if (total.eq(0) || valueBig.eq(0)) {
    return undefined;
  }

  return `${valueBig.div(total).times(100).toFixed(2)}%`;
}

import type { DockviewApi } from 'dockview';

import { LayoutError } from 'components/Layout/LayoutError';
import { useDockViewProvider } from '../DockViewProvider';

export function DockViewSubLayoutEmptyError({ containerApi }: { containerApi?: DockviewApi }) {
  const { restoreLayout } = useDockViewProvider();

  return <LayoutError handleReset={restoreLayout} containerApi={containerApi} />;
}

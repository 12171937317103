import {
  copyText,
  getConnectionTypeDrawerOptions,
  getCustomerUserDrawerOptions,
  getTimeDrawerOptions,
  IconName,
  useGetDefaultContextMenuItems,
  type ColumnDef,
  type CustomerUser,
  type EntityAdminClass,
  type ICustomerFIXConnection,
  type InputsAndDropdownsDrawerOption,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams } from 'ag-grid-community';
import { useCallback } from 'react';
import { isActiveRuleColumn } from '../columns';

export const CUSTOMER_FIX_CONNECTIONS_COLUMNS: ColumnDef<ICustomerFIXConnection>[] = [
  isActiveRuleColumn,
  {
    type: 'text',
    field: 'ConnectionID',
    width: 170,
    sortable: true,
    sort: '+',
    description: 'Unique ID of this connection',
    hide: true,
  },
  {
    type: 'text',
    field: 'Name',
    width: 180,
    sortable: true,
    sort: '-',
    description: 'Name of this connection',
  },
  {
    type: 'text',
    field: 'CustomerUser',
    width: 170,
    description: 'End user associated with this connection',
  },
  {
    type: 'text',
    field: 'ConnectionType',
    width: 200,
  },
  {
    type: 'text',
    field: 'URL',
    title: 'URL',
    width: 130,
  },
  {
    type: 'text',
    field: 'BeginString',
    width: 130,
    description: 'Usually FIX4.4',
  },
  {
    type: 'text',
    field: 'SenderCompID',
    width: 120,
    description: 'SenderCompId for this connection.',
  },
  {
    type: 'text',
    field: 'TargetCompID',
    width: 150,
  },
  {
    type: 'time',
    field: 'StartTime',
    width: 150,
  },
  {
    type: 'time',
    field: 'EndTime',
    width: 150,
  },
];

export function getCustomerFixConnectionDrawerOptions({
  customerUsers,
}: {
  customerUsers: CustomerUser[];
}): InputsAndDropdownsDrawerOption<ICustomerFIXConnection>[] {
  return [
    {
      field: 'CustomerUser',
      type: 'dropdown',
      title: 'Customer User',
      getIsRequired: () => true,
      getDropdownOptions: () => getCustomerUserDrawerOptions(customerUsers),
    },
    {
      field: 'ConnectionType',
      type: 'dropdown',
      title: 'Connection Type',
      getDropdownOptions: () => getConnectionTypeDrawerOptions(),
      getIsRequired: () => true,
    },
    { type: 'divider' },
    {
      field: 'URL',
      type: 'input',
      title: 'URL',
      getIsRequired: () => true,
      placeholder: 'fix://:port-number',
    },
    {
      field: 'BeginString',
      type: 'input',
      title: 'Begin String',
      getIsRequired: () => true,
      placeholder: 'FIX4.4',
    },
    {
      field: 'SenderCompID',
      type: 'input',
      title: 'SenderCompID',
      getIsRequired: () => true,
    },
    {
      field: 'TargetCompID',
      type: 'input',
      title: 'TargetCompID',
      getIsRequired: () => true,
    },
    { type: 'divider' },
    {
      field: 'StartTime',
      type: 'dropdown',
      title: 'Start Time',
      getDropdownOptions: () => getTimeDrawerOptions(),
    },
    {
      field: 'EndTime',
      type: 'dropdown',
      title: 'End Time',
      getDropdownOptions: () => getTimeDrawerOptions(),
    },
  ];
}

export const useGetCustomerFixConnectionContextMenuItems = () => {
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  return useCallback(
    (selectedEntities: GetContextMenuItemsParams<EntityAdminClass<ICustomerFIXConnection>>) => {
      return [
        {
          icon: `<i class="ag-icon ${IconName.ClipboardCopy}"/>`,
          name: `Copy Customer Details`,
          action: () => {
            if (!selectedEntities.node?.data) {
              return;
            }
            copyText(createCopiedCustomerDetailsTextForFixConnection(selectedEntities.node.data.data));
          },
        },
        'separator',
        ...getDefaultContextMenuItems(selectedEntities),
      ];
    },
    [getDefaultContextMenuItems]
  );
};

export const createCopiedCustomerDetailsTextForFixConnection = (
  fixConnection: Partial<
    Pick<ICustomerFIXConnection, 'BeginString' | 'TargetCompID' | 'SenderCompID' | 'URL' | 'StartTime' | 'EndTime'>
  >
): string => {
  const { BeginString, TargetCompID, SenderCompID, URL, StartTime, EndTime } = fixConnection;
  let text = '';

  const targetPort = URL?.split('fix://').at(1);

  if (TargetCompID) {
    text += `SenderCompID: ${TargetCompID}\n`;
  }
  if (SenderCompID) {
    // this is purposefully reversed
    text += `TargetCompID: ${SenderCompID}\n`;
  }
  if (targetPort) {
    text += `TargetPort: ${targetPort}\n`;
  }
  if (BeginString) {
    text += `BeginString: ${BeginString}\n`;
  }
  if (StartTime) {
    text += `StartTime: ${StartTime}\n`;
  }
  if (EndTime) {
    text += `EndTime: ${EndTime}\n`;
  }
  // remove traing newline
  return text.trimEnd();
};

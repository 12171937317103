import { MultiAndSelectAll } from 'components/MultiAndSelectAll';
import { useCustomerMarketAccounts } from 'providers';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { useState } from 'react';
import { addCustomerAccounts } from '../state/permissions/permissionsSlice';
import type { Warning } from './types';
const ALL_CUSTOMER_ACCOUNTS_LABEL = 'All Customer Accounts';
export function CustomerMarketAccountsDropdown(props: { onWarning: (warning: Warning) => void }) {
  const [selectionsToAdd, setSelectionsToAdd] = useState<string[]>([]);
  const { getCustomerMarketAccounts, customerMarketAccountsByName } = useCustomerMarketAccounts();
  const counterparty = useAppStateSelector(state => state.customerUserDrawer.userDetails.counterparty);
  const customerAccountPermissions = useAppStateSelector(
    state => state.customerUserDrawer.permissions.customerAccounts
  );
  const customerName = useAppStateSelector(state => state.customerUserDrawer.userDetails.displayName);

  const availableSelections = counterparty
    ? getCustomerMarketAccounts(counterparty).map(marketAccount => marketAccount.Name)
    : [];
  const dispatch = useAppStateDispatch();
  return (
    <MultiAndSelectAll
      data-testid="customer-accounts-dropdown"
      placeholder="Select customer account"
      availableSelections={availableSelections}
      selectionsToAdd={selectionsToAdd}
      setSelectionsToAdd={setSelectionsToAdd}
      allSelectionsValue={ALL_CUSTOMER_ACCOUNTS_LABEL}
      getLabel={marketAccount => customerMarketAccountsByName.get(marketAccount)?.SourceAccountID || marketAccount}
      onAddSelections={customerAccounts => {
        if (
          !customerAccountPermissions
            .map(p => p.permissions)
            .flat()
            .find(p => p === 'read')
        ) {
          props.onWarning({
            message: `${
              customerName ?? 'New user'
            } will only be able to view data for the accounts explicitly configured. Data for all other accounts will not be visible.`,
          });
        }
        if (customerAccounts.includes(ALL_CUSTOMER_ACCOUNTS_LABEL)) {
          dispatch(addCustomerAccounts(availableSelections));
        } else {
          dispatch(addCustomerAccounts(customerAccounts));
        }
        setSelectionsToAdd([]);
      }}
    />
  );
}

import { useGlobalToasts } from 'hooks/Toast/useGlobalToasts';
import { useCallback, useState, type ChangeEventHandler, type KeyboardEventHandler } from 'react';
import { useCopyToClipboard } from 'react-use';
import { useTheme } from 'styled-components';
import { HStack } from '../../../Core';

import { defineMessages, useIntl } from 'react-intl';
import { useDisclosure } from '../../../../hooks';
import { Alert, AlertVariants } from '../../../Alert';
import { Button } from '../../../Button';
import { CopyButton } from '../../../CopyButton';
import { Dialog } from '../../../Dialog';
import { FormControlSizes, FormGroup, TextArea } from '../../../Form';
import { Icon, IconName } from '../../../Icons';
import { FormattedMessage } from '../../../Intl';
import { NotificationVariants } from '../../../Notification';
import { Text } from '../../../Text';
import { Tooltip } from '../../../Tooltip';
import type { FilterClause } from '../types';
import { deserializeFilterClauses } from './deserializeFilterClauses';
import { PasteWrapper } from './styles';

const messages = defineMessages({
  copyFilters: {
    defaultMessage: 'Copy Filters',
    id: 'Filters.FilterBuilder.CopyPasteFilter.copyFilters',
  },
  copiedFilters: {
    defaultMessage: 'Copied Filters',
    id: 'Filters.FilterBuilder.CopyPasteFilter.copiedFilters',
  },
  pasteFilters: {
    defaultMessage: 'Paste Filters',
    id: 'Filters.FilterBuilder.CopyPasteFilter.pasteFilters',
  },
  create: {
    defaultMessage: 'Create',
    id: 'Filters.FilterBuilder.CopyPasteFilter.create',
  },
  filterInstructions: {
    defaultMessage:
      'Paste a filter provided by copying (<clipboardIcon></clipboardIcon>). Your new filter will overwrite your current tab.',
    id: 'Filters.FilterBuilder.CopyPasteFilter.filterInstructions',
  },
  invalidFilters: {
    defaultMessage: 'The pasted filters are invalid.',
    id: 'Filters.FilterBuilder.CopyPasteFilter.invalidFilters',
  },
  invalidFiltersCopiedToClipboard: {
    defaultMessage: 'Invalid filters copied to clipboard, try copying again.',
    id: 'Filters.FilterBuilder.CopyPasteFilter.invalidFiltersCopiedToClipboard',
  },
  reuseCopiedFilter: {
    defaultMessage: 'Reuse a copied filter',
    id: 'Filters.FilterBuilder.CopyPasteFilter.reuseCopiedFilter',
  },
});

export const CopyPasteFilter = ({
  currentFilterClauses,
  onSubmitCopiedFilterClauses,
}: {
  currentFilterClauses: FilterClause[];
  onSubmitCopiedFilterClauses: (filterClauses: FilterClause[]) => void;
}) => {
  const theme = useTheme();
  const { add: addToast } = useGlobalToasts();
  const { formatMessage } = useIntl();

  const [{ value: reactClipBoard }, copyToReactClipBoard] = useCopyToClipboard();

  const [pastedText, setPastedText] = useState<string>('');
  const [touched, setTouched] = useState(false);
  const pastedFilterClauses = deserializeFilterClauses(pastedText);
  const invalid = !pastedFilterClauses.valid;

  const handleOnDialogSubmit = useCallback(() => {
    const { valid, filterClauses } = pastedFilterClauses;
    if (valid) {
      onSubmitCopiedFilterClauses(filterClauses);
    }
  }, [pastedFilterClauses, onSubmitCopiedFilterClauses]);

  const handleOnDialogTextChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(e => {
    setPastedText(e.target.value);
    setTouched(true);
  }, []);

  const handleOnDialogClose = useCallback(() => {
    setPastedText('');
    setTouched(false);
  }, []);

  const pasteDialog = useDisclosure({
    onClose: handleOnDialogClose,
  });
  const handleOnKeyDown = useCallback<KeyboardEventHandler<HTMLTextAreaElement>>(
    e => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        if (!invalid) {
          handleOnDialogSubmit();
          pasteDialog.close();
        }
      }
    },
    [invalid, handleOnDialogSubmit, pasteDialog]
  );

  const handleOnClickCopy = useCallback(async () => {
    const json = JSON.stringify(currentFilterClauses);
    await navigator.clipboard?.writeText(json);
    copyToReactClipBoard(json);
    addToast({
      text: <FormattedMessage {...messages.copiedFilters} />,
      variant: NotificationVariants.Positive,
    });
  }, [addToast, copyToReactClipBoard, currentFilterClauses]);

  // We do not want to read the users clipboard, since that might have permission implications,
  // instead we rely on react-managed clipboard. If that does not exist, we open modal to allow for paste of config.
  const handleOnClickPaste = useCallback(() => {
    if (reactClipBoard) {
      const { valid, filterClauses } = deserializeFilterClauses(reactClipBoard);
      if (valid) {
        return onSubmitCopiedFilterClauses(filterClauses);
      } else {
        addToast({
          text: <FormattedMessage {...messages.invalidFiltersCopiedToClipboard} />,
          variant: NotificationVariants.Negative,
        });
      }
    } else {
      pasteDialog.open();
    }
  }, [addToast, onSubmitCopiedFilterClauses, pasteDialog, reactClipBoard]);

  return (
    <>
      <HStack gap="spacingSmall" flexWrap="wrap" alignItems="stretch">
        <Tooltip
          tooltip={<FormattedMessage {...messages.copyFilters} />}
          placement="left"
          delay={0}
          disabled={currentFilterClauses.length === 0}
        >
          <CopyButton
            disabled={currentFilterClauses.length === 0}
            size={FormControlSizes.Default}
            onClick={handleOnClickCopy}
          />
        </Tooltip>
        <Tooltip tooltip={<FormattedMessage {...messages.pasteFilters} />} placement="left" delay={0}>
          <Button size={FormControlSizes.Default} onClick={handleOnClickPaste}>
            <Icon icon={IconName.ClipboardPaste} />
          </Button>
        </Tooltip>
      </HStack>
      <Dialog
        {...pasteDialog}
        onConfirm={handleOnDialogSubmit}
        confirmLabel={formatMessage(messages.create)}
        title={formatMessage(messages.reuseCopiedFilter)}
        width={450}
        confirmDisabled={invalid}
        autoFocusFirstElement={false}
        showClose={true}
      >
        <PasteWrapper>
          <Alert variant={AlertVariants.Info} dismissable={false} background={theme.colors.gray['040']}>
            <Text>
              <FormattedMessage
                {...messages.filterInstructions}
                values={{ clipboardIcon: () => <Icon icon={IconName.ClipboardCopy} /> }}
              />
            </Text>
          </Alert>
          <FormGroup
            textAlign="left"
            gap={theme.spacingMedium}
            error={touched && invalid ? formatMessage(messages.invalidFilters) : undefined}
            w="100%"
          >
            <TextArea
              placeholder={'[{"key":"Sides","type":"inclusive","selections":["Buy"]}]'}
              autoFocus
              rows={8}
              value={pastedText}
              onChange={handleOnDialogTextChange}
              invalid={touched && invalid}
              onKeyDown={handleOnKeyDown}
            />
          </FormGroup>
        </PasteWrapper>
      </Dialog>
    </>
  );
};

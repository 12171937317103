import {
  Accordion,
  AccordionBody,
  Box,
  FilterBuilder,
  FilterBuilderClearAllButton,
  FilterBuilderToggleButton,
  FormControlSizes,
  HStack,
  useAccordionFilterBuilder,
  usePortal,
  type FilterClause,
} from '@talos/kyoko';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useSettlementMonitoringFilters } from '../providers/SettlementMonitoringFiltersProvider';
import { useSettlementMonitoringInteractions } from '../providers/SettlementMonitoringInteractionsProvider';
import { useSettlementMonitoringState } from '../providers/SettlementMonitoringStateProvider';
import { POSITION_MONITORING_BLOTTER_BUTTONS_PORTAL_ID } from '../tokens';
import { GroupByControl } from './GroupByControl';
import { GroupByCounterpartyControl } from './GroupByCounterpartyControl';

interface ControlPanelProps {
  initialFilterClauses: FilterClause[];
}

export const ControlPanel = ({ initialFilterClauses }: ControlPanelProps) => {
  const { filterableProperties, onFilterClausesChanged } = useSettlementMonitoringFilters();

  const { accordion, filterBuilder, openClause } = useAccordionFilterBuilder({
    filterBuilderProps: {
      properties: filterableProperties,
      initialFilterClauses,
      onFilterClausesChanged,
    },
  });

  // Register the openClause function with the interactions provider so anyone on the page can add filter clauses
  const { setOpenClause } = useSettlementMonitoringInteractions();
  useEffect(() => {
    setOpenClause(() => {
      return openClause;
    });
  }, [openClause, setOpenClause]);

  const { setPortalRef } = usePortal(POSITION_MONITORING_BLOTTER_BUTTONS_PORTAL_ID);

  const { state } = useSettlementMonitoringState();

  return (
    <ControlPanelContent w="100%">
      <HStack px="spacingDefault" gap="spacingDefault" justifyContent="space-between" py="spacingDefault">
        <HStack gap="spacingDefault">
          <GroupByControl />
          {state.groupBy === 'account' && <GroupByCounterpartyControl />}
        </HStack>
        <HStack gap="spacingDefault">
          <Box ref={setPortalRef} />
          <FilterBuilderClearAllButton
            removeAllFilterClauses={filterBuilder.removeAllFilterClauses}
            disabled={filterBuilder.filterClauses.length === 0}
            size={FormControlSizes.Small}
          />
          <FilterBuilderToggleButton
            filterClauses={filterBuilder.filterClauses}
            isOpen={accordion.isOpen}
            onClick={() => accordion.toggle()}
            size={FormControlSizes.Small}
          />
        </HStack>
      </HStack>
      <Accordion {...accordion}>
        <AccordionBody borderTopWhenOpened>
          <FilterBuilder {...filterBuilder} />
        </AccordionBody>
      </Accordion>
    </ControlPanelContent>
  );
};

const ControlPanelContent = styled(Box)`
  border-bottom: 2px solid ${({ theme }) => theme.backgroundBody};
  border-top: 2px solid ${({ theme }) => theme.backgroundBody};
  background: ${({ theme }) => theme.colors.gray.main};
`;

import type { SubscriptionResponse } from '@talos/kyoko';
import {
  UpdateActionEnum,
  logger,
  type CurrencyConversionRate,
  type DataResponse,
  type RequestStream,
} from '@talos/kyoko';
import type { BigSource } from 'big.js';
import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';
import { scan } from 'rxjs/operators';

export interface Limit {
  Mode: string;
  Description?: string;
  User?: string;
  UserGroupID?: string;
  Symbol?: string;
  Currency?: string;
  RejectThreshold: string;
  WarnThreshold: string;
  ThresholdCurrency: string;
  UpdateAction: UpdateActionEnum;
  Timestamp: string;
  TradingLimitID: string;
}

export interface ValidationResult {
  warn: boolean;
  reject: boolean;
  limit?: Limit;
}

export interface ConversionRequest extends RequestStream {
  EquivalentCurrency: string;
  Currencies: string[];
}

export interface TradingLimitsContextProps {
  maxOrderSizeByKey: Observable<Map<string, Limit>>;
  conversionRates: Map<string, CurrencyConversionRate>;
  validateMaxOrderSize: (sizeHomeCurrency: BigSource, symbol: string | undefined) => ValidationResult;
  listMaxOrderSizeLimits: () => Promise<DataResponse<Limit>>;
  getMaxOrderSizeLimit: (id: string) => Promise<Limit>;
  createMaxOrderSizeLimit: (limit: Limit) => Promise<any>;
  updateMaxOrderSizeLimit: (id: string, limit: Limit) => Promise<void>;
  deleteMaxOrderSizeLimit: (id: string) => Promise<void>;
}

export const TradingLimitsContext = createContext<TradingLimitsContextProps | undefined>(undefined);
TradingLimitsContext.displayName = 'TradingLimitsContext';

export const useTradingLimits = () => {
  const context = useContext(TradingLimitsContext);
  if (context === undefined) {
    throw new Error('Missing TradingLimitsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

// Bitmasks
export const MATCHERS = {
  NONE: 0,
  USER: 1,
  USER_GROUP: 2,
  SECURITY: 4,
  CURRENCY: 8,
};

export const RULES = [
  MATCHERS.USER | MATCHERS.SECURITY,
  MATCHERS.USER | MATCHERS.CURRENCY,
  MATCHERS.USER,
  MATCHERS.USER_GROUP | MATCHERS.SECURITY,
  MATCHERS.USER_GROUP | MATCHERS.CURRENCY,
  MATCHERS.USER_GROUP,
  MATCHERS.SECURITY,
  MATCHERS.CURRENCY,
  MATCHERS.NONE,
];

export const createLimitMatcher = (limit: Limit) => {
  let matcher = MATCHERS.NONE;
  if (limit.User) {
    matcher |= MATCHERS.USER;
  }
  if (limit.UserGroupID) {
    matcher |= MATCHERS.USER_GROUP;
  }
  if (limit.Symbol) {
    matcher |= MATCHERS.SECURITY;
  }
  if (limit.Currency) {
    matcher |= MATCHERS.CURRENCY;
  }
  return matcher;
};

export const createLimitKey = (
  matcher: number,
  user: string | undefined,
  userGroupID: string | undefined,
  symbol: string | undefined,
  currency: string | undefined
) => {
  const key: Partial<Limit> = {};
  if (matcher & MATCHERS.USER) {
    key.User = user;
  }
  if (matcher & MATCHERS.USER_GROUP) {
    key.UserGroupID = userGroupID;
  }
  if (matcher & MATCHERS.SECURITY) {
    key.Symbol = symbol;
  }
  if (matcher & MATCHERS.CURRENCY) {
    key.Currency = currency;
  }

  return JSON.stringify(key);
};

export function mapMaxOrderSizeLimits(source: Observable<SubscriptionResponse<Limit>>) {
  return source.pipe(
    scan(
      ({ byID, byKey }, json) => {
        for (const limit of json.data) {
          const matcher = createLimitMatcher(limit);
          const key = createLimitKey(matcher, limit.User, limit.UserGroupID, limit.Symbol, limit.Currency);
          const limitByKey = byKey.get(key);

          // Sanity check that we don't add limits with identical keys
          if (limitByKey != null && limitByKey.TradingLimitID !== limit.TradingLimitID) {
            logger.error(new Error(`Max order size limit key matches multiple entries, ignoring limit`), {
              extra: {
                maxOrderSizeLimit: JSON.stringify(limit),
              },
            });
            continue;
          }

          const oldLimit = byID.get(limit.TradingLimitID);
          if (oldLimit != null) {
            const oldMatcher = createLimitMatcher(oldLimit);
            const oldKey = createLimitKey(
              oldMatcher,
              oldLimit.User,
              oldLimit.UserGroupID,
              oldLimit.Symbol,
              oldLimit.Currency
            );
            byKey.delete(oldKey);
          }

          switch (limit.UpdateAction) {
            case UpdateActionEnum.Update:
              byID.set(limit.TradingLimitID, limit);
              byKey.set(key, limit);
              break;
            case UpdateActionEnum.Remove:
              byID.delete(limit.TradingLimitID);
              break;
          }
        }
        return { byID, byKey };
      },
      {
        byID: new Map<string, Limit>(),
        byKey: new Map<string, Limit>(),
      }
    )
  );
}

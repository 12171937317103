import { AuthContextProvider, BaseStyle, PortalContextProvider, usePreferredTheme } from '@talos/kyoko';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { AppRoutes } from 'containers/Routes';
import { startCase } from 'lodash-es';
import { UserContextProvider } from 'providers/UserContextProvider';
import { useEffect, useState } from 'react';
import { StyleSheetManager } from 'styled-components';
import { GlobalStyle } from 'styles/GlobalStyle';
import { ThemeProvider } from 'styles/ThemeProvider';
import { STYLE_TAG_ID } from './components/Layout/tokens';
import { useThemeTypePreference } from './hooks/useThemeTypePreference';

export default function App() {
  const isProd = import.meta.env.VITE_AVA_ENV === 'prod';
  const { themeType } = useThemeTypePreference();
  const preferredTheme = usePreferredTheme('DarkTalosTheme', themeType);
  const [stylesTarget, setStylesTarget] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const el = document.getElementById(STYLE_TAG_ID);
    if (el) {
      setStylesTarget(document.getElementById(STYLE_TAG_ID));
    }
  }, []);

  useEffect(() => {
    document.title = `Talos ${isProd ? 'Trading' : `- ${startCase(import.meta.env.VITE_AVA_ENV)}`}`;
  }, [themeType, isProd]);

  useEffect(() => {
    const meta = document.querySelector('meta[name="theme-color"]');
    if (meta) {
      meta.setAttribute('content', preferredTheme.backgroundContent);
    }
  }, [preferredTheme]);

  return (
    // Top level theme provider to make sure any error boundaries are styled correctly
    <ThemeProvider theme={preferredTheme}>
      <BaseStyle />
      <GlobalStyle />
      <div id={STYLE_TAG_ID} />
      {stylesTarget && (
        <StyleSheetManager disableCSSOMInjection={true} target={stylesTarget}>
          <ErrorBoundary>
            <PortalContextProvider>
              <AuthContextProvider
                loginUrl={import.meta.env.VITE_AUTH_LOGIN_URL}
                logoutUrl={import.meta.env.VITE_AUTH_LOGOUT_URL}
                sessionCookieName={import.meta.env.VITE_SESSION_COOKIE}
              >
                {/* Note: This User Context Provider should remain above the WebSocketClientProvider - if not, see note in WebSocketClientProvider */}
                <UserContextProvider>
                  <AppRoutes />
                </UserContextProvider>
              </AuthContextProvider>
            </PortalContextProvider>
          </ErrorBoundary>
        </StyleSheetManager>
      )}
    </ThemeProvider>
  );
}

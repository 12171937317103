import { isError } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import {
  ACTION,
  AccordionGroup,
  AccordionTable,
  AggregationType,
  Box,
  Button,
  ButtonVariants,
  Dialog,
  IconName,
  Input,
  LoaderSizes,
  NotificationVariants,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  Text,
  useDisclosure,
  useGlobalToasts,
  type AccordionTableColumn,
} from '@talos/kyoko';

import { Loader, LoaderWrapper } from 'components/Loader';
import { useRoleAuth } from 'hooks';
import type { Aggregation } from 'types';
import { useAggregationRequests, useAggregationsQuery } from '../../../hooks/useAggregationRequests';
import { AggregationAccordion } from './AggregationAccordion';
import { NewAggregationDialog } from './NewAggregationDialog';

const accordionTableColumns: AccordionTableColumn[] = [
  {
    id: 'displayName',
    label: 'Display Name',
  },
  {
    id: 'name',
    label: 'Name',
  },
];

export function CustomerAggregations() {
  const { createAggregation, deleteAggregation, updateAggregation } = useAggregationRequests();
  const aggregationsQuery = useAggregationsQuery();
  const customerAggregations = useMemo(
    () => aggregationsQuery.data?.filter(agg => agg.AggregationType === AggregationType.Customer),
    [aggregationsQuery]
  );

  const [isAdding, setAdding] = useState(false);
  const { add: addToast } = useGlobalToasts();
  const [displayName, setDisplayName] = useState<string>('');
  const [selectedAggregation, setSelectedAggregation] = useState<Aggregation | null>();
  const { isAuthorized } = useRoleAuth();

  const newAggregationDialog = useDisclosure();
  const editDisplayNameDialog = useDisclosure();

  const handleEditAggregation = useCallback(
    (a: Aggregation) => {
      setDisplayName(a.DisplayName);
      setSelectedAggregation(a);
      editDisplayNameDialog.open();
    },
    [editDisplayNameDialog]
  );

  const handleAddAggregation = useCallback(
    async ({ name, displayName }: { name: string; displayName: string }) => {
      setAdding(true);
      try {
        await createAggregation({ Name: name, DisplayName: displayName, AggregationType: AggregationType.Customer });
        newAggregationDialog.close();
      } catch (e: unknown) {
        let errorMessage = e;
        if (isError(e)) {
          errorMessage = e.message;
        }
        addToast({
          text: `Could not create aggregation: ${errorMessage}`,
          variant: NotificationVariants.Negative,
        });
      }
      setAdding(false);
    },
    [addToast, newAggregationDialog, createAggregation]
  );

  const handleDeleteAggregation = useCallback(
    async (name: string) => {
      try {
        await deleteAggregation(name);
      } catch (e) {
        let errorMessage = e;
        if (isError(e)) {
          errorMessage = e.message;
        }
        addToast({
          text: `Could not delete aggregation: ${errorMessage}`,
          variant: NotificationVariants.Negative,
        });
      }
    },
    [addToast, deleteAggregation]
  );

  return (
    <Panel>
      <PanelHeader>
        <h2>Customer Aggregations</h2>
        <PanelActions>
          <Button
            startIcon={IconName.Plus}
            onClick={() => newAggregationDialog.open()}
            variant={ButtonVariants.Positive}
            disabled={!isAuthorized(ACTION.DEALER_TRADING)}
          >
            Add Aggregation
          </Button>
        </PanelActions>
      </PanelHeader>
      <PanelContent>
        {customerAggregations == null ? (
          <LoaderWrapper>
            <Loader size={LoaderSizes.SMALL} />
          </LoaderWrapper>
        ) : (
          <AccordionGroup>
            <AccordionTable columns={accordionTableColumns} stickyHeader={true}>
              {customerAggregations.map(aggregation => (
                <AggregationAccordion
                  key={aggregation.Name}
                  aggregation={aggregation}
                  onDeleteAggregation={handleDeleteAggregation}
                  onEditAggregation={handleEditAggregation}
                />
              ))}
            </AccordionTable>
          </AccordionGroup>
        )}
        <NewAggregationDialog
          {...newAggregationDialog}
          title="New Aggregation"
          onConfirm={handleAddAggregation}
          confirmLabel="Add New Aggregation"
          showClose={true}
          confirmDisabled={isAdding}
          confirmLoading={isAdding}
        />
        <Dialog
          {...editDisplayNameDialog}
          title="Edit Display Name"
          onConfirm={() => updateAggregation({ ...selectedAggregation, DisplayName: displayName })}
          confirmLabel="Confirm"
          showClose={true}
          width="350px"
          stretchButtons
        >
          <Box mb="spacingDefault" textAlign="left">
            <Text>
              Display Name of: <Text color="colorTextImportant">{selectedAggregation?.Name}</Text>
            </Text>
          </Box>
          <Input
            value={displayName}
            autoComplete="off"
            onChange={e => setDisplayName(e.target.value)}
            data-testid="edit-aggregation-display-name-input"
          />
        </Dialog>
      </PanelContent>
    </Panel>
  );
}

import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

const lightSeliniColors: Colors = {
  ...lightColors,

  gray: {
    '000': '#DBDDE2',
    '010': '#E8E9ED',
    main: '#F4F6F9',
    '020': '#EFF1F4',
    '030': '#E1E3E6',
    '040': '#D3D4D8',
    '050': '#C9CBCF',
    '060': '#BFC1C6',
    '070': '#B3B4BB',
    '080': '#94969C',
    '090': '#65656E',
    '100': '#1E1E28',
  },
  green: {
    default: '#2e7e57',
    lighten: '#4caf50',
    dim: setAlpha(0.4, '#2e7e57'),
    mute: setAlpha(0.1, '#2e7e57'),
  },
  red: {
    default: '#b74638',
    lighten: '#c6392f',
    dim: setAlpha(0.4, '#b74638'),
    mute: setAlpha(0.1, '#b74638'),
  },
  blue: {
    default: '#42a5f5',
    lighten: '#64b5f6',
    dim: setAlpha(0.4, '#42a5f5'),
    mute: setAlpha(0.1, '#42a5f5'),
  },
  yellow: {
    default: '#fdd835',
    lighten: '#ffeb3b',
    dim: setAlpha(0.4, '#fdd835'),
    mute: setAlpha(0.1, '#fdd835'),
  },
  primary: {
    default: '#3949ab',
    lighten: '#3f51b5',
    dim: setAlpha(0.4, '#3949ab'),
    mute: setAlpha(0.1, '#3949ab'),
  },
};

export const LightSeliniCapitalTheme: DefaultTheme = mapLightTheme(lightSeliniColors, {
  name: 'LightSC',

  backgroundHeader: 'linear-gradient(60deg, #f5f7fa 0%, #c3cfe2 100%)',
  backgroundSidebar: 'linear-gradient(180deg, #f5f7fa 0%, #c3cfe2 100%)',
  backgroundBody: '#eceff1',

  backgroundSidebarItemActive: '#c5d0e3',
  backgroundSidebarItemHover: '#c5d0e3',
  backgroundContent: 'linear-gradient(180deg, #F8FAFC, #e9edf4)',

  borderRadiusButtonDefault: 0,
  borderRadiusButtonLarge: 0,
  borderRadiusButtonSmall: 0,
  borderRadiusButtonTiny: 0,
  borderRadiusCard: 0,
  borderRadiusContent: 0,
  borderRadiusBig: 0,
  borderRadiusDefault: 0,
  borderRadiusHuge: 0,
  borderRadiusLarge: 0,
  borderRadiusMedium: 0,
  borderRadiusLayoutWrapper: 0,
  borderRadiusSmall: 0,
  borderRadiusTiny: 0,
});

import { Box } from '@talos/kyoko';
import styled from 'styled-components';
import { MakeDefaultMarketAccountButton } from '../../DefaultMarketAccount/styles';

export const StyleWrapper = styled(Box)`
  .ag-pinned-left-cols-container,
  .ag-pinned-left-sticky-top {
    .ag-row {
      border-left: 3px solid transparent;
    }

    .market-selector-row-selected {
      border-left-color: var(--colors-primary-lighten);
    }
  }

  .market-selector-row-selected {
    background-color: var(--colors-gray-050);
  }

  .ag-row {
    ${MakeDefaultMarketAccountButton} {
      display: none;
    }

    &.ag-row-hover {
      ${MakeDefaultMarketAccountButton} {
        display: inline-flex;
      }
    }
  }
`;

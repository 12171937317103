import { Direction, Grid, useObservableValue } from '@talos/kyoko';
import styled from 'styled-components';
import { KPI, KPITypes } from '../../../../components/KPI';
import { useSubAccounts } from '../../../../providers';
import { PORTFOLIO_OVERVIEW_HUD_TOOLTIPS } from '../../PortfolioManagement/components/PortfolioOverview/PortfolioOverviewHUD';
import { usePortfolioViewStateSelector } from '../../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import { useOperationsOverviewPositions } from '../providers/OperationsOverviewPositionsProvider';

export const PMMarginHUD = () => {
  const { selectedPortfolioId } = usePortfolioViewStateSelector();
  const { subAccountsByID } = useSubAccounts();

  const { subAccountPositionsByAccountObs, hasDerivs } = useOperationsOverviewPositions();
  const subAccountPositionsByAccount = useObservableValue(
    () => subAccountPositionsByAccountObs,
    [subAccountPositionsByAccountObs]
  );

  const selectedPortfolioName =
    selectedPortfolioId != null ? subAccountsByID.get(selectedPortfolioId)?.Name : undefined;
  const subAccPosition = selectedPortfolioName ? subAccountPositionsByAccount?.get(selectedPortfolioName) : undefined;

  // We dont want to show any values in these KPI boxes if there are no derivs underpinning the page
  const showEmpties = !hasDerivs;
  const isLoading = !subAccPosition && !showEmpties;

  return (
    <Wrapper>
      <KPI
        label="EMR"
        isLoading={isLoading}
        value={showEmpties ? undefined : subAccPosition?.metric.EquityMarginRatio}
        type={KPITypes.Number}
        tooltip={PORTFOLIO_OVERVIEW_HUD_TOOLTIPS.EMR}
        positiveDirection={Direction.Asc}
        dataTestID="pm-margin-hud-emr"
      />
      <KPI
        label="Net Leverage Ratio"
        isLoading={isLoading}
        value={showEmpties ? undefined : subAccPosition?.metric.NetLeverageRatio}
        type={KPITypes.Number}
        positiveDirection={Direction.Asc}
        tooltip={PORTFOLIO_OVERVIEW_HUD_TOOLTIPS.NetLeverageRatio}
        dataTestID="pm-margin-hud-net-leverage-ratio"
      />
      <KPI
        label="Gross Leverage Ratio"
        isLoading={isLoading}
        value={showEmpties ? undefined : subAccPosition?.metric.GrossLeverageRatio}
        type={KPITypes.Number}
        positiveDirection={Direction.Asc}
        tooltip={PORTFOLIO_OVERVIEW_HUD_TOOLTIPS.GrossLeverageRatio}
        dataTestID="pm-margin-hud-gross-leverage-ratio"
      />
    </Wrapper>
  );
};

const Wrapper = styled(Grid)`
  width: 100%;
  overflow-x: hidden;
  gap: calc(var(--spacing2) * 1px);
  min-height: 5.125rem;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  margin-bottom: calc(var(--spacing2) * 1px);
`;

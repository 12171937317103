import {
  Box,
  type BoxProps,
  Divider,
  FormControlSizes,
  HelpIcon,
  HStack,
  InlineFormattedNumber,
  LoaderTalos,
  ProductTypeEnum,
  Text,
  toBigWithDefault,
  Toggle,
  ToggleHorizontal,
  type ToggleHorizontalOption,
  useCallbackRef,
  useCurrency,
  useMarketAccountsContext,
  useSyncedRef,
  VStack,
} from '@talos/kyoko';
import Big from 'big.js';
import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { Module } from '../../components/Module';
import { useOperationsOverviewConfig } from '../providers/OperationsOverviewConfigProvider';
import { useOperationsOverviewInteractions } from '../providers/OperationsOverviewInteractionsProvider';
import { OpsAccountPosition, OpsPosition } from '../types';
import { OpsBalancesChart } from './OpsBalancesChart';
import { OpsBalancesChartTooltip } from './OpsBalancesChartTooltip';
import type { OpsBalancesChartDimension } from './types';

type OpsBalancesChartModuleProps = {
  positions: OpsPosition[] | undefined;
  accountPositions: OpsAccountPosition[] | undefined;
};

/**
 * We pass both types to the chart. Based on the dimension we are showing by, we will only include one
 * or the other in the output to the chart itself. The getters like getLabel, getValue, etc will then handle
 * what to show based on the specific type of entity (Position- or Account-level) themselves
 */
type ChartedEntity = OpsPosition | OpsAccountPosition;

/**
 * Wraps the chart itself, provides the correct props, connects to contexts, etc
 */
export const OpsBalancesChartModule = ({ positions, accountPositions }: OpsBalancesChartModuleProps) => {
  const theme = useTheme();
  const { homeCurrency } = useDisplaySettings();
  const homeCurrencyInfo = useCurrency(homeCurrency);
  const { marketAccountsByName } = useMarketAccountsContext();
  const marketAccountsByNameRef = useSyncedRef(marketAccountsByName);
  const { goToGroupRow } = useOperationsOverviewInteractions();

  const {
    opsOverviewShowBy,
    opsOverviewBalancesChartShowByAsset: showByAsset,
    opsOverviewBalancesChartDimension: dimension,
    updateOpsOverviewBalancesChartShowByAsset,
    updateOpsOverviewBalancesChartDimension,
  } = useOperationsOverviewConfig();

  const dimensionOptions: ToggleHorizontalOption<OpsBalancesChartDimension>[] = useMemo(() => {
    return [
      {
        value: 'balances',
        displayName: 'Balances',
      },
      {
        value: 'delta',
        displayName: `Model Delta ${homeCurrency}`,
      },
    ];
  }, [homeCurrency]);

  const getValue = useCallback(
    (position: ChartedEntity) => {
      if (dimension === 'balances' && position instanceof OpsPosition) {
        return position.AssetType === ProductTypeEnum.Spot ? toBigWithDefault(position.Equivalent?.Amount, 0) : Big(0);
      } else if (dimension === 'delta' && position instanceof OpsAccountPosition) {
        return toBigWithDefault(position.metric.Equivalent?.NetDeltaExposure, 0);
      }

      return Big(0);
    },
    [dimension]
  );

  // We pass through both types of entities merged. The getters for value then handle conditional handling of these.
  const chartedEntities: ChartedEntity[] = useMemo(() => {
    if (!positions || !accountPositions) {
      return [];
    }
    return dimension === 'balances' ? positions : accountPositions;
  }, [dimension, positions, accountPositions]);

  const sums = useMemo(() => {
    if (!positions || !accountPositions) {
      return {};
    }

    let negativeValueSum = Big(0);
    let positiveValueSum = Big(0);
    let uPnLSum = Big(0);

    if (dimension === 'balances') {
      for (const position of positions) {
        if (position.AssetType === ProductTypeEnum.Spot) {
          const amountBig = toBigWithDefault(position.Equivalent?.Amount, 0);
          if (amountBig.gt(0)) {
            positiveValueSum = positiveValueSum.plus(amountBig);
          } else {
            negativeValueSum = negativeValueSum.plus(amountBig);
          }
        } else {
          // derivative
          uPnLSum = uPnLSum.plus(toBigWithDefault(position.Equivalent?.UnrealizedPnL, 0));
        }
      }
    }

    if (dimension === 'delta') {
      for (const position of positions) {
        uPnLSum = uPnLSum.plus(toBigWithDefault(position.Equivalent?.UnrealizedPnL, 0));
      }

      for (const accountPosition of accountPositions) {
        const deltaEquivBig = toBigWithDefault(accountPosition.metric.Equivalent?.NetDeltaExposure, 0);
        if (deltaEquivBig.gt(0)) {
          positiveValueSum = positiveValueSum.plus(deltaEquivBig);
        } else {
          negativeValueSum = negativeValueSum.plus(deltaEquivBig);
        }
      }
    }

    return {
      negativeValueSum,
      positiveValueSum,
      uPnLSum,
      netValueSum: positiveValueSum.plus(negativeValueSum),
    };
  }, [positions, accountPositions, dimension]);

  const getLabel = useCallback(
    (position: ChartedEntity) => {
      if (showByAsset && position instanceof OpsPosition) {
        return position.underlying;
      }

      const mktAccName = position instanceof OpsPosition ? position.MarketAccount : position.metric.AccountName;
      const mktAcc = marketAccountsByNameRef.current.get(mktAccName);
      return mktAcc?.DisplayName ?? mktAccName;
    },
    [showByAsset, marketAccountsByNameRef]
  );

  const getGroupBy = useCallback(
    (position: ChartedEntity) => {
      if (showByAsset && position instanceof OpsPosition) {
        return position.underlying;
      }

      // by market account

      return position instanceof OpsPosition ? position.MarketAccount : position.metric.AccountName;
    },
    [showByAsset]
  );

  const getTooltip = useCallback(
    (point: Highcharts.Point) => {
      return (
        <OpsBalancesChartTooltip
          point={point}
          dimension={dimension}
          showingByAsset={showByAsset}
          opsOverviewShowBy={opsOverviewShowBy}
          homeCurrency={homeCurrencyInfo}
          theme={theme}
        />
      );
    },
    [dimension, showByAsset, opsOverviewShowBy, homeCurrencyInfo, theme]
  );

  const handlePointClick = useCallbackRef((pointKey: string) => {
    // If the chart is grouping by asset then there is nowhere unique to go in the blotter, so stop this event here
    if (!showByAsset) {
      goToGroupRow?.(pointKey);
    }
  });

  const handleShowByAssetChange = useCallback(
    (newShowByAsset: boolean) => {
      updateOpsOverviewBalancesChartShowByAsset(newShowByAsset);
    },
    [updateOpsOverviewBalancesChartShowByAsset]
  );

  const handleDimensionChanged = useCallback(
    (newDimension: OpsBalancesChartDimension) => {
      updateOpsOverviewBalancesChartDimension(newDimension);
      // If the user switches to see data by Delta and they're in by asset mode, we remove by asset mode
      if (newDimension === 'delta' && showByAsset) {
        handleShowByAssetChange(false);
      }
    },
    [updateOpsOverviewBalancesChartDimension, showByAsset, handleShowByAssetChange]
  );

  const moduleLabelPrefix = dimension === 'balances' ? 'Balances' : 'Model Delta';
  const moduleLabelSuffix = showByAsset ? 'Asset' : 'Market Account';
  const summaryLabelSuffix = dimension === 'balances' ? 'Balance' : 'Model Delta';
  const chartValueAxisTitle = dimension === 'balances' ? `Balance (${homeCurrency})` : `Model Delta (${homeCurrency})`;

  return (
    <Module
      data-testid="balances-chart-module"
      title={
        <HStack gap="spacingSmall">
          <Text>{`${moduleLabelPrefix} by ${moduleLabelSuffix}`}</Text>
          <HelpIcon tooltip="Balances includes spot asset exposure only; Model Delta uses the Talos built-in risk model for delta-weighted exposure for both spot and derivatives." />
        </HStack>
      }
      flex="1 1 50%"
      h="100%"
      suffix={
        <HStack gap="spacingDefault" h="100%" p="spacingDefault">
          <Toggle
            size={FormControlSizes.Small}
            checked={showByAsset}
            onChange={handleShowByAssetChange}
            /* You cant showByAsset when looking at delta */
            disabled={!showByAsset && dimension === 'delta'}
            label="View by Asset"
            data-testid="view-by-asset-toggle"
          />

          <Divider orientation="vertical" />
          <Text fontSize="fontSizeSm">Dimension</Text>
          <ToggleHorizontal value={dimension} options={dimensionOptions} onChange={handleDimensionChanged} />
        </HStack>
      }
    >
      <HStack
        w="100%"
        gap="spacingDefault"
        justifyContent="space-between"
        px="spacingComfortable"
        pt="spacingComfortable"
      >
        {sums.negativeValueSum && (
          <Summary
            value={sums.negativeValueSum}
            label={`Short ${summaryLabelSuffix}`}
            data-testid="balances-chart-module-short-summary"
          />
        )}
        {sums.netValueSum && (
          <Summary
            value={sums.netValueSum}
            label={`Net ${summaryLabelSuffix}`}
            data-testid="balances-chart-module-net-summary"
          />
        )}
        {sums.positiveValueSum && (
          <Summary
            value={sums.positiveValueSum}
            label={`Long ${summaryLabelSuffix}`}
            data-testid="balances-chart-module-long-summary"
          />
        )}
        {sums.uPnLSum && (
          <Summary value={sums.uPnLSum} label="Unrealized P&L" data-testid="balances-chart-module-upnl-summary" />
        )}
      </HStack>
      <Box flex="1" overflow="hidden" w="100%">
        <Box p="spacingComfortable" h="100%" w="100%">
          {positions == null ? (
            <LoaderTalos />
          ) : positions.length === 0 ? (
            <VStack h="100%">
              <Text>No data found</Text>
            </VStack>
          ) : (
            <OpsBalancesChart
              entities={chartedEntities}
              getValue={getValue}
              getGroupBy={getGroupBy}
              getLabel={getLabel}
              getTooltip={getTooltip}
              showingByAsset={showByAsset}
              chartValueAxisTitle={chartValueAxisTitle}
              onPointClick={handlePointClick}
            />
          )}
        </Box>
      </Box>
    </Module>
  );
};

const Summary = ({ value, label, ...boxProps }: { value: Big; label: string } & BoxProps) => {
  const { homeCurrency } = useDisplaySettings();
  const homeCurrencyInfo = useCurrency(homeCurrency);
  const theme = useTheme();

  const isNegative = value.lt(0);

  return (
    <VStack gap="spacingSmall" alignItems="flex-start" {...boxProps}>
      <Text>{label}</Text>
      <InlineFormattedNumber
        number={value}
        currency={homeCurrency}
        increment={homeCurrencyInfo?.DefaultIncrement}
        highlightColor={isNegative ? theme.colorTextNegative : undefined}
        round
      />
    </VStack>
  );
};

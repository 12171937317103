import { getDisplayIdFromValue, getOrderStatusText, Order } from '@talos/kyoko';
import type { ToastAppInboxProps } from '@talos/kyoko/src/hooks/Toast/useToasts';
import type { AppNotification } from './AppNotifications';
import { notificationSeverityToToastVariant } from './notificationSeverityToToastVariant';

export const getNotificationRenderInfo = (notification: AppNotification): ToastAppInboxProps => {
  const order = new Order(notification.Payload);
  const displayId = getDisplayIdFromValue(order.OrderID);
  const friendlyStatus = getOrderStatusText({
    type: 'order',
    order,
  });

  const result: ToastAppInboxProps = {
    variant: notificationSeverityToToastVariant(notification.Severity),
    id: notification.NotificationID,
    title: `Order ${friendlyStatus}`,
    description: `Order for ${displayId} ${notification.Payload.Symbol} ${friendlyStatus}`,
    timeStamp: notification.Timestamp,
    targetUrl: `/trading/order/${order.OrderID}/details`,
  };
  return result;
};

export const Logo = ({ fill }: React.SVGProps<SVGPathElement>) => (
  /* F this viewbox, just went with something that looks centered and is the right size. Close enough */
  <svg viewBox="-10 -10 170 170">
    <path
      fill={fill}
      d="M46.8628 13.198C45.1333 12.9312 45.0603 12.8999 45.8742 12.7756C47.434 12.5371 51.1169 12.8621 53.6549 13.4621C59.5799 14.8625 64.9713 18.4497 70.7263 24.8206L72.2552 26.5131L74.4424 26.1635C83.6567 24.6912 93.0304 25.8613 100.871 29.4624C103.027 30.4532 106.428 32.4253 106.853 32.932C106.988 33.0936 107.237 34.1332 107.405 35.2426C107.988 39.0807 107.696 42.0227 106.516 44.22C105.873 45.4158 105.837 45.7947 106.269 46.818C106.614 47.6346 107.575 48.239 108.527 48.2377C110.475 48.2351 112.573 45.1036 113.544 40.7463L113.93 39.0155L114.695 39.8767C118.889 44.6011 122.183 51.0442 122.749 55.6304L122.896 56.8262L122.191 55.7391C120.978 53.8685 119.759 52.5952 118.198 51.5682C115.384 49.7169 112.409 49.0869 104.529 48.674C97.4124 48.3012 93.3846 47.6968 89.3908 46.4019C82.5956 44.1992 79.1701 41.2655 71.0984 30.736C67.5132 26.0591 65.2973 23.4716 63.093 21.3877C58.0843 16.6528 53.1628 14.1696 46.8628 13.198Z"
    />
    <path
      fill={fill}
      d="M108.458 23.6403C108.637 20.5063 109.065 18.4392 109.924 16.5515C110.264 15.8043 110.583 15.1927 110.632 15.1927C110.681 15.1927 110.533 15.7443 110.303 16.4182C109.679 18.2503 109.576 20.7562 110.006 23.6716C110.553 27.3708 110.863 27.9045 114.796 31.9004C116.64 33.7747 118.786 36.1386 119.563 37.1534L120.977 38.9988L119.563 37.68C117.834 36.0673 113.857 32.922 112.978 32.4724C112.389 32.1709 112.302 32.1761 111.938 32.5357C111.604 32.867 111.533 33.3649 111.486 35.7185C111.414 39.3868 110.911 41.7414 109.698 44.0957C109.041 45.369 108.938 45.0973 109.532 43.66C109.975 42.5869 110.02 42.1151 110.017 38.564C110.01 31.4289 109.158 29.7135 104.162 26.7751C102.896 26.0307 100.811 24.9571 99.5277 24.3892C98.2445 23.8214 97.2253 23.3268 97.2624 23.2898C97.4038 23.1498 102.276 24.5638 104.237 25.3139C107.155 26.4296 107.636 26.5742 107.99 26.4396C108.228 26.3494 108.343 25.6617 108.458 23.6403Z"
    />
    <path
      fill={fill}
      d="M50.2224 35.8464C46.7112 31.0402 44.5388 23.6711 45.0091 18.1625L45.1546 16.4578L45.9538 16.6028C47.4546 16.875 50.0425 17.8327 51.2542 18.5645C54.5795 20.5724 56.0191 23.2161 57.4837 30.0047C57.9127 31.9931 58.4756 34.2432 58.7346 35.005C59.1515 36.2312 60.7271 39.0953 62.008 40.9554C62.9307 42.2951 62.3178 42.9299 60.2783 42.7469C57.1634 42.4673 52.9442 39.5721 50.2224 35.8464Z"
    />
    <path
      fill={fill}
      d="M104.199 71.6166C87.7908 65.0478 82.0117 59.3461 82.0117 49.7256C82.0117 48.3099 82.0607 47.1515 82.1205 47.1515C82.1802 47.1515 82.8151 47.6187 83.5312 48.1899C86.8585 50.8433 90.5845 51.9767 100.899 53.4729C106.969 54.3534 110.385 55.0645 113.536 56.1035C123.551 59.4059 129.747 66.1077 131.225 75.236C131.654 77.8883 131.402 82.8624 130.706 85.4839C130.157 87.5545 128.48 91.2867 128.035 91.4298C127.912 91.4695 127.79 90.9995 127.759 90.3601C127.59 86.9325 125.848 83.5955 122.922 81.0957C119.596 78.2538 115.127 75.991 104.199 71.6166Z"
    />
    <path
      fill={fill}
      d="M92.68 74.3455C92.4744 73.1291 92.1179 71.5757 91.8877 70.8935L91.4689 69.6532L92.2466 70.5211C93.3228 71.722 94.1732 73.2589 94.894 75.3058C95.4441 76.868 95.506 77.3326 95.5018 79.8713C95.4976 82.3636 95.4289 82.8861 94.921 84.2923C94.12 86.5096 93.126 88.0819 91.4579 89.7696C88.4606 92.8027 84.607 94.4819 79.046 95.1785C78.0794 95.2994 75.262 95.5033 72.7851 95.6314C66.5431 95.9542 62.4349 96.621 58.7434 97.9098C58.2126 98.0952 57.7387 98.2079 57.6906 98.16C57.5412 98.0122 60.0546 96.5225 62.1306 95.5283C65.0578 94.1262 67.9717 93.3612 74.5004 92.2802C77.7255 91.7461 81.0561 91.0982 81.9019 90.8403C89.8886 88.4056 93.9939 82.1226 92.68 74.3455Z"
    />

    <path
      fill={fill}
      d="M100.202 87.6275C98.0218 82.9678 97.5212 78.4688 98.7156 74.2731C98.8434 73.8248 99.0488 73.4578 99.1725 73.4578C99.2959 73.4578 99.8104 73.7343 100.315 74.0722C101.32 74.7446 103.335 75.8771 108.703 78.7871C115.401 82.4182 119.221 85.2299 121.818 88.4424C124.092 91.2558 125.5 94.4597 126.177 98.367C126.561 100.58 126.336 105.906 125.765 108.134C123.964 115.161 119.778 120.681 113.808 123.902C112.933 124.374 112.148 124.761 112.063 124.763C111.978 124.765 112.297 123.959 112.771 122.972C114.78 118.798 115.008 114.738 113.49 110.218C112.56 107.451 110.665 104.075 106.837 98.3679C102.388 91.733 101.297 89.9672 100.202 87.6275Z"
    />
    <path
      fill={fill}
      d="M38.5696 112.769C44.6588 107.657 52.2351 104.026 59.1364 102.911C62.1106 102.431 67.0653 102.621 69.8194 103.322C74.2341 104.445 78.1834 106.962 80.2371 109.959C82.2444 112.889 83.1055 115.442 84.002 121.122C84.3557 123.363 84.7403 125.613 84.8568 126.123C85.5301 129.067 86.8403 131.42 88.464 132.602C91.0428 134.478 95.4834 134.595 99.8517 132.901C100.593 132.613 101.237 132.414 101.282 132.459C101.44 132.616 99.2408 134.08 97.689 134.851C95.6009 135.888 93.9406 136.288 91.7343 136.288C87.7335 136.288 84.412 134.265 81.6402 130.139C81.0948 129.327 79.8689 126.894 78.9159 124.734C75.9896 118.097 74.5445 116.076 71.1467 113.863C68.1898 111.938 64.3764 111.593 61.5076 112.992C57.7392 114.829 56.6878 119.617 59.3868 122.651C60.4595 123.857 62.4599 124.898 64.0956 125.1C67.1558 125.478 69.7856 123.164 69.7856 120.092C69.7856 118.097 69.0145 116.959 67.0734 116.088C64.4223 114.898 61.5726 116.289 61.5862 118.765C61.5922 119.821 62.0546 120.484 63.1192 120.963C63.8022 121.27 63.818 121.295 63.2611 121.18C60.8286 120.678 60.2587 117.762 62.2145 115.827C64.5627 113.503 69.4183 114.528 71.0857 117.7C71.7862 119.032 71.8675 121.686 71.2568 123.288C69.8899 126.874 65.9045 128.76 61.8615 127.734C59.1089 127.035 57.9881 126.278 54.6694 122.88C48.9025 116.973 46.6638 115.829 38.35 114.538L36.7568 114.291L38.5696 112.769Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5109 7.05728C30.7695 30.2977 44.0338 39.8862 45.5078 41.912C46.7249 43.5848 46.2668 45.0886 44.1818 46.2671C43.0223 46.9224 40.6386 47.5864 39.4451 47.5864C38.0951 47.5864 37.6317 47.0686 37.6317 47.0686C36.8489 46.3305 36.4081 46.4596 32.3886 39.3656C26.8083 30.7563 22.1383 23.6145 22.0109 23.4949C21.7163 23.2184 21.7214 23.2277 31.8197 41.1927C33.4512 44.937 32.1442 46.3115 32.1442 46.8448C32.1442 47.9296 31.8465 48.4999 30.5005 49.9926C28.2567 52.4815 27.2537 55.278 26.5296 61.0656C25.7179 67.5535 23.4354 72.1364 17.1099 79.98C13.4072 84.5714 12.8013 85.413 11.867 87.2636C10.6902 89.594 10.3666 90.8993 10.2354 93.8421C10.0968 96.9534 10.3668 98.9633 11.3229 101.938C12.1598 104.542 13.0335 106.262 15.267 109.702C17.1945 112.67 18.3043 114.876 18.3043 115.739C18.3043 116.425 18.4361 116.426 21.4229 115.756C28.5707 114.151 34.3746 111.328 37.6388 107.869C39.659 105.728 40.1333 104.546 40.1487 101.612C40.1588 99.693 40.0909 99.2912 39.5692 98.1872C38.7199 96.3903 37.1738 94.8963 33.7661 92.58C29.3011 89.5453 27.394 87.1021 26.8672 83.742C26.4351 80.9851 26.9364 79.0399 29.4065 73.8926C31.9632 68.5646 32.5967 66.2942 33.0253 60.9238C33.3021 57.4542 33.6855 56.0859 34.6881 54.9875C35.7338 53.8422 36.6751 53.4544 39.263 53.1028C43.4819 52.5297 46.1684 51.4445 48.3766 49.4213C50.2922 47.6661 51.0938 45.9749 51.2169 43.4291L51.3102 41.4994L50.2397 40.2584C46.363 35.7642 8.91473 3.52441 8.67616 3.52441C8.62519 3.52441 9.90089 5.11431 11.5109 7.05728ZM20.4805 97.3626C21.357 95.8192 20.8913 93.8349 19.4251 92.8657C18.0398 91.9498 15.8878 92.3811 15.8878 93.5747C15.8878 93.9388 16.0904 94.2039 16.5469 94.4376C17.3155 94.8309 17.3713 95.2731 16.7665 96.1768C16.1541 97.0921 16.2035 97.897 16.906 98.4437C18.0383 99.3253 19.6411 98.8405 20.4805 97.3626Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.9728 54.1151C51.9921 54.7199 50.0668 56.807 49.4708 58.9954C49.1073 60.3305 49.3136 62.6724 49.8582 63.3958C50.738 64.5639 51.5888 64.8717 53.8926 64.8556C58.4031 64.8243 62.3242 62.9009 62.78 60.4964C63.1537 58.5254 61.4316 55.7939 59.0595 54.5947C57.8354 53.9762 55.2322 53.7307 53.9728 54.1151ZM59.2455 58.2145C59.941 57.2318 59.6368 56.1698 58.4538 55.4515C56.201 54.0838 52.7942 55.2156 52.7942 57.3316C52.7942 58.385 54.5707 59.5342 56.1992 59.5342C57.2831 59.5342 58.7664 58.8915 59.2455 58.2145Z"
    />
  </svg>
);

import {
  DefaultFavoritesConfig,
  DefaultTabsStateConfig,
  DefaultWatchlistSettingsConfig,
  type BlotterTableState,
} from '@talos/kyoko';
import { DefaultBlottersConfig } from 'providers/BlottersContext';
import { DefaultContextGuideConfig } from 'providers/ContextGuideContext';
import { DefaultDisplaySettingsConfig } from 'providers/DisplaySettingsProvider';
import { DefaultMarketTabsConfig } from 'providers/MarketTabs.types';
import type { IOrderPreset } from 'providers/OrderPresetsContext';
import { DefaultPortfolioSettingsConfig } from 'providers/PortfolioSettingContext';
import { DefaultSoundSettingsConfig } from 'providers/SoundContext';
import { DefaultTradingSettingsConfig } from 'providers/TradingSettingsContext.types';
import { DefaultMarketAccountsConfigValue } from '../../../../../packages/kyoko/src/contexts/DefaultMarketAccountsContext';

import { DefaultMarketSelectorConfig } from '../../components/MarketSelector/MarketSelectorConfigContext';
import { DefaultLayoutsConfig } from '../AppLayoutConfig/AppLayoutConfigContext';
import { DefaultFavoriteAggregationsConfig } from '../FavoriteAggregationsContext';
import { DefaultOrderEntryConfig } from '../OrderEntryContext';

export const DEFAULT_APP_CONFIG = {
  recentSymbols: {},
  blotters2: {} as BlotterTableState,
  orderPresetsList: [] as IOrderPreset[],
  marketSelectorConfig: { ...DefaultMarketSelectorConfig },
  ...DefaultOrderEntryConfig,
  ...DefaultBlottersConfig,
  ...DefaultTabsStateConfig,
  ...DefaultDisplaySettingsConfig,
  ...DefaultMarketTabsConfig,
  ...DefaultTradingSettingsConfig,
  ...DefaultWatchlistSettingsConfig,
  ...DefaultSoundSettingsConfig,
  ...DefaultFavoritesConfig,
  ...DefaultPortfolioSettingsConfig,
  ...DefaultContextGuideConfig,
  ...DefaultLayoutsConfig,
  ...DefaultFavoriteAggregationsConfig,
  ...DefaultMarketAccountsConfigValue,
};

export type AppConfigState = typeof DEFAULT_APP_CONFIG;

import { HStack, Text } from '@talos/kyoko';
import { useMemo } from 'react';
import { useFeatureFlag } from '../../../hooks';
import { useAppStateDispatch } from '../../../providers/AppStateProvider';
import { OperationsOverviewNew } from '../OperationsOverview/OperationsOverview';
import type { OperationsOverviewConfigContextProps } from '../OperationsOverview/providers/OperationsOverviewConfigProvider';
import { portfolioViewLayoutSlice } from '../PortfolioManagement/stateManagement/portfolioViewLayoutSlice';
import { usePortfolioViewStateSelector } from '../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';

const {
  updatePMMarginBalancesChartDimension,
  updatePMMarginBalancesChartShowByAsset,
  updatePMMarginFilter,
  updatePMMarginShowBy,
} = portfolioViewLayoutSlice.actions;

export const PortfolioMargin = () => {
  const dispatch = useAppStateDispatch();
  const { pmMarginBalancesChartDimension, pmMarginBalancesChartShowByAsset, pmMarginFilter, pmMarginShowBy } =
    usePortfolioViewStateSelector();
  const { enableMarginManagementFeatures } = useFeatureFlag();

  const config: OperationsOverviewConfigContextProps = useMemo(
    () => ({
      mode: 'SubAccount',
      onlyDerivs: true,
      opsOverviewBalancesChartDimension: pmMarginBalancesChartDimension,
      opsOverviewBalancesChartShowByAsset: pmMarginBalancesChartShowByAsset,
      opsOverviewFilter: pmMarginFilter,
      opsOverviewShowBy: pmMarginShowBy,
      updateOpsOverviewBalancesChartDimension: args => dispatch(updatePMMarginBalancesChartDimension(args)),
      updateOpsOverviewBalancesChartShowByAsset: args => dispatch(updatePMMarginBalancesChartShowByAsset(args)),
      updateOpsOverviewFilter: args => dispatch(updatePMMarginFilter(args)),
      updateOpsOverviewShowBy: args => dispatch(updatePMMarginShowBy(args)),
    }),
    [pmMarginBalancesChartDimension, pmMarginBalancesChartShowByAsset, pmMarginFilter, pmMarginShowBy, dispatch]
  );

  if (!enableMarginManagementFeatures) {
    return (
      <HStack background="colors.gray.main" w="100%" h="100%">
        <Text>PM Margin is not yet available.</Text>
      </HStack>
    );
  }

  return <OperationsOverviewNew config={config} />;
};

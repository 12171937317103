import {
  Box,
  HelpIcon,
  HStack,
  IndicatorBadge,
  IndicatorBadgeSizes,
  IndicatorBadgeVariants,
  LoaderTalos,
  ProductTypeEnum,
  Text,
  toBigWithDefault,
  useCallbackRef,
  useCurrency,
  useMarketAccountsContext,
  useSyncedRef,
  VStack,
} from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { Module } from '../../components/Module';
import { useOperationsOverviewInteractions } from '../providers/OperationsOverviewInteractionsProvider';
import type { OpsAccountPosition, OpsPosition } from '../types';
import { OpsEMRChart } from './OpsEMRChart';
import { OpsEMRChartTooltip } from './OpsEMRChartTooltip';

type OpsEMRChartModuleProps = {
  positions: OpsPosition[] | undefined;
  accountPositions: OpsAccountPosition[] | undefined;
};

export const OpsEMRChartModule = ({ positions, accountPositions }: OpsEMRChartModuleProps) => {
  const theme = useTheme();
  const { homeCurrency } = useDisplaySettings();
  const homeCurrencyInfo = useCurrency(homeCurrency);
  const { marketAccountsByName } = useMarketAccountsContext();
  const marketAccountsByNameRef = useSyncedRef(marketAccountsByName);

  const { goToGroupRow } = useOperationsOverviewInteractions();
  const handlePointClick = useCallbackRef((pointKey: string) => {
    goToGroupRow?.(pointKey);
  });

  const getGroupBy = useCallback((position: OpsAccountPosition) => {
    return position.metric.AccountName;
  }, []);

  // we only want to show bubbles (accounts/market groupings) where there is at least one derivatives position within that grouping
  const relevantEntities = useMemo(() => {
    if (!positions || !accountPositions) {
      return undefined;
    }

    // reduce the positions array into a set of groupings which describes which groupings are to be shown
    const accountsWithOpenDerivsPositions = new Set<string>();
    for (const position of positions) {
      if (position.AssetType !== ProductTypeEnum.Spot) {
        accountsWithOpenDerivsPositions.add(position.MarketAccount);
      }
    }

    const accountPositionsByAccount = new Map(accountPositions.map(ap => [ap.metric.AccountName, ap]));
    const relevantAccountPositions: OpsAccountPosition[] = [];
    for (const account of accountsWithOpenDerivsPositions) {
      const accPos = accountPositionsByAccount.get(account);
      if (accPos) {
        relevantAccountPositions.push(accPos);
      }
    }

    return relevantAccountPositions;
  }, [positions, accountPositions]);

  const getSize = useCallback((position: OpsAccountPosition) => {
    return toBigWithDefault(position.metric.Equivalent?.NetLiquidationValue, 0).abs();
  }, []);

  const getNLV = useCallback((position: OpsAccountPosition) => {
    return toBigWithDefault(position.metric.Equivalent?.NetLiquidationValue, 0);
  }, []);

  const getLabel = useCallback(
    (position: OpsAccountPosition) => {
      const mktAcc = marketAccountsByNameRef.current.get(position.metric.AccountName);
      return mktAcc?.DisplayName ?? position.metric.AccountName;
    },
    [marketAccountsByNameRef]
  );

  const getEMR = useCallback((position: OpsAccountPosition) => {
    return toBigWithDefault(position.metric.EquityMarginRatio, 0);
  }, []);

  const getEquity = useCallback((position: OpsAccountPosition) => {
    return toBigWithDefault(position.metric.Equivalent?.EquityContribution?.Equity, 0);
  }, []);

  const getEMRTooltip = useCallback(
    (point: Highcharts.Point) => {
      return <OpsEMRChartTooltip point={point} theme={theme} homeCurrency={homeCurrencyInfo} />;
    },
    [theme, homeCurrencyInfo]
  );

  return (
    <Module
      data-testid="emr-bubble-chart-module"
      title={
        <HStack gap="spacingSmall">
          <Text>Equity and EMR by Market Account</Text>
          <HelpIcon tooltip="Click and drag to zoom. Hold shift, click and drag to pan." />
        </HStack>
      }
      flex="1 1 50%"
      h="100%"
      suffix={
        <IndicatorBadge
          whiteSpace="nowrap"
          size={IndicatorBadgeSizes.Large}
          children={
            <HStack gap="spacingDefault">
              <Circles />
              <Text color="colorTextImportant" fontWeight={500}>
                Net Liquidation Value {homeCurrency}
              </Text>
            </HStack>
          }
          variant={IndicatorBadgeVariants.Default}
        />
      }
    >
      <Box p="spacingComfortable" h="100%" w="100%">
        {relevantEntities == null ? (
          <LoaderTalos />
        ) : relevantEntities.length === 0 ? (
          <VStack h="100%">
            <Text>No data found</Text>
          </VStack>
        ) : (
          <OpsEMRChart
            entities={relevantEntities}
            getSize={getSize}
            getEMR={getEMR}
            getNLV={getNLV}
            getEquity={getEquity}
            getGroupBy={getGroupBy}
            getLabel={getLabel}
            getTooltip={getEMRTooltip}
            onPointClick={handlePointClick}
          />
        )}
      </Box>
    </Module>
  );
};

// draws 3 circles that look like this: O o . in svg
const Circles = () => {
  const theme = useTheme();
  return (
    <HStack gap="1px">
      {[12, 10, 8].map(height => (
        /* I don't really know what I'm doing with svgs but this works surprisingly well and looks good :+1: */
        <svg height={height} viewBox={`0 0 ${height} ${height}`} xmlns="http://www.w3.org/2000/svg" key={height}>
          <circle
            cx={height / 2}
            cy={height / 2}
            r={height / 2 - 1}
            stroke={theme.colors.gray['080']}
            fill={theme.colors.gray['070']}
          />
        </svg>
      ))}
    </HStack>
  );
};

import type { InitialFilterClause, ToggleHorizontalOption } from '@talos/kyoko';
import {
  Accordion,
  AccordionBody,
  Box,
  FilterBuilder,
  FilterBuilderClearAllButton,
  FilterBuilderToggleButton,
  FormControlSizes,
  HStack,
  ToggleHorizontal,
  useAccordionFilterBuilder,
} from '@talos/kyoko';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useOperationsOverviewConfig } from '../providers/OperationsOverviewConfigProvider';
import { useOperationsOverviewFilters } from '../providers/OperationsOverviewFiltersProvider';
import { useOperationsOverviewInteractions } from '../providers/OperationsOverviewInteractionsProvider';
import { SHOW_BY_DICT, type OperationsOverviewShowBy } from '../types';

const SHOW_BY_OPTIONS: ToggleHorizontalOption<OperationsOverviewShowBy>[] = Object.values(SHOW_BY_DICT).map(entry => ({
  value: entry.showBy,
  displayName: entry.label,
}));

interface ControlPanelProps {
  initialFilterClauses: InitialFilterClause[];
}

export function ControlPanel({ initialFilterClauses }: ControlPanelProps) {
  const { filterableProperties, onFilterClausesChanged } = useOperationsOverviewFilters();
  const { setOpenClause } = useOperationsOverviewInteractions();

  const { opsOverviewShowBy: showBy, updateOpsOverviewShowBy } = useOperationsOverviewConfig();

  const handleShowByChange = useCallback(
    (type: OperationsOverviewShowBy) => {
      updateOpsOverviewShowBy(type);
    },
    [updateOpsOverviewShowBy]
  );

  const { accordion, filterBuilder, openClause } = useAccordionFilterBuilder({
    filterBuilderProps: { initialFilterClauses, properties: filterableProperties, onFilterClausesChanged },
  });

  // Register the openClause function with the interactions provider
  useEffect(() => {
    setOpenClause(() => openClause);
  }, [openClause, setOpenClause]);

  return (
    <ControlPanelWrapper>
      <HStack px="spacingDefault" gap="spacingDefault" justifyContent="space-between" w="100%" py="spacingDefault">
        <ToggleHorizontal
          options={SHOW_BY_OPTIONS}
          value={showBy}
          onChange={handleShowByChange}
          optionDataTestId="show-by-selector"
        />

        {/* We currently dont have any filterable properties, but in the future we will again, so keeping this around */}
        {filterableProperties.length > 0 && (
          <HStack gap="spacingDefault">
            <FilterBuilderClearAllButton
              removeAllFilterClauses={filterBuilder.removeAllFilterClauses}
              disabled={filterBuilder.filterClauses.length === 0}
              size={FormControlSizes.Small}
            />
            <FilterBuilderToggleButton
              filterClauses={filterBuilder.filterClauses}
              isOpen={accordion.isOpen}
              onClick={() => accordion.toggle()}
              size={FormControlSizes.Small}
            />
          </HStack>
        )}
      </HStack>
      <Accordion {...accordion}>
        <AccordionBody borderTopWhenOpened>
          <FilterBuilder {...filterBuilder} />
        </AccordionBody>
      </Accordion>
    </ControlPanelWrapper>
  );
}

const ControlPanelWrapper = styled(Box)`
  border-bottom: 2px solid ${({ theme }) => theme.backgroundBody};
  background: ${({ theme }) => theme.colors.gray.main};
`;

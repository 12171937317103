import {
  Direction,
  Grid,
  MARGIN_PORTFOLIO_METRIC,
  type ProductTypeEnum,
  useCurrenciesContext,
  useObservableValue,
  useSubscription,
  wsScanToMap,
} from '@talos/kyoko';
import Big from 'big.js';
import { useMemo } from 'react';
import { map } from 'rxjs';
import styled, { css } from 'styled-components';
import { KPI, KPITypes } from '../../../../../components/KPI';
import { getIsAssetCashFunc } from '../../../../../hooks/useIncludeCashFilter';
import { useSubAccounts } from '../../../../../providers';
import { useDisplaySettings } from '../../../../../providers/DisplaySettingsProvider';
import { usePortfolioSettings } from '../../../../../providers/PortfolioSettingContext';
import { DERIVATIVES_PRODUCT_TYPES } from '../../../../Blotters/PositionsV3/Derivatives/usePositionsDerivativesFilter';
import type { IMarginPortfolioMetric } from '../../../OperationsOverview/types';
import { usePortfolioManagementProvider } from '../../providers/PortfolioManagementProvider';
import { usePortfolioViewStateSelector } from '../../stateManagement/portfolioViewLayoutSlice.hooks';

const DERIVS_PRODUCT_TYPES_SET: Set<keyof typeof ProductTypeEnum> = new Set(DERIVATIVES_PRODUCT_TYPES);

export const PortfolioOverviewHUD = () => {
  const { selectedPortfolioId, includeCash } = usePortfolioViewStateSelector();
  const { subAccountsByID } = useSubAccounts();

  const selectedPortfolioName =
    selectedPortfolioId != null ? subAccountsByID.get(selectedPortfolioId)?.Name : undefined;

  const { homeCurrency } = useDisplaySettings();
  const { treatStablecoinsAsCash } = usePortfolioSettings();
  const { currenciesBySymbol } = useCurrenciesContext();

  const isCash = useMemo(() => {
    return getIsAssetCashFunc(treatStablecoinsAsCash, currenciesBySymbol);
  }, [treatStablecoinsAsCash, currenciesBySymbol]);

  const { data: subAccMetrics } = useSubscription<IMarginPortfolioMetric>(
    selectedPortfolioName
      ? {
          name: MARGIN_PORTFOLIO_METRIC,
          EquivalentCurrency: homeCurrency,
          tag: 'portfolio-overview-hud',
          AccountType: 'SubAccount',
          SubAccounts: [selectedPortfolioName],
        }
      : null
  );

  const subAccMetricsByAccount = useObservableValue(
    () =>
      subAccMetrics.pipe(
        wsScanToMap({
          getUniqueKey: metric => metric.AccountName,
          newMapEachUpdate: true,
        })
      ),
    [subAccMetrics]
  );

  const subAccMetric = selectedPortfolioName ? subAccMetricsByAccount?.get(selectedPortfolioName) : undefined;

  const { riskSubAccountObs } = usePortfolioManagementProvider();
  const cash = useObservableValue(
    () =>
      riskSubAccountObs.pipe(
        map(message => {
          let cashTotal = Big(0);
          for (const datapoint of message.data) {
            if (isCash(datapoint.gridData.Asset)) {
              cashTotal = cashTotal.plus(datapoint.gridData.Equivalent.Position ?? '0');
            }
          }

          return cashTotal;
        })
      ),
    [riskSubAccountObs, isCash]
  );

  const anyDerivativesPositionsPresent = useObservableValue(
    () =>
      riskSubAccountObs.pipe(
        map(message => message.data.some(dp => DERIVS_PRODUCT_TYPES_SET.has(dp.gridData.AssetType)))
      ),
    [riskSubAccountObs]
  );

  return (
    <Wrapper boxes={anyDerivativesPositionsPresent ? 6 : 2}>
      <KPI
        label="Net Liquidation Value"
        isLoading={!subAccMetric}
        value={subAccMetric?.Equivalent?.NetLiquidationValue}
        type={KPITypes.Currency}
        currency={subAccMetric?.Equivalent?.Currency}
        positiveDirection={Direction.Asc}
        tooltip={PORTFOLIO_OVERVIEW_HUD_TOOLTIPS.NLV}
        dataTestID="pm-overview-hud-net-liquidation-value"
      />
      {includeCash && (
        <KPI
          label="Cash"
          isLoading={!subAccMetric}
          value={cash}
          type={KPITypes.Currency}
          currency={homeCurrency}
          positiveDirection={Direction.Asc}
          tooltip={PORTFOLIO_OVERVIEW_HUD_TOOLTIPS.Cash}
          dataTestID="pm-overview-hud-cash"
        />
      )}
      {anyDerivativesPositionsPresent && (
        <>
          <KPI
            label="Delta Exposure"
            isLoading={!subAccMetric}
            value={subAccMetric?.Equivalent?.NetDeltaExposure}
            currency={subAccMetric?.Equivalent?.Currency}
            type={KPITypes.Currency}
            positiveDirection={Direction.Asc}
            tooltip={PORTFOLIO_OVERVIEW_HUD_TOOLTIPS.DeltaExposure}
            dataTestID="pm-overview-hud-delta-exposure"
          />
          <KPI
            label="EMR"
            tooltip={PORTFOLIO_OVERVIEW_HUD_TOOLTIPS.EMR}
            isLoading={!subAccMetric}
            value={subAccMetric?.EquityMarginRatio}
            type={KPITypes.Number}
            positiveDirection={Direction.Asc}
            dataTestID="pm-overview-hud-emr"
          />
          <KPI
            label="Net Leverage Ratio"
            isLoading={!subAccMetric}
            value={subAccMetric?.NetLeverageRatio}
            type={KPITypes.Number}
            positiveDirection={Direction.Asc}
            tooltip={PORTFOLIO_OVERVIEW_HUD_TOOLTIPS.NetLeverageRatio}
            dataTestID="pm-overview-hud-net-leverage-ratio"
          />
          <KPI
            label="Gross Leverage Ratio"
            isLoading={!subAccMetric}
            value={subAccMetric?.GrossLeverageRatio}
            type={KPITypes.Number}
            positiveDirection={Direction.Asc}
            tooltip={PORTFOLIO_OVERVIEW_HUD_TOOLTIPS.GrossLeverageRatio}
            dataTestID="pm-overview-hud-gross-leverage-ratio"
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Grid)<{ boxes: 2 | 6 }>`
  width: 100%;
  overflow-x: hidden;
  gap: calc(var(--spacing2) * 1px);
  min-height: 5.125rem;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

  ${props =>
    props.boxes === 6 &&
    css`
      @media screen and (max-width: 1300px) {
        grid-template-columns: repeat(3, minmax(180px, 1fr));
      }

      @media screen and (max-width: 600px) {
        grid-template-columns: repeat(2, minmax(180px, 1fr));
      }
    `}
`;

export const PORTFOLIO_OVERVIEW_HUD_TOOLTIPS = {
  NLV: 'The home currency value of the currently-selected portfolio if all balances are sold for current market value, all derivatives positions are closed and all liabilities immediately repaid.',
  Cash: 'The home currency value of the currently-selected portfolio cash balances; if Stablecoins As Cash is enabled in settings, this includes stablecoins as well.',
  DeltaExposure:
    'Net equivalent delta notional in home currency from holding this position (the economic exposure driving performance).',
  EMR: 'EMR is the ratio of equity (collateral balances + unrealized P&L) to the initial margin posted.',
  NetLeverageRatio:
    'The amount of leverage in the currently-selected portfolio, incorporating both derivatives / margin exposure and any liabilities, on a net position basis.',
  GrossLeverageRatio:
    'The amount of leverage in the currently-selected portfolio, incorporating both derivatives / margin exposure and any liabilities, on a gross position basis.',
};

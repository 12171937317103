import type { RequestStream } from '@talos/kyoko';
import { PORTFOLIO_HISTORICAL_POSITION, type DateRangeFilter } from '@talos/kyoko';
import { isEqual } from 'lodash-es';
import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import type { BalancesFilter } from '../../../../types';
import { usePortfolioChartSubscription } from '../../PortfolioChart/usePortfolioChartSubscription';
import { dateRangeFilterToResolution, type SelectableResolution } from '../../SelectableResolution';
import type { PortfolioHistoricalPosition } from '../../types';
import { useTreasuryManagementContext } from '../providers/TreasuryManagementStateAndTabsProvider';

const resetKeys = [
  'Currencies',
  'Groups',
  'MarketAccounts',
  'MarketTypes',
  'Markets',
] satisfies (keyof BalancesFilter)[];

interface HistoricalPositionsRequest extends BalancesFilter, DateRangeFilter, RequestStream {
  name: string;
  EquivalentCurrency: string;
  Resolution: SelectableResolution;
}

export function useChartSubscriptions() {
  const { homeCurrency } = useDisplaySettings();

  const {
    state: { filter, chartRequestDateRange },
  } = useTreasuryManagementContext();

  const [request, setRequest] = useState<HistoricalPositionsRequest>(() => {
    const resolution = dateRangeFilterToResolution(chartRequestDateRange);

    return {
      name: PORTFOLIO_HISTORICAL_POSITION,
      EquivalentCurrency: homeCurrency,
      tag: 'TreasuryManagement/HistoricalValueChart',
      Resolution: resolution,
      ...filter,
      ...chartRequestDateRange,
    };
  });

  useUpdateEffect(() => {
    setRequest(prev => {
      const newRequest = {
        name: prev.name,
        EquivalentCurrency: prev.EquivalentCurrency,
        tag: prev.tag,
        Resolution: dateRangeFilterToResolution(chartRequestDateRange),
        ...filter,
        ...chartRequestDateRange,
      } satisfies HistoricalPositionsRequest;

      if (!isEqual(prev, newRequest)) {
        return newRequest;
      }

      return prev;
    });
  }, [filter, chartRequestDateRange]);

  const { isLoading, dataResetting, subscription } = usePortfolioChartSubscription<
    PortfolioHistoricalPosition,
    HistoricalPositionsRequest
  >({
    request,
    subscriptionOptions: { loadAll: true },
    resetDataOnRequestFieldChanges: resetKeys,
  });

  return {
    dataLoading: isLoading,
    dataResetting,
    subscription,
  };
}

import { ThemeProvider as HelixThemeProvider } from '@talos/helix';
import type { PropsWithChildren } from 'react';
import { ThemeProvider as StyledThemeProvider, type DefaultTheme } from 'styled-components';

export function ThemeProvider({ children, theme }: PropsWithChildren<{ theme: DefaultTheme }>) {
  return (
    <StyledThemeProvider theme={theme}>
      <HelixThemeProvider>{children}</HelixThemeProvider>
    </StyledThemeProvider>
  );
}

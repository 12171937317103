import { InstrumentCompositionEnum, ProductTypeEnum, SettleValueTypeEnum } from '@talos/kyoko';

export const futures = [
  {
    ProductType: ProductTypeEnum.Future,
    SettleValueType: SettleValueTypeEnum.Inverted,
    Expiration: '2028-06-26T12:00:00.000000Z',
    Markets: ['binance_futures'],
    Symbol: 'binance_futures:BCHUSD_230929',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'BCH',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'BCH',
    PositionCurrency: undefined, // important - Futures don't have this it seems
    NotionalMultiplier: '10',
    DisplaySymbol: 'BCH-USD',
    NormalSize: '1000',
    MinimumSize: '100',
    Composition: InstrumentCompositionEnum.Native,
  },
  {
    ProductType: ProductTypeEnum.Future,
    SettleValueType: SettleValueTypeEnum.Regular,
    Expiration: '2028-06-26T12:00:00.000000Z',
    Markets: ['okx'],
    Symbol: 'Future2',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'BCH',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'BCH',
    NotionalMultiplier: '10',
    DisplaySymbol: 'BCH-USD',
    NormalSize: '1000',
    MinimumSize: '100',
    Composition: InstrumentCompositionEnum.Native,
  },
  {
    ProductType: ProductTypeEnum.Future,
    SettleValueType: SettleValueTypeEnum.Regular,
    Expiration: '2029-06-26T12:00:00.000000Z',
    Markets: ['okx'],
    Symbol: 'Future3',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'BCH',
    QuoteCurrency: 'ETH',
    SettlementCurrency: 'BCH',
    NotionalMultiplier: '10',
    DisplaySymbol: 'BCH-ETH',
    NormalSize: '1000',
    MinimumSize: '100',
    Composition: InstrumentCompositionEnum.Native,
  },
  {
    ProductType: ProductTypeEnum.Future,
    SettleValueType: SettleValueTypeEnum.Regular,
    Expiration: '2029-06-26T12:00:00.000000Z',
    Markets: ['coinbase'],
    Symbol: 'Future4-Quanto',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'ETH',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'BCH', // quanto
    NotionalMultiplier: '10',
    DisplaySymbol: 'ETH-USD',
    NormalSize: '1000',
    MinimumSize: '100',
    Composition: InstrumentCompositionEnum.Native,
  },
  {
    ProductType: ProductTypeEnum.Future,
    SettleValueType: SettleValueTypeEnum.Regular,
    Expiration: '2029-06-26T12:00:00.000000Z',
    Markets: ['cme'],
    Symbol: 'Future5-CME',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'ETH',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'ETH',
    NotionalMultiplier: '10',
    DisplaySymbol: 'Future5-CME-DisplaySymbol',
    NormalSize: '1000',
    MinimumSize: '100',
    Composition: InstrumentCompositionEnum.Native,
  },
  {
    ProductType: ProductTypeEnum.Future,
    SettleValueType: SettleValueTypeEnum.Regular,
    Expiration: '2029-06-26T12:00:00.000000Z',
    Markets: ['cme'],
    Symbol: 'Future6-CME',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'ETH',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'ETH',
    NotionalMultiplier: '100',
    DisplaySymbol: 'Future6-CME-DisplaySymbol',
    NormalSize: '1000',
    MinimumSize: '100',
    Composition: InstrumentCompositionEnum.Native,
  },
];

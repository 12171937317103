import {
  FilterClauseType,
  cleanupInitialFilterDateRange,
  filterExcludesOrderStatus,
  filterExistsAndExcludes,
  orderStatusFilterTextToBackendStatus,
  removeEmptyFilters,
  useDateRangeFilter,
  useExecTypeFilter,
  useOrderStatusFilter,
  useSymbolsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type CareExecutionReport,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type OrdStatusEnum,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { compact, isEqual, keys } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useIDFilter } from '../../../hooks';

export interface CareExecutionReportsTableFilter extends DateRangeFilter {
  ExecTypes?: string[];
  Symbols?: string[];
  /** These are the Ord Status texts we are filtering by on the client side */
  _statuses?: string[];
  /** These are the Ord Statuses that we are filtering by on the server */
  Statuses?: OrdStatusEnum[];
  OrdType?: string[];
  OrderID?: string;
}

export interface UseCareExecutionReportsFilterParams<TData extends CareExecutionReport> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}
export function useCareExecutionReportsFilter<TData extends CareExecutionReport>({
  persistedBlotterTable,
}: UseCareExecutionReportsFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [initialFilter] = useState(() => cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter));
  const [filter, setFilter] = useState<CareExecutionReportsTableFilter>(
    (initialFilter as CareExecutionReportsTableFilter) || {}
  );

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<CareExecutionReport>>(
    row => {
      const { data } = row;
      if (filterExistsAndExcludes(filter, 'ExecTypes', data, 'ExecType')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExcludesOrderStatus(filter._statuses, data)) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const symbolFilter = useSymbolsFilter();
  const execTypeFilter = useExecTypeFilter();
  const orderStatusFilter = useOrderStatusFilter();
  const idFilter = useIDFilter();

  const filterableProperties = useMemo(
    () =>
      compact<FilterableProperty<keyof CareExecutionReportsTableFilter>>([
        execTypeFilter,
        symbolFilter,
        orderStatusFilter,
        { ...idFilter, key: 'OrderID', label: 'Order ID' },
      ]),
    [execTypeFilter, symbolFilter, orderStatusFilter, idFilter]
  );
  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof CareExecutionReportsTableFilter)[]).forEach(
        (key: keyof CareExecutionReportsTableFilter) => {
          switch (key) {
            case '_start':
            case 'StartDate':
            case 'EndDate':
            case 'TimestampField':
            case 'Statuses':
              return;
            default:
              clauses.push({
                key: key,
                type: FilterClauseType.INCLUSIVE,
                selections: filter[key] as string[],
              });
          }
        }
      );
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: CareExecutionReportsTableFilter = removeEmptyFilters<CareExecutionReportsTableFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies CareExecutionReportsTableFilter),
          // below are exact search and only one value is supported by backend
          OrderID: filterClausesByPropertyKey.get('OrderID')?.selections?.[0],
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
}

const colIDToFilterBuilderKeyMap = {
  ExecType: 'ExecTypes',
  Symbol: 'Symbols',
  OrdStatus: '_statuses',
  OrderID: 'OrderID',
} satisfies Partial<Record<keyof CareExecutionReport, keyof CareExecutionReportsTableFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof CareExecutionReportsTableFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}

export function onlyServerFilterKeys(filter: CareExecutionReportsTableFilter | undefined) {
  if (!filter) {
    return filter;
  }

  const serverFilter = {
    StartDate: filter.StartDate,
    EndDate: filter.EndDate,
    Statuses: orderStatusFilterTextToBackendStatus(filter._statuses),
    OrderID: filter.OrderID,
  };

  return serverFilter;
}

import {
  EXPOSURE,
  Exposure,
  MARKET_EXPOSURE,
  MarketExposure,
  useSubscription,
  wsMerge,
  type IMarketExposure,
  type IUniformExposure,
  type SubscriptionResponse,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { map, type Observable } from 'rxjs';
import { creditBlotterExposureCreationPipe } from './creditBlotterExposureCreationPipe';

interface UseCreditBlotterDataObsParams {
  showZeroBalances: boolean;
  tag: string;
  /** Defaults to true. You can pass false here to not make a request. */
  makeRequest?: boolean;
}

export const useCreditBlotterDataObs = ({
  showZeroBalances,
  tag,
  makeRequest = true,
}: UseCreditBlotterDataObsParams) => {
  const { data: exposuresRawObs } = useSubscription<Exposure>(
    makeRequest
      ? {
          name: EXPOSURE,
          tag,
          ShowZeroBalances: showZeroBalances,
        }
      : null
  );

  const { data: marketExposuresRawObs } = useSubscription<IMarketExposure>(
    makeRequest
      ? {
          name: MARKET_EXPOSURE,
          tag,
          ShowZeroBalances: showZeroBalances,
        }
      : null
  );

  const exposuresObs: Observable<SubscriptionResponse<IUniformExposure>> = useMemo(
    () =>
      exposuresRawObs.pipe(
        map(json => ({
          ...json,
          data: json.data.map(e => new Exposure(e)),
        }))
      ),
    [exposuresRawObs]
  );

  const marketExposuresObs: Observable<SubscriptionResponse<IUniformExposure>> = useMemo(
    () =>
      marketExposuresRawObs.pipe(
        map(json => ({
          ...json,
          data: json.data.map(me => new MarketExposure(me)),
        }))
      ),
    [marketExposuresRawObs]
  );

  const mergedExposuresObs = useMemo(
    () => wsMerge({ sources: [exposuresObs, marketExposuresObs], getUniqueKey: item => item.rowID }),
    [exposuresObs, marketExposuresObs]
  );

  const enrichedCreditBlotterExposuresObs = useMemo(
    () => mergedExposuresObs.pipe(creditBlotterExposureCreationPipe()),
    [mergedExposuresObs]
  );

  return { dataObservable: enrichedCreditBlotterExposuresObs };
};

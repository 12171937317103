import { logger } from '@talos/kyoko';
import { uniq } from 'lodash-es';
import { useMemo } from 'react';
import { OrgConfigurationKey, useOrgConfiguration } from '../../providers';
import { useDisplaySettings } from '../../providers/DisplaySettingsProvider';

const DEFAULT_COMMON_TRADE_SPOT_POSITION_CURRENCIES = ['USDT', 'USDC', 'USD', 'EUR'];

/**
 * In the case where you want to trade an existing spot position, for example a spot position of BTC, we want to elevate some
 * spot trading pairs including certain currencies (eg USD, USDT, EUR) to the top into their own "Common" group. Understanding that
 * different orgs have different currencies they regard as common, we allow this to be overridden through an org runtime config.
 */
export const useCommonTradeSpotPositionCurrencies = () => {
  const { homeCurrency } = useDisplaySettings();
  const { getConfig } = useOrgConfiguration();

  const commonCurrencies = useMemo(() => {
    const defaultCommonCurrencies = uniq([homeCurrency, ...DEFAULT_COMMON_TRADE_SPOT_POSITION_CURRENCIES]);
    const maybeConfiguredCurrencies = getConfig(OrgConfigurationKey.CommonTradeSpotPositionCurrencies, '');

    if (maybeConfiguredCurrencies == null || maybeConfiguredCurrencies === '') {
      return defaultCommonCurrencies;
    }

    // Try to parse the CSV list
    try {
      const parsedCurrencies = maybeConfiguredCurrencies.split(',');
      return parsedCurrencies;
    } catch (e) {
      logger.error(new Error(`Unable to parse ui.CommonTradeSpotPositionCurrencies CSV string`), {
        extra: {
          CommonTradeSpotPositionCurrencies: maybeConfiguredCurrencies,
        },
      });
      // If we failed to parse, just return the default common currencies from here
      return defaultCommonCurrencies;
    }
  }, [homeCurrency, getConfig]);

  return commonCurrencies;
};

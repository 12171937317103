import { useDynamicCallback, usePersistedTabs, useTabs, type UseTabs } from '@talos/kyoko';
import { isEqual } from 'lodash-es';
import { useContext, useMemo, useReducer, useState, type PropsWithChildren } from 'react';
import { useUpdateEffect } from 'react-use';
import { PerformanceActionType, performanceReducer, type PerformanceAction } from '../PerformanceReducer';
import { usePerformanceTabLabeler } from '../hooks/usePerformanceTabLabeler';
import { PERFORMANCE_BLOTTER_TABS_ID } from '../tokens';
import { periodToChartDateRange, type PerformanceState, type PerformanceTabState } from '../types';
import { DEFAULT_PERFORMANCE_STATE, getDefaultPerformanceState } from './getDefaultPerformanceState';
import {
  DEFAULT_PERFORMANCE_TAB_ID,
  PerformanceContext,
  PerformanceDispatchContext,
  PerformanceTabsContext,
  type PerformanceTabProps,
} from './types';

const DEFAULT_TAB: PerformanceTabProps = {
  label: 'Performance',
  id: DEFAULT_PERFORMANCE_TAB_ID,
  closable: true,
  editable: true,
  usingSmartLabel: true,
  ...DEFAULT_PERFORMANCE_STATE,
};

/**
 * Get tab-related data for the Performance page.
 */
export function usePerformanceTabs() {
  const context = useContext(PerformanceTabsContext);
  if (context === undefined) {
    throw new Error('Missing PerformanceTabsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/**
 * Get reducer (state) related data for the Performance page.
 */
export function usePerformanceContext() {
  const context = useContext(PerformanceContext);
  if (context === undefined) {
    throw new Error('Missing PerformanceContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context as { state: PerformanceState; dispatch: React.Dispatch<PerformanceAction> }; // we can guarantee this logically
}

/**
 * Get only the dispatch function from our reducer if you are not interested in reading (and getting re-renders) for state changes
 */
export function usePerformanceDispatch() {
  const context = useContext(PerformanceDispatchContext);
  if (context === undefined) {
    throw new Error('Missing PerformanceDispatchContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context as React.Dispatch<PerformanceAction>; // we can guarantee this logically
}

export const PerformanceStateAndTabsProvider = function PerformanceStateAndTabsProvider({
  children,
}: PropsWithChildren) {
  const [filtersInitialOpen, setFiltersInitialOpen] = useState(false);

  const persistedTabs = usePersistedTabs<PerformanceTabProps>(PERFORMANCE_BLOTTER_TABS_ID, {
    defaultInitialItems: [DEFAULT_TAB],
    defaultInitialSelectedIndex: 0,
  });

  const handleTabSelect = useDynamicCallback((i: number) => {
    persistedTabs.onSelect(i);

    dispatch({
      type: PerformanceActionType.TabChange,
      payload: {
        ...hydratePerformanceStateFromTab(tabs.items[i]),
      },
    });
  });

  const tabLabeler = usePerformanceTabLabeler();

  const tabs: UseTabs<PerformanceTabProps> = useTabs<PerformanceTabProps>({
    ...persistedTabs,
    initialItems: persistedTabs.initialItems,
    showAddTab: true,
    onSelect: handleTabSelect,
    allowClosingLastTab: false,
    onAdd: () => setFiltersInitialOpen(true),
    onCreateTab: () => getDefaultPerformanceTabState(), // Spread the default tab state onto every newly created tab
    tabLabeler,
  });

  const cloneCurrentTab = useDynamicCallback(() => {
    tabs.clone(tabs.selectedIndex);
  });

  const [state, dispatch] = useReducer(performanceReducer, {
    // grab any data which might be saved in the current tab and use that over the page defaults
    ...getDefaultPerformanceState(),
    ...hydratePerformanceStateFromTab(tabs.items[tabs.selectedIndex]),
  });

  //Whenever the state changes we update the tab stored state
  useUpdateEffect(() => {
    const currentTabState = getSharedDataBetweenTabAndReducer(tabs.items[tabs.selectedIndex]);
    const changedTabState = getSharedDataBetweenTabAndReducer(state);

    if (!isEqual(currentTabState, changedTabState)) {
      const newTabs = tabs.items.map((item: PerformanceTabProps) => {
        if (item.id !== tabs.items[tabs.selectedIndex].id) {
          return item;
        }

        return {
          ...item,
          ...changedTabState,
        };
      });

      tabs.setItems(newTabs);
      persistedTabs.onItemsChanged(newTabs);
    }
  }, [state]);

  const stateValue = useMemo(() => {
    return {
      state,
      dispatch,
    };
  }, [state, dispatch]);

  const tabsValue = useMemo(() => {
    return {
      tabs,
      currentTab: tabs.items[tabs.selectedIndex],
      cloneCurrentTab,
      filtersInitialOpen,
    };
  }, [tabs, cloneCurrentTab, filtersInitialOpen]);

  return (
    <PerformanceTabsContext.Provider value={tabsValue}>
      <PerformanceContext.Provider value={stateValue}>
        <PerformanceDispatchContext.Provider value={dispatch}>{children}</PerformanceDispatchContext.Provider>
      </PerformanceContext.Provider>
    </PerformanceTabsContext.Provider>
  );
};

// This function just grabs the overlap we have between the two types. It helps us bridge between the two concepts
// when saving state reducer data to the tab, or grabbing state reducer value from a previously saved tab
export function getSharedDataBetweenTabAndReducer(
  state: PerformanceTabState
): Omit<PerformanceState, 'pnlLookbacks' | 'chartRequestDateRange' | 'lifeToDateStartTime'> {
  return {
    showing: state.showing,
    filter: state.filter,
    period: state.period,
    snapshotDate: state.snapshotDate,
    pnlUnit: state.pnlUnit,
    showDelta: state.showDelta,
    benchmarks: state.benchmarks,
    showInsights: state.showInsights,
    subAccounts: state.subAccounts,
    showZeroBalances: state.showZeroBalances,
    // product was added after release of the page, meaning that some tabs wont have it instantiated. Thus we add a default here
    product: state.product ?? 'spot',
  };
}

/**
 * Whenever we instantiate the page using tab state, or we switch between tabs, we need to hydrate the state we returned with
 * derived state.
 */
function hydratePerformanceStateFromTab(tab: PerformanceTabProps): PerformanceState {
  return {
    ...getSharedDataBetweenTabAndReducer(tab),
    // PnLLookbacks always start empty. The blotter will set this piece of state accurately when it mounts.
    pnlLookbacks: [],
    // Always get a fresh request date range from the stored period. (There are rolling periods for example)
    chartRequestDateRange: periodToChartDateRange(tab.period),
    lifeToDateStartTime: null,
  };
}

function getDefaultPerformanceTabState(): PerformanceTabProps {
  return {
    ...getDefaultPerformanceState(),
    usingSmartLabel: true,
  };
}

import type { BoxProps } from '@talos/kyoko';
import { Button, Flex, FormControlSizes, Icon, IconButton, parseColor } from '@talos/kyoko';
import 'dockview/dist/styles/dockview.css';
import styled, { createGlobalStyle, css } from 'styled-components';
import { ANIMATION_DURATION } from './tokens';

export const DockViewStyles = createGlobalStyle`
  ${({ theme }) => css`
    .dockview-theme-dark.dockview-theme-dark {
      --dv-paneview-active-outline-color: var(--backgroundBody);
      --dv-tabs-and-actions-container-font-size: ${theme.fontSizeSmall}rem;
      --dv-tabs-and-actions-container-height: calc(${theme.baseSize * 1.5}px + 1px);
      --dv-tab-close-icon: var(--colorTextDefault);
      --dv-drag-over-background-color: var(--colors-blue-dim);
      --dv-drag-over-border-color: var(--colors-blue-lighten);
      --dv-tabs-container-scrollbar-color: var(--backgroundBody);
      --dv-group-view-background-color: var(--backgroundBody);
      --dv-tabs-and-actions-container-background-color: var(--backgroundFilledTabList);
      --dv-activegroup-visiblepanel-tab-background-color: var(--backgroundContent);
      --dv-activegroup-hiddenpanel-tab-background-color: var(--backgroundFilledTabList);
      --dv-inactivegroup-visiblepanel-tab-background-color: var(--backgroundContent);
      --dv-inactivegroup-hiddenpanel-tab-background-color: var(--backgroundFilledTabList);
      --dv-tab-divider-color: var(--backgroundBody);
      --dv-activegroup-visiblepanel-tab-color: var(--colorTextImportant);
      --dv-activegroup-hiddenpanel-tab-color: var(--colorTextSubtle);
      --dv-inactivegroup-visiblepanel-tab-color: var(--colorTextImportant);
      --dv-inactivegroup-hiddenpanel-tab-color: var(--colorTextSubtle);
      --dv-separator-border: var(--backgroundBody);
      --dv-paneview-header-border-color: var(--backgroundBody);
      --dv-icon-hover-background-color: var(--backgroundContentHover);
      --dv-floating-box-shadow: var(--backgroundShadow);
      --dv-active-sash-color: var(--colors-gray-050);
      --dv-background-color: var(--backgroundBody);
      --dv-group-gap-size: ${theme.spacingLayout / 2}px;

      &.animating {
        .dv-view {
          transition: width ease ${ANIMATION_DURATION}ms, height ease ${ANIMATION_DURATION}ms,
            top ease ${ANIMATION_DURATION}ms, left ease ${ANIMATION_DURATION}ms;
        }
      }

      // Style the overflow dropdown menu
      .dv-tabs-overflow-container {
        background: var(--backgroundPopover);
        .dv-tab {
          padding: 0.25rem;

          button {
            width: 100%;
            justify-content: space-between;

            .dv-default-tab-action {
              display: none;
            }
          }

          &:hover {
            background-color: var(--backgroundContentHover);
          }
        }

        .dv-tab.dv-active-tab {
          background-color: var(--backgroundContent);
        }
      }

      // Style the overflow dropdown trigger
      .dv-tabs-overflow-dropdown-default {
        white-space: nowrap;
        border-left: 1px solid var(--backgroundBody);
        span:after {
          content: ' More';
        }

        &:hover {
          background-color: var(--backgroundContentHover);
          color: var(--colorTextImportant);
        }
      }

      // Make sure the content is not visible if the panel is not visible
      .dv-view {
        display: none;

        &.visible {
          display: block;
        }
      }

      &.dv-drop-target,
      .dv-drop-target {
        > .dv-drop-target-dropzone > .dv-drop-target-selection {
          border-radius: ${theme.borderRadiusDefault}px;
          border: 2px solid var(--colors-blue-default);
          transition: all ease 200ms;
        }
      }

      .dv-content-container.dv-drop-target > .dv-drop-target-dropzone > .dv-drop-target-selection {
        background: linear-gradient(0deg, transparent 0%, hsl(from var(--colors-blue-default) h s l / 20%) 100%);

        &::before {
          content: 'Add ' attr(data-label, 'panel') ' here';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          font-size: ${theme.fontSizeSmall}rem;
          color: var(--colorTextImportant);
          background: var(--colors-blue-default);
          padding: ${theme.spacingSmall}px ${theme.spacingComfortable}px;
          border-radius: 0 0 ${theme.borderRadiusDefault}px ${theme.borderRadiusDefault}px;
        }
      }

      // Reduce the delay when hovering over the sash
      .dv-split-view-container {
        &.dv-horizontal,
        &.dv-vertical {
          & > .dv-view-container > .dv-view {
            // Make sure content inside a panel shrinks and allow it to handle scrolling
            overflow: hidden;
            // Hide annoying 1px pseudo element divider between panels
            &:not(:first-child)::before {
              content: unset;
            }
          }
        }

        .dv-sash-container .dv-sash:not(.dv-disabled):hover {
          transition-delay: 100ms;
        }
      }

      .dv-tabs-container {
        border-right: 1px solid var(--backgroundBody);
        scrollbar-width: none;
        &.dv-horizontal {
          background: var(--backgroundBody);
          gap: 1px;
        }
      }

      .dv-tabs-and-actions-container {
        border-bottom: 1px solid var(--backgroundBody);

        .dv-tabs-container {
          .dv-tab {
            min-width: 0;
            padding: 0;

            &.dv-inactive-tab:hover {
              background-color: var(--backgroundContentHover);
              color: var(--colorTextImportant);
            }

            &.dv-active-tab {
              .dv-default-tab {
                gap: ${theme.spacingSmall}px;
              }

              ${Icon} {
                color: var(--colorTextDefault);
              }

              .dv-default-tab-action {
                height: ${theme.baseSize}px;
                width: ${theme.baseSize}px;
                margin-right: -${theme.spacingSmall}px;

                :hover .dv-svg {
                  color: var(--colorTextImportant);
                }
              }
            }

            &:focus-within,
            &:focus {
              &:after {
                outline: solid 1px var(--colorsWhiteDim) !important;
              }
            }

            .dv-default-tab-content {
              padding: 0;
            }

            .dv-default-tab-action {
              width: 0;
              padding: 0;
              overflow: hidden;
              transition: ease width 0.2s;

              &:hover {
                ${Icon} {
                  color: var(--colorTextImportant);
                }
              }
            }
          }

          .dv-default-tab {
            min-width: 0;
          }

          .dv-svg {
            color: var(--colorTextMuted);
            width: 8px;
            height: 8px;
          }

          .dv-default-tab-content {
            padding: 0;
          }
        }
      }
    }
  `}
`;

export const LayoutWrapper = styled(Flex)`
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex: 1 1 0;
`;

export const TabWrapper = styled.button<Pick<BoxProps, 'background'>>`
  border-radius: 0;
  color: var(--colorTextDefault);
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: color 200ms ease, background 200ms ease;
  background: ${({ background, theme }) =>
    typeof background === 'string' ? parseColor(theme, background) : 'transparent'};

  .dv-active-tab & {
    color: var(--colorTextImportant);
    background: var(--backgroundContent);
  }

  &:hover {
    color: var(--colorTextImportant);
  }
`;
TabWrapper.defaultProps = {
  className: 'dv-default-tab',
};

export const TabTypeButton = styled(Button)`
  justify-content: space-between;
`;

export const TabIconButton = styled(IconButton)`
  border-radius: 0;
  margin-left: 1px;
  color: ${({ ghost }) => (ghost ? 'var(--colorTextDefault)' : 'var(--colorTextImportant)')};

  &:hover {
    color: var(--colorTextImportant);
  }
`;
TabIconButton.defaultProps = {
  size: FormControlSizes.Small,
  ghost: true,
};

export const TabMenu = styled(Flex)``;
TabMenu.defaultProps = {
  flexDirection: 'column',
};

export const TabMenuItem = styled(Button).attrs({ ghost: true })`
  justify-content: flex-start;
  font-weight: 400;
  text-align: left;
  color: var(--colorTextDefault);

  &:hover {
    color: var(--colorTextImportant);
  }
`;
TabMenuItem.defaultProps = {
  size: FormControlSizes.Small,
  ghost: true,
};

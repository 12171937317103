import {
  getCurrencyDrawerOptions,
  getFeeModeDrawerOptions,
  getPricingAggregationDrawerOptions,
  getSecurityDrawerOptions,
  getTierDrawerOptions,
  MarketFeeModeEnum,
  type AllInTier,
  type ColumnDef,
  type Currency,
  type FeeTier,
  type HierarchicalColumnProps,
  type InputsAndDropdownsDrawerOption,
  type Security,
  type TabProps,
} from '@talos/kyoko';
import type { ColDef } from 'ag-grid-community';
import type { Aggregation } from 'types';

export function getCustomerPricingTiersAllInTierPostPath(entity: AllInTier): string {
  return entity.Symbol == null ? `/all-in-tiers` : `/all-in-tiers/${encodeURIComponent(entity.Tier)}/symbols`;
}

export function getCustomerPricingTiersAllInTierPatchDeletePath(entity: AllInTier): string {
  return entity.Symbol == null
    ? `/all-in-tiers/${encodeURIComponent(entity.Tier)}`
    : `/all-in-tiers/${encodeURIComponent(entity.Tier)}/symbols/${entity.Symbol}`;
}

export function getCustomerPricingTiersFeeTierPostPath(entity: FeeTier): string {
  return entity.Symbol == null ? `/fee-tiers` : `/fee-tiers/${encodeURIComponent(entity.Tier)}/symbols`;
}

export function getCustomerPricingTiersFeeTierPatchDeletePath(entity: FeeTier): string {
  return entity.Symbol == null
    ? `/fee-tiers/${encodeURIComponent(entity.Tier)}`
    : `/fee-tiers/${encodeURIComponent(entity.Tier)}/symbols/${entity.Symbol}`;
}

const BPS_COLUMN_WIDTH = 120;
export const CUSTOMER_PRICING_TIERS_ALL_IN_TIER_COLUMNS: ColumnDef<AllInTier>[] = [
  { type: 'text', field: 'Tier', hide: true, sort: '+' },
  { type: 'security', field: 'Symbol', hide: true, sort: '+' },
  { type: 'bps', field: 'BidSpread', width: BPS_COLUMN_WIDTH },
  { type: 'bps', field: 'OfferSpread', width: BPS_COLUMN_WIDTH },
  { type: 'bps', field: 'SalesCommission', title: 'Commission', width: BPS_COLUMN_WIDTH },
  { type: 'pricingAggregation', field: 'PricingAggregation' },
  { type: 'bps', field: 'Fee', width: BPS_COLUMN_WIDTH },
  { type: 'feeMode', field: 'FeeMode' },
  { type: 'currency', field: 'FeeCurrency' },
  { type: 'text', field: 'QuoteTTL', title: 'Quote TTL', hide: true },
  { type: 'bps', field: 'AcceptPriceLeniency', hide: true },
];

export const CUSTOMER_PRICING_TIERS_FEE_TIERS_COLUMNS: ColumnDef<FeeTier>[] = [
  { type: 'text', field: 'Tier', hide: true, sort: '+' },
  { type: 'security', field: 'Symbol', hide: true, sort: '+' },
  { type: 'bps', field: 'Fee', width: BPS_COLUMN_WIDTH },
  { type: 'feeMode', field: 'FeeMode' },
  { type: 'currency', field: 'FeeCurrency' },
];

function getCustomerPricingTiersSharedAllInTierDrawerOptionsBeginning(
  pricingAggregations: Aggregation[]
): InputsAndDropdownsDrawerOption<AllInTier>[] {
  return [
    {
      field: 'BidSpread',
      type: 'inputBPS',
      title: 'Bid Spread (BPS)',
    },
    {
      field: 'OfferSpread',
      type: 'inputBPS',
      title: 'Offer Spread (BPS)',
    },
    {
      field: 'SalesCommission',
      type: 'inputBPS',
      title: 'Commission (BPS)',
    },
    {
      field: 'PricingAggregation',
      type: 'dropdown',
      getDropdownOptions: () => getPricingAggregationDrawerOptions(pricingAggregations),
      placeholder: 'Select Pricing Aggregation...',
    },
  ];
}

function getCustomerPricingTiersSharedAllInTierDrawerOptionsEnding(): InputsAndDropdownsDrawerOption<AllInTier>[] {
  return [
    {
      field: 'QuoteTTL',
      type: 'input',
      title: 'Quote TTL',
    },
    {
      field: 'AcceptPriceLeniency',
      type: 'inputBPS',
      title: 'Price Leniency',
    },
  ];
}

function getCustomerPricingTiersSharedFeeTierDrawerOptions(
  feeModeOptions: MarketFeeModeEnum[],
  feeCurrencyOptions: Currency[]
): InputsAndDropdownsDrawerOption<FeeTier>[] {
  return [
    { field: 'Fee', type: 'inputBPS', title: 'Fee (BPS)', getIsRequired: form => !!form.FeeMode },
    {
      field: 'FeeMode',
      type: 'dropdown',
      getDropdownOptions: () => getFeeModeDrawerOptions(feeModeOptions),
      getIsRequired: form => !!form.Fee,
      placeholder: 'Select Fee Mode...',
    },
    {
      field: 'FeeCurrency',
      type: 'dropdown',
      getDropdownOptions: () => getCurrencyDrawerOptions(feeCurrencyOptions),
      getIsHidden: form => form.FeeMode !== MarketFeeModeEnum.SpecificCurrency,
      getIsRequired: form => form.FeeMode === MarketFeeModeEnum.SpecificCurrency,
      placeholder: 'Select Fee Currency...',
    },
  ];
}

function getCustomerPricingTiersSharedAllInTierDrawerOptions(
  pricingAggregations: Aggregation[],
  feeModeOptions: MarketFeeModeEnum[],
  feeCurrencyOptions: Currency[]
): InputsAndDropdownsDrawerOption<AllInTier>[] {
  return [
    ...getCustomerPricingTiersSharedAllInTierDrawerOptionsBeginning(pricingAggregations),
    ...getCustomerPricingTiersSharedFeeTierDrawerOptions(feeModeOptions, feeCurrencyOptions),
    ...getCustomerPricingTiersSharedAllInTierDrawerOptionsEnding(),
  ];
}

export function getCustomerPricingTiersAllInTierDrawerOptions(
  pricingAggregations: Aggregation[],
  feeModeOptions: MarketFeeModeEnum[],
  feeCurrencyOptions: Currency[]
): InputsAndDropdownsDrawerOption<AllInTier>[] {
  return [
    {
      field: 'Tier',
      type: 'input',
      getIsRequired: () => true,
      disabledWhenEditing: true,
      title: 'Name',
    },
    { type: 'divider' },
    ...getCustomerPricingTiersSharedAllInTierDrawerOptions(pricingAggregations, feeModeOptions, feeCurrencyOptions),
  ];
}

export function getCustomerPricingTiersAllInTierOverridesDrawerOptions(
  pricingAggregations: Aggregation[],
  feeModeOptions: MarketFeeModeEnum[],
  feeCurrencyOptions: Currency[],
  allInTierGroups: AllInTier[],
  filteredSecurities: Security[]
): InputsAndDropdownsDrawerOption<AllInTier>[] {
  return [
    {
      field: 'Tier',
      type: 'dropdown',
      getIsRequired: () => true,
      disabledWhenEditing: true,
      title: 'Base Tier',
      getDropdownOptions: () => getTierDrawerOptions(allInTierGroups),
      placeholder: 'Select Base Tier...',
    },
    {
      field: 'Symbol',
      type: 'dropdown',
      disabledWhenEditing: true,
      getIsRequired: () => true,
      placeholder: 'Select Symbol...',
      getDropdownOptions: () => getSecurityDrawerOptions(filteredSecurities),
    },
    { type: 'divider' },
    ...getCustomerPricingTiersSharedAllInTierDrawerOptions(pricingAggregations, feeModeOptions, feeCurrencyOptions),
  ];
}

export function getCustomerPricingTiersFeeTierDrawerOptions(
  feeModeOptions: MarketFeeModeEnum[],
  feeCurrencyOptions: Currency[]
): InputsAndDropdownsDrawerOption<FeeTier>[] {
  return [
    {
      field: 'Tier',
      type: 'input',
      getIsRequired: () => true,
      disabledWhenEditing: true,
      title: 'Name',
    },
    { type: 'divider' },
    ...getCustomerPricingTiersSharedFeeTierDrawerOptions(feeModeOptions, feeCurrencyOptions),
  ];
}

export function getCustomerPricingTiersFeeTierOverridesDrawerOptions(
  feeModeOptions: MarketFeeModeEnum[],
  feeCurrencyOptions: Currency[],
  feeTierGroups: FeeTier[],
  filteredSecurities: Security[]
): InputsAndDropdownsDrawerOption<FeeTier>[] {
  return [
    {
      field: 'Tier',
      type: 'dropdown',
      getIsRequired: () => true,
      disabledWhenEditing: true,
      title: 'Fee Tier',
      getDropdownOptions: () => getTierDrawerOptions(feeTierGroups),
      placeholder: 'Select Fee Tier...',
    },
    {
      field: 'Symbol',
      type: 'dropdown',
      disabledWhenEditing: true,
      getIsRequired: () => true,
      placeholder: 'Select Symbol...',
      getDropdownOptions: () => getSecurityDrawerOptions(filteredSecurities),
    },
    { type: 'divider' },
    ...getCustomerPricingTiersSharedFeeTierDrawerOptions(feeModeOptions, feeCurrencyOptions),
  ];
}

export const CUSTOMER_PRICING_TIERS_GROUP_COLUMN_DEF: ColDef<AllInTier | FeeTier, string> = {
  headerName: 'Name',
  valueFormatter: ({ context, value }) => {
    const security = value ? context.current.securitiesBySymbol?.get(value) : null;
    return security?.DisplaySymbol ?? security?.Symbol ?? value ?? '';
  },
};

export const CUSTOMER_PRICING_TIERS_ADD_CHILD_ENTITY_BUTTON_PROPS: HierarchicalColumnProps<
  AllInTier | FeeTier
>['buttonProps'] = {
  text: 'Symbol Override',
  width: 137,
};

export const CUSTOMER_PRICING_TIERS_TAB_ITEMS: TabProps[] = [
  { label: 'Base Tiers', closable: false, editable: true },
  { label: 'Fee Tiers', closable: false, editable: true },
];

import {
  HEDGE_ORDER_STATUS,
  useObservable,
  useStaticSubscription,
  wsSubscriptionCache,
  type HedgeOrderStatus,
  type IOrderAnnotations,
  type MinimalSubscriptionResponse,
} from '@talos/kyoko';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import type { Observable } from 'rxjs';

export interface AuxiliaryOrderDataContextProps {
  hedgeOrderStatusObs: Observable<MinimalSubscriptionResponse<HedgeOrderStatus>>;
  orderAnnotationsObs: Observable<MinimalSubscriptionResponse<IOrderAnnotations>>;
}

export const AuxiliaryOrderDataContext = createContext<AuxiliaryOrderDataContextProps | undefined>(undefined);
AuxiliaryOrderDataContext.displayName = 'AuxiliaryOrderDataContext';

export function useAuxiliaryOrderData(): AuxiliaryOrderDataContextProps {
  const context = useContext(AuxiliaryOrderDataContext);
  if (!context) {
    throw new Error('Missing HedgeOrderStatus.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/**
 * This provider is responsible for enriching orders with auxiliary data types such as HedgeOrderStatus and OrderAnnotations.
 * The idea is that these related types contain references back to the orders they are related to, and this provider
 * handles stiching the entities together.
 *
 * The pattern works like this:
 * 1. This provider subscribes to the secondary streams (e.x. HedgeOrderStatus and OrderAnnotations) for updates only
 * 2. When other providers subscribe to orders (e.x. OrdersProvider), they ask the backend to include this auxiliary
 * data in the initial set and then use the useAuxiliaryOrderData hook to get updates on the secondary entities.
 *
 * Why? - This pattern allows us to stop adding properties to ExecutionReport and Order which are not always needed. It
 * also lets us avoid having those types update after their traditional lifecycle (which can cause issues in the BE).
 * The downside to this pattern is the backend must not offer filtering orders based on secondary type properties as
 * servicing those subscriptions would be very memory intensive.
 */
export const AuxiliaryOrderDataProvider = function AuxiliaryOrderDataProvider(props: PropsWithChildren<unknown>) {
  const { data: hedgeStatusSub } = useStaticSubscription<HedgeOrderStatus>({
    name: HEDGE_ORDER_STATUS,
    tag: 'HedgeOrderStatusProvider',
  });

  const hedgeOrderStatusCache = useObservable(
    () => hedgeStatusSub.pipe(wsSubscriptionCache(hos => hos.InitiatingOrderID, 'HedgeOrderStatus')),
    [hedgeStatusSub]
  );

  const { data: annotationSub } = useStaticSubscription<IOrderAnnotations>({
    name: 'OrderAnnotations',
    tag: 'OrderAnnotationProvider',
  });

  const orderAnnotationsCache = useObservable(
    () => annotationSub.pipe(wsSubscriptionCache(annotation => annotation.OrderID, 'Annotation')),
    [annotationSub]
  );

  const value = useMemo<AuxiliaryOrderDataContextProps>(() => {
    return { hedgeOrderStatusObs: hedgeOrderStatusCache, orderAnnotationsObs: orderAnnotationsCache };
  }, [hedgeOrderStatusCache, orderAnnotationsCache]);

  return <AuxiliaryOrderDataContext.Provider value={value}>{props.children}</AuxiliaryOrderDataContext.Provider>;
};

export const BLOTTER_ID_V1_OLD_RECENT_ORDERS = 'BLOTTER_ID_RECENT_ORDERS';
export const BLOTTER_ID_V1_OLD_RECENT_TRADES = 'BLOTTER_ID_RECENT_TRADES';
export const BLOTTER_ID_CUSTOMER_BALANCES = 'BLOTTER_ID_CUSTOMER_BALANCES_2.36.0';
export const BLOTTER_ID_CUSTOMER_TRANSFERS = 'BLOTTER_ID_CUSTOMER_TRANSFERS';
// Analytics
export const BLOTTER_ID_ORDER_ANALYTICS_TRADES = 'BLOTTER_ID_ORDER_ANALYTICS_TRADES';
// Order Details
export const BLOTTER_ID_TRADES_ORDER_DETAILS = 'BLOTTER_ID_TRADES_ORDER_DETAILS';
export const BLOTTER_ID_CUSTOMER_TRADES_ORDER_DETAILS = 'BLOTTER_ID_CUSTOMER_TRADES_ORDER_DETAILS';
export const BLOTTER_ID_MARKET_ORDERS_ORDER_DETAILS = 'BLOTTER_ID_MARKET_ORDERS_ORDER_DETAILS';
export const BLOTTER_ID_EXEC_REPORTS_ORDER_DETAILS = 'BLOTTER_ID_EXEC_REPORTS_ORDER_DETAILS_2.40.0';
export const BLOTTER_ID_MARKET_EXEC_REPORTS_ORDER_DETAILS = 'BLOTTER_ID_MARKET_EXEC_REPORTS_ORDER_DETAILS';
export const BLOTTER_ID_ALLOCATIONS_ORDER_DETAILS = 'BLOTTER_ID_ALLOCATIONS_ORDER_DETAILS';
export const BLOTTER_ID_DDH_ORDERS_ORDER_DETAILS = 'BLOTTER_ID_DDH_ORDERS_ORDER_DETAILS';
export const BLOTTER_ID_HEDGE_ORDER_STATUS_ORDER_DETAILS = 'BLOTTER_ID_HEDGE_ORDER_STATUS_ORDER_DETAILS';

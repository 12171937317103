import type { ComponentType, PropsWithChildren } from 'react';

/**
 * Composes a list of React components to prevent ski-sloping:
 * @example
 * ```
 * composeComponents([A,B,C])
 * ```
 * is equivalent to
 * ```
 * <A>
 *   <B>
 *     <C>
 *       ...
 *     </C>
 *   </B>
 * </A>
 * ```
 *
 * @param components Array of components to compose
 */
export function composeComponents(components: ComponentType<any>[]): ComponentType<PropsWithChildren<unknown>> {
  return function ComponentTree({ children }: PropsWithChildren) {
    const lastIndex = components.length - 1;
    let activeChildren = <>{children}</>;

    for (let i = lastIndex; i >= 0; i--) {
      const Provider = components[i];

      activeChildren = <Provider>{activeChildren}</Provider>;
    }

    return activeChildren;
  };
}

import { Flex } from '@talos/kyoko';
import { useMemo } from 'react';
import type { MarketSelectorRow } from './blotter/types';
import { useMarketSelectorContext } from './MarketSelectorContext';

interface MarketSelectorSelectionSummaryProps {
  rows: MarketSelectorRow[];
}

export const MarketSelectorSelectionSummary = ({ rows }: MarketSelectorSelectionSummaryProps) => {
  const { enrichments } = useMarketSelectorContext();
  const summary = useMemo(() => enrichments.find(e => e.selectionSummary != null)?.selectionSummary, [enrichments]);

  if (!summary) {
    return null;
  }

  return (
    <Flex minHeight="28px" px="spacingDefault" borderTop="1px solid var(--backgroundDivider)">
      {summary(rows)}
    </Flex>
  );
};

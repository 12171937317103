import { MarketAccountStatusEnum, type Market, type MarketAccount } from '@talos/kyoko';
import {
  AvailabilityEnum,
  type AvailabilityCondition,
  type AvailabilityResult,
  type MarketSelectorItem,
} from '../types';

type BuildMarketSelectorItemParams = {
  market: Market;
  marketAccount?: MarketAccount;
  conditions: AvailabilityCondition[];
};
/**
 * Given a market and maybe a market account, builds you a Market Selector Item. The function
 * verifies your to-be item against the AvailabilityConditions you pass in and applies that AvailabilityResult
 * to the item. As is standard for MarketSelectorItems, Market Accounts are preferred. As in: if both a market and market account is passed,
 * the item will reflect the market account. The market is still available on the item though to help with lookups.
 */
export function buildMarketSelectorItem({
  market,
  marketAccount,
  conditions,
}: BuildMarketSelectorItemParams): MarketSelectorItem {
  const availability = evaluateAvailabilityConditions({ market, marketAccount, conditions });
  return {
    type: marketAccount ? 'MarketAccount' : 'Market',
    name: marketAccount ? marketAccount.Name : market.Name,
    displayName: marketAccount ? marketAccount.DisplayName : market.DisplayName,
    market,
    marketAccount,
    availability,
  };
}

export function evaluateAvailabilityConditions({
  market,
  marketAccount,
  conditions,
}: {
  market: Market;
  marketAccount?: MarketAccount;
  conditions: AvailabilityCondition[];
}): AvailabilityResult {
  const availabilityResults = conditions
    .filter(condition => condition.ready)
    .map(condition => condition.condition(market, marketAccount));

  const worstResult: AvailabilityResult = availabilityResults.reduce(
    (worst, next) => {
      return next.availability < worst.availability ? next : worst;
    },
    { availability: AvailabilityEnum.Ok }
  );

  return worstResult;
}

export function isMarketSelectorItemSelectable(item: Pick<MarketSelectorItem, 'availability'>) {
  return item.availability.availability > AvailabilityEnum.Disabled;
}

/** Given an array of availability enums, will return the lowest (worst) availability. If the array is empty, will return OK. */
export function getLowestAvailability(availabilities: AvailabilityEnum[]): AvailabilityEnum {
  return availabilities.reduce((worst, next) => {
    return next < worst ? next : worst;
  }, AvailabilityEnum.Ok);
}

export function marketSelectorItemComparator(
  a: MarketSelectorItem | undefined,
  b: MarketSelectorItem | undefined,
  direction: '+' | '-'
): number {
  if (direction === '+') {
    // if we're sorting in the other direction, reverse the two variables
    const temp = a;
    a = b;
    b = temp;
  }

  if (!a && !b) {
    return 0;
  }

  if (!a) {
    return -1;
  }

  if (!b) {
    return 1;
  }

  return a.displayName.localeCompare(b.displayName);
}

/**
 * This function converts from the new MarketSelectorItem type to what the old Market Selector component will expect in terms of type and the array of items themselves.
 */
export function filterMarketSelectorItemsForOldMarketSelector(items: MarketSelectorItem[]): string[] {
  return items
    .filter(item => {
      const sufficientAvailability = item.availability.availability >= AvailabilityEnum.Disabled;
      return sufficientAvailability && item.marketAccount?.Status !== MarketAccountStatusEnum.Inactive;
    })
    .map(item => item.name);
}

import { Box, getLoadingAnimation } from '@talos/kyoko';
import styled from 'styled-components';

export const KPIWrapper = styled(Box)<{ isLoading?: boolean }>`
  ${({ theme, isLoading }) => getLoadingAnimation(theme, isLoading, 'backgroundContent', 'gray.010')}
`;
KPIWrapper.defaultProps = {
  p: 'spacingMedium',
  flex: '1 1 0',
  minWidth: '11rem',
  minHeight: '4rem',
  overflow: 'hidden',
};

export const ValueRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacingDefault}px;
`;

export const Value = styled.div`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colorTextImportant};
  white-space: nowrap;
`;

export const Suffix = styled.label`
  color: ${({ theme }) => theme.colorTextDefault};
  font-size: 75%;
`;

export const Label = styled.div`
  margin-bottom: ${({ theme }) => theme.spacingDefault}px;
  color: ${({ theme }) => theme.colorTextDefault};
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
`;

import {
  Card,
  FilledMeter,
  Flex,
  HStack,
  IconName,
  InlineFormattedNumber,
  SmartSummary,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  getFilledBaseQuantity,
  getFilledCounterAmount,
  getFilledNotional,
  getOpenNotional,
  percentToBps,
  useCurrenciesContext,
  useCustomerSummary,
  useSecurity,
  type CardProps,
  type CustomerOrder,
  type Order,
} from '@talos/kyoko';
import Big from 'big.js';
import { Fragment } from 'react';
import { SpreadFilledSpread } from '../../../../../components/SpreadFilledSpread';

export interface FilledCardProps extends CardProps {
  order: Order;
  customerOrder?: CustomerOrder;
  showExpandedDetails?: boolean;
}

export function FilledCard({ order, customerOrder, showExpandedDetails, ...props }: FilledCardProps) {
  const security = useSecurity(order.Symbol);
  const { currenciesBySymbol } = useCurrenciesContext();

  // Order
  const orderFillDetails = {
    filledCounterAmount: getFilledCounterAmount(order, security),
    filledBaseQty: getFilledBaseQuantity(order, security),
    filledNotional: getFilledNotional(order, security),
    openNotional: getOpenNotional(order, security),
  } as const;

  // Customer order
  const customerOrderFillDetails =
    customerOrder == null
      ? undefined
      : ({
          filledCounterAmount: getFilledCounterAmount(customerOrder, security),
          filledBaseQty: getFilledBaseQuantity(customerOrder, security),
          filledNotional: getFilledNotional(customerOrder, security),
          openNotional: getOpenNotional(customerOrder, security),
        } as const);

  const entitySummary = useCustomerSummary({
    entity: customerOrder,
    tag: 'FilledCard',
    type: 'order',
    idField: customerOrder?.RFQID ? 'RFQID' : 'OrderID',
  });

  const pnl = entitySummary?.PnL;
  const spread = entitySummary?.Spread;
  const filledSpread = entitySummary?.FilledSpread;

  return (
    <Card title="Filled" {...props}>
      <Flex
        flexDirection="column"
        alignItems="stretch"
        gap="spacingSmall"
        mt="-spacingComfortable"
        mx="-spacingComfortable"
        p="spacingComfortable"
        background="colors.gray.030"
        mb="spacingComfortable"
      >
        <HStack justifyContent="space-between" fontSize="fontSizeSmall">
          <HStack gap="spacingSmall">
            <HStack gap="spacingSmall">
              <Text>Filled</Text>
              <InlineFormattedNumber
                number={(Big(order.OrderQty ?? '0').gt(0)
                  ? Big(order.CumQty ?? '0')
                      .div(order.OrderQty)
                      .times(100)
                  : Big(0)
                ).toFixed(2)}
                currency="%"
              />
            </HStack>
          </HStack>
          <HStack gap="spacingSmall">
            <Text>Remaining</Text>
            <InlineFormattedNumber
              number={(Big(order.OrderQty ?? '0').gt(0)
                ? Big(order.LeavesQty ?? '0')
                    .div(order.OrderQty)
                    .times(100)
                : Big(0)
              ).toFixed(2)}
              currency="%"
            />
          </HStack>
        </HStack>
        <FilledMeter entity={order} />
      </Flex>
      <Table w="100%">
        <Thead>
          <Tr>
            {customerOrder != null && (
              <>
                <Th align="left" colSpan={1} pl={0} pb="spacingDefault">
                  <Card.Subtitle mb={0}>Filled</Card.Subtitle>
                </Th>
                <Th align="right" w="33%" pb="spacingDefault">
                  Customer
                </Th>
                <Th align="right" w="33%" pb="spacingDefault">
                  Hedge
                </Th>
              </>
            )}
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Th>Open Qty</Th>
            {customerOrder != null && (
              <Td align="right">
                <SmartSummary
                  entity={customerOrder}
                  type="order"
                  qtyField="LeavesQty"
                  showSide={false}
                  showPrice={false}
                  showLastMarket={false}
                />
              </Td>
            )}
            <Td align="right">
              <SmartSummary
                entity={order}
                type="order"
                qtyField="LeavesQty"
                showSide={false}
                showPrice={false}
                showLastMarket={false}
              />
            </Td>
          </Tr>
          {showExpandedDetails && (
            <Tr>
              <Th>Open Notional</Th>
              {customerOrderFillDetails != null && (
                <Td align="right">
                  <InlineFormattedNumber
                    number={customerOrderFillDetails.openNotional.value}
                    currency={customerOrderFillDetails.openNotional.currency}
                  />
                </Td>
              )}
              <Td align="right">
                <InlineFormattedNumber
                  number={orderFillDetails.openNotional.value}
                  currency={orderFillDetails.openNotional.currency}
                />
              </Td>
            </Tr>
          )}
          <Tr>
            <Th>Filled Qty</Th>
            {customerOrder != null && (
              <Td align="right">
                <SmartSummary
                  entity={customerOrder}
                  type="order"
                  qtyField="CumQty"
                  showSide={false}
                  showPrice={false}
                />
              </Td>
            )}
            <Td align="right">
              <SmartSummary entity={order} type="order" qtyField="CumQty" showSide={false} showPrice={false} />
            </Td>
          </Tr>
          <Tr>
            <Th>Filled Px (no fees)</Th>
            {customerOrder != null && (
              <Td align="right">
                <InlineFormattedNumber
                  number={customerOrder.AvgPx}
                  currency={security?.QuoteCurrency}
                  increment={security?.DefaultPriceIncrement}
                  specification={security?.PriceDisplaySpec}
                  align="right"
                />
              </Td>
            )}
            <Td align="right">
              <InlineFormattedNumber
                number={order.AvgPx}
                currency={security?.QuoteCurrency}
                increment={security?.DefaultPriceIncrement}
                specification={security?.PriceDisplaySpec}
                align="right"
              />
            </Td>
          </Tr>
          <Tr>
            <Th>Fees</Th>
            {entitySummary != null && (
              <Td align="right" colSpan={1}>
                {entitySummary?.Fees?.at(0) && (
                  <InlineFormattedNumber
                    number={entitySummary.Fees.at(0)?.Amount}
                    currency={entitySummary.Fees.at(0)?.Currency}
                    align="right"
                  />
                )}
              </Td>
            )}
            <Td align="right" colSpan={customerOrder == null ? 1 : 2}>
              <InlineFormattedNumber number={order.CumFee} currency={order.FeeCurrency} />
            </Td>
          </Tr>
          {entitySummary != null && (
            <Tr>
              <Th>Sales Commission</Th>
              {entitySummary.SalesCommission != null && (
                <Td align="right" colSpan={1}>
                  <InlineFormattedNumber
                    number={percentToBps(entitySummary.SalesCommission)}
                    currency="BPS"
                    align="right"
                  />
                </Td>
              )}
            </Tr>
          )}

          <Tr>
            <Th>Filled Px (w/ fees)</Th>
            {customerOrder && (
              <Td align="right" colSpan={1}>
                <InlineFormattedNumber
                  number={customerOrder.AvgPxAllIn}
                  currency={security?.QuoteCurrency}
                  increment={security?.DefaultPriceIncrement}
                  specification={security?.PriceDisplaySpec}
                  startIcon={IconName.Fees}
                  align="right"
                />
              </Td>
            )}
            <Td align="right" colSpan={customerOrder == null ? 1 : 2}>
              <InlineFormattedNumber
                number={order.AvgPxAllIn}
                currency={security?.QuoteCurrency}
                increment={security?.DefaultPriceIncrement}
                specification={security?.PriceDisplaySpec}
                startIcon={IconName.Fees}
                align="right"
              />
            </Td>
          </Tr>
          {showExpandedDetails && (
            <Tr>
              <Th>Talos Fee</Th>
              <Td align="right" colSpan={customerOrder == null ? 1 : 2}>
                <InlineFormattedNumber number={order.CumTalosFee} currency={order.TalosFeeCurrency} />
              </Td>
            </Tr>
          )}
          {showExpandedDetails && (
            <Tr>
              <Th>Filled Notional</Th>
              {customerOrderFillDetails != null && (
                <Td align="right">
                  <InlineFormattedNumber
                    number={customerOrderFillDetails.filledNotional.value}
                    currency={customerOrderFillDetails.filledNotional.currency}
                  />
                </Td>
              )}
              <Td align="right">
                <InlineFormattedNumber
                  number={orderFillDetails.filledNotional.value}
                  currency={orderFillDetails.filledNotional.currency}
                />
              </Td>
            </Tr>
          )}
          {showExpandedDetails && (
            <Tr>
              <Th>Filled Base Qty</Th>
              {customerOrderFillDetails != null && (
                <Td align="right">
                  <InlineFormattedNumber
                    round={true}
                    number={customerOrderFillDetails.filledBaseQty.value}
                    currency={customerOrderFillDetails.filledBaseQty.currency}
                  />
                </Td>
              )}
              <Td align="right">
                <InlineFormattedNumber
                  round={true}
                  number={orderFillDetails.filledBaseQty.value}
                  currency={orderFillDetails.filledBaseQty.currency}
                />
              </Td>
            </Tr>
          )}
          {showExpandedDetails && (
            <Tr>
              <Th>Filled Counter Amt</Th>
              {customerOrderFillDetails != null && (
                <Td align="right">
                  <InlineFormattedNumber
                    round={true}
                    number={customerOrderFillDetails.filledCounterAmount.value}
                    currency={customerOrderFillDetails.filledCounterAmount.currency}
                  />
                </Td>
              )}
              <Td align="right">
                <InlineFormattedNumber
                  round={true}
                  number={orderFillDetails.filledCounterAmount.value}
                  currency={orderFillDetails.filledCounterAmount.currency}
                />
              </Td>
            </Tr>
          )}
          {customerOrder != null && (
            <Tr>
              <Th>
                <Tooltip tooltip="Expected spread vs. Filled spread" showUnderline={true}>
                  Spread
                </Tooltip>
              </Th>
              <Td align="right" whiteSpace="nowrap">
                <SpreadFilledSpread spread={spread} filledSpread={filledSpread} />
              </Td>
            </Tr>
          )}
          {customerOrder != null && (
            <Tr>
              <Th>
                <Tooltip tooltip="Profit in base currency, and counter currency if applicable." showUnderline={true}>
                  Profit
                </Tooltip>
              </Th>
              <Td align="right">
                {pnl == null
                  ? '-'
                  : pnl.map(({ Currency, Amount }, index) => {
                      const currency = currenciesBySymbol.get(Currency);
                      return (
                        <Fragment key={Currency}>
                          <InlineFormattedNumber
                            number={Amount}
                            currency={Currency}
                            increment={currency?.DefaultIncrement}
                          />
                          {index !== pnl.length - 1 && ' / '}
                        </Fragment>
                      );
                    })}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Card>
  );
}

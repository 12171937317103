import {
  MarketAccountStatusEnum,
  PORTFOLIO_TRADING_ACCOUNTS,
  PORTFOLIO_TREASURY_ACCOUNTS,
  PORTFOLIO_TREASURY_LINKS,
  TreasuryLinkStatusEnum,
  useObservable,
  useObservableValue,
  useStaticSubscription,
  wsScanToDoubleMap,
  wsScanToMap,
  type SubscriptionResponse,
  type TreasuryLink,
} from '@talos/kyoko';
import type { DestinationAccount, SourceAccount } from 'containers/Portfolio/types';
import { createContext, useCallback, useContext, useMemo, useRef, type PropsWithChildren } from 'react';
import { Subject, map, merge, shareReplay, type Observable } from 'rxjs';

export const PortfolioAccountsContext = createContext<PortfolioAccountsContextProps | undefined>(undefined);
PortfolioAccountsContext.displayName = 'PortfolioAccountsContext';

export type PortfolioAccountsContextProps = {
  destinationAccountsList: DestinationAccount[] | undefined;
  sourceAccountsList: SourceAccount[] | undefined;
  sourceAccountsByName: Map<string, SourceAccount> | undefined;
  /**
   * A DoubleMap. The outer map has the specificness keys, and the inner map has the LinkID
   *
   * The specificness is not a unique key (any longer). This means that for each specificness key, there might be several Links (LinkIDs).
   */
  treasuryLinksBySpecificnessKey: Map<string, Map<string, TreasuryLink>> | undefined;
  treasuryLinksByLinkID: Map<string, TreasuryLink> | undefined;
  treasuryLinksList: TreasuryLink[] | undefined;
  treasuryLinksByMarketAccount: Map<string, TreasuryLink[]> | undefined;
  updateTreasuryLinks: (updatedLinks: TreasuryLink[]) => void;
  treasuryLinksBySourceDestinationMarketAccounts: Map<string, TreasuryLink> | undefined;
  treasuryLinksByDestinationMarketAccount: Map<string, TreasuryLink[]> | undefined;
  treasuryLinksByDestinationMarketAccountObs: Observable<Map<string, TreasuryLink[]>>;
};

export function usePortfolioAccounts() {
  const context = useContext(PortfolioAccountsContext);
  if (context === undefined) {
    throw new Error('Missing PortfolioAccountsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const PortfolioAccountsProvider = function PortfolioAccountsProvider({ children }: PropsWithChildren) {
  const { data: destinationAccountsRawObs } = useStaticSubscription<DestinationAccount>({
    name: PORTFOLIO_TRADING_ACCOUNTS,
    tag: 'PortfolioAccountsProvider',
  });

  const { data: sourceAccountsRawObs } = useStaticSubscription<SourceAccount>({
    name: PORTFOLIO_TREASURY_ACCOUNTS,
    tag: 'PortfolioAccountsProvider',
  });

  const { data: treasuryLinksRawObs } = useStaticSubscription<TreasuryLink>(
    {
      name: PORTFOLIO_TREASURY_LINKS,
      tag: 'PortfolioAccountsProvider',
    },
    { loadAll: true }
  );

  // When receiving updates over REST we immediately want to input into the provider.
  // backend ws updates come from dbpoller (slightly delayed), so this helps with that.
  // we then handle the delayed duplicate (pipe is idempotent)
  const treasuryLinksUpdatesSubject = useRef(new Subject<SubscriptionResponse<any, any>>());
  const treasuryLinksUpdatesObs = useObservable<SubscriptionResponse<TreasuryLink, string>>(
    () => treasuryLinksUpdatesSubject.current.asObservable(),
    []
  );
  const updateTreasuryLinks = useCallback((updatedLinks: TreasuryLink[]) => {
    treasuryLinksUpdatesSubject.current.next({
      type: '',
      ts: '',
      data: updatedLinks,
    });
  }, []);

  const destinationAccountsListObs = useObservable(
    () =>
      destinationAccountsRawObs.pipe(
        wsScanToMap({
          getUniqueKey: d => d.Name,
          newMapEachUpdate: false,
          shouldDelete: d => d.Status === MarketAccountStatusEnum.Inactive,
        }),
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [destinationAccountsRawObs]
  );

  const sourceAccountsListObs = useObservable(
    () =>
      sourceAccountsRawObs.pipe(
        wsScanToMap({
          getUniqueKey: d => d.Name,
          newMapEachUpdate: false,
          shouldDelete: d => d.Status === MarketAccountStatusEnum.Inactive,
        }),
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [sourceAccountsRawObs]
  );

  const sourceAccountsByNameObs = useObservable(
    () => sourceAccountsListObs.pipe(map(list => new Map(list.map(acc => [acc.Name, acc])))),
    [sourceAccountsListObs]
  );

  const mergedTreasuryLinksObs = useMemo(
    () => merge(treasuryLinksRawObs, treasuryLinksUpdatesObs),
    [treasuryLinksRawObs, treasuryLinksUpdatesObs]
  );

  const treasuryLinksByLinkIDObs = useMemo(
    () =>
      mergedTreasuryLinksObs.pipe(
        wsScanToMap({
          getUniqueKey: link => link.LinkID,
          newMapEachUpdate: true,
          shouldDelete: (d: TreasuryLink) => d.Status === TreasuryLinkStatusEnum.Inactive,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [mergedTreasuryLinksObs]
  );

  const treasuryLinksBySpecificnessKeyObs = useObservable(
    () =>
      mergedTreasuryLinksObs.pipe(
        wsScanToDoubleMap({
          getKey1: link => link.specificnessKey,
          getKey2: link => link.LinkID,
          newOuterMapEachUpdate: true,
          newInnerMapsEachUpdate: true,
          deleteInnerMapOnEmptied: true,
          shouldDelete: (d: TreasuryLink) => d.Status === TreasuryLinkStatusEnum.Inactive,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [mergedTreasuryLinksObs]
  );

  const treasuryLinksListObs = useObservable(
    () =>
      treasuryLinksByLinkIDObs.pipe(
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [treasuryLinksByLinkIDObs]
  );

  const treasuryLinksByMarketAccountObs = useObservable(
    () =>
      treasuryLinksListObs.pipe(
        map(linksList => {
          const map = new Map<string, TreasuryLink[]>();
          linksList.forEach((link: TreasuryLink) => {
            const mapLinkList = map.get(link.getMarketAccountOrWildcard);
            if (mapLinkList) {
              mapLinkList.push(link);
            } else {
              map.set(link.getMarketAccountOrWildcard, [link]);
            }
          });
          return map;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [treasuryLinksListObs]
  );

  const treasuryLinksByDestinationMarketAccountObs = useObservable(
    () =>
      treasuryLinksListObs.pipe(
        map(linksList => {
          const map = new Map<string, TreasuryLink[]>();
          linksList.forEach((link: TreasuryLink) => {
            if (link.DestinationMarketAccount) {
              const mapLinkList = map.get(link.DestinationMarketAccount);
              if (mapLinkList) {
                mapLinkList.push(link);
              } else {
                map.set(link.DestinationMarketAccount, [link]);
              }
            }
          });
          return map;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [treasuryLinksListObs]
  );

  // Be able to access links by their Source-Destination market account combo
  const treasuryLinksBySourceDestinationMarketAccountsObs = useObservable(
    () =>
      treasuryLinksListObs.pipe(
        map(linksList => {
          const map = new Map<string, TreasuryLink>();
          linksList.forEach((link: TreasuryLink) => {
            if (link.SourceMarketAccount && link.DestinationMarketAccount) {
              const key = getSourceDestinationKey(link.SourceMarketAccount, link.DestinationMarketAccount);
              map.set(key, link);
            }
          });

          return map;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [treasuryLinksListObs]
  );

  const destinationAccountsList = useObservableValue(() => destinationAccountsListObs, [destinationAccountsListObs]);
  const sourceAccountsList = useObservableValue(() => sourceAccountsListObs, [sourceAccountsListObs]);
  const sourceAccountsByName = useObservableValue(() => sourceAccountsByNameObs, [sourceAccountsByNameObs]);
  const treasuryLinksBySpecificnessKey = useObservableValue(
    () => treasuryLinksBySpecificnessKeyObs,
    [treasuryLinksBySpecificnessKeyObs]
  );
  const treasuryLinksByLinkID = useObservableValue(() => treasuryLinksByLinkIDObs, [treasuryLinksByLinkIDObs]);
  const treasuryLinksList = useObservableValue(() => treasuryLinksListObs, [treasuryLinksListObs]);
  const treasuryLinksByMarketAccount = useObservableValue(
    () => treasuryLinksByMarketAccountObs,
    [treasuryLinksByMarketAccountObs]
  );
  const treasuryLinksBySourceDestinationMarketAccounts = useObservableValue(
    () => treasuryLinksBySourceDestinationMarketAccountsObs,
    [treasuryLinksBySourceDestinationMarketAccountsObs]
  );
  const treasuryLinksByDestinationMarketAccount = useObservableValue(
    () => treasuryLinksByDestinationMarketAccountObs,
    [treasuryLinksByDestinationMarketAccountObs]
  );

  const value = useMemo(
    () => ({
      destinationAccountsList,
      sourceAccountsList,
      sourceAccountsByName,
      treasuryLinksBySpecificnessKey,
      treasuryLinksByLinkID,

      treasuryLinksList,
      treasuryLinksByMarketAccount,
      updateTreasuryLinks,
      treasuryLinksBySourceDestinationMarketAccounts,
      treasuryLinksByDestinationMarketAccountObs,
      treasuryLinksByDestinationMarketAccount,
    }),
    [
      destinationAccountsList,
      sourceAccountsList,
      sourceAccountsByName,
      treasuryLinksBySpecificnessKey,
      treasuryLinksByLinkID,

      treasuryLinksList,
      treasuryLinksByMarketAccount,
      updateTreasuryLinks,
      treasuryLinksBySourceDestinationMarketAccounts,
      treasuryLinksByDestinationMarketAccountObs,
      treasuryLinksByDestinationMarketAccount,
    ]
  );

  return <PortfolioAccountsContext.Provider value={value}>{children}</PortfolioAccountsContext.Provider>;
};

export function getSourceDestinationKey(sourceMktAcc: string, destinationMktAcc: string): string {
  return `${sourceMktAcc}-${destinationMktAcc}`;
}

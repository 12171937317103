import { type ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { useMarketAccountsContext, useMarketsContext } from '../../contexts';
import { Button, IconButton } from '../Button';
import { Box, type BoxProps, Flex, HStack, VStack } from '../Core';
import { FormControlSizes } from '../Form';
import { Icon, IconName } from '../Icons';
import { type SortState, SortableBox } from '../SortableBox/SortableBox';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

export type MarketSelectionListSortProperty = 'label' | 'suffix';

interface MarketSelectionsListProps {
  /** A selection is allowed to be either a Market.Name or a MarketAccount.Name. The array can also be a mixture of these. */
  selections: string[];
  onDelete?: (selection: string) => void;
  onDeleteAll?: () => void;
  /** Height of the entire list */
  h?: BoxProps['h'];
  /** Get the status of the item. If there is a problem, a yellow dot with a tooltip will be displayed. */
  getWarning?: (market: string) => ReactNode | undefined;
  /** Render an arbitrary react node as a suffix (far-right) element in the selection item */
  getItemSuffix?: (market: string) => ReactNode;
  /** Render an arbitrary react node as a suffix (far-right) element in the list's header */
  getHeaderSuffix?: () => ReactNode;
  /** Optional. Override the individual item height. */
  itemHeight?: string;
  /** The sorting state, if any, to apply to the list of selections to render */
  sort?: SortState<MarketSelectionListSortProperty>;
  /** An onChange handler  */
  onSortChange?: (newSort: SortState<MarketSelectionListSortProperty> | undefined) => void;
  /** Called when the + Add Market button is clicked. This button will be shown when there's no markets selected if this callback is provided. */
  onAddMarketClick?: () => void;
  /** If passed, each selection item will have an "Only" button shown on hover. This callback will be called when that is clicked. */
  onOnlyClicked?: (onlySelection: string) => void;
}

export const MarketSelectionsList = ({
  selections,
  onDelete,
  onDeleteAll,
  h,
  getWarning,
  getItemSuffix,
  getHeaderSuffix,
  itemHeight,
  sort,
  onSortChange,
  onAddMarketClick,
  onOnlyClicked,
}: MarketSelectionsListProps) => {
  const headerSuffix = useMemo(() => getHeaderSuffix?.(), [getHeaderSuffix]);

  if (!selections || selections.length === 0) {
    return (
      <VStack
        w="100%"
        h={h}
        p="spacingComfortable"
        borderRadius="borderRadiusMedium"
        borderWidth="2px"
        borderStyle="solid"
        borderColor="backgroundCard"
        data-testid="no-market-selections"
        gap="spacingDefault"
      >
        <Text fontSize="fontSizeSm">No selected markets</Text>
        {onAddMarketClick && (
          <Button startIcon={IconName.Plus} size={FormControlSizes.Small} dim onClick={onAddMarketClick}>
            Add Market
          </Button>
        )}
      </VStack>
    );
  }

  return (
    <VStack w="100%" fontSize="fontSizeSmall" gap="spacingSmall" data-testid="market-selections">
      <HStack w="100%" justifyContent="space-between" data-testid="market-selections-header">
        <SortableBox
          sort={sort}
          sortKey="label"
          onChange={onSortChange}
          gap="spacingTiny"
          data-testid="selected-markets-header-sortable"
        >
          <Text>Selected Markets</Text>
        </SortableBox>

        <HStack gap="spacingTiny">
          <SortableBox
            sort={sort}
            sortKey="suffix"
            onChange={onSortChange}
            arrowPlacement="left"
            gap="spacingTiny"
            data-testid="suffix-header-sortable"
          >
            {headerSuffix}
          </SortableBox>

          {onDeleteAll && (
            <IconButton
              ghost
              size={FormControlSizes.Tiny}
              icon={IconName.Close}
              onClick={() => onDeleteAll()}
              data-testid="market-selection-item-delete-all"
              color="colorTextSubtle"
            />
          )}
        </HStack>
      </HStack>

      <Flex w="100%" gap="spacingSmall" flexDirection="column" maxHeight={h} overflow={h ? 'auto' : undefined}>
        {selections.map(selection => (
          <SelectionItem
            key={selection}
            selection={selection}
            onDelete={onDelete}
            getWarning={getWarning}
            getItemSuffix={getItemSuffix}
            h={itemHeight}
            onOnlyClicked={selections.length > 1 ? onOnlyClicked : undefined}
          />
        ))}
      </Flex>

      {onAddMarketClick && selections.length > 0 && (
        <HStack
          gap="spacingTiny"
          color="colorTextSubtle"
          data-testid="market-selector-add-market-button"
          fontSize="fontSizeSm"
          alignSelf="flex-start"
          onClick={onAddMarketClick}
          cursor="pointer"
        >
          <Text fontWeight={400}>Add Market</Text>
          <Icon icon={IconName.Plus} />
        </HStack>
      )}
    </VStack>
  );
};

type SelectionItemProps = Omit<MarketSelectionsListProps, 'selections' | 'getHeaderSuffix' | 'itemHeight' | 'sort'> & {
  selection: string;
};

const SelectionItem = ({ selection, onDelete, onOnlyClicked, getWarning, getItemSuffix, h }: SelectionItemProps) => {
  const { marketsByName } = useMarketsContext();
  const { marketAccountsByName } = useMarketAccountsContext();

  // selection is either a market name or market account name. Resolve to market and market account.
  const { market, marketAccount } = useMemo(() => {
    const mktAcc = marketAccountsByName.get(selection);
    if (mktAcc) {
      const mkt = marketsByName.get(mktAcc.Market);
      return { market: mkt, marketAccount: mktAcc };
    }

    // else selection is a market name (or something is wrong ofc)
    const mkt = marketsByName.get(selection);
    return { market: mkt, marketAccount: undefined };
  }, [selection, marketsByName, marketAccountsByName]);

  const warning = useMemo(() => getWarning?.(selection), [getWarning, selection]);

  if (!market) {
    return null;
  }

  const label = marketAccount?.DisplayName ?? marketAccount?.Name ?? market.DisplayName ?? market.Name;

  return (
    <Row w="100%" h={h} data-testid="market-selection-item" data-market={market.Name}>
      <HStack w="100%" justifyContent="space-between">
        {/* Left bit */}
        <HStack gap="spacingSmall" color="colorTextImportant">
          {/* Pointer-events none because the text cursor is ugly and feels bad */}
          <Text style={{ cursor: 'default' }} py="spacingTiny">
            {label}
          </Text>

          {warning && (
            <Tooltip tooltipTestID="selection-item-warning-tooltip-content" tooltip={warning}>
              <Icon data-testid="selection-item-warning-icon" icon={IconName.DotSolid} color="yellow.lighten" />
            </Tooltip>
          )}

          {onOnlyClicked && (
            <OnlyButton
              startIcon={IconName.EyeShow}
              dim
              size={FormControlSizes.Xxs}
              onClick={() => onOnlyClicked(selection)}
              data-testid="market-selection-item-only"
            >
              Only
            </OnlyButton>
          )}
        </HStack>

        {/* Right bit */}
        <HStack data-testid="market-selection-item-suffix">
          {getItemSuffix?.(selection)}
          {onDelete && (
            <IconButton
              ghost
              size={FormControlSizes.Tiny}
              icon={IconName.Close}
              onClick={() => onDelete(selection)}
              data-testid="market-selection-item-delete"
              color="colorTextSubtle"
            />
          )}
        </HStack>
      </HStack>
    </Row>
  );
};

// These styles make it so the OnlyButton only shows when the row its in is hovered
const OnlyButton = styled(Button)``;
const Row = styled(Box)`
  ${OnlyButton} {
    display: none;
  }

  &:hover {
    ${OnlyButton} {
      display: inline-flex;
    }
  }
`;

import { createContext, useContext } from 'react';

export type DefaultMarketAccounts = Record<string, string>;

export const DefaultMarketAccountsConfigState = {
  defaultMarketAccounts: {} as DefaultMarketAccounts,
};

export const DefaultMarketAccountsMethods = {
  setDefaultMarketAccount: (market: string, marketAccount: string) => {},
};

export const DefaultMarketAccountsContextValue = {
  ...DefaultMarketAccountsConfigState,
  ...DefaultMarketAccountsMethods,
};

export type DefaultMarketAccountsConfig = typeof DefaultMarketAccountsConfigState;
export type DefaultMarketAccountsContextProps = typeof DefaultMarketAccountsContextValue;

export const DefaultMarketAccountsConfigValue: DefaultMarketAccountsContextProps = {
  defaultMarketAccounts: {},
  setDefaultMarketAccount: () => {},
};

export const DefaultMarketAccountsContext = createContext<DefaultMarketAccountsContextProps>(
  DefaultMarketAccountsContextValue
);
DefaultMarketAccountsContext.displayName = 'DefaultMarketAccountsContext';

export function useDefaultMarketAccounts() {
  const context = useContext(DefaultMarketAccountsContext);
  if (context === undefined) {
    throw new Error('Missing DefaultMarketAccountsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

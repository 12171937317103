import {
  BALANCE_DELTA,
  EMPTY_OBJECT,
  TreasuryLinkTypeEnum,
  removeEmptyFilters,
  useConstant,
  useMarketAccountsContext,
  useMarketsContext,
  useObservable,
  useSubscription,
  type Market,
  type MarketAccount,
} from '@talos/kyoko';
import { isEqual } from 'lodash-es';
import { useEffect, useState } from 'react';
import { combineLatestWith, map, pipe, type Observable } from 'rxjs';
import { usePortfolioAccounts } from '../../../providers';
import { useDisplaySettings } from '../../../providers/DisplaySettingsProvider';
import type { Balance, BalancesFilter, BalancesRequest } from '../../../types/Balance';
import { enrichBalances, ignoreSpecificBalances } from './pipes';

interface UseEnrichedDeltaBalancesObsParams {
  /** A suffix to add to the end of the request Tag field */
  requestTagSuffix?: string;
  /** The throttle to apply to the subscription. In the form of "1s" for example. Defaults to "1s" */
  throttle?: string;
  /** Whether or not to show zero balances. Sent on the request. */
  showZeroBalances?: boolean;
  /** The Balance filter to apply on the subscription */
  filter?: BalancesFilter;
}

export const useEnrichedDeltaBalancesObs = ({
  requestTagSuffix,
  throttle = '1s',
  showZeroBalances = false,
  filter = EMPTY_OBJECT,
}: UseEnrichedDeltaBalancesObsParams) => {
  const { homeCurrency } = useDisplaySettings();

  const [wsRequest, setWsRequest] = useState<BalancesRequest | null>(null);
  const { data: subscription } = useSubscription<Balance>(wsRequest, { replay: false });

  useEffect(() => {
    setWsRequest(currentRequest => {
      const maybeNewRequest = {
        name: BALANCE_DELTA,
        tag: BALANCE_DELTA + requestTagSuffix,
        Throttle: throttle,
        EquivalentCurrency: homeCurrency,
        ShowZeroBalances: showZeroBalances,
        ...removeEmptyFilters(filter),
      };

      if (!isEqual(currentRequest, maybeNewRequest)) {
        return maybeNewRequest;
      }

      return currentRequest;
    });
  }, [homeCurrency, showZeroBalances, requestTagSuffix, filter, throttle]);

  const pipe = useEnrichDeltaBalancesPipe();

  const enrichedDeltaBalancesObs = useObservable(() => subscription.pipe(pipe), [subscription, pipe]);

  return { enrichedDeltaBalancesObs, showZeroBalances };
};

const RELEVANT_LINK_TYPES: TreasuryLinkTypeEnum[] = [
  TreasuryLinkTypeEnum.ExchangeRebalance,
  TreasuryLinkTypeEnum.OTCSettlement,
];
export const useEnrichDeltaBalancesPipe = () => {
  const { treasuryLinksByDestinationMarketAccountObs } = usePortfolioAccounts();

  const destinationMarketAccountsObs = useObservable(
    () =>
      treasuryLinksByDestinationMarketAccountObs.pipe(
        map(linkMap => {
          // We're only interested in the names of market accounts attached to treasury links of specific types (see above),
          // so grab those here. Reason for the complexity is that one acc can have several links associated
          const filteredDestinationMktAccNames = [...linkMap.entries()]
            .filter(([_, linksForAccount]) => linksForAccount.some(link => RELEVANT_LINK_TYPES.includes(link.Type)))
            .map(([name]) => name);

          return new Set(filteredDestinationMktAccNames);
        })
      ),
    [treasuryLinksByDestinationMarketAccountObs]
  );

  const { marketAccountsByID } = useMarketAccountsContext();
  const { marketsByName } = useMarketsContext();

  return useConstant(enrichDeltaBalancesPipe(destinationMarketAccountsObs, marketAccountsByID, marketsByName));
};

export function enrichDeltaBalancesPipe(
  destinationMarketAccountsObs: Observable<Set<string>>,
  marketAccountsByID: Map<number, MarketAccount>,
  marketsByName: Map<string, Market>
) {
  return pipe(
    combineLatestWith(destinationMarketAccountsObs),
    ignoreSpecificBalances(marketAccountsByID),
    enrichBalances(marketAccountsByID, marketsByName)
  );
}

import { useMemo } from 'react';
import type { UseWsBlotterTableProps, WebsocketRequest } from '../../BlotterTable';
import type { BaseEntityAdminProps, EntityAdminRecord } from '../types';
import { EntityAdmin } from './EntityAdmin';

export interface EntityAdminWSProps<
  TRecord extends EntityAdminRecord,
  TDrawerRecord extends EntityAdminRecord = TRecord
> extends BaseEntityAdminProps<TRecord, TDrawerRecord> {
  /** The initial request for the Websocket API endpoint. */
  wsRequest: UseWsBlotterTableProps<WebsocketRequest, TRecord>['initialRequest'];
}

/**
 * EntityAdminWS lets you display and interact with entities from a Websocket request.
 * The header, tabs, filters, and the blotter are all included and can be customized.
 * @param wsRequest WebsocketRequest
 */
export const EntityAdminWS = <TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord = TRecord>(
  props: EntityAdminWSProps<TRecord, TDrawerRecord>
) => {
  return useMemo(() => <EntityAdmin<TRecord, TDrawerRecord> {...props} />, [props]);
};

import type { Placement } from '@popperjs/core';
import type { Column, FormControlSizes, Market, MarketAccount } from '@talos/kyoko';
import type { ReactNode } from 'react';
import type { MarketSelectorRow } from './blotter/types';

/**
 * The MarketSelectorPreset interface defines what items a preset is related to. Selecting the preset will select any
 * items found in the itemIDs array.
 */
export interface MarketSelectorPreset {
  id: string;
  label: string;
  itemIDs: string[];
}

/**
 * An interface defining how you can add custom items into the Presets list. These are different than the default presets.
 * Each item can define its own onClick ("action") callback, defining what selecting that item means in terms of state change
 * to the market selector.
 */
export interface MarketSelectorCustomPresetItem {
  id: string;
  label: string;
  /** Called when the item is clicked (selected). This is your cue to mutate the market selector state using the API. */
  onClick: (api: MarketSelectorAPI) => void;
  /**
   * Helps the selector determine if the custom preset item is selected. Can be one of a proper callback function where you evaluate
   * if the item should be selected given the state of the market selector, or define that this item is a "fallback" and will be selected
   * in the case that nothing else is selected. There should only be one fallback custom item.
   */
  isSelected: 'fallback' | ((api: MarketSelectorAPI) => boolean);
}

export type MarketSelectorValue =
  | {
      type: 'preset';
      presetID: string;
      selections: string[];
    }
  | {
      type: 'custom';
      selections: string[];
    };

/**
 * A MarketSelectorItem represents either a Market or a MarketAccount to be used in the Market Selector.
 *
 * If Market account is specified, market will be specified as well (resolved from the market account). In this case,
 * the MarketSelectorItem represents the specified market account.
 *
 * If only market is specified, then the MarketSelectorItem represents that market. In other words, the market account property
 * has presedence.
 */
export interface MarketSelectorItem {
  /** The .Name property of the represented market account, or if that is not specified, the represented market. */
  name: string;
  /** The .DisplayName property of the represented market account, or if that is not specified, the represented market. */
  displayName: string;
  /** Denotes whether or not this MarketSelectorItem represents a market or a market account */
  type: 'Market' | 'MarketAccount';
  /** The market behind the item. Always defined. */
  market: Market;
  /** The MarketAccount of the item. Not always defined. */
  marketAccount?: MarketAccount;
  /** Defines the availability of the item based on use-case specific availability conditions which have been applied. */
  availability: AvailabilityResult;
}

export interface AvailabilityCondition {
  id: string;
  condition: (market: Market, marketAccount?: MarketAccount) => AvailabilityResult;
  /**
   * Declare that your condition is ready or not. This is necessary for async conditions where we need to wait a bit before
   * being confident that the condition is able to be applied correctly.
   */
  ready: boolean;
}

// A lower number means "less available" to make comparison... intuitive? Small available = bad, big available = good.
export enum AvailabilityEnum {
  Excluded = 1,
  Disabled = 2,
  Warning = 3,
  Ok = 4,
}

/**
 * The AvailabilityResult type is attached to all MarketSelectorItems and describes the result of the AvailabilityConditions
 * which have been applied to the items.
 */
export type AvailabilityResult = {
  availability: AvailabilityEnum;
  /** Content to render in a tooltip to display information explaining the availability status conveyed to the user */
  infoNode?: ReactNode;
};

/** The MarketSelectorEnrichmentSpec specifies how to apply arbitrary enrichments to the Market Selector */
export type MarketSelectorEnrichmentSpec<T> = {
  /** A unique ID for this enrichment spec */
  id: string;
  /** An array of Talos Columns which will be used in the Market Selector Panel to display this enrichment data */
  columns: Column[];
  /** Specify which columns (by ID) should be visible when the Market Selector is in "simple" mode */
  simpleModeVisibleColIDs?: string[];
  /**
   * Specify what columns (by ID) should be made visible by default when the Market Selector is in "advanced" mode.
   * Columns not specified in this array can still be made visible by the user by using the basic AgGrid column header menu.
   */
  advancedModeVisibleColIDs: string[];
  /** The key of "where" to place this enrichment within the MarketSelectorItem.enrichments object. */
  enrichmentKey: string;

  selectionList?: {
    /** Some selection list item enrichments call for taller items so that our suffixes fit well. This property lets the us specify that. */
    selectionListItemHeight?: 'default' | 'taller'; // stupid simple api to start with, will change as needed
    /** A rendering function to provide if you want this enrichment to be shown in the Selection List (outside of the Panel) */
    selectionListItemSuffix?: (selection: string, allSelections: string[]) => ReactNode;
    /** A rendering function to provide if you want this enrichment to show something in the header of the selection list (like an aggregate value) */
    selectionListHeaderSuffix?: (allSelections: string[]) => ReactNode;
    /** A comparator to be used when sorting on the enrichment in the selectionList. */
    comparator?: (a: MarketSelectorItem | undefined, b: MarketSelectorItem | undefined, direction: '+' | '-') => number;
  };

  /** A rendering function to use if you want to display some summary or highlight related to this enrichment of the rows selected */
  selectionSummary?: (selectedRows: MarketSelectorRow[]) => ReactNode;

  /** A map of Market/Account -> enrichment entity T to be used to enrich market account items. Future: accept dataObservable too. */
  dataMap: Map<string, T> | undefined;
};

export type MarketSelectorProps = {
  marketSelectorAPI: MarketSelectorAPI;
  size?: FormControlSizes;
  disabled?: boolean;
  /* Flip this boolean to true to force the panel to open */
  forceOpen?: boolean;

  panelOffset?: UseMarketSelectorPanelParams['panelOffset'];
  panelPlacement?: UseMarketSelectorPanelParams['panelPlacement'];
  panelAnchorRef?: UseMarketSelectorPanelParams['panelAnchorRef'];
};

export type UseMarketSelectorPanelParams = {
  /** The panel offset, if any, to the reference element */
  panelOffset: number;
  /** The preferred placement of the Panel */
  panelPlacement: Placement;
  /** A reference to the element the panel will be positioned relative to. */
  panelAnchorRef: React.RefObject<HTMLElement>;

  isOpen: boolean;
  close: () => void;
};

export interface MarketSelectorAPI {
  /**
   * Whether or not the MarketSelectorAPI is instantiated properly and ready to be rendered to the user.
   * This will be false while waiting for async reference data to load which needs to be in place in order to perform availability checks.
   */
  ready: boolean;
  presetsReady: boolean;

  preset?: string;
  presets?: MarketSelectorPreset[];
  presetsByID?: Map<string, MarketSelectorPreset>;
  /**
   * Given a presetID, returns all relevant items within that preset. A relevant item is defined as one
   * which is referenced by its key in the preset definition, and is present in the `items` array provided.
   *
   * Returns an array of keys, or undefined if the preset cannot be resolved.
   */
  getItemsInPreset: (presetID: string) => MarketSelectorItem[];

  /** The EnrichmentSpecs passed into the MarketSelector. If none were passed, this will be an empty array. */
  enrichments: MarketSelectorEnrichmentSpec<unknown>[];

  selections: string[];
  selectionsSet: Set<string>;
  items: MarketSelectorItem[];
  itemsMap: Map<string, MarketSelectorItem>;
  itemsByMarket: Map<string, MarketSelectorItem[]>;

  /** Select one item. Will unselect any existing selection with the market of this new selection. */
  select: (item: MarketSelectorItem | string) => void;
  /** Unselect one item. */
  unselect: (item: MarketSelectorItem | string) => void;
  /** Select many items. Will unselect any existing selections within the new selection's markets. */
  selectMany: (items: (MarketSelectorItem | string)[]) => void;
  /** Unselect many items. */
  unselectMany: (items: (MarketSelectorItem | string)[]) => void;

  /** Unselects all other items other than this one */
  selectOnly: (item: MarketSelectorItem | string) => void;

  /**
   * Select all items. Provide a subset to apply select-all logic to some subset of items. Will leave items outside of this subset unaltered.
   * For any of the new selections, will unselect any existing selections within the new selections' markets.
   */
  selectAll: (subset?: MarketSelectorItem[]) => void;
  /* Unselect all items. Provide a subset to apply the unselect-all logic to some subset of items. Will leave items outside of this subset unaltered. */
  unselectAll: (subset?: MarketSelectorItem[]) => void;
  /** Set the selection state of some item. Just conditionally calls `select` or `unselect`, so refer to those functions for docs. */
  setSelected: (item: MarketSelectorItem | string, selected: boolean) => void;

  /**
   * Select a Market. This is used when you aren't selected an item itself, but just want to select a group of items (a market).,
   * This will select the first item within the market group.
   */
  selectMarket: (marketName: string, selected: boolean) => void;

  /** Select a preset by id. Calls `getItemsInPreset` under the hood, and then only selects the selectable items out of that returned array */
  selectPreset: (preset: string) => void;

  /** The custom preset items showed in the presets list */
  customPresetItems: MarketSelectorCustomPresetItem[];
}

import type { ToastAppInboxProps, ToastPosition, ToastProps, ToastPropsBase, ToastsProps } from '@talos/kyoko';
import {
  GlobalToastManager,
  GlobalToastStoreProvider,
  PRINCIPAL_MAIN_HEADER_HEIGHT,
  Toasts,
  useGlobalToastsSelector,
  Z_INDEX,
} from '@talos/kyoko';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import styled from 'styled-components';
import { ADMIN_HEADER_HEIGHT } from 'styles/dimensions';
import { useAdminHeaderContext } from '../../providers/AdminHeaderContext';
import { appNotificationsSlice } from '../../providers/AppNotifications/redux/AppNotificationsSlice';

const globalToastManager = new GlobalToastManager('ui');
const appInboxToastManager = new GlobalToastManager('appInbox');

export const GlobalToasts = () => {
  return (
    <GlobalToastStoreProvider>
      <GlobalToastsInner />
    </GlobalToastStoreProvider>
  );
};

export const GlobalToastsInner = () => {
  const uiToasts = useGlobalToastsSelector(state => state.toasts.uiToasts);
  const appInboxToasts = useGlobalToastsSelector(state => state.toasts.appInboxToasts);
  const appStateDispatch = useAppStateDispatch();
  const { isAdminHeaderDismissed } = useAdminHeaderContext();
  const processGroups: Array<{
    type: 'ui' | 'appInbox';
    toasts: ToastPropsBase[];
    position: ToastPosition;
    width: string;
    remove: ToastsProps<ToastAppInboxProps>['remove'];
  }> = [
    {
      type: 'ui',
      toasts: uiToasts,
      position: 'top-center',
      remove: id => globalToastManager.removeToast(id),
      width: '20rem',
    },
    {
      type: 'appInbox',
      toasts: appInboxToasts,
      position: 'top-right',
      width: '300px',
      remove: notificationId => {
        appInboxToastManager.removeToast(notificationId);
        appStateDispatch(
          appNotificationsSlice.actions.progressNotificationState({
            NotificationID: notificationId,
            state: 'inbox',
          })
        );
      },
    },
  ];

  return (
    <>
      {processGroups.map(({ remove, toasts, position, width }) => (
        <GlobalToastsWrapper
          data-testid="global-toasts-wrapper"
          key={position}
          position={position}
          isAdminToolbarVisible={!isAdminHeaderDismissed}
        >
          <Toasts
            stackingLimit={5}
            remove={remove}
            toasts={toasts as (ToastProps | ToastAppInboxProps)[]}
            direction="column-reverse"
            width={width}
            container="global"
          />
        </GlobalToastsWrapper>
      ))}
    </>
  );
};

const GlobalToastsWrapper = styled.div<{ position: ToastPosition; isAdminToolbarVisible: boolean }>`
  position: fixed;
  z-index: ${Z_INDEX.globalToast};
  ${({ theme, position, isAdminToolbarVisible }) => {
    const offset = 8;
    const headerHeight =
      (isAdminToolbarVisible ? ADMIN_HEADER_HEIGHT + PRINCIPAL_MAIN_HEADER_HEIGHT : PRINCIPAL_MAIN_HEADER_HEIGHT) +
      offset +
      10;
    switch (position) {
      case 'top-right':
        return `
          top: ${headerHeight - offset}px;
          right: ${offset}px;
          transform: none;
        `;
      case 'top-left':
        return `
          top: ${headerHeight - offset}px;
          left: ${offset}px;
          transform: none;
        `;
      case 'bottom-center':
        return `
          bottom: ${offset}px;
          left: 50%;
          transform: translateX(-50%);
        `;
      case 'bottom-right':
        return `
          bottom: ${offset}px;
          right: ${offset}px;
          transform: none;
        `;
      case 'bottom-left':
        return `
          bottom: ${offset}px;
          left: ${offset}px;
          transform: none;
        `;
      case 'top-center':
      default:
        return `
          top: ${headerHeight - offset}px;
          left: 50%;
          transform: translateX(-50%);
        `;
    }
  }}
`;

import styled, { css } from 'styled-components';

import { Flex } from '../../Core';
import { FormControlSizes } from '../../Form/types';
import { getFontSize } from '../../Form/utils';
import { ButtonWrapper, getButtonSpacing } from '../styles';
import { clippedButtonCss, getButtonBorderRadius } from '../utils';

export const ButtonGroup = styled(Flex)<{
  size?: FormControlSizes;
  orientation?: 'vertical' | 'horizontal';
  rounded?: boolean;
  shareBackground?: boolean;
  prominent?: boolean;
  pill?: boolean;
}>`
  flex-direction: ${({ orientation }) => (orientation === 'vertical' ? 'column' : 'row')};
  ${({ shareBackground, size, rounded, theme, background }) =>
    shareBackground &&
    css`
      background: ${background ?? theme.buttonGroupSharedBackground};
      --border-group-padding: ${(theme.baseSize / 16) * (size ?? 1)}px;
      padding: var(--border-group-padding);

      ${rounded &&
      css`
        border-radius: calc(${getButtonBorderRadius(theme, size)}px + var(--border-group-padding));
      `}
    `}

  ${({ prominent, theme, size = FormControlSizes.Default }) =>
    prominent && theme.chamferMultiplier > 0 && clippedButtonCss({ size, theme, customOffset: size * 3 })}

  ${ButtonWrapper} {
    --button-border-radius: ${({ theme, pill, size }) => (pill ? 10000 : getButtonBorderRadius(theme, size))}px;
    flex: 1 1 0;
    border-radius: 0;
    padding: ${({ size, theme }) => getButtonSpacing(theme, size)};
    font-size: ${({ size, theme }) => getFontSize(theme, size)}rem;

    & + ${ButtonWrapper} {
      gap: 1px;
    }

    ${({ rounded, orientation, size, theme, pill }) =>
      rounded
        ? css`
            border-radius: var(--button-border-radius);
          `
        : css`
            &:first-child {
              ${orientation === 'vertical'
                ? css`
                    border-radius: var(--button-border-radius) var(--button-border-radius) 0 0;
                  `
                : css`
                    border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
                  `};
            }

            &:last-child {
              ${orientation === 'vertical'
                ? css`
                    border-radius: 0 0 var(--button-border-radius) var(--button-border-radius);
                  `
                : css`
                    border-radius: 0 var(--button-border-radius) var(--button-border-radius) 0;
                  `};
            }

            &:first-child:last-child {
              border-radius: var(--button-border-radius);
            }
          `}
  }
`;
ButtonGroup.defaultProps = {
  gap: '1px',
};

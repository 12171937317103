import {
  getReconStatusWarningSeverity,
  MarginReportingTypeEnum,
  type MarketTypeEnum,
  type PositionReconStatus,
  type PositionWarning,
  type WarningSeverity,
  type WebsocketRequest,
} from '@talos/kyoko';

export interface BalancesRequest extends WebsocketRequest, BalancesFilter {
  EquivalentCurrency?: string;
  ShowZeroBalances: boolean;
  Throttle?: string;
}

export interface BalancesFilter {
  Currencies?: string[];
  Markets?: string[];
  MarketTypes?: string[];
  MarketAccounts?: string[];
  Groups?: string[];
}

export interface IBalance {
  LastUpdateTime: string;
  Status: string;
  Account: string;
  MarketAccountID: number;
  Currency: string;
  Amount: string;
  AvailableAmount: string;
  Market: string;
  OutstandingBuy: string;
  OutstandingSell: string;
  YieldingAmount?: string;
  ReconStatus?: PositionReconStatus;
  Equity?: string;
  InitialMargin?: string;
  MaintenanceMargin?: string;
  Equivalent?: {
    Currency: string;
    Amount: string;
    AvailableAmount: string;
    YieldingAmount?: string;
    OutstandingBuy: string;
    OutstandingSell: string;
    Equity?: string;
    InitialMargin?: string;
    MaintenanceMargin?: string;
  };
}

export class Balance {
  LastUpdateTime!: string;
  Status!: string;
  Account!: string;
  MarketAccountID!: number;
  Currency!: string;
  Amount!: string;
  AvailableAmount!: string;
  AvailableMargin?: string;
  Market!: string;
  OutstandingBuy!: string;
  OutstandingSell!: string;
  ReconStatus?: PositionReconStatus;
  MarginReportingType!: MarginReportingTypeEnum;
  YieldingAmount?: string;
  Equivalent?: {
    Amount: string;
    AvailableAmount: string;
    OutstandingBuy: string;
    OutstandingSell: string;
    ConversionRate?: string;
    YieldingAmount?: string;
    Currency: string;
    Equity?: string;
    InitialMargin?: string;
    MaintenanceMargin?: string;
  };

  Equity?: string;
  InitialMargin?: string;
  MaintenanceMargin?: string;

  marketAccountGroup?: string;
  marketAccountName?: string;
  marketType?: MarketTypeEnum;

  public enrichBalance({
    marketAccountGroup,
    marketAccountName,
    marketType,
  }: {
    marketAccountGroup?: string;
    marketAccountName?: string;
    marketType?: MarketTypeEnum;
  }) {
    this.marketAccountGroup = marketAccountGroup;
    this.marketAccountName = marketAccountName;
    this.marketType = marketType;
  }

  get reconWarning(): PositionWarning | undefined {
    if (this.ReconStatus == null) {
      return undefined;
    }

    const severity = getReconStatusWarningSeverity(this.ReconStatus?.Status);

    if (severity == null) {
      return undefined;
    }

    return {
      severity,
      asset: this.Currency,
      reconStatus: this.ReconStatus,
    };
  }

  get warningSeverity(): WarningSeverity | undefined {
    return this.reconWarning?.severity;
  }

  get rowID() {
    return getBalanceKey(this);
  }

  constructor(data: Balance | IBalance) {
    Object.assign(this, data);

    if (!this.MarginReportingType) {
      // Backend doesnt always send a MarginReportingType. If its not sent, then we should regard it as a Currency ReportingType.
      // Can be removed in a few versions (introducing in 2.48.9)
      this.MarginReportingType = MarginReportingTypeEnum.Currency;
    }
  }
}

export function getBalanceKey(balance: Balance) {
  return `${balance.Currency}-${balance.MarketAccountID}-${balance.MarginReportingType}`;
}

import type { FilterableProperty } from '@talos/kyoko';
import { IconName, SideEnum } from '@talos/kyoko';
import { values } from 'lodash-es';
import { useMemo } from 'react';

/**
 * This hook creates the filter property definition for Side properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for Side filters
 */
export function useSidesFilter<TKey extends string = 'Sides', P = FilterableProperty<TKey>>(
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
) {
  return useMemo(
    () => ({
      ...({
        key: 'Sides' as TKey,
        label: 'Side',
        icon: IconName.ArrowUpDown,
        options: values(SideEnum),
        getOptionLabel: (option: string) => option,
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [overrides]
  );
}

import { IconName } from '@talos/kyoko';
import type { SidebarSubNavItem, SidebarSubTitle } from '@talos/kyoko/src/routed';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useMemo } from 'react';
import { Blotters } from './Blotters';

export function getMonitoringSubNavItems({
  enableMonitoringBlotters,
}: Pick<ReturnType<typeof useFeatureFlag>, 'enableMonitoringBlotters'>): (
  | SidebarSubNavItem<'/monitoring'>
  | SidebarSubTitle
)[] {
  if (enableMonitoringBlotters) {
    return MONITORING_SUB_NAV_ITEMS;
  }
  return [];
}
const MONITORING_SUB_NAV_ITEMS: (SidebarSubNavItem<'/monitoring'> | SidebarSubTitle)[] = [
  {
    label: 'Blotters',
    path: '/monitoring/blotters',
    component: <Blotters />,
    icon: IconName.DeviceDesktop,
  },
];

export const useMonitoringSubNavItems = () => {
  const { enableMonitoringBlotters } = useFeatureFlag();
  return useMemo(() => getMonitoringSubNavItems({ enableMonitoringBlotters }), [enableMonitoringBlotters]);
};

import {
  FilterClauseType,
  getTypedKeys,
  type FilterableProperty,
  type FilterClause,
  type UseFilterBuilderProps,
} from '@talos/kyoko';
import { compact, isArray } from 'lodash-es';
import { createContext, useCallback, useContext, useMemo, type ReactNode } from 'react';
import type { PositionsTableFilter } from '../../../Blotters/PositionsV3/types';
import { useOperationsOverviewConfig } from './OperationsOverviewConfigProvider';

export const OperationsOverviewFiltersContext = createContext<OperationsOverviewFiltersContextProps | undefined>(
  undefined
);

export type OperationsOverviewFiltersContextProps = {
  filterableProperties: FilterableProperty[];
  onFilterClausesChanged: UseFilterBuilderProps['onFilterClausesChanged'];
  initialFilterClauses: UseFilterBuilderProps['initialFilterClauses'];
};

export function useOperationsOverviewFilters() {
  const context = useContext(OperationsOverviewFiltersContext);
  if (context === undefined) {
    throw new Error(
      'MissingOperationsOverviewFiltersContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

/**
 * This provider allows anyone in the page to have access to the filterable properties for example
 */
export const OperationsOverviewFiltersProvider = function OperationsOverviewFiltersProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { opsOverviewFilter: filter, updateOpsOverviewFilter } = useOperationsOverviewConfig();

  // We currently dont do any filtering thru filterable properties,
  // //but I would like to keep the infrastructure for adding it back in later
  const filterableProperties = useMemo(() => compact<FilterableProperty<keyof PositionsTableFilter>>([]), []);

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      getTypedKeys(filter).forEach(key => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: (isArray(filter[key]) ? filter[key] : compact([filter[key]])) as string[],
        });
      });
    }
    return clauses;
  }, [filter]);

  const onFilterClausesChanged: UseFilterBuilderProps['onFilterClausesChanged'] = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>) => {
      const newFilter: PositionsTableFilter = {};
      updateOpsOverviewFilter(newFilter);
    },
    [updateOpsOverviewFilter]
  );

  const filterBuilderProps = useMemo(() => {
    return {
      properties: filterableProperties,
      initialFilterClauses,
      onFilterClausesChanged,
    };
  }, [filterableProperties, initialFilterClauses, onFilterClausesChanged]);

  const value = useMemo(() => {
    return {
      filterableProperties: filterBuilderProps.properties,
      initialFilterClauses: filterBuilderProps.initialFilterClauses,
      onFilterClausesChanged: filterBuilderProps.onFilterClausesChanged,
    };
  }, [filterBuilderProps]);

  return (
    <OperationsOverviewFiltersContext.Provider value={value}>{children}</OperationsOverviewFiltersContext.Provider>
  );
};

import { ThemeTypes } from '@talos/kyoko';
import { useCallback } from 'react';
import { createGlobalState } from 'react-use';
import { THEME_TYPE } from '../tokens/appconfig';

export const useThemeType = createGlobalState<ThemeTypes>();

/** Get the user's theme type preference from localStorage */
export const useThemeTypePreference = () => {
  const [themeTypeState, setThemeTypeState] = useThemeType();

  const setThemeType = useCallback(
    (themeType: ThemeTypes) => {
      localStorage.setItem(THEME_TYPE, themeType);
      setThemeTypeState(themeType);
    },
    [setThemeTypeState]
  );

  let themeType = themeTypeState;
  if (!themeType) {
    switch (localStorage.getItem(THEME_TYPE)) {
      case 'light':
        themeType = ThemeTypes.light;
        break;
      case 'auto':
        themeType = ThemeTypes.auto;
        break;
      case 'dark':
      default:
        themeType = ThemeTypes.dark;
        break;
    }
  }

  return {
    themeType,
    setThemeType,
  };
};

import { AggregationType, ModeEnum } from '@talos/kyoko';
import { useMemo } from 'react';
import { useAggregationsQuery } from '../../../hooks/useAggregationRequests';
import type { MarketSelectorPreset } from '../types';

/** This hook collects and returns all Trading Aggregations mapped as Market Selector Presets to be used in the Market Selector */
export const useMarketSelectorAggregationPresets = () => {
  const aggregationsQuery = useAggregationsQuery();
  const userAggregations = useMemo(
    () => aggregationsQuery.data?.filter(agg => agg.AggregationType === AggregationType.User),
    [aggregationsQuery.data]
  );

  const aggregationPresets: MarketSelectorPreset[] | undefined = useMemo(() => {
    if (!userAggregations) {
      return undefined;
    }

    return userAggregations.map(agg => {
      // Only grab the enabled market accounts
      const relevantAggAccounts: string[] = [];
      for (const [_, aggAcc] of agg.Accounts) {
        if (aggAcc.Mode === ModeEnum.Enabled && aggAcc.MarketAccount) {
          relevantAggAccounts.push(aggAcc.MarketAccount);
        }
      }

      return {
        id: agg.Name,
        label: agg.DisplayName ?? agg.Name,
        itemIDs: relevantAggAccounts,
      } satisfies MarketSelectorPreset;
    });
  }, [userAggregations]);

  return { aggregationPresets };
};

import { useSyncedRef } from '@talos/kyoko';
import type { LayoutProps } from 'components/Layout/Layout';
import type { DockviewApi, SerializedDockview } from 'dockview';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type MutableRefObject,
  type PropsWithChildren,
} from 'react';
import type { LayoutSpec } from 'types/LayoutConfig';

type DockViewProviderContextType = {
  dockviewApiRef: MutableRefObject<DockviewApi | undefined>;
  handleReady: LayoutProps['onLayoutReady'];
  restoreLayout: () => void;
  selectedLayout: LayoutSpec;
};

const DockViewProviderContext = createContext<DockViewProviderContextType | undefined>(undefined);

export type DockViewProviderType<TViewType extends string> = {
  view: TViewType;
  tabLabel: string;
  getLayoutForView: (view: TViewType, tabLabel: string, defaultIfUndefined?: boolean) => SerializedDockview;
  getDefaultLayoutForView: (view: TViewType, tabLabel: string) => SerializedDockview;
  updateLayout: (arg: { view: TViewType; userLayout: SerializedDockview }) => void;
};

export function DockViewProvider<TViewType extends string>({
  children,
  view,
  tabLabel,
  getLayoutForView,
  getDefaultLayoutForView,
  updateLayout,
}: PropsWithChildren<DockViewProviderType<TViewType>>) {
  const [api, setApi] = useState<DockviewApi>();
  const isDockViewReadyRef = useRef(false);

  const handleReady = useCallback((api: DockviewApi) => {
    isDockViewReadyRef.current = true;
    setApi(api);
  }, []);

  // DockView's API state changes under the hood,
  // so we need to keep a ref to the current API to avoid useDynamicCallback
  const dockviewApiRef = useSyncedRef(api);

  const selectedLayout: LayoutSpec = useMemo(() => {
    return {
      id: view,
      label: tabLabel,
      collapsablePanels: [],
      constraints: [],
      dockViewLayout: getCurrentLayoutOrDefault<TViewType>(view, tabLabel, getLayoutForView, getDefaultLayoutForView),
    };
  }, [getDefaultLayoutForView, getLayoutForView, tabLabel, view]);

  useEffect(() => {
    if (!api) {
      return;
    }

    const disposables = [
      api.onDidLayoutChange(event => {
        setTimeout(() => {
          updateLayout({
            view,
            userLayout: api.toJSON(),
          });
        });
      }),
    ];

    return () => disposables.forEach(disposable => disposable.dispose());
  }, [api, updateLayout, view]);

  const restoreLayout = useCallback(() => {
    const defaultLayout = getDefaultLayoutForView(view, tabLabel);
    api?.fromJSON(defaultLayout);
    updateLayout({ view, userLayout: defaultLayout });
  }, [api, getDefaultLayoutForView, tabLabel, updateLayout, view]);

  const contextValue = useMemo((): DockViewProviderContextType => {
    return {
      dockviewApiRef,
      handleReady,
      restoreLayout,
      selectedLayout,
    };
  }, [dockviewApiRef, handleReady, restoreLayout, selectedLayout]);

  return <DockViewProviderContext.Provider value={contextValue}>{children}</DockViewProviderContext.Provider>;
}

export const useDockViewProvider = () => {
  const context = useContext(DockViewProviderContext);
  if (!context) {
    throw new Error('useDockViewProvider must be used within a DockViewProvider');
  }
  return context;
};
export default DockViewProvider;
function getCurrentLayoutOrDefault<TViewType extends string>(
  view: TViewType,
  tabLabel: string,
  getLayoutForViewTab: DockViewProviderType<TViewType>['getLayoutForView'],
  getDefaultLayoutForView: DockViewProviderType<TViewType>['getDefaultLayoutForView']
) {
  let currentLayout = getLayoutForViewTab(view, tabLabel);
  if (!currentLayout) {
    currentLayout = getDefaultLayoutForView(view, tabLabel);
  }
  return currentLayout;
}

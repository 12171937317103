import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { map, shareReplay, type Observable } from 'rxjs';
import { AggregationsContext } from '../contexts';
import { useObservable, useObservableValue, useStaticSubscription } from '../hooks';
import { wsSubscriptionCache } from '../pipes';
import { wsScanToMap } from '../pipes/wsScanToMap';
import { AGGREGATION } from '../tokens';
import { AggregationType, type Aggregation } from '../types/Aggregation';
import { EMPTY_ARRAY } from '../utils';

function filterAggregation(aggregation: Aggregation): boolean {
  // Talos way of saying that an aggregation should be hidden
  if (aggregation.DisplayName?.charAt(0) === '_') {
    return false;
  }

  return true;
}

export const AggregationsProvider = function AggregationsProvider({ children }: PropsWithChildren) {
  const { data: subscription } = useStaticSubscription<Aggregation>({
    name: AGGREGATION,
    tag: 'AggregationsProvider',
  });

  const aggregationsObs = useMemo(
    () =>
      subscription.pipe(
        map(json => ({ ...json, data: json.data.filter(filterAggregation) })),
        wsSubscriptionCache(agg => agg.Name)
      ),
    [subscription]
  );

  const aggregationsByNameObs = useObservable(
    () =>
      aggregationsObs.pipe(
        wsScanToMap({ getUniqueKey: d => d.Name, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [aggregationsObs]
  );

  const aggregationsListObs: Observable<Aggregation[]> = useObservable(
    () =>
      aggregationsByNameObs.pipe(
        map(memo => [...memo.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [aggregationsByNameObs]
  );

  const systemAggregationsListObs: Observable<Aggregation[]> = useMemo(
    () =>
      aggregationsByNameObs.pipe(
        map(memo => [...memo.values()].filter(agg => agg.AggregationType === AggregationType.System)),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [aggregationsByNameObs]
  );

  const aggregationsByName = useObservableValue(
    () => aggregationsByNameObs,
    [aggregationsByNameObs],
    new Map<string, Aggregation>()
  );
  const aggregationsList = useObservableValue(() => aggregationsListObs, [aggregationsListObs], [] as Aggregation[]);
  const systemAggregationsList = useObservableValue(
    () => systemAggregationsListObs,
    [systemAggregationsListObs],
    EMPTY_ARRAY as Aggregation[]
  );

  return (
    <AggregationsContext.Provider
      value={{
        aggregationsByNameObs,
        aggregationsListObs,
        aggregationsByName,
        aggregationsList,
        systemAggregationsListObs,
        systemAggregationsList,
      }}
    >
      {children}
    </AggregationsContext.Provider>
  );
};

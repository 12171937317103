import type { Security } from '../types/Security';
import type { IOMSExecutionReport4203LegSummary } from '../types/types';
import { ProductTypeEnum, SyntheticProductTypeEnum } from '../types/types';
import { getCumAmt, getFilledBaseQuantity, getFilledNotional } from './notional';
import { isOptionStrategy } from './security';

export const shouldDisplayReasonabilityCheckForProduct = (security: Security | undefined) => {
  if (!security) {
    return false;
  }
  if ([ProductTypeEnum.Basis, ProductTypeEnum.Option, ProductTypeEnum.CalendarSpread].includes(security.ProductType)) {
    return false;
  }
  if (isOptionStrategy(security)) {
    return false;
  }
  if (
    hasSyntheticProductType(security) &&
    security!.MultilegDetails!.SyntheticProductType === SyntheticProductTypeEnum.Delta1Spread
  ) {
    return false;
  }
  return true;
};

export const hasSyntheticProductType = (security: Security | undefined) => {
  if (!security) {
    return false;
  }
  if (security?.MultilegDetails && security?.MultilegDetails?.SyntheticProductType) {
    return true;
  }
  return false;
};

export function getLegFilledQtyAndNotional(leg: IOMSExecutionReport4203LegSummary, security: Security | undefined) {
  // Currently no countercurrency trades on multileg child orders.
  const legCurrency = security?.PositionCurrency || '';
  const cumAmt = getCumAmt(leg.CumQty || '0', leg.AvgPx || '0', legCurrency, security);
  const filledQty = getFilledBaseQuantity(
    { CumQty: leg.CumQty || '0', CumAmt: cumAmt.value, Currency: legCurrency, AmountCurrency: cumAmt.currency },
    security
  );
  const filledNotional = getFilledNotional(
    { CumQty: leg.CumQty || '0', CumAmt: cumAmt.value, Currency: legCurrency, AmountCurrency: cumAmt.currency },
    security
  );

  return {
    filledQty,
    filledNotional,
  };
}

export const PRODUCT_TYPE_TOOLTIP_MAP = {
  [SyntheticProductTypeEnum.Unknown]: 'unknown',
  [SyntheticProductTypeEnum.Cross]: 'Synthetic Cross',
  [SyntheticProductTypeEnum.Delta1Spread]: 'Delta-1 Spread',
  [SyntheticProductTypeEnum.CallCalendarSpread]: 'Call Calendar',
  [SyntheticProductTypeEnum.PutCalendarSpread]: 'Put Calendar',
  [SyntheticProductTypeEnum.CallSpread]: 'Call Spread',
  [SyntheticProductTypeEnum.PutSpread]: 'Put Spread',
};

import type { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { get } from 'lodash-es';
import styled, { keyframes } from 'styled-components';
import { HedgeControlStatusEnum } from '../../../types';
import { Flex, Grid } from '../../Core';
import { Icon, IconName } from '../../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../../IndicatorDot';
import { iconByHedgePositionStatus, iconColorByHedgePositionStatus } from '../../PositionThresholdMeter';
import { Text } from '../../Text';
import { Tooltip } from '../../Tooltip';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

type AutoHedgeStatusColumnParams = {
  textField?: string;
};

type AutoHedgeStatusColumnValue = undefined | { status: HedgeControlStatusEnum; text?: string };
export const autohedgeStatus: ColDefFactory<Column<AutoHedgeStatusColumnParams>> = column => {
  return {
    width: 100,
    ...baseColumn(column),
    valueGetter: (params: ValueGetterParams<unknown>): AutoHedgeStatusColumnValue => {
      if (!column.field) {
        return;
      }
      const status = get(params.data, column.field);
      if (status == null) {
        return;
      }
      const text = column.params?.textField ? get(params.data, column.params.textField) : undefined;
      return { status, text };
    },
    valueFormatter: (params: ValueFormatterParams<unknown, AutoHedgeStatusColumnValue>): string => {
      return params.value?.status ?? '';
    },
    cellRenderer: (params: ICellRendererParams<unknown, AutoHedgeStatusColumnValue>) => {
      const status = params.value?.status;
      const text = params.value?.text;
      if (status == null) {
        return null;
      }
      return <AutoHedgingStatus status={status} text={text} />;
    },
    comparator: (valueA: AutoHedgeStatusColumnValue, valueB: AutoHedgeStatusColumnValue) => {
      if (valueA && valueB) {
        const sortA = AUTO_HEDGE_STATUS_SORT_ORDER[valueA.status] ?? -1;
        const sortB = AUTO_HEDGE_STATUS_SORT_ORDER[valueB.status] ?? -1;
        return sortA - sortB;
      }
      if (valueA === null && valueB) {
        return -1;
      }
      if (valueA && valueB === null) {
        return 1;
      }

      return 0;
    },
  };
};

export const AutoHedgingStatus = ({ status, text }: { status: HedgeControlStatusEnum; text?: string }) => {
  const statusIcon = (
    <Flex gap="spacingSmall" color="colorTextImportant" alignItems="center">
      {text && (
        <IndicatorDotWrapper show variant={IndicatorDotVariants.Warning}>
          <AutohedgingStatusIcon status={status} />
        </IndicatorDotWrapper>
      )}
      {!text && <AutohedgingStatusIcon status={status} />}
      <Text>{status}</Text>
    </Flex>
  );

  if (text) {
    return (
      <div data-testid="hedge-control-status">
        <Tooltip tooltipTestID="autohedging-status-tooltip-content" tooltip={text} usePortal>
          {statusIcon}
        </Tooltip>
      </div>
    );
  }

  return <div data-testid="hedge-control-status">{statusIcon}</div>;
};

const AutohedgingStatusIcon = ({ status }: { status: HedgeControlStatusEnum }) => {
  if (status === HedgeControlStatusEnum.Hedging) {
    return (
      <Grid gridTemplateColumns="1fr" gridTemplateRows="1fr" placeItems="center">
        <Grid gridRow="-1/1" gridColumn="-1/1">
          <Icon size={7} icon={IconName.Check} color={iconColorByHedgePositionStatus[status]} />
        </Grid>
        <SpinningGrid gridRow="-1/1" gridColumn="-1/1">
          <Icon icon={IconName.CircleDotted} color={iconColorByHedgePositionStatus[status]} />
        </SpinningGrid>
      </Grid>
    );
  }

  return <Icon icon={iconByHedgePositionStatus[status]} color={iconColorByHedgePositionStatus[status]} />;
};

const spin = keyframes`
  0% {
    transform: rotate(0deg);
    opacity: 0.5;
  }
  30% {
    transform: rotate(180deg);
    opacity: 1;
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.5;
  }
  80% {
    transform: rotate(270deg);
    opacity: 1;
  }
  100% {
    transform: rotate(270deg);
    opacity: 0.5;
  }
`;
/**
 * This is a spinning dotted circle that is used to indicate that the hedger is currently hedging.
 */
const SpinningGrid = styled(Grid)`
  animation: ${spin} 5s cubic-bezier(0.25, 0.75, 0.25, 0.75) infinite;
`;

const AUTO_HEDGE_STATUS_SORT_ORDER: Record<HedgeControlStatusEnum, number> = {
  [HedgeControlStatusEnum.Disabling]: 0,
  [HedgeControlStatusEnum.Disabled]: 1,
  [HedgeControlStatusEnum.Rejected]: 2,
  [HedgeControlStatusEnum.Waiting]: 3,
  [HedgeControlStatusEnum.Hedging]: 4,
  [HedgeControlStatusEnum.Error]: 5,
  [HedgeControlStatusEnum.Incomplete]: 6,
  [HedgeControlStatusEnum.Complete]: 7,
  [HedgeControlStatusEnum.Tripped]: 8,
  [HedgeControlStatusEnum.Synchronizing]: 9,
} as const;

import {
  ACTION,
  BlotterDensity,
  Button,
  ButtonVariants,
  DEFAULT_MAX_ROWS,
  DEFAULT_PAGINATION_SIZE,
  EntityAdminREST,
  FormControlSizes,
  IconName,
  useDisclosure,
  useDynamicCallback,
  type AllInTier,
  type CustomerConfiguration,
  type FeeTier,
  type FilterableProperty,
  type SymbolGroup,
} from '@talos/kyoko';
import { useCounterpartyFilter, useModeFilter, usePricingTierFilters, useRoleAuth } from 'hooks';
import { useCustomersByName } from 'hooks/useCustomer';
import { compact } from 'lodash-es';
import { OrgConfigurationKey, useCustomerPricingTierContext, useOrgConfiguration } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NewCustomerDialog } from '../Dialogs/NewCustomerDialog';
import {
  CUSTOMER_CONFIGURATIONS_COLUMNS,
  getCustomerConfigurationsDrawerOptions,
  getCustomerConfigurationsPatchDeletePath,
} from './utils';

export const CustomerConfigurations = () => {
  const { isAuthorized } = useRoleAuth();
  const customersByName = useCustomersByName();
  const [refreshKey, setRefreshKey] = useState(0);
  const { getConfig } = useOrgConfiguration();

  const modeFilter = useModeFilter();
  const counterpartyFilter = useCounterpartyFilter();
  const { allInTierFilter, feeTierFilter, symbolGroupFilter } = usePricingTierFilters();

  const [allInTierOptions, setAllInTierOptions] = useState<AllInTier[]>();
  const [feeTierOptions, setFeeTierOptions] = useState<FeeTier[]>();
  const [symbolGroupOptions, setSymbolGroupOptions] = useState<SymbolGroup[]>();

  const { getAllInTiers, getFeeTiers, getSymbolGroups } = useCustomerPricingTierContext();
  useEffect(() => {
    getAllInTiers().then(({ data }) => setAllInTierOptions(data));
    getFeeTiers().then(({ data }) => setFeeTierOptions(data));
    getSymbolGroups().then(({ data }) => setSymbolGroupOptions(data));
  }, [getAllInTiers, getFeeTiers, getSymbolGroups]);

  const drawerOptions = useMemo(() => {
    if (allInTierOptions != null && feeTierOptions != null && symbolGroupOptions != null) {
      return getCustomerConfigurationsDrawerOptions(allInTierOptions, feeTierOptions, symbolGroupOptions);
    }
  }, [allInTierOptions, feeTierOptions, symbolGroupOptions]);

  const getEditCustomerConfigurationsEditEntityName = useCallback(
    (entity: CustomerConfiguration) => customersByName?.get(entity.Counterparty)?.DisplayName ?? entity.Counterparty,
    [customersByName]
  );

  const filterableProperties: FilterableProperty[] | undefined = useMemo(() => {
    const properties = compact([
      modeFilter,
      { ...counterpartyFilter, label: 'Customer' },
      allInTierFilter,
      feeTierFilter,
      symbolGroupFilter,
    ]);
    // As per the Entity Admin API, undefined is used to indicate a loading state
    return properties.length === 5 ? properties : undefined;
  }, [modeFilter, counterpartyFilter, allInTierFilter, feeTierFilter, symbolGroupFilter]);

  const newCustomerDialog = useDisclosure();
  const handleAddCustomerButton = useDynamicCallback(() => {
    newCustomerDialog.open();
  });

  const handleOnNewCustomerSuccess = useDynamicCallback(() => {
    // Refresh the rest blotter to show the new customer
    setRefreshKey(prevKey => prevKey + 1);
  });

  const panelActions = useMemo(
    () => [
      <>
        {isAuthorized(ACTION.EDIT_CUSTOMER_CONFIGURATIONS) && (
          <Button
            startIcon={IconName.Plus}
            onClick={handleAddCustomerButton}
            variant={ButtonVariants.Positive}
            disabled={!isAuthorized(ACTION.DEALER_TRADING)}
            size={FormControlSizes.Small}
          >
            Add Customer
          </Button>
        )}
        <NewCustomerDialog dialog={newCustomerDialog} onSuccess={handleOnNewCustomerSuccess} />
      </>,
    ],
    [handleAddCustomerButton, handleOnNewCustomerSuccess, isAuthorized, newCustomerDialog]
  );

  return (
    <EntityAdminREST<CustomerConfiguration>
      key={refreshKey}
      title="Customer Configurations"
      subtitle="View and manage customer configurations."
      entityName="Customer Configuration"
      path="/customer-configurations"
      getPatchDeletePath={getCustomerConfigurationsPatchDeletePath}
      entityIDField="Counterparty"
      density={BlotterDensity.Comfortable}
      columns={CUSTOMER_CONFIGURATIONS_COLUMNS}
      drawerOptions={drawerOptions}
      filterableProperties={filterableProperties}
      filterableServerFields={['Counterparty']}
      allowModeSwitch={isAuthorized(ACTION.EDIT_CUSTOMER_CONFIGURATIONS)}
      allowAddEntity={false} // adding entity is handled by the NewCustomerDialog
      allowEditEntity={isAuthorized(ACTION.EDIT_CUSTOMER_CONFIGURATIONS)}
      allowDeleteEntity={false}
      getEditEntityName={getEditCustomerConfigurationsEditEntityName}
      panelActions={panelActions}
      persistKey="customer-configurations"
      blotterRowCount={getConfig(OrgConfigurationKey.CustomerConfigurationRowsMax, DEFAULT_MAX_ROWS)}
      paginationSize={getConfig(OrgConfigurationKey.CustomerConfigurationsPageSize, DEFAULT_PAGINATION_SIZE)}
      allowBulkEdit={true}
      bulkEditFields={['Mode', 'AllInTier', 'FeeTier', 'SymbolGroup']}
    />
  );
};

import { createContext, useContext, useMemo, useRef, type ReactNode, type RefObject } from 'react';
import { useMarketSelectorConfig, type MarketSelectorConfigContextProps } from './MarketSelectorConfigContext';
import type { MarketSelectorProps } from './types';

export type MarketSelectorContextProps = MarketSelectorProps['marketSelectorAPI'] &
  MarketSelectorConfigContextProps & {
    blotterSearchRef: RefObject<HTMLInputElement>;
  };

export const MarketSelectorContext = createContext<MarketSelectorContextProps | undefined>(undefined);

export function useMarketSelectorContext() {
  const context = useContext(MarketSelectorContext);
  if (context === undefined) {
    throw new Error('Missing MarketSelectorContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

type MarketSelectorContextProviderProps = {
  marketSelectorAPI: MarketSelectorProps['marketSelectorAPI'];

  children: ReactNode;
};

/**
 * This provider exists to make it easier to consume the state and api returned from the useMarketSelector hook as well as common state
 * which can be useful for several views.
 */
export const MarketSelectorContextProvider = function MarketSelectorContextProvider({
  children,
  marketSelectorAPI,
}: MarketSelectorContextProviderProps) {
  const globalConfig = useMarketSelectorConfig();
  const blotterSearchRef = useRef<HTMLInputElement>(null);
  const value = useMemo(
    () => ({ ...globalConfig, ...marketSelectorAPI, blotterSearchRef }),
    [globalConfig, marketSelectorAPI]
  );
  return <MarketSelectorContext.Provider value={value}>{children}</MarketSelectorContext.Provider>;
};

import { isEqual } from 'lodash-es';
import { useLayoutEffect, useState } from 'react';
import type { MarketSelectorValue } from '../types';

/**
 * This hook is used in cases where your preserved state is just an array of selections, but you still want to
 * be able to select and use market selector presets within the life time of the Market Selector.
 *
 * The hook will effectively wrap the selections passed in with the selected preset, if any. If the selections change on their own,
 * the preset is unselected.
 */
export const useWrappedMarketSelectorValue = (selections: string[]) => {
  const [marketSelectorValue, setMarketSelectorValue] = useState<MarketSelectorValue>({
    type: 'custom',
    selections,
  });

  // There is an issue with having this just be useEffect -- the new value is set in the _next_ render cycle. We want it to
  // be set in the same render cycle as selections is set in.
  // This hook will be removed in its entirety once we are entirely migrated over to the new market selector.
  useLayoutEffect(() => {
    setMarketSelectorValue(current => {
      if (!isEqual(current.selections, selections)) {
        return {
          type: 'custom',
          selections,
        };
      } else {
        return current;
      }
    });
  }, [selections]);

  return { marketSelectorValue, onMarketSelectorValueChange: setMarketSelectorValue };
};

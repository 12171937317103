import type { ICellRendererParams } from 'ag-grid-community';
import type { ColDefFactory, Column } from '.';
import { baseColumn } from './baseColumn';
import type { ToggleParams } from './toggle.types';

export const toggle: ColDefFactory<Column<ToggleParams>> = column => ({
  width: 85,
  ...baseColumn(column),
  cellRenderer: 'toggle',
  valueGetter: ({ node }) => column.field && node?.data[column.field] === 'Enabled',
  valueSetter: params => {
    if (column.field == null) {
      return false;
    }
    params.data[column.field] = params.newValue ? 'Enabled' : 'Disabled';
    return true;
  },
  suppressHeaderMenuButton: column.suppressHeaderMenuButton ?? true,
  cellRendererParams: ({ data }: ICellRendererParams<any>) => {
    return { disabled: column.params?.isDisabled?.(data) ?? false };
  },
  // This is implicitly editable by clicking the toggle. Setting editable: true makes no sense here.
  editable: false,
});

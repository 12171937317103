import {
  BlotterTable,
  ButtonVariants,
  CUSTOM_BLOTTER_GROUPING,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  Divider,
  FormControlSizes,
  HStack,
  Icon,
  IconButton,
  IconName,
  Input,
  Portal,
  TabSize,
  VStack,
  columnTypes,
  createCSVFileName,
  getShowJSONContextItem,
  isGridApiReady,
  prettifyLedgerUpdateTypeEnum,
  selectAll,
  useBlotterGroupingToggling,
  useBlotterTable,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  usePersistedBlotterTable,
  type BlotterGroupingDefinition,
  type BlotterTableSort,
  type ColumnDef,
  type LedgerAccountTypeEnum,
  type LedgerEvent,
  type SubscriptionResponse,
  type UsePersistedColumnsChangedParams,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams } from 'ag-grid-community';
import { compact } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import type { Observable } from 'rxjs';
import { LedgerEventsBlotterSummaryLine } from '../../../Blotters/LedgerEvents/LedgerEventsBlotterSummaryLine';

interface LedgerEventsBlotterProps {
  ledgerEventsObs: Observable<SubscriptionResponse<LedgerEvent>>;
  accountType: LedgerAccountTypeEnum;
}

export const ACCOUNT_LEDGER_EVENTS_BLOTTER_BUTTONS_PORTAL = 'AccountLedgerEventsBlotterButtonsPortal';

const defaultSort: BlotterTableSort<LedgerEvent> = ['+TransactTime', '+Revision'];

type DerivsGroupings = 'Day' | 'DayAndType' | 'Asset' | typeof CUSTOM_BLOTTER_GROUPING;
type GroupablePositionColumns = keyof LedgerEvent;
const GROUPINGS: BlotterGroupingDefinition<DerivsGroupings, GroupablePositionColumns>[] = [
  {
    key: 'Day',
    label: 'By Day',
    rowGroupColumns: ['dayString'],
    buttonDataTestId: 'by-day-button',
  },
  {
    key: 'DayAndType',
    label: 'By Day and Type',
    rowGroupColumns: ['dayString', 'Type'],
    buttonDataTestId: 'by-day-and-type-button',
  },
  {
    key: 'Asset',
    label: 'By Asset',
    rowGroupColumns: ['Asset'],
    buttonDataTestId: 'by-asset-button',
  },
  {
    key: CUSTOM_BLOTTER_GROUPING,
    label: 'Custom',
    rowGroupColumns: [],
    buttonDataTestId: 'custom-grouping-button',
  },
];

const LEDGER_EVENTS_BLOTTER_ID = 'ledger-events-blotter';

export const LedgerEventsBlotter = ({ ledgerEventsObs, accountType }: LedgerEventsBlotterProps) => {
  const mixpanel = useMixpanel();

  const columns = useColumns(accountType);
  const persistedBlotterTable = usePersistedBlotterTable<LedgerEvent>(LEDGER_EVENTS_BLOTTER_ID, {
    columns,
    sort: defaultSort,
  });

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const { jsonModal, handleClickJson } = useJsonModal();
  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams<LedgerEvent>) => {
    const data = params.node?.data;
    return compact([
      selectAll(params, mixpanel),
      'separator',
      data ? getShowJSONContextItem({ params, handleClickJson }) : undefined,
      'separator',
      ...getDefaultContextMenuItems(params),
    ]);
  });

  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        editable: false,
        hide: false,
        suppressColumnsToolPanel: false,
        params: {
          suppressCount: true,
        },
      }),
    []
  );

  const [selectedRows, setSelectedRows] = useState<LedgerEvent[]>([]);

  const handleColumnsChanged = useDynamicCallback((params: UsePersistedColumnsChangedParams) => {
    updateGroupingOnColumnsChanged?.(params.columns);
    persistedBlotterTable.onColumnsChanged(params);
  });

  const persistence = useMemo(() => {
    return {
      ...persistedBlotterTable,
      onColumnsChanged: handleColumnsChanged,
    };
  }, [handleColumnsChanged, persistedBlotterTable]);

  const blotterTable = useBlotterTable<LedgerEvent>({
    dataObservable: ledgerEventsObs,
    persistence,
    columns: persistedBlotterTable.columns,
    rowID: 'rowID' satisfies keyof LedgerEvent,
    gridOptions: {
      onSelectionChanged: ({ api }) => {
        if (!isGridApiReady(api)) {
          return;
        }
        const selectedRows = api.getSelectedRows();
        setSelectedRows(selectedRows);
      },
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      showOpenedGroup: true,
      autoGroupColumnDef,
      rowGroupPanelShow: 'always',
      getContextMenuItems,
    },
  });

  const { expandAllGroups, collapseAllGroups, setRowGroupColumns, exportDataAsCSV } = blotterTable;

  const { updateGroupingOnColumnsChanged, groupingToggleButtons } = useBlotterGroupingToggling({
    blotterName: 'ledger events',
    initialColumns: persistedBlotterTable.columns,
    setRowGroupColumns,
    groupings: GROUPINGS,
    allowNoGrouping: true,
    size: TabSize.Small,
  });

  const handleExport = useCallback(() => {
    exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Ledger Events',
      }),
    });
  }, [exportDataAsCSV]);

  return (
    <VStack h="100%">
      <Portal portalId={ACCOUNT_LEDGER_EVENTS_BLOTTER_BUTTONS_PORTAL}>
        <HStack gap="spacingDefault">
          <Input
            prefix={<Icon icon={IconName.Search} />}
            clearable={true}
            size={FormControlSizes.Small}
            width="150px"
            value={blotterTable.blotterTableFiltersProps.quickFilterText}
            onChange={e => blotterTable.blotterTableFiltersProps.onQuickFilterTextChanged(e.target.value)}
          />
          <Divider orientation="vertical" />
          {groupingToggleButtons}
          <Divider orientation="vertical" />
          <HStack gap="spacingSmall">
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="ledger-events-expand-all"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
              data-testid="ledger-events-collapse-all"
            />
            <IconButton
              icon={IconName.DocumentDownload}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={handleExport}
            />
          </HStack>
          <Divider orientation="vertical" />
        </HStack>
      </Portal>
      <BlotterTable {...blotterTable} background="colors.gray['020']" />
      <LedgerEventsBlotterSummaryLine rows={selectedRows} />
      {jsonModal}
    </VStack>
  );
};

const useColumns = (accountType: LedgerAccountTypeEnum) => {
  return useMemo(() => {
    return compact([
      {
        type: 'text',
        field: 'Type',
        title: 'Talos Ledger Event',
        params: { getLabel: prettifyLedgerUpdateTypeEnum },
        rowGroup: false, // always starts false, user can enable
        enableRowGroup: true,
      },
      {
        type: 'number',
        field: 'Amount',
        params: {
          currencyField: 'amountAssetWithFallback' satisfies keyof LedgerEvent,
          highlightNegative: true,
          truncate: false,
        },
      },
      {
        type: 'asset',
        field: 'Asset',
        enableRowGroup: true,
        params: {
          showDescription: false,
          colorful: true,
        },
        hide: true,
      },
      {
        type: 'asset',
        field: 'AmountAsset',
        params: {
          showDescription: false,
          colorful: true,
        },
        hide: true,
      },
      { type: 'date', field: 'TransactTime', title: 'Venue Transact Time', params: { milliseconds: true } },
      { type: 'text', field: 'dayString', title: 'Day', rowGroup: true, hide: true, enableRowGroup: true },
      { type: 'text', field: 'hourString', title: 'Hour', rowGroup: false, hide: true, enableRowGroup: true },
      { type: 'date', field: 'Timestamp', title: 'Talos Received Time', hide: true, params: { milliseconds: true } },
      { type: 'text', field: 'Revision', title: 'Ledger Revision' },
      // note that I have a common ID between this column def and the one below. This is to apply the same persisted state to both
      // of these columns. They're effectively the same but for the two different account types
      accountType === 'SubAccount'
        ? {
            id: 'account',
            type: 'subAccountName',
            field: 'AccountName',
            title: 'Account',
            enableRowGroup: true,
            hide: true,
          }
        : {
            id: 'account',
            type: 'marketAccount',
            field: 'AccountName',
            title: 'Account',
            enableRowGroup: true,
            hide: true,
          },
      {
        type: 'number',
        field: 'ResultingAmount',
        title: 'Balance',
        params: {
          currencyField: 'amountAssetWithFallback' satisfies keyof LedgerEvent,
          highlightNegative: true,
          truncate: false,
        },
        hide: true,
      },
      {
        type: 'text',
        field: 'TradeID',
        enableRowGroup: true,
      },
      {
        type: 'text',
        field: 'Comments',
        enableRowGroup: true,
      },
      {
        type: 'price',
        field: 'AvgCost',
        title: 'Avg Cost',
        params: {
          quoteCurrencyField: 'AvgCostCurrency' satisfies keyof LedgerEvent,
        },
      },
    ] satisfies ColumnDef<LedgerEvent>[]);
  }, [accountType]);
};

import {
  FilterClauseType,
  removeEmptyFilters,
  useSymbolsFilter,
  type BlotterTableFilter,
  type BuyingPower,
  type FilterClause,
  type FilterableProperty,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { compact, isEqual, keys } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useMarketsFilter } from '../../../hooks';

export interface BuyingPowerBlotterFilter {
  Symbols?: string[];
  Counterparties?: string[];
}

export interface UseBuyingPowerBlotterFilterParams<TData extends BuyingPower> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}
export function useBuyingPowerBlotterFilter<TData extends BuyingPower>({
  persistedBlotterTable,
}: UseBuyingPowerBlotterFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [filter, setFilter] = useState<BuyingPowerBlotterFilter>(persistedBlotterTable.initialFilter || {});

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const symbolsFilter = useSymbolsFilter();
  const marketsFilter = useMarketsFilter({ onlyTradingMarkets: true });

  const filterableProperties = useMemo(
    () =>
      compact<FilterableProperty<keyof BuyingPowerBlotterFilter>>([
        {
          ...symbolsFilter,
          control: 'single-select',
        },
        {
          ...marketsFilter,
          key: 'Counterparties',
          label: 'Counterparties (Markets)',
        },
      ]),
    [symbolsFilter, marketsFilter]
  );
  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof BuyingPowerBlotterFilter)[]).forEach((key: keyof BuyingPowerBlotterFilter) => {
        switch (key) {
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: BuyingPowerBlotterFilter = removeEmptyFilters<BuyingPowerBlotterFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies BuyingPowerBlotterFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;

  return {
    initialFilter: persistedBlotterTable.initialFilter,
    filter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
  };
}

const colIDToFilterBuilderKeyMap = {
  Counterparty: 'Counterparties',
  Symbol: 'Symbols',
} satisfies Partial<Record<keyof BuyingPower, keyof BuyingPowerBlotterFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof BuyingPowerBlotterFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}

export function toServerFilter(filter: BuyingPowerBlotterFilter | undefined) {
  if (!filter) {
    return filter;
  }
  /*
    The backend is old. It only accepts one Symbol and a list of Counterparties. 
    This limits the usability of the blotter a bit, and to remove this limitation the backend needs to modernize to accept 
    an array of Symbols.
   */
  return {
    Symbol: filter.Symbols?.at(0),
    Counterparties: filter.Counterparties,
  };
}

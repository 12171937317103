import Big from 'big.js';
import { DEFAULT_LOCALE } from '../contexts/IntlContext';
import { toBig } from '../utils';
import {
  BaseField,
  FieldValidationLevel,
  FieldValidationType,
  type FieldData,
  type FieldValidationResult,
  type FieldValidationRule,
} from './BaseField';
import { fieldsMessages } from './messages';

export interface NumericFieldData extends FieldData<string> {
  unit: Unit;
  scale?: number; // number of digits after decimal point, undefined implies no rule, i.e. no limit
  precision?: number; // number of significant digits in a number, unsupported at the moment
  userInput?: string; // keep track of user input to show in error message
  userInputValid?: boolean;
  minimum?: string;
  displayTrailingZeroes?: boolean;
}

export enum Unit {
  Decimal = 'Decimal', // regular numbers such as 1000, 10.5, etc.
  Percent = 'Percent', // 1 = 100%, so 5% === 0.05
  Bps = 'Bps', // 1 === 0.0001, similar to pips but no JPY exception
  Notional = 'Notional', // 1k = 1000, 5m = 5000000
}

export enum Multiplier {
  k = 1000,
  m = 1000000,
}

type ValueFormatter = (value: string, scale?: number, options?: Partial<NumericFieldData>) => string;
type ValueSetter = (value: string | undefined) => { isValid: boolean; value: string | undefined };

// https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
export function isNumericText(value: string | undefined): boolean {
  if (typeof value !== 'string') {
    return false;
  }
  // casting on purpose to use type coercion - see more detailed explanation in above stackoverflow answer
  return !isNaN(value as any) && !isNaN(parseFloat(value));
}

function hasValidMultiplier(value: string | undefined): boolean {
  if (!value) {
    return false;
  }
  const multiplier = value.substring(value.length - 1);
  return Object.keys(Multiplier).includes(multiplier) && value.indexOf(multiplier) === value.lastIndexOf(multiplier);
}

function isValidNotional(value: string | undefined): boolean {
  return isNumericText(value) || hasValidMultiplier(value);
}

export function getScaleFromIncrement(increment?: string): number {
  if (!increment) {
    return 0;
  }
  const parts = increment.split('.');
  return !parts[1] ? 0 : parts[1]?.length;
}

export class NumericField extends BaseField<NumericFieldData> {
  private readonly formatter: ValueFormatter;
  private readonly valueSetter: ValueSetter;

  constructor(initial?: Partial<NumericFieldData>) {
    super({
      name: 'NumericField',
      value: undefined,
      isRequired: true,
      placeholder: 'Type here',
      isTouched: false,
      isDisabled: false,
      isVisible: true,
      errors: [],
      unit: Unit.Decimal,
      scale: undefined,
      displayTrailingZeroes: false,
      ...initial,
    });

    const util = getUnitUtils(this.data.unit);
    this.formatter = util.formatter;
    this.valueSetter = util.valueSetter;
  }

  public get bigValue(): Big | undefined {
    if (this.data.value == null) {
      return undefined;
    }
    try {
      return new Big(this.data.value);
    } catch (e) {
      console.error(`Error trying to get bigValue from ${this.data.value}`);
      return undefined;
    }
  }

  public override get displayValue(): string {
    if (this.data.userInput) {
      return this.data.userInput;
    }
    if (!this.data.value) {
      return '';
    }
    return this.formatter(this.data.value, this.data.scale, { displayTrailingZeroes: this.data.displayTrailingZeroes });
  }

  public get value(): string | undefined {
    return this.data.value;
  }

  public get scale(): number | undefined {
    return this.data.scale;
  }

  public get unit(): Unit {
    return this.data.unit;
  }

  // Note: System override assumes values would already be in the right format
  public override updateValue(value: string | undefined, isSystemOverride = false): NumericField {
    const result = this.valueSetter(value);

    const updatedData = {
      value: isSystemOverride ? value : result.isValid ? result.value : undefined,
      isTouched: isSystemOverride ? false : true,
      userInput: isSystemOverride ? undefined : value,
      userInputValid: isSystemOverride ? undefined : result.isValid,
    };

    const updated = this.updateData(updatedData);
    return updated.invariantCheck();
  }

  public updateUnit(unit: Unit): NumericField {
    return this.updateData({ unit });
  }

  public updateScale(scale: number | undefined): NumericField {
    const updated = this.updateData({ scale });
    // previous valid value might no longer be if new scale is violated
    return updated.invariantCheck();
  }

  public setTouched(isTouched: boolean): NumericField {
    const updated = this.updateData({ isTouched });
    return updated.invariantCheck();
  }

  public setIsRequired(isRequired: boolean): NumericField {
    const updated = this.updateData({ isRequired });
    return updated.invariantCheck();
  }

  public setIsVisible(isVisible: boolean): NumericField {
    const updated = this.updateData({ isVisible });
    return updated.invariantCheck();
  }

  public setMinimum(minimum: string): NumericField {
    const updated = this.updateData({ minimum });
    return updated.invariantCheck();
  }

  public validate<C>(rules: FieldValidationRule<NumericField, C, string>[] = [], context?: C): NumericField {
    const checked = this.invariantCheck();
    const errors = checked.data.errors.filter(e => e.type !== FieldValidationType.Rule);

    rules.forEach(rule => {
      const result = rule(this, context);
      if (result) {
        errors.push({ ...result, type: FieldValidationType.Rule });
      }
    });

    return this.updateData({ errors });
  }

  public override setDisabled(isDisabled: boolean): NumericField {
    return this.updateData({ isDisabled });
  }

  private invariantCheck(): NumericField {
    const errors: FieldValidationResult[] = this.data.errors.filter(e => e.type === FieldValidationType.Rule);

    if (this.data.isRequired && this.data.value == null) {
      errors.push({
        message: fieldsMessages.dataNameIsRequired,
        values: { dataName: this.data.name },
        level: FieldValidationLevel.Error,
      });
    }
    if (!this.data.userInputValid && this.data.userInput) {
      errors.push({ message: `Invalid input`, level: FieldValidationLevel.Error });
    }
    if (this.data.minimum != null && toBig(this.data.value)) {
      const violateMin = toBig(this.data.value)!.lt(this.data.minimum);
      if (violateMin) {
        errors.push({
          message: fieldsMessages.minQuantityIsMinSizeCurrency,
          values: { minSize: this.data.minimum },
          level: FieldValidationLevel.Error,
        });
      }
    }

    const dps = this.displayValue?.split('.')[1];
    if (this.data.scale != null && dps?.length > this.data.scale) {
      errors.push({
        message: fieldsMessages.dataNameExceedsMaximumDpsOfScale,
        values: { dataName: this.data.name, scale: this.data.scale },
        level: FieldValidationLevel.Error,
      });
    }

    return this.updateData({ errors });
  }

  private updateData(data: Partial<NumericFieldData>): NumericField {
    const newData = {
      ...this.data,
      ...data,
    };
    return new NumericField(newData);
  }
}

const percentFormatter = (value: string, scale?: number): string => {
  return new Big(value)
    .times(100)
    .toNumber()
    .toLocaleString(DEFAULT_LOCALE, {
      minimumFractionDigits: 0,
      maximumFractionDigits: scale != null ? scale : 20, // maximumFractionDigits only supports up to 20 max
    })
    .replace(/,/g, '');
};

const decimalFormatter = (value: string, scale?: number, options?: Partial<NumericFieldData>): string => {
  const { displayTrailingZeroes = false } = options ?? {};
  return Number(value)
    .toLocaleString(DEFAULT_LOCALE, {
      minimumFractionDigits: displayTrailingZeroes ? scale ?? 0 : 0,
      maximumFractionDigits: displayTrailingZeroes ? scale ?? 0 : scale ?? 20,
    })
    .replace(/,/g, '');
};

const notionalFormatter = (value: string, scale?: number): string => {
  return Number(value)
    .toLocaleString(DEFAULT_LOCALE, {
      minimumFractionDigits: 0,
      maximumFractionDigits: scale != null ? scale : 20,
    })
    .replace(/,/g, '');
};

const bpsFormatter = (value: string, scale?: number): string => {
  return new Big(value)
    .times(10000)
    .toNumber()
    .toLocaleString(DEFAULT_LOCALE, {
      minimumFractionDigits: 0,
      maximumFractionDigits: scale != null ? scale : 20,
    })
    .replace(/,/g, '');
};

const percentValueSetter: ValueSetter = (value: string | undefined) => {
  const userInputValid = !!value && isNumericText(value);
  return {
    isValid: userInputValid,
    value: userInputValid ? new Big(value).div(100).toFixed() : undefined,
  };
};

const decimalValueSetter: ValueSetter = (value: string | undefined) => {
  const userInputValid = !!value && isNumericText(value);
  return {
    isValid: userInputValid,
    value: userInputValid ? new Big(value).toFixed() : undefined,
  };
};

const notionalValueSetter: ValueSetter = (value: string | undefined) => {
  const isValidNumber = isNumericText(value);
  if (isValidNumber || !value) {
    return decimalValueSetter(value);
  }

  const userInputValid = isValidNotional(value);
  const multiplierString = value.substring(value.length - 1);
  const multiplier = Multiplier[multiplierString as keyof typeof Multiplier];
  const amount = value.slice(0, -1);
  return {
    isValid: userInputValid,
    value: userInputValid ? new Big(amount).times(multiplier).toFixed() : undefined,
  };
};

const bpsValueSetter: ValueSetter = (value: string | undefined) => {
  const userInputValid = !!value && isNumericText(value);
  return {
    isValid: userInputValid,
    value: userInputValid ? new Big(value).div(10000).toFixed() : undefined,
  };
};

function getUnitUtils(unit: Unit): {
  formatter: ValueFormatter;
  valueSetter: ValueSetter;
} {
  switch (unit) {
    case Unit.Decimal:
      return {
        formatter: decimalFormatter,
        valueSetter: decimalValueSetter,
      };
    case Unit.Percent:
      return {
        formatter: percentFormatter,
        valueSetter: percentValueSetter,
      };
    case Unit.Notional:
      return {
        formatter: notionalFormatter,
        valueSetter: notionalValueSetter,
      };
    case Unit.Bps:
      return {
        formatter: bpsFormatter,
        valueSetter: bpsValueSetter,
      };
    default:
      return {
        formatter: decimalFormatter,
        valueSetter: decimalValueSetter,
      };
  }
}

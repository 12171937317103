import Big from 'big.js';
import { isNil } from 'lodash-es';
import { memo, useMemo } from 'react';
import { SparklineContext, type SparklineContextProps, type SparklineValues } from '../contexts/SparklineContext';
import { useMultipleStreamsSubscription } from '../hooks/useMultipleStreamsSubscription';
import { SPARKLINE } from '../tokens';
import type { RequestStream } from '../types/RequestStream';
import type { SubscriptionResponse } from '../types/SubscriptionResponse';
import type { ISparkline } from '../types/types';

interface SparklineRequest extends RequestStream {
  name: typeof SPARKLINE;
  Resolution: string;
  Symbols: string[];
}

function getSparklineStreamRequestForSymbol(symbol: string): string {
  return symbol;
}

function getSparklineStreamRequestForAllSymbols(requestMap: Map<string, string>): SparklineRequest | null {
  return requestMap.size === 0
    ? null
    : { name: SPARKLINE, Resolution: '1h', Symbols: Array.from(requestMap.keys()), tag: 'SparklineContextProvider' };
}

function subscriptionCallback(
  memo: Map<string, SparklineValues | undefined>,
  json: SubscriptionResponse<ISparkline, string>
) {
  for (const d of json.data) {
    const sparklineData: SparklineValues = {
      Open: isNil(d.Open) ? undefined : Big(d.Open),
      Close: isNil(d.Close) ? undefined : Big(d.Close),
      Volume: isNil(d.Volume) ? undefined : Big(d.Volume),
      Values: d.Values,
      PercentChange: undefined,
    };

    sparklineData.PercentChange =
      isNil(sparklineData.Close) || isNil(sparklineData.Open) || sparklineData.Open?.eq(0)
        ? undefined
        : Big(sparklineData.Close).sub(sparklineData.Open).div(sparklineData.Open).times(100);
    memo.set(d.Symbol, sparklineData);
  }
  return memo;
}
export const SparklineProvider = memo(function SparklineProvider({ children }: React.PropsWithChildren<unknown>) {
  const {
    valueMapObservable: sparklinesBySymbol,
    registerSubscription,
    registerSubscriptions,
    unregisterSubscription,
    unregisterSubscriptions,
  } = useMultipleStreamsSubscription({
    getRequest: getSparklineStreamRequestForSymbol,
    getAllRequests: getSparklineStreamRequestForAllSymbols,
    subscriptionCallback: subscriptionCallback,
  });

  const value = useMemo<SparklineContextProps>(
    () => ({
      sparklinesBySymbol: sparklinesBySymbol,
      registerSubscription,
      registerSubscriptions,
      unregisterSubscription,
      unregisterSubscriptions,
    }),
    [sparklinesBySymbol, registerSubscription, unregisterSubscription, registerSubscriptions, unregisterSubscriptions]
  );
  return <SparklineContext.Provider value={value}>{children}</SparklineContext.Provider>;
});

import {
  LedgerAccountTypeEnum,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  abbreviateId,
  isCypressWindow,
  logger,
  useDynamicCallback,
  useMixpanel,
  useTabs,
  type Security,
  type UseTabs,
} from '@talos/kyoko';
import type { OrderDetailsPath } from 'containers/Trading/Markets/OrderDetails/types';
import { cloneDeep, intersection } from 'lodash-es';
import { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MAX_NUM_COLUMNS } from 'tokens/appconfig';
import { v1 as uuid } from 'uuid';
import * as routes from '../containers/Routes/routes';
import { useSearchParams } from '../hooks/useSearchParams';
import {
  MarketTabType,
  type AccountLedgerEventsDetailsMarketTab,
  type DeepDiveMarketTab,
  type DeepDiveMarketTabData,
  type GeneralMarketTab,
  type MarketTab,
  type OrderDetailsMarketTab,
  type ReconDetailsMarketTab,
  type UseInitialMarketTabsStateProps,
} from './MarketTabs.types';

export const MarketTabsContext = createContext<
  | (UseTabs<MarketTab> & {
      sortCards: boolean;
      setSortCards: (shouldSort: boolean) => void;
      prepareDeepDiveMarketTab: (
        symbol: string,
        label?: string,
        selectedMarketAccounts?: string[],
        selectedMarkets?: string[],
        aggregation?: string
      ) => void;
      prepareReconDetailsMarketTab: (mismatchIDs: string[], source?: MixpanelEventSource) => void;
      prepareAccountLedgerEventsDetailsMarketTab: ({
        asset,
        account,
        accountType,
        source,
      }: {
        asset?: string;
        account: string;
        accountType: LedgerAccountTypeEnum;
        source?: MixpanelEventSource;
      }) => void;
      updateDeepDiveMarketTabData: (marketTabIndex: number, data: Partial<DeepDiveMarketTabData>) => void;
      updateDeepDiveMarketTabSymbol: (marketTabIndex: number, symbol: Security) => void;
      updateOrderDetailsMarketTab: (marketTabIndex: number, tab: OrderDetailsPath) => void;
      removeActiveTab: () => void;
      saveOrDiscardTemporaryTab: (value: boolean) => void;
    })
  | undefined
>(undefined);

MarketTabsContext.displayName = 'MarketTabsContext';

export const useMarketTabs = () => {
  const context = useContext(MarketTabsContext);
  if (context == null) {
    throw new Error('Missing MarketTabsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export const useMarketTabDefaults = () => {
  const { selectedIndex: marketTabIndex, items: marketTabs } = useMarketTabs();
  const tab = marketTabs.at(marketTabIndex);
  return tab?.type === MarketTabType.Market ? tab?.defaults ?? {} : {};
};

export function useInitialMarketTabsState({
  initialMarketTabs,
  setMarketTabs,
  enableFlexibleLayout,
}: UseInitialMarketTabsStateProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useSearchParams();
  const mixpanel = useMixpanel(MixpanelEventSource.MarketsTab);

  const updateUrlFromMarketTab = useDynamicCallback((marketTab: MarketTab) => {
    if (enableFlexibleLayout) {
      return;
    }
    switch (marketTab.type) {
      case MarketTabType.DeepDive: {
        navigate(routes.getDeepDiveRoute({ symbol: marketTab.symbol, id: marketTab.id }));
        return;
      }
      case MarketTabType.OrderDetails: {
        mixpanel.track(MixpanelEvent.OpenOrderDetails);
        // If current URL already looks correct don't change it, keep existing sub-path intact rather than forcing details tab
        const id = routes.getIdFromOrderRoute(location.pathname);
        if (marketTab.orderID === id && marketTab.openTab === routes.getOpenTabFromOrderRoute(location.pathname)) {
          return;
        }
        navigate(routes.getOrderDetailsRoute({ orderID: marketTab.orderID, tab: marketTab.openTab, type: 'order' }));
        return;
      }

      case MarketTabType.ReconDetails: {
        navigate(routes.getReconDetailsRoute({ mismatchIDs: marketTab.mismatchIDs }));
        return;
      }

      case MarketTabType.AccountLedgerEventsDetails: {
        navigate(routes.getAccountLedgerEventsDetailsRoute(marketTab));
        return;
      }

      case MarketTabType.Multileg:
      case MarketTabType.Option:
      case MarketTabType.Market:
      case MarketTabType.PriceBlotter:
      case MarketTabType.PositionAutoHedging:
      case MarketTabType.CareOrders:
      case undefined: {
        navigate(routes.getMarketRoute(marketTab.id));
        return;
      }
      default: {
        // We should never be here
        const _exhaustiveCheck: never = marketTab;
      }
    }
  });

  const onTabsReorder = useDynamicCallback((startIndex: number, endIndex: number) => {
    const { selectedIndex, items } = marketTabs.reorderItems(startIndex, endIndex);
    if (selectedIndex > -1) {
      onTabsChanged(items as MarketTab[]);
      marketTabs.setSelectedIndex(selectedIndex);
    }
    mixpanel.track(MixpanelEvent.ReorderTab);
  });

  const onTabsChanged = useDynamicCallback((items: MarketTab[]) => {
    setMarketTabs(
      items.map(tab => {
        if (tab.type === MarketTabType.Market) {
          const newTab: GeneralMarketTab = {
            label: tab.label,
            columns:
              tab?.columns ??
              Array(MAX_NUM_COLUMNS)
                .fill(0)
                .map(() => []),
            type: MarketTabType.Market,
            defaults: tab.defaults,
            id: tab.id,
          };
          return newTab;
        } else {
          return tab;
        }
      })
    );
  });

  const handleAdd = useDynamicCallback((tab?: Partial<MarketTab> | undefined) => {
    mixpanel.track(MixpanelEvent.AddMarketsTab, {
      [MixpanelEventProperty.TabType]: tab?.type,
    });
  });

  const handleRename = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.RenameTab);
  });

  const marketTabs = useTabs<MarketTab>({
    initialItems: initialMarketTabs,
    showAddTab: true,
    onAdd: handleAdd,
    onRename: handleRename,
    onReorder: onTabsReorder,
    onItemsChanged: onTabsChanged,
    onCreateTab: () => ({
      // Set default type on adding market tab by + button
      type: MarketTabType.Market,
    }),
    onRemove: index => {
      const marketTab = marketTabs.items.at(index);
      if (marketTab) {
        mixpanel.track(MixpanelEvent.CloseTab, {
          [MixpanelEventProperty.TabType]: marketTab.type,
        });
      }
    },
    onSelect: useDynamicCallback((index: number) => {
      mixpanel.track(MixpanelEvent.ChangeMarketTab, {
        [MixpanelEventProperty.TabIndex]: index,
        [MixpanelEventProperty.TabLabel]: marketTabs.items.at(index)?.label,
        [MixpanelEventProperty.TabLength]: marketTabs.items.length,
        [MixpanelEventProperty.Type]: marketTabs.items.at(index)?.type,
      });
      const marketTab = marketTabs.items[index];

      // If the newly selected tab is not a temporary tab, we do a catch-all here to make sure we don't leave any temporary tabs behind in the items array.
      if (!marketTab.isTemporary) {
        removeAnyTemporaryTab();
      }
      updateUrlFromMarketTab(marketTab);
    }),
    requireRemoveConfirmation: useDynamicCallback((index: number, tab: MarketTab) => tab.type === MarketTabType.Market),
    getConfirmationContent: useDynamicCallback((index: number, tab: MarketTab) => (
      <>
        <p>{removeMarketTabText(tab)}</p>
        <p>Would you like to proceed?</p>
      </>
    )),
  });

  const { setSelectedIndex, items, selectedIndex } = marketTabs;

  // keep track to where to return after discarding a temporary tab
  const previousSelectedIndex = useRef(0);

  // Detect market tab index based on URL
  useEffect(() => {
    // location.pathname in testing used to be the cypress page, so if in  testing, this should be skipped
    if (!location.pathname?.startsWith('/trading') || isCypressWindow(window)) {
      previousSelectedIndex.current = 0;
      return;
    }
    if (enableFlexibleLayout) {
      return;
    }

    const defaultMarketIndex = items.findIndex(marketTab => marketTab.type === MarketTabType.Market);
    if (location.pathname === '/trading' || location.pathname === '/trading/') {
      // handle Talos logo click
      previousSelectedIndex.current = 0;
      saveOrDiscardTemporaryTab(false);
      const selectedTabIndex =
        marketTabs.selectedIndex > -1 && !marketTabs.items?.[marketTabs.selectedIndex]?.isTemporary
          ? marketTabs.selectedIndex
          : -1;

      if (selectedTabIndex > -1) {
        // We have previously selected tab so let's move to it
        const selectedTab = items.at(selectedTabIndex);
        switch (selectedTab?.type) {
          case MarketTabType.DeepDive: {
            navigate(routes.getDeepDiveRoute({ symbol: selectedTab.symbol, id: selectedTab.id }), {
              replace: true,
            });
            break;
          }

          case MarketTabType.OrderDetails: {
            navigate(
              routes.getOrderDetailsRoute({ orderID: selectedTab.orderID, tab: selectedTab.openTab, type: 'order' }),
              { replace: true }
            );
            break;
          }
          case MarketTabType.Multileg:
          case MarketTabType.Option:
          case MarketTabType.Market:
          case MarketTabType.PositionAutoHedging:
          case MarketTabType.PriceBlotter:
          case MarketTabType.CareOrders: {
            navigate(routes.getMarketRoute(selectedTab.id), { replace: true });
            break;
          }
          case MarketTabType.ReconDetails: {
            navigate(routes.getReconDetailsRoute({ mismatchIDs: selectedTab.mismatchIDs }), { replace: true });
            break;
          }
          case MarketTabType.AccountLedgerEventsDetails: {
            navigate(routes.getAccountLedgerEventsDetailsRoute(selectedTab), { replace: true });
            break;
          }
          case undefined: {
            // If we get here, there are zero tabs. It should not be possible.
            logger.error(new Error(`Unable to redirect to a tab from '/trading`));
            break;
          }
          default: {
            const _exhaustiveCheck: never = selectedTab;
          }
        }
      } else if (defaultMarketIndex !== -1) {
        navigate(routes.getMarketRoute(items[defaultMarketIndex]?.id), { replace: true });
      }
      return;
    }

    const isOrderRoute = routes.isCurrentRouteOrderDetails(location.pathname);
    const isDeepDiveRoute = routes.isCurrentRouteDeepDive(location.pathname);
    const isReconDetailsRoute = routes.isCurrentRouteReconDetails(location.pathname);
    const isAccountLedgerEventsDetailsRoute = routes.isCurrentRouteAccountLedgerEventsDetails(location.pathname);

    // First try to see if we can match existing tab with what we want
    const tabIndex = items.findIndex(marketTab => {
      switch (marketTab.type) {
        case MarketTabType.DeepDive: {
          if (isDeepDiveRoute) {
            return routes.getDeepDiveRoute({ symbol: marketTab.symbol, id: marketTab.id }) === location.pathname;
          }
          break;
        }
        case MarketTabType.OrderDetails: {
          if (isOrderRoute) {
            return location.pathname.includes(
              routes.getOrderDetailsRoute({ orderID: marketTab.orderID, type: 'order' })
            );
          }
          break;
        }
        case MarketTabType.ReconDetails: {
          if (isReconDetailsRoute) {
            // When we have reached this point, we have already asserted that the pathname is correct (url excluding query search params)
            // due to checking routes.isCurrentRouteReconDetails above.

            // For ReconDetails tabs, we perform tab equality checking on the set of mismatchIDs present in the URL.
            // We might expand this in the future
            return searchParamsMatchesReconDetailsTab(searchParams, marketTab);
          }
          break;
        }
        case MarketTabType.AccountLedgerEventsDetails: {
          if (isAccountLedgerEventsDetailsRoute) {
            // We do equality based on the search params. They make up the effective composite key of the tab
            return searchParamsMatchesAccountLedgerEventsDetailsTabLike(searchParams, marketTab);
          }
          break;
        }
        case MarketTabType.Multileg:
        case MarketTabType.Option:
        case MarketTabType.Market:
        case MarketTabType.PositionAutoHedging:
        case MarketTabType.CareOrders:
        case MarketTabType.PriceBlotter: {
          return routes.getMarketRoute(marketTab.id) === location.pathname;
        }
      }
      return false;
    });

    // If we don't have existing tab, create one in the last index in cases of DeepDive, OrderDetails, ReconDetails
    if (isDeepDiveRoute && tabIndex === -1) {
      const [symbol] = routes.getDataFromDeepDiveRoute(location.pathname);
      prepareDeepDiveMarketTab(symbol);
      return;
    }

    if (isOrderRoute && tabIndex === -1) {
      const id = routes.getIdFromOrderRoute(location.pathname);
      const openTab = routes.getOpenTabFromOrderRoute(location.pathname);
      prepareOrderDetailsMarketTab(id, openTab);
      return;
    }

    if (isReconDetailsRoute && tabIndex === -1) {
      const mismatchIDs = searchParams.get('mismatchIDs');
      const mismatchIDsArray = mismatchIDs?.split(',');
      if (!mismatchIDsArray) {
        // If something went wrong for whatever reason, exit to default place
        navigate(routes.getMarketRoute(items[defaultMarketIndex]?.id), { replace: true });
        return;
      }

      prepareReconDetailsMarketTab(mismatchIDsArray);
      return;
    }

    if (isAccountLedgerEventsDetailsRoute && tabIndex === -1) {
      const { asset, account, accountType } = getAccountLedgerEventsRouteParams(searchParams);
      const ok = account != null && accountType != null && isLedgerAccountTypeEnum(accountType);
      if (!ok) {
        // If something went wrong for whatever reason, exit to default place
        navigate(routes.getMarketRoute(items[defaultMarketIndex]?.id), { replace: true });
        return;
      }

      prepareAccountLedgerEventsDetailsMarketTab({ asset, account, accountType });
      return;
    }

    if (tabIndex > -1) {
      setSelectedIndex(tabIndex);
    } else {
      navigate(routes.getMarketRoute(items[defaultMarketIndex]?.id), { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, enableFlexibleLayout]);

  const updateDeepDiveMarketTabData = useCallback(
    (marketTabIndex: number, data: Partial<DeepDiveMarketTabData>) => {
      const items = cloneDeep(marketTabs.items);
      const item = items[marketTabIndex] as DeepDiveMarketTab;
      item.data = {
        ...item.data,
        ...data,
      };
      marketTabs.setItems(items);
    },
    [marketTabs]
  );

  /** @deprecated Use tabLabelerEnumerated from kyoko */
  function getIncrementedLabel(initial: string, labelsSet: Set<string>): string {
    let label = initial;

    // random practically high (computationally low) number to prevent infinite loop just in case
    for (let i = 1; i < 200; ++i) {
      if (labelsSet.has(label)) {
        label = `${initial} (${i})`;
        continue;
      }
      break; // Exit the loop if a unique label is found
    }
    return label;
  }

  const suggestNextLabel = useDynamicCallback((initial: string): string => {
    const labelsSet = new Set(marketTabs.items.map(t => t.label));
    return getIncrementedLabel(initial, labelsSet);
  });

  const updateOrderDetailsMarketTab = useCallback(
    (marketTabIndex: number, openTab: OrderDetailsPath) => {
      const items = cloneDeep(marketTabs.items);
      const item = items[marketTabIndex] as OrderDetailsMarketTab;
      item.openTab = openTab;
      marketTabs.setItems(items);
      updateUrlFromMarketTab(item);
    },
    [marketTabs, updateUrlFromMarketTab]
  );

  const updateDeepDiveMarketTabSymbol = useCallback(
    (marketTabIndex: number, security: Security) => {
      const items = cloneDeep(marketTabs.items);
      const item = items[marketTabIndex];
      if (item.type !== MarketTabType.DeepDive) {
        return;
      }
      item.data.selectedMarkets = security != null ? intersection(item.data.selectedMarkets, security.Markets) : [];
      item.symbol = security.Symbol;
      item.label = suggestNextLabel(security.Symbol);
      marketTabs.setItems(items);
      updateUrlFromMarketTab(item);
    },
    [marketTabs, updateUrlFromMarketTab, suggestNextLabel]
  );

  const prepareDeepDiveMarketTab = useDynamicCallback(
    (
      symbol: string,
      label?: string,
      selectedMarketAccounts?: string[],
      selectedMarkets?: string[],
      aggregation?: string
    ) => {
      const newMarketTabs = marketTabs.items.filter(t => !t.isTemporary);
      const temporaryTabExisted = newMarketTabs.length !== marketTabs.items.length;

      const id = uuid();
      newMarketTabs.push({
        id,
        type: MarketTabType.DeepDive,
        label: suggestNextLabel(label || symbol),
        symbol,
        data: {
          selectedMarketAccounts: selectedMarketAccounts ?? [],
          selectedMarkets: selectedMarkets ?? [],
          selectedAggregation: aggregation,
        },
        isTemporary: true,
      });
      marketTabs.setItems(newMarketTabs);
      navigate(routes.getDeepDiveRoute({ symbol, id }));

      previousSelectedIndex.current = temporaryTabExisted ? previousSelectedIndex.current : selectedIndex;
      setSelectedIndex(newMarketTabs.length - 1);
    }
  );

  const prepareOrderDetailsMarketTab = useDynamicCallback((orderID: string, openTab: OrderDetailsPath) => {
    const newMarketTabs = marketTabs.items.filter(t => !t.isTemporary);
    const temporaryTabExisted = newMarketTabs.length !== marketTabs.items.length;

    newMarketTabs.push({
      id: uuid(),
      label: `#${abbreviateId(orderID)}`,
      type: MarketTabType.OrderDetails,
      orderID: orderID,
      isTemporary: true,
      openTab,
    });
    marketTabs.setItems(newMarketTabs);

    previousSelectedIndex.current = temporaryTabExisted ? previousSelectedIndex.current : selectedIndex;
    setSelectedIndex(newMarketTabs.length - 1);
  });

  const prepareReconDetailsMarketTab = useDynamicCallback((mismatchIDs: string[], source?: MixpanelEventSource) => {
    const newMarketTabs = marketTabs.items.filter(t => !t.isTemporary);
    const temporaryTabExisted = newMarketTabs.length !== marketTabs.items.length;
    newMarketTabs.push({
      id: uuid(),
      label: `Recon Details`,
      type: MarketTabType.ReconDetails,
      mismatchIDs,
      isTemporary: true,
    });
    marketTabs.setItems(newMarketTabs);
    navigate(routes.getReconDetailsRoute({ mismatchIDs }));

    previousSelectedIndex.current = temporaryTabExisted ? previousSelectedIndex.current : selectedIndex;
    setSelectedIndex(newMarketTabs.length - 1);

    mixpanel.track(MixpanelEvent.CreateTempReconMismatchDetailsTab, {
      [MixpanelEventProperty.Source]: source,
      [MixpanelEventProperty.Amount]: mismatchIDs.length,
    });
  });

  const prepareAccountLedgerEventsDetailsMarketTab = useDynamicCallback(
    ({
      asset,
      account,
      accountType,
      source,
    }: {
      asset?: string;
      account: string;
      accountType: LedgerAccountTypeEnum;
      source?: MixpanelEventSource;
    }) => {
      const newMarketTabs = marketTabs.items.filter(t => !t.isTemporary);
      const temporaryTabExisted = newMarketTabs.length !== marketTabs.items.length;

      newMarketTabs.push({
        id: uuid(),
        label: `Ledger Details`,
        type: MarketTabType.AccountLedgerEventsDetails,
        asset,
        account,
        accountType,
        isTemporary: true,
      });
      marketTabs.setItems(newMarketTabs);
      navigate(routes.getAccountLedgerEventsDetailsRoute({ asset, account, accountType }));

      previousSelectedIndex.current = temporaryTabExisted ? previousSelectedIndex.current : selectedIndex;
      setSelectedIndex(newMarketTabs.length - 1);

      mixpanel.track(MixpanelEvent.CreateTempAccountLedgerEventsDetailsTab, {
        [MixpanelEventProperty.Source]: source,
      });
    }
  );

  const removeActiveTab = useDynamicCallback(() => {
    const marketTabIndex = marketTabs.selectedIndex;
    let newMarketTabIndex;
    if (marketTabs.items[marketTabIndex].isTemporary) {
      newMarketTabIndex = previousSelectedIndex.current || 0;
    } else {
      newMarketTabIndex = Math.max(marketTabIndex - 1, 0);
    }
    const newMarketTabItems = marketTabs.items.filter((tab, tabIndex) => tabIndex !== marketTabIndex);
    const newMarketTab = newMarketTabItems[newMarketTabIndex];
    updateUrlFromMarketTab(newMarketTab);
    setSelectedIndex(newMarketTabIndex);
    marketTabs.setItems(newMarketTabItems);
  });

  const saveOrDiscardTemporaryTab = (save: boolean) => {
    if (save) {
      const items = marketTabs.items.slice();
      const tempIndex = items.findIndex(t => t.isTemporary);
      const updated = {
        ...items[tempIndex],
        isTemporary: undefined,
      };
      items[tempIndex] = updated;
      marketTabs.setItems(items);
      setSelectedIndex(items.length - 1);
    } else {
      const items = marketTabs.items.filter(t => !t.isTemporary);

      if (items.length !== marketTabs.items.length) {
        marketTabs.setItems(items);
        setSelectedIndex(previousSelectedIndex.current || 0);
      }
    }
  };

  /** Removes any temporary tab which exists in the marketTabs.items list without performing any further action */
  const removeAnyTemporaryTab = useDynamicCallback(() => {
    const items = marketTabs.items.filter(t => !t.isTemporary);

    if (items.length !== marketTabs.items.length) {
      marketTabs.setItems(items);
    }
  });

  return {
    marketTabs,
    removeActiveTab,
    saveOrDiscardTemporaryTab,
    prepareDeepDiveMarketTab,
    updateDeepDiveMarketTabData,
    updateDeepDiveMarketTabSymbol,
    updateOrderDetailsMarketTab,
    prepareReconDetailsMarketTab,
    prepareAccountLedgerEventsDetailsMarketTab,
  };
}

const DEFAULT_REMOVE_TAB_MESSAGE = ({ label }: MarketTab) => `You are about to remove the "${label}" tab.`;
const REMOVE_TAB_MESSAGE = {
  [MarketTabType.Market]: ({ label }: MarketTab) =>
    `You are about to remove the "${label}" tab and the associated market cards.`,
};

export const removeMarketTabText = (marketTab: MarketTab): string => {
  const marketTabType = marketTab.type;
  return hasMarketTabTypeCustomMessage(marketTabType)
    ? REMOVE_TAB_MESSAGE[marketTabType](marketTab)
    : DEFAULT_REMOVE_TAB_MESSAGE(marketTab);
};

const hasMarketTabTypeCustomMessage = (
  marketTabType: MarketTabType
): marketTabType is keyof typeof REMOVE_TAB_MESSAGE => marketTabType in REMOVE_TAB_MESSAGE;

function searchParamsMatchesReconDetailsTab(searchParams: URLSearchParams, tab: ReconDetailsMarketTab): boolean {
  // Grab the mismatch ids from the search params, and parse to a set of individual ids we can use to check against
  const mismatchIDs = searchParams.get('mismatchIDs');
  const mismatchIDsSet = mismatchIDs ? new Set(mismatchIDs.split(',')) : undefined;
  if (!mismatchIDsSet) {
    return false;
  }

  if (mismatchIDsSet.size !== tab.mismatchIDs.length) {
    return false;
  }

  // If we can find any mismatchID in the tab thats not in the set, return false.
  // Else, the mismatchIDs search param includes the exact same set of ids as the market tab, meaning that they're effectively the same.
  return !tab.mismatchIDs.some(id => !mismatchIDsSet.has(id));
}

// This is the equality function for checking AccountLedgerEvents details tab equality
// Equality is just based on the equality of the individual params. If they're equal, we have a tab match
export function searchParamsMatchesAccountLedgerEventsDetailsTabLike(
  searchParams: URLSearchParams,
  tabLike: Pick<AccountLedgerEventsDetailsMarketTab, 'account' | 'accountType' | 'asset'>
): boolean {
  const { asset, account, accountType } = getAccountLedgerEventsRouteParams(searchParams);
  return tabLike.asset === asset && tabLike.account === account && tabLike.accountType === accountType;
}

/** Resolves the route params from an account ledger events route. Does some parsing to make sure that we ingest these params uniformly. */
export function getAccountLedgerEventsRouteParams(searchParams: URLSearchParams) {
  const asset = searchParams.get('asset') || undefined; // normalize ("" | null) -> undefined to simplify things and align with internal representation
  // the others don't need to be defaulted to undefined because they are not optional where undefined (null) becomes a valid state
  const account = searchParams.get('account') || undefined;
  const accountType = searchParams.get('accountType') || undefined;
  return {
    asset,
    account,
    accountType: isLedgerAccountTypeEnum(accountType) ? accountType : undefined,
  };
}

function isLedgerAccountTypeEnum(value: string | undefined | null): value is LedgerAccountTypeEnum {
  return value === LedgerAccountTypeEnum.MarketAccount || value === LedgerAccountTypeEnum.SubAccount;
}

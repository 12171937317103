import type {
  Allocation,
  SubAccountReconCheckpointStatusEnum,
  SubAccountReconMatch,
  SubAccountReconMatchStatusEnum,
} from '@talos/kyoko';

export const DRAWER_TABLIST_SUFFIX_PORTAL_ID = 'BreakResolutionDrawerTablistSuffix';

export type ResolutionType = 'adjust' | 'ignore';

export interface ResolveBreakForm {
  /** The type of resolution to apply to the break */
  resolutionType?: ResolutionType;
  /** The sub account allocations meant to resolve the break */
  subAccountAllocations?: Allocation[];
  /** The asset which the break pertains to */
  asset?: string;
  /** Any comments the user has related to their resolution of the break */
  comments?: string;
  /** The timestamp at which the resolution will be applied */
  timestamp?: Date;
}

/**
 * A ReconBreak defines a break in reconciliation. Recon breaks can exist at two different levels: the
 * SubAccountReconCheckpoint level, and the SubAccountReconMatch level. Matches are members of some Checkpoint.
 *
 * You can resolve entire Checkpoints, or individual Matches. Hence, the MatchID is optional. When you're resolving a
 * match you provide both IDs, and when you're resolving a checkpoint, you provide only the Checkpoint's ID.
 */
export interface ReconBreak {
  /** The CheckpointID this break pertains to */
  checkpointID: string;
  /** If the break represents a SubAccountReconMatch, this is the .ID of that Match entity */
  matchID?: string;
  /** The asset that the break pertains to */
  asset: string;
  /** Status of the break. TBD what states this type will have exactly. */
  status: SubAccountReconMatchStatusEnum | SubAccountReconCheckpointStatusEnum;
  /** The amount of the break. Can go to 0 if the break is resolved and this entity is updated. */
  breakAmount: string;
  /**
   * "hasBreak" might seem silly: this is a Break entity, so it should always be true?
   * However, a break might go from unresolved -> resolved, so we allow for this wiggle room in the type
   * such that the entity can progress through its different states.
   */
  hasBreak: boolean;
  /** Timestamp of the break. Only applicable in the Match case. */
  timestamp?: string;
  /** Sub accounts that the break pertains to */
  subAccounts?: string[];
  /** The market account that the break pertains to */
  marketAccount?: string;
  /** Either the type of the Match event, or if we're a checkpoint break, just say "Checkpoint" */
  type: SubAccountReconMatch['EventType'] | 'Checkpoint';
}

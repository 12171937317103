import type { StringSelectItem } from '@talos/kyoko';
import {
  AG_GRID_OPTION_MARK_KEY,
  AG_GRID_OPTION_PRICE_QUOTE_KEY,
  ProductTypeEnum,
  SideEnum,
  Text,
  productTypeToString,
  toBig,
  toBigWithDefault,
  type AgGridSearchSelectDropdownProps,
  type GridOptionMarkParams,
  type GridOptionsPriceQuoteParams,
  type Security,
} from '@talos/kyoko';
import type {
  ColDef,
  EditableCallbackParams,
  ICellRendererParams,
  NestedFieldPaths,
  ValueFormatterParams,
} from 'ag-grid-community';
import type { CellClassParams } from 'ag-grid-enterprise';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { compact, isEqual } from 'lodash-es';
import type { AgMultilegMarketAccountSelectorProps } from './components/AgMultilegMarketAccountSelector';
import { MultilegComboType } from './enums';
import { LegsContainer } from './models';
import type { CustomLegRow } from './types';

const getDefaultRenderOptions = (
  key: NestedFieldPaths<CustomLegRow, string> | keyof CustomLegRow
): Partial<ColDef<CustomLegRow>> => {
  return {
    field: key,
    valueGetter: ({ data }) => {
      if (!data) {
        return '';
      }
      return data[key];
    },
    cellRenderer: ({ data, value, valueFormatted, context }) => {
      if (!data) {
        return '';
      }
      const usesOwnData = data[key] != null && data[key] !== '';
      // Aggregations column uses the result from its valueFormatter
      const effectiveValue = valueFormatted ?? value;
      return (
        <Text color={usesOwnData ? 'colorTextDefault' : 'colorTextMuted'} theme={context.current.theme}>
          {effectiveValue}
        </Text>
      );
    },
  };
};

export const getDelta1ColDef = (
  handleSecurityChanged: (security: Security, index: number) => void,
  legsContainer?: LegsContainer,
  isEntryMode?: boolean
): ColDef[] => {
  if (!legsContainer) {
    return [];
  }
  return compact<ColDef>([
    {
      ...getDefaultRenderOptions('leg'),
      editable: false,
      headerName: 'Leg',
      minWidth: 40,
      width: 40,
      maxWidth: 40,
      cellClass: 'ag-cell-leg-cell',
    },
    {
      editable: false,
      headerName: '',
      minWidth: 40,
      width: 40,
      maxWidth: 40,
      cellClass: 'ag-cell-symbol-selector-cell',
      cellRenderer: 'symbolSelector',
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          isDisabled: !isEntryMode,
          onChange: security => params.node.rowIndex != null && handleSecurityChanged(security, params.node.rowIndex),
          filter: (security: Security) => {
            const rowIndex = params.node.rowIndex;
            if (rowIndex == null) {
              return true;
            }

            const availableProductTypes = legsContainer.getAvailableProductTypes(rowIndex);
            if (!availableProductTypes) {
              return true;
            }
            const containsProductType = availableProductTypes.includes(security.ProductType);

            const isDelta1 = legsContainer.instrumentType === MultilegComboType.Delta1Spread;
            const isCross = legsContainer.instrumentType === MultilegComboType.SyntheticCross;

            const baseCurrency =
              rowIndex === 1
                ? legsContainer.legs[0]?.security?.BaseCurrency
                : legsContainer.legs[1]?.security?.BaseCurrency;
            const quoteCurrency =
              rowIndex === 1
                ? legsContainer.legs[0]?.security?.QuoteCurrency
                : legsContainer.legs[1]?.security?.QuoteCurrency;

            if (isDelta1) {
              return !baseCurrency ? containsProductType : security.BaseCurrency === baseCurrency;
            }

            if (isCross) {
              const validCross =
                security.BaseCurrency === baseCurrency ||
                security.BaseCurrency === quoteCurrency ||
                security.QuoteCurrency === baseCurrency ||
                security.QuoteCurrency === quoteCurrency;

              return !baseCurrency && !quoteCurrency ? containsProductType : validCross;
            }

            return containsProductType;
          },
        };
      },
    },
    {
      ...getDefaultRenderOptions('productType'),
      headerName: 'Product',
      cellEditor: 'searchSelectDropdown',
      suppressKeyboardEvent: () => true,
      cellEditorPopup: true,
      cellEditorParams: (params: CustomCellEditorProps) => {
        return {
          ...params,
          useSearchSelectParams: {
            items: legsContainer.getAvailableProductTypes(params.rowIndex),
            getLabel: (v: string) => (v === ProductTypeEnum.PerpetualSwap ? 'Perp' : v),
            initialSortByLabel: false,
          },
          portalize: true,
          showClear: legsContainer.instrumentType === MultilegComboType.Delta1Spread,
        } satisfies AgGridSearchSelectDropdownProps<string>;
      },
      editable: isEntryMode,
      valueFormatter: (params: ValueFormatterParams) => {
        return productTypeToString(params.value);
      },
      minWidth: 120,
      width: 120,
      maxWidth: 120,
      cellClass: isEntryMode ? 'ag-cell-editable' : undefined,
    },
    {
      ...getDefaultRenderOptions('symbol'),
      headerName: legsContainer.hasOption ? 'Symbol / Coin' : 'Symbol',
      cellEditor: 'searchSelectDropdown',
      suppressKeyboardEvent: () => true,
      cellEditorPopup: true,
      cellEditorParams: (params: CustomCellEditorProps) => {
        return {
          ...params,
          useSearchSelectParams: {
            items: legsContainer.getAvailableSymbols(params.rowIndex),
            getLabel: (v: StringSelectItem) => v.label,
            initialSortByLabel: false,
          },
          portalize: true,
          showClear: true,
        } satisfies AgGridSearchSelectDropdownProps<StringSelectItem>;
      },
      editable: isEntryMode,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value?.label;
      },
      minWidth: 120,
      width: 120,
      maxWidth: 120,
      cellClass: isEntryMode ? 'ag-cell-editable' : undefined,
    },
    legsContainer.supportsExchange
      ? {
          ...getDefaultRenderOptions('exchange'),
          headerName: legsContainer.hasFuture || legsContainer.hasPerp ? 'Exchange & Type' : 'Exchange',
          cellEditor: 'searchSelectDropdown',
          suppressKeyboardEvent: () => true,
          cellEditorPopup: true,
          cellEditorParams: (params: CustomCellEditorProps) => {
            return {
              ...params,
              useSearchSelectParams: {
                items: legsContainer.getAvailableExchanges(params.rowIndex),
                getLabel: (v: StringSelectItem) => v.label,
                initialSortByLabel: false,
              },
              portalize: true,
              showClear: true,
            } satisfies AgGridSearchSelectDropdownProps<StringSelectItem>;
          },
          minWidth: 190,
          width: 190,
          maxWidth: 190,
          cellClass: ['ag-cell-larger'],
          cellClassRules: {
            'ag-cell-editable': (params: CellClassParams) => {
              if (!isEntryMode) {
                return false;
              }
              const leg = legsContainer.legs[params.rowIndex];
              return LegsContainer.isLegFuture(leg) || LegsContainer.isLegOption(leg) || LegsContainer.isLegPerp(leg);
            },
          },
          editable: (params: EditableCallbackParams) => {
            if (!isEntryMode) {
              return false;
            }
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            return LegsContainer.isLegFuture(leg) || LegsContainer.isLegOption(leg) || LegsContainer.isLegPerp(leg);
          },
          valueFormatter: (params: ValueFormatterParams) => {
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            const supported =
              LegsContainer.isLegFuture(leg) || LegsContainer.isLegOption(leg) || LegsContainer.isLegPerp(leg);
            return supported ? params.value?.label?.toUpperCase() : '--';
          },
        }
      : undefined,
    legsContainer.hasFuture || legsContainer.hasOption
      ? {
          ...getDefaultRenderOptions('expiry'),
          headerName: 'Expiry Date',
          cellEditorPopup: true,
          cellEditor: 'searchSelectDropdown',
          cellEditorParams: (params: CustomCellEditorProps) => {
            return {
              ...params,
              useSearchSelectParams: {
                items: legsContainer.getAvailableExpiries(params.rowIndex),
                getLabel: (v: StringSelectItem) => v.label,
                initialSortByLabel: false,
              },
              showClear: true,
              portalize: true,
            } satisfies AgGridSearchSelectDropdownProps<StringSelectItem>;
          },
          editable: (params: EditableCallbackParams) => {
            if (!isEntryMode) {
              return false;
            }
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            return LegsContainer.isLegFuture(leg) || LegsContainer.isLegOption(leg);
          },
          valueFormatter: (params: ValueFormatterParams) => {
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            const supported = LegsContainer.isLegFuture(leg) || LegsContainer.isLegOption(leg);
            return supported ? params.value?.label?.toUpperCase() : '--';
          },
          minWidth: 120,
          width: 120,
          maxWidth: 120,
          cellClassRules: {
            'ag-cell-editable': (params: CellClassParams) => {
              if (!isEntryMode) {
                return false;
              }
              const leg = legsContainer.legs[params.rowIndex];
              return LegsContainer.isLegFuture(leg) || LegsContainer.isLegOption(leg);
            },
          },
        }
      : undefined,
    legsContainer.hasOption
      ? {
          ...getDefaultRenderOptions('strike'),
          cellEditor: 'searchSelectDropdown',
          suppressKeyboardEvent: () => true,
          cellEditorPopup: true,
          cellEditorParams: (params: CustomCellEditorProps) => {
            return {
              ...params,
              useSearchSelectParams: {
                items: legsContainer.getAvailableStrikes(params.rowIndex),
                getLabel: (v: StringSelectItem) => v.label,
                initialSortByLabel: false,
              },
              showClear: true,
              portalize: true,
            } satisfies AgGridSearchSelectDropdownProps<StringSelectItem>;
          },
          editable: (params: EditableCallbackParams) => {
            if (!isEntryMode) {
              return false;
            }
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            return LegsContainer.isLegOption(leg);
          },
          valueFormatter: (params: ValueFormatterParams) => {
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            const supported = LegsContainer.isLegOption(leg);
            return supported ? params.value?.label : '--';
          },
          minWidth: 120,
          width: 120,
          maxWidth: 120,
          cellClassRules: {
            'ag-cell-editable': (params: CellClassParams) => {
              if (!isEntryMode) {
                return false;
              }
              const leg = legsContainer.legs[params.rowIndex];
              return LegsContainer.isLegOption(leg);
            },
          },
        }
      : undefined,
    legsContainer.hasOption
      ? {
          ...getDefaultRenderOptions('type'),
          headerName: 'Type',
          cellEditor: 'searchSelectDropdown',
          suppressKeyboardEvent: () => true,
          cellEditorPopup: true,
          cellEditorParams: (params: CustomCellEditorProps) => {
            return {
              ...params,
              useSearchSelectParams: {
                items: legsContainer.getAvailableTypes(params.rowIndex),
                getLabel: (v: StringSelectItem) => v.label,
                initialSortByLabel: false,
              },
              portalize: true,
            } satisfies AgGridSearchSelectDropdownProps<StringSelectItem>;
          },
          editable: (params: EditableCallbackParams) => {
            if (!isEntryMode) {
              return false;
            }
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            return LegsContainer.isLegOption(leg);
          },
          valueFormatter: (params: ValueFormatterParams) => {
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            const supported = LegsContainer.isLegOption(leg);
            return supported ? params.value?.label : '--';
          },
          minWidth: 120,
          width: 120,
          maxWidth: 120,
          cellClassRules: {
            'ag-cell-editable': (params: CellClassParams) => {
              if (!isEntryMode) {
                return false;
              }
              const leg = legsContainer.legs[params.rowIndex];
              return LegsContainer.isLegOption(leg);
            },
            'ag-cell-type-call': params => params.value?.value === 'Call',
            'ag-cell-type-put': params => params.value?.value === 'Put',
          },
        }
      : undefined,
    {
      ...getDefaultRenderOptions('markets'),
      headerName: 'Market Account',
      cellRenderer: 'marketAccountRenderer',
      cellEditor: 'multilegMarketAccountSelector',
      suppressKeyboardEvent: () => true,
      cellEditorPopup: true,
      cellEditorParams: (params: CustomCellEditorProps<CustomLegRow>): AgMultilegMarketAccountSelectorProps => {
        const side = params.node.data?.ratio === -1 ? SideEnum.Sell : SideEnum.Buy;
        return {
          security: legsContainer.getLegSecurity(params.rowIndex),
          side,
          legIndex: params.rowIndex,
        };
      },
      editable: isEntryMode,
      valueFormatter: (params: ValueFormatterParams) => {
        const leg = legsContainer.legs[params.node?.rowIndex || 0];
        const isSpot = LegsContainer.isLegSpot(leg);
        if (isSpot && isEqual(params.value, [])) {
          return 'All Markets';
        }
        return params.value?.map((v: StringSelectItem) => v.label).join(', ');
      },
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      cellClass: isEntryMode ? ['ag-cell-editable', 'ag-cell-larger'] : 'ag-cell-larger',
    },
    {
      ...getDefaultRenderOptions('ratio'),
      cellEditor: 'amountInput',
      editable: false,
      headerName: 'Ratio',
      cellClassRules: {
        'ag-cell-ratio-positive': params => toBig(params.value)?.gte(0) ?? false,
        'ag-cell-ratio-negative': params => toBig(params.value)?.lt(0) ?? false,
      },
      valueFormatter: params => {
        return toBigWithDefault(params.value, 0).gt(0) ? `+${params.value}` : params.value;
      },
      minWidth: 75,
      width: 75,
      maxWidth: 75,
    },
    {
      ...getDefaultRenderOptions('initiating'),
      cellRenderer: 'checkbox',
      cellRendererParams: {
        disabled: !isEntryMode,
      },
      editable: false,
      headerName: 'Initiating',
      cellClass: 'ag-cell-initiating',
      minWidth: 75,
      width: 75,
      maxWidth: 75,
    },
    {
      field: 'bid',
      cellRenderer: AG_GRID_OPTION_PRICE_QUOTE_KEY,
      cellRendererParams: ({ data }: ICellRendererParams) => {
        return {
          currency: data?.currency,
          type: 'Bid',
          underlyingQuoteCurrency: data?.underlyingQuoteCurrency,
          underlyingPrice: data?.underlyingPrice,
          expiration: data?.expiryDate?.value,
          quoteCurrency: data?.quoteCurrency,
        } satisfies GridOptionsPriceQuoteParams;
      },
      minWidth: 110,
      flex: 1,
      maxWidth: 200,
      headerClass: 'ag-right-aligned-cell',
      cellClass: 'ag-right-aligned-cell',
    },
    legsContainer.supportsMark
      ? {
          ...getDefaultRenderOptions('mark'),
          cellRendererSelector: (params: ICellRendererParams) => {
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            const supported = !LegsContainer.isLegSpot(leg);
            if (supported) {
              return {
                component: AG_GRID_OPTION_MARK_KEY,
                cellRendererParams: ({ data }: ICellRendererParams) =>
                  ({ currency: data?.quoteCurrency } satisfies GridOptionMarkParams),
              };
            }
          },
          valueFormatter: ({ value, node }) => {
            const leg = legsContainer.legs[node?.rowIndex || 0];
            const supported = !LegsContainer.isLegSpot(leg);

            if (!supported) {
              return '--';
            }

            if (value && typeof value === 'object' && 'markPrice' in value) {
              return value.markPrice;
            }
            return value;
          },
          minWidth: 90,
          flex: 1,
          maxWidth: 200,
          headerClass: 'ag-right-aligned-cell',
          cellClass: 'ag-right-aligned-cell',
        }
      : undefined,
    {
      field: 'offer',
      cellRenderer: AG_GRID_OPTION_PRICE_QUOTE_KEY,
      cellRendererParams: ({ data }: ICellRendererParams) => {
        return {
          currency: data?.currency,
          type: 'Offer',
          underlyingQuoteCurrency: data?.underlyingQuoteCurrency,
          underlyingPrice: data?.underlyingPrice,
          expiration: data?.expiryDate?.value,
          quoteCurrency: data?.quoteCurrency,
        } satisfies GridOptionsPriceQuoteParams;
      },
      minWidth: 110,
      flex: 1,
      maxWidth: 200,
      headerClass: 'ag-right-aligned-cell',
      cellClass: 'ag-right-aligned-cell',
    },
    legsContainer.supportsGreeks
      ? {
          ...getDefaultRenderOptions('delta'),
          cellRendererSelector: (params: ICellRendererParams) => {
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            const supported = LegsContainer.isLegOption(leg);
            if (supported) {
              return {
                component: 'formattedNumberColumn',
                cellRendererParams: { increment: '0.01' },
              };
            }
          },
          minWidth: 90,
          flex: 1,
          maxWidth: 200,
          headerClass: 'ag-right-aligned-cell',
          cellClass: 'ag-right-aligned-cell',
          valueFormatter: (params: ValueFormatterParams) => {
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            const supported = LegsContainer.isLegOption(leg);
            return supported ? params.value?.label : '--';
          },
        }
      : undefined,
    legsContainer.supportsGreeks
      ? {
          ...getDefaultRenderOptions('gamma'),
          cellRendererSelector: (params: ICellRendererParams) => {
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            const supported = LegsContainer.isLegOption(leg);
            if (supported) {
              return {
                component: 'formattedNumberColumn',
                cellRendererParams: { increment: '0.000001' },
              };
            }
          },
          minWidth: 90,
          flex: 1,
          maxWidth: 200,
          headerClass: 'ag-right-aligned-cell',
          cellClass: 'ag-right-aligned-cell',
          valueFormatter: (params: ValueFormatterParams) => {
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            const supported = LegsContainer.isLegOption(leg);
            return supported ? params.value?.label : '--';
          },
        }
      : undefined,
    legsContainer.supportsGreeks
      ? {
          ...getDefaultRenderOptions('vega'),
          cellRendererSelector: (params: ICellRendererParams) => {
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            const supported = LegsContainer.isLegOption(leg);
            if (supported) {
              return {
                component: 'formattedNumberColumn',
                cellRendererParams: { increment: '0.01' },
              };
            }
          },
          minWidth: 90,
          maxWidth: 200,
          flex: 1,
          headerClass: 'ag-right-aligned-cell',
          cellClass: 'ag-right-aligned-cell',
          valueFormatter: (params: ValueFormatterParams) => {
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            const supported = LegsContainer.isLegOption(leg);
            return supported ? params.value?.label : '--';
          },
        }
      : undefined,
    legsContainer.supportsGreeks
      ? {
          ...getDefaultRenderOptions('theta'),
          cellRendererSelector: (params: ICellRendererParams) => {
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            const supported = LegsContainer.isLegOption(leg);
            if (supported) {
              return {
                component: 'formattedNumberColumn',
                cellRendererParams: { increment: '0.01' },
              };
            }
          },
          minWidth: 90,
          maxWidth: 200,
          flex: 1,
          headerClass: 'ag-right-aligned-cell',
          cellClass: 'ag-right-aligned-cell',
          valueFormatter: (params: ValueFormatterParams) => {
            const leg = legsContainer.legs[params.node?.rowIndex || 0];
            const supported = LegsContainer.isLegOption(leg);
            return supported ? params.value?.label : '--';
          },
        }
      : undefined,
  ]);
};

export const DEFAULT_COLUMN_DEFINITION = {
  suppressMovable: true,
  resizable: false,
  cellClassRules: {
    editable: params => params.colDef.editable,
  },
  suppressHeaderMenuButton: true,
};

import Big from 'big.js';
import { defineMessages } from 'react-intl';
import type { ExecutionReport } from '../types/ExecutionReport';
import { PricingMode } from '../types/PricingMode';
import { PricingModeEnum } from '../types/types';
import type { AmountObject } from './notional.types';

export const messages = defineMessages({
  impliedVolatility: {
    defaultMessage: 'Implied Volatility',
    id: 'utils.impliedVolatility',
  },
  submittedPrice: {
    defaultMessage: 'Submitted Price',
    id: 'utils.submittedPrice',
  },
  spreadToFixing: {
    defaultMessage: 'Spread to Fixing',
    id: 'utils.spreadToFixing',
  },
});

type MessageKey = keyof typeof messages;

export function getUnitForPriceMode(mode: PricingMode | PricingModeEnum | undefined, defaultUnit?: string): string {
  switch (mode) {
    case PricingMode.ImpliedVolatility:
    case PricingModeEnum.ImpliedVolatility:
      return '%';
    case PricingMode.UnderlyingQuoteCurrency:
    case PricingModeEnum.UnderlyingQuoteCurrency:
      // UI-3085: Default to USD, even though it is theoretically possible to
      // retrieve the currency on the security, it will (almost) always be USD.
      return 'USD';
    case PricingModeEnum.SpreadToFixing:
      return 'BPS';
    case PricingMode.Default:
    case undefined: {
      return defaultUnit || '';
    }
    default: {
      const _exhaustiveCheck: never = mode;
      return '';
    }
  }
}

export type PricingReferenceEntity = Partial<Pick<ExecutionReport, 'PricingReference' | 'PricingMode'>>;

export function getPricingReference(
  entity: PricingReferenceEntity,
  priceField: keyof PricingReferenceEntity = 'PricingReference'
): AmountObject | null {
  if (!entity.PricingMode || !entity[priceField]) {
    return null;
  }

  let priceMultiplier = 1;
  if (entity.PricingMode === PricingModeEnum.SpreadToFixing) {
    priceMultiplier = 10000;
  } else if (entity.PricingMode === PricingModeEnum.ImpliedVolatility) {
    priceMultiplier = 100;
  }

  return {
    value: Big(entity[priceField]).mul(priceMultiplier).toFixed(),
    currency: getUnitForPriceMode(entity.PricingMode),
  };
}

export function prettyPricingMode(pricingMode: NonNullable<ExecutionReport['PricingMode']>): MessageKey {
  switch (pricingMode) {
    case PricingModeEnum.ImpliedVolatility:
      return 'impliedVolatility' as MessageKey;
    case PricingModeEnum.UnderlyingQuoteCurrency:
      return 'submittedPrice' as MessageKey;
    case PricingModeEnum.SpreadToFixing:
      return 'spreadToFixing' as MessageKey;
    default: {
      const _exhaustiveCheck: never = pricingMode;
      return pricingMode;
    }
  }
}

import { identity } from 'lodash-es';
import { pipe, scan } from 'rxjs';
import type { MinimalSubscriptionResponse } from '../types/SubscriptionResponse';
import { UpdateActionEnum } from '../types/types';

export interface WSScanToDoubleMapParams<T, KeyType1 extends string | number, KeyType2 extends string | number, P> {
  /** Get the first key to use at the first level of indexing */
  getKey1: (item: T) => KeyType1;
  /** Get the second key to use at the second level of indexing */
  getKey2: (item: T) => KeyType2;
  /**
   * Whether or not to return a new map instance on every data update, treating it as an immutable map
   * Useful when the instance of the map is used to trigger change detection.
   */
  newOuterMapEachUpdate: boolean;
  /**
   * Whether or not to create new map instances of the inner maps as well on each update, treating it as an immutable map
   * Only the changed inner maps will have new instances after some SubscriptionResponse update is handled.
   * Useful if you have change detection dependent on some inner map.
   */
  newInnerMapsEachUpdate: boolean;
  /**
   * An optional parameter which allows you to take full control over the deletion of items.
   * If provided, this function is the only code path for deletion. Any potential UpdateAction: "Remove" on the incoming entries will be ignored
   * by the pipe itself for instance.
   * @param item the item in question, coming from json.data
   * @returns whether or not to delete the given item
   */
  shouldDelete?: (item: T) => boolean;
  /**
   * Whether or not to delete an inner map when it is emptied (size = 0).
   * Defaults to false.
   */
  deleteInnerMapOnEmptied?: boolean;
  /** An optional function going from `T => P` where you determine what you would like stored as values in the map.
   * @example
   * // basic case: just store T. This will emit a double map of type `Map<KeyType1, Map<KeyType2, T>`
   * getInsertable: item => item
   * // store a specific property of T, eg DisplayName. This will emit a map of type `Map<KeyType1, Map<KeyType2, string>`
   * getInsertable: item => item.DisplayName
   * @default lodash.identity
   */
  getInsertable?: (item: T) => P;
}

/**
 * An RxJS operator to scan SubscriptionResponses to a "double-map", meaning a two-leveled map.
 * Useful in cases where you want to index data with two keys.
 * @returns `Map<string | number, Map<string | number, T>>`
 */
export function wsScanToDoubleMap<
  T extends AnyObject,
  KeyType1 extends string | number,
  KeyType2 extends string | number,
  P = T
>({
  getKey1,
  getKey2,
  newOuterMapEachUpdate,
  newInnerMapsEachUpdate,
  shouldDelete,
  deleteInnerMapOnEmptied = false,
  getInsertable = identity,
}: WSScanToDoubleMapParams<T, KeyType1, KeyType2, P>) {
  return pipe(
    scan((outerMapAcc, json: MinimalSubscriptionResponse<T>) => {
      const outerMap =
        // if initial always create a new map
        json.initial === true
          ? new Map()
          : // if not initial, create new map based on flag.
          newOuterMapEachUpdate
          ? new Map(outerMapAcc)
          : outerMapAcc;

      // For safety
      if (json.data == null) {
        return outerMap;
      }

      const changedInnerMaps = new Set<KeyType1>();
      for (const update of json.data) {
        const key1 = getKey1(update);
        const key2 = getKey2(update);
        const maybeUpdateAction: UpdateActionEnum | undefined =
          ('UpdateAction' in update && (update['UpdateAction'] as UpdateActionEnum)) || json.action;
        // If specified, the shouldDelete function has full control over _all_ deletion
        const shouldDeleteUpdate = shouldDelete ? shouldDelete(update) : maybeUpdateAction === UpdateActionEnum.Remove;

        // if the inner map is already marked as changed, we don't need to create a new instance of it.
        const shouldUpdateInnerMapThisUpdate = newInnerMapsEachUpdate && !changedInnerMaps.has(key1);

        if (shouldDeleteUpdate) {
          // Should delete. Try to grab the inner map first.
          const currentInnerMap = outerMap.get(key1);
          if (!currentInnerMap) {
            continue;
          }

          const innerMap = shouldUpdateInnerMapThisUpdate ? new Map(currentInnerMap) : currentInnerMap;
          innerMap.delete(key2);
          outerMap.set(key1, innerMap);

          if (deleteInnerMapOnEmptied && innerMap.size === 0) {
            outerMap.delete(key1);
            // in case messages are poorly coalesced, in which case there can be updates + deletions in one single data array,
            // make sure to delete any possible entry of a changed map related to the map we're now deleting
            changedInnerMaps.delete(key1);
            continue;
          }

          // We only add the map to set of changed inner maps if we don't end up deleting it directly above ^
          changedInnerMaps.add(key1);
        } else {
          const currentInnerMap = outerMap.get(key1);
          if (currentInnerMap != null) {
            const innerMap = shouldUpdateInnerMapThisUpdate ? new Map(currentInnerMap) : currentInnerMap;
            // we're making a change to an already created map
            innerMap.set(key2, getInsertable(update));
            outerMap.set(key1, innerMap);
            changedInnerMaps.add(key1);
          } else {
            // immediately set the item in a new innerMap and dont add this map to the changedInnerMaps set
            // since it implicitly changes due to being... created :)
            outerMap.set(key1, new Map([[key2, getInsertable(update)]]));
          }
        }
      }

      return outerMap;
    }, new Map<KeyType1, Map<KeyType2, P>>())
  );
}

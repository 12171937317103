import Layout, { type LayoutProps } from 'components/Layout/Layout';
import 'dockview/dist/styles/dockview.css';
import { useMemo } from 'react';
import { noop } from 'rxjs';
import type { PanelComponentSpec } from 'types/LayoutConfig';
import { useDockViewProvider } from '../DockViewProvider';
import { WrapComponentsForDockView } from '../panelUtils';
import { DockViewSubLayoutEmptyError } from './DockViewSubLayoutEmptyError';
import { useSubLayoutContext } from './SubLayoutContextProvider';

interface GridLayoutProps {
  availablePanels: PanelComponentSpec[];
  onLayoutReady: LayoutProps['onLayoutReady'];
}

/** Dockview that can be used for subview scenarios (currently focused on PMS) */
export const DockViewSubLayout: React.FC<GridLayoutProps> = ({ availablePanels, onLayoutReady }) => {
  const { addPanel } = useSubLayoutContext();
  const { selectedLayout } = useDockViewProvider();

  const panelComponents = useMemo(() => WrapComponentsForDockView(availablePanels), [availablePanels]);
  return (
    <Layout
      addPanel={addPanel}
      availablePanelTypes={availablePanels}
      onLayoutChange={noop} // Layout changes auto-handled by api callback
      onLayoutReady={onLayoutReady}
      panelComponents={panelComponents}
      selectedLayout={selectedLayout}
      watermarkComponent={DockViewSubLayoutEmptyError}
      disallowLastCloseMode="lastTabInContainer"
    />
  );
};

import { FormControlSizes, Grid, IconName, Popover, usePopoverState } from '@talos/kyoko';
import type { IDockviewHeaderActionsProps } from 'dockview';
import type { PanelComponentSpec } from 'types/LayoutConfig';
import { useLayoutContext } from '../providers/LayoutContextProvider';
import { TabIconButton, TabTypeButton } from '../styles';

export const AddTabComponent = (props: IDockviewHeaderActionsProps) => {
  const popoutDocument =
    props?.group.api.location.type === 'popout' ? props?.group.api.location.getWindow().document : undefined;

  const popover = usePopoverState({
    placement: 'bottom-start',
    trigger: 'click',
    closeOnClickOutside: true,
    usePortal: false,
    popoverDocument: popoutDocument,
  });

  const { addPanel, availablePanelTypes } = useLayoutContext();

  const onClickTabType = (panel: PanelComponentSpec) => {
    popover.close();
    addPanel(panel.type, { label: panel.label, groupId: props.group.api.id });
  };

  if (availablePanelTypes.length === 0) {
    return null;
  }

  return (
    <Popover {...popover}>
      <TabIconButton data-testid="add-tab-button" ghost size={FormControlSizes.Small} icon={IconName.Plus} />
      <Grid data-testid="addable-panel-options" gridTemplateColumns="1fr" gap="spacingSmall">
        {availablePanelTypes.map(tab => (
          <TabTypeButton
            data-testid={`add-tab-${tab.type}`}
            key={tab.type}
            onClick={() => onClickTabType(tab)}
            endIcon={IconName.ArrowRightCircle}
            dim={true}
          >
            {tab.label}
          </TabTypeButton>
        ))}
      </Grid>
    </Popover>
  );
};

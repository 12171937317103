import {
  ACTION,
  Accordion,
  AccordionBody,
  BlotterDensity,
  BlotterTable,
  BlotterTableOverlay,
  Box,
  Button,
  ConnectionModeEnum,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  FilterBuilder,
  FilterBuilderClearAllButton,
  FilterBuilderToggleButton,
  FilterClauseType,
  FormControlSizes,
  HStack,
  Icon,
  IconName,
  LocalFilterInput,
  MARKET_SECURITY_STATUS,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  Tooltip,
  createCSVFileName,
  createPersistedFilterFromQueryParams,
  createQueryParamsFromFilterClauses,
  getMarketSecurityStatusKey,
  getTypedKeys,
  removeEmptyFilters,
  useAccordion,
  useAccordionFilterBuilder,
  useConstant,
  useDisclosure,
  useMixpanel,
  usePersistedBlotterTable,
  useWsBlotterTable,
  withAccordionGroup,
  type FilterClause,
  type InitialFilterClause,
  type MarketAccount,
  type MarketSecurityStatus,
  type MinimalSubscriptionResponse,
  type WebsocketRequest,
} from '@talos/kyoko';
import { useRouterQueryParams } from '@talos/kyoko/src/hooks/useRouterQueryParams';
import type { RowClassParams } from 'ag-grid-community';
import { useFeatureFlag, useRoleAuth } from 'hooks';
import { isEmpty } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { map, pipe } from 'rxjs';
import { Link } from '../../../components/OMS/styles';
import { EditMarketSecModeDialog } from './EditMarketSecModeDialog';
import { useSecurityMastersMenu } from './SecurityMasterBlotterMenu';
import type { MarketSecurityStatusLocal, SecurityBlotterParams } from './types';
import { useSecurityMasterColumns } from './useSecurityMasterColumns';
import { useSecurityMasterFilter, type SecurityMasterFilter } from './useSecurityMasterFilter';

export const SecurtyMasterBlotter = withAccordionGroup(function (props: {
  marketAccountsByName: Map<string, MarketAccount>;
}) {
  const { enableSecurityMasterRequiredFiltering } = useFeatureFlag();
  const { queryParams, setQueryParams } = useRouterQueryParams<SecurityBlotterParams>();
  const { isAuthorized } = useRoleAuth();
  const [selectedStatuses, setSelectedStatuses] = useState<MarketSecurityStatusLocal[] | undefined>();
  const mixpanel = useMixpanel();
  const editMarketSecModeDialog = useDisclosure();
  const { open } = editMarketSecModeDialog;

  const handleEdit = useCallback(
    (rows: MarketSecurityStatusLocal[]) => {
      if (isAuthorized(ACTION.EDIT_SECMASTER)) {
        mixpanel.track(rows.length === 1 ? MixpanelEvent.EditSymbol : MixpanelEvent.BulkEditSymbol, {
          [MixpanelEventProperty.Source]: MixpanelEventSource.SecurityMaster,
        });
        setSelectedStatuses(rows);
        open();
      }
    },
    [open, isAuthorized, mixpanel]
  );

  const columns = useSecurityMasterColumns(isAuthorized, handleEdit);

  const persisted = usePersistedBlotterTable('SecurityMaster', {
    columns,
  });

  const filterBuilderAccordion = useAccordion({ id: 'securityMasterFilter', initialOpen: true });
  const { changeFilter, filterableProperties, filterFunc, filter } = useSecurityMasterFilter({
    saveFilter: persisted.onFilterChanged,
    initialFilter: isEmpty(queryParams) ? persisted.initialFilter : createPersistedFilterFromQueryParams(queryParams),
  });

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters({
          ...curr,
          MarketAccounts: filterClausesByPropertyKey.get('MarketAccounts')?.selections,
          Markets: filterClausesByPropertyKey.get('Markets')?.selections,
          Statuses: filterClausesByPropertyKey.get('Statuses')?.selections,
          Enableds: filterClausesByPropertyKey.get('Enableds')?.selections,
          ProductTypes: filterClausesByPropertyKey.get('ProductTypes')?.selections,
          MarketDataEnableds: filterClausesByPropertyKey.get('MarketDataEnableds')?.selections,
          Symbols: filterClausesByPropertyKey.get('Symbols')?.selections,
          Available: filterClausesByPropertyKey.get('Available')?.selections,
        });

        const filtersToQueryParam = createQueryParamsFromFilterClauses(filterClausesByPropertyKey);

        setQueryParams(filtersToQueryParam);

        return newFilter;
      });
    },
    [changeFilter, setQueryParams]
  );

  const initialFilterClauses: InitialFilterClause[] = useConstant(
    getTypedKeys(filter).map(key => ({
      key: key,
      type: FilterClauseType.INCLUSIVE,
      selections: filter[key] as string[],
    }))
  );
  // For the initial load, sync the query params with the initial filter clauses.
  useEffectOnce(() => {
    const filtersToQueryParam = createQueryParamsFromFilterClauses(
      new Map<string, FilterClause>(
        initialFilterClauses.map(clause => [
          clause.key,
          {
            key: clause.key,
            selections: clause.selections!,
            type: clause.type,
          },
        ])
      )
    );
    setQueryParams(filtersToQueryParam);
  });

  const filterBuilder = useAccordionFilterBuilder({
    filterBuilderProps: {
      initialFilterClauses: initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: filterClauses => handleFilterClausesChanged(filterClauses),
    },
    accordionProps: filterBuilderAccordion,
  });

  const { getContextMenuItems, dialogComponents } = useSecurityMastersMenu({
    openClause: filterBuilder.openClause,
    filterableProperties,
    onEdit: handleEdit,
  });

  const request = useConstant({
    name: MARKET_SECURITY_STATUS,
    tag: 'SecurityMasterBlotter',
  });

  const mssLocalMappingPipe = useMemo(
    () =>
      pipe(
        map((msg: MinimalSubscriptionResponse<MarketSecurityStatus>) => ({ ...msg, data: msg.data.map(transformMsg) }))
      ),
    []
  );

  const allowRequest = useMemo(() => {
    // feature flagged, only require filtering for certain heavy clients
    if (!enableSecurityMasterRequiredFiltering) {
      return true;
    }
    // we need one of these keys to be specified by the user in order to make a backend request
    const requiredKeys: (keyof SecurityMasterFilter)[] = ['MarketAccounts', 'Symbols', 'Markets'];
    return requiredKeys.some(key => {
      const clause = filterBuilder.filterBuilder.filterClausesByPropertyKey.get(key);
      return clause != null && clause.selections.length > 0;
    });
  }, [enableSecurityMasterRequiredFiltering, filterBuilder.filterBuilder.filterClausesByPropertyKey]);

  const blotterTable = useWsBlotterTable<WebsocketRequest, MarketSecurityStatusLocal, MarketSecurityStatus>({
    initialRequest: request,
    allowRequest,
    columns: columns,
    rowID: 'ID',
    clientLocalFilter: filterFunc,
    pipe: mssLocalMappingPipe,
    filter: onlyServerFilterKeys(filter),
    gridOptions: {
      onRowDoubleClicked: ({ data }) => data != null && handleEdit([data]),
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems,
    },
  });

  const handleExport = useCallback(() => {
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'SecurityMaster',
      }),
    });
  }, [blotterTable]);

  const handleClickClearAll = useCallback(() => {
    filterBuilder.filterBuilder.removeAllFilterClauses();
  }, [filterBuilder.filterBuilder]);

  return (
    <Panel>
      <PanelHeader alignItems="center">
        <h2>Security Master</h2>
        <PanelActions>
          <Tooltip tooltip="See the Knowledge Base for further information on Security Master.">
            <HStack gap="spacingSmall">
              <Icon icon={IconName.BookOpen} /> See the{' '}
              <Link href="https://kb.talostrading.com/system-configuration/settings/security-master" target="_blank">
                Knowledge Base
              </Link>
            </HStack>
          </Tooltip>
          <LocalFilterInput
            value={blotterTable.blotterTableFiltersProps.quickFilterText}
            onChange={evt => {
              blotterTable.blotterTableFiltersProps.onQuickFilterTextChanged(evt);
              mixpanel.track(MixpanelEvent.FilterQuickSearch, {
                [MixpanelEventProperty.Source]: MixpanelEventSource.SecurityMaster,
              });
            }}
            width="200px"
          />

          <FilterBuilderClearAllButton
            removeAllFilterClauses={handleClickClearAll}
            disabled={filterBuilder.filterBuilder.filterClauses.length === 0}
            size={FormControlSizes.Default}
          />

          <FilterBuilderToggleButton
            filterClauses={filterBuilder.filterBuilder.filterClauses}
            isOpen={filterBuilderAccordion.isOpen}
            onClick={() => filterBuilderAccordion.toggle()}
            size={FormControlSizes.Default}
          />
          <Button startIcon={IconName.DocumentDownload} onClick={handleExport}>
            Export CSV
          </Button>
        </PanelActions>
      </PanelHeader>
      <Box background="colors.gray.main" w="100%" px="spacingBig">
        <Accordion {...filterBuilderAccordion}>
          <AccordionBody>
            <FilterBuilder {...filterBuilder.filterBuilder} />
          </AccordionBody>
        </Accordion>
      </Box>
      <PanelContent pb={0}>
        <BlotterTable
          {...blotterTable}
          gridOptions={{
            ...blotterTable.gridOptions,
            getRowStyle: (params: RowClassParams<MarketSecurityStatusLocal>) => {
              if (params.data?._marketEnabled !== ConnectionModeEnum.Up) {
                return { opacity: '0.5' };
              }
            },
          }}
          density={BlotterDensity.Comfortable}
          hidden={!allowRequest}
        />
        {!allowRequest && (
          <BlotterTableOverlay>
            <h2>Filter by Symbol, Market Account or Market to view Security Master data</h2>
          </BlotterTableOverlay>
        )}
        {selectedStatuses && <EditMarketSecModeDialog {...editMarketSecModeDialog} mktSecStatuses={selectedStatuses} />}
      </PanelContent>
      {dialogComponents}
    </Panel>
  );
});

function transformMsg(msg: MarketSecurityStatus): MarketSecurityStatusLocal {
  // We pick all properties manually instead of spreading since spreading is significantly slower and we
  // are handling tens of thousands of records with this function
  return {
    Capabilities: msg.Capabilities,
    ConnectionType: msg.ConnectionType,
    Enabled: msg.Enabled,
    Market: msg.Market,
    MarketAccount: msg.MarketAccount,
    MaximumSize: msg.MaximumSize,
    MinPriceIncrement: msg.MinPriceIncrement,
    MinSizeIncrement: msg.MinSizeIncrement,
    MinimumAmount: msg.MinimumAmount,
    MinimumSize: msg.MinimumSize,
    SizeBuckets: msg.SizeBuckets,
    Status: msg.Status,
    Symbol: msg.Symbol,
    Timestamp: msg.Timestamp,
    MarketCapabilities: msg.MarketCapabilities,
    MarketEnabled: msg.MarketEnabled,
    MarketMinPriceIncrement: msg.MarketMinPriceIncrement,
    MarketMinSizeIncrement: msg.MarketMinSizeIncrement,
    MarketMaximumSize: msg.MarketMaximumSize,
    MarketMinimumSize: msg.MarketMinimumSize,
    MarketMinimumAmount: msg.MarketMinimumAmount,
    MarketSizeBuckets: msg.MarketSizeBuckets,
    RequestedEnabled: msg.RequestedEnabled,
    RequestedMinPriceIncrement: msg.RequestedMinPriceIncrement,
    RequestedMinSizeIncrement: msg.RequestedMinSizeIncrement,
    RequestedMinimumSize: msg.RequestedMinimumSize,
    RequestedMaximumSize: msg.RequestedMaximumSize,
    RequestedMinimumAmount: msg.RequestedMinimumAmount,
    RequestedCapabilities: msg.RequestedCapabilities,
    RequestedSizeBuckets: msg.RequestedSizeBuckets,
    ID: getMarketSecurityStatusKey(msg.MarketAccount, msg.Symbol),
    _capabilities: capabilitiesToList(msg.Capabilities),
    _marketEnabled: msg.MarketEnabled !== undefined ? msg.MarketEnabled : ConnectionModeEnum.Up,
  } satisfies MarketSecurityStatusLocal;
}

function capabilitiesToList(caps?: { [key: string]: boolean }): string {
  if (caps == null) {
    return '';
  }
  return Object.keys(caps)
    .map(key => (caps[key] ? key : ''))
    .compact()
    .join(', ');
}

function onlyServerFilterKeys(filter: SecurityMasterFilter | undefined): SecurityMasterFilter {
  if (filter == null) {
    return {};
  }

  return {
    Symbols: filter.Symbols,
    // The backend devs think its fun to combine Markets and Market Accounts :^)
    Markets: [...(filter.MarketAccounts ?? []), ...(filter.Markets ?? [])],
    MarketAccounts: undefined,
  };
}

import {
  FormControlSizes,
  IconName,
  IndicatorBadge,
  MixpanelEvent,
  Tooltip,
  useDefaultMarketAccounts,
  useMarketAccountsContext,
  useMixpanel,
} from '@talos/kyoko';
import { get } from 'lodash-es';
import { MakeDefaultMarketAccountButton } from './styles';

interface DefaultMarketAccountProps {
  marketAccount: string;
}

export function DefaultMarketAccount({ marketAccount }: DefaultMarketAccountProps) {
  const mixpanel = useMixpanel();
  const { marketAccountsByName } = useMarketAccountsContext();
  const { defaultMarketAccounts, setDefaultMarketAccount } = useDefaultMarketAccounts();
  const market = marketAccountsByName.get(marketAccount)?.Market;
  if (!market) {
    return null;
  }

  const isDefault = get(defaultMarketAccounts, market) === marketAccount;

  return (
    <>
      {isDefault && <IndicatorBadge data-testid="default-market-account-indicator">Default</IndicatorBadge>}
      {!isDefault && (
        <Tooltip tooltip="Make Default">
          <MakeDefaultMarketAccountButton
            // Adding an onClick directly to this Button triggers the onSelectionChanged event of the AgGrid
            ref={ref => {
              if (!ref) {
                return;
              }
              ref.onclick = e => {
                setDefaultMarketAccount(market, marketAccount);
                mixpanel.track(MixpanelEvent.DefaultMarketAccount);
                e.preventDefault();
              };
            }}
            icon={IconName.Check}
            size={FormControlSizes.Xxs}
            data-testid="default-market-account-button"
          />
        </Tooltip>
      )}
    </>
  );
}

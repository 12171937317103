import { runValidation, type MarketAccount } from '@talos/kyoko';
import { isEqual } from 'lodash-es';
import { useEffect, useState } from 'react';
import { object, string } from 'yup';
import type { CustomerMarketAccountCreate } from '../../../providers';

export const useAddCustomerMarketAccountValidation = (form: Partial<MarketAccount>) => {
  const [errors, setErrors] = useState<CustomerMarketAccountCreate>({});

  useEffect(() => {
    const schema = {
      Counterparty: string().required('Customer is required'),
      SourceAccountID: string().required('Customer ID is required'),
    };

    setErrors(prev => {
      const next = runValidation(object().shape(schema), form);
      return isEqual(prev, next) ? prev : next;
    });
  }, [form]);

  return { errors };
};

import { capitalize } from 'lodash-es';
import type React from 'react';
import {
  createRef,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type MutableRefObject,
} from 'react';
import { getTypedKeys } from '../../utils';
import { PRECISIONS, PRECISION_TOOLKITS, TimePickerPrecision, stepPrecisionValue } from './TimePickerPrecision';
import { Delimiter, PrecisionWrapper, TimePickerInput, TimePickerInputWrapper } from './styles';
export { TimePickerPrecision } from './TimePickerPrecision';

const formatTimeText = (timeText: TimeText): TimeText => {
  getTypedKeys(timeText).forEach(precision => {
    const precisionToolkit = PRECISION_TOOLKITS[precision];
    timeText[precision] = precisionToolkit.formatter(timeText[precision]);
  });

  // Return a new object to get the change detection to run despite no changes perhaps being detected
  return timeText;
};

const dateToTimeTextState = (date: Date | null): TimeText => {
  const shouldFormat = date != null;

  let timeText = PRECISIONS.reduce((o, precision) => {
    const precisionToolkit = PRECISION_TOOLKITS[precision];
    const timeTextValue = date ? precisionToolkit.valueGetter(date).toString() : '';
    return Object.assign(o, {
      [precision]: timeTextValue,
    });
  }, {});

  if (shouldFormat) {
    timeText = formatTimeText(timeText);
  }

  return timeText;
};

const isInputTextInteger = (text: string): boolean => {
  const reg = new RegExp('^[0-9]+$');
  return reg.test(text);
};

const isInputTextValid = (text: string, precision: TimePickerPrecision): boolean => {
  if (text === '') {
    return true;
  }
  const timeVal = +text;
  const { min, max } = PRECISION_TOOLKITS[precision];
  const maxLength = max.toString().length;
  return isInputTextInteger(text) && timeVal >= min && timeVal <= max && text.length <= maxLength;
};

const keyToPrecision = (key: string | number): TimePickerPrecision => {
  return parseInt(key.toString());
};

/**
 * Given an input value string, determines if the input is filled completely and valid.
 * @param inputValue
 * @param precision
 * @returns
 */
const precisionInputCompletedAndValid = (inputValue: string, precision: TimePickerPrecision): boolean => {
  const precisionCompletedLength = PRECISION_TOOLKITS[precision].max.toString().length;
  return isInputTextValid(inputValue, precision) && inputValue.length === precisionCompletedLength;
};

export type TimePickerProps = {
  date: Date | null;
  maxPrecision?: TimePickerPrecision;
  onChange: (date: Date) => void;
  highlightTextOnFocus?: boolean;
  moveCursorOnInputCompletion?: boolean;
  disabled?: boolean;
};

type TimeText = {
  [key: number]: string;
};

/**
 * A controlled TimePicker component.
 */
export const TimePicker = memo(
  forwardRef<HTMLLabelElement, TimePickerProps>(
    (
      {
        date,
        maxPrecision = TimePickerPrecision.SECOND,
        onChange,
        highlightTextOnFocus = true,
        moveCursorOnInputCompletion = true,
        disabled = false,
      },
      ref
    ) => {
      const [touched, setTouched] = useState(false);
      const showedPrecisions = useMemo(() => PRECISIONS.filter(p => p <= maxPrecision), [maxPrecision]);
      const [timeText, setTimeText] = useState(dateToTimeTextState(date));

      // populate with one Ref for each desired input field
      const inputRefs = useRef<{ [key: number]: MutableRefObject<HTMLInputElement> }>(
        showedPrecisions.reduce(
          (obj, precision) =>
            Object.assign(obj, {
              [precision]: createRef(),
            }),
          {}
        )
      );

      useEffect(() => {
        // each time date changes we update timeText to reflect this
        setTimeText(dateToTimeTextState(date));
      }, [date]);

      const invalid = useMemo(() => {
        return touched && getTypedKeys(timeText).some(key => !isInputTextValid(timeText[key], keyToPrecision(key)));
      }, [timeText, touched]);

      // Returns a copy of the prop date with the current timeText values applied
      const getDateFromTimeText = useCallback(() => {
        const nonNullDate: Date = date ?? Sugar.Date.create('today'); // today at 00 hours

        const newDate = showedPrecisions
          .map(p => PRECISION_TOOLKITS[p])
          .reduce((updatingDate, toolkit) => {
            return toolkit.dateSetter(updatingDate, +timeText[toolkit.precision]);
          }, new Date(nonNullDate));

        return newDate;
      }, [timeText, showedPrecisions, date]);

      const somethingHasChanged = useMemo(() => {
        // somethingHasChanged is only used on blur.
        // if date is null and we're blurring an input, it means it will be populated in some way.
        if (date == null) {
          return true;
        }

        return getDateFromTimeText().getTime() !== date.getTime();
      }, [getDateFromTimeText, date]);

      const updateDate = useCallback(() => {
        onChange(getDateFromTimeText());
      }, [onChange, getDateFromTimeText]);

      const handleInputChange = useCallback(
        (inputValue: string, precision: TimePickerPrecision) => {
          setTimeText(curr => ({ ...curr, [precision]: inputValue }));

          if (moveCursorOnInputCompletion && precisionInputCompletedAndValid(inputValue, precision)) {
            // step forward to next if we are not the last input field.
            if (precision !== maxPrecision) {
              const nextInputRef = inputRefs.current[precision + 1];
              // place at back of js call stack to perform after state update above
              // the alternative is to introduce substantially more complexity in the component.
              setTimeout(() => nextInputRef.current.focus(), 0);
            } else {
              // we're done with the last input, remove focus.
              setTimeout(() => inputRefs.current[precision].current.blur(), 0);
            }
          }
        },
        [moveCursorOnInputCompletion, maxPrecision]
      );

      // On blur we make bigger decisions: either emit onChange with new date,
      // or reset to previous correct date because something was invalid.
      const handleBlur = useCallback(
        (precision: TimePickerPrecision) => {
          if (somethingHasChanged) {
            const proposedText = timeText[precision];
            if (isInputTextValid(proposedText, precision)) {
              updateDate();
            } else {
              // Reset the text state to the latest received (correct) date values.
              setTimeText(dateToTimeTextState(date));
            }
          } else {
            // if nothing changes let's just take the opportunity to format everything now that we're moving between inputs
            // since nothing has changed, we need to ensure that change detection runs to catch formatting, so create new timeText obj.
            setTimeText(prevTimeText => Object.assign({}, formatTimeText(prevTimeText)));
          }
        },
        [timeText, updateDate, date, somethingHasChanged]
      );

      const handleFocus = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
          if (!touched) {
            setTouched(true);
          }

          if (highlightTextOnFocus) {
            event.target.select();
          }
        },
        [highlightTextOnFocus, touched]
      );

      const isSomeTextSelectedInInput = (input: HTMLInputElement): boolean => {
        return input.selectionStart !== input.selectionEnd;
      };

      const isCursorAtExtremityOfInputText = (extremity: 'left' | 'right', input: HTMLInputElement): boolean => {
        const cursorLocation = input.selectionStart;
        if (extremity === 'left') {
          return cursorLocation === 0;
        } else {
          const textLength = input.value.length;
          return cursorLocation === textLength;
        }
      };

      const cursorShouldJump = useCallback(
        (direction: 'left' | 'right', event: React.KeyboardEvent<HTMLInputElement>) => {
          const input = event.target as HTMLInputElement;
          return !isSomeTextSelectedInInput(input) && isCursorAtExtremityOfInputText(direction, input);
        },
        []
      );

      const incOrDecTimeTextValue = useCallback(
        (incOrDec: 'inc' | 'dec', precision: TimePickerPrecision) => {
          const timeTextStr = timeText[precision];
          if (isInputTextInteger(timeTextStr)) {
            setTimeText(timeText => ({
              ...timeText,
              [precision]: stepPrecisionValue(+timeTextStr, precision, incOrDec).toString(),
            }));
          }
        },
        [timeText]
      );

      const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>, precision: TimePickerPrecision) => {
          switch (event.key) {
            case 'ArrowDown':
              incOrDecTimeTextValue('dec', precision);
              event.preventDefault();
              return;
            case 'ArrowUp':
              incOrDecTimeTextValue('inc', precision);
              event.preventDefault();
              return;
            case 'Enter': {
              const proposedText = timeText[precision];
              if (isInputTextValid(proposedText, precision) && somethingHasChanged) {
                updateDate();
              }
              return;
            }
            case 'Backspace':
              if (timeText[precision] === '' && precision !== TimePickerPrecision.HOUR) {
                const prevInputRef = inputRefs.current[precision - 1];
                setTimeout(() => prevInputRef.current.focus(), 0);
              }
              return;
            case 'ArrowLeft':
              if (cursorShouldJump('left', event) && precision !== TimePickerPrecision.HOUR) {
                const prevInputRef = inputRefs.current[precision - 1];
                setTimeout(() => {
                  const prevInput = prevInputRef.current;
                  prevInput.focus();
                  // place cursor at the end
                  prevInput.setSelectionRange(prevInput.value.length, prevInput.value.length);
                }, 0);
              }
              return;
            case 'ArrowRight':
              if (cursorShouldJump('right', event) && precision !== showedPrecisions[showedPrecisions.length - 1]) {
                const nextInputRef = inputRefs.current[precision + 1];
                setTimeout(() => {
                  const nextInput = nextInputRef.current;
                  nextInput.focus();
                  // place cursor at the end
                  nextInput.setSelectionRange(0, 0);
                }, 0);
              }
              return;
            default:
              return;
          }
        },
        [cursorShouldJump, incOrDecTimeTextValue, somethingHasChanged, showedPrecisions, timeText, updateDate]
      );

      const renderInput = useCallback(
        (precision: TimePickerPrecision) => {
          const delimiter = PRECISION_TOOLKITS[precision].delimiter;

          return (
            <PrecisionWrapper key={precision}>
              {delimiter && <Delimiter>{delimiter}</Delimiter>}
              <TimePickerInput
                disabled={disabled}
                type="text"
                value={timeText[precision]}
                onChange={inputChange => handleInputChange(inputChange.target.value, precision)}
                onBlur={() => handleBlur(precision)}
                onFocus={handleFocus}
                onKeyDown={event => handleKeyDown(event, precision)}
                ref={inputRefs.current[precision]}
                role="textbox"
                aria-label={capitalize(TimePickerPrecision[precision] as string) + 's'}
              />
            </PrecisionWrapper>
          );
        },
        [disabled, handleBlur, handleFocus, handleInputChange, handleKeyDown, timeText]
      );

      return (
        <TimePickerInputWrapper invalid={invalid} disabled={disabled} ref={ref}>
          {showedPrecisions.map(precision => renderInput(precision))}
        </TimePickerInputWrapper>
      );
    }
  )
);

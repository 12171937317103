import {
  Box,
  Button,
  ButtonVariants,
  CheckboxFuseAutocompleteDropdown,
  Flex,
  IconName,
  MultiSelectInput,
  useDropdownPopper,
  useMultiSelectAutocomplete,
  useRenderMultiSelectSelection,
} from '@talos/kyoko';
import { identity } from 'lodash-es';
import { useCallback, useMemo, useRef, type Dispatch, type SetStateAction } from 'react';

export function MultiAndSelectAll({
  placeholder,
  allSelectionsValue,
  availableSelections,
  selectionsToAdd,
  setSelectionsToAdd,
  onAddSelections,
  getLabel,
  ...props
}: {
  placeholder: string;
  allSelectionsValue?: string;
  availableSelections: string[];
  selectionsToAdd: string[];
  setSelectionsToAdd: Dispatch<SetStateAction<string[]>>;
  onAddSelections: (selections: string[]) => void;
  getLabel?: (selection: string) => string;
  'data-testid'?: string;
}) {
  const allSelectionsArray = useMemo(() => (allSelectionsValue ? [allSelectionsValue] : []), [allSelectionsValue]);
  const items = useMemo(() => {
    return [...allSelectionsArray, ...availableSelections];
  }, [allSelectionsArray, availableSelections]);

  const allChecked = useMemo(
    () => allSelectionsValue && selectionsToAdd.includes(allSelectionsValue),
    [allSelectionsValue, selectionsToAdd]
  );

  const isItemDisabled = useCallback(
    (selection: string) => {
      return allChecked ? selection !== allSelectionsValue : false;
    },
    [allSelectionsValue, allChecked]
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const { autocompleteOutput, multipleSelectionOutput } = useMultiSelectAutocomplete({
    selections: allChecked && allSelectionsValue ? allSelectionsArray : selectionsToAdd,
    items,
    getLabel: getLabel ?? identity,
    isItemDisabled,
    initialSortByLabel: false,
    onChange: setSelectionsToAdd,
    clearInputAfterSelection: false,
    removeItemOnInputBackspace: true,
    highlightInputTextAfterSelection: true,
    inputRef,
  });

  const { isOpen, closeMenu, openMenu, getInputProps, getMenuProps } = autocompleteOutput;
  const { getDropdownProps, getSelectedItemProps, removeSelectedItem } = multipleSelectionOutput;
  const handleClickOutside = useCallback(() => closeMenu(), [closeMenu]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownPopper = useDropdownPopper({
    isOpen,
    referenceElement: dropdownRef.current,
    dropdownWidth: '240px',
    dropdownPlacement: 'bottom-end',
    onClickOutside: handleClickOutside,
  });

  const renderMultiSelectItem = useRenderMultiSelectSelection({
    removeSelectedItem,
    getSelectedItemProps,
    getLabel,
  });

  return (
    <Flex gap="spacingSmall" w="100%">
      <Box flex={1}>
        <Box ref={dropdownRef} flex={1}>
          <MultiSelectInput
            {...getInputProps(getDropdownProps({ ref: inputRef }))}
            placeholder={placeholder}
            onClick={openMenu}
            items={multipleSelectionOutput.selectedItems.map((item, index) => renderMultiSelectItem(item, index))}
            data-testid={props['data-testid']}
          />
        </Box>
        <div {...getMenuProps()}>
          <CheckboxFuseAutocompleteDropdown
            {...autocompleteOutput}
            {...dropdownPopper}
            selectedItems={multipleSelectionOutput.selectedItems}
            addSelectedItem={multipleSelectionOutput.addSelectedItem}
            removeSelectedItem={multipleSelectionOutput.removeSelectedItem}
            maxHeight={400}
          />
        </div>
      </Box>

      <Button
        variant={ButtonVariants.Primary}
        startIcon={IconName.Plus}
        disabled={multipleSelectionOutput.selectedItems.length === 0}
        onClick={() => onAddSelections(multipleSelectionOutput.selectedItems)}
        data-testid="add-selections-button"
      >
        Add
      </Button>
    </Flex>
  );
}

import { useMemo } from 'react';
import { useIntl as useLibraryIntl } from 'react-intl';
import type { ColDefIntl, IntlMessages } from '../components/BlotterTable/columns';
import { getIntlKey } from '../contexts/IntlContext';
import { prettyName } from '../utils';
import { useDynamicCallback } from './useDynamicCallback';
import { useIntlContext } from './useIntlContext';

export function useIntl(): ColDefIntl {
  const { preferServerEnglishText, formatCell } = useIntlContext();
  const sharedIntl = useLibraryIntl();
  return useMemo(
    () => ({ ...sharedIntl, preferServerEnglishText, formatCell }),
    [sharedIntl, preferServerEnglishText, formatCell]
  );
}

/**
 * Returns a function that can be used to handle the translations of an option label.
 */
export function useTranslatedOptionLabel(messages: IntlMessages) {
  const { formatMessage } = useIntl();

  return useDynamicCallback((option: string) => {
    const intlKey = getIntlKey(option);
    if (intlKey in messages) {
      return formatMessage(messages[intlKey as keyof typeof messages]);
    }
    return prettyName(option);
  });
}

import {
  filterByCellValueMenuItem,
  getShowJSONContextItem,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  type Column,
  type FilterableProperty,
  type ITradeAllocation,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItems } from 'ag-grid-community';
import type { GetContextMenuItemsParams } from 'ag-grid-enterprise';
import { compact } from 'lodash-es';
import { useMemo } from 'react';
import { TradeAllocationMenu } from './TradeAllocationMenu';
import { colIDToFilterBuilderKey } from './useTradeAllocationFilter';

export function useTradeAllocationMenu({
  openClause,
  filterableProperties,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
}): {
  getContextMenuItems: GetContextMenuItems<ITradeAllocation>;
  dialogs: JSX.Element;
  column: Column;
} {
  const mixpanel = useMixpanel();
  const { handleClickJson, jsonModal } = useJsonModal();

  const dialogs = useMemo(() => <>{jsonModal}</>, [jsonModal]);

  const column = useMemo<Column>(
    () => ({
      type: 'hamburgerMenu',
      id: 'marketExecutionReportMenu',
      params: {
        renderItems: params => <TradeAllocationMenu {...params} onShowJson={handleClickJson} />,
        csvExport: true,
      },
    }),
    [handleClickJson]
  );

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const marketExecutionReport = params?.node?.data;
    if (marketExecutionReport == null) {
      return [];
    }
    return compact([
      getShowJSONContextItem({ params, handleClickJson }),
      ...filterByCellValueMenuItem({
        params,
        filterableProperties,
        openClause,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      'separator',
      ...getDefaultContextMenuItems(params),
    ]);
  });

  return { column, getContextMenuItems, dialogs };
}

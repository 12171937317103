import {
  CreditBlotterExposure,
  ExposureLimitSideTypeEnum,
  getExposureID,
  jsonDefined,
  type IUniformExposure,
  type MinimalSubscriptionResponse,
} from '@talos/kyoko';
import { compact } from 'lodash-es';
import { filter, map, pipe, scan, type Observable, type UnaryFunction } from 'rxjs';

/**
 * This pipe takes in both Exposures and MarketExposures, and then combines them into CreditBlotterExposures.
 *
 * exposures can have Sides Both, Short or Long. Longs and Shorts are combined, and Both will be on its own row.
 * This complexity is handled in the backend-model though, and the front-end assumes that it wont receive a Both and Short entry
 * for the same id for example.
 *
 * The pipe below maintains a data set of all individual exposures, and then on each update will create brand new
 * combined CreditBlotterExposures from the underlying data in order to keep things functional.
 */
export function creditBlotterExposureCreationPipe(): UnaryFunction<
  Observable<MinimalSubscriptionResponse<IUniformExposure>>,
  Observable<MinimalSubscriptionResponse<CreditBlotterExposure>>
> {
  return pipe(
    scan(
      ({ map }, json) => {
        // The logic in this pipe below is bespoke enough in my opinion to not be made into a re-usable pipe.
        if (json.initial) {
          map.clear();
        }

        const changedEntryIds = new Set<string>(); // rowIDs referring to which entries in the map have been changed
        for (const update of json.data) {
          const id = getExposureID(update);
          const similarEntryInMap = map.get(id) ?? {};
          map.set(id, { ...similarEntryInMap, [update.ExposureSide]: update });
          changedEntryIds.add(id);
        }

        const outputData: CreditBlotterExposure[] = [];
        for (const changedEntryId of changedEntryIds) {
          const entry = map.get(changedEntryId);
          if (!entry) {
            continue;
          }

          const exposureInputs = compact([
            entry[ExposureLimitSideTypeEnum.Short],
            entry[ExposureLimitSideTypeEnum.Long],
            entry[ExposureLimitSideTypeEnum.Both],
          ]);

          // We always want to re-create the CreditBlotterExposure to keep things functional
          const cbe = CreditBlotterExposure.Create(exposureInputs, exposureInputs[0].type);
          if (cbe) {
            outputData.push(cbe);
          }
        }

        const newOutput: MinimalSubscriptionResponse<CreditBlotterExposure> = {
          ...json,
          data: outputData,
        };

        return { output: newOutput, map };
      },
      {
        output: undefined as MinimalSubscriptionResponse<CreditBlotterExposure> | undefined,
        map: new Map<string, ExposureAllSidesEntry>(),
      }
    ),
    map(({ output }) => output),
    filter(jsonDefined)
  );
}

interface ExposureAllSidesEntry {
  [ExposureLimitSideTypeEnum.Short]?: IUniformExposure;
  [ExposureLimitSideTypeEnum.Long]?: IUniformExposure;
  [ExposureLimitSideTypeEnum.Both]?: IUniformExposure;
}

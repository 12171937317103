import {
  NotificationVariants,
  abbreviateId,
  filterByCellValueMenuItem,
  getShowJSONContextItem,
  logger,
  percentToBps,
  request,
  useDynamicCallback,
  useEndpointsContext,
  useGetDefaultContextMenuItems,
  useGlobalDialog,
  useGlobalToasts,
  useJsonModal,
  useMixpanel,
  type Column,
  type ColumnDef,
  type CustomerTrade,
  type FilterableProperty,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-enterprise';
import { compact } from 'lodash-es';
import { useMemo, type ReactNode } from 'react';
import { CustomerTradeMenu } from './CustomerTradeMenu';
import { BookAndModifyTradeDialog } from './Dialogs';
import { useBookAndModifyTradeDialog } from './Dialogs/useBookAndModifyTradeDialog';
import { isTradeCancelled } from './isTradeCancelled';
import { colIDToFilterBuilderKey } from './useCustomerTradeFilter';

export function useCustomerTradeMenu({
  openClause,
  filterableProperties,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
}): {
  columns: Column[];
  getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} {
  const mixpanel = useMixpanel();
  const { add: addToast } = useGlobalToasts();
  const { orgApiEndpoint } = useEndpointsContext();
  const { open } = useGlobalDialog();

  const { handleClickJson, jsonModal } = useJsonModal();

  const bookAndModifyTradeDialog = useBookAndModifyTradeDialog();

  const dialogs = useMemo(
    () => (
      <>
        {jsonModal}
        <BookAndModifyTradeDialog {...bookAndModifyTradeDialog} />
      </>
    ),
    [jsonModal, bookAndModifyTradeDialog]
  );

  // Modify trade
  const handleModifyTrade = useDynamicCallback((customerTrade: CustomerTrade) => {
    bookAndModifyTradeDialog.resetForm();
    bookAndModifyTradeDialog.updateForm({
      counterparty: customerTrade.Counterparty!,
      symbol: customerTrade.Symbol,
      side: customerTrade.Side,
      currency: customerTrade.Currency,
      quantity: customerTrade.Quantity,
      price: customerTrade.Price,
      amount: customerTrade.Amount,
      amountCurrency: customerTrade.AmountCurrency,
      clTradeID: customerTrade.ClTradeID,
      tradeID: customerTrade.TradeID,
      marketAccount: customerTrade.MarketAccount,
      comments: customerTrade.Comments,
      salesCommission: percentToBps(customerTrade.SalesCommission),
      fee: customerTrade.Fee,
      feeCurrency: customerTrade.FeeCurrency,
    });
    bookAndModifyTradeDialog.open();
  });

  // Cancel trade
  const handleCancelTrade = useDynamicCallback((customerTrade: CustomerTrade) => {
    const tradeID = customerTrade.TradeID;
    if (tradeID == null) {
      logger.error(new Error('TradeID is null'), {
        extra: { customerTrade },
      });
      return;
    }
    open({
      title: 'Cancel trade',
      content: <>{`Are you sure you want to cancel trade #${abbreviateId(tradeID)}?`}</>,
      onConfirm: () => {
        request('DELETE', `${orgApiEndpoint}/customer/trades/${tradeID}`)
          .then(() => {
            addToast({
              text: `Trade ${abbreviateId(tradeID)} cancelled.`,
              variant: NotificationVariants.Positive,
            });
          })
          .catch((e: ErrorEvent) =>
            addToast({
              text: `Could not cancel trade: ${e.message}`,
              variant: NotificationVariants.Negative,
            })
          );
      },
    });
  });
  const columns = useMemo(
    () =>
      compact([
        {
          type: 'hamburgerMenu',
          id: 'rowMenu',
          params: {
            renderItems: params => (
              <CustomerTradeMenu
                {...params}
                onModifyTrade={handleModifyTrade}
                onCancelTrade={handleCancelTrade}
                onShowJson={handleClickJson}
              />
            ),
          },
        },
      ] satisfies ColumnDef<CustomerTrade>[]),
    [handleCancelTrade, handleModifyTrade, handleClickJson]
  );

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[] = useDynamicCallback(
    (params: GetContextMenuItemsParams) => {
      const customerTrade = params?.node?.data;
      if (customerTrade == null) {
        return [];
      }

      return compact([
        ...filterByCellValueMenuItem({ params, filterableProperties, openClause, colIDToFilterBuilderKey, mixpanel }),
        ...(customerTrade
          ? [
              {
                name: `Modify`,
                action: () => handleModifyTrade(customerTrade),
                disabled: isTradeCancelled(customerTrade),
              },
              {
                name: `Cancel`,
                action: () => handleCancelTrade(customerTrade),
                disabled: isTradeCancelled(customerTrade),
              },
              getShowJSONContextItem({ params, handleClickJson }),
              'separator',
            ]
          : []),
        ...getDefaultContextMenuItems(params),
      ]);
    }
  );

  return {
    getContextMenuItems,
    columns,
    dialogs,
  };
}

import {
  Button,
  FormControlSizes,
  HamburgerMenu,
  Icon,
  IconName,
  Text,
  filterByCellValueMenuItem,
  getShowJSONContextItem,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  type Column,
  type ColumnDef,
  type ExecutionReport,
  type FilterableProperty,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-enterprise';
import { isCurrentRouteMonitoringOrderDetails, isCurrentRouteOrderDetails } from 'containers/Routes/routes';
import type { GenerateOrderDetailsRoute } from 'containers/Trading/Markets/OrderDetails/types';
import { compact } from 'lodash-es';
import { useMemo, type ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigateToOrderDetails } from '../../../hooks/useNavigateToOrderDetails';
import { colIDToFilterBuilderKey } from './useExecutionReportFilter';

export function useExecutionReportMenu({
  openClause,
  filterableProperties,
  generateOrderDetailsRoute,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
  generateOrderDetailsRoute: GenerateOrderDetailsRoute;
}): {
  columns: Column[];
  getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} {
  const mixpanel = useMixpanel();
  const location = useLocation();
  const { handleClickJson, jsonModal } = useJsonModal();

  const dialogs = useMemo(() => <>{jsonModal}</>, [jsonModal]);

  const onOpenDetails = useNavigateToOrderDetails(generateOrderDetailsRoute);

  const openDetailsItem = useDynamicCallback((report: ExecutionReport) =>
    openDetailsMenuItem({
      report,
      onOpenDetails,
    })
  );

  const columns = useMemo(
    () =>
      compact([
        {
          type: 'hamburgerMenu',
          id: 'rowMenu',
          params: {
            renderItems: params => {
              return (
                <>
                  <HamburgerMenu {...params} onShowJson={handleClickJson} />
                  {!isCurrentRouteMonitoringOrderDetails(location.pathname) &&
                    !isCurrentRouteOrderDetails(location.pathname) &&
                    params.node.data && (
                      <Button
                        size={FormControlSizes.Small}
                        ghost
                        onClick={() => onOpenDetails(params.node.data.OrderID)}
                        width="100%"
                      >
                        <Text mr="spacingDefault">Open Details</Text> <Icon icon={IconName.Deepdive} />
                      </Button>
                    )}
                </>
              );
            },
          },
        },
      ] satisfies ColumnDef<ExecutionReport>[]),
    [handleClickJson, location.pathname, onOpenDetails]
  );

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const executionReport = params?.node?.data;
    if (executionReport == null) {
      return [];
    }
    return compact([
      getShowJSONContextItem({ params, handleClickJson }),
      ...filterByCellValueMenuItem({
        params,
        filterableProperties,
        openClause,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      'separator',
      !isCurrentRouteMonitoringOrderDetails(location.pathname) &&
        !isCurrentRouteOrderDetails(location.pathname) &&
        openDetailsItem(executionReport),
      ...getDefaultContextMenuItems(params),
    ]);
  });

  return {
    columns,
    getContextMenuItems,
    dialogs,
  };
}

const openDetailsMenuItem = ({
  onOpenDetails,
  report,
}: {
  onOpenDetails: ReturnType<typeof useNavigateToOrderDetails>;
  report: ExecutionReport;
}) => {
  if (!report.OrderID) {
    return null;
  }
  return {
    name: `Open details`,
    action: () => {
      onOpenDetails(report.OrderID);
    },
    icon: `<i class="ag-icon ${IconName.Deepdive}"/>`,
  };
};

import type { GridOptions } from 'ag-grid-community';
import { compact } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useDynamicCallback } from '../../hooks/useDynamicCallback';
import { nodeToRowGroupOpenedStateKey, useBlotterTableStorageById } from './BlotterTableStorageContext';
import type { RowGroupsOpenedState } from './types';

interface usePersistedRowGroupsOpenedStateParams {
  /** Whether or not to do any actual persisting. Defaults to true, can be set to false to switch it off for whatever reason. */
  persistRowGroupsOpened?: boolean;
  /** The defaultRowGroupsOpened. Provide this to start off in a specific rowGroupOpened state. */
  defaultRowGroupsOpened?: RowGroupsOpenedState;
  /**
   * If true, on first population of the blotter, will expand all groups and persist that initial opened state
   * to AppConfig. The row groups will only be initialized to all opened the first ever time the blotter is opened.
   * If defaultRowGroupsOpened is provided, that takes precedence over this parameter.
   */
  defaultAllRowGroupsOpened?: boolean;

  /**
   * If your blotter supports several types of distinct groupings and you have `defaultAllRowGroupsOpened: true`,
   * then in order to have the initial state of each grouping variant of the blotter start expanded, you need to pass
   * the groupings in here. A grouping is an array of column keys.
   */
  defaultAllRowGroupsOpenedGroupings?: string[][];
}

/**
 * Stores blotter row group open state in persisted storage (AppConfig).
 * It does this by, every time a row group is opened, the key of that opened row group is stored in AppConfig.
 * The key is built using the path from the root node to the opened group row node in question, taking the keys along the way and
 * joining the array of keys with double underscore "__".
 * An example entry could look like:
 *
 * `{ ... "1: Trading Markets__mkt/acc": true ... }`
 *
 * Where in this case "1: Trading Markets" is key 1, and key 2 is "mkt/acc".
 *
 * Is hooked up to our blotter tables by spreading the returned `blotterTableProps` onto the blotter table props.
 *
 * If you want to have a Blotter start with all row groups expanded on the very first population of data,
 * then set `defaultAllRowGroupsOpened`.
 */
export function usePersistedRowGroupsOpenedState(
  blotterID: string | null,
  {
    persistRowGroupsOpened = true,
    defaultRowGroupsOpened,
    defaultAllRowGroupsOpened,
    defaultAllRowGroupsOpenedGroupings,
  }: usePersistedRowGroupsOpenedStateParams
) {
  const { getState, setRowGroupsOpenedState } = useBlotterTableStorageById(blotterID);

  type OnRowGroupOpened = NonNullable<GridOptions['onRowGroupOpened']>;
  /**
   * Should be hooked up to the onRowGroupOpened prop of our BlotterTable component.
   * When invoked, will update the persisted blotter state using the information in the row group opened event.
   */
  const onRowGroupOpened = useCallback<OnRowGroupOpened>(
    event => {
      // Modify the existing state with this event and then set the modified state to storage
      if (blotterID != null && persistRowGroupsOpened) {
        const rowGroupsOpened = getState()?.rowGroupsOpened ?? defaultRowGroupsOpened;
        if (!rowGroupsOpened) {
          return;
        }

        const keyOfOpenedRowGroup = nodeToRowGroupOpenedStateKey(event.node);

        const newRowGroupsOpened = { ...rowGroupsOpened };
        if (event.expanded) {
          newRowGroupsOpened[keyOfOpenedRowGroup] = true;
        } else {
          delete newRowGroupsOpened[keyOfOpenedRowGroup];
        }

        setRowGroupsOpenedState?.(newRowGroupsOpened);
      }
    },
    [blotterID, persistRowGroupsOpened, getState, defaultRowGroupsOpened, setRowGroupsOpenedState]
  );

  type ExpandOrCollapseAllEvent = NonNullable<GridOptions['onExpandOrCollapseAll']>;
  /**
   * Should be hooked up to the onExpandOrCollapseAll prop of our BlotterTable component.
   * When invoked, will update the persisted blotter state using the information in the event.
   */
  const onExpandOrCollapseAll = useCallback<ExpandOrCollapseAllEvent>(
    event => {
      if (blotterID != null && persistRowGroupsOpened) {
        const activeState = getState()?.rowGroupsOpened ?? defaultRowGroupsOpened;
        if (!activeState) {
          return;
        }

        if (event.source === 'expandAll') {
          // Iterate over each group row node and set its individual value to true
          const newRowGroupsOpened: RowGroupsOpenedState = {};
          event.api.forEachNode(node => {
            if (!node.group) {
              return;
            }

            newRowGroupsOpened[nodeToRowGroupOpenedStateKey(node)] = true;
            setRowGroupsOpenedState?.(newRowGroupsOpened);
          });
        } else {
          // We're closing all so just remove all records by setting an empty object
          setRowGroupsOpenedState?.({});
        }
      }
    },
    [blotterID, defaultRowGroupsOpened, getState, persistRowGroupsOpened, setRowGroupsOpenedState]
  );

  type IsGroupOpenByDefault = NonNullable<GridOptions['isGroupOpenByDefault']>;
  /**
   * Should be hooked up to the isGroupOpenByDefault prop of our BlotterTable component.
   * Is invoked on every new group creation by the blotter, returning a boolean true or false
   * whether that group should be expanded or not.
   */
  const isGroupOpenByDefault = useCallback<IsGroupOpenByDefault>(
    params => {
      const activeState = getState()?.rowGroupsOpened ?? defaultRowGroupsOpened;
      // If nothing is persisted, then use the initial. The row groups opened state only persist when something is changed,
      // so the initial-code-path here might get hit quite a bit.
      const rowGroupsOpenedState = activeState ?? defaultRowGroupsOpened;
      if (rowGroupsOpenedState) {
        return rowGroupsOpenedState[nodeToRowGroupOpenedStateKey(params.rowNode)];
      }

      if (defaultAllRowGroupsOpened) {
        return true;
      }

      return false;
    },
    [getState, defaultRowGroupsOpened, defaultAllRowGroupsOpened]
  );

  type OnRowDataUpdated = NonNullable<GridOptions['onRowDataUpdated']>;
  /**
   * Should be hooked up to the onRowDataUpdated prop of our BlotterTable component.
   * Is invoked every time row data updates, _after_ it renders, meaning _after_ the row data has passed through `isGroupOpenByDefault`.
   *
   * This function allows us to grab the initial set of nodes in the blotter. If `defaultAllRowGroupsOpened` is true, these
   * will have been opened by `isGroupOpenByDefault`. If they were opened, we need to persist this fact to the AppConfig.
   * Once these are persisted, this function then effectively becomes a no-op going forward.
   */
  const onRowDataUpdated = useDynamicCallback<OnRowDataUpdated>(event => {
    if (blotterID == null || !defaultAllRowGroupsOpened) {
      return;
    }

    const activeState = getState()?.rowGroupsOpened ?? defaultRowGroupsOpened;
    const shouldPersistInitialRowData = activeState == null;
    if (shouldPersistInitialRowData) {
      const newRowGroupsOpened: RowGroupsOpenedState = {};

      const groupings: string[][] = defaultAllRowGroupsOpenedGroupings ?? [
        event.api.getRowGroupColumns().map(c => c.getColId()),
      ];

      event.api.forEachNode(node => {
        if (node.group) {
          // Here we only work on child nodes, since they have a properly populated data property.
          return;
        }

        for (const grouping of groupings) {
          // Here grouping is for example ["marketAccount", "asset"].
          // This rowGroupOpenedStateKeys then becomes like ["mkt/acc", "USD"]
          const rowGroupOpenedStateKeys = compact(
            grouping.map(groupingColumnKey =>
              event.api.getCellValue<unknown>({ colKey: groupingColumnKey, rowNode: node })
            )
          ).filter((value: unknown): value is string => typeof value === 'string');

          // node is a child node, and may have several parents upwards between themselves and the root of the blotter.
          // We want to create a rowGroupOpened entry for each of these layers of parents to ensure the child node is exposed.
          // We step through the rowGroupOpenedStateKeys like this, if our keys are ["mkt/acc", "USD", "28JUL23"]
          // 1. ["mkt/acc"] -> "mkt/acc": true
          // 2. ["mkt/acc", "USD"] -> "mkt/acc__USD": true
          // 3. ["mkt/acc", "USD", "28JUL23"] -> "mkt/acc__USD__28JUL23"
          // These three rowGroupOpened entries are then persisted.

          const workingKeys: string[] = [];
          rowGroupOpenedStateKeys.forEach(key => {
            workingKeys.push(key);
            const joinedKey = workingKeys.join('__');
            newRowGroupsOpened[joinedKey] = true;
          });
        }
      });

      setRowGroupsOpenedState?.(newRowGroupsOpened);
    }
  });

  /**
   * A helper function to get the current persisted state of rowGroupsOpened.
   */
  const getRowGroupsOpenedState = useCallback(() => {
    if (!blotterID || !persistRowGroupsOpened) {
      return defaultRowGroupsOpened;
    }

    return getState()?.rowGroupsOpened ?? defaultRowGroupsOpened;
  }, [blotterID, persistRowGroupsOpened, getState, defaultRowGroupsOpened]);

  // Expose grid options that are as stable as possible to make things easier for the consumer
  const gridOptionsOverlay = useMemo(
    () => ({
      isGroupOpenByDefault: persistRowGroupsOpened ? isGroupOpenByDefault : undefined,
      onRowGroupOpened,
      onExpandOrCollapseAll,
      onRowDataUpdated,
    }),
    [persistRowGroupsOpened, isGroupOpenByDefault, onRowGroupOpened, onExpandOrCollapseAll, onRowDataUpdated]
  );

  return {
    gridOptionsOverlay,
    getRowGroupsOpenedState,
  };
}

import {
  buildMoveColumnToFirstColumn,
  type Column,
  type Leaves,
  type SizeColumnParams,
  type UsePersistedOnApplyInitialStateParams,
} from '@talos/kyoko';
import type { PortfolioManagementBlotterDataItem } from '../types/PortfolioManagementBlotterDataItem';

type GetPnlColumnBase = PortfolioManagementBlotterDataItem;
/** Build common column definition for PnL columns */
export function getPnLColumn({
  id,
  title,
  field,
  currencyType,
  currencyField,
  homeCurrency,
  description,
}: {
  id?: string;
  title: string;
  field: Leaves<GetPnlColumnBase>;
  /** Specify a currency field for this field (by default it's PnlCurrency for non-currencyType home fields) */
  currencyField?: Leaves<GetPnlColumnBase>;
  /** currencyType for the column
   * - 'home' adds '(USD)' to the title
   * - quote by default uses PnlCurrency
   * - home by default uses Equivalent.Currency */
  currencyType: 'quote' | 'home';
  homeCurrency: string;
  description: string;
}) {
  const currencyFieldValue =
    currencyField ?? currencyType === 'home' ? 'position.Equivalent.Currency' : 'position.PnLCurrency';
  return {
    id,
    field,
    type: 'size',
    title: `${title}${currencyType === 'home' ? ` (${homeCurrency})` : ''}`,
    aggregate: true,
    params: {
      currencyField: currencyFieldValue,
      highlightNegative: true,
    } satisfies SizeColumnParams<GetPnlColumnBase>,
    // by default hide these columns, they're made visible via visibleColumns in usePortfolioPerformanceColumns
    hide: true,
    description:
      description == null
        ? undefined
        : `${description} ${
            currencyType === 'home'
              ? `(in home currency).`
              : `(in settlement currency for derivatives, home currency otherwise).`
          }`,
  } satisfies Column<SizeColumnParams>;
}

export function movePmsWarningsColumnToFirstColumn<R>(params: UsePersistedOnApplyInitialStateParams<R>) {
  return buildMoveColumnToFirstColumn<R>('pmsWarnings')(params);
}

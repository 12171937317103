import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { getIntlKey } from '../contexts';
import type { StrategyLike } from '../types';
import { isKeyIn } from '../utils';
import { useIntl } from './useIntl';

// Keep these ids in sync with what they were
export const strategyTranslations = defineMessages({
  limit: {
    defaultMessage: 'Limit',
    id: 'Forms.NewOrderForm.limit',
  },
  fullAmount: {
    defaultMessage: 'Full Amount',
    id: 'Forms.NewOrderForm.fullAmount',
  },
  market: {
    defaultMessage: 'Market',
    id: 'Forms.NewOrderForm.market',
  },
  steadyPace: {
    defaultMessage: 'Steady Pace',
    id: 'Forms.NewOrderForm.steadyPace',
  },
  stopFullAmount: {
    defaultMessage: 'Stop Full Amount',
    id: 'Forms.NewOrderForm.stopFullAmount',
  },
  multilegAggressiveLimit: {
    defaultMessage: 'Limit',
    id: 'Forms.NewOrderForm.multilegAggressiveLimit',
  },
  stopLimit: {
    defaultMessage: 'Stop Limit',
    id: 'Forms.NewOrderForm.stopLimit',
  },
  timeSliced: {
    defaultMessage: 'Time Sliced',
    id: 'Forms.NewOrderForm.timeSliced',
  },
  takeProfitFullAmount: {
    defaultMessage: 'Take Profit Full Amount',
    id: 'Forms.NewOrderForm.takeProfitFullAmount',
  },
  takeProfitLimit: {
    defaultMessage: 'Take Profit Limit',
    id: 'Forms.NewOrderForm.takeProfitLimit',
  },
  TWAP: {
    defaultMessage: 'TWAP',
    id: 'Forms.NewOrderForm.TWAP',
  },
  stopMarket: {
    defaultMessage: 'Stop Market',
    id: 'Forms.NewOrderForm.stopMarket',
  },
  rfq: {
    defaultMessage: 'RFQ',
    id: 'Forms.NewOrderForm.rfq',
  },
} as const);

export function useGetStrategyLikeLabel() {
  const intl = useIntl();

  return useCallback(
    (s: StrategyLike) => {
      if (intl.preferServerEnglishText) {
        return s.DisplayName || s.Name;
      }
      const intlKey = getIntlKey(s.Name);

      if (isKeyIn(intlKey, strategyTranslations)) {
        return intl.formatMessage(strategyTranslations[intlKey]);
      }

      return s.DisplayName || s.Name;
    },
    [intl]
  );
}

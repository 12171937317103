import {
  ConnectionModeEnum,
  ConnectionStatusEnum,
  IconName,
  ProductTypeEnum,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  productTypeLabel,
  useSecuritiesContext,
  useSymbolsFilter,
  useTradingMarketAccountsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type DateRangeFilter,
  type FilterableProperty,
} from '@talos/kyoko';
import { values } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useFeatureFlag, useMarketsFilter } from '../../../hooks';
import type { MarketSecurityStatusLocal } from './types';

const FilterableProductTypesArray = [
  ProductTypeEnum.Spot,
  ProductTypeEnum.Future,
  ProductTypeEnum.PerpetualSwap,
  ProductTypeEnum.Option,
];

export interface SecurityMasterFilter extends DateRangeFilter {
  Statuses?: ConnectionStatusEnum[];
  MarketDataEnableds?: string[];
  Enableds?: ConnectionModeEnum[];
  MarketAccounts?: string[];
  Symbols?: string[];
  Markets?: string[];
  ProductTypes?: (typeof FilterableProductTypesArray)[];
  Available?: string;
}

export const useSecurityMasterFilter = ({
  initialFilter,
  saveFilter,
}: {
  initialFilter?: SecurityMasterFilter;
  saveFilter: (newFilter: any) => void;
}) => {
  const [filter, setFilter] = useState(() => cleanupInitialFilterDateRange(initialFilter));
  const { securitiesBySymbol } = useSecuritiesContext();
  const { enableCalendarSpreads, enableCFDs } = useFeatureFlag();

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const newFilter = action instanceof Function ? action(filter) : action;
      setFilter(newFilter);
      saveFilter(newFilter);
    },
    [filter, saveFilter]
  );

  const filterFunc = useCallback<BlotterTableClientLocalFilter<MarketSecurityStatusLocal>>(
    row => {
      const data = row.data;
      if (!data) {
        return false;
      }
      const security = securitiesBySymbol.get(data.Symbol);
      if (security && filterExistsAndExcludes(filter, 'ProductTypes', security, 'ProductType')) {
        return false;
      }
      // ProductType filtering is driven by data accessed on the security matching row symbol,
      // if the security is undefined, it has been deleted.
      // We decide to not show it when filtering on product type, since it is not matching (it is undefined)
      if (!security && filter['ProductTypes'] && filter['ProductTypes'] instanceof Array) {
        return false;
      }
      if (filter['MarketDataEnableds'] && filter['MarketDataEnableds'] instanceof Array) {
        if (data['_capabilities'].includes('MarketData') && !filter['MarketDataEnableds'].includes('true')) {
          return false;
        }
        if (!data['_capabilities'].includes('MarketData') && !filter['MarketDataEnableds'].includes('false')) {
          return false;
        }
      }

      if (filterExistsAndExcludes(filter, 'Statuses', data, 'Status')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Enableds', data, 'Enabled')) {
        return false;
      }

      if (filterExistsAndExcludes(filter, 'Available', data, '_marketEnabled')) {
        return false;
      }

      return true;
    },
    [filter, securitiesBySymbol]
  );

  const filterableProductTypesArray = useMemo(() => {
    const result = [...FilterableProductTypesArray];
    if (enableCalendarSpreads) {
      result.push(ProductTypeEnum.CalendarSpread);
    }
    if (enableCFDs) {
      result.push(ProductTypeEnum.CFD);
    }
    return result;
  }, [enableCalendarSpreads, enableCFDs]);

  const tradingMarketAccountsFilter = useTradingMarketAccountsFilter();
  const symbolFilter = useSymbolsFilter();
  const marketsFilter = useMarketsFilter({ onlyTradingMarkets: true });

  const filterableProperties: FilterableProperty[] = useMemo(
    () =>
      [
        tradingMarketAccountsFilter,
        marketsFilter,
        {
          key: 'Statuses',
          label: 'Status',
          icon: IconName.CheckCircle,
          options: values(ConnectionStatusEnum),
          getOptionLabel: (option: string) => option,
        },
        {
          key: 'Enableds',
          label: 'Enabled',
          icon: IconName.CheckCircle,
          options: values(ConnectionModeEnum),
          getOptionLabel: (option: string) => option,
        },
        {
          key: 'Available',
          label: 'Available',
          icon: IconName.CheckCircle,
          options: values(ConnectionModeEnum),
          getOptionLabel: (option: string) => option,
        },
        {
          key: 'MarketDataEnableds',
          label: 'Market Data Enabled',
          icon: IconName.CheckCircle,
          options: ['true', 'false'],
          getOptionLabel: (option: string) => (option === 'true' ? 'enabled' : 'disabled'),
        },
        {
          key: 'ProductTypes',
          label: 'Product Type',
          icon: IconName.CubeTransparent,
          options: filterableProductTypesArray,
          getOptionLabel: (option: string) => productTypeLabel(option as ProductTypeEnum),
        },
        symbolFilter,
      ].compact(),
    [tradingMarketAccountsFilter, marketsFilter, symbolFilter, filterableProductTypesArray]
  );

  return {
    filter,
    filterableProperties,
    changeFilter,
    filterFunc,
  };
};

export function colIDToFilterBuilderKey(id: string): keyof SecurityMasterFilter | undefined {
  switch (id as keyof MarketSecurityStatusLocal) {
    case 'Symbol':
      return 'Symbols';
    case 'MarketAccount':
      return 'MarketAccounts';
    case 'Market':
      return 'Markets';
    case 'Status':
      return 'Statuses';
    case 'RequestedEnabled':
      return 'Enableds';
    case '_capabilities':
      return 'MarketDataEnableds';
    case '_marketEnabled':
      return 'Available';
  }
  return undefined;
}

import { logger, type ValueOf } from '@talos/kyoko';
import type { NotificationAppInboxVariants } from '@talos/kyoko/src/components/Notification/types';
import type { AppNotification } from './AppNotifications';

export function notificationSeverityToToastVariant(
  severity: AppNotification['Severity']
): ValueOf<typeof NotificationAppInboxVariants> {
  switch (severity) {
    case 'High':
      return 'HIGH_PRIORITY';
    case 'Medium':
      return 'MEDIUM_PRIORITY';
    case 'Informational':
      return 'DEFAULT';
    default:
      logger.warn(`Unknown notification severity: ${severity}.  Defaulting to Low`);
      return 'DEFAULT';
  }
}

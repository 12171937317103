import {
  AccordionGroup,
  AccordionRow,
  AccordionRowBody,
  AccordionRowBodyCell,
  AccordionRowCell,
  AccordionRowHeader,
  AccordionTable,
  Box,
  Button,
  ButtonVariants,
  Dialog,
  FormControlSizes,
  FormGroup,
  HStack,
  IconButton,
  IconName,
  Input,
  LoaderSizes,
  NotificationVariants,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  SearchSelect,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UpdateActionEnum,
  VStack,
  formattedDate,
  useAccordion,
  useConstant,
  useDisclosure,
  useGlobalToasts,
  type DialogProps,
  type User,
} from '@talos/kyoko';
import { sortBy, uniqBy } from 'lodash-es';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useAsync, useAsyncFn } from 'react-use';

import { Loader, LoaderWrapper } from 'components/Loader';
import { useUsers } from 'hooks/useUsers';
import { useUserGroups } from 'providers';
import type { UserGroup } from 'types';

interface AddMemberItem {
  value: string;
  label: string;
}

const COLUMNS = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'timestamp',
    label: 'Updated At',
  },
];

const CreateUserGroupDialog = forwardRef<HTMLDivElement | null, DialogProps>(({ onConfirm, ...props }, ref) => {
  const { createUserGroup } = useUserGroups();
  const [name, setName] = useState('');
  const [state, handleConfirm] = useAsyncFn(() => {
    setName('');
    return createUserGroup({ DisplayName: name }).then(onConfirm);
  }, [onConfirm, name]);

  const [error, setError] = useState<string>();
  const handleChange = useCallback(e => {
    setName(e.target.value);
    if (e.target.value !== '') {
      setError(undefined);
    }
  }, []);
  const handleBlur = useCallback(() => setError(name === '' ? 'Please fill out a name' : undefined), [name]);

  return (
    <Dialog
      {...props}
      onConfirm={handleConfirm}
      confirmDisabled={name === ''}
      confirmLoading={state.loading}
      ref={ref}
      stretchButtons
    >
      <FormGroup label="Name" help="The name of your user group" error={error}>
        <Input value={name} onChange={handleChange} onBlur={handleBlur} />
      </FormGroup>
    </Dialog>
  );
});

const UserGroupAccordionRow = function UserGroupAccordionRow({
  userGroup,
  handleDelete,
  handleSave,
}: {
  userGroup: UserGroup;
  handleDelete: any;
  handleSave: any;
}) {
  const accordion = useAccordion({ id: userGroup.GroupID });
  return (
    <AccordionRow {...accordion}>
      <AccordionRowHeader>
        <AccordionRowCell>{userGroup.DisplayName}</AccordionRowCell>
        <AccordionRowCell>{formattedDate(userGroup.Timestamp)}</AccordionRowCell>
      </AccordionRowHeader>
      <AccordionRowBody>
        <tr>
          <AccordionRowBodyCell colSpan={3}>
            <UserGroupDetails
              userGroup={userGroup}
              onDelete={handleDelete}
              onSave={handleSave}
              onClose={() => accordion.close()}
            />
          </AccordionRowBodyCell>
        </tr>
      </AccordionRowBody>
    </AccordionRow>
  );
};

const UserGroupDetails = ({
  userGroup,
  onDelete,
  onSave,
  onClose,
}: {
  userGroup: UserGroup;
  onDelete: any;
  onSave: any;
  onClose: any;
}) => {
  const users = useUsers();
  const { deleteUserGroup, listMemberships } = useUserGroups();
  const [displayName, setDisplayName] = useState<string>(userGroup.DisplayName);
  const [rowData, setRowData] = useState<User[] | undefined>();
  const [didEdit, setDidEdit] = useState<boolean>(false);

  const [addedMembers, setAddedMembers] = useState<User[]>([]);
  const [deletedMembers, setDeletedMembers] = useState<User[]>([]);

  useEffect(() => {
    listMemberships(userGroup.GroupID).then(response => {
      if (response.data) {
        setRowData(
          response.data.map(
            membership =>
              users.find(u => u.Name === membership.User) || {
                ID: null,
                Name: null,
              }
          )
        );
      } else {
        setRowData([]);
      }
    });
  }, [listMemberships, users, userGroup.GroupID]);

  const handleDeleteMember = useCallback(user => {
    setDeletedMembers(prev => uniqBy([...prev, user], user => user.ID));
    setRowData(prev => (prev ?? []).filter(u => u.ID !== user.ID));
    setItems(prev =>
      sortBy(
        [
          ...prev,
          {
            value: user.ID,
            label: user.Name,
          },
        ],
        item => item.label
      )
    );
    setDidEdit(true);
  }, []);

  const handleAddMember = useCallback(
    item => {
      const user = users.find(user => user.ID === item.value)!;
      setAddedMembers(prev => uniqBy([...prev, user], user => user.ID));
      setRowData(prev => [...(prev ?? []), user]);
      setDidEdit(true);
    },
    [users]
  );

  const handleChangeDisplayName = useCallback(e => {
    setDisplayName(e.target.value);
    setDidEdit(true);
  }, []);

  const [saveState, handleSave] = useAsyncFn(async () => {
    try {
      await onSave(
        { ...userGroup, displayName },
        addedMembers.map(u => ({ GroupID: userGroup.GroupID, User: u.Name })),
        deletedMembers.map(u => ({ GroupID: userGroup.GroupID, User: u.Name }))
      );
      setAddedMembers([]);
      setDeletedMembers([]);
    } catch (e) {
      // Do nothing
    }
  }, [userGroup, onSave, displayName, addedMembers, deletedMembers]);

  const [deleteState, handleDelete] = useAsyncFn(() => deleteUserGroup(userGroup.GroupID).then(onDelete), [onDelete]);

  const deleteGroupDialog = useDisclosure();

  const [items, setItems] = useState<AddMemberItem[]>(() =>
    sortBy(
      users.map(user => ({
        value: user.ID!,
        label: user.Name!,
      })),
      item => item.label
    )
  );

  const getLabel = useConstant((item: AddMemberItem) => item.label);

  return (
    <Box pt="spacingLarge" pb="spacingLarge">
      <FormGroup label="Group name">
        <Input value={displayName || ''} onChange={handleChangeDisplayName} />
      </FormGroup>
      <HStack justifyContent="space-between">
        <h3>Members</h3>
        <FormGroup>
          <Box w="200px">
            <SearchSelect<AddMemberItem>
              options={items}
              getLabel={getLabel}
              onChange={selectedItem => {
                if (selectedItem != null) {
                  handleAddMember(selectedItem);
                  setItems(prev => prev.filter(item => item.value !== selectedItem.value));
                }
              }}
              size={FormControlSizes.Small}
            />
          </Box>
        </FormGroup>
      </HStack>
      {rowData == null ? (
        <LoaderWrapper style={{ height: '200px' }}>
          <Loader size={LoaderSizes.SMALL} />
        </LoaderWrapper>
      ) : (
        <Table w="100%" bordered={true}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>&nbsp;</Th>
            </Tr>
          </Thead>
          <Tbody>
            {rowData.map(row => (
              <Tr key={row.ID}>
                <Td width="100%">{row.Name}</Td>
                <Td>
                  <IconButton
                    icon={IconName.Trash}
                    ghost={true}
                    size={FormControlSizes.Small}
                    onClick={() => handleDeleteMember(row)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
      <HStack justifyContent="space-between" mt="spacingMedium">
        <HStack>
          {onDelete && (
            <Button variant={ButtonVariants.Negative} onClick={() => deleteGroupDialog.open()}>
              Delete
            </Button>
          )}
        </HStack>
        <HStack gap="spacingDefault">
          <Button onClick={onClose}>Close</Button>
          {onSave && (
            <Button disabled={!didEdit || saveState.loading} variant={ButtonVariants.Primary} onClick={handleSave}>
              Save
            </Button>
          )}
        </HStack>
      </HStack>
      <Dialog
        {...deleteGroupDialog}
        confirmLabel="Yes"
        cancelLabel="No"
        onConfirm={handleDelete}
        confirmLoading={deleteState.loading}
        variant={ButtonVariants.Negative}
        showClose={true}
        title="Delete user group"
      >
        <VStack w="100%" gap="spacingDefault">
          <Text>
            Are you sure you want to delete the group <strong>{userGroup.DisplayName}</strong>?
          </Text>
          <Text>Note that all trading limits associated with this group will be deleted too.</Text>
        </VStack>
      </Dialog>
    </Box>
  );
};

export const UserGroups = function UserGroups() {
  const { listUserGroups, updateUserGroup, batchUpdateMemberships } = useUserGroups();
  const { value: initialUserGroups } = useAsync(listUserGroups, []);
  const [userGroups, setUserGroups] = useState<UserGroup[] | undefined>();
  const { add } = useGlobalToasts();

  useEffect(() => {
    if (initialUserGroups) {
      setUserGroups(initialUserGroups.data || []);
    }
  }, [initialUserGroups]);

  const createUserGroupDialog = useDisclosure();

  const handleSave = useCallback(
    (userGroup, addedMembers, deletedMembers) => {
      return Promise.all([
        updateUserGroup(userGroup.GroupID, {
          GroupID: userGroup.GroupID,
          DisplayName: userGroup.DisplayName,
        }),
        batchUpdateMemberships(
          userGroup.GroupID,
          UpdateActionEnum.Update,
          addedMembers.map(m => ({
            GroupID: userGroup.GroupID,
            User: m.User,
          }))
        ),
        batchUpdateMemberships(
          userGroup.GroupID,
          UpdateActionEnum.Remove,
          deletedMembers.map(m => ({
            GroupID: userGroup.GroupID,
            User: m.User,
          }))
        ),
      ])
        .then(() => listUserGroups())
        .then(updated => {
          setUserGroups(updated.data || []);
          add({
            text: `User group "${userGroup.DisplayName}" saved.`,
            variant: NotificationVariants.Positive,
          });
        })
        .catch(e => {
          add({
            text: `Could not save user group: ${e.message}`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [add, batchUpdateMemberships, listUserGroups, updateUserGroup]
  );

  const handleDelete = useCallback(deleted => {
    setUserGroups(prev => (prev ?? []).filter(group => group.GroupID !== deleted.data[0].GroupID));
  }, []);

  if (userGroups == null) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <Panel>
      <PanelHeader>
        <h2>User Groups</h2>
        <PanelActions>
          <Button
            startIcon={IconName.Plus}
            variant={ButtonVariants.Primary}
            onClick={() => createUserGroupDialog.open()}
          >
            Add User Group
          </Button>
        </PanelActions>
      </PanelHeader>
      <PanelContent>
        <AccordionGroup>
          <AccordionTable columns={COLUMNS}>
            {userGroups.map(userGroup => (
              <UserGroupAccordionRow
                userGroup={userGroup}
                key={userGroup.GroupID}
                handleDelete={handleDelete}
                handleSave={handleSave}
              />
            ))}
          </AccordionTable>
        </AccordionGroup>
      </PanelContent>
      <CreateUserGroupDialog
        {...createUserGroupDialog}
        title="Create User Group"
        showClose={true}
        confirmLabel="Create"
        width={300}
        alignContent="left"
        onConfirm={response =>
          setUserGroups(prev => [
            ...(prev ?? []),
            {
              ...response.data[0],
              users: [],
            },
          ])
        }
      />
    </Panel>
  );
};

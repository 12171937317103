import { isNil } from 'lodash-es';
import { useCallback, useContext, type RefCallback } from 'react';
import { PortalContext } from '../contexts/PortalContext';

export function usePortal<TElement extends HTMLElement = HTMLElement>(
  portalId: string
): { portalRef: HTMLElement | null; setPortalRef: RefCallback<TElement> } {
  const context = useContext(PortalContext);
  if (isNil(context)) {
    throw new Error(`Missing PortalContext.Provider further up in the tree. Did you forget to add it?`);
  }
  const { getElement, setElement } = context;
  const portalRef = getElement(portalId);

  const setPortalRef: RefCallback<TElement> = useCallback(
    node => {
      setElement(portalId, node);
    },
    [portalId, setElement]
  );

  return { portalRef, setPortalRef };
}

import { BLOTTER_TABLE_FILTERS_CONTAINER_ID, Box, Tab, TabList, Tabs, TabSize, usePortal } from '@talos/kyoko';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import type { OrderDetailsRoute } from './types';

export const MemoizedTabs = memo(function MemoizedTabs({
  selectedTabIndex,
  matchUrl,
  onSelectTab,
  routes,
  useLink,
}: {
  useLink: boolean;
  selectedTabIndex: number;
  matchUrl: string | undefined;
  onSelectTab: (index: number) => void;
  routes: OrderDetailsRoute[];
}) {
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  return (
    <Tabs
      selectedIndex={selectedTabIndex}
      onSelect={onSelectTab}
      mb="-1px"
      w="100%"
      flex="0 0 auto"
      data-testid="order-details-tabs"
      size={TabSize.Large}
      background="gray.020"
    >
      <TabList rightItems={<Box ref={filtersContainerRef} />}>
        {routes.map(route => {
          return (
            <Tab
              id={route.path}
              key={route.path}
              label={route.label}
              forwardedAs={useLink ? Link : undefined}
              to={`${matchUrl}/${route.path}`}
            />
          );
        })}
      </TabList>
    </Tabs>
  );
});

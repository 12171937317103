import {
  ControlPrefix,
  DateRangePicker,
  FormControlSizes,
  LookbackOption,
  TimePickerPrecision,
  formattedDateForSubscription,
  useDynamicCallback,
  type DateRange,
  type LookbackWindow,
  type ShortcutOption,
} from '@talos/kyoko';
import { values } from 'lodash-es';
import { useContextBlotterFilter } from 'providers/ContextBlotterFilterProvider/useContextBlotterFilter';
import { useCallback, useEffect, useMemo } from 'react';
import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import { PERIODS, isPeriodCustomPeriod, periodToChartDateRange, periodToLookbackEquivalent } from '../types';

const SHORTCUT_OPTIONS: ShortcutOption[] = [
  { value: LookbackOption.Today },
  { value: LookbackOption.WeekToDate },
  { value: LookbackOption.MonthToDate },
  { value: LookbackOption.YearToDate },
  { value: LookbackOption.LifeToDate },
  { value: LookbackOption.Past24Hours },
  { value: LookbackOption.PastWeek },
  { value: LookbackOption.Past30Days },
  { value: LookbackOption.PastMonth },
  { value: LookbackOption.Past90Days },
  { value: LookbackOption.PastYear },
];

function getGroup(option: ShortcutOption): string | undefined {
  switch (option.value) {
    case LookbackOption.Today:
    case LookbackOption.WeekToDate:
    case LookbackOption.MonthToDate:
    case LookbackOption.YearToDate:
    case LookbackOption.LifeToDate:
      return 'To Date';
    case LookbackOption.Past24Hours:
    case LookbackOption.PastWeek:
    case LookbackOption.Past30Days:
    case LookbackOption.PastMonth:
    case LookbackOption.Past90Days:
    case LookbackOption.PastYear:
      return 'Rolling';
    default:
      return undefined;
  }
}

export const PeriodControl = () => {
  const {
    state: { period },
  } = usePerformanceContext();

  // Convert the selected period to what DateRangePicker wants
  const dateRangePickerValue: DateRange | LookbackWindow = useMemo(() => {
    // If we have a period selected, we grab the equivalent lookback (which is what the DateRangePicker understands),
    // and use forward that.
    const maybeLookback = periodToLookbackEquivalent(period);
    if (maybeLookback) {
      return { lookback: maybeLookback } satisfies LookbackWindow;
    }

    // Otherwise we have a custom period, and we grab the chart date ranges (from and to dates)
    const { StartDate, EndDate } = periodToChartDateRange(period);
    if (!StartDate) {
      return { from: null, to: null };
    }

    return {
      from: new Date(StartDate),
      to: EndDate ? new Date(EndDate) : null,
    };
  }, [period]);

  const getLabel = useDynamicCallback((value: DateRange | LookbackWindow) => {
    // This is just me using the performance state instead to populate the label, and if its a CustomPeriod
    // we just return undefined, which allows the DateRangePicker to take over
    if (isPeriodCustomPeriod(period)) {
      return undefined;
    }

    return PERIODS[period].label;
  });

  const { showDateRangeFilter, handleDateRangeChange } = useDateRangeUpdate();

  return (
    <>
      {showDateRangeFilter && (
        <DateRangePicker
          value={dateRangePickerValue}
          onChange={handleDateRangeChange}
          shortcuts={SHORTCUT_OPTIONS}
          maxTimePickerPrecision={TimePickerPrecision.MINUTE}
          size={FormControlSizes.Small}
          prefix={<ControlPrefix>Period</ControlPrefix>}
          getLabel={getLabel}
          getGroup={getGroup}
          clearable={false}
          timePickerVariant="selector"
          timeSelectorIntervalMinutes={15}
        />
      )}
    </>
  );
};

function isLookbackWindow(value: DateRange | LookbackWindow): value is LookbackWindow {
  return 'lookback' in value;
}

function useDateRangeUpdate() {
  const { additionalFilterState } = useContextBlotterFilter();
  const externalDateRangeFilter = additionalFilterState && 'DateRange' in additionalFilterState;

  const { dispatch } = usePerformanceContext();
  const handleDateRangeChange = useCallback(
    (value: DateRange | LookbackWindow) => {
      if (isLookbackWindow(value)) {
        const equivalentPeriod = values(PERIODS).find(period => period.lookbackEquivalent === value.lookback);
        if (!equivalentPeriod) {
          return;
        }

        dispatch({
          type: PerformanceActionType.PeriodChange,
          payload: {
            period: equivalentPeriod.period,
          },
        });
      } else {
        if (value.from == null) {
          return;
        }
        // we receive a from and to (CustomPeriod)
        dispatch({
          type: PerformanceActionType.PeriodChange,
          payload: {
            period: {
              StartDate: formattedDateForSubscription(value.from),
              EndDate: value.to ? formattedDateForSubscription(value.to) : undefined,
            },
          },
        });
      }
    },
    [dispatch]
  );

  // If we're connected to an external date filter from ContextBlotterFilterProvider,
  // trigger the callback to update the state (this value should never be undefined if it's set)
  useEffect(() => {
    if (additionalFilterState?.DateRange) {
      handleDateRangeChange(additionalFilterState.DateRange);
    }
  }, [additionalFilterState?.DateRange, handleDateRangeChange]);

  return { showDateRangeFilter: !externalDateRangeFilter, handleDateRangeChange };
}

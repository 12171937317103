import { IconName, type LedgerAccountTypeEnum, type MixpanelEventSource } from '@talos/kyoko';
import type { MenuItemDef } from 'ag-grid-community';
import { useCallback } from 'react';
import { useNavigateToAccountLedgerEvents } from './useNavigateToAccountLedgerEvents';

/** A hook returning a function to call which returns AgGrid MenuItemDefs for navigating to account and position ledger event pages */
export function useGetOpenTransactionsMenuItems() {
  const navigateToAccountLedgerEvents = useNavigateToAccountLedgerEvents();

  return useCallback(
    ({
      account,
      asset,
      type,
      source,
      showAccountOption = false,
      name,
    }: {
      account: number | string | undefined;
      asset?: string;
      type: LedgerAccountTypeEnum | undefined;
      /** If true, will add a _second_ option to open the Account Ledger Events page for the entire account (asset is unused) */
      showAccountOption?: boolean;
      source?: MixpanelEventSource;
      name?: string;
    }) => {
      const items: MenuItemDef[] = [];
      if (showAccountOption && account != null && type != null) {
        items.push({
          name: name ?? `Open account transactions`,
          action: () => {
            navigateToAccountLedgerEvents({
              account,
              type,
              source,
            });
          },
          icon: `<i class="ag-icon ${IconName.ViewListDrilldown}"/>`,
        });
      }

      if (account != null && type != null && asset != null) {
        items.push({
          name: name ?? (showAccountOption ? `Open instrument transactions` : `Open transactions`),
          action: () => {
            navigateToAccountLedgerEvents({
              account,
              asset,
              type,
              source,
            });
          },
          icon: `<i class="ag-icon ${IconName.ViewListDrilldown}"/>`,
        });
      }

      return items;
    },
    [navigateToAccountLedgerEvents]
  );
}

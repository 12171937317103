import { ACTION, IconName, type Column, type User } from '@talos/kyoko';
import type { IRowNode } from 'ag-grid-enterprise';
import { compact, sortBy } from 'lodash-es';
import { useMemo } from 'react';
import { useRoleAuth } from '../../../hooks';
import { prettifyRole } from '../../../tokens/roles';
import type { UseUsersBlotterTableMenu } from './UsersBlotterTableMenu';

interface UseUsersListColumns {
  availableRoles: string[];
  showDeletedUsers: boolean;
  handleEditUser: (user: User) => void;
  usersBlotterTableMenu: UseUsersBlotterTableMenu;
}

export const useColumns = ({
  availableRoles,
  showDeletedUsers,
  handleEditUser,
  usersBlotterTableMenu,
}: UseUsersListColumns) => {
  const { isAuthorized } = useRoleAuth();

  return useMemo<Column[]>(
    () =>
      compact([
        {
          type: 'text',
          field: 'ID',
          width: 80,
        },
        {
          type: 'text',
          field: 'Name',
          sortable: true,
        },
        {
          type: 'text',
          field: 'Email',
          sortable: true,
        },
        {
          id: 'roles',
          type: 'custom',
          title: 'Roles',
          width: 250,
          params: {
            valueGetter: ({ data }) => {
              if (data.Roles == null) {
                return '';
              }
              return sortBy(data.Roles)
                .filter(role => role !== '' && availableRoles.includes(role))
                .map(role => prettifyRole(role))
                .join(', ');
            },
          },
          sortable: true,
        },
        {
          type: 'custom',
          id: 'tradeSubAccounts',
          title: 'Trade Sub Accounts',
          params: {
            valueGetter: ({ data }) => {
              return data?.tradeSubAccounts;
            },
          },
        },
        {
          type: 'custom',
          id: 'viewSubAccounts',
          title: 'View Sub Accounts',
          params: {
            valueGetter: ({ data }) => {
              return data?.viewSubAccounts;
            },
          },
        },
        {
          id: 'hasAPIKey',
          type: 'custom',
          title: 'Has API Key',
          params: {
            valueGetter: ({ data }) => Boolean(data?.HasAPIKey),
            valueFormatter: ({ data }) => (data?.HasAPIKey ? 'Yes' : 'No'),
          },
        },
        {
          type: 'date',
          field: 'LastActive',
          sortable: true,
        },
        showDeletedUsers && {
          type: 'date',
          field: 'DeletedAt',
          sortable: true,
        },
        {
          id: 'edit',
          type: 'iconButton',
          pinned: 'right',
          width: 60,
          suppressColumnsToolPanel: true,
          params: {
            onClick: ({ node }: { node: IRowNode }) => handleEditUser(node.data),
            icon: IconName.Pencil,
            hide: ({ node }) => Boolean(node.data.DeletedAt),
          },
        },
        isAuthorized(ACTION.EDIT_USERS) ? usersBlotterTableMenu.column : null,
      ]),
    [showDeletedUsers, isAuthorized, usersBlotterTableMenu.column, availableRoles, handleEditUser]
  );
};

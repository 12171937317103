import { Button, VStack } from '@talos/kyoko';
import type { DockviewApi } from 'dockview';

export function LayoutError({
  containerApi,
  enablePopoutWindows,
  handleReset,
}: {
  containerApi?: DockviewApi;
  enablePopoutWindows?: boolean;
  handleReset: () => void;
}) {
  if (!containerApi || containerApi.size === 0) {
    return (
      <VStack background="gray.010" h="100%" w="100%" textAlign="center">
        <p>An unexpected error occurred with the layout.</p>
        <p>
          If this issue persists, please get in touch with Talos support and we will resolve the problem as soon as
          possible.
        </p>
        <Button onClick={handleReset}>Reset layout</Button>
        <p>
          <a href="mailto:support@talos.com">support@talos.com</a>
        </p>
        <p>{import.meta.env.VITE_SUPPORT_PHONE}</p>
      </VStack>
    );
  } else if (enablePopoutWindows) {
    return (
      <VStack background="gray.010" h="100%" w="100%" textAlign="center">
        <h3>This group is empty</h3>
        <p>This may happened if your browser blocks popups and this group was opened in a popout window.</p>
        You can allow popups for this site by clicking the icon in the address bar or using the browser settings.
      </VStack>
    );
  } else {
    return (
      <VStack background="gray.010" h="100%" w="100%" textAlign="center">
        <h3>This group is empty</h3>You can add content to this group using the plus icon
      </VStack>
    );
  }
}

import { createContext, useContext, useEffect, useMemo, useReducer, type PropsWithChildren } from 'react';
import {
  PositionsActionType,
  positionsFormReducer,
  type PositionsAction,
  type PositionsFormState,
} from '../DealerSettlement/PositionsFormReducer';
import { useRelativeMarketPositions } from '../DealerSettlement/useRelativeMarketPositions';

export const PositionsFormContext = createContext({});
export const PositionsFormDispatchContext = createContext({});
PositionsFormDispatchContext.displayName = 'PositionsFormDispatchContext';

export function usePositionsFormContext() {
  const context = useContext(PositionsFormContext);
  if (context === undefined) {
    throw new Error('Missing PositionsFormContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context as { state: PositionsFormState; dispatch: React.Dispatch<PositionsAction> }; // we can guarantee this logically
}

// This separate dispatch-only provider is necessary because on each action, the state is renewed.
// This means that all components dependent on the usePositionsFormContext will re-render.
// However, it will also make dependents which only want access to dispatch rerender as well, despite dispatch not changing.
// So this allows us to improve performance.
export function usePositionsFormDispatch() {
  const context = useContext(PositionsFormDispatchContext);
  if (context === undefined) {
    throw new Error('Missing PositionsFormContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context as React.Dispatch<PositionsAction>; //{ state: PositionsFormState; dispatch: React.Dispatch<PositionsAction> }; // we can guarantee this logically
}

export const PositionsFormProvider = function PositionsFormProvider({ children }: PropsWithChildren) {
  const [state, dispatch] = useReducer(positionsFormReducer, new Map());

  const marketPositions = useRelativeMarketPositions();

  const value = useMemo(() => {
    return {
      state,
      dispatch,
    };
  }, [state, dispatch]);

  useEffect(() => {
    // as market positions change, apply changes to the reducer state
    if (marketPositions == null) {
      return;
    }
    dispatch({
      type: PositionsActionType.WSMarketPositionsChanged,
      payload: {
        marketPositions: marketPositions,
      },
    });
  }, [marketPositions, dispatch]);

  return (
    <PositionsFormContext.Provider value={value}>
      <PositionsFormDispatchContext.Provider value={dispatch}>{children}</PositionsFormDispatchContext.Provider>
    </PositionsFormContext.Provider>
  );
};

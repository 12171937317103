import { removeEmptyFilters } from '@talos/kyoko';
import { isEqual, pick } from 'lodash-es';
import { createContext, useContext, useReducer, type ReactNode } from 'react';
import { useUpdateEffect } from 'react-use';
import type { SettlementMonitoringTab } from '../../tabs';
import type { SettlementMonitoringState } from '../types';

/**
 * Get reducer (state) related data for the Performance page.
 */
export function useSettlementMonitoringState() {
  const context = useContext(SettlementMonitoringStateContext);
  if (context === undefined) {
    throw new Error(
      'Missing SettlementMonitoringStateContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context as { state: SettlementMonitoringState; dispatch: React.Dispatch<SettlementMonitoringAction> }; // we can guarantee this logically
}

const SettlementMonitoringStateContext = createContext({});

export const SettlementMonitoringStateProvider = ({
  children,
  tab,
  onUpdateTab,
}: {
  children: ReactNode;
  tab: SettlementMonitoringTab;
  onUpdateTab: (updatedTab: SettlementMonitoringTab) => void;
}) => {
  const [state, dispatch] = useReducer(settlementMonitoringStateReducer, tabToState(tab));

  useUpdateEffect(() => {
    // Whenever a non-initial change (useUpdateEffect) happens to our state, we emit that change upwards as a tab change.
    // We here also map the reducer state back to tab state.
    if (!isEqual(tabToState(tab), state)) {
      onUpdateTab(applyNewStateToTab(tab, state));
    }
  }, [state]);

  return (
    <SettlementMonitoringStateContext.Provider value={{ state, dispatch }}>
      {children}
    </SettlementMonitoringStateContext.Provider>
  );
};

function tabToState(tab: SettlementMonitoringTab): SettlementMonitoringState {
  return pick(tab, ['groupBy', 'filter', 'counterpartyType', 'summaryOpen', 'groupByCounterparty']);
}

function applyNewStateToTab(tab: SettlementMonitoringTab, state: SettlementMonitoringState): SettlementMonitoringTab {
  // For now this simple approach works because there's a complete overlap in this direction (state -> tab)
  return {
    ...tab,
    ...state,
  };
}

export enum SettlementMonitoringActionType {
  GroupByChange,
  FilterChange,
  GroupByCounterpartyChange,
}

interface GroupByChangeAction {
  type: SettlementMonitoringActionType.GroupByChange;
  payload: {
    groupBy: SettlementMonitoringState['groupBy'];
  };
}

interface GroupByChangeCounterpartyAction {
  type: SettlementMonitoringActionType.GroupByCounterpartyChange;
  payload: {
    groupByCounterparty: SettlementMonitoringState['groupByCounterparty'];
  };
}

interface FilterChangeAction {
  type: SettlementMonitoringActionType.FilterChange;
  payload: {
    filter: SettlementMonitoringState['filter'];
  };
}

export type SettlementMonitoringAction = GroupByChangeAction | FilterChangeAction | GroupByChangeCounterpartyAction;

const settlementMonitoringStateReducer = (
  state: SettlementMonitoringState,
  action: SettlementMonitoringAction
): SettlementMonitoringState => {
  switch (action.type) {
    case SettlementMonitoringActionType.GroupByChange: {
      return {
        ...state,
        groupBy: action.payload.groupBy,
      };
    }
    case SettlementMonitoringActionType.GroupByCounterpartyChange: {
      return {
        ...state,
        groupByCounterparty: action.payload.groupByCounterparty,
      };
    }
    case SettlementMonitoringActionType.FilterChange: {
      // do some smart diff checking on the cleaned set of filters
      const currentFilters = removeEmptyFilters(state.filter);
      const nextFilters = removeEmptyFilters(action.payload.filter);
      if (isEqual(currentFilters, nextFilters)) {
        return state;
      }

      return {
        ...state,
        filter: action.payload.filter,
      };
    }
    default: {
      return state;
    }
  }
};

import {
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  type BlotterGroupingDefinition,
  BlotterTable,
  BlotterTableFilters,
  Box,
  ButtonVariants,
  columnTypes,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  EMPTY_ARRAY,
  filterByCellValueMenuItem,
  FormControlSizes,
  getRowNodesToOperateOn,
  getShowJSONContextItem,
  HStack,
  IconButton,
  IconName,
  type MarketAccount,
  Panel,
  PanelContent,
  PanelHeader,
  useAccordionFilterBuilder,
  useBehaviorSubject,
  useBlotterGroupingToggling,
  useBlotterTable,
  useDisclosure,
  useDrawer,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMarketAccountsContext,
  useMixpanel,
  usePersistedBlotterTable,
  type UsePersistedColumnsChangedParams,
  usePortal,
  useWSFilterPipe,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef, RowDoubleClickedEvent } from 'ag-grid-community';
import { compact } from 'lodash-es';
import { useMemo, useState } from 'react';
import { BulkModifyMarketAccountGroupModal } from './BulkModifyMarketAccountGroupModal';
import { EditMarketAccountsDrawer } from './EditMarketAccountDrawer';
import { GROUP_CLIENT_HIDDEN } from './types';
import { colIDToFilterBuilderKey, useMarketAccountBlotterFilter } from './useMarketAccountBlotterFilter';
import { useMarketAccountColumns } from './useMarketAccountColumns';

function getMarketAccountKey(ma: MarketAccount) {
  return ma.Name;
}

const MARKET_ACCOUNT_BLOTTER_ID = 'settings/market-account-blotter';

type MarketAccountGroupings = 'Market' | 'Group' | 'Custom';
type GroupableMarketAccountColumns = keyof MarketAccount;
const GROUPINGS: BlotterGroupingDefinition<MarketAccountGroupings, GroupableMarketAccountColumns>[] = [
  {
    key: 'Market',
    label: 'By Market',
    rowGroupColumns: ['Market'],
    buttonDataTestId: 'by-market-button',
  },
  {
    key: 'Group',
    label: 'By Group',
    rowGroupColumns: ['Group'],
    buttonDataTestId: 'by-group-button',
  },
  {
    key: 'Custom',
    label: 'Custom',
    rowGroupColumns: [],
    buttonDataTestId: 'by-custom-button',
  },
];

export const MarketAccountsSettingsPage = () => {
  const [editedMarketAccountName, setEditedMarketAccountName] = useState<string>();
  const [bulkEditedMktAccs, setBulkEditedMktAccs] = useState<MarketAccount[]>(EMPTY_ARRAY);

  const mixpanel = useMixpanel();
  const { setPortalRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  const { marketAccountsList } = useMarketAccountsContext();

  const drawer = useDrawer({ position: 'relative', width: 480, placement: 'right' });
  const bulkEditGroupDialog = useDisclosure();

  const handleEditRow = useDynamicCallback((mktAcc: MarketAccount) => {
    setEditedMarketAccountName(mktAcc.Name);
    drawer.open();
  });

  const columns = useMarketAccountColumns({ onEditClicked: handleEditRow });

  const persistedBlotterTable = usePersistedBlotterTable<MarketAccount>(MARKET_ACCOUNT_BLOTTER_ID, {
    columns,
    sort: '+DisplayName',
  });

  const { filterFunc, filterBuilderProps } = useMarketAccountBlotterFilter({ persistedBlotterTable });
  const filterPipe = useWSFilterPipe({ getUniqueKey: getMarketAccountKey, filterFunc });

  const filterBuilderAccordion = useAccordionFilterBuilder({
    filterBuilderProps,
    accordionProps: { initialOpen: true },
  });

  const { observable: dataObservable } = useBehaviorSubject(
    () => ({
      initial: true,
      data: marketAccountsList.filter(ma => {
        // In principal, we want to hide all market accounts starting with an underscore, except for the ones specifically named _ClientHidden
        // This allows client org admins to also be able to hide market accounts out in the app (if desired)
        if (ma.Group === GROUP_CLIENT_HIDDEN) {
          return true;
        }

        if (ma.Group?.startsWith('_')) {
          return false;
        }

        return true;
      }),
    }),
    [marketAccountsList]
  );

  const filteredDataObservable = useMemo(() => {
    return dataObservable.pipe(filterPipe);
  }, [dataObservable, filterPipe]);

  const handleColumnsChanged = useDynamicCallback((params: UsePersistedColumnsChangedParams) => {
    updateGroupingOnColumnsChanged?.(params.columns);
    persistedBlotterTable.onColumnsChanged(params);
  });

  const { handleClickJson, jsonModal } = useJsonModal();
  const defaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams<MarketAccount>) => {
    const rightClickedMktAcc = params.node?.data;

    const selectedNodes = getRowNodesToOperateOn(params);
    const selectedMktAccs = compact(selectedNodes.map(node => node.data));

    const items: (MenuItemDef | 'separator')[] = [
      ...filterByCellValueMenuItem({
        params,
        filterableProperties: filterBuilderProps.properties,
        openClause: filterBuilderAccordion.openClause,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      'separator',
    ];

    if (rightClickedMktAcc && selectedMktAccs.length === 1) {
      items.push({
        icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
        name: `Edit Market Account`,
        action: () => {
          setEditedMarketAccountName(rightClickedMktAcc.Name);
          drawer.open();
        },
      });
    }

    if (selectedMktAccs.length > 1) {
      // arbitrary maximum accounts we can bulk update at once
      const max = 50;

      items.push({
        icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
        name: `Modify Group (${selectedMktAccs.length})${selectedMktAccs.length > max ? ` (max ${max})` : ''}`,
        disabled: selectedMktAccs.length > max,
        action: () => {
          setBulkEditedMktAccs(selectedMktAccs);
          bulkEditGroupDialog.open();
        },
      });
    }

    if (rightClickedMktAcc) {
      items.push('separator');
      items.push(getShowJSONContextItem({ params, handleClickJson }));
      items.push('separator');
    }

    items.push(...defaultContextMenuItems(params));

    return items;
  });

  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        editable: false,
        hide: false,
        sortable: true,
        suppressColumnsToolPanel: false,
        params: {
          suppressCount: true,
        },
      }),
    []
  );

  const handleDoubleClickRow = useDynamicCallback(({ data }: RowDoubleClickedEvent<MarketAccount>) => {
    if (!data) {
      return;
    }

    setEditedMarketAccountName(data.Name);
    drawer.open();
  });

  const persistence = useMemo(() => {
    return {
      ...persistedBlotterTable,
      onColumnsChanged: handleColumnsChanged,
    };
  }, [handleColumnsChanged, persistedBlotterTable]);

  const blotterTable = useBlotterTable({
    dataObservable: filteredDataObservable,
    rowID: 'Name' satisfies keyof MarketAccount,
    persistence,
    columns: persistedBlotterTable.columns,
    gridOptions: {
      onRowDoubleClicked: handleDoubleClickRow,
      autoGroupColumnDef,
      rowGroupPanelShow: 'always',
      getContextMenuItems,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
    },
  });

  const { updateGroupingOnColumnsChanged, groupingToggleButtons } = useBlotterGroupingToggling({
    blotterName: MARKET_ACCOUNT_BLOTTER_ID,
    initialColumns: columns,
    setRowGroupColumns: blotterTable.setRowGroupColumns,
    groupings: GROUPINGS,
  });

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  return (
    <>
      <HStack h="100%" w="100%" gap="spacingSmall" overflow="hidden">
        <Panel>
          <PanelHeader>
            <h2>Market Accounts</h2>
            <Box ref={setPortalRef} />
          </PanelHeader>

          <PanelContent>
            <BlotterTableFilters
              size={FormControlSizes.Default}
              {...blotterTable.blotterTableFiltersProps}
              {...filterBuilderAccordion}
              gap="spacingDefault"
              accordionBodyPrefix={groupingToggleButtons}
              prefix={
                <>
                  <IconButton
                    icon={IconName.ListExpand}
                    size={FormControlSizes.Small}
                    variant={ButtonVariants.Default}
                    onClick={expandAllGroups}
                    data-testid="expand-all-button"
                  />
                  <IconButton
                    icon={IconName.ListCollapse}
                    size={FormControlSizes.Small}
                    variant={ButtonVariants.Default}
                    onClick={collapseAllGroups}
                    data-testid="collapse-all-button"
                  />
                </>
              }
            />
            <BlotterTable {...blotterTable} />
          </PanelContent>
        </Panel>
      </HStack>
      <EditMarketAccountsDrawer marketAccountName={editedMarketAccountName} {...drawer} />
      <BulkModifyMarketAccountGroupModal {...bulkEditGroupDialog} marketAccounts={bulkEditedMktAccs} />
      {jsonModal}
    </>
  );
};

import { useEffect, useState } from 'react';
import { map, shareReplay } from 'rxjs/operators';

import {
  BUYING_POWER,
  UnifiedLiquidityEnum,
  useObservable,
  useObservableValue,
  useSubscription,
  wsScanToDoubleMap,
  type BuyingPower,
  type RequestStream,
} from '@talos/kyoko';

interface BuyingPowerRequest extends RequestStream {
  name: typeof BUYING_POWER;
  Counterparties?: string[];
  Symbol: string;
  Tolerance?: string;
  Throttle: string;
  UnifiedLiquidity?: UnifiedLiquidityEnum;
}

export interface BuyingPowerProps {
  symbol: string;
  marketNames?: string[];
  currency: string;
  threshold?: string;
  tolerance?: string;
  /** Specify if buying power should include unified liquidity symbols, i.e. BTC-USD might include BTC-USDT */
  unifiedLiquidity?: UnifiedLiquidityEnum;
  tag: string;
}

export const useBuyingPower = ({
  symbol,
  marketNames, // Or MarketAccount Names
  currency,
  threshold = '5s',
  tolerance,
  unifiedLiquidity = UnifiedLiquidityEnum.Disabled,
  tag,
}: BuyingPowerProps) => {
  const [buyingPowerReq, setBuyingPowerReq] = useState<BuyingPowerRequest | null>(null);

  useEffect(() => {
    if (!symbol) {
      return setBuyingPowerReq(null);
    }
    const req: BuyingPowerRequest = {
      name: BUYING_POWER,
      tag: `${tag}/useBuyingPower`,
      Symbol: symbol,
      Throttle: threshold,
      Tolerance: tolerance,
      UnifiedLiquidity: unifiedLiquidity,
    };
    if (marketNames) {
      req.Counterparties = marketNames;
    }
    setBuyingPowerReq(req);
  }, [symbol, marketNames, threshold, tolerance, unifiedLiquidity, tag]);

  const { data: buyingPowerSubscription } = useSubscription<BuyingPower>(buyingPowerReq);

  const buyingPowerResponseByCurrencyMarketAccount = useObservable(
    () =>
      buyingPowerSubscription.pipe(
        wsScanToDoubleMap({
          getKey1: bp => bp.Currency,
          getKey2: bp => bp.MarketAccount,
          newOuterMapEachUpdate: true,
          newInnerMapsEachUpdate: true,
        }),
        shareReplay(1)
      ),
    [buyingPowerSubscription]
  );

  const buyingPowerByMarketAccount: Map<string, BuyingPower> | undefined = useObservableValue(
    () =>
      buyingPowerResponseByCurrencyMarketAccount.pipe(
        map(bpByCurrencyMarketAccount => {
          return bpByCurrencyMarketAccount.get(currency) ?? new Map();
        })
      ),
    [buyingPowerResponseByCurrencyMarketAccount, currency]
  );

  return { buyingPowerByMarketAccount };
};

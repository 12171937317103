import { useCallback, useEffect, useMemo, useState, type ReactNode } from 'react';

import { v1 as uuid } from 'uuid';
import type { NotificationAppInboxVariants, NotificationVariants } from '../../components/Notification/types';
import { EMPTY_OBJECT } from '../../utils/empty';
import type { ValueOf } from '../../utils/types';
import { useConstant } from '../useConstant';

export type ToastPosition = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';

export interface ToastProps extends ToastPropsBase {
  text: ReactNode;
  variant?: ValueOf<typeof NotificationVariants>;
}

export interface ToastAppInboxProps extends ToastPropsBase {
  id: string;
  /** Bolded title for the toast) */
  title: string;
  /** Subtext for toast */
  description?: string;
  /** ISO Date when the toast came in */
  timeStamp: string;
  /** Style level of toast */
  variant: ValueOf<typeof NotificationAppInboxVariants>;
  /** URL to navigate to when the toast is clicked */
  targetUrl?: string;
}

export type ToastPropsBase = {
  id?: string;
  /** Toast Timeout (undefined for default, null for no timeout) */
  timeout?: number | null; // null for no timeout
  /** is X shown to remove */
  dismissable?: boolean;
  /** followup removal after the toast removal */
  onToastClick?: (id: string) => void;
  onRemove?: (id: string) => void;
};

type UseToastArgs = {
  defaultProps: ToastPropsBase;
};

/** Hook to manage toasts in the application */
export function useToasts<T extends ToastPropsBase = ToastProps>(args?: UseToastArgs) {
  const timeouts: ReturnType<typeof setTimeout>[] = useConstant([]);
  const [toasts, setToasts] = useState<T[]>([]);
  const toastDefaultProps = args?.defaultProps ?? EMPTY_OBJECT;

  const remove = useCallback((id: string) => {
    setToasts(prev => prev.filter(toast => toast.id !== id));
  }, []);

  const clearAll = useCallback(() => {
    setToasts([]);
  }, []);

  const add = useCallback(
    (toast: T): string => {
      if (toast.id == null) {
        toast.id = uuid();
      }
      const newToast = { ...toastDefaultProps, ...toast } as T & { id: string };
      setToasts(prev => [...prev, newToast]);
      if (newToast.timeout != null) {
        timeouts.push(setTimeout(() => remove(newToast.id), newToast.timeout));
      }
      return newToast.id;
    },
    [remove, timeouts, toastDefaultProps]
  );

  useEffect(() => {
    return () => {
      for (const timeout of timeouts) {
        clearTimeout(timeout);
      }
    };
  }, [timeouts]);

  return useMemo(
    () => ({
      toasts,
      add,
      remove,
      clearAll,
    }),
    [toasts, add, remove, clearAll]
  );
}

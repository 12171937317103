import type { Placement } from '@popperjs/core';
import { easings, useTransition } from '@react-spring/web';
import { useEffect, useRef, useState, type CSSProperties, type PropsWithChildren } from 'react';
import { usePopper } from 'react-popper';
import { useMixpanel } from '../../contexts';
import { useDynamicCallback } from '../../hooks';
import { MixpanelEvent, MixpanelEventProperty } from '../../tokens';
import { PopoverContent } from '../Popover/PopoverContent';
import { PopoverVariant } from '../Popover/types';
import { TourContext } from './TourContext';
import { TourStep } from './TourStep';
import type { ITourProviderProps, PersistedTourState } from './types';

const TRANSITION = {
  overflow: 'hidden',
  from: { opacity: 0 as CSSProperties['opacity'], transform: 'translateY(8px)' },
  enter: { opacity: 1 as CSSProperties['opacity'], transform: 'translateY(0)' },
  leave: { opacity: 0 as CSSProperties['opacity'], transform: 'translateY(8px)' },
  config: {
    duration: 300,
    easing: easings.easeOutBack,
  },
  unique: true,
} satisfies Parameters<typeof useTransition>[1];

const DEFAULT_OFFSET: [number, number] = [0, 0];
const DEFAULT_PLACEMENT: Placement = 'auto';

/**
 * The TourProvider contains all the logic for when to show/hide tours.
 *
 * The list of `tours` must be provided up front so we know in which order to render steps of a tour.
 * We also store the current step of a tour in localStorage so that we can persist the state across page reloads.
 *
 * @param children
 * @param tours List of tours to render
 * @param localStorageKey Key to use for persisting the state in localStorage
 * @param enable Small hack to disable the tour provider when running tests. Will be deleted once we have a better solution.
 * @constructor
 */
export function TourProvider({
  children,
  tours,
  localStorageKey = 'tours',
  enable = import.meta.env.VITE_IS_TEST_ENVIRONMENT !== 'true',
}: PropsWithChildren<ITourProviderProps>) {
  // TODO These would probably be better as useReducer
  const [currentTour, setCurrentTour] = useState<string | undefined>();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<[number, number]>(DEFAULT_OFFSET);
  const [currentPlacement, setCurrentPlacement] = useState<Placement>(DEFAULT_PLACEMENT);

  // TODO fhqvst The biggest ick I have with this implementation is that we're using refs to store state.
  // We need them because `useDynamicCallback` behaves odd when we're calling `startTour` and `enqueueTour` asynchronously
  const tourRef = useRef<string | undefined>(undefined);
  const queueRef = useRef<string[]>([]);

  const [targetRef, setTargetRef] = useState<Element | null>();
  const [contentRef, setContentRef] = useState<HTMLElement | null>();

  const mixpanel = useMixpanel();

  const popper = usePopper(targetRef, contentRef, {
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          padding: 0,
        },
      },
      {
        name: 'offset',
        options: {
          offset: currentOffset,
        },
      },
    ],
    placement: currentPlacement,
  });

  const getPersistedState = useDynamicCallback((): PersistedTourState => {
    return JSON.parse(localStorage?.[localStorageKey] ?? '{}');
  });

  const setPersistedState = useDynamicCallback((state: PersistedTourState): void => {
    return localStorage?.setItem(localStorageKey, JSON.stringify(state));
  });

  useEffect(() => {
    if (currentTour) {
      setPersistedState({ ...getPersistedState(), [currentTour]: currentStep });
    }
  }, [getPersistedState, setPersistedState, currentTour, currentStep]);

  /**
   * Starts the tour immediately, cancelling any other tour that is currently running.
   *
   * Note that if we've already seen this tour, it will not be run.
   */
  const startTour = useDynamicCallback((tour: string) => {
    const persistedStep = getPersistedState()[tour];
    if (tours[tour].steps.length - 1 === persistedStep) {
      // Already ran this tour, run next one
      const tour = pollQueue();
      if (tour != null) {
        startTour(tour);
      }
      return;
    }

    tourRef.current = tour;
    const { offset, placement, anchor } = tours[tourRef.current].steps[persistedStep ?? 0];

    setCurrentTour(tour);
    setCurrentStep(persistedStep ?? 0);
    setCurrentOffset(offset ?? DEFAULT_OFFSET);
    setCurrentPlacement(placement ?? DEFAULT_PLACEMENT);
    render(anchor);
  });

  /**
   * Add a tour to the queue
   */
  const enqueueTour = useDynamicCallback((tour: string) => {
    if (queueRef.current.length === 0 && tourRef.current == null) {
      startTour(tour);
      return;
    }
    queueRef.current = queueRef.current.includes(tour) ? queueRef.current : [...queueRef.current, tour];
  });

  /**
   * Remove a tour from the queue
   */
  const dequeueTour = useDynamicCallback((tour: string) => {
    queueRef.current = queueRef.current.filter(t => t !== tour);
  });

  /**
   * End the current tour
   */
  const endTour = useDynamicCallback((tour: string) => {
    if (tour !== tourRef.current) {
      return;
    }

    const nextTour = pollQueue();
    if (nextTour != null) {
      startTour(nextTour);
      return;
    }

    // Manually update persisted state
    setPersistedState({ ...getPersistedState(), [tourRef.current]: tours[tourRef.current].steps.length - 1 });

    setCurrentTour(undefined);
    tourRef.current = undefined;
    setCurrentStep(0);
  });

  const pollQueue = useDynamicCallback(() => {
    if (queueRef.current.length === 0) {
      return;
    }
    const head = queueRef.current[0];
    queueRef.current = queueRef.current.slice(1);
    return head;
  });

  const clearQueue = useDynamicCallback(() => {
    queueRef.current = [];
  });

  /**
   * Navigate to a step in a tour.
   */
  const visitStep = useDynamicCallback((step: number) => {
    if (tourRef.current == null) {
      throw new Error('Cannot visit step when no tour is active');
    }
    if (step < 0) {
      throw new Error('Cannot visit step less than 0');
    }
    if (step >= tours[tourRef.current].steps.length) {
      endTour(tourRef.current);
      return;
    }

    const { offset, placement, anchor } = tours[tourRef.current].steps[step];

    setCurrentStep(step);
    setCurrentOffset(offset ?? DEFAULT_OFFSET);
    setCurrentPlacement(placement ?? DEFAULT_PLACEMENT);
    render(anchor);
  });

  const prevStep = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.PrevTourStep, {
      [MixpanelEventProperty.Tour]: currentTour,
    });
    visitStep(currentStep - 1);
  });
  const nextStep = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.NextTourStep, {
      [MixpanelEventProperty.Tour]: currentTour,
    });
    visitStep(currentStep + 1);
  });

  /**
   * End all tours and mark them as displayed.
   */
  const endAllTours = useDynamicCallback(() => {
    setCurrentTour(undefined);
    tourRef.current = undefined;
    setCurrentStep(0);
    clearQueue();
    setPersistedState(Object.keys(tours).reduce((acc, tour) => ({ ...acc, [tour]: tours[tour].steps.length - 1 }), {}));
  });

  /**
   * Render the popover at the given anchor.
   */
  const render = useDynamicCallback((anchor: string) => {
    const element = document.querySelector(anchor);
    if (element == null) {
      console.warn(`Could not find element with anchor ${anchor}`);
    }
    setTargetRef(element);
  });

  const handleDismiss = useDynamicCallback(() => {
    if (currentTour) {
      mixpanel.track(MixpanelEvent.DismissTour, {
        [MixpanelEventProperty.Tour]: currentTour,
      });
      endTour(currentTour);
    }
  });

  const handleDismissAll = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.DismissAllTours);
    endAllTours();
  });

  const transitions = useTransition(enable ? currentTour : false, TRANSITION);

  return (
    <TourContext.Provider
      value={{
        tours,
        startTour,
        endTour,

        enqueueTour,
        dequeueTour,

        nextStep,
        visitStep,
        prevStep,
      }}
    >
      {children}
      {transitions(
        (transitionStyle, item, t) =>
          item &&
          currentTour && (
            <PopoverContent
              variant={PopoverVariant.Primary}
              style={{ ...popper.styles.popper }}
              ref={setContentRef}
              key={t.key}
              placement={currentPlacement}
              transitionStyle={transitionStyle}
              isSmall={false}
              noPaddingAndBorder={false}
              {...popper.attributes.popper}
            >
              <TourStep
                title={tours[currentTour].steps[currentStep]?.title}
                description={tours[currentTour].steps[currentStep]?.description}
                readMoreURL={tours[currentTour].steps[currentStep]?.readMoreURL}
                showNavigation={tours[currentTour].steps?.length > 1}
                onNext={() => nextStep()}
                onPrev={() => prevStep()}
                onDismiss={handleDismiss}
                onDismissAll={handleDismissAll}
                showClose={true}
              />
            </PopoverContent>
          )
      )}
    </TourContext.Provider>
  );
}

import { createSlice, isAnyOf, type PayloadAction } from '@reduxjs/toolkit';
import type { ToastAppInboxProps } from '@talos/kyoko';
import { globalToastActions, globalToastsDispatch } from '@talos/kyoko';
import { OMSSlice } from 'components/OMS/OMSSlice';
import { OMSView } from 'components/OMS/OMSView';
import { navigationSlice } from 'providers/AppStateProvider/navigationSlice';
import type { AppStateListenerStart } from 'providers/AppStateProvider/types';
import type { AppNotification } from '../AppNotifications';
import { getNotificationRenderInfo } from '../getNotificationRenderInfo';
import { appNotificationsApi, appNotificationsStreamingApi } from './AppNotificationsApi';

export interface AppNotificationStateItem {
  state: UIAppNotificationState;
  toastInfo: ToastAppInboxProps;
  notification: AppNotification;
}

export interface AppNotificationsState {
  /** true if the notifications data is ready (useful for counts and such) */
  isReady: boolean;
  /** mapping of latest notifications to their id */
  notificationMap: { [P in string]?: AppNotificationStateItem };
}

const initialState: AppNotificationsState = {
  isReady: false,
  notificationMap: {},
};

/** UI state to the normally progressed order */
export const UIAppNotificationStates = {
  new: 1,
  inbox: 2,
  closed: 3,
};
type UIAppNotificationState = keyof typeof UIAppNotificationStates;
export const appNotificationsSlice = createSlice({
  name: 'appNotifications',
  initialState,
  reducers: {
    addNotification: (
      state,
      action: PayloadAction<{
        initialState: UIAppNotificationState;
        notification: AppNotification;
        toastInfo: ToastAppInboxProps;
      }>
    ) => {
      const serverState = action.payload.notification.Targets.find(item => item.DeliveryType === 'InApp')?.State;
      const isServerStateClosed = serverState !== 'New';
      const existingUIState = state.notificationMap[action.payload.notification.NotificationID]?.state;
      const nextUiState = isServerStateClosed ? 'closed' : existingUIState ?? action.payload.initialState;
      state.notificationMap[action.payload.notification.NotificationID] = {
        state: nextUiState,
        toastInfo: action.payload.toastInfo,
        notification: action.payload.notification,
      };
    },
    /** Move notification to a subsequent state */
    progressNotificationState: (
      state,
      action: PayloadAction<{
        NotificationID: string;
        state: UIAppNotificationState;
      }>
    ) => {
      const existingNotification = state.notificationMap[action.payload.NotificationID];
      const currentState = existingNotification?.state ? UIAppNotificationStates[existingNotification.state] : 0;
      const newState = UIAppNotificationStates[action.payload.state];
      // if notification is a higher state than the one we are trying to set, go to it
      if (existingNotification && newState > currentState) {
        existingNotification.state = action.payload.state;
      }
    },
    moveAllToInbox: state => {
      Object.values(state.notificationMap).forEach(notification => {
        if (notification?.state === 'new') {
          notification.state = 'inbox';
        }
      });
    },

    // Actions used to trigger middleware effects
    openInbox: _ => {},
    closeInbox: _ => {},
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(
        appNotificationsStreamingApi.endpoints.getNotificationData.matchFulfilled,
        appNotificationsApi.endpoints.getExistingNotifications.matchFulfilled
      ),
      state => {
        state.isReady = true;
      }
    );
  },
});

export function setupListeners(startListening: AppStateListenerStart) {
  const unsubNotificationUpdate = startListening({
    matcher: isAnyOf(
      appNotificationsStreamingApi.endpoints.getNotificationData.matchFulfilled,
      appNotificationsApi.endpoints.getExistingNotifications.matchFulfilled
    ),
    effect: (action, api) => {
      const initialState: 'new' | 'inbox' = appNotificationsApi.endpoints.getExistingNotifications.matchFulfilled(
        action
      )
        ? 'inbox'
        : 'new';
      let notifications: AppNotification[];
      if (appNotificationsStreamingApi.endpoints.getNotificationData.matchFulfilled(action)) {
        // Handle the action from streamingDataSlice
        notifications = [...action.payload.values()];
      } else if (appNotificationsApi.endpoints.getExistingNotifications.matchFulfilled(action)) {
        // Handle the action from appNotificationsApi
        notifications = action.payload;
      } else {
        throw new Error(`Unexpected action '${action.type}' type for notification data`);
      }

      notifications.forEach(notification => {
        const notificationRenderInfo = getNotificationRenderInfo(notification);
        api.dispatch(
          appNotificationsSlice.actions.addNotification({
            initialState,
            notification,
            toastInfo: {
              ...getNotificationRenderInfo(notification),
              onToastClick: () => {
                notificationRenderInfo.targetUrl &&
                  api.dispatch(navigationSlice.actions.navigate(notificationRenderInfo.targetUrl));
              },
              onRemove: () => {
                api.dispatch(
                  appNotificationsSlice.actions.progressNotificationState({
                    NotificationID: notification.NotificationID,
                    state: 'inbox',
                  })
                );
              },
            },
          })
        );
      });
    },
  });

  const unsubInboxOpener = startListening({
    actionCreator: appNotificationsSlice.actions.openInbox,
    effect: (_, api) => {
      api.dispatch(OMSSlice.actions.openView(OMSView.NotificationsInbox));

      // Update the states of all notifications to 'inbox'
      api.dispatch(appNotificationsSlice.actions.moveAllToInbox());
    },
  });

  const unsubInboxCloser = startListening({
    actionCreator: appNotificationsSlice.actions.closeInbox,
    effect: (_, api) => {
      api.dispatch(OMSSlice.actions.closeView());
    },
  });

  const unsubMoveToInboxIfOpen = startListening({
    actionCreator: appNotificationsSlice.actions.addNotification,
    effect: (action, api) => {
      if (api.getState().OMS.openedView === OMSView.NotificationsInbox) {
        api.dispatch(appNotificationsSlice.actions.moveAllToInbox());
      }
    },
  });

  const unsubSendNotification = startListening({
    actionCreator: appNotificationsSlice.actions.progressNotificationState,
    effect: async (action, api) => {
      if (action.payload.state === 'closed') {
        const notificationData = action.payload;
        const result = await api.dispatch(
          appNotificationsApi.endpoints.archiveNotifications.initiate({
            notificationId: notificationData.NotificationID,
          })
        );
        if (result.error) {
          globalToastsDispatch(
            globalToastActions.addToastWithTimeout({
              type: 'ui',
              value: {
                text: `Failed to archive notification`,
                variant: 'NEGATIVE',
                id: `notification-archive-failure-${notificationData.NotificationID}`,
              },
            })
          );
        }
      }
    },
  });

  return () => {
    unsubNotificationUpdate();
    unsubInboxOpener();
    unsubInboxCloser();
    unsubMoveToInboxIfOpen();
    unsubSendNotification();
  };
}

import type { AddPanelOptions } from 'components/Layout/types';
import { getEnumeratedTitle } from 'components/Layout/utils/utils';
import type { DockviewApi } from 'dockview';
import { createContext, useCallback, useContext, useMemo, type MutableRefObject, type PropsWithChildren } from 'react';
import type { PanelType } from 'types/LayoutConfig';
import { v1 as uuid } from 'uuid';

interface SubLayoutContextProps {
  addPanel: (type: PanelType, options: AddPanelOptions) => void;
  dockviewApiRef: MutableRefObject<DockviewApi | undefined>;
  resetLayout: () => void;
}

const SubLayoutContext = createContext<SubLayoutContextProps | undefined>(undefined);
SubLayoutContext.displayName = 'SubLayoutContext';

export function useSubLayoutContext() {
  const context = useContext(SubLayoutContext);
  if (context === undefined) {
    throw new Error('Missing SubLayoutContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/** Provide access to methods for modifying the layout for components outside the flexible part of the layout */
export function SubLayoutContextProvider({
  children,
  resetLayout,
  dockviewApiRef: dockviewExternalApiRef,
}: PropsWithChildren<{
  resetLayout: () => void;
  dockviewApiRef: MutableRefObject<DockviewApi | undefined>;
}>) {
  const addPanel = useCallback(
    (type: PanelType, { label, labelGetter, groupId, panelId, direction, params }: AddPanelOptions = {}) => {
      const dockviewApi = dockviewExternalApiRef.current;
      if (dockviewApi == null) {
        return;
      }
      const referenceGroup = groupId ? dockviewApi.getGroup(groupId) : undefined;
      const referencePanel = panelId ? dockviewApi.getPanel(panelId) : undefined;
      const defaultTitle = label ?? labelGetter?.() ?? type;
      const title = getEnumeratedTitle(defaultTitle, dockviewApi.panels);
      const id = uuid();

      return dockviewApi.addPanel({
        id,
        component: type,
        title,
        params: {
          ...params,
          type,
        },
        position: referenceGroup
          ? {
              referenceGroup: groupId,
              direction: direction ?? 'within',
            }
          : referencePanel
          ? {
              referencePanel: panelId,
              direction: direction ?? 'right',
            }
          : undefined,
      });
    },
    [dockviewExternalApiRef]
  );

  const value = useMemo(
    () => ({
      dockviewApiRef: dockviewExternalApiRef,
      addPanel,
      resetLayout,
    }),
    [dockviewExternalApiRef, addPanel, resetLayout]
  );

  return <SubLayoutContext.Provider value={value}>{children}</SubLayoutContext.Provider>;
}

import type { BaseExportParams, FlashCellsParams, GridApi, IRowNode } from 'ag-grid-community';
import { compact, uniqBy } from 'lodash-es';

import { useMixpanel } from '../../../contexts';
import { useCallbackRef } from '../../../hooks';
import { MixpanelEvent } from '../../../tokens';
import { getCustomColDefProperties } from '../columns';
import type {
  BlotterTableRow,
  ExportDataAsCsvParams,
  ExportDataAsExcelParams,
  TalosBlotterExportParams,
  TalosGetSheetDataForExcelParams,
  UseBlotterTableUtilitiesOutput,
} from '../types';
import {
  cellCsvSafety,
  expandAllNodesInGroup,
  getAllParentsOfNodeInclusive,
  getParamsFormatted,
  isGridApiReady,
} from '../utils';

export function useBlotterTableUtilities<R>(api?: GridApi): UseBlotterTableUtilitiesOutput<R> {
  const mixpanel = useMixpanel();

  const addRow = useCallbackRef((data?: R) => {
    if (!isGridApiReady(api)) {
      return;
    }
    api.applyTransactionAsync({ add: [{ ...data }] });
  });
  const getRows = useCallbackRef(() => {
    const rows: BlotterTableRow<R>[] = [];
    if (isGridApiReady(api)) {
      api.forEachNode(node => {
        rows.push({
          data: node.data,
          setData: newData => node.setData(newData),
          remove: () => api.applyTransactionAsync({ remove: [node.data] }),
          setSelected: (selected: boolean) => {
            node.setSelected(selected);
          },
        });
      });
    }
    return rows;
  });
  const getSelectedRows = useCallbackRef(() => {
    if (isGridApiReady(api)) {
      const selectedNodes = api.getSelectedNodes();
      const selectedData: BlotterTableRow<R>[] = selectedNodes.map(node => ({
        data: node.data,
        setData: newData => node.setData(newData),
        remove: () => {
          if (!isGridApiReady(api)) {
            return;
          }
          return api.applyTransactionAsync({ remove: [node.data] });
        },
        setSelected: (selected: boolean) => {
          node.setSelected(selected);
        },
      }));
      return selectedData;
    }
    return [];
  });

  const getRowsAfterFilter = useCallbackRef(() => {
    if (!isGridApiReady(api)) {
      return [];
    }

    const rows: BlotterTableRow<R>[] = [];
    api.forEachNodeAfterFilter(node => {
      rows.push({
        data: node.data,
        setData: newData => node.setData(newData),
        remove: () => {
          if (!isGridApiReady(api)) {
            return;
          }
          return api.applyTransactionAsync({ remove: [node.data] });
        },
        setSelected: (selected: boolean) => {
          node.setSelected(selected);
        },
      });
    });

    return rows;
  });

  type GetColumnKeysToUseForExportArg = Pick<BaseExportParams, 'columnKeys'> & TalosBlotterExportParams;
  const getColumnKeysToUseForExport = useCallbackRef(
    ({ includeHiddenColumns, ignoredColIds, ignoreColumn, columnKeys }: GetColumnKeysToUseForExportArg) => {
      const startingColumns = (includeHiddenColumns ? api?.getColumns() : api?.getAllDisplayedColumns()) ?? [];

      const columnKeysToUse =
        columnKeys ??
        startingColumns.filter(column => {
          const colDef = column.getColDef();

          const customColDef = getCustomColDefProperties(colDef);
          if (customColDef && customColDef.exportable === false) {
            return false;
          }

          if (colDef.headerName === '') {
            return false;
          }

          if (ignoredColIds && ignoredColIds.has(column.getColId())) {
            return false;
          }

          if (ignoreColumn && ignoreColumn(colDef)) {
            return false;
          }

          return true;
        });

      return columnKeysToUse;
    }
  );

  const exportDataAsExcel = useCallbackRef((params: ExportDataAsExcelParams) => {
    const columnKeysToUse = getColumnKeysToUseForExport(params);

    return api?.exportDataAsExcel({
      columnKeys: columnKeysToUse,
      processCellCallback: params => {
        return getParamsFormatted(params, 'Excel');
      },
      ...params,
    });
  });

  const getSheetDataForExcel = useCallbackRef((params: TalosGetSheetDataForExcelParams) => {
    const columnKeysToUse = getColumnKeysToUseForExport(params);

    return api?.getSheetDataForExcel({
      skipRowGroups: true,
      skipPinnedTop: true,
      skipPinnedBottom: true,
      columnKeys: columnKeysToUse,
      processCellCallback: getParamsFormatted,
      ...params,
    });
  });

  const exportDataAsCSV = useCallbackRef((params: ExportDataAsCsvParams) => {
    const columnKeysToUse = getColumnKeysToUseForExport(params);

    return api?.exportDataAsCsv({
      skipRowGroups: true,
      skipPinnedTop: true,
      skipPinnedBottom: true,
      suppressQuotes: false,
      columnSeparator: ',',
      columnKeys: columnKeysToUse,
      processCellCallback: params => {
        const cellContent = getParamsFormatted(params);
        const safeCsvCellContent = cellCsvSafety(cellContent);
        return safeCsvCellContent;
      },
      ...params,
    });
  });

  const getDataAsCSV = useCallbackRef((params: ExportDataAsCsvParams) => {
    const columnKeysToUse = getColumnKeysToUseForExport(params);

    return api?.getDataAsCsv({
      skipRowGroups: true,
      skipPinnedTop: true,
      skipPinnedBottom: true,
      suppressQuotes: false,
      columnSeparator: ',',
      columnKeys: columnKeysToUse,
      processCellCallback: getParamsFormatted,
      ...params,
    });
  });

  /**
   * Expand a group row.
   * The recursively param, defaulting to true, tells the function to open any intermediary group rows as well
   */
  const expandGroupRow = useCallbackRef((nodeKey: string, recursively = true) => {
    if (!isGridApiReady(api)) {
      return;
    }

    api.forEachNode((node: IRowNode) => {
      if (node.group && node.key === nodeKey) {
        node.setExpanded(true);

        // Recursively open all parent nodes up to the root level
        if (recursively) {
          let workingNode = node;
          while (workingNode.level >= 0) {
            if (workingNode.parent == null) {
              break;
            }
            workingNode = workingNode.parent;
            workingNode.setExpanded(true);
          }
        }
      }
    });
  });

  const scrollToRow = useCallbackRef((...args: Parameters<GridApi<R>['ensureNodeVisible']>) => {
    if (!isGridApiReady(api)) {
      return;
    }

    api.ensureNodeVisible(...args);
  });
  const scrollVerticallyToColumn = useCallbackRef((...args: Parameters<GridApi<R>['ensureColumnVisible']>) => {
    if (!isGridApiReady(api)) {
      return;
    }

    api.ensureColumnVisible(...args);
  });

  const expandAllGroups = useCallbackRef(() => {
    if (!isGridApiReady(api)) {
      return;
    }
    mixpanel.track(MixpanelEvent.ExpandAllRows);

    api.expandAll();
  });

  const collapseAllGroups = useCallbackRef(() => {
    if (!isGridApiReady(api)) {
      return;
    }
    mixpanel.track(MixpanelEvent.CollapseAllRows);

    api.collapseAll();
  });

  const collapseAllLevelsGreaterThan = useCallbackRef((level: number) => {
    if (!isGridApiReady(api)) {
      return;
    }
    api.forEachNode(node => {
      if (node.level > level) {
        api.setRowNodeExpanded(node, false);
      }
    });
  });

  const setRowGroupColumns = useCallbackRef((...[columnsOrColIds]: Parameters<GridApi['setRowGroupColumns']>) => {
    if (!isGridApiReady(api)) {
      return;
    }

    // Grab the column object if there are any colIds passed in so we have a uniform array going forward
    const columns = columnsOrColIds
      .map(item => {
        if (typeof item === 'string') {
          return api.getColumn(item);
        }

        return item;
      })
      .compact();
    api.setRowGroupColumns(columns);
  });

  const addRowGroupColumns = useCallbackRef((colIds: string[]) => {
    if (!isGridApiReady(api)) {
      return;
    }

    // Grab the existing grouped columns, join with the new ones we're adding, and pass to the set function above
    const currentRowGroupColumns = api.getRowGroupColumns();
    const addedRowGroupColumns = colIds.map(colId => api.getColumn(colId)).compact();
    const uniqueNewRowGroupColumns = uniqBy([...currentRowGroupColumns, ...addedRowGroupColumns], c => c.getColId());
    setRowGroupColumns(uniqueNewRowGroupColumns);
  });

  const removeRowGroupColumns = useCallbackRef((colIds: string[]) => {
    if (!isGridApiReady(api)) {
      return;
    }

    api.removeRowGroupColumns(colIds);
  });

  const getRowGroupColumnIds = useCallbackRef(() => {
    if (!isGridApiReady(api)) {
      return new Set<string>();
    }

    return new Set(compact(api.getRowGroupColumns().map(c => c.getColId())));
  });

  const setColumnsVisible: GridApi['setColumnsVisible'] = useCallbackRef((...args) => {
    if (!isGridApiReady(api)) {
      return;
    }
    api.setColumnsVisible(...args);
  });

  const flashCells = useCallbackRef((params: FlashCellsParams) => {
    if (!isGridApiReady(api)) {
      return;
    }

    api.flashCells(params);
  });

  const highlightRows = useCallbackRef((rowIDs: string[], expandChildren?: boolean) => {
    if (!isGridApiReady(api)) {
      return;
    }

    const nodes = compact(rowIDs.map(id => api.getRowNode(id)));
    if (nodes.length === 0) {
      return;
    }

    // We recursively expand the parent of each node we want to highlight.
    for (const node of nodes) {
      // Potentially expand all the children of this group node we're wanting to highlight
      if (node.group && expandChildren) {
        expandAllNodesInGroup(node);
      }

      // Then also work expand myself and all my parents
      const parentsAndNode = getAllParentsOfNodeInclusive(node);
      parentsAndNode.forEach(node => node.setExpanded(true));
    }

    // These timings below are a bit arbitrary, but were just leaving a bit of timing margin here
    // so the ux is good
    const firstNode = nodes[0];
    setTimeout(() => {
      // wait a bit to allow all the parent nodes to be properly expanded before ensuring node is visible
      api.ensureNodeVisible(firstNode, 'middle');
    }, 20);

    setTimeout(() => {
      // flash cells slightly later after ensuring that the node is visible to the user
      api.flashCells({ rowNodes: nodes });
    }, 50);
  });

  // This function is really just a wrapper around highlightRows above. It maps from a group node.key to a group node.id (rowID)
  const highlightGroupRow = useCallbackRef((groupingKey: string) => {
    if (!isGridApiReady(api)) {
      return;
    }

    // first we have to find some groupRow that matches this key
    let referencedNode: IRowNode | undefined = undefined;
    api.forEachNodeAfterFilter(n => {
      if (referencedNode == null && n.group && n.key === groupingKey) {
        referencedNode = n;
        // there's no way to break out of this loop afaik
      }
    });

    if (!referencedNode) {
      return;
    }

    const rowID = (referencedNode as IRowNode).id;
    if (rowID) {
      highlightRows([rowID], true);
    }
  });

  const selectRows = useCallbackRef((rowIDs: string[]) => {
    if (!isGridApiReady(api)) {
      return;
    }

    api.deselectAll();
    rowIDs.forEach(id => api.getRowNode(id)?.setSelected(true));
  });

  const selectAllRows = useCallbackRef(() => {
    if (!isGridApiReady(api)) {
      return;
    }

    api.selectAll();
  });

  const refreshClientSideRowModel = useCallbackRef(() => {
    if (!isGridApiReady(api)) {
      return;
    }

    api.refreshClientSideRowModel();
  });

  const hidePopupMenu = useCallbackRef(() => {
    api?.hidePopupMenu();
  });

  const getAllDisplayedColumns = useCallbackRef(() => {
    if (!isGridApiReady(api)) {
      return [];
    }
    return api?.getAllDisplayedColumns();
  });

  return {
    gridApi: api,
    addRow,
    getRows,
    getRowsAfterFilter,
    getSelectedRows,
    exportDataAsCSV,
    exportDataAsExcel,
    getDataAsCSV,
    expandGroupRow,
    scrollToRow,
    scrollVerticallyToColumn,
    expandAllGroups,
    collapseAllGroups,
    collapseAllLevelsGreaterThan,
    setRowGroupColumns,
    addRowGroupColumns,
    removeRowGroupColumns,
    getRowGroupColumnIds,
    setColumnsVisible,
    flashCells,
    highlightRows,
    highlightGroupRow,
    selectRows,
    selectAllRows,
    refreshClientSideRowModel,
    getSheetDataForExcel,
    hidePopupMenu,
    getAllDisplayedColumns,
  };
}

import {
  FilterClauseType,
  getTypedKeys,
  useAssetsFilter,
  useProductTypesFilter,
  type FilterableProperty,
  type FilterClause,
  type ProductTypeEnum,
  type UseFilterBuilderProps,
} from '@talos/kyoko';
import { compact, isArray } from 'lodash-es';
import { createContext, useCallback, useContext, useMemo, type ReactNode } from 'react';
import { useMarketsFilter } from '../../../../hooks';
import { useAppStateDispatch } from '../../../../providers/AppStateProvider';
import type { PositionsTableFilter } from '../../../Blotters/PositionsV3/types';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';

export const OperationsOverviewFiltersContext = createContext<OperationsOverviewFiltersContextProps | undefined>(
  undefined
);

export type OperationsOverviewFiltersContextProps = {
  filterableProperties: FilterableProperty[];
  onFilterClausesChanged: UseFilterBuilderProps['onFilterClausesChanged'];
  initialFilterClauses: UseFilterBuilderProps['initialFilterClauses'];
};

export function useOperationsOverviewFilters() {
  const context = useContext(OperationsOverviewFiltersContext);
  if (context === undefined) {
    throw new Error(
      'MissingOperationsOverviewFiltersContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

const { updateOpsOverviewFilter } = getPortfolioViewActions();

/**
 * This provider allows anyone in the page to have access to the filterable properties for example
 */
export const OperationsOverviewFiltersProvider = function OperationsOverviewFiltersProvider({
  children,
}: {
  children: ReactNode;
}) {
  const dispatch = useAppStateDispatch();
  const { opsOverviewFilter: filter } = usePortfolioViewStateSelector();
  const assetsFilter = useAssetsFilter();
  const assetTypesFilter = useProductTypesFilter();
  const marketsFilter = useMarketsFilter();

  const filterableProperties = useMemo(
    () =>
      compact<FilterableProperty<keyof PositionsTableFilter>>([
        { ...assetsFilter, label: 'Instrument' },
        marketsFilter,
        assetTypesFilter,
      ]),
    [assetsFilter, marketsFilter, assetTypesFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      getTypedKeys(filter).forEach(key => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: (isArray(filter[key]) ? filter[key] : compact([filter[key]])) as string[],
        });
      });
    }
    return clauses;
  }, [filter]);

  const onFilterClausesChanged: UseFilterBuilderProps['onFilterClausesChanged'] = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>) => {
      const newFilter: PositionsTableFilter = {
        Symbols: filterClausesByPropertyKey.get('Symbols')?.selections,
        Markets: filterClausesByPropertyKey.get('Markets')?.selections,
        AssetTypes: filterClausesByPropertyKey.get('AssetTypes')?.selections as ProductTypeEnum[],
      };
      dispatch(updateOpsOverviewFilter(newFilter));
    },
    [dispatch]
  );

  const filterBuilderProps = useMemo(() => {
    return {
      properties: filterableProperties,
      initialFilterClauses,
      onFilterClausesChanged,
    };
  }, [filterableProperties, initialFilterClauses, onFilterClausesChanged]);

  const value = useMemo(() => {
    return {
      filterableProperties: filterBuilderProps.properties,
      initialFilterClauses: filterBuilderProps.initialFilterClauses,
      onFilterClausesChanged: filterBuilderProps.onFilterClausesChanged,
    };
  }, [filterBuilderProps]);

  return (
    <OperationsOverviewFiltersContext.Provider value={value}>{children}</OperationsOverviewFiltersContext.Provider>
  );
};

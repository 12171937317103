import {
  FilterClauseType,
  cleanupInitialFilterDateRange,
  removeEmptyFilters,
  useCurrenciesFilter,
  useCustomerTransactionStatusesFilter,
  useDateRangeFilter,
  type BlotterTableFiltersProps,
  type CustomerTransaction,
  type FilterClause,
  type FilterableProperty,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { compact, isArray, isEqual, keys } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useCounterpartiesFilter } from '../../../hooks';
import type { CustomerTransactionsFilter } from './types';

export interface UseCustomerTransactionsFilterParams<CustomerTransaction> {
  persistedBlotterTable: UsePersistedBlotterTable<CustomerTransaction>;
}
export function useCustomerTransactionsFilter<CustomerTransaction>({
  persistedBlotterTable,
}: UseCustomerTransactionsFilterParams<CustomerTransaction>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [initialFilter] = useState(() => cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter));
  const [filter, setFilter] = useState<CustomerTransactionsFilter>((initialFilter as CustomerTransactionsFilter) || {});

  const changeFilter = useCallback(
    (action: SetStateAction<CustomerTransactionsFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const currenciesFilter = useCurrenciesFilter();
  const statusesFilter = useCustomerTransactionStatusesFilter();
  const counterpartiesFilter = useCounterpartiesFilter();

  const filterableProperties: FilterableProperty<keyof CustomerTransactionsFilter>[] = useMemo(
    () => [currenciesFilter, statusesFilter, counterpartiesFilter],
    [currenciesFilter, statusesFilter, counterpartiesFilter]
  );

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters<CustomerTransactionsFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies CustomerTransactionsFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof CustomerTransactionsFilter)[]).forEach((key: keyof CustomerTransactionsFilter) => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: (isArray(filter[key]) ? filter[key] : compact([filter[key]])) as string[],
        });
      });
    }
    return clauses;
  }, [filter]);

  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () =>
      ({
        initialFilterClauses,
        properties: filterableProperties,
        onFilterClausesChanged: handleFilterClausesChanged,
      } satisfies UseFilterBuilderProps),
    [initialFilterClauses, filterableProperties, handleFilterClausesChanged]
  );
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;

  return {
    initialFilter,
    filter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
}

const colIDToFilterBuilderKeyMap = {
  Counterparty: 'Counterparties',
  Currency: 'Currencies',
  Status: 'Statuses',
} satisfies Partial<Record<keyof CustomerTransaction, keyof CustomerTransactionsFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof CustomerTransactionsFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}

import type { ICellRendererParams } from 'ag-grid-community';
import { Flex } from '../../Core';
import { MarketColorDot } from '../../MarketColor';
import { MarketLogo } from '../../MarketLogos';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';
import { getStringFormattedColumnComparator } from './utils';

export const market: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellRendererParams: column.params,
  valueFormatter: ({ value, context }) => {
    if (value == null) {
      return '';
    }
    return context.current.marketDisplayNameByName?.get(value) ?? value;
  },
  cellRenderer: ({ value, valueFormatted }: ICellRendererParams<any, string>) => {
    const withColor = column.params?.withColor;
    const withLogo = column.params?.withLogo;
    return (
      <Flex alignItems="center" gap="spacingSmall">
        {value && withColor && <MarketColorDot market={value} />}
        {value && withLogo && <MarketLogo market={value} size={16} />}
        {valueFormatted}
      </Flex>
    );
  },
  comparator: getStringFormattedColumnComparator(column),
});

import { SideEnum } from './types';

export enum OrderFormSides {
  Buy = 'Buy',
  Sell = 'Sell',
  Twoway = '2 way',
}

export function orderFormSideToSideEnum(side: OrderFormSides.Buy | OrderFormSides.Sell): SideEnum {
  return side === OrderFormSides.Buy ? SideEnum.Buy : SideEnum.Sell;
}

export function stringToOrderFormSide(side: string | undefined): OrderFormSides | undefined {
  return side === OrderFormSides.Buy
    ? OrderFormSides.Buy
    : side === OrderFormSides.Sell
    ? OrderFormSides.Sell
    : side === OrderFormSides.Twoway
    ? OrderFormSides.Twoway
    : undefined;
}

export function sideEnumToOrderFormSide(side: SideEnum): OrderFormSides {
  return side === SideEnum.Buy ? OrderFormSides.Buy : OrderFormSides.Sell;
}

export function stringToSideEnum(side: string | undefined): SideEnum | undefined {
  return side === SideEnum.Buy ? SideEnum.Buy : side === SideEnum.Sell ? SideEnum.Sell : undefined;
}

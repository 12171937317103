import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import { EMPTY_ARRAY, toBigWithDefault } from '../utils';
import type {
  HedgeControlStatusEnum,
  HedgeDecisionEnum,
  IHedgeOrderStatus,
  IStrategyParameters,
  SideEnum,
} from './types';

export class HedgeOrderStatus {
  Timestamp!: string;
  Revision!: number;
  InitiatingOrderID!: string;
  HedgeControlStatus!: HedgeControlStatusEnum;
  HedgeDecision?: HedgeDecisionEnum;
  HedgeSymbol!: string;
  OrderSymbol!: string;
  HedgeSide!: SideEnum;
  HedgeStrategy!: string;
  ActiveHedgeOrderID?: string;
  UnhedgedQty!: string;
  CumHedgeQty!: string;
  CumHedgeAmt!: string;
  AvgHedgePx!: string;
  AvgHedgePxAllIn!: string;
  CumHedgeFee!: string;
  FeeCurrency?: string;
  HedgeStrategyParams!: IStrategyParameters;
  Currency?: string;
  AmountCurrency?: string;
  LastDelta?: string;
  Text?: string;

  static readonly rowID = 'Revision';
  /** Front-end calculated UnhedgedQty + CumHedgeQty */
  get totalQty() {
    const unhedgedQtyBig = toBigWithDefault(this.UnhedgedQty, 0).abs(); // UI-5316, Currently, the UnhedgedQty number is always negative, which is causing issues in the calculation.
    const cumHedgeQtyBig = toBigWithDefault(this.CumHedgeQty, 0);
    return unhedgedQtyBig.plus(cumHedgeQtyBig).toFixed();
  }

  constructor(data: HedgeOrderStatus | IHedgeOrderStatus) {
    Object.assign(this, data);
  }
}

interface UseHedgeOrderStatusColumns {
  defaultColumns?: (keyof HedgeOrderStatus | Partial<Column>)[];
}

export function useHedgeOrderStatusColumns({ defaultColumns = EMPTY_ARRAY }: UseHedgeOrderStatusColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'Timestamp',
              type: 'date',
              width: 100,
              sortable: true,
              params: { milliseconds: true },
            },
            {
              field: 'Revision',
              type: 'number',
            },
            {
              field: 'HedgeControlStatus',
              type: 'text',
            },
            {
              field: 'UnhedgedQty',
              type: 'size',
              params: { currencyField: 'Currency' },
            },
            {
              field: 'CumHedgeQty',
              type: 'size',
              params: { currencyField: 'Currency' },
            },
            {
              field: 'AvgHedgePx',
              type: 'price',
              params: { assetField: 'HedgeSymbol' },
            },
            {
              field: 'HedgeStrategyParams',
              type: 'orderParameters',
            },
            {
              field: 'LastDelta',
              type: 'number',
            },
            {
              field: 'Text',
              type: 'text',
            },
            {
              field: 'HedgeSide',
              type: 'side',
            },
            {
              field: 'HedgeStrategy',
              type: 'strategy',
            },
            {
              field: 'ActiveHedgeOrderID',
              type: 'id',
            },
          ] satisfies ColumnDef<HedgeOrderStatus>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        [
          {
            field: 'InitiatingOrderID',
            type: 'id',
          },
          {
            field: 'HedgeSymbol',
            type: 'security',
          },
          {
            field: 'OrderSymbol',
            type: 'security',
          },
          {
            field: 'CumHedgeAmt',
            type: 'size',
            params: { currencyField: 'AmountCurrency' },
          },
          {
            field: 'AvgHedgePxAllIn',
            type: 'price',
            params: { assetField: 'HedgeSymbol' },
          },
          {
            field: 'CumHedgeFee',
            type: 'size',
            params: { currencyField: 'FeeCurrency' },
          },
        ] satisfies (false | ColumnDef<HedgeOrderStatus>)[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, []);
  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [
          ...defaultVisibleColumns.values(),
          ...defaultHiddenColumns.values(),
        ] satisfies ColumnDef<HedgeOrderStatus>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}

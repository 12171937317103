import {
  BlotterTable,
  Box,
  Button,
  ButtonVariants,
  Dialog,
  FileArea,
  HStack,
  useBlotterTable,
  type Column,
  type DialogProps,
  type MinimalSubscriptionResponse,
} from '@talos/kyoko';
import type { GridOptions, IsRowSelectable } from 'ag-grid-community';
import { isFunction } from 'lodash-es';
import { useCallback, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import type { Observable } from 'rxjs';

export type ImportStep = 'initial' | 'uploading' | 'preview' | 'done';

export type ImportDialogDraft = {
  warning: string | undefined;
};

export interface ImportDialogProps<TDraft extends ImportDialogDraft> extends DialogProps {
  onUpload: (file: File | undefined) => Promise<void>;
  rowID: string;
  columns: Column<TDraft>[];
  dataObservable: Observable<MinimalSubscriptionResponse<TDraft>>;
  knowledgeBaseURL?: string;
  step: ImportStep;
  onSelectionChanged?: GridOptions<TDraft>['onSelectionChanged'];
  isRowSelectable?: IsRowSelectable<TDraft>;
  shouldPreselectRow?: boolean | ((data: TDraft) => boolean);
}

export function ImportDialog<TDraft extends ImportDialogDraft>({
  title = 'Import',
  onUpload,
  onSelectionChanged,
  step,
  rowID,
  columns,
  dataObservable,
  knowledgeBaseURL,
  isRowSelectable,
  shouldPreselectRow,
  ...props
}: ImportDialogProps<TDraft>) {
  const { close, onCancel } = props;
  const handleCancel = useCallback(() => {
    onCancel?.();
    close();
  }, [onCancel, close]);

  return (
    <Dialog
      width={960}
      closeOnClickOutside={true}
      closeOnEscape={false}
      closeOnConfirm={false}
      showClose={true}
      customActions={
        <>
          <HStack
            justifyContent={knowledgeBaseURL ? 'space-between' : 'flex-end'}
            gap="spacingComfortable"
            p="spacingMedium"
            w="100%"
            borderTop="1px solid var(--backgroundDivider)"
          >
            {knowledgeBaseURL ? (
              <Button forwardedAs={Link} to={knowledgeBaseURL}>
                Knowledge Base
              </Button>
            ) : null}
            <HStack justifyContent="flex-end" gap="spacingComfortable">
              <Button onClick={handleCancel} data-testid="dialog-cancel">
                {props.cancelLabel}
              </Button>
              <Button
                variant={ButtonVariants.Primary}
                onClick={props.onConfirm}
                loading={props.confirmLoading}
                disabled={props.confirmDisabled}
                data-testid="dialog-confirm"
              >
                {props.confirmLabel}
              </Button>
            </HStack>
          </HStack>
        </>
      }
      title={title}
      {...props}
    >
      <Box h="400px" w="100%">
        {['initial', 'uploading'].includes(step) && <FileArea onChange={onUpload} accept="text/csv" />}
        <Box m="-spacingMedium">
          {['preview', 'done'].includes(step) && (
            <Preview
              dataObservable={dataObservable}
              columns={columns}
              rowID={rowID}
              onSelectionChanged={onSelectionChanged}
              isRowSelectable={isRowSelectable}
              shouldPreselectRow={shouldPreselectRow}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
}

interface PreviewProps<TDraft extends ImportDialogDraft> {
  dataObservable: Observable<MinimalSubscriptionResponse<TDraft>>;
  columns: Column<TDraft>[];
  rowID: string;
  onSelectionChanged?: GridOptions<TDraft>['onSelectionChanged'];
  isRowSelectable?: IsRowSelectable<TDraft>;
  shouldPreselectRow?: boolean | ((row: TDraft) => boolean);
}

function Preview<TDraft extends ImportDialogDraft>({
  dataObservable,
  columns,
  rowID,
  onSelectionChanged,
  isRowSelectable,
  shouldPreselectRow,
}: PreviewProps<TDraft>) {
  const didSelectAllRows = useRef(false);
  const rowSelection: GridOptions<TDraft>['rowSelection'] = useMemo(
    () => ({
      mode: 'multiRow',
      checkboxes: true,
      headerCheckbox: true,
      enableClickSelection: false,
      hideDisabledCheckboxes: true,
      isRowSelectable,
    }),
    [isRowSelectable]
  );
  const blotterTable = useBlotterTable({
    columns,
    rowID,
    dataObservable,
    gridOptions: {
      onSelectionChanged,
      rowSelection,
      onRowDataUpdated: params => {
        if (didSelectAllRows.current) {
          return;
        }
        if (isFunction(shouldPreselectRow)) {
          const preselectedRowIDs: string[] = [];
          params.api.forEachNode(row => {
            if (row.data == null) {
              return;
            }
            if (shouldPreselectRow(row.data) && row.id != null) {
              preselectedRowIDs.push(row.id);
            }
          });
          blotterTable.selectRows(preselectedRowIDs);
        } else if (shouldPreselectRow) {
          blotterTable.selectAllRows();
        }
        didSelectAllRows.current = true;
      },
    },
  });

  return (
    <Box w="100%" h="400px">
      <BlotterTable {...blotterTable} background="backgroundModal" />
    </Box>
  );
}

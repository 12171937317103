import { ACTION, IconName, MixpanelEvent } from '@talos/kyoko';
import type { SidebarSubNavItem } from '@talos/kyoko/src/routed';
import { useFeatureFlag } from '../../hooks';

// Ordering is important!
export const ALL_NAV_PATHS: SidebarSubNavItem[] = [
  {
    path: 'trading',
    label: 'Trading',
    icon: IconName.ChartBarBox,
  },
  {
    path: 'transfers',
    label: 'Transfers',
    icon: IconName.ArrowLeftRight,
    requiredPermission: ACTION.SUBMIT_TRANSFER,
    mixpanelEvent: MixpanelEvent.NavigateToTransfers,
  },
  {
    path: 'portfolio',
    label: 'Portfolio',
    icon: IconName.ChartDonut,
    hasSubNavItems: true,
    requiredPermission: ACTION.VIEW_PORTFOLIO,
    mixpanelEvent: MixpanelEvent.NavigateToPortfolio,
  },
  {
    path: 'portfolio-engineering',
    label: 'Portfolio Engineering',
    icon: IconName.ChartBar,
    hasSubNavItems: true,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    path: 'dealer',
    label: 'Dealer',
    icon: IconName.Collection,
    hasSubNavItems: true,
    requiredPermission: ACTION.VIEW_DEALER,
  },
  {
    path: 'analytics',
    label: 'Analytics',
    icon: IconName.ChartLine,
    requiredPermission: ACTION.VIEW_ANALYTICS,
  },
  {
    path: 'settings',
    label: 'Settings',
    icon: IconName.Cog,
    hasSubNavItems: true,
  },
  {
    path: 'admin',
    label: 'Admin',
    icon: IconName.ShieldCheck,
    hasSubNavItems: true,
    requiredPermission: ACTION.VIEW_TALOS_ADMIN_SETTINGS,
  },
  {
    path: 'monitoring',
    label: 'Monitoring',
    icon: IconName.DeviceDesktop,
    hasSubNavItems: true,
  },
];

const BOTTOM_SECTIONS = ['settings', 'admin'];
const TOP_NAV_PATHS = ALL_NAV_PATHS.filter(path => !BOTTOM_SECTIONS.includes(path.path));
export const BOTTOM_NAV_PATHS = ALL_NAV_PATHS.filter(path => BOTTOM_SECTIONS.includes(path.path));

export function useTopNavPaths() {
  const { enableMonitoringBlotters } = useFeatureFlag();
  if (!enableMonitoringBlotters) {
    return TOP_NAV_PATHS.filter(item => item.path !== 'monitoring');
  }
  return TOP_NAV_PATHS;
}

import {
  CustomerAddressCreator,
  Dialog,
  ModeEnum,
  NotificationVariants,
  getAddressRoutingTypeOfCurrency,
  useCurrenciesContext,
  useGlobalToasts,
  type DialogProps,
  type NewCustomerAddressFormState,
} from '@talos/kyoko';
import { useCallback } from 'react';

import { useCustomers, useCustomersContext } from 'hooks/useCustomer';

/**
 * @deprecated
 */
export const AddNewAddressDialog = (dialog: DialogProps) => {
  const { createCustomerAddress } = useCustomersContext();
  const { add: addToast } = useGlobalToasts();
  const customers = useCustomers();

  const { currenciesBySymbol } = useCurrenciesContext();

  const handleCreateNewAddress = useCallback(
    (newAddressForm: NewCustomerAddressFormState) => {
      const addressRoutingType = getAddressRoutingTypeOfCurrency(currenciesBySymbol.get(newAddressForm.currency));

      createCustomerAddress({
        Counterparty: newAddressForm.counterparty,
        Currency: newAddressForm.currency,
        AddressType: newAddressForm.addressType,
        AddressRoutingType: addressRoutingType,
        Mode: ModeEnum.Enabled,
        Name: newAddressForm.name,
        RoutingInfo: {
          WalletAddress: newAddressForm.address,
          Memo: newAddressForm.memo,
          DestinationTag: '',
        },
      })
        .then(() => dialog.close())
        .catch(error => {
          addToast({
            text: error?.toString() || 'Could not create address',
            variant: NotificationVariants.Negative,
            dismissable: true,
          });
        });
    },
    [addToast, createCustomerAddress, currenciesBySymbol, dialog]
  );
  return (
    <Dialog {...dialog} width={450} showConfirm={false} showCancel={false} title="New Customer Address">
      <CustomerAddressCreator
        onSubmit={handleCreateNewAddress}
        onCancel={() => dialog.close()}
        enabledFields={{ counterparty: true, addressType: true, name: true, memo: true, address: true, currency: true }}
        counterpartyOptions={customers}
      />
    </Dialog>
  );
};

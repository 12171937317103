import { type DefaultTheme, useTheme } from 'styled-components';
import { Grid } from '../Core';
import { Icon, IconName } from '../Icons';
import { AlertBannerWrapper, Content, Stripes } from './styles';

import { mix } from 'polished';
import type { ReactNode } from 'react';
import { AlertVariants } from '../Alert/types';

export type AlertBannerVariants = AlertVariants.Negative | AlertVariants.Positive | AlertVariants.Warning;

export function AlertBanner({
  variant,
  children,
  icon: propsIcon,
}: {
  variant: AlertBannerVariants;
  children: ReactNode;
  icon?: IconName;
}) {
  const theme = useTheme();
  const { backgroundColor, iconColor, icon: defaultIcon } = getAlertBannerColor(variant, theme);
  const icon = propsIcon ?? defaultIcon;

  return (
    <Grid w="100%" gridTemplateColumns="1fr auto 1fr" position="relative" background={backgroundColor}>
      <Stripes backgroundPosition="right top" />
      <Content>
        <Icon icon={icon} color={iconColor} />
        {children}
      </Content>
      <Stripes backgroundPosition="left top" />
      <AlertBannerWrapper color={backgroundColor} />
    </Grid>
  );
}

const getAlertBannerColor = (
  variant: AlertBannerVariants,
  theme: DefaultTheme
): { backgroundColor: string; icon: IconName; iconColor: string } => {
  switch (variant) {
    case AlertVariants.Positive:
      return {
        backgroundColor: mix(0.4, theme.colors.green.default, theme.colors.gray.main),
        iconColor: theme.colors.green.lighten,
        icon: IconName.InformationCircleSolid,
      };
    case AlertVariants.Negative:
      return {
        backgroundColor: mix(0.6, theme.colors.red.default, theme.colors.gray.main),
        iconColor: theme.colors.red.lighten,
        icon: IconName.ExclamationSolid,
      };
    case AlertVariants.Warning:
      return {
        backgroundColor: theme.colors.yellow.lighten,
        iconColor: theme.colors.gray['000'],
        icon: IconName.ExclamationSolid,
      };
  }
};

import { NotificationVariants, type PricingRule, useGlobalToasts } from '@talos/kyoko';
import { keys } from 'lodash-es';
import { useCallback } from 'react';
import { usePricingRules } from '../../../providers';
import { PRICING_CONFIG_UPDATE_ID } from '../../../providers/PricingRulesProvider';
function getPricingRuleKey(pricingRule: PricingRule) {
  return `${pricingRule.Counterparty}${pricingRule.Symbol}`;
}

export function useEditPricingRule({ onSettled }: { onSettled: (() => void) | undefined }) {
  const { remove: removeToast, add: addToast } = useGlobalToasts();
  const { updatePricingRule } = usePricingRules();
  return useCallback(
    // If this is being invoked in a loop as part of a batch
    // from handleEditPricingRules, we suppress any toast messages
    // here at the individual level, return a Promise without a catch
    // chain on it, and then execute a single toast within that function,
    // once all of the Promises have settled one way or another, with
    // a generated message, depending on how many passed vs failed.
    (data: PricingRule, partOfBatch = false) => {
      const preparedData: PricingRule = { ...data };
      keys(preparedData).forEach(key => {
        // Remove all blank fields from the final message
        if (preparedData[key] == null || preparedData[key] === '') {
          delete preparedData[key];
        }
      });
      if (!partOfBatch) {
        removeToast(PRICING_CONFIG_UPDATE_ID);
      }
      // Store this Promise to return to the calling function
      const promise = updatePricingRule(preparedData).then(() => {
        if (!preparedData.Counterparty && !preparedData.Symbol) {
          if (!partOfBatch) {
            addToast({
              text: `Updated Default Pricing Rule Configuration. Your grid will refresh automatically.`,
              variant: NotificationVariants.Positive,
            });
          }
          onSettled?.();
        }

        // When we get a confirmation back from the backend, remove the record of the "dirty update" from the updatedIDs data set
        const updatedKey = getPricingRuleKey(data);
        return updatedKey;
      });

      // If this is an individual call to this function, attach the catch
      // handler. Otherwise, let it reject and check its settlement status
      // in the parent function, to compute the overall toast output.
      if (!partOfBatch) {
        promise.catch(error => {
          removeToast(PRICING_CONFIG_UPDATE_ID);
          addToast({
            text: `Could not update: ${error?.toString()}`,
            variant: NotificationVariants.Negative,
          });
        });
      }

      return promise;
    },
    [addToast, onSettled, removeToast, updatePricingRule]
  );
}

import { GlobalToastManager } from '../../providers/GlobalToastManager';
import type { ToastAppInboxProps, ToastProps, useToasts } from './useToasts';

/** Return common toast dispatching callbacks  */
function createToastHooks(manager: InstanceType<typeof GlobalToastManager>) {
  return {
    add: (...args: Parameters<typeof manager.addToast>) => manager.addToast(...args),
    remove: (...args: Parameters<typeof manager.removeToast>) => manager.removeToast(...args),
    clearAll: (...args: Parameters<typeof manager.clearToasts>) => manager.clearToasts(...args),
  };
}

const globalToastManagerUI = new GlobalToastManager('ui');
const appInboxManagerUI = new GlobalToastManager('appInbox');
const uiToastHooks = createToastHooks(globalToastManagerUI);
const appInboxHooks = createToastHooks(appInboxManagerUI);

type ResultType<T extends ToastProps | ToastAppInboxProps> = Omit<ReturnType<typeof useToasts<T>>, 'toasts'>;

// Return ths toast functions for the global toasts and app inbox toasts - if you ne
export const useGlobalToasts = (): ResultType<ToastProps> => uiToastHooks;
export const useAppInboxToasts = (): ResultType<ToastAppInboxProps> => appInboxHooks;

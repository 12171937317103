import {
  abbreviateId,
  formattedDateForSubscription,
  formattedUTCDate,
  logger,
  parseDate,
  type OrderStrategy,
  type Security,
  type AggregationWindowEnum as ServerAggregationWindowEnum,
  type TabLabelerFn,
} from '@talos/kyoko';
import { addYears } from 'date-fns';
import { compact, merge } from 'lodash-es';
import { v1 as uuid } from 'uuid';
import {
  AggregationWindowEnum,
  ReportType,
  type IAnalyticsOrderDetailsTab,
  type IAnalyticsReportTab,
  type NavigationInstructions,
  type ReportFilter,
} from './types';

/** Get a new tab from navigation instructions */
export function getTab(instructions: NavigationInstructions): IAnalyticsReportTab | IAnalyticsOrderDetailsTab {
  const baseTab = {
    id: uuid(),
    closable: true,
    usingSmartLabel: true,
    filter: {
      AggregationWindow: instructions.aggregationWindow,
      StartDate: formattedDateForSubscription(instructions.startDate),
      EndDate: formattedDateForSubscription(instructions.endDate),
    },
  };
  if (instructions.dimension === 'Order' && instructions.id) {
    return merge(baseTab, {
      type: 'order-details' as const,
      orderID: instructions.id,
      id: instructions.id,
    });
  } else if (instructions.dimension === 'Market' && instructions.id) {
    return merge(baseTab, {
      type: ReportType.marketDetails,
      dimension: 'Market' as const,
      filter: {
        Markets: [instructions.id],
      },
    });
  } else if (instructions.dimension === 'Market') {
    return merge(baseTab, {
      type: ReportType.marketsOverview,
      dimension: 'Market' as const,
    });
  } else if (instructions.dimension === 'Asset' && instructions.id) {
    return merge(baseTab, {
      type: ReportType.symbolDetails,
      dimension: 'Asset' as const,
      filter: {
        Symbols: [instructions.id],
      },
    });
  } else if (instructions.dimension === 'Asset') {
    return merge(baseTab, {
      type: ReportType.symbolsOverview,
      dimension: 'Asset' as const,
    });
  } else if (instructions.dimension === 'Strategy' && instructions.id) {
    return merge(baseTab, {
      type: ReportType.strategyDetails,
      dimension: 'Strategy' as const,
      filter: {
        Strategies: [instructions.id],
      },
    });
  } else if (instructions.dimension === 'Strategy') {
    return merge(baseTab, {
      type: ReportType.strategiesOverview,
      dimension: 'Strategy' as const,
    });
  } else {
    logger.error(new Error(`Invalid navigation instructions in analytics`), {
      extra: {
        ...instructions,
      },
    });
    return merge(baseTab, {
      type: ReportType.symbolsOverview,
      dimension: 'Asset' as const,
    });
  }
}

/** Get a tab label based on current filters */
export function tabLabelerAnalytics<T extends IAnalyticsReportTab | IAnalyticsOrderDetailsTab>(
  marketDisplayNameByName: Map<string, string>,
  securitiesBySymbol: Map<string, Security>,
  strategiesByName: Map<string, OrderStrategy>
): TabLabelerFn<T> {
  return (tab, currentTabs, action) => {
    if (tab.type === 'order-details') {
      return tab.label ?? `#${abbreviateId(tab.orderID)}`;
    }
    if (tab.customLabel) {
      return tab.label ?? '';
    }
    const monthString = getTabPeriodLabel(tab.filter);
    switch (tab.type) {
      case ReportType.marketDetails: {
        return `${monthString}: ${
          marketDisplayNameByName.get(tab.filter?.Markets?.[0] ?? '') ?? tab.filter?.Markets?.[0] ?? ''
        }`;
      }
      case ReportType.marketsOverview: {
        return `${monthString}: Markets`;
      }
      case ReportType.symbolDetails: {
        return `${monthString}: ${
          securitiesBySymbol.get(tab.filter?.Symbols?.[0] ?? '')?.DisplaySymbol ?? tab.filter?.Symbols?.[0] ?? ''
        }`;
      }
      case ReportType.symbolsOverview: {
        return `${monthString}: Symbols`;
      }
      case ReportType.strategyDetails: {
        return `${monthString}: ${
          strategiesByName.get(tab.filter?.Strategies?.[0] ?? '')?.DisplayName ?? tab.filter?.Strategies?.[0] ?? ''
        }`;
      }
      case ReportType.strategiesOverview: {
        return `${monthString}: Strategies`;
      }
      case ReportType.monthlyOverview: {
        return `Past year`;
      }
      default: {
        const _exhaustiveCheck: never = tab;
        throw new Error(`Unknown tab type: ${(tab as any).type}`);
      }
    }
  };
}

export function getTabPeriodLabel(filter: ReportFilter | undefined) {
  switch (filter?.AggregationWindow) {
    case undefined:
    case AggregationWindowEnum.Month:
      return formattedUTCDate(filter?.StartDate, '{Mon} {year}');
    case AggregationWindowEnum.Past_7_Days:
      return 'Past 7 days';
    case AggregationWindowEnum.Past_30_Days:
      return 'Past 30 days';
    case AggregationWindowEnum.Week_to_Date:
      return 'WTD';
    case AggregationWindowEnum.Month_to_Date:
      return 'MTD';
  }
}

// These functions are used over date-fns because we use UTC only and there was an
// issue with using their add function when switching to DST.

/**
 * Get the start of day in UTC
 * @param date Calculate from this date
 * @returns Date
 */
export function startOfUTCDay(date: Date): Date {
  const day = new Date(date);
  day.setUTCHours(0, 0, 0, 0);

  return day;
}
/**
 * Get the first day of this month in UTC
 * @returns Date
 */
export function startOfUTCMonth(date: Date): Date {
  const newDate = new Date(date);
  newDate.setUTCDate(1);
  newDate.setUTCHours(0, 0, 0, 0);

  return newDate;
}

/**
 * Get the first day of last month in UTC
 * @returns Date
 */
export function startOfLastUTCMonth() {
  const lastMonth = getPreviousMonth(startOfUTCMonth(parseDate()));
  return lastMonth;
}

/**
 * Get the previous UTC month based on the date param
 * @param date Calculate from this date
 * @returns Date
 */
export function getPreviousMonth(date: Date) {
  const previousMonth = new Date(date);
  previousMonth.setUTCMonth(date.getUTCMonth() - 1);

  return previousMonth;
}

/**
 * Get the first day of the last month of the previous year
 * @returns Date
 */
export function getLastYear() {
  const lastYear = addYears(startOfLastUTCMonth(), -1);

  return lastYear;
}

/**
 * Get the start of the next UTC day from @date
 * @param date Calculate from this date
 * @returns Date
 */
export function startOfNextUTCDay(date: Date) {
  const day = date.getUTCDate();
  const nextDay = new Date(date);

  nextDay.setUTCDate(day + 1);
  nextDay.setUTCHours(0, 0, 0, 0);
  return nextDay;
}

/**
 * Get the next monday of the next UTC week from @date
 * @param date Calculate from this date
 * @returns Date
 */
export function startOfNextUTCMonday(date: Date) {
  const day = date.getUTCDate();
  // 0 = Monday, 6 = Sunday
  const weekdayOffset = (date.getUTCDay() || 7) - 1;
  const nextMonth = new Date(date);

  nextMonth.setUTCDate(day + 7 - weekdayOffset);
  nextMonth.setUTCHours(0, 0, 0, 0);
  return nextMonth;
}

/**
 * Get the start of the next UTC month based on the date param
 * @param date Calculate from this date
 * @returns Date
 */
export function startOfNextUTCMonth(date: Date) {
  const day = date.getUTCDate();
  const month = date.getUTCMonth();
  const nextMonth = new Date(date);

  nextMonth.setUTCMonth(month + 1);
  nextMonth.setUTCDate(day);
  nextMonth.setUTCHours(0, 0, 0, 0);
  return nextMonth;
}

export function clientAggregationWindowToServerFilter(
  filter: ReportFilter | undefined,
  resolution: '1D' | '1MO' | '1W' = '1MO'
): {
  AggregationWindow: ServerAggregationWindowEnum extends `AggregationWindow_${infer Suffix}` ? Suffix : never;
  StartDate?: string;
  EndDate?: string;
} {
  if (resolution === '1D') {
    return {
      AggregationWindow: resolution,
      StartDate: formattedDateForSubscription(filter?.StartDate),
      EndDate: formattedDateForSubscription(filter?.EndDate),
    };
  }
  const aggregationWindow = filter?.AggregationWindow;
  switch (aggregationWindow) {
    case undefined:
    case AggregationWindowEnum.Month:
      return {
        AggregationWindow: '1MO',
        StartDate: formattedDateForSubscription(filter?.StartDate),
        EndDate: formattedDateForSubscription(filter?.EndDate),
      };
    case AggregationWindowEnum.Month_to_Date:
      return { AggregationWindow: 'MTD' };
    case AggregationWindowEnum.Past_30_Days:
      return { AggregationWindow: 'L30D' };
    case AggregationWindowEnum.Past_7_Days:
      return { AggregationWindow: 'L7D' };
    case AggregationWindowEnum.Week_to_Date:
      return { AggregationWindow: 'WTD' };
    default: {
      const _exhaustiveCheck: never = aggregationWindow;
      throw new Error(`Unknown aggregation window: ${aggregationWindow}`);
    }
  }
}

/**
 * Generate a key for react components, based on the current tab filter.
 * Used to destroy / recreate the reports when the filter changes.
 *
 * @param filter Current tab filter
 * @returns React key that changes when the filter changes
 */
export function filterToReactKey(filter?: ReportFilter): string {
  if (!filter) {
    return '';
  }
  return compact([
    filter.AggregationWindow,
    filter.StartDate,
    filter.EndDate,
    ...(filter.Markets ?? []),
    ...(filter.Symbols ?? []),
    ...(filter.Strategies ?? []),
  ]).join('_');
}

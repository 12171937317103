import {
  CURRENCY_CONVERSION,
  DELETE,
  GET,
  MAX_ORDER_SIZE_LIMIT,
  ModeEnum,
  POST,
  PUT,
  TRADING_LIMITS_CURRENCY_CONVERSION,
  logger,
  request,
  useObservable,
  useObservableRef,
  useObservableValue,
  useSecuritiesContext,
  useStaticSubscription,
  useSubscription,
  useUserContext,
  type CurrencyConversionRate,
} from '@talos/kyoko';
import type { BigSource } from 'big.js';
import Big from 'big.js';
import { useUser } from 'hooks/useUser';
import { isEmpty, isEqual, uniq } from 'lodash-es';
import { useDisplaySettings } from 'providers/AppConfigProvider';
import { useUserGroups } from 'providers/UserGroupsContext';
import type React from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { map, shareReplay } from 'rxjs/operators';
import type { UserGroupMembership } from 'types';
import {
  MATCHERS,
  RULES,
  TradingLimitsContext,
  createLimitKey,
  mapMaxOrderSizeLimits,
  type ConversionRequest,
  type Limit,
  type ValidationResult,
} from './TradingLimitsContext';

export const TradingLimitsProvider = memo(function TradingLimitsProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const { orgApiEndpoint } = useUserContext();
  const { membershipsByUser } = useUserGroups();
  const { securitiesBySymbol } = useSecuritiesContext();
  const user = useUser();

  const { data: maxOrderSizeSubscription } = useStaticSubscription<Limit>({
    name: MAX_ORDER_SIZE_LIMIT,
    tag: 'TradingLimitsProvider',
  });

  const maxOrderSizeObs = useObservable<{ byID: Map<string, Limit>; byKey: Map<string, Limit> }>(
    () =>
      maxOrderSizeSubscription.pipe(
        mapMaxOrderSizeLimits,
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [maxOrderSizeSubscription]
  );

  const maxOrderSizeByKey = useObservable<Map<string, Limit>>(
    () =>
      maxOrderSizeObs.pipe(
        map(({ byKey }) => byKey),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [maxOrderSizeObs]
  );

  // Currency conversion
  const [conversionRequest, setConversionRequest] = useState<ConversionRequest | null>(null);
  const { data: conversionSubscription } = useSubscription<CurrencyConversionRate>(conversionRequest);
  const { homeCurrency } = useDisplaySettings();

  useEffect(() => {
    const sub = maxOrderSizeByKey.subscribe((limits: Map<string, Limit>) => {
      if (homeCurrency != null) {
        const currencies = uniq([...limits.values()].map<string>(item => item.ThresholdCurrency)).sort();
        if (currencies.length) {
          setConversionRequest(prev => {
            if (prev == null || homeCurrency !== prev.EquivalentCurrency || !isEqual(currencies, prev.Currencies)) {
              return {
                name: CURRENCY_CONVERSION,
                tag: TRADING_LIMITS_CURRENCY_CONVERSION,
                EquivalentCurrency: homeCurrency,
                Currencies: currencies,
              };
            } else {
              return prev;
            }
          });
        } else {
          setConversionRequest(null);
        }
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [maxOrderSizeByKey, homeCurrency]);

  // CRUD
  const endpoint = `${orgApiEndpoint}/max-order-size-limits`;

  const listMaxOrderSizeLimits = useCallback(() => request(GET, endpoint), [endpoint]);
  const getMaxOrderSizeLimit = useCallback((id: string) => request(GET, `${endpoint}/${id}`), [endpoint]);
  const createMaxOrderSizeLimit = useCallback((limit: Limit) => request(POST, endpoint, limit), [endpoint]);
  const updateMaxOrderSizeLimit = useCallback(
    (id: string, limit: Limit) => request(PUT, `${endpoint}/${id}`, limit),
    [endpoint]
  );
  const deleteMaxOrderSizeLimit = useCallback((id: string) => request(DELETE, `${endpoint}/${id}`, null), [endpoint]);

  const conversionRates: Map<string, CurrencyConversionRate> = useObservableValue(
    () =>
      conversionSubscription.pipe(
        map(json => json && json.data.reduce((acc, rate) => acc.set(rate.Asset, rate), new Map()))
      ),
    [conversionSubscription],
    new Map()
  );

  // Validation
  const maxOrderSizeByKeyRef = useObservableRef(() => maxOrderSizeByKey, [maxOrderSizeByKey]);
  const membershipsByUserRef = useObservableRef<Map<string, UserGroupMembership[]>>(
    () => membershipsByUser,
    [membershipsByUser]
  );

  const validateMaxOrderSize = useCallback(
    (sizeHomeCurrency: BigSource, symbol: string | undefined) => {
      const maxOrderSize = maxOrderSizeByKeyRef.current;
      if (maxOrderSize == null || conversionRates == null) {
        logger.error(new Error(`Missing max order size limits or conversion rates`), {
          extra: {
            maxOrderSize: JSON.stringify(maxOrderSize),
            conversionRates: JSON.stringify(conversionRates),
          },
        });
        throw new Error('Missing max order size limits or conversion rates. Please contact Talos Support.');
      }

      const result: ValidationResult = {
        warn: false,
        reject: false,
      };

      if (isEmpty(maxOrderSize)) {
        return result;
      }

      if (sizeHomeCurrency == null) {
        logger.error(new Error(`Order size was null while validating max order size limit`));
        throw new Error(
          'Could not compute order size while applying max order size limits. Please contact Talos Support.'
        );
      }

      const toHomeCurrency = (sizeOrThreshold: BigSource, currency: string) => {
        const conversion = conversionRates.get(currency);
        if (conversion && conversion.Rate) {
          return Big(sizeOrThreshold).times(conversion.Rate);
        }
        logger.error(new Error(`Missing conversion rate for ${currency} while validating max order size`), {
          extra: {
            conversion: JSON.stringify(conversion),
          },
        });
        throw new Error(`Missing conversion rate. Please contact Talos support.`);
      };

      let matchingLimits: Limit[] = [];
      for (const matcher of RULES) {
        // Find user groups
        let groupIDs;
        if (matcher & MATCHERS.USER_GROUP) {
          const memberships = membershipsByUserRef.current?.get(user.Name);
          if (memberships) {
            groupIDs = memberships.map(item => item.GroupID);
          } else {
            // This rule requires that user is actually part of a group,
            // so we can safely proceed to the one
            continue;
          }
        } else {
          groupIDs = [undefined];
        }

        // Check if security or currency matches
        let currencies;
        if (matcher & MATCHERS.CURRENCY) {
          const security = symbol ? securitiesBySymbol.get(symbol) : undefined;
          currencies = [security?.BaseCurrency, security?.QuoteCurrency];
        } else {
          currencies = [undefined];
        }

        for (const groupID of groupIDs) {
          for (const currency of currencies) {
            const key = createLimitKey(matcher, user.Name, groupID, symbol, currency);

            const matchingLimit = maxOrderSizeByKeyRef.current?.get(key);
            if (matchingLimit && matchingLimit.Mode === ModeEnum.Enabled) {
              matchingLimits.push(matchingLimit);
            }
          }
        }

        if (matchingLimits.length > 0) {
          break;
        } else {
          matchingLimits = [];
        }
      }

      for (const limit of matchingLimits) {
        const shouldReject = Big(sizeHomeCurrency || 0).gt(
          toHomeCurrency(limit.RejectThreshold, limit.ThresholdCurrency)
        );
        if (!result.reject && shouldReject) {
          result.reject = true;
          result.limit = limit;
        }

        const shouldWarn = Big(sizeHomeCurrency || 0).gt(toHomeCurrency(limit.WarnThreshold, limit.ThresholdCurrency));
        if (!result.warn && shouldWarn) {
          result.warn = true;
          result.limit = limit;
        }

        if (result.warn || result.reject) {
          break;
        }
      }

      return result;
    },
    [conversionRates, maxOrderSizeByKeyRef, membershipsByUserRef, securitiesBySymbol, user.Name]
  );

  const value = useMemo(
    () => ({
      maxOrderSizeByKey,
      conversionRates,
      validateMaxOrderSize,
      listMaxOrderSizeLimits,
      getMaxOrderSizeLimit,
      createMaxOrderSizeLimit,
      updateMaxOrderSizeLimit,
      deleteMaxOrderSizeLimit,
    }),
    [
      maxOrderSizeByKey,
      conversionRates,
      validateMaxOrderSize,
      listMaxOrderSizeLimits,
      getMaxOrderSizeLimit,
      createMaxOrderSizeLimit,
      updateMaxOrderSizeLimit,
      deleteMaxOrderSizeLimit,
    ]
  );

  return <TradingLimitsContext.Provider value={value}>{children}</TradingLimitsContext.Provider>;
});

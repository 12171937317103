import { hasDateRangeFilter } from '@talos/kyoko';
import { getReconMismatchesBlotterDefaultStartDate } from 'containers/Blotters/Reconciliation/ReconMismatches';
import { RECON_MISMATCHES_BLOTTER_PREFIX } from 'containers/Blotters/Reconciliation/tokens';
import { forEach } from 'lodash-es';

/**
 * For release 2.39 -> 2.40 we are adding a date range selector to the ReconMismatches blotter.
 * You should always have a date range set.
 * This migration sets the default start date on the
 */
export function ensureReconMismatchesBlotterTabsHaveStartDate(config: any) {
  const blotterTabs: object | undefined = config?.blotters2;
  if (blotterTabs == null) {
    return;
  }

  forEach(blotterTabs, (value: any, key) => {
    if (key.includes(RECON_MISMATCHES_BLOTTER_PREFIX)) {
      if (!hasDateRangeFilter(value.filter)) {
        value.filter = {
          ...(value.filter ?? {}), // value.filter might be undefined still
          StartDate: getReconMismatchesBlotterDefaultStartDate(),
        };
      }
    }
  });
}

import { toNumber } from 'lodash-es';
import { useEffect, type MutableRefObject } from 'react';
import { useTheme } from 'styled-components';
import {
  consumePortfolioChartSubscription,
  type SeriesDefinition,
} from '../../PortfolioChart/consumePortfolioChartSubscription';
import type { PortfolioHistoricalPosition } from '../../types';
import { BALANCES_SERIES_NAME } from './tokens';
import { useChartSubscriptions } from './useChartSubscriptions';

interface UseHistoricalValueData {
  isLoaded: boolean;
  chartRef: MutableRefObject<Highcharts.Chart | undefined>;
}

const seriesDefinitions: SeriesDefinition<PortfolioHistoricalPosition>[] = [
  {
    name: BALANCES_SERIES_NAME,
    entityToPoint: positionToPoint,
  },
];

export function useHistoricalValueData({ isLoaded, chartRef }: UseHistoricalValueData) {
  const { dataLoading, dataResetting, subscription } = useChartSubscriptions();
  const theme = useTheme();

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    const mySub = subscription.subscribe(({ json, reset }) => {
      if (!chartRef.current) {
        return;
      }
      consumePortfolioChartSubscription({ json, reset, chart: chartRef.current, seriesDefinitions });

      // If we performed a reset, we make sure to set the extremes of the xAxis to the extremes of the data we received
      if (reset && json.data.length !== 0) {
        const first = json.data[0];
        const last = json.data[json.data.length - 1];
        chartRef.current?.xAxis[0].setExtremes(
          new Date(first.Timestamp).getTime(),
          new Date(last.Timestamp).getTime(),
          true
        );
      }
    });

    return () => mySub.unsubscribe();
  }, [subscription, theme, isLoaded, chartRef]);

  return {
    dataLoading,
    dataResetting,
  };
}

function positionToPoint(position: PortfolioHistoricalPosition) {
  return [new Date(position.Timestamp).getTime(), toNumber(position.EquivalentPosition)];
}

/**
 * NOTE: This strategies provider is specific to the Principal UI Order Strategies,
 * and is not to be confused with the one in Kyoko which provides Customer Order Strategies ...
 */
import {
  ORDER_STRATEGY,
  StrategiesContext,
  StrategyScopeEnum,
  useObservable,
  useObservableValue,
  useStaticSubscription,
  useStrategiesContext,
  wsScanToMap,
  type OrderStrategy,
  type Parameter,
} from '@talos/kyoko';
import type React from 'react';
import { memo } from 'react';
import { map, scan, shareReplay } from 'rxjs/operators';
import { ParameterKeysEnum } from 'tokens/orderStrategy';

export const useStrategies = () => useStrategiesContext();

/**
 * Ensures that EndTime is placed directly after StartTime
 * Returns a copy of the given array. Does not mutate original.
 * If both StartTime and EndTime do not exist, or they already placed correctly relative to each other,
 * we return the passed in array
 * @param parameters the parameter list to check
 * @returns parameter list with fixed ordering of StartTime and EndTime
 */
export const ensureStartTimeEndTimeOrdering = (parameters: Parameter[]): Parameter[] => {
  const startTimeIndex = parameters.findIndex(param => param.Name === ParameterKeysEnum.StartTime);
  const endTimeIndex = parameters.findIndex(param => param.Name === ParameterKeysEnum.EndTime);

  if (startTimeIndex === -1 || endTimeIndex === -1) {
    return parameters;
  }

  if (endTimeIndex !== startTimeIndex + 1) {
    // EndTime param is not directly after StartTime. Put EndTime directly after StartTime
    const startTimeParam = parameters[startTimeIndex];
    const endTimeParam = parameters[endTimeIndex];
    const newParameters = parameters.flatMap(param => {
      if (param.Name === ParameterKeysEnum.StartTime) {
        // Whenever we find StartTime, put both StartTime and EndTime here (note that we use flatMap)
        return [startTimeParam, endTimeParam];
      } else if (param.Name === ParameterKeysEnum.EndTime) {
        // Whenever we find EndTime, filter out since it's being added upon finding StartTime
        return [];
      } else {
        return [param];
      }
    });

    return newParameters;
  }

  // do nothing
  return parameters;
};

export const StrategiesProvider = memo(function StrategiesProvider(props: React.PropsWithChildren<unknown>) {
  const { data: strategiesSubscription } = useStaticSubscription<OrderStrategy>({
    name: ORDER_STRATEGY,
    tag: 'StrategiesProvider',
  });

  const listStrategiesObs = useObservable(
    () =>
      strategiesSubscription.pipe(
        wsScanToMap({
          getUniqueKey: d => d.Name,
          newMapEachUpdate: false,
        }),
        map(strategies => {
          return [...strategies.values()];
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [strategiesSubscription]
  );

  const strategiesByNameObs = useObservable(
    () =>
      listStrategiesObs.pipe(
        scan((strategiesByNameMap, newStrategies) => {
          newStrategies.forEach(strategy => {
            strategiesByNameMap.set(strategy.Name, strategy);
          });
          return strategiesByNameMap;
        }, new Map()),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [listStrategiesObs]
  );
  const strategiesByName = useObservableValue(() => strategiesByNameObs, [strategiesByNameObs], new Map());

  const listNormalStrategies = useObservable(
    () =>
      listStrategiesObs.pipe(
        map(strategies => strategies.filter(s => s.StrategyScope === StrategyScopeEnum.OrderStrategy))
      ),
    [listStrategiesObs]
  );
  const strategiesList = useObservableValue(() => listNormalStrategies, [listNormalStrategies], []);

  const listAutoHedgingStrategies = useObservable(
    () =>
      listStrategiesObs.pipe(
        map(strategies => strategies.filter(s => s.StrategyScope === StrategyScopeEnum.AutoHedgingStrategy))
      ),
    [listStrategiesObs]
  );
  const autoHedgingStrategiesList = useObservableValue(
    () => listAutoHedgingStrategies,
    [listAutoHedgingStrategies],
    []
  );

  const listDDHStrategies = useObservable(
    () =>
      listStrategiesObs.pipe(
        map(strategies => strategies.filter(s => s.StrategyScope === StrategyScopeEnum.DDHSubStrategy))
      ),
    [listStrategiesObs]
  );
  const ddhStrategiesList = useObservableValue(() => listDDHStrategies, [listDDHStrategies], []);

  const isLoaded = useObservableValue(() => listStrategiesObs.pipe(map(() => true)), [listStrategiesObs], false);

  return (
    <StrategiesContext.Provider
      value={{
        isLoaded,
        strategiesByNameObs,
        listStrategiesObs,
        strategiesList,
        autoHedgingStrategiesList,
        ddhStrategiesList,
        strategiesByName,
      }}
    >
      {props.children}
    </StrategiesContext.Provider>
  );
});

import { isEqual } from 'lodash-es';
import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  type MutableRefObject,
  type PropsWithChildren,
  type ReactNode,
  type RefObject,
} from 'react';
import { useDynamicCallback, useElementSize } from '../../hooks';
import { EXPANDABLE_MIN_HEIGHT } from '../../styles/dimensions';
import { ButtonVariants, IconButton } from '../Button';
import { Flex } from '../Core';
import { FormControlSizes } from '../Form/types';
import { IconName } from '../Icons';
import { DragHandle, ExpandablePanel, ExpandablePanelHeader, ExpandablePanelWrapper } from './styles';

export interface ExpandableBottomPanelProps {
  height: number;
  isDragged: MutableRefObject<boolean>;
  isExpanded?: boolean;
  isMinimized?: boolean;
  onAdjustedHeight: (height: number) => void;
  header?: ReactNode | undefined;
  showControls?: boolean | undefined;
  onToggleExpanded?: (newBlotterExpandedValue: boolean) => void;
  onToggleMinimize?: (newBlotterExpandedValue: boolean) => void;
  maxHeight: number;

  /**
   * Pass this ref of an HTMLElement in if you are rendering this bottom panel inside a Modal (vertically centered modal).
   *
   * This is needed because the height we set on the panel needs to be calculated now from the bottom of the modal as opposed to the default
   * which is from the bottom of the window
   */
  modalContainerRef?: RefObject<HTMLElement>;
}

export const ExpandableBottomPanel = memo(
  ({
    height,
    maxHeight,
    onAdjustedHeight,
    isDragged,
    children,
    header,
    showControls = false,
    isExpanded = false,
    isMinimized = false,
    onToggleExpanded,
    onToggleMinimize,
    modalContainerRef,
  }: PropsWithChildren<ExpandableBottomPanelProps>) => {
    const dragHandleRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const getHeight = useDynamicCallback((position: number) => {
      // This code here adds support for rendering the expandable panel in the bottom of modals.
      // If we're in a modal container ref, get the the distance from the bottom of the modal to the bottom of the screen, and
      // include this in the new height calculations
      let distanceFromWindowBottom = 0;
      if (modalContainerRef?.current) {
        const diff = window.innerHeight - modalContainerRef?.current.clientHeight;
        distanceFromWindowBottom = diff / 2;
      }

      return Math.max(
        EXPANDABLE_MIN_HEIGHT,
        Math.min(maxHeight, window.innerHeight - position - distanceFromWindowBottom)
      );
    });

    const handleToggleExpanded: React.MouseEventHandler<HTMLButtonElement> = useCallback(
      e => {
        e.stopPropagation();
        onToggleExpanded?.(!isExpanded);
      },
      [onToggleExpanded, isExpanded]
    );

    const handleToggleMinimize: React.MouseEventHandler<HTMLButtonElement> = useCallback(
      e => {
        e.stopPropagation();
        onToggleMinimize?.(!isMinimized);
      },
      [onToggleMinimize, isMinimized]
    );

    useEffect(() => {
      if (dragHandleRef.current) {
        const dragHandler = (e: MouseEvent) => {
          if (isDragged.current) {
            if (wrapperRef.current) {
              wrapperRef.current.style.transition = 'none';
              wrapperRef.current.style.height = `${getHeight(e.y)}px`;
            }
          }
        };
        const dropHandler = (e: MouseEvent) => {
          if (isDragged.current) {
            isDragged.current = false;
            onAdjustedHeight(getHeight(e.y));
            if (wrapperRef.current) {
              wrapperRef.current.style.height = '';
              wrapperRef.current.style.transition = '';
            }
          }
          onToggleMinimize?.(false);
          document.removeEventListener('mousemove', dragHandler);
          document.removeEventListener('mouseup', dropHandler);
        };
        dragHandleRef.current.addEventListener('mousedown', () => {
          isDragged.current = true;
          document.addEventListener('mousemove', dragHandler);
          document.addEventListener('mouseup', dropHandler);
        });
      }
    }, [getHeight, isDragged, onAdjustedHeight, onToggleMinimize]);

    return (
      <ExpandablePanelWrapper height={height} ref={wrapperRef}>
        <ExpandablePanel height={height} maxHeight={maxHeight} isContentExpanded={isExpanded}>
          {(header || showControls) && (
            <ExpandablePanelHeader isMinimized={isMinimized}>
              {header}{' '}
              {showControls && (
                <Flex>
                  {handleToggleMinimize && (
                    <IconButton
                      ghost
                      color={isMinimized ? 'colorTextImportant' : 'colorTextDefault'}
                      icon={isMinimized ? IconName.ChevronUp : IconName.Minus}
                      onClick={handleToggleMinimize}
                      size={FormControlSizes.Small}
                      style={{ alignSelf: 'center' }}
                    />
                  )}
                  {showControls && (
                    <IconButton
                      ghost
                      color={isExpanded ? 'colorTextImportant' : 'colorTextDefault'}
                      variant={ButtonVariants.Default}
                      icon={isExpanded ? IconName.ChevronDown : IconName.ArrowsExpand}
                      onClick={handleToggleExpanded}
                      size={FormControlSizes.Small}
                      style={{ alignSelf: 'center' }}
                    />
                  )}
                </Flex>
              )}
            </ExpandablePanelHeader>
          )}
          <DragHandle
            className={`${isDragged.current ? 'active' : ''} ${isExpanded ? 'hidden' : ''}`}
            ref={dragHandleRef}
          />
          {children}
        </ExpandablePanel>
      </ExpandablePanelWrapper>
    );
  },
  (p, n) => isEqual(p, n)
);

interface UseExpandablePanelProps {
  initialHeight: number;
  showExpand?: boolean;
  appConfigHeight?: number;
  isMinimized?: boolean;
  isExpanded?: boolean;

  onAdjustedHeight?: (newHeight: number) => void;
  onToggleExpanded?: (shouldExpand: boolean) => void;
  onToggleMinimize?: (shouldMinimize: boolean) => void;
}
export function useExpandablePanel<TContainerElementType extends HTMLElement>({
  initialHeight,
  isExpanded,
  isMinimized,
  onAdjustedHeight,
  onToggleExpanded,
  onToggleMinimize,
}: UseExpandablePanelProps): ExpandableBottomPanelProps & { containerRef: RefObject<TContainerElementType> } {
  const [panelHeight, setPanelHeight] = useState<number>(initialHeight);
  const isPanelDragged = useRef(false);

  const handleAdjustPanelHeight = useCallback(
    (height: number) => {
      if (height <= EXPANDABLE_MIN_HEIGHT) {
        onToggleMinimize?.(true);
      } else {
        onToggleMinimize?.(false);
        setPanelHeight(height);
        onAdjustedHeight?.(height);
      }
    },
    [onAdjustedHeight, onToggleMinimize]
  );

  const { elementRef: containerRef, size: containerSize } = useElementSize<TContainerElementType>();

  return {
    height: isMinimized ? EXPANDABLE_MIN_HEIGHT : panelHeight,
    isDragged: isPanelDragged,
    onAdjustedHeight: handleAdjustPanelHeight,
    onToggleExpanded,
    onToggleMinimize,
    isExpanded,
    isMinimized,
    maxHeight: containerSize.clientHeight ?? EXPANDABLE_MIN_HEIGHT,
    containerRef,
  };
}

import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDynamicCallback } from '../../hooks';
import { Button, ButtonVariants, IconButton } from '../Button';
import { Box, Flex } from '../Core';
import { DrawerContent, DrawerFooter, type DrawerProps } from '../Drawer';
import { FormControlSizes } from '../Form';
import { IconName } from '../Icons';
import { IndicatorBadge } from '../IndicatorBadge';
import { IndicatorDotVariants } from '../IndicatorDot';
import { RR6Prompt } from '../Prompt';
import { Tab, TabList, Tabs, TabSize } from '../Tabs';
import { Text } from '../Text';
import type { EditingDrawerTab } from './types';

export type { EditingDrawerTab } from './types';

type EntityEditingDrawerContentProps = {
  drawerTitle: string;
  tabs: EditingDrawerTab[];
} & DrawerProps;

export function EntityEditingDrawerContent({ drawerTitle, tabs, close, isOpen }: EntityEditingDrawerContentProps) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = useCallback((tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  }, []);

  const viewableTabs = useMemo(() => tabs.filter(t => t.viewable), [tabs]);

  const handleSaveChanges = useCallback(() => {
    viewableTabs.forEach(tab => tab.save());
  }, [viewableTabs]);

  const handleMouseOverSaveButton = useCallback(() => {
    viewableTabs.forEach(tab => tab.touchAll());
  }, [viewableTabs]);

  const someTabHasError = viewableTabs.some(tab => tab.hasError);
  const isDirty = viewableTabs.some(tab => tab.isDirty);
  const isDirtyMap = new Map(viewableTabs.map(tab => [tab.name, tab.isDirty]));

  const handleClose = useDynamicCallback(() => {
    if (!isDirty) {
      return close();
    }

    const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
    if (confirmLeave) {
      close();
    }
  });

  return (
    <>
      <RR6Prompt when={isOpen && isDirty} message="You have unsaved changes. Are you sure you want to leave?" />
      <DrawerHeader>
        <Flex gap="spacingSmall">
          <Text color="colorTextImportant">{drawerTitle}</Text>
        </Flex>
        <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={handleClose} />
      </DrawerHeader>
      <Tabs
        data-testid="edit-entity-drawer-tabs"
        w="100%"
        h="100%"
        selectedIndex={activeTabIndex}
        onSelect={handleTabChange}
        size={TabSize.Large}
      >
        <TabList isBordered flex="0 0 auto">
          {viewableTabs.map(tab => (
            <Tab
              data-testid={`edit-entity-drawer-tab-${tab.name}`}
              showDot={isDirtyMap.get(tab.name)}
              dotVariant={IndicatorDotVariants.Primary}
              label={tab.name}
              suffix={tab.badgeContent ? <IndicatorBadge children={tab.badgeContent} /> : null}
              key={tab.name}
            />
          ))}
        </TabList>
        <DrawerContent>
          <Flex h="100%" flexDirection="column">
            {viewableTabs.map(tab => (
              <Box
                key={tab.name}
                display={viewableTabs[activeTabIndex].name === tab.name ? 'flex' : 'none'}
                w="100%"
                h="100%"
              >
                {tab.component}
              </Box>
            ))}
          </Flex>
        </DrawerContent>
      </Tabs>
      <DrawerFooter>
        <Button onClick={handleClose} data-testid="edit-entity-drawer-close-button">
          Close
        </Button>
        <Button
          data-testid="edit-entity-drawer-save-button"
          onClick={handleSaveChanges}
          onMouseOver={handleMouseOverSaveButton}
          disabled={!isDirty || someTabHasError}
          variant={ButtonVariants.Primary}
        >
          Save
        </Button>
      </DrawerFooter>
    </>
  );
}

const DrawerHeader = styled(Flex)`
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing8}px ${theme.spacing16}px`};
  min-height: 3.5rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray['010']};
  align-items: center;
  gap: ${({ theme }) => theme.spacing4}px;
`;

import type { RowGroupsOpenedState } from '@talos/kyoko';
import { useOperationsOverviewConfig } from '../providers/OperationsOverviewConfigProvider';
import { OperationsOverviewBlotter } from './OperationsOverviewBlotter';
import type { OperationsOverviewBlotterColumnSpecification } from './useOperationsOverviewBlotterColumns';

const sharedColumns = [
  'balance',
  'balanceEquivalent',
  'positionQty',
  'positionEquivalent',
  'avgCost',
  'markPrice',
  'unrealizedPnLEquivalent',
  'equityEquivalent',
  'emr',
  'initialMarginEquivalent',
  'maintenanceMarginEquivalent',
  'leverageRatio',
  'distanceToLiquidationPct',
  'creditUsage',
] satisfies OperationsOverviewBlotterColumnSpecification[];

const defaultRowGroupsOpened: RowGroupsOpenedState = {};

const byMarketColumns = [
  'reconWarning',
  { field: 'position.market', rowGroupIndex: 0, rowGroup: true, hide: true },
  { field: 'position.MarketAccount', rowGroupIndex: 1, rowGroup: true, hide: true },
  { field: 'position.underlying', rowGroupIndex: 2, rowGroup: true, hide: true },
  { field: 'position.Asset', rowGroupIndex: 3, rowGroup: true, hide: true },
  ...sharedColumns,
] satisfies OperationsOverviewBlotterColumnSpecification[];

// This component wraps the blotter and specifically handles grabbing the correct blotterID and passing it to the blotter,
// also making sure to re-mount the blotter on blotterID change
export const OperationsOverviewBlotterContainer = () => {
  const { mode } = useOperationsOverviewConfig();

  // Temporary additional nesting layer (/Market). We might get rid of this in time for 2.50 (todo Olof)
  const blotterID = `${mode}/OPERATIONS_OVERVIEW_BLOTTER/Market`;
  return (
    <OperationsOverviewBlotter
      key={blotterID}
      blotterID={blotterID}
      defaultColumns={byMarketColumns}
      defaultRowGroupsOpened={defaultRowGroupsOpened}
    />
  );
};

import { logger, type OptionalProperties, type RequiredProperties } from '../utils';
import type { ParameterLike, StrategyLike } from './OrderStrategy.types';
import { ParameterTypeEnum } from './OrderStrategy.types';
import type {
  AlgoTypeEnum,
  IParametersEnumValues,
  IStrategy,
  IStrategy4860Parameters,
  PresenceEnum,
  StrategyScopeEnum,
  UpdateActionEnum,
} from './types';

/**
 * Note the types in this file should really only be used for Orders in Principal and not Orders in White Label
 */

type InstrumentScope = 'Native' | 'Synthetic' | 'All';

/**
 * JSON types are because the autogenerated ones do not properly represent the optionality of some of these properties
 */
export type JSON_StrategyParameter = RequiredProperties<
  OptionalProperties<IStrategy4860Parameters, 'Metadata' | 'EnumValues' | 'DefaultValue' | 'ParameterID'>,
  'Name' | 'DisplayName' | 'Description' | 'Type' | 'Presence'
>;

export type JSON_OrderStrategy = RequiredProperties<
  OptionalProperties<
    // This overwrites the autogenerated type with a more accurate one for Parameters
    Omit<IStrategy, 'Parameters' | 'AlgoType' | 'Strategy'> & {
      Parameters: JSON_StrategyParameter[];
      AlgoType: string | AlgoTypeEnum;
    },
    'Metadata' | 'UpdateAction' | 'Revision' | 'AlgoType'
  >,
  'Name' | 'DisplayName' | 'Description' | 'Group' | 'StrategyScope'
>;

// TODO copy the pattern from TotalTradingVolume
export class OrderStrategy implements StrategyLike {
  public static StreamName = 'OrderStrategy';

  AlgoType?: AlgoTypeEnum;
  Description: string;
  DisplayName: string;
  Group: string;
  InstrumentScope: InstrumentScope;
  Metadata: Record<string, unknown>;
  Name: string;
  Parameters: Parameter[];
  Revision?: number;
  StrategyScope: StrategyScopeEnum;
  UpdateAction?: UpdateActionEnum;

  get supportsUnifiedLiquidity(): boolean {
    return this.Parameters.some(param => param.Name === ParameterTypeEnum.UnifiedLiquidity);
  }

  get isManual(): boolean {
    // TODO don't base this on Name. Make a fake type instead
    return this.Name === 'Manual';
  }

  constructor(data: JSON_OrderStrategy | OrderStrategy) {
    this.AlgoType = data.AlgoType as AlgoTypeEnum;
    this.Description = data.Description;
    this.DisplayName = data.DisplayName;
    this.Group = data.Group;
    this.Name = data.Name;
    this.Revision = data.Revision;
    this.StrategyScope = data.StrategyScope;
    this.UpdateAction = data.UpdateAction;
    this.InstrumentScope = parseInstrumentScope(data.InstrumentScope);
    this.Metadata = parseMetadata(data);
    this.Parameters = parseParameters(data.Parameters as JSON_StrategyParameter[]);
  }
}

function parseInstrumentScope(scope: string): InstrumentScope {
  if (scope === 'Native' || scope === 'Synthetic' || scope === 'All') {
    return scope;
  }
  logger.warn(`Unknown InstrumentScope: ${scope}`);
  return scope as InstrumentScope;
}

function parseMetadata(data: JSON_OrderStrategy | JSON_StrategyParameter | OrderStrategy): Record<string, unknown> {
  if (typeof data.Metadata !== 'string') {
    return {};
  }
  try {
    return JSON.parse(data.Metadata);
  } catch (e) {
    logger.warn(`Failed to parse ${data.Name} metadata: ${data.Metadata} - ${e}`);
    return {};
  }
}

function parseParameters(parameters: JSON_StrategyParameter[]): Parameter[] {
  return ensureStartTimeEndTimeOrdering(parameters).map(param => new Parameter(param));
}

/**
 * Ensures that EndTime is placed directly after StartTime
 * Returns a copy of the given array. Does not mutate original.
 * If both StartTime and EndTime do not exist, or they already placed correctly relative to each other,
 * we return the passed in array
 * @param parameters the parameter list to check
 * @returns parameter list with fixed ordering of StartTime and EndTime
 */
export const ensureStartTimeEndTimeOrdering = (parameters: JSON_StrategyParameter[]): JSON_StrategyParameter[] => {
  const startTimeIndex = parameters.findIndex(param => param.Name === 'StartTime');
  const endTimeIndex = parameters.findIndex(param => param.Name === 'EndTime');

  if (startTimeIndex === -1 || endTimeIndex === -1) {
    return parameters;
  }

  if (endTimeIndex !== startTimeIndex + 1) {
    // EndTime param is not directly after StartTime. Put EndTime directly after StartTime
    const startTimeParam = parameters[startTimeIndex];
    const endTimeParam = parameters[endTimeIndex];
    const newParameters = parameters.flatMap(param => {
      if (param.Name === 'StartTime') {
        // Whenever we find StartTime, put both StartTime and EndTime here (note that we use flatMap)
        return [startTimeParam, endTimeParam];
      } else if (param.Name === 'EndTime') {
        // Whenever we find EndTime, filter out since it's being added upon finding StartTime
        return [];
      } else {
        return [param];
      }
    });

    return newParameters;
  }

  // do nothing
  return parameters;
};

/**
 * The following props are omitted from interface for given reason
 * DefaultValue - Can sometimes be undefined
 * Metadata - Comes over wire as string, but is parsed into object
 * EnumValues - Can sometimes be undefined
 * ParameterID - Not used in the frontend
 */
export class Parameter implements ParameterLike {
  DefaultValue: IStrategy4860Parameters['DefaultValue'] | undefined;
  Type: ParameterTypeEnum;
  Name: string;
  DisplayName: string;
  Description: string;
  ParameterID?: number;
  Presence: PresenceEnum;
  EnumValues: IParametersEnumValues[] | undefined;
  Metadata: Record<string, unknown>;

  constructor(data: JSON_StrategyParameter) {
    this.DefaultValue = data.DefaultValue;
    this.Name = data.Name;
    this.DisplayName = data.DisplayName;
    this.Description = data.Description;
    this.ParameterID = data.ParameterID;
    this.Presence = data.Presence;
    this.EnumValues = data.EnumValues;
    // TODO maybe parse this or get rid of ParameterTypeEnum
    this.Type = data.Type as ParameterTypeEnum;
    this.Metadata = parseMetadata(data);
  }
}

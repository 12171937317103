import { useMemo } from 'react';
import { useMarketAccountsContext } from '../contexts';

/**
 * Returns the list of present MarketAccount.Groups in the system, after filtering out bad / hidden groups.
 * See implementation for more details on this filtering.
 */
export const useMarketAccountGroups = () => {
  const { marketAccountsList } = useMarketAccountsContext();

  const groups = useMemo(() => {
    const groupsSet = new Set<string>();
    for (const mktAcc of marketAccountsList) {
      if (mktAcc.Group != null && !ignoreGroup(mktAcc.Group)) {
        groupsSet.add(mktAcc.Group);
      }
    }
    return [...groupsSet];
  }, [marketAccountsList]);

  return groups;
};

function ignoreGroup(group: string) {
  return group.startsWith('_') || group === '';
}

import { values } from 'lodash-es';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useIntl } from '../../../../hooks';
import { ExecTypeEnum } from '../../../../types/types';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

const messages = defineMessages({
  execType: {
    defaultMessage: 'Exec Type',
    id: 'Filters.FilterBuilder.filters.execType',
  },
});

/**
 * This hook creates the filter property definition for Exec Type properties.
 *
 * @returns Filter Property definition to use for Exec Type filters
 */

export function useExecTypeFilter() {
  const { formatMessage } = useIntl();

  return useMemo(
    () =>
      ({
        key: 'ExecTypes',
        label: formatMessage(messages.execType),
        icon: IconName.CheckCircle,
        options: values(ExecTypeEnum),
        getOptionLabel: (option: string) => option,
      } as const satisfies FilterableProperty),
    [formatMessage]
  );
}

import {
  ACTION,
  API_PERMISSION_WILDCARD,
  ApiPermissionActionEnum,
  Flex,
  FormRowStatus,
  FormTable,
  NotificationVariants,
  stringColumnComparator,
  UpdateActionEnum,
  useDynamicCallback,
  useFormTable,
  useGlobalToasts,
  useMarketAccountsContext,
  useMarketsContext,
  type Column,
  type SubAccount,
  type UseFormTable,
} from '@talos/kyoko';
import { useRoleAuth } from 'hooks';
import { compact, get } from 'lodash-es';
import { useMarketAccountPermissionFilters, type MarketAccountSubAccountPermissionRow } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { MarketAccountsDropdown } from '../MarketAccountDropdown';
import type { IEditSubAccountsDrawerTab } from '../types';
import { useCanViewAccountSegregation } from '../useCanViewAccountSegregation';

interface SubAccountsMarketAccountsTabProps {
  marketAccountsToAdd: string[];
  onAddMarketAccounts: (marketAccounts: string[]) => void;
  setMarketAccountsToAdd: React.Dispatch<React.SetStateAction<string[]>>;
  allMarketAccountsSelected: boolean;
  marketAccountsFormTable: UseFormTable<MarketAccountSubAccountPermissionRow>;
  marketAccountsFilterPermissions: MarketAccountSubAccountPermissionRow[];
}

export function SubAccountsMarketAccountsTab({
  allMarketAccountsSelected,
  marketAccountsFormTable,
  marketAccountsToAdd,
  onAddMarketAccounts,
  setMarketAccountsToAdd,
}: SubAccountsMarketAccountsTabProps) {
  return (
    <>
      <Flex flexDirection="column" gap="spacingMedium" w="100%" h="100%">
        <MarketAccountsDropdown
          marketAccountsToAdd={marketAccountsToAdd}
          onAddMarketAccounts={onAddMarketAccounts}
          setMarketAccountsToAdd={setMarketAccountsToAdd}
          allChecked={allMarketAccountsSelected}
        />
        <FormTable background="transparent" promptIfUnsavedChanges={false} {...marketAccountsFormTable} />
      </Flex>
    </>
  );
}

const ALL_MARKET_ACCOUNTS_LABEL = 'All Market Accounts';

export function useSubAccountsMarketAccountsTab({ subAccount }: { subAccount: SubAccount }): IEditSubAccountsDrawerTab {
  const { add: addToast } = useGlobalToasts();
  const {
    updateMarketAccountPermissionFilters,
    marketAccountFilterIDsBySubAccountName,
    marketAccountPermissionFiltersByFilterID,
  } = useMarketAccountPermissionFilters();
  const { isAuthorized } = useRoleAuth();
  const canEditFilterPermissions = isAuthorized(ACTION.EDIT_FILTER_PERMISSIONS);

  const { marketAccountDisplayNameByName } = useMarketAccountsContext();
  const getLabel = useCallback(
    (marketAccountName: string) =>
      marketAccountName === API_PERMISSION_WILDCARD
        ? ALL_MARKET_ACCOUNTS_LABEL
        : marketAccountDisplayNameByName.get(marketAccountName) || marketAccountName,
    [marketAccountDisplayNameByName]
  );

  const marketAccountColumns = useMemo<Column[]>(
    () =>
      compact([
        {
          suppressHeaderMenuButton: true,
          title: 'Market account name',
          id: 'marketAccount',
          sort: '+',
          sortIndex: 0,
          minWidth: 500,
          width: 500,
          type: 'custom',
          params: {
            valueGetter: ({ data }: { data: MarketAccountSubAccountPermissionRow }) => get(data, 'MarketAccount.Name'),
            valueFormatter: ({ value }: { value: string }) => getLabel(value),
            comparator: (a: string, b: string) => stringColumnComparator(getLabel(a), getLabel(b)),
          },
        },
        {
          field: 'MarketAccount.MarketType',
          title: 'Type',
          type: 'text',
          minWidth: 144,
          width: 144,
          suppressHeaderMenuButton: true,
        },
        canEditFilterPermissions
          ? {
              type: 'remove',
              id: 'remove',
              pinned: 'right',
              title: 'remove',
              frozen: true,
              suppressHeaderMenuButton: true,
            }
          : null,
      ]),
    [canEditFilterPermissions, getLabel]
  );

  const { marketAccountsByName } = useMarketAccountsContext();
  const { marketsByName } = useMarketsContext();

  const marketAccountsFilterPermissions: MarketAccountSubAccountPermissionRow[] = useMemo(() => {
    const subAccountRelatedMarketAccountFilterPermissions = compact(
      Array.from(marketAccountFilterIDsBySubAccountName?.get(subAccount.Name) || []).map(filterID =>
        marketAccountPermissionFiltersByFilterID?.get(filterID)
      )
    );
    return subAccountRelatedMarketAccountFilterPermissions.map(FilterPermission => {
      const marketAccountName = FilterPermission.Filter.MarketAccount;
      const marketAccount = marketAccountsByName.get(marketAccountName);
      const marketType = (marketAccount && marketsByName.get(marketAccount?.Market)?.Type) || '';

      return {
        MarketAccount: {
          Name: marketAccountName,
          MarketType: marketType,
        },
        FilterPermission,
      };
    });
  }, [
    marketAccountFilterIDsBySubAccountName,
    subAccount.Name,
    marketAccountPermissionFiltersByFilterID,
    marketAccountsByName,
    marketsByName,
  ]);

  const marketAccountsFormTable = useFormTable<MarketAccountSubAccountPermissionRow>({
    rowID: 'FilterPermission.FilterID',
    data: marketAccountsFilterPermissions,
    columns: marketAccountColumns,
  });

  const resetMarketAccountsTable = useDynamicCallback(marketAccountsFormTable.reset);
  useEffect(() => {
    if (!marketAccountsFormTable.isDirty) {
      resetMarketAccountsTable(marketAccountsFilterPermissions);
    }
  }, [resetMarketAccountsTable, marketAccountsFilterPermissions, marketAccountsFormTable.isDirty]);

  const [marketAccountsToAdd, setMarketAccountsToAdd] = useState<string[]>([]);
  const allMarketAccountsSelected = useMemo(
    () => marketAccountsToAdd.includes(API_PERMISSION_WILDCARD),
    [marketAccountsToAdd]
  );
  const onAddMarketAccounts = useCallback(
    (marketAccounts: string[]) => {
      const rows = marketAccountsFormTable.getRows();
      const marketAccountsInRowsMap = new Map(
        rows.map(row => [row.data.FilterPermission.Filter.MarketAccount, row.data.FilterPermission.FilterID])
      );
      for (const marketAccount of marketAccounts) {
        const rowId = marketAccountsInRowsMap.get(marketAccount);
        if (rowId) {
          const row = marketAccountsFormTable.gridApi?.getRowNode(rowId);
          // Instead of adding a duplicate, lets highlight the already existing entry and toast
          if (row) {
            marketAccountsFormTable.gridApi?.flashCells({ rowNodes: [row] });
            addToast({
              text: `There is already an existing row for Market Account: "${getLabel(
                marketAccount
              )}" in the table. Update the existing one or remove it.`,
              variant: NotificationVariants.Primary,
            });
          }
        } else {
          const market = marketAccountsByName.get(marketAccount)?.Market;

          const marketAccountObj = {
            MarketType: (market && marketsByName.get(market)?.Type) || '',
            Name: marketAccount,
          } as const;

          marketAccountsFormTable.addRow({
            FilterPermission: {
              // For now, we do not have a concept of Action
              // in subaccount -> marketaccount, it is always write.
              Action: ApiPermissionActionEnum.Write,
              Filter: { SubAccount: undefined, MarketAccount: marketAccount },
              Subject: { User: undefined, SubAccount: subAccount.Name },
              FilterID: uuid(),
              UpdateAction: UpdateActionEnum.Initial,
            },
            MarketAccount: marketAccountObj,
          });
        }
      }
      setMarketAccountsToAdd([]);
    },
    [addToast, getLabel, marketAccountsByName, marketAccountsFormTable, marketsByName, subAccount.Name]
  );

  const handleSaveChanges = useCallback(async () => {
    // subaccounts -> marketAccounts
    if (marketAccountsFormTable.isDirty) {
      const rows = marketAccountsFormTable.getRows();

      const updates = compact(
        rows.map<MarketAccountSubAccountPermissionRow['FilterPermission'] | null>(row => {
          if (row.status === FormRowStatus.Added) {
            return { ...row.data.FilterPermission, UpdateAction: UpdateActionEnum.Initial };
          }
          if (row.status === FormRowStatus.Removed) {
            return { ...row.data.FilterPermission, UpdateAction: UpdateActionEnum.Remove };
          }
          if (row.status === FormRowStatus.Updated) {
            return { ...row.data.FilterPermission, UpdateAction: UpdateActionEnum.Update };
          }

          return null;
        })
      );

      updateMarketAccountPermissionFilters(updates);
      resetMarketAccountsTable();
    }
  }, [marketAccountsFormTable, updateMarketAccountPermissionFilters, resetMarketAccountsTable]);

  const canViewAccountSegregation = useCanViewAccountSegregation();

  return {
    name: 'Market Accounts',
    viewable: canViewAccountSegregation,
    isDirty: marketAccountsFormTable.isDirty,
    badgeContent: marketAccountsFilterPermissions.length.toString(),
    save: handleSaveChanges,
    component: (
      <SubAccountsMarketAccountsTab
        marketAccountsToAdd={marketAccountsToAdd}
        onAddMarketAccounts={onAddMarketAccounts}
        setMarketAccountsToAdd={setMarketAccountsToAdd}
        allMarketAccountsSelected={allMarketAccountsSelected}
        marketAccountsFormTable={marketAccountsFormTable}
        marketAccountsFilterPermissions={marketAccountsFilterPermissions}
      />
    ),
  };
}

import { Navigate, Route } from 'react-router-dom';

/** Produce SubRoutes for OrderDetails tabs, with default-to-details behavior
 * - this is NOT a component but a Route fragment generator, since RR requires that actual Route components live underneath each other
 */

export const buildOrderDetailsRoutes = ({
  parentRoute,
  detailsElement,
}: {
  parentRoute: 'order' | 'customer-order';
  detailsElement?: React.ReactElement;
}) => {
  return (
    <Route path={`${parentRoute}/:orderID`}>
      <Route index element={<Navigate to="./details" replace />} />
      <Route path="*" element={detailsElement} />
    </Route>
  );
};

import styled from 'styled-components';
import { getButtonSpacing } from '../../Button';
import { Flex } from '../../Core';
import type { FormControlSizes } from '../../Form/types';
import { InputWrapper } from '../Input';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

export const TextWrapper = styled.div`
  overflow-y: hidden;
`;

export const Results = styled.ul<{ maxHeight?: number }>`
  margin: 0;
  list-style: none;
  padding: 0;

  max-height: ${({ maxHeight }) => `${maxHeight}px`};
  overflow-y: ${({ maxHeight }) => maxHeight != null && 'auto'};
`;

export const AutocompleteResultsWrapper = styled.div<{ size?: FormControlSizes }>`
  ${InputWrapper} {
    border-radius: 0;
    border-color: transparent;
    border-bottom-color: ${({ theme }) => theme.borderColorDivider};
    background: ${({ theme }) => theme.backgroundDropdownInput};
    padding: ${({ theme }) => `${theme.spacingTiny}px ${theme.spacingComfortable}px`};
    height: auto;
  }
`;

export const Title = styled.div<{ disabled?: boolean }>`
  color: ${({ disabled, theme }) => (disabled ? theme.colorTextMuted : theme.colorTextDefault)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Description = styled.div<{ disabled?: boolean }>`
  color: ${({ disabled, theme }) => (disabled ? theme.colorTextMuted : theme.colorTextSubtle)};
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Result = styled.li<{
  isHighlighted: boolean;
  selectionIndicator?: boolean;
  isSelected: boolean;
  disabled?: boolean;
  size?: FormControlSizes;
}>`
  padding: ${({ theme, size }) => getButtonSpacing(theme, size)};
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.backgroundDropdownResults};
  border-left: ${({ selectionIndicator, isSelected, theme }) => {
    if (!selectionIndicator) {
      return 'none';
    }

    return `
      3px solid ${isSelected ? theme.colors.primary.lighten : 'transparent'}
      `;
  }};

  ${({ isHighlighted, isSelected, disabled, theme }) => {
    if (disabled) {
      return `
        cursor: not-allowed;
      `;
    } else if (isSelected) {
      return `
      background: ${theme.backgroundDropdownSelected};
      color: ${theme.colorTextAttention};

      ${Title} {
        color: ${theme.colorTextAttention};
      }
      ${Description} {
        color: ${theme.colorTextImportant};
      }
    `;
    } else if (isHighlighted) {
      return `
      background: ${theme.backgroundDropdownHovered};
      color: ${theme.colorTextImportant};

      ${Title} {
        color: ${theme.colorTextImportant};
      }
      ${Description} {
        color: ${theme.colorTextDefault};
      }
    `;
    }
  }};
`;

export const NoResults = styled(Flex)``;
NoResults.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
  h: '37px',
  px: 'spacingMedium',
};

export const Dropdown = styled.div``;

import { MarketAccountStatusEnum, getMACKey, type MarketAccount, type MarketAccountCurrency } from '@talos/kyoko';

interface FilterMarketAccountsParams {
  marketAccounts: MarketAccount[];
  acceptedCurrenciesByMarketAccountID: Map<number, Set<string>>;
  MACsByKey: Map<string, MarketAccountCurrency>;
  market?: string;
  currency?: string;
  chainCurrency?: string;
}

export function filterSourceMarketAccounts({
  marketAccounts,
  acceptedCurrenciesByMarketAccountID,
  MACsByKey,
  market,
  currency,
  destination,
}: FilterMarketAccountsParams & { destination?: number }): MarketAccount[] {
  if (currency == null) {
    return [];
  }

  const chainCurrenciesToCheckFor = getChainCurrenciesToCheckFor(MACsByKey, currency, destination);

  return marketAccounts.filter(ma => {
    if (market && ma.Market !== market) {
      return false;
    }

    if (ma.Status === MarketAccountStatusEnum.Inactive) {
      return false;
    }

    if (ma.Capabilities == null) {
      return false;
    }

    if (!isMarketAccountSelectableAsSource(ma)) {
      return false;
    }

    if (shouldHideMarketAccount(ma)) {
      return false;
    }

    if (!isValidSourceForCurrency(ma, currency, acceptedCurrenciesByMarketAccountID)) {
      return false;
    }

    if (!accountSatisfiesChainCurrenciesConstraint(ma, currency, chainCurrenciesToCheckFor, MACsByKey)) {
      return false;
    }

    return true;
  });
}

export function filterDestinationMarketAccounts({
  marketAccounts,
  acceptedCurrenciesByMarketAccountID,
  MACsByKey,
  market,
  currency,
  source,
}: FilterMarketAccountsParams & { source?: number }): MarketAccount[] {
  if (currency == null) {
    return [];
  }

  const chainCurrenciesToCheckFor = getChainCurrenciesToCheckFor(MACsByKey, currency, source);

  return marketAccounts.filter(ma => {
    if (market && ma.Market !== market) {
      return false;
    }

    if (ma.Status === MarketAccountStatusEnum.Inactive) {
      return false;
    }

    if (ma.Capabilities == null) {
      return false;
    }

    if (!isMarketAccountSelectableAsDest(ma)) {
      return false;
    }

    if (shouldHideMarketAccount(ma)) {
      return false;
    }

    if (!isValidDestinationForCurrency(ma, currency, acceptedCurrenciesByMarketAccountID)) {
      return false;
    }

    if (!accountSatisfiesChainCurrenciesConstraint(ma, currency, chainCurrenciesToCheckFor, MACsByKey)) {
      return false;
    }

    return true;
  });
}

/**
 * This function encapsulates the logic of collecting what chain currencies you need to check for when filtering either
 * the source or destination account of a transfer.
 * @param otherAccount the other potentially selected account. destination if you are checking on behalf of the source, and vice versa
 * @param currency
 */
function getChainCurrenciesToCheckFor(
  MACsByKey: Map<string, MarketAccountCurrency>,
  currency: string,
  otherAccount?: number
) {
  if (otherAccount) {
    // Another account is specified. Using this, we get the MAC's chain currencies and return those.
    // If there is no MAC, treat it as if there are no chain currencies constraining the selection -- as in, return an empty list.
    // The empty list for ChainCurrencySymbols represents "all allowed"
    return MACsByKey.get(getMACKey(otherAccount, currency))?.ChainCurrencySymbols ?? [];
  }

  // Same here, return the empty list which represents "all allowed" in MAC.ChainCurrencySymbols world.
  return [];
}

function accountSatisfiesChainCurrenciesConstraint(
  account: MarketAccount,
  currency: string,
  chainCurrenciesToCheckFor: string[],
  MACsByKey: Map<string, MarketAccountCurrency>
): boolean {
  // The empty ChainCurrencies array means that no constraints are applied
  if (chainCurrenciesToCheckFor.length === 0) {
    return true;
  }

  // Default this to an empty array since that's how I've decided to handle non-existant or incorrect MACs for now. Just treat them as all-allowing.
  const accountChainCurrencies =
    MACsByKey.get(getMACKey(account.MarketAccountID, currency))?.ChainCurrencySymbols ?? [];
  if (accountChainCurrencies.length === 0) {
    return true;
  }

  // At this point I have two populated lists of chain currencies. The account satisfies the constraints if any of its own chain currencies are in the constraining (other) list of chain currencies.
  const chainCurrenciesToCheckForSet = new Set(chainCurrenciesToCheckFor);
  return accountChainCurrencies.some(cc => chainCurrenciesToCheckForSet.has(cc));
}

// Checks if the passed acceptedCurrenciesByMarketAccountID includes the provided mktAccID and currency
function mktAccCurrenciesIncludes(
  acceptedCurrenciesByMarketAccountID: Map<number, Set<string>>,
  mktAccID: number,
  currency: string
): boolean {
  return acceptedCurrenciesByMarketAccountID?.get(mktAccID)?.has(currency) ?? false;
}

export function isMarketAccountSelectableAsSource(marketAccount: MarketAccount): boolean {
  if (isMarketAccountUnusable(marketAccount)) {
    return false;
  }
  return !!marketAccount.Capabilities?.TransferOutgoingSource;
}

export function isMarketAccountSelectableAsDest(marketAccount: MarketAccount): boolean {
  if (isMarketAccountUnusable(marketAccount)) {
    return false;
  }
  return !!marketAccount.Capabilities?.TransferIncomingDest || !!marketAccount.Capabilities?.TransferOutgoingDest;
}

function isMarketAccountUnusable(marketAccount: MarketAccount): boolean {
  return marketAccount.Capabilities == null || marketAccount.Status !== MarketAccountStatusEnum.Active;
}

export function shouldHideMarketAccount(marketAccount: MarketAccount): boolean {
  return marketAccount.Group ? marketAccount.Group.startsWith('_') : false;
}

/**
 * This function returns whether or not the given market account and currency work together as Source-Currency pair in a transfer.
 * You can see it as framing the question: "Given this currency, can I select this market account as source?", with a yes or no answer coming back
 */
export function isValidSourceForCurrency(
  marketAccount: MarketAccount,
  currency: string,
  acceptedCurrenciesByMarketAccountID: Map<number, Set<string>>
): boolean {
  const acceptAllCurrencies = marketAccount.Capabilities?.AcceptAllCurrencies ?? false;
  return (
    acceptAllCurrencies ||
    mktAccCurrenciesIncludes(acceptedCurrenciesByMarketAccountID, marketAccount.MarketAccountID, currency)
  );
}

/**
 * This function returns whether or not the given market account and currency work together as destination-Currency pair in a transfer.
 * You can see it as framing the question: "Given this currency, can I select this market account as destination?", with a yes or no answer coming back
 */
export function isValidDestinationForCurrency(
  marketAccount: MarketAccount,
  currency: string,
  acceptedCurrenciesByMarketAccountID: Map<number, Set<string>>
): boolean {
  const zeroCurrenciesWithinMarketAccount =
    acceptedCurrenciesByMarketAccountID.get(marketAccount.MarketAccountID) == null;
  const acceptAllCurrencies = marketAccount.Capabilities?.AcceptAllCurrencies ?? false;
  return (
    acceptAllCurrencies ||
    zeroCurrenciesWithinMarketAccount ||
    mktAccCurrenciesIncludes(acceptedCurrenciesByMarketAccountID, marketAccount.MarketAccountID, currency)
  );
}

import { VStack } from '@talos/kyoko';
import styled from 'styled-components';

export const Wrapper = styled(VStack)`
  text-align: center;
`;
Wrapper.defaultProps = {
  h: '100%',
};

export const ErrorContainer = styled.div`
  display: inline-block;
  max-width: 240px;
  text-align: center;
  margin: auto;
  color: ${({ theme }) => theme.colors.gray['070']};
  transform: translateY(-5%);

  svg {
    margin-bottom: ${({ theme }) => theme.spacingDefault}px;
  }
`;

import type { ColDef, CsvExportParams, ExcelExportParams } from 'ag-grid-community';
import type { ColGroupDef, GridApi, GridOptions } from 'ag-grid-enterprise';
import type { UnaryFunction } from 'rxjs';
import type { Column } from './columns/types';
import type { UseBlotterTable, UseBlotterTableProps } from './useBlotterTable/types';

/**
 * An RxJS pipeline
 */
export type CompositePipeFunction<TRowType, TResponseType = TRowType> = UnaryFunction<
  UseBlotterTableProps<TResponseType>['dataObservable'],
  UseBlotterTableProps<TRowType>['dataObservable']
>;

export interface ColumnDefsOptions<R> {
  handleClickJson?: (data: R | undefined) => any;
  exportDataAsCSV?: UseBlotterTable<R>['exportDataAsCSV'];
}

export interface BlotterTableRow<R> {
  readonly data: R;
  setData(data: R): void;
  remove(): void;
  setSelected(selected: boolean): void;
}

export type BlotterTableProps<R = any> = {
  readonly gridOptions: GridOptions<R> | null;
  readonly density?: BlotterDensity;
  readonly background?: string;
  readonly hidden?: boolean;
  readonly extraComponents?: {
    [key: string]: any; // same as gridOptions.d.ts provided by ag-grid
  };
  readonly rowEndSpacing?: string | number;
} & Partial<Pick<UseBlotterTableUtilitiesOutput<R>, 'hidePopupMenu'>>; // BlotterTable wants this one utility

export enum BlotterDensity {
  Compact,
  Default,
  Comfortable,
  VeryComfortable,
}

export const AGGRID_AUTOCOLUMN_ID = 'ag-Grid-AutoColumn'; // internal aggrid id
export type BlotterTableColumnSortItem<R = any> = `${'+' | '-'}${(keyof R & string) | typeof AGGRID_AUTOCOLUMN_ID}`;
export type BlotterTableSort<R = any> = BlotterTableColumnSortItem<R> | BlotterTableColumnSortItem<R>[];
export type BlotterTableFilter = { [key: string]: any };

/**
 * It's dangerous to go alone! Take this: https://www.ag-grid.com/react-grid/ *
 */

export type PinnedRow<R> = R & { groupColumnValue?: string };

export interface TalosBlotterExportParams {
  /** Whether or not to also include hidden columns in the export */
  includeHiddenColumns?: boolean;
  /** An array of colIds (computed via function getAgGridColId(column)) to ignore. Applies before the optional `includeColumn` callback */
  ignoredColIds?: Set<string>;
  /** A callback which allows you to filter out any columns you don't want included in the export. Applies after the `ignoredColIds` is applied. */
  ignoreColumn?: (columns: ColDef) => boolean;
  /** Take full control over which columns to include in exporting by providing this array. All other column-related options become no-ops essentially. */
  columnKeys?: CsvExportParams['columnKeys'];
}

// These three types below are a bit different due to being csv vs excel. But the talos-part is still shared
// - The top two are for the full export, so they can receive all pararms, while the last one is for the sheet data only
export type ExportDataAsCsvParams = TalosBlotterExportParams & CsvExportParams;
export type ExportDataAsExcelParams = TalosBlotterExportParams & ExcelExportParams;
export type TalosGetSheetDataForExcelParams = TalosBlotterExportParams &
  Pick<ExcelExportParams, 'sheetName' | 'appendContent' | 'prependContent'>;

export interface UseBlotterTableUtilitiesOutput<R> {
  gridApi: GridApi<R> | undefined;
  /**
   * Triggers a CSV download of the grid rows
   * @param params Export parameters to be passed to ag grid
   * By default, the function hides group and pinned rows, as well as does not show any columns with an empty string as a headerName.
   */
  exportDataAsCSV(params: ExportDataAsCsvParams): void;
  /**
   * Triggers an Excel download of the grid rows
   * @param params Export parameters to be passed to ag grid
   * By default, the function hides group and pinned rows, as well as does not show any columns with an empty string as a headerName.
   */
  exportDataAsExcel(params: ExportDataAsExcelParams): void;
  /**
   * Grabs export-ready data and returns as a csv string. The returned csv string is what would otherwise be exported as a csv file, but in this case its just returned as a string.
   * @param params Export parameters to be passed to ag grid
   * By default, the function hides group and pinned rows, as well as does not show any columns with an empty string as a headerName.
   */
  getDataAsCSV(params: ExportDataAsCsvParams): string | undefined;

  addRow(data: R): void;
  getRows(): BlotterTableRow<R>[];
  getRowsAfterFilter(): BlotterTableRow<R>[];
  getSelectedRows(): BlotterTableRow<R>[];
  expandGroupRow(nodeKey: string): void;
  scrollToRow(...args: Parameters<GridApi<R>['ensureNodeVisible']>): void;
  scrollVerticallyToColumn(...args: Parameters<GridApi<R>['ensureColumnVisible']>): void;
  /** Given a node level, will collapse all nodes whose node.level are greater than this provided level. */
  collapseAllLevelsGreaterThan(level: number): void;
  getRowGroupColumnIds(): Set<string>;
  /**
   * Sets new rowGroupColumns to be used. Overrides any rowGroupIndex properties and uses the order of elements provided.
   */
  setRowGroupColumns(colIds: string[]): void;
  /**
   * Adds the provided colId columns to the currently set row group columns at the end.
   */
  addRowGroupColumns(colIds: string[]): void;
  removeRowGroupColumns(colIds: string[]): void;

  // the below calls directly mimic {@link GridApi} calls, but several have different logic and/or track to MixPanel
  expandAllGroups: GridApi['expandAll'];
  collapseAllGroups: GridApi['collapseAll'];
  setColumnsVisible: GridApi['setColumnsVisible'];
  flashCells: GridApi['flashCells'];
  refreshClientSideRowModel: GridApi['refreshClientSideRowModel'];
  selectAllRows: GridApi['selectAll'];

  getSheetDataForExcel(params: TalosGetSheetDataForExcelParams): string | undefined;

  /**
   * Highlights the provided rows (leaf node rows) for the user.
   *
   * The function opens all possible intermediate groups on its way to the leaf nodes,
   * scrolls the view to the first passed row, and then flashes all passed rows.
   * @param rowID the rowids of the rows to highlight to the user
   * @param expandChildren if highlighting a group row, whether or not to also expand the children of that highlighted row
   */
  highlightRows: (rowIDs: string[], expandChildren?: boolean) => void;

  /**
   * Highlights the provided group row for the user
   *
   * The key to be passed is the key that AgGrid puts on a group row node's .key attribute. If you for example
   * have a group on "Asset", and there's a "BTC" group, the node.key attributes will be "BTC". There can be several row group nodes
   * that have "BTC" as the key. This function just picks the first one it finds, and its up to you to make sure that this makes sense for your use case.
   */
  highlightGroupRow: (groupingKey: string) => void;
  /**
   * Deselects all previous selections and selects all nodes corresponding to the passed in rowids.
   */
  selectRows: (rowIDs: string[]) => void;

  /**
   * AgGrid's own hidePopupMenu function. Closes **any and all** open AgGrid popup menus.
   *
   * One niched example use is how we are forced to programmatically hide AgGrid's popups ourselves when they're clicked outside of
   * when the blotter is rendered within a React Portal.
   */
  hidePopupMenu: GridApi['hidePopupMenu'];

  getAllDisplayedColumns: GridApi['getAllDisplayedColumns'];
}

/** Blotters State V2 format (blotters2 in AppConfig) */
export type BlotterState<R = any> = {
  columns?: BlotterColumnState[];
  sort?: BlotterTableSort<R>;
  filter?: BlotterTableFilter;
  rowGroupsOpened?: RowGroupsOpenedState;
  /** Date stamp for when this state was deprecated - auto-deleted after a month */
  migratedOutDate?: number;
};

/** Stored Column state model (easily convertable to/from Ag-Grid ColumnState, but maintained for backward compatibility)
 * - TODO: at some point we may consider Ag-Grid's GridState, but interim steps have been taken for confidence in the data model
 */
export type BlotterColumnState = {
  id: string;
  hide?: boolean;
  width?: number;
  pinned?: 'left' | 'right' | undefined;
  rowGroup?: boolean;
  rowGroupIndex?: number;
  // sort +/- values match Talos BlotterTable Column sort values
  sort?: '+' | '-';
  sortIndex?: number;
};

export interface RowGroupsOpenedState {
  [key: string]: boolean;
}

/** Simpler Native HeaderType alternative to Column type='group' based on ColGroupDef
 * - TODO: Decide if we need to:
 *   - keep this type
 *   - use native AgGrid ColGroupDef or
 *   - if we should use Column type='group'
 * @see ColGroupDef
 */
export type ColumnGroup<TData = any> = Omit<ColGroupDef, 'children' | 'groupId'> & {
  type: typeof COLUMN_GROUP_NATIVE_TYPE;
  groupId: string;
  children: Column<TData>[];
};

export const COLUMN_GROUP_NATIVE_TYPE = 'columnGroupNative';
export type ColumnOrColumnGroup = Column | ColumnGroup;

/** is Talos Blotter Column based on ColumnOrColumnGroup */
export function isColumn(column: ColumnOrColumnGroup): column is Column {
  return column.type !== COLUMN_GROUP_NATIVE_TYPE;
}

/** is Talos Blotter ColumnGroup based on ColumnOrColumnGroup */
export function isColumnGroup(column: ColumnOrColumnGroup): column is ColumnGroup {
  return column.type === COLUMN_GROUP_NATIVE_TYPE;
}

/** Extract and flatten Columns and ColumnGroups for compatible analysis */
export function extractColumns(groupCollection: ColumnOrColumnGroup[]): Column[] {
  const result = groupCollection.flatMap(column => {
    return isColumnGroup(column) ? extractColumns(column.children) : column;
  });
  return result;
}

/** is AgGrid ColDef based on ColDef or ColGroupDef */
export function isColDef<R>(colDef: ColDef<R> | ColGroupDef<R>): colDef is ColDef<R> {
  return (colDef as ColGroupDef<R>).children == null;
}

/** is AgGrid ColGroupDef based on ColDef or ColGroupDef */
export function isColGroupDef<R>(colDef: ColDef<R> | ColGroupDef<R>): colDef is ColGroupDef<R> {
  return (colDef as ColGroupDef<R>).children != null;
}

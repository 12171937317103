import { memo } from 'react';
import type { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { useTheme } from 'styled-components';

import {
  ConnectionStatusEnum,
  HeartBeatStatusEnum,
  ICON_SIZES,
  StatusIndicator,
  isConnectionRecovering,
  useConnectionStatusContext,
  useHeartbeats,
  useMarketsContext,
  useObservableValue,
  type MarketCredentialWithConnectionStatus,
  type MarketDataSnapshot,
  type WebsocketError,
} from '@talos/kyoko';

import { isEqual } from 'lodash-es';
import { computeGlobalStatusColor, computeNumConnectedMarkets } from 'utils/status';

export const HeartBeatStatusEnumUI = {
  LOW_LATENCY: 'Low latency',
  MEDIUM_LATENCY: 'Medium latency',
  HIGH_LATENCY: 'High latency',
  OFFLINE: 'Offline',
};

export const GlobalStatusIndicator = ({
  showText = true,
  showIcon = true,
}: {
  showText?: boolean;
  showIcon?: boolean;
}) => {
  const heartbeats = useHeartbeats();
  const { marketsWithConnTypesSupported } = useMarketsContext();
  const { marketCredentialsWithConnectionStatusByCredentialID } = useConnectionStatusContext();
  const theme = useTheme();

  const markets = useObservableValue(
    () => marketsWithConnTypesSupported().pipe(map(markets => new Map(markets.map(m => [m.Name, m])))),
    [marketsWithConnTypesSupported]
  );
  const marketCredentialsWithStatus = [
    ...useObservableValue(
      () => marketCredentialsWithConnectionStatusByCredentialID,
      [marketCredentialsWithConnectionStatusByCredentialID],
      new Map<number, MarketCredentialWithConnectionStatus>()
    ).values(),
  ];
  const heartbeatStatus = useObservableValue(
    () => heartbeats.status as Observable<string>,
    [heartbeats.status],
    HeartBeatStatusEnumUI.LOW_LATENCY
  );

  if (heartbeatStatus == null || markets == null || !marketCredentialsWithStatus?.length) {
    return null;
  }

  let text = '';
  if (heartbeatStatus === HeartBeatStatusEnum.OFFLINE) {
    text = 'Disconnected';
  } else {
    const numConnected = computeNumConnectedMarkets(marketCredentialsWithStatus);
    text = `Connected (${numConnected})`;
  }
  const color = computeGlobalStatusColor(marketCredentialsWithStatus, heartbeatStatus, theme);
  return <StatusIndicator color={color} text={showText ? text : ''} showIcon={showIcon} />;
};

export const CardStatusIndicator = memo<{
  selectedMarkets: string[];
  selectedMarketAccounts: string[];
  snapshots: Observable<MarketDataSnapshot>;
  websocketError?: WebsocketError;
}>(({ selectedMarkets, selectedMarketAccounts, snapshots, websocketError }) => {
  const theme = useTheme();
  const { marketCredentialsWithConnectionStatusByMarketAccountName } = useConnectionStatusContext();
  const statuses: { Status: ConnectionStatusEnum; Market: string }[] = useObservableValue(
    () =>
      snapshots.pipe(
        map(snapshot => Object.values<any>(snapshot.Markets ?? {})),
        distinctUntilChanged((p, n) => isEqual(p, n))
      ),
    [snapshots],
    []
  );

  const hasOfflineMarketsOnly = statuses?.every(s => s.Status === ConnectionStatusEnum.Offline);
  const hasErroredMarkets = statuses?.some(s =>
    [ConnectionStatusEnum.Stale, ConnectionStatusEnum.Error, ConnectionStatusEnum.Offline].includes(s.Status)
  );
  const hasRecoveringMarkets = selectedMarketAccounts?.some(marketAccount => {
    const credential = marketCredentialsWithConnectionStatusByMarketAccountName?.get(marketAccount);
    return isConnectionRecovering(credential?.connectionStatus);
  });

  if (websocketError) {
    return <StatusIndicator size={ICON_SIZES.MEDIUM} isLoading={hasRecoveringMarkets} color={theme.colorTextWarning} />;
  }
  if (statuses == null || hasOfflineMarketsOnly || (!selectedMarketAccounts.length && !selectedMarkets.length)) {
    return <StatusIndicator size={ICON_SIZES.MEDIUM} isLoading={hasRecoveringMarkets} color={theme.colorTextMuted} />;
  }
  if (hasErroredMarkets) {
    return <StatusIndicator size={ICON_SIZES.MEDIUM} isLoading={hasRecoveringMarkets} color={theme.colorTextWarning} />;
  }
  return <StatusIndicator size={ICON_SIZES.MEDIUM} isLoading={hasRecoveringMarkets} color={theme.colorTextPositive} />;
});

import {
  FilterClauseType,
  cleanupInitialFilterDateRange,
  filterExcludesOrderStatus,
  filterExistsAndExcludes,
  removeEmptyFilters,
  useDateRangeFilter,
  useExecTypeFilter,
  useOrderStatusFilter,
  useSymbolsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type DateRangeFilter,
  type ExecutionReport,
  type FilterClause,
  type FilterableProperty,
  type OrdStatusEnum,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { compact, isEqual, keys, pick } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useIDFilter, useMarketsFilter, useStrategiesFilter } from '../../../hooks';

export interface ExecutionReportsTableFilter extends DateRangeFilter {
  _statuses?: string[];
  Statuses?: OrdStatusEnum[];
  Users?: string[];
  SubAccounts?: string[];
  LastMarkets?: string[];
  ExecTypes?: string[];
  Strategies?: string[];
  Symbols?: string[];
  OrderID?: string;
  RFQID?: string;
  ParentOrderID?: string;
  ParentRFQID?: string;
}

export interface UseExecutionReportsFilterParams<TData extends ExecutionReport> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
  includeStatusFilter: boolean;
  includeIDFilter: boolean; // Include IdFilter only if blotter table was not already filtered by some Id
}
export const useExecutionReportFilter = function useExecutionReportFilter<TData extends ExecutionReport>({
  persistedBlotterTable,
  includeStatusFilter,
  includeIDFilter,
}: UseExecutionReportsFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [initialFilter] = useState(() => cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter));
  const [filter, setFilter] = useState<ExecutionReportsTableFilter>(
    (initialFilter as ExecutionReportsTableFilter) || {}
  );

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<ExecutionReport>>(
    row => {
      const data = row.data;
      if (filterExistsAndExcludes(filter, 'ExecTypes', data, 'ExecType')) {
        return false;
      }
      if (filterExcludesOrderStatus(filter._statuses, data)) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'LastMarkets', data, 'LastMarket')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Strategies', data, 'Strategy')) {
        return false;
      }

      return true;
    },
    [filter]
  );

  const lastMarketsFilter = useMarketsFilter();
  const execTypeFilter = useExecTypeFilter();
  const ordStatusFilter = useOrderStatusFilter();
  const symbolsFilter = useSymbolsFilter();
  const strategiesFilter = useStrategiesFilter();
  const idFilter = useIDFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () =>
      compact<FilterableProperty<keyof ExecutionReportsTableFilter>>([
        execTypeFilter,
        { ...lastMarketsFilter, key: 'LastMarkets', label: 'Last Market' },
        includeStatusFilter ? ordStatusFilter : null,
        symbolsFilter,
        strategiesFilter,
        includeIDFilter ? { ...idFilter, key: 'OrderID', label: 'Order ID' } : null,
        includeIDFilter ? { ...idFilter, key: 'RFQID', label: 'RFQ ID' } : null,
        includeIDFilter ? { ...idFilter, key: 'ParentOrderID', label: 'Parent Order ID' } : null,
        includeIDFilter ? { ...idFilter, key: 'ParentRFQID', label: 'Parent RFQ ID' } : null,
      ]),
    [
      execTypeFilter,
      includeStatusFilter,
      lastMarketsFilter,
      ordStatusFilter,
      strategiesFilter,
      symbolsFilter,
      idFilter,
      includeIDFilter,
    ]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      keys(filter).forEach(key => {
        if (key === '_start' || key === 'StartDate') {
          return;
        }
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: filter[key],
        });
      });
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: ExecutionReportsTableFilter = removeEmptyFilters<ExecutionReportsTableFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => {
              let selections: undefined | string | string[];
              switch (key) {
                case 'OrderID':
                case 'RFQID':
                case 'ParentOrderID':
                case 'ParentRFQID':
                  // below are exact search and only one value is supported by backend
                  selections = filterClausesByPropertyKey.get(key)?.selections?.[0];
                  break;
                default:
                  selections = filterClausesByPropertyKey.get(key)?.selections;
              }
              return [key, selections];
            })
          ) satisfies ExecutionReportsTableFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
};

const colIDToFilterBuilderKeyMap = {
  ExecType: 'ExecTypes',
  Symbol: 'Symbols',
  OrdStatus: '_statuses',
  User: 'Users',
  SubAccount: 'SubAccounts',
  LastMarket: 'LastMarkets',
  Strategy: 'Strategies',
  OrderID: 'OrderID',
  RFQID: 'RFQID',
  ParentOrderID: 'ParentOrderID',
  ParentRFQID: 'ParentRFQID',
} satisfies Partial<Record<keyof ExecutionReport, keyof ExecutionReportsTableFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof ExecutionReportsTableFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}

export function onlyServerFilterKeys(filter?: ExecutionReportsTableFilter) {
  return filter == null
    ? filter
    : pick(filter, ['StartDate', 'EndDate', 'Statuses', 'Symbols', 'OrderID', 'RFQID', 'ParentOrderID', 'ParentRFQID']);
}

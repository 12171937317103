import { LayoutError } from 'components/Layout/LayoutError';
import type { IWatermarkPanelProps } from 'dockview';
import { useAppLayoutContext } from 'providers/AppLayoutContextProvider/AppLayoutContextProvider';
import { useCallback } from 'react';
import { useFeatureFlag } from '../../hooks';

export function AppLayoutEmptyError({ containerApi, group }: Partial<IWatermarkPanelProps>) {
  const { resetLayoutAndTabs } = useAppLayoutContext();
  const { enablePopoutWindows } = useFeatureFlag();
  const handleReset = useCallback(() => {
    try {
      resetLayoutAndTabs();
    } catch (e) {
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [resetLayoutAndTabs]);

  return (
    <LayoutError handleReset={handleReset} enablePopoutWindows={enablePopoutWindows} containerApi={containerApi} />
  );
}

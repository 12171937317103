import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { mapLightTheme } from './LightTheme';

const colors: Colors = {
  primary: {
    lighten: '#3e5eb2',
    default: '#314A8C',
    dim: 'rgba(49,74,140,0.4)',
    mute: 'rgba(49,74,140,0.1)',
  },
  gray: {
    '100': '#1d1d28',
    '000': '#e6e6e7',
    '010': '#f2f2f3',
    main: '#ffffff',
    '020': '#fafafa',
    '030': '#ebebeb',
    '040': '#dbdbdd',
    '050': '#d2d2d4',
    '060': '#c8c8ca',
    '070': '#bbbbbe',
    '080': '#9b9b9f',
    '090': '#696970',
  },
  green: {
    lighten: '#78bf9d',
    default: '#56ae84',
    dim: 'rgba(86,174,132,0.4)',
    mute: 'rgba(86,174,132,0.1)',
  },
  red: {
    lighten: '#db5f7a',
    default: '#d23557',
    dim: 'rgba(210,53,87,0.4)',
    mute: 'rgba(210,53,87,0.1)',
  },
  yellow: {
    lighten: '#d7bd66',
    default: '#ccac3e',
    dim: 'rgba(204,172,62,0.4)',
    mute: 'rgba(204,172,62,0.1)',
  },
  orange: {
    lighten: '#ff6511',
    default: '#DD4E00',
    dim: 'rgba(221,78,0,0.4)',
    mute: 'rgba(221,78,0,0.1)',
  },
  blue: {
    lighten: '#5fa2e7',
    default: '#3388E0',
    dim: 'rgba(51,136,224,0.4)',
    mute: 'rgba(51,136,224,0.1)',
  },
  purple: {
    lighten: '#9f73d7',
    default: '#844BCC',
  },
  white: {
    default: '#ffffff',
    dim: 'rgba(255,255,255,0.4)',
    mute: 'rgba(255,255,255,0.1)',
  },
  black: {
    default: '#000000',
    dim: 'rgba(0,0,0,0.4)',
    mute: 'rgba(0,0,0,0.1)',
  },
};

export const LightArchaxTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightAx',

  colorTextDefaultButton: colors.black.default,
  colorTextDefaultButtonHover: colors.black.default,
  colorTextDefaultButtonFocus: colors.black.default,
  colorTextDefaultButtonActive: colors.black.default,
  colorTextDefaultButtonDisabled: setAlpha(0.5, colors.black.default),

  colorTextPositiveButton: colors.black.default,
  colorTextPositiveButtonHover: colors.black.default,
  colorTextPositiveButtonFocus: colors.black.default,
  colorTextPositiveButtonActive: colors.black.default,
  colorTextPositiveButtonDisabled: setAlpha(0.5, colors.black.default),
  colorTextNegativeButton: colors.white.default,
  colorTextNegativeButtonHover: colors.white.default,
  colorTextNegativeButtonFocus: colors.white.default,
  colorTextNegativeButtonActive: colors.white.default,
  colorTextNegativeButtonDisabled: setAlpha(0.5, colors.white.default),
  colorTextWarningButton: colors.black.default,
  colorTextWarningButtonHover: colors.black.default,
  colorTextWarningButtonFocus: colors.black.default,
  colorTextWarningButtonActive: colors.black.default,
  colorTextWarningButtonDisabled: setAlpha(0.5, colors.black.default),
  colorTextPrimaryButton: colors.white.default,
  colorTextPrimaryButtonHover: colors.white.default,
  colorTextPrimaryButtonFocus: colors.white.default,
  colorTextPrimaryButtonActive: colors.white.default,
  colorTextPrimaryButtonDisabled: setAlpha(0.5, colors.white.default),
});

import { createContext, useCallback, useContext, useMemo } from 'react';

export const DefaultFavoriteAggregationsConfig = {
  favoriteAggregations: [] as string[],
};

export const DefaultFavoriteAggregationsMethods = {
  setFavoriteAggregations: (favoriteAggregations: string[]) => {},
};

export const DefaultFavoriteAggregationsContext = {
  ...DefaultFavoriteAggregationsConfig,
  ...DefaultFavoriteAggregationsMethods,
};

export type FavoriteAggregationsConfig = typeof DefaultFavoriteAggregationsConfig;
export type FavoriteAggregationsContextProps = typeof DefaultFavoriteAggregationsContext;

export const FavoriteAggregationsContext = createContext<FavoriteAggregationsContextProps>(
  DefaultFavoriteAggregationsContext
);
FavoriteAggregationsContext.displayName = 'FavoriteAggregationsContext';

export function useFavoriteAggregations() {
  const context = useContext(FavoriteAggregationsContext);
  if (context === undefined) {
    throw new Error('Missing FavoriteAggregationsContext.Provider further up in the tree. Did you forget to add it?');
  }

  const favoriteAggregationsSet = useMemo(() => new Set(context.favoriteAggregations), [context.favoriteAggregations]);

  const favoriteAggregation = useCallback(
    (aggregation: string, favorite: boolean) => {
      let newAggregations = [...context.favoriteAggregations];
      if (favorite) {
        newAggregations.push(aggregation);
      } else {
        newAggregations = newAggregations.filter(agg => agg !== aggregation);
      }

      context.setFavoriteAggregations(newAggregations);
    },
    [context]
  );

  return {
    ...context,
    favoriteAggregationsSet,
    favoriteAggregation,
  };
}

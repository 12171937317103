import type { useDisclosure } from '@talos/kyoko';
import { Dialog, Flex, FormGroup, Input, type DialogProps } from '@talos/kyoko';
import { useMemo, useState, type ChangeEvent } from 'react';

export function RenameTabDialog({
  onConfirm,
  initialTitle,
  ...props
}: ReturnType<typeof useDisclosure> & {
  portalElement: HTMLElement;
  initialTitle: string | undefined;
  onConfirm: (title: string) => void;
}) {
  const [title, setTitle] = useState(initialTitle);

  const dialogProps = useMemo(
    () =>
      ({
        onConfirm: () => onConfirm(title ?? ''),
        dataTestId: 'rename-tab-dialog',
        confirmDisabled: !title,
        title: 'Rename tab',
        width: 360,
        confirmLabel: 'Update tab',
        cancelLabel: 'Cancel',
        alignContent: 'left',
        stretchButtons: true,
      } satisfies Partial<DialogProps>),
    [title, onConfirm]
  );
  const isTitleValid = !!title;
  return (
    <Dialog {...dialogProps} {...props}>
      <Flex flexDirection="column" gap="spacingComfortable">
        <div>Change the label for the &quot;{initialTitle}&quot; tab</div>
        <FormGroup label="Tab label" error={!title ? 'Label is required' : ''}>
          <Input
            autoFocus
            invalid={!isTitleValid}
            placeholder="Enter new label"
            value={title}
            onKeyDown={e => {
              if (isTitleValid && e.key === 'Enter') {
                dialogProps.onConfirm();
                props.close();
              }
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
            onFocus={e => e.target.select()}
          />
        </FormGroup>
      </Flex>
    </Dialog>
  );
}

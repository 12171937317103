import React, { useContext, useMemo, type PropsWithChildren } from 'react';
import { useObservableValue, useSubscription } from '../hooks';
import { wsScanToMap } from '../pipes';
import { EXPOSURE_DEFINITION } from '../tokens';
import type { IExposureDefinition } from '../types';

export interface ExposureDefinitionsContextProps {
  exposureDefinitionsByName?: Map<string, IExposureDefinition>;
}

export const ExposureDefinitionsContext = React.createContext<ExposureDefinitionsContextProps | undefined>(undefined);

export function useExposureDefinitions() {
  const context = useContext(ExposureDefinitionsContext);
  if (context === undefined) {
    throw new Error('Missing ExposureDefinitionsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const ExposureDefinitionsProvider = function ExposureDefinitionsProvider({ children }: PropsWithChildren) {
  const { data } = useSubscription<IExposureDefinition>({
    name: EXPOSURE_DEFINITION,
    tag: 'ExposureDefinitionsProvider',
  });

  const exposureDefinitionsByNameObs = useMemo(
    () => data.pipe(wsScanToMap({ getUniqueKey: ed => ed.Name, newMapEachUpdate: true })),
    [data]
  );

  const exposureDefinitionsByName = useObservableValue(
    () => exposureDefinitionsByNameObs,
    [exposureDefinitionsByNameObs]
  );

  const value = useMemo(() => {
    return { exposureDefinitionsByName };
  }, [exposureDefinitionsByName]);

  return <ExposureDefinitionsContext.Provider value={value}>{children}</ExposureDefinitionsContext.Provider>;
};

import { Flex, FormGroup, Input, VStack } from '@talos/kyoko';
import { useFeatureFlag } from 'hooks';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { forwardRef, type Ref } from 'react';
import { updateField } from '../state/userDetails/userDetailsSlice';
import { CounterpartyDropdown } from './CounterpartyDropdown';
import { CustomerUserLayoutArrangementDropdown } from './CustomerUserLayoutArrangementDropdown';
import { CustomerUserLayoutDropdown } from './CustomerUserLayoutDropdown';
import { CustomerUserRoleSelector } from './CustomerUserRoleSelector';

export const CustomerUserDetails = forwardRef((_: any, ref: Ref<HTMLInputElement>) => {
  const { customerUserId, displayName, email, counterparty, selectedRoles, externalId, layoutType, layoutArrangement } =
    useAppStateSelector(state => state.customerUserDrawer.userDetails);
  const { allowModifyCustomerUserEmail } = useFeatureFlag();
  // Editing the customer user email is allowed only if we are creating a new user, or if the feature flag is enabled.
  const isEmailEditingEnabled = !customerUserId || allowModifyCustomerUserEmail;
  const dispatch = useAppStateDispatch();

  return (
    <VStack mt="spacingBig" w="100%">
      <Flex flexDirection="column" w="100%">
        <FormGroup label="Name" controlId="name">
          <Input
            data-testid="name"
            value={displayName ?? ''}
            onChange={e => dispatch(updateField({ displayName: e.target.value }))}
            disabled={!!customerUserId}
            ref={ref}
          />
        </FormGroup>
        <FormGroup label="Email" controlId="email">
          <Input
            data-testid="email"
            disabled={!isEmailEditingEnabled}
            value={email ?? ''}
            onChange={e => dispatch(updateField({ email: e.target.value }))}
            inputType="email"
          />
        </FormGroup>
        <CounterpartyDropdown
          counterparty={counterparty}
          setCounterparty={counterparty => dispatch(updateField({ counterparty }))}
        />
        <CustomerUserRoleSelector
          selectedRoles={selectedRoles}
          onChange={selectedRoles => dispatch(updateField({ selectedRoles }))}
        />
        <FormGroup label="External ID" controlId="externalId">
          <Input
            data-testid="external-id"
            value={externalId ?? ''}
            onChange={e => dispatch(updateField({ externalId: e.target.value }))}
          />
        </FormGroup>
        <CustomerUserLayoutDropdown
          layoutType={layoutType}
          setLayoutType={layoutType => dispatch(updateField({ layoutType }))}
        />
        <CustomerUserLayoutArrangementDropdown
          layoutType={layoutType}
          layoutArrangement={layoutArrangement}
          setLayoutArrangement={layoutArrangement => dispatch(updateField({ layoutArrangement }))}
        />
      </Flex>
    </VStack>
  );
});

import {
  ConnectionModeEnum,
  HStack,
  Icon,
  IconName,
  IndicatorBadge,
  IndicatorBadgeSizes,
  Text,
  useMarketAccountsContext,
  useSecuritiesContext,
  VStack,
  type MarketSecurityStatus,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { MULTILEG_MARKET_ACCOUNT } from '../../../providers/OMSContext.types';
import { AvailabilityEnum, type AvailabilityCondition, type AvailabilityResult } from '../types';

interface UseMSSEnabledConditionParams {
  /** The complete map of MSS records to base the condition checking on */
  mssByAccountBySymbol: Map<string, Map<string, MarketSecurityStatus>> | undefined;
  /** The symbols to check the MSS record of for each account. Will only be more than one item in the unified liquidity case. */
  symbols: string[];
  /** Whether or not unified liquidity is enabled */
  unifiedLiquidityEnabled?: boolean;
  /** If true, will just Warn instead of Disabling the item on condition failure */
  justWarnOnFailure?: boolean;
}

/**
 * Returns an AvailabilityCondition used to check if a MarketSecurityStatus.Enabled is Up or not.
 *
 * If we at any point are not able to resolve an MSS, the condition will return available: true.
 */
export const useMSSEnabledCondition = ({
  mssByAccountBySymbol,
  symbols,
  unifiedLiquidityEnabled = false,
  justWarnOnFailure,
}: UseMSSEnabledConditionParams): AvailabilityCondition => {
  const failureAvailability = justWarnOnFailure ? AvailabilityEnum.Warning : AvailabilityEnum.Disabled;
  return useMemo(
    () => ({
      id: `mss-enabled`,
      ready: mssByAccountBySymbol != null,
      condition: (market, marketAccount) => {
        if (!mssByAccountBySymbol) {
          // If we fail to load the map at all, we default to showing all.
          return { availability: AvailabilityEnum.Ok };
        }

        if (!marketAccount) {
          // MarketSecurityStatuses are only applicable for market accounts
          return { availability: AvailabilityEnum.Ok };
        }

        if (marketAccount.Name === MULTILEG_MARKET_ACCOUNT) {
          // For multileg specifically, the backend doesn't do any MSS Status=Enabled validation, so we don't either. Yes, its insane.
          return { availability: AvailabilityEnum.Ok };
        }

        const results: MSSConditionSymbolLevelResult[] = symbols.map(symbol => {
          const mss = mssByAccountBySymbol.get(marketAccount.Name)?.get(symbol);

          if (!mss) {
            return { symbol, availability: failureAvailability, failedReason: 'No MSS found' };
          }

          return mss.Enabled === ConnectionModeEnum.Up
            ? { symbol, availability: AvailabilityEnum.Ok }
            : { symbol, availability: failureAvailability, failedReason: 'Symbol disabled' };
        });

        const accountLevelAvailability = getMSSConditionAccountLevelAvailability(results);

        return {
          availability: accountLevelAvailability,
          infoNode: (
            <MSSConditionInfo
              accountName={marketAccount.Name}
              symbolLevelResults={results}
              unifiedLiquidityEnabled={unifiedLiquidityEnabled}
            />
          ),
        } satisfies AvailabilityResult;
      },
    }),
    [mssByAccountBySymbol, symbols, unifiedLiquidityEnabled, failureAvailability]
  );
};

export function MSSConditionInfo({
  accountName,
  symbolLevelResults,
  unifiedLiquidityEnabled,
}: {
  accountName: string;
  symbolLevelResults: MSSConditionSymbolLevelResult[];
  unifiedLiquidityEnabled: boolean;
}) {
  const { securitiesBySymbol } = useSecuritiesContext();
  const { marketAccountsByName } = useMarketAccountsContext();
  const marketAccount = marketAccountsByName.get(accountName);

  // FYI: whenever UL is enabled, we _always_ want to show the extra detail using the JSX below!
  if (symbolLevelResults.length === 1 && !unifiedLiquidityEnabled) {
    const result = symbolLevelResults[0];
    return 'failedReason' in result ? result.failedReason : undefined;
  }

  // UL case. There can be one or more symbols.
  return (
    <VStack gap="spacingSmall" minWidth="220px" alignItems="flex-start" p="spacingTiny">
      <Text fontWeight="bold" color="colorTextSubtle">
        {marketAccount?.DisplayName ?? accountName}
      </Text>
      {symbolLevelResults.map(result => {
        const security = securitiesBySymbol.get(result.symbol);
        return (
          <HStack w="100%" key={result.symbol} justifyContent="space-between" gap="spacingComfortable">
            <HStack gap="spacingTiny">
              <Text color="colorTextAttention">{security?.DisplaySymbol ?? result.symbol}</Text>
              {result.availability < AvailabilityEnum.Ok && <Icon icon={IconName.DotSolid} color="yellow.lighten" />}
            </HStack>

            <IndicatorBadge size={IndicatorBadgeSizes.Small}>
              <HStack gap="spacingTiny" alignItems="flex-start">
                <Icon icon={result.availability === AvailabilityEnum.Ok ? IconName.Check : IconName.Close} />
                {'failedReason' in result && <Text>{result.failedReason}</Text>}
              </HStack>
            </IndicatorBadge>
          </HStack>
        );
      })}
    </VStack>
  );
}

export type MSSConditionSymbolLevelResult =
  | {
      symbol: string;
      availability: AvailabilityEnum.Ok;
    }
  | {
      symbol: string;
      availability: AvailabilityEnum.Disabled | AvailabilityEnum.Warning;
      failedReason: string;
    };

/**
 * For MSS Conditions we support the unified liquidity case where we have one availability for each symbol within the account
 *
 * This function takes in all the availabilities, one coming from each symbol, and pushes out what the availability at the account-level
 * should be.
 */
export function getMSSConditionAccountLevelAvailability(
  symbolLevelResults: MSSConditionSymbolLevelResult[]
): AvailabilityEnum {
  const { ok, disabled } = symbolLevelResults.reduce(
    (agg, result) => {
      return {
        ok: result.availability === AvailabilityEnum.Ok ? agg.ok + 1 : agg.ok,
        disabled: result.availability === AvailabilityEnum.Disabled ? agg.disabled + 1 : agg.disabled,
      };
    },
    { ok: 0, disabled: 0 }
  );

  const allOk = ok === symbolLevelResults.length;
  if (allOk) {
    return AvailabilityEnum.Ok;
  }

  const allDisabled = disabled === symbolLevelResults.length;
  if (allDisabled) {
    return AvailabilityEnum.Disabled;
  }

  // Else, lastly, we have some mixture of Ok, Disabled, Warn. We at least know that we're not entirely OK, and we're not entirely disabled.
  // Hence, we're in between: return Warning.
  return AvailabilityEnum.Warning;
}

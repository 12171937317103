import { EMPTY_ARRAY } from '@talos/kyoko';
import { compact } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type {
  MarketSelectorAPI,
  MarketSelectorCustomPresetItem,
  MarketSelectorEnrichmentSpec,
  MarketSelectorItem,
  MarketSelectorPreset,
  MarketSelectorValue,
} from './types';
import { isMarketSelectorItemSelectable } from './utils/items';

interface UseMarketSelectorParams {
  value: MarketSelectorValue;

  /** List of available presets to select from. Only required if using presets functionality. */
  presets?: MarketSelectorPreset[];

  /** List of custom items, if any, to show in the Presets list. Will be used in addition to the default "Custom" item */
  customPresetItems?: MarketSelectorCustomPresetItem[];

  /** A list of enrichments to apply to the Market Selector */
  enrichmentSpecs?: MarketSelectorEnrichmentSpec<any>[];

  /** A list of selectable items */
  items: MarketSelectorItem[] | undefined;

  /** The main onChange handler. Will emit the entire new selection state on every change: both markets and accounts */
  onChange: (newValue: MarketSelectorValue) => void;
}

export const useMarketSelector = ({
  value,
  items: inputItems,
  presets: inputPresets,
  enrichmentSpecs,
  customPresetItems = EMPTY_ARRAY,
  onChange,
}: UseMarketSelectorParams): MarketSelectorAPI => {
  const items = inputItems ?? EMPTY_ARRAY;
  const itemsMap = useMemo(() => new Map(items.map(option => [option.name, option])), [items]);
  const itemsByMarket: Map<string, MarketSelectorItem[]> = useMemo(() => {
    const newMap = new Map<string, MarketSelectorItem[]>();
    for (const item of items) {
      newMap.set(item.market.Name, [...(newMap.get(item.market.Name) ?? []), item]);
    }
    return newMap;
  }, [items]);

  const presets = useMemo(() => {
    if (!inputPresets) {
      return undefined;
    }

    // We only show / allow the implementer to interact with presets which are represented by at least one item somewhere.
    return inputPresets.filter(preset => {
      return preset.itemIDs.some(itemId => itemsMap.has(itemId));
    });
  }, [inputPresets, itemsMap]);

  const presetsByID = useMemo(() => {
    if (!presets) {
      return undefined;
    }

    return new Map(presets.map(preset => [preset.id, preset]));
  }, [presets]);

  const getItemsInPreset = useCallback(
    (presetID: string) => {
      return compact(presetsByID?.get(presetID)?.itemIDs.map(id => itemsMap.get(id)));
    },
    [presetsByID, itemsMap]
  );

  const selections = value.selections;

  const selectionsSet = useMemo(() => new Set(selections), [selections]);

  const select = useCallback(
    (itemOrKey: MarketSelectorItem | string) => {
      const key = typeof itemOrKey === 'string' ? itemOrKey : itemOrKey.name;
      const validatedNewSelections = ensureOnlyOneSelectionPerMarket([key], itemsMap, selections);
      onChange({ type: 'custom', selections: validatedNewSelections });
    },
    [onChange, itemsMap, selections]
  );

  const unselect = useCallback(
    (item: MarketSelectorItem | string) => {
      const key = typeof item === 'string' ? item : item.name;
      onChange({ type: 'custom', selections: selections.filter(s => s !== key) });
    },
    [onChange, selections]
  );

  const setSelected = useCallback(
    (item: MarketSelectorItem | string, selected: boolean) => {
      selected ? select(item) : unselect(item);
    },
    [select, unselect]
  );

  const selectMany = useCallback(
    (items: (MarketSelectorItem | string)[]) => {
      const newSelectionKeys = items.map(item => (typeof item === 'string' ? item : item.name));
      const validatedNewSelections = ensureOnlyOneSelectionPerMarket(newSelectionKeys, itemsMap, selections);
      return onChange({ type: 'custom', selections: validatedNewSelections });
    },
    [onChange, itemsMap, selections]
  );

  const unselectMany = useCallback(
    (items: (MarketSelectorItem | string)[]) => {
      const keys = items.map(item => (typeof item === 'string' ? item : item.name));
      const keysSet = new Set(keys);
      onChange({ type: 'custom', selections: selections.filter(selection => !keysSet.has(selection)) });
    },
    [onChange, selections]
  );

  const selectOnly = useCallback(
    (item: MarketSelectorItem | string) => {
      onChange({ type: 'custom', selections: [typeof item === 'string' ? item : item.name] });
    },
    [onChange]
  );

  const selectAll = useCallback(
    (subset?: MarketSelectorItem[]) => {
      const itemsToActOn = subset ?? items;

      const marketsFound = new Set<string>();
      const marketsSelected = new Set<string>();
      for (const item of itemsToActOn) {
        marketsFound.add(item.market.Name);
        if (selectionsSet.has(item.name)) {
          marketsSelected.add(item.market.Name);
        }
      }

      // this marketsToAdd data set is all the markets which do not have some selection made within them already
      // So for all these markets in this array, we must make one selection for each.
      const marketsToAdd = [...marketsFound].filter(market => !marketsSelected.has(market));

      const newSelections = compact(
        marketsToAdd.map(market => {
          // we need to decide what item to select within this market.
          const itemsWithinMarket = itemsByMarket.get(market);
          if (!itemsWithinMarket) {
            return undefined;
          }

          // If we can find some available item, we select that one
          const firstSelectableItem = getItemToSelectInMarket(itemsWithinMarket);
          if (firstSelectableItem) {
            return firstSelectableItem.name;
          }

          // If we didnt find any selectable items, then dont select anything within this market.
          return undefined;
        })
      );

      onChange({ type: 'custom', selections: [...selections, ...newSelections] });
    },
    [items, itemsByMarket, onChange, selections, selectionsSet]
  );

  const unselectAll = useCallback(
    (subset?: MarketSelectorItem[]) => {
      const selectionsInProvidedSubset = subset
        ? subset.filter(item => selectionsSet.has(item.name)).map(selectedItem => selectedItem.name)
        : undefined;

      unselectMany(selectionsInProvidedSubset ?? selections);
    },
    [selections, selectionsSet, unselectMany]
  );

  const selectMarket = useCallback(
    (marketName: string, selected: boolean) => {
      const itemsWithinMarket = itemsByMarket.get(marketName);
      if (!itemsWithinMarket) {
        return;
      }

      const itemsWithinMarketSet = new Set(itemsWithinMarket.map(i => i.name));
      // Start by unselecting all items within the market
      const newSelections = selections.filter(s => !itemsWithinMarketSet.has(s));

      if (selected) {
        const firstSelectableItem = getItemToSelectInMarket(itemsWithinMarket);
        if (firstSelectableItem) {
          newSelections.push(firstSelectableItem.name);
        }
      }

      onChange({ type: 'custom', selections: newSelections });
    },
    [itemsByMarket, onChange, selections]
  );

  const selectPreset = useCallback(
    (newPresetID: string) => {
      const presetItems = getItemsInPreset(newPresetID);
      const selectablePresetItems = presetItems.filter(isMarketSelectorItemSelectable);

      onChange({ type: 'preset', presetID: newPresetID, selections: selectablePresetItems.map(item => item.name) });
    },
    [onChange, getItemsInPreset]
  );

  return useMemo(
    () => ({
      ready: inputItems != null,
      presetsReady: presets != null,

      preset: value.type === 'preset' ? value.presetID : undefined,
      presets,
      presetsByID,
      getItemsInPreset,

      enrichments: enrichmentSpecs ?? EMPTY_ARRAY,

      selections,
      selectionsSet,
      items,
      itemsMap,
      itemsByMarket,

      select,
      unselect,
      selectMany,
      unselectMany,
      selectOnly,
      selectAll,
      unselectAll,
      setSelected,
      selectMarket,

      selectPreset,

      customPresetItems,
    }),
    [
      inputItems,
      value,

      presets,
      presetsByID,
      getItemsInPreset,

      enrichmentSpecs,

      selections,
      selectionsSet,
      items,
      itemsMap,
      itemsByMarket,

      select,
      unselect,
      selectMany,
      selectOnly,
      unselectMany,
      selectAll,
      unselectAll,
      setSelected,
      selectMarket,

      selectPreset,

      customPresetItems,
    ]
  );
};

/**
 * Given a new array of selections coming in, this function ensures that we don't end up with more than one selection
 * per market. It will remove any existing selections which collide with new selections.
 *
 * Returns an absolute selection list: a validated combination of the previous selections and the new selections.
 */
function ensureOnlyOneSelectionPerMarket(
  newSelections: string[],
  itemsMap: Map<string, MarketSelectorItem>,
  prevSelections: string[]
) {
  const newSelectionsMarketsSet = new Set(compact(newSelections.map(key => itemsMap.get(key)?.market.Name)));
  const stillValidPreviousSelections = prevSelections.filter(selection => {
    const selectionItem = itemsMap.get(selection)?.market.Name;
    if (!selectionItem) {
      // shouldnt happen but if it does then regard as not valid and remove selection
      return false;
    }

    return !newSelectionsMarketsSet.has(selectionItem);
  });

  return [...stillValidPreviousSelections, ...newSelections];
}

/**
 * Figures out which item to select assuming that we are to select one item within this provided array of items
 *
 * If none of these items are selectable, then the function returns undefined
 */
function getItemToSelectInMarket(itemsInMarket: MarketSelectorItem[]): MarketSelectorItem | undefined {
  return itemsInMarket
    .toSorted((a, b) => (a.displayName ?? a.name).localeCompare(b.displayName ?? b.name))
    .find(isMarketSelectorItemSelectable);
}

/** This function returns what the selection would be of a certain preset given the set of preset and items available */
export function getSelectionsFromPreset(
  preset: MarketSelectorPreset | undefined,
  itemsMap: Map<string, MarketSelectorItem>
): MarketSelectorItem[] {
  if (!preset) {
    return [];
  }

  const selections: MarketSelectorItem[] = [];
  const presetItemIDs = preset.itemIDs ?? [];
  for (const itemID of presetItemIDs) {
    const item = itemsMap.get(itemID);
    if (item != null && isMarketSelectorItemSelectable(item)) {
      selections.push(item);
    }
  }

  return selections;
}

import { EntityAdminWS, type CustomerCredit } from '@talos/kyoko';
import { useCounterpartiesFilter } from 'hooks';
import { useCustomers } from 'hooks/useCustomer';
import { useCustomerCredit, useCustomerMarketAccounts } from 'providers';
import { useMemo } from 'react';
import {
  CUSTOMER_CREDITS_COLUMNS,
  CUSTOMER_CREDITS_WS_REQUEST,
  getCustomerCreditsDrawerOptions,
  getCustomerCreditsEntityAsDrawerEntity,
  type CustomerCreditDrawerEntity,
} from './utils';

export const CustomerCredits = () => {
  const customers = useCustomers();
  const { customerMarketAccountsList } = useCustomerMarketAccounts();
  const { customerCreditByMarketAccount } = useCustomerCredit();
  const drawerOptions = useMemo(() => {
    if (customers != null && customerMarketAccountsList != null && customerCreditByMarketAccount != null) {
      return getCustomerCreditsDrawerOptions(customers, customerMarketAccountsList, customerCreditByMarketAccount);
    }
  }, [customerMarketAccountsList, customers, customerCreditByMarketAccount]);

  const counterpartiesFilter = useCounterpartiesFilter();
  const filterableProperties = useMemo(() => [counterpartiesFilter], [counterpartiesFilter]);

  return (
    <EntityAdminWS<CustomerCredit, CustomerCreditDrawerEntity>
      wsRequest={CUSTOMER_CREDITS_WS_REQUEST}
      path="/customer-credit"
      title="Customer Credit"
      entityName="Credit Limit"
      subtitle="Manage customer credit limits"
      entityIDField="MarketAccount"
      columns={CUSTOMER_CREDITS_COLUMNS}
      allowAddEntity={true}
      allowEditEntity={true}
      drawerOptions={drawerOptions}
      getEntityAsDrawerEntity={getCustomerCreditsEntityAsDrawerEntity}
      filterableProperties={filterableProperties}
      filterableServerFields={['Counterparty']}
      persistKey="dealer/credit"
      confirmTextActions={['single-edit', 'single-delete']}
      /* In the context of CustomerCredit, all POST, PATCH, and DELETE requests are made to the same endpoint.
       * The default behavior would append the entityID to the path, but in this case, we don't want that. */
      getPostPath={() => '/customer-credit/limits'}
      getPatchDeletePath={() => '/customer-credit/limits'}
      usePutForPatchPost={true}
    />
  );
};

import { values } from 'lodash-es';
import { memo, useEffect, useMemo, useRef, useState, type ChangeEventHandler } from 'react';
import { defineMessages } from 'react-intl';
import { SecurityType, useMixpanel } from '../../contexts';
import { useConstant, useDeviceType, useDynamicCallback, useIntl } from '../../hooks';
import { MixpanelEvent, MixpanelEventProperty } from '../../tokens';
import { ButtonVariants, IconButton } from '../Button';
import { Flex, type BoxProps } from '../Core';
import { Divider } from '../Divider';
import { DropdownFilter, ToggleButtonFilter, type DropdownFilterProps, type ToggleButtonFilterProps } from '../Filters';
import { FormControlSizes, Input } from '../Form';
import { Icon, IconName } from '../Icons';
import { SearchWrapper, WatchListFiltersWrapper } from './styles';
import type { WatchlistLayout } from './types';

const messages = defineMessages({
  searchSymbol: {
    defaultMessage: 'Search Symbol...',
    id: 'Watchlist.searchSymbol',
  },
});

export interface WatchlistFiltersProps {
  filteredCurrencies: string[];
  onFilteredCurrenciesChange: (currencies: string[]) => void;
  securityType: SecurityType;
  onSecurityTypeChange?: (securityType: SecurityType) => void;
  showOnlyFavorites: boolean;
  onShowOnlyFavorites: (showOnlyFavorites: boolean) => void;
  onSearchTextChange: ChangeEventHandler<HTMLInputElement>;
  searchText: string;
  selectableCurrencies?: string[];
  layout: WatchlistLayout | undefined;
  securityTypesOptions?: SecurityType[];
}

export enum FilterableProperties {
  FAVORITES = 'Favorites',
  PRODUCT_TYPE = 'Product Type',
  CURRENCY = 'Currency',
}

const DEFAULT_CURRENCIES = ['BTC', 'ETH', 'USD', 'USDT', 'EUR'];
const DEFAULT_LAYOUT: WatchlistLayout = {
  currency: 'show',
  favorites: 'show',
  search: 'expanded',
  securityType: 'show',
};

const securityTypeFilterOptions = values(SecurityType);

export const WatchlistFilters = memo(function ({
  filteredCurrencies,
  onFilteredCurrenciesChange,
  securityType,
  onSecurityTypeChange,
  showOnlyFavorites,
  onShowOnlyFavorites,
  onSearchTextChange,
  searchText,
  selectableCurrencies = DEFAULT_CURRENCIES,
  layout = DEFAULT_LAYOUT,
  securityTypesOptions = securityTypeFilterOptions,
}: WatchlistFiltersProps) {
  const mixpanel = useMixpanel();
  const deviceType = useDeviceType();
  const isMobile = deviceType === 'mobile';

  const toggleOnlyFavorites = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.FilterWatchlist, {
      [MixpanelEventProperty.Filter]: FilterableProperties.FAVORITES,
    });
    onShowOnlyFavorites(!showOnlyFavorites);
  });

  const dropdownItemProps = useConstant<DropdownFilterProps<SecurityType>['itemProps']>({
    size: FormControlSizes.Default,
  });

  const dropdownButtonProps = useConstant<DropdownFilterProps<SecurityType>['buttonProps']>({
    size: FormControlSizes.Small,
    variant: ButtonVariants.Default,
    endIcon: undefined,
  });

  const dropdownFilterProps = useMemo(
    function DropdownFilterProps() {
      return {
        options: securityTypesOptions,
        renderItem: label => label,
        renderSelectedItem: (selected: SecurityType) =>
          selected === SecurityType.CalendarSpread ? 'C Sprd' : selected,
        getKey: label => label,
        onChange: option => {
          mixpanel.track(MixpanelEvent.FilterWatchlist, {
            [MixpanelEventProperty.Filter]: FilterableProperties.PRODUCT_TYPE,
            [MixpanelEventProperty.Value]: option,
          });
          onSecurityTypeChange?.(option);
        },
        selection: securityType,
        wrapperProps: {
          minWidth: '42px',
        },
      } satisfies DropdownFilterProps<SecurityType>;
    },
    [mixpanel, securityType, onSecurityTypeChange, securityTypesOptions]
  );

  const selectedCurrencies = useMemo(() => new Set(filteredCurrencies), [filteredCurrencies]);
  const toggleFilterProps = useMemo(
    function ToggleButtonFilterProps() {
      return {
        options: selectableCurrencies,
        selections: [...selectedCurrencies.values()],
        getKey: label => label,
        onToggleChange: newSelections => {
          mixpanel.track(MixpanelEvent.FilterWatchlist, {
            [MixpanelEventProperty.Filter]: FilterableProperties.CURRENCY,
          });
          onFilteredCurrenciesChange(newSelections);
        },
        renderContent: label => label,
        multiSelect: false,
      } satisfies Partial<ToggleButtonFilterProps<string>>;
    },
    [mixpanel, selectedCurrencies, onFilteredCurrenciesChange, selectableCurrencies]
  );

  return (
    <>
      <Flex flexDirection="column" px="spacingComfortable">
        {(!isMobile || layout.search === 'expanded') && (
          <SearchComponent onSearchTextChange={onSearchTextChange} searchText={searchText} />
        )}
        <WatchListFiltersWrapper>
          {(!isMobile || layout.favorites === 'show') && (
            <IconButton
              data-testid="toggle-only-favorites"
              onClick={toggleOnlyFavorites}
              size={isMobile ? FormControlSizes.Default : FormControlSizes.Small}
              icon={showOnlyFavorites ? IconName.StarSolid : IconName.Star}
              color={showOnlyFavorites ? 'yellow.lighten' : 'gray.080'}
            />
          )}
          {isMobile && layout.search === 'icon' && (
            <SearchButton searchText={searchText} onSearchTextChange={onSearchTextChange} />
          )}
          {!isMobile && layout.securityType === 'show' && onSecurityTypeChange && (
            <DropdownFilter buttonProps={dropdownButtonProps} itemProps={dropdownItemProps} {...dropdownFilterProps} />
          )}
          <Divider orientation="vertical" mr="spacingSmall" ml="spacingSmall" />
          {layout.currency !== 'hide' && (
            <ToggleButtonFilter
              size={isMobile ? FormControlSizes.Default : FormControlSizes.Small}
              {...toggleFilterProps}
            />
          )}
        </WatchListFiltersWrapper>
      </Flex>
    </>
  );
});

interface SearchProps {
  onSearchTextChange: ChangeEventHandler<HTMLInputElement>;
  searchText: string;
}

function SearchComponent({ onSearchTextChange, searchText, ...props }: SearchProps & BoxProps) {
  const { formatMessage } = useIntl();
  return (
    <SearchWrapper {...props}>
      <Input
        data-testid="search-input"
        prefix={<Icon icon={IconName.Search} />}
        placeholder={formatMessage(messages.searchSymbol)}
        onChange={onSearchTextChange}
        value={searchText}
        clearable={true}
      />
    </SearchWrapper>
  );
}

function SearchButton({ onSearchTextChange, searchText }: SearchProps) {
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const { formatMessage } = useIntl();

  const handleBlur = useDynamicCallback(() => {
    if (searchText === '') {
      setActive(false);
    }
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = useDynamicCallback(e => {
    onSearchTextChange(e);
    if (!e.target.value && e.target !== document.activeElement) {
      setActive(false);
    }
  });

  useEffect(() => {
    active && ref.current && ref.current.focus();
  }, [active]);

  return (
    <>
      <IconButton icon={IconName.Search} onClick={() => setActive(true)} />
      {active && (
        <SearchWrapper zIndex={1} p={0} left="spacingComfortable" right="spacingComfortable" position="absolute">
          <Input
            ref={ref}
            prefix={<Icon icon={IconName.Search} />}
            placeholder={formatMessage(messages.searchSymbol)}
            onChange={handleChange}
            onBlur={handleBlur}
            value={searchText}
            clearable={true}
          />
        </SearchWrapper>
      )}
    </>
  );
}

import { Observable } from 'rxjs';
import { getErrorMessage, type DataResponse, type ErrorResponse } from '../types/rest';
import { eraseCookie } from './cookies';
import { logger } from './logger';

export class FetchError extends Error {
  public response: Response;
  public body: string;
  public errorJson: ErrorResponse | undefined;
  public url: string;
  public options: RequestInit;

  constructor({
    response,
    body,
    errorJson,
    url,
    options,
  }: {
    response: Response;
    body: string;
    errorJson: ErrorResponse | undefined;
    url: string;
    options: RequestInit;
  }) {
    if (errorJson) {
      super(getErrorMessage(errorJson));
    } else {
      super(`${response.status} ${response.statusText}: ${url}\n${body ?? ''}`);
    }
    this.response = response;
    this.body = body;
    this.errorJson = errorJson;
    this.url = url;
    this.options = options;
  }
}

function createFormData(obj: object | null) {
  const data: string[] = [];
  for (const key in obj) {
    data.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key as keyof typeof obj]));
  }
  return data.join('&');
}

interface RequestOptions extends RequestInit {
  /**
   * Set to enable pagination. Denotes how many records you want to paginate at most. Will paginate until this limit is reached.
   * Set to Infinity to not apply any limit (risk paging indefinitely).
   */
  paginateRecords?: number;
}

// Wrapper around performRequestInner to do pagination handling
function performRequest<TResponse = any>(
  endpoint: string,
  path: string,
  { paginateRecords, ...restOfOptions }: RequestOptions = {}
): Promise<TResponse> {
  if (paginateRecords) {
    const baseUrl = `${endpoint}${path}`;
    // There's some typing annoyances here. The fetching process relies on the returned type being DataResponse<T>.
    // If its not, it will throw. So arriving at this point means that it was successful. We still need to cast the more-specific DataResponse<any> to the less specific
    // TResponse just to make ts happy. The user would have typed their Get call with eg Get<DataResponse<Trade>> anyway so this is fine.
    const pagedResponse: Promise<DataResponse<any>> = restFetchAllPages(
      baseUrl,
      paginateRecords,
      (dynamicUrl: string) => performRequestInner(dynamicUrl, restOfOptions)
    );
    return pagedResponse as Promise<TResponse>;
  } else {
    return performRequestInner(`${endpoint}${path}`, restOfOptions);
  }
}

/* CSRF Token handling
 * We need to send the CSRF Token with every non-idempotent (e.g. POST / PUT / DELETE / PATCH)
 * request we make.
 * We're working on the assumption that before making any non-idempotent request, we'll have first
 * made e.g. a `GET` request.  For every request that we make, if it has the `X-CSRF-Token` header,
 * we'll save that and use it for the next request we make.
 * Technically, we only need to send the token for CSRF Protected endpoints (non-idempotent ones),
 * but sending it every time is just easier to implement.
 * Also, for CORS requests, we need the backend to send `Access-Control-Allow-Headers: X-CSRF-Token`
 * and also `Access-Control-Expose-Headers: X-CSRF-Token` (otherwise we won't be able to read that header
 * on any CORS responses).
 * NOTE: CSRF Tokens are unique per origin, hence the map of Origin -> Token.
 */
const csrfTokenMap = new Map<string, string>();
const csrfTokenName = 'X-Csrf-Token';

function performRequestInner<TResponse = any>(url: string, options: RequestInit): Promise<TResponse> {
  const requestUrl = new URL(url, window.location.origin);
  const hadCsrfTokenBeforeRequest = csrfTokenMap.has(requestUrl.origin);
  options.headers = {
    ...(options.headers || {}),
    'X-Talos-UI-Version': import.meta.env.VITE_GIT_HASH,
    ...(csrfTokenMap.has(requestUrl.origin) ? { [csrfTokenName]: csrfTokenMap.get(requestUrl.origin) } : {}),
  };
  // Must use `requestUrl.toString()` here because MSW doesn't like passing in a `URL` instance
  return fetch(requestUrl.toString(), options)
    .then(async response => {
      if (response.headers.has(csrfTokenName)) {
        // Save the CSRF Token if we can
        csrfTokenMap.set(requestUrl.origin, response.headers.get(csrfTokenName) ?? '');
      }
      if (response.status === 204) {
        return null;
      }
      const body: any = await response.text();
      let json: TResponse | ErrorResponse | undefined;

      try {
        json = JSON.parse(body);
      } catch (e) {
        // Could not parse JSON, assume it's plain text
      }

      if (response.ok) {
        return json ?? body;
      }

      throw new FetchError({
        response,
        body,
        errorJson: json as ErrorResponse,
        url,
        options,
      });
    })
    .catch((e: FetchError | TypeError) => {
      if ('response' in e) {
        if (e.response.status === 401) {
          eraseCookie(import.meta.env.VITE_SESSION_COOKIE);
        }
        if (e.response.status === 403 && !hadCsrfTokenBeforeRequest && csrfTokenMap.has(requestUrl.origin)) {
          // Possibly an error caused by a missing CSRF token
          // Since we didn't have one before, but we got one in the error response, we'll retry the request
          // and this time it will include the new CSRF token
          return performRequestInner(url, options);
        }
        // Don't log auth errors
        if (![401, 403].includes(e.response.status)) {
          logger.error(e, {
            url,
            response: { body: e.body, status: e.response.status },
          });
        }
      }
      return Promise.reject(e);
    });
}

export function Get<TResponse = any>(endpoint: string, path = '', options: RequestOptions = {}): Promise<TResponse> {
  return performRequest(endpoint, path, {
    credentials: 'include',
    ...options,
  });
}

const isContentTypeMatching = (contentType: HeadersInit | undefined, matchType: 'json' | 'formUrlEncoded') => {
  const typeToMatch = matchType === 'json' ? 'application/json' : 'application/x-www-form-urlencoded';
  return (contentType as Extract<HeadersInit, Record<string, string>> | undefined)?.['Content-Type'] === typeToMatch;
};

export function Post<TResponse = any>(
  endpoint: string,
  path: string,
  payload: object | null = {},
  options: RequestOptions = {}
): Promise<TResponse> {
  return performRequest(endpoint, path, {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: isContentTypeMatching(options?.headers, 'json') ? JSON.stringify(payload) : createFormData(payload),
    ...options,
  });
}

export function Put<TResponse = any>(
  endpoint: string,
  path: string,
  payload: object | null = {},
  options: RequestOptions = {}
): Promise<TResponse> {
  return performRequest(endpoint, path, {
    method: 'put',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: isContentTypeMatching(options?.headers, 'json') ? JSON.stringify(payload) : createFormData(payload),
    ...options,
  });
}

export function Patch<TResponse = any>(
  endpoint: string,
  path: string,
  payload: object | null = {},
  options: RequestOptions = {}
): Promise<TResponse> {
  return performRequest(endpoint, path, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: isContentTypeMatching(options?.headers, 'formUrlEncoded') ? createFormData(payload) : JSON.stringify(payload),
    ...options,
  });
}

export function Delete<TResponse = any>(
  endpoint: string,
  path: string,
  payload: object | null = {},
  options: RequestOptions = {}
): Promise<TResponse> {
  return performRequest(endpoint, path, {
    method: 'delete',
    credentials: 'include',
    body: JSON.stringify(payload),
    ...options,
  });
}

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const PATCH = 'PATCH';
export const DELETE = 'DELETE';

/**
 * Send a JSON request.
 * @param method One of GET, POST, PUT, PATCH, or DELETE
 * @param url URL to which the JSON call should be sent
 * @param payload Non-stringified JSON (GET requests do not accept a payload)
 * @param requestOptions Additional options to pass to the fetch call
 */
export async function request<TResponse = any>(
  method: typeof GET | typeof POST | typeof PUT | typeof PATCH | typeof DELETE,
  url: string
): Promise<TResponse>;
export async function request<TResponse = any>(
  method: typeof GET | typeof POST | typeof PUT | typeof PATCH | typeof DELETE,
  url: string,
  payload: null,
  requestOptions?: RequestOptions
): Promise<TResponse>;
export async function request<TResponse = any>(
  method: typeof POST | typeof PUT | typeof PATCH | typeof DELETE,
  url: string,
  payload: object
): Promise<TResponse>;
export async function request<TResponse = any>(
  method: typeof GET | typeof POST | typeof PUT | typeof PATCH | typeof DELETE,
  url: string,
  payload: object | null = null,
  requestOptions: RequestOptions = {}
): Promise<TResponse> {
  const options: RequestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
    ...requestOptions,
  };
  if (payload != null) {
    if (method === GET) {
      throw new Error(`Payload must be empty when using ${GET}`);
    }
    options.body = JSON.stringify(payload);
  }
  switch (method) {
    case GET:
      return Get(url, '', options);
    case POST:
      return Post(url, '', null, options);
    case PUT:
      return Put(url, '', null, options);
    case PATCH:
      return Patch(url, '', null, options);
    case DELETE:
      return Delete(url, '', null, options);
    default:
      throw new Error('Unsupported HTTP method');
  }
}

/**
 * Send a JSON request.
 * @param method One of GET, POST, PUT, PATCH, or DELETE
 * @param url URL to which the JSON call should be sent
 */
export function requestObservable<TResponse = any>(
  method: typeof GET | typeof POST | typeof PUT | typeof PATCH | typeof DELETE,
  url: string
): Observable<TResponse>;

/**
 * Send a JSON request.
 * @param method One of GET, POST, PUT, PATCH, or DELETE
 * @param url URL to which the JSON call should be sent
 * @param payload Non-stringified JSON (GET requests do not accept a payload)
 */
export function requestObservable<TResponse = any>(
  method: typeof POST | typeof PUT | typeof PATCH | typeof DELETE,
  url: string,
  payload: object
): Observable<TResponse>;

/**
 * Send a JSON request.
 * @param method One of GET, POST, PUT, PATCH, or DELETE
 * @param url URL to which the JSON call should be sent
 * @param payload Non-stringified JSON (GET requests do not accept a payload)
 */
export function requestObservable<TResponse = any>(
  method: typeof GET | typeof POST | typeof PUT | typeof PATCH | typeof DELETE,
  url: string,
  payload: object | null = null
): Observable<TResponse> {
  const abortController = new AbortController();
  const options: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
    },
    signal: abortController.signal,
  };
  if (payload != null) {
    if (method === GET) {
      throw new Error(`Payload must be empty when using ${GET}`);
    }
    options.body = JSON.stringify(payload);
  }
  return new Observable(function subscribe(subscriber) {
    let result: Promise<TResponse>;
    switch (method) {
      case GET:
        result = Get(url, '', options);
        break;
      case POST:
        result = Post(url, '', null, options);
        break;
      case PUT:
        result = Put(url, '', null, options);
        break;
      case PATCH:
        result = Patch(url, '', null, options);
        break;
      case DELETE:
        result = Delete(url, '', null, options);
        break;
      default:
        throw new Error('Unsupported HTTP method');
    }
    result
      .then(response => subscriber.next(response))
      .then(() => subscriber.complete())
      .catch(err => subscriber.error(err));
    return function unsubscribe() {
      abortController.abort('Observable unsubscribed');
    };
  });
}

/**
 * A Promise-based REST paginator tool you can use to paginate a data set completely before having the promise resolve.
 *
 * The resolved promise will look as if no paging has been done (next will be undefined), and the data property will be a merged
 * data array of all received pages.
 *
 * @param baseUrl The standard request url (not applying any ?after= query)
 * @param limit pagination limit (max records)
 * @param paginatableRequestFn A wrapped request function which accepts a request with the after query param set
 */
export async function restFetchAllPages<T>(
  baseUrl: string,
  limit: number,
  paginatableRequestFn: (paginatedUrl: string) => Promise<DataResponse<T>>
) {
  const responses: DataResponse<T>[] = [];
  let url: string | null = baseUrl;
  let recordsReceived = 0;

  while (url) {
    const response = await paginatableRequestFn(url);
    if (!isDataResponse(response)) {
      throw new Error('Received an invalid REST response type for performing pagination');
    }
    responses.push(response);
    recordsReceived += response.data.length;
    if (response.next && recordsReceived < limit) {
      url = attachAfterSearchParam(baseUrl, response.next);
    } else {
      url = null;
    }
  }

  return {
    ...responses[0],
    next: undefined,
    data: responses.flatMap(response => response.data),
  };
}

function attachAfterSearchParam(baseUrl: string, afterValue: string): string {
  const url = new URL(baseUrl);
  url.searchParams.set('after', afterValue);
  return url.href;
}

function isDataResponse<T = any>(response: unknown): response is DataResponse<T> {
  return !!(response && response instanceof Object && 'data' in response && response['data'] instanceof Array);
}

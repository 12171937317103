import { isOrder, type ExecutionReport, type MarketOrder, type Order } from '@talos/kyoko';
import { compact, uniq } from 'lodash-es';

interface SelectedOrdersListUsersProps {
  selectedOrders: (Order | ExecutionReport | MarketOrder)[];
}

export const SelectedOrdersUsers = ({ selectedOrders }: SelectedOrdersListUsersProps) => {
  if (!selectedOrders?.length) {
    return null;
  }

  return (
    <b>
      {uniq(compact(selectedOrders.map(selectedOrder => (isOrder(selectedOrder) ? selectedOrder?.User : '')))).join(
        ', '
      )}
    </b>
  );
};

import { useDynamicCallback } from '@talos/kyoko';
import { useCustomersContext } from 'hooks/useCustomer';
import { useCustomerMarketAccounts } from 'providers';
import { useAppStateSelector } from 'providers/AppStateProvider';
import { getCustomerAccountPermissions } from '../state/permissions/permissionsSlice';

export function useCustomerUserPermissions() {
  const { addMarketAccountAuthorization, deleteMarketAccountAuthorization } = useCustomersContext();
  const permissions = useAppStateSelector(state => state.customerUserDrawer.permissions);
  const { customerMarketAccountsByName } = useCustomerMarketAccounts();
  const savePermissions = useDynamicCallback(async (customerUserId: string) => {
    try {
      const updatedMarketAccountAuthorizations = getMarketAccountPermissions(permissions.dirtyCustomerAccounts).map(
        x => ({
          marketAccount: x.marketAccount,
          authorizationToAdd: x.customerAccountPermissions.permissions.filter(
            p => !x.customerAccountPermissions.initialPermissions?.includes(p)
          ),
          authorizationToRemove: x.customerAccountPermissions.initialPermissions?.filter(
            p => !x.customerAccountPermissions.permissions?.includes(p)
          ),
        })
      );

      const marketAccountsAuthorizationsToAdd = updatedMarketAccountAuthorizations.flatMap(u => {
        return u.authorizationToAdd.map(authorization => ({
          marketAccount: u.marketAccount,
          authorization,
        }));
      });
      const marketAccountsAuthorizationsToDelete = updatedMarketAccountAuthorizations
        .filter(u => u.authorizationToRemove)
        .flatMap(u => {
          return u.authorizationToRemove!.map(authorization => ({
            marketAccount: u.marketAccount,
            authorization,
          }));
        })
        .concat(
          getMarketAccountPermissions(permissions.deletedCustomerAccounts).flatMap(u => {
            return u.customerAccountPermissions.permissions.map(authorization => ({
              marketAccount: u.marketAccount,
              authorization,
            }));
          })
        );

      if (marketAccountsAuthorizationsToAdd.length > 0) {
        await addMarketAccountAuthorization(customerUserId, marketAccountsAuthorizationsToAdd);
      }
      if (marketAccountsAuthorizationsToDelete.length > 0) {
        await deleteMarketAccountAuthorization(customerUserId, marketAccountsAuthorizationsToDelete);
      }
    } catch (e) {
      throw Error('Customer User Permissions could not be saved.');
    }
  });
  return { savePermissions };

  function getMarketAccountPermissions(ids: string[]) {
    return ids.map(id => {
      const customerAccountPermissions = getCustomerAccountPermissions(permissions.customerAccounts, id);
      const marketAccount = customerMarketAccountsByName.get(customerAccountPermissions?.customerAccount);
      if (!marketAccount) {
        throw new Error(`Market account ${id} not found`);
      }
      return {
        marketAccount: marketAccount.MarketAccountID,
        customerAccountPermissions,
      };
    });
  }
}

import {
  BlotterTable,
  Box,
  useBlotterTable,
  useConstant,
  useSecuritiesContext,
  type Column,
  type Order,
} from '@talos/kyoko';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { memo, useMemo } from 'react';
import { of } from 'rxjs';
import { MarketDistributionAveragePriceColumn, MarketDistributionMarketColumn } from '../columns';
import { DistributionWrapper } from '../styles';
import { REMAINDER } from '../types';
import { useChartOptions } from '../useChartOptions';
import { useMarketDistribution } from '../useMarketDistribution';

export interface FilledMarketDistributionChartProps {
  legIndex?: 0 | 1;
  order: Order;
}

export const FilledMarketDistributionChart = memo(function FilledMarketDistributionChart({
  legIndex,
  order,
}: FilledMarketDistributionChartProps) {
  const { distribution } = useMarketDistribution({
    order: order,
    legIndex,
    includeZeroCumQty: false,
    variant: 'Market',
  });

  const highchartOptions = useChartOptions({ distribution, centerText: 'Market Distribution' });
  const remainderFreeDistribution = useMemo(() => distribution.filter(d => d.id !== REMAINDER), [distribution]);

  const { securitiesBySymbol } = useSecuritiesContext();
  const currentSymbol =
    (legIndex != null && securitiesBySymbol.get(order.Symbol)?.MultilegDetails?.Legs[legIndex].Symbol) || order.Symbol;

  const distributionObs = useMemo(
    () => of({ initial: true, data: remainderFreeDistribution }),
    [remainderFreeDistribution]
  );

  const columns = useConstant([
    MarketDistributionMarketColumn,
    MarketDistributionAveragePriceColumn({ tab: 'filled' }),
    {
      type: 'price',
      field: 'cumQty',
      title: 'Quantity',
      params: {
        quoteCurrencyField: 'sizeCurrency',
        showDashIfZero: true,
      },
    },
    {
      type: 'price',
      field: 'cumFee',
      title: 'Fees',
      params: {
        asset: currentSymbol,
        showDashIfZero: false,
      },
    },
    {
      type: 'percent',
      field: 'y',
      title: 'Pct',
      params: {
        increment: '0.01',
      },
    },
  ] satisfies Column[]);

  const blotterTable = useBlotterTable({
    dataObservable: distributionObs,
    columns: columns,
    rowID: 'id',
    fitColumns: true,
    initialSort: '-y',
    gridOptions: {
      domLayout: 'autoHeight',
    },
  });

  if (highchartOptions == null) {
    return null;
  }

  return (
    <DistributionWrapper>
      <Box h="250px">
        <HighchartsReact highcharts={Highcharts} options={highchartOptions} />
      </Box>

      <Box minHeight="80px" w="100%">
        <BlotterTable background="gray.030" {...blotterTable} />
      </Box>
    </DistributionWrapper>
  );
});

import { MixpanelEvent } from '@talos/kyoko';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';
import { TradingSettingCheckbox } from './TradingSettingCheckbox';

export function DefaultToEmptyOrderMarketsSetting() {
  const { defaultToEmptyOrderMarkets, setDefaultToEmptyOrderMarkets } = useTradingSettings();

  return (
    <TradingSettingCheckbox
      id="defaultToEmptyOrderMarkets"
      isEnabled={defaultToEmptyOrderMarkets}
      onChecked={setDefaultToEmptyOrderMarkets}
      mixPanelEvent={MixpanelEvent.DefaultToEmptyOrderMarkets}
      toastDisplayName="Blank Market Accounts when opening the Order Form: "
      displayName="Blank Market Accounts when opening the Order Form"
      displayDescription="If enabled, market accounts will not default when the user opens the order form via the order form button. If Priming the order, via a market card for example, market accounts will be selected."
    />
  );
}

import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
  type Dispatch,
  type ReactNode,
  type RefObject,
} from 'react';
import { useEvent } from 'react-use';
import { useDisclosure } from '../../hooks';

export type PowerSearchModalContextOutput = {
  modal: ReturnType<typeof useDisclosure>;
  inputValue: string;
  setInputValue: Dispatch<React.SetStateAction<string>>;
  inputRef: RefObject<HTMLInputElement>;
};

export const PowerSearchModalContext = createContext<PowerSearchModalContextOutput | undefined>(undefined);
PowerSearchModalContext.displayName = 'PowerSearchModalContext';

export function PowerSearchProvider({ children }: { children: ReactNode }) {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const modal = useDisclosure({
    onClose: () => !modal.isOpen && setInputValue(''),
  });

  const onKeyDown = useCallback(
    // TODO: this callback should be refactored to use 'useEventListener' so the KeyboardEvent type can be used
    (e: Event) => {
      // Escape
      if ('keyCode' in e && e.keyCode === 27 && modal.isOpen) {
        if (inputValue) {
          setInputValue('');
        }
      }
    },
    [modal.isOpen, inputValue]
  );

  useEvent('keydown', modal.isOpen ? onKeyDown : undefined, document);
  return (
    <PowerSearchModalContext.Provider value={{ modal, inputValue, setInputValue, inputRef }}>
      {children}
    </PowerSearchModalContext.Provider>
  );
}

export function usePowerSearchContext(): PowerSearchModalContextOutput {
  const context = useContext(PowerSearchModalContext);
  if (context == null) {
    throw new Error(`${this.prototype.name} must be called within a PowerSearchModalContext.Provider.`);
  }
  return context;
}

import { createContext, useContext } from 'react';

/** The default config of the market selector. This is global, and stored in the AppConfig. */
export const DefaultMarketSelectorConfig: MarketSelectorConfigProperties = {
  advancedMode: false,
  showDisabledItems: false,
};

interface MarketSelectorConfigProperties {
  advancedMode: boolean;
  showDisabledItems: boolean;
}

interface MarketSelectorConfigMethods {
  setAdvancedMode: (value: boolean) => void;
  setShowDisabledItems: (value: boolean) => void;
}

export type MarketSelectorConfigContextProps = MarketSelectorConfigProperties & MarketSelectorConfigMethods;

export const MarketSelectorConfigContext = createContext<MarketSelectorConfigContextProps | undefined>(undefined);

/** This hook exposes the global config settings for the market selector. These are used internally by the new market selector. */
export const useMarketSelectorConfig = () => {
  const context = useContext(MarketSelectorConfigContext);
  if (context === undefined) {
    throw new Error('Missing MarketSelectorConfigContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

import { useCallbackRef, type MinimalSubscriptionResponse } from '@talos/kyoko';
import { useMemo } from 'react';
import { combineLatest, map, of, type Observable } from 'rxjs';
import { useGetPathFromMarketAccountToSubAccount } from '../../../../utils/getPathFromMarketAccountToSubAccount';
import type { OpsAccountPosition, OpsPosition } from '../types';
import { MarketAccountRow, MarketRow, OpsPositionRow, SubAccountRow, UnderlierRow } from './rows';

interface UseOpsOverviewRowsParams {
  positionsObs: Observable<OpsPosition[]>;
  marketAccountPositionsByAccountObs: Observable<Map<string, OpsAccountPosition>>;
  subAccountPositionsByAccountObs?: Observable<Map<string, OpsAccountPosition>>;
  rootSubAccountID?: number;

  /** If true, will include a Market grouping level "on top of" the account grouping level */
  groupByMarket: boolean;
}

export const useOpsOverviewRows = ({
  positionsObs,
  groupByMarket,
  marketAccountPositionsByAccountObs,
  subAccountPositionsByAccountObs,
  rootSubAccountID,
}: UseOpsOverviewRowsParams): Observable<MinimalSubscriptionResponse<OpsOverviewBlotterRow>> => {
  const inputs = useMemo(
    () => ({
      positions: positionsObs,
      marketAccountPositionsByAccount: marketAccountPositionsByAccountObs,
      subAccountPositionsByAccount: subAccountPositionsByAccountObs ?? of(null),
    }),
    [positionsObs, marketAccountPositionsByAccountObs, subAccountPositionsByAccountObs]
  );

  const getPathFromMarketAccountToSubAccount = useGetPathFromMarketAccountToSubAccount();
  // This function is made stable so we don't recompute the entire blotter more often than we need to, and don't recalculate paths more often than we need to.
  // This is a work-around done in the ui just to try to keep things simple. This path building should be done by the backend, and then
  // provided to us, but it is not. If this becomes problematic, this function might need to be come unstable (but be "throttled").
  const getSubAccountPath = useCallbackRef((position: OpsPosition) => {
    if (rootSubAccountID == null) {
      return undefined;
    }
    return getPathFromMarketAccountToSubAccount(position.MarketAccount, rootSubAccountID);
  });

  const rowsObs = useMemo(
    () =>
      combineLatest(inputs).pipe(
        map(({ positions, marketAccountPositionsByAccount, subAccountPositionsByAccount }) => {
          /**
           * Grouping structure is...
           * - ...(Sub accounts - Rollups and Books - can be several rows, but only used in PM Margin)
           *   - (Market - toggleable)
           *     - Market Account
           *       - (Margin type - future)
           *         - Underlying (conditionally redundant - future)
           *           - Instrument (leaf node)
           */

          const wrapWithSubAccountRows = subAccountPositionsByAccount != null;

          const { levels } = groupByMarket ? GROUPINGS.ByMarket : GROUPINGS.ByMarketAccount;

          const rowIDsCreated = new Set<string>();
          const rows: OpsOverviewBlotterRow[] = [];

          for (const position of positions) {
            // In order to not be instantiating tons of duplicate classes (rows) only to throw them away, we preview the rowIDs and only build
            // the tree row instance if it does not already exist in the blotter. I think this is the correct trade-off.
            let workingDataPath: string[] = [];
            const subAccountPath = wrapWithSubAccountRows ? getSubAccountPath(position)?.subAccountPath : undefined;
            if (subAccountPath) {
              // Sub accounts are treated differently than other levels -- they "wrap" the other levels. There is also no pre-determined
              // leveling of sub accounts, it is determined by the sub account path's depth, which is dynamic. Lastly, they are also not directly
              // derived from positions, which all other levels are. Hence, they get their own code path here.
              for (const subAccount of subAccountPath) {
                workingDataPath = [...workingDataPath, subAccount];
                const rowAlreadyCreated = rowIDsCreated.has(SubAccountRow.getRowID(workingDataPath));
                if (rowAlreadyCreated) {
                  continue;
                }

                const maybeAccountPosition = subAccountPositionsByAccount?.get(subAccount);
                const newRow = buildRow(workingDataPath, SubAccountRow, position, maybeAccountPosition);
                rowIDsCreated.add(newRow.rowID);
                rows.push(newRow);
              }
            }

            // All non sub-account levels:
            for (const level of levels) {
              workingDataPath = [...workingDataPath, level.getPathComponent(position)]; // create new array instead of mutating!
              const rowAlreadyCreated = rowIDsCreated.has(level.getRowID(workingDataPath));
              if (rowAlreadyCreated) {
                continue;
              }

              const maybeAccountPosition = marketAccountPositionsByAccount.get(position.MarketAccount);
              const newRow = buildRow(workingDataPath, level, position, maybeAccountPosition);
              rowIDsCreated.add(newRow.rowID);
              rows.push(newRow);
            }
          }

          return {
            initial: true,
            data: rows,
          };
        })
      ),
    [inputs, groupByMarket, getSubAccountPath]
  );

  return rowsObs;
};

export type OpsOverviewBlotterRow = SubAccountRow | MarketRow | MarketAccountRow | UnderlierRow | OpsPositionRow;
export type OpsOverviewBlotterRowClasses =
  | typeof SubAccountRow
  | typeof MarketRow
  | typeof MarketAccountRow
  | typeof UnderlierRow
  | typeof OpsPositionRow;

interface GroupingSpec {
  levels: OpsOverviewBlotterRowClasses[];
  /* Will likely expand here in the future to include metadata for excluding "redundant" levels etc */
}

type Grouping = 'ByMarket' | 'ByMarketAccount';

const GROUPINGS: { [key in Grouping]: GroupingSpec } = {
  ByMarket: {
    levels: [MarketRow, MarketAccountRow, UnderlierRow, OpsPositionRow],
  },
  ByMarketAccount: {
    levels: [MarketAccountRow, UnderlierRow, OpsPositionRow],
  },
};

// This could be replaced by a static .Build function with a shared interface on each of these classes but this is easier for now
function buildRow(
  workingDataPath: string[],
  level: OpsOverviewBlotterRowClasses,
  position: OpsPosition,
  accountPosition?: OpsAccountPosition
): OpsOverviewBlotterRow {
  switch (level) {
    case SubAccountRow:
      return new SubAccountRow(workingDataPath, accountPosition);
    case MarketRow:
      return new MarketRow(workingDataPath);
    case MarketAccountRow:
      return new MarketAccountRow(workingDataPath, accountPosition);
    case UnderlierRow:
      return new UnderlierRow(workingDataPath);
    default:
      return new OpsPositionRow(workingDataPath, position);
  }
}

import { endsWith } from 'lodash-es';
import { createContext, useContext, useEffect, useMemo, useState, type PropsWithChildren } from 'react';
import { useSearchParam } from 'react-use';
import { ReplaySubject, type Observable } from 'rxjs';
import { useConstant } from '../hooks/useConstant';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { hashThemeRunTime } from '../styles/themes';
import { CustomerUserConfigLayoutType } from '../types/CustomerUserConfigLayout';
import type { WLOrgConfig } from '../types/WLOrgConfig';
import { logger } from '../utils';
import { Get } from '../utils/http';

export const DEFAULT_WL_ORG_CONFIG: WLOrgConfig = {
  title: 'Demo Trade',
  theme: 'DarkTheme',
  favicon: 'cryptoco-otc-icon.svg',
  layout: CustomerUserConfigLayoutType.TradingLayout,
  supportPhone: 'support-phone-here',
  supportEmail: 'support-email-here',
  supportWebsite: 'support-website-here',
  logo: {
    dark: 'cryptoco-otc-logo-d.svg',
    light: 'cryptoco-otc-logo-l.svg',
  },
  // [sc-76773] Enable Customer Withdraw on the "default" whitelabel UI config
  enableBalanceTransactions: true,
  blotterRowsMax: 10000,
  disableCustomLadderSizeBuckets: false,
  hideRecordDepositButton: false,
  showMarketAccountDisplayName: false,
  usePreciseTopOfBookIncrement: true,
  allowMobile: true,
  isFallbackConfig: true,
};

interface WLOrgConfigContextProps {
  config: WLOrgConfig;
  isLoaded: boolean;
  overrides: Partial<WLOrgConfig>;
  loadedConfig: Observable<WLOrgConfig>;
  setOverrides: (overrides: Partial<WLOrgConfig>) => void;
}

export const WLCustomerConfigContext = createContext<WLOrgConfigContextProps | undefined>(undefined);

export function useWLOrgConfigContext() {
  const context = useContext(WLCustomerConfigContext);
  if (context === undefined) {
    throw new Error('Missing WLCustomerConfigContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

const DEMO_OVERRIDES = 'DEMO_OVERRIDES';

export const WLOrgConfigProvider = ({ children }: PropsWithChildren) => {
  const urlOverride = useSearchParam('override');
  const urlthemeOverride = useSearchParam('themeOverride');
  // The usecase here is to provide a way to test customer themes using url params in local/staging.
  // Try it out by adding ?themeOverride=DarkTDATheme to the end of the url.
  const { override, themeOverride } =
    import.meta.env.VITE_IS_MOBILE === 'true'
      ? // for mobile, no url overrides and set url to be base url, since code defaults to hostname which includes .localhost
        { override: import.meta.env.VITE_BASE_URL, themeOverride: undefined }
      : import.meta.env.VITE_AVA_ENV === 'local' || import.meta.env.VITE_AVA_ENV === 'staging'
      ? { override: urlOverride, themeOverride: urlthemeOverride }
      : { override: undefined, themeOverride: undefined };
  const [config, setConfig] = useState<WLOrgConfig>(DEFAULT_WL_ORG_CONFIG);
  const [overrides, setOverrides] = useSessionStorage<Partial<WLOrgConfig>>(DEMO_OVERRIDES, {});
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadedConfig] = useState(() => new ReplaySubject<WLOrgConfig>(1));

  const canUseDefaultConfig = useConstant(
    // Allow using default customer config in a Local environment,
    // or on NON-PROD environments that end with ".whitelabel-talos.com"
    import.meta.env.VITE_AVA_ENV === 'local' ||
      (import.meta.env.VITE_AVA_ENV !== 'prod' && endsWith(window.location.hostname, '.whitelabel-talos.com'))
  );

  useEffect(() => {
    const configUrl = `/config/${override ?? window.location.hostname}.json`;
    const fetchConfig = (shouldRetry: boolean) => {
      logger.info(`[config] Fetching customer config`, { extra: { url: configUrl } });
      return Get(configUrl)
        .then(async (res: WLOrgConfig) => {
          const config: WLOrgConfig = {
            ...res,
            usePreciseTopOfBookIncrement: res.usePreciseTopOfBookIncrement ?? true,
            volumeLadderDecimalPrecision: res.volumeLadderDecimalPrecision?.toString(),
            theme: hashThemeRunTime(themeOverride ?? res.theme),
          };
          setConfig(config);
          loadedConfig.next(config);
          setIsLoaded(true);
          logger.info(`[config] Loaded customer config`, { extra: { url: configUrl } });
        })
        .catch(reason => {
          if (shouldRetry) {
            fetchConfig(false);
          } else if (canUseDefaultConfig) {
            setConfig(DEFAULT_WL_ORG_CONFIG);
            loadedConfig.next(DEFAULT_WL_ORG_CONFIG);
            setIsLoaded(true);
          } else {
            throw new Error(`Could not load customer config ${configUrl}: ${reason}`);
          }
        });
    };
    fetchConfig(true);
  }, [canUseDefaultConfig, override, themeOverride, loadedConfig]);

  useEffect(() => {
    if (isLoaded) {
      document.title = config.title || '';
      const favicon = document.getElementById('favicon') as HTMLLinkElement | undefined;
      if (favicon) {
        favicon.href = `customers/${config.favicon}`;
      }
    }
  }, [config, isLoaded]);

  const value = useMemo(
    () => ({
      config: { ...config, ...overrides },
      isLoaded,
      loadedConfig,
      overrides,
      setOverrides,
    }),
    [config, isLoaded, overrides, setOverrides, loadedConfig]
  );

  return <WLCustomerConfigContext.Provider value={value}>{children}</WLCustomerConfigContext.Provider>;
};

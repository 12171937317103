import {
  BuyingPowerWarningTooltip,
  getBuyingPowerWarnings,
  HStack,
  InlineFormattedNumber,
  OrderFormSides,
  orderFormSideToSideEnum,
  SideEnum,
  Text,
  toBigWithDefault,
  VStack,
  type BuyingPower,
  type ColumnDef,
} from '@talos/kyoko';
import type { IRowNode } from 'ag-grid-community';
import Big from 'big.js';
import { useCallback, useMemo } from 'react';
import { useBuyingPower } from '../../../hooks';
import type { BuyingPowerProps } from '../../../hooks/useBuyingPower';
import type { MarketSelectorRow } from '../blotter/types';
import type { MarketSelectorEnrichmentSpec, MarketSelectorItem } from '../types';

type UseMarketSelectorBuyingPowerEnrichmentParams = {
  /** The Order Qty, if any. Used to compare the BuyingPower and produce warnings if quantity > buying power. */
  quantity: string | undefined;
  /**
   * The Order Side. Needed to be able to pick either MaxBuy or MaxSell off of the BuyingPower entity.
   */
  side: OrderFormSides | undefined;
  /**
   * Used by the BuyingPower class's business logic, see usage in there. If provided, will
   * include BuyingPower.MaxBuy/SellOrderSize in the calculation of BuyingPower
   */
  useMaxOrderSize?: boolean;
  /** Specify which columns (by ID) should be visible when the Market Selector is in "simple" mode */
  simpleModeVisibleColIDs?: string[];
} & BuyingPowerProps;

const ENRICHMENT_KEY = 'buyingPower';
const MAX_BUY_COLID = 'maxBuy';
const MAX_SELL_COLID = 'maxSell';

// Override the MarketSelectorRow type to attach our enrichment type BuyingPower for typing within this hook
type BuyingPowerEnrichedMarketSelectorRow = Omit<MarketSelectorRow, 'enrichments'> & {
  enrichments: { [ENRICHMENT_KEY]: BuyingPower | undefined };
};

const WARNING_TOOLTIP_WIDTH = '340px';

/** Constructs a MarketSelectorEnrichmentSpec output */
export const useMarketSelectorBuyingPowerEnrichment = ({
  quantity,
  side,
  useMaxOrderSize,

  symbol,
  marketNames,
  currency: requestCurrency,
  threshold,
  tolerance,
  unifiedLiquidity,
  tag,
  simpleModeVisibleColIDs,
}: UseMarketSelectorBuyingPowerEnrichmentParams): MarketSelectorEnrichmentSpec<BuyingPower> => {
  const { buyingPowerByMarketAccount: buyingPower } = useBuyingPower({
    symbol,
    marketNames,
    currency: requestCurrency,
    threshold,
    tolerance,
    unifiedLiquidity,
    tag,
  });

  // We derive the currency from the received buying power from the backend. All these BP records will have the same .Currency.
  const currency = useMemo(() => {
    if (!buyingPower) {
      return requestCurrency;
    }

    const someBp = [...buyingPower.values()].at(0);
    return someBp?.Currency ?? requestCurrency;
  }, [buyingPower, requestCurrency]);

  const getBuyingPowerDetail = useCallback(
    (mktAccName: string, side: SideEnum, showSide: boolean) => {
      if (!mktAccName || !buyingPower) {
        return <></>;
      }

      const quantityBig = toBigWithDefault(quantity, 0);
      const { warnings } = getBuyingPowerWarnings({
        accounts: [mktAccName],
        quantity: quantityBig,
        side,
        buyingPowerByAccount: buyingPower,
      });
      const bp = buyingPower.get(mktAccName);
      const max = toBigWithDefault(bp?.getMaxSize({ side, useMaxOrderSize }), 0);
      const showWarning = quantityBig.gt(max);

      return (
        <HStack gap="spacingSmall" alignItems="baseline">
          {showWarning && (
            <BuyingPowerWarningTooltip
              warnings={warnings}
              currency={currency}
              side={side}
              tooltipProps={{ contentMaxWidth: WARNING_TOOLTIP_WIDTH }}
            />
          )}
          <InlineFormattedNumber number={max} currency={currency} />
          {showSide && <Text>{side}</Text>}
        </HStack>
      );
    },
    [buyingPower, quantity, useMaxOrderSize, currency]
  );

  const getSelectionListItemSuffix = useCallback(
    (mktAccName: string) => {
      if (!side) {
        return <></>;
      }

      if (side === OrderFormSides.Twoway) {
        return (
          <VStack gap="spacingTiny" alignItems="flex-end">
            {getBuyingPowerDetail(mktAccName, SideEnum.Buy, true)}
            {getBuyingPowerDetail(mktAccName, SideEnum.Sell, true)}
          </VStack>
        );
      }

      return getBuyingPowerDetail(mktAccName, orderFormSideToSideEnum(side), false);
    },
    [getBuyingPowerDetail, side]
  );

  const getBuyingPowerHeaderDetail = useCallback(
    (allSelections: string[], side: SideEnum) => {
      if (!buyingPower) {
        return <></>;
      }

      const { warnings, totalWarning, totalBuyingPower } = getBuyingPowerWarnings({
        buyingPowerByAccount: buyingPower,
        accounts: allSelections,
        useMaxOrderSize,
        side,
        quantity: toBigWithDefault(quantity, 0),
      });

      return (
        <HStack gap="spacingSmall">
          {totalWarning && (
            <BuyingPowerWarningTooltip
              warnings={warnings}
              totalWarning={totalWarning}
              currency={currency}
              side={side}
              tooltipProps={{ contentMaxWidth: WARNING_TOOLTIP_WIDTH }}
            />
          )}
          <Text color="colorTextSubtle">{side === SideEnum.Buy ? 'Max Buy' : 'Max Sell'}</Text>
          <InlineFormattedNumber number={totalBuyingPower} currency={currency} />
        </HStack>
      );
    },
    [buyingPower, currency, quantity, useMaxOrderSize]
  );

  const selectionListHeaderSuffix = useCallback(
    (allSelections: string[]) => {
      if (!buyingPower || !side) {
        return <></>;
      }

      if (side === OrderFormSides.Twoway) {
        return (
          <VStack gap="spacingTiny" alignItems="flex-end">
            {getBuyingPowerHeaderDetail(allSelections, SideEnum.Buy)}
            {getBuyingPowerHeaderDetail(allSelections, SideEnum.Sell)}
          </VStack>
        );
      }

      const sideEnum = orderFormSideToSideEnum(side);
      return getBuyingPowerHeaderDetail(allSelections, sideEnum);
    },
    [side, buyingPower, getBuyingPowerHeaderDetail]
  );

  const selectionSummary = useCallback(
    (selectedRows: MarketSelectorRow[]) => {
      // We summarize the MaxSell and MaxBuy of all the selected rows and show that in the summary
      let maxBuy = Big(0);
      let maxSell = Big(0);

      for (const row of selectedRows) {
        const bp: BuyingPower | undefined = row.enrichments[ENRICHMENT_KEY];
        if (!bp) {
          continue;
        }

        maxBuy = maxBuy.plus(toBigWithDefault(bp.getMaxBuy({ useMaxOrderSize }), 0));
        maxSell = maxSell.plus(toBigWithDefault(bp.getMaxSell({ useMaxOrderSize }), 0));
      }

      return (
        <HStack gap="spacingDefault" justifyContent="flex-start">
          <BuyingPowerSummary side={SideEnum.Buy} total={maxBuy} currency={currency} />
          <BuyingPowerSummary side={SideEnum.Sell} total={maxSell} currency={currency} />
        </HStack>
      );
    },
    [useMaxOrderSize, currency]
  );

  const comparator = useCallback(
    (a: MarketSelectorItem | undefined, b: MarketSelectorItem | undefined, direction: '+' | '-') => {
      if (direction === '+') {
        // if we're sorting in the other direction, reverse the two variables
        const temp = a;
        a = b;
        b = temp;
      }

      if (!buyingPower || !side) {
        return 0;
      }

      const bpA = a ? buyingPower.get(a.name) : undefined;
      const bpB = b ? buyingPower.get(b.name) : undefined;

      if (!bpA && !bpB) {
        return 0;
      }

      if (!bpA) {
        return -1;
      }

      if (!bpB) {
        return 1;
      }

      // both bpA and bpB exist here
      const valueA = getBuyingPowerValueForComparison(side, bpA, useMaxOrderSize);
      const valueB = getBuyingPowerValueForComparison(side, bpB, useMaxOrderSize);
      // I think this below might be more efficient than doing Big subtraction?
      return valueA.lt(valueB) ? -1 : valueA.gt(valueB) ? 1 : 0;
    },
    [buyingPower, side, useMaxOrderSize]
  );

  return useMemo(
    () => ({
      id: 'buying-power-enrichment',
      enrichmentKey: ENRICHMENT_KEY,
      simpleModeVisibleColIDs:
        simpleModeVisibleColIDs != null
          ? simpleModeVisibleColIDs
          : side === OrderFormSides.Twoway
          ? [MAX_BUY_COLID, MAX_SELL_COLID]
          : side === OrderFormSides.Buy
          ? [MAX_BUY_COLID]
          : [MAX_SELL_COLID],
      advancedModeVisibleColIDs: [MAX_BUY_COLID, MAX_SELL_COLID],
      columns: [
        {
          id: MAX_BUY_COLID,
          type: 'buyingPower',
          field: 'enrichments.buyingPower',
          title: 'Max Buy',
          width: 130,
          flex: 1,
          params: {
            side: SideEnum.Buy,
            quantity,
            showExcessWarning,
            currency,
          },
        },
        {
          id: MAX_SELL_COLID,
          type: 'buyingPower',
          field: 'enrichments.buyingPower',
          title: 'Max Sell',
          flex: 1,
          width: 130,
          params: {
            side: SideEnum.Sell,
            quantity,
            showExcessWarning,
            currency,
          },
        },
      ] satisfies ColumnDef<BuyingPowerEnrichedMarketSelectorRow>[],
      selectionList: {
        selectionListItemHeight: side === OrderFormSides.Twoway ? 'taller' : 'default',
        selectionListItemSuffix: getSelectionListItemSuffix,
        selectionListHeaderSuffix,
        comparator,
      },
      selectionSummary,
      dataMap: buyingPower,
    }),
    // We are rebuilding the columns on every change of quantity...
    [
      buyingPower,
      quantity,
      side,
      currency,
      getSelectionListItemSuffix,
      selectionListHeaderSuffix,
      selectionSummary,
      comparator,
      simpleModeVisibleColIDs,
    ]
  );
};

// We only show a buying power excess warning if the item is selected
function showExcessWarning(node: IRowNode<BuyingPowerEnrichedMarketSelectorRow>): boolean {
  return node.data?.selected ?? false;
}

function BuyingPowerSummary({ side, total, currency }: { side: SideEnum; total: Big; currency: string }) {
  return (
    <HStack gap="spacingSmall" fontSize="fontSizeSm" data-testid={`buying-power-summary-${side}`}>
      <Text textTransform="uppercase">Max {side}</Text>
      <InlineFormattedNumber number={total} currency={currency} />
    </HStack>
  );
}

function getBuyingPowerValueForComparison(
  side: OrderFormSides,
  bp: BuyingPower,
  useMaxOrderSize: boolean | undefined
): Big {
  if (side === OrderFormSides.Twoway) {
    return toBigWithDefault(bp?.getMaxBuy({ useMaxOrderSize }), 0).plus(
      toBigWithDefault(bp?.getMaxSell({ useMaxOrderSize }), 0)
    );
  }

  return toBigWithDefault(bp.getMaxSize({ useMaxOrderSize, side }), 0);
}

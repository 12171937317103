import { nth, reduce, transform, uniq } from 'lodash-es';
import type { ObjectSchema, ValidationError } from 'yup';
import type { AnyObject } from 'yup/lib/types';

const pathPartsRegex = /(\.?\[?[^.[\]]+\]?)/g;
export function runValidation(schema: ObjectSchema<AnyObject>, order: AnyObject): { [key: string]: string } {
  try {
    schema.validateSync(order, { abortEarly: false });
    return {};
  } catch (err: unknown) {
    /*
  When we validate the objects in an array, yup emits errors with a path like `subAccountAllocations[0].subAccount`, which isn't what we want
  This code takes that path, breaks it up into the various "parts", and adds the error message to each of those keys.
  The result of this is that errors would look something like this:
  ```
  {
    "subAccountAllocations": ["Sub Account is required", "Sub Account is required", "Value must be > 0"],
    "subAccountAllocations[0]": ["Sub Account is required"],
    "subAccountAllocations[0].subAccount": ["Sub Account is required"],
    "subAccountAllocations[1]": ["Sub Account is required" "Value must be > 0"],
    "subAccountAllocations[1].subAccount": ["Sub Account is required"],
    "subAccountAllocations[1].value": ["Value must be > 0"],
  }
  ```
*/
    const errors = ((err as ValidationError).inner ?? []).reduce((allErrors, innerError) => {
      const pathParts = (innerError.path ?? '').match(pathPartsRegex) ?? [];
      const paths = reduce(
        pathParts,
        (paths, currentSegment) => [...paths, `${nth(paths, -1) ?? ''}${currentSegment}`],
        [] as string[]
      );
      paths.forEach(path => {
        allErrors[path] = [...(allErrors[path ?? ''] ?? []), innerError.message];
      });
      return allErrors;
    }, {} as { [key: string]: string[] });
    /*
  We now take that errors object, and concatenate all the unique errors per key,
  to create a summary of the errors for each key with its children
  The result of this is that errors would look something like this:
  ```
  {
    "subAccountAllocations": "Sub Account is required\nValue must be > 0",
    "subAccountAllocations[0]": "Sub Account is required",
    "subAccountAllocations[0].subAccount": "Sub Account is required",
    "subAccountAllocations[1]": "Sub Account is required\nValue must be > 0",
    "subAccountAllocations[1].subAccount": "Sub Account is required",
    "subAccountAllocations[1].value": "Value must be > 0",
  }
  ```
*/
    return transform<string[], Record<string, string>>(
      errors,
      (allErrors, thisError, key) => (allErrors[key] = uniq(thisError).join('\n')),
      {}
    );
  }
}

import type { Order } from '@talos/kyoko';
import { isCypressWindow } from '@talos/kyoko';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { AppNotificationToast } from './AppNotificationToast';

export type AppNotificationPriority = 'High' | 'Medium' | 'Informational';
type AppNotificationType = 'OrderPaused' | 'OrderFilled';

type AppNotificationTargetDeliveryType = 'InApp';
type AppNotificationTargetState = 'New' | 'Archived' | 'Withdrawn';
type AppNotificationTargetDestination = {
  DeliveryType: AppNotificationTargetDeliveryType;
  State: AppNotificationTargetState;
  Destination: string;
};

/** Server type for AppNotification
 * TODO: get it from sbe-generated type
 */
type AppNotificationBase = {
  NotificationID: string;
  OrgID: number;
  Timestamp: string;
  Severity: AppNotificationPriority;
  Type: AppNotificationType;
  Targets: AppNotificationTargetDestination[];
};

type AppNotificationOrder =
  | {
      Type: 'OrderPaused';
      Payload: Order;
    }
  | {
      Type: 'OrderFilled';
      Payload: Order;
    };

export type AppNotification = AppNotificationBase & AppNotificationOrder;

export const AppNotifications: React.FC = () => {
  const { enableNotifications } = useFeatureFlag();

  // if cypress is testing, don't kick off cypress notifications
  return !enableNotifications || isCypressWindow(window) ? null : <AppNotificationToast />;
};

import {
  BlotterDensity,
  BlotterTable,
  Button,
  DEFAULT_BLOTTER_COLDEF,
  DEFAULT_BLOTTER_SELECTION_READONLY_PARAMS,
  Flex,
  FormControlSizes,
  Icon,
  IconName,
  Input,
  MarketTypeEnum,
  Tab,
  TabList,
  TabPanel,
  TabSize,
  Tabs,
  getAgGridColId,
  useBehaviorSubject,
  useBlotterTable,
  useCallbackRef,
  useTabs,
  type Column,
  type ColumnDef,
  type MinimalSubscriptionResponse,
} from '@talos/kyoko';
import type {
  ColDef,
  GridOptions,
  IAggFuncParams,
  RowClassParams,
  RowClickedEvent,
  ValueGetterParams,
} from 'ag-grid-community';
import { compact } from 'lodash-es';
import { useMemo } from 'react';
import { useMarketSelectorContext } from '../MarketSelectorContext';
import { MarketSelectorSelectionSummary } from '../MarketSelectorSelectionSummary';
import { AvailabilityEnum, type MarketSelectorEnrichmentSpec } from '../types';
import { isMarketSelectorItemSelectable } from '../utils/items';
import { MarketSelectorBlotterCheckboxCellRenderer } from './MarketSelectorBlotterCheckboxCellRenderer';
import { MarketSelectorBlotterHeaderComponent } from './MarketSelectorBlotterCheckboxHeaderComponent';
import { isMarketParentRowSelectable, marketSelectorAutoGroupColumnDef } from './MarketSelectorGroupColumn';
import { StyleWrapper } from './styles';
import { ALL_TAB, DARK_TAB, DEALERS_TAB, EXCHANGES_TAB, type MarketSelectorTab } from './tabs';
import type { MarketSelectorRow } from './types';

export const MarketSelectorBlotter = () => {
  const {
    showDisabledItems,
    items,
    selectionsSet,
    setSelected,
    enrichments,
    selectMarket,
    advancedMode,
    unselectAll,
    blotterSearchRef,
  } = useMarketSelectorContext();

  // These tabs are specific to the view, so they're built and referenced out here in the blotter component as opposed to in the model.
  const tabsItems: MarketSelectorTab[] = useMemo(() => {
    const darkExists = items.some(item => item.market.Type === MarketTypeEnum.Dark);
    return compact([ALL_TAB, EXCHANGES_TAB, DEALERS_TAB, darkExists && DARK_TAB]);
  }, [items]);

  const tabs = useTabs({
    initialItems: tabsItems,
    initialSelectedIndex: 0,
  });

  const selectedTab = tabs.items[tabs.selectedIndex];

  // Go from MarketSelectorItems to more specific Blotter entities.
  // TODO: convert this to a delta model
  const blotterRows: MarketSelectorRow[] = useMemo(() => {
    let rows: MarketSelectorRow[] = items.map(item => ({
      item,
      key: item.name,

      // we attach selected to the blotter entity because we want the row's change detection to run when selection state changes without hacking anything
      // we _could_ avoid this and just have the cells resolve their selected state from the market selector context, but we opt to do it this way instead
      selected: selectionsSet.has(item.name),

      enrichments: {},
    }));

    if (!showDisabledItems) {
      rows = rows.filter(row => {
        // We filter out all items that have Availability = Excluded, except for the case where they are already selected.
        // We leave them around such that the user can unselect them explicitly to avoid confusion.
        const remove = !row.selected && row.item.availability.availability <= AvailabilityEnum.Disabled;
        return !remove;
      });
    }

    // If the selectedTab has a filter specified, apply it to our list of rows
    if (selectedTab.params.filter != null) {
      rows = rows.filter(row => selectedTab.params.filter?.(row.item));
    }

    // Attach our enrichments to all the rows
    for (const enrichmentSpec of enrichments) {
      for (const row of rows) {
        row.enrichments[enrichmentSpec.enrichmentKey] = enrichmentSpec.dataMap?.get(row.key);
      }
    }

    return rows;
  }, [items, showDisabledItems, selectionsSet, selectedTab, enrichments]);

  const selectedRows = useMemo(() => blotterRows.filter(row => row.selected), [blotterRows]);

  const { observable: dataObservable } = useBehaviorSubject(() => {
    // Convert our data array into an observable
    // In the future, we will want to send delta messages here when it comes to showing real-time data
    return {
      type: '',
      ts: '',
      data: blotterRows,
      initial: true,
    } satisfies MinimalSubscriptionResponse<MarketSelectorRow>;
  }, [blotterRows]);

  const handleRowClicked = useCallbackRef((event: RowClickedEvent<MarketSelectorRow>) => {
    const row = event.data;
    if (!row) {
      // we're a group row
      const marketName = event.node.key;
      if (marketName) {
        const groupRowChecked =
          event.api.getCellValue({ rowNode: event.node, colKey: MARKET_SELECTOR_CHECKBOX_COLID }) ?? false;
        selectMarket(marketName, !groupRowChecked);
      }
      return;
    }

    // The user cant click the row to select a disabled item. However, if the item already is selected, the user can click to de-select.
    if (!isMarketSelectorItemSelectable(row.item) && !row.selected) {
      return;
    }

    setSelected(row.item.name, !selectionsSet.has(row.item.name));
  });

  const gridOptions: GridOptions = useMemo(
    () => ({
      rowClassRules: {
        'market-selector-row-selected': (params: RowClassParams<MarketSelectorRow>) => {
          if (params.node.data != null) {
            return !!params.node.data?.selected;
          }

          return params.node.allLeafChildren?.some(child => child.data?.selected) ?? false;
        },
        'market-selector-row-disabled': (params: RowClassParams<MarketSelectorRow>) => {
          if (params.node.group) {
            return !isMarketParentRowSelectable(params.node);
          }

          const availability = params.node.data?.item.availability.availability;
          if (availability == null) {
            return false;
          }

          return availability <= AvailabilityEnum.Disabled;
        },
      },
      onRowClicked: handleRowClicked,
      autoGroupColumnDef: marketSelectorAutoGroupColumnDef,
      groupRemoveSingleChildren: true,
      // We handle selection on our own in this component, as in we don't rely on any AgGrid selection functionality.
      // Even the checkboxes are our own rendered components.
      rowSelection: DEFAULT_BLOTTER_SELECTION_READONLY_PARAMS,
      groupDefaultExpanded: -1,
      defaultColDef: {
        suppressHeaderMenuButton: true,
        ...DEFAULT_BLOTTER_COLDEF,
      },
      suppressContextMenu: true,
      rowHeight: 40,
      autoSizeStrategy: {
        type: 'fitGridWidth',
      },
    }),
    [handleRowClicked]
  );

  const columns: Column[] = useMemo(() => {
    return [...BASE_COLUMNS, ...getEnrichmentColumns(advancedMode, enrichments)];
  }, [advancedMode, enrichments]);

  const blotterTable = useBlotterTable({
    dataObservable,
    rowID: 'key' satisfies keyof MarketSelectorRow,
    columns,
    gridOptions,
  });

  return (
    <>
      <Flex flexDirection="column" h="100%" data-testid="market-selector-panel-blotter">
        <Tabs {...tabs} size={TabSize.Large} h="100%">
          <TabList
            isBordered
            rightItems={
              <Button disabled={selectionsSet.size === 0} onClick={() => unselectAll()} size={FormControlSizes.Small}>
                Clear All
              </Button>
            }
          >
            {tabs.items.map((tab, index) => (
              <Tab key={index} {...tab} />
            ))}
          </TabList>
          <TabPanel h="100%">
            <Flex h="100%" flexDirection="column">
              <Input
                prefix={<Icon icon={IconName.Search} />}
                placeholder="Search..."
                clearable={true}
                value={blotterTable.blotterTableFiltersProps.quickFilterText}
                onChange={e => blotterTable.blotterTableFiltersProps.onQuickFilterTextChanged(e.target.value)}
                data-testid="market-selector-panel-search"
                ref={blotterSearchRef}
                style={{
                  border: 'none',
                  borderRadius: '0',
                }}
              />
              <StyleWrapper h="100%">
                <BlotterTable {...blotterTable} density={BlotterDensity.Default} background="colors.gray.040" />
              </StyleWrapper>
              <MarketSelectorSelectionSummary rows={selectedRows} />
            </Flex>
          </TabPanel>
        </Tabs>
      </Flex>
    </>
  );
};

const MARKET_SELECTOR_CHECKBOX_COLID = 'checkbox';
const BASE_COLUMNS: Column[] = [
  {
    type: 'custom',
    id: MARKET_SELECTOR_CHECKBOX_COLID,
    field: 'selected',
    pinned: 'left',
    params: {
      lockPinned: true,
      suppressColumnsToolPanel: true,
      suppressHeaderMenuButton: true,
      resizable: false,
      lockPosition: true,
      lockVisible: true,
      maxWidth: 27, // 27 is the magic number which gives the col enough width to render the left padding and the entire checkbox
      headerClass: 'ag-header-custom-checkbox', // we need to do some padding fixups so we have our own class for this situation
      valueGetter: (params: ValueGetterParams<MarketSelectorRow>): boolean => {
        return params.node?.data?.selected ?? false;
      },
      aggFunc: ({ values }: IAggFuncParams<MarketSelectorRow, boolean>) => {
        return values.some(value => value === true);
      },
      cellRenderer: MarketSelectorBlotterCheckboxCellRenderer,
      headerComponent: MarketSelectorBlotterHeaderComponent,
    } satisfies ColDef,
  },
  {
    type: 'market',
    id: 'market',
    field: 'item.market.Name',
    title: 'Markets',
    rowGroup: true,
    hide: true,
  },
] satisfies ColumnDef<MarketSelectorRow>[];

// Builds the correct enrichment columns. Will look at the mode we're in and the enrichments available.
function getEnrichmentColumns(advancedMode: boolean, enrichments: MarketSelectorEnrichmentSpec<unknown>[]): Column[] {
  const columns = enrichments.flatMap(e => e.columns);
  const visibleColIDs = new Set(
    enrichments.flatMap(e => (advancedMode ? e.advancedModeVisibleColIDs : e.simpleModeVisibleColIDs) ?? [])
  );
  return columns.map(c => ({ ...c, hide: !visibleColIDs.has(getAgGridColId(c)) }));
}

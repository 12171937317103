import type { ICellRendererParams } from 'ag-grid-community';

import { Checkbox } from '@talos/kyoko';
import { noop } from 'lodash-es';
import { useMarketSelectorContext } from '../MarketSelectorContext';
import { isMarketSelectorItemSelectable } from '../utils/items';
import type { MarketSelectorRow } from './types';

export const MarketSelectorBlotterCheckboxCellRenderer = (params: ICellRendererParams<MarketSelectorRow, boolean>) => {
  const { setSelected } = useMarketSelectorContext();

  const data = params.node.data;
  const checked = params.value ?? false;

  if (!data) {
    return <GroupCheckbox params={params} />;
  }

  // We disable the item if it shouldnt be selectable, and it isn't already selected. We want to allow the user to de-select
  // selected but problematic/"offline" selections
  const disabled = !isMarketSelectorItemSelectable(data.item) && !checked;

  return (
    <Checkbox
      checked={checked}
      disabled={disabled}
      inputRef={ref => {
        if (!ref) {
          return;
        }

        // https://stackoverflow.com/questions/63964553/ag-grid-prevent-onrowclicked-event-when-clicking-icon-inside-cell
        // We have to do this in order to listen to the native event and be able to cancel it so it doesnt trigger
        // our blotters row clicked event
        ref.onchange = (e: Event) => {
          e.stopPropagation();
          const checked: boolean = e.target?.['checked'];
          setSelected(data.key, checked);
        };
      }}
      // Have to provide some function here to keep everyone happy
      onChange={noop}
    />
  );
};

const GroupCheckbox = ({ params }: { params: ICellRendererParams<MarketSelectorRow, boolean> }) => {
  const { selectMarket } = useMarketSelectorContext();
  const marketName = params.node.allLeafChildren?.at(0)?.data?.item.market.Name;
  if (!marketName) {
    return <></>;
  }
  const checked = params.value ?? false;

  const allChildrenDisabled = params.node.allLeafChildren?.every(child =>
    child.data ? !isMarketSelectorItemSelectable(child.data.item) : true
  );
  // The group row checkbox is disabled if... there are no selectable children, and we are not checked ourselves
  const disabled = allChildrenDisabled && !checked;

  return (
    <Checkbox
      checked={checked}
      disabled={disabled}
      inputRef={ref => {
        if (!ref) {
          return;
        }

        // https://stackoverflow.com/questions/63964553/ag-grid-prevent-onrowclicked-event-when-clicking-icon-inside-cell
        // We have to do this in order to listen to the native event and be able to cancel it so it doesnt trigger
        // our blotters row clicked event
        ref.onchange = (e: Event) => {
          e.stopPropagation();
          const checked: boolean = e.target?.['checked'];
          selectMarket(marketName, !checked);
        };
      }}
      // Have to provide some function here to keep everyone happy
      onChange={noop}
    />
  );
};

import {
  IconName,
  MixpanelEventSource,
  ReconStateEnum,
  filterByCellValueMenuItem,
  getRowNodesToOperateOn,
  selectAll,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useMixpanel,
  type FilterableProperty,
  type ReconMismatch,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams } from 'ag-grid-enterprise';
import { compact } from 'lodash-es';
import { useResolveReconMismatchesDrawer } from '../../../../hooks/useResolveReconMismatchesDrawer';
import { useMarketTabs } from '../../../../providers/MarketTabsProvider';
import { colIDToFilterBuilderKey } from './useReconMismatchesFilter';

const RESOLVABLE_STATES = [ReconStateEnum.PendingReview];

export const useResolveMismatchMenu = ({
  openClause,
  filterableProperties,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
}) => {
  const mixpanel = useMixpanel();
  const { openResolveReconMismatchesDrawer } = useResolveReconMismatchesDrawer();
  const { prepareReconDetailsMarketTab } = useMarketTabs();

  const getMenuItems = useDynamicCallback((params: GetContextMenuItemsParams<ReconMismatch>) => {
    const selectedMismatches = compact(getRowNodesToOperateOn(params).map(node => node.data));
    const resolvableSelectedMismatches = selectedMismatches.filter(mismatch =>
      RESOLVABLE_STATES.includes(mismatch.State)
    );

    return compact([
      selectedMismatches.length > 0
        ? {
            name: `Open Details (${selectedMismatches.length})`,
            action: () => {
              prepareReconDetailsMarketTab(
                selectedMismatches.map(m => m.ID),
                MixpanelEventSource.ReconMismatchesBlotter
              );
            },
            icon: `<i class="ag-icon ${IconName.Deepdive}"/>`,
          }
        : undefined,
      resolvableSelectedMismatches.length > 0
        ? {
            name: `Resolve (${resolvableSelectedMismatches.length})`,
            action: () => {
              openResolveReconMismatchesDrawer(
                resolvableSelectedMismatches.map(m => m.ID),
                MixpanelEventSource.ReconMismatchesBlotter
              );
            },
            icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
          }
        : undefined,
    ]);
  });

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    return compact([
      ...filterByCellValueMenuItem({
        params,
        filterableProperties,
        openClause,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      ...getMenuItems(params),
      'separator',
      selectAll(params, mixpanel),
      'separator',
      ...getDefaultContextMenuItems(params),
    ]);
  });

  return {
    getContextMenuItems,
  };
};

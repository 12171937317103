import Big, { type BigSource } from 'big.js';
import { isEmpty } from 'lodash-es';
import type { Allocation } from '../../types';
import { logger } from '../../utils';

export const validatePrecision = (minSize: BigSource, value: BigSource | undefined): boolean => {
  if (value == null) {
    return false;
  }
  const quantity = Big(value);

  if (minSize === '0') {
    return true;
  }

  try {
    const scaledQuantity = quantity.div(minSize);
    const roundedQuantity = scaledQuantity.round();
    return scaledQuantity.eq(roundedQuantity);
  } catch (e) {
    logger.error(e as Error);
  }

  return true;
};

export function calculateMidPrice(
  bidPrice: BigSource | undefined | null,
  offerPrice: BigSource | undefined | null
): BigSource | null {
  if (bidPrice == null && offerPrice == null) {
    return null;
  }
  const bp = Big(bidPrice || 0);
  const op = Big(offerPrice || 0);
  if (!bp.eq(0) && !op.eq(0)) {
    return bp.plus(op).div(2);
  } else if (bp.eq(0)) {
    return op;
  } else {
    return bp;
  }
}

export function validateMidPriceDiff(
  midPrice: BigSource | undefined | null,
  value: BigSource | undefined | null,
  allowedDiff = 0.05
): boolean {
  if (midPrice == null || value == null) {
    return true;
  }
  const min = Big(midPrice).times(1 - allowedDiff);
  const max = Big(midPrice).times(1 + allowedDiff);
  return min.lte(value) && max.gte(value);
}

export const validateSubAccountAllocations = (arr: Allocation[] | null | undefined, requiredTotal: number) => {
  if (arr == null) {
    return false;
  }
  const values: string[] = arr.map(v => v.value);
  let valueError = false;
  values.forEach(value => {
    // Reject if value is negative, or if greater than required total
    if (Big(value || 0).lt(0)) {
      valueError = true;
    }
    if (Big(value || 0).gt(requiredTotal)) {
      valueError = true;
    }
  });
  if (valueError) {
    return false;
  }
  const total = values.reduce((total, value) => total.add(value || 0), Big(0));
  return total.eq(requiredTotal);
};

const isInvalid = (prop: string) => isEmpty(prop);
export const validateFilledSubAccountAllocationsFields = (arr: Allocation[] | null | undefined) => {
  if (arr == null) {
    return false;
  }
  let returnVal = true;
  arr.forEach(item => {
    if (isInvalid(item.subAccount) || isInvalid(item.value)) {
      returnVal = false;
    }
  });
  return returnVal;
};

export const validatePositiveValues = (arr: string[]) => {
  let returnVal = true;
  arr.forEach(item => {
    if (item != null && Big(item || 0).lte(0)) {
      returnVal = false;
    }
  });
  return returnVal;
};

import {
  MultilegReportingTypeEnum,
  getTypedEntries,
  isOrderComplete,
  parseDate,
  useObservable,
  useObservableValue,
  type Order,
  type OrderAnalytic,
  type ResolutionEnumNoPrefix,
} from '@talos/kyoko';
import { last } from 'lodash-es';
import { useEffect, useState } from 'react';
import { filter, map, scan, tap } from 'rxjs/operators';
import { getAnalyticsResolution } from 'utils/getAnalyticsResolution';
import { useOrderAnalytics } from './useOrderAnalytics';

export function useLatestOrderAnalytics({ order, tag }: { order: Order; tag: string }) {
  const [resolution, setResolution] = useState<ResolutionEnumNoPrefix>();
  const { SubmitTime, Timestamp, OrdStatus } = order;
  useEffect(() => {
    if (resolution != null) {
      // If we've already opened the analytics pane, don't update resolution
      return;
    }
    const startTime = parseDate(SubmitTime);
    let endTime = new Date();
    if (isOrderComplete(OrdStatus)) {
      // If order is done, we use the time of last update to figure out the resolution
      endTime = parseDate(Timestamp);
    }
    setResolution(getAnalyticsResolution(startTime, endTime));
  }, [OrdStatus, Timestamp, SubmitTime, resolution]);
  const analyticsObservable = useOrderAnalytics({
    orderID: order.OrderID,
    resolution,
    tag: `${tag}/useLatestOrderAnalytics`,
  });
  const [receivedAnalytics, setReceivedAnalytics] = useState(false);
  // If multileg then we want to get only the leg messages for the given order analytic screen
  const legOrParentAnalyticsObservable = useObservable(
    () =>
      analyticsObservable.pipe(
        map(json => {
          const dataPerLeg: Record<number, OrderAnalytic[]> = {};
          for (const oa of json.data) {
            const legIndex =
              oa.MultilegReportingType === MultilegReportingTypeEnum.Parent ? 0 : (oa?.LegIndex || 0) + 1;
            if (dataPerLeg[legIndex] === undefined) {
              dataPerLeg[legIndex] = [];
            }
            dataPerLeg[legIndex].push(oa);
          }
          return {
            ...json,
            data: dataPerLeg,
          };
        }),
        tap(() => setReceivedAnalytics(true)),
        filter(json => Object.keys(json.data).length !== 0)
      ),
    [analyticsObservable]
  );

  const latestAnalyticsObservable = useObservable(
    () =>
      legOrParentAnalyticsObservable.pipe(
        scan((dataPerLegLatest, json) => {
          for (const [legNumber, legData] of getTypedEntries(json.data)) {
            const lastLegData: OrderAnalytic | undefined = last(legData) ?? dataPerLegLatest[legNumber];
            if (lastLegData) {
              dataPerLegLatest[legNumber] = lastLegData;
            }
          }
          return { ...dataPerLegLatest };
        }, {} as Record<number, OrderAnalytic>)
      ),
    [legOrParentAnalyticsObservable]
  );
  const latestAnalytics = useObservableValue(() => latestAnalyticsObservable, [latestAnalyticsObservable]);

  // Check if resolution can be lowered based on Order age
  useEffect(() => {
    const startTime = Sugar.Date.create(SubmitTime);
    let endTime = Sugar.Date.create();
    if (isOrderComplete(OrdStatus)) {
      // If order is done, we use the time of last update to figure out the resolution
      endTime = Sugar.Date.create(Timestamp);
    }
    const res = getAnalyticsResolution(startTime, endTime);
    if (resolution && resolution !== res) {
      setResolution(res);
    }
  }, [latestAnalytics, SubmitTime, resolution, Timestamp, OrdStatus]);

  return {
    latestAnalytics,
    receivedAnalytics,
    legOrParentAnalyticsObservable,
  };
}

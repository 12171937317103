import type { MarketSecurityStatus } from '@talos/kyoko';
import { get } from 'lodash-es';
import { useMemo } from 'react';
import { AvailabilityEnum, type AvailabilityCondition, type AvailabilityResult } from '../types';
import {
  getMSSConditionAccountLevelAvailability,
  MSSConditionInfo,
  type MSSConditionSymbolLevelResult,
} from './useMSSEnabledCondition';

export type CapabilityKey = keyof NonNullable<MarketSecurityStatus['Capabilities']>;

interface UseMSSCapabilityEnabledConditionParams {
  /** The capability to check */
  capability: CapabilityKey;
  /** The complete map of MSS records to base the condition checking on */
  mssByAccountBySymbol: Map<string, Map<string, MarketSecurityStatus>> | undefined;
  /** The symbols to check the MSS record of for each account. Will only be more than one item in the unified liquidity case. */
  symbols: string[];
  /** Whether or not unified liquidity is enabled */
  unifiedLiquidityEnabled?: boolean;
  /** If true, will just Warn instead of Disabling the item on condition failure */
  justWarnOnFailure?: boolean;
}

/**
 * A AvailabilityCondition which asserts that the MarketSecurityStatus.[Capability] is true.
 *
 * If no MarketSecurityStatus has been resolved and attached to the passed record, returns true.
 * If there is no Capabilities struct on the successfully resolved MarketSecurityStatus record, returns false.
 */
export const useMSSCapabilityEnabledCondition = ({
  capability,
  symbols,
  mssByAccountBySymbol,
  unifiedLiquidityEnabled = false,
  justWarnOnFailure,
}: UseMSSCapabilityEnabledConditionParams): AvailabilityCondition => {
  const failureAvailability = justWarnOnFailure ? AvailabilityEnum.Warning : AvailabilityEnum.Disabled;
  return useMemo(
    () =>
      ({
        id: `mss-${capability}-enabled`,
        ready: mssByAccountBySymbol != null,
        condition: (market, marketAccount) => {
          if (!mssByAccountBySymbol) {
            return { availability: AvailabilityEnum.Ok };
          }

          if (!marketAccount) {
            // MarketSecurityStatuses are only applicable for market accounts
            return { availability: AvailabilityEnum.Ok };
          }

          const results: MSSConditionSymbolLevelResult[] = symbols.map(symbol => {
            const mss = mssByAccountBySymbol.get(marketAccount.Name)?.get(symbol);
            if (!mss) {
              // If we can't find any MSS something is wrong, and we should disable the item.
              return { symbol, availability: failureAvailability, failedReason: 'No MSS found' };
            }

            const capabilitiesStruct = mss.Capabilities;
            if (!capabilitiesStruct) {
              return {
                symbol,
                availability: failureAvailability,
                failedReason: 'No capabilities',
              };
            }

            const capabilityOk = get(capabilitiesStruct, capability) === true;
            return capabilityOk
              ? { symbol, availability: AvailabilityEnum.Ok }
              : {
                  symbol,
                  availability: failureAvailability,
                  failedReason: `${capability} disabled`,
                };
          });

          const accountLevelAvailability = getMSSConditionAccountLevelAvailability(results);

          return {
            availability: accountLevelAvailability,
            infoNode: (
              <MSSConditionInfo
                accountName={marketAccount.Name}
                symbolLevelResults={results}
                unifiedLiquidityEnabled={unifiedLiquidityEnabled}
              />
            ),
          } satisfies AvailabilityResult;
        },
      } satisfies AvailabilityCondition),
    [capability, mssByAccountBySymbol, symbols, unifiedLiquidityEnabled, failureAvailability]
  );
};

import styled, { css } from 'styled-components';
import { setAlpha } from '../../styles';
import { Box, Flex, Grid } from '../Core';

export const Content = styled(Flex)`
  ${({ theme }) => css`
    gap: ${theme.spacingSmall}px;
    color: ${setAlpha(0.8, theme.colorTextImportant)};
    padding: ${theme.spacingSmall}px;
    height: 100%;
    font-size: ${theme.fontSizeSm}rem;
    line-height: ${theme.lineHeightSm}rem;
    justify-content: center;
    align-items: center;
  `}
`;

export const AlertBannerWrapper = styled(Grid)`
  position: absolute;
  inset: 0;
  background: ${({ color }) => color};
  background: linear-gradient(
    90deg,
    ${({ color }) => color} 0%,
    ${({ color }) => color} max(10px, 1%),
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0) 90%,
    ${({ color }) => color} min(calc(100% - 10px), 99%),
    ${({ color }) => color} 100%
  );
  pointer-events: none;
`;

export const Stripes = styled(Box)`
  margin: ${({ theme }) => theme.spacingSmall}px;
  background-image: ${({ theme }) =>
    `linear-gradient(120deg, transparent 22.73%, ${setAlpha(0.12, theme.colors.black.default)} 22.73% , ${setAlpha(
      0.12,
      theme.colors.black.default
    )} 50%, transparent 50%, transparent 72.73%, ${setAlpha(0.12, theme.colors.black.default)} 72.73%, ${setAlpha(
      0.12,
      theme.colors.black.default
    )} 100%)`};
  background-size: 25.4px 44px;
  background-repeat: repeat;
  background-clip: content-box;
`;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import {
  ACTION,
  Box,
  Button,
  ButtonVariants,
  Dialog,
  Flex,
  FormControlSizes,
  FormGroup,
  FormMessage,
  Grid,
  HStack,
  Icon,
  IconName,
  Input,
  LoaderSizes,
  LoaderTalos,
  MarketAccountOwnershipEnum,
  MixpanelEvent,
  NumberInput,
  SearchSelect,
  Text,
  TextDivider,
  TransferStatusEnum,
  VStack,
  format,
  formattedTime,
  logger,
  toBig,
  useCurrency,
  useDisclosure,
  useMarketAccountsContext,
  useMixpanel,
  useObservable,
  useObservableValue,
  type Market,
  type MarketAccount,
} from '@talos/kyoko';

import { useBalances } from 'hooks/useBalances';
import { useRoleAuth } from 'hooks/useRoleAuth';
import { useTransfers } from 'providers';

import { compact, isEqual } from 'lodash-es';
import { usePrevious } from 'react-use';
import { useTheme } from 'styled-components';
import type { BalancesFilter } from '../../types';
import { AdminMACHelpTooltip } from './AdminMACHelpTooltip';
import { CenteredRow, DialogWrapper, InfoWrapper } from './styles';
import { getEmptyTransferForm, type SourceDestinationOption, type TransferForm } from './types';
import { useTransferFormOptions } from './useTransferFormOptions';
import { useTransferFormValidation } from './useTransferFormValidation';

const getProviderLabel = (providerOption: Market) => providerOption.DisplayName;
const getAssetLabel = (asset: string) => asset;
const getSourceDestinationLabel = (o: SourceDestinationOption) => o.label;
const getSourceDestinationDescription = (o: SourceDestinationOption) => o.description ?? '';
const getSourceDestinationGroup = (o: SourceDestinationOption) => o.marketAccount.TypeDescription ?? 'Other';

const ACCOUNT_DROPDOWN_WIDTH = 310;
const ACCOUNT_DROPDOWN_HEIGHT = 400;

const sourceDestinationAdditionalSearchKeys = ['addressForCurrency'] satisfies (keyof SourceDestinationOption)[];

interface TransferFormComponentProps {
  initialForm?: TransferForm;
  scrollLogs?: boolean;
  portalizeDropdowns?: boolean;
}

function calculateInitialForm(
  initialForm: TransferForm | undefined,
  marketAccountsByID: Map<number, MarketAccount>
): TransferForm {
  if (initialForm == null) {
    return getEmptyTransferForm();
  }

  const definedSourceOrDestination = initialForm.source ?? initialForm.destination;
  if (definedSourceOrDestination != null && !initialForm.provider) {
    // There is a specified source or destination but there is no provider. Apply a provider.
    const providerName = marketAccountsByID.get(definedSourceOrDestination.marketAccount.MarketAccountID)?.Market;
    return providerName ? { ...initialForm, provider: providerName } : getEmptyTransferForm();
  }

  return initialForm;
}

export function TransferFormComponent({
  initialForm,
  scrollLogs = false,
  portalizeDropdowns = true,
}: TransferFormComponentProps) {
  const { marketAccountsByID } = useMarketAccountsContext();
  const { initiateTransfer, transfersObs } = useTransfers();
  const mixpanel = useMixpanel();

  const [transferForm, setTransferForm] = useState(calculateInitialForm(initialForm, marketAccountsByID));
  const [showErrors, setShowErrors] = useState(false);
  const [availableSourceAmount, setAvailableSourceAmount] = useState<string | undefined>();
  const [availableDestinationAmount, setAvailableDestinationAmount] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [logMessages, setLogMessages] = useState<string[]>([]);

  const { isAuthorized } = useRoleAuth();
  const authorizedForTransfer = isAuthorized(ACTION.SUBMIT_TRANSFER);

  const { clientID, provider, amount, asset, chainCurrency, source, destination, reference, description } =
    transferForm;

  const { providerOptions, assetOptions, chainCurrencyOptions, sourceOptions, destinationOptions } =
    useTransferFormOptions(transferForm);

  const currencyInfo = useCurrency(asset);
  const { DefaultIncrement } = currencyInfo ?? {};

  const updateTransfer = useCallback((update: Partial<TransferForm>) => {
    setTransferForm(prev => ({ ...prev, ...update }));
  }, []);

  const handleClear = useCallback(() => {
    updateTransfer(getEmptyTransferForm());
    setShowErrors(false);
  }, [updateTransfer]);

  const balancesFilter: BalancesFilter = useMemo(
    () => ({
      MarketAccounts: compact([source, destination].map(option => option?.marketAccount.Name)),
      Currencies: compact([asset]),
    }),
    [source, destination, asset]
  );

  const { balancesByCurrencyMarketAccountIDObs } = useBalances({
    filter: balancesFilter,
    tag: 'TransferFormComponent',
    openStreamWithoutFilter: false,
  });
  const balancesByCurrencyMarketAccountID = useObservableValue(
    () => balancesByCurrencyMarketAccountIDObs,
    [balancesByCurrencyMarketAccountIDObs]
  );

  // If destination changed, see if reference data exists to populate the Reference
  // If there is no reference data, clear out reference and description
  useEffect(() => {
    if (destination?.marketAccount?.ReferenceData && provider !== 'fireblocks') {
      updateTransfer({ reference: destination.marketAccount.ReferenceData, description: '' });
    } else {
      updateTransfer({ reference: '', description: '' });
    }
  }, [source, destination, marketAccountsByID, provider, updateTransfer]);

  // Whenever the provider changes, and it's not "", clear log messages
  useEffect(() => {
    provider !== '' && setLogMessages([]);
  }, [provider]);

  // Available Amounts
  useEffect(() => {
    const sourceAccountBalance = source
      ? balancesByCurrencyMarketAccountID?.get(asset)?.get(source.marketAccount.MarketAccountID)
      : undefined;
    const destinationAccountBalance = destination?.marketAccount
      ? balancesByCurrencyMarketAccountID?.get(asset)?.get(destination.marketAccount?.MarketAccountID)
      : undefined;

    setAvailableSourceAmount(sourceAccountBalance?.AvailableAmount || (source ? '0' : undefined));
    // [ch24667] Don't show balance for external accounts
    if (destination?.marketAccount?.Ownership === MarketAccountOwnershipEnum.Internal) {
      setAvailableDestinationAmount(destinationAccountBalance?.AvailableAmount || (destination ? '0' : undefined));
    } else {
      setAvailableDestinationAmount(undefined);
    }
  }, [asset, balancesByCurrencyMarketAccountID, destination, source]);

  const handleSubmit = useCallback(() => {
    if (!source?.marketAccount || !destination?.marketAccount) {
      logger.error(new Error(`Unknown Source Account Info or Destination Account Info for ${provider} Transfer`));
      return;
    }
    const newClientID = uuid();
    updateTransfer({ clientID: newClientID });
    setIsLoading(true);
    setLogMessages([]);
    mixpanel.track(MixpanelEvent.InitiatePrincipalTransfer);
    initiateTransfer({
      clientID: newClientID,
      currency: asset,
      chainCurrency: chainCurrency || undefined,
      amount,
      market: provider,
      fromMarketAccountID: source?.marketAccount.MarketAccountID,
      toMarketAccountID: destination?.marketAccount.MarketAccountID,
      fromSourceAccountID: source?.marketAccount.SourceAccountID,
      toSourceAccountID: destination?.marketAccount.SourceAccountID,
      description,
      reference,
    });
  }, [
    mixpanel,
    initiateTransfer,
    updateTransfer,
    asset,
    chainCurrency,
    amount,
    provider,
    source,
    destination,
    description,
    reference,
  ]);

  const confirmTransferDialog = useDisclosure();

  const transferResponseObservable = useObservable(
    () =>
      transfersObs.pipe(
        // Find the last relevant message
        map(transfers => [...transfers].reverse().find(t => t.ClientID === clientID)),
        filter(transfer => transfer != null),
        distinctUntilChanged((p, n) => (p && n ? isEqual(p, n) : false))
      ),

    [clientID, transfersObs]
  );

  const response = useObservableValue(() => transferResponseObservable, [transferResponseObservable]);

  // Transfer Status Log Messages
  const prevStatus = usePrevious(response?.Status);
  useEffect(() => {
    if (!response) {
      return;
    }
    // Ignore writing to log messages if the status hasn't changed
    const { Text: text, Status: status, Timestamp: timestamp, TransferID: transferID } = response;
    if (prevStatus != null && status === prevStatus) {
      return;
    }
    let message = '';
    switch (status) {
      case TransferStatusEnum.Pending:
        setLogMessages([`Transfer ${transferID}`]);
        message = 'Your transfer is pending';
        setIsLoading(true);
        break;
      case TransferStatusEnum.WaitingForExternalAuth:
        message = 'Your transfer is waiting for external authorization';
        setIsLoading(false);
        handleClear();
        break;
      case TransferStatusEnum.Processing:
        message = 'Your transfer is processing';
        setIsLoading(false);
        handleClear();
        break;
      case TransferStatusEnum.Rejected:
        message = 'Your transfer was rejected';
        setIsLoading(false);
        setShowErrors(false);
        break;
      case TransferStatusEnum.Completed:
        message = 'Your transfer was completed';
        setIsLoading(false);
        setShowErrors(false);
        break;
      default:
        return;
    }

    if (text) {
      message += `: ${text}`;
    } else {
      message += '.';
    }
    setLogMessages(prev => [...prev, `${formattedTime(timestamp)} ${message}`]);
  }, [handleClear, prevStatus, response, updateTransfer]);

  const allInputsDisabled = !authorizedForTransfer || isLoading;

  const { errors, disabledInputs } = useTransferFormValidation(
    transferForm,
    allInputsDisabled,
    chainCurrencyOptions,
    availableSourceAmount,
    balancesByCurrencyMarketAccountID
  );

  const theme = useTheme();

  const handleAssetChange = useCallback(
    (asset: string | undefined) =>
      // Reset source, destination and chainCurrency selections on asset change
      updateTransfer({ asset, source: undefined, destination: undefined, chainCurrency: '' }),
    [updateTransfer]
  );

  const handleProviderChange = useCallback(
    (po: Market | undefined) => {
      // Reset a bunch of stuff on provider change
      updateTransfer({
        provider: po?.Name,
        asset: '',
        amount: '',
        source: undefined,
        destination: undefined,
        reference: '',
        chainCurrency: '',
      });
      setShowErrors(false);
    },
    [updateTransfer]
  );

  const handleSourceChange = useCallback((newSource: SourceDestinationOption | undefined) => {
    setTransferForm(prev => {
      return {
        ...prev,
        source: newSource,
        chainCurrency: '',
      };
    });
  }, []);

  const handleDestinationChange = useCallback((newDestination: SourceDestinationOption | undefined) => {
    setTransferForm(prev => {
      return {
        ...prev,
        destination: newDestination,
        chainCurrency: '',
      };
    });
  }, []);

  const providerSelection = useMemo(
    () => providerOptions.find((po: Market) => po.Name === provider),
    [provider, providerOptions]
  );

  const disableChainSelection = chainCurrencyOptions.required === false || source == null || destination == null;
  const maxAvailableSet = areStringAmountsEqual(transferForm.amount, availableSourceAmount);

  return (
    <>
      <Box mb="spacingBig">
        <Text size={theme.fontSizeBig} color={theme.colorTextAttention}>
          New Transfer
        </Text>
      </Box>
      <HStack w="100%" alignItems="flex-start" gap="spacingComfortable">
        <FormGroup label="Provider" disabled={disabledInputs.provider} flex="1">
          <SearchSelect
            disabled={disabledInputs.provider}
            selection={providerSelection}
            options={providerOptions}
            onChange={handleProviderChange}
            getLabel={getProviderLabel}
            portalize={portalizeDropdowns}
            data-testid="providers-select"
          />
        </FormGroup>
        <FormGroup label="Asset" disabled={disabledInputs.asset} flex="1">
          <SearchSelect
            disabled={disabledInputs.asset}
            selection={asset}
            options={assetOptions}
            onChange={handleAssetChange}
            getLabel={getAssetLabel}
            portalize={portalizeDropdowns}
            data-testid="asset-select"
          />
        </FormGroup>
      </HStack>

      <TextDivider text="Source, Destination & Amount" mb="spacingMedium" />
      <FormGroup
        label={<AccountFormGroupLabel label="Source" account={source?.marketAccount.MarketAccountID} asset={asset} />}
        mb="spacingLarge"
        disabled={disabledInputs.source}
        help={
          availableSourceAmount && (
            <>
              Available balance:{' '}
              <Text color="colorTextImportant">
                {format(availableSourceAmount, {
                  spec: DefaultIncrement,
                })}{' '}
                {asset}
              </Text>
            </>
          )
        }
      >
        <SearchSelect
          selection={source}
          onChange={handleSourceChange}
          options={sourceOptions}
          getLabel={getSourceDestinationLabel}
          getDescription={getSourceDestinationDescription}
          showDescriptionInButton
          disabled={disabledInputs.source}
          touched={showErrors}
          invalid={errors?.source != null}
          getGroup={getSourceDestinationGroup}
          maxHeight={ACCOUNT_DROPDOWN_HEIGHT}
          portalize={portalizeDropdowns}
          dropdownWidth={ACCOUNT_DROPDOWN_WIDTH}
          additionalSearchKeys={sourceDestinationAdditionalSearchKeys}
          showClear
          data-testid="source-select"
        />
      </FormGroup>

      <FormGroup
        label={
          <AccountFormGroupLabel
            label="Destination"
            account={destination?.marketAccount.MarketAccountID}
            asset={asset}
          />
        }
        mb="spacingLarge"
        disabled={disabledInputs.destination}
        help={
          availableDestinationAmount && (
            <>
              Available balance:{' '}
              <Text color="colorTextImportant">
                {format(availableDestinationAmount, {
                  spec: DefaultIncrement,
                })}{' '}
                {asset}
              </Text>
            </>
          )
        }
      >
        <SearchSelect
          selection={destination}
          onChange={handleDestinationChange}
          options={destinationOptions}
          getLabel={getSourceDestinationLabel}
          getDescription={getSourceDestinationDescription}
          showDescriptionInButton
          disabled={disabledInputs.destination}
          touched={showErrors}
          invalid={errors?.destination != null}
          getGroup={getSourceDestinationGroup}
          maxHeight={ACCOUNT_DROPDOWN_HEIGHT}
          portalize={portalizeDropdowns}
          dropdownWidth={ACCOUNT_DROPDOWN_WIDTH}
          additionalSearchKeys={sourceDestinationAdditionalSearchKeys}
          showClear
          data-testid="destination-select"
        />
      </FormGroup>
      <FormGroup
        label="Amount"
        disabled={disabledInputs.amount}
        labelSuffix={
          <Button
            size={FormControlSizes.Xxs}
            dim={maxAvailableSet}
            disabled={disabledInputs.amount || !availableSourceAmount}
            onClick={() => updateTransfer({ amount: availableSourceAmount })}
          >
            Max Available
          </Button>
        }
      >
        <NumberInput
          disabled={disabledInputs.amount}
          touched={showErrors}
          invalid={errors?.amount != null}
          name="amount"
          onChange={value => updateTransfer({ amount: value })}
          autoComplete="off"
          min="0"
          value={amount}
          suffix={asset}
          data-testid="amount-input"
        />
      </FormGroup>

      <TextDivider text="Misc" mb="spacingMedium" />
      <HStack w="100%" alignItems="flex-start" gap="spacingComfortable">
        <FormGroup label="Chain" flex="1">
          <SearchSelect
            selection={chainCurrency}
            options={chainCurrencyOptions.chainOptions}
            onChange={chainCurrency => updateTransfer({ chainCurrency })}
            getLabel={chainCurrency => chainCurrency}
            portalize={portalizeDropdowns}
            disabled={disableChainSelection}
            touched={showErrors}
            invalid={errors?.chainCurrency != null}
            showClear
            data-testid="chain-select"
          />
        </FormGroup>

        <FormGroup label="Reference (Provider)" disabled={disabledInputs.reference} flex="1">
          <Input
            name="reference"
            onChange={e => updateTransfer({ reference: e.target.value })}
            autoComplete="off"
            value={reference}
            placeholder={provider === 'fireblocks' ? 'Disabled for Fireblocks' : 'Optional'}
            disabled={disabledInputs.reference}
          />
        </FormGroup>
      </HStack>
      <FormGroup label="Note (Talos)" disabled={disabledInputs.description}>
        <Input
          name="description"
          onChange={e => updateTransfer({ description: e.target.value })}
          autoComplete="off"
          value={description}
          placeholder="Optional"
          disabled={disabledInputs.description}
        />
      </FormGroup>

      <Grid columns="1fr 1fr" gap="spacingMedium">
        <Box style={scrollLogs ? { overflowY: 'scroll', height: '60px' } : undefined}>
          {logMessages.length > 0 && (
            <VStack alignItems="flex-start" gap="spacingDefault">
              {logMessages.map((message, index) => (
                <Text key={`${message}${index}`} textAlign="left">
                  {message}
                </Text>
              ))}
            </VStack>
          )}
          {!authorizedForTransfer ? (
            <InfoWrapper>
              <Icon icon={IconName.ExclamationCircle} color={theme.colors.yellow.lighten} />
              <Text textAlign="left">You are not authorized to submit transfers.</Text>
            </InfoWrapper>
          ) : (
            showErrors && (
              <div data-testid="error-logs">
                {Object.keys(errors).map(error => (
                  <FormMessage key={error as string}>{errors[error as string]}</FormMessage>
                ))}
              </div>
            )
          )}
        </Box>
        <Grid columns="1fr 2fr" gap="spacingMedium" alignItems="flex-start">
          <Button disabled={allInputsDisabled} onClick={handleClear}>
            Clear
          </Button>
          <Flex
            flex="1"
            onMouseEnter={() => {
              if (provider && asset) {
                setShowErrors(true);
                setLogMessages([]);
              }
            }}
          >
            <Button
              width="100%"
              variant={ButtonVariants.Primary}
              disabled={allInputsDisabled || !errors || Object.keys(errors).length > 0}
              onClick={() => confirmTransferDialog.open()}
              data-testid="transfer-button"
            >
              {isLoading ? <LoaderTalos size={LoaderSizes.TINY} /> : 'Transfer'}
            </Button>
          </Flex>
        </Grid>
      </Grid>
      <Dialog
        {...confirmTransferDialog}
        usePortal={false}
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        onConfirm={handleSubmit}
        title="Confirm Transfer"
      >
        <DialogWrapper>
          <div>
            Send{' '}
            <Text color="colorTextImportant">
              {format(amount, {
                spec: DefaultIncrement,
              })}{' '}
              {asset}
            </Text>{' '}
            via <Text color="colorTextImportant">{providerOptions?.find(po => po.Name === provider)?.DisplayName}</Text>
          </div>
          <CenteredRow>
            <Text color="colorTextImportant">{source?.label}</Text> <Icon icon={IconName.ArrowRight} size={22} />{' '}
            <Text color="colorTextImportant">{destination?.label}</Text>
          </CenteredRow>
        </DialogWrapper>
      </Dialog>
    </>
  );
}

interface AccountFormGroupLabelProps {
  label: string;
  account?: number;
  asset?: string;
}
function AccountFormGroupLabel({ label, account, asset }: AccountFormGroupLabelProps) {
  return (
    <HStack gap="spacingDefault" justifyContent="space-between">
      <Text>{label}</Text>
      <AdminMACHelpTooltip account={account} currency={asset} />
    </HStack>
  );
}

function areStringAmountsEqual(a: string | undefined, b: string | undefined) {
  const aBig = toBig(a);
  const bBig = toBig(b);

  // Just treat the case where either (or both!) of them are nullish as falsy, nullish == nullish is arguably not equal anyway
  if (aBig == null || bBig == null) {
    return false;
  }

  return aBig.eq(bBig);
}

import {
  MultiSelectorField,
  ProductTypeEnum,
  SelectorField,
  type CurrencySelectItem,
  type Security,
  type StringSelectItem,
} from '@talos/kyoko';
import { immerable } from 'immer';
import type { OMSReferenceDataState } from '../../types';

interface SpotData {
  symbolField: SelectorField<CurrencySelectItem>;
  marketAccountField: MultiSelectorField<StringSelectItem>;
}

export class Spot {
  [immerable] = true;

  public static readonly type = ProductTypeEnum.Spot;
  private readonly _data: SpotData;

  private constructor(private _referenceData: OMSReferenceDataState, data: SpotData) {
    this._data = data;
  }

  public static createFromBlank(referenceData: OMSReferenceDataState) {
    const symbols = Spot.getAvailableSymbols(referenceData);

    const data = {
      symbolField: new SelectorField<CurrencySelectItem>({
        name: 'Symbol',
        idProperty: 'value',
        availableItems: symbols,
      }),
      marketAccountField: new MultiSelectorField<StringSelectItem>({
        name: 'Market Account',
        idProperty: 'value',
      }),
    };

    return new Spot(referenceData, data);
  }

  public static createFromSecurity(referenceData: OMSReferenceDataState, security: Security): Spot {
    const availableSymbols = Spot.getAvailableSymbols(referenceData);

    const data = {
      symbolField: new SelectorField({
        name: 'Symbol',
        idProperty: 'value',
        value: availableSymbols.find(c => c.value === security?.Symbol),
        availableItems: availableSymbols,
      }),
      marketAccountField: new MultiSelectorField<StringSelectItem>({
        name: 'Market Account',
        idProperty: 'value',
      }),
    };

    return new Spot(referenceData, data);
  }

  public get data(): SpotData {
    return this._data;
  }

  public get security(): Security | undefined {
    const { symbolField } = this.data;
    if (!symbolField.hasValue) {
      return undefined;
    }

    return this._referenceData.securities.securitiesBySymbol.get(symbolField.value!.value);
  }

  public updateSymbol = (symbol: CurrencySelectItem | undefined) => {
    const newData = {
      ...this._data,
      symbolField: this._data.symbolField.updateValue(symbol),
    };
    return this.updateData(newData);
  };

  public updateMarketAccount = (marketAccounts: StringSelectItem[] | undefined) => {
    const newData = {
      ...this._data,
      marketAccountField: this._data.marketAccountField.updateValue(marketAccounts),
    };
    return this.updateData(newData);
  };

  public getType(): ProductTypeEnum {
    return Spot.type;
  }

  private updateData(data: Partial<SpotData>): Spot {
    const newData = {
      ...this._data,
      ...data,
    };
    return new Spot(this._referenceData, newData);
  }

  private static getAvailableSymbols(referenceData: OMSReferenceDataState): CurrencySelectItem[] {
    return referenceData.securities.securitiesList
      .filter(s => s.ProductType === ProductTypeEnum.Spot)
      .map(c => ({ value: c.Symbol, label: c.Symbol, baseCurrency: c.BaseCurrency, quoteCurrency: c.QuoteCurrency }));
  }
}

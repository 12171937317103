import { createContext, memo, useCallback, useContext, useMemo, type PropsWithChildren } from 'react';
import { map, scan, shareReplay } from 'rxjs/operators';

import {
  CUSTOMER_CREDIT,
  PUT,
  request,
  useObservable,
  useObservableValue,
  useStaticSubscription,
  useUserContext,
  wsScanToMap,
} from '@talos/kyoko';
import { useCustomersByName } from 'hooks/useCustomer';
import type { Observable } from 'rxjs';
import { CustomerCreditLEGACY } from './CustomerCredit';

/**
 * @deprecated
 */
export interface CustomerCreditContextProps {
  customerCreditByMarketAccount: Map<string, CustomerCreditLEGACY> | undefined;
  customerCreditByMarketAccountObs: Observable<Map<string, CustomerCreditLEGACY>>;
  customerCreditByCounterpartyAndMarketAccount: Map<string, Map<string, CustomerCreditLEGACY>> | undefined;
  customerCreditList: CustomerCreditLEGACY[] | undefined;
  // REST Api uses Qty field instead of ExposureLimit
  createUpdateCreditLimit: (creditLimit: Partial<CustomerCreditLEGACY> & { Qty: string }) => Promise<any>;
}

const CustomerCreditContext = createContext<CustomerCreditContextProps | null>(null);
CustomerCreditContext.displayName = 'CustomerCreditContext';

/**
 * @deprecated Use useCustomerCredit instead
 */
export function useCustomerCreditLEGACY() {
  const context = useContext(CustomerCreditContext);
  if (context == null) {
    throw new Error('Missing CustomerCreditContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/**
 * @deprecated Use CustomerCreditProvider instead
 */
export const CustomerCreditProviderLEGACY = memo(function CustomerCreditProvider(props: PropsWithChildren<unknown>) {
  const { data: customerCreditSub } = useStaticSubscription({
    name: CUSTOMER_CREDIT,
    tag: 'CustomerCreditProvider',
  });

  const customerCreditByMarketAccountObs: Observable<Map<string, CustomerCreditLEGACY>> = useObservable(
    () =>
      customerCreditSub.pipe(
        wsScanToMap({ getUniqueKey: d => d.MarketAccount, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customerCreditSub]
  );

  const customersByName = useCustomersByName();

  const customerCreditListObs = useObservable(
    () =>
      customerCreditByMarketAccountObs.pipe(
        map(map =>
          [...map.values()]
            ?.map(
              val =>
                new CustomerCreditLEGACY({
                  ...val,
                  RowID: getCustomerCreditRowID(val),
                })
            )
            ?.sort((a, b) => {
              // Prioritize Customer Display Name over Counterparty Name
              const cNameA = customersByName?.get(a.Counterparty)?.DisplayName || a.Counterparty || '';
              const cNameB = customersByName?.get(b.Counterparty)?.DisplayName || b.Counterparty || '';
              return cNameA.localeCompare(cNameB);
            })
        )
      ),
    [customerCreditByMarketAccountObs, customersByName]
  );

  const customerCreditByMarketAccount = useObservableValue(
    () => customerCreditByMarketAccountObs,
    [customerCreditByMarketAccountObs]
  );

  const customerCreditByCounterpartyAndMarketAccountObs = useObservable(
    () =>
      customerCreditByMarketAccountObs.pipe(
        scan((memo, customerCredits) => {
          return [...customerCredits.values()].reduce((acc, customerCredit) => {
            if (!acc.has(customerCredit.Counterparty)) {
              acc.set(customerCredit.Counterparty, new Map());
            }
            acc.get(customerCredit.Counterparty)!.set(customerCredit.MarketAccount, customerCredit);
            return acc;
          }, memo);
        }, new Map<string, Map<string, CustomerCreditLEGACY>>())
      ),
    [customerCreditByMarketAccountObs]
  );

  const customerCreditList = useObservableValue(() => customerCreditListObs, [customerCreditListObs]);
  const customerCreditByCounterpartyAndMarketAccount = useObservableValue(
    () => customerCreditByCounterpartyAndMarketAccountObs,
    [customerCreditByCounterpartyAndMarketAccountObs]
  );
  const { orgApiEndpoint } = useUserContext();
  const endpoint = `${orgApiEndpoint}/customer-credit/limits`;
  const createUpdateCreditLimit = useCallback(
    (creditLimit: Partial<CustomerCreditLEGACY>) =>
      request(PUT, endpoint, creditLimit).then(response => {
        // Backend's REST model is not the same as WS model. So we add in something we know here, being that
        // we should have Exposure equal to the Qty on the response
        if (response && response.data && response.data[0]) {
          response.data[0].ExposureLimit = response.data[0].Qty;
        }
        return response;
      }),
    [endpoint]
  );

  const value = useMemo(
    () => ({
      customerCreditByMarketAccount,
      customerCreditByMarketAccountObs,
      customerCreditByCounterpartyAndMarketAccount,
      customerCreditList,
      createUpdateCreditLimit,
    }),
    [
      customerCreditByMarketAccount,
      customerCreditByMarketAccountObs,
      customerCreditByCounterpartyAndMarketAccount,
      customerCreditList,
      createUpdateCreditLimit,
    ]
  );

  return <CustomerCreditContext.Provider value={value}>{props.children}</CustomerCreditContext.Provider>;
});

/**
 * @deprecated
 */
export const getCustomerCreditRowID = (customerCredit: CustomerCreditLEGACY) =>
  `${customerCredit.Counterparty}-${customerCredit.MarketAccount}`;

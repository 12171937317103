import {
  CONNECTION_TYPE_UI,
  EMPTY_ARRAY,
  Flex,
  FormGroup,
  NotificationVariants,
  SearchSelect,
  replaceTokens,
  runValidation,
  useDynamicCallback,
  useGlobalToasts,
  useUserContext,
  type CredentialTemplate,
  type Market,
  type MarketConfig,
  type MarketCredential,
  type ReplaceTokens,
} from '@talos/kyoko';
import { kebabCase, omit } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { object, string } from 'yup';
import { SelectMarketCredential } from './SelectMarketCredential';
import { CredentialsForm } from './components/CredentialsForm';
import {
  OMIT_FORM_KEYS,
  type AddCredentialFormArg,
  type AddEditCredentialForm,
  type ICredentialDrawerTab,
} from './types';
import { EMPTY_FORM, getCredentialTemplateLabel, getReplaceTokens, validateCheckboxChecked } from './utils';

interface AddCredentialGeneralTabProps extends UseAddCredentialTabProps {
  selectedMarketConfig: MarketConfig | undefined;
  form: AddEditCredentialForm;
  errors: AddEditCredentialForm;
  updateForm: (arg: AddCredentialFormArg) => void;
  resetForm: () => void;
  credentialTemplate: CredentialTemplate | undefined;
  setCredentialTemplate: React.Dispatch<React.SetStateAction<CredentialTemplate | undefined>>;
  tokensToReplace: ReplaceTokens;
}

export function AddCredentialGeneralTab({
  marketConfigs,
  selectedMarket,
  externalIP,
  onMarketSelected,

  selectedMarketConfig,
  form,
  errors,
  updateForm,
  resetForm,
  credentialTemplate,
  setCredentialTemplate,
  tokensToReplace,
}: AddCredentialGeneralTabProps) {
  const availableCredentials = useMemo(() => selectedMarketConfig?.credentials || [], [selectedMarketConfig]);

  const handleCredentialTemplateChange = useCallback(
    (newCredentialTemplate: CredentialTemplate | undefined) => {
      setCredentialTemplate(newCredentialTemplate);
    },
    [setCredentialTemplate]
  );

  useEffect(() => {
    if (availableCredentials.length > 0) {
      setCredentialTemplate(availableCredentials[0]);
    }
  }, [availableCredentials, setCredentialTemplate]);

  useEffect(() => {
    resetForm();
    let defaultLabel = selectedMarket?.DisplayName ?? '';
    if (credentialTemplate != null && CONNECTION_TYPE_UI[credentialTemplate.type]) {
      defaultLabel = `${defaultLabel} (${CONNECTION_TYPE_UI[credentialTemplate.type]})`;
    }
    updateForm({ key: 'label', value: defaultLabel });
  }, [selectedMarket, credentialTemplate, resetForm, updateForm]);

  const handleSelected = useCallback(
    (market: Market | undefined) => {
      onMarketSelected(market);
    },
    [onMarketSelected]
  );

  return (
    <Flex flexDirection="column" w="100%">
      <FormGroup label="Market" flex="1">
        <SelectMarketCredential
          selectedMarket={selectedMarket}
          marketConfigs={marketConfigs ?? EMPTY_ARRAY}
          onSelected={handleSelected}
        />
      </FormGroup>
      {selectedMarket != null && (
        <>
          <FormGroup label="Type" flex="1">
            <SearchSelect
              options={availableCredentials}
              getLabel={getCredentialTemplateLabel}
              onChange={handleCredentialTemplateChange}
              selection={credentialTemplate}
              disabled={availableCredentials.length <= 1}
              initialSortByLabel={false}
              showDropdownSearch={false}
            />
          </FormGroup>
          <CredentialsForm
            key={`${selectedMarket.Name}-${credentialTemplate?.type}`}
            credentialTemplate={credentialTemplate}
            externalIP={externalIP}
            updateForm={updateForm}
            form={form}
            errors={errors}
            tokensToReplace={tokensToReplace}
          />
        </>
      )}
    </Flex>
  );
}

interface UseAddCredentialTabProps {
  marketCredentials: MarketCredential[];
  marketConfigs: MarketConfig[];
  selectedMarket: Market | undefined;
  externalIP: string;
  onMarketSelected: (market: Market | undefined) => void;
}

export function useAddCredentialTab(props: UseAddCredentialTabProps): ICredentialDrawerTab {
  const { add: addToast } = useGlobalToasts();
  const [form, setForm] = useState<AddEditCredentialForm>(EMPTY_FORM);
  const [errors, setErrors] = useState<AddEditCredentialForm>({});
  const { createMarketCredential } = useUserContext();
  const { marketCredentials, marketConfigs, selectedMarket, externalIP } = props;
  const [credentialTemplate, setCredentialTemplate] = useState<CredentialTemplate | undefined>(undefined);
  const isDirty = useMemo((): boolean => {
    return selectedMarket != null && Object.values(omit(form, OMIT_FORM_KEYS)).some(value => value !== '');
  }, [selectedMarket, form]);
  const tokensToReplace: ReplaceTokens = getReplaceTokens(externalIP);

  const isDisclaimerRequired = useMemo(() => {
    return selectedMarket?.Name === 'coinbase_intl';
  }, [selectedMarket]);

  const selectedMarketConfig = useMemo(
    () => marketConfigs.find(mktCfg => mktCfg.name === selectedMarket?.Name),
    [marketConfigs, selectedMarket]
  );

  const validate = useCallback(() => {
    const values = omit(form, OMIT_FORM_KEYS);
    if (!credentialTemplate) {
      return {
        valid: false,
        values,
      };
    }
    values['name'] = kebabCase(values['label'] as string);

    const validate = object().shape(validationSchema(credentialTemplate, tokensToReplace));
    const result: AddEditCredentialForm = runValidation(validate, values);
    if (marketCredentials.find(m => m.Label === form?.label && m.Market === selectedMarket?.Name)) {
      result['label'] = 'Name is already in use';
    }
    if (form.isDisclaimerRequired && !form.isDisclaimerChecked) {
      result['isDisclaimerRequired'] = true;
    }

    setErrors(result);
    return {
      valid: Object.keys(result).length === 0,
      values,
    };
  }, [form, marketCredentials, credentialTemplate, selectedMarket?.Name, tokensToReplace]);

  const handleSaveChanges = useDynamicCallback(async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      const validation = validate();
      if (!selectedMarketConfig || !validation.valid) {
        reject();
        return;
      }
      const valuesWithExplicitConnectionType = { ...validation.values, connectionType: credentialTemplate!.type };
      createMarketCredential(selectedMarketConfig.name, valuesWithExplicitConnectionType)
        .then(() => {
          addToast({
            text: `Credential ${form.label} added.`,
            variant: NotificationVariants.Positive,
          });
          resolve();
        })
        .catch(e => {
          addToast({
            text: (e as Error).message,
            variant: NotificationVariants.Negative,
          });
          reject();
        });
    });
  });

  const updateForm = useDynamicCallback(({ key, value }: AddCredentialFormArg) => {
    setForm(prev => ({ ...prev, [key]: value, isDisclaimerRequired }));
    setErrors(prev => {
      delete prev[key];
      return prev;
    });
  });

  const resetForm = useCallback(() => {
    setForm(EMPTY_FORM);
    setErrors({});
  }, []);

  return {
    name: 'General',
    component: (
      <AddCredentialGeneralTab
        {...props}
        selectedMarketConfig={selectedMarketConfig}
        form={form}
        errors={errors}
        updateForm={updateForm}
        resetForm={resetForm}
        credentialTemplate={credentialTemplate}
        setCredentialTemplate={setCredentialTemplate}
        tokensToReplace={tokensToReplace}
      />
    ),
    isDirty,
    viewable: true,
    save: handleSaveChanges,
  };
}

const validationSchema = (config: CredentialTemplate, tokens: ReplaceTokens) => {
  const schema = {
    label: string()
      .required(`Name is required`)
      .test('Does not contain slashes', 'Name cannot contain slashes', label => (label ? !label.includes('/') : false)),
  };
  for (const field of config?.fields ?? []) {
    if (field.required) {
      const fieldLabel = replaceTokens(field.label, tokens);
      if (field.type === 'checkbox') {
        schema[field.name] = string()
          .required(`This field is required`)
          .test('isTrue', `This field is required`, validateCheckboxChecked);
      } else {
        schema[field.name] = string().required(`${fieldLabel} is required`);
      }
    }
  }
  return schema;
};

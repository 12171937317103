import {
  getAgGridColId,
  isColumn,
  isColumnGroup,
  type Column,
  type ColumnGroup,
  type ColumnOrColumnGroup,
} from '@talos/kyoko';
import { useMemo } from 'react';

/**
 * Group-enabled version of useDefaultColumns - Groups (only in defaultVisibleColumnsAndGroups) will be expanded into their children and
 * appended to the end of the list of columns.
 * IMPORTANT: Due to the way groups are returned at the end, it's currently only useful with PortfolioRiskBlotter
 * TODO: Make this more generic
 *
 * @see useDefaultColumns
 * @param columnDefinitions The visible definitions of the columns for this blotter
 * @returns An array of column definitions to pass to `useBlotterTable`, keeping the default order and visibility of columns
 */
export function useDefaultColumnsWithAppendedGroupings(
  defaultVisibleColumnsAndGroups: (string | Column | ColumnGroup)[],
  columnDefinitions: Map<string, Column>
): ColumnOrColumnGroup[] {
  const columns = useMemo(() => {
    const groups = defaultVisibleColumnsAndGroups.filter(col => typeof col === 'object' && isColumnGroup(col));
    const columns = defaultVisibleColumnsAndGroups.filter(
      col => typeof col === 'string' || (typeof col === 'object' && isColumn(col))
    );
    const visibleColumnObjects = columns.filter(col => typeof col === 'object' && isColumn(col));
    const visibleColumnIds = columns.map(col => (typeof col === 'string' ? col : getAgGridColId(col)));

    const allColDefs = new Map<string, Column | ColumnGroup>();

    // this logic ensures the original mapping defines the overall order of the column in the column selector,
    // while the visibleColumnIds defines the visibility within the grid
    for (const [key, value] of columnDefinitions.entries()) {
      allColDefs.set(key, {
        ...value,
        hide: visibleColumnIds.includes(key) ? false : true,
      });
    }

    return [...allColDefs.values(), ...visibleColumnObjects, ...groups];
  }, [columnDefinitions, defaultVisibleColumnsAndGroups]);

  return columns;
}

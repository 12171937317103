import { animated } from '@react-spring/web';
import { forwardRef } from 'react';
import { Z_INDEX } from '../../styles';
import { EMPTY_OBJECT } from '../../utils';
import { useDropdownContext } from '../Form/Dropdown/DropdownContext';
import { useModalContext } from '../Modal/Modal';
import { Content, Wrapper } from './styles';
import type { PopoverContentProps } from './types';

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  (
    {
      style = EMPTY_OBJECT,
      placement,
      variant,
      transitionStyle = EMPTY_OBJECT,
      isSmall = false,
      children,
      overflow,
      overflowY,
      zIndex,
      noPaddingAndBorder = false,
      ...props
    },
    ref
  ) => {
    const modalContext = useModalContext();
    const dropdownContext = useDropdownContext();

    // Popovers are most often renders absolutely due to available space and position reasons. They can be rendered absolutely but within a modal or a dropdown, which themselves
    // are usually rendered absolutely. In these cases, we need to modify the base zIndex we render the tooltip at to ensure that the tooltip is on top of the context we're being rendered within.
    // The dropdown has precedence over modals (unless zIndexOverride is set)
    const baseZIndex = zIndex
      ? zIndex
      : dropdownContext
      ? Z_INDEX.dropdown + 1 // render on _top of_ the dropdown or modal in these cases (+1)
      : modalContext
      ? Z_INDEX.modal + 1
      : Z_INDEX.popover;

    return (
      <div style={{ ...style, zIndex: baseZIndex }} ref={ref}>
        <Wrapper placement={placement} isSmall={isSmall} {...props}>
          <animated.div style={transitionStyle}>
            <Content
              variant={variant}
              role="tooltip"
              placement={placement}
              overflow={overflow}
              overflowY={overflowY}
              isSmall={isSmall}
              aria-busy={props['aria-busy']}
              noPaddingAndBorder={noPaddingAndBorder}
            >
              {children}
            </Content>
          </animated.div>
        </Wrapper>
      </div>
    );
  }
);

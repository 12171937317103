import {
  MARKET_ACCOUNT_PERMISSIONS_FILTER,
  NotificationVariants,
  UpdateActionEnum,
  request,
  useEndpointsContext,
  useGlobalToasts,
  useMarketAccountsContext,
  useObservable,
  useObservableValue,
  useSubscription,
  type IMarketAccountApiPermissionFilter,
  type MarketAccount,
  type MarketAccountApiPermissionFilter,
  type MarketTypeEnum,
  type SubscriptionResponse,
} from '@talos/kyoko';
import { createContext, useCallback, useContext, useMemo, useRef, type PropsWithChildren } from 'react';
import { Subject, merge, scan } from 'rxjs';
import { scanToMap } from '../components/OMS/testutils';

export const MarketAccountPermissionFiltersContext = createContext<
  MarketAccountPermissionFiltersContextProps | undefined
>(undefined);
MarketAccountPermissionFiltersContext.displayName = 'MarketAccountPermissionFiltersContext';

export type MarketAccountPermissionFiltersContextProps = {
  marketAccountFilterIDsBySubAccountName: Map<string, Set<string>> | undefined;
  marketAccountPermissionFiltersByFilterID: Map<string, MarketAccountApiPermissionFilter> | undefined;
  updateMarketAccountPermissionFilters: (updatedPermissions: MarketAccountApiPermissionFilter[]) => void;
};

export type MarketAccountSubAccountPermissionRow = {
  MarketAccount: Pick<MarketAccount, 'Name'> & { MarketType: MarketTypeEnum | '' };
  FilterPermission: MarketAccountApiPermissionFilter;
};

export function useMarketAccountPermissionFilters() {
  const context = useContext(MarketAccountPermissionFiltersContext);
  if (context === undefined) {
    throw new Error(
      'Missing MarketAccountPermissionFiltersContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

const MARKET_ACCOUNT_PERMISSION_REQUEST = {
  name: MARKET_ACCOUNT_PERMISSIONS_FILTER,
  tag: 'MarketAccountPermissionFiltersProvider',
  // These records are small so we can get more of them at once. Default on backend is 500.
  limit: 5000,
};

export const MarketAccountPermissionFiltersProvider = function MarketAccountPermissionFiltersProvider({
  children,
}: PropsWithChildren) {
  const { data: marketAccountPermissionsFilterRawObs } = useSubscription<IMarketAccountApiPermissionFilter>(
    MARKET_ACCOUNT_PERMISSION_REQUEST
  );

  const marketAccountPermissionsFilterUpdatesSubject = useRef(
    new Subject<SubscriptionResponse<MarketAccountApiPermissionFilter, string>>()
  );
  const marketAccountPermissionsFilterUpdatesObs = useObservable<
    SubscriptionResponse<MarketAccountApiPermissionFilter, string>
  >(() => marketAccountPermissionsFilterUpdatesSubject.current.asObservable(), []);

  const { orgApiEndpoint } = useEndpointsContext();
  const { marketAccountDisplayNameByName } = useMarketAccountsContext();
  const { add: addToast } = useGlobalToasts();

  const updateMarketAccountPermissionFilters = useCallback(
    async (updatedPermissions: MarketAccountApiPermissionFilter[]) => {
      if (orgApiEndpoint) {
        // The api is weird, updates are not really updates, rather they are additions with removals.
        // So for all updates that we do, we're not actually editing the filter, an update to filterPerm1 is:
        // DELETE filterPerm1
        // POST filterPerm1 (with your changes) and receive back filterPerm2
        const deletions = updatedPermissions
          .filter(uP => uP.UpdateAction === UpdateActionEnum.Remove || uP.UpdateAction === UpdateActionEnum.Update)
          .map(uP => ({ ...uP, UpdateAction: UpdateActionEnum.Remove }));
        const updates = updatedPermissions.filter(uP => uP.UpdateAction !== UpdateActionEnum.Remove);

        // Send deletions
        const attemptedDeletions = await Promise.all(
          deletions.map(async apiFilter =>
            request('DELETE', `${orgApiEndpoint}/permission-filters/marketaccounts/${apiFilter.FilterID}`)
              .then((res: '') => ({ status: 'fulfilled' as const, data: apiFilter }))
              .catch((r: undefined) => ({ status: 'rejected' as const, data: apiFilter }))
          )
        );

        const successfulDeletions = attemptedDeletions
          .filter(settledRes => settledRes.status === 'fulfilled')
          .map(settledRes => settledRes.data);
        const failedDeletions = attemptedDeletions
          .filter(settledRes => settledRes.status === 'rejected')
          .map(settledRes => settledRes.data);

        // Send updates
        const attemptedUpdates = await Promise.all(
          updates.map(apiFilter =>
            request('POST', `${orgApiEndpoint}/permission-filters/marketaccounts`, {
              Action: apiFilter.Action,
              Filter: apiFilter.Filter,
              Subject: apiFilter.Subject,
            })
              .then((res: { data: MarketAccountApiPermissionFilter[] }) => ({
                status: 'fulfilled' as const,
                data: res.data[0],
              }))
              .catch((r: undefined) => ({ status: 'rejected' as const, data: apiFilter }))
          )
        );

        const successfulUpdates = attemptedUpdates
          .filter(settledRes => settledRes.status === 'fulfilled')
          .map(settledRes => settledRes.data);
        const failedUpdates = attemptedUpdates
          .filter(settledRes => settledRes.status === 'rejected')
          .map(settledRes => settledRes.data);

        for (const failedDeletion of failedDeletions) {
          const marketAccountName =
            marketAccountDisplayNameByName.get(failedDeletion.Filter.MarketAccount) ||
            failedDeletion.Filter.MarketAccount;
          addToast({
            text: `Failed to delete permission for Subaccount: "${failedDeletion.Subject.SubAccount}" to Market Account: "${marketAccountName}"`,
            variant: NotificationVariants.Negative,
          });
        }

        for (const failedUpdate of failedUpdates) {
          const marketAccountName =
            marketAccountDisplayNameByName.get(failedUpdate.Filter.MarketAccount) || failedUpdate.Filter.MarketAccount;
          addToast({
            text: `Failed to update permission for Subaccount: "${failedUpdate.Subject.SubAccount}" to Market Account: "${marketAccountName}"`,
            variant: NotificationVariants.Negative,
          });
        }

        const flatUpdates = successfulDeletions.concat(successfulUpdates);

        marketAccountPermissionsFilterUpdatesSubject.current.next({
          type: '',
          tag: 'REST_RESPONSE',
          ts: '',
          data: flatUpdates,
        });
      }
    },
    [orgApiEndpoint, marketAccountDisplayNameByName, addToast]
  );

  const marketAccountPermissionFiltersObs = useObservableValue(
    () =>
      merge(marketAccountPermissionsFilterRawObs, marketAccountPermissionsFilterUpdatesObs).pipe(
        scan(
          ({ marketAccountPermissionFiltersByFilterID, marketAccountFilterIDsBySubAccountName }, json) => {
            if (json.initial) {
              marketAccountPermissionFiltersByFilterID.clear();
            }

            for (const data of json.data) {
              // per filterID
              const key = data.FilterID;
              if (key) {
                if (data.UpdateAction === UpdateActionEnum.Remove) {
                  marketAccountPermissionFiltersByFilterID.delete(key);
                } else {
                  marketAccountPermissionFiltersByFilterID.set(key, data);
                }
              }

              const subAccount = data.Subject.SubAccount;
              const marketAccount = data.Filter.MarketAccount;
              if (subAccount && marketAccount) {
                const filterIDs = marketAccountFilterIDsBySubAccountName.get(subAccount) ?? new Set<string>();
                data.UpdateAction === UpdateActionEnum.Remove
                  ? filterIDs.delete(data.FilterID)
                  : filterIDs.add(data.FilterID);
                // Note how we wrap our set with new Set below -- we ensure that we get a new reference for react change detection purposes
                marketAccountFilterIDsBySubAccountName.set(subAccount, new Set(filterIDs));
              }
            }

            return {
              // This is stable, since we're only extracting data from it via dynamic sets.
              marketAccountPermissionFiltersByFilterID: marketAccountPermissionFiltersByFilterID,
              marketAccountFilterIDsBySubAccountName: new Map(marketAccountFilterIDsBySubAccountName),
            };
          },
          {
            marketAccountPermissionFiltersByFilterID: new Map<string, MarketAccountApiPermissionFilter>(),
            marketAccountFilterIDsBySubAccountName: new Map<string, Set<string>>(),
          }
        )
      ),
    [marketAccountPermissionsFilterRawObs, marketAccountPermissionsFilterUpdatesObs],
    {
      marketAccountPermissionFiltersByFilterID: new Map<string, MarketAccountApiPermissionFilter>(),
      marketAccountFilterIDsBySubAccountName: new Map<string, Set<string>>(),
    }
  );

  const { marketAccountFilterIDsBySubAccountName, marketAccountPermissionFiltersByFilterID } =
    marketAccountPermissionFiltersObs;

  const value = useMemo(
    () => ({
      marketAccountPermissionFiltersByFilterID,
      marketAccountFilterIDsBySubAccountName,
      updateMarketAccountPermissionFilters,
    }),
    [
      marketAccountPermissionFiltersByFilterID,
      marketAccountFilterIDsBySubAccountName,
      updateMarketAccountPermissionFilters,
    ]
  );

  return (
    <MarketAccountPermissionFiltersContext.Provider value={value}>
      {children}
    </MarketAccountPermissionFiltersContext.Provider>
  );
};

/** Test utility function to help build one of the data sets exposed by this provider */
export function testBuildMarketAccountPermissionFiltersByFilterID(
  permissions: MarketAccountApiPermissionFilter[]
): Map<string, MarketAccountApiPermissionFilter> {
  return scanToMap(permissions, p => p.FilterID);
}

/** Test utility function to help build one of the data sets exposed by this provider */
export function testBuildMarketAccountFilterIDsBySubAccountName(
  permissions: MarketAccountApiPermissionFilter[]
): Map<string, Set<string>> {
  const map = new Map<string, Set<string>>();
  permissions.forEach(p => {
    const set = map.get(p.Subject.SubAccount) ?? new Set();
    set.add(p.FilterID);
    map.set(p.Subject.SubAccount, set);
  });

  return map;
}

/** Test utility function to help build all the data sets and other functions exposed by this provider */
export function testBuildMarketAccountPermissionFiltersContextProps(
  permissions: MarketAccountApiPermissionFilter[]
): MarketAccountPermissionFiltersContextProps {
  return {
    marketAccountFilterIDsBySubAccountName: testBuildMarketAccountFilterIDsBySubAccountName(permissions),
    marketAccountPermissionFiltersByFilterID: testBuildMarketAccountPermissionFiltersByFilterID(permissions),
    updateMarketAccountPermissionFilters: () => {
      return;
    },
  };
}

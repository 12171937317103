export const ENV_COLORS = {
  prod: '',
  local: 'gray.080',
  staging: 'green.lighten',
  uat: 'blue.lighten',
  alpha: 'red.lighten',
  sandbox: 'yellow.lighten',
  develop: '#804AD8',
  release: '#FF9900',
  test: '#f205b7',
};

export const Logo = ({ fill }: React.SVGProps<SVGPathElement>) => (
  <>
    <polygon
      id="Fill-5"
      fill={fill}
      fillOpacity="0.45"
      points="96.76 192.42 126.64 192.42 126.64 162.55 96.76 162.55"
    />
    <polyline
      id="Fill-6"
      fillOpacity="0.65"
      fill={fill}
      points="153.15 121.26 96.76 121.26 96.76 151.14 163.4 151.14 163.4 121.27 153.15 121.27 153.15 121.26"
    />
    <polygon id="Fill-7" fill={fill} points="96.76 109.87 189.32 109.87 189.32 80 96.76 80" />
    <polygon id="Fill-8" fillOpacity="0.65" fill={fill} points="60 151.14 89.87 151.14 89.87 121.26 60 121.26" />
  </>
);

import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v1 as uuid } from 'uuid';
import { useConstant } from '../../hooks/useConstant';
import { useAccordionGroupContext } from './AccordionGroup/AccordionGroupContext';

export type AccordionInputs = {
  id?: string;
  initialOpen?: boolean;
  headerClickable?: boolean;
  onOpened?: () => void;
  onClosed?: () => void;
  ghost?: boolean;
  alignChevron?: 'left' | 'right';
};

export type AccordionOutputs = {
  /** Whether or not the state of the accordion is open or false */
  isOpen: boolean;
  /**
   * Whether or not the accordion is open _at all_ visually.
   *
   * This could be true while isOpen above is false if we are in the process of animating from opened -> closed. This should be used
   * if we are conditionally rendering something while the accordion is open. In this case, if you were using .isOpen above,
   * immediately upon the accordion _starting to close_, all content would be removed and the accordion would jump as its height is lessened.
   */
  isOpenVisually: boolean;
  toggle: (e?: React.MouseEvent<HTMLElement>) => void;
  open: () => void;
  close: () => void;
  headerClickable: boolean;
  initialOpen: boolean;
  onOpened?: () => void;
  onClosed?: () => void;
  ghost: boolean;
  alignChevron?: 'left' | 'right';
};

export const useAccordion = ({
  id: customID,
  initialOpen = false,
  headerClickable = true,
  onOpened: inputOnOpened,
  onClosed: inputOnClosed,
  ghost = false,
  alignChevron = 'right',
}: AccordionInputs = {}): AccordionOutputs => {
  const id = useConstant(customID ?? uuid());
  const { accordionsMap, openAccordion, closeAccordion, toggleAccordion, registerAccordion, unregisterAccordion } =
    useAccordionGroupContext();

  const initialOpenRef = useRef(initialOpen);
  useEffect(() => {
    registerAccordion(id, initialOpenRef.current);
    return () => unregisterAccordion(id);
  }, [registerAccordion, unregisterAccordion, id]);

  // Listen to changes in the AccordionGroup map and pluck out any changes in our own open state
  const isOpen = useMemo(() => {
    const newOpen = accordionsMap.get(id);
    return newOpen == null ? initialOpen : newOpen;
  }, [accordionsMap, initialOpen, id]);

  const [isOpenedAtRest, setIsOpenedAtRest] = useState(initialOpen);
  const onOpened = useCallback(() => {
    setIsOpenedAtRest(true);
    inputOnOpened?.();
  }, [inputOnOpened]);

  const onClosed = useCallback(() => {
    setIsOpenedAtRest(false);
    inputOnClosed?.();
  }, [inputOnClosed]);

  /**
   *  onOpened and onClose are called _at "rest"_, as in when animations are done.
   *
   * The base .isOpen property just reflects the programmatic state / the state we are animating to.
   * This property reflects if we are visually open to the user. As in, we take the animations into consideration
   */
  const isOpenVisually = useMemo(() => {
    return isOpen || isOpenedAtRest;
  }, [isOpen, isOpenedAtRest]);

  const value = useMemo(() => {
    return {
      isOpen,
      isOpenVisually,
      toggle: () => toggleAccordion(id),
      open: () => openAccordion(id),
      close: () => closeAccordion(id),
      headerClickable,
      ghost,
      initialOpen,
      onOpened,
      onClosed,
      alignChevron,
    };
  }, [
    id,
    isOpen,
    isOpenVisually,
    openAccordion,
    closeAccordion,
    toggleAccordion,
    headerClickable,
    ghost,
    alignChevron,
    initialOpen,
    onOpened,
    onClosed,
  ]);

  return value;
};

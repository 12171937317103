import { Breadcrumb, BreadcrumbItem } from '@talos/kyoko';
import { mapDisplayPartToPath, mapPathToDisplayParts } from 'containers/Routes/utils';
import { useTabLabelResolver } from 'hooks/useTabLabelResolver';
import { compact } from 'lodash-es';
import { useAppLayoutConfig } from 'providers/AppLayoutConfig/AppLayoutConfigContext';
import { useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

const breadcrumbRoutes = [
  'trading',
  'dealer',
  'transfers',
  'portfolio',
  'portfolio-engineering',
  'analytics',
  'monitoring',
  'settings',
  'admin',
] as const;

export const AppBreadcrumb = () => {
  const { enableFlexibleLayout } = useAppLayoutConfig();

  const location = useLocation();

  const { resolveTabLabel } = useTabLabelResolver();

  const breadcrumbItems = useMemo(() => {
    // Temporary fix to show Trading breadcrumb for Flexible Layout on /trading routes
    const parts =
      enableFlexibleLayout && location.pathname.startsWith('/trading')
        ? ['Trading']
        : compact(mapPathToDisplayParts(resolveTabLabel, location.pathname));

    return parts.map((part, index) => {
      const to = mapDisplayPartToPath(parts.slice(0, index + 1), location.pathname);

      const capitalize = !part.startsWith('#');

      return (
        <BreadcrumbItem
          key={index}
          to={index < parts.length - 1 ? `/${to}` : '#'}
          onClick={e => !to && e.preventDefault()}
          style={{ textTransform: capitalize ? 'capitalize' : 'none' }}
        >
          {part}
        </BreadcrumbItem>
      );
    });
  }, [location.pathname, resolveTabLabel, enableFlexibleLayout]);

  return (
    <Routes>
      {breadcrumbRoutes.map(path => (
        <Route key={path} path={`/${path}/*`} element={<Breadcrumb>{breadcrumbItems}</Breadcrumb>} />
      ))}
    </Routes>
  );
};

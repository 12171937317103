import { Button, type ColumnDef, FormControlSizes, IconName, type Leaves } from '@talos/kyoko';
import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import { get } from 'lodash-es';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import type { TransferForm } from '../../containers/Transfers/types';
import type { useTransfers } from '../../providers';

const messages = defineMessages({
  transfer: {
    defaultMessage: 'Transfer',
    id: 'utils.columns.transfer',
  },
  withdraw: {
    defaultMessage: 'Withdraw',
    id: 'utils.columns.withdraw',
  },
  deposit: {
    defaultMessage: 'Deposit',
    id: 'utils.columns.deposit',
  },
});

export interface UseTransferButtonColumnParams<T> {
  /** Point to a currency */
  currencyField: Leaves<T>;
  /** Point to the market name */
  marketField: Leaves<T>;
  /** Point to the market account name _or_ market account id. The column will bridge between the two */
  marketAccountField: Leaves<T>;
  /** The type of transfer you want to send when this button is clicked. Pass null if you don't know at this stage. */
  type: 'withdraw' | 'deposit' | null;
  /** Fancy function that you should grab from the useTransfers hook */
  getPrimeableTransfer: ReturnType<typeof useTransfers>['getPrimeableTransfer'];
  /** Called when the user clicks a button. Is called with the transfer form to prime for the user, derived from the row node */
  onClick: (primedTransfer: TransferForm) => void;
  /** The column's unique ID */
  columnID: string;
  /** Whether or not to try to fall back to a linked account. Defaults to true. */
  tryWithLinkedAccount?: boolean;
}

export const useTransferButtonColumn = <T,>({
  currencyField,
  marketField,
  marketAccountField,
  type,
  getPrimeableTransfer,
  onClick,
  columnID,
  tryWithLinkedAccount = true,
}: UseTransferButtonColumnParams<T>) => {
  return useMemo(
    () =>
      ({
        type: 'custom',
        id: columnID,
        params: {
          headerValueGetter: ({ context }) =>
            type == null
              ? context.current.intl.formatMessage(messages.transfer)
              : type === 'withdraw'
              ? context.current.intl.formatMessage(messages.withdraw)
              : context.current.intl.formatMessage(messages.deposit),
          menuTabs: [],
          width: 100,
          cellRenderer: ({ node, ...params }: ICellRendererParams<unknown>) => {
            if (node.group || node.rowPinned != null) {
              return <></>;
            }

            const text = type == null ? 'Transfer' : type === 'withdraw' ? 'Withdraw' : 'Deposit';
            const icon =
              text === 'Transfer' ? IconName.ArrowLeftRight : text === 'Withdraw' ? IconName.Upload : IconName.Download;

            const marketAccountIDOrName: number | string | undefined = get(params.data, marketAccountField);
            // Normalize number | string into just number
            const marketAccountID =
              typeof marketAccountIDOrName === 'string'
                ? params.context.current.marketAccountsByName?.get(marketAccountIDOrName)?.MarketAccountID
                : marketAccountIDOrName;

            const options = {
              asset: get(params.data, currencyField),
              provider: get(params.data, marketField),
              source: type === 'withdraw' ? marketAccountID : undefined,
              destination: type === 'deposit' ? marketAccountID : undefined,
            };

            const primeableTransfer = getPrimeableTransfer?.(options, tryWithLinkedAccount);

            const disabled = !params.data || !primeableTransfer;

            return (
              <Button
                disabled={disabled}
                endIcon={icon}
                size={FormControlSizes.Small}
                onClick={() => primeableTransfer && onClick(primeableTransfer)}
              >
                {text}
              </Button>
            );
          },
        } satisfies ColDef,
      } satisfies ColumnDef<unknown>),
    [
      columnID,
      currencyField,
      getPrimeableTransfer,
      marketAccountField,
      marketField,
      onClick,
      tryWithLinkedAccount,
      type,
    ]
  );
};

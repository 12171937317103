import styled from 'styled-components';
import { Button } from '../../Button';
import { ButtonGroup } from '../../Button/ButtonGroup';
import { NotificationInternalToast, NotificationVariants } from '../../Notification';

export const QuoteButtons = styled(ButtonGroup)`
  position: relative;

  ${Button} {
    padding: ${({ theme }) => `${theme.spacingDefault}px 0px`};
    height: ${({ theme }) => theme.baseSize * 8}px;

    > span {
      flex-direction: column;
    }
  }
`;

export const QuotePrice = styled.div`
  display: inline-block;
  margin: ${({ theme }) => theme.spacingDefault}px 0;
`;

export const QuoteSide = styled.div`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  line-height: 1;
`;

export const QuoteAmount = styled.div`
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  line-height: 1;
`;

export const ErrorMessage = styled(NotificationInternalToast).attrs({
  variant: NotificationVariants.Negative,
})`
  margin-top: ${({ theme }) => theme.spacingMedium}px;
`;

export const QuoteSpread = styled.div`
  padding: ${({ theme }) => theme.spacingTiny}px;
  line-height: 1;
  background: ${({ theme }) => theme.backgroundContent};
  color: ${({ theme }) => theme.colorTextAttention};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  min-width: 25px;
  text-align: center;
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
`;

import { Post } from '@talos/kyoko';
import { useCallback } from 'react';
import type { CreateSupportTicketRequest, CreateSupportTicketResponse, SupportTicketForm } from './types';

export function useSupportTicketDialog() {
  const createTicket = useCallback(async (form: SupportTicketForm): Promise<CreateSupportTicketResponse> => {
    const payload: CreateSupportTicketRequest = {
      Summary: form.summary,
      Description: form.description,
      RequestType: form.requestType === 'General query or request' ? 'GeneralQuery' : 'OrderIssue',
      Priority: form.priority as CreateSupportTicketRequest['Priority'],
      Escalate: form.pagerDutyAlert,
    };
    return Post(import.meta.env.VITE_AVA_API_ENDPOINT, `/support/ticket`, null, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }, []);

  return {
    createTicket,
  };
}

import type { Leaves } from '@talos/kyoko';
import { getAgGridColId, type Column, type ColumnDef } from '@talos/kyoko';
import { compact } from 'lodash-es';
import { useMemo } from 'react';
import { useFeatureFlag } from '../../../hooks';
import { useDisplaySettings } from '../../../providers/DisplaySettingsProvider';
import type { Balance } from '../../../types';
import { useEquityMarginRatioLimits } from '../../Settings/TradingControls/useEquityMarginRatioLimits';
import { equityMarginRatioColumn } from './EquityMarginRatio';

type BalancesLeaves = Leaves<Balance>;

export function useBalancesColumnDefinitions(): Map<string, Column> {
  const { homeCurrency } = useDisplaySettings();
  const { showPositionsBlotterWarningColumn } = useFeatureFlag();
  const { isEquityMarginRatioLimitCheckEnabled } = useEquityMarginRatioLimits();

  return useMemo(() => {
    const arr = compact([
      showPositionsBlotterWarningColumn && {
        id: 'reconWarning',
        field: 'reconWarning',
        type: 'reconWarning',
        aggregate: true,
        sortable: true,
        exportable: false,
      },
      {
        type: 'text',
        field: 'marketAccountGroup',
        id: 'marketAccountGroup',
        title: 'Group',
        hide: true,
        sortable: true,
        params: { color: 'colorTextImportant' },
      },
      {
        type: 'account',
        field: 'marketAccountName',
        sortable: true,
        params: { color: 'colorTextImportant', prefixCustodianMarketAccounts: true },
      },
      {
        type: 'currency',
        field: 'Currency',
        id: 'Currency',
        title: 'Instrument',
        sortable: true,
        params: { colorful: true },
      },
      {
        type: 'market',
        field: 'Market',
        title: 'Market',
        hide: true,
        sortable: true,
      },
      {
        type: 'size',
        field: 'Amount',
        title: 'Quantity',
        params: { currencyField: 'Currency' satisfies BalancesLeaves, highlightNegative: true },
        sortable: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'Equivalent.Amount',
        title: `${homeCurrency} value`,
        params: { currencyField: 'Equivalent.Currency' satisfies BalancesLeaves, highlightNegative: true },
        sortable: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'AvailableAmount',
        title: 'Available Amount',
        params: { currencyField: 'Currency' satisfies BalancesLeaves, highlightNegative: true },
        sortable: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'Equivalent.AvailableAmount',
        title: `Available Amount (${homeCurrency})`,
        params: { currencyField: 'Equivalent.Currency' satisfies BalancesLeaves, highlightNegative: true },
        sortable: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'OutstandingBuy',
        title: 'Outstanding Buy',
        params: { currencyField: 'Currency' satisfies BalancesLeaves, highlightNegative: true },
        sortable: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'OutstandingSell',
        title: 'Outstanding Sell',
        params: { currencyField: 'Currency' satisfies BalancesLeaves, highlightNegative: true },
        sortable: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'Equivalent.OutstandingBuy',
        title: `Outstanding Buy (${homeCurrency})`,
        params: { currencyField: 'Equivalent.Currency' satisfies BalancesLeaves, highlightNegative: true },
        sortable: true,
        hide: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'Equivalent.OutstandingSell',
        title: `Outstanding Sell (${homeCurrency})`,
        params: { currencyField: 'Equivalent.Currency' satisfies BalancesLeaves, highlightNegative: true },
        sortable: true,
        hide: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'YieldingAmount',
        title: `Yielding Amount`,
        description: 'Amount currently earning yield, including assets locked in staking, in instrument terms.',
        params: { currencyField: 'Currency' satisfies BalancesLeaves, highlightNegative: true },
        sortable: true,
        hide: true,
        aggregate: true,
      },
      {
        type: 'size',
        field: 'Equivalent.YieldingAmount',
        title: `Yielding Amount (${homeCurrency})`,
        description: 'Amount currently earning yield, including assets locked in staking, in home currency terms.',
        params: { currencyField: 'Equivalent.Currency' satisfies BalancesLeaves, highlightNegative: true },
        sortable: true,
        hide: true,
        aggregate: true,
      },
      isEquityMarginRatioLimitCheckEnabled && equityMarginRatioColumn,
      {
        type: 'size',
        field: 'Equity',
        title: 'Equity',
        params: {
          highlightNegative: true,
          currencyField: 'Currency' satisfies BalancesLeaves,
        },
        aggregate: false,
        sortable: true,
        hide: true,
        description: 'Venue-published equity value, in instrument terms.',
      },
      {
        type: 'size',
        title: 'Initial Margin',
        field: 'InitialMargin',
        params: {
          highlightNegative: true,
          currencyField: 'Currency' satisfies BalancesLeaves,
        },
        aggregate: false,
        sortable: true,
        hide: true,
        description: 'Venue-published initial margin requirement for derivatives positions, in instrument terms.',
      },
      {
        type: 'size',
        title: 'Maintenance Margin',
        field: 'MaintenanceMargin',
        params: {
          highlightNegative: true,
          currencyField: 'Currency' satisfies BalancesLeaves,
        },
        aggregate: false,
        sortable: true,
        hide: true,
        description:
          'Venue-published minimum margin requirement to maintain derivatives positions, in instrument terms.',
      },
      {
        type: 'size',
        field: 'Equivalent.Equity',
        title: `Equity (${homeCurrency})`,
        params: {
          highlightNegative: true,
          currencyField: 'Equivalent.Currency' satisfies BalancesLeaves,
        },
        aggregate: false,
        sortable: true,
        hide: true,
        description: 'Venue-published equity value, in home currency.',
      },
      {
        type: 'size',
        field: 'Equivalent.InitialMargin',
        title: `Initial Margin (${homeCurrency})`,
        params: {
          highlightNegative: true,
          currencyField: 'Equivalent.Currency' satisfies BalancesLeaves,
        },
        aggregate: false,
        sortable: true,
        hide: true,
        description: 'Venue-published initial margin requirement for derivatives positions, in home currency.',
      },
      {
        type: 'size',
        title: `Maintenance Margin (${homeCurrency})`,
        field: 'Equivalent.MaintenanceMargin',
        params: {
          highlightNegative: true,
          currencyField: 'Equivalent.Currency' satisfies BalancesLeaves,
        },
        aggregate: false,
        sortable: true,
        hide: true,
        width: 180,
        description: 'Venue-published minimum margin requirement to maintain derivatives positions, in home currency.',
      },
      {
        type: 'text',
        title: 'Margin Type',
        field: 'MarginReportingType',
        width: 100,
        hide: true,
        description:
          'The margin classification reported by the venue, specifying if it applies at the position level (per currency) or the account level.',
      },
    ] satisfies (ColumnDef<Balance> | false)[]);

    return new Map<string, Column>(arr.map(c => [getAgGridColId(c), c]));
  }, [homeCurrency, showPositionsBlotterWarningColumn, isEquityMarginRatioLimitCheckEnabled]);
}

import type { WebsocketRequest } from '@talos/kyoko';
import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  FormControlSizes,
  IconName,
  MARKET_QUOTE,
  MixpanelEvent,
  columnToColumnState,
  createCSVFileName,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useDynamicCallback,
  useMarketQuoteColumns,
  useMixpanel,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type BlotterColumnState,
  type BlotterTableFilter,
  type Column,
  type MarketQuote,
} from '@talos/kyoko';
import { pick } from 'lodash-es';
import { useMemo } from 'react';

import type { RowDoubleClickedEvent } from 'ag-grid-community';
import { useMarketQuotesFilter, type MarketQuotesTableFilter } from './useMarketQuotesFilter';
import { useMarketQuotesMenu } from './useMarketQuotesMenu';

export interface FilteredMarketQuotesParams {
  blotterID: string;
  tabLabel?: string;
  defaultColumns: Column[];
  defaultFilter: MarketQuotesTableFilter;
  initialIsOpen?: boolean;

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab?: (filter: BlotterTableFilter, columns: BlotterColumnState[]) => void;
  onRowDoubleClicked?: (event: RowDoubleClickedEvent<MarketQuote>) => void;
}

export function MarketQuotesBlotterTable({
  blotterID,
  tabLabel,
  defaultColumns,
  defaultFilter,
  initialIsOpen,
  onCloneTab,
  onRowDoubleClicked,
}: FilteredMarketQuotesParams) {
  const mixpanel = useMixpanel();
  const defaultColumnDefinitions = useMarketQuoteColumns({ defaultColumns });

  const persistedBlotterTable = usePersistedBlotterTable<MarketQuote>(blotterID, {
    columns: defaultColumnDefinitions,
    filter: defaultFilter,
    sort: '-SubmitTime',
  });

  const filterResults = useMarketQuotesFilter({
    persistedBlotterTable,
  });
  const { clientSideFilter: clientLocalFilter, blotterTableFilterProps, filterBuilderProps } = filterResults;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });

  const marketQuotesMenu = useMarketQuotesMenu({
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterBuilderProps.properties,
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...marketQuotesMenu.columns],
    [marketQuotesMenu.columns, persistedBlotterTable.columns]
  );

  const blotterTable = useWsBlotterTable<WebsocketRequest, MarketQuote>({
    initialRequest: {
      tag: blotterID,
      name: MARKET_QUOTE,
    },
    rowID: 'QID',
    filter: onlyServerFilterKeys(filterResults.filter),
    clientLocalFilter,
    persistence: persistedBlotterTable,
    columns: columnsWithMenu,
    gridOptions: {
      onRowDoubleClicked,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems: marketQuotesMenu.getContextMenuItems,
    },
  });

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(filterResults.filter, blotterTable.getColumns().map(columnToColumnState));
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'MarketQuotes',
        tabLabel,
      }),
    });
    extrasMenuPopover.close();
  });

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTableFilterProps}
        {...blotterTable.blotterTableFiltersProps}
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>
            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
          </BlotterTableExtrasMenu>
        }
      />
      <BlotterTable {...blotterTable} />
      {marketQuotesMenu.dialogs}
    </>
  );
}

function onlyServerFilterKeys(filter: MarketQuotesTableFilter | undefined) {
  if (!filter) {
    return filter;
  }
  const serverFilter = pick(filter, ['StartDate', 'EndDate', 'Symbols', 'Statuses', 'ParentRFQID']);
  return serverFilter;
}

import {
  PORTFOLIO_HISTORICAL_POSITION_AS_OF,
  formattedDateForSubscription,
  removeEmptyFilters,
  useMarketAccountsContext,
  useObservable,
  useSubscription,
  type ProductTypeEnum,
} from '@talos/kyoko';
import { compact, isEqual } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { map } from 'rxjs';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { Balance } from '../../../../types';
import { useEnrichDeltaBalancesPipe } from '../../../Blotters/BalancesV2/useEnrichedDeltaBalancesObs';
import { useTreasuryManagementContext } from '../providers/TreasuryManagementStateAndTabsProvider';

interface HistoricalPositionAsOf {
  Amount: string;
  Asset: string;
  AssetType: ProductTypeEnum;
  Equity?: string;
  InitialMargin?: string;
  MaintenanceMargin?: string;
  Equivalent?: {
    Amount: string;
    ConversionRate: string;
    Currency: string;
    Equity?: string;
    InitialMargin?: string;
    MaintenanceMargin?: string;
  };
  MarketAccount: string;
  Timestamp: string;
}

export function useHistoricalPositionsSub({ tag }: { tag: string }) {
  const { homeCurrency } = useDisplaySettings();
  const { marketAccountsByName } = useMarketAccountsContext();

  // Create a ref of this data set and keep it up to date so we don't have to specify it in the useObservable deps array
  const marketAccountsByNameRef = useRef(marketAccountsByName);
  useEffect(() => {
    marketAccountsByNameRef.current = marketAccountsByName;
  }, [marketAccountsByName]);

  const {
    state: { filter, snapshotDate },
  } = useTreasuryManagementContext();

  const [request, setRequest] = useState<{
    name: string;
    tag: string;
    EquivalentCurrency: string;
    Date: string;
  } | null>(null);

  useEffect(() => {
    // If snapshotDate is null, that means we are viewing positions "as of now".
    if (snapshotDate == null) {
      return;
    }

    setRequest(currentRequest => {
      const maybeNewRequest = {
        name: PORTFOLIO_HISTORICAL_POSITION_AS_OF,
        tag: `${tag}/useHistoricalPositionsSub`,
        EquivalentCurrency: homeCurrency,
        Date: formattedDateForSubscription(snapshotDate),
        ...removeEmptyFilters(filter),
      };

      if (!isEqual(currentRequest, maybeNewRequest)) {
        return maybeNewRequest;
      }

      return currentRequest;
    });
  }, [homeCurrency, filter, snapshotDate, tag]);

  const { data: subscription } = useSubscription<HistoricalPositionAsOf>(request, { replay: false, loadAll: true });

  const enrichBalancesPipe = useEnrichDeltaBalancesPipe();

  // In this observable, we map the received historical positions enriched Balance instances
  // This allows us to feed it into all the middleware related to balances seamlessly
  const mappedHistoricalPositionsDeltaObs = useObservable(
    () =>
      subscription.pipe(
        map(json => {
          return {
            ...json,
            data: compact(
              json.data.map(position => {
                const marketAccount = marketAccountsByNameRef.current.get(position.MarketAccount);
                if (!marketAccount) {
                  return undefined;
                }

                return new Balance({
                  Account: '',
                  MarketAccountID: marketAccount.MarketAccountID,
                  Currency: position.Asset,
                  Amount: position.Amount,
                  AvailableAmount: '',
                  Market: marketAccount.Market,
                  OutstandingBuy: '',
                  OutstandingSell: '',
                  Equity: position.Equity,
                  MaintenanceMargin: position.MaintenanceMargin,
                  InitialMargin: position.InitialMargin,
                  Equivalent: position.Equivalent
                    ? {
                        Amount: position.Equivalent.Amount,
                        Currency: position.Equivalent.Currency,
                        AvailableAmount: '',
                        OutstandingBuy: '',
                        OutstandingSell: '',
                        Equity: position.Equivalent.Equity,
                        InitialMargin: position.Equivalent.InitialMargin,
                        MaintenanceMargin: position.Equivalent.MaintenanceMargin,
                      }
                    : undefined,
                  LastUpdateTime: '',
                  Status: '',
                });
              })
            ),
          };
        }),
        enrichBalancesPipe
      ),
    [subscription, enrichBalancesPipe]
  );

  return mappedHistoricalPositionsDeltaObs;
}

import {
  Button,
  ButtonVariants,
  Flex,
  IconName,
  LoaderTalos,
  MixpanelEvent,
  NotificationVariants,
  TreasuryLinkDirectionEnum,
  TreasuryLinkTypeEnum,
  useGlobalToasts,
  useMarketAccountsContext,
  useMixpanel,
} from '@talos/kyoko';
import { usePositionsFormContext } from 'containers/Portfolio/providers/PositionsFormProvider';
import { PortfolioTh } from 'containers/Portfolio/styles';
import type { MarketPositions, TransferInstruction } from 'containers/Portfolio/types';
import { sortBy } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PositionsActionType } from '../PositionsFormReducer';
import { SettlePositionRow } from './SettlePositionRow';
import { CloseButton, ContentContainer, ModalTitle } from './styles';
import { useSettleRequests } from './useSettleRequests';
import { useTransferInstructions } from './useTransferInstructions';

export interface SettleModalContentProps {
  marketPositions: MarketPositions[];
  close: () => void;
}

export const SettleModalContent = ({ marketPositions, close }: SettleModalContentProps) => {
  const mixpanel = useMixpanel();
  const [checks, setChecks] = useState<Map<string, boolean> | undefined>();
  const { postTransferInstructions } = useSettleRequests();
  const { add: addToast } = useGlobalToasts();
  const { dispatch } = usePositionsFormContext();
  const { marketAccountsByName } = useMarketAccountsContext();

  const [posting, setPosting] = useState(false);

  const transferInstructions = useTransferInstructions(
    marketPositions,
    TreasuryLinkTypeEnum.OTCSettlement,
    TreasuryLinkDirectionEnum.Outbound
  );

  /**
   * Maintain the list of checks in parallell to the transfer instructions
   */
  useEffect(() => {
    if (!transferInstructions) {
      return;
    }

    setChecks(prevChecks => {
      if (!prevChecks) {
        // checks hasnt been initialized yet, so do init mapping
        return new Map(transferInstructions.map(instr => [getInstructionKey(instr), true])); // default all transfers to true
      } else {
        // transferinstructions changed from last run, lets see what we should do
        const newChecksMap = new Map();
        transferInstructions.forEach(instr => {
          const key = getInstructionKey(instr);
          const existingCheck = prevChecks.get(key);
          if (existingCheck !== undefined) {
            newChecksMap.set(key, existingCheck);
          } else {
            // create new entry and default to true
            newChecksMap.set(key, true);
          }
        });

        return newChecksMap;
      }
    });
  }, [transferInstructions]);

  const handleCheckedChange = useCallback((checked: boolean, instruction: TransferInstruction) => {
    setChecks(prevChecks => {
      if (!prevChecks) {
        return prevChecks;
      }
      const newMap = new Map(prevChecks);
      newMap.set(getInstructionKey(instruction), checked);
      return newMap;
    });
  }, []);

  const confirmDisabled = useMemo(() => {
    if (!checks) {
      return true;
    }
    return ![...checks.values()].some(check => check);
  }, [checks]);

  const handleSettleClicked = useCallback(() => {
    mixpanel.track(MixpanelEvent.SettlementInitiated);
    if (!transferInstructions || !checks) {
      return;
    }
    const selectedTransferInstructions = transferInstructions.filter((instruction, i) =>
      checks.get(getInstructionKey(instruction))
    );
    setPosting(true);
    postTransferInstructions(selectedTransferInstructions)
      .then(() => {
        dispatch({
          type: PositionsActionType.TransferInstructionsDelivered,
          payload: {
            transferInstructions: selectedTransferInstructions,
          },
        });
      })
      .catch((e: ErrorEvent) => {
        addToast({
          text: `Could not initiate settlement: ${e.message}`,
          variant: NotificationVariants.Negative,
        });
      })
      .finally(() => {
        setPosting(false);
        close();
      });
  }, [addToast, checks, transferInstructions, postTransferInstructions, close, dispatch, mixpanel]);

  const sortedTransferInstructions = useMemo(() => {
    if (!transferInstructions) {
      return undefined;
    }
    return sortBy(
      transferInstructions.map(instr => ({
        ...instr,
        DisplayName: marketAccountsByName.get(instr.MarketAccount)?.DisplayName ?? instr.MarketAccount,
      })),
      item => item.DisplayName
    );
  }, [transferInstructions, marketAccountsByName]);

  return (
    <ContentContainer>
      {!sortedTransferInstructions || !checks ? (
        <Flex h="100%" justifyContent="center" alignItems="center">
          <LoaderTalos />
        </Flex>
      ) : (
        <Flex h="100%" flexDirection="column" alignItems="flex-start" style={{ maxHeight: '100%' }}>
          <CloseButton ghost icon={IconName.Close} onClick={close} />
          <ModalTitle>Settlement details</ModalTitle>
          <Flex flexDirection="column" justifyContent="space-between" w="100%" flex="1" style={{ overflow: 'auto' }}>
            <Flex pt="spacingMedium" pb="spacingMedium" w="100%">
              <table width="100%">
                <thead>
                  <tr>
                    <PortfolioTh style={{ width: '1%' }} />
                    <PortfolioTh>Dealer</PortfolioTh>
                    <PortfolioTh>Balance available</PortfolioTh>
                    <PortfolioTh align="right">Settle outgoing</PortfolioTh>
                    <PortfolioTh>From</PortfolioTh>
                    <PortfolioTh>To</PortfolioTh>
                  </tr>
                </thead>
                <tbody>
                  {sortedTransferInstructions.map((instruction, i) => {
                    const checked = checks.get(getInstructionKey(instruction));
                    return (
                      checked != null && (
                        <SettlePositionRow
                          key={i}
                          transferInstruction={instruction}
                          checked={checked}
                          onCheckedChange={checked => handleCheckedChange(checked, instruction)}
                        />
                      )
                    );
                  })}
                </tbody>
              </table>
            </Flex>
          </Flex>
          <Flex gap="spacingMedium" w="100%" justifyContent="flex-end" pt="spacingLarge">
            <Button onClick={close}>Close</Button>
            <Button
              variant={ButtonVariants.Primary}
              startIcon={IconName.ArrowLeftRight}
              disabled={confirmDisabled || posting}
              onClick={handleSettleClicked}
              data-testid="settlement-modal-confirm-button"
            >
              Confirm and settle
            </Button>
          </Flex>
        </Flex>
      )}
    </ContentContainer>
  );
};

function getInstructionKey({ Currency, MarketAccount }: TransferInstruction): string {
  return `${Currency}-${MarketAccount}`;
}

import { useAppLayoutSection } from 'components/AppLayout/hooks/useAppLayoutSection';
import { createContext, useCallback, useContext } from 'react';
import type { LayoutSpec, Section } from 'types/LayoutConfig';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

export interface AppLayoutConfigContextProps {
  selectedLayoutIds: Record<Section, string>;
  layoutConfigs: Record<Section, Record<string, LayoutSpec>>;
  setLayoutConfig: (section: string, value: LayoutSpec) => void;
  setLayoutConfigs: (section: string, value: Record<string, LayoutSpec>) => void;
  setSelectedLayoutId: (section: string, value: string) => void;
  enableFlexibleLayout: boolean;
  setEnableFlexibleLayout: (value: boolean) => void;
  resetLayoutConfig: () => void;
  reImportTabs: () => void;
}

export const DefaultLayoutsConfig: Omit<
  AppLayoutConfigContextProps,
  | 'setLayoutConfig'
  | 'setEnableFlexibleLayout'
  | 'resetLayoutConfig'
  | 'reImportTabs'
  | 'setSelectedLayoutId'
  | 'setLayoutConfigs'
> = {
  layoutConfigs: {
    trading: {},
  },
  selectedLayoutIds: {
    trading: 'default',
  },
  enableFlexibleLayout: true,
};

/**
 * Provide method for updating AppConfig related to the AppLayout
 */
export const AppLayoutConfigContext = createContext<AppLayoutConfigContextProps>({
  layoutConfigs: DefaultLayoutsConfig.layoutConfigs,
  selectedLayoutIds: DefaultLayoutsConfig.selectedLayoutIds,
  setSelectedLayoutId: () => {},
  setLayoutConfig: () => {},
  setLayoutConfigs: () => {},
  setEnableFlexibleLayout: () => {},
  enableFlexibleLayout: false,
  resetLayoutConfig: () => {},
  reImportTabs: () => {},
});
AppLayoutConfigContext.displayName = 'AppLayoutConfigContext';

export const useAppLayoutConfig = (): {
  selectedLayoutId: string | undefined;
  layoutSection: Section | undefined;
  layoutConfigs: Record<string, LayoutSpec> | undefined;
  layoutConfig: LayoutSpec | undefined;
  updateLayoutConfig: (layoutConfig: Partial<LayoutSpec> & Pick<LayoutSpec, 'id'>) => void;
  updateLayoutConfigs: (layoutConfigs: Record<string, LayoutSpec>) => void;
  updateSelectedLayoutId: (id: string) => void;
  setEnableFlexibleLayout: (value: boolean) => void;
  enableFlexibleLayout: boolean;
  resetLayoutConfig: () => void;
  reImportTabs: () => void;
} => {
  const { enableFlexibleUI } = useFeatureFlag();
  const layoutSection = useAppLayoutSection();
  const {
    layoutConfigs,
    setLayoutConfig,
    setLayoutConfigs,
    selectedLayoutIds,
    setSelectedLayoutId,
    setEnableFlexibleLayout,
    enableFlexibleLayout,
    resetLayoutConfig,
    reImportTabs,
  } = useContext(AppLayoutConfigContext);

  const updateLayoutConfigs = useCallback(
    (layoutConfigs: Record<string, LayoutSpec>) => {
      if (layoutSection) {
        setLayoutConfigs(layoutSection, layoutConfigs);
      }
    },
    [setLayoutConfigs, layoutSection]
  );

  const updateLayoutConfig = useCallback(
    (layoutConfig: Partial<LayoutSpec> & Pick<LayoutSpec, 'id'>) => {
      if (layoutSection) {
        const prevConfig = layoutConfigs[layoutSection]?.[layoutConfig.id] ?? {};
        const newConfig = { ...prevConfig, ...layoutConfig };
        setLayoutConfig(layoutSection, newConfig);
      }
    },
    [setLayoutConfig, layoutSection, layoutConfigs]
  );

  const selectedLayoutId = layoutSection ? selectedLayoutIds[layoutSection] : undefined;

  const updateSelectedLayoutId = useCallback(
    (id: string) => {
      layoutSection && setSelectedLayoutId(layoutSection, id);
    },
    [layoutSection, setSelectedLayoutId]
  );

  return {
    selectedLayoutId,
    layoutSection,
    layoutConfigs: layoutSection ? layoutConfigs[layoutSection] : undefined,
    layoutConfig: layoutSection && selectedLayoutId ? layoutConfigs[layoutSection]?.[selectedLayoutId] : undefined,
    updateLayoutConfig,
    updateLayoutConfigs,
    updateSelectedLayoutId,
    setEnableFlexibleLayout,
    enableFlexibleLayout: enableFlexibleUI && enableFlexibleLayout,
    resetLayoutConfig,
    reImportTabs,
  };
};

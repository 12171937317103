import { datadogLogs, type LogsInitConfiguration } from '@datadog/browser-logs';
import { datadogRum, type RumInitConfiguration } from '@datadog/browser-rum';
import { noop } from 'lodash-es';

export interface DatadogArgs {
  environment: string;
  version: string;
  applicationId: string;
  clientToken: string;
  service: string;
  proxy?: string;

  /**
   * If true, will require a manual call to `startSessionReplayRecording()`, possibly with `{ force: true }`,
   * depending on the value of `sessionReplaySampleRate`
   *
   * See https://github.com/DataDog/browser-sdk/issues/1967#issuecomment-2023862956
   */
  startSessionReplayRecordingManually?: boolean;
}

// Non-prod back-ends go down a lot, so this allows us to ignore some errors
const IGNORED_UAT_ERRORS: Set<string> = new Set(['Failed to fetch', 'NetworkError when attempting to fetch resource.']);
const IGNORED_PROD_ERRORS: Set<string> = new Set([]);

const CSP_ERROR_TYPES: Set<string> = new Set([
  'script-src',
  'font-src',
  'frame-src',
  'img-src',
  'script-src-elem',
  'media-src',
  'connect-src',
  'worder-src',
]);

export function initDatadog({
  environment,
  version,
  applicationId,
  clientToken,
  service,
  proxy,
  startSessionReplayRecordingManually,
}: DatadogArgs) {
  const isLocal = environment === 'local';
  const isProduction = environment === 'prod' || environment === 'sandbox';

  const commonConfiguration = {
    applicationId,
    site: 'datadoghq.com',
    clientToken,
    service,
    proxy: proxy == null ? undefined : options => `${proxy}${options.path}?${options.parameters}`,
    env: environment,
    version,
    useSecureSessionCookie: !isLocal,
    telemetrySampleRate: 0,
    sessionSampleRate: 100,
  } as const satisfies RumInitConfiguration & LogsInitConfiguration;

  datadogRum.init({
    ...commonConfiguration,
    compressIntakeRequests: true,
    allowFallbackToLocalStorage: true,

    // Track all the things
    trackUserInteractions: true,
    trackLongTasks: true,
    trackResources: true,

    // Only record session replays in production (100% of sessions by default)
    sessionReplaySampleRate: isProduction ? 100 : 0,
    startSessionReplayRecordingManually,
    beforeSend: (event, context) => {
      if (isLocal) {
        let consoleMethod: (message?: any, ...optionalParams: any[]) => void;
        switch (event.type) {
          case 'long_task':
          case 'vital':
            // Ignore logging long_task & vital events. They're not useful.
            consoleMethod = noop;
            break;
          case 'error':
            consoleMethod = console.warn;
            break;
          default:
            // eslint-disable-next-line no-console
            consoleMethod = console.debug;
            break;
        }
        consoleMethod('[Datadog]', event.type, event);
        // View events cannot be discarded
        // See https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration
        if (event.type === 'view') {
          return true;
        }
        return false;
      }

      // If the page was discarded, we want to know about it
      // See https://developer.chrome.com/blog/memory-and-energy-saver-mode
      if (event.type === 'long_task' && 'wasDiscarded' in document && document.wasDiscarded) {
        event.context = { ...event.context, wasDiscarded: true };
      }

      if (event.type === 'error') {
        // Ignore certain errors
        const ignoredErrors = isProduction ? IGNORED_PROD_ERRORS : IGNORED_UAT_ERRORS;
        if (ignoredErrors.has(event.error.message)) {
          return false;
        }

        // Always ignore CSP errors
        if (CSP_ERROR_TYPES.has(event.error.type as string)) {
          return false;
        }

        // Attempt to ignore any errors coming from chrome extensions
        const stack = event.error.stack ?? (context as any).error.stack;
        if (stack?.includes('chrome-extension://')) {
          return false;
        }
      }

      return true;
    },
  });

  // Logs
  datadogLogs.init({
    ...commonConfiguration,
    forwardErrorsToLogs: !isLocal,

    // note that debug/info are manually forwarded in our logger
    forwardConsoleLogs: isLocal ? [] : ['error', 'warn', 'info'],
  });

  // Event handlers
  document.addEventListener('visibilitychange', () => {
    datadogRum.setGlobalContextProperty('document.visibilityState', document.visibilityState);
    datadogLogs.setGlobalContextProperty('document.visibilityState', document.visibilityState);
  });
}

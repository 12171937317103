import { Suspense } from 'react';
import lazyWithPrefetch from '../../utils/lazyWithPrefetch';
import { logger } from '../../utils/logger';
import type { RR6PromptProps } from './RR6PromptLazy';

const RR6LazyPrompt =
  import.meta.env.VITE_BUILD_MOBILE === 'true' ? null : lazyWithPrefetch(() => import('./RR6PromptLazy'));

RR6LazyPrompt?.prefetch();

/** React-Router 6+ backward compatible version of the RR5 Prompt component, which blocks navigation
 * under speciific conditions.
 * - IMPORTANT, this component is not available in mobile builds due to the RR6/RR5 conclift - if needed, use it locally in the whitelabel-mobile project
 * @param when - when to block navigation
 * @param message - message to display in the confirmation dialog
 * @param handleOnConfirmed - callback to execute when the user confirms the navigation
 */
export function RR6Prompt({ when, message, handleOnConfirmed }: RR6PromptProps) {
  if (!RR6LazyPrompt) {
    logger.warn('RR6Prompt is not available in mobile builds');
    return null;
  }
  return (
    <Suspense>
      <RR6LazyPrompt when={when} message={message} handleOnConfirmed={handleOnConfirmed} />
    </Suspense>
  );
}

export default RR6Prompt;

import styled, { css } from 'styled-components';
import { setAlpha } from '../../styles';
import { Box, Flex } from '../Core';

export const SlideItem = styled.button<{ selected: boolean }>`
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSizeMd}rem;
  padding: ${({ theme }) => theme.spacingDefault}px ${({ theme }) => theme.spacingMedium}px;
  height: 100%;
  gap: ${({ theme }) => theme.spacingTiny}px ${({ theme }) => theme.spacingSmall}px;
  justify-content: center;
  align-items: flex-start;
  border-radius: ${({ theme }) => theme.borderRadiusSmall}px;
  flex-direction: column;
  display: flex;

  border-style: unset;
  cursor: pointer;
  ${({ theme }) => css`
    color: ${theme.colorTextDefault};
  `}

  ${({ theme, selected }) =>
    selected
      ? css`
          background: ${theme.colors.gray['040']};
          color: ${theme.colorTextImportant};
        `
      : css`
          opacity: 0.8;
          background: ${theme.colors.gray['030']};
        `}
`;

export const SlideContainer = styled(Flex).attrs({
  overflow: 'auto',
  w: '100%',
  gap: 'spacingSmall',
  background: 'backgroundBody',
  p: 'spacingSmall',
  borderRadius: 'borderRadiusMedium',
})`
  scrollbar-width: none;
`;

export const FadedScrollableEdge = styled(Box)`
  pointer-events: none;
  transition: opacity 200ms ease-in-out;
  position: absolute;
  width: 8%;
  z-index: 1;
  top: 1px;
  bottom: 1px;
  ${({ theme }) => css`
    background: linear-gradient(90deg, ${theme.backgroundShadow} 20%, ${setAlpha(0, theme.backgroundShadow)} 100%);
  `}

  border-radius:  ${({ theme }) => theme.borderRadiusMedium}px 0 0 ${({ theme }) => theme.borderRadiusMedium}px;

  &:first-of-type {
    left: -1px;
  }

  &:last-of-type {
    right: -1px;
    transform: rotate(180deg);
  }
`;

import {
  useAggDeltaUpdatesPipe,
  useObservable,
  wsSubscriptionCache,
  type DeepPartial,
  type Leaves,
  type MinimalSubscriptionResponse,
  type PinnedRow,
  type Position,
} from '@talos/kyoko';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import { shareReplay, type Observable } from 'rxjs';
import { useHistoricalPositionStatAsOfSub } from '../PerformanceBlotter/useHistoricalPositionStatAsOfSub';
import { useSubAccountPositionsSub } from '../PerformanceBlotter/useSubAccountPositionsSub';
import { usePerformanceContext } from './PerformanceStateAndTabsProvider';

function getPositionKey(position: Position) {
  return position.rowID;
}

const aggSpecs = [
  { valuePath: 'PnLLookbacks.Today.Equivalent.PnLDelta', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'PnLLookbacks.H24.Equivalent.PnLDelta', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'PnLLookbacks.WeekToDate.Equivalent.PnLDelta', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'PnLLookbacks.D7.Equivalent.PnLDelta', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'PnLLookbacks.MonthToDate.Equivalent.PnLDelta', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'PnLLookbacks.D30.Equivalent.PnLDelta', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'PnLLookbacks.YearToDate.Equivalent.PnLDelta', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'PnLLookbacks.D365.Equivalent.PnLDelta', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.Amount', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.OutstandingBuy', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.OutstandingSell', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.RealizedPnL', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.UnrealizedPnL', currencyPath: 'Equivalent.Currency' },
] satisfies { valuePath: Leaves<Position>; currencyPath: Leaves<Position> }[];

export const PerformancePositionsContext = createContext<PerformancePositionsContextProps | undefined>(undefined);

export type PerformancePositionsContextProps = {
  positionsObs: Observable<MinimalSubscriptionResponse<Position>>;
  positionTotalsObs: Observable<DeepPartial<PinnedRow<Position>>>;
};

export function usePerformancePositions() {
  const context = useContext(PerformancePositionsContext);
  if (context === undefined) {
    throw new Error('Missing PerformancePositionsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const PerformancePositionsProvider = function PerformancePositionsProvider({ children }: PropsWithChildren) {
  const {
    state: { snapshotDate },
  } = usePerformanceContext();

  const livePositionsSub = useSubAccountPositionsSub();
  const asOfPositionsSub = useHistoricalPositionStatAsOfSub();

  // we cache each of these subscriptions here to normalize the resubscription behavior such that we can toggle between them
  // while being ensured we always get the complete correct data
  const cachedLiveSub = useMemo(() => {
    return livePositionsSub.pipe(wsSubscriptionCache(p => p.rowID));
  }, [livePositionsSub]);
  const cachedAsOfSub = useMemo(() => {
    return asOfPositionsSub.pipe(wsSubscriptionCache(p => p.rowID));
  }, [asOfPositionsSub]);

  const positionsObs = snapshotDate ? cachedAsOfSub : cachedLiveSub;

  const totalsPipe = useAggDeltaUpdatesPipe({
    getUniqueKey: getPositionKey,
    aggSpecs: aggSpecs,
  });

  const positionTotalsObs: Observable<DeepPartial<PinnedRow<Position>>> = useObservable(() => {
    return positionsObs.pipe(
      totalsPipe,
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );
  }, [positionsObs, totalsPipe]);

  const value = useMemo(() => {
    return {
      positionsObs,
      positionTotalsObs,
    };
  }, [positionsObs, positionTotalsObs]);

  return <PerformancePositionsContext.Provider value={value}>{children}</PerformancePositionsContext.Provider>;
};

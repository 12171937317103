import { identity } from 'lodash-es';
import { memo, useMemo } from 'react';
import styled from 'styled-components';
import { useDynamicCallback, usePortal } from '../../../hooks';
import { EMPTY_ARRAY } from '../../../utils';
import { BLOTTER_TABLE_FILTERS_CONTAINER_ID } from '../../BlotterTable';
import { Box, HStack, VStack } from '../../Core';
import { Tab, TabAppearance, TabList, TabSize, useAddTabPopover } from '../../Tabs';
import { useEntityAdminTabsContext } from '../wrappers/EntityAdminTabsWrapper';

export const EntityAdminTabsAndFilters = memo(() => {
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  const tabsContext = useEntityAdminTabsContext();

  const tabTypeOptions = useMemo(() => {
    return tabsContext?.defaultInitialItems.map(tab => tab.tabType!) ?? EMPTY_ARRAY;
  }, [tabsContext?.defaultInitialItems]);

  const handleSelectTabType = useDynamicCallback((type: string) => tabsContext?.onAdd({ label: type, tabType: type }));

  const { renderAddTab } = useAddTabPopover({
    options: tabTypeOptions,
    getLabel: identity,
    onSelection: handleSelectTabType,
    appearance: TabAppearance.Filled,
    size: TabSize.Small,
  });

  return (
    <VStack w="100%">
      <HStack
        w="100%"
        h="40px" // Match tabs height
        pl="spacingLarge"
        pr="spacingLarge"
        background="backgroundContent"
        justifyContent="space-between"
      >
        <HStack w="100%">
          {tabsContext != null && (
            <TabList
              w="100%"
              overflow="hidden"
              renderAddTab={tabsContext.defaultInitialItems.length > 1 ? renderAddTab : undefined}
            >
              {tabsContext.tabItems.map(item => (
                <Tab key={item.id} {...item} />
              ))}
            </TabList>
          )}
        </HStack>
        <FiltersContainerBox ref={filtersContainerRef} />
      </HStack>
    </VStack>
  );
});

const FiltersContainerBox = styled(Box)`
  @media (max-width: 576px) {
    display: none;
  }
`;

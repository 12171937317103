import Big from 'big.js';
import { useTheme } from 'styled-components';
import type { DecisionStatusEnum, OrdStatusEnum, PricingModeEnum } from '../../types';
import { orderStatusTextToColor } from '../../utils';
import { Meter, type MeterProps } from '../Meter';
import { getOrderStatusText } from '../Status';

export function FilledMeter({
  entity,
  ...props
}: {
  entity: {
    OrderQty: string;
    CumQty: string;
    OrdStatus: OrdStatusEnum;
    DecisionStatus?: DecisionStatusEnum;
    PricingMode?: PricingModeEnum;
  };
} & Omit<MeterProps, 'value'>) {
  const theme = useTheme();

  if (entity == null) {
    return null;
  }

  const { OrderQty, CumQty } = entity;
  const status = getOrderStatusText({
    type: 'orderLike',
    order: entity,
  });

  const filledShare = Big(OrderQty ?? '0').gt(0) ? Big(CumQty ?? '0').div(OrderQty) : Big(0);
  const progressBarColor = orderStatusTextToColor(status, theme);

  return <Meter color={progressBarColor} showLabel={false} {...props} value={filledShare.toNumber()} />;
}

import {
  Button,
  type Column,
  ConnectionModeEnum,
  CopyCsv,
  CopyJson,
  Divider,
  type FilterableProperty,
  filterByCellValueMenuItem,
  FormControlSizes,
  getRowNodesToOperateOn,
  IconName,
  type MarketConfig,
  type MarketCredential,
  NotificationVariants,
  useDisclosure,
  type UseDisclosureReturn,
  useDynamicCallback,
  type UseFilterBuilderOutput,
  useGetDefaultContextMenuItems,
  useGlobalToasts,
  useMixpanel,
  useUserContext,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, ICellRendererParams, MenuItemDef } from 'ag-grid-enterprise';
import { compact } from 'lodash-es';
import { useMemo, useState } from 'react';
import { EnableDisableSelectedDialog } from './Dialogs/EnableDisableSelectedDialog';
import { RemoveSelectedDialog } from './Dialogs/RemoveSelectedDialog';
import type { HydratedMarketCredential } from './types';
import { colIDToFilterBuilderKey } from './useCredentialsFilter';

export function CredentialsBlotterTableMenu({
  canEditCredential,
  onEditCredential,
  onRemoveCredential,
  onEnableDisable,
  ...params
}: ICellRendererParams<HydratedMarketCredential> & {
  canEditCredential: boolean;
  onEditCredential(credential: MarketCredential): void;
  onRemoveCredential(credentials: HydratedMarketCredential[]): void;
  onEnableDisable(credentials: HydratedMarketCredential[], mode: ConnectionModeEnum): void;
}) {
  const credential = params.data;
  if (!credential) {
    return null;
  }
  const oppositeMode = credential.Mode === ConnectionModeEnum.Up ? ConnectionModeEnum.Down : ConnectionModeEnum.Up;

  return (
    <>
      {canEditCredential && (
        <Button size={FormControlSizes.Small} ghost={true} onClick={() => onEditCredential(credential)}>
          Edit &quot;{credential?.Label}&quot;
        </Button>
      )}
      <Button size={FormControlSizes.Small} ghost={true} onClick={() => onEnableDisable([credential], oppositeMode)}>
        {credential.Mode === ConnectionModeEnum.Up ? 'Disable' : 'Enable'} Credential
      </Button>
      {credential.Mode === ConnectionModeEnum.Down && (
        <Button size={FormControlSizes.Small} ghost={true} onClick={() => onRemoveCredential([credential])}>
          Delete Credential
        </Button>
      )}
      <Divider orientation="horizontal" />
      <CopyJson {...params} />
      <CopyCsv {...params} />
    </>
  );
}

export interface UseCredentialsBlotterTableMenu {
  columns: Column[];
  getContextMenuItems: (params: GetContextMenuItemsParams) => Array<string | MenuItemDef>;
  dialogComponents: JSX.Element[];
}

export function useCredentialsBlotterTableMenu({
  canEditCredential,
  onEditCredential,
  openClause,
  filterableProperties,
  marketConfigs,
  onUpdate,
}: {
  canEditCredential: boolean;
  onEditCredential(credential: MarketCredential): void;
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
  marketConfigs: MarketConfig[];
  onUpdate: () => void;
}): UseCredentialsBlotterTableMenu {
  const { updateMarketCredential, deleteMarketCredential } = useUserContext();
  const dialog = useDisclosure();
  const removeDialog = useDisclosure();
  const mixpanel = useMixpanel();
  const { add: addToast } = useGlobalToasts();
  const [dialogMode, setDialogMode] = useState<ConnectionModeEnum>(ConnectionModeEnum.Up);
  const [dialogItemsToChange, setDialogItemsToChange] = useState<HydratedMarketCredential[]>([]);

  const onEnableDisable = useDynamicCallback(
    async (credentials: HydratedMarketCredential[], mode: ConnectionModeEnum) => {
      const promises: Promise<any>[] = [];
      for (const credential of credentials) {
        const marketCofnig = marketConfigs.find(mktCfg => mktCfg.name === credential.Market);
        if (!marketCofnig) {
          console.error('Market config not found for market', credential.Market);
          continue;
        }
        promises.push(
          updateMarketCredential(marketCofnig.name, credential.Name, {
            label: credential.Label,
            connectionType: credential.ConnectionType,
            mode,
          })
            .then(() => {
              addToast({
                text: `Updated ${credential.Label}`,
                variant: NotificationVariants.Positive,
              });
            })
            .catch(e => {
              addToast({
                text: e?.toString() || `Could not update ${credential.Label}`,
                variant: NotificationVariants.Negative,
              });
            })
        );
      }
      await Promise.all(promises);
      onUpdate();
    }
  );

  const onRemoveCredential = useDynamicCallback(async (credentials: HydratedMarketCredential[]) => {
    const promises: Promise<any>[] = [];
    for (const credential of credentials) {
      const marketCofnig = marketConfigs.find(mktCfg => mktCfg.name === credential.Market);
      if (!marketCofnig) {
        console.error('Market config not found for market', credential.Market);
        continue;
      }
      promises.push(
        deleteMarketCredential(marketCofnig.name, credential.Name)
          .then(() => {
            addToast({
              text: `Deleted ${credential.Label}`,
              variant: NotificationVariants.Positive,
            });
          })
          .catch(e => {
            addToast({
              text: e?.toString() || `Could not delete ${credential.Label}`,
              variant: NotificationVariants.Negative,
            });
          })
      );
    }
    await Promise.all(promises);
    onUpdate();
  });

  const removeItem = useDynamicCallback((credentials: HydratedMarketCredential[]) => {
    return removeCredentialMenuItem({
      credentials,
      setDialogItemsToChange,
      dialog: removeDialog,
    });
  });

  const handleRemoveCredential = useDynamicCallback((credentials: HydratedMarketCredential[]) => {
    setDialogItemsToChange(credentials);
    removeDialog.open();
  });

  const columns = useMemo<Column[]>(
    () =>
      compact([
        {
          id: 'menu',
          type: 'hamburgerMenu',
          params: {
            renderItems: params => (
              <CredentialsBlotterTableMenu
                {...params}
                canEditCredential={canEditCredential}
                onEditCredential={onEditCredential}
                onRemoveCredential={handleRemoveCredential}
                onEnableDisable={onEnableDisable}
              />
            ),
          },
        },
      ]),
    [canEditCredential, onEditCredential, onEnableDisable, handleRemoveCredential]
  );

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const enableItem = useDynamicCallback((credentials: HydratedMarketCredential[]) =>
    toggleCredentialModeMenuItem({
      credentials,
      onEnableDisable,
      mode: ConnectionModeEnum.Up,
      setDialogMode,
      setDialogItemsToChange,
      dialog,
    })
  );
  const disableItem = useDynamicCallback((credentials: HydratedMarketCredential[]) =>
    toggleCredentialModeMenuItem({
      credentials,
      onEnableDisable,
      mode: ConnectionModeEnum.Down,
      setDialogMode,
      setDialogItemsToChange,
      dialog,
    })
  );

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const selectedItems: HydratedMarketCredential[] = getRowNodesToOperateOn(params).map(node => node.data);
    return compact([
      ...filterByCellValueMenuItem({
        params,
        filterableProperties,
        openClause,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      canEditCredential
        ? {
            name: `Edit "${params?.node?.data?.Label}"`,
            action: () => onEditCredential(params?.node?.data),
          }
        : null,
      enableItem(selectedItems),
      disableItem(selectedItems),
      removeItem(selectedItems),
      'separator',
      ...getDefaultContextMenuItems(params),
    ]);
  });

  return {
    columns,
    getContextMenuItems,
    dialogComponents: [
      <EnableDisableSelectedDialog
        key="enable-disable-selected-dialog"
        selectedItems={dialogItemsToChange}
        enableDisableDialog={dialog}
        onConfirm={(credentials: HydratedMarketCredential[]) => {
          onEnableDisable(credentials, dialogMode);
        }}
        mode={dialogMode}
      />,
      <RemoveSelectedDialog
        key="remove-selected-dialog"
        selectedItems={dialogItemsToChange}
        removeDialog={removeDialog}
        onConfirm={(credentials: HydratedMarketCredential[]) => {
          onRemoveCredential(credentials);
        }}
      />,
    ],
  };
}

interface CredentialsMenuItemProps {
  credentials: HydratedMarketCredential[];
  onEnableDisable: (credentials: HydratedMarketCredential[], mode: ConnectionModeEnum) => void;
  mode: ConnectionModeEnum;
  setDialogMode: React.Dispatch<React.SetStateAction<ConnectionModeEnum>>;
  setDialogItemsToChange: React.Dispatch<React.SetStateAction<HydratedMarketCredential[]>>;
  dialog: UseDisclosureReturn;
}

interface RemoveCredentialsMenuItemProps {
  credentials: HydratedMarketCredential[];
  setDialogItemsToChange: React.Dispatch<React.SetStateAction<HydratedMarketCredential[]>>;
  dialog: UseDisclosureReturn;
}

const toggleCredentialModeMenuItem = ({
  credentials,
  onEnableDisable,
  mode,
  setDialogMode,
  setDialogItemsToChange,
  dialog,
}: CredentialsMenuItemProps) => {
  const oppositeMode = mode === ConnectionModeEnum.Up ? ConnectionModeEnum.Down : ConnectionModeEnum.Up;
  const filteredCredentials = credentials.filter(credential => credential.Mode === oppositeMode);
  if (filteredCredentials.length < 1) {
    return null;
  }
  const label = mode === ConnectionModeEnum.Up ? 'Enable' : 'Disable';
  const name =
    filteredCredentials.length === 1 ? `${label} Credential` : `${label} ${filteredCredentials.length} Credentials`;
  return {
    name,
    action: () => {
      if (filteredCredentials.length === 1) {
        onEnableDisable(filteredCredentials, mode);
      } else {
        setDialogItemsToChange(filteredCredentials);
        setDialogMode(mode);
        dialog.open();
      }
    },
    icon: `<i class="ag-icon ${
      mode === ConnectionModeEnum.Up ? IconName.CheckCircleSolid : IconName.CloseCircleSolid
    }"/>`,
  };
};

const removeCredentialMenuItem = ({ credentials, setDialogItemsToChange, dialog }: RemoveCredentialsMenuItemProps) => {
  const filteredCredentials = credentials.filter(credential => credential.Mode === ConnectionModeEnum.Down);
  const name = `Delete ${filteredCredentials.length} Credential${filteredCredentials.length > 1 ? 's' : ''}`;
  if (filteredCredentials.length < 1) {
    return null;
  }
  return {
    name,
    action: () => {
      setDialogItemsToChange(filteredCredentials);
      dialog.open();
    },
    icon: `<i class="ag-icon ${IconName.Close}"/>`,
  };
};

import { minBy } from 'lodash-es';

/**
 *
 * @param sets An array of sets
 * @returns
 */
export function intersectManySets<T>(sets: Set<T>[]): Set<T> | undefined {
  if (sets.length === 0) {
    return undefined;
  }

  // If we grab the shortest set and loop over its items only, computing an intersection is much more efficient
  const shortestSet = minBy(sets, set => set.size);
  if (!shortestSet) {
    return undefined;
  }

  const intersection = new Set<T>();
  for (const item of shortestSet) {
    if (sets.every(set => set.has(item))) {
      intersection.add(item);
    }
  }

  return intersection;
}

export function intersectTwoSets<T>(set1: Set<T>, set2: Set<T>) {
  const intersection = new Set<T>();

  for (const item of set1) {
    if (set2.has(item)) {
      intersection.add(item);
    }
  }

  return intersection;
}

import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { SerializedDockview } from 'dockview';
import { castDraft } from 'immer';
import type { AppState } from '../../providers/AppStateProvider/types';
import type { LayoutSpec, PanelConfig, PanelType, Section } from '../../types/LayoutConfig';
import type { MinimizedPanel } from '../Layout/types';

export interface AppLayoutState {
  layoutSection: Section;
  openPanels: string[];
  temporaryPanel: TemporaryPanelConfig | undefined;
  minimizedPanels: MinimizedPanel[];
  maximizedPanels: string[];
  currentLayout: LayoutSpec | undefined;
  initialLayoutState: LayoutSpec | undefined;
  layoutOptions: Record<string, LayoutSpec> | undefined;
  undoStack: SerializedDockview[];
  redoStack: SerializedDockview[];
  layoutHasChanged: boolean;
  layoutIsResetting: boolean;
  showUndoPopup: boolean;
}

export interface MinimizePanelAction extends MinimizedPanel {}

export interface CurrentLayoutAction {
  layoutConfig: LayoutSpec;
}

interface InitLayoutAction {
  layoutConfig: LayoutSpec;
  layoutSection: Section;
}

export interface TemporaryPanelConfig {
  type: PanelType;
  params: PanelConfig;
  title: string;
}

const initialState: AppLayoutState = {
  layoutSection: 'trading',
  temporaryPanel: undefined,
  openPanels: [],
  maximizedPanels: [],
  minimizedPanels: [],
  currentLayout: undefined,
  initialLayoutState: undefined,
  layoutOptions: undefined,
  undoStack: [],
  redoStack: [],
  /** Whether the layout has changed since the initial load. The config may differ even if the layout hasn't changed because of size rounding on load */
  layoutHasChanged: false,
  layoutIsResetting: false,
  showUndoPopup: false,
};

export const appLayoutSlice = createSlice({
  name: 'appLayout',
  initialState,
  reducers: {
    setTemporaryPanel: (state, action: PayloadAction<TemporaryPanelConfig | undefined>) => {
      state.temporaryPanel = castDraft(action.payload);
    },
    updateTemporaryPanelParams: (state, action: PayloadAction<Partial<PanelConfig>>) => {
      const panel = state.temporaryPanel;
      if (panel) {
        panel.params = Object.assign({}, panel.params, action.payload);
        state.temporaryPanel = panel;
      }
    },
    updateTemporaryPanelTitle: (state, action: PayloadAction<string>) => {
      if (state.temporaryPanel) {
        state.temporaryPanel.title = action.payload;
      }
    },
    openPanel: (state, action: PayloadAction<string>) => {
      state.openPanels = state.openPanels.concat(action.payload);
    },
    closePanel: (state, action: PayloadAction<string>) => {
      state.openPanels = state.openPanels.filter(id => id !== action.payload);
    },
    togglePanel: (state, action: PayloadAction<string>) => {
      const isOpen = state.openPanels.includes(action.payload);
      if (isOpen) {
        state.openPanels = state.openPanels.filter(id => id !== action.payload);
      } else {
        state.openPanels = state.openPanels.concat(action.payload);
      }
    },
    minimizePanel: (state, action: PayloadAction<MinimizePanelAction>) => {
      state.minimizedPanels = state.minimizedPanels.concat(action.payload);
      state.maximizedPanels = state.maximizedPanels.filter(groupId => groupId !== action.payload.groupId);
    },
    maximizePanel: (state, action: PayloadAction<string>) => {
      state.maximizedPanels = state.maximizedPanels.concat(action.payload);
      state.minimizedPanels = state.minimizedPanels.filter(panel => panel.groupId !== action.payload);
    },
    restorePanel: (state, action: PayloadAction<string>) => {
      state.minimizedPanels = state.minimizedPanels.filter(panel => panel.groupId !== action.payload);
      state.maximizedPanels = state.maximizedPanels.filter(groupId => groupId !== action.payload);
    },
    /** Update the dockview part of the currently selected layout, whenever dockview sends a onLayoutChange. Should also update appConfig */
    updateDockviewLayout: (state, action: PayloadAction<SerializedDockview>) => {
      if (state.currentLayout) {
        state.currentLayout.dockViewLayout = action.payload;
        state.layoutHasChanged = true;
      }
    },
    /** Set the list of layouts available to switch between */
    setLayoutOptions: (state, action: PayloadAction<Record<string, LayoutSpec>>) => {
      state.layoutOptions = action.payload;
    },
    /** Switch to the new layout from the list of layout options, should also update appConfig */
    setCurrentLayout: (state, action: PayloadAction<CurrentLayoutAction>) => {
      state.currentLayout = action.payload.layoutConfig;
      state.undoStack = [];
      state.redoStack = [];
      state.layoutIsResetting = true;
      state.layoutHasChanged = false;
      state.initialLayoutState = action.payload.layoutConfig;
      state.openPanels =
        state.currentLayout?.collapsablePanels.filter(panel => panel.defaultVisible).map(panel => panel.groupId) ?? [];
    },
    setInitialLayoutState: (state, action: PayloadAction<SerializedDockview>) => {
      if (state.initialLayoutState) {
        state.initialLayoutState.dockViewLayout = action.payload;
        state.undoStack = [];
        state.redoStack = [];
        state.layoutHasChanged = false;
      }
    },
    /** Initialize the layout on load based on the layoutSection */
    initLayout: (state, action: PayloadAction<InitLayoutAction>) => {
      if (action.payload.layoutSection == null && state.layoutSection == null) {
        return;
      }

      state.layoutSection = action.payload.layoutSection ?? state.layoutSection;

      // Merge the default state with the layout config
      state.currentLayout = action.payload.layoutConfig;
      state.initialLayoutState = action.payload.layoutConfig;
      state.openPanels =
        state.currentLayout?.collapsablePanels.filter(panel => panel.defaultVisible).map(panel => panel.groupId) ?? [];
    },
    unmountCurrentLayout: state => {
      state.currentLayout = undefined;
    },
    undoChange: state => {
      if (state.undoStack.length === 0 || !state.currentLayout) {
        return;
      }

      const newLayout = state.undoStack.pop();
      if (newLayout) {
        state.redoStack.push(state.currentLayout?.dockViewLayout);
        state.currentLayout.dockViewLayout = newLayout;
        state.layoutIsResetting = true;
      }
    },
    redoChange: state => {
      if (state.redoStack.length === 0 || !state.currentLayout) {
        return;
      }

      const newLayout = state.redoStack.pop();
      if (newLayout) {
        state.undoStack.push(state.currentLayout?.dockViewLayout);
        state.currentLayout.dockViewLayout = newLayout;
        state.layoutIsResetting = true;
      }
    },

    /** Add the current state as an undo action (should be called before updateDockviewLayout) */
    addUndo: state => {
      if (state.currentLayout?.dockViewLayout && !state.layoutIsResetting) {
        state.undoStack.push(state.currentLayout?.dockViewLayout);
        state.redoStack = [];
        state.showUndoPopup = true;
      }
    },
    resetToInitialLayout: state => {
      state.currentLayout = state.initialLayoutState;
      state.layoutIsResetting = true;
      state.layoutHasChanged = false;
      state.undoStack = [];
      state.redoStack = [];
    },
    layoutWasReset: state => {
      state.layoutIsResetting = false;
    },
    hideUndoPopup: state => {
      state.showUndoPopup = false;
    },
  },
});

export const selectOpenPanels = (state: AppState) => state.appLayout.openPanels;
export const selectMinimizedPanels = (state: AppState) => state.appLayout.minimizedPanels;
export const selectMaximizedPanels = (state: AppState) => state.appLayout.maximizedPanels;
export const selectCurrentLayout = (state: AppState) => state.appLayout.currentLayout;

export const {
  initLayout,
  setLayoutOptions,
  setCurrentLayout,
  updateDockviewLayout,
  maximizePanel,
  minimizePanel,
  restorePanel,
  togglePanel,
  openPanel,
  closePanel,
  setTemporaryPanel,
  updateTemporaryPanelTitle,
  updateTemporaryPanelParams,
  unmountCurrentLayout,
  addUndo,
  undoChange,
  redoChange,
  setInitialLayoutState,
  resetToInitialLayout,
  layoutWasReset,
  hideUndoPopup,
} = appLayoutSlice.actions;

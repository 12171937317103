import { cloneDeep, merge } from 'lodash-es';
import { hsl, hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { DarkTheme, mapDarkTheme } from './DarkTheme';

const customcolors: Partial<Colors> = {
  gray: {
    main: '#1F2128',

    '000': '#121317',
    '010': hsla(231, 0.11, 0.11, 1),
    '020': hsla(231, 0.11, 0.14, 1),
    '030': hsla(231, 0.11, 0.16, 1),
    '040': hsla(231, 0.11, 0.2, 1),
    '050': '#363843',
    '060': hsla(231, 0.11, 0.28, 1),
    '070': hsla(231, 0.11, 0.36, 1),
    '080': hsla(231, 0.05, 0.57, 1),
    '090': hsla(231, 0.05, 0.75, 1),
    '100': hsla(231, 0.05, 0.95, 1),
  },

  primary: {
    mute: hsla(217, 0.64, 0.6, 0.1),
    dim: hsla(217, 0.64, 0.6, 0.4),
    default: hsl(217, 0.64, 0.47),
    lighten: hsl(217, 0.64, 0.6),
  },

  green: {
    mute: hsl(101, 0.52, 0.22),
    dim: hsl(101, 0.52, 0.31),
    default: hsl(101, 0.73, 0.36),
    lighten: hsl(101, 0.73, 0.42),
  },

  red: {
    mute: hsl(352, 0.59, 0.21),
    dim: hsl(352, 1, 0.29),
    default: hsl(352, 0.59, 0.34),
    lighten: hsl(352, 0.59, 0.45),
  },

  yellow: {
    ...DarkTheme.colors.yellow,
    default: hsl(32, 0.92, 0.52),
    lighten: hsl(32, 0.92, 0.56),
  },

  blue: {
    mute: hsla(217, 0.64, 0.6, 0.1),
    dim: hsla(217, 0.64, 0.6, 0.4),
    default: hsl(217, 0.64, 0.47),
    lighten: hsl(217, 0.64, 0.6),
  },
};
const colors: Colors = merge(cloneDeep(DarkTheme.colors), customcolors);

export const DarkHextrustSidebarTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkHTSidebar',

  fontFamily: "'Poppins', sans-serif",
  fontUrl:
    'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  borderRadiusDefault: 4,
  borderRadiusSmall: 2,
  borderRadiusTiny: 1,
  borderRadiusContent: 7,

  spacingLayout: DarkTheme.baseSize * 0.5,

  borderColorSidebar: colors.gray['000'],
  backgroundSidebar: colors.gray['000'],
  backgroundSidebarItemActive: setAlpha(0.8, colors.primary.default),
  backgroundSidebarItemHover: setAlpha(1, colors.primary.default),

  backgroundHeader: '#001A42',
  borderColorHeader: '#001A42',
});

import { EMPTY_OBJECT, IconName, type FilterableProperty, type StrategyScopeEnum } from '@talos/kyoko';
import { uniqBy } from 'lodash-es';
import { useStrategies } from 'providers/StrategiesProvider';
import { useMemo } from 'react';
/**
 * This hook creates the filter property definition for Strategies properties.
 *
 * @returns Filter Property definition to use for Strategies filters
 */

export function useStrategiesFilter(opts?: { strategyScope?: StrategyScopeEnum }) {
  const { strategiesByName, strategiesList } = useStrategies();
  const { strategyScope } = opts ?? EMPTY_OBJECT;

  return useMemo(() => {
    const filteredStrategies = strategyScope
      ? strategiesList?.filter(s => s.StrategyScope === strategyScope)
      : strategiesList;

    return {
      ...({
        key: 'Strategies',
        label: 'Strategy',
        icon: IconName.DocumentText,
        // Include the option to lookup empty strategies
        options: uniqBy([''].concat((filteredStrategies || []).map(s => s.Name)), (option: string) =>
          option === '' ? ' None' : strategiesByName?.get(option)?.DisplayName || ''
        ),
        getOptionLabel: (option: string) =>
          option === '' ? ' None' : strategiesByName?.get(option)?.DisplayName || '',
      } as const satisfies FilterableProperty),
    };
  }, [strategiesList, strategiesByName, strategyScope]);
}

import { memo, Suspense, useEffect } from 'react';

import { createBrowserRouter, Navigate, Route, RouterProvider, Routes, useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import {
  ACTION,
  AppwideDrawer,
  Dialog,
  Flex,
  HStack,
  LoaderTalos,
  MixpanelEvent,
  MixpanelEventProperty,
  Notes,
  PortalContextProvider,
  useAuthContext,
  useEventListener,
  useGlobalDialog,
  useMixpanel,
  VStack,
} from '@talos/kyoko';
import { ROUTER_CREATE_FUTURE_FLAGS, ROUTER_PROVIDER_FUTURE_FLAGS } from '@talos/kyoko/src/routed';
import lazyWithPrefetch from '@talos/kyoko/src/utils/lazyWithPrefetch';
import { AppLayoutEmptyError } from 'components/AppLayout/AppLayoutEmptyError';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { NavigationSidebar } from 'components/NavigationSidebar';
import { Header } from 'containers/Header';
import { NoMatchPage } from 'containers/NoMatchPage';
import { useFeatureFlag, useRoleAuth } from 'hooks';
import { ContextProvider } from 'providers';
import { useAppLayoutConfig } from 'providers/AppLayoutConfig/AppLayoutConfigContext';
import { getDisplayMode } from 'utils/getDisplayMode';
import { ConnectionMessage } from '../../components/ConnectionMessage';
import { useAdminHeaderContext } from '../../providers/AdminHeaderContext';
import { Unauthorized } from '../Unauthorized';

export const Content = styled(Flex)`
  flex: 1 1 0;
  overflow: hidden;
  background: ${({ theme }) => theme.backgroundBody};
`;

const AdminContainer = lazyWithPrefetch(() => import('containers/Admin'));
const TradingContainer = lazyWithPrefetch(() => import('containers/Trading'));
const PortfolioContainer = lazyWithPrefetch(() => import('containers/Portfolio'));
const PortfolioEngineeringContainer = lazyWithPrefetch(() => import('containers/PortfolioEngineering'));
const DealerContainer = lazyWithPrefetch(() => import('containers/Dealer'));
const AnalyticsContainer = lazyWithPrefetch(() => import('containers/Analytics'));
const MonitoringContainer = lazyWithPrefetch(() => import('containers/Monitoring'));
const SettingsContainer = lazyWithPrefetch(() => import('containers/Settings'));
const OMS = lazyWithPrefetch(() => import('components/OMS/OMS'));
const MarketDataCardsProvider = lazyWithPrefetch(() => import('containers/Trading/MarketDataCardsProvider'));
const PowerSearch = lazyWithPrefetch(() => import('../../components/PowerSearch'));
const CredentialsAgeBanner = lazyWithPrefetch(
  () => import('../../components/CredentialsAgeBanner/CredentialsAgeBanner')
);
const AppLayout = lazyWithPrefetch(() => import('components/AppLayout/AppLayout'));

// Workaround for UI-5968.
// When a new version is deployed, these files will no longer exist, and so
// we prefetch them to avoid the page crashing.
AdminContainer.prefetch();
TradingContainer.prefetch();
PortfolioContainer.prefetch();
PortfolioEngineeringContainer.prefetch();
DealerContainer.prefetch();
AnalyticsContainer.prefetch();
MonitoringContainer.prefetch();
SettingsContainer.prefetch();
OMS.prefetch();
MarketDataCardsProvider.prefetch();
PowerSearch.prefetch();
CredentialsAgeBanner.prefetch();
AppLayout.prefetch();

const TradeMatchRedirect = () => {
  const location = useLocation();
  const newPath = location.pathname.replace('trade', 'trading');
  return <Navigate to={newPath} replace />;
};

const FlexLayoutRoute = () => {
  return (
    <ErrorBoundary message={<AppLayoutEmptyError />}>
      <AppLayout />
      <ConnectionMessage />
    </ErrorBoundary>
  );
};
export const Pages = memo(function Pages() {
  const { isAuthorized } = useRoleAuth();
  const location = useLocation();
  const mixpanel = useMixpanel();

  const path = location?.pathname;
  const section = path.split('/')[1] ?? 'trading';

  useEffect(() => {
    mixpanel.time_event(MixpanelEvent.ViewSection);
    return () => {
      mixpanel.track(MixpanelEvent.ViewSection, { [MixpanelEventProperty.Section]: section });
    };
  }, [section, mixpanel]);

  useEventListener('beforeunload', () => {
    mixpanel.track(MixpanelEvent.ViewSection, { [MixpanelEventProperty.Section]: section });
  });

  const { enableFlexibleLayout } = useAppLayoutConfig();
  const { enableMonitoringBlotters, enableFlexibleUI } = useFeatureFlag();
  const { content, ...dialog } = useGlobalDialog();

  const displayMode = getDisplayMode();
  const useFlexibleUI = enableFlexibleLayout && enableFlexibleUI && ['trading'].includes(section);
  const { showAdminHeader } = useAdminHeaderContext();

  const { backgroundBody, spacingLayout, spacingTiny } = useTheme();

  const layoutSpacing = useFlexibleUI ? 'spacingLayout' : 'spacingTiny';

  return (
    <ErrorBoundary>
      <Dialog {...dialog}>{content}</Dialog>
      <MarketDataCardsProvider>
        <VStack data-testid="app-content" h="100%" alignItems="stretch">
          <Notes mb={layoutSpacing} />
          <PowerSearch />
          <Suspense>
            <CredentialsAgeBanner />
          </Suspense>
          <Content
            gap={layoutSpacing}
            borderTop={
              !showAdminHeader && ['standalone', 'minimal-ui'].includes(displayMode)
                ? `${useFlexibleUI ? spacingLayout : spacingTiny}px solid ${backgroundBody}`
                : 'none'
            }
            transition="border-top-width 2s"
          >
            <NavigationSidebar />
            <VStack w="100%" h="100%" gap={layoutSpacing} overflow="hidden" alignItems="initial">
              <Header />
              <Suspense fallback={<LoaderTalos />}>
                <HStack h="100%" w="100%" gap={layoutSpacing} overflow="hidden" alignItems="initial">
                  <Flex w="100%" flex="1" overflow="hidden">
                    <Routes>
                      <Route path="/" element={<Navigate to="/trading" replace />} />
                      <Route path="/trade" element={<Navigate to="/trading" replace />} />
                      <Route path="/trade/*" element={<TradeMatchRedirect />} />
                      <Route path="/dealer" element={<Navigate to="/dealer/monitoring" replace />} />
                      <Route
                        path="/portfolio-engineering"
                        element={<Navigate to="/portfolio-engineering/rebalance" replace />}
                      />
                      {useFlexibleUI ? (
                        <Route path="/:section/*" element={<FlexLayoutRoute />} />
                      ) : (
                        <Route path="/trading/*" element={<TradingContainer rootPath="trading" />} />
                      )}
                      {isAuthorized(ACTION.SUBMIT_TRANSFER) && (
                        <Route path="/transfers" element={<TradingContainer rootPath="transfers" />} />
                      )}
                      {isAuthorized(ACTION.VIEW_PORTFOLIO) && (
                        <Route path="/portfolio/*" element={<PortfolioContainer />} />
                      )}
                      {isAuthorized(ACTION.EDIT_PORTFOLIO_ENGINEERING) && (
                        <Route path="/portfolio-engineering/*" element={<PortfolioEngineeringContainer />} />
                      )}
                      {isAuthorized(ACTION.VIEW_DEALER) && <Route path="/dealer/*" element={<DealerContainer />} />}
                      {isAuthorized(ACTION.VIEW_ANALYTICS) && (
                        <Route path="/analytics/*" element={<AnalyticsContainer />} />
                      )}
                      <Route path="/settings/*" element={<SettingsContainer />} />
                      <Route path="/monitoring" element={<Navigate to="/monitoring/blotters" replace />} />
                      {enableMonitoringBlotters && <Route path="/monitoring/*" element={<MonitoringContainer />} />}
                      {isAuthorized(ACTION.VIEW_TALOS_ADMIN_SETTINGS) && (
                        <Route path="/admin/*" element={<AdminContainer />} />
                      )}
                      <Route path="*" element={<NoMatchPage />} />
                    </Routes>
                  </Flex>
                  <AppwideDrawer enableFlexibleLayout={enableFlexibleLayout} />
                  <OMS />
                </HStack>
              </Suspense>
            </VStack>
          </Content>
        </VStack>
      </MarketDataCardsProvider>
    </ErrorBoundary>
  );
});

function AuthenticatedPages() {
  return (
    <ContextProvider>
      <PortalContextProvider>
        <Pages />
      </PortalContextProvider>
    </ContextProvider>
  );
}

export const AppRoutesInner = () => {
  const auth = useAuthContext();

  return (
    <>
      <Routes>
        {auth.isAuthenticated && (
          <>
            <Route path="/popout" />
            <Route path="*" element={<AuthenticatedPages />} />
          </>
        )}
        {!auth.isAuthenticated && <Route path="/unauthorized/*" element={<Unauthorized />} />}
      </Routes>
    </>
  );
};

const browserRouter = createBrowserRouter([{ path: '*', element: <AppRoutesInner /> }, {}], {
  future: ROUTER_CREATE_FUTURE_FLAGS,
});

export const AppRoutes = () => <RouterProvider future={ROUTER_PROVIDER_FUTURE_FLAGS} router={browserRouter} />;

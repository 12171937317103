import { createContext, type PropsWithChildren } from 'react';
import { createDispatchHook, createSelectorHook, Provider, type ReactReduxContextValue } from 'react-redux';
import { globalToastStore, type GlobalToastStoreType } from './GlobalToastsStore';

const GlobalToastStoreContext = createContext<ReactReduxContextValue<GlobalToastStoreType> | null>(null as any);
export const GlobalToastStoreProvider = ({ children }: PropsWithChildren) => {
  return (
    <Provider context={GlobalToastStoreContext} store={globalToastStore}>
      {children}
    </Provider>
  );
};

export const useGlobalToastsSelector = createSelectorHook(GlobalToastStoreContext).withTypes<GlobalToastStoreType>();
export const useGlobalToastsDispatch =
  createDispatchHook(GlobalToastStoreContext).withTypes<typeof globalToastStore.dispatch>();

import { isKeyIn } from '../../utils';
import { CURRENCY_COLORS } from './currencyColors';

export function getCurrencyColor(currency: string): string | undefined {
  const colors = CURRENCY_COLORS;
  const lowercaseCurrency = currency.toLowerCase();
  if (isKeyIn(lowercaseCurrency, colors)) {
    return colors[lowercaseCurrency];
  }
  return undefined;
}

import { memo, useCallback, type ReactNode } from 'react';

import {
  AllocationValueTypeEnum,
  Button,
  ButtonGroup,
  ButtonVariants,
  EMPTY_OBJECT,
  FormControlSizes,
  FormMessage,
  Grid,
  HStack,
  IconButton,
  IconName,
  Label,
  MixpanelEvent,
  NumberInput,
  SearchSelect,
  toBigWithDefault,
  ToggleButton,
  Tooltip,
  useDynamicCallback,
  useMixpanel,
  VStack,
  type Allocation,
  type SearchSelectProps,
} from '@talos/kyoko';

import { cloneDeep } from 'lodash-es';
import { useSubAccounts } from 'providers';
import { useSubAccountCreation } from '../OMS/OMSSubAccounts/useSubAccountCreation';

// The contents of these change objects can be improved to contain more information as it becomes necessary.
export type AllocationChange =
  | {
      type: 'modification';
      index: number;
      key: keyof Allocation;
      value: Allocation[keyof Allocation];
    }
  | { type: 'deletion'; index: number }
  | { type: 'addition' };

export interface AllocationsSelectorProps {
  subAccountAllocations: Allocation[];
  allocationValueType?: AllocationValueTypeEnum;

  subAccountOptions: string[];

  disabled?: boolean;

  /**
   * The onChange handler emits two objects. The first represents the new complete state which is equivalent to a basic
   * onChange output where you just set that as your new state in your implementation.
   *
   * The second object is the delta change, so represents what the user actually did exactly. This can be referenced
   * to perform other side-effects, such as updating the default (last used) sub account in the OMSSubAccounts for example.
   */
  onAllocationsChange: (newAllocations: Allocation[], change: AllocationChange) => void;

  onAllocationsValueTypeChange: (newAllocationValueType: AllocationValueTypeEnum) => void;

  touched: { [key: string]: boolean | string };
  errors: { [key: string]: string };

  /** If using allocations, what currency to denominate quantity allocations in. Defaults to just "Qty". */
  quantityCurrency?: string;

  /** Whether or not to use allocations: allow the user to select more than one sub account */
  useAllocations: boolean;

  /** Set to false if you want to hide the +Allocate button. Defaults to true, showing the button. */
  showAddAllocationsButton?: boolean;

  /** Placeholder text to show in each of the search select boxes */
  placeholder?: string;

  /** A string prefix to show before the main label of `Sub Account(s)` */
  titlePrefix?: string;

  /** If provided, will show a tooltip on the label (underline-help-thing) with this content */
  helpTooltip?: ReactNode;
  /**
   * If set, will not show the toggles for switching between Percentage and Quantity AllocationValueTypes.
   * Does not modify or gate the model / values at all, so that responsibility is still on the implementer.
   *
   * Defaults to false.
   */
  hideTypeToggleButtons?: boolean;
  /**
   * If true, will show a create sub account button which the user can use to create a sub account on the spot. Defaults to false.
   */
  showCreateNewSubAccount?: boolean;

  /** If true, will always show the user the number input to the right of the sub account selector. Defaults to false. */
  allowEditingSingleAllocationValue?: boolean;
}

const BUTTON_GROUP_WIDTH = '5rem';

const SEARCH_SELECT_STYLE: React.CSSProperties = { flex: 1 };

const EMPTY_SUB_ACCOUNT_SELECTION = '';

export const AllocationsSelector = memo(function AllocationsSelector({
  subAccountAllocations,
  allocationValueType,
  subAccountOptions,
  disabled = false,
  onAllocationsChange,
  onAllocationsValueTypeChange,
  touched = EMPTY_OBJECT,
  errors = EMPTY_OBJECT,
  quantityCurrency = 'Qty',
  placeholder = '',
  titlePrefix = '',
  helpTooltip,
  useAllocations,
  hideTypeToggleButtons = false,
  showCreateNewSubAccount = false,
  showAddAllocationsButton = true,
  allowEditingSingleAllocationValue = false,
}: AllocationsSelectorProps) {
  const mixpanel = useMixpanel();
  const { subAccountsByName } = useSubAccounts();

  const handleAddAllocation = useCallback(() => {
    onAllocationsChange([...subAccountAllocations, { subAccount: EMPTY_SUB_ACCOUNT_SELECTION, value: '' }], {
      type: 'addition',
    });
  }, [onAllocationsChange, subAccountAllocations]);

  const handleUpdateAllocation = useCallback(
    <K extends keyof Allocation>(index: number, key: K, value: Allocation[K]) => {
      const newAllocations = cloneDeep(subAccountAllocations);
      newAllocations[index][key] = value;
      mixpanel.track(useAllocations ? MixpanelEvent.ChangeAllocations : MixpanelEvent.ChangeSubAccount);
      onAllocationsChange(newAllocations, { type: 'modification', index, key, value });
    },
    [onAllocationsChange, subAccountAllocations, useAllocations, mixpanel]
  );

  const handleDeleteAllocation = useCallback(
    (index: number) => {
      const newAllocations = cloneDeep(subAccountAllocations);
      newAllocations.splice(index, 1);
      mixpanel.track(useAllocations ? MixpanelEvent.ChangeAllocations : MixpanelEvent.ChangeSubAccount);

      // If after deletion we're just left with one allocation, and we dont want to show the number input anymore,
      // then we have to set the value of the now lone allocation to be "100" and Percent
      if (newAllocations.length === 1 && !allowEditingSingleAllocationValue) {
        newAllocations[0].value = '100';
        onAllocationsChange(newAllocations, { type: 'deletion', index });
        onAllocationsValueTypeChange(AllocationValueTypeEnum.Percentage);
      } else {
        onAllocationsChange(newAllocations, { type: 'deletion', index });
      }
    },
    [
      onAllocationsChange,
      subAccountAllocations,
      useAllocations,
      mixpanel,
      onAllocationsValueTypeChange,
      allowEditingSingleAllocationValue,
    ]
  );

  // stable callback so searchselect doesnt have to recalc all items on every update if subAccountsByName
  const getSubAccountLabel = useDynamicCallback((subAccountName?: string) => {
    if (!subAccountName) {
      return '';
    }

    return subAccountsByName?.get(subAccountName)?.DisplayName ?? subAccountName;
  });

  const subAccountCreation = useSubAccountCreation({
    subAccountAllocations,
    handleUpdateAllocation,
  });

  const showNumberInputs = subAccountAllocations.length > 1 || allowEditingSingleAllocationValue;
  const formControlSize = subAccountAllocations.length === 1 ? FormControlSizes.Default : FormControlSizes.Small;

  return (
    <>
      <VStack alignItems="initial">
        <VStack alignItems="initial" gap="spacingSmall" mb="spacingMedium">
          <HStack justifyContent="space-between">
            <Label>
              <Tooltip tooltip={helpTooltip}>
                <div>
                  {titlePrefix}
                  {useAllocations ? 'Sub Account(s)' : 'Sub Account'}{' '}
                </div>
              </Tooltip>
            </Label>
            <HStack gap="spacingSmall">
              {useAllocations && subAccountAllocations?.length !== 1 && !disabled && !hideTypeToggleButtons && (
                <ButtonGroup style={{ width: BUTTON_GROUP_WIDTH }} size={FormControlSizes.Tiny}>
                  <ToggleButton
                    onClick={() => onAllocationsValueTypeChange(AllocationValueTypeEnum.Percentage)}
                    selected={allocationValueType === AllocationValueTypeEnum.Percentage}
                    variant={ButtonVariants.Default}
                    selectedVariant={ButtonVariants.Priority}
                    size={FormControlSizes.Tiny}
                    data-testid="oms-subaccounts-percentage-button"
                  >
                    %
                  </ToggleButton>
                  <ToggleButton
                    onClick={() => onAllocationsValueTypeChange(AllocationValueTypeEnum.Quantity)}
                    selected={allocationValueType === AllocationValueTypeEnum.Quantity}
                    variant={ButtonVariants.Default}
                    selectedVariant={ButtonVariants.Priority}
                    size={FormControlSizes.Tiny}
                    data-testid="oms-subaccounts-quantity-button"
                  >
                    {quantityCurrency}
                  </ToggleButton>
                </ButtonGroup>
              )}
              {useAllocations && showAddAllocationsButton && (
                <Button
                  endIcon={IconName.Plus}
                  size={FormControlSizes.Tiny}
                  onClick={handleAddAllocation}
                  data-testid="add-allocation-button"
                  disabled={disabled}
                >
                  Allocate
                </Button>
              )}
            </HStack>
          </HStack>
          {subAccountAllocations?.map((allocation, index) => (
            <Grid
              key={index}
              data-testid={`allocation-row-${index}`}
              gap="spacingTiny"
              columns={showNumberInputs ? '1fr 1fr auto' : '1fr'}
            >
              {/* When adding new props to this component, if they would cause the search select to rerender each oms render, please memoize them in the wrapper. See jsdoc on the wrapper. */}
              <MemoizedAllocationsSearchSelect
                selectorIndex={index}
                data-testid={`sub-account-select-${index}`}
                placeholder={placeholder}
                options={subAccountOptions}
                selection={allocation.subAccount}
                showClear={allocation.subAccount !== EMPTY_SUB_ACCOUNT_SELECTION}
                disabled={disabled}
                onChange={subAccount =>
                  handleUpdateAllocation(index, 'subAccount', subAccount || EMPTY_SUB_ACCOUNT_SELECTION)
                }
                onClearClick={() => handleUpdateAllocation(index, 'subAccount', EMPTY_SUB_ACCOUNT_SELECTION)}
                initialSortByLabel
                getLabel={getSubAccountLabel}
                style={SEARCH_SELECT_STYLE}
                touched={!!touched.subAccountAllocations}
                size={formControlSize}
                invalid={
                  (allocation.subAccount == null || allocation.subAccount === EMPTY_SUB_ACCOUNT_SELECTION) &&
                  !!errors.subAccountAllocations
                }
                onIsOpenChange={() => subAccountCreation?.setLastTouchedSelector(index)}
                // Only conditionally render the add account button in the dropdown here based on the prop
                dropdownSuffix={
                  showCreateNewSubAccount ? subAccountCreation?.searchSelectProps.dropdownSuffix : undefined
                }
              />
              {showNumberInputs && (
                <>
                  <NumberInput
                    invalid={
                      (allocation.value == null ||
                        allocation.value === '' ||
                        toBigWithDefault(allocation.value, 0).lte(0)) &&
                      !!errors.subAccountAllocations
                    }
                    data-testid={`allocation-value-input-${index}`}
                    disabled={disabled}
                    touched={!!touched.subAccountAllocations}
                    autoComplete="off"
                    onChange={value => handleUpdateAllocation(index, 'value', value)}
                    value={allocation.value?.toString() || ''}
                    size={formControlSize}
                    suffix={
                      allocationValueType === AllocationValueTypeEnum.Percentage
                        ? '%'
                        : allocationValueType === AllocationValueTypeEnum.Quantity
                        ? quantityCurrency
                        : ''
                    }
                  />
                  <IconButton
                    disabled={disabled || subAccountAllocations.length === 1}
                    size={formControlSize}
                    icon={IconName.Trash}
                    onClick={() => handleDeleteAllocation(index)}
                    data-testid="allocation-delete-button"
                  />
                </>
              )}
            </Grid>
          ))}
          {touched.subAccountAllocations && errors.subAccountAllocations && (
            <FormMessage data-testid="oms-subaccounts-error" style={{ justifyContent: 'flex-end' }}>
              {errors.subAccountAllocations}
            </FormMessage>
          )}
        </VStack>
      </VStack>
      {subAccountCreation?.modal}
    </>
  );
});

type MemoizedAllocationsSearchSelectProps = SearchSelectProps<string> & {
  selectorIndex: number;
};

/**
 * Our SearchSelect component doesn't play nice with unstable props. It'll keep re-rendering and making the items
 * in the dropdown result list unresponsive. To get around this, we make specific props here stable.
 */
const MemoizedAllocationsSearchSelect = ({
  selectorIndex,
  onChange,
  onClearClick,
  onIsOpenChange,
  ...props
}: MemoizedAllocationsSearchSelectProps) => {
  const stableOnChange = useDynamicCallback(onChange);
  const stableOnClearClick = useDynamicCallback(onClearClick);
  const stableOnIsOpenChange = useDynamicCallback(onIsOpenChange);

  return (
    <SearchSelect
      data-testid={`sub-account-select-${selectorIndex}`}
      onChange={stableOnChange}
      onClearClick={stableOnClearClick}
      onIsOpenChange={stableOnIsOpenChange}
      {...props}
    />
  );
};

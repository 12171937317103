import {
  ConnectionModeEnum,
  MARKET_SECURITY_STATUS,
  ModeEnum,
  TreasuryLinkTypeEnum,
  useObservableValue,
  useSecuritiesContext,
  useSubscription,
  type MarketSecurityStatus,
} from '@talos/kyoko';
import { compact, flatten, uniq } from 'lodash-es';
import { useMemo } from 'react';
import { map } from 'rxjs';
import { useCustomerSecurities } from '../../../../providers';
import type { TreasuryLinkConstraints } from './shared';

type UseSuggestedTreasuryLinkCurrenciesParams = Pick<TreasuryLinkConstraints, 'marketAccount' | 'type'>;

/**
 * This hook takes in some Treasury Link constraints and returns suggested currencies the user might want to add treasury links for.
 */
export const useSuggestedTreasuryLinkCurrencies = ({
  type,
  marketAccount,
}: UseSuggestedTreasuryLinkCurrenciesParams) => {
  const { securitiesBySymbol } = useSecuritiesContext();

  const loadMarketAccountSecurities =
    type === TreasuryLinkTypeEnum.ExchangeRebalance || type === TreasuryLinkTypeEnum.OTCSettlement;

  const conditionalRequest = loadMarketAccountSecurities
    ? {
        name: MARKET_SECURITY_STATUS,
        tag: 'portfolio/addresses',
        Markets: [marketAccount], // only load securities for the given market account
      }
    : null;

  const { data: marketAccountSecuritiesObs } = useSubscription<MarketSecurityStatus>(conditionalRequest);
  const marketAccountSecurities = useObservableValue(
    () => marketAccountSecuritiesObs.pipe(map(response => response.data)),
    [marketAccountSecuritiesObs]
  );

  const customerSecurities = useCustomerSecurities();
  const enabledCustomerCurrencies = useMemo(() => {
    if (customerSecurities == null || type !== TreasuryLinkTypeEnum.CustomerTransfer) {
      return [];
    }
    const enabledSymbols = compact(
      customerSecurities.filter(sec => sec.Mode === ModeEnum.Enabled).map(sec => securitiesBySymbol.get(sec.Symbol))
    );

    return uniq(flatten(enabledSymbols?.map(symbol => [symbol.BaseCurrency, symbol.QuoteCurrency])));
  }, [customerSecurities, securitiesBySymbol, type]);

  const enabledPrincipalCurrencies = useMemo(() => {
    if (
      !marketAccountSecurities ||
      ![TreasuryLinkTypeEnum.ExchangeRebalance, TreasuryLinkTypeEnum.OTCSettlement].includes(type)
    ) {
      return [];
    }

    const uniqueCurrencySet = new Set(
      marketAccountSecurities
        .filter(sec => sec.Enabled === ConnectionModeEnum.Up)
        .map(sec => securitiesBySymbol.get(sec.Symbol))
        .flatMap(sec => (sec == null ? [] : [sec.BaseCurrency, sec.QuoteCurrency]))
    );

    return [...uniqueCurrencySet];
  }, [marketAccountSecurities, type, securitiesBySymbol]);

  // Depending on the type of links we're setting up, we either inject customer currencies or currencies with balances at the given mkt acc
  const currenciesToSuggest =
    type === TreasuryLinkTypeEnum.CustomerTransfer ? enabledCustomerCurrencies : enabledPrincipalCurrencies;

  // Return the currencies to suggest but sorted
  return useMemo(() => currenciesToSuggest.sort(), [currenciesToSuggest]);
};

import { AnimatePresence, motion } from 'framer-motion';
import type { ToastAppInboxProps, ToastProps, ToastPropsBase } from 'hooks/Toast/useToasts';
import { useEffect, useState } from 'react';
import { Box, type BoxProps } from '../Core/Box';
import { Flex } from '../Core/Flex';
import { AppInboxToast, ToastSimple } from '../Notification/AppInboxToast';
import { ToastInternal } from './ToastInternal';

export type ToastsProps<T extends ToastPropsBase> = {
  toasts: T[];
  direction?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  remove: (id: string) => void;
  /** Maximum number of toasts before it gets limited, by default, unlimited toasts are shown */
  stackingLimit?: number;
  /** Container to display toasts, driving width behavior */
  container?: 'global' | 'local';
  /** Width of the toast lists in question (content will wrap if needed)
   * - width is (currently) critical for framer-layout's popLayout behavior  to not cause the layout to slide out of view
   */
  width?: string;
};

function isInternalToast(toast: ToastProps | ToastAppInboxProps): toast is ToastProps {
  return 'text' in toast;
}

const GLOBAL_TOAST_WIDTH = 290;
/** Area (typically, dialog-level) Toasts container for the useToasts hook */
export function Toasts<T extends ToastProps | ToastAppInboxProps>({
  width,
  toasts,
  remove,
  stackingLimit,
  direction = 'column',
  container = 'local',
}: ToastsProps<T>) {
  const [preventStacking, setPreventStacking] = useState(false);
  const totalToasts = toasts.length;
  useEffect(() => {
    if (totalToasts === 0) {
      setPreventStacking(false);
    }
  }, [totalToasts]);

  const isStackGroupShown = stackingLimit && toasts.length > stackingLimit - 1 && !preventStacking;

  if (isStackGroupShown) {
    toasts = [...toasts].slice(-stackingLimit + 1);
  }
  return (
    <Box w={width}>
      <AnimatePresence initial={false}>
        <motion.div
          layout="position"
          style={{ width: '100%', display: 'flex', gap: '4px', flexDirection: direction }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, layout: { delay: 0, duration: 0.1 } }}
        >
          <AnimatePresence mode="popLayout">
            {isStackGroupShown && (
              <OverflowToast
                overflowCount={totalToasts - toasts.length}
                onClick={event => {
                  event?.preventDefault();
                  setPreventStacking(true);
                }}
              />
            )}
            {toasts.map((toast, index) => {
              return (
                <motion.div
                  layout="position"
                  key={toast.id}
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  style={{ width: '100%' }}
                  transition={{ duration: 0.2, layout: { delay: 0, duration: 0.1 } }}
                >
                  {isInternalToast(toast) ? (
                    <ToastInternal
                      boxProps={{
                        w: container === 'local' ? width : GLOBAL_TOAST_WIDTH,
                      }}
                      {...toast}
                      onRemove={() => (toast.id ? remove?.(toast.id) : undefined)}
                    />
                  ) : (
                    <AppInboxToast
                      {...toast}
                      wrapperWidth={GLOBAL_TOAST_WIDTH}
                      containerVariant="toast"
                      onRemove={() => {
                        return toast.id ? remove?.(toast.id) : undefined;
                      }}
                    />
                  )}
                </motion.div>
              );
            })}
          </AnimatePresence>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
}

function OverflowToast({ overflowCount, onClick }: { overflowCount: number; onClick: BoxProps['onClick'] }) {
  return (
    <motion.div
      layout="position"
      key="stacking-indicator"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: GLOBAL_TOAST_WIDTH }}
      onClick={onClick}
    >
      <ToastSimple justifyContent="center">
        <Flex fontSize="fontSizeMd" style={{ fontSize: '0.75rem' }}>{`+${overflowCount} more`}</Flex>
      </ToastSimple>
    </motion.div>
  );
}

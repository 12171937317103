import {
  FilterClauseType,
  HedgeControlStatusEnum,
  HedgeDecisionEnum,
  IconName,
  filterExistsAndExcludes,
  removeEmptyFilters,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type FilterClause,
  type FilterableProperty,
  type HedgeOrderStatus,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { useIDFilter } from 'hooks';
import { isEqual, keys, pick, values } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';

export interface HedgeOrderStatusFilter {
  InitiatingOrderID?: string;
  HedgeControlStatuses?: HedgeControlStatusEnum[];
  HedgeDecisions?: HedgeDecisionEnum[];
}

export interface UseHedgeOrderStatusFilterParams<TData extends HedgeOrderStatus> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
  permanentFilters: BlotterTableFilter;
}
export const useHedgeOrderStatusFilter = function useHedgeOrderStatusFilter<TData extends HedgeOrderStatus>({
  persistedBlotterTable,
  permanentFilters,
}: UseHedgeOrderStatusFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [filter, setFilter] = useState<HedgeOrderStatusFilter>((permanentFilters as HedgeOrderStatusFilter) || {});

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      Object.assign(newFilter, permanentFilters);
      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, permanentFilters, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<HedgeOrderStatus>>(
    row => {
      const data = row.data;
      if (filterExistsAndExcludes(filter, 'HedgeControlStatuses', data, 'HedgeControlStatus')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'HedgeDecisions', data, 'HedgeDecision')) {
        return false;
      }

      return true;
    },
    [filter]
  );

  const idFilter = useIDFilter();

  const filterableProperties: FilterableProperty[] = useMemo<FilterableProperty[]>(
    () => [
      {
        key: 'HedgeControlStatuses',
        label: 'Hedge Control Status',
        icon: IconName.CheckCircle,
        options: values(HedgeControlStatusEnum),
        getOptionLabel: (option: string) => option,
      },
      {
        key: 'HedgeDecisions',
        label: 'Hedge Decisions',
        icon: IconName.CheckCircle,
        options: values(HedgeDecisionEnum),
        getOptionLabel: (option: string) => option,
      },
      {
        ...idFilter,
        key: 'InitiatingOrderID',
        label: 'Initiating Order ID',
      },
    ],
    [idFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      keys(filter).forEach(key => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: filter[key],
        });
      });
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: HedgeOrderStatusFilter = removeEmptyFilters<HedgeOrderStatusFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => {
              let selections: undefined | string | string[];
              switch (key) {
                case 'InitiatingOrderID':
                  // below are exact search and only one value is supported by backend
                  selections = filterClausesByPropertyKey.get(key)?.selections?.[0];
                  break;
                default:
                  selections = filterClausesByPropertyKey.get(key)?.selections;
              }
              return [key, selections];
            })
          ) satisfies HedgeOrderStatusFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  return {
    permanentFilters,
    filter,
    clientSideFilter,
    changeFilter,
    filterBuilderProps,
  };
};

const colIDToFilterBuilderKeyMap = {
  HedgeControlStatus: 'HedgeControlStatuses',
  HedgeDecision: 'HedgeDecisions',
} satisfies Partial<Record<keyof HedgeOrderStatus, keyof HedgeOrderStatusFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof HedgeOrderStatusFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}

export function onlyServerFilterKeys(filter?: HedgeOrderStatusFilter) {
  return filter == null ? filter : pick(filter, ['InitiatingOrderID']);
}

import type { Column, GridApi, IHeaderParams, IRowNode, ModelUpdatedEvent } from 'ag-grid-community';
import { isGridApiReady } from 'components/BlotterTable/utils';
import { throttle } from 'lodash-es';
import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from '../../hooks';
import { WarningSeverity } from '../../types/WarningSeverity';
import { safeGridApi } from '../BlotterTable/utils';
import { HStack } from '../Core';
import { ICON_SIZES, Icon, IconName } from '../Icons';
import { WarningSeverityIcon, prettyWarningSeverityTitle } from '../Icons/WarningSeverityIcon';

export const AgGridWarningHeader = ({ progressSort, column, api }: IHeaderParams) => {
  const intl = useIntl();
  const onSortRequested: React.MouseEventHandler<HTMLDivElement> = useCallback(
    e => {
      progressSort(e.shiftKey);
    },
    [progressSort]
  );
  const [currentSort, setCurrentSort] = useState(() => column.getSort());

  const [warningSeverity, setWarningSeverity] = useState<WarningSeverity>(WarningSeverity.NONE);

  // Updating headers based on warningSeverity if included in column data.
  const onHandleModelUpdate = useRef(
    throttle((e: ModelUpdatedEvent<{ warningSeverity: WarningSeverity }>) => {
      // Because of the throttling here, there is evidence of this api being nulled by the time the callback runs (Blotter instance deleted).
      // We handle this by returning early if the api doesnt exist for whatever reason.
      if (!isGridApiReady(api)) {
        return;
      }

      let highestSeverity = WarningSeverity.NONE;
      api.forEachNodeAfterFilterAndSort(node => {
        const currWarningSeverity = tryGetWarningSeverityFromNode(api, node, column);
        if (currWarningSeverity && currWarningSeverity > highestSeverity) {
          highestSeverity = currWarningSeverity;
          // Since we found highest possible warning severity, we can quit early.
          if (highestSeverity === WarningSeverity.HIGH) {
            return;
          }
        }
      });
      setWarningSeverity(highestSeverity);
    }, 300)
  );

  useEffect(() => {
    const handleModelUpdated = (e: ModelUpdatedEvent) => onHandleModelUpdate.current && onHandleModelUpdate.current(e);
    safeGridApi(api)?.addEventListener('modelUpdated', handleModelUpdated);
    return () => {
      safeGridApi(api)?.removeEventListener('modelUpdated', handleModelUpdated);
    };
  }, [api]);

  useEffect(() => {
    const columnSortChanged = () => {
      setCurrentSort(column.getSort());
    };
    safeGridApi(api)?.addEventListener('sortChanged', columnSortChanged);
    return () => {
      safeGridApi(api)?.removeEventListener('sortChanged', columnSortChanged);
    };
  }, [api, column]);

  return (
    <HStack h="100%" alignItems="center" justifyContent="center" w="100%" onClick={onSortRequested}>
      {currentSort == null && warningSeverity !== WarningSeverity.NONE && (
        <WarningSeverityIcon
          data-testid="warning-severity-header-icon"
          severity={warningSeverity}
          title={prettyWarningSeverityTitle(warningSeverity, intl)}
        />
      )}
      {currentSort && (
        <Icon icon={currentSort === 'desc' ? IconName.ArrowDown : IconName.ArrowUp} size={ICON_SIZES.MEDIUM} />
      )}
    </HStack>
  );
};

// This interface is to be extended by implementers of the warning column type
export interface WarningValue {
  severity: WarningSeverity;
}

// This function grabs a warningSeverity off of the data on the node if it exists, otherwise tries to grab a severity
// from the value of the node + WARNING_COLID column
export function tryGetWarningSeverityFromNode(
  api: GridApi,
  node: IRowNode,
  column: Column
): WarningSeverity | undefined {
  if (node.data?.warningSeverity != null) {
    return node.data.warningSeverity;
  }

  // Try safely to see if we can get a proper .severity off the valueGetter of the column
  const maybeWarningValue: Partial<WarningValue> | null | undefined = api.getCellValue({
    colKey: column.getColId(),
    rowNode: node,
  });
  if (maybeWarningValue != null && maybeWarningValue.severity != null) {
    return maybeWarningValue.severity;
  }

  return undefined;
}

import type { IRowNode } from 'ag-grid-community';
import Big from 'big.js';
import { get } from 'lodash-es';
import type { DefaultTheme } from 'styled-components';
import { numericComparator, orderStatusTextToColor, toBigWithDefault, type Leaves } from '../../../utils';
import type { AgGridMeterProps } from '../../AgGrid/AgGridMeter';
import { getOrderStatusText, type OrderLike } from '../../Status/OrderStatus';
import type { ColDefFactory, Column } from '../columns/types';
import { baseColumn } from './baseColumn';

export type FilledPercentColumnParams<T = any> = {
  /** The filled part of the bar, required */
  filledQtyField: Leaves<T>;
  /** The total "length" of the bar, required */
  totalQtyField: Leaves<T>;
  /** Given the node, get the color */
  getColor?: (rowNode: IRowNode<T>, theme: DefaultTheme) => string | undefined;
} & Partial<Pick<AgGridMeterProps, 'tooltip' | 'showLabel'>>;

export interface FilledPercentColumnValue {
  value: string;
  color: string | undefined;
}

export const filledPercent: ColDefFactory<Column<FilledPercentColumnParams>> = column => ({
  ...baseColumn(column),
  cellRenderer: 'meterColumn',
  cellRendererParams: {
    showInitialAnimation: false,
    ...column.params,
  },
  menuTabs: [],
  minWidth: 120,
  maxWidth: 480,
  flex: 1,

  // Todo: calculating this percentage below is somewhat heavy. Instead, these percentage calcs should be performed earlier and stored in the data entity so it doesnt have to be recalced in here
  valueGetter: ({ data, api, node, context }): FilledPercentColumnValue => {
    const filledQty = column.params?.filledQtyField ? get(data, column.params.filledQtyField) : undefined;
    const totalQty = column.params?.totalQtyField ? get(data, column.params.totalQtyField) : undefined;

    const filledQtyBig = toBigWithDefault(filledQty, 0);
    const totalQtyBig = toBigWithDefault(totalQty, 0);

    let value = '0';
    if (!totalQtyBig.eq('0') && !filledQtyBig.eq('0')) {
      const percent = filledQtyBig.div(totalQtyBig);
      if (percent.gt('0.99') && filledQtyBig.lt(totalQtyBig)) {
        // Ensure that we don't show '100.00%' when the order isn't fully filled
        // by adding extra precision and rounding down between 99% and 100%
        value = percent.toFixed(4, Big.roundDown);
      } else if (filledQtyBig.gt(0) && percent.lt('0.01')) {
        // Ensure that we don't show '0.00%' when the order is partially filled
        value = percent.toFixed(4, Big.roundUp);
      } else {
        value = percent.toFixed(4);
      }
    }

    const color = node && context.current.theme ? column.params?.getColor?.(node, context.current.theme) : undefined;

    return {
      value,
      color,
    };
  },
  comparator: (valueA, valueB) => {
    return numericComparator(valueA.value, valueB.value);
  },
});

/** When using the Filled Percent column for order types, this function can be used to derive the correct color */
export function getOrderStatusColor(order: OrderLike | undefined, theme: DefaultTheme) {
  // Just to make things simpler on usage, we handle the undefined order case in here. It shouldnt happen.
  if (!order) {
    return undefined;
  }

  const text = getOrderStatusText({
    type: 'orderLike',
    order: order,
  });

  return orderStatusTextToColor(text, theme);
}

import type Big from 'big.js';
import { compact } from 'lodash-es';
import { parseDate, toBig, toBigWithDefault } from '../utils';
import { ExposureTypeEnum, getExposureID, type IUniformExposure } from './Exposure';
import { WarningSeverity } from './WarningSeverity';
import { ExposureLimitSideTypeEnum, ExposureStatusEnum } from './types';

/**
 * A CreditBlotterExposure is an entity reflecting one or more combined underlying Exposures or MarketExposures, doing
 * its best to provide the developer with abstractions in order to more easily consume the underlying data.
 */
export class CreditBlotterExposure {
  // All these properties here are shared among individual exposure sides (both, long, short)
  Counterparty!: string;
  Market?: string;
  MarketAccount!: string;
  Currency?: string;
  ExposureCurrency!: string;
  ExposureDefinition!: string;
  // Exposure and Market Exposure will never be stitched together due to how the model works, so this is fine:
  type!: ExposureTypeEnum;

  both?: IUniformExposure;
  short?: IUniformExposure;
  long?: IUniformExposure;

  private get sides() {
    return compact([this.both, this.long, this.short]);
  }

  /**
   * Looks at the attached exposure sides on this entity and returns
   * the one which is to be regarded as in effect (where there is a non-zero exposure),
   * and returns all metadata associated with that exposure
   */
  get effectiveSide() {
    const nonZeroEffectiveExposure = this.sides.find(
      side => side.exposure != null && !toBigWithDefault(side.exposure, 0).eq(0)
    );

    if (nonZeroEffectiveExposure) {
      return nonZeroEffectiveExposure;
    }

    // We didnt find a non-zero effective exposure. Let's just take the first one we come across instead.
    return this.sides.find(side => side != null);
  }

  /** Grabs effectiveSide.exposure but flips short exposures to be negative numbers */
  get effectiveExposureWithSign() {
    if (!this.effectiveSide) {
      return '';
    }

    if (this.effectiveSide.ExposureSide === ExposureLimitSideTypeEnum.Short) {
      const shortExposureBig = toBig(this.effectiveSide.exposure);
      return shortExposureBig ? shortExposureBig.times(-1).toFixed() : '';
    }

    return this.effectiveSide.exposure;
  }

  get effectiveRemainingCredit() {
    if (!this.effectiveSide) {
      return '';
    }

    return this.effectiveSide.usage?.remainder?.toFixed();
  }

  /** Grabs effectiveSide.exposure but flips short limits to be negative numbers */
  get effectiveLimitWithSign() {
    if (!this.effectiveSide) {
      return '';
    }

    if (this.effectiveSide.ExposureSide === ExposureLimitSideTypeEnum.Short) {
      const shortLimitBig = toBig(this.effectiveSide.limit);
      return shortLimitBig ? shortLimitBig.times(-1).toFixed() : '';
    }

    return this.effectiveSide.limit;
  }

  get creditUsages() {
    return {
      bothUsage: this.both?.usage,
      shortUsage: this.short?.usage,
      longUsage: this.long?.usage,
    };
  }

  get rowID() {
    return getExposureID(this);
  }

  get treePath() {
    if (this.type === ExposureTypeEnum.Exposure) {
      return [this.MarketAccount];
    }

    return [this.MarketAccount, this.rowID];
  }

  get text() {
    return compact(this.sides.map(side => side.Text)).join(', ');
  }

  get status() {
    const anyOfflineSide = this.sides.find(side => side.Status === ExposureStatusEnum.Offline);
    return anyOfflineSide ? ExposureStatusEnum.Offline : ExposureStatusEnum.Online;
  }

  /** The latest timestamp */
  get timestamp() {
    const timestamps = compact(this.sides.map(side => side.Timestamp)).map(parseDate);
    return timestamps.sort().at(-1)?.toISOString(); // grab the last one, which is the most recent
  }

  get creditUsageWarning(): CreditUsageWarning | undefined {
    const effectiveUsage = this.effectiveSide?.usage;
    if (!effectiveUsage) {
      return undefined;
    }

    const severity = effectiveUsage.usage.gte(0.99)
      ? WarningSeverity.HIGH
      : effectiveUsage.usage.gte(0.9)
      ? WarningSeverity.MEDIUM
      : undefined;

    if (severity == null) {
      return undefined;
    }

    return {
      usage: effectiveUsage.usage,
      severity,
      currency: effectiveUsage.currency,
    };
  }

  get warningSeverity(): WarningSeverity | undefined {
    return this.creditUsageWarning?.severity;
  }

  static Create(inputs: IUniformExposure[], type: ExposureTypeEnum): CreditBlotterExposure | undefined {
    if (inputs.length === 0) {
      return undefined;
    }

    const [firstInput, ...restOfInputs] = inputs;
    const newCBE = new CreditBlotterExposure(firstInput, type);
    restOfInputs.forEach(input => newCBE.enrichWithSide(input));
    return newCBE;
  }

  clone(): CreditBlotterExposure | undefined {
    return CreditBlotterExposure.Create(compact(this.sides), this.type);
  }

  enrichWithSide(data: IUniformExposure) {
    if (data.ExposureSide === ExposureLimitSideTypeEnum.Both) {
      this.both = data;
    } else if (data.ExposureSide === ExposureLimitSideTypeEnum.Short) {
      this.short = data;
    } else if (data.ExposureSide === ExposureLimitSideTypeEnum.Long) {
      this.long = data;
    }
  }

  getExposureSide(side: ExposureLimitSideTypeEnum): IUniformExposure | undefined {
    switch (side) {
      case ExposureLimitSideTypeEnum.Both:
        return this.both;
      case ExposureLimitSideTypeEnum.Long:
        return this.long;
      case ExposureLimitSideTypeEnum.Short:
        return this.short;
      default:
        return undefined;
    }
  }

  constructor(input: IUniformExposure, type: ExposureTypeEnum) {
    Object.assign(this, input);
    this.type = type;
    this.enrichWithSide(input);
  }
}

export interface CreditUsageWarning {
  currency?: string;
  usage: Big;
  severity: WarningSeverity;
}

export function isCreditUsageWarning(value: any): value is CreditUsageWarning {
  return value != null && 'usage' in value && 'severity' in value;
}

export function getOppositeExposureSide(side: ExposureLimitSideTypeEnum): ExposureLimitSideTypeEnum | undefined {
  if (side === ExposureLimitSideTypeEnum.Short) {
    return ExposureLimitSideTypeEnum.Long;
  }

  if (side === ExposureLimitSideTypeEnum.Long) {
    return ExposureLimitSideTypeEnum.Short;
  }

  // both doesnt have an other side
  return undefined;
}

import {
  BlotterTable,
  Box,
  Button,
  ButtonVariants,
  columnTypes,
  createCSVFileName,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  Divider,
  EXPANDABLE_HEADER_HEIGHT,
  filterByCellValueMenuItem,
  filterByColumnMainMenuItems,
  FormControlSizes,
  HStack,
  Icon,
  IconButton,
  IconName,
  Input,
  selectAll,
  useAggDeltaUpdatesPipe,
  useBlotterTable,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useMixpanel,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  VStack,
  type Leaves,
  type MinimalSubscriptionResponse,
  type RowGroupsOpenedState,
} from '@talos/kyoko';
import type {
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridOptions,
} from 'ag-grid-community';
import { compact } from 'lodash-es';
import { useEffect, useMemo } from 'react';

import { map, type Observable } from 'rxjs';
import { colIDToFilterBuilderKey as positionsColIDToFilterBuilderKey } from '../../../Blotters/PositionsV3/types';
import { AsOfDateBadge } from '../../components/AsOfDateBadge';
import { usePortfolioViewStateSelector } from '../../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import { useOperationsOverviewFilters } from '../providers/OperationsOverviewFiltersProvider';
import { useOperationsOverviewInteractions } from '../providers/OperationsOverviewInteractionsProvider';
import { useOperationsOverviewPositions } from '../providers/OperationsOverviewPositionsProvider';
import type { OpsPosition } from '../types';
import {
  useOperationsOverviewBlotterColumns,
  type OperationsOverviewBlotterColumnSpecification,
} from './useOperationsOverviewBlotterColumns';

const ABOVE_BLOTTER_MENU_ROW_HEIGHT = '40px';

export const aggSpecs = [
  { valuePath: 'Equivalent.Amount', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'balanceEquivalent', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'talosEquityEquivalent', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.UnrealizedPnL', currencyPath: 'Equivalent.Currency' },
] satisfies { valuePath: Leaves<OpsPosition>; currencyPath: Leaves<OpsPosition> }[];

function getPositionKey(position: OpsPosition) {
  return position.rowID;
}

interface OperationsOverviewBlotterProps {
  blotterID: string;
  defaultColumns: OperationsOverviewBlotterColumnSpecification[];
  defaultRowGroupsOpened: RowGroupsOpenedState;
}

export const OperationsOverviewBlotter = ({
  blotterID,
  defaultColumns,
  defaultRowGroupsOpened,
}: OperationsOverviewBlotterProps) => {
  const mixpanel = useMixpanel();
  const { opsOverviewShowByOld } = usePortfolioViewStateSelector();
  const columns = useOperationsOverviewBlotterColumns(defaultColumns, opsOverviewShowByOld);

  const { filterableProperties } = useOperationsOverviewFilters();
  const { openClause, setGoToGroupRow } = useOperationsOverviewInteractions();

  const { positionsDataObs } = useOperationsOverviewPositions();
  const dataObs: Observable<MinimalSubscriptionResponse<OpsPosition>> = useMemo(() => {
    return positionsDataObs.pipe(
      map(positions => ({
        initial: true,
        data: positions,
      }))
    );
  }, [positionsDataObs]);

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams<OpsPosition>) => {
    if (openClause) {
      return filterByColumnMainMenuItems({
        params,
        colIDToFilterBuilderKey: positionsColIDToFilterBuilderKey,
        openClause,
        mixpanel,
      });
    }

    return [];
  });

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems: GetContextMenuItems<OpsPosition> = useDynamicCallback(
    (params: GetContextMenuItemsParams) => {
      const items = [selectAll(params, mixpanel), 'separator', ...getDefaultContextMenuItems(params)];

      if (openClause) {
        items.unshift(
          ...filterByCellValueMenuItem({
            params,
            filterableProperties,
            openClause,
            colIDToFilterBuilderKey: positionsColIDToFilterBuilderKey,
            mixpanel,
          })
        );
      }

      return compact(items);
    }
  );

  const persisted = usePersistedBlotterTable<OpsPosition>(blotterID, {
    persistFilter: false, // this is handled elsewhere in operations overview
    columns,
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const pinnedRowDataPipe = useAggDeltaUpdatesPipe({
    getUniqueKey: getPositionKey,
    aggSpecs: aggSpecs,
  });

  const gridOptions: GridOptions<OpsPosition> = useMemo(
    () => ({
      ...persisted.gridOptionsOverlay,
      ...persistedRowGroupsOpened.gridOptionsOverlay,
      autoGroupColumnDef: columnTypes.group({
        type: 'group',
        title: 'Grouping',
        editable: false,
        hide: false,
        width: 300,
        suppressColumnsToolPanel: false,
        params: {
          suppressCount: true,
        },
      }),
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems,
      groupDisplayType: 'singleColumn',
      showOpenedGroup: true,
      // sticky group rows becomes too much for this blotter with our level of indentation. Both performance-wise (saw some big lags) and how much it flickered. AgGrid needs to work more on this one.
      suppressGroupRowsSticky: true,
      // FYI: this property below is used in conjunction with the concept of grouping in such a way that leaf nodes are always _single children_ in their group
      // This is because we group on _all_ properties in the rowID. Try disabling this and see the change if you're confused.
      groupRemoveLowestSingleChildren: true,
    }),
    [persisted.gridOptionsOverlay, getContextMenuItems, persistedRowGroupsOpened.gridOptionsOverlay]
  );

  const blotter = useBlotterTable<OpsPosition>({
    dataObservable: dataObs,
    pinnedRowDataPipe: pinnedRowDataPipe,
    showPinnedRows: true,
    rowID: 'rowID' satisfies keyof OpsPosition,
    getExtraMainMenuItems,
    columns: persisted.columns,
    persistence: persisted,
    gridOptions,
  });

  const { expandAllGroups, collapseAllGroups, highlightGroupRow } = blotter;

  useEffect(() => {
    setGoToGroupRow(() => highlightGroupRow);
  }, [setGoToGroupRow, highlightGroupRow]);

  const handleExport = useDynamicCallback(() => {
    blotter.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Operations - Overview',
      }),
    });
  });

  return (
    <Box h={`calc(100% - ${EXPANDABLE_HEADER_HEIGHT}px)`}>
      <HStack
        data-testid="treasury-management-blotter-controls"
        justifyContent="space-between"
        gap="spacingComfortable"
        px="spacingDefault"
        h={ABOVE_BLOTTER_MENU_ROW_HEIGHT}
      >
        <AsOfDateBadge snapshotDate={null} /> {/* live */}
        <HStack justifyContent="flex-end" w="100%" gap="spacingSmall">
          <Input
            prefix={<Icon icon={IconName.Search} />}
            clearable={true}
            size={FormControlSizes.Small}
            width="120px"
            value={blotter.blotterTableFiltersProps.quickFilterText}
            onChange={e => blotter.blotterTableFiltersProps.onQuickFilterTextChanged(e.target.value)}
          />
          <Divider orientation="vertical" mx="spacingSmall" />
          <IconButton
            icon={IconName.ListExpand}
            size={FormControlSizes.Small}
            variant={ButtonVariants.Default}
            onClick={expandAllGroups}
            data-testid="expand-all-button"
          />
          <IconButton
            icon={IconName.ListCollapse}
            size={FormControlSizes.Small}
            variant={ButtonVariants.Default}
            onClick={collapseAllGroups}
          />
          <Divider orientation="vertical" mx="spacingSmall" />
          <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
            Export
          </Button>
        </HStack>
      </HStack>
      <Divider />
      <VStack h={`calc(100% - ${ABOVE_BLOTTER_MENU_ROW_HEIGHT})`} w="100%">
        <Box px="spacingDefault" flex="1" w="100%">
          <BlotterTable {...blotter} />
        </Box>
      </VStack>
    </Box>
  );
};

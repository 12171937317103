import { useCallback, type RefObject } from 'react';
import { useEvent } from 'react-use';
import { DROPDOWN_PORTAL_ID } from '../components/Form/Dropdown/tokens';
import { useCurrentDocument } from '../providers/CurrentDocumentProvider';
import { useDynamicCallback } from './useDynamicCallback';

export const useOnClickOutside = <T extends HTMLElement>(
  ref: RefObject<T | null>,
  handler?: (e: MouseEvent) => void
) => {
  const currentDocument = useCurrentDocument();

  const isClickingOutsideRef = useDynamicCallback((e: MouseEvent) => {
    // we ignore any clicks in an autocomplete dropdown
    // at some later time we can make this functionality more flexible. Maybe allow the user of this hook to pass a list of refs to check against.
    const autocompleteDropdown = currentDocument.getElementById(DROPDOWN_PORTAL_ID);
    if (autocompleteDropdown?.contains(e.target as Node)) {
      return false;
    }

    // normal case
    return !ref.current || !ref.current.contains(e.target as Node);
  });

  const onClick = useCallback(
    (e: any) => {
      if (isClickingOutsideRef(e)) {
        handler?.(e);
      }
    },
    [isClickingOutsideRef, handler]
  );

  useEvent('mousedown', handler ? onClick : null, currentDocument);
};

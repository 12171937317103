import { uniq } from 'lodash-es';
import { toBigWithDefault } from '../utils';
import { type LedgerUpdateTypeEnum, SubAccountReconMatchStatusEnum } from './types';

export class SubAccountReconMatch {
  ID!: string;
  Asset!: string;
  CheckpointID!: string;
  LastUpdateTime!: string;
  EventType!: LedgerUpdateTypeEnum;
  Status!: SubAccountReconMatchStatusEnum;
  TransactTime!: string;
  TradeID!: string;

  Comments?: string[];

  /** An array of the sub accounts (name) represented in this ReconMatch */
  subAccounts: string[];

  get breakAmount() {
    const marketAccountEventAmount = toBigWithDefault(this.MarketAccountEvent?.Amount, 0);
    const subAccountEventsAmount = toBigWithDefault(this.SubAccountEvents?.Amount, 0);
    return subAccountEventsAmount.minus(marketAccountEventAmount).toFixed();
  }

  get hasBreak() {
    return !toBigWithDefault(this.breakAmount, 0).eq(0);
  }

  /** Whether or not this recon match is resolvable */
  get resolvable() {
    return this.hasBreak && this.Status !== SubAccountReconMatchStatusEnum.Resolved;
  }

  MarketAccountEvent?: {
    Amount: string;
    AvgCost: string;
    AvgCostCurrency: string;
    Market: string;
    MarketAccount: string;
    Comments?: string;
  };

  SubAccountEvents?: {
    Amount: string;
    Events?: {
      Amount: string;
      AvgCost: string;
      AvgCostCurrency: string;
      SubAccount: string;
      Comments?: string;
    }[];
  };

  constructor(data: SubAccountReconMatch) {
    Object.assign(this, data);
    this.subAccounts = uniq((this.SubAccountEvents?.Events ?? []).map(event => event.SubAccount));
  }
}

export class SubAccountReconMatchHistory extends SubAccountReconMatch {
  Revision: number;
  UpdateUser: string;
  HistoryAction: string;
  HistoryComment: string;
  HistoryDescription: string;

  get rowID() {
    return `${this.ID}-${this.Revision}`;
  }

  constructor(mh: SubAccountReconMatchHistory) {
    super(mh);
    this.Revision = mh.Revision;
    this.UpdateUser = mh.UpdateUser;
    this.HistoryAction = mh.HistoryAction;
    this.HistoryDescription = mh.HistoryDescription;
    this.HistoryComment = mh.HistoryComment;
  }
}

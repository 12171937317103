import { useTransition } from '@react-spring/web';
import Big from 'big.js';
import { useMemo, type ReactNode } from 'react';
import { getFractions } from '../../utils/relativeSize';
import { HStack } from '../Core';
import { Bar, BarPart, Container, Headers, Label, SectionContainer, SectionHeader, SectionWrapper } from './styles';

export interface DistributionPart {
  id: string;
  value: Big;
  color: string;
  appearance?: 'filled' | 'striped';
}

export interface DistributionNamedSection {
  id: string;
  header: ReactNode;
  parts: DistributionPart[];
}

export interface DistributionBarProps {
  sections: DistributionNamedSection[];
  roundLeft?: boolean;
  roundRight?: boolean;
  direction?: 'ltr' | 'rtl';
  showHeaders?: boolean;
  showRemainder?: boolean;
  showLabel?: boolean;
  label?: ReactNode;
  size?: 'small' | 'default';
}

export const DistributionBar = ({
  sections,
  roundLeft = true,
  roundRight = true,
  direction = 'ltr',
  showHeaders = true,
  showRemainder = false,
  showLabel = false,
  label,
  size = 'default',
}: DistributionBarProps) => {
  const total = useMemo(
    () =>
      sections.reduce(
        (prev, curr) => prev.plus(curr.parts.reduce((prev, curr) => curr.value.plus(prev), new Big(0))),
        new Big(0)
      ),
    [sections]
  );
  const sectionsWithRemainder = useMemo(() => {
    if (!showRemainder || total.eq(1)) {
      return sections;
    }

    return [
      ...sections,
      {
        id: 'remainder',
        header: 'Remainder',
        parts: [
          {
            id: 'remainder',
            value: Big(1).minus(total),
            color: 'var(--backgroundProgressBar)',
          },
        ],
      },
    ];
  }, [sections, showRemainder, total]);

  // compute the size of each section
  const sectionSizes = getFractions(
    sectionsWithRemainder.map(section => section.parts.reduce((prev, curr) => prev.plus(curr.value), Big(0)))
  );

  // Assume here that the color of a part is unique, which does make sense!
  const transitions = useTransition(sectionsWithRemainder, {
    keys: section => section.id,
    initial: (section, i) => ({ flexBasis: getWidth(i, sectionSizes) }),
    from: { flexBasis: '0%' },
    enter: (section, i) => ({ flexBasis: getWidth(i, sectionSizes) }),
    update: (section, i) => ({ flexBasis: getWidth(i, sectionSizes) }),
    leave: { flexBasis: '0%' },
    config: {
      duration: 200,
    },
  });

  return (
    <HStack h="100%" w="100%">
      <Container>
        {showHeaders && (
          <Headers direction={direction}>
            {sections.map((section, i) => {
              const sectionWidth = getWidth(i, sectionSizes);
              return (
                <SectionWrapper key={i} style={{ flexBasis: sectionWidth, alignSelf: 'flex-end' }}>
                  <SectionHeader> {section.header} </SectionHeader>
                </SectionWrapper>
              );
            })}
          </Headers>
        )}
        <Bar roundLeft={roundLeft} roundRight={roundRight} direction={direction} size={size}>
          {transitions((transition, item) => (
            <SectionWrapper style={transition}>
              <BarSection parts={item.parts} size={size} />
            </SectionWrapper>
          ))}
        </Bar>
      </Container>
      {showLabel && <Label>{label ?? `${total.times(100).toFixed(2)}%`}</Label>}
    </HStack>
  );
};

function BarSection({ parts, size }: { parts: DistributionPart[]; size: 'small' | 'default' }) {
  const partSizes = getFractions(parts.map(part => part.value));

  const transitions = useTransition(parts, {
    keys: part => part.id,
    initial: (part, i) => ({ width: getWidth(i, partSizes) }),
    from: { width: '0%' },
    enter: (part, i) => ({ width: getWidth(i, partSizes) }),
    update: (part, i) => ({ width: getWidth(i, partSizes) }),
    leave: { width: '0%' },
    config: {
      duration: 200,
    },
  });

  return (
    <SectionContainer>
      {transitions((transition, item) => (
        <BarPart
          color={item.color}
          appearance={item.appearance}
          size={size}
          style={{
            ...transition,
          }}
        />
      ))}
    </SectionContainer>
  );
}

const getWidth = (i: number, widths: number[] | undefined) => {
  if (!widths) {
    return '100%';
  }
  // We always want to round up such that there are no super small gaps after rounding
  // widths to one decimal point.
  return Math.ceil(widths[i] * 100 * 10) / 10 + '%';
};

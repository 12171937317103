import {
  HISTORICAL_POSITION_STAT_AS_OF,
  Position,
  formattedDateForSubscription,
  useObservable,
  useSubscription,
  type HistoricalPositionStatAsOf,
} from '@talos/kyoko';
import { compact, isEqual } from 'lodash-es';
import { useEffect, useState } from 'react';
import { map } from 'rxjs';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { customEODToRequestEOD } from '../../../Blotters/PositionsV3/SubAccounts/useSubAccountPositionsBlotterRequest';
import { POSITIONS_BLOTTER_CONVERSION_TOLERANCE } from '../../../Blotters/PositionsV3/tokens';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import { PRODUCTS, type PerfSubAccountPositionsRequest } from '../types';

export const useHistoricalPositionStatAsOfSub = () => {
  const { customEOD, homeCurrency } = useDisplaySettings();
  const {
    state: { snapshotDate, pnlLookbacks, filter, subAccounts, showZeroBalances, product },
  } = usePerformanceContext();

  const [request, setRequest] = useState<PerfSubAccountPositionsRequest | null>(null);

  useEffect(() => {
    if (snapshotDate == null) {
      return;
    }

    setRequest(currentRequest => {
      const maybeNewRequest = {
        name: HISTORICAL_POSITION_STAT_AS_OF,
        tag: 'portfolio/performance-blotter',
        EquivalentCurrency: homeCurrency,
        Date: formattedDateForSubscription(snapshotDate),
        EndOfDay: customEODToRequestEOD(customEOD),
        PnLLookbacks: pnlLookbacks,
        SubAccounts: subAccounts,
        ShowZeroBalances: showZeroBalances,
        AssetTypes: PRODUCTS[product].productTypes,
        Tolerance: POSITIONS_BLOTTER_CONVERSION_TOLERANCE,
        ...filter,
      };

      return isEqual(currentRequest, maybeNewRequest) ? currentRequest : maybeNewRequest;
    });
  }, [homeCurrency, snapshotDate, filter, pnlLookbacks, customEOD, subAccounts, showZeroBalances, product]);

  const { data } = useSubscription<HistoricalPositionStatAsOf>(request);

  const mappedPositionsObs = useObservable(
    () =>
      data.pipe(
        map(json => {
          return {
            ...json,
            data: compact(json.data.map(stat => new Position({ ...stat, MarketAccount: '', PositionSource: '' }))),
          };
        })
      ),
    [data]
  );

  return mappedPositionsObs;
};

import {
  ACTION,
  BlotterTable,
  BlotterTableExtrasMenu,
  Button,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  formattedDateAtMinutes,
  FormControlSizes,
  IconName,
  InlineFormattedNumberContext,
  NumberVariants,
  Portal,
  useBlotterTable,
  useBlotterTableExtrasMenu,
  useDisclosure,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  usePersistedBlotterTable,
  type Column,
  type ColumnDef,
  type StringArrayColumnParams,
} from '@talos/kyoko';
import type {
  ColDef,
  GetContextMenuItemsParams,
  ICellRendererParams,
  MenuItemDef,
  RowClassParams,
  RowDoubleClickedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { compact } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useFeatureFlag, useRoleAuth } from '../../../../../hooks';
import { AddCommentDialog } from './AddCommentDialog';
import {
  RECON_MATCH_PROVIDER_TALOS,
  ReconMatchMarketAccountRow,
  ReconMatchSubAccountRow,
  type ReconMatchRow,
} from './reconMatchRows';
import { BlotterStylesWrapper } from './styles';
import {
  RECON_MATCHES_BLOTTER_PORTAL_ID,
  type BreakDetailsBlotterTabProps,
  type OpenResolutionDrawerFunc,
} from './types';
import { useReconMatchesObs } from './useReconMatchesObs';

interface BreakDetailsBlotterProps {
  tab: BreakDetailsBlotterTabProps;
  checkpointID: string;
  openResolutionDrawer: OpenResolutionDrawerFunc;
  showAllDecimals?: boolean;
}

function getRowClass(params: RowClassParams<ReconMatchRow>) {
  const data = params.node.data;
  return data instanceof ReconMatchMarketAccountRow ? 'row-lighten' : undefined;
}

export const BreakDetailsBlotter = ({
  tab,
  openResolutionDrawer,
  checkpointID,
  showAllDecimals = false,
}: BreakDetailsBlotterProps) => {
  const { enableIBORAuditTrail } = useFeatureFlag();
  const { isAuthorized } = useRoleAuth();
  const isAuthorizedToEdit = isAuthorized(ACTION.EDIT_PORTFOLIO_RECON);
  const addCommentDialog = useDisclosure();
  const { open: openAddCommentDialog } = addCommentDialog;

  const [selectedMatch, setSelectedMatch] = useState<ReconMatchRow>();

  const dataObservable = useReconMatchesObs(tab.defaultFilter, checkpointID);
  const columns = useReconMatchesColumns(openResolutionDrawer);

  const persisted = usePersistedBlotterTable<ReconMatchRow>(`${tab.id}/break-details-blotter`, {
    columns,
  });

  const handleAddComment = useCallback(
    (row: ReconMatchRow) => {
      setSelectedMatch(row);
      openAddCommentDialog();
    },
    [openAddCommentDialog]
  );

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams<ReconMatchRow>) => {
      const data = params.node?.data;
      const items: (MenuItemDef | string)[] = getDefaultContextMenuItems(params);

      if (data != null && enableIBORAuditTrail) {
        items.unshift('separator');
        items.unshift({
          name: 'Show Audit Log',
          action: () => openResolutionDrawer(data, 'MatchHistory'),
          icon: `<i class="ag-icon ${IconName.ViewList}"/>`,
        });
      }

      if (isAuthorizedToEdit && data != null) {
        items.unshift('separator');
        items.unshift({
          name: 'Add Comment',
          action: () => handleAddComment(data),
          icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
        });
      }

      return items;
    },
    [getDefaultContextMenuItems, isAuthorizedToEdit, handleAddComment, openResolutionDrawer, enableIBORAuditTrail]
  );

  const onRowDoubleClicked = useCallback(
    (event: RowDoubleClickedEvent<ReconMatchRow>) => {
      if (!isAuthorizedToEdit) {
        return;
      }
      if (event.node.data != null) {
        openResolutionDrawer(event.node.data);
      }
    },
    [openResolutionDrawer, isAuthorizedToEdit]
  );

  const blotterTable = useBlotterTable<ReconMatchRow>({
    dataObservable,
    rowID: 'rowID' satisfies keyof ReconMatchRow,
    columns: persisted.columns,
    persistence: persisted,
    gridOptions: {
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      suppressRowTransform: true,
      getRowClass,
      getContextMenuItems,
      onRowDoubleClicked,
    },
  });

  const handleExport = useDynamicCallback(() => {
    blotterTable.exportDataAsCSV({
      fileName: `${tab.label} - ${formattedDateAtMinutes(new Date())}.csv`,
    });
  });

  const extrasMenu = useBlotterTableExtrasMenu();

  return (
    <>
      <Portal portalId={RECON_MATCHES_BLOTTER_PORTAL_ID}>
        <BlotterTableExtrasMenu dataTestID="recon-breaks-extras-menu" {...extrasMenu}>
          <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
            Export
          </Button>
        </BlotterTableExtrasMenu>
      </Portal>
      <InlineFormattedNumberContext.Provider value={useMemo(() => ({ showAllDecimals }), [showAllDecimals])}>
        <BlotterStylesWrapper h="100%" data-testid="break-details-blotter">
          <BlotterTable {...blotterTable} />
        </BlotterStylesWrapper>
      </InlineFormattedNumberContext.Provider>
      <AddCommentDialog match={selectedMatch} {...addCommentDialog} />
    </>
  );
};

// This callback will expand any invocations from ReconMatchSubAccountRow nodes to be 2 rows tall.
const doubleHeightRowSpanFn: ColDef<ReconMatchRow>['rowSpan'] = params => {
  const data: ReconMatchRow | undefined = params.node?.data;
  return data instanceof ReconMatchSubAccountRow ? 2 : 1;
};

// We need a way to distinguish double-height cells such that we can then apply special css rules to them.
const doubleHeightRowSpanClassRules: ColDef<ReconMatchRow>['cellClassRules'] = {
  'cell-span': params => {
    const data: ReconMatchRow | undefined = params.node?.data;
    return data instanceof ReconMatchSubAccountRow;
  },
};

const doubleHeightColDefProps = {
  cellClassRules: doubleHeightRowSpanClassRules,
  rowSpan: doubleHeightRowSpanFn,
} as const;

const useReconMatchesColumns = (openResolutionDrawer: OpenResolutionDrawerFunc) => {
  const { isAuthorized } = useRoleAuth();
  const isAuthorizedToResolveBreaks = isAuthorized(ACTION.EDIT_PORTFOLIO_RECON);

  const showResolveColumn = isAuthorizedToResolveBreaks;

  const columns: Column[] = useMemo(() => {
    return compact([
      {
        sortable: true,
        ...doubleHeightColDefProps,
        type: 'text',
        field: 'EventType',
        title: 'Ledger Event',
        description: 'Description of the IBOR ledger event for the transaction.',
        width: 120,
      },
      {
        sortable: true,
        ...doubleHeightColDefProps,
        type: 'asset',
        field: 'Asset',
        title: 'Instrument',
        params: {
          colorful: true,
        },
        hide: true,
        description: 'Financial instrument for the transaction.',
      },
      {
        sortable: true,
        ...doubleHeightColDefProps,
        type: 'reconMatchStatus',
        field: 'Status',
        description:
          'Status of the transaction (Unmatched, Matched, Resolved, etc.) This field includes comments entered by the user for Resolved transactions.',
      },
      {
        sortable: true,
        title: 'Transact Time',
        field: 'TransactTime',
        ...doubleHeightColDefProps,
        type: 'date',
        params: {
          milliseconds: true,
        },
        description: 'Transaction time for the IBOR ledger event.',
        width: 150,
      },
      {
        sortable: true,
        ...doubleHeightColDefProps,
        title: 'Break Amount',
        type: 'size',
        field: 'breakAmount',
        params: {
          currencyField: 'Asset' satisfies keyof ReconMatchRow,
          getSentiment: (params: ICellRendererParams<ReconMatchRow>) => {
            return params.node.data?.hasBreak ? NumberVariants.Warning : undefined;
          },
        },
        description: 'Amount of the break, in instrument terms.',
      },
      {
        sortable: true,
        ...doubleHeightColDefProps,
        title: 'Sub Account(s)',
        field: 'subAccounts',
        type: 'stringArray',
        params: {
          getDisplayName: (value, context) => {
            return context.current.subAccountsByName?.get(value)?.DisplayName ?? value;
          },
        } satisfies StringArrayColumnParams,
        description: 'Sub Account(s) to which the ledger event has been allocated.',
      },
      {
        sortable: true,
        ...doubleHeightColDefProps,
        title: 'Market Account',
        field: 'marketAccount',
        type: 'marketAccount',
        description: "External venue's Market Account where the transaction has occurred.",
      },
      {
        sortable: false,
        title: 'Provider',
        field: 'provider',
        width: 120,
        // Need to make this a custom column since the provider is either a Market.Name or "Talos"
        // You could just use the Market column and rely on "Talos" not getting a hit in the marketsByName lookup but
        // that seems hacky... I prefer this.
        type: 'custom',
        params: {
          valueFormatter: ({ value, context }: ValueFormatterParams<ReconMatchRow, ReconMatchRow['provider']>) => {
            if (value == null) {
              return '';
            }

            // value is either "Talos" or it is a Market.Name.
            if (value === RECON_MATCH_PROVIDER_TALOS) {
              return value;
            }

            return context.current.marketDisplayNameByName?.get(value) ?? value;
          },
        },
        description: 'Lists the provider of IBOR data (Talos) and the provider of the market account data.',
      },
      {
        sortable: false,
        title: 'Amount',
        type: 'size',
        field: 'amount',
        params: {
          currencyField: 'Asset' satisfies keyof ReconMatchRow,
        },
        description: 'Transaction amount, in instrument terms.',
      },
      {
        sortable: false,
        title: 'Avg Cost',
        type: 'price',
        field: 'avgCost',
        params: {
          quoteCurrencyField: 'avgCostCurrency' satisfies keyof ReconMatchRow,
        },
        description: 'Average cost recorded of the IBOR ledger event.',
      },
      {
        sortable: false,
        title: 'Comments',
        type: 'textArray',
        field: 'comments',
        description: 'System- and user-generated comments (if applicable).',
      },
      showResolveColumn && {
        sortable: false,
        type: 'button',
        id: 'resolve-match-column',
        suppressColumnsToolPanel: true,
        pinned: 'right',
        frozen: true,
        ...doubleHeightColDefProps,
        width: 70,
        params: {
          onClick: (params: ICellRendererParams<ReconMatchRow>) => {
            const data = params.node.data;
            if (data instanceof ReconMatchSubAccountRow) {
              openResolutionDrawer(data);
            }
          },
          disabled: params => {
            const match: ReconMatchRow | undefined = params.node.data;
            if (!match) {
              return true;
            }

            // we disable the resolve button if the match cannot be resolved
            return !match.resolvable;
          },

          size: FormControlSizes.Small,
          children: 'Resolve',
        },
      },
    ]) satisfies ColumnDef<ReconMatchRow>[];
  }, [openResolutionDrawer, showResolveColumn]);

  return columns;
};

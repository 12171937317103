import { useMemo } from 'react';
import type { Column } from '../../BlotterTable';
import type { BaseEntityAdminProps, EntityAdminRecord } from '../types';
import { EntityAdmin } from './EntityAdmin';

export interface EntityAdminRESTProps<
  TRecord extends EntityAdminRecord,
  TDrawerRecord extends EntityAdminRecord = TRecord
> extends Omit<BaseEntityAdminProps<TRecord, TDrawerRecord>, 'allowPauseResume'> {
  /** The initial path for the REST API endpoint. */
  path: string;

  /** The number of records to paginate until */
  blotterRowCount?: number;

  /** The number of records to paginate at a time */
  paginationSize?: number;

  /**
   * @deprecated Do not use this method - it is for /admin/dev-blotter only.
   * Callback function when the columns are ready. */
  onColumnsReady?: (columns: Column<unknown, TRecord>[]) => void;
}

/**
 * EntityAdminREST lets you display and interact with entities from a REST API endpoint.
 * The header, tabs, filters, and the blotter are all included and can be customized.
 * @param path "/api-endpoint"
 */
export const EntityAdminREST = <TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord = TRecord>(
  props: EntityAdminRESTProps<TRecord, TDrawerRecord>
) => {
  return useMemo(() => <EntityAdmin<TRecord, TDrawerRecord> {...props} />, [props]);
};

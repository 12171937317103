import {
  getMarketSecurityStatusKey,
  MARKET_SECURITY_STATUS,
  useObservable,
  useObservableValue,
  useStaticSubscription,
  useSubscription,
  wsScanToDoubleMap,
  wsScanToMap,
  wsSubscriptionCache,
  type MarketSecurityStatus,
  type MinimalSubscriptionResponse,
} from '@talos/kyoko';
import { createContext, useContext, type PropsWithChildren } from 'react';
import { map, shareReplay, type Observable } from 'rxjs';

export interface MarketSecurityStatusesContextProps {
  marketSecurityStatusesByKeyObs: Observable<Map<string, MarketSecurityStatus>>;
  marketSecurityStatusesListObs: Observable<MarketSecurityStatus[]>;
  marketSecurityStatusesByMarketAccountSymbolObs: Observable<Map<string, Map<string, MarketSecurityStatus>>>;
  marketSecurityStatusesCache: Observable<MinimalSubscriptionResponse<MarketSecurityStatus>>;
}

const MarketSecurityStatusesContext = createContext<MarketSecurityStatusesContextProps | null>(null);
MarketSecurityStatusesContext.displayName = 'MarketSecurityStatusesContext';

export function useMarketSecurityStatuses() {
  const context = useContext(MarketSecurityStatusesContext);
  if (context == null) {
    throw new Error('Missing MarketSecurityStatusesContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const MarketSecurityStatusesProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { data } = useStaticSubscription<MarketSecurityStatus>({
    name: MARKET_SECURITY_STATUS,
    tag: 'MarketSecurityStatusesProvider',
  });

  // You can use this cache to populate blotters. It emits SubscriptionResponse<MarketSecurityStatus> messages as if
  // they're coming from the backend directly, while preventing subscribe timing race conditions
  const marketSecurityStatusesCache = useObservable(
    () => data.pipe(wsSubscriptionCache(mss => getMarketSecurityStatusKey(mss.MarketAccount, mss.Symbol))),
    [data]
  );

  const marketSecurityStatusesByKeyObs = useObservable(
    () =>
      marketSecurityStatusesCache.pipe(
        wsScanToMap({
          getUniqueKey: mss => getMarketSecurityStatusKey(mss.MarketAccount, mss.Symbol),
          newMapEachUpdate: true,
        }),
        shareReplay({ bufferSize: 1, refCount: true })
      ),
    [marketSecurityStatusesCache]
  );

  const marketSecurityStatusesByMarketAccountSymbolObs = useObservable(
    () =>
      marketSecurityStatusesByKeyObs.pipe(
        map(mssByKey => {
          const map = new Map<string, Map<string, MarketSecurityStatus>>();
          for (const mss of mssByKey.values()) {
            const innerMap = map.get(mss.MarketAccount) ?? new Map();
            innerMap.set(mss.Symbol, mss);
            map.set(mss.MarketAccount, innerMap);
          }
          return map;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketSecurityStatusesByKeyObs]
  );

  const marketSecurityStatusesListObs = useObservable(
    () =>
      marketSecurityStatusesByKeyObs.pipe(
        map(byKeyMap => [...byKeyMap.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketSecurityStatusesByKeyObs]
  );

  return (
    <MarketSecurityStatusesContext.Provider
      value={{
        marketSecurityStatusesByKeyObs,
        marketSecurityStatusesListObs,
        marketSecurityStatusesByMarketAccountSymbolObs,
        marketSecurityStatusesCache,
      }}
    >
      {children}
    </MarketSecurityStatusesContext.Provider>
  );
};

/** Given a Symbol, will open a subscription to the backend to get all MarketSecurityStatuses records for this symbol
 *
 * Returns a Map of MSS records by Market Account (because we're filtered on Symbol, there'll only be one entry per Market Account)
 */
export const useMSSForSymbolByAccount = ({ symbol, tag }: { symbol: string | undefined; tag: string }) => {
  const { data: marketSecurityStatusesObs } = useSubscription<MarketSecurityStatus>(
    symbol ? { name: MARKET_SECURITY_STATUS, tag, Symbols: [symbol] } : null
  );

  return useObservableValue(
    () =>
      marketSecurityStatusesObs.pipe(
        wsScanToMap({ getUniqueKey: item => item.MarketAccount, newMapEachUpdate: true }),
        shareReplay({ bufferSize: 1, refCount: true })
      ),
    [marketSecurityStatusesObs]
  );
};

/**
 * Given an array of Symbols, will open a subscription to the backend for all MarketSecurityStatuses for these symbols
 *
 * Returns a double map Map<MarketAccount.Name, Map<Security.Symbol, MarketSecurityStatus>>
 */
export const useMSSByAccountBySymbol = ({
  symbols,
  tag,
  makeQuery: inputMakeQuery = true,
}: {
  symbols: string[] | undefined;
  tag: string;
  /** Set to false to conditionally not make this query. Defaults to true. */
  makeQuery?: boolean;
}): Map<string, Map<string, MarketSecurityStatus>> | undefined => {
  const makeQuery = inputMakeQuery && symbols && symbols.length > 0;

  const { data: marketSecurityStatusesObs } = useSubscription<MarketSecurityStatus>(
    makeQuery ? { name: MARKET_SECURITY_STATUS, tag, Symbols: symbols } : null
  );

  return useObservableValue(
    () =>
      marketSecurityStatusesObs.pipe(
        wsScanToDoubleMap({
          getKey1: item => item.MarketAccount,
          getKey2: item => item.Symbol,
          newInnerMapsEachUpdate: true,
          newOuterMapEachUpdate: true,
        }),
        shareReplay({ bufferSize: 1, refCount: true })
      ),
    [marketSecurityStatusesObs]
  );
};

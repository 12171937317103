import {
  Box,
  Flex,
  FormControlSizes,
  Icon,
  IconName,
  IndicatorBadge,
  IndicatorBadgeVariants,
  MixpanelEvent,
  MixpanelEventProperty,
  Popover,
  useDisclosure,
  useMixpanel,
  usePopoverState,
  usePortal,
} from '@talos/kyoko';
import type { IDockviewDefaultTabProps } from 'dockview';
import { useCallback } from 'react';
import { v1 as uuid } from 'uuid';
import { useLayoutContext } from '../providers/LayoutContextProvider';
import { TabMenu, TabMenuItem, TabWrapper } from '../styles';
import { MAIN_GROUP_ID, POPOUT_URL } from '../tokens';
import { RemoveTabDialog } from './RemoveTabDialog';
import { RenameTabDialog } from './RenameTabDialog';

export const DefaultTabComponent = (
  props: IDockviewDefaultTabProps & {
    canDuplicate?: boolean;
  }
) => {
  const notificationsCount = 0; // TODO: Get notifications count
  const mixpanel = useMixpanel();
  const { enablePopoutWindows, onPopoutGroup, disallowLastCloseMode } = useLayoutContext();
  const isPopout = props.api.location.type === 'popout';
  const canPopout = enablePopoutWindows && !isPopout;

  // Get the popout window if the tab is in a popout window
  const popoutWindow = props.api.location.type === 'popout' ? props.api.location.getWindow() : undefined;
  const portalElement = popoutWindow?.document.body ?? document.body;

  const renameTabDialog = useDisclosure();
  const removeTabDialog = useDisclosure();

  const popover = usePopoverState({
    trigger: '',
    noPaddingAndBorder: true,
    closeOnClickOutside: true,
    usePortal: true,
    placement: 'bottom-start',
    popoverDocument: popoutWindow?.document,
  });

  let disableClose = props.params.readOnly || props.hideClose;
  if (!disableClose) {
    if (disallowLastCloseMode === 'lastTabInContainer') {
      const visibleGroups = props.containerApi.groups.filter(g => g.isVisible);
      disableClose = visibleGroups.length === 1 && props.api.group.isVisible && props.api.group.panels.length === 1;
    } else {
      // Don't allow closing the last tab in the main group
      disableClose = props.api.group.id === MAIN_GROUP_ID && props.api.group.panels.length === 1;
    }
  }

  const onConfirmRemove = useCallback(() => {
    props.api.close();
    mixpanel.track(MixpanelEvent.CloseTab, {
      [MixpanelEventProperty.TabType]: props.api.component,
    });
  }, [props.api, mixpanel]);

  const onClose = useCallback(() => {
    if (disableClose) {
      return;
    }

    popover.close();
    if (props.params.readOnly || props.hideClose) {
      return;
    }
    removeTabDialog.open();
  }, [props, popover, disableClose, removeTabDialog]);

  const onConfirmRename = useCallback(
    (title: string) => {
      if (title) {
        props.api.setTitle(title);
        mixpanel.track(MixpanelEvent.RenameTab, {
          [MixpanelEventProperty.TabType]: props.api.component,
        });
      }
    },
    [props.api, mixpanel]
  );

  const openRenameDialog = useCallback(() => {
    popover.close();
    renameTabDialog.open();
  }, [popover, renameTabDialog]);

  const duplicateTab = useCallback(() => {
    props.containerApi.addPanel({
      component: props.api.component,
      title: `${props.api.title} (copy)`,
      params: props.params,
      id: uuid(),
      position: {
        referenceGroup: props.api.group,
      },
    });
    mixpanel.track(MixpanelEvent.DuplicateTab, {
      [MixpanelEventProperty.TabType]: props.api.component,
    });
    popover.close();
  }, [props, mixpanel, popover]);

  const openMenu = useCallback(
    (e: React.MouseEvent) => {
      if (e.metaKey) {
        return;
      }
      mixpanel.track(MixpanelEvent.OpenTabMenu, {
        [MixpanelEventProperty.TabType]: props.api.component,
      });
      popover.open();
      e.preventDefault();
    },
    [popover, mixpanel, props.api.component]
  );

  const handlePopout = useCallback(() => {
    popover.close();
    const panelInstance = props.api.group.panels.find(p => p.id === props.api.id);
    if (panelInstance == null) {
      return;
    }
    props.containerApi.addPopoutGroup(panelInstance, {
      popoutUrl: POPOUT_URL,
      onDidOpen: ({ window: popoutWindow, id }) => {
        onPopoutGroup(props.api.group.api.id, popoutWindow);
        mixpanel.track(MixpanelEvent.PopoutLayoutPanel, {
          [MixpanelEventProperty.PanelType]: props.api.component,
        });
      },
      onWillClose: ({ id }) => {
        onPopoutGroup(props.api.group.api.id, undefined);
        mixpanel.track(MixpanelEvent.ClosePopout, {
          [MixpanelEventProperty.PanelType]: props.api.component,
        });
      },
    });
  }, [props, onPopoutGroup, popover, mixpanel]);

  const tabMenuPortalId = `tabMenu_${props.api.id}`;
  const { setPortalRef } = usePortal(tabMenuPortalId);

  return (
    <>
      <Popover {...popover} portalElement={portalElement} targetStyle={POPOVER_TARGET_STYLE}>
        <TabWrapper
          data-testid={`${props.api.id}-layout-tab`}
          onContextMenu={openMenu}
          onDoubleClick={openRenameDialog}
          onAuxClick={e => {
            if (e.button === 1) {
              onClose();
            }
          }}
        >
          <Box>{props.api.title}</Box>
          {notificationsCount && notificationsCount > 0 ? (
            <IndicatorBadge children={notificationsCount} variant={IndicatorBadgeVariants.Notification} />
          ) : null}
          {disableClose ? null : (
            <Box className="dv-default-tab-action" data-testid="close-tab-button">
              <Icon icon={IconName.Close} onClick={onClose} size={12} />
            </Box>
          )}
        </TabWrapper>
        <TabMenu data-testid="tab-menu">
          <TabMenuItem size={FormControlSizes.Small} startIcon={IconName.PencilField} onClick={openRenameDialog}>
            Rename
          </TabMenuItem>
          {(props.canDuplicate ?? true) && (
            <TabMenuItem size={FormControlSizes.Small} startIcon={IconName.Duplicate} onClick={duplicateTab}>
              Duplicate
            </TabMenuItem>
          )}
          {canPopout && (
            <TabMenuItem size={FormControlSizes.Small} startIcon={IconName.ExternalLink} onClick={handlePopout}>
              Popout
            </TabMenuItem>
          )}
          {/* Portal target for adding custom tab menu items based on the panel content */}
          <Flex flexDirection="column" ref={setPortalRef} />
          {disableClose ? null : (
            <TabMenuItem size={FormControlSizes.Small} startIcon={IconName.Trash} onClick={onClose}>
              Remove
            </TabMenuItem>
          )}
        </TabMenu>
      </Popover>
      <RenameTabDialog
        key={`rename_${renameTabDialog.isOpen.toString()}`}
        initialTitle={props.api.title}
        onConfirm={onConfirmRename}
        portalElement={portalElement}
        {...renameTabDialog}
      />
      <RemoveTabDialog
        key={`remove_${popover.isOpen.toString()}`}
        panel={props}
        onConfirm={onConfirmRemove}
        portalElement={portalElement}
        {...removeTabDialog}
      />
    </>
  );
};

const POPOVER_TARGET_STYLE = {
  height: '100%',
};

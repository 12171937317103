import {
  ACTION,
  AccordionRow,
  AccordionRowBody,
  AccordionRowBodyCell,
  AccordionRowCell,
  AccordionRowHeader,
  Box,
  Button,
  ButtonVariants,
  Dialog,
  Flex,
  FormControlSizes,
  IconName,
  useAccordion,
  useDisclosure,
} from '@talos/kyoko';
import { useRoleAuth } from 'hooks';

import { useMemo } from 'react';
import type { Aggregation } from 'types';
import { useAggregationMarkets } from '../../../hooks/useAggregationRequests';
import { AggregationMarketsTable } from './AggregationMarketsTable';
import { getAggregationMarketRowID } from './utils';

export function AggregationAccordion({
  aggregation,
  onDeleteAggregation,
  onEditAggregation,
}: {
  aggregation: Aggregation;
  onDeleteAggregation: (name: string) => void;
  onEditAggregation: (a: Aggregation) => void;
}) {
  const accordion = useAccordion({ id: aggregation.Name });
  const aggregationMarkets = useAggregationMarkets(aggregation.Name);

  const aggregationMarketsWithRowID = useMemo(
    () => aggregationMarkets.map(am => ({ ...am, RowID: getAggregationMarketRowID(am) })),
    [aggregationMarkets]
  );

  const { isAuthorized } = useRoleAuth();
  const deleteAggregationDialog = useDisclosure();

  return (
    <AccordionRow {...accordion}>
      <AccordionRowHeader>
        <AccordionRowCell>{aggregation.DisplayName}</AccordionRowCell>
        <AccordionRowCell>{aggregation.Name}</AccordionRowCell>
        <AccordionRowCell>
          <Flex justifyContent="flex-end">
            <Button
              startIcon={IconName.Pencil}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={e => {
                e.stopPropagation();
                onEditAggregation(aggregation);
              }}
              disabled={!isAuthorized(ACTION.DEALER_TRADING)}
              data-testid="edit-aggregation-display-name-button"
            >
              Edit Display Name
            </Button>
          </Flex>
        </AccordionRowCell>
      </AccordionRowHeader>
      <AccordionRowBody>
        <tr>
          <AccordionRowBodyCell colSpan={100}>
            <Box pt="spacingDefault" pb="spacingDefault">
              {accordion.isOpenVisually && (
                <AggregationMarketsTable
                  aggregation={aggregation}
                  aggregationMarkets={aggregationMarketsWithRowID}
                  onDeleteAggregation={() => deleteAggregationDialog.open()}
                />
              )}
            </Box>
          </AccordionRowBodyCell>
        </tr>
      </AccordionRowBody>
      <Dialog
        {...deleteAggregationDialog}
        title="Delete Aggregation"
        onConfirm={() => onDeleteAggregation(aggregation.Name)}
        confirmLabel="Delete Aggregation"
        showClose={true}
        variant={ButtonVariants.Negative}
      >
        <Box pt="spacingLarge" pb="spacingLarge">
          Are you sure you want to delete aggregation &quot;{aggregation.DisplayName}&quot;?
        </Box>
      </Dialog>
    </AccordionRow>
  );
}

import { BALANCES_V2_BLOTTER_PREFIX } from 'containers/Blotters/BalancesV2/tokens';
import { TREASURY_BLOTTER_PREFIX } from 'containers/Portfolio/TreasuryManagement/tokens';
import { camelCase, forEach } from 'lodash-es';

/**
 * For 2.41, "MarketAccountName", "MarketType" and "MarketAccountGroup" properties on the Balance entity were camel cased.
 * This also impacts columns stored in blotter column storage as well as tab column storage (for cloned tabs)
 * Look through and camel case all instances
 * Works on both the balances blotter and treasury blotter
 * @param config
 */
export function renamePersistedBalancesBlotterColumns(config: any) {
  const blotterTabs: object | undefined = config?.blotters2;
  if (blotterTabs == null) {
    return;
  }

  forEach(blotterTabs, (value: any, key) => {
    if (key.includes(BALANCES_V2_BLOTTER_PREFIX) || key.includes(TREASURY_BLOTTER_PREFIX)) {
      const columns = value['columns'];
      if (columns != null && columns instanceof Array) {
        renameRelevantBlotterColumns(columns);
      }
    }
  });
}

// Similar to above but now for standalone tab state
export function renameBalancesBlotterTabColumns(config: any) {
  const tabs: object | undefined = config?.tabs;
  if (tabs == null) {
    return;
  }

  forEach(tabs, (value: any, key) => {
    if (key.includes(BALANCES_V2_BLOTTER_PREFIX)) {
      const items = value['items'];
      if (items != null && items instanceof Array) {
        for (const item of items) {
          renameRelevantTabDefaultColumns(item);
        }
      }
    }
  });
}

const idsToCamelCase = ['MarketAccountGroup', 'MarketAccountName', 'MarketType'];
function renameRelevantBlotterColumns(columns: any[]) {
  columns.forEach(column => {
    if (typeof column.id === 'string' && idsToCamelCase.includes(column.id)) {
      column.id = camelCase(column.id);
      if (column.field != null) {
        column.field = camelCase(column.field);
      }
    }
  });
}

function renameRelevantTabDefaultColumns(tabItems: any) {
  if ('defaultColumns' in tabItems && tabItems.defaultColumns instanceof Array) {
    tabItems.defaultColumns = tabItems.defaultColumns.map((column: string | object) => {
      if (typeof column === 'string') {
        if (idsToCamelCase.includes(column)) {
          return camelCase(column);
        }
      } else {
        // object
        // We have either id or field
        const key = column['id'] ?? column['field'];
        if (idsToCamelCase.includes(key)) {
          if ('id' in column && typeof column.id === 'string') {
            column.id = camelCase(column.id);
          }
          if ('field' in column && typeof column.field === 'string') {
            column.field = camelCase(column.field);
          }
        }
      }

      return column;
    });
  }
}

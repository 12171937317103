import type { OptionTypeEnum, SelectItem, StringSelectItem } from '@talos/kyoko';
import { OptionStrategies } from 'components/MultilegCombo/enums';
import { immerable } from 'immer';
import type { OMSReferenceDataState } from '../../types';
import { Option } from './Option';
import { OptionStrategy, type OptionStrategyData } from './OptionStrategy';

export class Butterfly extends OptionStrategy {
  [immerable] = true;

  public static createFromBlank(referenceData: OMSReferenceDataState, partial?: Partial<OptionStrategyData>) {
    const data: OptionStrategyData = {
      name: OptionStrategies.CalendarSpread,
      legs: [
        Option.createFromBlank(referenceData),
        Option.createFromBlank(referenceData),
        Option.createFromBlank(referenceData),
      ],
      initiatingLegs: [true, true, true],
      ratios: ['1', '-2', '1'],
      ...partial,
    };

    return new Butterfly(referenceData, data);
  }

  public updateExpiry = (expiry: StringSelectItem, legIndex: number) => {
    // TODO each expiry must be greater than the previous

    const newData = {
      legs: this._data.legs.map(leg => leg.updateExpiry(expiry)),
    };

    return this.create(newData);
  };

  public updateStrike = (strike: StringSelectItem, legIndex: number) => {
    const newData = {
      legs: [
        legIndex === 0 ? this._data.legs[0].updateStrike(strike) : this._data.legs[0],
        legIndex === 1 ? this._data.legs[1].updateStrike(strike) : this._data.legs[1],
        legIndex === 2 ? this._data.legs[2].updateStrike(strike) : this._data.legs[2],
      ],
    };
    return this.create(newData);
  };

  public updateType = (type: SelectItem<OptionTypeEnum>) => {
    const newData = {
      legs: this._data.legs.map(leg => leg.updateType(type)),
    };
    return this.create(newData);
  };

  public updateRatio = (ratio: string, legIndex: number) => {
    // Unsupported operation

    return this.create({});
  };

  public getPrettyName(): string {
    return 'TODO';
  }

  protected create(newData: Partial<OptionStrategyData>) {
    const data = {
      ...this._data,
      ...newData,
    };
    return new Butterfly(this._referenceData, data);
  }
}

import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { compact } from 'lodash-es';
import { isColDef, type BlotterTableSort } from '../types';

/** Apply the sorts of the current column state to the columns and autoGroupColumnDef */

export type ApplySortsToColumnsArg<TRowType> = {
  columnDefs: Array<ColDef<TRowType> | ColGroupDef<TRowType>>;
  autoGroupColumnDef: ColDef<TRowType> | undefined;
  initialSorts: BlotterTableSort<TRowType> | undefined;
};
/** Given input columnDefs and autoGroupColumnDef, apply the initial state to columnDefa */
export function applyInitialSortsToColumns<TRowType>(args: ApplySortsToColumnsArg<TRowType>): {
  columnDefs: Array<ColDef<TRowType> | ColGroupDef<TRowType>>;
  autoGroupColumnDef: ColDef<TRowType> | undefined;
} {
  const { columnDefs, autoGroupColumnDef, initialSorts } = args;

  // If there are no sorts applied to the blotter currently, but we're configured to have some amount of default sorting, we apply that default sorting.
  const shouldInitialise = initialSorts != null;
  const columns = compact([autoGroupColumnDef, ...columnDefs]);
  if (shouldInitialise) {
    const initialSortArr = Array.isArray(initialSorts) ? initialSorts : [initialSorts];
    initialSortArr.forEach((sort, sortIndex) => {
      const [dir, colId] = [sort.substring(0, 1), sort.substring(1, sort.length)];

      // We allow the user to define an initialSort on the group coldef as well, meaning that we have to include that in this search operation.
      const columnDef = columns.filter(isColDef).find(columnDef => columnDef.colId === colId);
      if (columnDef != null) {
        columnDef.sort = dir === '+' ? 'asc' : 'desc';
        columnDef.sortIndex = sortIndex;
      }
    });
  }
  return { columnDefs, autoGroupColumnDef };
}

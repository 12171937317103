import { type Currency, Divider, Flex, HStack, InlineFormattedNumber, PopoverContent, Text } from '@talos/kyoko';
import type { DefaultTheme } from 'styled-components';
import type { OperationsOverviewShowBy } from '../types';
import { getPointCustomAttrs, type OpsBalancesChartDimension } from './types';

interface OpsBalancesChartTooltipProps {
  point: Highcharts.Point;
  dimension: OpsBalancesChartDimension;
  showingByAsset: boolean;
  opsOverviewShowBy: OperationsOverviewShowBy;
  homeCurrency: Currency | undefined;
  theme: DefaultTheme;
}

export const OpsBalancesChartTooltip = ({
  point,
  dimension,
  showingByAsset,
  opsOverviewShowBy,
  homeCurrency,
  theme,
}: OpsBalancesChartTooltipProps) => {
  const attrs = getPointCustomAttrs(point);
  if (!attrs || !homeCurrency) {
    return <div>Error</div>;
  }

  const isLong = attrs.direction === 'positive';
  const directionTerm = isLong ? 'Long' : 'Short';
  const dimensionTerm = dimension === 'balances' ? 'Balance' : 'Model Delta';

  const getAssetContent = (point: Highcharts.Point) => {
    const asset = point.name;
    return (
      <>
        <HStack justifyContent="space-between" gap="spacingDefault">
          <Text fontWeight="bold" color="colorTextImportant">
            {asset}
          </Text>

          <Text>
            {directionTerm} {dimensionTerm}
          </Text>
        </HStack>

        <HStack justifyContent="space-between" gap="spacingDefault">
          <Text>
            {directionTerm} {dimensionTerm} {homeCurrency.Symbol}
          </Text>

          <Text>
            <InlineFormattedNumber
              number={attrs.nonAbsValue}
              currency={homeCurrency.Symbol}
              increment={homeCurrency.DefaultIncrement}
              round
              highlightColor={attrs.nonAbsValue < 0 ? theme.colorTextNegative : undefined}
            />
          </Text>
        </HStack>
      </>
    );
  };

  const getMarketOrAccountContent = (point: Highcharts.Point) => {
    return (
      <>
        <HStack justifyContent="space-between" gap="spacingDefault">
          <Text fontWeight="bold" color="colorTextImportant">
            {point.name}
          </Text>
          <Text textAlign="right">
            {directionTerm} {dimensionTerm}
          </Text>
        </HStack>

        <Divider orientation="horizontal" />

        <HStack justifyContent="space-between" gap="spacingDefault">
          <Text>
            {directionTerm} {dimensionTerm}
          </Text>
          <InlineFormattedNumber
            number={attrs.nonAbsValue}
            currency={homeCurrency.Symbol}
            increment={homeCurrency.DefaultIncrement}
            round
            highlightColor={attrs.nonAbsValue < 0 ? theme.colorTextNegative : undefined}
          />
        </HStack>

        <HStack justifyContent="space-between" gap="spacingDefault">
          <Text>
            % of Total {directionTerm} {dimensionTerm}
          </Text>
          <Text color="colorTextImportant">{attrs.percentageString}</Text>
        </HStack>
      </>
    );
  };

  return (
    <PopoverContent>
      <Flex
        color="colorTextDefault"
        flexDirection="column"
        gap="spacingDefault"
        minWidth="200px"
        maxWidth="300px"
        data-testid="balances-bar-chart-tooltip"
      >
        {showingByAsset ? getAssetContent(point) : getMarketOrAccountContent(point)}
      </Flex>
    </PopoverContent>
  );
};

import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';
import type { Aggregation } from '../types/Aggregation';

export const AggregationsContext = createContext<AggregationsContextProps | undefined>(undefined);
AggregationsContext.displayName = 'AggregationsContext';

export function useAggregationsContext() {
  const context = useContext(AggregationsContext);
  if (context === undefined) {
    throw new Error('Missing AggregationsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export interface AggregationsContextProps {
  /** An observable of all aggregations in array form */
  aggregationsListObs: Observable<Aggregation[]>;
  /** All aggregations in array form */
  aggregationsList: Aggregation[];
  /** An observable of only system aggregations in array form */
  systemAggregationsListObs: Observable<Aggregation[]>;
  /** Only system aggregations in array form */
  systemAggregationsList: Aggregation[];
  /** An observable of all aggregations by .Name */
  aggregationsByNameObs: Observable<Map<string, Aggregation>>;
  /** A map of all aggregations by .Name */
  aggregationsByName: Map<string, Aggregation>;
}

import type { ColDef, GridOptions } from 'ag-grid-community';
import { get } from 'lodash-es';
import type { ReactNode } from 'react';
import type { Paths } from '../../utils';
import type { BlotterDensity, ColumnDef } from '../BlotterTable';
import type { FilterableProperty } from '../Filters';
import type { InputsAndDropdownsDrawerProps } from './components';
import type { useEntityAdmin } from './useEntityAdmin';

/** A constant key used for the internal row ID. */
export const ENTITY_INTERNAL_ROW_ID = 'ENTITY_INTERNAL_ROW_ID';

/** Represents a generic record for an entity page.*/
export type EntityAdminRecord = Record<string, any>;

/**
 * Class representing an entity blotter row with optional parent-child relationships.
 * Uses a Proxy to intercept property access and fallback to parent entity fields.
 */
export class EntityAdminClass<T extends EntityAdminRecord> {
  private parentEntity?: T;

  /**
   * Retrieves the value of a specified field from the given target object.
   * @param {T | undefined} target - The object from which to retrieve the field.
   * @param {keyof T} field - The key of the field to retrieve.
   * @returns {T[keyof T] | undefined} The value of the field, or undefined if not found.
   */
  private getField(target: T | undefined, field: keyof T): T[keyof T] | undefined {
    return get(target, field);
  }

  /**
   * A Proxy is returned to intercept property access, enabling fallback to parent entity fields.
   * @param {T} entity - The primary entity object.
   * @param {keyof T} entityIDField - The unique field key used to identify the entity.
   * @param {keyof T} [childIDField] - Optional unique field key used to identify child entities.
   * @param {Map<keyof T, T>} [parentMap] - Optional map to look up parent entities by their ID.
   */
  constructor(
    private entity: T,
    private entityIDField: keyof T,
    private childIDField?: keyof T,
    private parentMap?: Map<keyof T, T>
  ) {
    const parentKey = get(entity, entityIDField);
    this.parentEntity = parentKey ? this.parentMap?.get(parentKey) : undefined;

    return new Proxy(this, {
      get: (target, prop: string) => {
        if (prop in target) {
          return target[prop as keyof this];
        }
        const field: keyof T = prop satisfies keyof T;
        return target.getField(target.entity, field) ?? target.getField(target.parentEntity, field);
      },
      set: (target, prop: string, value) => {
        // When the Blotter Table edits a field like Mode, we need to update the entity itself.
        target.entity[prop as keyof T] = value;
        return true;
      },
    });
  }

  /**
   * Gets the internal row ID based on entityIDField and childIDField.
   * @returns {string} The computed internal row ID.
   */
  get [ENTITY_INTERNAL_ROW_ID](): string {
    const entityID = this.getField(this.entity, this.entityIDField)!;
    const childEntityID = this.childIDField ? this.getField(this.entity, this.childIDField) : undefined;
    return childEntityID ? `${entityID}-${childEntityID}` : entityID;
  }

  /**
   * Determines whether a field is inherited from the parent entity.
   * @param {keyof T} field - The field key to check.
   * @returns {boolean} True if the field is not present in the entity but exists in the parent entity.
   */
  isFieldInherited(field: keyof T): boolean {
    return this.getField(this.entity, field) == null && this.getField(this.parentEntity, field) != null;
  }

  get isChildRow(): boolean {
    return this.childIDField != null && this.getField(this.entity, this.childIDField) != null;
  }

  get isParentRow(): boolean {
    return !this.isChildRow;
  }

  /** Retrieves the underlying entity data. */
  get data(): T {
    return this.entity;
  }

  /** @deprecated Use `this.data` instead. */
  toJSON(): T {
    return this.data;
  }
}

export interface HierarchicalColumnProps<T> {
  openEntityDrawer: (entity: T | undefined, isParentOverride: boolean) => void;
  openBulkEditDrawer?: (entities: T[]) => void;
  buttonProps?: { text?: string; width?: number };
  entityIDField: keyof T;
  childIDField: keyof T;
}

export type InputsAndDropdownsDrawerDropdownOption = {
  value: string | boolean;
  label: string;
  description?: string;
};

interface CommonDrawerOptionProps<TDrawerRecord> {
  field: Paths<TDrawerRecord>;
  title?: string;
  placeholder?: string;
  disabledWhenEditing?: boolean;
  getIsRequired?: (form: TDrawerRecord) => boolean;
  getIsHidden?: (form: TDrawerRecord) => boolean;
  getIsDisabled?: (form: TDrawerRecord) => boolean;
}

export interface InputDrawerOption<TDrawerRecord> extends CommonDrawerOptionProps<TDrawerRecord> {
  type: 'input' | 'inputBPS' | 'inputNumeric';
  getInputSuffix?: (form: TDrawerRecord) => string;
}

export interface DropdownDrawerOption<TDrawerRecord> extends CommonDrawerOptionProps<TDrawerRecord> {
  type: 'dropdown';
  getDropdownOptions: (form: TDrawerRecord, isEditing: boolean) => InputsAndDropdownsDrawerDropdownOption[];
}

export interface DividerDrawerOption {
  type: 'divider';
}

export type InputsAndDropdownsDrawerOption<TDrawerRecord extends EntityAdminRecord> =
  | DividerDrawerOption
  | InputDrawerOption<TDrawerRecord>
  | DropdownDrawerOption<TDrawerRecord>;

export interface BaseEntityAdminProps<
  TRecord extends EntityAdminRecord,
  TDrawerRecord extends EntityAdminRecord = TRecord
> {
  /** The title of the page. */
  title?: string;

  /** The subtitle of the page. */
  subtitle?: ReactNode;

  /** The name of the entity. */
  entityName?: string;

  /** The field to use in the API request. */
  entityIDField: keyof TRecord;

  /** The field to use as the Child ID for Tree Data blotters. */
  childIDField?: keyof TRecord;

  /** The base URL for API endpoints. */
  baseUrl?: string;

  /** The path for the GET API endpoint. */
  path?: string;

  /** Whether to allow adding new entities. */
  allowAddEntity?: boolean;

  /** Whether to allow editing existing entities. */
  allowEditEntity?: boolean;

  /** Whether to allow deleting entities. */
  allowDeleteEntity?: boolean;

  /** Add the Show JSON button to the context menu items. Defaults to true. */
  allowShowJSON?: boolean;

  /** If provided, the key to use for persisting the blotter table. */
  persistKey?: string;

  /** The density of the table. */
  density?: BlotterDensity;

  /** The columns to display in the table. If undefined, all columns will be generated and displayed. */
  columns?: ColumnDef<TRecord>[];

  /**
   * Set to EMPTY_ARRAY to disable filtering and the filter builder.
   * If set to undefined, the filter builder will display as loading.
   */
  filterableProperties?: FilterableProperty[];

  /** The server filter keys which are keyof TRecord. */
  filterableServerFields?: (keyof TRecord)[];

  /** Whether to allow mode switching. */
  allowModeSwitch?: boolean;

  /** The group column definition. */
  groupColumnDef?: ColDef<TRecord>;

  /** The Blotter API ref */
  blotterTableApiRef?: React.MutableRefObject<{ refresh?: (force?: boolean) => void }>;

  /** This conversion is required when initiating the form to edit the record. */
  getEntityAsDrawerEntity?: (entity: TRecord) => TDrawerRecord;

  /** If this function is provided, it will be used to filter the entities returned from the API. */
  userFilterFunc?: (entity: TRecord) => boolean;

  /** The drawer options to display. */
  drawerOptions?: InputsAndDropdownsDrawerOption<TDrawerRecord>[];

  /** The drawer type to use when adding/editing endities. Defaults to InputsAndDropdowns type. */
  drawerType?: 'JSON' | 'InputsAndDropdowns';

  /** The hierarchical properties, of tree data structures */
  addChildEntityButtonProps?: HierarchicalColumnProps<TRecord>['buttonProps'];

  /** Function to resolve the entity name for the drawer. */
  getEditEntityName?: (entity: TDrawerRecord) => TDrawerRecord[keyof TDrawerRecord];

  /** Function to resolve the context menu for the row */
  getContextMenuItems?: GridOptions<EntityAdminClass<TRecord>>['getContextMenuItems'];

  /** The panel actions to display alongside the New Entity button. */
  panelActions?: ReactNode[];

  /** Function to get the drawer options based on the entity. */
  getEntityDrawerOptions?: (
    entity: TDrawerRecord | undefined,
    addingChildEntity: boolean
  ) => InputsAndDropdownsDrawerOption<TDrawerRecord>[] | undefined;

  /** Whether to use persistent tabs for the drawer.
   * If persistKey is provided, true by default. */
  useTabs?: boolean;

  /**
   * Whether to enable bulk edting in your blotter.
   * Off by default.
   */
  allowBulkEdit?: boolean;

  /**
   * The fields that are allowed to be bulk edited.
   */
  bulkEditFields?: Paths<TDrawerRecord>[];

  /**
   * Whether to enable pause/resume in your blotter.
   * Off by default.
   */
  allowPauseResume?: boolean;

  /**
   * Which actions should prompt a confirmation dialog where the user types "confirm" to proceed.
   * EMPTY_ARRAY by default. By default, bulk actions (bulk-edit, bulk-delete) and single-delete only require clicking a Confirm button.
   * Any action included in confirmTextActions will require the user to type "confirm" and then click the Confirm button.
   */
  confirmTextActions?: ('single-add' | 'single-edit' | 'bulk-edit' | 'single-delete' | 'bulk-delete')[];

  /**
   * @deprecated (kept for legacy API compatibility)
   * Push for back-end consistency in PATCH and POST requests.
   * If provided, a custom entity can be constructed for the PATCH and POST requests. */
  getEntityForPatchPost?: (entity: TDrawerRecord) => EntityAdminRecord;

  /**
   * @deprecated (kept for legacy API compatibility)
   * Push for back-end consistency in POST paths.
   * If provided, a custom path can be constructed for the POST requests. */
  getPostPath?: (entity: TDrawerRecord) => string;

  /**
   * @deprecated (kept for legacy API compatibility)
   * Push for back-end consistency in PATCH and DELETE paths.
   * If provided, a custom path can be constructed for the PATCH and DELETE requests. */
  getPatchDeletePath?: (entity: TDrawerRecord) => string;

  /**
   * @deprecated (kept for legacy API compatibility)
   * Default behavior is to send null for removed fields.
   * If set to true, the patch will send empty strings for removed fields. */
  sendEmptyStringForNulledValues?: boolean;

  /**
   * @deprecated (kept for legacy API compatibility)
   * Push for back-end consistency in POST requests.
   * If set to true, the POST request payload will be [entity]. */
  postEntityInArray?: boolean;

  /**
   * @deprecated (kept for legacy API compatibility)
   * Push for back-end consistency in PATCH and POST requests.
   * If set to true, the PATCH and POST requests will be sent as PUT requests. */
  usePutForPatchPost?: boolean;
}

export interface useEntityAdminProps<TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord>
  extends Omit<BaseEntityAdminProps<TRecord, TDrawerRecord>, 'filterableProperties'> {
  renderDrawer: (
    renderDrawerProps: Omit<InputsAndDropdownsDrawerProps<TRecord, TDrawerRecord>, 'isEditing'> & {
      bulkEditing?: boolean;
    }
  ) => JSX.Element;
}

export type EntityAdminBlotterProps<
  TRecord extends EntityAdminRecord,
  TDrawerRecord extends EntityAdminRecord = TRecord
> = useEntityAdminProps<TRecord, TDrawerRecord> &
  ReturnType<typeof useEntityAdmin<TRecord, TDrawerRecord>> & {
    filterableProperties: FilterableProperty[];
  };

export type EntityAdminContentProps<
  TRecord extends EntityAdminRecord,
  TDrawerRecord extends EntityAdminRecord
> = ReturnType<typeof useEntityAdmin<TRecord, TDrawerRecord>> & {
  filterableProperties: FilterableProperty[];
};

import { isGridApiReady, type UseBlotterTableProps } from '@talos/kyoko';
import { useCallbackRef } from '@talos/kyoko/src/hooks/useCallbackRef';
import type { ColDef, GridApi } from 'ag-grid-community';
import { get, noop } from 'lodash-es';
import { useCallback, useMemo, useRef } from 'react';
import type { PortfolioRiskDataItem } from '../../types/PortfolioRiskDataItem';
import type { PortfolioRiskGridContext } from './PortfolioRiskBlotter';
import { splitPivotField } from './usePortfolioRiskColumns';

/** Using the customColumnUpdate mechanism of {@link useBlotterTable}, handle the updates of the tenor
 * columns and moneyness columns based on the riskPivotType.
 */
export function usePivotColumnShowHide(): {
  /** Callback for useBlotterTable to trigger internally */
  customColumnUpdate: NonNullable<UseBlotterTableProps<PortfolioRiskDataItem>['customColumnUpdate']>;
  /** Callback (stabilized in a ref) to perform applyColumnDefs based on the next state of the pivot */
  updatePivotColumnVisibility: (api: GridApi, visiblePivotFields: string[]) => void;
  refresh: ({ api }: { api: GridApi }) => void;
} {
  type Callback = NonNullable<UseBlotterTableProps<PortfolioRiskDataItem>['customColumnUpdate']>;

  /** Update the visibility of the pivot columns based on the riskPivotType and riskAggMode, along with the visible
   * column state.
   * - Since this is used as an effect event internally and downstream, useCallbackRef is fine here.
   */

  const lastVisiblePivotFields = useRef<string[]>([]);
  const updatePivotColumnVisibility = useCallback((api: GridApi, visiblePivotFields: string[]) => {
    if (isGridApiReady(api)) {
      const columnState = api.getColumnState();
      const activeContext = api.getGridOption('context');
      if (!activeContext) {
        return;
      }
      const { riskAggMode, riskPivotType } = activeContext.current as unknown as PortfolioRiskGridContext;
      lastVisiblePivotFields.current = visiblePivotFields;
      const pivotFields = columnState
        .filter(item => item.colId.match(/\bPivot\b/))
        .map(item => {
          // for the group columns, field is represented in the colId
          const [_a, _b, _greek, pivotType, _bucket, netGross] = splitPivotField(item.colId);
          const show =
            pivotType === riskPivotType && netGross === riskAggMode && visiblePivotFields.includes(item.colId);
          item.hide = !show;
          return item;
        });

      const nonPivotFields = columnState.filter(item => !item.colId.match(/\bPivot\b/));

      const colDefsToSet = [...nonPivotFields, ...pivotFields];

      // remove all fields except for colId and hide
      const columnStateToApply = colDefsToSet.map(item => {
        return { colId: item.colId, hide: item.hide };
      });

      api.applyColumnState({ state: columnStateToApply, applyOrder: true });
    }
  }, []);

  // On every risk batch update, analyze the latest tenors and moneyness to ensure we have the columns to show
  // and update the columnDefs.
  //
  // Improvements: Refactor this check to be based on grid transaction completions (and potentially row filter changes)
  const customColumnUpdate = useCallback<Callback>(
    (arg): (() => void) => {
      const { api, dataObservable } = arg;
      if (!isGridApiReady(api)) {
        return noop;
      }
      const sub = dataObservable.subscribe(subaccountRiskBatch => {
        const data = subaccountRiskBatch.data ?? [];
        if (isGridApiReady(api) && data.length > 0) {
          const visibleFields = new Set<string>();

          const columnDefs = api.getColumnDefs();
          // the only pivot columns that should be shown are the group children
          const pivotColumn = (columnDefs ?? [])
            .filter(colDef => 'children' in colDef)
            .flatMap(colDef => {
              return colDef.children.filter(child => {
                const isGroupDef = 'children' in child;
                return !isGroupDef;
              }) as ColDef[];
            });

          // generate the new visibleFields set based on the latest data, as we only want to
          // show the pivot columns that have non-empty values
          pivotColumn.forEach(child => {
            if (child.field) {
              const field = child.field;
              const isValid = data.some(item => get(item, field));
              isValid && visibleFields.add(field);
            }
          });

          // only show the pivot columns that have non-empty values
          const resolvedVisibleFields = [...visibleFields]
            .filter(field => {
              return data.some(item => get(item, field) != null);
            })
            .sort();

          updatePivotColumnVisibility(api, resolvedVisibleFields);
        }
      });
      return () => {
        sub.unsubscribe();
      };
    },
    [updatePivotColumnVisibility]
  );
  const refresh = useCallbackRef(({ api }: { api: GridApi }) => {
    updatePivotColumnVisibility(api, lastVisiblePivotFields.current);
  });
  return useMemo(
    () => ({ customColumnUpdate, updatePivotColumnVisibility, refresh }),
    [customColumnUpdate, updatePivotColumnVisibility, refresh]
  );
}

import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import type { BoxProps } from '../Core';
import { Box } from '../Core';
import type { AccordionProps } from './Accordion';

type AccordionElementProps = Pick<AccordionProps, 'isOpen' | 'onOpened' | 'onClosed'> & {
  initialOpen?: boolean;
} & BoxProps;

export function AccordionElement({
  isOpen,
  onOpened,
  onClosed,
  initialOpen = false,
  children,
  ...props
}: AccordionElementProps) {
  // isVisible is used to prevent content from rendering when the accordion closed
  const [isVisible, setIsVisible] = useState(isOpen);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      if (ref.current) {
        ref.current.style.overflow = 'hidden';
      }
    }
    ref.current?.getAnimations().forEach(async animation => {
      try {
        await animation.finished;
        if (isOpen) {
          // Make sure content is allowed to overflow after animation is done
          setTimeout(() => {
            if (ref.current) {
              ref.current.style.overflow = 'unset';
            }
          }, 0);
          onOpened?.();
        } else {
          onClosed?.();
          // Prevent content from rendering when closed
          setIsVisible(false);
        }
      } catch (e) {
        // Mostly to avoid errors when running cypress tests
        console.error(e);
      }
    });
  }, [isOpen, onOpened, onClosed]);

  return (
    <AccordionWrapper ref={ref} isOpen={isOpen} initialOpen={initialOpen} {...props}>
      {isVisible ? children : null}
    </AccordionWrapper>
  );
}

const AccordionWrapper = styled(Box)<{ isOpen: boolean; initialOpen: boolean }>`
  transition-property: height, display;
  transition-duration: 200ms;
  transition-timing-function: ease;
  // Allows animating between display: none and display: block etc.
  transition-behavior: allow-discrete;
  // Fallback for browsers that don't support calc-size
  height: auto;
  // Allows animating between height: 0 and height: auto
  height: calc-size(auto, size);

  ${({ initialOpen }) =>
    !initialOpen &&
    css`
      // Set the style that the element should start with when first rendered or when dispay is not none
      @starting-style {
        height: 0;
      }
    `}

  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: none;
      height: 0;
    `}
`;
// Make sure props can be change by props
AccordionElement.defaultProps = {
  flex: '0 0 auto',
  h: '0px',
};

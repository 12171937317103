import { EMPTY_ARRAY } from '../../../utils';
import { Button, ButtonVariants } from '../../Button';
import { Box, HStack } from '../../Core';
import { FormControlSizes } from '../../Form';
import { IconName } from '../../Icons';
import { PanelActions, PanelHeader } from '../../Panel';
import { Text } from '../../Text';
import type { BaseEntityAdminProps, EntityAdminRecord, HierarchicalColumnProps } from '../types';

export interface EntityAdminHeaderProps<TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord>
  extends Pick<
    BaseEntityAdminProps<TRecord, TDrawerRecord>,
    'title' | 'subtitle' | 'entityName' | 'panelActions' | 'drawerOptions' | 'drawerType' | 'allowAddEntity'
  > {
  /** The open entity drawer function. */
  openEntityDrawer: HierarchicalColumnProps<TRecord>['openEntityDrawer'];
}

export const EntityAdminHeader = <TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord>({
  drawerOptions,
  entityName = 'Entity',
  title = `${entityName} Page Title`,
  subtitle = `${entityName} Page Subtitle`,
  panelActions = EMPTY_ARRAY,
  allowAddEntity = false,
  openEntityDrawer,
}: EntityAdminHeaderProps<TRecord, TDrawerRecord>) => {
  return (
    <PanelHeader alignItems="center">
      <Box>
        {title && <h2 data-testid="entity-admin-page-title">{title}</h2>}
        {subtitle && (
          <Box mt="spacingDefault">
            <Text whiteSpace="break-spaces" data-testid="entity-admin-page-subtitle">
              {subtitle}
            </Text>
          </Box>
        )}
      </Box>
      <PanelActions>
        {panelActions.length > 0 && (
          <HStack data-testid="entity-admin-page-panel-actions" gap="spacingDefault">
            {panelActions.map((inputComponent, index) => (
              <Box key={index}>{inputComponent}</Box>
            ))}
          </HStack>
        )}
        {allowAddEntity && (
          <Button
            startIcon={IconName.Plus}
            onClick={() => openEntityDrawer(undefined, false)}
            variant={ButtonVariants.Positive}
            data-testid="entity-admin-page-new-entity-button"
            size={FormControlSizes.Small}
            disabled={drawerOptions == null}
          >
            New {entityName}
          </Button>
        )}
      </PanelActions>
    </PanelHeader>
  );
};

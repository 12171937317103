import { cloneDeep, entries, get, noop, set, toNumber, uniqueId } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useConstant } from '../../../hooks';
import { bpsToPercent, percentToBps, prettyName } from '../../../utils';
import { Button, ButtonVariants } from '../../Button';
import { Box, VStack } from '../../Core';
import { Divider } from '../../Divider';
import { DrawerContent, DrawerFooter } from '../../Drawer';
import { FormGroup, Input, SearchSelect } from '../../Form';
import type {
  DividerDrawerOption,
  DropdownDrawerOption,
  EntityAdminRecord,
  InputDrawerOption,
  InputsAndDropdownsDrawerOption,
} from '../types';
import type { useEntityAdmin } from '../useEntityAdmin';

const isOptionDivider = <TDrawerRecord extends EntityAdminRecord>(
  option: InputsAndDropdownsDrawerOption<TDrawerRecord>
): option is DividerDrawerOption => option.type === 'divider';

const isOptionInput = <TDrawerRecord extends EntityAdminRecord>(
  option: InputsAndDropdownsDrawerOption<TDrawerRecord>
): option is InputDrawerOption<TDrawerRecord> => ['input', 'inputBPS', 'inputNumeric'].includes(option.type);

const isOptionDropdown = <TDrawerRecord extends EntityAdminRecord>(
  option: InputsAndDropdownsDrawerOption<TDrawerRecord>
): option is DropdownDrawerOption<TDrawerRecord> => option.type === 'dropdown';

const getOptionLabel = <TDrawerRecord extends EntityAdminRecord>(
  option: InputsAndDropdownsDrawerOption<TDrawerRecord>,
  form: TDrawerRecord
) => {
  if (isOptionDivider(option)) {
    return null;
  }
  return `${option.title || prettyName(String(option.field))}${option.getIsRequired?.(form) ? '*' : ''}`;
};

const getOptionKey = <TDrawerRecord extends EntityAdminRecord>(option: InputsAndDropdownsDrawerOption<TDrawerRecord>) =>
  isOptionDivider(option) ? uniqueId('inputs-and-dropdowns-drawer-divider') : String(option.field);

const getFormState = <TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord>(
  entity: TRecord | undefined,
  drawerOptions: InputsAndDropdownsDrawerOption<TDrawerRecord>[]
): TDrawerRecord => {
  return entries<string>(entity).reduce<TDrawerRecord>((acc, [field, value]) => {
    const drawerOption = drawerOptions.find(option => !isOptionDivider(option) && option.field === field);
    if (drawerOption?.type === 'inputBPS') {
      // Convert percent to BPS for form initialization
      acc[field as keyof TDrawerRecord] = percentToBps(value) as TDrawerRecord[keyof TDrawerRecord];
    } else {
      acc[field as keyof TDrawerRecord] = value as TDrawerRecord[keyof TDrawerRecord];
    }

    return acc;
  }, {} as TDrawerRecord);
};

const hideDrawerOption = <TDrawerRecord extends EntityAdminRecord>(
  option: InputsAndDropdownsDrawerOption<TDrawerRecord>,
  form: TDrawerRecord
): boolean => (option.type !== 'divider' && option.getIsHidden?.(form)) ?? false;

export type InputsAndDropdownsDrawerProps<
  TRecord extends EntityAdminRecord,
  TDrawerRecord extends EntityAdminRecord
> = {
  onSaveEntity: (modifiedEntity: TDrawerRecord) => Promise<TRecord> | undefined; // For bulk edits, return undefined
  onDeleteEntity: (selectedEntities: TDrawerRecord[]) => Promise<void>;
  isEditing: boolean;
  drawerOptions: InputsAndDropdownsDrawerOption<TDrawerRecord>[];
  addingChildEntity?: boolean;
} & Pick<ReturnType<typeof useEntityAdmin<TRecord, TDrawerRecord>>, 'allowDeleteEntity' | 'selectedEntity'>;

export function InputsAndDropdownsDrawer<TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord>({
  selectedEntity,
  onSaveEntity,
  allowDeleteEntity,
  onDeleteEntity,
  drawerOptions,
  isEditing,
}: InputsAndDropdownsDrawerProps<TRecord, TDrawerRecord>) {
  const [form, setForm] = useState<TDrawerRecord>(getFormState(selectedEntity, drawerOptions));

  const handleOnFormUpdate = useConstant((field: keyof TDrawerRecord, value: string | boolean) => {
    setForm(prev => {
      const next = cloneDeep(prev);
      set(next, field, value);
      return next;
    });
  });

  const handleOnDelete = useCallback(() => {
    onDeleteEntity([selectedEntity!]);
  }, [onDeleteEntity, selectedEntity]);

  const handleOnSave = useCallback(() => {
    const formWithInputTypesConverted = drawerOptions.reduce((acc, option) => {
      if (option.type === 'inputBPS' && form[option.field]) {
        // Convert BPS to percent for saving
        acc[option.field as keyof typeof acc] = bpsToPercent(form[option.field]) as TDrawerRecord[keyof TDrawerRecord];
      } else if (option.type === 'inputNumeric' && form[option.field]) {
        // Convert string to number for saving
        acc[option.field as keyof typeof acc] = toNumber(form[option.field]) as TDrawerRecord[keyof TDrawerRecord];
      }
      return acc;
    }, cloneDeep(form));

    onSaveEntity(formWithInputTypesConverted)
      ?.then(entity => entity && setForm(getFormState(entity, drawerOptions)))
      .catch(noop);
  }, [drawerOptions, form, onSaveEntity]);

  const someRequiredInputNotPopulated = useMemo(
    () =>
      drawerOptions.some(
        option =>
          // Divider options are ignored
          !isOptionDivider(option) &&
          // Hidden options are ignored
          !hideDrawerOption(option, form) &&
          // If the option is required and not populated, return true
          option.getIsRequired?.(form) &&
          !form[option.field]
      ),
    [drawerOptions, form]
  );

  return (
    <VStack data-testid="entity-admin-page-inputs-and-dropdowns-drawer" h="100%">
      <DrawerContent overflow="overlay" w="100%">
        <Box>
          {drawerOptions
            .filter(drawerOption => !hideDrawerOption(drawerOption, form))
            .map(drawerOption => (
              <FormGroup key={getOptionKey(drawerOption)} label={getOptionLabel(drawerOption, form)}>
                {isOptionDivider(drawerOption) ? (
                  <Divider data-testid="inputs-and-dropdowns-drawer-divider" />
                ) : isOptionInput(drawerOption) ? (
                  <Input
                    value={(get(form, drawerOption.field) as string | undefined) ?? ''}
                    onChange={e => handleOnFormUpdate(drawerOption.field, e.target.value)}
                    disabled={(drawerOption.disabledWhenEditing && isEditing) || drawerOption.getIsDisabled?.(form)}
                    placeholder={drawerOption.placeholder}
                    suffix={drawerOption.type === 'inputBPS' ? 'BPS' : drawerOption.getInputSuffix?.(form)}
                    inputType="text"
                    data-testid={`inputs-and-dropdowns-drawer-${String(drawerOption.field)}`}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                  />
                ) : isOptionDropdown(drawerOption) ? (
                  <SearchSelect
                    selection={drawerOption
                      .getDropdownOptions(form, isEditing)
                      .find(option => option.value === get(form, drawerOption.field))}
                    options={drawerOption.getDropdownOptions(form, isEditing)}
                    getLabel={option => option.label}
                    getDescription={option => option.description ?? ''}
                    onChange={newValue => handleOnFormUpdate(drawerOption.field, newValue?.value ?? '')}
                    showClear={true}
                    disabled={(drawerOption.disabledWhenEditing && isEditing) || drawerOption.getIsDisabled?.(form)}
                    placeholder={drawerOption.placeholder}
                    data-testid={`inputs-and-dropdowns-drawer-${String(drawerOption.field)}`}
                  />
                ) : null}
              </FormGroup>
            ))}
        </Box>
      </DrawerContent>
      <DrawerFooter w="100%">
        {allowDeleteEntity && isEditing && (
          <Button
            variant={ButtonVariants.Negative}
            onClick={handleOnDelete}
            data-testid="inputs-and-dropdowns-drawer-delete-button"
          >
            Delete
          </Button>
        )}
        <Button
          onClick={handleOnSave}
          variant={ButtonVariants.Primary}
          data-testid="inputs-and-dropdowns-drawer-save-button"
          disabled={someRequiredInputNotPopulated}
        >
          Save
        </Button>
      </DrawerFooter>
    </VStack>
  );
}

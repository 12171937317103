import {
  Box,
  Button,
  FormControlSizes,
  HStack,
  IconName,
  Input,
  SelectorFieldView,
  Text,
  Tooltip,
  VStack,
  type Security,
} from '@talos/kyoko';
import { noop, startCase } from 'lodash-es';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import type { AppState } from 'providers/AppStateProvider/types';
import { useCallback } from 'react';
import type { MultilegComboType } from './enums';
import {
  selectEditableProperties,
  selectInstrumentField,
  selectIsEditing,
  updateEditableProperty,
  updateInstrument,
} from './MultilegComboSlice';
import type { GroupableSelectItem } from './types';

const getLabel = (item: GroupableSelectItem<MultilegComboType>) => item.label;

export function MultilegComboDetails({
  security,
  isModal,
  panelID,
}: {
  security: Security | undefined;
  isModal?: boolean;
  panelID: string;
}) {
  const dispatch = useAppStateDispatch();
  const isEditing = useAppStateSelector((state: AppState) => selectIsEditing(state, panelID));
  const editableProperties = useAppStateSelector((state: AppState) => selectEditableProperties(state, panelID));
  const instrumentField = useAppStateSelector((state: AppState) => selectInstrumentField(state, panelID));

  const handleChangeInstrument = useCallback(
    (value: GroupableSelectItem<MultilegComboType> | undefined) => {
      if (value != null) {
        dispatch(updateInstrument({ panelID, instrument: value }));
      }
    },
    [dispatch, panelID]
  );

  return (
    <>
      <Box w="260px">
        <SelectorFieldView
          field={instrumentField}
          handleChange={handleChangeInstrument}
          labelGetter={getLabel}
          initialSortByLabel={false} // underlying data already sorted
          size={isModal ? FormControlSizes.Default : FormControlSizes.Small}
          groupField="group"
          prefix="Multileg Type"
          testid="multileg-instrument-selector"
          id="multileg-instrument-selector"
        />
      </Box>
      {!security ? null : isEditing ? (
        <HStack gap="spacingComfortable">
          <Box w={350}>
            <Input
              prefix="Description"
              value={editableProperties.description ?? security.Description}
              size={isModal ? FormControlSizes.Default : FormControlSizes.Small}
              data-testid="multileg-description-input"
              onChange={e =>
                isEditing
                  ? dispatch(updateEditableProperty({ key: 'description', value: e.target.value, panelID }))
                  : null
              }
            />
          </Box>
          <Box w={isModal ? undefined : 220}>
            <Input
              prefix="Min Price Increment"
              value={editableProperties.minPriceIncrement || security.MinPriceIncrement}
              size={isModal ? FormControlSizes.Default : FormControlSizes.Small}
              type="number"
              data-testid="multileg-min-price-increment-input"
              onChange={e =>
                isEditing
                  ? dispatch(updateEditableProperty({ key: 'minPriceIncrement', value: e.target.value, panelID }))
                  : null
              }
            />
          </Box>
          <Box w={isModal ? undefined : 220}>
            <Input
              prefix="Min Size Increment"
              value={editableProperties.minSizeIncrement || security.MinSizeIncrement}
              size={isModal ? FormControlSizes.Default : FormControlSizes.Small}
              type="number"
              data-testid="multileg-min-size-increment-input"
              onChange={e =>
                isEditing
                  ? dispatch(updateEditableProperty({ key: 'minSizeIncrement', value: e.target.value, panelID }))
                  : null
              }
            />
          </Box>
          <Box w={isModal ? undefined : 220}>
            <Input
              prefix="Max Market Data Depth"
              value={editableProperties.maxMarketDataDepth || security?.MultilegDetails?.MaxMarketDataDepth}
              size={isModal ? FormControlSizes.Default : FormControlSizes.Small}
              type="number"
              data-testid="multileg-max-market-data-depth-input"
              onChange={e =>
                isEditing
                  ? dispatch(
                      updateEditableProperty({
                        key: 'maxMarketDataDepth',
                        value: e.target.value,
                        panelID,
                      })
                    )
                  : null
              }
            />
          </Box>
        </HStack>
      ) : (
        <Tooltip
          trigger="click"
          closeOnClickOutside={true}
          placement="bottom-end"
          contentMaxWidth="100%"
          usePortal={false}
          tooltip={
            <VStack gap="spacingDefault" justifyContent="flex-start" alignItems="stretch">
              {['Description', 'MinPriceIncrement', 'MinSizeIncrement'].map(key => (
                <HStack key={key} justifyContent="space-between" gap="spacingLarge">
                  <Text color="colorTextSubtle" size="fontSizeSm">
                    {startCase(key)}
                  </Text>
                  <Text color="colorTextImportant" size="fontSizeSm">
                    {security?.[key]}
                  </Text>
                </HStack>
              ))}
              <HStack key="MaxMarketDataDepth" justifyContent="space-between" gap="spacingLarge">
                <Text color="colorTextSubtle" size="fontSizeSm">
                  Max Market Data Depth
                </Text>
                <Text color="colorTextImportant" size="fontSizeSm">
                  {security?.MultilegDetails?.MaxMarketDataDepth}
                </Text>
              </HStack>
            </VStack>
          }
        >
          <Button onClick={noop} size={FormControlSizes.Tiny} endIcon={IconName.InformationCircleSolid}>
            Details
          </Button>
        </Tooltip>
      )}
    </>
  );
}

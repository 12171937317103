import { Box, HStack, IndicatorDot, IndicatorDotVariants, JITTooltip, Text } from '@talos/kyoko';
import type { ColDef, ICellRendererParams, IRowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { DefaultMarketAccount } from '../../DefaultMarketAccount/DefaultMarketAccount';
import { AvailabilityEnum, type AvailabilityResult } from '../types';
import { isMarketSelectorItemSelectable } from '../utils/items';
import type { MarketSelectorRow } from './types';

// AgGrid returns the grouping key (string below) for group rows.
type MarketColumnValue =
  | string
  | {
      name: string;
      label: string;
      availability: AvailabilityResult;
    };

export const marketSelectorAutoGroupColumnDef = {
  headerName: 'Markets',
  sortable: true,
  comparator: marketsColumnComparator,
  sort: 'asc',
  valueGetter: (params: ValueGetterParams<MarketSelectorRow>): MarketColumnValue | undefined => {
    // FYI this valueGetter is only ran for leaf rows. AgGrid automatically takes the grouping key for group rows.
    const data = params.node?.data;
    return data
      ? {
          name: data.item.name,
          label: data.item.displayName,
          availability: data.item.availability,
        }
      : undefined;
  },
  valueFormatter: ({ value, context }: ValueFormatterParams<MarketSelectorRow, MarketColumnValue>) => {
    if (!value) {
      return '';
    }

    if (typeof value === 'string') {
      return context.current.marketDisplayNameByName?.get(value) ?? value;
    }

    return value.label;
  },
  cellRendererParams: (params: ICellRendererParams) => ({
    innerRenderer: MarketSelectorMarketCellRenderer,
    suppressCount: true,
    ...params,
  }),
} satisfies ColDef;

const MarketSelectorMarketCellRenderer = ({
  value,
  context,
  node,
  data,
}: ICellRendererParams<MarketSelectorRow, MarketColumnValue>) => {
  const isGroup = data == null;
  if (!value) {
    return 'Unknown';
  }

  if (typeof value === 'string') {
    // When the value in this column is of type string, we are a Market Row with children accounts. The value is our Market.Name.
    const selectable = isMarketParentRowSelectable(node);
    const label = context.current.marketDisplayNameByName?.get(value) ?? value;

    return (
      <HStack color={selectable ? 'colorTextImportant' : 'colorTextSubtle'} gap="spacingSmall">
        <Text>{label}</Text>
        {!selectable && (
          <JITTooltip tooltip="No Accounts selectable within Market" usePortal>
            <Box pl="spacingSmall">
              <IndicatorDot variant={IndicatorDotVariants.Warning} show size={8} />
            </Box>
          </JITTooltip>
        )}
      </HStack>
    );
  }

  const availabilityContent =
    value.availability.availability < AvailabilityEnum.Ok ? value.availability.infoNode : undefined;

  const isOnlyChild = (node?.parent?.childrenAfterGroup?.length ?? 1) === 1;

  return (
    <HStack color={isMarketSelectorItemSelectable(value) ? 'colorTextImportant' : 'colorTextSubtle'} gap="spacingSmall">
      <Text>{value.label}</Text>
      {availabilityContent && (
        <JITTooltip tooltip={availabilityContent} usePortal>
          <Box pl="spacingSmall">
            <IndicatorDot variant={IndicatorDotVariants.Warning} show size={8} />
          </Box>
        </JITTooltip>
      )}
      {!isGroup && !isOnlyChild && <DefaultMarketAccount marketAccount={value.name} />}
    </HStack>
  );
};

function marketsColumnComparator(
  _valueA: unknown,
  _valueB: unknown,
  nodeA: IRowNode<MarketSelectorRow>,
  nodeB: IRowNode<MarketSelectorRow>
) {
  const labelA = nodeA.data?.item.displayName;
  const labelB = nodeB.data?.item.displayName;
  if (!labelA || !labelB) {
    return 0;
  }

  return labelA.localeCompare(labelB);
}

/**
 * When our node is a market parent row, as in we are a market row with account children within us,
 * we are selectable if at least one of our children is selectable.
 */
export function isMarketParentRowSelectable(node: IRowNode<MarketSelectorRow>): boolean {
  const children = node.allLeafChildren ?? [];
  return children.some(child => (child.data?.item ? isMarketSelectorItemSelectable(child.data.item) : false));
}

import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';
import { parseColor } from '../../styles';

export const Container = styled.div`
  width: 100%;
`;

export const Bar = styled.div<{
  roundLeft: boolean;
  roundRight: boolean;
  direction: 'ltr' | 'rtl';
  size: 'small' | 'default';
}>`
  display: flex;
  justify-content: flex-start;
  flex-direction: ${({ direction }) => (direction === 'ltr' ? 'row' : 'row-reverse')};

  overflow: hidden;
  ${({ theme, roundLeft, size }) =>
    roundLeft &&
    `
      border-top-left-radius: ${size === 'small' ? 9999 : theme.borderRadiusDefault}px;
      border-bottom-left-radius: ${size === 'small' ? 9999 : theme.borderRadiusDefault}px;
    `};

  ${({ theme, roundRight, size }) =>
    roundRight &&
    `
      border-top-right-radius: ${size === 'small' ? 9999 : theme.borderRadiusDefault}px;
      border-bottom-right-radius: ${size === 'small' ? 9999 : theme.borderRadiusDefault}px;
    `};
`;

const background = css<BarPartProps>`
  background: ${({ theme, color, appearance = 'filled' }) => {
    if (appearance === 'striped') {
      return `repeating-linear-gradient(
						-45deg,
						transparent,
						transparent 2px,
            ${parseColor(theme, color)} 2px,
						${parseColor(theme, color)} 4px
					)
  `;
    }
    return color ? parseColor(theme, color) : theme.backgroundProgressBarMeter;
  }};
`;

interface BarPartProps {
  appearance?: 'filled' | 'striped';
  color: string;
  size: 'small' | 'default';
}

export const BarPart = styled(animated.div)<BarPartProps>`
  height: ${({ size }) => (size === 'small' ? 8 : 18)}px;
  ${background};
`;

export const SectionContainer = styled.div`
  display: flex;
`;

export const SectionWrapper = styled(animated.div)`
  flex-grow: 1;
  margin-right: 2px;
  &:last-child {
    margin-right: 0;
  }
`;

export const SectionHeader = styled.div``;

export const Headers = styled.div<{ direction: 'ltr' | 'rtl' }>`
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'ltr' ? 'row' : 'row-reverse')};
  gap: ${({ theme }) => theme.spacingDefault}px;
`;

export const Label = styled.div`
  min-width: calc(3 * var(--baseSize) * 1px);
  text-align: right;
  font-size: calc(var(--fontSizeTiny) * 1rem);
  color: var(--colorTextSubtle);
`;

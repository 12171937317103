import type { PropsWithChildren } from 'react';
import { Children, createContext, useCallback, useContext, useMemo } from 'react';
import { AccordionGroup } from '../../Accordion';
import { TabPanels, Tabs, TabSize, usePersistedTabs, useTabs, type TabProps } from '../../Tabs';

export const EntityAdminTabsContext = createContext<
  | { tabItems: TabProps[]; selectedTab: TabProps; defaultInitialItems: TabProps[]; onAdd: (tab: TabProps) => void }
  | undefined
>(undefined);
EntityAdminTabsContext.displayName = 'EntityAdminTabsContext';

/**
 * A hook to access the EntityAdminTabsContext.
 * @returns EntityAdminTabsContext if the component is wrapped in the EntityAdminTabsWrapper
 * @returns undefined if the component is not wrapped in the EntityAdminTabsWrapper
 */
export function useEntityAdminTabsContext() {
  return useContext(EntityAdminTabsContext);
}

const DEFAULT_ALL_TAB: TabProps = { label: 'All', tabType: 'All', closable: true, editable: true };

interface EntityAdminTabsWrapperProps {
  tabItems?: TabProps[];
  persistKey?: string;
  allowAddTabs?: boolean;
}

const EntityAdminTabsAndContextWrapper = ({
  tabItems,
  persistKey,
  allowAddTabs = true,
  children,
}: PropsWithChildren<EntityAdminTabsWrapperProps>) => {
  const childrenArray: React.ReactNode[] = Children.toArray(children);

  const defaultInitialItems = useMemo(() => {
    return tabItems?.map(item => ({ tabType: item.label, ...item })) ?? [DEFAULT_ALL_TAB];
  }, [tabItems]);

  if (allowAddTabs && !persistKey) {
    throw new Error('persistKey must be provided when allowAddTabs is true');
  }

  if (!allowAddTabs && !defaultInitialItems) {
    throw new Error('staticTabItems must be provided when allowAddTabs is false');
  }

  const persistedTabs = usePersistedTabs<TabProps>(persistKey!, { defaultInitialItems });

  const tabs = useTabs<TabProps>({
    ...persistedTabs,
    showAddTab: allowAddTabs,
    allowClosingLastTab: false,
  });

  const renderCorrectTab = useCallback(
    (tab: TabProps) => {
      if (childrenArray.length === 1) {
        // Default behavior - Accordion Group handles which page to show
        return childrenArray.at(0);
      } else {
        // Custom static tabs behavior - Show the correct page based on the tabType
        const foundIndex = defaultInitialItems.findIndex(item => item.label === tab.tabType);
        if (foundIndex === -1) {
          throw new Error(`Tab with label ${tab.tabType} not found in tabItems`);
        }
        return childrenArray[foundIndex];
      }
    },
    [childrenArray, defaultInitialItems]
  );

  return (
    <EntityAdminTabsContext.Provider
      value={{
        tabItems: tabs.items,
        selectedTab: tabs.items[tabs.selectedIndex],
        defaultInitialItems,
        onAdd: tabs.onAdd,
      }}
    >
      <Tabs {...tabs} h="100%" size={TabSize.Large} data-testid="entity-admin-tabs">
        <TabPanels h="100%">
          {tabs.items.map(tab => (
            <AccordionGroup key={tab.id}>{renderCorrectTab(tab)}</AccordionGroup>
          ))}
        </TabPanels>
      </Tabs>
    </EntityAdminTabsContext.Provider>
  );
};

/**
 * Wrap the entire EntityAdmin component in this wrapper to enable the tabs context for this page.
 * @param tabItems - An array of TabProps to be used as tabs. If undefined, a default "All" tab will be created.
 */
export const EntityAdminTabsWrapper = EntityAdminTabsAndContextWrapper;

import type { FilterableProperty } from '@talos/kyoko';
import { IconName } from '@talos/kyoko';
import { useMemo } from 'react';
import { useAggregationsQuery } from '../useAggregationRequests';

/**
 * This hook creates the filter property definition for PricingAggregation properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for PricingAggregation filters
 */
export function usePricingAggregationsFilter<TKey extends string = 'PricingAggregations', P = FilterableProperty<TKey>>(
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
): FilterableProperty<TKey> {
  const aggregationsQuery = useAggregationsQuery();
  const aggregationsByName = useMemo(
    () => new Map(aggregationsQuery.data?.map(agg => [agg.Name, agg])),
    [aggregationsQuery.data]
  );

  return useMemo(
    () => ({
      ...({
        key: 'PricingAggregations' as TKey,
        label: 'Pricing Aggregation',
        field: 'PricingAggregation',
        icon: IconName.CreditCard,
        options: aggregationsQuery.data?.map(aggregation => aggregation.Name) ?? [],
        getOptionLabel: aggName => aggregationsByName.get(aggName)?.DisplayName ?? aggName,
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [aggregationsQuery.data, aggregationsByName, overrides]
  );
}

import { type ConnectionType, useMarketAccountsContext, useMarketsContext } from '@talos/kyoko';
import { compact } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useMSSByAccountBySymbol } from '../../providers/MarketSecurityStatusProvider';
import { useMarketConfiguredCondition } from '../MarketSelector/conditions/useMarketConfiguredCondition';
import { useMarketOnlineCondition } from '../MarketSelector/conditions/useMarketOnlineCondition';
import { useMarketSupportedCondition } from '../MarketSelector/conditions/useMarketSupportedCondition';
import { useMSSCapabilityEnabledCondition } from '../MarketSelector/conditions/useMSSCapabilityEnabledCondition';
import { AvailabilityEnum, type AvailabilityResult } from '../MarketSelector/types';
import { evaluateAvailabilityConditions } from '../MarketSelector/utils/items';

/**
 * Returns a function that can be used to check the availability of a market.
 * Use it to see if a market supports the connectiontype. The symbol is used to narrow which market statuses to check.
 */
export function useGetSymbolMarketsAvailability({
  symbol,
  connectionType,
}: {
  symbol: string | undefined;
  connectionType: ConnectionType;
}) {
  const { marketAccountsByName } = useMarketAccountsContext();
  const { marketsByName } = useMarketsContext();
  const symbols = useMemo(() => compact([symbol]), [symbol]);
  const mssMap = useMSSByAccountBySymbol({ symbols, tag: 'useGetSymbolMarketsAvailability' });
  const marketSupportedCondition = useMarketSupportedCondition({
    connectionType,
  });
  const marketConfiguredCondition = useMarketConfiguredCondition({
    connectionType: connectionType,
  });

  const marketOnlineCondition = useMarketOnlineCondition({ connectionType });
  const mssCapabilityCondition = useMSSCapabilityEnabledCondition({
    mssByAccountBySymbol: mssMap,
    capability: 'Orders',
    symbols,
  });

  const conditions = useMemo(
    () => [marketSupportedCondition, marketConfiguredCondition, marketOnlineCondition, mssCapabilityCondition],
    [marketConfiguredCondition, marketSupportedCondition, marketOnlineCondition, mssCapabilityCondition]
  );

  return useCallback(
    function getSymbolMarketAvailability(marketName: string): AvailabilityResult {
      const maybeMarketAccount = marketAccountsByName.get(marketName);
      let maybeMarket = marketsByName.get(marketName);
      if (!maybeMarketAccount && !maybeMarket) {
        return { availability: AvailabilityEnum.Excluded, infoNode: `Market account ${marketName} not found` };
      }

      // if we have found a market account, get use the market name from the account.
      // if we have not found a market account, assume lookup by market name.
      maybeMarket = maybeMarketAccount ? marketsByName.get(maybeMarketAccount.Market) : maybeMarket;
      if (!maybeMarket) {
        return { availability: AvailabilityEnum.Excluded, infoNode: `Market account ${marketName} not found` };
      }

      return evaluateAvailabilityConditions({ market: maybeMarket, marketAccount: maybeMarketAccount, conditions });
    },
    [marketAccountsByName, marketsByName, conditions]
  );
}

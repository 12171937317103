import type { ReactNode } from 'react';
import { defineMessages } from 'react-intl';
import { getIntlKey } from '../../contexts';
import { useIntl } from '../../hooks';
import { EMPTY_OBJECT, isKeyIn } from '../../utils';
import { Button, type ButtonProps, ButtonVariants } from '../Button';
import { type BoxProps, HStack } from '../Core';
import { EllipsisText } from '../Form';
import { FormControlSizes } from '../Form/types';

export type QuickOptionsProps<T> = {
  options: {
    label: ReactNode;
    value: T;
  }[];

  onOptionClick: (value: T) => void;
  disabled?: boolean;
  tabbable?: boolean;
  isOptionDisabled?(value: T): boolean;
  equalWidths?: boolean;
  containerProps?: BoxProps;
} & Pick<ButtonProps, 'dim' | 'size'>;

const messages = defineMessages({
  bid: {
    id: 'QuickOptions.bid',
    defaultMessage: 'Bid',
  },
  offer: {
    id: 'QuickOptions.offer',
    defaultMessage: 'Offer',
  },
});

export const QuickOptions = <T,>({
  options,
  onOptionClick,
  disabled = false,
  tabbable = false,
  size = FormControlSizes.Tiny,
  dim,
  isOptionDisabled,
  equalWidths = false,
  containerProps = EMPTY_OBJECT,
}: QuickOptionsProps<T>) => {
  const { formatMessage } = useIntl();
  return (
    <HStack gap="spacingSmall" overflow="hidden" {...containerProps}>
      {options.map((option, i) => {
        const labelIntlKey = typeof option.label === 'string' ? getIntlKey(option.label) : '';
        return (
          <Button
            flex="1"
            // if we do the equalWidths mode we override with some more styles here to achieve it
            style={
              equalWidths
                ? {
                    flexBasis: '0',
                    flexGrow: 1,
                    minWidth: 0, // for some reason minWidth 0 is needed to achieve the equal-widths behavior :shrug:
                  }
                : EMPTY_OBJECT
            }
            key={i}
            data-testid={`QuickOptions-${option.value}`}
            onClick={() => onOptionClick(option.value)}
            variant={ButtonVariants.Default}
            size={size}
            dim={dim}
            disabled={disabled || isOptionDisabled?.(option.value)}
            tabIndex={tabbable ? undefined : -1}
          >
            <EllipsisText>
              {isKeyIn(labelIntlKey, messages) ? formatMessage(messages[labelIntlKey]) : option.label}
            </EllipsisText>
          </Button>
        );
      })}
    </HStack>
  );
};

import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { freeze } from 'immer';
import type { AppState } from 'providers/AppStateProvider/types';
import type { ActiveOrderCardsState, OrderCardData, RFQCardData } from './types';

export const initialState: ActiveOrderCardsState = {
  clearedIDs: new Set(),
  openOrdersData: new Map(),
  filledRFQsData: new Map(),
  expandedCardID: undefined,
  // openOrdersData contains the details for the cards pertaining to current user
  // however in order to have the ability to modify on behalf of other users, we still need to load in other user's card data on demand
  // so that when a user initiate modify workflow we can render the card on top of the order form
  otherOrdersData: new Map(),
};

export const activeOrderCardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    setOrderCardsData: (state, action: PayloadAction<Map<string, OrderCardData>>) => {
      state.openOrdersData = freeze(action.payload);
    },
    addOtherOrderCardsData: (state, action: PayloadAction<OrderCardData>) => {
      const key = action.payload.order.OrderID;
      const showDetails = state.otherOrdersData.get(key)?.showDetails || false;
      state.otherOrdersData.set(action.payload.order.OrderID, {
        ...action.payload,
        showDetails,
      });
    },
    setRFQCardsData: (state, action: PayloadAction<Map<string, RFQCardData>>) => {
      state.filledRFQsData = action.payload;
    },
    toggleShowDetails: (state, action: PayloadAction<string>) => {
      state.expandedCardID = state.expandedCardID === action.payload ? undefined : action.payload;
    },
    clearID: (state, action: PayloadAction<string>) => {
      state.clearedIDs = state.clearedIDs.add(action.payload);
    },
  },
});

export const { setOrderCardsData, setRFQCardsData, clearID, toggleShowDetails, addOtherOrderCardsData } =
  activeOrderCardsSlice.actions;

export const selectVisibleOrdersData = createSelector(
  (state: AppState) => state.cards.openOrdersData,
  (state: AppState) => state.cards.clearedIDs,
  (state: AppState) => state.cards.expandedCardID,
  (openOrdersData, clearedIDs, expandedCardID) => {
    return Array.from(openOrdersData.values())
      .filter(data => !clearedIDs.has(data.order.OrderID))
      .map(data => {
        if (data.order.OrderID === expandedCardID) {
          return { ...data, showDetails: true };
        }
        return data;
      });
  }
);

export const selectVisibleRFQsData = createSelector(
  (state: AppState) => state.cards.filledRFQsData,
  (state: AppState) => state.cards.clearedIDs,
  (state: AppState) => state.cards.expandedCardID,
  (filledRFQsData, clearedIDs, expandedCardID) => {
    return Array.from(filledRFQsData.values())
      .filter(data => !clearedIDs.has(data.quote.RFQID))
      .map(data => {
        if (data.quote.RFQID === expandedCardID) {
          return { ...data, showDetails: true };
        }
        return data;
      });
  }
);

import type { GetContextMenuItemsParams, GridApi, GridOptions } from 'ag-grid-community';
import { cloneDeep, compact, get } from 'lodash-es';
import { useCallback, useMemo, useRef, useState } from 'react';
import { map, pipe } from 'rxjs';
import { useConstant, useDynamicCallback, useJsonModal, useWSFilterPipe } from '../../hooks';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../utils';
import {
  baseTreeGroupColumnDef,
  BLOTTER_SELECTION_MULTI_PARAMS_WITH_CHECKBOXES,
  BlotterDensity,
  DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
  filterExistsAndExcludes,
  getRowNodesToOperateOn,
  useGenericFilter,
  useGetDefaultContextMenuItems,
  usePersistedBlotterTable,
  type ColumnDef,
  type CompositePipeFunction,
  type GenericFilter,
  type UseBlotterTableProps,
} from '../BlotterTable';
import {
  ENTITY_INTERNAL_ROW_ID,
  EntityAdminClass,
  type EntityAdminBlotterProps,
  type EntityAdminRecord,
} from './types';
import {
  applyInheritanceCellStyle,
  getAddChildEntityColumn,
  getDeleteColumn,
  getEditColumn,
  getEntitiesByParentIDMap,
  getModeColumn,
  getShowJSONContextItem,
  onlyServerFilterEntries,
} from './utils';
import { useEntityAdminTabsContext } from './wrappers/EntityAdminTabsWrapper';

export const useEntityAdminBlotterProps = <TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord>(
  props: Omit<EntityAdminBlotterProps<TRecord, TDrawerRecord>, 'getEntityAsDrawerEntity' | 'renderDrawer'> & {
    getEntityAsDrawerEntity: NonNullable<EntityAdminBlotterProps<TRecord, TDrawerRecord>['getEntityAsDrawerEntity']>;
  }
) => {
  const {
    childIDField,
    entityIDField,
    filterableProperties,
    filterableServerFields = EMPTY_ARRAY,
    allowEditEntity = false,
    groupColumnDef: _groupColumnDef = EMPTY_OBJECT,
    getContextMenuItems: _getContextMenuItems,
    density = BlotterDensity.Comfortable,
    allowAddEntity = false,
    allowDeleteEntity = false,
    allowModeSwitch = false,
    allowBulkEdit = false,
    allowShowJSON = true,
    allowPauseResume = false,
    addChildEntityButtonProps,
    persistKey: _persistKey = null,
    openEntityDrawer,
    openBulkEditDrawer,
    getEntityAsDrawerEntity,
    handleOnDeleteEntitiesWithConfirmation,
    handleOnUpdateEntity,
    bulkEditRows,
    setBulkEditRows,
    isEntityAdminDrawerOpen,
    closeEntityDrawer,
  } = props;

  if ((allowAddEntity || allowEditEntity || allowDeleteEntity) && props.path == null) {
    throw new Error('path is required when allowAddEntity, allowEditEntity, or allowDeleteEntity is true');
  }

  type OnRowDoubleClicked = NonNullable<GridOptions<EntityAdminClass<TRecord>>['onRowDoubleClicked']>;
  const onRowDoubleClicked = useDynamicCallback<OnRowDoubleClicked>(({ data: entityClass, node }) => {
    if (entityClass && allowEditEntity) {
      openEntityDrawer(entityClass.data, false);
      node.setSelected(true, true);
    }
  });

  const [selectAllEntityType, setSelectAllEntityType] = useState<'parents' | 'children' | null>('parents');
  type OnSelectionChanged = NonNullable<GridOptions<EntityAdminClass<TRecord>>['onSelectionChanged']>;
  const onSelectionChanged = useDynamicCallback<OnSelectionChanged>(params => {
    const selectedChildrenNodes = getRowNodesToOperateOn(params).filter(node => node.data?.isChildRow);
    if (childIDField == null) {
      // If childIDField is not provided, the data is not hierarchical.
      params.api.setNodesSelected({ nodes: selectedChildrenNodes, newValue: true });
    } else {
      const selectedParentNodes = getRowNodesToOperateOn(params, 0).filter(node => node.data?.isParentRow);
      if (selectedChildrenNodes.length > 0 && selectedParentNodes.length > 0) {
        // Resolve crash - children and parent rows cannot be selected at the same time
        switch (params.source) {
          case 'uiSelectAll': {
            // Select all logic, toggles between parent rows, child rows, and no rows selected
            if (selectAllEntityType === 'parents') {
              params.api.setNodesSelected({ nodes: selectedChildrenNodes, newValue: false });
              params.api.setNodesSelected({ nodes: selectedParentNodes, newValue: true });
              setSelectAllEntityType('children');
            } else if (selectAllEntityType === 'children') {
              params.api.setNodesSelected({ nodes: selectedParentNodes, newValue: false });
              params.api.setNodesSelected({ nodes: selectedChildrenNodes, newValue: true });
              params.api.expandAll();
              setSelectAllEntityType(null);
            } else if (selectAllEntityType == null) {
              params.api.deselectAll();
              closeEntityDrawer();
              setSelectAllEntityType('parents');
            }
            break;
          }
          default: {
            // Check box manual selection logic, detect if the new selection includes a parent row
            const newSelectionHasChildRow = [...selectedChildrenNodes, ...selectedParentNodes]
              // Only consider newly selected nodes
              .filter(node => node.data?.data && !bulkEditRows.includes(getEntityAsDrawerEntity(node.data.data)))
              .some(node => node.data?.isChildRow);
            if (newSelectionHasChildRow) {
              // New selection includes a child row, deselect all parent rows
              params.api.setNodesSelected({ nodes: selectedChildrenNodes, newValue: true });
              params.api.setNodesSelected({ nodes: selectedParentNodes, newValue: false });
            } else {
              // New selection includes a parent row, deselect all child rows
              params.api.setNodesSelected({ nodes: selectedParentNodes, newValue: true });
              params.api.setNodesSelected({ nodes: selectedChildrenNodes, newValue: false });
            }

            // Update the select all entity type based on the new selection type
            setSelectAllEntityType(newSelectionHasChildRow ? 'children' : 'parents');
            break;
          }
        }
      }
    }

    updateSelectedNodes(params);
  });

  const updateSelectedNodes = useDynamicCallback((params: { api: GridApi<EntityAdminClass<TRecord>> }) => {
    const selectedNodes = params.api
      .getSelectedNodes()
      .map(node => node.data)
      .filter(entityClass => entityClass != null);

    if (selectedNodes.length === 0) {
      // If no rows are selected, reset the select all entity type
      setSelectAllEntityType('parents');
    }

    // End of bulk edit selection logic - update the selected rows
    setBulkEditRows(selectedNodes.map(entityClass => getEntityAsDrawerEntity(entityClass.data)));

    if (isEntityAdminDrawerOpen && allowEditEntity) {
      if (selectedNodes.length > 1) {
        // If the drawer is open and editing is allowed, update the drawer's selected rows
        openBulkEditDrawer(selectedNodes.map(entityClass => entityClass.data));
      } else if (selectedNodes.length === 1) {
        openEntityDrawer(selectedNodes[0].data, false);
      }
    }
  });

  const getDataPath = useDynamicCallback((data: TRecord): TRecord[keyof TRecord][] => {
    // If childIDField is provided, the data is hierarchical.
    if (childIDField != null && get(data, childIDField)) {
      return [get(data, entityIDField), get(data, childIDField)] satisfies TRecord[keyof TRecord][];
    }
    // If childIDField is provided but the childIDField is null, the row is a parent row.
    return [get(data, entityIDField)] satisfies TRecord[keyof TRecord][];
  });

  const getUniqueKey = useDynamicCallback((entity: TRecord) => getDataPath(entity).join('-'));

  const styledColumns = useMemo(() => {
    if (childIDField != null) {
      // If childIDField is provided, the data is hierarchical.
      return props.columns?.map(applyInheritanceCellStyle) ?? EMPTY_ARRAY;
    }
    return props.columns ?? EMPTY_ARRAY;
  }, [props.columns, childIDField]);

  const tabsContext = useEntityAdminTabsContext();
  const persistKey = tabsContext && _persistKey ? `${_persistKey}/${tabsContext.selectedTab.id}` : _persistKey;
  const persistedTable = usePersistedBlotterTable<EntityAdminClass<TRecord>, TRecord>(persistKey, {
    columns: styledColumns,
    persistColumns: persistKey != null,
    persistFilter: persistKey != null,
    persistSort: persistKey != null,
  });

  const filterResults = useGenericFilter(filterableProperties, {
    initialFilter: persistedTable.initialFilter,
    onFilterChanged: persistedTable.onFilterChanged,
  });

  const filterFunc = useCallback(
    (entity: TRecord) => {
      let filteredOut = false;
      filterableProperties.forEach(property => {
        if (property.field == null) {
          throw new Error('Field is required for all filterable properties of EntityAdmin');
        }
        if (filterExistsAndExcludes(filterResults.filter, property.key, entity, property.field)) {
          filteredOut = true;
        }
      });
      return !filteredOut;
    },
    [filterResults.filter, filterableProperties]
  );

  const userFilterFunc = useMemo(() => props.userFilterFunc ?? (() => true), [props.userFilterFunc]);
  const filterPipe = useWSFilterPipe<TRecord>({ getUniqueKey, filterFunc });
  const parentMapRef = useRef<Map<keyof TRecord, TRecord> | undefined>();
  const blotterTablePipe: CompositePipeFunction<EntityAdminClass<TRecord>, TRecord> = useConstant(
    pipe(
      filterPipe,
      map(json => {
        parentMapRef.current = getEntitiesByParentIDMap(json.data, entityIDField, childIDField);
        return json;
      }),
      map(json => ({
        ...json,
        data: json.data
          .filter(item => userFilterFunc(item))
          .map(row => new EntityAdminClass<TRecord>(cloneDeep(row), entityIDField, childIDField, parentMapRef.current)),
      }))
    )
  );

  const treeDataProps: Pick<GridOptions<any>, 'autoGroupColumnDef' | 'treeData' | 'getDataPath'> | undefined =
    useMemo(() => {
      if (childIDField != null) {
        // If childIDField is provided, the data is hierarchical.
        return {
          autoGroupColumnDef: { ...baseTreeGroupColumnDef, ..._groupColumnDef },
          treeData: true,
          getDataPath: (entity: EntityAdminClass<TRecord>) => {
            return getDataPath(entity.data);
          },
        };
      }
      return undefined;
    }, [childIDField, getDataPath, _groupColumnDef]);

  const startingColumns = useMemo(() => {
    const colDefs: ColumnDef<TRecord>[] = [];

    if (allowModeSwitch) {
      colDefs.push(
        getModeColumn<TRecord>({
          handleOnSwitchMode: entity => handleOnUpdateEntity(getEntityAsDrawerEntity(entity)),
          updateSelectedNodes,
        })
      );
    }

    return colDefs;
  }, [allowModeSwitch, getEntityAsDrawerEntity, handleOnUpdateEntity, updateSelectedNodes]);

  const endingColumns = useMemo(() => {
    const colDefs: ColumnDef<TRecord>[] = [];

    if (allowAddEntity && childIDField != null) {
      colDefs.push(
        getAddChildEntityColumn<TRecord>({
          openEntityDrawer: openEntityDrawer,
          buttonProps: addChildEntityButtonProps,
          entityIDField: entityIDField,
          childIDField: childIDField,
        })
      );
    }

    if (allowEditEntity) {
      colDefs.push(
        getEditColumn<TRecord>({
          handleOnClick: (entity: TRecord) => {
            openEntityDrawer(entity, false);
          },
        })
      );
    }

    if (allowDeleteEntity) {
      colDefs.push(
        getDeleteColumn<TRecord>({
          handleOnClick: (entity: TRecord) => {
            handleOnDeleteEntitiesWithConfirmation([getEntityAsDrawerEntity(entity)]);
          },
        })
      );
    }

    return colDefs;
  }, [
    addChildEntityButtonProps,
    allowAddEntity,
    allowDeleteEntity,
    allowEditEntity,
    childIDField,
    entityIDField,
    getEntityAsDrawerEntity,
    handleOnDeleteEntitiesWithConfirmation,
    openEntityDrawer,
  ]);

  const serverFieldsFilter: GenericFilter = useMemo(() => {
    return onlyServerFilterEntries(
      filterResults.filter,
      filterableProperties.filter(p => filterableServerFields.includes(p.field!))
    );
  }, [filterResults.filter, filterableProperties, filterableServerFields]);

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const { handleClickJson, jsonModal } = useJsonModal();

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams<EntityAdminClass<TRecord>>) => {
    return compact([
      ...getDefaultContextMenuItems(params),
      allowShowJSON ? getShowJSONContextItem<EntityAdminClass<TRecord>>({ params, handleClickJson }) : undefined,
      ...(_getContextMenuItems?.(params) ?? EMPTY_ARRAY),
    ]);
  });

  const gridOptions: UseBlotterTableProps<EntityAdminClass<TRecord>, TRecord>['gridOptions'] = useMemo(
    () => ({
      ...treeDataProps,
      rowSelection: allowBulkEdit
        ? BLOTTER_SELECTION_MULTI_PARAMS_WITH_CHECKBOXES
        : DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
      selectionColumnDef: { pinned: 'left' },
      onRowDoubleClicked,
      getContextMenuItems,
      onSelectionChanged,
    }),
    [allowBulkEdit, treeDataProps, onRowDoubleClicked, getContextMenuItems, onSelectionChanged]
  );

  const pauseParams: UseBlotterTableProps<EntityAdminClass<TRecord>, TRecord>['pauseParams'] = useMemo(
    () => ({ showPauseButton: allowPauseResume }),
    [allowPauseResume]
  );

  const blotterTableProps: Omit<UseBlotterTableProps<EntityAdminClass<TRecord>, TRecord>, 'dataObservable'> & {
    // This conversion handled internally by us and each row is guaranteed a unique row ID
    rowID: typeof ENTITY_INTERNAL_ROW_ID;
    pipe: typeof blotterTablePipe;
  } = useMemo(
    () => ({
      rowID: ENTITY_INTERNAL_ROW_ID,
      density,
      pipe: blotterTablePipe,
      startingColumns,
      endingColumns,
      persistence: persistedTable,
      columns: persistedTable.columns,
      filter: serverFieldsFilter,
      gridOptions,
      pauseParams,
    }),
    [
      density,
      blotterTablePipe,
      startingColumns,
      endingColumns,
      persistedTable,
      serverFieldsFilter,
      gridOptions,
      pauseParams,
    ]
  );

  const sharedFiltersProps = useMemo(() => {
    return {
      ...props,
      allowBulkEdit,
      childIDField,
      allowModeSwitch,
      allowDeleteEntity,
      filterableProperties,
      allowPauseResume,
      filterResults,
      getEntityAsDrawerEntity,
    };
  }, [
    props,
    allowBulkEdit,
    childIDField,
    allowModeSwitch,
    allowDeleteEntity,
    filterableProperties,
    allowPauseResume,
    filterResults,
    getEntityAsDrawerEntity,
  ]);

  const value = useMemo(() => {
    return { blotterTableProps, sharedFiltersProps, jsonModal };
  }, [blotterTableProps, jsonModal, sharedFiltersProps]);

  return value;
};

import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { darkColors, mapDarkTheme } from './DarkTheme';

export const darkSeliniColors: Colors = {
  ...darkColors,

  green: {
    default: '#2e7e57',
    lighten: '#2E7D32',
    dim: setAlpha(0.4, '#2e7e57'),
    mute: setAlpha(0.1, '#2e7e57'),
  },
  red: {
    default: '#b74638',
    lighten: '#c6392f',
    dim: setAlpha(0.4, '#b74638'),
    mute: setAlpha(0.1, '#b74638'),
  },
  blue: {
    default: '#01579b',
    lighten: '#0277bd',
    dim: setAlpha(0.4, '#01579b'),
    mute: setAlpha(0.1, '#01579b'),
  },
  yellow: {
    default: '#f9a825',
    lighten: '#fbc02d',
    dim: setAlpha(0.4, '#f9a825'),
    mute: setAlpha(0.1, '#f9a825'),
  },

  primary: {
    default: '#283593',
    lighten: '#303f9f',
    dim: setAlpha(0.4, '#283593'),
    mute: setAlpha(0.1, '#283593'),
  },
  gray: {
    '000': '#020F21',
    '010': '#0C182A',
    main: '#121D2D',
    '020': '#1C2736',
    '030': '#283340',
    '040': '#303A46',
    '050': '#38424C',
    '060': '#414B55',
    '070': '#535C62',
    '080': '#818787',
    '090': '#A4A8A4',
    '100': '#FFFFEE',
  },
};

export const DarkSeliniCapitalTheme: DefaultTheme = mapDarkTheme(darkSeliniColors, {
  name: 'DarkSC',
  backgroundHeader: 'linear-gradient(300deg, #0A1F33, #08253F, #06355A, #0B3A60)',
  backgroundSidebar: 'linear-gradient(0deg, #0A1F33, #08253F, #06355A, #0B3A60)',
  backgroundBody: '#020F21',

  backgroundSidebarItemActive: darkSeliniColors.gray.main,
  backgroundSidebarItemHover: darkSeliniColors.gray.main,
  backgroundContent: 'linear-gradient(180deg, #121D2D, #0A1F33)',

  backgroundChartUpColor: darkSeliniColors.green.default,
  backgroundChartDownColor: darkSeliniColors.red.default,

  borderRadiusButtonDefault: 0,
  borderRadiusButtonLarge: 0,
  borderRadiusButtonSmall: 0,
  borderRadiusButtonTiny: 0,
  borderRadiusCard: 0,
  borderRadiusContent: 0,
  borderRadiusBig: 0,
  borderRadiusDefault: 0,
  borderRadiusHuge: 0,
  borderRadiusLarge: 0,
  borderRadiusMedium: 0,
  borderRadiusLayoutWrapper: 0,
  borderRadiusSmall: 0,
  borderRadiusTiny: 0,
});

import type { MouseEventHandler } from 'react';
import styled from 'styled-components';

import { Box, type BoxProps } from '../Core';
import { Icon, IconName } from '../Icons';

import type { ValueOf } from '../../utils';
import { IndicatorDot, IndicatorDotVariants } from '../IndicatorDot';
import { NotificationWrapper } from './styles';
import type { NotificationSizes } from './types';
import { NotificationVariants } from './types';

export interface NotificationInternalProps extends BoxProps {
  dismissable?: boolean;
  variant?: ValueOf<typeof NotificationVariants>;
  onRemove?: MouseEventHandler<HTMLDivElement>;
  size?: NotificationSizes;
}

/** Notification Toast display for Internal events (saves/updates/errors) */
export const NotificationInternalToast = styled(
  ({ children, dismissable, variant, size = 'Default', onRemove, ...props }: NotificationInternalProps) => {
    return (
      <NotificationWrapper size={size} data-testid="notification" {...props}>
        <IndicatorDot halo variant={fromNotificationVariantToIndicatorDotVariant(variant)} />
        <Box flex="1">{children}</Box>
        {dismissable && (
          <Icon
            data-testid="notification-close"
            onClick={onRemove}
            icon={IconName.Close}
            color="colors.gray['070']"
            size="fontSizeLarge"
            spaceBefore="auto"
          />
        )}
      </NotificationWrapper>
    );
  }
)``;

const fromNotificationVariantToIndicatorDotVariant = (
  notification: ValueOf<typeof NotificationVariants> = NotificationVariants.Default
): IndicatorDotVariants => {
  switch (notification) {
    case NotificationVariants.Negative:
      return IndicatorDotVariants.Negative;
    case NotificationVariants.Positive:
      return IndicatorDotVariants.Positive;
    case NotificationVariants.Warning:
      return IndicatorDotVariants.Warning;
    case NotificationVariants.Primary:
      return IndicatorDotVariants.Primary;
    case NotificationVariants.Attention:
      return IndicatorDotVariants.Notification;
    case NotificationVariants.Default:
    default:
      return IndicatorDotVariants.Default;
  }
};

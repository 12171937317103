import {
  AutocompleteResults,
  Box,
  Button,
  Flex,
  FormControlSizes,
  type FuseSearchResult,
  HStack,
  Icon,
  IconButton,
  IconName,
  Input,
  MixpanelEvent,
  Text,
  useElementSize,
  useMixpanel,
  useSearchSelect,
  VStack,
} from '@talos/kyoko';
import { sortBy } from 'lodash-es';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useFavoriteAggregations } from '../../../providers/FavoriteAggregationsContext';
import { useMarketSelectorContext } from '../MarketSelectorContext';
import type { MarketSelectorCustomPresetItem } from '../types';

const ITEM_HEIGHT = 32;
const DEFAULT_CUSTOM_PRESET_ITEM: MarketSelectorCustomPresetItem = {
  id: '__custom__',
  label: 'Custom',
  onClick: api => api.unselectAll(),
  isSelected: 'fallback',
};

/**
 * The MarketSelectorPresetsList component renders the list of presets provided from the MarketSelectorContext.
 *
 * The list is implemented as a dynamically-sized FixedSizeList.
 */
export const MarketSelectorPresetsList = () => {
  const mixpanel = useMixpanel();
  const inputRef = useRef<HTMLInputElement>(null);
  const { favoriteAggregationsSet, favoriteAggregation } = useFavoriteAggregations();

  const marketSelectorAPI = useMarketSelectorContext();
  const { presets, preset, presetsByID, selectPreset, customPresetItems: inputCustomPresetItems } = marketSelectorAPI;

  const [searching, setSearching] = useState(false);

  const customPresetItems = useMemo(
    () => [DEFAULT_CUSTOM_PRESET_ITEM, ...sortBy(inputCustomPresetItems, item => item.label)],
    [inputCustomPresetItems]
  );
  const customPresetItemsByID = useMemo(
    () => new Map(customPresetItems.map(item => [item.id, item])),
    [customPresetItems]
  );

  const navigate = useNavigate();

  const handleSelectPreset = useCallback(
    (presetID: string | undefined) => {
      if (!presetID) {
        return;
      }

      const customPresetItem = customPresetItemsByID.get(presetID);
      if (customPresetItem) {
        customPresetItem.onClick(marketSelectorAPI);
      } else {
        selectPreset(presetID);
        mixpanel.track(MixpanelEvent.SelectAggregation);
      }
    },
    [selectPreset, customPresetItemsByID, marketSelectorAPI, mixpanel]
  );

  const getPresetLabel = useCallback(
    (presetID: string) => {
      return customPresetItemsByID.get(presetID)?.label ?? presetsByID?.get(presetID)?.label ?? presetID;
    },
    [customPresetItemsByID, presetsByID]
  );

  const items: string[] = useMemo(() => {
    const sortedBaseItems = sortBy(
      presets,
      // Sort by Favorite state first, label second
      preset => !favoriteAggregationsSet.has(preset.id), // "!" to reverse the sorting. Favorites on top.
      preset => preset.label
    ).map(preset => preset.id);
    sortedBaseItems.unshift(...customPresetItems.map(item => item.id));
    return sortedBaseItems;
  }, [presets, favoriteAggregationsSet, customPresetItems]);

  const selectedPreset: string | undefined = useMemo(() => {
    if (preset) {
      return preset;
    }

    const maybeFallbackItem = customPresetItems.find(item => item.isSelected === 'fallback');
    const maybeSelectedItem = customPresetItems.find(
      item => item.isSelected !== 'fallback' && item.isSelected(marketSelectorAPI)
    );
    return maybeSelectedItem?.id ?? maybeFallbackItem?.id;
  }, [preset, customPresetItems, marketSelectorAPI]);

  const autocomplete = useSearchSelect<string>({
    selectedItem: selectedPreset,
    items,
    getLabel: getPresetLabel,
    inputRef,
    onChange: handleSelectPreset,
    inputValueChangeOnItemSelection: 'keep',
    closeDropdownOnItemSelection: false,
    initialInputValue: '',
    initialSortByLabel: false,
  });

  const renderResult = useCallback(
    (searchResult: FuseSearchResult<string>, disabled: boolean) => {
      const isFavorite = favoriteAggregationsSet.has(searchResult.item.item);
      return (
        <AggregationListItem
          favorite={isFavorite}
          onFavoriteClicked={() => {
            favoriteAggregation(searchResult.item.item, !isFavorite);
            mixpanel.track(MixpanelEvent.FavoriteAggregation);
          }}
          searchResult={searchResult}
          selection={selectedPreset}
          customPresetItemsByID={customPresetItemsByID}
        />
      );
    },
    [selectedPreset, favoriteAggregationsSet, favoriteAggregation, customPresetItemsByID, mixpanel]
  );

  // We listen to the size of the flex=1 box wrapping the FixedSizeList such that we can make that FixedSizeList, which can't dynamically grow to fit
  // available space, become dynamically sized.
  const { elementRef, size } = useElementSize<HTMLDivElement>();

  const showNoAggregations = inputCustomPresetItems.length === 0 && (presets == null || presets.length === 0);

  return (
    <Flex flexDirection="column" w="100%" fontSize="fontSizeSm" h="100%" overflow="auto" flex="1" position="relative">
      <Flex
        {...autocomplete.getMenuProps()}
        flexDirection="column"
        overflow="auto"
        position="absolute"
        top="0"
        right="0"
        bottom="0"
        left="0"
      >
        {!searching && (
          <HStack
            px="spacingComfortable"
            minHeight="40px"
            w="100%"
            justifyContent="space-between"
            borderBottom="1px solid var(--backgroundDivider)"
          >
            <Text textTransform="uppercase" color="colorTextSubtle" fontSize="fontSizeTiny">
              Aggregations
            </Text>
            <IconButton
              icon={IconName.Search}
              size={FormControlSizes.Tiny}
              dim
              onClick={() => {
                setSearching(true);
                mixpanel.track(MixpanelEvent.SearchForAggregation);
              }}
            />
          </HStack>
        )}
        <Box
          minHeight="40px"
          display={searching ? 'flex' : 'none'}
          alignItems="center"
          background="backgroundInput"
          pr="spacingComfortable"
          borderBottom="1px solid var(--backgroundDivider)"
        >
          <Input
            {...autocomplete.getInputProps({
              ref: inputRef,
              style: {
                display: searching ? 'flex' : 'none',
                border: 'none',
                borderRadius: '0',
                background: 'transparent',
              },
            })}
            placeholder="Search..."
            prefix={<Icon icon={IconName.Search} />}
          />
          <IconButton
            icon={IconName.Close}
            size={FormControlSizes.Tiny}
            dim
            onClick={() => {
              autocomplete.setInputValue('');
              setSearching(false);
            }}
          />
        </Box>
        {showNoAggregations ? (
          <VStack w="100%">
            <Text fontSize="fontSizeSmall" p="spacingComfortable">
              No relevant aggregations found
            </Text>
            <Button
              startIcon={IconName.Exit}
              size={FormControlSizes.Tiny}
              onClick={() => navigate('/settings/trading-aggregations')}
            >
              Manage Aggregations
            </Button>
          </VStack>
        ) : (
          <Box flex="1" ref={elementRef}>
            <AutocompleteResults
              {...autocomplete}
              maxHeight={size.clientHeight ?? 1000} // this is the dynamic height of the Box just above here (elementRef)
              groupMaxHeight={1000} // this just needs to be taller than the parent element will ever be
              itemSize={ITEM_HEIGHT}
              renderResult={renderResult}
              showBorderSelectionIndicator
              isOpen // always true
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

const AggregationListItem = ({
  selection,
  searchResult,
  favorite,
  onFavoriteClicked,
  customPresetItemsByID,
}: {
  selection: string | undefined;
  searchResult: FuseSearchResult<string>;
  favorite: boolean;
  onFavoriteClicked: () => void;
  customPresetItemsByID: Map<string, MarketSelectorCustomPresetItem>;
}) => {
  const theme = useTheme();
  const { presetsByID } = useMarketSelectorContext();
  const presetID = searchResult.item.item;
  const selected = selection === presetID;

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      // We dont want the click to favorite to also select the item, so stop the propagation
      e.stopPropagation();
      onFavoriteClicked();
    },
    [onFavoriteClicked]
  );

  const label = customPresetItemsByID.get(presetID)?.label ?? presetsByID?.get(presetID)?.label;
  if (!label) {
    return null;
  }

  return (
    <HStack justifyContent="space-between" gap="spacingDefault" data-testid="aggregation-list-item">
      <HStack gap="spacingDefault" w="100%" overflow="hidden" justifyContent="flex-start">
        {!customPresetItemsByID.has(presetID) && (
          <Icon
            icon={favorite ? IconName.StarSolid : IconName.Star}
            size={14}
            color={favorite ? theme.colors.yellow.default : theme.colorTextMuted}
            onClick={handleClick}
          />
        )}
        <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" color="colorTextImportant">
          {label}
        </Text>
      </HStack>
      {selected && <Icon icon={IconName.ChevronRight} />}
    </HStack>
  );
};

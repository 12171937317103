import { type Column, getAgGridColId } from '@talos/kyoko';
import { compact } from 'lodash-es';
import type { MarketSelectorEnrichmentSpec } from '../types';

/** This function returns how much width to add to the Panel dropdown based on what enrichment columns we'll be showing to the user */
export function getAdditionalPanelWidthFromEnrichments(
  advancedMode: boolean,
  enrichments: MarketSelectorEnrichmentSpec<unknown>[]
): number {
  const columnsByColID = new Map<string, Column>();
  enrichments.forEach(spec => spec.columns.forEach(c => columnsByColID.set(getAgGridColId(c), c)));

  const simpleModeColIDs = compact(
    enrichments.flatMap(spec => (advancedMode ? spec.advancedModeVisibleColIDs : spec.simpleModeVisibleColIDs))
  );
  const additionalWidth = simpleModeColIDs.reduce((total, colID) => {
    const column = columnsByColID.get(colID);
    return total + (column?.width ?? 100); // arbitrary defaulting... i think this is fine, this'll be manageable
  }, 0);

  return additionalWidth;
}

import { get, keys, uniq } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { ReplaySubject } from 'rxjs';
import { useEndpointsContext } from '../../contexts';
import { useEffectOnce } from '../../hooks';
import { useGlobalToasts } from '../../hooks/Toast/useGlobalToasts';
import { useConstant } from '../../hooks/useConstant';
import { useObservable } from '../../hooks/useObservable';
import type { MinimalSubscriptionResponse } from '../../types';
import { EMPTY_ARRAY } from '../../utils';
import { request as sendRequest } from '../../utils/http';
import { NotificationVariants } from '../Notification';
import type { Column } from './columns';
import { DEFAULT_MAX_ROWS } from './helpers';
import type { BlotterTableFilter, BlotterTableSort, ColumnOrColumnGroup, CompositePipeFunction } from './types';
import { useBlotterTable } from './useBlotterTable';
import type { UseBlotterTable, UseBlotterTableProps } from './useBlotterTable/types';
import type { UsePersistedBlotterTable } from './usePersistedBlotterTable';

interface RestBlotterRequest {
  baseUrl?: string;
  path: string;
  filters?: any;
  sortBy?: string;
  limit?: number;
  next?: string;
}

export type UseRestBlotterTableProps<TRowOutputType, TRowInputType = TRowOutputType> = Omit<
  UseBlotterTableProps<TRowOutputType>,
  'dataObservable' | 'persistence'
> & {
  rowID: string;
  request: RestBlotterRequest;
  startingColumns?: Column[]; // Columns to be added at the start of the inferred columns
  columns?: UseBlotterTableProps<TRowOutputType>['columns']; // Columns to be added after the inferred columns.
  endingColumns?: Column[]; // Columns to be added at the end of the inferred columns.
  onColumnsReady?: (columns: Column[]) => void;
  initialSort?: BlotterTableSort<TRowOutputType>;
  blotterRowCount?: number;
  filter?: BlotterTableFilter;
  pipe: CompositePipeFunction<TRowOutputType, TRowInputType>;
  persistence?: UsePersistedBlotterTable<TRowOutputType, TRowInputType>;
};

const columnKeyToTypeMap: Record<string, Column['type']> = {
  Symbol: 'security',
  Timestamp: 'date',
  Mode: 'mode',
  Counterparty: 'counterparty',
};

const getColumnType = (key: string): Column['type'] => {
  return get(columnKeyToTypeMap, key) ?? 'text';
};

export function useRestBlotterTable<TRowOutputType, TRowInputType = TRowOutputType>({
  request,
  rowID,
  columns,
  startingColumns = EMPTY_ARRAY,
  endingColumns = EMPTY_ARRAY,
  onColumnsReady,
  blotterRowCount = DEFAULT_MAX_ROWS,
  filter,
  pipe: convertInputToOutputPipe,
  ...props
}: UseRestBlotterTableProps<TRowOutputType, TRowInputType>): UseBlotterTable<TRowOutputType> & {
  // Refreshes the Rest API call. If force is true, it will clear the blotter AND make a new request.
  // We need to clear the blotter when deleting a row, for example.
  refresh: (force?: boolean) => void;
} {
  const { orgApiEndpoint } = useEndpointsContext();
  const baseUrl = request.baseUrl ?? orgApiEndpoint;
  const { add: addToast, remove: removeToast } = useGlobalToasts();

  const dataSubject = useConstant(new ReplaySubject<MinimalSubscriptionResponse<TRowInputType>>(1));
  const dataObservable = useObservable(() => dataSubject.asObservable(), [dataSubject]);

  const pipedSubscription = useObservable(
    () => dataObservable.pipe(convertInputToOutputPipe),
    [dataObservable, convertInputToOutputPipe]
  );

  const [activeRequest, setActiveRequest] = useState({ ...request, filters: filter });
  const [activeColumns, setActiveColumns] = useState<ColumnOrColumnGroup[]>(
    columns?.length ? [...startingColumns, ...columns, ...endingColumns] : EMPTY_ARRAY
  );

  const setActiveColumnsFromResponseArray = useCallback(
    (data: TRowInputType[]) => {
      const uniqueFieldColumns: Column[] = [];
      startingColumns.forEach(column => {
        uniqueFieldColumns.push(column);
      });
      uniq(data.flatMap(item => keys(item)))
        .map<Column>(key => ({
          type: getColumnType(key),
          field: key,
          width: 120,
        }))
        .forEach(column => {
          uniqueFieldColumns.push(column);
        });
      endingColumns.forEach(column => {
        uniqueFieldColumns.push(column);
      });
      setActiveColumns(uniqueFieldColumns);
      onColumnsReady?.(uniqueFieldColumns);
    },
    [endingColumns, onColumnsReady, startingColumns]
  );

  const queryApi = useCallback(
    (req: RestBlotterRequest) => {
      const query = {
        ...req.filters,
      };
      if (req.limit) {
        query.limit = req.limit;
      }
      if (req.next) {
        query.after = req.next;
      }
      if (req.sortBy) {
        query.orderBy = req.sortBy;
      }
      const queryString = new URLSearchParams(query).toString();
      const url = queryString ? `${baseUrl}${req.path}?${queryString}` : `${baseUrl}${req.path}`;
      // Paginate until we fit the blotterRowCount. This is a "single page" of the blotter table
      return sendRequest<MinimalSubscriptionResponse<TRowInputType>>('GET', url, null, {
        paginateRecords: blotterRowCount,
      })
        .then(response => {
          if (activeColumns === EMPTY_ARRAY) {
            setActiveColumnsFromResponseArray(response.data);
          }
          dataSubject.next(response);
        })
        .catch(err => {
          removeToast('rest-blotter-table-error');
          addToast({
            text: err?.toString() ?? 'Check path: Could not fetch.',
            variant: NotificationVariants.Negative,
            id: 'rest-blotter-table-error',
          });
          dataSubject.next({ data: [], initial: true });
        });
    },
    [baseUrl, blotterRowCount, activeColumns, dataSubject, setActiveColumnsFromResponseArray, removeToast, addToast]
  );

  useEffect(() => {
    setActiveRequest(prev => ({ ...prev, filters: filter }));
  }, [filter]);

  const refresh = useCallback(
    (force?: boolean) => {
      force && dataSubject.next({ data: [], initial: true });
      queryApi(activeRequest);
    },
    [activeRequest, dataSubject, queryApi]
  );

  // Strict Mode change - this still works as intended.
  // - NOTE: While not critical, this useEffectOnce should be reinvestigated as changes to activeRequest thru the state are presently ignored,
  // but since we're storing the sort/filter state in the persistence and these blotters are relatively small, it's not a big deal at present
  // - consider RTK Query or similar for a more robust solution
  useEffectOnce(() => {
    if (activeRequest) {
      refresh();
    }
  });

  return {
    ...useBlotterTable({
      dataObservable: pipedSubscription,
      rowID,
      columns: activeColumns,
      initialSort: props.initialSort,
      ...props,
      //TODO: Change the blotter table props to align with the useBlotterTable props
      // - the Input/Output RowType generic used with persistence does not (yet) line up with the UseBlotterTableProps types
    } as UseBlotterTableProps<TRowOutputType>),
    refresh,
  };
}

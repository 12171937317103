export const Logo = ({ fill }: React.SVGProps<SVGPathElement>) => (
  <>
    <path
      d="M119.805 135.193C121.043 130.136 121.043 124.854 119.805 119.796C114.057 118.384 108.806 115.426 104.621 111.241C100.436 107.057 97.4761 101.806 96.0633 96.0587C91.2988 94.8938 86.332 94.8269 81.5379 95.863C76.7438 96.8991 72.2479 99.0111 68.3898 102.04C64.5316 105.068 61.4122 108.933 59.2671 113.344C57.122 117.755 56.0073 122.595 56.0073 127.5C56.0073 132.405 57.122 137.245 59.2671 141.656C61.4122 146.067 64.5316 149.932 68.3898 152.961C72.2479 155.989 76.7438 158.101 81.5379 159.137C86.332 160.173 91.2988 160.106 96.0633 158.941C97.4747 153.192 100.434 147.94 104.619 143.753C108.804 139.567 114.056 136.606 119.805 135.193V135.193Z"
      fill={fill}
      fillOpacity="0.9"
    />
    <path
      d="M119.805 119.803C118.392 114.056 115.432 108.805 111.247 104.62C107.062 100.435 101.811 97.4756 96.0633 96.0623C97.4766 101.81 100.436 107.06 104.621 111.245C108.807 115.43 114.058 118.39 119.805 119.803V119.803Z"
      fill={fill}
      fillOpacity="0.9"
    />
    <path
      d="M119.805 135.193C114.058 136.606 108.807 139.566 104.621 143.751C100.436 147.936 97.4766 153.187 96.0633 158.934C101.811 157.521 107.062 154.561 111.247 150.376C115.432 146.191 118.392 140.94 119.805 135.193V135.193Z"
      fill={fill}
      fillOpacity="0.9"
    />
    <path
      d="M135.202 135.193C130.144 133.955 124.863 133.955 119.805 135.193C118.392 140.94 115.432 146.191 111.247 150.376C107.062 154.561 101.811 157.521 96.0633 158.934C94.8984 163.698 94.8314 168.665 95.8676 173.459C96.9037 178.253 99.0157 182.749 102.044 186.607C105.073 190.465 108.938 193.584 113.349 195.729C117.76 197.874 122.601 198.989 127.505 198.989C132.41 198.989 137.251 197.874 141.662 195.729C146.072 193.584 149.938 190.465 152.967 186.607C155.995 182.749 158.107 178.253 159.143 173.459C160.179 168.665 160.112 163.698 158.947 158.934C153.199 157.522 147.947 154.563 143.761 150.378C139.575 146.193 136.615 140.941 135.202 135.193Z"
      fill={fill}
      fillOpacity="0.8"
    />
    <path
      d="M135.202 135.193C136.614 140.941 139.574 146.192 143.759 150.378C147.944 154.563 153.196 157.522 158.944 158.934C157.531 153.187 154.571 147.936 150.386 143.751C146.201 139.566 140.95 136.606 135.202 135.193V135.193Z"
      fill={fill}
      fillOpacity="0.8"
    />
    <path
      d="M119.805 135.193C114.058 136.606 108.807 139.566 104.621 143.751C100.436 147.936 97.4766 153.187 96.0633 158.934C101.811 157.521 107.062 154.561 111.247 150.376C115.432 146.191 118.392 140.94 119.805 135.193V135.193Z"
      fill={fill}
      fillOpacity="0.8"
    />
    <path
      d="M166.642 95.1307C164.05 95.1303 161.466 95.4419 158.947 96.0586C157.536 101.806 154.577 107.056 150.392 111.241C146.207 115.426 140.957 118.384 135.209 119.796C133.971 124.854 133.971 130.135 135.209 135.193C140.957 136.606 146.207 139.565 150.392 143.75C154.577 147.936 157.535 153.187 158.947 158.934C163.393 160.02 168.019 160.151 172.519 159.318C177.019 158.485 181.291 156.707 185.054 154.102C188.816 151.497 191.984 148.123 194.347 144.204C196.71 140.285 198.215 135.91 198.763 131.366C199.311 126.823 198.889 122.215 197.526 117.846C196.162 113.478 193.887 109.448 190.852 106.023C187.816 102.598 184.089 99.8557 179.916 97.9769C175.743 96.0981 171.219 95.1256 166.642 95.1235V95.1307Z"
      fill={fill}
      fillOpacity="0.7"
    />
    <path
      d="M135.202 135.193C136.614 140.941 139.574 146.192 143.759 150.378C147.944 154.563 153.196 157.522 158.944 158.934C157.531 153.187 154.571 147.936 150.386 143.751C146.201 139.566 140.95 136.606 135.202 135.193V135.193Z"
      fill={fill}
      fillOpacity="0.7"
    />
    <path
      d="M135.202 119.803C140.95 118.39 146.201 115.43 150.386 111.245C154.571 107.06 157.531 101.81 158.944 96.0623C153.196 97.4756 147.945 100.435 143.76 104.62C139.575 108.805 136.615 114.056 135.202 119.803V119.803Z"
      fill={fill}
      fillOpacity="0.7"
    />
    <path
      d="M119.805 119.803C118.392 114.056 115.432 108.805 111.247 104.62C107.062 100.435 101.811 97.4756 96.0633 96.0623C97.4766 101.81 100.436 107.06 104.621 111.245C108.807 115.43 114.058 118.39 119.805 119.803V119.803Z"
      fill={fill}
      fillOpacity="0.6"
    />
    <path
      d="M119.805 119.803C124.863 121.042 130.144 121.042 135.202 119.803C136.615 114.056 139.575 108.805 143.76 104.62C147.945 100.435 153.196 97.4756 158.944 96.0623C160.109 91.2979 160.176 86.3312 159.14 81.5372C158.103 76.7433 155.991 72.2475 152.963 68.3894C149.934 64.5314 146.069 61.412 141.658 59.267C137.247 57.1219 132.407 56.0073 127.502 56.0073C122.597 56.0073 117.756 57.1219 113.346 59.267C108.935 61.412 105.069 64.5314 102.041 68.3894C99.0122 72.2475 96.9001 76.7433 95.864 81.5372C94.8279 86.3312 94.8948 91.2979 96.0597 96.0623C101.808 97.4739 107.061 100.433 111.247 104.618C115.433 108.803 118.392 114.055 119.805 119.803V119.803Z"
      fill={fill}
      fillOpacity="0.6"
    />
    <path
      d="M135.202 119.803C140.95 118.39 146.201 115.43 150.386 111.245C154.571 107.06 157.531 101.81 158.944 96.0623C153.196 97.4756 147.945 100.435 143.76 104.62C139.575 108.805 136.615 114.056 135.202 119.803V119.803Z"
      fill={fill}
      fillOpacity="0.6"
    />
  </>
);

import {
  ACTION,
  DELETE,
  POST,
  PUT,
  SUB_ACCOUNT_WINDOW_LIMIT,
  request,
  useDynamicCallback,
  useEndpointsContext,
  useObservable,
  useObservableValue,
  useSubscription,
  wsScanToMap,
  type DataResponse,
  type RequiredProperties,
  type SubAccountWindowLimit,
} from '@talos/kyoko';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import { map, shareReplay, type Observable } from 'rxjs';
import { useRoleAuth } from '../hooks/useRoleAuth';

export const SubAccountWindowLimitsContext = createContext<SubAccountWindowLimitsContextProps | undefined>(undefined);
SubAccountWindowLimitsContext.displayName = 'SubAccountWindowLimitsProvider';

export interface SubAccountWindowLimitsContextProps {
  subAccountWindowLimitsList: SubAccountWindowLimit[] | undefined;
  /** Limits by sub account name. Does not include wildcards. */
  subAccountWindowLimitsBySubAccountNameObs: Observable<Map<string, SubAccountWindowLimit[]>>;

  // crud
  createSubAccountWindowLimit: (limit: Partial<SubAccountWindowLimit>) => Promise<DataResponse<SubAccountWindowLimit>>;
  updateSubAccountWindowLimit: (
    limit: RequiredProperties<Partial<SubAccountWindowLimit>, 'LimitID'>
  ) => Promise<SubAccountWindowLimit>;
  deleteSubAccountWindowLimit: (limitID: SubAccountWindowLimit['LimitID']) => Promise<unknown>;
}

export function useSubAccountWindowLimits() {
  const context = useContext(SubAccountWindowLimitsContext);
  if (context === undefined) {
    throw new Error('Missing SubAccountWindowLimitsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const SubAccountWindowLimitsProvider = function SubAccountWindowLimitsProvider({ children }: PropsWithChildren) {
  const { orgApiEndpoint } = useEndpointsContext();
  const endpoint = `${orgApiEndpoint}/subaccounts/window-limits`;
  const { isAuthorized } = useRoleAuth();

  const createSubAccountWindowLimit = useDynamicCallback((limit: Partial<SubAccountWindowLimit>) => {
    return request(POST, endpoint, limit);
  });

  const updateSubAccountWindowLimit = useDynamicCallback(
    (limit: RequiredProperties<Partial<SubAccountWindowLimit>, 'LimitID'>) => {
      return request(PUT, `${endpoint}/${limit.LimitID}`, limit);
    }
  );

  const deleteSubAccountWindowLimit = useDynamicCallback((limitID: SubAccountWindowLimit['LimitID']) => {
    return request(DELETE, `${endpoint}/${limitID}`);
  });

  const wsRequest = useMemo(
    () =>
      isAuthorized(ACTION.EDIT_LIMITS)
        ? { name: SUB_ACCOUNT_WINDOW_LIMIT, tag: 'SubAccountWindowLimitProvider' }
        : null,
    [isAuthorized]
  );
  const { data: subscription } = useSubscription<SubAccountWindowLimit>(wsRequest);

  const subAccountWindowLimitsByIDObs = useObservable(
    () => subscription.pipe(wsScanToMap({ getUniqueKey: l => l.LimitID, newMapEachUpdate: false }), shareReplay(1)),
    [subscription]
  );

  const subAccountWindowLimitsListObs = useObservable(
    () =>
      subAccountWindowLimitsByIDObs.pipe(
        map(map => [...map.values()]),
        shareReplay(1)
      ),
    [subAccountWindowLimitsByIDObs]
  );

  const subAccountWindowLimitsBySubAccountNameObs = useObservable(
    () =>
      subAccountWindowLimitsListObs.pipe(
        map(limits => {
          const map = new Map<string, SubAccountWindowLimit[]>();
          for (const limit of limits) {
            if (limit.SubAccount == null) {
              continue;
            }

            if (map.has(limit.SubAccount)) {
              map.get(limit.SubAccount)?.push(limit);
            } else {
              map.set(limit.SubAccount, [limit]);
            }
          }

          return map;
        })
      ),
    [subAccountWindowLimitsListObs]
  );

  const subAccountWindowLimitsList = useObservableValue(
    () => subAccountWindowLimitsListObs,
    [subAccountWindowLimitsListObs]
  );

  const value = useMemo(
    () => ({
      subAccountWindowLimitsList,
      subAccountWindowLimitsBySubAccountNameObs,
      createSubAccountWindowLimit,
      updateSubAccountWindowLimit,
      deleteSubAccountWindowLimit,
    }),
    [
      subAccountWindowLimitsList,
      subAccountWindowLimitsBySubAccountNameObs,
      createSubAccountWindowLimit,
      updateSubAccountWindowLimit,
      deleteSubAccountWindowLimit,
    ]
  );

  return <SubAccountWindowLimitsContext.Provider value={value}>{children}</SubAccountWindowLimitsContext.Provider>;
};

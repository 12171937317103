import { createContext, memo, useCallback, type PropsWithChildren } from 'react';
import { map, scan, shareReplay } from 'rxjs/operators';
import { v1 as uuid } from 'uuid';

import {
  CUSTOMER,
  CUSTOMER_ADDRESS,
  CUSTOMER_QUOTE,
  CUSTOMER_ROLE,
  CUSTOMER_USER,
  CustomerBalanceTransactionTypeEnum,
  DEFAULT_BIGGER_PAGINATION_SIZE,
  DELETE,
  GET,
  ModeEnum,
  PATCH,
  POST,
  PUT,
  TreasuryLinkDirectionEnum,
  TreasuryLinkTypeEnum,
  formattedDateForSubscription,
  request,
  useObservable,
  useStaticSubscription,
  useUserContext,
  wsScanToMap,
  type Customer,
  type CustomerQuote,
  type CustomerRole,
  type CustomerTradingLimit,
  type CustomerTransaction,
  type CustomerUser,
  type CustomerUserApiKey,
  type ICustomerAddress,
  type ICustomerFIXConnection,
  type ICustomerOrderExecutionRule,
} from '@talos/kyoko';
import { sortBy } from 'lodash-es';
import type {
  CustomerAddressResponse,
  CustomerQuoteResponse,
  CustomerTradeUpdate,
  CustomerTransferProps,
  CustomerUserConfig,
  CustomersContextProps,
  MarketAccountAuthorization,
} from './CustomersContext.types';

export const CustomersContext = createContext<CustomersContextProps | null>(null);
CustomersContext.displayName = 'CustomersContext';

export const CustomersProvider = memo(function CustomersProvider(props: PropsWithChildren<unknown>) {
  const { data: customersSubscription } = useStaticSubscription<Customer>(
    { name: CUSTOMER, IncludePricingRuleMode: true, tag: 'CustomersProvider', limit: DEFAULT_BIGGER_PAGINATION_SIZE },
    { loadAll: true }
  );

  const customers = useObservable(
    () =>
      customersSubscription.pipe(
        wsScanToMap({ getUniqueKey: d => d.CounterpartyID, newMapEachUpdate: false }),
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customersSubscription]
  );

  const { data: customerUsersSub } = useStaticSubscription<CustomerUser>(
    { name: CUSTOMER_USER, tag: 'CustomersProvider' },
    { loadAll: true }
  );

  const customerUsers = useObservable(
    () =>
      customerUsersSub.pipe(
        wsScanToMap({ getUniqueKey: d => d.CustomerUserID, newMapEachUpdate: false }),
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customerUsersSub]
  );

  const { data: customerRolesSub } = useStaticSubscription<CustomerRole>({
    name: CUSTOMER_ROLE,
    tag: 'CustomersProvider',
  });

  const customerRoles = useObservable(
    () =>
      customerRolesSub.pipe(
        wsScanToMap({ getUniqueKey: d => d.RoleID, newMapEachUpdate: false }),
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customerRolesSub]
  );

  const { orgApiEndpoint } = useUserContext();
  const customerEndpoint = `${orgApiEndpoint}/customer`;
  const customerUserEndpoint = `${orgApiEndpoint}/customer-users`;
  const customerConfigurationsEndpoint = `${orgApiEndpoint}/customer-configurations`;
  const customerBalancesEndpoint = `${orgApiEndpoint}/customer-balances`;
  const customerAddressesEndpoint = `${orgApiEndpoint}/customer-addresses`;
  const customerTreasuryEndpoint = `${orgApiEndpoint}/treasury`;
  const organizationEndpoint = `${orgApiEndpoint}/organization`;

  const getCustomerUserConfigsEndpoint = useCallback(
    (customerUserID: string) => `${customerUserEndpoint}/${customerUserID}/configs`,
    [customerUserEndpoint]
  );

  const getEffectiveLinkForCustomerTransaction = useCallback(
    (entity: CustomerTransaction) => {
      const params = new URLSearchParams({
        Currency: entity.Currency,
        Direction:
          entity.TransactionType === CustomerBalanceTransactionTypeEnum.Withdrawal
            ? TreasuryLinkDirectionEnum.Outbound
            : TreasuryLinkDirectionEnum.Inbound,
        MarketAccount: entity.MarketAccount,
        Type: TreasuryLinkTypeEnum.CustomerTransfer,
      });
      return request(GET, `${customerTreasuryEndpoint}/accounts/effective-link?${params.toString()}`);
    },
    [customerTreasuryEndpoint]
  );

  const createCustomerUser = useCallback(
    (customerUser: Partial<CustomerUser>) => request(POST, `${customerUserEndpoint}`, customerUser),
    [customerUserEndpoint]
  );
  const updateCustomerUser = useCallback(
    (customerUser: Partial<CustomerUser>) =>
      request(PATCH, `${customerUserEndpoint}/${customerUser.CustomerUserID}`, customerUser),
    [customerUserEndpoint]
  );
  const updateCustomerUserRole = useCallback(
    (customerUserID: string, initialRoles?: string[], newRoles?: string[]) => {
      const rolesToDisable = initialRoles?.filter(role => !newRoles?.includes(role)) ?? [];
      const rolesToEnable = newRoles?.filter(role => !initialRoles?.includes(role)) ?? [];
      return request(
        POST,
        `${customerUserEndpoint}/roles`,
        rolesToDisable
          .map(role => getRole(role, ModeEnum.Disabled))
          .concat(rolesToEnable.map(role => getRole(role, ModeEnum.Enabled)))
      );

      function getRole(role: string, mode: ModeEnum) {
        return {
          CustomerUserID: customerUserID,
          RoleID: role,
          Mode: mode,
        };
      }
    },
    [customerUserEndpoint]
  );
  const getOrderExecutionRulesGlobalStatus = useCallback(
    () =>
      request(GET, `${customerEndpoint}/order-execution-rules/global-status`, null).then(
        (response: { ExecutionRulesGlobalStatus: ModeEnum }) => {
          return response.ExecutionRulesGlobalStatus === ModeEnum.Enabled;
        }
      ),
    [customerEndpoint]
  );
  const updateOrderExecutionRulesGlobalStatus = useCallback(
    (status: 'enable' | 'disable') =>
      request(PATCH, `${customerEndpoint}/order-execution-rules/global-status/${status}`, null).then(
        (response: { ExecutionRulesGlobalStatus: ModeEnum }) => {
          return response.ExecutionRulesGlobalStatus === ModeEnum.Enabled;
        }
      ),
    [customerEndpoint]
  );
  const getCustomerRoles = useCallback(() => request(GET, `${customerUserEndpoint}/roles`), [customerUserEndpoint]);
  const getCustomerConfigs = useCallback(
    () => request(GET, customerConfigurationsEndpoint, null, { paginateRecords: Infinity }),
    [customerConfigurationsEndpoint]
  );
  const getCustomerUserConfigs = useCallback(
    (customerUserID: string): Promise<CustomerUserConfig[]> => {
      return request(GET, `${getCustomerUserConfigsEndpoint(customerUserID)}`).then(configs => configs.data);
    },
    [getCustomerUserConfigsEndpoint]
  );
  const upsertCustomerUserConfigs = useCallback(
    (configs: Array<CustomerUserConfig & { CustomerUserID: string }>): Promise<CustomerUserConfig[]> => {
      return request(POST, `${customerUserEndpoint}/configs`, configs).then(configs => configs.data);
    },
    [customerUserEndpoint]
  );
  const upsertCustomerUserConfig = useCallback(
    <T,>(customerUserID: string, configKey: string, value: T): Promise<CustomerUserConfig[]> => {
      return upsertCustomerUserConfigs([
        {
          CustomerUserID: customerUserID,
          Key: configKey,
          Value: JSON.stringify(value),
        },
      ]);
    },
    [upsertCustomerUserConfigs]
  );
  const removeCustomerUserConfig = useCallback(
    (customerUserID: string, configKey: string): Promise<null> => {
      return request(DELETE, `${customerUserEndpoint}/${customerUserID}/configs/${configKey}`, null);
    },
    [customerUserEndpoint]
  );
  const getCustomerUserAPIKeys = useCallback(
    (userID: string) => request(GET, `${customerUserEndpoint}/${userID}/api-key`, null),
    [customerUserEndpoint]
  );
  const createCustomerUserAPIKey = useCallback(
    (userID: string) => request(POST, `${customerUserEndpoint}/${userID}/api-key`, null),
    [customerUserEndpoint]
  );
  const deleteCustomerUserAPIKey = useCallback(
    (userID: string, apiKeyID: string) =>
      request(DELETE, `${customerUserEndpoint}/${userID}/api-key/${apiKeyID}`, null),
    [customerUserEndpoint]
  );
  const updateCustomerUserAPIKey = useCallback(
    (customerUserApiKey: CustomerUserApiKey) =>
      request(PATCH, `${customerUserEndpoint}/${customerUserApiKey.CustomerUserID}/api-key`, customerUserApiKey),
    [customerUserEndpoint]
  );
  const customerTradingLimitsEndpoint = `${customerEndpoint}/max-order-size-limits`;
  const createCustomerTradingLimit = useCallback(
    (customerUserTradingLimit: CustomerTradingLimit) =>
      request(POST, `${customerTradingLimitsEndpoint}`, customerUserTradingLimit),
    [customerTradingLimitsEndpoint]
  );
  const updateCustomerTradingLimit = useCallback(
    (customerUserTradingLimit: CustomerTradingLimit) =>
      request(
        PUT,
        `${customerTradingLimitsEndpoint}/${customerUserTradingLimit.TradingLimitID}`,
        customerUserTradingLimit
      ),
    [customerTradingLimitsEndpoint]
  );
  const deleteCustomerTradingLimit = useCallback(
    (tradingLimitID: string) => request(DELETE, `${customerTradingLimitsEndpoint}/${tradingLimitID}`, null),
    [customerTradingLimitsEndpoint]
  );

  const customerExecutionRulesEndpoint = `${customerEndpoint}/order-execution-rules`;
  const getCustomerExecutionRules = useCallback(
    () => request(GET, customerExecutionRulesEndpoint, null),
    [customerExecutionRulesEndpoint]
  );
  const createCustomerExecutionRule = useCallback(
    (customerUserExecutionRule: ICustomerOrderExecutionRule) =>
      request(POST, customerExecutionRulesEndpoint, customerUserExecutionRule),
    [customerExecutionRulesEndpoint]
  );
  const editCustomerExecutionRule = useCallback(
    (customerUserExecutionRule: ICustomerOrderExecutionRule) =>
      request(
        PATCH,
        `${customerExecutionRulesEndpoint}/${customerUserExecutionRule.RuleID}`,
        customerUserExecutionRule
      ),
    [customerExecutionRulesEndpoint]
  );
  const deleteCustomerExecutionRule = useCallback(
    (ruleID: string) => request(DELETE, `${customerExecutionRulesEndpoint}/${ruleID}`, null),
    [customerExecutionRulesEndpoint]
  );

  const customerFIXConnectionsEndpoint = `${organizationEndpoint}/customer-fix-connections`;
  const getCustomerFIXConnections = useCallback(
    () => request(GET, customerFIXConnectionsEndpoint, null),
    [customerFIXConnectionsEndpoint]
  );

  const upsertCustomerFIXConnection = useCallback(
    (customerFIXConnection: Partial<ICustomerFIXConnection>[]) =>
      request(PUT, customerFIXConnectionsEndpoint, customerFIXConnection),
    [customerFIXConnectionsEndpoint]
  );

  const deleteCustomerFIXConnection = useCallback(
    (targetCompID: string) => request(DELETE, `${customerFIXConnectionsEndpoint}/${targetCompID}`, null),
    [customerFIXConnectionsEndpoint]
  );

  const customerTradeEndpoint = `${customerEndpoint}/trade`;
  const createCustomerTrade = useCallback(
    (customerTrade: CustomerTradeUpdate) => request(POST, `${customerTradeEndpoint}`, customerTrade),
    [customerTradeEndpoint]
  );

  const customerTradesEndpoint = `${customerEndpoint}/trades`;
  const updateCustomerTrade = useCallback(
    (customerTrade: CustomerTradeUpdate) =>
      request(PATCH, `${customerTradesEndpoint}/${customerTrade.TradeID}`, customerTrade),
    [customerTradesEndpoint]
  );

  const customerOrdersEndpoint = `${customerEndpoint}/orders`;
  const modifyCustomerOrderSpread = useCallback(
    (orderID: string, spread: string) =>
      request(POST, `${customerOrdersEndpoint}/${orderID}/update-parameters`, {
        Spread: spread,
      }),
    [customerOrdersEndpoint]
  );

  const { data: customerQuoteSub } = useStaticSubscription<CustomerQuoteResponse>({
    name: CUSTOMER_QUOTE,
    tag: 'CustomersProvider',
    HideAPICalls: true,
    sort_by: '-Timestamp',
  });

  const { data: customerAddressSub } = useStaticSubscription<CustomerAddressResponse>({
    name: CUSTOMER_ADDRESS,
    tag: 'CustomersProvider',
  });

  const customerQuotesByRFQIDObs = useObservable<Map<string, CustomerQuote>>(
    () =>
      customerQuoteSub.pipe(
        scan(
          (quotes: Map<string, CustomerQuote>, json) =>
            json.data
              ? json.data.reduce(
                  (quotes: Map<string, CustomerQuote>, quote: CustomerQuote) => quotes.set(quote.RFQID, quote),
                  quotes
                )
              : quotes,
          new Map()
        ),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customerQuoteSub]
  );

  const customerAddressesByCustomerAddressID = useObservable(
    () =>
      customerAddressSub.pipe(
        wsScanToMap({ getUniqueKey: d => d.CustomerAddressID, newMapEachUpdate: true }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customerAddressSub]
  );

  const customerQuotesObs = useObservable(
    () => customerQuotesByRFQIDObs.pipe(map(quotes => sortBy([...quotes.values()], item => item.SubmitTime))),
    [customerQuotesByRFQIDObs]
  );

  const customerQuotesByRFQID = useObservable(() => customerQuotesByRFQIDObs, [customerQuotesByRFQIDObs]);

  const approvePendingApprovalTransaction = useCallback(
    (customerTransaction: CustomerTransaction) =>
      request(PUT, `${customerBalancesEndpoint}/transactions/${customerTransaction.TransactionID}/accept`, {
        TransferID: customerTransaction.TransferID,
      }),
    [customerBalancesEndpoint]
  );
  const approvePendingTransferTransaction = useCallback(
    (customerTransaction: CustomerTransaction, txhash?: string) =>
      request(PUT, `${customerTreasuryEndpoint}/transactions/${customerTransaction.TransactionID}/accept`, {
        TransferID: customerTransaction.TransferID,
        TxHash: txhash,
      }),
    [customerTreasuryEndpoint]
  );
  const initiatePendingTransferTransaction = useCallback(
    ({
      amount,
      clientID = uuid(),
      currency,
      description,
      fromMarketAccountID,
      market,
      reference,
      toMarketAccountID,
      transactionID,
      chainCurrency,
      transactTime = new Date(),
    }: CustomerTransferProps) =>
      request(POST, `${customerTreasuryEndpoint}/transactions/${transactionID}/transfer`, {
        ClientID: clientID,
        Currency: currency,
        Amount: amount,
        Market: market,
        TransactTime: formattedDateForSubscription(transactTime),
        FromMarketAccountID: fromMarketAccountID,
        ToMarketAccountID: toMarketAccountID,
        Description: description,
        ReferenceData: reference,
        ChainCurrencySymbol: chainCurrency,
      }),
    [customerTreasuryEndpoint]
  );
  const rejectPendingApprovalTransaction = useCallback(
    (customerTransaction: CustomerTransaction) =>
      request(PUT, `${customerBalancesEndpoint}/transactions/${customerTransaction.TransactionID}/reject`, {
        TransferID: customerTransaction.TransferID,
      }),
    [customerBalancesEndpoint]
  );

  const getCustomerWithdrawAddress = useCallback(
    (customerAddressID: string) => request(GET, `${customerAddressesEndpoint}/${customerAddressID}`),
    [customerAddressesEndpoint]
  );

  const createCustomerAddress = useCallback(
    (addressDetails: Partial<ICustomerAddress>) => request(POST, customerAddressesEndpoint, addressDetails),
    [customerAddressesEndpoint]
  );

  const rejectPendingTransferTransaction = useCallback(
    (customerTransaction: CustomerTransaction) =>
      request(PUT, `${customerTreasuryEndpoint}/transactions/${customerTransaction.TransactionID}/reject`, {
        TransferID: customerTransaction.TransferID,
      }),
    [customerTreasuryEndpoint]
  );

  const addOrDeleteMarketAccountAuthorization = useCallback(
    (customerUserID: string, authorizations: MarketAccountAuthorization[], type: 'add' | 'delete') => {
      return request(
        PATCH,
        `${customerUserEndpoint}/${customerUserID}/${type}-authorizations`,
        authorizations.map(authorization => ({
          CustomerUserID: customerUserID,
          Action: authorization.authorization,
          Filter: { Type: 'MarketAccount', Value: authorization.marketAccount },
        }))
      );
    },
    [customerUserEndpoint]
  );

  const addMarketAccountAuthorization = useCallback(
    (customerUserID: string, authorizations: MarketAccountAuthorization[]) => {
      return addOrDeleteMarketAccountAuthorization(customerUserID, authorizations, 'add');
    },
    [addOrDeleteMarketAccountAuthorization]
  );

  const deleteMarketAccountAuthorization = useCallback(
    (customerUserID: string, authorizations: MarketAccountAuthorization[]) => {
      return addOrDeleteMarketAccountAuthorization(customerUserID, authorizations, 'delete');
    },
    [addOrDeleteMarketAccountAuthorization]
  );

  return (
    <CustomersContext.Provider
      value={{
        customers,
        customerUsers,
        customerRoles,
        createCustomerUser,
        createCustomerTrade,
        updateCustomerTrade,
        updateCustomerUser,
        updateCustomerUserRole,
        getOrderExecutionRulesGlobalStatus,
        updateOrderExecutionRulesGlobalStatus,
        getCustomerRoles,
        getCustomerConfigs,
        getCustomerUserAPIKeys,
        getCustomerUserConfigs,
        getEffectiveLinkForCustomerTransaction,
        removeCustomerUserConfig,
        upsertCustomerUserConfigs,
        upsertCustomerUserConfig,
        createCustomerUserAPIKey,
        deleteCustomerUserAPIKey,
        updateCustomerUserAPIKey,
        createCustomerTradingLimit,
        updateCustomerTradingLimit,
        deleteCustomerTradingLimit,
        getCustomerExecutionRules,
        createCustomerExecutionRule,
        editCustomerExecutionRule,
        deleteCustomerExecutionRule,
        getCustomerFIXConnections,
        upsertCustomerFIXConnection,
        deleteCustomerFIXConnection,
        modifyCustomerOrderSpread,
        customerQuoteSub,
        customerQuotesObs,
        customerQuotesByRFQID,
        customerAddressesByCustomerAddressID,
        approvePendingApprovalTransaction,
        approvePendingTransferTransaction,
        initiatePendingTransferTransaction,
        rejectPendingApprovalTransaction,
        rejectPendingTransferTransaction,
        getCustomerWithdrawAddress,
        createCustomerAddress,
        addMarketAccountAuthorization,
        deleteMarketAccountAuthorization,
      }}
    >
      {props.children}
    </CustomersContext.Provider>
  );
});

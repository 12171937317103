import { EMPTY_ARRAY, logger, type Market, type MarketAccount, useMarketsContext } from '@talos/kyoko';
import { compact } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { AvailabilityCondition, MarketSelectorItem } from '../types';
import { buildMarketSelectorItem } from './items';

type useBuildMarketSelectorItemsParams = {
  /** The markets to build items from, if any */
  markets?: Market[];
  /** The market accounts to build items from, if any */
  marketAccounts?: MarketAccount[];
  /** Conditions to check each item against to evaluate their availability statuses */
  conditions: AvailabilityCondition[];
  /**
   * If provided, will wait this amount of ms for all conditions to become ready before evaluating and building items.
   * While the hook is waiting for all conditions to become ready, the output of the hook will be undefined.
   */
  waitForConditionsMs?: number;
};

/**
 * This hook will build your MarketSelectorItems given your inputs of markets and/or market accounts.
 *
 * The hook also supports use cases where you want to wait some amount of time for your availability conditions to all be ready
 * before producing an initial set of items. When `waitForConditionsMs` is provided, the hook will initially return `undefined`
 * until either all conditions are ready, or waitForConditionsMs time has elapsed.
 *
 * Note that this hook will only wait once initially. Once the hook has waited, it will never wait again if conditions go from
 * ready -> unready.
 */
export function useBuildMarketSelectorItems(
  params: useBuildMarketSelectorItemsParams & { waitForConditionsMs: number }
): MarketSelectorItem[] | undefined;
export function useBuildMarketSelectorItems(params: useBuildMarketSelectorItemsParams): MarketSelectorItem[];
export function useBuildMarketSelectorItems({
  markets = EMPTY_ARRAY,
  marketAccounts = EMPTY_ARRAY,
  conditions,
  waitForConditionsMs,
}: useBuildMarketSelectorItemsParams): MarketSelectorItem[] | undefined {
  const { marketsByName } = useMarketsContext();

  const items = useMemo(() => {
    const marketItems = markets.map(market => buildMarketSelectorItem({ market, conditions }));

    const missing: MarketAccount[] = [];
    const marketAccountItems = marketAccounts.map(marketAccount => {
      const market = marketsByName.get(marketAccount.Market);
      if (!market) {
        missing.push(marketAccount);
        return undefined;
      }

      return buildMarketSelectorItem({ market, marketAccount, conditions });
    });

    if (missing.length > 0) {
      logger.warn('Unable to find Markets for MarketAccounts', {
        missing: missing.map(ma => ({ MarketAccount: ma.Name, Market: ma.Market })),
      });
    }

    return compact([...marketItems, ...marketAccountItems]);
  }, [markets, marketAccounts, marketsByName, conditions]);

  const allConditionsReady = useMemo(() => conditions.every(c => c.ready), [conditions]);
  const [waitingState, setWaitingState] = useState<'none' | 'waiting' | 'waited'>('none');
  const waitTimer = useRef<ReturnType<typeof setTimeout>>();

  const awaitedItems = useMemo(() => {
    if (allConditionsReady || waitForConditionsMs == null) {
      return items;
    }

    // Else down here the conditions aren't ready and the implementer wants to wait for some ms
    if (waitingState === 'none') {
      setWaitingState('waiting');
      waitTimer.current = setTimeout(() => setWaitingState('waited'), waitForConditionsMs);
      return undefined;
    }

    if (waitingState === 'waiting') {
      return undefined;
    }

    if (waitingState === 'waited') {
      return items;
    }

    return undefined;
  }, [items, allConditionsReady, waitForConditionsMs, waitingState]);

  // simple clearing of the waitTimer so we dont attempt to update state after the hook is unmounted
  useEffect(() => {
    return () => {
      if (waitTimer.current) {
        clearTimeout(waitTimer.current);
      }
    };
  }, []);

  return awaitedItems;
}

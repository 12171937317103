import {
  ButtonGroup,
  Card,
  EmptyView,
  FormControlSizes,
  HStack,
  HelpIcon,
  Icon,
  IconName,
  LoaderTalos,
  SymbolDisplay,
  SymbolDisplaySize,
  Table,
  Tbody,
  Td,
  Th,
  ToggleButton,
  Tooltip,
  Tr,
  UnifiedLiquidityEnum,
  getSymbolsAcrossMarkets,
  isDelta1Multileg,
  toBigWithDefault,
  useSecuritiesContext,
  useStrategiesContext,
  type CardProps,
  type Order,
  type OrderAnalytic,
  type Security,
  type SideEnum,
} from '@talos/kyoko';
import Big from 'big.js';
import type { useLatestOrderAnalytics } from 'hooks/useLatestOrderAnalytics';
import React from 'react';
import { isMultiLegSecurity } from '../../utils/security';
import { SlippageText } from './SlippageText';
import { MetricLabel, Tables } from './styles';

export function KeyMetricsCard({
  order,
  isMultileg,
  analytics,
  ...props
}: {
  order: Order;
  isMultileg?: boolean;
  analytics: ReturnType<typeof useLatestOrderAnalytics>;
} & CardProps) {
  const [activeLeg, setActiveLeg] = React.useState<number>(isMultileg ? 0 : 1);
  const underlyingSymbols =
    order.unifiedLiquidity === UnifiedLiquidityEnum.Enabled && activeLeg === 0 ? getSymbolsAcrossMarkets(order) : [];

  const { latestAnalytics, receivedAnalytics } = analytics;
  if (latestAnalytics == null) {
    return (
      <Card title="Key Metrics" {...props}>
        {receivedAnalytics ? <EmptyView>No key metrics analytics available</EmptyView> : <LoaderTalos />}
      </Card>
    );
  }

  return (
    <Card
      title="Key Metrics"
      actions={
        isMultileg && (
          <ButtonGroup size={FormControlSizes.Small}>
            <ToggleButton data-testid="button-leg-0" onClick={() => setActiveLeg(0)} selected={activeLeg === 0}>
              Order
            </ToggleButton>
            <ToggleButton data-testid="button-leg-1" onClick={() => setActiveLeg(1)} selected={activeLeg === 1}>
              Leg 1
            </ToggleButton>
            <ToggleButton data-testid="button-leg-2" onClick={() => setActiveLeg(2)} selected={activeLeg === 2}>
              Leg 2
            </ToggleButton>
          </ButtonGroup>
        )
      }
      {...props}
    >
      {isMultileg ? (
        <>
          <HStack justifyContent="space-between">
            <SymbolDisplay
              symbol={analytics?.latestAnalytics?.[activeLeg]?.Symbol}
              showDescription={false}
              underlyingSymbols={underlyingSymbols}
              p="0"
              size={SymbolDisplaySize.Small}
            />
          </HStack>
          <KeyMetricsCardContent order={order} leg={activeLeg} latestAnalytics={latestAnalytics} />
        </>
      ) : (
        <KeyMetricsCardContent order={order} leg={1} latestAnalytics={latestAnalytics} />
      )}
    </Card>
  );
}

export function KeyMetricsCardContent({
  latestAnalytics,
  order,
  leg,
}: {
  order: Order;
  latestAnalytics: Record<number, OrderAnalytic>;
  leg: number;
} & CardProps) {
  const { securitiesBySymbol } = useSecuritiesContext();
  const { strategiesByName } = useStrategiesContext();
  const security = securitiesBySymbol.get(order.Symbol);
  let orderAnalytics = latestAnalytics[leg];
  let parentAnalytic = latestAnalytics[0];
  if (isDelta1Multileg(security)) {
    orderAnalytics = latestAnalytics[leg || 1];
    parentAnalytic = latestAnalytics[leg];
  }

  // Use order.Side for parent order, and order.legSummaryLegs[leg - 1].Side for leg orders when dealing with Delta-1 Multileg
  const side =
    isDelta1Multileg(security) && order.legSummaryLegs && leg > 0 ? order.legSummaryLegs[leg - 1].Side : order.Side;

  if (!orderAnalytics) {
    return null;
  }

  const strategy = order.Strategy ? strategiesByName.get(order.Strategy) : null;
  const showSweepPx = !isMultiLegSecurity(security) && strategy;

  return (
    <Tables>
      {leg !== 0 && (
        <Table>
          <Tbody>
            <Tr>
              <Th>Participation</Th>
              <Th align="right">%</Th>
            </Tr>
            <Tr>
              <Td>Global</Td>
              <Td align="right">
                {`${Big(orderAnalytics.GlobalParticipationRate || 0)
                  .times(100)
                  .toFixed(2)}`}
              </Td>
            </Tr>
            <Tr>
              <Td>Order markets</Td>
              <Td align="right">
                {`${Big(orderAnalytics.ParticipationRate || 0)
                  .times(100)
                  .toFixed(2)}`}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      )}
      <Table>
        <Tbody>
          <Tr>
            <Th>
              Slippage vs.{' '}
              <HelpIcon tooltip="Slippage includes analytics side, meaning a positive slippage indicates worse performance compared to the benchmark, and negative slippage indicates better performance." />
            </Th>
            <Th align="right">BPS</Th>
          </Tr>
          <Tr data-testid="arrival-px-row">
            <Td>Arrival Px</Td>
            <Td align="right">
              <SlippageText
                security={security}
                side={side}
                versusPxField="ArrivalPx"
                analyticOrLegAnalytic={orderAnalytics}
                parentAnalytic={parentAnalytic}
              />
            </Td>
          </Tr>
          <Tr data-testid="market-mid-row">
            <Td>Market Mid Px</Td>
            <Td align="right">
              <SlippageText
                security={security}
                side={side}
                versusPxField="MarketMidPx"
                analyticOrLegAnalytic={orderAnalytics}
                parentAnalytic={parentAnalytic}
              />
            </Td>
          </Tr>
          <Tr data-testid="reference-px-row">
            <Td>Reference Px</Td>
            <Td align="right">
              <SlippageText
                security={security}
                side={side}
                versusPxField="ReferencePx"
                analyticOrLegAnalytic={orderAnalytics}
                parentAnalytic={parentAnalytic}
              />
            </Td>
          </Tr>
          <Tr data-testid="market-vwap-row">
            <Td>Market VWAP</Td>
            <Td align="right">
              <SlippageText
                security={security}
                side={side}
                versusPxField="CumulativeVWAP"
                analyticOrLegAnalytic={orderAnalytics}
                parentAnalytic={parentAnalytic}
              />
            </Td>
          </Tr>
          {showSweepPx && (
            <SweepPX security={security} side={side} orderAnalytics={orderAnalytics} parentAnalytic={parentAnalytic} />
          )}
        </Tbody>
      </Table>
    </Tables>
  );
}

interface SweepPXProps {
  security: Security | undefined;
  side: SideEnum;
  orderAnalytics: OrderAnalytic;
  parentAnalytic: OrderAnalytic;
}

function SweepPX({ security, side, orderAnalytics, parentAnalytic }: SweepPXProps) {
  if (!orderAnalytics.ExpectedPx || toBigWithDefault(orderAnalytics.ExpectedPx, 0).eq(0)) {
    return null;
  }

  const expectedQtyBig = toBigWithDefault(orderAnalytics.ExpectedQty, 0);
  const orderQtyBig = toBigWithDefault(orderAnalytics.OrderQty, 0);

  if (orderQtyBig.eq(0)) {
    return null;
  }

  const percent = expectedQtyBig.div(orderQtyBig).times(100);

  return (
    <Tr data-testid="sweep-px-row">
      <Td>
        <HStack gap="spacingSmall" justifyContent="flex-start">
          <Tooltip
            tooltip={
              <>
                The expected price at which {percent.toFixed()}% of the order would be filled by sweeping the order book
                at the time of submission.
                <br />
                E.g. For an order to buy 3 coins, if 1 coin is offered at each of $100, $101, and $102, the expected
                Sweep Price is $101.
              </>
            }
          >
            <MetricLabel>Sweep Px</MetricLabel>
          </Tooltip>
        </HStack>
      </Td>
      <Td align="right">
        <HStack justifyContent="flex-end" gap="spacingSmall">
          {!expectedQtyBig.eq(orderQtyBig) && (
            <Tooltip tooltip={`Calculated based on expected fill quantity of ${orderAnalytics.ExpectedQty}`}>
              <Icon icon={IconName.ExclamationSolid} color="colors.yellow.lighten" />
            </Tooltip>
          )}
          <SlippageText
            security={security}
            side={side}
            versusPxField="ExpectedPx"
            analyticOrLegAnalytic={orderAnalytics}
            parentAnalytic={parentAnalytic}
          />
        </HStack>
      </Td>
    </Tr>
  );
}

import { FormGroup, SearchSelect } from '@talos/kyoko';
import { useCustomers } from 'hooks/useCustomer';
import { useAppStateSelector } from 'providers/AppStateProvider';

interface CounterpartyDropdownProps {
  counterparty?: string | null;
  setCounterparty: (counterparty?: string) => void;
}
export function CounterpartyDropdown({ counterparty, setCounterparty }: CounterpartyDropdownProps) {
  const customers = useCustomers();
  const { customerAccounts } = useAppStateSelector(state => state.customerUserDrawer.permissions);
  if (!customers) {
    return null;
  }

  const disabled = customerAccounts.length > 0;

  return (
    <FormGroup
      label="Customer"
      tooltip={disabled ? 'Cannot modify customer while customer account permissions are assigned' : ''}
    >
      <SearchSelect
        selection={customers.find(option => option.Name === counterparty)}
        options={customers}
        getLabel={option => option.DisplayName}
        onChange={customer => setCounterparty(customer?.Name)}
        disabled={disabled}
        data-testid="customer"
      />
    </FormGroup>
  );
}

import {
  ButtonVariants,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  useMixpanel,
  useWatchlistSettings,
} from '@talos/kyoko';
import { closePanel, selectCurrentLayout, togglePanel } from 'components/AppLayout/AppLayoutSlice';
import { useAppLayoutConfig } from 'providers/AppLayoutConfig/AppLayoutConfigContext';
import { getLayoutGroupById } from 'providers/AppLayoutContextProvider/utils/getLayoutGroupById';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { useCallback, useEffect, useMemo } from 'react';
import { HeaderButton } from './styles';

export const WatchlistButton = () => {
  const mixpanel = useMixpanel();
  const selectedLayout = useAppStateSelector(selectCurrentLayout);

  const currentLayout = useAppStateSelector(state => state.appLayout.currentLayout);
  const openPanels = useAppStateSelector(state => state.appLayout.openPanels);

  const { isVisible, setIsVisible } = useWatchlistSettings();
  const { enableFlexibleLayout } = useAppLayoutConfig();
  const dispatch = useAppStateDispatch();

  const toggleWatchlistVisible = useCallback(() => {
    const nextVisible = !isVisible;
    mixpanel.track(MixpanelEvent.ToggleWatchlist, { [MixpanelEventProperty.Enabled]: nextVisible });
    setIsVisible(nextVisible);
    if (enableFlexibleLayout) {
      dispatch(togglePanel('watchlists'));
    }
  }, [dispatch, mixpanel, isVisible, setIsVisible, enableFlexibleLayout]);

  const showWatchlist = useMemo(
    () =>
      !currentLayout ||
      (currentLayout?.collapsablePanels.findIndex(panel => panel.groupId === 'watchlists') !== -1 &&
        getLayoutGroupById(currentLayout.dockViewLayout, 'watchlists')),
    [currentLayout]
  );

  const watchlistIsVisible = isVisible || openPanels.includes('watchlists');

  // Temporary fix to sync state for Watchlist panel visibility until we can remove feature flag for Flexible Layout
  useEffect(() => {
    if (!isVisible && selectedLayout?.collapsablePanels != null) {
      dispatch(closePanel('watchlists'));
    }
  }, [dispatch, selectedLayout?.collapsablePanels, isVisible]);

  return showWatchlist ? (
    <HeaderButton
      data-testid="watchlist-button"
      border="right"
      onClick={toggleWatchlistVisible}
      size={FormControlSizes.Small}
      variant={watchlistIsVisible ? ButtonVariants.Priority : ButtonVariants.Default}
      startIcon={IconName.PresentationChartLine}
    >
      Watchlist
    </HeaderButton>
  ) : null;
};

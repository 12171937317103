import { shouldHideMarketAccount, useMarketAccountsContext } from '@talos/kyoko';
import { compact } from 'lodash-es';
import { useMemo } from 'react';

/**
 * Returns the a unique list of .Group properties found in the MarketAccountProvider's market account list.
 *
 * Will filter out all groups that equate to a "hidden" group (ones starting with "_")
 */
export const useMarketAccountGroups = () => {
  const { marketAccountsList } = useMarketAccountsContext();
  return useMemo(
    () => compact(marketAccountsList.filter(ma => !shouldHideMarketAccount(ma)).map(ma => ma.Group)),
    [marketAccountsList]
  );
};

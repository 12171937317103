import {
  Box,
  EMPTY_ARRAY,
  Flex,
  InlineFormattedNumber,
  NumberWrapper,
  getAgGridColId,
  getOrderStatusColor,
  useDefaultColumns,
  type Column,
  type ColumnDef,
  type CustomerOrder,
  type FilledPercentColumnParams,
  type OrderStatusParams,
} from '@talos/kyoko';

import type { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import Big from 'big.js';
import { compact } from 'lodash-es';
import { useMemo } from 'react';
import styled from 'styled-components';

interface UseCustomerOrdersBlotterColumns {
  defaultColumns?: (keyof CustomerOrder | Partial<Column>)[];
  source?: 'monitoring' | 'dealer-monitoring';
  enableMonitoringCustomerOrderSummaryColumns?: boolean;
}

export const useCustomerOrdersColumns = function useOrdersColumns({
  defaultColumns = EMPTY_ARRAY,
  source = 'dealer-monitoring',
  enableMonitoringCustomerOrderSummaryColumns = true,
}: UseCustomerOrdersBlotterColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            { field: 'SubmitTime', type: 'date', sortable: true },
            { field: 'Counterparty', type: 'customer', title: 'Customer' },
            { field: 'MarketAccount', type: 'text', title: 'Account', id: 'marketAccount' },
            {
              field: 'MarketAccount',
              type: 'marketAccountSourceAccountID',
              id: 'customerAccountID',
              hide: true,
            },
            { field: 'Symbol', type: 'security' },
            { field: 'Side', type: 'side' },
            { field: 'OrderQty', type: 'size', params: { currencyField: 'Currency' } },
            { field: 'Price', type: 'price', params: { assetField: 'Symbol' } },
            { field: 'Strategy', type: 'strategy' },
            {
              field: 'CumQty',
              type: 'size',
              title: 'Filled Qty',
              params: { currencyField: 'Currency' },
              width: 120,
            },
            {
              id: 'filledPercent',
              type: 'filledPercent',
              title: 'Filled %',
              params: {
                filledQtyField: 'CumQty',
                totalQtyField: 'OrderQty',
                getColor: (node, theme) => getOrderStatusColor(node.data, theme),
              } satisfies FilledPercentColumnParams<CustomerOrder>,
            },
            {
              field: 'AvgPx',
              type: 'price',
              title: 'Filled Price',
              params: { assetField: 'Symbol', showFeeIcon: 'never' },
            },
            {
              id: 'filledCounterAmount',
              type: 'filledCounterAmount',
              title: 'Filled Counter Amt',
              params: { currencyField: 'AmountCurrency', showFeeIcon: 'never' },
            },
            {
              field: 'OrdStatus',
              type: 'orderStatus',
              title: 'Status',
              params: {
                tooltipField: 'InternalText',
              } satisfies OrderStatusParams,
            },
            { field: 'User', type: 'user' },
            { field: 'MarketAccount', type: 'marketAccount', title: 'Customer Market Account' },
            { field: 'OrderID', type: 'id' },

            ...(!enableMonitoringCustomerOrderSummaryColumns
              ? []
              : ([
                  {
                    id: 'Spread',
                    type: 'custom',
                    headerGroupToggle: true,
                    // If toggling this group, PerCurrencyPnl column gets closed/opened
                    columnsInGroup: [
                      'PerCurrencyPnL',
                      'SalesCommission',
                      'PerCurrencyFees',
                      // this should be the ids, but we usually use the field as the id
                    ] satisfies (keyof CustomerOrder)[],
                    columnGroup: true,
                    title: 'Spread',
                    sort: undefined,
                    sortable: false,
                    frozen: true,
                    params: {
                      headerTooltip: 'Target / Actual',
                      valueGetter: (params: ValueGetterParams<CustomerOrder>): SpreadObject => {
                        if (params.data) {
                          const { TargetSpread, ActualSpread } = params.data;
                          return {
                            TargetSpread: TargetSpread ? Big(TargetSpread).mul(10_000).toFixed() : undefined,
                            ActualSpread: ActualSpread ? Big(ActualSpread).mul(10_000).toFixed() : undefined,
                          };
                        }
                        return {};
                      },
                      valueFormatter: (params: ValueFormatterParams<CustomerOrder, SpreadObject>): string => {
                        if (!params.value) {
                          return '';
                        }
                        const { ActualSpread, TargetSpread } = params.value;
                        if (ActualSpread === undefined && TargetSpread === undefined) {
                          return 'N/A';
                        }
                        return `${TargetSpread ?? '-'} BPS / ${ActualSpread ?? '-'} BPS`;
                      },
                      cellRenderer: (params: ICellRendererParams<CustomerOrder, SpreadObject>) => {
                        if (!params.value) {
                          return null;
                        }
                        const { ActualSpread, TargetSpread } = params.value;
                        if (ActualSpread === undefined && TargetSpread === undefined) {
                          return params.valueFormatted;
                        }
                        return (
                          <Box>
                            {TargetSpread ? (
                              <InlineFormattedNumber number={TargetSpread} increment="0.01" currency="BPS" />
                            ) : (
                              '-'
                            )}
                            {' / '}
                            {ActualSpread ? (
                              <InlineFormattedNumber number={ActualSpread} increment="0.01" currency="BPS" />
                            ) : (
                              '-'
                            )}
                          </Box>
                        );
                      },
                    },
                  },
                  {
                    field: 'PerCurrencyPnL',
                    columnGroup: true,
                    type: 'custom',
                    id: 'PerCurrencyPnL',
                    title: 'Total PnL',
                    sort: undefined,
                    sortable: false,
                    frozen: true,
                    params: {
                      headerTooltip: 'Profit in base currency, and counter currency if applicable',
                      valueFormatter: (
                        params: ValueFormatterParams<CustomerOrder, CustomerOrder['PerCurrencyPnL']>
                      ): string => {
                        const PerCurrencyPnL = params.value;
                        if (PerCurrencyPnL && PerCurrencyPnL.length) {
                          // reverse it, since base should be first
                          return PerCurrencyPnL.reverse()
                            .map(pnl => {
                              return `${pnl.Amount} ${pnl.Currency}`;
                            })
                            .join(' | ');
                        } else {
                          return 'N/A';
                        }
                      },
                      cellRenderer: PnLCellRenderer,
                    },
                  },
                  {
                    field: 'SalesCommission',
                    id: 'SalesCommission',
                    title: 'Commission',
                    columnGroup: true,
                    sort: undefined,
                    sortable: false,
                    frozen: true,
                    type: 'number',
                    params: {
                      currency: 'BPS',
                      multiplier: 10_000,
                    },
                  },
                  {
                    field: 'PerCurrencyFees',
                    id: 'PerCurrencyFees',
                    sort: undefined,
                    sortable: false,
                    frozen: true,
                    columnGroup: true,
                    title: 'Total Fees',
                    type: 'custom',
                    params: {
                      valueFormatter: (
                        params: ValueFormatterParams<CustomerOrder, CustomerOrder['PerCurrencyFees']>
                      ): string => {
                        const PerCurrencyFees = params.value;
                        if (PerCurrencyFees && PerCurrencyFees.length) {
                          // reverse it, since base should be first
                          return PerCurrencyFees.reverse()
                            .map(pcf => {
                              return `${pcf.Amount} ${pcf.Currency}`;
                            })
                            .join(' | ');
                        } else {
                          return '';
                        }
                      },
                      cellRenderer: PnLCellRenderer,
                    },
                  },
                ] satisfies ColumnDef<CustomerOrder>[])),
          ] satisfies ColumnDef<CustomerOrder>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    [enableMonitoringCustomerOrderSummaryColumns]
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        compact([
          { field: 'CustomerUser', type: 'text', title: 'Customer User' },
          { field: 'SubAccount', type: 'subAccount', title: 'Sub Account(s)' },
          source === 'monitoring' && {
            field: 'AdminFillPrice',
            type: 'price',
            params: { assetField: 'Symbol', showFeeIcon: 'never' },
          },
          { field: 'OrigClOrdID', type: 'text' },
          { field: 'ClOrdID', type: 'text' },
          { field: 'Comments', type: 'text' },
          { field: 'CumAmt', type: 'size', params: { currencyField: 'AmountCurrency' } },
          { field: 'CxlRejReason', type: 'text' },
          { field: 'DecisionStatus', type: 'text' },
          { field: 'EndTime', type: 'date' },
          { field: 'ExecID', type: 'text' },
          { field: 'ExecType', type: 'text' },
          {
            field: 'ExpectedFillPrice',
            type: 'price',
            params: { assetField: 'Symbol', showFeeIcon: 'never' },
          },
          { field: 'ExpectedFillQty', type: 'size', params: { currencyField: 'Currency' } },
          {
            field: 'CumFee',
            type: 'size',
            title: 'Fees',
            params: { currencyField: 'FeeCurrency' },
            width: 80,
          },
          { field: 'Group', type: 'text' },
          {
            field: 'LeavesQty',
            type: 'size',
            params: { currencyField: 'Currency' },
            title: 'Leaves Qty',
          },
          { field: 'OrdRejReason', type: 'text' },
          { field: 'OrdType', type: 'text' },
          { field: 'Parameters', type: 'text' },
          { field: 'PricingParameters', type: 'text' },
          { field: 'QuoteID', type: 'id' },
          { field: 'RFQID', type: 'id' },
          { field: 'InternalText', type: 'text' },
          { id: 'startTime', type: 'orderStartTime', title: 'Start Time' },
          { field: 'Text', type: 'text' },
          { field: 'TimeInForce', type: 'text' },
          { field: 'Timestamp', type: 'date', params: { milliseconds: true } },
          { field: 'ExecutionStrategy', type: 'text', title: 'Execution Strategy' },
          { field: 'TransactTime', type: 'date' },
          {
            field: 'PricingReference',
            type: 'price',
            params: {
              isReferencePrice: true,
            },
          },
          {
            field: 'FixingDetails.Index',
            title: 'Fixing Index',
            type: 'text',
          },
          {
            field: 'FixingDetails.Fixing',
            title: 'Fixing Price',
            type: 'price',
            params: {
              // TODO fhqvst this should probably be `FixingDetails.Index`
              assetField: 'Symbol',
            },
          },
        ]) satisfies ColumnDef<CustomerOrder>[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, [source]);

  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [
          ...defaultVisibleColumns.values(),
          ...defaultHiddenColumns.values(),
        ] satisfies ColumnDef<CustomerOrder>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);

  return useDefaultColumns(defaultColumns, columnDefinitions);
};

// Create a divider between the InlineFormattedNumbers
const PnLWrapper = styled(Flex)`
  > ${NumberWrapper}:not(:last-child):after {
    content: '|';
    color: ${({ theme }) => theme.colorTextDefault};
  }
`;

type SpreadObject = Pick<CustomerOrder, 'TargetSpread' | 'ActualSpread'>;

const PnLCellRenderer = (params: ICellRendererParams<CustomerOrder, CustomerOrder['PerCurrencyPnL']>) => {
  const PerCurrencyPnL = params.value;
  if (PerCurrencyPnL && PerCurrencyPnL.length) {
    return (
      <PnLWrapper gap="spacingSmall">
        {/* Reverse it, since base should be first */}
        {PerCurrencyPnL.reverse().map(pnl => {
          return <InlineFormattedNumber key={pnl.Currency} number={pnl.Amount} currency={pnl.Currency} />;
        })}
      </PnLWrapper>
    );
  } else {
    return params.valueFormatted;
  }
};

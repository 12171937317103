import {
  Flex,
  FormControlSizes,
  IconButton,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  Popover,
  Toggle,
  useMixpanel,
  usePopoverState,
} from '@talos/kyoko';
import { useMarketSelectorContext } from './MarketSelectorContext';

export const MarketSelectorSettingsButton = () => {
  const mixpanel = useMixpanel();
  const { showDisabledItems, setShowDisabledItems } = useMarketSelectorContext();

  const popover = usePopoverState({
    closeOnClickOutside: true,
    trigger: 'click',
    placement: 'bottom-end',
    usePortal: false,
  });

  return (
    <Popover {...popover}>
      <IconButton
        ghost
        icon={IconName.Cog}
        color="colorTextDefault"
        size={FormControlSizes.Small}
        data-testid="market-selector-settings-cog"
      />
      <Flex>
        <Toggle
          size={FormControlSizes.Small}
          onChange={enabled => {
            setShowDisabledItems(enabled);
            mixpanel.track(MixpanelEvent.ToggleShowDisabled, { [MixpanelEventProperty.Enabled]: enabled });
          }}
          checked={showDisabledItems}
          label="Show disabled items"
          data-testid="market-selector-show-disabled-items-toggle"
        />
      </Flex>
    </Popover>
  );
};

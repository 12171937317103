import {
  HStack,
  InlineFormattedNumber,
  Insight,
  Text,
  VStack,
  readableDate,
  useObservableValue,
  type PnlLookbackEnum,
} from '@talos/kyoko';
import { get } from 'lodash-es';
import type { MutableRefObject } from 'react';
import { BlueMarkerBox } from '../../components/BlueMarkerBox';
import type { HistoricalPositionStat } from '../../types';
import { usePerformancePositions } from '../providers/PerformancePositionsProvider';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import { PERFORMANCE_SERIES_NAME } from './tokens';
import { getNumberVariant, showNegativeSign } from './utils';

interface InsightsProps {
  chart: Highcharts.Chart | undefined;
  currentDataPointsByTsRef: MutableRefObject<Map<number, HistoricalPositionStat>>;
}

export const Insights = ({ chart, currentDataPointsByTsRef }: InsightsProps) => {
  const {
    state: { snapshotDate, pnlLookbacks },
  } = usePerformanceContext();

  const { positionTotalsObs } = usePerformancePositions();
  const positionTotals = useObservableValue(() => positionTotalsObs, [positionTotalsObs]);

  const periodExtremeDataPoints = getPeriodExtremeDataPoints(chart, currentDataPointsByTsRef);

  const firstStat = periodExtremeDataPoints?.first;
  const asOfStat = snapshotDate
    ? currentDataPointsByTsRef.current.get(new Date(snapshotDate).getTime())
    : periodExtremeDataPoints?.last;

  const openingPnLInsight = firstStat ? (
    <VStack w="100%" alignItems="flex-start" gap="spacingDefault">
      <Text color="colorTextSubtle" fontSize="fontSizeDefault" textAlign="left">
        Period Start: {readableDate(firstStat.Timestamp, true)}
      </Text>
      <Insight
        w="100%"
        label="Opening PnL"
        text={
          <InlineFormattedNumber
            number={firstStat.PnLTotal}
            variant={getNumberVariant(firstStat.PnLTotal)}
            currency={firstStat.EquivalentCurrency}
            showSign={showNegativeSign(firstStat.PnLTotal)}
          />
        }
        showBenchmark={false} // opening pnl isnt compared to anything
      />
    </VStack>
  ) : null;

  const pnlInsightsAsOf =
    firstStat && asOfStat ? (
      <VStack w="100%" alignItems="flex-start" gap="spacingDefault">
        <HStack gap="spacingSmall">
          {snapshotDate && <BlueMarkerBox />}
          <Text color="colorTextSubtle" fontSize="fontSizeDefault" textAlign="left">
            Insights as of {readableDate(asOfStat?.Timestamp, true)}
          </Text>
        </HStack>
        <VStack gap="spacingComfortable" w="100%">
          <Insight
            w="100%"
            label="Closing PnL"
            text={
              <InlineFormattedNumber
                number={asOfStat.PnLTotal}
                variant={getNumberVariant(asOfStat.PnLTotal)}
                currency={asOfStat.EquivalentCurrency}
                showSign={showNegativeSign(asOfStat.PnLTotal)}
              />
            }
            helpIconTooltip={pnlTooltips['Closing PnL']}
            showBenchmark={false}
          />
          <Insight
            w="100%"
            label="Interval PnL"
            text={
              <InlineFormattedNumber
                number={asOfStat.IntervalPnL}
                variant={getNumberVariant(asOfStat.IntervalPnL)}
                currency={asOfStat.EquivalentCurrency}
                showSign={showNegativeSign(asOfStat.IntervalPnL)}
              />
            }
            percentageChange={asOfStat.IntervalPnLPercent}
            helpIconTooltip={pnlTooltips['Interval PnL']}
            showBenchmark={false}
          />
          {pnlLookbacks.map(lookback => {
            const totalForLookback: string | undefined = get(
              positionTotals,
              `PnLLookbacks.${lookback}.Equivalent.PnLDelta`
            );

            return totalForLookback ? (
              <Insight
                key={lookback}
                w="100%"
                label={`${PNL_LOOKBACK_LABELS[lookback]} PnL`}
                text={
                  <InlineFormattedNumber
                    number={totalForLookback}
                    variant={getNumberVariant(totalForLookback)}
                    currency={asOfStat.EquivalentCurrency}
                    showSign={showNegativeSign(totalForLookback)}
                  />
                }
                helpIconTooltip={pnlTooltips[lookback]}
                showBenchmark={false}
              />
            ) : null;
          })}
        </VStack>
      </VStack>
    ) : null;

  return (
    <VStack gap="spacingMedium">
      {/* Insights as of Period Start date */}
      {openingPnLInsight}
      {/* Insights as of closing / "as of" date */}
      {pnlInsightsAsOf}
    </VStack>
  );
};

function getPeriodExtremeDataPoints(
  chart: Highcharts.Chart | undefined,
  currentDataPointsByTsRef: React.MutableRefObject<Map<number, HistoricalPositionStat>>
) {
  const performanceSeries = chart?.series.find(s => s.name === PERFORMANCE_SERIES_NAME);
  const performanceSeriesXData: number[] | undefined = performanceSeries?.['xData'];
  const performanceSeriesYData: number[] | undefined = performanceSeries?.['yData'];
  if (
    !performanceSeriesXData ||
    performanceSeriesXData.length === 0 ||
    !performanceSeriesYData ||
    performanceSeriesYData.length === 0
  ) {
    return undefined;
  }

  const firstNonNullIndex = performanceSeriesYData.findIndex(value => value != null);

  return {
    first: currentDataPointsByTsRef.current.get(performanceSeriesXData[firstNonNullIndex]),
    last: currentDataPointsByTsRef.current.get(performanceSeriesXData[performanceSeriesXData.length - 1]),
  };
}

const PNL_LOOKBACKS_TOOLTIPS: { [key in PnlLookbackEnum]: string } = {
  Today: 'Profit or loss for today prior to the selected closing date and time.',
  H24: 'Profit or loss for the last 24 hours prior to the selected closing date and time.',
  WeekToDate: 'Profit or loss for the week prior to the selected closing date and time.',
  D7: 'Profit or loss for the last 7 days prior to the selected closing date and time.',
  MonthToDate: 'Profit or loss for the month prior to the selected closing date and time.',
  D30: 'Profit or loss for the last 30 days prior to the selected closing date and time.',
  YearToDate: 'Profit or loss for the year prior to the selected closing date and time.',
  D365: 'Profit or loss for the last 365 days prior to the selected closing date and time.',
};

const PNL_LOOKBACK_LABELS: { [key in PnlLookbackEnum]: string } = {
  Today: 'Intraday',
  H24: '24H',
  WeekToDate: 'Week To Date',
  D7: '7D',
  MonthToDate: 'Month To Date',
  D30: '30D',
  YearToDate: 'Year To Date',
  D365: '365D',
};

const pnlTooltips = {
  'Opening PnL':
    'Accumulated profit or loss at the beginning of the selected time period, represented by the left-most point on the chart.',
  'Closing PnL':
    'Accumulated profit or loss at the end of the selected time period, represented by the blue dashed line on the chart.',
  'Interval PnL':
    'Profit or loss for the selected time interval, calculated as the difference between Closing PnL and Opening PnL.',
  ...PNL_LOOKBACKS_TOOLTIPS,
};

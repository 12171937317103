import {
  ACTION,
  API_PERMISSION_WILDCARD,
  Flex,
  FormRowStatus,
  FormTable,
  NotificationVariants,
  UpdateActionEnum,
  useDynamicCallback,
  useFormTable,
  useGlobalToasts,
  type Column,
  type SubAccount,
  type UseFormTable,
  type User,
} from '@talos/kyoko';
import { useRoleAuth } from 'hooks';
import { cloneDeep, compact, get, sortBy } from 'lodash-es';
import { useSubAccountPermissionFilters, useUsersContext, type UserSubAccountPermissionRow } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { prettifyRole } from 'tokens/roles';
import {
  getApiPermissionActionFromUser,
  useApiPermissionActionColumn,
} from 'utils/columns/useApiPermissionActionColumn';
import { v4 as uuid } from 'uuid';
import { UserDropdown } from '../UserDropdown';
import type { IEditSubAccountsDrawerTab } from '../types';
import { useCanViewAccountSegregation } from '../useCanViewAccountSegregation';

interface SubAccountUsersTabProps {
  onAddUser: (Users: User[]) => void;
  usersToAdd: User[];
  setUsersToAdd: React.Dispatch<React.SetStateAction<User[]>>;
  subAccountsFormTable: UseFormTable<UserSubAccountPermissionRow>;
  userSubAccountPermissions: UserSubAccountPermissionRow[];
}

export function SubAccountsUsersTab({
  onAddUser,
  setUsersToAdd,
  subAccountsFormTable,
  usersToAdd,
}: SubAccountUsersTabProps) {
  return (
    <>
      <Flex flexDirection="column" gap="spacingMedium" w="100%" h="100%">
        <UserDropdown onAddUser={onAddUser} usersToAdd={usersToAdd} setUsersToAdd={setUsersToAdd} />
        <FormTable background="transparent" promptIfUnsavedChanges={false} {...subAccountsFormTable} />
      </Flex>
    </>
  );
}

export function useSubAccountUsersTab({ subAccount }: { subAccount: SubAccount }): IEditSubAccountsDrawerTab {
  const { add: addToast } = useGlobalToasts();
  const permissionActionColumn = useApiPermissionActionColumn('Subaccount');
  const { isAuthorized } = useRoleAuth();
  const canEditFilterPermissions = isAuthorized(ACTION.EDIT_FILTER_PERMISSIONS);

  const subAccountColumns = useMemo<Column[]>(
    () =>
      compact([
        {
          type: 'custom',
          id: 'userName',
          suppressHeaderMenuButton: true,
          minWidth: 150,
          width: 150,
          title: 'USER NAME',
          sort: '+',
          sortIndex: 0,
          params: {
            valueGetter: ({ data }) => {
              return get(data, 'FilterPermission.Subject.User');
            },
            valueFormatter: params => {
              return params.value;
            },
          },
        },
        {
          id: 'roles',
          type: 'custom',
          title: 'Role',
          suppressHeaderMenuButton: true,
          minWidth: 390,
          width: 390,
          params: {
            valueGetter: ({ data }) => {
              if (data?.User?.Roles == null) {
                return '';
              }
              return sortBy(data.User.Roles)
                .filter(role => role !== '')
                .map(role => prettifyRole(role))
                .join(', ');
            },
          },
        },
        permissionActionColumn,
        canEditFilterPermissions
          ? {
              type: 'remove',
              id: 'remove',
              pinned: 'right',
              title: 'remove',
              suppressHeaderMenuButton: true,
              params: {
                disabled: data => {
                  return get(data, 'FilterPermission.Filter.SubAccount') === '*';
                },
                disabledTooltip:
                  'Users associated with All Sub Accounts cannot be removed from specific sub accounts. This can be changed via User administration.',
              },
            }
          : null,
      ]),
    [permissionActionColumn, canEditFilterPermissions]
  );

  const { filterIDsBySubAccountName, subAccountPermissionFiltersByFilterID, updateSubAccountPermissionFilters } =
    useSubAccountPermissionFilters();

  const { userByUserName } = useUsersContext();

  const userSubAccountPermissions: UserSubAccountPermissionRow[] = useMemo(() => {
    const subAccountRelatedFilterPermissions = compact(
      Array.from(filterIDsBySubAccountName?.get(subAccount.Name) || [])
        .concat(Array.from(filterIDsBySubAccountName?.get(API_PERMISSION_WILDCARD) || []))
        .map(filterID => cloneDeep(subAccountPermissionFiltersByFilterID?.get(filterID)))
    );

    return subAccountRelatedFilterPermissions.map(FilterPermission => {
      const User = (FilterPermission.Subject.User && userByUserName.get(FilterPermission.Subject.User)) || null;
      return { User: { Roles: User?.Roles || [] }, FilterPermission };
    });
  }, [filterIDsBySubAccountName, subAccountPermissionFiltersByFilterID, subAccount.Name, userByUserName]);

  const subAccountsFormTable = useFormTable<UserSubAccountPermissionRow>({
    rowID: 'FilterPermission.FilterID',
    data: userSubAccountPermissions,
    columns: subAccountColumns,
  });

  const resetSubAccountsTable = useDynamicCallback(subAccountsFormTable.reset);
  useEffect(() => {
    if (!subAccountsFormTable.isDirty) {
      resetSubAccountsTable(userSubAccountPermissions);
    }
  }, [resetSubAccountsTable, userSubAccountPermissions, subAccountsFormTable.isDirty]);

  const [usersToAdd, setUsersToAdd] = useState<User[]>([]);

  const onAddUser = useCallback(
    (Users: User[]) => {
      const rows = subAccountsFormTable
        .getRows()
        // remove implicit rows (*)
        .filter(row => row.data.FilterPermission.Filter.SubAccount !== API_PERMISSION_WILDCARD);
      const usersInRowsMap = new Map(
        rows.map(row => [row.data.FilterPermission.Subject.User, row.data.FilterPermission.FilterID])
      );

      for (const User of Users) {
        const rowId = usersInRowsMap.get(User.Name);

        if (rowId) {
          const row = (rowId && subAccountsFormTable.gridApi?.getRowNode(rowId)) || undefined;
          // Instead of adding a duplicate, lets highlight the already existing entry and toast
          if (row) {
            subAccountsFormTable.gridApi?.flashCells({ rowNodes: [row] });
            addToast({
              text: `There is already an existing row for user: "${User.Name}" in the table. Update the existing one or remove it.`,
              variant: NotificationVariants.Primary,
            });
          }
        } else {
          subAccountsFormTable.addRow({
            FilterPermission: {
              Action: getApiPermissionActionFromUser(User),
              Filter: { SubAccount: subAccount.Name, MarketAccount: undefined },
              Subject: { User: User.Name, SubAccount: undefined },
              FilterID: uuid(),
              UpdateAction: UpdateActionEnum.Initial,
            },
            User,
          });
        }
      }
      setUsersToAdd([]);
    },
    [subAccountsFormTable, addToast, subAccount]
  );

  const handleSaveChanges = useCallback(async () => {
    // subaccounts -> users
    if (subAccountsFormTable.isDirty) {
      const rows = subAccountsFormTable.getRows();

      const updates = compact(
        rows.map(row => {
          if (row.status === FormRowStatus.Added) {
            return { ...row.data.FilterPermission, UpdateAction: UpdateActionEnum.Initial };
          }
          if (row.status === FormRowStatus.Removed) {
            return { ...row.data.FilterPermission, UpdateAction: UpdateActionEnum.Remove };
          }
          if (row.status === FormRowStatus.Updated) {
            return { ...row.data.FilterPermission, UpdateAction: UpdateActionEnum.Update };
          }

          return null;
        })
      );

      updateSubAccountPermissionFilters(updates);
      resetSubAccountsTable();
    }
  }, [subAccountsFormTable, updateSubAccountPermissionFilters, resetSubAccountsTable]);

  const canViewAccountAccountSegregation = useCanViewAccountSegregation();

  return {
    name: 'Users',
    viewable: canViewAccountAccountSegregation,
    badgeContent: userSubAccountPermissions.length.toString(),
    isDirty: subAccountsFormTable.isDirty,
    save: handleSaveChanges,
    component: (
      <SubAccountsUsersTab
        onAddUser={onAddUser}
        usersToAdd={usersToAdd}
        setUsersToAdd={setUsersToAdd}
        subAccountsFormTable={subAccountsFormTable}
        userSubAccountPermissions={userSubAccountPermissions}
      />
    ),
  };
}

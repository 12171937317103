import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  HStack,
  Panel,
  PanelContent,
  PanelHeader,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  columnToColumnState,
  tabLabelerEnumerated,
  useDynamicCallback,
  usePersistedTabs,
  usePortal,
  useTabs,
  type Column,
  type CustomerTransaction,
} from '@talos/kyoko';
import { compact } from 'lodash-es';
import { useState } from 'react';
import type { CustomerTransactionsFilter, CustomerTransactionsTabsProps } from './types';

import type { RowDoubleClickedEvent } from 'ag-grid-community';
import { useDetailsDrawer } from '../../../components/DetailsDrawer/useDetailsDrawer';
import { CustomerTransactionsBlotter } from './CustomerTransactionsBlotter';
import { DEFAULT_CUSTOMER_TRANSACTION_TABS } from './defaults';

const CUSTOMER_TRANSACTIONS_BLOTTER_PREFIX = 'dealer/customer-transactions';

const tabLabeler = tabLabelerEnumerated('Transfers');

const TABS_EXCLUDED_IDS = compact(DEFAULT_CUSTOMER_TRANSACTION_TABS.map(tab => tab.id));

export const CustomerTransactions = () => {
  const [initialFiltersOpen, setInitialFiltersOpen] = useState(false);
  const handleSelect = useDynamicCallback(() => setInitialFiltersOpen(false));
  const handleAdd = useDynamicCallback(() => setInitialFiltersOpen(true));

  const persistedTabs = usePersistedTabs(CUSTOMER_TRANSACTIONS_BLOTTER_PREFIX, {
    defaultInitialItems: DEFAULT_CUSTOMER_TRANSACTION_TABS,
    excludedIDs: TABS_EXCLUDED_IDS,
    defaultInitialSelectedIndex: 0,
    onSelect: handleSelect,
  });

  const tabs = useTabs({ ...persistedTabs, showAddTab: true, tabLabeler, onAdd: handleAdd });

  const handleCloneTab = useDynamicCallback((filter: CustomerTransactionsFilter, columns: Column[]) => {
    tabs.clone(tabs.selectedIndex, {
      defaultColumns: columns.map(columnToColumnState),
      defaultFilter: filter,
      closable: true,
      reorderable: true,
    });
  });

  const handleUpdateTab = useDynamicCallback((updatedTab: CustomerTransactionsTabsProps) => {
    tabs.updateItem(updatedTab);
  });

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  const { openDetailsDrawer, closeDetailsDrawer, isOpen: isDrawerOpen } = useDetailsDrawer();

  const [lastSelectedRowID, setLastSelectedRowID] = useState<string | undefined>();
  const handleDoubleClickRow = useDynamicCallback(({ data }: RowDoubleClickedEvent<CustomerTransaction>) => {
    if (!data) {
      return;
    }
    const id = data.ClReqID;
    // If clicking the same row, close the drawer
    if (lastSelectedRowID === id && isDrawerOpen) {
      setLastSelectedRowID(undefined);
      closeDetailsDrawer();
      return;
    }

    // If clicking a different row, open the drawer
    openDetailsDrawer({ entity: data });
    setLastSelectedRowID(id);
  });

  return (
    <HStack h="100%" w="100%" gap="spacingTiny" overflow="hidden">
      <Panel>
        <PanelHeader>
          <h2>Customer Transfers</h2>
        </PanelHeader>

        <PanelContent style={{ overflow: 'hidden' }}>
          <Tabs h="100%" {...tabs} size={TabSize.Large}>
            <TabList
              rightItems={
                <HStack gap="spacingComfortable">
                  <Box ref={filtersContainerRef} />
                </HStack>
              }
            >
              {tabs.items.map((tab, index) => (
                <Tab key={index} {...tab} />
              ))}
            </TabList>
            <TabPanels style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
              {tabs.items.map(tab => (
                <AccordionGroup key={tab.id}>
                  <CustomerTransactionsBlotter
                    tab={tab}
                    blotterID={`${CUSTOMER_TRANSACTIONS_BLOTTER_PREFIX}/${tab.id}`}
                    initialFiltersOpen={initialFiltersOpen}
                    onCloneTab={handleCloneTab}
                    onUpdateTab={handleUpdateTab}
                    onRowDoubleClicked={handleDoubleClickRow}
                  />
                </AccordionGroup>
              ))}
            </TabPanels>
          </Tabs>
        </PanelContent>
      </Panel>
    </HStack>
  );
};

import type { PropsWithChildren } from 'react';
import { useOverflowHandler } from '../../../hooks/useOverflowHandler';
import { Box, type BoxProps } from '../../Core';
import type { TooltipProps } from '../../Tooltip';
import { Tooltip } from '../../Tooltip';

/** This is very similar in nature to the styled-component EllipsisText, but that had limitations getting it to work
 * with the auto-tooltip you ideally want whenever you're ellipsizing text.  This allows that functionality as a built-in behavior.
 *
 * By default, handles horizontal ellipsizing, but by setting lineClamp, this changes to handle vertical ellipsizing based on lines.
 *
 * Once we're confident on this component, we could consider removing EllipsisText and replacing it with this.
 */
export const EllipsisBoxWithTooltip = ({
  children,
  tooltipTestId,
  tooltipZIndex,
  tooltipProps,
  outerBoxProps,
  lineClamp,
  ...props
}: PropsWithChildren<
  BoxProps & {
    /** For vertical ellipsizing, supports line-clamping the text to lines
     * - if set, will override the whiteSpace, textOverflow, and overflow properties
     */
    lineClamp?: number;
    /** Box Props for Container Box */
    outerBoxProps?: BoxProps;
    'data-testid'?: string;
    tooltipTestId?: string;
    tooltipZIndex?: number;
    tooltipProps?: Partial<TooltipProps>;
  }
>) => {
  const { isOverflowing, ref } = useOverflowHandler();

  return (
    <Box maxWidth="100%" overflow="hidden" {...outerBoxProps}>
      {/* At present this cannot be replaced with JITTooltip because of the inner/outer Box relationship */}
      <Tooltip
        delay={500}
        tooltip={children}
        placement="bottom"
        trigger={isOverflowing ? 'hover' : ''}
        tooltipTestID={tooltipTestId}
        zIndex={tooltipZIndex}
        {...tooltipProps}
      >
        {lineClamp ? (
          <Box ref={ref} lineClamp={lineClamp} {...props}>
            {children}
          </Box>
        ) : (
          <Box ref={ref} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" {...props}>
            {children}
          </Box>
        )}
      </Tooltip>
    </Box>
  );
};

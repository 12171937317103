import {
  Box,
  Divider,
  Dropdown,
  type DropdownProps,
  MixpanelEventSource,
  MixpanelSourceProvider,
  VStack,
} from '@talos/kyoko';
import { useTheme } from 'styled-components';
import { MarketSelectorBlotter } from './blotter/MarketSelectorBlotter';
import { MarketSelectorPresetsList } from './presets/MarketSelectorPresetsList';

export type MarketSelectorPanelProps = {
  dropdownProps: Omit<DropdownProps, 'children'>;
  /** Useful if you have multiple market selectors rendered at once to give them different testIDs. */
  testID?: string;
};

export const MarketSelectorPanel = ({ dropdownProps, testID }: MarketSelectorPanelProps) => {
  const theme = useTheme();

  return (
    <Dropdown portalize {...dropdownProps}>
      <MixpanelSourceProvider value={MixpanelEventSource.MarketAccountSelector}>
        <Box
          h="100%"
          overflow="hidden"
          display="grid"
          gridTemplateColumns="min-content min-content auto"
          gridTemplateRows="100%"
          data-testid={`${testID ?? 'market-selector-panel'}`}
        >
          <>
            <Box h="100%" overflow="hidden">
              <VStack w="200px" h="100%" overflow="hidden">
                <MarketSelectorPresetsList />
              </VStack>
            </Box>
            <Divider orientation="vertical" my="0" color={theme.backgroundDivider} />

            <Box alignSelf="stretch">
              <MarketSelectorBlotter />
            </Box>
          </>
        </Box>
      </MixpanelSourceProvider>
    </Dropdown>
  );
};

/**
 * Removes all `<style>` and `<link rel="stylesheet">` elements within a specified target element by its ID.
 *
 * @param targetDocument - The document object where the target element resides.
 * @param targetId - The ID of the target element from which styles should be removed.
 */
export function removeStyles(targetDocument: Document, targetId: string) {
  const styles = targetDocument.querySelectorAll(`#${targetId} style, #${targetId} link[rel="stylesheet"]`);
  styles.forEach(style => style.parentNode?.removeChild(style));
}

import {
  DateField,
  Field,
  IntegerField,
  NumericField,
  ParameterTypeEnum,
  PresenceEnum,
  SelectorField,
  UnifiedLiquidityEnum,
  Unit,
  type ParameterLike,
  type PriceProtectionEnum,
} from '@talos/kyoko';

export const mapParameterToField = (
  parameter: ParameterLike,
  priceScale: number,
  quantityScale: number | undefined,
  defaultPriceProtection: PriceProtectionEnum | undefined,
  isModify: boolean | undefined
) => {
  const isRequired = parameter.Presence === PresenceEnum.Required;
  const placeholder = parameter.Presence === PresenceEnum.Optional ? 'Optional' : '';
  const isVisible = parameter.Presence !== PresenceEnum.Hidden;
  const defaultValue = !isModify ? parameter.DefaultValue : undefined;

  const name = parameter.DisplayName;

  // enum values are only applicable for Enum parameters
  // backend send us values in string format, but expect us to send it back in numeric...
  const enumValues = parameter.EnumValues || [];
  const availableEnums: number[] = enumValues.map(e => e.Index);

  switch (parameter.Type) {
    case ParameterTypeEnum.PriceProtection:
      return new Field({
        name,
        placeholder,
        isRequired,
        value: defaultValue || defaultPriceProtection,
        isVisible,
      }).validate();
    case ParameterTypeEnum.UnifiedLiquidity:
      return new Field({
        name,
        placeholder,
        isRequired,
        value: defaultValue || UnifiedLiquidityEnum.Disabled,
        isVisible,
        // UnifiedLiquidity cannot be changed for existing orders
        isDisabled: isModify,
      }).validate();
    case ParameterTypeEnum.ReduceOnly:
      return new Field({
        name,
        placeholder,
        isRequired,
        value: defaultValue,
        isVisible,
        isDisabled: isModify,
      }).validate();
    case ParameterTypeEnum.ReduceFirst:
      return new Field({
        name,
        placeholder,
        isRequired,
        value: defaultValue,
        isVisible,
        isDisabled: isModify,
      }).validate();
    case ParameterTypeEnum.TargetParticipationRate:
    case ParameterTypeEnum.Percent:
      return new NumericField({
        unit: Unit.Percent,
        placeholder,
        name,
        isRequired,
        value: defaultValue,
        isVisible,
      }).validate();
    case ParameterTypeEnum.Bps:
      return new NumericField({
        unit: Unit.Bps,
        placeholder,
        name,
        scale: 2,
        isRequired,
        value: defaultValue,
        isVisible,
      }).validate();
    case ParameterTypeEnum.Integer:
      return new IntegerField({
        placeholder,
        name,
        isRequired,
        value: typeof defaultValue === 'string' ? parseInt(defaultValue) : defaultValue,
        isVisible,
      }).validate();
    case ParameterTypeEnum.Qty:
      return new NumericField({
        placeholder,
        name,
        scale: quantityScale,
        isRequired,
        value: defaultValue,
        isVisible,
      }).validate();
    case ParameterTypeEnum.Price:
    case ParameterTypeEnum.PriceOffset:
      return new NumericField({
        name,
        placeholder,
        scale: priceScale,
        isRequired,
        value: defaultValue,
        isVisible,
      }).validate();
    case ParameterTypeEnum.Date:
      return new DateField({ name, placeholder, isRequired, value: defaultValue, isVisible }).validate();
    case ParameterTypeEnum.Enum:
      return new SelectorField<number>({
        name,
        placeholder,
        isRequired,
        value: Number(defaultValue),
        isVisible,
        availableItems: availableEnums,
      }).validate();
    default:
      return new Field({ name, placeholder, isRequired, value: defaultValue, isVisible }).validate();
  }
};

import type {
  CellClassParams,
  GetContextMenuItemsParams,
  GridApi,
  ICellRendererParams,
  IRowNode,
  MenuItemDef,
} from 'ag-grid-community';
import { entries, get } from 'lodash-es';
import type { MarketAccount } from '../../contexts';
import {
  CustomerAddressTypeEnum,
  CustomerExecutionStrategyEnum,
  ModeEnum,
  OrdTypeEnum,
  TimeInForceEnum,
  type Aggregation,
  type AllInTier,
  type Currency,
  type Customer,
  type CustomerSecurity,
  type CustomerUser,
  type FeeTier,
  type MarketFeeModeEnum,
  type Security,
  type SymbolGroup,
} from '../../types';
import { prettyName } from '../../utils';
import type { ColumnDef, GenericFilter } from '../BlotterTable';
import type { FilterableProperty } from '../Filters';
import { FormControlSizes } from '../Form';
import { IconName } from '../Icons';
import type {
  EntityAdminClass,
  EntityAdminRecord,
  HierarchicalColumnProps,
  InputsAndDropdownsDrawerDropdownOption,
} from './types';

export function getShowJSONContextItem<T extends EntityAdminRecord>({
  params,
  handleClickJson,
}: {
  params: GetContextMenuItemsParams<T>;
  handleClickJson: (item?: T) => void;
}): MenuItemDef<T> {
  return {
    action: () => handleClickJson(params.node?.data),
    name: 'Show JSON',
    icon: `<i class="ag-icon ${IconName.Braces}"/>`,
  };
}

export const getEditColumn = <T extends EntityAdminRecord>({
  handleOnClick,
}: {
  handleOnClick: (entity: T) => void;
}): ColumnDef<T> => ({
  id: 'edit',
  type: 'button',
  pinned: 'right',
  width: 70,
  suppressColumnsToolPanel: true,
  params: {
    startIcon: IconName.Pencil,
    onClick: ({ node, api }: { node: IRowNode<EntityAdminClass<T>>; api: GridApi<EntityAdminClass<T>> }) => {
      const entityClass = node.data;
      entityClass && handleOnClick(entityClass.data);
      setTimeout(() => {
        node.setSelected(true, true);
      }, 0);
    },
    children: 'Edit',
    size: FormControlSizes.Small,
  },
});
export const getDeleteColumn = <T extends EntityAdminRecord>({
  handleOnClick,
}: {
  handleOnClick: (entity: T) => void;
}): ColumnDef<T> => ({
  id: 'delete',
  type: 'button',
  pinned: 'right',
  width: 50,
  suppressColumnsToolPanel: true,
  params: {
    startIcon: IconName.Trash,
    onClick: ({ node }: { node: IRowNode<EntityAdminClass<T>> }) => {
      const entityClass = node.data;
      entityClass && handleOnClick(entityClass.data);
    },
    size: FormControlSizes.Small,
  },
});
export const getModeColumn = <T extends EntityAdminRecord>({
  handleOnSwitchMode,
  updateSelectedNodes,
}: {
  handleOnSwitchMode: (entity: T) => Promise<T>;
  updateSelectedNodes: (params: { api: GridApi<EntityAdminClass<T>> }) => void;
}): ColumnDef<T> => ({
  id: 'mode',
  field: 'Mode' as T[keyof T],
  type: 'modeWithMessage',
  pinned: 'left',
  width: 70,
  params: {
    cellRendererParams: ({ data: entityClass, api }: ICellRendererParams<EntityAdminClass<T>>) => {
      return {
        checked: entityClass?.data.Mode === ModeEnum.Enabled,
        onChange: (setToEnabled: boolean) => {
          if (entityClass != null) {
            const newMode = setToEnabled ? ModeEnum.Enabled : ModeEnum.Disabled;
            updateSelectedNodes({ api });
            handleOnSwitchMode({ ...entityClass.data, Mode: newMode });
          }
        },
      };
    },
  },
});

export const getAddChildEntityColumn = <T extends EntityAdminRecord>({
  openEntityDrawer,
  buttonProps,
  entityIDField,
  childIDField,
}: HierarchicalColumnProps<T>): ColumnDef<T> => ({
  id: 'add-child',
  type: 'button',
  pinned: 'right',
  width: buttonProps?.width ?? 100,
  suppressColumnsToolPanel: true,
  params: {
    startIcon: IconName.Plus,
    onClick: ({ node, api }: { node: IRowNode<EntityAdminClass<T>>; api: GridApi<EntityAdminClass<T>> }) => {
      if (node.data) {
        const newEntity = { [entityIDField]: get(node.data, entityIDField) } as T;
        openEntityDrawer(newEntity, true);
        api.deselectAll();
      }
    },
    hide: (data: T) => get(data, childIDField) != null,
    children: buttonProps?.text ?? 'Add Child',
    size: FormControlSizes.Small,
  },
});

export const getEntitiesByParentIDMap = <T,>(
  data: T[],
  entityIDField: keyof T,
  childIDField?: keyof T
): Map<keyof T, T> | undefined => {
  if (childIDField != null) {
    return new Map(
      data
        // Only get the rows that are parents
        .filter(row => get(row, childIDField) == null)
        // Map the parentID to the row
        .map(row => [get(row, entityIDField), row])
    );
  }
  return undefined;
};

export const applyInheritanceCellStyle = <T extends EntityAdminRecord>(column: ColumnDef<T>): ColumnDef<T> => ({
  ...column,
  cellStyle: (params: CellClassParams<EntityAdminClass<T>>) => ({
    ...(typeof column.cellStyle === 'function' ? column.cellStyle(params) : column.cellStyle),
    // If this column field is inherited, make it slightly transparent. We overwrite any existing cellStyle opacity.
    opacity: params.data?.isFieldInherited(params.colDef.field as keyof T) ? 0.5 : 1,
  }),
});

export const onlyServerFilterEntries = (
  filter: GenericFilter,
  filterableProperties: FilterableProperty[]
): GenericFilter => {
  return entries(filter).reduce((acc, [key, values]) => {
    const foundProperty = filterableProperties.find(property => property.key === key);
    if (foundProperty && values.at(0)) {
      acc[foundProperty.field!] = values.at(0)!;
    }
    return acc;
  }, {} as GenericFilter);
};

export function getCustomerUserDrawerOptions(
  customerUsers: Pick<CustomerUser, 'Email'>[]
): InputsAndDropdownsDrawerDropdownOption[] {
  return customerUsers.map(({ Email }) => ({
    value: Email,
    label: Email, // Add preference to use DisplayName ?
  }));
}

/**
 * Returns an array of 24-hour time options in 15-minute intervals.
 */
export function getTimeDrawerOptions(): InputsAndDropdownsDrawerDropdownOption[] {
  return Array.from({ length: 24 }, (_, h) => {
    const hours = h < 10 ? `0${h}` : h;
    return ['00', '15', '30', '45']
      .map(minutes => `${hours}:${minutes}:00`)
      .map(time => ({ value: time, label: `${time} UTC` }));
  }).flat();
}

export function getCustomerDrawerOptions(
  customers: Pick<Customer, 'Name' | 'DisplayName'>[]
): InputsAndDropdownsDrawerDropdownOption[] {
  return customers.map(({ Name, DisplayName }) => ({
    value: Name,
    label: DisplayName ?? Name,
  }));
}

export function getCustomerMarketAccountOptions(
  customerMarketAccounts: Pick<MarketAccount, 'Name' | 'DisplayName' | 'Counterparty' | 'SourceAccountID'>[]
): InputsAndDropdownsDrawerDropdownOption[] {
  return customerMarketAccounts.map(({ Name, SourceAccountID, DisplayName }) => {
    return {
      value: Name,
      label: DisplayName ?? SourceAccountID,
    };
  });
}

export function getSecurityDrawerOptions(
  securities: Pick<CustomerSecurity | Security, 'Symbol' | 'DisplaySymbol' | 'Description'>[]
): InputsAndDropdownsDrawerDropdownOption[] {
  return securities.map(({ Symbol, DisplaySymbol, Description }) => ({
    value: Symbol,
    label: DisplaySymbol ?? Symbol,
    description: Description,
  }));
}

export function getCurrencyDrawerOptions(
  currencies: Pick<Currency, 'Symbol' | 'Description'>[]
): InputsAndDropdownsDrawerDropdownOption[] {
  return currencies.map(({ Symbol, Description }) => ({
    value: Symbol,
    label: Symbol,
    description: Description,
  }));
}

export function getAddressTypeDrawerOptions(): InputsAndDropdownsDrawerDropdownOption[] {
  return [
    { value: CustomerAddressTypeEnum.Deposit, label: 'Deposit' },
    { value: CustomerAddressTypeEnum.Withdrawal, label: 'Withdrawal' },
  ];
}

export function getOrdTypeDrawerOptions(): InputsAndDropdownsDrawerDropdownOption[] {
  return [
    {
      value: OrdTypeEnum.Limit,
      label: 'Limit',
    },
    {
      value: OrdTypeEnum.Market,
      label: 'Market',
    },
  ];
}

enum FIXConnectionTypeEnum {
  MarketData = 'MARKETDATA',
  Orders = 'ORDERS',
  DropCopy = 'DROPCOPY',
}

export function getConnectionTypeDrawerOptions(): InputsAndDropdownsDrawerDropdownOption[] {
  return [
    {
      value: FIXConnectionTypeEnum.Orders,
      label: 'Orders',
      description: 'Orders FIX Connection',
    },
    {
      value: FIXConnectionTypeEnum.DropCopy,
      label: 'Drop Copy',
      description: 'Post-Trade FIX Connection',
    },
    {
      value: FIXConnectionTypeEnum.MarketData,
      label: 'Market Data',
      description: 'MarketDataDepth and MarketDataTrades Connection Types',
    },
  ];
}

export function getExecutionStrategiesDrawerOptions(): InputsAndDropdownsDrawerDropdownOption[] {
  return [
    {
      value: CustomerExecutionStrategyEnum.OrderAcceptance,
      label: 'Order Acceptance',
      description: 'Execute Customer Order Unhedged',
    },
    {
      value: CustomerExecutionStrategyEnum.RisklessPrincipal,
      label: 'Riskless Principal',
      description: 'Execute with a Cover and Deal Strategy',
    },
  ];
}

export function getTimeInForceDrawerOptions(): InputsAndDropdownsDrawerDropdownOption[] {
  return [
    {
      value: TimeInForceEnum.FillAndKill,
      label: 'Fill and Kill',
    },
    {
      value: TimeInForceEnum.FillOrKill,
      label: 'Fill or Kill',
    },
    {
      value: TimeInForceEnum.GoodTillCancel,
      label: 'Good Till Cancel',
    },
  ];
}

export function getPricingAggregationDrawerOptions(
  aggregations: Pick<Aggregation, 'Name' | 'DisplayName'>[]
): InputsAndDropdownsDrawerDropdownOption[] {
  return aggregations.map(({ Name, DisplayName }) => ({
    value: Name,
    label: DisplayName,
  }));
}

export function getFeeModeDrawerOptions(feeModeOptions: MarketFeeModeEnum[]): InputsAndDropdownsDrawerDropdownOption[] {
  return feeModeOptions.map(mode => ({ value: mode, label: prettyName(mode) }));
}

export function getTierDrawerOptions(
  tiers: Pick<AllInTier | FeeTier | SymbolGroup, 'Tier'>[]
): InputsAndDropdownsDrawerDropdownOption[] {
  return tiers.map(({ Tier }) => ({ value: Tier, label: Tier }));
}

import { createContext, memo, useContext, useMemo, type PropsWithChildren } from 'react';
import { scan, shareReplay } from 'rxjs/operators';

import {
  CUSTOMER_CREDIT,
  useObservable,
  useObservableValue,
  useStaticSubscription,
  wsScanToMap,
  type CustomerCredit,
} from '@talos/kyoko';
import type { Observable } from 'rxjs';

export interface CustomerCreditContextProps {
  customerCreditByMarketAccount: Map<string, CustomerCredit> | undefined;
  customerCreditByCounterpartyAndMarketAccount: Map<string, Map<string, CustomerCredit>> | undefined;
}

const CustomerCreditContext = createContext<CustomerCreditContextProps | null>(null);
CustomerCreditContext.displayName = 'CustomerCreditContext';

export function useCustomerCredit() {
  const context = useContext(CustomerCreditContext);
  if (context == null) {
    throw new Error('Missing CustomerCreditContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const CustomerCreditProvider = memo(function CustomerCreditProvider(props: PropsWithChildren<unknown>) {
  const { data: customerCreditSub } = useStaticSubscription({
    name: CUSTOMER_CREDIT,
    tag: 'CustomerCreditProvider',
  });

  const customerCreditByMarketAccountObs: Observable<Map<string, CustomerCredit>> = useObservable(
    () =>
      customerCreditSub.pipe(
        wsScanToMap({ getUniqueKey: d => d.MarketAccount, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customerCreditSub]
  );

  const customerCreditByMarketAccount = useObservableValue(
    () => customerCreditByMarketAccountObs,
    [customerCreditByMarketAccountObs]
  );

  const customerCreditByCounterpartyAndMarketAccountObs = useObservable(
    () =>
      customerCreditByMarketAccountObs.pipe(
        scan((memo, customerCredits) => {
          return [...customerCredits.values()].reduce((acc, customerCredit) => {
            if (!acc.has(customerCredit.Counterparty)) {
              acc.set(customerCredit.Counterparty, new Map());
            }
            acc.get(customerCredit.Counterparty)!.set(customerCredit.MarketAccount, customerCredit);
            return acc;
          }, memo);
        }, new Map<string, Map<string, CustomerCredit>>())
      ),
    [customerCreditByMarketAccountObs]
  );

  const customerCreditByCounterpartyAndMarketAccount = useObservableValue(
    () => customerCreditByCounterpartyAndMarketAccountObs,
    [customerCreditByCounterpartyAndMarketAccountObs]
  );

  const value = useMemo(
    () => ({
      customerCreditByMarketAccount,
      customerCreditByCounterpartyAndMarketAccount,
    }),
    [customerCreditByMarketAccount, customerCreditByCounterpartyAndMarketAccount]
  );

  return <CustomerCreditContext.Provider value={value}>{props.children}</CustomerCreditContext.Provider>;
});

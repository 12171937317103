import { FilterClauseType, type FilterClause, type Position, type UsePersistedBlotterTable } from '@talos/kyoko';
import { compact, isArray, isEqual, keys } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import type { PositionsTableFilter } from './types';

export interface UsePositionsBaseFilterParams<TData extends Position> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}
export function usePositionsBaseFilter<TData extends Position>({
  persistedBlotterTable,
}: UsePositionsBaseFilterParams<TData>) {
  const { initialFilter, onFilterChanged: saveFilter } = persistedBlotterTable;
  const [filter, setFilter] = useState<PositionsTableFilter>((initialFilter as PositionsTableFilter) || {});

  const changeFilter = useCallback(
    (action: SetStateAction<PositionsTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);

        // The need to push this to the next render cycle is that we have a common case now in the Sub account positions blotter where
        // we perform some cleaning of the Sub accounts clause selections on init. This causes us to trigger this changeFilter callback while mounting
        // the component. React does not like that we setState we are rendering based on in the same cycle. The usual way of handling this
        // is to move the logic for changing the state from a callback into a useEffect, but that is a larger refactor of our usePersistedBlotterTable.
        setTimeout(() => saveFilter(newFilter), 0);
      }
    },
    [filter, saveFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof PositionsTableFilter)[]).forEach((key: keyof PositionsTableFilter) => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: (isArray(filter[key]) ? filter[key] : compact([filter[key]])) as string[],
        });
      });
    }
    return clauses;
  }, [filter]);

  return {
    filter,
    initialFilterClauses,
    changeFilter,
  };
}

import { get } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Observable, iif, map, of } from 'rxjs';
import { useEndpointsContext } from '../contexts';
import { CUSTOMER_ORDER_SUMMARY } from '../tokens';
import type { CustomerOrder, CustomerQuote, SubscriptionResponse } from '../types';
import { CustomerDealSummary } from '../types/CustomerOrderSummary';
import { OrdStatusEnum, QuoteStatusEnum, type ICustomerOrderSummary } from '../types/types';
import { request } from '../utils';
import { useObservable, useObservableValue } from './useObservable';
import { useSubscription, type UseSubscriptionRequest } from './useSubscription';

type OrderArgs = {
  type: 'order';
  entity: Pick<CustomerOrder, 'OrderID' | 'OrdStatus' | 'CumQty' | 'RFQID'> | undefined;
  idField: 'OrderID' | 'RFQID';
  tag: string;
};
type QuoteArgs = {
  type: 'quote';
  entity: Pick<CustomerQuote, 'RFQID' | 'TradedQty' | 'QuoteStatus'> | undefined;
  idField: 'RFQID';
  tag: string;
};

export type CustomerSummaryArgs = OrderArgs | QuoteArgs;

/**
 * Hook to get the customer summary for a given order or quote.
 */
export function useCustomerSummary({ entity, tag, type, idField }: CustomerSummaryArgs) {
  const props: CustomerSummaryInnerProps = useMemo(() => {
    if (!entity) {
      return { id: undefined, tag, enabled: false, type };
    }
    const id = get(entity, idField);
    if (type === 'order') {
      const enabled = entity.OrdStatus !== OrdStatusEnum.Rejected && entity.CumQty !== '0';
      return { id, tag, enabled, type };
    }
    // its a quote
    const enabled = entity.QuoteStatus !== QuoteStatusEnum.Rejected && entity.TradedQty !== '0';
    return { id, tag, enabled, type };
  }, [entity, idField, tag, type]);

  return useCustomerSummaryInner(props);
}

type CustomerSummaryInnerProps = {
  type: 'order' | 'quote';
  tag: string;
  id: string | undefined;
  /**
   * Whether or not to make a request for the summary or not.
   */
  enabled: boolean;
};

function useCustomerSummaryInner({ type, tag, id, enabled }: CustomerSummaryInnerProps): CustomerDealSummary | null {
  const wsRequest: UseSubscriptionRequest | null = useMemo(() => {
    return type === 'order' && id
      ? { name: CUSTOMER_ORDER_SUMMARY, OrderID: id, tag }
      : type === 'quote' && id
      ? { name: CUSTOMER_ORDER_SUMMARY, RFQID: id, tag }
      : null;
  }, [id, type, tag]);

  const { data: summaryWsObsRaw } = useSubscription<ICustomerOrderSummary>(wsRequest);

  const [wsSummaryAvailable, setWsSummaryAvailable] = useState(true);
  const summaryWsObs = useObservable<CustomerDealSummary | null>(
    () =>
      summaryWsObsRaw.pipe(
        map(json => {
          const summary = json.data.at(-1);
          if (summary) {
            return new CustomerDealSummary({
              Fees: summary.PerCurrencyFees,
              FilledSpread: summary.ActualSpread,
              PnL: summary.PerCurrencyPnL,
              SalesCommission: summary.SalesCommission,
              Spread: summary.TargetSpread,
            });
          } else {
            setWsSummaryAvailable(false);
            return null;
          }
        })
      ),

    [summaryWsObsRaw]
  );

  const { orgApiEndpoint } = useEndpointsContext();
  const summaryRESTObs = useObservable<CustomerDealSummary | null>(
    () =>
      new Observable<CustomerDealSummary | null>(subscriber => {
        if (id == null) {
          subscriber.next(null);
          subscriber.complete();
          return;
        }
        request<SubscriptionResponse<CustomerDealSummary>>(
          'GET',
          `${orgApiEndpoint}/customer/${type}s/${id}/summary-v2`
        )
          .then(response => {
            const summary = response.data.at(-1);
            if (summary) {
              subscriber.next(new CustomerDealSummary(summary));
            }
            subscriber.complete();
          })
          .catch(error => {
            subscriber.error(error);
          });
      }),
    [id, orgApiEndpoint, type]
  );

  const shouldUseWs = wsSummaryAvailable && wsRequest !== null;

  const result = useObservableValue(
    () =>
      iif(
        () => !enabled,
        of(null),
        iif(() => shouldUseWs, summaryWsObs, summaryRESTObs)
      ),
    [enabled, shouldUseWs, summaryWsObs, summaryRESTObs],
    null
  );

  return result;
}

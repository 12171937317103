import type { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';
import { get } from 'lodash-es';
import { CustomerOrder, type Order } from '../../../types';
import { getOrderStatusText, OrderStatus, type OrderStatusText } from '../../Status';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';
import { stringColumnComparator } from './utils';

export type OrderStatusParams = {
  tooltipField?: string;
};

type OrderStatusValue = { statusText: OrderStatusText; Text?: string } | undefined;
export const orderStatus: ColDefFactory<Column<OrderStatusParams>> = column => {
  return {
    ...baseColumn(column),
    valueGetter: ({ data }: ValueGetterParams<CustomerOrder | Order>): OrderStatusValue => {
      if (!data) {
        return;
      }
      const statusText = getOrderStatusText({
        type: 'order',
        order: {
          ...data,
          PricingMode: data instanceof CustomerOrder ? undefined : data.PricingMode,
        },
      });

      return { statusText, Text: data.Text };
    },
    valueFormatter: ({ value }: ValueFormatterParams<unknown, OrderStatusValue>) => {
      if (!value) {
        return '';
      }
      return value.statusText;
    },
    cellRenderer: (params: ICellRendererParams) => {
      const data = params.data;
      if (!data) {
        return params.value;
      }
      const { OrdStatus, OrderQty, CumQty, DecisionStatus, Text, PricingMode } = data;
      const text = (column.params?.tooltipField && get(data, column.params?.tooltipField)) || Text;
      return (
        <OrderStatus
          theme={params.context.current.theme}
          ordStatus={OrdStatus}
          decisionStatus={DecisionStatus}
          cumQty={CumQty}
          orderQty={OrderQty}
          pricingMode={data instanceof CustomerOrder ? undefined : PricingMode}
          tooltipText={text}
          iconPlacement="left"
        />
      );
    },
    comparator: (valueA: OrderStatusValue, valueB: OrderStatusValue) =>
      stringColumnComparator(valueA?.statusText, valueB?.statusText),
    minWidth: 180,
    maxWidth: 220,
    flex: 1,
  };
};

import { useCallback, useMemo } from 'react';
import { useCurrency, useGlobalToasts } from '../../hooks';
import {
  CustomerBalanceTransactionStatusEnum,
  CustomerBalanceTransactionTypeEnum,
  type CustomerTransaction,
} from '../../types';
import { copyText } from '../../utils';
import { IconButton } from '../Button';
import { Flex, HStack } from '../Core';
import { FormControlSizes } from '../Form';
import { InlineFormattedNumber } from '../FormattedNumber';
import { IconName } from '../Icons';
import { FormattedMessage } from '../Intl';
import { NotificationVariants } from '../Notification';
import { getFriendlyTransferStatus } from '../Status';
import { Text } from '../Text';
import { messages } from './messages';

export function CustomerTransactionSummary({ entity }: { entity: CustomerTransaction }) {
  const currencyInfo = useCurrency(entity.Currency);
  const { add: addToast } = useGlobalToasts();

  const handleOnClickCopyTxHash = useCallback(() => {
    copyText(entity.TxHash);
    addToast({
      text: <FormattedMessage {...messages.txHashCopiedToClipboard} />,
      variant: NotificationVariants.Positive,
    });
  }, [addToast, entity.TxHash]);

  const transactionMessage = useMemo(() => {
    switch (entity.Initiator) {
      // Transaction Summary for Initiator=Dealer
      case 'Dealer':
        switch (entity.TransactionType) {
          case CustomerBalanceTransactionTypeEnum.Deposit:
            switch (entity.Status) {
              case CustomerBalanceTransactionStatusEnum.PendingApproval:
                return (
                  <Text>
                    <FormattedMessage {...messages.pendingSecondaryApproval} />
                  </Text>
                );
              case CustomerBalanceTransactionStatusEnum.Completed:
                if (entity.DealerUser) {
                  // None
                  return <Text>{getFriendlyTransferStatus(entity.Status)}</Text>;
                }
                return (
                  <Text>
                    <FormattedMessage
                      {...messages.typeAutoApproved}
                      values={{ transactionType: entity.TransactionType }}
                    />
                  </Text>
                );
              default:
                // None
                return <Text>{getFriendlyTransferStatus(entity.Status)}</Text>;
            }
          case CustomerBalanceTransactionTypeEnum.Withdrawal:
            switch (entity.Status) {
              case CustomerBalanceTransactionStatusEnum.PendingTransfer:
                return (
                  <Flex gap="spacingDefault" flexDirection="column">
                    <Text>
                      <FormattedMessage
                        {...messages.userLockedQuantity}
                        values={{
                          quantity: () => (
                            <InlineFormattedNumber
                              number={entity.Quantity}
                              currency={entity.Currency}
                              increment={currencyInfo?.DefaultIncrement}
                            />
                          ),
                          user: 'System',
                        }}
                      />
                    </Text>
                    <Text>
                      <FormattedMessage {...messages.pendingTransferConfirmation} />
                    </Text>
                  </Flex>
                );
              case CustomerBalanceTransactionStatusEnum.Completed:
                if (entity.TreasuryUser) {
                  // None
                  return <Text>{getFriendlyTransferStatus(entity.Status)}</Text>;
                }
                return (
                  <Text>
                    <FormattedMessage
                      {...messages.userApprovedType}
                      values={{
                        user: 'System',
                        transactionType: entity.TransactionType,
                      }}
                    />
                  </Text>
                );
              case CustomerBalanceTransactionStatusEnum.Rejected:
                if (entity.TreasuryUser) {
                  <Text>
                    <FormattedMessage
                      {...messages.userReleasedQuantity}
                      values={{
                        quantity: () => (
                          <InlineFormattedNumber
                            number={entity.Quantity}
                            currency={entity.Currency}
                            increment={currencyInfo?.DefaultIncrement}
                          />
                        ),
                        user: 'System',
                      }}
                    />
                  </Text>;
                }
                return (
                  <Flex gap="spacingDefault" flexDirection="column">
                    <Text>
                      <FormattedMessage
                        {...messages.userRejectedTypeForQuantity}
                        values={{
                          quantity: () => (
                            <InlineFormattedNumber
                              number={entity.Quantity}
                              currency={entity.Currency}
                              increment={currencyInfo?.DefaultIncrement}
                            />
                          ),
                          transactionType: entity.TransactionType,
                          user: 'System',
                        }}
                      />
                    </Text>
                    <Text>{entity.RejectText}</Text>
                  </Flex>
                );
              default:
                // None
                return <Text>{getFriendlyTransferStatus(entity.Status)}</Text>;
            }
          default:
            // None
            return <Text>{getFriendlyTransferStatus(entity.Status)}</Text>;
        }

      // Transaction Summary for Initiator=Customer
      case 'Customer':
        switch (entity.Status) {
          case CustomerBalanceTransactionStatusEnum.PendingApproval:
            return (
              <Flex gap="spacingDefault" flexDirection="column">
                <Flex gap="spacingSmall">
                  <FormattedMessage
                    {...messages.userLockedQuantity}
                    values={{
                      quantity: () => (
                        <InlineFormattedNumber
                          number={entity.Quantity}
                          currency={entity.Currency}
                          increment={currencyInfo?.DefaultIncrement}
                        />
                      ),
                      user: 'System',
                    }}
                  />
                </Flex>
                <Text>
                  <FormattedMessage {...messages.pendingDealerApproval} />
                </Text>
              </Flex>
            );
          case CustomerBalanceTransactionStatusEnum.PendingTransfer:
            return (
              <Text>
                <FormattedMessage {...messages.pendingTransferConfirmation} />
              </Text>
            );
          case CustomerBalanceTransactionStatusEnum.Completed:
            return (
              <Flex gap="spacingDefault" alignItems="center" justifyContent="space-between">
                <HStack gap="spacingSmall" justifyContent="flex-start" minWidth="0" w="100%">
                  <Text weight="fontWeightBold">
                    <FormattedMessage {...messages.txHash} />:{' '}
                  </Text>
                  <Text overflow="hidden" textOverflow="ellipsis" title={entity.TxHash || undefined}>
                    {entity.TxHash || <FormattedMessage {...messages.notSpecified} />}
                  </Text>
                </HStack>
                {entity.TxHash && (
                  <IconButton
                    icon={IconName.Duplicate}
                    size={FormControlSizes.Small}
                    onClick={handleOnClickCopyTxHash}
                  />
                )}
              </Flex>
            );
          case CustomerBalanceTransactionStatusEnum.Rejected:
            return (
              <Flex gap="spacingDefault" flexDirection="column">
                <Text>
                  <FormattedMessage
                    {...messages.userRejectedDueToReason}
                    values={{
                      reason: entity.RejectReason,
                      user: 'System',
                    }}
                  />
                </Text>
                <Text>{entity.RejectText}</Text>
              </Flex>
            );
          default:
            return <Text>{getFriendlyTransferStatus(entity.Status)}</Text>;
        }

      // Fallback
      default:
        return <Text>{getFriendlyTransferStatus(entity.Status)}</Text>;
    }
  }, [entity, currencyInfo, handleOnClickCopyTxHash]);

  return (
    <Flex flexDirection="column" gap="spacingDefault">
      {transactionMessage}
    </Flex>
  );
}

import { compact, concat, flatten, uniq } from 'lodash-es';
import { useMemo } from 'react';
import { useMarketAccountsContext, useStrategiesContext } from '../contexts';
import { useUnifiedLiquidityContext, type UnifiedLiquidityResponse } from '../providers/UnifiedLiquidityProvider';
import type { Parameter } from '../types/OrderStrategy';
import { ParameterTypeEnum } from '../types/OrderStrategy.types';
import { useSecurity } from './useSecurity';

export function useUnifiedLiquidity(symbol?: string): UnifiedLiquidityResponse | undefined {
  const { unifiedLiquidityTokenBySymbol } = useUnifiedLiquidityContext();
  return symbol ? unifiedLiquidityTokenBySymbol?.get(symbol) : undefined;
}

export type UnifiedLiquidityParameter = Parameter & { Type: ParameterTypeEnum.UnifiedLiquidity };
export function isUnifiedLiquidityParameter(parameter: Parameter): parameter is UnifiedLiquidityParameter {
  return parameter.Type === ParameterTypeEnum.UnifiedLiquidity;
}
export function useUnifiedLiquidityParameter(): UnifiedLiquidityParameter | null {
  const { strategiesList } = useStrategiesContext();
  return useMemo(() => {
    for (const strategy of strategiesList) {
      for (const parameter of strategy.Parameters) {
        if (isUnifiedLiquidityParameter(parameter)) {
          return parameter;
        }
      }
    }
    return null;
  }, [strategiesList]);
}

interface UseUnifiedLiquidityAvailableProps {
  markets: string[];
  marketAccounts: string[];
  symbol?: string;
}

/**
 * Returns list of available symbols under unified liquidity given a list of markets, marketaccounts and symbol.
 */
export function useUnifiedLiquidityAvailableSymbols({
  markets,
  marketAccounts,
  symbol,
}: UseUnifiedLiquidityAvailableProps): string[] {
  const unifiedLiquidityToken = useUnifiedLiquidity(symbol);
  const { marketAccountsByName } = useMarketAccountsContext();
  const security = useSecurity(symbol);

  return useMemo(() => {
    if (!symbol) {
      return [];
    }
    if (!unifiedLiquidityToken) {
      return [symbol];
    }
    // Find all markets given marketAccounts.
    const marketsSet = new Set(
      compact(
        concat(
          markets,
          flatten(
            marketAccounts.map(selectedeMarketAccount => marketAccountsByName.get(selectedeMarketAccount)?.Market)
          )
        )
      )
    );

    // There are scenarios where market selection would result in baseSymbol not being tradable.
    // For example USDT USD on binance and binance us.
    const includeBaseSymbol = !!security?.Markets?.find(market => marketsSet.has(market));

    // Based on marketsSet, check which symbols we'll potentially trade using the unified liquidity token
    return uniq(
      concat(
        includeBaseSymbol ? unifiedLiquidityToken.Symbol : [],
        unifiedLiquidityToken.Tokens.filter(token => marketsSet.has(token.Market)).map(token => token.Symbol)
      )
    );
  }, [marketAccounts, marketAccountsByName, markets, security?.Markets, symbol, unifiedLiquidityToken]);
}

import type { BaseEntityAdminProps, EntityAdminRecord } from '../types';
import { InputsAndDropdownsDrawer, type InputsAndDropdownsDrawerProps } from './InputsAndDropdownsDrawer';
import { ModifyJsonDrawer } from './ModifyJsonDrawer';

export const EntityAdminDrawer = <TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord>({
  drawerOptions,
  selectedEntity,
  onSaveEntity,
  onDeleteEntity,
  allowAddEntity,
  allowEditEntity,
  allowDeleteEntity,
  addingChildEntity,
  drawerType = drawerOptions.length ? 'InputsAndDropdowns' : 'JSON',
}: Pick<
  BaseEntityAdminProps<TRecord, TDrawerRecord>,
  'drawerOptions' | 'drawerType' | 'allowAddEntity' | 'allowEditEntity' | 'allowDeleteEntity'
> &
  Omit<InputsAndDropdownsDrawerProps<TRecord, TDrawerRecord>, 'isEditing'>) => {
  if (!allowAddEntity && !allowEditEntity) {
    // No permissions to add or edit entities using the drawer
    return null;
  }

  return (
    <>
      {drawerType === 'JSON' && (
        <ModifyJsonDrawer<TRecord, TDrawerRecord>
          selectedEntity={selectedEntity}
          onSaveEntity={onSaveEntity}
          allowDeleteEntity={allowDeleteEntity}
          onDeleteEntity={onDeleteEntity}
        />
      )}
      {drawerType === 'InputsAndDropdowns' && drawerOptions != null && (
        <InputsAndDropdownsDrawer<TRecord, TDrawerRecord>
          selectedEntity={selectedEntity}
          drawerOptions={drawerOptions}
          onSaveEntity={onSaveEntity}
          allowDeleteEntity={allowDeleteEntity}
          onDeleteEntity={onDeleteEntity}
          isEditing={selectedEntity != null && !addingChildEntity}
        />
      )}
    </>
  );
};

import type { GridApi, GridOptions, RowClassRules } from 'ag-grid-community';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import type { BlotterTableClientLocalFilter, ColumnOrColumnGroup, UsePersistedBlotterTable } from '..';
import { useCallbackRef } from '../../../hooks/useCallbackRef';
import { WarningSeverity } from '../../../types';
import { WARNING_ROW_CLASSNAME } from '../../AgGrid';
import { useBlotterTableContext, type BlotterTableContextProps } from '../BlotterTableContext';
import { BlotterDensity } from '../types';
import { alphabeticalGroupOrder, quickFilterParser } from '../utils';
import { applyInitialSortsToColumns } from './applySortsToColumns';
import type { UseBlotterTableProps } from './types';
import { useBlotterTableMenus } from './useBlotterTableMainMenu';

/** UseBlotterTable's gridOption's prop intends to represent a GridOptions object to the developer,
 * but there are limited properties that useBlotterTable modifies behavior of, so this protects from that usage
 *
 * Props excluded:
 * - context: the BlotterTableContextProps is always included, so the main prop adds to it
 * - getRowId: the rowID prop is used for data transaction processing, so needed for useBlotterTable
 * - onSortChanged: useBlotterTable specially handles our sort +/- key format, so this is overriden for now (candidate for refactoring)
 * - colDefs: useBlotterTable utilizes our custom Column definition, so would cause a conflict
 * - isExternalFilterPresent, doesExternalFilterPass: clientLocalFilter overrides this behavior
 */
type UseBlotterTableGridOptions<TRow> = Omit<
  GridOptions<TRow>,
  'context' | 'getRowId' | 'onSortChanged' | 'colDefs' | 'isExternalFilterPresent' | 'doesExternalFilterPass'
>;

export type UseBlotterTableGridOptionsProps<TRow> = {
  /** Custom GridOptions provided with useBlotterTable hook call */
  gridOptions: UseBlotterTableGridOptions<TRow> | undefined;

  // The rest of the props are useBlotterTable inputs used to customize the gridOptions
  clientLocalFilter?: BlotterTableClientLocalFilter<TRow>;
  columns: ColumnOrColumnGroup[];
  autoGroupColumnDef?: GridOptions<TRow>['autoGroupColumnDef'];
  columnDefs: NonNullable<GridOptions['columnDefs']>;
  customBlotterContext: UseBlotterTableProps<TRow>['context'];
  density: UseBlotterTableProps<TRow>['density'];
  getParamsFormatted: GridOptions['processCellForClipboard'];
  onGridReady: NonNullable<GridOptions<TRow>['onGridReady']>;
  rowID: UseBlotterTableProps<TRow>['rowID'];
  initialSort: UseBlotterTableProps<TRow>['initialSort'];
  suppressContextChangeRefreshForce: UseBlotterTableProps<TRow>['suppressContextChangeRefreshForce'];
  persistence?: UsePersistedBlotterTable<TRow>;
};

// memoization and customization of AgGrid GridOptions {@link GridOptions}
export function useBlotterTableGridOptions<TRow>({
  gridOptions,
  clientLocalFilter,
  columns,
  columnDefs,
  customBlotterContext,
  density,
  getParamsFormatted,
  onGridReady,
  rowID,
  initialSort,
  suppressContextChangeRefreshForce,
  persistence,
}: UseBlotterTableGridOptionsProps<TRow>): GridOptions<TRow> {
  const [api, setApi] = useState<GridApi | null>(null);
  const {
    rowHeightBlotterTableCompact,
    rowHeightBlotterTableDefault,
    rowHeightBlotterTableComfortable,
    rowHeightBlotterTableVeryComfortable,
  } = useTheme();
  const resolvedRowHeight =
    gridOptions?.rowHeight ??
    (density === BlotterDensity.Compact
      ? rowHeightBlotterTableCompact
      : density === BlotterDensity.Comfortable
      ? rowHeightBlotterTableComfortable
      : density === BlotterDensity.VeryComfortable
      ? rowHeightBlotterTableVeryComfortable
      : rowHeightBlotterTableDefault);

  // memoize the onGridReady callback in case someone else wants to use it
  // if user provides their own onGridReady, we need to call both
  // - useCallbackRef used to avoid recreating the function on every render for custom gridOptions
  const onGridReadyCallback = useCallbackRef<NonNullable<GridOptions['onGridReady']>>(params => {
    setApi(params.api);
    persistence?.gridOptionsOverlay?.onGridReady?.(params);
    // run feature-provided onGridReady
    gridOptions?.onGridReady?.(params);
    // run useGridBlottersTable's onGridReady
    onGridReady?.(params);
  });

  const rowClassRules: RowClassRules<TRow> | undefined = useMemo(() => {
    if (columns.find(c => c.type === 'warning' && c.hide !== true)) {
      return {
        [WARNING_ROW_CLASSNAME]: params =>
          (
            params?.data as {
              warningSeverity?: WarningSeverity;
            }
          )?.warningSeverity === WarningSeverity.HIGH,
      };
    }
    return undefined;
  }, [columns]);

  const getRowId = useCallback<NonNullable<GridOptions['getRowId']>>(
    ({ data }) => (rowID ? data?.[rowID] : null),
    [rowID]
  );

  const { getMainMenuItems, getContextMenuItems } = useBlotterTableMenus<TRow>(
    gridOptions?.getMainMenuItems,
    gridOptions?.getContextMenuItems,
    persistence?.onBlotterColumnReset
  );

  // Context
  const blotterTableContext = useBlotterTableContext();
  const agGridContext = useRef<BlotterTableContextProps | null>(null);
  useEffect(() => {
    agGridContext.current = {
      ...blotterTableContext,
      ...customBlotterContext,
    };
    api?.refreshCells({ force: suppressContextChangeRefreshForce ? false : true });
    api?.refreshHeader();
  }, [api, blotterTableContext, customBlotterContext, suppressContextChangeRefreshForce]);

  // - useCallbackRef used to avoid recreating the function on every render for custom gridOptions
  const onFirstDataRendered: GridOptions<TRow>['onFirstDataRendered'] = useCallbackRef(params => {
    persistence?.gridOptionsOverlay?.onFirstDataRendered?.(params);
    // run feature-provided onFirstDataRendered
    gridOptions?.onFirstDataRendered?.(params);
  });

  const memoizedGridOptions = useMemo(() => {
    const outputOptions: GridOptions<TRow> = {
      ...gridOptions,
      ...applyInitialSortsToColumns<TRow>({
        columnDefs,
        autoGroupColumnDef: gridOptions?.autoGroupColumnDef,
        initialSorts: initialSort ?? persistence?.initialSort,
      }),
      onFirstDataRendered,
      onGridReady: onGridReadyCallback,
      animateRows: gridOptions?.animateRows ?? false,
      suppressAggFuncInHeader: gridOptions?.suppressAggFuncInHeader ?? true,
      rowHeight: resolvedRowHeight,
      rowClassRules: gridOptions?.rowClassRules ?? rowClassRules,
      getRowId,
      getMainMenuItems,
      getContextMenuItems,
      isExternalFilterPresent: () => clientLocalFilter !== undefined,
      doesExternalFilterPass: node => clientLocalFilter?.(node) ?? true,
      cacheQuickFilter: true,
      quickFilterParser: gridOptions?.quickFilterParser ?? quickFilterParser,
      initialGroupOrderComparator: gridOptions?.initialGroupOrderComparator ?? alphabeticalGroupOrder,
      // @ts-expect-error - The context should always be set
      context: agGridContext,
      processCellForClipboard: gridOptions?.processCellForClipboard ?? getParamsFormatted,
    };
    return outputOptions;
  }, [
    gridOptions,
    columnDefs,
    initialSort,
    persistence?.initialSort,
    onFirstDataRendered,
    onGridReadyCallback,
    resolvedRowHeight,
    rowClassRules,
    getRowId,
    getMainMenuItems,
    getContextMenuItems,
    getParamsFormatted,
    clientLocalFilter,
  ]);

  return memoizedGridOptions;
}

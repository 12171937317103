import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
  mapBlotterColumnStateToBlotterTableSort,
  type BlotterColumnState,
  type BlotterTableFilter,
  type RowGroupsOpenedState,
} from '@talos/kyoko';
import type { AppConfigState } from 'providers/AppConfigProvider/AppConfigProvider.types';

let blotter2Actions: ReturnType<typeof createBlotter2Slice>['actions'] | undefined;
export const getBlotters2SliceActions = () => {
  if (!blotter2Actions) {
    throw new Error('AppConfigSlice not initialized');
  }
  return blotter2Actions;
};

/** Reducer for {@link AppConfigReduxState}'s blotters2, providing storage for with usePersistedBlotterTable2 (Grid and Filter state storage) */
export const createBlotter2Slice = (appConfig: AppConfigState) => {
  const result = createSlice({
    name: 'appConfig/blotters2',
    initialState: appConfig.blotters2,
    reducers: {
      setColumnState: (
        state,
        action: PayloadAction<{
          blotterId: string;
          blotterColumnState: BlotterColumnState[];
        }>
      ) => {
        if (!(action.payload.blotterId in state)) {
          state[action.payload.blotterId] = {};
        }
        state[action.payload.blotterId].columns = action.payload.blotterColumnState;
        state[action.payload.blotterId].sort = mapBlotterColumnStateToBlotterTableSort(
          action.payload.blotterColumnState
        );
      },

      resetColumnState: (
        state,
        action: PayloadAction<{
          blotterId: string;
        }>
      ) => {
        if (action.payload.blotterId in state) {
          delete state[action.payload.blotterId].columns;
          delete state[action.payload.blotterId].sort;
        }
      },

      setFilterState: (
        state,
        action: PayloadAction<{
          blotterId: string;
          /** Blotter filter to save, undefined to clear it */
          blotterFilter: BlotterTableFilter | undefined;
        }>
      ) => {
        if (!(action.payload.blotterId in state)) {
          state[action.payload.blotterId] = {};
        }
        state[action.payload.blotterId].filter = action.payload.blotterFilter;
      },

      setRowGroupsOpenedState: (
        state,
        action: PayloadAction<{
          blotterId: string;
          rowGroupsOpened: RowGroupsOpenedState;
        }>
      ) => {
        if (!(action.payload.blotterId in state)) {
          state[action.payload.blotterId] = {};
        }
        state[action.payload.blotterId].rowGroupsOpened = action.payload.rowGroupsOpened;
      },

      clearAllBlotterState: state => {
        Object.keys(state).forEach(blotterId => {
          delete state[blotterId];
        });
      },
    },
  });
  blotter2Actions = result.actions;
  return result;
};

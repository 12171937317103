import { EMPTY_ARRAY, LoaderTalos, MixpanelEvent, MixpanelEventProperty, useMixpanel } from '@talos/kyoko';
import type {
  DockviewApi,
  DockviewReadyEvent,
  IDockviewPanel,
  IDockviewPanelProps,
  IWatermarkPanelProps,
  SerializedDockview,
  TabDragEvent,
} from 'dockview';
import { DockviewReact, themeDark as dockviewThemeDark } from 'dockview';
import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import type { LayoutSpec, PanelComponentSpec, PanelType } from 'types/LayoutConfig';
import { ErrorBoundary } from '../ErrorBoundary';
import { AddTabComponent } from './Components/AddTabComponent';
import { RightComponent } from './Components/RightComponent';
import { TAB_COMPONENTS } from './Components/TabComponents';
import { LayoutContextProvider, type LayoutContextProps } from './providers/LayoutContextProvider';
import { DockViewStyles, LayoutWrapper } from './styles';
import { STYLE_TAG_ID } from './tokens';
import type { AddPanelOptions, MinimizedPanel } from './types';
import { addStyles } from './utils/addStyles';
import { removeStyles } from './utils/removeStyles';
import { applyLayoutConstraints, updatePanelVisibility } from './utils/utils';

export interface LayoutProps {
  /** Provide a context specific method to add a panel using the api from dockviewApiRef */
  addPanel: (type: PanelType, options: AddPanelOptions) => void;

  selectedLayout: LayoutSpec | undefined;

  /** Which toggleable panels are open */
  openPanels?: string[];

  /** If the layout should allow popout windows */
  enablePopoutWindows?: boolean;
  onPopoutGroup?: (groupId: string, popoutWindow: Window | undefined, windowId?: string) => void;

  /** What type of panels can be rendered. Must match the up with the components in the selectedLayout */
  panelComponents: Record<string, FunctionComponent<IDockviewPanelProps>>;

  onLayoutReady: (api: DockviewApi) => void;
  onLayoutChange: (layout: SerializedDockview) => void;
  onActivePanelChange?: (panel: IDockviewPanel | undefined) => void;

  minimizedPanels?: MinimizedPanel[];
  maximizedPanels?: string[];
  onMaximizePanel?: (groupId: string, open: boolean) => void;
  onMinimizePanel?: (groupId: MinimizedPanel, open: boolean) => void;

  /** What type of panels can be added by the user */
  availablePanelTypes: PanelComponentSpec[];

  dockviewApiRef?: React.MutableRefObject<DockviewApi | undefined>;
  watermarkComponent?: FunctionComponent<IWatermarkPanelProps>;

  /** How to protect the last closed Tab - default is 'mainGroup' (which looks for a group named 'main') */
  disallowLastCloseMode?: 'mainGroup' | 'lastTabInContainer';
}

interface PopoutConfig {
  /** The id (window.name) of the popout window */
  id: string;
  popoutWindow: Window;
  stylesObserver: MutationObserver;
  handleResize: () => void;
  handleFocus: () => void;
  handleBlur: () => void;
  handleKeyDown: (e: KeyboardEvent) => void;
}

/** Uses Dockview to layout groups of panels.
 * Can be used for an entire view layout or for laying out a specific type of content, like DeepDive */
export function Layout({
  addPanel,

  selectedLayout,
  openPanels = EMPTY_ARRAY,
  enablePopoutWindows,

  panelComponents,
  onLayoutReady,
  onLayoutChange,
  onActivePanelChange,

  watermarkComponent,

  minimizedPanels = EMPTY_ARRAY,
  maximizedPanels = EMPTY_ARRAY,
  onMaximizePanel,
  onMinimizePanel,
  onPopoutGroup,

  availablePanelTypes,
  disallowLastCloseMode = 'mainGroup',
}: LayoutProps) {
  const [dockviewApi, setDockviewApi] = useState<DockviewApi | undefined>();
  // Track if the layout is animating. We don't want transitions when a user is resizing a group
  const [isAnimating, setIsAnimating] = useState(false);

  const mixpanel = useMixpanel();

  const [popoutWindows, setPopoutWindows] = useState<Map<string, PopoutConfig | undefined>>(new Map());

  const handleReady = useCallback(
    (event: DockviewReadyEvent) => {
      setDockviewApi(event.api);
      if (selectedLayout) {
        event.api.fromJSON(selectedLayout.dockViewLayout);
        applyLayoutConstraints(selectedLayout.constraints, event.api);
        updatePanelVisibility(selectedLayout.collapsablePanels, event.api);
      }
      onLayoutReady(event.api);
    },
    [onLayoutReady, setDockviewApi, selectedLayout]
  );

  const collapsablePanels = selectedLayout?.collapsablePanels;

  // Update panel visibility based on openPanels
  useEffect(() => {
    if (!dockviewApi) {
      return;
    }
    openPanels.forEach(groupId => {
      if (!dockviewApi.activeGroup?.api.isMaximized()) {
        const group = dockviewApi.getGroup(groupId);
        if (group?.api.location.type === 'grid') {
          group?.api.setVisible(true);
        }
      }

      // Set default size for collapsable panels
      // Should only be applied on first open
      // const spec = selectedLayout?.collapsablePanels.find(panel => panel.groupId === groupId);
      // group?.api.setSize({ width: spec?.defaultSize })
    });
    collapsablePanels?.forEach(panel => {
      if (!openPanels.includes(panel.groupId)) {
        const group = dockviewApi.getGroup(panel.groupId);
        if (group?.api.location.type === 'grid') {
          group?.api.setVisible(false);
        }
      }
    });
    setIsAnimating(true);
    // Remove animating state after animation is done
    setTimeout(() => {
      setIsAnimating(false);
    }, 200);
  }, [dockviewApi, collapsablePanels, openPanels]);

  useEffect(() => {
    // Adjust minimized panel size here to enable restoring minimized panels on load
    if (!dockviewApi) {
      return;
    }
    minimizedPanels.forEach(panel => {
      const group = dockviewApi.getGroup(panel.groupId);
      if (group) {
        group.api.setConstraints({
          minimumHeight: 24,
        });
        group.api.setSize({
          height: 0,
        });
      }
    });
  }, [dockviewApi, minimizedPanels]);

  // Setup event listeners for layout changes to for callbacks and event tracking
  useEffect(() => {
    if (!dockviewApi) {
      return;
    }

    const disposables = [
      dockviewApi.onDidLayoutChange(() => {
        const serializedLayout = dockviewApi.toJSON();
        // Not tracking the layout change event for now since it updates for every type of change
        onLayoutChange(serializedLayout);
      }),

      dockviewApi.onDidActivePanelChange(panel => {
        mixpanel.track(MixpanelEvent.ChangeMarketTab, { [MixpanelEventProperty.PanelType]: panel?.api.component });
        onActivePanelChange?.(panel);
      }),

      dockviewApi.onDidRemoveGroup(group => {
        mixpanel.track(MixpanelEvent.RemoveLayoutGroup);
      }),

      dockviewApi.onDidAddGroup(group => {
        mixpanel.track(MixpanelEvent.AddLayoutGroup);
      }),

      dockviewApi.onWillDragPanel((e: TabDragEvent) => {
        e.panel.group.id === 'main' && e.panel.group.panels.length === 1 && e.nativeEvent.preventDefault();
      }),

      dockviewApi.onDidMovePanel(e => {
        mixpanel.track(MixpanelEvent.MovedLayoutPanel, { [MixpanelEventProperty.PanelType]: e.panel.api.component });
      }),

      dockviewApi.onDidRemovePanel((panel: IDockviewPanel) => {
        mixpanel.track(MixpanelEvent.RemoveLayoutPanel, { [MixpanelEventProperty.PanelType]: panel.api.component });
      }),

      dockviewApi.onDidAddPanel((panel: IDockviewPanel) => {
        mixpanel.track(MixpanelEvent.AddLayoutPanel, { [MixpanelEventProperty.PanelType]: panel.api.component });
      }),
    ];

    return () => disposables.forEach(disposable => disposable.dispose());
  }, [dockviewApi, mixpanel, onLayoutChange, onActivePanelChange]);

  const handlePopoutGroup = useCallback(
    (groupId: string, popoutWindow: Window | undefined, id?: string) => {
      onPopoutGroup && onPopoutGroup(groupId, popoutWindow);

      if (popoutWindow && id) {
        const activePanel = dockviewApi?.getGroup(groupId)?.activePanel;

        const handleResize = () => {
          // Refresh layout config to store popout dimensions
          activePanel?.api.updateParameters({ refresh: Date.now });
        };
        const handleFocus = () => {
          // Refresh layout config to store popout position when popout is focused
          activePanel?.api.updateParameters({ refresh: Date.now });
        };
        const handleBlur = () => {
          // Refresh layout config to store popout position when popout is not focused
          activePanel?.api.updateParameters({ refresh: Date.now });
        };

        const handleKeyDown = (e: KeyboardEvent) => {
          /* Prevent refresh on F5 or Cmd/Ctrl+R as it breaks the popout,
           instead close the popout window so users can recover if the page crashed */
          if (e.key === 'F5' || (e.key === 'r' && e.metaKey)) {
            e.preventDefault();
            popoutWindow.close();
          }
        };

        // Start observing changes to styles in the parent window and add them to the popout window
        const stylesObserver = new MutationObserver(() => {
          removeStyles(popoutWindow.document, STYLE_TAG_ID);
          addStyles(popoutWindow.document, document.styleSheets, STYLE_TAG_ID);
        });
        const appLayoutStyles = window.document.getElementById(STYLE_TAG_ID);
        if (appLayoutStyles) {
          stylesObserver.observe(appLayoutStyles, { childList: true, characterData: true, subtree: true });
        }
        popoutWindow.addEventListener('resize', handleResize);
        popoutWindow.addEventListener('focus', handleFocus);
        popoutWindow.addEventListener('blur', handleBlur);
        popoutWindow.addEventListener('keydown', handleKeyDown);

        setPopoutWindows(prev =>
          new Map(prev).set(groupId, {
            id,
            popoutWindow,
            stylesObserver,
            handleResize,
            handleFocus,
            handleBlur,
            handleKeyDown,
          })
        );
      } else if (popoutWindows.has(groupId)) {
        const popout = popoutWindows.get(groupId);
        if (popout) {
          const { popoutWindow, handleResize, handleFocus, handleBlur, handleKeyDown, stylesObserver } = popout;
          stylesObserver.disconnect();
          popoutWindow.removeEventListener('resize', handleResize);
          popoutWindow.removeEventListener('focus', handleFocus);
          popoutWindow.removeEventListener('blur', handleBlur);
          popoutWindow.removeEventListener('keydown', handleKeyDown);
          setPopoutWindows(prev => {
            const next = new Map(prev);
            next.delete(groupId);
            return next;
          });
        }
      }
    },
    [onPopoutGroup, dockviewApi, popoutWindows]
  );

  const theme = useTheme();
  const darkTheme = useMemo(
    () => ({
      ...dockviewThemeDark,
      gap: theme.spacingLayout,
    }),
    [theme]
  );

  const context = useMemo((): LayoutContextProps => {
    return {
      addPanel,
      dockviewApi,
      isAnimating,
      setIsAnimating,
      maximizedPanels,
      minimizedPanels,
      availablePanelTypes,
      enablePopoutWindows: !!enablePopoutWindows,
      onMaximizePanel: (groupId: string, open: boolean) => onMaximizePanel && onMaximizePanel(groupId, open),
      onMinimizePanel: (groupId: MinimizedPanel, open: boolean) => onMinimizePanel && onMinimizePanel(groupId, open),
      onPopoutGroup: handlePopoutGroup,
      disallowLastCloseMode,
    };
  }, [
    addPanel,
    dockviewApi,
    onMaximizePanel,
    onMinimizePanel,
    maximizedPanels,
    minimizedPanels,
    isAnimating,
    setIsAnimating,
    enablePopoutWindows,
    availablePanelTypes,
    handlePopoutGroup,
    disallowLastCloseMode,
  ]);

  return (
    <ErrorBoundary>
      <LayoutContextProvider value={context}>
        <LayoutWrapper>
          <DockViewStyles />
          {selectedLayout ? (
            <DockviewReact
              onReady={handleReady}
              components={panelComponents}
              theme={darkTheme}
              className={isAnimating ? ' animating' : ''}
              leftHeaderActionsComponent={AddTabComponent}
              rightHeaderActionsComponent={RightComponent}
              tabComponents={TAB_COMPONENTS}
              defaultTabComponent={TAB_COMPONENTS.custom}
              watermarkComponent={watermarkComponent}
            />
          ) : (
            <LoaderTalos />
          )}
        </LayoutWrapper>
      </LayoutContextProvider>
    </ErrorBoundary>
  );
}
export default Layout;

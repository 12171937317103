import type { CSSProperties } from 'react';
import { Box } from '../Core';
import { LoaderWrapper, Square, Wrapper } from './styles';

export { LoaderWrapper } from './styles';

export enum LoaderSizes {
  XXS = 8,
  XS = 12,
  TINY = 16,
  SMALL = 24,
  MEDIUM = 32,
  DEFAULT = 40,
}

interface LoaderTalosProps {
  size?: LoaderSizes;
  wrapperStyle?: CSSProperties;
  text?: string;
  /**
   * Delay in ms before showing the loader
   */
  delay?: number;
  dataTestID?: string;
}

/**
 * The talos logo loader
 */
export const LoaderTalos = ({
  size = LoaderSizes.DEFAULT,
  text,
  wrapperStyle = {},
  delay,
  dataTestID,
}: LoaderTalosProps) => (
  <LoaderWrapper style={wrapperStyle} data-testid={dataTestID ?? 'loader'} delay={delay}>
    <LoaderTalosIcon text={text} size={size} />
  </LoaderWrapper>
);

export const LoaderTalosIcon = ({ size = LoaderSizes.DEFAULT, text }: { size?: LoaderSizes; text?: string }) => (
  <>
    <Wrapper size={size}>
      <Square />
      <Square />
      <Square />

      <Square />
      <Square />
      <Square />

      <Square />
      <Square />
      <Square />
    </Wrapper>
    {text && <Box mt={size / 2}>{text}</Box>}
  </>
);

import type { KeysBeginningWith } from '@talos/kyoko';
import type { Setters } from 'providers/types';
import { createContext, useContext } from 'react';

interface BlottersContextProps {
  groupMarketAccounts: boolean;
  balancesHiddenSymbols: unknown[];
  balancesHiddenMarketAccountIDs: unknown[];
  balancesMarketAccountIDsOrder: unknown[];
  balancesCurrenciesOrder: unknown;
  enableMonitoringCustomerOrderSummaryColumns: boolean;
  filterValueCustomerUsers: string | undefined;
  filterValueSecurityMaster: string | undefined;
  filterValueCustomerAddresses: string | undefined;
  filterValueCustomerBalances: string | undefined;
  filterValueCustomerTradingLimits: string | undefined;
  filterValueCustomerCredit: string | undefined;
  filterValueAggregations: string | undefined;
  filterValueSubAccountTradingLimits: string | undefined;
  filterValueSubAccountWindowLimits: string | undefined;
  isExpanded: boolean;
  isMinimized: boolean;
  sessionBlotterHeight: number | undefined;
  customerBalancesShowZeroBalances: boolean;

  setGroupMarketAccounts: (checked: boolean) => void;
  setBalancesHiddenSymbols: (symbols: string[]) => void;
  setBalancesHiddenMarketAccountIDs: (ids: string[]) => void;
  setBalancesMarketAccountIDsOrder: (ids: string[]) => void;
  setBalancesCurrenciesOrder: (ids: string[]) => void;
  setEnableMonitoringCustomerOrderSummaryColumns: (enableMonitoringCustomerOrderSummaryColumns: boolean) => void;
  setFilterValueCustomerUsers: (id: string) => void;
  setFilterValueAggregations: (id: string) => void;
  setFilterValueSubAccountTradingLimits: (value: string) => void;
  setFilterValueSubAccountWindowLimits: (value: string) => void;
  setIsExpanded: (isExpanded: boolean) => void;
  setIsMinimized: (isExpanded: boolean) => void;
  setSessionBlotterHeight: (blotterHeight: number) => void;

  setFilterValueSecurityMaster: (id: string) => void;
  setFilterValueCustomerAddresses: (id: string) => void;
  setFilterValueCustomerBalances: (id: string) => void;
  setFilterValueCustomerTradingLimits: (id: string) => void;
  setFilterValueCustomerCredit: (id: string) => void;
  setCustomerBalancesShowZeroBalances: (customerBalancesShowZeroBalances: boolean) => void;
}

export const DefaultBlottersConfig: Omit<BlottersContextProps, KeysBeginningWith<BlottersContextProps, 'set'>> = {
  groupMarketAccounts: true as boolean,

  balancesCurrenciesOrder: undefined,
  balancesHiddenSymbols: [],
  balancesHiddenMarketAccountIDs: [],
  balancesMarketAccountIDsOrder: [],
  enableMonitoringCustomerOrderSummaryColumns: true,
  filterValueCustomerUsers: undefined,
  filterValueSecurityMaster: undefined,
  filterValueCustomerAddresses: undefined,
  filterValueCustomerBalances: undefined,
  filterValueCustomerTradingLimits: undefined,
  filterValueCustomerCredit: undefined,
  filterValueAggregations: undefined,
  filterValueSubAccountTradingLimits: undefined,
  filterValueSubAccountWindowLimits: undefined,
  isExpanded: false,
  isMinimized: false,
  sessionBlotterHeight: undefined,
  customerBalancesShowZeroBalances: false,
} satisfies Omit<BlottersContextProps, KeysBeginningWith<BlottersContextProps, 'set'>>;

export const DefaultBlottersMethods = {
  setGroupMarketAccounts: () => {},
  setBalancesHiddenSymbols: () => {},
  setBalancesHiddenMarketAccountIDs: () => {},
  setBalancesMarketAccountIDsOrder: () => {},
  setBalancesCurrenciesOrder: () => {},
  setEnableMonitoringCustomerOrderSummaryColumns: () => {},
  setFilterValueCustomerUsers: () => {},
  setFilterValueSecurityMaster: () => {},
  setFilterValueCustomerAddresses: () => {},
  setFilterValueCustomerBalances: () => {},
  setFilterValueCustomerTradingLimits: () => {},
  setFilterValueCustomerCredit: () => {},
  setFilterValueAggregations: () => {},
  setFilterValueSubAccountTradingLimits: () => {},
  setFilterValueSubAccountWindowLimits: () => {},
  setIsExpanded: () => {},
  setIsMinimized: () => {},
  setSessionBlotterHeight: () => {},
  setCustomerBalancesShowZeroBalances: () => {},
} satisfies Omit<Setters<typeof DefaultBlottersConfig>, 'setBlotters'> &
  Pick<BlottersContextProps, KeysBeginningWith<BlottersContextProps, 'set'>>;

export const BlottersContext = createContext({
  ...DefaultBlottersConfig,
  ...DefaultBlottersMethods,
} as BlottersContextProps);

BlottersContext.displayName = 'BlottersContext';
export const useBlotterState = () => useContext(BlottersContext);

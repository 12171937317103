import type { ICellRendererParams } from 'ag-grid-enterprise';
import { get } from 'lodash-es';
import type { ReactNode } from 'react';
import { useCurrenciesContext, useSecuritiesContext } from '../../contexts';
import { Order } from '../../types/Order';
import { OrdTypeEnum, SideEnum } from '../../types/types';
import {
  getPricingReference,
  messages as prettyPricingMessages,
  prettyPricingMode,
  toBigWithDefault,
} from '../../utils';
import type { PriceValue } from '../BlotterTable/aggFuncs/priceAggFunc';
import { Flex } from '../Core';
import { InlineFormattedNumber, NumberVariants } from '../FormattedNumber';
import { IconName } from '../Icons';
import { FormattedMessage } from '../Intl';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import type { AgGridPriceProps } from './AgGridPrice.types';

function isPrettyPricingMessageKey(key: string): key is keyof typeof prettyPricingMessages {
  return key in prettyPricingMessages;
}

export function AgGridPrice({
  value,
  data,
  showCurrency = true,
  round,
  increment,
  showFeeIcon = 'auto',
  colDef,
  sideColor,
  showReferencePrice,
  showDashIfZero,
}: AgGridPriceProps & Pick<ICellRendererParams<unknown, PriceValue>, 'colDef' | 'value' | 'data'>) {
  const { currenciesBySymbol } = useCurrenciesContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const securityInfo = value?.asset ? securitiesBySymbol.get(value.asset) : undefined;
  const currencyInfo = value?.quoteCurrency ? currenciesBySymbol.get(value.quoteCurrency) : undefined;
  const ordType = data instanceof Order ? data.OrdType : undefined;

  let shouldShowFeeIcon = showFeeIcon === 'always';
  if (showFeeIcon === 'auto') {
    shouldShowFeeIcon = ordType === OrdTypeEnum.LimitAllIn || colDef?.field === 'AvgPxAllIn';
  }

  let variant: NumberVariants | undefined;
  const side = data instanceof Order ? data.AggressorSide : undefined;
  if (sideColor && side) {
    variant = side === SideEnum.Buy ? NumberVariants.Positive : NumberVariants.Negative;
  }

  const maybePricingMode = get(data, 'PricingMode');
  const maybePricingReference = get(data, 'PricingReference');

  if (!value || !value.price) {
    if (showReferencePrice) {
      // If no price returned from market (bad connection or order not yet started), we show the reference price
      const amount = getPricingReference({ PricingMode: maybePricingMode, PricingReference: maybePricingReference });
      return (
        <InlineFormattedNumber number={amount?.value} currency={amount?.currency} round={round} variant={variant} />
      );
    }

    return <></>;
  }
  if (showDashIfZero && toBigWithDefault(value.price, 0).eq(0)) {
    return '-';
  }

  return (
    <ReferencePriceTooltip
      disabled={!showReferencePrice}
      entity={{ PricingMode: maybePricingMode, PricingReference: maybePricingReference }}
    >
      <InlineFormattedNumber
        round={round}
        number={value.price}
        variant={variant}
        increment={increment ?? securityInfo?.DefaultPriceIncrement ?? currencyInfo?.DefaultIncrement}
        currency={showCurrency ? currencyInfo?.Symbol ?? value.quoteCurrency : null}
        startIcon={shouldShowFeeIcon ? IconName.Fees : undefined}
      />
    </ReferencePriceTooltip>
  );
}

/**
 * Renders the PricingReference as a tooltip.
 */
export function ReferencePriceTooltip({
  entity,
  disabled = false,
  children,
}: {
  entity: Partial<Pick<Order, 'PricingReference' | 'PricingMode'>>;
  disabled?: boolean;
  children: ReactNode;
}) {
  if (!entity.PricingMode || !entity.PricingReference || disabled) {
    return <>{children}</>;
  }

  const amount = getPricingReference(entity);
  if (!amount) {
    return <>{children}</>;
  }

  const pricingMode = prettyPricingMode(entity.PricingMode);
  const renderedPricingMode = isPrettyPricingMessageKey(pricingMode) ? (
    <FormattedMessage {...prettyPricingMessages[pricingMode]} />
  ) : (
    pricingMode
  );

  return (
    <Tooltip
      usePortal
      showUnderline
      tooltip={
        <Flex gap="spacingSmall">
          <Text>{renderedPricingMode}:</Text>
          <InlineFormattedNumber number={amount?.value} currency={amount?.currency} />
        </Flex>
      }
    >
      {children}
    </Tooltip>
  );
}

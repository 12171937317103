import {
  BlotterDensity,
  BlotterTable,
  Button,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  IconName,
  InstrumentCompositionEnum,
  LocalFilterInput,
  MixpanelEvent,
  NotificationVariants,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  createCSVFileName,
  useBlotterTable,
  useDynamicCallback,
  useEndpointsContext,
  useGetDefaultContextMenuItems,
  useGlobalToasts,
  useMixpanel,
  useObservable,
  useSecuritiesContext,
  type Security,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams } from 'ag-grid-community';
import { compact } from 'lodash-es';
import { useCallback } from 'react';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateMultilegDialog, useCreateMultilegDialog } from '../../../components/MultilegCombo/CreateMultilegDialog';
import { deleteMultileg } from '../../../utils/multileg';
import { useMultilegSecmasterColumns } from './columns';

export const MultilegSecurityMaster = function MultilegSecurityMaster() {
  const { searchableSecurities } = useSecuritiesContext();
  const { orgApiEndpoint } = useEndpointsContext();
  const { add: addToast } = useGlobalToasts();
  const mixpanel = useMixpanel();

  const dataObservable = useObservable(
    () =>
      of(searchableSecurities).pipe(
        map(data => ({
          data: data.filter(s => s.Composition === InstrumentCompositionEnum.Synthetic && s.EndTime == null),
          initial: true,
          type: 'Security',
        }))
      ),
    [searchableSecurities]
  );

  const createMultilegDialog = useCreateMultilegDialog();

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    return compact([
      {
        name: 'Delete',
        action: async () => {
          const symbol = params?.node?.data.Symbol;
          if (orgApiEndpoint && symbol) {
            try {
              await deleteMultileg(symbol, orgApiEndpoint);
              addToast({
                text: `Symbol ${symbol} deleted.`,
                variant: NotificationVariants.Positive,
              });
            } catch (e) {
              addToast({
                text: (e as Error).message,
                variant: NotificationVariants.Negative,
              });
            }
          }
        },
      },
      'separator',
      ...getDefaultContextMenuItems(params),
    ]);
  });

  const handleEdit = useDynamicCallback((security: Security) => {
    mixpanel.track(MixpanelEvent.EditMultileg);
    createMultilegDialog.onEdit(security.Symbol);
  });

  const handleCreate = useCallback(() => {
    createMultilegDialog.open();
  }, [createMultilegDialog]);

  const { columns } = useMultilegSecmasterColumns({ handleEdit });

  const blotterTable = useBlotterTable<Security>({
    dataObservable,
    columns,
    rowID: 'Symbol',
    density: BlotterDensity.Comfortable,
    initialSort: '+Symbol',
    gridOptions: {
      onRowDoubleClicked: ({ data }) => data != null && handleEdit(data),
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems,
    },
  });

  const handleExport = useCallback(() => {
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Multileg SecurityMaster',
      }),
    });
  }, [blotterTable]);

  return (
    <>
      <Panel alignItems="initial" justifyContent="initial">
        <PanelHeader>
          <h2>Multileg Instruments</h2>
          <PanelActions>
            <LocalFilterInput
              value={blotterTable.blotterTableFiltersProps.quickFilterText}
              onChange={evt => {
                blotterTable.blotterTableFiltersProps.onQuickFilterTextChanged(evt);
                mixpanel.track(MixpanelEvent.FilterQuickSearch);
              }}
              width="200px"
            />
            <Button startIcon={IconName.Plus} variant={ButtonVariants.Primary} onClick={handleCreate}>
              Add Symbol
            </Button>
            <Button startIcon={IconName.DocumentDownload} onClick={handleExport}>
              Export CSV
            </Button>
          </PanelActions>
        </PanelHeader>
        <PanelContent pb={0}>
          <BlotterTable {...blotterTable} />
        </PanelContent>
      </Panel>
      <CreateMultilegDialog {...createMultilegDialog} />
    </>
  );
};

import {
  type Allocation,
  type Customer,
  type CustomerConfiguration,
  type CustomerOrder,
  type DefaultMarketAccounts,
  EMPTY_ARRAY,
  type Field,
  type MarketAccount,
  ModeEnum,
  type NumericField,
  OrdTypeEnum,
  type Order,
  type ParameterLike,
  type Security,
  bigMax,
  toBigWithDefault,
} from '@talos/kyoko';
import type { WritableDraft } from 'immer';
import { compact, get, groupBy, map } from 'lodash-es';
import type { LegParams } from '../../providers/OMSContext.types';
import { isOrderTypeLimitAllIn } from '../../utils/security';
import { AvailabilityEnum, type MarketSelectorItem } from '../MarketSelector/types';
import { isMarketSelectorItemSelectable } from '../MarketSelector/utils/items';

/**
 * Given an array of selectable market accounts, returns the default ones.
 *
 * This function groups the available market accounts by market, sorts each group by display name,
 * and then takes the default one or first one if no default one is defined from each market grouping
 */
export function getOneAccountPerMarket(
  marketAccounts: MarketAccount[],
  defaultMarketAccounts?: DefaultMarketAccounts
): MarketAccount[] {
  const groups = groupBy(marketAccounts, acc => acc.Market);
  // Map each group to one selection. Within each group we sort by display name to get the default or "top" one
  const oneSelectionPerMarket = map(groups, group => {
    const items = group.toSorted((a, b) => (a.DisplayName ?? a.Name).localeCompare(b.DisplayName ?? b.Name));

    // Get default Market Account for given Market...
    const defaultItem =
      defaultMarketAccounts != null
        ? items.find(marketAccount => get(defaultMarketAccounts, marketAccount.Market) === marketAccount.Name)
        : undefined;

    // ...or pick first item if not default
    return defaultItem ?? items.at(0);
  });

  return compact(oneSelectionPerMarket);
}

/**
 * This function is used by OrderSlice and RFQSlice.handleOrderMarketsChange.
 *
 * Three flows:
 * 1) If the accounts field is untouched, and there is nothing selected, we treat this as "unprimed", and set defaults
 * 2) If the accounts field is touched, we will only ever _remove_ newly-inapplicable selections. We never _add_ selections.
 * 3) Edge case: if there is nothing selected, and there is only one possible selection, that will be selected.
 *
 * The logic contained within this function will grow in the near future.
 */
export function getSelectionsAfterItemUpdate({
  selections,
  items,
  shouldDefault,
  defaultToEmpty,
  defaultMarketAccounts,
}: {
  /** The currently selected accounts */
  selections: string[];
  /** The new selectable market account names */
  items: MarketSelectorItem[];
  /** If we should suggest default selections */
  shouldDefault: boolean;
  /** Some users want the order form to default to empty */
  defaultToEmpty: boolean;
  /** The default market accounts config the user has, if any */
  defaultMarketAccounts?: DefaultMarketAccounts;
}): string[] {
  const relevantMarketAccountNamesSet = new Set<string>();
  const selectableMarketAccounts: MarketAccount[] = [];

  for (const item of items) {
    if (item.availability.availability >= AvailabilityEnum.Disabled && item.marketAccount != null) {
      // All items disabled or above in terms of availability are relevant to the user. This means that we can be "allowing through" Disabled accounts at this stage.
      relevantMarketAccountNamesSet.add(item.marketAccount.Name);
    }

    if (isMarketSelectorItemSelectable(item) && item.marketAccount != null) {
      selectableMarketAccounts.push(item.marketAccount);
    }
  }
  const stillRelevantSelections = selections.filter(selection => relevantMarketAccountNamesSet.has(selection));

  let newValue: string[] = [];
  if (shouldDefault) {
    // Field is _not touched_ and items changed. Reset to default selections if there are no relevant selections remaining, otherwise just keep them.
    const defaultSelections = defaultToEmpty
      ? []
      : getOneAccountPerMarket(selectableMarketAccounts, defaultMarketAccounts).map(acc => acc.Name);

    newValue = stillRelevantSelections.length === 0 ? defaultSelections : stillRelevantSelections;
  } else {
    // If the field is touched we only ever remove ineligible selections. We do not randomly add any new selections.
    newValue = stillRelevantSelections;
  }

  // Lastly... kind of an escape hatch here: if there is only one selection possible, select it on behalf of the user.
  // This is the only place where we might hit a corner case where we are changing a user's selection. But realistically should be OK.
  if (newValue.length === 0 && selectableMarketAccounts.length === 1) {
    newValue = [selectableMarketAccounts[0].Name];
  }

  return newValue;
}

/**
 * This is a sibling function to getSelectionsAfterItemUpdate above. This one is used for multileg leg market selections.
 *
 * On update, if the leg in question has not been defaulted yet, it will return the default set of markets given the provided params.
 * For a leg without any default markets on the security, will select all possible tradable accounts. This will also leverage the
 * provided defaultMarketAccounts, if any.
 *
 * If the leg has already been defaulted, this function will just prune the current selections, as in remove any no-longer-relevant selections,
 * and return that.
 */
export function getLegSelectionsAfterItemUpdate({
  defaultLegMarkets,
  shouldDefault,
  legParam,
  items,
  defaultToEmpty,
  defaultMarketAccounts,
}: {
  /** The default leg markets. Found on the Security's MultilegDetails.Legs. An empty array signifies "All Markets" */
  defaultLegMarkets: string[];
  /** Whether or not we should perform a defaulting operation (first setting of the leg markets) */
  shouldDefault: boolean;
  /** The current state of the LegParams */
  legParam: WritableDraft<LegParams>;
  /** The new Market Selector Items to be evaluated for selection */
  items: MarketSelectorItem[];
  /** Whether or not the user wants to default to an empty selection. Is applied if `shouldDefault` is true. */
  defaultToEmpty: boolean;
  /** The set of default market accounts, if any, to use when deciding which account within a market to prioritize */
  defaultMarketAccounts?: DefaultMarketAccounts;
}) {
  const relevantMarketAccountNamesSet = new Set<string>();
  const selectableMarketAccounts: MarketAccount[] = [];

  for (const item of items) {
    if (item.availability.availability >= AvailabilityEnum.Disabled && item.marketAccount != null) {
      // All items disabled or above in terms of availability are relevant to the user. This means that we can be "allowing through" Disabled accounts at this stage.
      relevantMarketAccountNamesSet.add(item.marketAccount.Name);
    }

    if (isMarketSelectorItemSelectable(item) && item.marketAccount != null) {
      selectableMarketAccounts.push(item.marketAccount);
    }
  }

  if (shouldDefault) {
    if (defaultToEmpty) {
      return [];
    }

    if (defaultLegMarkets.length === 0) {
      // The leg's markets is set to "All Markets". This means that we default to as many markets as we can. We get one account per market,
      // also leveraging our DefaultMarketAccounts setup (if any).
      return getOneAccountPerMarket(selectableMarketAccounts, defaultMarketAccounts).map(ma => ma.Name);
    } else {
      // the Leg has some array of markets specified on it which we should default to.
      // We intersect the selectableMarketAccounts with this array to find the items to select.
      const legDefaultMarketsSet = new Set(defaultLegMarkets);
      return selectableMarketAccounts.filter(ma => legDefaultMarketsSet.has(ma.Name)).map(ma => ma.Name);
    }
  }

  // Else down here the leg markets have already been defaulted, and now we simply get an update to the selectable markets.
  // This is where we just prune the selections and remove any that arent relevant any more
  const stillRelevantSelections = legParam.Markets.filter(selection => relevantMarketAccountNamesSet.has(selection));
  return stillRelevantSelections;
}

export function mapOrderToCustomerOrder(order: Order): CustomerOrder {
  const customerOrder = {
    ...order,
    // Remember: The ParentOrderID in Order is the OrderID in CustomerOrder
    OrderID: order.ParentOrderID || '',
    PricingParameters: order.customerPricingParams,
    Counterparty: order.customerOrder || '',
    MarketAccount: order.customerAccount || '',
    OrderQty: order.customerQuantity || '',
    Price: order.customerPrice || '',
    AvgPx: order.customerAvgPx || '',
    CustomerUser: '',
    AmountCurrency: order.AmountCurrency || '',
    CumAmt: order.CumAmt || '',
    ExecID: order.ExecID || '',
    LeavesQty: order.LeavesQty || '',
    Strategy: order.customerStrategy || order.Strategy,
    // Sales Order ETH-BTC translates to ETH-BTC:USD-2 in Principal land but remains as ETH-BTC in dealer
    Symbol: order.customerSymbol || order.Symbol,
    Parameters: order.customerParameters ?? undefined,
  };

  return customerOrder;
}

export function getNormalizedOrderQty(
  security: Security | undefined,
  orderCurrency: string | undefined,
  size?: string
): string {
  if (!orderCurrency) {
    // number of Contracts
    // For some security the NormalSize is less than MinimumSize; in these case use MinimumSize
    const initialSize = bigMax(security?.MinimumSize, security?.NormalSize, '1');
    return size || initialSize?.toFixed() || '';
  } else {
    return toBigWithDefault(security?.NotionalMultiplier, 1)
      .times(size || security?.NormalSize || 1)
      .toFixed();
  }
}

export const getOrdType = (showAllInPrices, security, strategy): OrdTypeEnum => {
  if (strategy?.Name === 'Market') {
    return OrdTypeEnum.Market;
  }
  // OrdTypes are sent in StrategyParams as a TopLevelHidden just so we read the value and send it back
  const beOrdTypeParamHack: ParameterLike | undefined = strategy?.Parameters.find(p => p.Name === 'OrdType');
  if (beOrdTypeParamHack) {
    return beOrdTypeParamHack.DefaultValue as OrdTypeEnum;
  }
  return isOrderTypeLimitAllIn(showAllInPrices, security) ? OrdTypeEnum.LimitAllIn : OrdTypeEnum.Limit;
};

/* If Customer Configuration Filtering is enabled, we should only take the enabled customer configurations,
   then filter out any disabled customer pricing rules. Return the filtered customers list. */
export const getSelectableCustomers = (
  enabledCustomers: Customer[],
  customerConfigurations: CustomerConfiguration[]
) => {
  if (enabledCustomers.length === 0) {
    // Customers weren't loaded yet
    return [];
  }

  if (customerConfigurations.length === 0) {
    // Customer configurations weren't loaded yet, or are not being used.
    return enabledCustomers;
  }

  const enabledCustomersMapByName = new Map(enabledCustomers.map(c => [c.Name, c]));
  // Return only the customers that have enabled configurations
  return compact(
    customerConfigurations.map(cc => {
      // If the customer configuration is disabled, return null
      if (cc.Mode === ModeEnum.Disabled) {
        return null;
      }
      // Find the not disabled customer that matches the configuration
      const foundCustomerForConfiguration = enabledCustomersMapByName.get(cc.Counterparty);
      if (foundCustomerForConfiguration == null) {
        return null;
      }

      // Return the found customer
      return foundCustomerForConfiguration;
    })
  );
};

/** This function takes in an array of allocation "fields", and outputs standard allocations from these fields */
export function subAccountFieldsToAllocations(
  allocations: { subAccountField: Field; valueField: NumericField }[]
): Allocation[] {
  return allocations.length
    ? allocations.map(x => ({
        subAccount: x.subAccountField.value || '',
        value: x.valueField.displayValue || '',
      }))
    : EMPTY_ARRAY;
}

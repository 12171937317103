import type { ValueGetterParams } from 'ag-grid-community';
import type { ValueFormatterParams } from 'ag-grid-enterprise';
import { differenceInCalendarDays } from 'date-fns';
import { get } from 'lodash-es';
import type React from 'react';
import { HStack } from '../../Core';
import { Icon, IconName } from '../../Icons';
import { Text } from '../../Text';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export const DEFAULT_CREDENTIAL_AGE_WARNING_DAYS = 180;
export const DEFAULT_CREDENTIAL_AGE_ALERT_DAYS = 365;

export const credentialAge: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  headerName: 'Credential Age',
  sortable: true,
  valueGetter: (params: ValueGetterParams): number | null => {
    if (!column.field) {
      return null;
    }
    const updatedAt = get(params.data, column.field);
    if (!hasValidCredentialAge(updatedAt)) {
      return null;
    }
    return differenceInCalendarDays(new Date(), new Date(updatedAt));
  },
  valueFormatter: ({ value }: ValueFormatterParams<unknown, number | null>) => {
    return value?.toString() ?? '';
  },
  cellRenderer: ({ value }: { value: number | null }) => {
    if (value === null) {
      return null;
    }
    const { ageWarningDays, ageAlertDays } = column.params;
    const result = getCredentialAgeWarningPrefixGivenDays(value, ageWarningDays, ageAlertDays);
    return (
      <HStack gap="spacingSmall">
        {result.prefix}
        <Text color={result.color}>
          {value} day{value !== 1 ? 's' : ''}
        </Text>
      </HStack>
    );
  },
});

export function getCredentialAgeWarningPrefixGivenDays(
  ageInDays: number,
  ageWarningDays: number,
  ageAlertDays: number
) {
  ageWarningDays = ageWarningDays || DEFAULT_CREDENTIAL_AGE_WARNING_DAYS;
  ageAlertDays = ageAlertDays || DEFAULT_CREDENTIAL_AGE_ALERT_DAYS;
  let color = '';
  let prefix: React.ReactNode = null;
  if (ageInDays > ageAlertDays) {
    color = 'colors.red.lighten';
    prefix = <Icon icon={IconName.ExclamationSolid} color="colors.red.lighten" data-testid="icon-alert" />;
  } else if (ageInDays > ageWarningDays) {
    color = 'colors.yellow.lighten';
    prefix = <Icon icon={IconName.ExclamationSolid} color="colors.yellow.lighten" data-testid="icon-warning" />;
  }
  return {
    prefix,
    color,
  };
}

/**
 * Checks if the credential age is valid.
 * The backend sometimes sends "0001-01-01T00:00:00Z" for the SecretsUpdatedAt field, which is considered invalid.
 * @param {string | undefined} value - The date string to validate.
 * @returns {boolean} - True if the date is valid, false otherwise.
 */
export function hasValidCredentialAge(value: string | undefined): boolean {
  return Boolean(value && !value.startsWith('0'));
}

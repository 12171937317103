import { MixpanelEvent, type PriceInputMode, SearchSelect, Text, useMixpanel, VStack } from '@talos/kyoko';
import { identity } from 'lodash-es';
import { useCallback } from 'react';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';

const availableOptions: PriceInputMode[] = ['Increment', 'Reset'];

export function PriceOffsetBPSBehaviour() {
  const { priceOffsetBPSBehaviour, setPriceOffsetBPSBehaviour } = useTradingSettings();
  const mixpanel = useMixpanel();

  const updatePriceOffsetBPSBehaviour = useCallback(
    (option: PriceInputMode | undefined) => {
      const selection = option || availableOptions[0];
      setPriceOffsetBPSBehaviour(selection);
      mixpanel.track(
        selection === 'Increment'
          ? MixpanelEvent.PriceOffsetBPSBehaviourIncrement
          : MixpanelEvent.PriceOffsetBPSBehaviourReset
      );
    },
    [setPriceOffsetBPSBehaviour, mixpanel]
  );

  return (
    <VStack gap="spacingDefault" alignItems="flex-start">
      <Text color="colorTextImportant">Price Offset BPS Behaviour</Text>
      <VStack gap="spacingDefault" ml="spacingLarge" alignItems="flex-start">
        <span>Set the behaviour of the BPS Price Offsets in the New Order and Sales Order forms.</span>
        <div>
          <strong>Increment</strong>: When the Limit Px is set, the BPS Price Offsets will move the Limit Px by the
          specified BPS value each time it is clicked.
          <br />
          <strong>Reset</strong>: The BPS Price Offsets will reset the Limit Px to Bid/Offer +/- the offset.
        </div>
        <SearchSelect<PriceInputMode>
          data-testid="price-offset-behaviour-select"
          selection={priceOffsetBPSBehaviour || availableOptions[0]}
          options={availableOptions}
          onChange={updatePriceOffsetBPSBehaviour}
          getLabel={identity}
          initialSortByLabel={false}
          style={{ width: '200px' }}
        />
      </VStack>
    </VStack>
  );
}

import { useEffect } from 'react';
import { useDynamicCallback } from '../hooks/useDynamicCallback';

interface TargetEvent {
  key: KeyboardEvent['key'];
  metaKey?: KeyboardEvent['metaKey'];
  ctrlKey?: KeyboardEvent['ctrlKey'];
  shiftKey?: KeyboardEvent['shiftKey'];
  altKey?: KeyboardEvent['altKey'];
}

export const useOnKeyDown = (targetEvent: TargetEvent, onKeyDown?: (e: KeyboardEvent) => void) => {
  const handler = useDynamicCallback((e: KeyboardEvent) => {
    for (const property in targetEvent) {
      // @ts-expect-error - Valid check for optional properties
      if (e[property] !== targetEvent[property]) {
        return;
      }
    }
    onKeyDown?.(e);
    e.preventDefault();
  });

  useEffect(() => {
    if (onKeyDown) {
      window.addEventListener('keydown', handler);
      return () => {
        window.removeEventListener('keydown', handler);
      };
    }
  }, [handler, onKeyDown]);
};

import type { Placement } from '@popperjs/core';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type CSSProperties,
  type Dispatch,
  type RefObject,
  type SetStateAction,
} from 'react';
import { usePopper } from 'react-popper';
import { useTheme } from 'styled-components';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

export interface UseDropdownPopperProps {
  isOpen: boolean;
  dropdownWidth?: CSSProperties['width'];
  dropdownHeight?: CSSProperties['height'];
  dropdownPlacement?: Placement;
  /** The referenceElement is the element which the dropdown is anchored to */
  referenceElement: HTMLElement | null;
  onClickOutside?: (e: MouseEvent) => void;
  offset?: number;
  padding?: number;
  /** The dropdownContentRef will be attached to the container of the content within the dropdown */
  dropdownContentRef?: RefObject<HTMLDivElement>;
}

export interface UseDropdownPopperOutput {
  setPopperElement: Dispatch<SetStateAction<HTMLElement | null>>;
  popper: ReturnType<typeof usePopper>;
  dropdownWidth?: CSSProperties['width'];
  dropdownHeight?: CSSProperties['height'];
  referenceElement: HTMLElement | null;
  isOpen: boolean;
  dropdownContentRef: RefObject<HTMLDivElement>;
}

export const useDropdownPopper = ({
  isOpen,
  dropdownPlacement,
  dropdownWidth,
  dropdownHeight,
  referenceElement,
  onClickOutside,
  offset = 0,
  padding = 0,
  dropdownContentRef,
}: UseDropdownPopperProps): UseDropdownPopperOutput => {
  const { spacingSmall } = useTheme();
  const _offset = offset || spacingSmall;
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  // if we are applying a manual width, we want the dropdown to be just bottom. This will split the additional width evenly across left and right sides.
  // if we are going for auto-width, then set it to bottom-start to align dropdown with input field exactly.
  const popperPlacement = dropdownPlacement ? dropdownPlacement : dropdownWidth ? 'bottom' : 'bottom-start';
  const popper = usePopper(referenceElement, popperElement, {
    placement: popperPlacement,

    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, _offset],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding,
        },
      },
    ],
  });
  const { update } = popper;

  const defaultDropdownContentRef = useRef<HTMLDivElement>(null);
  const dropdownContentRefToUse = dropdownContentRef ?? defaultDropdownContentRef;

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      onClickOutside && onClickOutside(e);
    },
    [onClickOutside]
  );

  useOnClickOutside(dropdownContentRefToUse, isOpen ? handleClickOutside : undefined);

  // We need to manually listen to resizes in the referenceElement
  // and when that resizes, we update the popper to follow along.
  useEffect(() => {
    if (referenceElement && update) {
      const observer = new ResizeObserver(() => {
        window.requestAnimationFrame(() => {
          update();
        });
      });
      observer.observe(referenceElement);
      return () => observer.disconnect();
    }
  }, [referenceElement, update]);

  // Similarly to above, we also listen to size changed in the content of our dropdown.
  // When the height changes, we might have to update our position. Usually this is just done on init+1 after
  // the component realizes what height its content takes up.
  useEffect(() => {
    if (dropdownContentRefToUse.current && update) {
      const observer = new ResizeObserver(() => {
        window.requestAnimationFrame(() => {
          update();
        });
      });
      observer.observe(dropdownContentRefToUse.current);
      return () => observer.disconnect();
    }
  }, [dropdownContentRefToUse, update]);

  // We also need to call update every time the referenceElement changes
  // https://github.com/floating-ui/floating-ui/issues/538#issuecomment-596601887
  useEffect(() => {
    update && update();
  }, [referenceElement, update]);

  // Whenever we open the dropdown, lets update the position as well to make sure its opened in the right place
  useEffect(() => {
    if (isOpen && update) {
      update();
    }
  }, [isOpen, update]);

  return {
    setPopperElement,
    popper,
    dropdownWidth,
    dropdownHeight,
    referenceElement,
    isOpen,
    dropdownContentRef: dropdownContentRefToUse,
  };
};

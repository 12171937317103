import {
  Box,
  ButtonVariants,
  Divider,
  FormControlSizes,
  HStack,
  Icon,
  IconButton,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  MixpanelSourceContext,
  Popover,
  TalosLogo,
  Text,
  Tooltip,
  Tour,
  VStack,
  useAuthContext,
  useDisclosure,
  useMixpanel,
  usePopoverState,
  usePowerSearchContext,
  type BoxProps,
} from '@talos/kyoko';
import {
  Sidebar,
  SidebarHeader,
  SidebarItem,
  SidebarPinToggle,
  SidebarSection,
  SidebarTitle,
  isSidebarSubTitle,
  useSidebarContext,
  type SidebarSubNavItem,
  type SidebarSubTitle,
} from '@talos/kyoko/src/routed';
import { GlobalStatusIndicator } from 'components/StatusIndicator';
import { GlobalMarketStatusTable } from 'components/StatusTable';
import { useAdminSubNavItems } from 'containers/Admin/Users/hooks/useAdminSubNavItems';
import { useDealerSubNavItems } from 'containers/Dealer/NavItems';
import { usePortfolioSubNavItems } from 'containers/Portfolio/PortfolioRouter';
import { useSettingsSubNavItems } from 'containers/Settings/NavItems';
import { useFeatureFlag, useRoleAuth, useUser } from 'hooks';
import { useDisplaySettings } from 'providers/AppConfigProvider';
import { useIntercom } from 'providers/IntercomContext';
import { useCallback, useEffect, useMemo, useState, type ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { NAVIGATION_SIDEBAR_WIDTH } from 'styles/dimensions';
import { useMonitoringSubNavItems } from '../../containers/Monitoring/getMonitoringSubNavItems';
import { PORTFOLIO_ENGINEERING_SUB_NAV_ITEMS } from '../../containers/PortfolioEngineering/NavItems';
import { SupportTicketDialog } from '../SupportTicket/SupportTicketDialog';
import { ALL_NAV_PATHS, BOTTOM_NAV_PATHS, useTopNavPaths } from './Nav';
import { Environment, SiteName, StatusSection, Version } from './styles';
import { appVersion } from './version';

export const NavigationSidebar = (props: BoxProps) => {
  const { pinSidebar } = useDisplaySettings();

  // On initial render, we should check the route and provide the initial state to the Sidebar context
  const location = useLocation();
  const [initialPinned] = useState<boolean>(() => {
    const mainPath = getMainPath(location.pathname);

    // We might not find a pathData object here, this is then "undefined", and we just go by whatever
    // "pinSidebar" is set to
    const pathData = ALL_NAV_PATHS.find(path => path.path === mainPath);

    // If either of these are true, the sidebar should start pinned
    return pathData?.hasSubNavItems || pinSidebar;
  });

  return (
    <MixpanelSourceContext.Provider value={MixpanelEventSource.NavigationSidebar}>
      <Sidebar expandedWidth={NAVIGATION_SIDEBAR_WIDTH} initialPinned={initialPinned} {...props}>
        <NavigationBody />
      </Sidebar>
    </MixpanelSourceContext.Provider>
  );
};

const currVersion = import.meta.env.VITE_NPM_PACKAGE_VERSION?.split('.').slice(0, 2).join('.'); // eg "2.34"

const TALOS_KNOWLEDGE_BASE_URL = 'https://kb.talostrading.com/';

const NavigationBody = () => {
  const { unreadCount } = useIntercom();
  const theme = useTheme();
  const user = useUser();
  const location = useLocation();
  const { showSupportChat, enableSupportModal } = useFeatureFlag();
  const {
    modal: { open: openPowerSearch },
  } = usePowerSearchContext();
  const mixpanel = useMixpanel();
  const auth = useAuthContext();

  const { pinSidebar, setPinSidebar, latestReleaseNotesRead, setLatestReleaseNotesRead } = useDisplaySettings();
  const statusIndicatorPopover = usePopoverState({ trigger: 'hover', placement: 'right', usePortal: true });
  const helpPopover = usePopoverState({
    trigger: 'hover',
    placement: 'right-start',
    usePortal: true,
    noPaddingAndBorder: true,
  });
  const { isExpanded, setPinned, showSubNavItems, setShowSubNavItems } = useSidebarContext();
  const [subNavItems, setSubNavItems] = useState<(SidebarSubNavItem | SidebarSubTitle)[] | undefined>(undefined);

  const adminSubNavItems = useAdminSubNavItems();
  const portfolioSubNavItems = usePortfolioSubNavItems();
  const dealerSubNavItems = useDealerSubNavItems();
  const settingsSubNavItems = useSettingsSubNavItems();
  const monitoringSubNavItems = useMonitoringSubNavItems();

  const supportTicketDialog = useDisclosure();

  const sidebarSubNavItems = useMemo(() => {
    return {
      portfolio: portfolioSubNavItems,
      'portfolio-engineering': PORTFOLIO_ENGINEERING_SUB_NAV_ITEMS,
      dealer: dealerSubNavItems,
      monitoring: monitoringSubNavItems,
      settings: settingsSubNavItems,
      admin: adminSubNavItems,
    };
  }, [portfolioSubNavItems, dealerSubNavItems, monitoringSubNavItems, settingsSubNavItems, adminSubNavItems]);

  const handleOnPinChange = useCallback(
    (pinned: boolean) => {
      mixpanel.track(MixpanelEvent.ChangeSidebarPin, { [MixpanelEventProperty.Pinned]: pinned });
      // Store selection in display settings
      setPinSidebar(pinned);
    },
    [mixpanel, setPinSidebar]
  );

  const handleOpenPowerSearch = useCallback(() => {
    mixpanel.track(MixpanelEvent.OpenPowerSearch);
    openPowerSearch();
  }, [mixpanel, openPowerSearch]);

  useEffect(() => {
    // Whenever the browser location changes, we apply the changes to the sidebar state as well to reflect this
    const newMainPath = getMainPath(location.pathname);
    const pathData = ALL_NAV_PATHS.find(path => path.path === newMainPath);
    if (!pathData) {
      return;
    } // do nothing

    const newTabConfig: (SidebarSubNavItem | SidebarSubTitle)[] | undefined =
      sidebarSubNavItems[newMainPath as keyof typeof sidebarSubNavItems];
    setSubNavItems(newTabConfig);

    // When a route change happens, we always pin the menu on navigating to a route with subnavitems
    // else if we're navigating to a path without subnavitems, we either pin or don't pin depending on user preference
    if (pathData.hasSubNavItems) {
      setPinned(true);
    } else {
      setPinned(pinSidebar);
    }
  }, [location, pinSidebar, setPinned, setShowSubNavItems, sidebarSubNavItems]);

  const showWhatsNewDot = useMemo(() => {
    return latestReleaseNotesRead !== currVersion;
  }, [latestReleaseNotesRead]);

  const handleWhatsNewClick = useCallback(() => {
    mixpanel.track(MixpanelEvent.NavigateToWhatsNew);
    setLatestReleaseNotesRead(currVersion);
  }, [setLatestReleaseNotesRead, mixpanel]);

  const handleSupportTicketClick = useCallback(() => {
    supportTicketDialog.open();
  }, [supportTicketDialog]);

  const StatusIndicator: ReactNode = (
    <Box ml="spacingTiny">
      <GlobalStatusIndicator showText={false} />
    </Box>
  );

  const topNavPaths = useTopNavPaths();
  const isProd = import.meta.env.VITE_AVA_ENV === 'prod';

  return (
    <>
      <SidebarSection>
        <SidebarHeader>
          <TalosLogo collapsed={!isExpanded} width={isExpanded ? 100 : 20} />
          {isExpanded && (
            <SiteName>
              <Environment>
                {!isProd ? import.meta.env.VITE_AVA_ENV : ''}
                <Version title={import.meta.env.VITE_GIT_HASH}>
                  {appVersion(import.meta.env.VITE_NPM_PACKAGE_VERSION, isProd)}
                </Version>
              </Environment>
            </SiteName>
          )}
        </SidebarHeader>
        {isExpanded && subNavItems && showSubNavItems ? (
          <>
            <HStack gap="spacingDefault" justifyContent="flex-start" h={`${theme.baseSize * 2}px`}>
              <IconButton
                variant={ButtonVariants.Default}
                icon={IconName.ArrowLeft}
                size={FormControlSizes.Small}
                onClick={() => setShowSubNavItems(false)}
              />
              <Text size="fontSizeSmall" transform="uppercase">
                {getMainPathTitle(location.pathname)}
              </Text>
            </HStack>{' '}
            {subNavItems?.map(tab =>
              isSidebarSubTitle(tab) ? (
                <SidebarTitle key={tab.label} icon={tab.icon}>
                  {tab.label}
                </SidebarTitle>
              ) : (
                <SidebarItem
                  id={tab.id}
                  key={tab.path}
                  to={tab.path}
                  icon={tab.icon}
                  mixpanelEvent={tab.mixpanelEvent}
                  menuIndent={tab.menuIndent}
                >
                  {tab.label}
                  {tab.tour && <Tour tour={tab.tour} />}
                </SidebarItem>
              )
            )}
          </>
        ) : (
          <NavPaths navPaths={topNavPaths} />
        )}
      </SidebarSection>
      <SidebarSection mt="auto">
        <Popover {...statusIndicatorPopover}>
          <SidebarItem icon={StatusIndicator}>
            <StatusSection>
              <GlobalStatusIndicator showIcon={false} />
            </StatusSection>
          </SidebarItem>
          <GlobalMarketStatusTable />
        </Popover>
      </SidebarSection>
      <Divider />
      <SidebarSection>
        <SidebarItem icon={IconName.Search} onClick={handleOpenPowerSearch}>
          Search
        </SidebarItem>
        <Popover {...helpPopover}>
          <SidebarItem showDot={unreadCount > 0} icon={IconName.Support}>
            <HStack h="100%" w="100%" justifyContent="space-between">
              Help
              <Icon icon={IconName.ChevronRight} size={theme.baseSize} />
            </HStack>
          </SidebarItem>
          <VStack background={theme.backgroundSidebar}>
            {enableSupportModal && (
              <SidebarItem
                onClick={handleSupportTicketClick}
                icon={IconName.Chat}
                id="talos-support-ticket"
                style={{
                  paddingRight: theme.spacingHuge,
                }}
              >
                Support Ticket
              </SidebarItem>
            )}
            {showSupportChat && !enableSupportModal && (
              <SidebarItem
                onClick={() => {
                  mixpanel.track(MixpanelEvent.Support);
                }}
                icon={IconName.Chat}
                id="talos-intercom"
                showDot={unreadCount > 0}
                style={{
                  paddingRight: theme.spacingHuge,
                }}
              >
                Support
              </SidebarItem>
            )}
            <a
              href={TALOS_KNOWLEDGE_BASE_URL}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                mixpanel.track(MixpanelEvent.KnowledgeBase);
              }}
              style={{ borderBottom: '0px' }}
            >
              <SidebarItem
                style={{
                  paddingRight: theme.spacingHuge,
                }}
                icon={IconName.BookOpen}
              >
                Knowledge Base
              </SidebarItem>
            </a>
          </VStack>
        </Popover>
        <a
          href={`https://${import.meta.env.VITE_RELEASE_NOTES_URL}`}
          target="_blank"
          rel="noreferrer"
          onClick={handleWhatsNewClick}
        >
          <SidebarItem icon={IconName.Newspaper} showDot={showWhatsNewDot}>
            What&apos;s New
          </SidebarItem>
        </a>
        <NavPaths navPaths={BOTTOM_NAV_PATHS} />
        <SidebarItem
          onClick={() => {
            mixpanel.track(MixpanelEvent.LogOut);
            auth.logout();
          }}
          icon={IconName.Logout}
        >
          Log Out
        </SidebarItem>
      </SidebarSection>
      <Divider />
      <SidebarSection>
        <SidebarPinToggle onChange={handleOnPinChange} />
      </SidebarSection>
      <Divider />
      <SidebarSection>
        <Tooltip tooltip={user.Email}>
          <SidebarItem icon={IconName.UserCircle}>{user.Name}</SidebarItem>
        </Tooltip>
      </SidebarSection>
      <SupportTicketDialog dialog={supportTicketDialog} />
    </>
  );
};

function getMainPath(pathname: string): string {
  return pathname.split('/')[1];
}

function getMainPathTitle(pathname: string): string {
  const mainPath = getMainPath(pathname);
  return ALL_NAV_PATHS.find(path => path.path === mainPath)?.label ?? mainPath.replace('-', ' ');
}

function NavPaths({ navPaths }: { navPaths: SidebarSubNavItem[] }) {
  const { isAuthorized } = useRoleAuth();
  const { setShowSubNavItems } = useSidebarContext();

  return (
    <>
      {navPaths.map(({ path, icon, hasSubNavItems, requiredPermission, label, mixpanelEvent }) => {
        return (
          (requiredPermission ? isAuthorized(requiredPermission) : true) && (
            <SidebarItem
              key={path}
              to={'/' + path}
              icon={icon}
              hasSubNavItems={hasSubNavItems}
              mixpanelEvent={mixpanelEvent}
              onClick={() => setShowSubNavItems(hasSubNavItems ?? false)}
            >
              {label}
            </SidebarItem>
          )
        );
      })}
    </>
  );
}

import type { Column } from '@talos/kyoko';
import { compact } from 'lodash-es';
import { useMemo } from 'react';

export const useTradeAllocationsColumns: (isUnifiedLiquidity: boolean) => Column[] = isUnifiedLiquidity => {
  return useMemo(
    () =>
      compact([
        { field: 'TransactTime', type: 'date' },
        { field: 'SubAccount', type: 'subAccount' },
        isUnifiedLiquidity ? { field: 'Symbol', type: 'security' } : null,
        { field: 'Quantity', type: 'size', params: { currencyField: 'Currency' } },
        { field: 'Price', type: 'price', params: { quoteCurrencyField: 'QuoteCurrency' } },
        { field: 'Fee', type: 'size', params: { currencyField: 'FeeCurrency' } },
        { field: 'PriceAllIn', type: 'price', title: 'Filled Price All-In', params: { assetField: 'Symbol' } },
        { field: 'Amount', type: 'size', params: { currencyField: 'AmountCurrency' } },
        { field: 'TradeID', type: 'id' },
        { field: 'AllocationID', type: 'id' },
        { field: 'MarketAccount', type: 'marketAccount' },
        { field: 'TradeStatus', title: 'Status', type: 'tradeStatus' },
        { field: 'MarketOrderID', type: 'id' },
        { field: 'MarketExecID', type: 'id' },
        { field: 'AggressorSide', type: 'side', hide: true },
        { field: 'Comments', type: 'text', hide: true },
        { field: 'Group', type: 'group', hide: true },
        { field: 'Market', type: 'market', hide: true },
        { field: 'MarketTradeID', type: 'id', hide: true },
        { field: 'OrderID', type: 'id', hide: true },
        { field: 'ParentTradeID', type: 'id', hide: true },
        { field: 'QuoteID', type: 'id', hide: true },
        { field: 'RFQID', type: 'id', hide: true },
        { field: 'Side', type: 'side', hide: true },
        { field: 'Symbol', type: 'security', hide: true },
        { field: 'TalosFee', type: 'size', params: { currencyField: 'TalosFeeCurrency' }, hide: true },
        { field: 'Timestamp', type: 'date', hide: true },
        { field: 'TradeSource', type: 'text', hide: true },
        { field: 'User', type: 'user', hide: true },
      ]),
    [isUnifiedLiquidity]
  );
};

import { ACTION, IconName, Text, format, isKeyIn, type Column, type ColumnDef } from '@talos/kyoko';
import type { ICellRendererParams, IRowNode, ValueGetterParams } from 'ag-grid-community';
import { compact } from 'lodash-es';
import { useMemo } from 'react';
import type { MarketSecurityStatusLocal } from './types';

const createCellRenderer =
  (field: keyof MarketSecurityStatusLocal, useFormatFunction = true) =>
  ({ data, value }: ICellRendererParams<MarketSecurityStatusLocal>) => {
    const key = `Requested${field}`;
    const isNotDefault = data && isKeyIn(key, data) && !!data[key];
    return (
      <Text
        color={isNotDefault ? 'colorTextDefault' : 'colorTextMuted'}
        fontWeight={isNotDefault ? 'bolder' : 'normal'}
      >
        {useFormatFunction ? format(value) : value}
      </Text>
    );
  };

export function useSecurityMasterColumns(
  isAuthorized: (action: ACTION) => boolean,
  handleEdit: (rows: MarketSecurityStatusLocal[]) => void
) {
  return useMemo<Column[]>(
    () =>
      compact([
        {
          field: 'ID',
          type: 'text',
          hide: true,
        },
        {
          field: 'Symbol',
          type: 'text',
          width: 150,
          checkboxSelection: true,
        },
        {
          title: 'Market Account',
          field: 'MarketAccount',
          type: 'marketAccount',
          width: 150,
        },
        {
          title: 'Market',
          field: 'Market',
          type: 'market',
          width: 120,
        },
        {
          title: 'Available',
          field: '_marketEnabled',
          type: 'connectionStatus',
          description: 'Indicates if the Market is Enabled.',
          width: 80,
          hide: true,
          suppressColumnsToolPanel: false,
        },
        {
          title: 'Enabled',
          field: 'Enabled',
          type: 'connectionStatus',
          description: 'Indicates if the symbol is enabled on the market account.',
          width: 80,
          suppressColumnsToolPanel: false,
        },
        {
          title: 'Market Data Enabled',
          field: 'Capabilities.MarketData',
          type: 'connectionStatus',
          description: 'Indicates if market data is enabled for the symbol on the market account.',
          width: 80,
          suppressColumnsToolPanel: false,
        },
        {
          title: 'Status',
          field: 'Status',
          type: 'connectionStatus',
          description: 'Indicates if the gateway is up and running for the symbol on the market account.',
          width: 80,
          suppressColumnsToolPanel: false,
        },
        {
          title: 'Buckets',
          field: 'SizeBuckets',
          type: 'custom',
          width: 250,
          params: {
            valueGetter: ({ data }: ValueGetterParams<MarketSecurityStatusLocal>) => {
              return data?.SizeBuckets?.map(sb => sb.Size).join(', ') || '';
            },
            cellRenderer: createCellRenderer('SizeBuckets', false),
          },
        },
        {
          field: 'MinPriceIncrement',
          type: 'custom',
          width: 180,
          params: {
            cellRenderer: createCellRenderer('MinPriceIncrement'),
          },
        },
        {
          field: 'MinSizeIncrement',
          type: 'custom',
          width: 180,
          params: {
            cellRenderer: createCellRenderer('MinSizeIncrement'),
          },
        },
        {
          field: 'MinimumSize',
          type: 'custom',
          width: 150,
          params: {
            cellRenderer: createCellRenderer('MinimumSize'),
          },
        },
        {
          field: 'MaximumSize',
          type: 'custom',
          width: 150,
          params: {
            cellRenderer: createCellRenderer('MaximumSize'),
          },
        },
        {
          field: 'MinimumAmount',
          type: 'custom',
          width: 150,
          params: {
            cellRenderer: createCellRenderer('MinimumAmount'),
          },
        },
        {
          title: 'Capabilities',
          field: '_capabilities',
          type: 'custom',
          width: 250,
          params: {
            cellRenderer: createCellRenderer('Capabilities', false),
          },
        },
        isAuthorized(ACTION.EDIT_SECMASTER) && {
          type: 'iconButton',
          id: 'edit-button',
          pinned: 'right',
          width: 40,
          params: {
            onClick: ({ node }: { node: IRowNode<MarketSecurityStatusLocal> }) => {
              if (!node.data) {
                return;
              }
              handleEdit([node.data]);
            },
            icon: IconName.Pencil,
          },
        },
      ] satisfies (ColumnDef<MarketSecurityStatusLocal> | false)[]),
    [handleEdit, isAuthorized]
  );
}

import { useState, type PropsWithChildren } from 'react';
import { map } from 'rxjs/operators';
import { CurrenciesContext } from '../contexts';
import { useObservable, useObservableValue, useStaticSubscription } from '../hooks';
import { wsScanToMap } from '../pipes/wsScanToMap';
import type { Currency } from '../types/Currency';

const CURRENCY = 'Currency';

export const CurrenciesProvider = function CurrenciesProvider({ children }: PropsWithChildren) {
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: subscription } = useStaticSubscription<Currency>({ name: CURRENCY, tag: 'CurrenciesProvider' });

  // Please discuss with wider team if the below newMapEachUpdate: true needs changing to false
  const currenciesBySymbolObs = useObservable(
    () => subscription.pipe(wsScanToMap({ getUniqueKey: d => d.Symbol, newMapEachUpdate: true })),
    [subscription]
  );

  const currenciesBySymbol = useObservableValue(() => currenciesBySymbolObs, [currenciesBySymbolObs], new Map());

  const currenciesList = useObservableValue(
    () =>
      currenciesBySymbolObs.pipe(
        map(currenciesBySymbol => {
          setIsLoaded(true);
          return [...currenciesBySymbol.values()];
        })
      ),
    [currenciesBySymbolObs],
    []
  );

  return (
    <CurrenciesContext.Provider value={{ isLoaded, currenciesBySymbol, currenciesList }}>
      {children}
    </CurrenciesContext.Provider>
  );
};

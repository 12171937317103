import {
  ACTION,
  AlertBanner,
  AlertVariants,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  BlotterDensity,
  BlotterTable,
  BlotterTableFilters,
  Box,
  Button,
  ButtonVariants,
  ConnectionStatusEnum,
  createCSVFileName,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  DEFAULT_CREDENTIAL_AGE_ALERT_DAYS,
  Divider,
  Flex,
  FormControlSizes,
  hasValidCredentialAge,
  IconName,
  type Market,
  type MarketConfig,
  type MarketCredential,
  MixpanelEvent,
  OnboardingGuide,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  setAlpha,
  type SubscriptionResponse,
  useAccordionFilterBuilder,
  useBlotterTable,
  useConnectionStatusContext,
  useDrawer,
  useDynamicCallback,
  useMarketsContext,
  useMixpanel,
  useObservable,
  usePersistedBlotterTable,
  usePortal,
  useSyncedRef,
  useUserContext,
  withAccordionGroup,
} from '@talos/kyoko';
import { differenceInCalendarDays } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { combineLatest, of } from 'rxjs';
import { useTheme } from 'styled-components';
import { useRoleAuth } from '../../../hooks';
import { OrgConfigurationKey, useFees, useOrgConfiguration } from '../../../providers';
import { TradingControlsDetailsDrawerWrapper } from '../TradingControls/TradingControlsDetailsDrawerWrapper';
import { useTradingControlsDetailsDrawerWrapper } from '../TradingControls/useTradingControlsDetailsDrawerWrapper';
import { AddCredentialDrawer } from './AddCredentialDrawer';
import { useCredentialsBlotterTableMenu } from './CredentialsBlotterTableMenu';
import { EditCredentialDrawer } from './EditCredentialDrawer';
import type { HydratedMarketCredential } from './types';
import { useColumns } from './useColumns';
import { useCredentialsFilter } from './useCredentialsFilter';

export const CredentialsBlotter = withAccordionGroup(function Credentials() {
  const theme = useTheme();
  const mixpanel = useMixpanel();
  const { listMarketCredentials, listMarketConfigs, orgMetadata } = useUserContext();
  const { listMarketFees } = useFees();
  const [marketCredentials, setMarketCredentials] = useState<MarketCredential[]>([]);
  const [marketConfigs, setMarketConfigs] = useState<MarketConfig[]>([]);
  const marketConfigsByMarketName = useMemo(() => new Map(marketConfigs.map(mc => [mc.name, mc])), [marketConfigs]);
  const [selectedCredential, setSelectedCredential] = useState<MarketCredential | undefined>();
  const [selectedMarket, setSelectedMarket] = useState<Market | undefined>(undefined);
  const [mktAccsWithFees, setMktAccsWithFees] = useState<Set<string>>();
  const [externalIP, setExternalIP] = useState('');
  const { updateMarketCredentials, connectionStatusByCredentialIDAndMarketName } = useConnectionStatusContext();
  const { marketsByName } = useMarketsContext();
  const { isAuthorized } = useRoleAuth();
  const { getConfig } = useOrgConfiguration();

  const canEditCredential = isAuthorized(ACTION.EDIT_CREDENTIALS);
  const ageAlertDays = Number(getConfig(OrgConfigurationKey.CredentialAgeAlertDays, DEFAULT_CREDENTIAL_AGE_ALERT_DAYS));

  const marketsByNameRef = useSyncedRef(marketsByName);

  useEffect(() => {
    orgMetadata()?.then(metadata => setExternalIP(metadata['ExternalIP']));
  }, [orgMetadata]);

  useEffectOnce(() => {
    listMarketFees().then(fees => setMktAccsWithFees(new Set(fees.map(fee => fee.MarketAccount))));
    refreshData();
  });

  const [credentialAgeAlertBannerVisible, setCredentialAgeAlertBannerVisible] = useState(true);

  const showCredentialAgeAlertBanner = useMemo(() => {
    const now = new Date();
    return marketCredentials.some(
      credential =>
        credential.SecretsUpdatedAt &&
        hasValidCredentialAge(credential.SecretsUpdatedAt) &&
        differenceInCalendarDays(now, new Date(credential.SecretsUpdatedAt)) > ageAlertDays
    );
  }, [marketCredentials, ageAlertDays]);

  const refreshData = useDynamicCallback(() => {
    listMarketConfigs().then(configs => setMarketConfigs(configs));
    listMarketCredentials().then(credentials => {
      setMarketCredentials(credentials);
    });
    updateMarketCredentials();
  });

  const filteredMarketCredentials = useMemo(() => {
    return marketCredentials.filter(
      (credential: MarketCredential) =>
        marketsByNameRef.current.has(credential.Market) && marketConfigsByMarketName.has(credential.Market)
    );
  }, [marketCredentials, marketConfigsByMarketName, marketsByNameRef]);

  const addCredentialDrawer = useDrawer({
    position: 'relative',
    width: 720,
    placement: 'right',
  });
  const editCredentialDrawer = useDrawer({
    position: 'relative',
    width: 720,
    placement: 'right',
  });

  const { tradingDetailsDrawer, selectedMarketAccount, handleOpenTradingControls } =
    useTradingControlsDetailsDrawerWrapper();

  const handleAddCredential = useDynamicCallback(() => {
    setSelectedCredential(undefined);
    setSelectedMarket(undefined);
    tradingDetailsDrawer.close();
    editCredentialDrawer.close();
    addCredentialDrawer.open();
  });

  const handleEditCredential = useDynamicCallback((marketCredential: MarketCredential) => {
    setSelectedCredential(marketCredential);
    const market = marketsByName.get(marketCredential.Market);
    handleMarketSelected(market);
    tradingDetailsDrawer.close();
    addCredentialDrawer.close();
    editCredentialDrawer.open();
  });

  const handleOnSaved = useDynamicCallback(() => {
    setSelectedCredential(undefined);
    setSelectedMarket(undefined);
    addCredentialDrawer.close();
    editCredentialDrawer.close();
    refreshData();
  });

  const handleOpenTradingControlsDrawer = useCallback(
    (marketAccountName: string) => {
      mixpanel.track(MixpanelEvent.ViewTradingControl);
      addCredentialDrawer.close();
      editCredentialDrawer.close();
      handleOpenTradingControls(marketAccountName);
    },
    [addCredentialDrawer, editCredentialDrawer, handleOpenTradingControls, mixpanel]
  );

  const handleMarketSelected = useDynamicCallback((market: Market | undefined) => {
    setSelectedMarket(market);
  });

  const selectedMarketConfig = useMemo(
    () => (selectedMarket ? marketConfigsByMarketName.get(selectedMarket.Name) : undefined),
    [marketConfigsByMarketName, selectedMarket]
  );

  const defaultColumns = useColumns({ canEditCredential, handleEdit: handleEditCredential });

  const persistedBlotterTable = usePersistedBlotterTable<HydratedMarketCredential>('credentials', {
    columns: defaultColumns,
    sort: '+Market',
  });

  const filterResults = useCredentialsFilter({
    persistedBlotterTable,
  });
  const { clientSideFilter: clientLocalFilter, filterBuilderProps } = filterResults;
  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: true },
    filterBuilderProps,
  });
  const blotterTableMenu = useCredentialsBlotterTableMenu({
    canEditCredential,
    onEditCredential: handleEditCredential,
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterBuilderProps.properties,
    marketConfigs,
    onUpdate: refreshData,
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...blotterTableMenu.columns],
    [persistedBlotterTable.columns, blotterTableMenu.columns]
  );

  const dataObservable = useObservable<SubscriptionResponse<HydratedMarketCredential>>(
    () =>
      combineLatest(
        [of(filteredMarketCredentials), connectionStatusByCredentialIDAndMarketName, of(marketConfigsByMarketName)],
        (marketCredentials, statuses, marketConfigsByMarketName) => {
          const hydratedData: HydratedMarketCredential[] = marketCredentials.map(credential => {
            // We use the MarketConfig related to our ConnectionType in order to find the MarketConfig.displayName for display.
            const usedMarketConfig = marketConfigsByMarketName
              .get(credential.Market)
              ?.credentials.find(cred => cred.type === credential.ConnectionType);

            return {
              ...credential,
              RowID: `${credential.CredentialID}-${credential.Market}`, // Blotter table does not work well with numbers as rowID
              Status:
                statuses?.get(credential.CredentialID)?.get(credential.Market)?.Status ?? ConnectionStatusEnum.Offline,
              MarketType: marketsByNameRef.current.get(credential.Market)?.Type,
              ConnectionTypeLabel: usedMarketConfig?.displayName ?? credential.ConnectionType,
            };
          });

          return { data: hydratedData, initial: true, type: 'MarketCredential', ts: '0' };
        }
      ),
    [
      filteredMarketCredentials,
      connectionStatusByCredentialIDAndMarketName,
      marketsByNameRef,
      marketConfigsByMarketName,
    ]
  );

  const blotterTable = useBlotterTable<HydratedMarketCredential>({
    dataObservable,
    rowID: 'RowID',
    density: BlotterDensity.Comfortable,
    clientLocalFilter,
    persistence: persistedBlotterTable,
    columns: columnsWithMenu,
    gridOptions: {
      onRowDoubleClicked: ({ data }) => data && handleEditCredential(data),
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems: blotterTableMenu.getContextMenuItems,
    },
  });

  const handleExport = useCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Credentials',
      }),
    });
  }, [blotterTable, mixpanel]);

  const showInstructions = selectedMarket && !selectedCredential;
  const formControlSize =
    editCredentialDrawer.isOpen || addCredentialDrawer.isOpen ? FormControlSizes.Small : FormControlSizes.Default;
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  return (
    <>
      <Panel>
        <PanelHeader p="spacingLarge">
          <h2>{showInstructions ? 'Instructions' : 'Credentials'}</h2>
          {!showInstructions && (
            <PanelActions>
              <Box ref={filtersContainerRef} />
              <Button startIcon={IconName.DocumentDownload} onClick={handleExport} size={formControlSize}>
                Export CSV
              </Button>
              {canEditCredential && (
                <>
                  <Divider orientation="vertical" />
                  <Button
                    startIcon={IconName.Plus}
                    variant={ButtonVariants.Positive}
                    onClick={handleAddCredential}
                    data-testid="add-credential-button"
                    size={formControlSize}
                  >
                    Add Credential
                  </Button>
                </>
              )}
            </PanelActions>
          )}
        </PanelHeader>
        <PanelContent overflow="hidden" px={0} pb={0}>
          <Flex
            w="100%"
            h="100%"
            flexDirection="column"
            position="absolute"
            visibility={showInstructions ? 'hidden' : undefined}
            pointerEvents={showInstructions ? 'none' : undefined}
          >
            <BlotterTableFilters
              {...filterBuilderAccordion}
              {...blotterTable.blotterTableFiltersProps}
              size={formControlSize}
              rowEndSpacing="spacingLarge"
            />
            {credentialAgeAlertBannerVisible && showCredentialAgeAlertBanner && (
              <AlertBanner variant={AlertVariants.Negative}>
                Some API keys haven’t been updated in a while. We recommend updating them to ensure security
                <Divider
                  orientation="vertical"
                  ml="spacingSmall"
                  mr="-spacingSmall"
                  color={setAlpha(0.8, theme.colorTextImportant)}
                />
                <Box my="-spacingSmall">
                  <Button
                    ghost
                    endIcon={IconName.Close}
                    size={FormControlSizes.Small}
                    onClick={() => setCredentialAgeAlertBannerVisible(false)}
                  >
                    Dismiss
                  </Button>
                </Box>
              </AlertBanner>
            )}
            <BlotterTable {...blotterTable} rowEndSpacing="spacingLarge" />
          </Flex>
          {showInstructions && (
            <Box overflow="hidden" w="100%" h="100%" position="absolute" px="spacingLarge" pb="spacingLarge">
              <OnboardingGuide
                selectedMarket={selectedMarket}
                guide={selectedMarketConfig?.guide}
                externalIP={externalIP}
                formControlSize={FormControlSizes.Default}
              />
            </Box>
          )}
        </PanelContent>
      </Panel>
      <AddCredentialDrawer
        {...addCredentialDrawer}
        marketCredentials={marketCredentials}
        marketConfigs={marketConfigs}
        selectedMarket={selectedMarket}
        externalIP={externalIP}
        onSaved={handleOnSaved}
        onMarketSelected={handleMarketSelected}
        data-testid="add-credential-drawer"
      />
      <EditCredentialDrawer
        {...editCredentialDrawer}
        marketCredentials={marketCredentials}
        marketConfigs={marketConfigs}
        selectedCredential={selectedCredential!}
        externalIP={externalIP}
        onSaved={handleOnSaved}
        mktAccsWithFees={mktAccsWithFees}
        handleMarketAccountClick={handleOpenTradingControlsDrawer}
        data-testid="edit-credential-drawer"
      />
      <TradingControlsDetailsDrawerWrapper
        {...tradingDetailsDrawer}
        selectedEntity={selectedMarketAccount}
        marketCredentials={marketCredentials}
      />
      {blotterTableMenu.dialogComponents}
    </>
  );
});

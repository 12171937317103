import { configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { intlDefaultFormatter, type IntlWithFormatter } from '../../contexts/IntlContext';
import { referenceDataSlice } from './state/OMSReferenceDataSlice/reducer';
import { orderSlice } from './state/OrderSlice/reducer';
import { getInitialState } from './state/OrderSlice/utils';
import { sessionSlice } from './state/SessionSlice/reducer';

enableMapSet();

export const getWLOrderFormStore = (
  params?: Parameters<typeof getInitialState>[0],
  intl: IntlWithFormatter = intlDefaultFormatter
) =>
  configureStore({
    preloadedState: {
      order: getInitialState(params, intl),
      referenceData: {
        securities: params ? params.securities : { securitiesBySymbol: new Map(), securitiesList: [] },
        marketAccounts: params
          ? params.marketAccounts
          : { customerMarketAccountsBySourceAccountID: new Map(), customerMarketAccountsList: [] },
        strategiesBySymbol: params?.strategiesBySymbol || new Map(),
      },
    },
    reducer: {
      order: orderSlice.reducer,
      referenceData: referenceDataSlice.reducer,
      session: sessionSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
    devTools: true,
  });

export type RootState = ReturnType<ReturnType<typeof getWLOrderFormStore>['getState']>;
export type AppStore = ReturnType<typeof getWLOrderFormStore>;
export type AppDispatch = ReturnType<typeof getWLOrderFormStore>['dispatch'];

export const useWLOrderFormStore = useStore.withTypes<AppStore>();
export const useWLOrderFormDispatch = useDispatch.withTypes<AppDispatch>();
export const useWLOrderFormSelector = useSelector.withTypes<RootState>();

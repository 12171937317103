import { createApi } from '@reduxjs/toolkit/query/react';
import { noopBaseQuery } from 'providers/AppStateProvider/noopBaseQuery';
import { restEndpointBaseQuery } from 'providers/AppStateProvider/restEndpointBaseQuery';
import type { BaseWsQuery } from 'providers/AppStateProvider/streamingDataSlice';
import { buildWsEndpointBuilder } from 'providers/AppStateProvider/wsEndpointBuilder';
import type { AppNotification } from '../AppNotifications';

export const appNotificationsApi = createApi({
  reducerPath: 'appNotificationsApi',
  baseQuery: restEndpointBaseQuery,
  endpoints: builder => ({
    // Individual endpoints
    getExistingNotifications: builder.query<AppNotification[], void>({
      query: () => ({
        method: 'GET',
        url: `/notifications`,
      }),
    }),
    archiveNotifications: builder.mutation({
      query: (data: { notificationId: string }) => ({
        url: `/notifications/${data.notificationId}/archive`,
        method: 'POST',
        credentials: 'include',
      }),
    }),
  }),
});

export const appNotificationsStreamingApi = createApi({
  reducerPath: 'appNotificationsStreamingApi',
  baseQuery: noopBaseQuery,
  keepUnusedDataFor: 30,
  endpoints: builder => {
    return {
      getNotificationData: buildWsEndpointBuilder<typeof builder>(builder).query<
        Map<string, AppNotification>,
        BaseWsQuery
      >({
        buildRequest: ({ tag = 'streamingDataSlice', ...args }) => {
          return { name: 'Notification', tag, ...args };
        },
        initializeCache: (data: AppNotification[]) =>
          data.reduce((map, notification) => map.set(notification.NotificationID, notification), new Map()),
        updateCache: (draft, data: AppNotification[]) =>
          new Map(data.reduce((map, notification) => map.set(notification.NotificationID, notification), draft)),
      }),
    };
  },
});

export const { useGetExistingNotificationsQuery, useArchiveNotificationsMutation } = appNotificationsApi;
export const { useGetNotificationDataQuery } = appNotificationsStreamingApi;

import { useCallback, type PropsWithChildren, type ReactPortal } from 'react';
import { createPortal } from 'react-dom';
import { usePortal } from '../../hooks/usePortal';

export type PortalProps =
  | {
      portalId: string;
      portalElement?: undefined;
    }
  | {
      portalId?: string;
      portalElement: HTMLElement;
    };

export const Portal = ({ children, portalId, portalElement }: PropsWithChildren<PortalProps>) => {
  const { portalRef } = usePortal(portalId ?? '');
  // Used for injecting the filters + action items in the parent container
  const PortalCallback = useCallback(
    ({ children }: PropsWithChildren<any>): ReactPortal | null => {
      if (portalElement) {
        return createPortal(children, portalElement, portalId);
      } else if (portalRef) {
        return createPortal(children, portalRef, portalId);
      }
      return null;
    },
    [portalRef, portalElement, portalId]
  );

  return <PortalCallback>{children}</PortalCallback>;
};

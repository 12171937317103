import type { ValueFormatterParams } from 'ag-grid-community';
import type { BigSource } from 'big.js';
import { get, isNil } from 'lodash-es';
import { format } from 'utils/number';
import type { AgGridOptionsData } from '../../AgGrid';
import { AG_GRID_OPTION_GROUP_KEY } from '../../AgGrid/Option/AgGridOptionGroupCell';
import { AG_GRID_OPTION_STRIKE_PRICE_KEY } from '../../AgGrid/Option/AgGridOptionStrikePrice';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';
import { numericColumnComparator } from './utils';
export const STRIKE_CLASSNAME = 'option_strike';

export type StrikeOptionColumn = Column<{ currencyField: string }>;

export const optionStrike: ColDefFactory<StrikeOptionColumn> = column => ({
  ...baseColumn(column),
  cellClass: STRIKE_CLASSNAME,
  headerClass: STRIKE_CLASSNAME,
  comparator: numericColumnComparator,
  cellRenderer: AG_GRID_OPTION_STRIKE_PRICE_KEY,
  valueFormatter: ({ value, data, context }: ValueFormatterParams<AgGridOptionsData, BigSource | undefined>) => {
    if (isNil(value)) {
      return '';
    }
    const currencySymbol = column.params?.currencyField ? get(data, column.params?.currencyField) : undefined;
    const currency = currencySymbol ? context.current.currenciesBySymbol?.get(currencySymbol) : undefined;

    return format(value, {
      spec: currency?.DefaultIncrement,
    });
  },
  sort: 'asc',
});

export const optionSide: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  children: column.children,
});

export const optionGroup: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellRenderer: AG_GRID_OPTION_GROUP_KEY,
  rowGroup: true,
});

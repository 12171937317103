import { keys } from 'lodash-es';
import { useMemo } from 'react';
import { useDynamicCallback } from '../../hooks';
import { ModeEnum } from '../../types';
import {
  useAccordionFilterBuilder,
  type BlotterTableFiltersProps,
  type UseBlotterTable,
  type useGenericFilter,
} from '../BlotterTable';
import { Button, ButtonVariants, IconButton } from '../Button';
import { HStack } from '../Core';
import { Divider } from '../Divider';
import type { FilterableProperty } from '../Filters';
import { FormControlSizes } from '../Form';
import { IconName } from '../Icons';
import type { EntityAdminClass, EntityAdminRecord } from './types';
import type { useEntityAdmin } from './useEntityAdmin';

export const useEntityAdminBlotterFilter = <TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord>(
  props: Omit<ReturnType<typeof useEntityAdmin<TRecord, TDrawerRecord>>, 'renderDrawer'> & {
    filterableProperties: FilterableProperty[];
    blotterTableFiltersProps: UseBlotterTable<EntityAdminClass<TRecord>>['blotterTableFiltersProps'];
    getSelectedRows: UseBlotterTable<EntityAdminClass<TRecord>>['getSelectedRows'];
    filterResults: ReturnType<typeof useGenericFilter>;
    expandAllGroups: () => void;
    collapseAllGroups: () => void;
  }
) => {
  const {
    blotterTableFiltersProps,
    getSelectedRows,
    allowBulkEdit,
    allowModeSwitch,
    allowDeleteEntity,
    bulkEditRows,
    bulkEditFields,
    filterResults,
    openBulkEditDrawer,
    handleOnUpdateEntitiesWithConfirmation,
    handleOnDeleteEntitiesWithConfirmation,
    getEntityAsDrawerEntity,
    expandAllGroups,
    collapseAllGroups,
    childIDField,
  } = props;

  const handleOnClickBulkEdit = useDynamicCallback(() => {
    openBulkEditDrawer(getSelectedRows().map(row => row.data.data));
  });

  const handleOnClickBulkModeSwitch = useDynamicCallback((mode: ModeEnum) => {
    const rows = getSelectedRows()
      .map(row => row.data.data)
      .map(getEntityAsDrawerEntity);

    handleOnUpdateEntitiesWithConfirmation(rows, { Mode: mode });
  });

  const handleOnClickBulkDelete = useDynamicCallback(() => {
    const rows = getSelectedRows()
      .map(row => row.data.data)
      .map(getEntityAsDrawerEntity);
    handleOnDeleteEntitiesWithConfirmation(rows);
  });

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: keys(filterResults.filter).length > 0 },
    filterBuilderProps: filterResults.filterBuilderProps,
  });

  const bulkEditPrefix = useMemo(() => {
    if (allowBulkEdit === false) {
      return null;
    }
    return (
      <HStack gap="spacingSmall">
        <HStack gap="spacingSmall">
          {/* Show Bulk Edit button if any field other than Mode is allowed to be bulk edited.
          Mode editing is not performed from the drawer, but rather from the enable/disable buttons. */}
          {bulkEditFields?.some(field => field !== 'Mode') && (
            <Button
              startIcon={IconName.Pencil}
              size={FormControlSizes.Small}
              disabled={bulkEditRows.length === 0}
              variant={ButtonVariants.Primary}
              onClick={() => handleOnClickBulkEdit()}
              data-testid="bulk-edit-button"
            >
              Bulk Edit Selection
            </Button>
          )}
          {(allowModeSwitch || allowDeleteEntity) && <Divider orientation="vertical" m="spacingSmall" />}
        </HStack>
        {bulkEditFields?.includes('Mode' as (typeof bulkEditFields)[number]) && (
          <HStack gap="spacingSmall">
            <Button
              startIcon={IconName.Check}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              disabled={bulkEditRows.every(row => row.Mode === ModeEnum.Enabled)}
              onClick={() => handleOnClickBulkModeSwitch(ModeEnum.Enabled)}
              data-testid="entity-admin-enable-selected"
            >
              Enable
            </Button>
            <Button
              startIcon={IconName.Close}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              disabled={bulkEditRows.every(row => row.Mode === ModeEnum.Disabled)}
              onClick={() => handleOnClickBulkModeSwitch(ModeEnum.Disabled)}
              data-testid="entity-admin-disable-selected"
            >
              Disable
            </Button>
            {allowDeleteEntity && <Divider orientation="vertical" m="spacingSmall" />}
          </HStack>
        )}
        {allowDeleteEntity && (
          <IconButton
            icon={IconName.Trash}
            size={FormControlSizes.Small}
            variant={ButtonVariants.Negative}
            disabled={bulkEditRows.length === 0}
            onClick={() => handleOnClickBulkDelete()}
            data-testid="entity-admin-delete-selected"
          />
        )}
      </HStack>
    );
  }, [
    allowBulkEdit,
    allowDeleteEntity,
    allowModeSwitch,
    bulkEditFields,
    bulkEditRows,
    handleOnClickBulkDelete,
    handleOnClickBulkEdit,
    handleOnClickBulkModeSwitch,
  ]);

  const collapseExpandPrefix = useMemo(() => {
    return childIDField ? (
      <HStack gap="spacingSmall">
        <IconButton
          icon={IconName.ListExpand}
          size={FormControlSizes.Small}
          variant={ButtonVariants.Default}
          onClick={expandAllGroups}
          data-testid="entity-admin-expand-all"
        />
        <IconButton
          icon={IconName.ListCollapse}
          size={FormControlSizes.Small}
          variant={ButtonVariants.Default}
          onClick={collapseAllGroups}
          data-testid="entity-admin-collapse-all"
        />
      </HStack>
    ) : null;
  }, [childIDField, collapseAllGroups, expandAllGroups]);

  const blotterTableFilter: BlotterTableFiltersProps = useMemo(() => {
    return {
      ...filterBuilderAccordion,
      ...blotterTableFiltersProps,
      quickFilterPrefix: bulkEditPrefix,
      prefix: collapseExpandPrefix,
    };
  }, [filterBuilderAccordion, blotterTableFiltersProps, bulkEditPrefix, collapseExpandPrefix]);

  const value = useMemo(() => {
    return blotterTableFilter;
  }, [blotterTableFilter]);

  return value;
};

import {
  Button,
  ButtonGroup,
  ButtonVariants,
  CopyButton,
  Divider,
  Flex,
  FormControlSizes,
  isTalosUser,
  Text,
  VStack,
} from '@talos/kyoko';
import { startCase } from 'lodash-es';
import { useEditLayoutControls } from '../../components/AppLayout/useEditLayoutControls';
import { useUser } from '../../hooks';
import { useAppLayoutContext } from '../../providers/AppLayoutContextProvider/AppLayoutContextProvider';

export function LayoutMenu() {
  const user = useUser();

  const { dockviewApiRef } = useAppLayoutContext();

  const {
    undoStack,
    redoStack,
    handleResetToInitial,
    handleChangeLayout,
    handleResetLayout,
    handleUndo,
    handleRedo,
    layoutHasChanged,
    selectedLayout,
    layoutSection,
    layoutOptions,
  } = useEditLayoutControls();

  const layoutList = Object.values(layoutOptions ?? {}).map(layout => ({ label: layout.label, value: layout.id }));

  return (
    <VStack gap="spacingDefault" p="spacingDefault" w="100%" minWidth={180} data-testid="layout-menu">
      <Text color="colorTextImportant" textAlign="left" w="100%">
        {startCase(layoutSection)} Layout
      </Text>
      <Divider />
      <Flex flexDirection="column" gap="spacingDefault" w="100%">
        <ButtonGroup size={FormControlSizes.Small}>
          <Button size={FormControlSizes.Small} onClick={handleUndo} disabled={undoStack.length === 0}>
            Undo
          </Button>
          <Button size={FormControlSizes.Small} onClick={handleRedo} disabled={redoStack.length === 0}>
            Redo
          </Button>
        </ButtonGroup>
        <ButtonGroup size={FormControlSizes.Small} orientation="vertical">
          {layoutList.map(option => (
            <Button
              size={FormControlSizes.Small}
              key={option.value}
              onClick={() => handleChangeLayout(option.value)}
              variant={option.value === selectedLayout?.id ? ButtonVariants.Primary : ButtonVariants.Default}
            >
              {option.label}
            </Button>
          ))}
        </ButtonGroup>
        <Divider />
        <Button
          disabled={!layoutHasChanged}
          onClick={handleResetToInitial}
          variant={ButtonVariants.Default}
          size={FormControlSizes.Small}
        >
          Revert layout changes
        </Button>
        <Button onClick={handleResetLayout} variant={ButtonVariants.Default} size={FormControlSizes.Small}>
          Reset layout to default
        </Button>
        {isTalosUser(user) && (
          <>
            <Divider />
            <CopyButton value={JSON.stringify(dockviewApiRef?.current?.toJSON(), null, 2)}>Copy JSON</CopyButton>
          </>
        )}
      </Flex>
    </VStack>
  );
}

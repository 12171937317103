import { LRUCache } from 'lru-cache';
import { useMemo } from 'react';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { useSecuritiesContext } from '../contexts';
import { useSocketClient } from '../providers/WebSocketClientProvider';
import { MARKET_DATA_SNAPSHOT } from '../tokens';
import {
  DepthTypeEnum,
  FeeModeEnum,
  LiquidityTypeEnum,
  type MarketDataSnapshot,
  type MarketDataSnapshotRequest,
  type SubscriptionResponse,
} from '../types';
import { EMPTY_ARRAY } from '../utils';
import { useSyncedRef } from './useSyncedRef';

export interface UseMultiMarketDataSnapshotOutput {
  cache: Map<string, MarketDataSnapshot>;
  json: SubscriptionResponse<MarketDataSnapshot>;
}

interface UseMultiMarketDataParams {
  /** Just a straight list of symbols you want market data for. This should be a ReplaySubject. */
  symbolsObs: Observable<string[]>;
  /** The maximum amount of subscriptions we dare keep open with the backend */
  maxActiveSubscriptions: number;
  /** The tag to put on the subscription for better debugging of all related streams to the hook invocation */
  subscriptionTag?: string;
  showFirmLiquidity?: boolean;
  showAllInPrices?: boolean;
}

/**
 * This hook allows you to create multiple MarketDataSnapshot streams by simply providing an array of symbols. The
 * hook returns one single observable which all stream responses will be pushed through. The output observable
 * returns the latest message as well as a "cache", as in the current absolute state of all market data snapshots
 * we have received by symbol.
 *
 * The hook uses an LRU cache internally to maintain records of what subscriptions are opened, and when more symbols
 * are requested than the maxActiveSubscriptions, the least recently requested symbol will be dropped. When a symbol is dropped,
 * the subscription to the backend is closed, and the latest received snapshot removed from the market data cache.
 *
 * Each symbol passed in gets its own MarketDataSnapshot backend stream.
 *
 * For each stream opened, we send all Security.Markets. This is subject to change in the future. One can imagine how the hook, instead of currently
 * receiving a straight list of symbols, receives a list of tuples of (symbol, markets) where the contents of this tuple make up one unique stream request.
 * But for now, we go the simple route.
 */
export const useMultiMarketDataSnapshot = ({
  symbolsObs,
  maxActiveSubscriptions,
  subscriptionTag,
  showFirmLiquidity,
  showAllInPrices,
}: UseMultiMarketDataParams) => {
  const websocketClient = useSocketClient();
  const { securitiesBySymbol } = useSecuritiesContext();

  /**
   * We just make this a synced ref so we dont need to handle with the case where securitiesBySymbol updates in the pipe. Its
   * fine if we fall slightly out of date. In the worst case, a refresh will always fix any problem the user has.
   */
  const securitiesBySymbolRef = useSyncedRef(securitiesBySymbol);

  const marketDataObs = useMemo(
    () =>
      new Observable<{ cache: Map<string, MarketDataSnapshot>; json: SubscriptionResponse<MarketDataSnapshot> }>(
        output => {
          // The cache is a key-value store representing our registered subscriptions with the websocket client.
          // Keys are symbols, and values are subscription "addresses" (their unique identifier)
          const activeSubscriptionsBySymbol = new LRUCache<string, string>({
            max: maxActiveSubscriptions,
            dispose: (address, symbol) => {
              marketDataBySymbol.delete(symbol);
              websocketClient.unregisterSubscription(address);
            },
            // Whether or not to increase the usage metric of this cache entry when we check if it exists in the cache (calling cache.has(...)).
            // This is disabled by default, but something we want in this use case.
            updateAgeOnHas: true,
          });

          const marketDataBySymbol = new Map<string, MarketDataSnapshot>();

          const symbolsSub = symbolsObs.subscribe(newSymbols => {
            for (const symbol of newSymbols) {
              const isSymbolRegistered = activeSubscriptionsBySymbol.has(symbol);
              if (isSymbolRegistered) {
                continue;
              }

              const address = uuid();
              const request: MarketDataSnapshotRequest = {
                name: MARKET_DATA_SNAPSHOT,
                DepthType: DepthTypeEnum.Price,
                Symbol: symbol,
                Depth: 3,
                tag: subscriptionTag ?? 'MultiMarketDataSnapshot',
                // For backend performance reasons, we send all markets on the security. The fallback (undesireable) is we send an empty array, which can lead to performance issues on BE.
                Markets: securitiesBySymbolRef.current.get(symbol)?.Markets ?? EMPTY_ARRAY,
                LiquidityType: showFirmLiquidity ? LiquidityTypeEnum.Firm : LiquidityTypeEnum.Indicative,
                FeeMode: showAllInPrices ? FeeModeEnum.Taker : undefined,
              };

              websocketClient.registerSubscription(address, [request], (err, json) => {
                if (err) {
                  websocketClient.unregisterSubscription(address);
                  activeSubscriptionsBySymbol.delete(request.Symbol);
                }

                if (json && json.error) {
                  //
                } else if (json && json.data && Array.isArray(json.data)) {
                  const data = json.data as MarketDataSnapshot[];
                  for (const update of data) {
                    marketDataBySymbol.set(update.Symbol, update);
                  }
                  // Were combining a bunch of streams and none of them are delta updates anyway so just set initial to always be false
                  output.next({
                    cache: marketDataBySymbol,
                    json: { ...json, data, initial: false },
                  } satisfies UseMultiMarketDataSnapshotOutput);
                }
              });
              activeSubscriptionsBySymbol.set(symbol, address);
            }
          });

          return () => {
            symbolsSub.unsubscribe();
            activeSubscriptionsBySymbol.forEach(address => websocketClient.unregisterSubscription(address));
          };
        }
      ),
    [
      symbolsObs,
      websocketClient,
      maxActiveSubscriptions,
      subscriptionTag,
      securitiesBySymbolRef,
      showFirmLiquidity,
      showAllInPrices,
    ]
  );

  return marketDataObs;
};

import { cloneDeep, compact, isEqual, isFunction } from 'lodash-es';
import {
  createElement,
  useCallback,
  useMemo,
  useState,
  type Dispatch,
  type ReactElement,
  type SetStateAction,
} from 'react';
import { useUpdateEffect } from 'react-use';
import { v1 as uuid } from 'uuid';
import { useDynamicCallback } from '../../hooks/useDynamicCallback';
import { useGlobalDialog } from '../../providers/DialogProvider';
import type { TabsProps } from './Tabs';
import type { TabProps } from './types';

export type UseTabsProps<T extends TabProps = TabProps> = Omit<TabsProps<T>, 'selectedIndex' | 'onSelect'> & {
  /** The index to have selected on init */
  initialSelectedIndex?: number;
  /** The initial set of items */
  initialItems: (T | null | undefined)[];
  /** A function which is called every time the items set changes, but also when onItemsChanged function itself changes (be careful) */
  onItemsChanged?(items: T[]): void;
  /** Called whenever the selectedIndex changes, or this function reference changes (be careful) */
  onSelect?(index: number, items?: T[]): void;
  /**
   * A function which is called on tab creation, which spreads the return of the function on top of all new tabs.
   * Is invoked with the passed in tab to be created as its only parameter.
   */
  onCreateTab?: (tab: Partial<T> | undefined) => Partial<T>;
  requireRemoveConfirmation?: boolean | ((tabIndex: number, tab: T) => boolean);
  getConfirmationContent?: (tabIndex: number, tab: T) => ReactElement;
  /**
   * Supplying this callback allows you to control the label of the tabs as a function of their state.
   * This callback will run whenever there is some state change to any tab.
   *
   * A tab is "usingSmartLabel" up until the user manually renames the tab. At that point, that tab will stop calling
   * this callback forever, and instead just have its manually set label.
   */
  tabLabeler?: string | TabLabelerFn<T>;
};

export type TabLabelerFn<T extends TabProps = TabProps> = (
  tab: T,
  currentTabs: T[],
  action: 'creation' | 'update' | 'cloning'
) => string;

export type UseTabs<T> = {
  /** If true we're in edit mode, can rename new tab **/
  isAdding: boolean;

  /** Currently selected tab index **/
  selectedIndex: number;

  /** Change selected tab index **/
  setSelectedIndex: Dispatch<SetStateAction<number>>;

  /** Called after tab name change */
  onRename(index: number, name: string): void;

  /** Called on tab change */
  onSelect(index: number): void;

  /** Called on cancel/exit edit mode */
  onCancel(index: number): void;

  /** Called on tab removal */
  onRemove(index: number): void;

  /** Custom reorder function */
  onReorder(startIndex: number, endIndex: number): void;

  /** Called on tab addition */
  onAdd?(tab?: Partial<T>): string | undefined;

  /** Default reorder function when onReorder is not provided */
  reorderItems(
    startIndex: number,
    endIndex: number
  ): {
    selectedIndex: number;
    items: (T | TabProps)[];
  };

  /**
   * Call to clone the tab at the provided index.
   * The provided `props` are spread last onto the newly created tab.
   *
   * `editable` and `closable` are set to `true` on the new tab. If you for whatever reason really don't want
   * your cloned tab to be editad or closed, set these to false in the passed props.
   */
  clone(index: number, props?: T | TabProps): void;

  /** Current list of tabs */
  items: T[];

  /** Set new list of tabs */
  setItems: Dispatch<SetStateAction<T[]>>;

  /**
   * Allows you to update just one item. Assumes that the item is already present in the `items` array (finds by item.id).
   * If the item.id is not found in the current items array, will result in this call being a no-op.
   */
  updateItem: (item: T) => void;

  /** Allow to have 0 tabs or require at least 1 tab */
  allowClosingLastTab: boolean | undefined;
};

export function useTabs<T extends TabProps = TabProps>({
  initialSelectedIndex = 0,
  initialItems,
  requireRemoveConfirmation,
  getConfirmationContent,
  onSelect,
  onAdd,
  onRemove,
  onReorder,
  onCancel,
  onRename,
  onItemsChanged,
  allowClosingLastTab,
  onCreateTab,
  tabLabeler,
  // TODO spreading props like this makes the memo at the bottom useless
  ...props
}: UseTabsProps<T>) {
  const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);
  const [items, setItems] = useState<T[]>(
    compact(initialItems).map((tab, _, currentTabs) => ({
      ...tab,
      id: tab.id ?? uuid(),
      label: getLabelToUse({ tab, currentTabs, action: 'update', tabLabeler }),
    }))
  );
  const [isAdding, setAdding] = useState(false);
  useUpdateEffect(() => {
    onSelect?.(selectedIndex, items);
  }, [selectedIndex, onSelect]);

  // This effect runs every time any tab's state updates.
  useUpdateEffect(() => {
    onItemsChanged?.(items);
  }, [items, onItemsChanged]);

  const handleRename = useCallback(
    (index: number, name: string) => {
      setItems(items => {
        if (items[index].isAddingTab) {
          setSelectedIndex(index);
        }
        return items.map((tab, i) =>
          // Set the new name, disable any potential smartLabeling going on, and if we're adding a tab also stop that action
          i === index ? { ...tab, label: name, usingSmartLabel: false, isAddingTab: false } : tab
        );
      });
      setAdding(false);
      onRename?.(index, name);
    },
    [onRename]
  );

  const handleSelect = useCallback((index: number) => setSelectedIndex(index), []);

  const handleCancel = useCallback(
    (index: number) => {
      setAdding(false);
      onCancel?.(index);
    },
    [onCancel]
  );

  const { open } = useGlobalDialog();

  const removeTab = useDynamicCallback((index: number) => {
    setSelectedIndex(selectedIndex => {
      if (selectedIndex >= index) {
        return Math.max(0, selectedIndex - 1);
      }
      return selectedIndex;
    });
    setItems(items => items.filter((_, i) => i !== index));
    onRemove?.(index);
  });

  const onRemoveWithDialog = useDynamicCallback((index: number, tab: T) => {
    open({
      onConfirm: () => {
        removeTab(index);
      },
      width: 360,
      content: getConfirmationContent
        ? getConfirmationContent(index, tab)
        : createElement('p', null, 'Would you like to proceed?'),
    });
  });

  const handleRemove = useDynamicCallback((index: number) => {
    const openDialog = isFunction(requireRemoveConfirmation)
      ? requireRemoveConfirmation(index, items[index])
      : requireRemoveConfirmation;
    if (openDialog) {
      onRemoveWithDialog(index, items[index]);
    } else {
      removeTab(index);
    }
  });

  const handleAdd = useCallback(
    (tab?: Partial<T>) => {
      const newTab = {
        id: uuid(),
        closable: true,
        editable: true,
        reorderable: true,
        ...onCreateTab?.(tab),
        ...tab,
      } as T;

      setItems(currentTabs => {
        const label = getLabelToUse({ tab: newTab, currentTabs, action: 'creation', tabLabeler });
        const hasLabel = label !== '';
        setAdding(!hasLabel);

        newTab.isAddingTab = !hasLabel;
        newTab.label = label;
        const newItems = [...currentTabs, newTab];

        if (hasLabel) {
          setSelectedIndex(newItems.length - 1);
        }
        return newItems;
      });
      onAdd?.(tab);
      return newTab.id;
    },
    [onAdd, onCreateTab, tabLabeler]
  );

  const reorderItems = useCallback(
    (
      startIndex: number,
      endIndex: number
    ): {
      selectedIndex: number;
      items: (T | TabProps)[];
    } => {
      if (startIndex === endIndex) {
        return {
          selectedIndex,
          items,
        };
      }
      const newItems = [...items];
      const selectedId = newItems[selectedIndex].id;
      const [removed] = newItems.splice(startIndex, 1);
      newItems.splice(endIndex, 0, removed);
      setItems(newItems);
      const newSelectedIndex = newItems.findIndex(item => item.id === selectedId);
      if (newSelectedIndex > -1) {
        setSelectedIndex(newSelectedIndex);
      }
      return {
        selectedIndex: newSelectedIndex,
        items: newItems,
      };
    },
    [items, selectedIndex]
  );

  const customReorderItems = useCallback(
    (startIndex: number, endIndex: number) => {
      onReorder ? onReorder(startIndex, endIndex) : reorderItems(startIndex, endIndex);
    },
    [onReorder, reorderItems]
  );

  const clone = useCallback(
    (index: number, props: T | TabProps = {}) => {
      setItems(currentTabs => {
        const newTab: T = {
          ...currentTabs[index],
          id: uuid(),
          closable: true,
          editable: true,
          ...props,
        };

        // if the user provided their own label as an override (via props), use that first and foremost
        newTab.label = props.label ?? getClonedTabLabel({ clonedTab: newTab, currentTabs, tabLabeler });
        const updatedTabs = [...currentTabs, newTab];
        setSelectedIndex(updatedTabs.length - 1);
        return updatedTabs;
      });
    },
    [tabLabeler]
  );

  const wrappedSetTabItems = useDynamicCallback((setItemsAction: ((currentItems: T[]) => T[]) | T[]) => {
    // Just how you can pass a function or just the new state in a setState call, same happens here.
    // We can now inject custom logic here before the _actual_ setItems call is made to apply any derived state.
    setItems(prevItems => {
      const prevItemsByID = new Map(prevItems.map(item => [item.id, item]));
      // Note: We deep clone the items here, so that if any of the items are modified without cloning,
      // we can still deep-compare against the original items list for changes.
      let updatedItems = typeof setItemsAction === 'function' ? setItemsAction(cloneDeep(prevItems)) : setItemsAction;

      // We now have the updates the implementer wishes to make. At this point we apply some internal derived state (maybe)
      // to the new list of tabs
      if (tabLabeler) {
        // Check each tab and make sure their smart label is up to date (if the tab is using smart labels)
        updatedItems = updatedItems.map(item => {
          if (!item.usingSmartLabel) {
            return item;
          }

          const prevItem = item.id ? prevItemsByID.get(item.id) : undefined;
          const labelChanged = prevItem != null && prevItem.label !== item.label;
          if (labelChanged) {
            // If there was a change to the tab label from the outside, we assume the implementer no longer wants to use our smart labeling
            return {
              ...item,
              usingSmartLabel: false,
            };
          }

          return {
            ...item,
            label: getLabelToUse({ tab: item, currentTabs: updatedItems, action: 'update', tabLabeler }),
          };
        });
      }

      // Only do the deep equals check in the case of a function for the setItemsAction;
      // if an array was passed for the item, we assume that the caller already knows whether any changes were made or not.
      // But for a function, the caller may use `tabs.forEach` and update e.g. filters for the tabs, without checking for actual differences
      // (see the analytics code for an example, which unconditionally updates the filters when new datasets come in)
      if (typeof setItemsAction === 'function' && isEqual(updatedItems, prevItems)) {
        return prevItems;
      }

      return updatedItems;
    });
  });

  const updateItem = useDynamicCallback((updatedItem: T) => {
    wrappedSetTabItems(currentItems => currentItems.map(item => (item.id === updatedItem.id ? updatedItem : item)));
  });

  return useMemo(
    () =>
      ({
        isAdding,
        selectedIndex,
        setSelectedIndex,
        onRename: handleRename,
        onSelect: handleSelect,
        onCancel: handleCancel,
        onRemove: handleRemove,
        onAdd: handleAdd,
        onReorder: customReorderItems,
        reorderItems,
        clone,
        items,
        setItems: wrappedSetTabItems,
        allowClosingLastTab,
        updateItem,
        ...props,
      } satisfies UseTabs<T>),
    [
      isAdding,
      selectedIndex,
      setSelectedIndex,
      handleRename,
      handleSelect,
      handleCancel,
      handleRemove,
      handleAdd,
      customReorderItems,
      reorderItems,
      wrappedSetTabItems,
      clone,
      updateItem,
      items,
      allowClosingLastTab,
      props,
    ]
  );
}

interface GetLabelToUseParams<T extends TabProps> {
  tab: T;
  currentTabs: T[];
  action: 'creation' | 'update' | 'cloning';
  tabLabeler: UseTabsProps<T>['tabLabeler'];
}

/** Given a tab, returns what tab it should show */
function getLabelToUse<T extends TabProps>({ tab, currentTabs, action, tabLabeler }: GetLabelToUseParams<T>) {
  // We use the tabLabeler if we are creating a new tab, or if the tab has usingSmartLabel to true.
  const shouldGetLabelFromTabLabeler = tabLabeler != null && (action === 'creation' || tab.usingSmartLabel);
  if (shouldGetLabelFromTabLabeler) {
    return typeof tabLabeler === 'string' ? tabLabeler : tabLabeler(tab, currentTabs, action);
  }

  return tab.label ?? '';
}

interface GetClonedTabLabelParams<T extends TabProps> {
  clonedTab: T;
  currentTabs: T[];
  tabLabeler: UseTabsProps<T>['tabLabeler'];
}

function getClonedTabLabel<T extends TabProps>({ clonedTab, currentTabs, tabLabeler }: GetClonedTabLabelParams<T>) {
  if (clonedTab.usingSmartLabel && tabLabeler) {
    return getLabelToUse({ tab: clonedTab, currentTabs, action: 'cloning', tabLabeler });
  }

  return `Copy of ${clonedTab.label}`;
}

import { type ColumnDef, type CreditBlotterExposure, numericComparator } from '@talos/kyoko';
import type { ICellRendererParams, IRowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { CreditUsage } from './CreditUsage';

type CreditUsageColumnValue = {
  usages: CreditBlotterExposure['creditUsages'];
  exposure: CreditBlotterExposure;
};

/**
 * Returns a Credit Usage column. All it needs to know is where to find the CreditBlotterExposure entity.
 *
 * In some blotters that'll be a property of the row node's data, or it might be the row node's data itself.
 */
export const getCreditUsageColumn = <T,>({
  colId,
  getCreditBlotterExposure,
}: {
  colId: string;
  getCreditBlotterExposure: (item: T) => CreditBlotterExposure | undefined;
}) =>
  ({
    id: colId,
    type: 'custom',
    title: 'Credit Usage',
    sortable: true,
    description: 'For OTC accounts offering deferred settlement, how much credit has been consumed.',
    params: {
      width: 400,
      valueGetter: (params: ValueGetterParams<T>) => {
        if (!params.data) {
          return undefined;
        }

        const exposure = getCreditBlotterExposure(params.data);
        if (!exposure) {
          return undefined;
        }

        return { exposure, usages: exposure.creditUsages };
      },
      cellRenderer: ({ value }: ICellRendererParams<T, CreditUsageColumnValue>) => {
        if (!value) {
          return <></>;
        }
        return <CreditUsage value={value.usages} data={value.exposure} />;
      },
      valueFormatter: ({ data }: ValueFormatterParams<CreditBlotterExposure, CreditUsageColumnValue>) => {
        return data?.effectiveSide?.usage?.usage.toFixed() ?? '';
      },
      comparator: (valueA, valueB, nodeA: IRowNode<CreditBlotterExposure>, nodeB: IRowNode<CreditBlotterExposure>) =>
        numericComparator(nodeA.data?.effectiveSide?.usage?.usage ?? 0, nodeB.data?.effectiveSide?.usage?.usage ?? 0),
    },
  } satisfies ColumnDef<T>);

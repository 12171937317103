import { PORTFOLIO_OTC_TREASURY, useObservable, useObservableValue, useStaticSubscription } from '@talos/kyoko';
import type { MarketPositions, Position } from 'containers/Portfolio/types';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import { map, scan, shareReplay } from 'rxjs';

export const PortfolioTreasuryContext = createContext<PortfolioTreasuryContextProps | undefined>(undefined);
PortfolioTreasuryContext.displayName = 'PortfolioTreasuryContext';

export interface PortfolioTreasuryContextProps {
  OTCTreasuryPositions: MarketPositions[] | undefined;
  OTCTreasuryPositionsByMarketAccountAndCurrency: Map<string, Map<string, Position>> | undefined;
}

export function usePortfolioTreasury() {
  const context = useContext(PortfolioTreasuryContext);
  if (context === undefined) {
    throw new Error('Missing PortfolioTreasuryContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const PortfolioTreasuryProvider = function PortfolioTreasuryProvider({ children }: PropsWithChildren) {
  const { data: OTCTreasuryPositionsRawSub } = useStaticSubscription({
    name: PORTFOLIO_OTC_TREASURY,
    tag: 'PortfolioTreasuryProvider',
  });

  const OTCTreasuryPositionsSub = useObservable(
    () =>
      OTCTreasuryPositionsRawSub.pipe(
        scan((map, json) => {
          if (json.initial) {
            map.clear();
          }
          for (const data of json.data) {
            map.set(data.MarketAccount, data);
          }
          return map;
        }, new Map<string, MarketPositions>()),
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [OTCTreasuryPositionsRawSub]
  );

  // MarketPositions[] -> Map<MarketAccount string, Map<Currency string, Position>>
  const OTCTreasuryPositionsByMarketAccountAndCurrencySub = useObservable(
    () =>
      OTCTreasuryPositionsSub.pipe(
        map(mposArr => {
          const newMap = new Map<string, Map<string, Position>>();
          for (const mpos of mposArr) {
            newMap.set(mpos.MarketAccount, new Map(mpos.Positions.map(pos => [pos.Currency, pos])));
          }
          return newMap;
        })
      ),
    [OTCTreasuryPositionsSub]
  );

  const OTCTreasuryPositions = useObservableValue(() => OTCTreasuryPositionsSub, [OTCTreasuryPositionsSub]);
  const OTCTreasuryPositionsByMarketAccountAndCurrency = useObservableValue(
    () => OTCTreasuryPositionsByMarketAccountAndCurrencySub,
    [OTCTreasuryPositionsByMarketAccountAndCurrencySub]
  );

  const value = useMemo(
    () => ({
      OTCTreasuryPositions,
      OTCTreasuryPositionsByMarketAccountAndCurrency,
    }),
    [OTCTreasuryPositions, OTCTreasuryPositionsByMarketAccountAndCurrency]
  );

  return <PortfolioTreasuryContext.Provider value={value}>{children}</PortfolioTreasuryContext.Provider>;
};

import {
  ConnectionType,
  logger,
  MarketAccountTypeEnum,
  MarketTypeEnum,
  useMarketAccountsContext,
  useMarketsContext,
  useSecuritiesContext,
  useStrategiesContext,
  useUnifiedLiquidity,
  type Allocation,
  type FixingIndex,
  type Market,
  type MarketAccount,
  type OrderStrategy,
  type Security,
} from '@talos/kyoko';
import { compact, concat, uniq } from 'lodash-es';
import { useMemo } from 'react';

import { useFixingIndices } from 'providers/FixingIndexProvider';
import { useTradingSettings } from '../providers/TradingSettingsContext';
import { STRATEGY_GROUP } from '../tokens/order';
import type { OrderStrategiesEnum } from '../tokens/orderStrategy';
import { usePermittedTradableMarketAccountsSet } from './usePermittedTradableMarketAccountsSet';

export type UseTradableMarketAccountNamesParams = {
  security: Security | undefined;
  /**
   * If set to -1, there will be no filtering and all available market accounts will be returned
   */
  subAccountAllocations: Allocation[] | -1;
  combineAllMarketAccounts?: boolean;
  strategy?: OrderStrategiesEnum;
  fixingIndex?: FixingIndex;
  /**
   * Whether or not we are trading in counter currency. Some markets do not support this, and will be removed from the output if this is true.
   * Defaults to false.
   */
  usingCounterCurrency?: boolean;
  /** Whether or not to include Accounts related to Markets where the Market has status Offline. Defaults to true. */
  includeOfflineMarkets?: boolean;
} & (
  | {
      connectionType: ConnectionType.Orders;
      isUnifiedLiquidityEnabled?: boolean;
    }
  | {
      connectionType: ConnectionType.RFQ;
      isUnifiedLiquidityEnabled?: false;
    }
);

/**
 * Given a Security, strategy, configuration of the organizations PermissionFilters and subAccountAllocation(s),
 * returns list of configured, tradable market account names, independent of their status.
 *
 */
export function useTradableMarketAccountNames({
  security,
  fixingIndex,
  subAccountAllocations,
  connectionType,
  isUnifiedLiquidityEnabled = false,
  combineAllMarketAccounts = false,
  strategy,
  usingCounterCurrency = false,
  includeOfflineMarkets = true,
}: UseTradableMarketAccountNamesParams): MarketAccount['Name'][] {
  const unifiedLiquidityToken = useUnifiedLiquidity(security?.Symbol);
  const { isMarketConfigured, isMarketOnline, marketsByName } = useMarketsContext();

  const { marketAccountsByMarket } = useMarketAccountsContext();
  const { allowSyntheticCrosses, allowSyntheticCcy: allowSyntheticCrossCurrency } = useTradingSettings();
  const { securitiesBySymbol } = useSecuritiesContext();
  const { strategiesByName } = useStrategiesContext();
  const { marketsByFixingIndex } = useFixingIndices();

  // If Set<string>, only those marketAccounts included are allowed, if null, everything goes
  const permittedTradableMarketAccountsSet = usePermittedTradableMarketAccountsSet({
    subAccountAllocations,
    combineAllMarketAccounts,
  });

  const possibleMarketAccountNames = useMemo(() => {
    if (!security || !security.Markets) {
      return [];
    }

    let possibleMarkets = security.Markets.filter(market => {
      return includeOfflineMarkets
        ? isMarketConfigured(market, connectionType)
        : isMarketOnline(market, connectionType);
    });

    // Possibly extend the market selection with rfq synthethic crossing
    if (connectionType === ConnectionType.RFQ && allowSyntheticCrosses) {
      const crossSecurity = securitiesBySymbol.get(`${security.BaseCurrency}-USD`);
      if (crossSecurity != null) {
        const crossSecurityMarkets = crossSecurity.Markets?.filter(m => isMarketOnline(m, ConnectionType.RFQ)) || [];
        possibleMarkets = concat(possibleMarkets, crossSecurityMarkets);
      }
    }
    // Possibly extend the market selection with unified liquidity enabled
    else if (isUnifiedLiquidityEnabled && unifiedLiquidityToken) {
      const unifiedLiquidityMarkets = unifiedLiquidityToken.Tokens.map(m => m.Market);
      possibleMarkets = concat(possibleMarkets, unifiedLiquidityMarkets);
    }

    // remove markets based on selected strategy
    if (strategy) {
      const orderStrategy = strategiesByName.get(strategy);
      if (!orderStrategy) {
        logger.warn('Unable to resolve Strategy when producing set of Tradable Market Accounts', { strategy });
      }
      possibleMarkets = possibleMarkets.filter(m => {
        const market = marketsByName.get(m);
        if (!market || !orderStrategy) {
          return false;
        }

        // If the Market is an InternalAlgo market (the multileg market), we pass it through.
        // This selection will later be filtered out in the view component, but it needs to be present in the model's item array
        // in order to be both put on the order and correctly create a market data snapshot request
        if (market.Type === MarketTypeEnum.InternalAlgo) {
          return true;
        }

        return filterMarketsByStrategy(market, orderStrategy);
      });
    }

    // Possibly remove markets if we want to trade in counter currency, but the market does not support counter currency trading, and the user does not allow synthetic cross currency trading (a setting)
    if (usingCounterCurrency) {
      possibleMarkets = possibleMarkets.filter(m => {
        const market = marketsByName.get(m);
        const supportsNativeCounterCurrency = market?.Flags?.SupportsNativeCounterCurrency === true;
        if (supportsNativeCounterCurrency || allowSyntheticCrossCurrency) {
          return true;
        }
        return false;
      });
    }

    if (fixingIndex == null) {
      // Remove all markets that have a market fixing index
      const marketsWithFixingIndex = [...marketsByFixingIndex.values()].flat();
      possibleMarkets = possibleMarkets.filter(market => !marketsWithFixingIndex.includes(market));
    } else {
      // Possibly exclude the market selection based on fixing index
      const marketsForFixingIndex = marketsByFixingIndex.get(fixingIndex.Name) || [];
      possibleMarkets = possibleMarkets.filter(market => marketsForFixingIndex.includes(market));
    }

    return compact(
      uniq(possibleMarkets).flatMap(market =>
        marketAccountsByMarket.has(market)
          ? marketAccountsByMarket.get(market)!.map(ma =>
              // Ensure market account is of trading type
              ma.Type === MarketAccountTypeEnum.Trading ? ma.Name : null
            )
          : null
      )
    );
  }, [
    allowSyntheticCrosses,
    connectionType,
    isMarketConfigured,
    isMarketOnline,
    isUnifiedLiquidityEnabled,
    marketAccountsByMarket,
    marketsByName,
    strategiesByName,
    securitiesBySymbol,
    security,
    unifiedLiquidityToken,
    strategy,
    marketsByFixingIndex,
    fixingIndex,
    usingCounterCurrency,
    allowSyntheticCrossCurrency,
    includeOfflineMarkets,
  ]);

  return useMemo(() => {
    if (permittedTradableMarketAccountsSet === null || subAccountAllocations === -1) {
      return possibleMarketAccountNames;
    }
    // there is a whitelist of market accounts for the particular selection of subaccounts and org setup
    return possibleMarketAccountNames.filter(marketaccount => permittedTradableMarketAccountsSet.has(marketaccount));
  }, [permittedTradableMarketAccountsSet, possibleMarketAccountNames, subAccountAllocations]);
}

function filterMarketsByStrategy(market: Market, orderStrategy: OrderStrategy): boolean {
  // If the Strategy supports all markets, we show all Markets except those of type Dark
  if (orderStrategy.Group === STRATEGY_GROUP.ALL) {
    if (market.Type === MarketTypeEnum.Dark) {
      return false;
    }

    return true;
  }

  // If the Strategy only supports a specific type of Market (Strategy.Group), then make sure we only show Markets of that type
  if (orderStrategy.Group !== market.Type) {
    return false;
  }

  return true;
}

import {
  AccordionGroup,
  ExpandableBottomPanel,
  Flex,
  HStack,
  Tab,
  TabAppearance,
  TabList,
  Tabs,
  TabSize,
  Text,
  useExpandablePanel,
  useTabs,
  VStack,
} from '@talos/kyoko';
import { useCallback, useMemo, useState } from 'react';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { BlotterWrapper, XScrollableContainer } from '../styles';
import { OperationsOverviewBlotterContainer } from './blotter';
import { ControlPanel } from './components/ControlPanel';
import {
  OperationsOverviewFiltersProvider,
  useOperationsOverviewFilters,
} from './providers/OperationsOverviewFiltersProvider';
import { OperationsOverviewInteractionsProvider } from './providers/OperationsOverviewInteractionsProvider';

import { useFeatureFlag } from '../../../hooks';
import { useAppStateDispatch } from '../../../providers/AppStateProvider';
import { OperationsOverviewOld } from '../OperationsOverviewOld/OperationsOverview';
import { portfolioViewLayoutSlice } from '../PortfolioManagement/stateManagement/portfolioViewLayoutSlice';
import { usePortfolioViewStateSelector } from '../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import { OpsBalancesChartModule } from './BalancesChart/OpsBalancesChartModule';
import { PMMarginHUD } from './components/PMMarginHUD';
import { OpsEMRChartModule } from './EMRChart/OpsEMRChartModule';
import {
  type OperationsOverviewConfigContextProps,
  OperationsOverviewConfigProvider,
  useOperationsOverviewConfig,
} from './providers/OperationsOverviewConfigProvider';
import {
  OperationsOverviewPositionsProvider,
  useOperationsOverviewPositions,
} from './providers/OperationsOverviewPositionsProvider';

const TABS = [
  {
    label: 'Balances and Positions',
    render: () => <OperationsOverviewBlotterContainer />,
  },
];

const DEFAULT_BLOTTER_HEIGHT = 450;
const OPS_OVERVIEW_BLOTTER_HEIGHT = 'OpsOverviewBlotterHeight';

const OperationsOverviewInner = () => {
  const { initialFilterClauses } = useOperationsOverviewFilters();
  const { mode } = useOperationsOverviewConfig();

  const [isBlotterMaximized, setIsBlotterMaximized] = useState(false);
  const [isBlotterMinimized, setIsBlotterMinimized] = useState(false);

  const handleMaximizeBlotter = useCallback((shouldExpand: boolean) => {
    setIsBlotterMinimized(false);
    setIsBlotterMaximized(curr => shouldExpand ?? !curr);
  }, []);

  const handleMinimizeBlotter = useCallback((shouldMinimize: boolean) => {
    setIsBlotterMaximized(false);
    setIsBlotterMinimized(curr => shouldMinimize ?? !curr);
  }, []);

  const tabs = useTabs({
    initialSelectedIndex: 0,
    initialItems: TABS,
  });

  const { containerRef, ...panelProps } = useExpandablePanel<HTMLDivElement>({
    initialHeight: parseInt(localStorage.getItem(OPS_OVERVIEW_BLOTTER_HEIGHT) ?? '0') || DEFAULT_BLOTTER_HEIGHT,
    isExpanded: isBlotterMaximized,
    isMinimized: isBlotterMinimized,
    onToggleExpanded: handleMaximizeBlotter,
    onToggleMinimize: handleMinimizeBlotter,
    onAdjustedHeight(newHeight) {
      localStorage.setItem(OPS_OVERVIEW_BLOTTER_HEIGHT, newHeight.toString());
    },
  });

  const { positionsData, marketAccountPositionsData, hasDerivs } = useOperationsOverviewPositions();

  const showPMMarginHUD = mode === 'SubAccount';
  const showEMRChart = hasDerivs;
  const showBalancesChart = mode === 'MarketAccount' ? true : hasDerivs;
  const showNoDerivsMessage = mode === 'SubAccount' && !hasDerivs;

  return (
    <Flex h="100%" w="100%" flexDirection="column" overflow="hidden">
      <AccordionGroup>
        <ControlPanel initialFilterClauses={initialFilterClauses} />
      </AccordionGroup>

      {showPMMarginHUD && <PMMarginHUD />}

      <VStack w="100%" h="100%" overflow="hidden" position="relative" ref={containerRef}>
        <XScrollableContainer w="100%" justifyContent="center">
          <HStack minHeight="300px" w="100%" h="100%" minWidth="1200px" gap="spacingTiny">
            {/* Only show the emr chart module if there are any derivatives positions present given the positions data we are given */}
            {showEMRChart && (
              <OpsEMRChartModule positions={positionsData} accountPositions={marketAccountPositionsData} />
            )}
            {showBalancesChart && (
              <OpsBalancesChartModule positions={positionsData} accountPositions={marketAccountPositionsData} />
            )}
            {showNoDerivsMessage && (
              <HStack h="100%" w="100%" background="colors.gray.main">
                <Text data-testid="no-derivatives-position-message">
                  Selected portfolio does not contain derivatives positions.
                </Text>
              </HStack>
            )}
          </HStack>
        </XScrollableContainer>
        <BlotterWrapper>
          <ExpandableBottomPanel
            {...panelProps}
            showControls
            header={
              <Tabs {...tabs} appearance={TabAppearance.Filled} size={TabSize.Small}>
                <TabList>
                  {TABS.map((tab, i) => (
                    <Tab key={i} {...tab} />
                  ))}
                </TabList>
              </Tabs>
            }
          >
            {TABS.map(
              (tab, i) =>
                tabs.selectedIndex === i && <ErrorBoundary key={`component-${i}`}>{tab.render()}</ErrorBoundary>
            )}
          </ExpandableBottomPanel>
        </BlotterWrapper>
      </VStack>
    </Flex>
  );
};

export const OperationsOverviewNew = ({ config }: { config: OperationsOverviewConfigContextProps }) => {
  return (
    <OperationsOverviewConfigProvider config={config}>
      <OperationsOverviewFiltersProvider>
        <OperationsOverviewInteractionsProvider>
          <OperationsOverviewPositionsProvider>
            <OperationsOverviewInner />
          </OperationsOverviewPositionsProvider>
        </OperationsOverviewInteractionsProvider>
      </OperationsOverviewFiltersProvider>
    </OperationsOverviewConfigProvider>
  );
};

const {
  updateOpsOverviewBalancesChartDimension,
  updateOpsOverviewBalancesChartShowByAsset,
  updateOpsOverviewFilter,
  updateOpsOverviewShowBy,
} = portfolioViewLayoutSlice.actions;

export const OperationsOverview = () => {
  const dispatch = useAppStateDispatch();

  const { enableMarginManagementFeatures } = useFeatureFlag();
  const {
    opsOverviewBalancesChartDimension,
    opsOverviewBalancesChartShowByAsset,
    opsOverviewFilter,
    opsOverviewShowBy,
  } = usePortfolioViewStateSelector();

  const config: OperationsOverviewConfigContextProps = useMemo(
    () => ({
      mode: 'MarketAccount',
      opsOverviewBalancesChartDimension,
      opsOverviewBalancesChartShowByAsset,
      opsOverviewFilter,
      opsOverviewShowBy,
      updateOpsOverviewBalancesChartDimension: dimension =>
        dispatch(updateOpsOverviewBalancesChartDimension(dimension)),
      updateOpsOverviewBalancesChartShowByAsset: showByAsset =>
        dispatch(updateOpsOverviewBalancesChartShowByAsset(showByAsset)),
      updateOpsOverviewFilter: filter => dispatch(updateOpsOverviewFilter(filter)),
      updateOpsOverviewShowBy: showBy => dispatch(updateOpsOverviewShowBy(showBy)),
    }),
    [
      opsOverviewBalancesChartDimension,
      opsOverviewBalancesChartShowByAsset,
      opsOverviewFilter,
      opsOverviewShowBy,
      dispatch,
    ]
  );

  return enableMarginManagementFeatures ? <OperationsOverviewNew config={config} /> : <OperationsOverviewOld />;
};

import { useCallback, type PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';

import { EllipsisBoxWithTooltip } from 'components/Form/BaseSelect/EllipsisBoxWithTooltip';
import { logger } from 'utils/logger';
import { Flex, HStack, VStack, type BoxProps } from '../Core';

import { format, isToday } from 'date-fns';
import type { ToastAppInboxProps } from '../../hooks/Toast/useToasts';
import { parseDimension } from '../../styles';
import type { ValueOf } from '../../utils/types';
import { IconName } from '../Icons';
import { Icon } from '../Icons/Icon';
import { Menu, MenuItem } from '../Menu';
import { Case, Switch } from '../Switch';
import { Text } from '../Text';
import { NotificationAppInboxWrapper } from './styles';
import { NotificationAppInboxVariants } from './types';

export type NotificationAppInboxToastProps = ToastAppInboxProps & {
  /** Where the notification is to be displayed */
  containerVariant: 'inbox' | 'toast';
  wrapperWidth?: BoxProps['w']; // Width for the toast content in this context
  'data-testid'?: string;
};

/** Notification Toast display for Inbox-managed items */
export const NotificationAppInboxToast = ({
  'data-testid': dataTestId,
  id: toastId,
  wrapperWidth,
  variant,
  onToastClick,
  onRemove,
  title,
  description,
  timeStamp,
  boxProps,
}: PropsWithChildren<NotificationAppInboxToastProps & { boxProps?: Omit<BoxProps, 'onClick'> }>) => {
  const theme = useTheme();
  const handleClick: React.MouseEventHandler<HTMLElement> = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      onToastClick?.(toastId);
    },
    [onToastClick, toastId]
  );
  const handleRemove: React.MouseEventHandler<HTMLElement> = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      onRemove?.(toastId);
    },
    [onRemove, toastId]
  );
  return (
    <NotificationAppInboxWrapper
      {...boxProps}
      data-testid={dataTestId}
      size="Default"
      variant={variant}
      w={wrapperWidth ? parseDimension(theme, wrapperWidth) : '100%'}
      onClick={handleClick}
    >
      <VStack gap="spacing4" alignItems="flex-start" w="100%" fontSize={theme.fontSizeSm} lineHeight="16px">
        <HStack
          data-testid="title-row"
          justifyContent="stretch"
          alignItems="center"
          w="100%"
          display="flex"
          gap="spacing4"
        >
          <NotificationInboxIcon notification={variant} />
          <EllipsisBoxWithTooltip
            outerBoxProps={{ flex: 1 }}
            alignItems="center"
            justifyContent="flex-start"
            wordBreak="break-all"
            fontWeight={theme.fontWeightMedium}
          >
            {title}
          </EllipsisBoxWithTooltip>
          <HStack color="colors.gray['090']" gap="spacing2">
            <Text>{formatToastDate(timeStamp)}</Text>
            <Menu
              triggerRound={false}
              menuPopoverZIndex="globalToastPopover"
              menuButtonIconProps={{
                // override the width, since the default width is measured by the height of the icon
                width: `${theme.spacing4}px`,
                height: `${theme.spacing16}px`,
                color: theme.colors.gray['090'],
              }}
            >
              <MenuItem onClick={() => logger.info('Notification settings')}>Notification Settings</MenuItem>
            </Menu>
            <Icon onClick={handleRemove} icon={IconName.Close} size="fontSizeSmall" spaceBefore="auto" />
          </HStack>
        </HStack>
        {description && (
          <EllipsisBoxWithTooltip data-testid="description" w="100%" color="colors.gray.090" lineClamp={2}>
            {description}
          </EllipsisBoxWithTooltip>
        )}
      </VStack>
    </NotificationAppInboxWrapper>
  );
};

const NotificationInboxIcon = ({
  notification = NotificationAppInboxVariants.Default,
}: {
  notification?: ValueOf<typeof NotificationAppInboxVariants>;
}) => {
  return (
    <Flex alignItems="center">
      <Switch value={notification}>
        <Case value={NotificationAppInboxVariants.Default}>
          <Icon icon={IconName.InformationCircle} />
        </Case>
        <Case value={NotificationAppInboxVariants.Confirmed}>
          <Icon color="colors.green.lighten" icon={IconName.CheckCircleSolid} />
        </Case>
        <Case value={NotificationAppInboxVariants.MediumPriority}>
          <Icon color="colors.yellow.lighten" icon={IconName.ExclamationSolid} />
        </Case>
        <Case value={NotificationAppInboxVariants.HighPriority}>
          <Icon color="colors.red.lighten" icon={IconName.ExclamationSolid} />
        </Case>
      </Switch>
    </Flex>
  );
};

function formatToastDate(timeStamp: string): string {
  const date = new Date(timeStamp);
  if (!isToday(date)) {
    return format(date, 'dd MMM');
  }
  return format(date, 'H:mm:ss');
}

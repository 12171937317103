import Big, { type BigSource } from 'big.js';
import { cloneDeep, groupBy, isNil, map } from 'lodash-es';
import type { DefaultTheme } from 'styled-components';
import { OrderStatusText } from '../components/Status/types';
import type { CustomerExecutionReport } from '../types/CustomerExecutionReport';
import type { CustomerOrder } from '../types/CustomerOrder';
import type { ExecutionReport } from '../types/ExecutionReport';
import { ManualStrategy } from '../types/ManualStrategy';
import type { Order } from '../types/Order';
import type { Security } from '../types/Security';
import {
  DecisionStatusEnum,
  MultilegReportingTypeEnum,
  OrderMarketStatusEnum,
  SideEnum,
  UnifiedLiquidityEnum,
  type IOMSExecutionReport4203LegSummary,
  type IOMSExecutionReport4203Markets,
} from '../types/types';
import { isOrderComplete } from './isOrderComplete';
import { isOrderPending } from './isOrderPending';
import { toBigWithDefault } from './number';
import { isPerpetualSwap } from './security';
import type { RequiredProperties } from './types';

// If this is a counter currency order, use the MinAmtIncrement if it's provided, otherwise, fall
// back to the MinPriceIncrement field. If it's not counter currency, use MinSizeIncrement.
export const getEffectiveMinIncrement = (security: Security, isCcy: boolean) => {
  return isCcy ? security.MinAmtIncrement || security.MinPriceIncrement : security.MinSizeIncrement;
};

export const isCounterCurrency = (currency?: string, security?: Security): boolean => {
  return currency && security ? currency === security.QuoteCurrency : false;
};

export const getOrdersForModify = (orders: Order[]): Order[] => {
  return orders.filter(
    order =>
      !isOrderComplete(order.OrdStatus) &&
      (order.DecisionStatus === DecisionStatusEnum.Staged || order.Strategy != null)
  );
};

export const isCustomerOrderModifiable = (order: CustomerOrder | CustomerExecutionReport): boolean => {
  const canModify = !(isOrderPending(order.OrdStatus) || isOrderComplete(order.OrdStatus));
  const customerPropertiesLoaded =
    !!order.MarketAccount && !!order.OrderQty && !!order.Price && !!order.PricingParameters && !!order.Strategy;

  return canModify && customerPropertiesLoaded;
};

/**
 * Check if CustomerOrder is resubmittable from a whitelabel context.
 */
export const WLCanResubmitCustomerOrder = (order: Pick<CustomerOrder, 'Strategy' | 'OrdStatus'>): boolean => {
  if (!order.Strategy || order.Strategy === ManualStrategy.Name) {
    return false;
  }
  return true;
};
/**
 * Check if CustomerOrder is modifiable from a whitelabel context.
 */
export const WLCanModifyCustomerOrder = (order: Pick<CustomerOrder, 'Strategy' | 'OrdStatus' | 'RFQID'>): boolean => {
  if (order.Strategy === ManualStrategy.Name) {
    return false;
  }
  if (isOrderComplete(order.OrdStatus)) {
    return false;
  }
  // cannot modify rfqs
  if (order.RFQID) {
    return false;
  }
  return true;
};

export const orderStatusTextToColor = (status: OrderStatusText | undefined, theme?: DefaultTheme | null) => {
  if (theme == null) {
    return undefined;
  }
  const { colorOrderStatusFilled, colorTextDefault, colorOrderStatusRejected } = theme;
  switch (status) {
    case OrderStatusText.Filled:
    case OrderStatusText.FilledAndCanceled:
      return colorOrderStatusFilled;
    case OrderStatusText.Rejected:
      return colorOrderStatusRejected;
    case OrderStatusText.Canceled:
    case OrderStatusText.Paused:
    case OrderStatusText.Staged:
      return colorTextDefault;
    default:
      return theme.colors.blue.lighten;
  }
};

export const getOppositeSide = (side: SideEnum): SideEnum => {
  switch (side) {
    case SideEnum.Buy:
      return SideEnum.Sell;
    case SideEnum.Sell:
      return SideEnum.Buy;
  }
};

export const orderHasLegSummary = (
  order: Order
): order is RequiredProperties<Order, 'LegSummary' | 'legSummaryLegs' | 'legSummaryParent'> =>
  Array.isArray(order.LegSummary);

export const legSummaryHasUnhedgedAmt = (
  legSummary: IOMSExecutionReport4203LegSummary
): legSummary is RequiredProperties<IOMSExecutionReport4203LegSummary, 'UnhedgedAmt'> =>
  typeof legSummary.UnhedgedAmt === 'string';

export function isEntityRFQ<T extends { RFQID?: string }>(
  entity: T | undefined
): entity is RequiredProperties<T, 'RFQID'> {
  return !isNil(entity?.RFQID);
}

/**
 * Given an order-like object with Markets, returns list of traded on symbols, and symbols on enabled markets.
 */
export const getSymbolsAcrossMarkets = (order?: Partial<Pick<Order, 'Markets' | 'LegSummary'>>): string[] => {
  if (order == null || order.Markets == null) {
    return [];
  }
  const symbolsSet = new Set<string>();
  for (const market of order.Markets) {
    const legSummary = order.LegSummary?.find(leg => leg.LegIndex === market.LegIndex);
    if (
      market.Symbol &&
      (market.MarketStatus !== OrderMarketStatusEnum.Disabled || Big(market.CumQty || 0).gt(0)) &&
      (legSummary == null || legSummary.MultilegReportingType === MultilegReportingTypeEnum.Parent)
    ) {
      symbolsSet.add(market.Symbol);
    }
  }
  return Array.from(symbolsSet);
};

type BareMinimumMarket = RequiredProperties<
  Partial<
    Pick<
      IOMSExecutionReport4203Markets,
      | 'Symbol'
      | 'LeavesQty'
      | 'CumAmt'
      | 'CumFee'
      | 'FeeCurrency'
      | 'CumQty'
      | 'AvgPx'
      | 'AvgPxAllIn'
      | 'Market'
      | 'MarketAccount'
      | 'Price'
      | 'LastPx'
      | 'LegIndex'
    >
  >,
  'Symbol' | 'Market'
>;

/**
 * Helper function which given IOMSExecutionReport4203Market[] (that is assumed to have the same symbol)
 * summarizes quantities and prices into one IOMSExecutionReport4203Market.
 * AvgPx and AvgPxAllIn is calculated as vwap.
 */
export const netMarketsToSingleMarket = function <T extends BareMinimumMarket>(
  order: Order | ExecutionReport,
  securitiesBySymbol: Map<string, Security>,
  markets: T[]
): T | null {
  if (markets.length === 1) {
    return markets[0];
  }
  return getVWAPFromMarkets(order, securitiesBySymbol, markets, 'Market')?.[0] || null;
};

export const getVWAPFromMarkets = function <T extends BareMinimumMarket>(
  order: Order | ExecutionReport,
  securitiesBySymbol: Map<string, Security>,
  markets: T[],
  groupByKey: 'Symbol' | 'Market' | null
): T[] {
  if (groupByKey === null) {
    return markets;
  }
  const isOrderUnifiedLiquidity = isUnifiedLiquidityOrder(order);
  const representativeMarket: T[] = map(
    groupBy(markets, m => getVWAPGroupBy(m, order, securitiesBySymbol, groupByKey))
  ).map(groupedMarkets => {
    const nettedValues = groupedMarkets.reduce(
      (acc, curr) => {
        // UI-3136 - Filled/Open values in Analytics need to be normalised
        const marketSecurity = securitiesBySymbol.get(curr.Symbol!);
        let cumQtyBig = toBigWithDefault(curr.CumQty, 0);
        let leavesQtyBig = toBigWithDefault(curr.LeavesQty, 0);
        const notionalMultiplierBig = toBigWithDefault(marketSecurity?.NotionalMultiplier, 1);
        if (marketSecurity && isOrderUnifiedLiquidity && isPerpetualSwap(marketSecurity)) {
          cumQtyBig = cumQtyBig.times(notionalMultiplierBig);
          leavesQtyBig = leavesQtyBig.times(notionalMultiplierBig);
        }

        return {
          LeavesQty: Big(acc.LeavesQty || 0).plus(leavesQtyBig),
          CumQty: Big(acc.CumQty || 0).plus(cumQtyBig),
          AvgPx: Big(acc.AvgPx || 0).plus(Big(curr.AvgPx || 0).times(cumQtyBig)),
          AvgPxAllIn: Big(acc.AvgPxAllIn || 0).plus(Big(curr.AvgPxAllIn || 0).times(cumQtyBig)),
          CumFee: Big(acc.CumFee || 0).plus(curr.CumFee || 0),
          CumAmt: Big(acc.CumAmt || 0).plus(curr.CumAmt || 0),
          LastPx: Big(acc.LastPx || 0).plus(curr.LastPx || 0),
          Price: Big(acc.Price || 0).plus(Big(curr.Price || 0).times(curr.LeavesQty || 0)),
        };
      },
      {
        LeavesQty: 0 as BigSource,
        CumQty: 0 as BigSource,
        AvgPx: 0 as BigSource,
        AvgPxAllIn: 0 as BigSource,
        CumFee: 0 as BigSource,
        CumAmt: 0 as BigSource,
        LastPx: 0 as BigSource,
        Price: 0 as BigSource,
      }
    );

    // This will be the market which we summarize onto.
    const marketExecReport = cloneDeep(groupedMarkets[0] || {});
    marketExecReport.LeavesQty = Big(nettedValues.LeavesQty).toFixed();
    marketExecReport.CumQty = Big(nettedValues.CumQty).toFixed();
    marketExecReport.CumFee = Big(nettedValues.CumFee).toFixed();
    marketExecReport.CumAmt = Big(nettedValues.CumAmt).toFixed();
    marketExecReport.LastPx = Big(nettedValues.LastPx)
      .div(groupedMarkets.length || 1)
      .toFixed();
    // CumQty
    marketExecReport.AvgPx = Big(nettedValues.AvgPx)
      .div(Big(nettedValues.CumQty).gt(0) ? nettedValues.CumQty : 1)
      .toFixed();
    marketExecReport.AvgPxAllIn = Big(nettedValues.AvgPxAllIn)
      .div(Big(nettedValues.CumQty).gt(0) ? nettedValues.CumQty : 1)
      .toFixed();
    // LeavesQty
    marketExecReport.Price = Big(nettedValues.Price)
      .div(Big(nettedValues.LeavesQty).gt(0) ? nettedValues.LeavesQty : 1)
      .toFixed();

    return { ...marketExecReport };
  });

  return representativeMarket;
};

export function isUnifiedLiquidityOrder(order: Order | ExecutionReport): boolean {
  return order.Parameters?.UnifiedLiquidity === UnifiedLiquidityEnum.Enabled;
}

/*
 *  Gets grouping key based on desired groupByKey order being unified perp order for groupByKey == 'Market'
 */
export function getVWAPGroupBy<T extends BareMinimumMarket>(
  m: T,
  order: Order | ExecutionReport,
  securitiesBySymbol: Map<string, Security>,
  groupByKey: 'Symbol' | 'Market'
) {
  const orderSecurity = securitiesBySymbol.get(order.Symbol);
  switch (groupByKey) {
    case 'Symbol':
      return m.Symbol;
    case 'Market': {
      if (isPerpetualSwap(orderSecurity) && isUnifiedLiquidityOrder(order)) {
        return `${m.Market}-${m.Symbol}`;
      }
      return m.Market;
    }
    default:
      return groupByKey;
  }
}

export enum OMSView {
  Cards = 'CARDS_VIEW',
  ManualTradeForm = 'MANUAL_TRADE_FORM_VIEW',
  NewOrderForm = 'NEW_ORDER_FORM_VIEW',
  OrderImport = 'ORDER_IMPORT_VIEW',
  RFQForm = 'RFQ_FORM_VIEW',
  SalesRFQForm = 'SALES_RFQ_FORM_VIEW',
  SalesOrder = 'SALES_ORDER_FORM_VIEW',
  ManualOrderFillForm = 'MANUAL_ORDER_FILL_FORM_VIEW',
  ManualRFQPricingForm = 'MANUAL_RFQ_PRICING_FORM',
  DetailsDrawer = 'DETAILS_DRAWER',
  CareOrderForm = 'CARE_ORDER_FORM_VIEW',
  NotificationsInbox = 'NOTIFICATIONS_INBOX_VIEW',
}

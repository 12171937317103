import { isEqual } from 'lodash-es';
import { createElement, createRef, useEffect, useState, type RefObject } from 'react';
import { defineMessages } from 'react-intl';
import { FixedSizeList } from 'react-window';
import { useTheme } from 'styled-components';
import { AccordionGroup } from '../../Accordion';
import { Box, HStack } from '../../Core';
import { IndicatorBadge } from '../../IndicatorBadge';
import { FormattedMessage } from '../../Intl';
import { Text } from '../../Text';
import { NoResults, Result, Results, Title } from '../AutocompleteDropdown/styles';
import { IGNORED_AUTOCOMPLETE_GROUP } from './tokens';
import type { AutocompleteGroup, AutocompleteResultsProps, FuseSearchResult } from './types';

const messages = defineMessages({
  noResultsFound: {
    defaultMessage: 'No results found',
    id: 'Form.Autocomplete.noResultsFound',
  },
});

// We can add more to this state at a later point. Like isOpen state once we have time to implement that properly
interface DropdownState {
  listRef: RefObject<FixedSizeList<any>>;
}

export const AutocompleteResults = <T,>({
  isOpen,
  searchResultGroups,
  highlightedIndex,
  getItemProps,
  size,
  itemSize = 40, // 2 rows
  maxHeight = 200,
  groupMaxHeight = 200,
  renderResult,
  selectedItem,
  selectedGroup,
  resultsRef,
  equalityChecker = isEqual,
  RenderGroupHeader = AutoCompleteDropdownGroupHeader,
  renderEmpty = RenderEmpty,
  showBorderSelectionIndicator,
}: AutocompleteResultsProps<T>) => {
  const showGroupHeaders = selectedGroup == null;

  // a mapping for group -> dropdown state to store FixedSizeList ref and isOpen state
  const [state, setState] = useState<Map<string, DropdownState>>(new Map());

  // Whenever new groups come in always make sure they're given a ref.
  useEffect(() => {
    setState(currState => {
      const newGroupsWithoutstate = searchResultGroups.filter(group => !currState.has(group.group));
      for (const newGroup of newGroupsWithoutstate) {
        currState.set(newGroup.group, { listRef: createRef() });
      }

      return new Map(currState);
    });
  }, [searchResultGroups]);

  useEffect(() => {
    const groupAndIndex = getGroupAndGroupIndexFromGlobalIndex(searchResultGroups, highlightedIndex);
    if (!groupAndIndex) {
      return;
    }

    const groupState = state.get(groupAndIndex.group);
    if (!groupState) {
      return;
    }

    groupState.listRef.current?.scrollToItem(groupAndIndex.groupIndex);
  }, [highlightedIndex, state, searchResultGroups]);

  return (
    <AccordionGroup>
      <Box ref={resultsRef}>
        {isOpen && (
          <Results maxHeight={maxHeight} data-testid="dropdown-results">
            {searchResultGroups.length > 0
              ? searchResultGroups.reduce((output, group, groupIdx) => {
                  const groupState = state.get(group.group);

                  if (!groupState) {
                    return output;
                  }

                  const showHeader = group.group !== IGNORED_AUTOCOMPLETE_GROUP && showGroupHeaders;

                  output.push(
                    <Box key={group.group}>
                      {showHeader && <RenderGroupHeader group={group} />}
                      <FixedSizeList
                        width="auto"
                        height={Math.min(groupMaxHeight, group.items.length * itemSize)}
                        itemCount={group.items.length}
                        itemSize={itemSize}
                        ref={groupState.listRef}
                      >
                        {({ index, style }) => {
                          const result = group.items[index];
                          const globalItemIndex = getGlobalIndex(searchResultGroups, groupIdx, index);

                          const isDisabled = result.item.disabled;
                          const dataItem = result.item.item;
                          const isSelected = equalityChecker(dataItem, selectedItem ?? undefined);
                          const key =
                            (typeof dataItem === 'object' && (dataItem as { key?: string })?.key) ||
                            `${groupIdx}${index}`; // might be a performance problem to have this based on itemIndex
                          return (
                            <Result
                              key={key}
                              size={size}
                              isSelected={isSelected}
                              isHighlighted={highlightedIndex === globalItemIndex}
                              data-testid="dropdown-result"
                              disabled={isDisabled}
                              selectionIndicator={showBorderSelectionIndicator}
                              {...getItemProps({
                                item: dataItem,
                                index: globalItemIndex,
                                style,
                                'aria-selected': isSelected,
                              })}
                            >
                              {renderResult(result, isDisabled)}
                            </Result>
                          );
                        }}
                      </FixedSizeList>
                    </Box>
                  );

                  return output;
                }, [] as any[]) // <--- Here's the init array for the reducer
              : createElement(renderEmpty)}
          </Results>
        )}
      </Box>
    </AccordionGroup>
  );
};

function getGlobalIndex<T>(groups: AutocompleteGroup<FuseSearchResult<T>>[], groupIdx: number, itemIdx: number) {
  let idx = 0;
  for (let i = 0; i < groupIdx; i++) {
    idx += groups[i].items.length;
  }
  return idx + itemIdx;
}

function getGroupAndGroupIndexFromGlobalIndex<T>(
  groups: AutocompleteGroup<FuseSearchResult<T>>[],
  globalIndex: number
) {
  let count = 0;

  for (const group of groups) {
    count += group.items.length;
    const indexOfLastItemThisFar = count - 1;
    if (globalIndex <= indexOfLastItemThisFar) {
      const itemsBetweenGlobalIndexAndLastIndexInGroup = indexOfLastItemThisFar - globalIndex;
      const groupIndex = group.items.length - 1 - itemsBetweenGlobalIndexAndLastIndexInGroup;

      return { group: group.group, groupIndex };
    }
  }

  return undefined;
}

export function AutoCompleteDropdownGroupHeader<T>({
  group,
  showCount = true,
}: {
  group: AutocompleteGroup<FuseSearchResult<T>>;
  showCount?: boolean;
}) {
  const theme = useTheme();
  return (
    <HStack
      gap="spacingSmall"
      justifyContent="flex-start"
      background="backgroundDropdownGroupHeader"
      py="spacingSmall"
      px="spacingComfortable"
      fontSize="fontSizeTiny"
      borderBottom={`solid 1px ${theme.backgroundDropdownResults}`}
    >
      <Text size="fontSizeTiny" textTransform="uppercase" style={{ fontWeight: 500 }}>
        {group.group}
      </Text>
      {showCount && <IndicatorBadge children={group.items?.length} />}
    </HStack>
  );
}

function RenderEmpty() {
  return (
    <NoResults data-testid="dropdown-no-results">
      <Title>
        <FormattedMessage {...messages.noResultsFound} />
      </Title>
    </NoResults>
  );
}

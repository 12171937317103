import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  ACTION,
  Button,
  ButtonVariants,
  FormRowStatus,
  FormTable,
  IconName,
  LocalFilterInput,
  NotificationVariants,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  Tooltip,
  promisesHaveResolved,
  useFormTable,
  useGlobalToasts,
  useMarketAccountsContext,
  type AgGridCurrencyParams,
  type Column,
  type ColumnDef,
  type Currency,
  type FormRow,
  type RequiredProperties,
  type SizeColumnParams,
} from '@talos/kyoko';

import type { CounterpartyColumnParams } from '@talos/kyoko/src/components/BlotterTable/columns/counterparty';
import type { MarketAccountColumnParams } from '@talos/kyoko/src/components/BlotterTable/columns/marketAccount';
import { useFeatureFlag, useRoleAuth } from 'hooks';
import { useCustomers } from 'hooks/useCustomer';
import { useBlotterState } from 'providers/AppConfigProvider';
import { OrgConfigurationKey, useOrgConfiguration } from '../../../../providers';
import type { CustomerCreditLEGACY } from './CustomerCredit';
import { getCustomerCreditRowID, useCustomerCreditLEGACY } from './CustomerCreditProvider';

/**
 * @deprecated
 */
export const DEFAULT_CUSTOMER_CREDIT_CURRENCIES = new Map<
  string,
  RequiredProperties<Partial<Currency>, 'Symbol' | 'Description'>
>([
  ['BTC', { Symbol: 'BTC', Description: '' }],
  ['CAD', { Symbol: 'CAD', Description: '' }],
  ['CHF', { Symbol: 'CHF', Description: '' }],
  ['ETH', { Symbol: 'ETH', Description: '' }],
  ['EUR', { Symbol: 'EUR', Description: '' }],
  ['USD', { Symbol: 'USD', Description: '' }],
  ['USDC', { Symbol: 'USDC', Description: '' }],
  ['USDT', { Symbol: 'USDT', Description: '' }],
]);

function useCustomerCreditUtilizationColumns(
  getIsCustomerDisabled: (customerName: string) => boolean,
  getIsMarketAccountDisabled: (name: string) => boolean
): Column[] {
  const { enableCreditLimitsPerMarketAccount } = useFeatureFlag();
  const { getConfig } = useOrgConfiguration();

  const customers = useCustomers();
  const customerCreditCurrencies = useMemo(() => {
    const additionalCreditCurrencies = getConfig(OrgConfigurationKey.AdditionalCustomerCreditCurrencies, '')
      .split(',')
      .map(currency => currency.trim())
      .filter(currency => !!currency); // guard against trailing commas leading to empty strings

    const creditCurrencyMap = new Map(DEFAULT_CUSTOMER_CREDIT_CURRENCIES);

    for (const currency of additionalCreditCurrencies) {
      creditCurrencyMap.set(currency, { Symbol: currency, Description: '' });
    }

    return creditCurrencyMap;
  }, [getConfig]);

  return useMemo<ColumnDef<CustomerCreditLEGACY>[]>(
    () => [
      {
        type: 'counterparty',
        field: 'Counterparty',
        title: 'Customer',
        editable: ({ node }) => node.data.formRow.status === FormRowStatus.Added,
        params: {
          counterparties: customers,
          isItemDisabled: getIsCustomerDisabled,
          resetsMarketAccount: true,
        } satisfies CounterpartyColumnParams,
      },
      {
        type: 'marketAccountSourceAccountID',
        field: 'MarketAccount',
        editable: ({ node }) => node.data.formRow.status === FormRowStatus.Added,
        params: {
          isItemDisabled: enableCreditLimitsPerMarketAccount ? getIsMarketAccountDisabled : undefined,
          requiresCounterparty: true,
        } satisfies MarketAccountColumnParams,
      },
      {
        type: 'size',
        field: 'ExposureLimit',
        title: 'Credit Limit',
        params: { currencyField: 'CreditCurrency' } satisfies SizeColumnParams<CustomerCreditLEGACY>,
        editable: true,
      },
      {
        type: 'size',
        field: 'Exposure',
        title: 'Credit Exposure',
        width: 250,
        editable: false,
        params: { currencyField: 'ExposureCurrency' } satisfies SizeColumnParams<CustomerCreditLEGACY>,
      },
      {
        type: 'exposureUtilization',
        title: 'Credit Exposure',
        id: 'utilization',
        width: 250,
      },
      {
        type: 'currency',
        // CreditCurrency here since it is the currency of the credit limit, not the exposure (from the websocket)
        field: 'CreditCurrency' as const,
        title: 'Credit Currency',
        params: {
          currencies: customerCreditCurrencies,
          showIcon: false,
          showDescription: false,
        },
        editable: true,
      } satisfies Column<AgGridCurrencyParams>,
      { type: 'filler', id: 'filler1' },
      { type: 'remove', id: 'remove', params: { allowOnlyForAddedRows: true } },
    ],
    [
      enableCreditLimitsPerMarketAccount,
      getIsCustomerDisabled,
      getIsMarketAccountDisabled,
      customerCreditCurrencies,
      customers,
    ]
  );
}

/**
 * @deprecated Use CustomerCredits instead
 */
export const CustomerCreditsLEGACY = () => {
  const { enableCreditLimitsPerMarketAccount } = useFeatureFlag();
  const { activeCustomerMarketAccountsByCounterpartyAndName, marketAccountsByName } = useMarketAccountsContext();
  const { add: addToast } = useGlobalToasts();
  const [isSaving, setIsSaving] = useState(false);
  const { isAuthorized } = useRoleAuth();

  const { filterValueCustomerCredit, setFilterValueCustomerCredit } = useBlotterState();

  const customerCreditService = useCustomerCreditLEGACY();
  const { customerCreditList, customerCreditByCounterpartyAndMarketAccount } = customerCreditService;
  const customers = useCustomers();

  // Compute the subset of customers we are yet to set any credit limit for.
  const selectableCustomerNames = useMemo(() => {
    if (!customers || !customerCreditList) {
      return new Set([]);
    }

    const customerNamesWithCreditLimit = new Set(customerCreditList.map(credit => credit.Counterparty));

    if (enableCreditLimitsPerMarketAccount) {
      return new Set(
        customers
          .filter(customer => {
            // Return true if the customer has no credit limits set yet, or if the
            // number of active credit limits in place are less than the number of
            // active market accounts the customer has access to.
            return (
              !customerNamesWithCreditLimit.has(customer.Name) ||
              (customerCreditByCounterpartyAndMarketAccount?.get(customer.Name)?.size ?? 0) <
                (activeCustomerMarketAccountsByCounterpartyAndName?.get(customer.Name)?.size ?? 0)
            );
          })
          .map(customer => customer.Name)
      );
    }

    return new Set(
      customers.filter(customer => !customerNamesWithCreditLimit.has(customer.Name)).map(customer => customer.Name)
    );
  }, [
    activeCustomerMarketAccountsByCounterpartyAndName,
    customerCreditByCounterpartyAndMarketAccount,
    customerCreditList,
    customers,
    enableCreditLimitsPerMarketAccount,
  ]);

  const getIsCustomerDisabled = useCallback(
    (customerName: string) => {
      return !selectableCustomerNames.has(customerName);
    },
    [selectableCustomerNames]
  );

  const getIsMarketAccountDisabled = useCallback(
    (name: string) => {
      const marketAccount = marketAccountsByName.get(name);
      if (marketAccount) {
        return customerCreditByCounterpartyAndMarketAccount?.get(marketAccount.Counterparty)?.has(name) ?? false;
      }

      return false;
    },
    [customerCreditByCounterpartyAndMarketAccount, marketAccountsByName]
  );

  const columns = useCustomerCreditUtilizationColumns(getIsCustomerDisabled, getIsMarketAccountDisabled);

  const formTable = useFormTable<CustomerCreditLEGACY>({
    rowID: 'RowID',
    data: customerCreditList ?? [],
    columns,
    quickSearchParams: {
      filterText: filterValueCustomerCredit,
    },
  });
  const { dirtyRows, getRow, isDirty } = formTable;

  const formIsValid = useValidateCreditForm({ dirtyRows: dirtyRows as Set<string>, getRow, isDirty });

  const handleAddCreditLimitButton = useCallback(() => {
    formTable.addRow({
      ExposureLimit: '',
      Exposure: '',
      Counterparty: '',
      ExposureCurrency: '',
      MarketAccount: '',
    });
  }, [formTable]);

  const createCustomerCreditLimit = useCallback(
    (row: FormRow<CustomerCreditLEGACY>) => {
      const { Counterparty, ExposureLimit, ExposureCurrency, CreditCurrency, MarketAccount } = row.data;
      // Recall, that CreditCurrency is the currency of the credit limit, not the exposure (from the websocket).
      // i.e. it is the currency that can be changed by the user.
      const currency = CreditCurrency || ExposureCurrency;
      return customerCreditService
        .createUpdateCreditLimit({ Counterparty, Qty: ExposureLimit, ExposureCurrency: currency, MarketAccount })
        .then(({ data }) => {
          row.setData({
            ...data[0],
            // Set the CreditCurrency to the returned ExposureCurrency, since that is the currency of the credit limit.
            CreditCurrency: data[0]?.ExposureCurrency,
            RowID: getCustomerCreditRowID(data[0]),
          });
          return data[0];
        })
        .catch((e: ErrorEvent) => {
          addToast({
            text: e?.toString() || `Could not add customer credit limit.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [addToast, customerCreditService]
  );

  const updateCustomerCreditLimit = useCallback(
    (row: FormRow<CustomerCreditLEGACY>) => {
      const { Exposure, Counterparty, ExposureLimit, ExposureCurrency, MarketAccount, CreditCurrency } = row.data;
      const newCurrency = CreditCurrency || ExposureCurrency;

      return customerCreditService
        .createUpdateCreditLimit({ Counterparty, Qty: ExposureLimit, ExposureCurrency: newCurrency, MarketAccount })
        .then(({ data }) => {
          if (newCurrency === row.data.ExposureCurrency) {
            // The REST response doesn't return Exposure, but we do already know it,
            // So just attach it to the returned data like this.
            row.setData({ ...row.data, ...data[0], Exposure, RowID: getCustomerCreditRowID(data[0]) });
          } else {
            // Since we are now dealing with a different currency, the Exposure value is invalid so we clear it (it will be updated by ws).
            row.setData({ ...row.data, ...data[0], Exposure: '' });
          }

          return data[0];
        })
        .catch((e: ErrorEvent) => {
          addToast({
            text: e?.toString() || `Could not update customer credit.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [addToast, customerCreditService]
  );

  const handleSave = useCallback(() => {
    setIsSaving(true);
    const rows = formTable.getRows();

    // If the user is adding several rows at a time we should sanity check the unique counterparty at least
    // before sending to server. Otherwise we get wonky behavior since these are parallell requests.
    const rowsToAdd = rows.filter(row => row.status === FormRowStatus.Added);
    const customersToAdd = rowsToAdd.map(rowToAdd => rowToAdd.data.Counterparty);
    const customersToAddSet = new Set(customersToAdd);
    const areAdditionsUnique = rowsToAdd.length === customersToAddSet.size;

    if (!areAdditionsUnique) {
      // Cant add >1 credit limit for the same customer.
      addToast({
        text: 'Can only add one credit limit per customer',
        variant: NotificationVariants.Negative,
      });
      setIsSaving(false);
      return;
    }

    const requests: Promise<any>[] = [];
    for (const row of rows) {
      switch (row.status) {
        case FormRowStatus.Added:
          requests.push(createCustomerCreditLimit(row));
          break;
        case FormRowStatus.Updated:
          requests.push(updateCustomerCreditLimit(row));
          break;
        default:
          break;
      }
    }
    Promise.allSettled(requests).then(promises => {
      setIsSaving(false);
      if (promisesHaveResolved(promises)) {
        addToast({
          text: 'Customer Credit saved.',
          variant: NotificationVariants.Positive,
        });
      } else {
        addToast({
          text: 'Customer Credit could not be saved.',
          variant: NotificationVariants.Negative,
        });
      }
    });
  }, [addToast, formTable, createCustomerCreditLimit, updateCustomerCreditLimit]);

  // Want to be able to use the formTable without depending on it in the useEffect directly below,
  // so put it in a ref and update the ref.current when the formtable updates
  const formTableRef = useRef(formTable);
  useEffect(() => {
    formTableRef.current = formTable;
  }, [formTable]);

  useEffect(() => {
    // customer credit changed over ws, let's apply all exposure values to the formtable untouched rows
    if (!formTableRef.current || !customerCreditList) {
      return;
    }

    for (const credit of customerCreditList) {
      const row = formTableRef.current.getRow(getCustomerCreditRowID(credit));
      if (row && row.status === FormRowStatus.None) {
        row.setData(credit);
      }
    }
  }, [customerCreditList]);

  const saveButton = (
    <Button
      variant={ButtonVariants.Primary}
      onClick={handleSave}
      disabled={isSaving || !formTable.isDirty || !formIsValid || !isAuthorized(ACTION.DEALER_TRADING)}
      data-testid="customer-credit-save-button"
    >
      Save
    </Button>
  );

  const headerActions = (
    <PanelActions>
      <LocalFilterInput
        placeholder="Filter by Customer or Currency"
        value={filterValueCustomerCredit}
        onChange={setFilterValueCustomerCredit}
      />
      <Button
        startIcon={IconName.Plus}
        onClick={handleAddCreditLimitButton}
        disabled={selectableCustomerNames.size === 0 || !isAuthorized(ACTION.DEALER_TRADING)}
        variant={ButtonVariants.Positive}
      >
        Add Credit Limit
      </Button>
      {formIsValid ? (
        saveButton
      ) : (
        <Tooltip placement="bottom" tooltip="Credit Currency is Required">
          {saveButton}
        </Tooltip>
      )}
    </PanelActions>
  );

  return (
    <Panel>
      <PanelHeader>
        <h2>Customer Credit</h2>
        {headerActions}
      </PanelHeader>
      <PanelContent>{customerCreditList && customers && <FormTable {...formTable} />}</PanelContent>
    </Panel>
  );
};

function useValidateCreditForm({
  dirtyRows,
  getRow,
  isDirty,
}: {
  dirtyRows: Set<string>;
  getRow: (id: string) => FormRow<CustomerCreditLEGACY> | undefined;
  isDirty: boolean;
}): boolean {
  return useMemo(() => {
    // If the form isn't dirty, it's automatically valid, else check the dirtyRows
    return (
      !isDirty ||
      [...dirtyRows].reduce((acc, dirtyRowId) => {
        // If acc is false, we've already failed a row check, continue returning false,
        // else validate that we have a non-falsy CreditCurrency
        if (!acc) {
          return acc;
        }

        const dirtyRow = getRow(dirtyRowId);
        return !!(dirtyRow?.data.CreditCurrency || dirtyRow?.data.ExposureCurrency);
      }, true)
    );
  }, [dirtyRows, getRow, isDirty]);
}

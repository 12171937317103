import {
  EMPTY_ARRAY,
  EMPTY_MAP,
  FIXING_INDEX,
  MARKET_FIXING_INDEX,
  useObservableValue,
  useStaticSubscription,
  wsScanToMap,
  type FixingIndex,
  type MarketFixingIndex,
  type SubscriptionResponse,
} from '@talos/kyoko';
import { compact, uniq } from 'lodash-es';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { scan, shareReplay } from 'rxjs/operators';
import { useFeatureFlag } from '../hooks/useFeatureFlag';

export type IFixingIndexContext = {
  fixingIndicesList: FixingIndex[];
  fixingIndicesByName: Map<string, FixingIndex>;
  fixingIndicesBySymbol: Map<string, FixingIndex[]>;
  marketsByFixingIndex: Map<string, string[]>;
  isLoaded: boolean;
};

export const FixingIndexContext = createContext<IFixingIndexContext | undefined>(undefined);

export function useFixingIndices() {
  const context = useContext(FixingIndexContext);
  if (!context) {
    throw new Error('useFixingIndexContext must be used within a FixingIndexProvider');
  }
  return context;
}

export function FixingIndexProvider({ children }: PropsWithChildren) {
  const fixingIndexSubscription = useStaticSubscription<SubscriptionResponse<FixingIndex>>({
    name: FIXING_INDEX,
    tag: 'FixingIndexProvider',
  });
  const marketFixingIndexSubscription = useStaticSubscription<SubscriptionResponse<MarketFixingIndex>>({
    name: MARKET_FIXING_INDEX,
    tag: 'FixingIndexProvider',
  });

  const { spreadToFixingSymbolIndexesMap } = useFeatureFlag();

  const fixingIndicesByName = useObservableValue(
    () =>
      fixingIndexSubscription.data.pipe(
        wsScanToMap({ getUniqueKey: d => d.Name, newMapEachUpdate: true }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [fixingIndexSubscription.data]
  );

  const marketsByFixingIndex = useObservableValue(
    () =>
      marketFixingIndexSubscription.data.pipe(
        scan((acc, json) => {
          if (json.data == null) {
            return acc;
          }
          const map = new Map(acc);
          if (json.initial) {
            map.clear();
          }
          for (const marketFixingIndex of json.data) {
            const markets = uniq([...(map.get(marketFixingIndex.Index) ?? []), marketFixingIndex.Market]);
            map.set(marketFixingIndex.Index, markets);
          }
          return map;
        }, new Map()),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketFixingIndexSubscription.data]
  );

  const fixingIndicesList = useMemo(
    () => (fixingIndicesByName == null ? undefined : [...fixingIndicesByName.values()]),
    [fixingIndicesByName]
  );

  // TODO fhqvst As the back-end isn't sure how to best do this mapping, we're cheating with an org config
  // The value of this org config is a Record<string, string[]> that we parse into a Map<string, FixingIndex[]>
  const fixingIndicesBySymbol: Map<string, FixingIndex[]> | undefined = useMemo(() => {
    if (spreadToFixingSymbolIndexesMap == null || fixingIndicesByName == null) {
      return undefined;
    }
    try {
      const parsed = JSON.parse(spreadToFixingSymbolIndexesMap) as Record<string, string[]>;
      const fixingIndicesBySymbol = new Map();
      for (const [symbol, fixingIndexNames] of Object.entries(parsed)) {
        fixingIndicesBySymbol.set(symbol, compact(fixingIndexNames.map(name => fixingIndicesByName.get(name))));
      }
      return fixingIndicesBySymbol;
    } catch (e) {
      return EMPTY_MAP;
    }
  }, [spreadToFixingSymbolIndexesMap, fixingIndicesByName]);

  const isLoaded = useMemo(
    () =>
      fixingIndicesList != null &&
      fixingIndicesByName != null &&
      fixingIndicesBySymbol != null &&
      marketsByFixingIndex != null,
    [fixingIndicesList, fixingIndicesByName, fixingIndicesBySymbol, marketsByFixingIndex]
  );

  const value = useMemo(
    () => ({
      fixingIndicesList: fixingIndicesList ?? EMPTY_ARRAY,
      fixingIndicesByName: fixingIndicesByName ?? EMPTY_MAP,
      fixingIndicesBySymbol: fixingIndicesBySymbol ?? EMPTY_MAP,
      marketsByFixingIndex: marketsByFixingIndex ?? EMPTY_MAP,
      isLoaded,
    }),
    [fixingIndicesList, fixingIndicesByName, fixingIndicesBySymbol, marketsByFixingIndex, isLoaded]
  );

  return <FixingIndexContext.Provider value={value}>{children}</FixingIndexContext.Provider>;
}

import {
  ACTION,
  Box,
  Checkbox,
  Dialog,
  Flex,
  HelpIcon,
  Input,
  MarketFeeModeEnum,
  SearchSelect,
  Text,
  bpsToPercent,
  percentToBps,
  useCurrenciesContext,
  useMarketsContext,
  type DialogProps,
  type ISubaccount,
  type PricingRule,
} from '@talos/kyoko';
import { first, identity } from 'lodash-es';

import { usePricingRules, useSubAccounts } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SectionTitle } from '../../../components/SectionTitle';
import { useFeatureFlag, useRoleAuth } from '../../../hooks';
import { DEFAULT_CUSTOMER_CREDITS_CURRENCIES } from '../CustomerCredits/utils';
import { DEALER_FEE_MODE_OPTIONS } from '../utils';

enum InputTypeEnum {
  BPS,
  CHECKBOX,
  FEE,
  SELECT_SUBACCOUNT,
  SELECT_CROSS_CURRENCY,
  SELECT_CROSS_MARKET,
  SELECT_FEE_MODE,
  SELECT_FEE_CURRENCY,
  TEXT,
}

interface DefaultOptionItem {
  key: keyof PricingRule;
  displayName: string;
  type: InputTypeEnum;
  helpText?: string;
  selectOptions?: readonly string[];
}

interface DefaultOption {
  title: string;
  itemsPerRow: readonly number[];
  items: readonly DefaultOptionItem[];
}

function useDefaultPricingRulesOptions(): DefaultOption[] {
  const { pricingRuleAllowedCrossMarkets } = useFeatureFlag();

  return useMemo<DefaultOption[]>(
    () => [
      {
        title: 'Sub Accounts',
        itemsPerRow: [2],
        items: [
          {
            key: 'TradeSubAccount',
            displayName: 'Trade Sub Account',
            type: InputTypeEnum.SELECT_SUBACCOUNT,
          },
          {
            key: 'HedgeSubAccount',
            displayName: 'Hedge Sub Account',
            type: InputTypeEnum.SELECT_SUBACCOUNT,
          },
        ],
      },
      {
        title: 'Spread and Commissions',
        itemsPerRow: [3, 1],
        items: [
          { key: 'BidSpread', displayName: 'Bid Spread (BPS)', type: InputTypeEnum.BPS },
          { key: 'OfferSpread', displayName: 'Offer Spread (BPS)', type: InputTypeEnum.BPS },
          { key: 'AcceptPriceLeniency', displayName: 'Accept Price Leniency (BPS)', type: InputTypeEnum.BPS },
          { key: 'SalesCommission', displayName: 'Commission', type: InputTypeEnum.BPS },
        ],
      },
      {
        title: 'Post Trade Fees',
        itemsPerRow: [3],
        items: [
          {
            key: 'FeeMode',
            displayName: 'Fee Mode',
            helpText: 'How fees should be applied to trades. If unset there is no per trade fee applied.',
            type: InputTypeEnum.SELECT_FEE_MODE,
            selectOptions: DEALER_FEE_MODE_OPTIONS,
          },
          {
            key: 'FeeCurrency',
            displayName: 'Fee Currency',
            type: InputTypeEnum.SELECT_FEE_CURRENCY,
            selectOptions: DEFAULT_CUSTOMER_CREDITS_CURRENCIES.map(c => c.Symbol),
          },
          { key: 'Fee', displayName: 'Fee', type: InputTypeEnum.FEE },
        ],
      },
      {
        title: 'Time & Duration',
        itemsPerRow: [3, 2],
        items: [
          { key: 'QuoteTTL', displayName: 'Quote TTL', type: InputTypeEnum.TEXT },
          { key: 'RFQTTL', displayName: 'RFQ TTL', type: InputTypeEnum.TEXT },
          { key: 'AcceptTimeLeniency', displayName: 'Accept Time Leniency', type: InputTypeEnum.TEXT },
          { key: 'PricesTimeout', displayName: 'Prices Timeout', type: InputTypeEnum.TEXT },
          { key: 'MinMarketDataThrottle', displayName: 'Market Data Throttle', type: InputTypeEnum.TEXT },
        ],
      },
      {
        title: 'Pricing',
        itemsPerRow: [2],
        items: [
          { key: 'PriceImprovementRatio', displayName: 'Price Improvement Ratio for RFQ', type: InputTypeEnum.TEXT },
          {
            key: 'OrderPriceImprovementRatio',
            displayName: 'Price Improvement Ratio for Orders',
            type: InputTypeEnum.TEXT,
          },
        ],
      },
      {
        title: 'Synthetic Crosses',
        itemsPerRow: [1, 2],
        items: [
          {
            key: '_AllowSyntheticCrosses',
            displayName: 'Enable Synthetic Crosses',
            type: InputTypeEnum.CHECKBOX,
            helpText:
              'The RFQ engine also supports pricing via Synthetic Crosses. When a currency pair is not supported by a dealer, a synthetic price can be generated by combining the prices of two legs while crossing through another intermediary currency.',
          },
          {
            key: 'AllowedCrossCurrencies',
            displayName: 'Cross Currency',
            type: InputTypeEnum.SELECT_CROSS_CURRENCY,
            selectOptions: ['USD', 'USDT', 'USDC', 'CHF', 'EUR', 'CAD', 'GBP'],
          },
          {
            key: 'AllowedCrossMarkets',
            displayName: 'Cross Market',
            type: InputTypeEnum.SELECT_CROSS_MARKET,
            selectOptions: pricingRuleAllowedCrossMarkets,
          },
        ],
      },
      {
        title: 'Misc',
        itemsPerRow: [1, 1],
        items: [
          {
            key: 'EnableSyntheticCounterCurrency',
            displayName: 'Enable Synthetic Counter Currency',
            type: InputTypeEnum.CHECKBOX,
          },
          {
            key: 'TakeProfitInQuoteCurrency',
            displayName: 'Take Profit in Quote Currency',
            type: InputTypeEnum.CHECKBOX,
            helpText:
              'When Take Profit in Quote Currency is enabled, profits will always be taken in the quote currency on counter-currency requests. When disabled, profit will be taken in the base currency on counter-currency requests',
          },
        ],
      },
    ],
    [pricingRuleAllowedCrossMarkets]
  );
}

interface EditDefaultPricingRuleDialogProps extends DialogProps {
  handleEditPricingRule: (data: PricingRule) => void;
}
const getSubAccountLabel = (sA: ISubaccount) => sA.Name ?? sA.DisplayName;

export const EditDefaultPricingRuleDialog = ({
  handleEditPricingRule,
  ...props
}: EditDefaultPricingRuleDialogProps) => {
  const { globalDefault } = usePricingRules();
  const { subAccountsByName, allSubAccountBooks } = useSubAccounts();
  const { currenciesBySymbol } = useCurrenciesContext();
  const { marketsByName } = useMarketsContext();
  const defaultOptions = useDefaultPricingRulesOptions();
  const { isAuthorized } = useRoleAuth();

  const normalizeGlobalDefault = useCallback(
    (globalDefault: PricingRule): PricingRule => {
      const data: PricingRule = { ...globalDefault };
      defaultOptions
        .map(option => option.items)
        .forEach(items => {
          items.forEach(({ type, key }) => {
            // For each item type BPS, apply the correct BPS/Percent conversion.
            if ([InputTypeEnum.BPS, InputTypeEnum.FEE].includes(type)) {
              Object.assign(data, { [key]: percentToBps(data[key] as string) });
            }
          });
        });
      // Manually set this UI checkbox to true if synthetic crosses are enabled
      if (globalDefault.AllowedCrossCurrencies || globalDefault.AllowedCrossMarkets) {
        data._AllowSyntheticCrosses = true;
      }
      return data;
    },
    [defaultOptions]
  );

  const [newGlobalPricingRule, setNewGlobalPricingRule] = useState<PricingRule>(normalizeGlobalDefault(globalDefault));

  // If the Global Pricing Rule updates when the dialog is closed, reset newGlobalPricingRule to match back-end
  useEffect(() => {
    if (!props.isOpen) {
      setNewGlobalPricingRule(normalizeGlobalDefault(globalDefault));
    }
  }, [props.isOpen, globalDefault, defaultOptions, normalizeGlobalDefault]);

  const handleEditDefaultPricingRules = useCallback(() => {
    const data: PricingRule = { ...newGlobalPricingRule };
    defaultOptions
      .map(option => option.items)
      .forEach(items => {
        items.forEach(({ type, key }) => {
          // For each item type BPS, apply the correct BPS/Percent conversion.
          if ([InputTypeEnum.BPS, InputTypeEnum.FEE].includes(type)) {
            Object.assign(data, { [key]: bpsToPercent(data[key] as string) });
          }
        });
      });

    // Manually delete synthetic crosses if the UI checkbox is disabled
    if (!data._AllowSyntheticCrosses) {
      delete data.AllowedCrossCurrencies;
      delete data.AllowedCrossMarkets;
    }
    delete data._AllowSyntheticCrosses;

    // Manuall delete fee if no FeeMode is set. (TODO we should clear the UI field)
    if (!data.FeeMode) {
      delete data.Fee;
    }

    handleEditPricingRule(data);
  }, [newGlobalPricingRule, handleEditPricingRule, defaultOptions]);

  return (
    <Dialog
      {...props}
      confirmDisabled={!isAuthorized(ACTION.EDIT_DEFAULT_PRICING_RULE)}
      onConfirm={handleEditDefaultPricingRules}
    >
      <Flex flexDirection="column" gap="spacingComfortable">
        {props.isOpen &&
          defaultOptions.map(({ title, itemsPerRow, items }) => {
            let indexOffset = 0;
            return (
              <Box textAlign="left" key={title}>
                <SectionTitle title={title} />
                {itemsPerRow.map((numItems: number, i: number) => {
                  indexOffset += i > 0 ? itemsPerRow[i - 1] : 0;
                  return (
                    <Flex gap="spacingDefault" mt="spacingDefault" key={i}>
                      {items.slice(indexOffset, numItems + indexOffset).map(option => {
                        const { displayName, key, type, selectOptions, helpText } = option;
                        const allowSyntheticCcy = newGlobalPricingRule['_AllowSyntheticCrosses'];
                        return (
                          <Flex flexDirection="column" w="100%" key={key}>
                            {type !== InputTypeEnum.CHECKBOX && <Text mb="spacingSmall">{displayName}</Text>}
                            {type === InputTypeEnum.BPS ? (
                              <Input
                                autoComplete="off"
                                value={newGlobalPricingRule[key] as string}
                                onChange={e => setNewGlobalPricingRule(prev => ({ ...prev, [key]: e.target.value }))}
                                suffix="BPS"
                              />
                            ) : type === InputTypeEnum.SELECT_SUBACCOUNT ? (
                              <SearchSelect
                                options={allSubAccountBooks ?? []}
                                selection={
                                  typeof newGlobalPricingRule[key] === 'string'
                                    ? subAccountsByName?.get(newGlobalPricingRule[key] as string)
                                    : undefined
                                }
                                getLabel={getSubAccountLabel}
                                showClear={true}
                                onChange={subAccount =>
                                  setNewGlobalPricingRule(prev => ({ ...prev, [key]: subAccount?.Name }))
                                }
                              />
                            ) : type === InputTypeEnum.CHECKBOX ? (
                              <Checkbox
                                checked={newGlobalPricingRule[key] as boolean}
                                onChange={e => setNewGlobalPricingRule(prev => ({ ...prev, [key]: e.target.checked }))}
                              >
                                {displayName} {helpText && <HelpIcon usePortal={false} tooltip={helpText} />}
                              </Checkbox>
                            ) : type === InputTypeEnum.TEXT ? (
                              <Input
                                autoComplete="off"
                                value={newGlobalPricingRule[key] as string}
                                onChange={e => setNewGlobalPricingRule(prev => ({ ...prev, [key]: e.target.value }))}
                              />
                            ) : type === InputTypeEnum.SELECT_CROSS_CURRENCY ? (
                              <SearchSelect
                                options={selectOptions as string[]}
                                disabled={!allowSyntheticCcy}
                                // We receive an array with 1 item from the back-end. Setting multiple currencies is not currently supported.
                                selection={allowSyntheticCcy ? first(newGlobalPricingRule[key] as string[]) : undefined}
                                getLabel={identity}
                                getDescription={currency => currenciesBySymbol.get(currency)?.Description as string}
                                onChange={selection =>
                                  setNewGlobalPricingRule(prev => ({ ...prev, [key]: [selection] }))
                                }
                              />
                            ) : type === InputTypeEnum.SELECT_CROSS_MARKET ? (
                              <SearchSelect
                                options={selectOptions as string[]}
                                disabled={!allowSyntheticCcy}
                                // We receive an array with 1 item from the back-end. Setting multiple market accounts is not currently supported.
                                selection={allowSyntheticCcy ? first(newGlobalPricingRule[key] as string[]) : undefined}
                                getLabel={m => marketsByName.get(m)?.DisplayName ?? m}
                                onChange={selection =>
                                  setNewGlobalPricingRule(prev => ({ ...prev, [key]: [selection] }))
                                }
                              />
                            ) : type === InputTypeEnum.SELECT_FEE_CURRENCY ? (
                              <SearchSelect
                                options={selectOptions as string[]}
                                selection={
                                  typeof newGlobalPricingRule[key] === 'string' ? newGlobalPricingRule[key] : undefined
                                }
                                getLabel={identity}
                                onChange={selection => {
                                  setNewGlobalPricingRule(prev => ({ ...prev, [key]: selection }));
                                }}
                                showClear={true}
                                disabled={newGlobalPricingRule.FeeMode !== MarketFeeModeEnum.SpecificCurrency}
                                data-testid="pricing-rule-global-fee-currency"
                              />
                            ) : type === InputTypeEnum.SELECT_FEE_MODE ? (
                              <SearchSelect
                                options={selectOptions as string[]}
                                selection={
                                  typeof newGlobalPricingRule[key] === 'string' ? newGlobalPricingRule[key] : undefined
                                }
                                getLabel={identity}
                                onChange={selection => {
                                  setNewGlobalPricingRule(prev => ({ ...prev, [key]: selection }));
                                  if (selection === undefined) {
                                    setNewGlobalPricingRule(prev => ({
                                      ...prev,
                                      Fee: undefined,
                                      FeeCurrency: undefined,
                                    }));
                                  } else if (selection !== MarketFeeModeEnum.SpecificCurrency) {
                                    setNewGlobalPricingRule(prev => ({ ...prev, FeeCurrency: undefined }));
                                  }
                                }}
                                showClear={true}
                                data-testid="pricing-rule-global-fee-mode"
                              />
                            ) : type === InputTypeEnum.FEE ? (
                              <Input
                                autoComplete="off"
                                disabled={!newGlobalPricingRule.FeeMode}
                                value={newGlobalPricingRule[key] as string}
                                onChange={e => setNewGlobalPricingRule(prev => ({ ...prev, [key]: e.target.value }))}
                                suffix="BPS"
                                data-testid="pricing-rule-global-fee"
                              />
                            ) : null}
                          </Flex>
                        );
                      })}
                    </Flex>
                  );
                })}
              </Box>
            );
          })}
      </Flex>
    </Dialog>
  );
};

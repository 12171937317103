import {
  ACTION,
  Button,
  ButtonVariants,
  Dialog,
  FormControlSizes,
  IconName,
  Text,
  Tooltip,
  useDisclosure,
} from '@talos/kyoko';
import { useRoleAuth } from 'hooks';
import { useCustomersContext } from 'hooks/useCustomer';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

/**
 * @deprecated
 */
export const KillSwitch = ({ size = FormControlSizes.Default }: { size?: FormControlSizes }) => {
  const [globalStatusEnabled, setGlobalStatusEnabled] = useState<boolean>(false);
  const [globalStatusEnabledLoading, setGlobalStatusEnabledLoading] = useState<boolean>(true);

  const { isAuthorized } = useRoleAuth();
  const killSwitchConfirmDialog = useDisclosure();

  const { getOrderExecutionRulesGlobalStatus, updateOrderExecutionRulesGlobalStatus } = useCustomersContext();
  useEffect(() => {
    setGlobalStatusEnabledLoading(true);
    getOrderExecutionRulesGlobalStatus().then(enabled => {
      setGlobalStatusEnabled(enabled);
      setGlobalStatusEnabledLoading(false);
    });
  }, [getOrderExecutionRulesGlobalStatus]);

  const handleOnConfirm = useCallback(() => {
    setGlobalStatusEnabledLoading(true);
    updateOrderExecutionRulesGlobalStatus(globalStatusEnabled ? 'disable' : 'enable').then(status => {
      setGlobalStatusEnabled(status);
      setGlobalStatusEnabledLoading(false);
    });
  }, [globalStatusEnabled, updateOrderExecutionRulesGlobalStatus]);

  const authorizedToToggleKillSwitch = isAuthorized(ACTION.EDIT_EXECUTION_RULES_MODE);

  const buttonVariant = useMemo(() => {
    if (globalStatusEnabledLoading) {
      return ButtonVariants.Default;
    } else if (globalStatusEnabled) {
      return ButtonVariants.Positive;
    } else {
      return ButtonVariants.Negative;
    }
  }, [globalStatusEnabled, globalStatusEnabledLoading]);

  return (
    <Fragment>
      <Tooltip tooltip={authorizedToToggleKillSwitch ? null : 'Contact an Admin to modify this setting.'}>
        <Button
          disabled={!authorizedToToggleKillSwitch || globalStatusEnabledLoading}
          size={size}
          variant={buttonVariant}
          startIcon={IconName.LockClosed}
          onClick={() => killSwitchConfirmDialog.open()}
          data-testid="kill-switch-button"
        >
          Execution Rules are {globalStatusEnabledLoading ? 'Loading' : globalStatusEnabled ? 'Enabled' : 'Disabled'}
        </Button>
      </Tooltip>
      <Dialog
        {...killSwitchConfirmDialog}
        onConfirm={handleOnConfirm}
        confirmLabel={globalStatusEnabled ? 'Disable Execution Rules' : 'Enable Execution Rules'}
        width={400}
        variant={globalStatusEnabled ? ButtonVariants.Negative : ButtonVariants.Positive}
        title="Confirmation"
      >
        <Text>
          Please confirm action to <strong>{globalStatusEnabled ? 'Disable' : 'Enable'}</strong> Execution Rules
        </Text>
      </Dialog>
    </Fragment>
  );
};

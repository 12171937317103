import { Flex } from '../../Core';
import { AutocompleteDropdown, FuseAutocompleteResult } from '../../Form/AutocompleteDropdown';
import type { AutocompleteDropdownProps } from '../../Form/AutocompleteDropdown/types';
import { Icon, type IconName } from '../../Icons';

export const PropertyDropdown = <T extends { icon: IconName }>(
  props: Omit<AutocompleteDropdownProps<T>, 'renderResult'>
) => {
  const prefix = (item: T) => (
    <Flex mr="spacingSmall" alignItems="center">
      <Icon icon={item.icon} />
    </Flex>
  );

  return (
    <AutocompleteDropdown
      {...props}
      renderResult={(searchResult, disabled) => FuseAutocompleteResult(searchResult, disabled, prefix)}
    />
  );
};

import type { DockviewApi } from 'dockview';
import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';
import type { LayoutProps } from '../Layout';

export interface LayoutContextProps
  extends Required<
    Pick<
      LayoutProps,
      | 'minimizedPanels'
      | 'maximizedPanels'
      | 'onMaximizePanel'
      | 'onMinimizePanel'
      | 'addPanel'
      | 'availablePanelTypes'
      | 'enablePopoutWindows'
      | 'onPopoutGroup'
      | 'disallowLastCloseMode'
    >
  > {
  dockviewApi?: DockviewApi;
  isAnimating: boolean;
  setIsAnimating: (isAnimating: boolean) => void;
}

export const LayoutContext = createContext<LayoutContextProps>({
  isAnimating: false,
  setIsAnimating: () => {},

  minimizedPanels: [],
  maximizedPanels: [],
  onMaximizePanel: () => {},
  onMinimizePanel: () => {},
  onPopoutGroup: () => {},

  addPanel: () => {},

  availablePanelTypes: [],

  enablePopoutWindows: false,
  disallowLastCloseMode: 'mainGroup',
});

export function useLayoutContext() {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('Missing LayoutContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/** Provided access to layout related methods to content inside the current layout */
export const LayoutContextProvider = ({ children, value }: PropsWithChildren<{ value: LayoutContextProps }>) => {
  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

import { useCombobox } from 'downshift';
import Fuse from 'fuse.js';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useConstant } from '../../../hooks';
import { useTabs, type TabProps } from '../../Tabs';
import { ALL_TAB } from '../AutocompleteDropdown/types';
import type {
  AutocompleteGroup,
  FuseSearchObject,
  FuseSearchResult,
  UseAutocompleteOutput,
  UseAutocompleteProps,
} from './types';
import { getGroupsMetadata } from './utils';

// Searching options for fuse.js
const fuseOptions = {
  keys: ['label', 'description'],
  includeMatches: true,
  includeScore: true,
} as const;

const DEFAULT_THRESHOLD = 0.5; // default fusejs threshold is 0.6. Keeping it defined here so we can change it.

export function getLabelWrapper<T>(getLabel: ((obj: T) => string) | undefined, obj: T | undefined) {
  if (obj == null) {
    return '';
  } else if (getLabel == null) {
    if (typeof obj === 'string') {
      return obj;
    } else {
      return JSON.stringify(obj);
    }
  } else {
    return getLabel(obj);
  }
}

export function useAutocomplete<T>({
  items,
  getLabel,
  getDescription,
  isItemDisabled,
  getGroup,
  initialSortByLabel = true,
  matchThreshold = DEFAULT_THRESHOLD,
  minMatchCharLength,
  searchKeys,
  itemSize,
  additionalSearchKeys,
  fuseDistance,
  sortFilterOverride,
  customFuseSearchResultsSorter,
  disableFuzzyMatching = false,
  groupSorter,
  inputRef,
  ...props
}: UseAutocompleteProps<T>): UseAutocompleteOutput<T> {
  const isReadyRef = useRef(false);
  const [results, setResults] = useState<FuseSearchResult<T>[]>([]);

  const itemLabelSorter = useCallback(
    (a: T, b: T) => {
      return getLabelWrapper(getLabel, a).localeCompare(getLabelWrapper(getLabel, b));
    },
    [getLabel]
  );

  // This sorting is applied on search results only.
  // Note that Fuse by default sorts the search results by score. This function below allows you to pass in a "modifier" to this in
  // order to for example bubble certain special options to the top. Or whatever you want to do to the search results.
  const maybeSortSearchResults = useCallback(
    (searchResults: FuseSearchResult<T>[], searchString: string) => {
      if (customFuseSearchResultsSorter) {
        return searchResults.sort((a, b) => customFuseSearchResultsSorter(a, b, searchString));
      } else {
        return searchResults;
      }
    },
    [customFuseSearchResultsSorter]
  );

  // We want to store a mapping of group key -> group metadata since we are flattening all groups' items
  // in order to push it through fuse. when the items come back on the other side, we need to stitch
  // the groups back together using this group -> metadata lookup
  // Note in the case of selecting groups the metadata does not change, the items are just filtered
  const groupMetadata = useMemo(() => {
    const itemsAlreadyGrouped = itemsAreAutocompleteGroups(items);
    if (itemsAlreadyGrouped) {
      return new Map(items.map(itemGroup => [itemGroup.group, itemGroup]));
    }
    return getGroupsMetadata(items, getGroup);
  }, [getGroup, items]);

  // After making sure all items are grouped, we sort the groups individually
  const groups = useMemo(() => {
    const newGroups = [...groupMetadata.values()];
    if (initialSortByLabel) {
      return newGroups.map(group => ({ ...group, items: group.items.slice().sort(itemLabelSorter) }));
    }
    if (groupSorter) {
      return newGroups.sort(groupSorter);
    }
    return newGroups;
  }, [groupMetadata, initialSortByLabel, itemLabelSorter, groupSorter]);

  const initialTabItems = useConstant([ALL_TAB, ...groups.map(group => ({ group: group.group }))]);

  // We now grouped and sorted items. Using this, we can create tabs.
  const tabs = useTabs<TabProps & { group: string }>({
    initialItems: initialTabItems,
    showAddTab: false,
    initialSelectedIndex: 0,
    onSelect: () => {
      // Upon clicking a tab item, the input loses focus. Return focus to the input immediately.
      inputRef?.current?.focus();
    },
  });

  const { setItems: setTabItems } = tabs;

  // Keep the state of the tab items in check with the AutocompleteGroups we compute directly above.
  // We already set an initial set of tabs, so skip the initial render here by doing useUpdateEffect.
  useUpdateEffect(() => {
    // The tabs only care about the "group" (key) property, so map to that.
    const newTabItems = [ALL_TAB, ...groups.map(group => ({ group: group.group }))];
    setTabItems(prevTabItems => {
      if (!isEqual(prevTabItems, newTabItems)) {
        return newTabItems;
      }
      return prevTabItems;
    });
  }, [groups, setTabItems]);

  // Given the selected tab ("selectedGroup"), we then filter down the groups to just that group.
  const renderedGroups = useMemo(() => {
    const selectedGroup = tabs.items[tabs.selectedIndex]?.group ?? ALL_TAB.group;
    if (selectedGroup === ALL_TAB.group) {
      return groups;
    }

    return groups.filter(group => group.group === selectedGroup);
  }, [tabs.items, tabs.selectedIndex, groups]);

  // After having pushed all the items through fuse, re-attach items to their groups
  const { resultGroups, flattenedResults } = useMemo(() => {
    const resultsByGroup = results.reduce((map, result) => {
      if (map.has(result.item._group)) {
        map.get(result.item._group)!.push(result);
      } else {
        map.set(result.item._group, [result]);
      }

      return map;
    }, new Map<string, FuseSearchResult<T>[]>());
    const resultGroups: AutocompleteGroup<FuseSearchResult<T>>[] = [...resultsByGroup.entries()]
      .map(([groupKey, items]) => {
        const group = groupMetadata.get(groupKey);
        if (!group) {
          return undefined;
        }

        return {
          ...group,
          items,
        };
      })
      .compact();

    if (groupSorter) {
      resultGroups.sort(groupSorter);
    }

    // After re-merging all search results into groups, we need to proide downshift with a flat list of all our items.
    // This list should be of type T[]
    // It's important that we group them and then re-flatten because then we can guarantee the correct ordering of items over several groups
    const flattenedResults = resultGroups.reduce(
      (arr, group) => arr.concat(group.items.map(item => item.item.item)),
      [] as T[]
    );
    return { resultGroups, flattenedResults };
  }, [results, groupMetadata, groupSorter]);

  const combobox = useCombobox<T>({
    defaultHighlightedIndex: 0,
    items: flattenedResults,
    itemToString: item => {
      return item ? getLabelWrapper(getLabel, item) : '';
    },
    onInputValueChange: ({ inputValue }) => {
      if (inputValue == null) {
        inputValue = '';
      }
    },
    isItemDisabled: (item, index) => {
      // Two issues:
      // - due to the useUpdateEffect re-render, we need to check if the items are ready before we can check if they are disabled
      // - the 'item' checked could be undefined due to internal downshift behavior when the user is typing and the list is empty
      const listEmpty = flattenedResults.length === 0;
      return !isReadyRef.current || listEmpty ? true : isItemDisabled?.(item, index) ?? false;
    },
    ...props,
  });

  const fuseSearchObjects = useMemo(() => {
    return renderedGroups.flatMap(group => {
      return group.items.map(item => {
        const wrappedItem: FuseSearchObject<T> = {
          item: item,
          label: getLabelWrapper(getLabel, item),
          description: getDescription ? getDescription(item) : '',
          disabled: isItemDisabled ? isItemDisabled(item) : false,
          _group: group.group,
        };

        // If there are any additional search keys provided, apply them to the FuseSearchObject
        if (additionalSearchKeys) {
          for (const additionalSearchKey of additionalSearchKeys) {
            wrappedItem[additionalSearchKey] = item[additionalSearchKey];
          }
        }

        return wrappedItem;
      });
    });
  }, [renderedGroups, additionalSearchKeys, getLabel, getDescription, isItemDisabled]);

  const shouldSort = customFuseSearchResultsSorter == null;
  const fuse = useMemo(() => {
    const fuseKeys: (string | { name: string; weight?: number })[] = [
      ...fuseOptions.keys,
      ...(additionalSearchKeys || []),
    ];

    return new Fuse(fuseSearchObjects, {
      ...fuseOptions,
      threshold: matchThreshold,
      ignoreLocation: true, // ignore _where_ in the string the match occurred. if its there, its there.
      keys: searchKeys ? searchKeys : fuseKeys,
      distance: fuseDistance,
      useExtendedSearch: disableFuzzyMatching,
      minMatchCharLength,
      shouldSort,
    });
  }, [
    fuseSearchObjects,
    searchKeys,
    minMatchCharLength,
    matchThreshold,
    additionalSearchKeys,
    fuseDistance,
    disableFuzzyMatching,
    shouldSort,
  ]);

  // Here we change the search results based on any inputValue changes and any fuse object changes.
  // If fuse object changes when there is a non-empty inputValue for example, we redo the search on the new fuseObject using
  // our pre-existing inputValue search string
  useEffect(() => {
    if (combobox.inputValue === '' || combobox.inputValue == null) {
      setResults(fuseSearchObjects.map(item => ({ item, refIndex: 0, matches: undefined, score: 0 })));
    } else {
      const searchString = combobox.inputValue.toLowerCase();
      // https://www.fusejs.io/examples.html#extended-search
      const extendedSearchString = searchString
        .split(' ')
        .filter(n => n)
        .map(item => `'${item}`) // note the ' applied here, see the extended search url above for docs
        .join(' ');

      if (sortFilterOverride) {
        const fuseSearchResults = fuseSearchObjects.map(item => ({ item, refIndex: 0, matches: undefined, score: 0 }));
        const results = sortFilterOverride(fuseSearchResults, searchString);

        setResults(results);
      } else {
        const results = fuse.search(disableFuzzyMatching ? extendedSearchString : searchString);
        setResults(maybeSortSearchResults(results, searchString));
      }
    }
    isReadyRef.current = true;
  }, [
    combobox.inputValue,
    fuse,
    maybeSortSearchResults,
    disableFuzzyMatching,
    fuseSearchObjects,
    customFuseSearchResultsSorter,
    sortFilterOverride,
  ]);
  return {
    groupMetadata,
    searchResults: results.map(item => item.item.item),
    items: flattenedResults,
    searchResultGroups: resultGroups,
    itemSize,
    isItemDisabled,
    tabs,
    ...combobox,
  };
}

export function itemsAreAutocompleteGroups<T>(items: T[] | AutocompleteGroup<T>[]): items is AutocompleteGroup<T>[] {
  if (items.length === 0) {
    return false; // inconclusive, assume false for this render
  }

  const firstItem = items[0];
  if (typeof firstItem !== 'object' || firstItem == null) {
    // If its not an object then its a string or a number meaning it cant be an autocompletegroup item
    return false;
  }

  return itemIsAutocompleteGroup(firstItem);
}

export function itemIsAutocompleteGroup<T>(item: T | AutocompleteGroup<T>): item is AutocompleteGroup<T> {
  return (
    item &&
    typeof item === 'object' &&
    ('items' satisfies keyof AutocompleteGroup<T>) in item &&
    ('group' satisfies keyof AutocompleteGroup<T>) in item
  );
}

/**
 * Takes an array of items, which can be whatever mix of T and AutocompleteGroup<T>, and flattens them to just T[]
 */
export function flattenAutocompleteGroupItems<T extends object>(items: (T | AutocompleteGroup<T>)[]): T[] {
  return items.flatMap(groupOrItem => {
    if (itemIsAutocompleteGroup(groupOrItem)) {
      return groupOrItem.items;
    } else {
      return groupOrItem;
    }
  });
}

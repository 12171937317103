import { Text, VStack, useConstant, useDynamicCallback } from '@talos/kyoko';
import Big from 'big.js';
import { filter, find } from 'lodash-es';
import { useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { v1 as uuid } from 'uuid';
import { REMAINDER, type MarketDistributionItem } from './types';

export function useChartOptions({
  distribution,
  centerText,
}: {
  distribution: MarketDistributionItem[];
  centerText: string;
}): null | Highcharts.Options {
  const centerTextId = useConstant(uuid());

  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const hoveredItem = useMemo(() => {
    if (hoveredId) {
      return find(distribution, d => d.id === hoveredId) || null;
    }
    return null;
  }, [distribution, hoveredId]);

  const sumText = useMemo(() => {
    if (hoveredItem) {
      return `${Math.min(Big(hoveredItem.y).mul(100).toNumber(), 100).toFixed(2)}%`;
    }
    const sum = filter(distribution, d => d.id !== REMAINDER).reduce((acc, curr) => acc.plus(curr.y), Big(0));

    return `${Math.min(sum.mul(100).toNumber(), 100).toFixed(2)}%`;
  }, [distribution, hoveredItem]);

  const mouseOver: Highcharts.PointMouseOverCallbackFunction = useDynamicCallback(e => {
    if (e.target && 'id' in e.target && typeof e.target.id === 'string') {
      const newHoveredId = e.target.id;
      if (newHoveredId === REMAINDER) {
        setHoveredId(null);
        return;
      }
      const maybeText = document.getElementById(centerTextId);
      if (maybeText) {
        setHoveredId(newHoveredId);
      }
    }
  });
  const mouseOut: Highcharts.PointMouseOverCallbackFunction = useDynamicCallback(() => {
    setHoveredId(null);
  });

  return {
    credits: { enabled: false },
    chart: {
      backgroundColor: 'transparent',
      plotBackgroundColor: undefined,
      plotBorderWidth: undefined,
      plotShadow: false,
      type: 'pie',
      spacingLeft: 0,
      width: 250,
      height: 250,
      style: { fontFamily: 'Roboto' },
    },
    title: {
      useHTML: true,
      text: renderToString(
        <VStack maxWidth="160px" overflow="hidden" textAlign="center" style={{ whiteSpace: 'normal' }}>
          <Text fontSize="var(--fontSizeXs)" color="var(--colorTextSubtle)">
            {(hoveredItem ? hoveredItem.name : centerText) || <br />}
          </Text>
          <Text id={centerTextId} fontSize="var(--fontSizeMd)" color="var(--colorTextImportant)">
            {sumText}
          </Text>
        </VStack>
      ),
      align: 'center',
      floating: true,
      verticalAlign: 'middle',
    },

    plotOptions: {
      series: {
        // Disable initial animation
        animation: false,
        borderWidth: 0,
      },
      pie: {
        innerSize: '85%',
        cursor: 'pointer',
        dataLabels: { enabled: false },
        point: { events: { mouseOver, mouseOut } },
        borderRadius: 0,
        borderWidth: 1,
        borderColor: 'var(--backgroundBody)',
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        type: 'pie',
        name: 'Distribution',
        data: distribution,
        animation: false,
      },
    ],
    accessibility: {
      enabled: false,
    },
  };
}

import type { BoxProps } from '@talos/kyoko';
import {
  Box,
  CurrentDocumentProvider,
  DROPDOWN_PORTAL_ID,
  getLoadingAnimation,
  MODAL_PORTAL_ID,
  POPOVER_PORTAL_ID,
  PortalContextProvider,
  useIsIntersecting,
  usePortal,
} from '@talos/kyoko';
import type { DockviewGroupLocation } from 'dockview';
import type { PropsWithChildren } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';

type PanelProps = PropsWithChildren<BoxProps> & {
  panelLocation: DockviewGroupLocation;
};

export function Panel(props: PanelProps) {
  // Get the current document if the panel is in a popout window
  const currentDocument = props.panelLocation.type === 'popout' ? props.panelLocation.getWindow().document : undefined;

  return (
    <CurrentDocumentProvider currentDocument={currentDocument ?? document}>
      <PortalContextProvider>
        <InnerPanel {...props} />
      </PortalContextProvider>
    </CurrentDocumentProvider>
  );
}

/** Wrapper component to make sure only visible content is rendered */
function InnerPanel({ children, ...props }: PanelProps) {
  const panelRef = useRef<HTMLDivElement>(null);
  const isIntersecting = useIsIntersecting(panelRef);
  const { setPortalRef: modalPortalRef } = usePortal(MODAL_PORTAL_ID);
  const { setPortalRef: dropdownPortalRef } = usePortal(DROPDOWN_PORTAL_ID);
  const { setPortalRef: popoverPortalRef } = usePortal(POPOVER_PORTAL_ID);
  const isPopout = props.panelLocation.type === 'popout';

  return (
    <Box data-testid="layout-panel" ref={panelRef} h="100%" background="backgroundContent" overflow="hidden" {...props}>
      {isPopout && (
        <>
          <Box ref={modalPortalRef} />
          <Box ref={popoverPortalRef} />
          <Box ref={dropdownPortalRef} />
        </>
      )}
      {panelRef.current && (isIntersecting ? children : <LoadingPanel />)}
    </Box>
  );
}

const LoadingPanel = styled(Box).attrs({
  'data-testid': 'loading-panel',
})`
  ${({ theme }) => getLoadingAnimation(theme, true, 'backgroundContent', 'gray.010')}
  height: 100%;
  width: 100%;
`;

import { useDebugValue, useState, type Dispatch, type SetStateAction } from 'react';
function format<S>({ label, value }: { label: string; value: S }) {
  return label + ': ' + (typeof value === 'object' ? JSON.stringify(value) : value);
}

/**
 * Returns a stateful value, and a function to update it.
 * This version adds the ability to name the state variable, which makes the dev tools much easier to use.
 * @see {useState} from react - this wraps that function but adds the debug label
 
 * @param label Label to show in react-dev-tools
 */

export function useNamedState<S>(initialState: S | (() => S), label = 'UseState'): [S, Dispatch<SetStateAction<S>>] {
  const states = useState(initialState);
  useDebugValue({ label, value: states[0] }, format);
  return states;
}

import {
  FilterClauseType,
  IconName,
  QUOTE_STATUS_MAPPING,
  QuoteStatusEnum,
  SideEnum,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  removeEmptyFilters,
  useDateRangeFilter,
  useSymbolsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type MarketQuote,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { compact, isEqual, keys, startCase, uniq, values } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useIDFilter, useMarketsFilter, useSidesFilter } from '../../../hooks';

export interface MarketQuotesTableFilter extends DateRangeFilter {
  /** These are the quote status texts we are filtering by on the client side */
  _statuses?: string[];
  /** These are the quote statuses that we are filtering by on the server */
  Statuses?: QuoteStatusEnum[];
  Markets?: string[];
  Sides?: SideEnum[];
  TradedSides?: SideEnum[];
  Symbols?: string[];
  ParentRFQID?: string;
}

export interface UseMarketQuotesFilterParams<TData extends MarketQuote> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}
export function useMarketQuotesFilter<TData extends MarketQuote>({
  persistedBlotterTable,
}: UseMarketQuotesFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [initialFilter] = useState(() => cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter));
  const [filter, setFilter] = useState<MarketQuotesTableFilter>((initialFilter as MarketQuotesTableFilter) || {});

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<TData>>(
    row => {
      const data = row.data;

      if (filterExistsAndExcludes(filter, 'Statuses', data, 'QuoteStatus')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Sides', data, 'Side')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'TradedSides', data, 'TradedSide')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Markets', data, 'Market')) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const symbolFilter = useSymbolsFilter();
  const sidesFilter = useSidesFilter();
  const marketsFilter = useMarketsFilter({ onlyTradingMarkets: true });
  const idFilter = useIDFilter();

  const filterableProperties = useMemo(
    () =>
      compact<FilterableProperty<keyof MarketQuotesTableFilter>>([
        symbolFilter,
        sidesFilter,
        {
          key: 'TradedSides',
          label: 'Traded Side',
          icon: IconName.ArrowUpDown,
          options: values(SideEnum),
          getOptionLabel: (option: string) => option,
        },
        marketsFilter,
        {
          key: '_statuses',
          label: 'Status',
          icon: IconName.CheckCircle,
          options: [...keys(QUOTE_STATUS_MAPPING), ...keys(QuoteStatusEnum)],
          getOptionLabel: (option: string) => startCase(option),
        },
        { ...idFilter, key: 'ParentRFQID', label: 'Parent RFQ ID' },
      ]),
    [symbolFilter, sidesFilter, marketsFilter, idFilter]
  );
  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof MarketQuotesTableFilter)[]).forEach((key: keyof MarketQuotesTableFilter) => {
        switch (key) {
          case '_start':
          case 'StartDate':
          case 'EndDate':
          case 'TimestampField':
            return;
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: MarketQuotesTableFilter = removeEmptyFilters<MarketQuotesTableFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies MarketQuotesTableFilter),
          _statuses: filterClausesByPropertyKey.get('_statuses')?.selections,
          Statuses: (filterClausesByPropertyKey.get('_statuses')?.selections || []).reduce(
            (prev, curr) => uniq(prev.concat(QUOTE_STATUS_MAPPING[curr as keyof typeof QUOTE_STATUS_MAPPING] ?? curr)),
            [] as QuoteStatusEnum[]
          ),
          // below are exact search and only one value is supported by backend
          ParentRFQID: filterClausesByPropertyKey.get('ParentRFQID')?.selections?.[0],
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
}

const colIDToFilterBuilderKeyMap = {
  Market: 'Markets',
  Side: 'Sides',
  Symbol: 'Symbols',
  QuoteStatus: '_statuses',
  TradedSide: 'TradedSides',
} satisfies Partial<Record<keyof MarketQuote, keyof MarketQuotesTableFilter>>;
export function colIDToFilterBuilderKey(
  id: keyof typeof colIDToFilterBuilderKeyMap
): keyof MarketQuotesTableFilter | undefined {
  return colIDToFilterBuilderKeyMap[id as keyof typeof colIDToFilterBuilderKeyMap];
}

import { useCallback } from 'react';
import { Icon, IconName } from '../../components/Icons';
import { ChevronWrapper } from './styles';
import type { AccordionOutputs } from './useAccordion';

type AccordionChevronProps = Pick<AccordionOutputs, 'toggle' | 'isOpen'>;

export const AccordionChevron = ({ toggle, isOpen }: AccordionChevronProps) => {
  const handleChevronClick: React.MouseEventHandler<HTMLElement> = useCallback(
    e => {
      e.stopPropagation();
      toggle(e);
    },
    [toggle]
  );

  return (
    <ChevronWrapper>
      <Icon icon={IconName.ChevronDown} onClick={handleChevronClick} rotate={isOpen ? 180 : 0} />
    </ChevronWrapper>
  );
};

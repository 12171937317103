import {
  CUSTOMER_CREDIT,
  type ColumnDef,
  type Currency,
  type Customer,
  type CustomerCredit,
  type InputsAndDropdownsDrawerOption,
  type MarketAccount,
  getCurrencyDrawerOptions,
  getCustomerDrawerOptions,
  getCustomerMarketAccountOptions,
} from '@talos/kyoko';

export interface CustomerCreditDrawerEntity {
  Counterparty: string;
  MarketAccount: string;
  ExposureCurrency: string;
  Qty: string;
}

export const DEFAULT_CUSTOMER_CREDITS_CURRENCIES: Pick<Currency, 'Symbol' | 'Description'>[] = [
  { Symbol: 'BTC', Description: '' },
  { Symbol: 'CAD', Description: '' },
  { Symbol: 'CHF', Description: '' },
  { Symbol: 'ETH', Description: '' },
  { Symbol: 'EUR', Description: '' },
  { Symbol: 'USD', Description: '' },
  { Symbol: 'USDC', Description: '' },
  { Symbol: 'USDT', Description: '' },
];

export const CUSTOMER_CREDITS_COLUMNS: ColumnDef<CustomerCredit>[] = [
  {
    type: 'counterparty',
    field: 'Counterparty',
    title: 'Customer',
    sort: '+',
  },
  {
    type: 'marketAccountSourceAccountID',
    field: 'MarketAccount',
    sort: '+',
  },
  {
    type: 'size',
    field: 'ExposureLimit',
    title: 'Credit Limit',
    width: 175,
    params: { currencyField: 'ExposureCurrency' },
  },
  {
    type: 'size',
    field: 'Exposure',
    title: 'Credit Exposure',
    width: 175,
    editable: false,
    params: { currencyField: 'ExposureCurrency' },
  },
  {
    type: 'exposureUtilization',
    title: 'Credit Exposure Utilization',
    id: 'utilization',
    width: 250,
  },
  {
    type: 'currency',
    field: 'ExposureCurrency',
    title: 'Credit Currency',
  },
];

export const CUSTOMER_CREDITS_WS_REQUEST = {
  name: CUSTOMER_CREDIT,
  tag: 'dealer/credit',
};

interface FilterDropdownOptionsProps {
  customerMarketAccountOptions: MarketAccount[];
  customerCreditByMarketAccount: Map<string, CustomerCredit>;
  isEditing: boolean;
  filteredCounterparty?: string;
}

function getFilteredCustomerMarketAccounts({
  customerMarketAccountOptions,
  customerCreditByMarketAccount,
  isEditing,
  filteredCounterparty,
}: FilterDropdownOptionsProps) {
  return customerMarketAccountOptions
    .filter(
      ({ SourceAccountID, Counterparty }) =>
        // Only show market accounts that belong to the selected customer
        // If for any reason SourceAccountID is null, filter out the record
        SourceAccountID != null && (filteredCounterparty == null || Counterparty === filteredCounterparty)
    )
    .filter(
      // Only show market accounts that don't have a credit limit set
      // Ignore this check when editing an existing record
      ({ Name }) => isEditing || !customerCreditByMarketAccount.has(Name)
    );
}

export function getCustomerCreditsDrawerOptions(
  customerOptions: Customer[],
  customerMarketAccountOptions: MarketAccount[],
  customerCreditByMarketAccount: Map<string, CustomerCredit>
): InputsAndDropdownsDrawerOption<CustomerCreditDrawerEntity>[] {
  return [
    {
      field: 'Counterparty',
      type: 'dropdown',
      title: 'Customer',
      getDropdownOptions: (_, isEditing) => {
        // Filter to customers that have some market account with no credit limit set
        const filteredCustomers = customerOptions.filter(
          customer =>
            getFilteredCustomerMarketAccounts({
              customerMarketAccountOptions,
              customerCreditByMarketAccount,
              isEditing,
              // Effective filteredCounterparty would be this customer
              filteredCounterparty: customer.Name,
            }).length > 0
        );
        return getCustomerDrawerOptions(filteredCustomers);
      },
      getIsRequired: () => true,
      disabledWhenEditing: true,
    },
    {
      field: 'MarketAccount',
      type: 'dropdown',
      getIsDisabled: form => !form.Counterparty,
      getDropdownOptions: (form, isEditing) => {
        // Filter to market accounts that belong to the selected customer and have no credit limit set
        const filteredMarketAccounts = getFilteredCustomerMarketAccounts({
          customerMarketAccountOptions,
          customerCreditByMarketAccount,
          isEditing,
          // Effective filteredCounterparty is the selected customer
          filteredCounterparty: form.Counterparty,
        });
        return getCustomerMarketAccountOptions(filteredMarketAccounts);
      },
      getIsRequired: () => true,
      disabledWhenEditing: true,
    },
    {
      field: 'ExposureCurrency',
      type: 'dropdown',
      getDropdownOptions: () => getCurrencyDrawerOptions(DEFAULT_CUSTOMER_CREDITS_CURRENCIES),
      getIsRequired: () => true,
    },
    {
      field: 'Qty',
      title: 'Credit Limit',
      type: 'input',
      getInputSuffix: form => form.ExposureCurrency,
      getIsDisabled: form => !form.ExposureCurrency,
      getIsRequired: () => true,
    },
  ];
}

export function getCustomerCreditsEntityAsDrawerEntity(entity: CustomerCredit): CustomerCreditDrawerEntity {
  return {
    Counterparty: entity.Counterparty,
    MarketAccount: entity.MarketAccount,
    ExposureCurrency: entity.ExposureCurrency,
    Qty: entity.ExposureLimit,
  };
}

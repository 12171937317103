import { animated } from '@react-spring/web';
import { Button, Flex, Table, Td, Th } from '@talos/kyoko';
import styled from 'styled-components';
import { ANALYTICS_WIDTH } from 'styles/dimensions';

export const OrderAnalyticsWrapper = styled(animated.div)`
  width: ${ANALYTICS_WIDTH}px;
  background: ${({ theme }) => theme.colors.gray['020']};
  display: flex;
  flex-direction: column;
  overflow: overlay;
  height: 100%;
`;

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  & > :last-child {
    margin-left: ${({ theme }) => theme.spacingSmall}px;
  }
`;

export const Summary = styled.div`
  background: ${({ theme }) => theme.backgroundDrawer};
  padding: ${({ theme }) => `${theme.spacingMedium}px ${theme.spacingMedium}px`};
  border-bottom: ${({ theme }) => `1px solid ${theme.backgroundBody}`};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacingMedium}px;

  > div {
    justify-content: center;
    flex-direction: column;
    display: flex;
    flex: 1;
  }

  > div:first-child(1) {
    align-items: flex-start;
  }
  > div:nth-child(2) {
    align-items: center;
  }
  > div:last-child {
    align-items: flex-end;
  }
`;

export const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacingMedium}px;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const IndicatorBadgeStack = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacingDefault}px;
`;

export const TitleRight = styled.div`
  text-align: right;
  span {
    margin-left: auto;
    color: ${({ theme }) => theme.colors.gray['070']};
    font-weight: ${({ theme }) => theme.fontWeightMedium};
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  }
`;

export const AnalyticsTabWrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.backgroundDrawer};
  padding: ${({ theme }) => `${theme.spacingMedium}px ${theme.spacingDefault}px`};
  overflow: auto;
  scrollbar-gutter: stable both-edges;
  gap: ${({ theme }) => theme.spacingDefault}px 0;
`;

export const TradesTabWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.spacingMedium}px;
  flex: 1;
`;

export const Footer = styled.div`
  display: flex;
  flex: 0;
  align-items: flex-end;
  padding: ${({ theme }) => theme.spacingMedium}px;
  gap: ${({ theme }) => theme.spacingDefault}px;

  ${Button} {
    flex: 1;
  }
`;

export const Description = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${({ theme }) => theme.spacingDefault}px;
  grid-auto-rows: 1fr;
`;

export const Tables = styled(Flex)`
  &:first-child {
    margin-top: 0;
  }

  > ${Table} {
    flex-grow: 1;
    width: 50%;
  }

  > ${Table}:only-child {
    width: 100%;
  }

  & + & {
    border-top: ${({ theme }) => `1px solid ${theme.colors.gray['040']}`};
  }

  ${Th}, ${Td} {
    padding-left: 0;
    padding-right: 0;
  }
`;
Tables.defaultProps = {
  alignItems: 'flex-start',
  gap: 'spacingMedium',
  mt: 'spacingMedium',
};

export const TradeMarkerPopover = styled.div`
  width: auto;
  height: auto;
  position: absolute;
  display: none;
  padding: ${({ theme }) => theme.spacingSmall}px;
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  color: ${({ theme }) => theme.colors.gray['100']};
  background: ${({ theme }) => theme.colors.gray.main};
  text-align: left;
  z-index: 100;
  pointer-events: none;
`;

export const ChartMeta = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.colors.gray['070']};
  display: flex;
  align-items: center;
  z-index: 100;

  > * + * {
    margin-left: ${({ theme }) => theme.spacingSmall}px;
  }
`;

export const MetricLabel = styled.span`
  ${({ theme }) => `border-bottom: dotted 1px ${theme.colors.gray['080']}`};
`;

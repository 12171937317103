import {
  type AggregationWithAccounts,
  type ConnectionType,
  Flex,
  FormGroup,
  type FormGroupProps,
  Icon,
  IconName,
  MarketSelectionsList,
  SearchSelect,
  type SearchSelectProps,
  type SearchSelectRef,
  useSecuritiesContext,
} from '@talos/kyoko';
import { compact } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { AvailabilityEnum } from '../MarketSelector/types';
import { useGetSymbolMarketsAvailability } from './useGetSymbolMarketsAvailability';

export function AggregationsSelector(
  props: SearchSelectProps<AggregationWithAccounts> & { ref?: React.ForwardedRef<SearchSelectRef> } & {
    showMarketSelections?: boolean;
    marketSelectionsHeight?: string;
    /** If provided, will wrap the selector within a form group. */
    formGroupProps?: FormGroupProps;
    /** If provided, will filter markets based on the security.Markets */
    symbol: string | undefined;
    /**
     * The selected items render a status warning icon in certain cases. The status is dependent on what the markets will be used for, which is specified
     * by this property. If the market/accounts selected in the aggreagtion should be used for Market Data for example, then pass ConnectionType.MarketData
     * to get Market Data-related status warnings.
     */
    statusConnectionType: ConnectionType;
  }
) {
  const { securitiesBySymbol } = useSecuritiesContext();
  const { selection, showMarketSelections = true, marketSelectionsHeight, formGroupProps, symbol } = props;
  const security = symbol ? securitiesBySymbol.get(symbol) : undefined;

  const getAvailabilityResult = useGetSymbolMarketsAvailability({ symbol, connectionType: props.statusConnectionType });
  const getWarning = useCallback(
    (market: string) => {
      const result = getAvailabilityResult(market);
      return result.availability < AvailabilityEnum.Ok ? result.infoNode : undefined;
    },
    [getAvailabilityResult]
  );

  const selections = useMemo(() => {
    if (security?.Markets) {
      const allowedMarketSet = new Set(security?.Markets);
      const selectionList = selection?.Accounts
        ? Array.from(selection.Accounts.values()).filter(
            aggMarket => aggMarket.Market && allowedMarketSet.has(aggMarket.Market)
          )
        : [];

      return compact(selectionList.map(aggMarket => aggMarket.MarketAccount));
    }

    return selection?.Accounts ? Array.from(selection.Accounts.keys()) : [];
  }, [selection, security?.Markets]);

  const select = <SearchSelect {...props} prefix={<Icon icon={IconName.CommandCmd} color="purple.default" />} />;

  if (formGroupProps) {
    return (
      <>
        <FormGroup {...formGroupProps}>{select}</FormGroup>
        {showMarketSelections && (
          <MarketSelectionsList h={marketSelectionsHeight} selections={selections} getWarning={getWarning} />
        )}
      </>
    );
  }

  return (
    <>
      <Flex gap="spacingDefault" flexDirection="column">
        {select}
        {showMarketSelections && (
          <MarketSelectionsList h={marketSelectionsHeight} selections={selections} getWarning={getWarning} />
        )}
      </Flex>
    </>
  );
}

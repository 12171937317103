import {
  AccordionGroup,
  CustomerLedgerUpdateTypeEnum,
  IconName,
  LookbackOption,
  ROLE,
  useDisclosure,
  useDynamicCallback,
  type Column,
  type TabProps,
  type Transfer,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { NewBalanceTransactionDialog } from 'containers/Dealer/CustomerBalances/NewBalanceTransactionDialog';
import { compact } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useRoleAuth } from '../../../hooks';
import { FilteredTransfers, type RecentTransfersParams } from './FilteredTransfers';
import type { TransfersBlotterFilter } from './useTransfersFilter';

export interface TransfersTabProps extends TabProps {
  defaultFilter: Partial<TransfersBlotterFilter>;
  defaultColumns: Column<any>[];
}

export function FilteredTransfersWrapper({
  tabId,
  blotterID,
  initialIsOpen,
  defaultColumns,
  defaultFilter,
  onCloneTab: handleCloneTab,
}: RecentTransfersParams & { tabId: string }) {
  const [transfer, setTransfer] = useState<Transfer | undefined>();
  const { isRole } = useRoleAuth();
  const canCreateBalanceTransaction = isRole(ROLE.TALOS_SUPPORT) || isRole(ROLE.DEALER);

  const newBalanceTransactionDialog = useDisclosure();

  // reset form state when opening new
  const openNewBalanceTransactionDialog = useCallback(
    (transfer?: Transfer) => {
      setTransfer(transfer);
      newBalanceTransactionDialog.open();
    },
    [newBalanceTransactionDialog]
  );

  const newTabDefaults = useMemo(() => {
    return {
      defaultFilter: {
        _start: LookbackOption.Past30Days,
      },
      defaultColumns,
    } satisfies TransfersTabProps;
  }, [defaultColumns]);

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams<Transfer>) => {
    const transferMenuItems: MenuItemDef[] = [];
    if (canCreateBalanceTransaction) {
      const transfer = params?.node?.data;
      if (transfer != null && !transfer.ParentTransactionID && !transfer?.isTransferOutgoing) {
        transferMenuItems.push({
          name: 'Create Customer Deposit',
          action: () => {
            openNewBalanceTransactionDialog(transfer);
          },
          icon: `<i class="ag-icon ${IconName.DocumentDownload}"/>`,
        });
      }
    }
    return compact([...transferMenuItems]);
  });

  return (
    <>
      <AccordionGroup key={tabId}>
        <FilteredTransfers
          key={tabId}
          blotterID={blotterID}
          defaultColumns={defaultColumns ?? newTabDefaults.defaultColumns}
          defaultFilter={defaultFilter ?? newTabDefaults.defaultFilter}
          onCloneTab={handleCloneTab}
          initialIsOpen={initialIsOpen}
          getContextMenuItems={getContextMenuItems}
        />
      </AccordionGroup>
      <NewBalanceTransactionDialog
        {...newBalanceTransactionDialog}
        entity={transfer}
        transactionType={CustomerLedgerUpdateTypeEnum.Deposit}
      />
    </>
  );
}

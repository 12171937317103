import {
  abbr,
  Benchmark,
  currencySymbols,
  HelpIcon,
  MixpanelEvent,
  MixpanelEventProperty,
  Text,
  toBig,
  useMixpanel,
  type Direction,
} from '@talos/kyoko';
import type { BigSource } from 'big.js';
import type { ReactNode } from 'react';
import { KPIWrapper, Label, Suffix, Value, ValueRow } from './styles';

export enum KPITypes {
  Percent = 'Percent',
  Bps = 'Bps',
  Currency = 'Currency',
  Duration = 'Duration',
  Number = 'Number',
}

interface KPIProps {
  label: string;
  value: BigSource | undefined;
  type?: KPITypes;
  tooltip?: ReactNode;
  currency?: string;
  benchmark?: BigSource | undefined;
  isLoading?: boolean;
  isEstimate?: boolean;
  positiveDirection: Direction;
  precision?: number;
  dataTestID?: string;
}

export function KPI({
  label,
  value,
  type,
  currency,
  benchmark,
  isLoading,
  positiveDirection,
  tooltip,
  isEstimate,
  precision = 3,
  dataTestID,
}: KPIProps) {
  const mixpanel = useMixpanel();
  const numbericValue = toBig(value)?.toNumber() ?? 0;
  const { number, suffix } = abbr(numbericValue, precision);
  let unit = '';
  if (type === KPITypes.Currency) {
    unit = currency && !currencySymbols[currency as keyof typeof currencySymbols] ? currency : '';
  } else if (type === KPITypes.Bps) {
    unit = 'bps';
  } else if (type === KPITypes.Percent) {
    unit = '%';
  }
  const suffixUnit = `${suffix ?? ''} ${unit}`;

  return (
    <KPIWrapper isLoading={isLoading} data-testid={dataTestID}>
      {!isLoading && (
        <>
          <Label>
            <Text verticalAlign="middle">{label}</Text>{' '}
            {tooltip && (
              <HelpIcon
                tooltip={tooltip}
                onOpen={() =>
                  mixpanel.track(MixpanelEvent.ViewReportTooltip, {
                    [MixpanelEventProperty.Title]: label,
                  })
                }
              />
            )}
          </Label>
          <ValueRow>
            <Value>
              {value != null ? (
                <>
                  {isEstimate && <Suffix>~ </Suffix>}
                  {type === KPITypes.Currency && currency && (
                    <Suffix>{currencySymbols[currency as keyof typeof currencySymbols]}</Suffix>
                  )}
                  {number}
                  {suffixUnit && <Suffix>{suffixUnit}</Suffix>}
                </>
              ) : (
                <Suffix>N/A</Suffix>
              )}
            </Value>
            {benchmark && <Benchmark value={value} benchmark={benchmark} positiveDirection={positiveDirection} />}
          </ValueRow>
        </>
      )}
    </KPIWrapper>
  );
}

import {
  Box,
  CopyButton,
  EMPTY_ARRAY,
  HStack,
  Icon,
  IconName,
  IndicatorBadge,
  InlineFormattedNumber,
  NotificationVariants,
  NumberVariants,
  ProductTypeEnum,
  QuoteSide,
  Table,
  TableSize,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  WarningSeverity,
  WarningSeverityIcon,
  getLegFilledQtyAndNotional,
  getOrderIncrements,
  legSummaryHasUnhedgedAmt,
  setAlpha,
  useCurrenciesContext,
  useSecuritiesContext,
  type AmountObject,
  type BoxProps,
  type IOMSExecutionReport4203LegSummary,
  type Order,
  type RequiredProperties,
  type Security,
} from '@talos/kyoko';
import Big from 'big.js';
import { compact } from 'lodash-es';
import styled, { useTheme } from 'styled-components';

import { toBigWithDefault } from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { isMultiLegSecurity } from '../../utils/security';

export type MultilegTableProps = {
  order: RequiredProperties<Order, 'legSummaryLegs' | 'legSummaryParent'>;
  orderSecurity: Security;
} & BoxProps;

export const MultilegTable = ({ order, orderSecurity, ...boxProps }: MultilegTableProps) => {
  const theme = useTheme();
  const { securitiesBySymbol } = useSecuritiesContext();
  const { currenciesBySymbol } = useCurrenciesContext();

  const legs =
    (isMultiLegSecurity(orderSecurity) ? orderSecurity.MultilegDetails.Legs : orderSecurity.Legs) || EMPTY_ARRAY;
  const securityByLegIndex: (Security | undefined)[] = useMemo(
    () => legs.map(leg => securitiesBySymbol.get(leg.Symbol)),
    [legs, securitiesBySymbol]
  );

  const legSummaryLegs = useMemo(() => order.legSummaryLegs, [order.legSummaryLegs]);

  const nativeLegSummaries = useMemo(
    () =>
      order.legSummaryParent.filter(
        leg => securitiesBySymbol.get(leg.Symbol)?.ProductType !== ProductTypeEnum.Synthetic
      ),
    [order.legSummaryParent, securitiesBySymbol]
  );

  const mlLegSummaries = useMemo(
    () =>
      order.legSummaryParent.filter(
        leg => securitiesBySymbol.get(leg.Symbol)?.ProductType === ProductTypeEnum.Synthetic
      ),
    [order.legSummaryParent, securitiesBySymbol]
  );

  const isOrderLegged = !!order.RiskStatus?.Legged;
  // If this is true, it would be regarded as a bug since multiple legs shouldn't be unhedged at the same time.
  const isMultipleUnhedged = useMemo(
    () => legSummaryLegs.filter(leg => leg.UnhedgedAmt && !Big(leg.UnhedgedAmt).eq(0)).length > 1,
    [legSummaryLegs]
  );

  const createRow = useCallback(
    (leg: IOMSExecutionReport4203LegSummary, isLeg: boolean) => {
      // If multiple legs are unhedged, show only first leg.
      const showUnhedged = Boolean(
        isOrderLegged && leg.UnhedgedAmt && !Big(leg.UnhedgedAmt).eq(0) && (!isMultipleUnhedged || leg.LegIndex === 0)
      );

      const rowSecurity = isLeg ? securityByLegIndex[leg.LegIndex] : securitiesBySymbol.get(leg.Symbol);
      const currentLegDetails = legs.at(leg.LegIndex);
      const filledPercent = (
        toBigWithDefault(order.OrderQty, 0).gt(0) ? toBigWithDefault(leg.ParentCumQty, 0).div(order.OrderQty) : Big(0)
      )
        .times(100)
        .toNumber()
        .toFixed(2);

      const Symbol = rowSecurity?.DisplaySymbol || currentLegDetails?.Symbol;
      if (!Symbol) {
        return null;
      }

      const { filledQty, filledNotional } = getLegFilledQtyAndNotional(leg, rowSecurity);

      const orderIncrements = getOrderIncrements(
        {
          Currency: filledQty.currency,
          Symbol,
        },
        rowSecurity,
        currenciesBySymbol?.get(filledQty.currency)
      );

      return {
        Symbol,
        legSecurity: rowSecurity,
        filledQty,
        filledNotional,
        filledPercent,
        showUnhedged,
        leg,
        orderIncrements,
        isNative: rowSecurity?.ProductType !== ProductTypeEnum.Synthetic && !isLeg,
        isParent: rowSecurity?.ProductType === ProductTypeEnum.Synthetic && !isLeg,
      };
    },
    [
      isMultipleUnhedged,
      isOrderLegged,
      legs,
      order.OrderQty,
      securityByLegIndex,
      currenciesBySymbol,
      securitiesBySymbol,
    ]
  );

  const rows = useMemo(() => {
    return compact([
      ...mlLegSummaries.map(leg => createRow(leg, false)),
      ...legSummaryLegs.map(syntheticLeg => createRow(syntheticLeg, true)),
      ...(nativeLegSummaries ? nativeLegSummaries.map(leg => createRow(leg, false)) : []),
    ]);
  }, [createRow, legSummaryLegs, nativeLegSummaries, mlLegSummaries]);

  return (
    <TableWrapper {...boxProps}>
      <CustomTable insetPadding="spacingComfortable" size={TableSize.Small}>
        <Thead>
          <Tr>
            <Th>Leg</Th>
            <Th>Symbol</Th>
            <Th align="left">Side</Th>
            <Th>Filled Qty</Th>
            <Th>Filled Price</Th>
            <Th align="right">Filled Amt</Th>
            <Th align="right">Filled %</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows.map(
            ({
              Symbol,
              filledQty,
              filledNotional,
              filledPercent,
              showUnhedged,
              leg,
              orderIncrements,
              legSecurity,
              isNative,
              isParent,
            }) => {
              return (
                <Tr key={leg.Symbol} background={isNative || isParent ? 'transparent' : 'gray.030'}>
                  <Td minimize>
                    {isNative || isParent ? (
                      <Tooltip tooltip="Unified Liquidity">
                        <IndicatorBadge
                          w={18}
                          h={18}
                          children={
                            <Icon
                              icon={isNative ? IconName.Database : IconName.Share}
                              rotate={isNative ? -90 : 90}
                              style={{ margin: `0 -${theme.spacingSmall}px` }}
                            />
                          }
                        />
                      </Tooltip>
                    ) : (
                      <IndicatorBadge w={18} h={18} children={leg.LegIndex + 1} />
                    )}
                  </Td>

                  <Td color="colorTextImportant" width="auto">
                    <Box overflow="hidden" display="inline-flex" alignItems="center" gap="spacingSmall">
                      {!(isNative || isParent) && <Icon icon={IconName.Connector} color="colorTextMuted" size={18} />}
                      <span>
                        <MultilegTableSymbolDisplay symbol={Symbol} />
                      </span>
                    </Box>
                  </Td>
                  <Td align="left">
                    <QuoteSide side={leg.Side} />
                  </Td>
                  <Td>
                    <InlineFormattedNumber
                      pretty
                      number={filledQty.value}
                      currency={filledQty.currency}
                      increment={orderIncrements.quantityIncrement}
                      specification={legSecurity?.SizeDisplaySpec}
                    />
                  </Td>
                  <Td>
                    <InlineFormattedNumber
                      pretty
                      number={leg.AvgPx || '0'}
                      currency={legSecurity?.QuoteCurrency}
                      increment={legSecurity?.DefaultPriceIncrement}
                      specification={legSecurity?.PriceDisplaySpec}
                    />
                  </Td>
                  <Td align="right">
                    {showUnhedged && legSummaryHasUnhedgedAmt(leg) ? (
                      <FilledAmount filledNotional={filledNotional} legSecurity={legSecurity} leg={leg} />
                    ) : (
                      <InlineFormattedNumber
                        pretty
                        number={filledNotional.value}
                        currency={filledNotional.currency}
                        increment={legSecurity?.DefaultPriceIncrement}
                        specification={legSecurity?.PriceDisplaySpec}
                      />
                    )}
                  </Td>

                  <Td color="colorTextImportant" align="right">
                    <InlineFormattedNumber pretty round number={filledPercent} currency="%" />
                  </Td>
                </Tr>
              );
            }
          )}
        </Tbody>
      </CustomTable>
    </TableWrapper>
  );
};

const MultilegTableSymbolDisplay = ({ symbol }: { symbol: string }) => {
  if (symbol.length > 8) {
    return (
      <Tooltip
        tooltip={<Text color="colorTextImportant">{symbol}</Text>}
        targetStyle={{ whiteSpace: 'nowrap' }}
        usePortal
      >
        {symbol}
      </Tooltip>
    );
  }
  return <>{symbol}</>;
};

const CustomTable = styled(Table)`
  ${Tbody} > ${Tr} {
    border-top: 1px solid ${({ theme }) => setAlpha(0.12, theme.colors.gray[100])};
  }

  ${Th} {
    text-transform: none;
    font-size: ${({ theme }) => theme.fontSizeXs}rem;
  }

  ${Tbody} ${Td}, ${Thead} ${Th} {
    padding: ${({ theme }) => theme.spacingDefault}px;
  }
`;

const FilledAmount = ({
  filledNotional,
  legSecurity,
  leg,
}: {
  filledNotional: AmountObject;
  legSecurity?: Security;
  leg: RequiredProperties<IOMSExecutionReport4203LegSummary, 'UnhedgedAmt'>;
}) => {
  const { UnhedgedAmt, UnhedgedAmtCurrency } = leg;

  return (
    <HStack justifyContent="flex-end" gap="spacingTiny">
      <Tooltip
        usePortal
        tooltip={
          <HStack justifyContent="space-between" gap="spacingSmall">
            <span>
              {'Unhedged Amount: '}
              <Text color="colorTextImportant">{`${UnhedgedAmt} ${UnhedgedAmtCurrency ?? ''}`}</Text>
            </span>
            <CopyButton
              value={UnhedgedAmt}
              toastProps={{ text: `Copied ${UnhedgedAmt}`, variant: NotificationVariants.Positive }}
            />
          </HStack>
        }
      >
        <WarningSeverityIcon severity={WarningSeverity.MEDIUM} />
      </Tooltip>
      <InlineFormattedNumber
        variant={NumberVariants.Warning}
        pretty
        number={filledNotional.value}
        currency={filledNotional.currency}
        increment={legSecurity?.DefaultPriceIncrement}
        specification={legSecurity?.PriceDisplaySpec}
      />
    </HStack>
  );
};

export const TableWrapper = styled(Box)`
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  background: ${({ theme }) => theme.backgroundCard};
  width: 100%;
`;

import {
  request,
  useEndpointsContext,
  type IHedgeRule,
  type MinimalSubscriptionResponse,
  type ModeEnum,
} from '@talos/kyoko';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';
import type {
  GlobalHedgeCommandPayload,
  HedgeCommandResponse,
  HedgeRulePatchPayload,
  HedgeRulePostPayload,
  SingleHedgeCommandPayload,
} from '../../../../components/AutoHedging/types';

const HEDGE_RULE_ID = 'HEDGE_RULE' as const;

const generateHedgeRuleQueryID = (id?: string) => {
  return [HEDGE_RULE_ID, '/', id];
};

export function useHedgeRuleQuery(id?: string) {
  const { orgApiEndpoint } = useEndpointsContext();
  const queryClient = useQueryClient();

  return useQuery<IHedgeRule>({
    enabled: !!id,
    queryKey: generateHedgeRuleQueryID(id),
    staleTime: 0,
    refetchInterval: 10_000,
    placeholderData: () => {
      const allHedgeRulesQueryData = queryClient.getQueryData<ReturnType<typeof useHedgeRulesQuery>['data']>([
        HEDGE_RULE_ID,
      ]);
      if (allHedgeRulesQueryData) {
        return allHedgeRulesQueryData.data.find(hedgeRule => hedgeRule.HedgeRuleID === id);
      }
    },
    queryFn: async () =>
      request<MinimalSubscriptionResponse<IHedgeRule>>('GET', `${orgApiEndpoint}/hedge-rules/${id}`).then(response => {
        return response.data[0];
      }),
  });
}

export function useHedgeRulesQuery() {
  const { orgApiEndpoint } = useEndpointsContext();

  return useQuery<MinimalSubscriptionResponse<IHedgeRule>>({
    queryKey: [HEDGE_RULE_ID],
    queryFn: async () => request<MinimalSubscriptionResponse<IHedgeRule>>('GET', `${orgApiEndpoint}/hedge-rules`),
  });
}

export type HedgeRuleCreationMutationArgs = HedgeRulePostPayload & { Mode: ModeEnum };
export function useHedgeRuleCreationMutation() {
  const { orgApiEndpoint } = useEndpointsContext();
  const queryClient = useQueryClient();
  return useMutation<IHedgeRule, undefined, HedgeRuleCreationMutationArgs>({
    mutationFn: async payload =>
      request<MinimalSubscriptionResponse<IHedgeRule>>('POST', `${orgApiEndpoint}/hedge-rules`, payload).then(res => {
        return res.data[0];
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [HEDGE_RULE_ID] });
    },
  });
}

type HedgeRuleModificationMutationArgs = {
  id: string;
  payload: HedgeRulePatchPayload;
};
export function useHedgeRuleModificationMutation() {
  const { orgApiEndpoint } = useEndpointsContext();
  const queryClient = useQueryClient();
  return useMutation<IHedgeRule, undefined, HedgeRuleModificationMutationArgs>({
    mutationFn: async ({ id, payload }) =>
      request<MinimalSubscriptionResponse<IHedgeRule>>('PATCH', `${orgApiEndpoint}/hedge-rules/${id}`, payload).then(
        res => {
          return res.data[0];
        }
      ),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [HEDGE_RULE_ID] });
    },
  });
}
type HedgeRuleCommandMutationArgs = SingleHedgeCommandPayload | GlobalHedgeCommandPayload;
export function useHedgeRuleCommandMutation() {
  const { orgApiEndpoint } = useEndpointsContext();
  const queryClient = useQueryClient();
  return useMutation<HedgeCommandResponse, undefined, HedgeRuleCommandMutationArgs>({
    mutationFn: async payload => {
      const HedgeCommandID = uuid();
      return request<MinimalSubscriptionResponse<HedgeCommandResponse>>('POST', `${orgApiEndpoint}/hedge-command`, {
        ...payload,
        HedgeCommandID,
      }).then(res => res.data[0]);
    },
    onSettled: (_data, _err, origPayload) => {
      if ('HedgeRuleID' in origPayload) {
        queryClient.invalidateQueries({ queryKey: generateHedgeRuleQueryID(origPayload.HedgeRuleID) });
      }
    },
    mutationKey: ['HedgeRuleCommand'] as const,
  });
}

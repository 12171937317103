import { clamp } from 'lodash-es';
import { useCallback, useMemo, useRef } from 'react';
import { useStaticTabsStorage, type TabsState } from './TabsStorage';
import type { TabProps } from './types';
import type { UseTabsProps } from './useTabs';

interface PersistedTabProps<T extends TabProps> extends Pick<Partial<UseTabsProps<T>>, 'onSelect' | 'onItemsChanged'> {
  defaultInitialSelectedIndex?: number;
  defaultInitialItems?: T[];
  // Don't persist tabs matching these IDs
  excludedIDs?: string[];
}

export function usePersistedTabs<T extends TabProps = TabProps>(
  tabsID: string,
  {
    defaultInitialSelectedIndex = -1,
    defaultInitialItems = [],
    onSelect,
    onItemsChanged,
    excludedIDs,
  }: PersistedTabProps<T>
): Required<Pick<UseTabsProps<T>, 'initialSelectedIndex' | 'onSelect' | 'onItemsChanged'>> & {
  initialItems: T[];
} {
  const { getState, setItems, setSelectedIndex } = useStaticTabsStorage();

  const handleSelect = useCallback(
    (index: number, tabs: T[]) => {
      setSelectedIndex(tabsID, index);
      onSelect?.(index, tabs);
    },
    [onSelect, setSelectedIndex, tabsID]
  ) satisfies UseTabsProps<T>['onSelect'];

  const handleItemsChanged = useCallback(
    (items: T[]) => {
      const tabs = items.filter(tab => !tab.isAddingTab && !tab.isTemporary);
      if (excludedIDs == null || excludedIDs?.length === 0) {
        setItems(tabsID, tabs);
      } else {
        setItems(
          tabsID,
          tabs.filter(tab => !excludedIDs.includes(tab.id as string))
        );
      }
      onItemsChanged?.(items);
    },
    [onItemsChanged, setItems, tabsID, excludedIDs]
  );

  const defaultInitialSelectedIndexRef = useRef(defaultInitialSelectedIndex);
  const defaultInitialItemsRef = useRef(defaultInitialItems);

  return useMemo(() => {
    const { items: initialItems, selectedIndex: initialSelectedIndex } = getPersistedTabs<T>(
      getState,
      tabsID,
      defaultInitialItemsRef.current,
      defaultInitialSelectedIndexRef.current,
      excludedIDs
    );

    return {
      initialItems,
      initialSelectedIndex,
      onSelect: handleSelect,
      onItemsChanged: handleItemsChanged,
    };
  }, [getState, tabsID, handleSelect, handleItemsChanged, excludedIDs]);
}

function getPersistedTabs<T extends TabProps = TabProps>(
  getState: (tabsID: string) => TabsState<TabProps> | null,
  tabsID: string,
  defaultInitialItems: T[],
  defaultInitialSelectedIndex: number,
  excludedIDs: string[] | undefined
) {
  let items: T[];
  const persisted = getState(tabsID);
  const defaultExcludedInitialItems = defaultInitialItems.filter(tab => tab.id && excludedIDs?.includes(tab.id));
  if (persisted?.items) {
    items = [
      ...defaultExcludedInitialItems,
      ...(persisted.items as T[]).map((item): T => ({ ...item, reorderable: true })),
    ];
  } else {
    items = defaultInitialItems;
  }

  const selectedIndex = clamp(
    persisted?.selectedIndex ?? defaultInitialSelectedIndex,
    0,
    items.length + defaultExcludedInitialItems.length - 1
  );
  return { items, selectedIndex };
}

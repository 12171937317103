export const Logo = ({ fill }: React.SVGProps<SVGPathElement>) => (
  <>
    <path
      d="M198,55.7993615 C197.481168,55.5889443 143.55066,48.2383725 90.2371423,82.5924763 C57.1300367,103.914746 52.7409964,135.140648 54.2554257,156 C131.757744,147.274703 196.233166,58.254228 198,55.7993615 Z"
      id="Path"
      fillOpacity="0.8"
      fill={fill}
      fillRule="nonzero"
    />
    <path
      d="M59.5341019,179.463299 C66.2584324,186.614919 103.235231,220.88075 161.269432,181.110424 C203.243855,148.33686 202.190985,77.2852362 198.162002,55.8162991 C196.758175,58.9416132 148.059422,165.990661 59.5341019,179.463299 Z"
      id="Path"
      fillOpacity="0.5"
      fill={fill}
    />
  </>
);

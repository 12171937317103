import { type ReactNode, useCallback } from 'react';
import { type BoxProps, Flex } from '../Core';
import { Icon, IconName } from '../Icons';

export type SortDirection = '+' | '-';
/** The state of some sort */
export interface SortState<Keys> {
  /** What we're sorting on */
  key: Keys;
  /** The direction of the sort */
  direction: SortDirection;
}

type SortableBoxProps<Keys> = Omit<BoxProps, 'onChange'> & {
  /** The keys you can sort on */
  sortKey: Keys;
  /** The current sort state */
  sort: SortState<Keys> | undefined;
  /** Change handler called when the sort is changed (this component is clicked) */
  onChange?: (sort: SortState<Keys> | undefined) => void;
  /** Where to place the rendered sorting arrow. Defaults to "right". */
  arrowPlacement?: 'right' | 'left';
  children: ReactNode;
};

/**
 * Wraps your components and makes it click-to-sort-able.
 */
export function SortableBox<Keys>({
  sortKey,
  sort,
  onChange,
  children,
  arrowPlacement = 'right',
  ...boxProps
}: SortableBoxProps<Keys>) {
  const sortingEnabled = onChange != null;
  const handleClick = useCallback(() => {
    onChange?.({
      key: sortKey,
      direction: sort?.key === sortKey ? getOtherSortDirection(sort.direction) : '-',
    });
  }, [sortKey, sort, onChange]);

  return (
    <Flex
      onClick={handleClick}
      {...boxProps}
      flexDirection={arrowPlacement === 'right' ? 'row' : 'row-reverse'}
      alignItems="center"
      cursor={sortingEnabled ? 'pointer' : 'default'}
    >
      {children}
      {sortKey === sort?.key && <Icon icon={sort.direction === '-' ? IconName.ArrowUp : IconName.ArrowDown} />}
    </Flex>
  );
}

function getOtherSortDirection(direction: SortDirection): SortDirection {
  return direction === '+' ? '-' : '+';
}

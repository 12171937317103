import {
  DEFAULT_MAX_ROWS,
  TRADE,
  TRADE_SETTLE_REPORT,
  useConstant,
  useDynamicCallback,
  useSubscription,
  useWsPaginationLimiter,
  wsStitchWith,
  type LimitReachedChangeEvent,
  type Trade,
  type TradeSettleReport,
  type WebsocketRequest,
} from '@talos/kyoko';
import { isEqual } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { OrgConfigurationKey, useOrgConfiguration } from '../../../../../providers';
import { SettleableTrade } from './types';

interface TradeSettlementBlotterServerFilter {
  StartDate?: string;
  EndDate?: string;
}

interface TradeRequest extends WebsocketRequest, TradeSettlementBlotterServerFilter {
  Markets?: string[];
}

interface TradeSettleReportRequest extends WebsocketRequest, TradeSettlementBlotterServerFilter {}

interface UseTradeSettlementBlotterObsParams {
  serverFilter: TradeSettlementBlotterServerFilter;
}

export const useTradeSettlementBlotterObs = ({ serverFilter }: UseTradeSettlementBlotterObsParams) => {
  const [tradeRequest, setTradeRequest] = useState<TradeRequest | null>(null);
  const [tradeSettleReportRequest, setTradeSettleReportRequest] = useState<TradeSettleReportRequest | null>(null);

  useEffect(() => {
    setTradeRequest(currentRequest => {
      const maybeNewRequest: TradeRequest = {
        name: TRADE,
        tag: 'Trade settlement blotter',
        sort_by: '-TransactTime',
        Markets: ['dealer'],
        ...serverFilter,
      };

      return isEqual(currentRequest, maybeNewRequest) ? currentRequest : maybeNewRequest;
    });

    setTradeSettleReportRequest(currentRequest => {
      const maybeNewRequest: TradeRequest = {
        name: TRADE_SETTLE_REPORT,
        tag: 'Trade settlement blotter',
        sort_by: '-TradeTransactTime',
        ...serverFilter,
      };

      return isEqual(currentRequest, maybeNewRequest) ? currentRequest : maybeNewRequest;
    });
  }, [serverFilter]);

  const { data: tradeObs, nextPage: tradeNextPage } = useSubscription<Trade>(tradeRequest, { loadAll: false });
  const { data: tsrObs, nextPage: tsrNextPage } = useSubscription<TradeSettleReport>(tradeSettleReportRequest, {
    loadAll: false,
  });

  const { getConfig } = useOrgConfiguration();
  // Get a constant limit (user has to refresh for it to change)
  // Divide the default by two because the max is being applied to two streams as opposed to one
  const limit = useConstant(getConfig(OrgConfigurationKey.CustomerSettlementTradeRowsMax, DEFAULT_MAX_ROWS));

  const [limitReachedState, setLimitReachedState] = useState<LimitReachedChangeEvent<Trade> | undefined>(undefined);

  const tradesPaginationLimiter = useWsPaginationLimiter<Trade>({
    startingLimit: limit,
    nextPage: tradeNextPage,
    onLimitReachedChange: setLimitReachedState,
  });

  const tsrsPaginationLimiter = useWsPaginationLimiter<TradeSettleReport>({
    startingLimit: limit,
    nextPage: tsrNextPage,
  });

  const pagedTrades = useMemo(
    () => tradeObs.pipe(tradesPaginationLimiter.wsPaginationLimiterInstance),
    [tradeObs, tradesPaginationLimiter.wsPaginationLimiterInstance]
  );

  const pagedTSRs = useMemo(
    () => tsrObs.pipe(tsrsPaginationLimiter.wsPaginationLimiterInstance),
    [tsrObs, tsrsPaginationLimiter.wsPaginationLimiterInstance]
  );

  const raisePaginationLimits = useDynamicCallback(() => {
    tradesPaginationLimiter.raiseLimit(limit);
    tsrsPaginationLimiter.raiseLimit(limit);
  });

  const stitchedDataObs = useMemo(
    () =>
      pagedTrades.pipe(
        wsStitchWith({
          secondarySource: pagedTSRs,
          getPrimaryTypeKey: item => item.TradeID,
          getSecondaryTypeKey: item => item.TradeID,
          stitch: (trade, tsr) => SettleableTrade.fromTradeAndTSR(trade, tsr),
        })
      ),
    [pagedTrades, pagedTSRs]
  );

  return { dataObservable: stitchedDataObs, raisePaginationLimits, limitReachedState };
};

import {
  AggregationType,
  Box,
  ConnectionType,
  Dialog,
  Flex,
  Input,
  MarketAccountTypeEnum,
  ModeEnum,
  NotificationVariants,
  Text,
  VStack,
  useDisclosure,
  useGlobalToasts,
  useMarketAccountsContext,
  type DialogProps,
} from '@talos/kyoko';
import { MarketAccountSelectionList } from 'components/MarketAccountSelectionList';
import { TitleRow } from 'containers/Dealer/styles';
import { uniq } from 'lodash-es';

import { forwardRef, useCallback, useMemo, useState } from 'react';
import { useSetState } from 'react-use';
import type { AggregationMarket, AggregationWithAccounts } from 'types';
import { useAggregationRequests } from '../../../hooks/useAggregationRequests';

export type AddEditAggregationDialogProps = DialogProps & {
  values: AggregationWithAccounts;
  onChange(
    patch: Partial<AggregationWithAccounts> | ((prevState: AggregationWithAccounts) => Partial<AggregationWithAccounts>)
  ): void;
  onAdd(): void;
  onEdit(aggregation: AggregationWithAccounts): void;
  onSave(aggregation: AggregationWithAccounts): Promise<void>;
  isSaving: boolean;
  isAdding: boolean;
};

export const NewAggregationDialog = forwardRef<HTMLDivElement | null, AddEditAggregationDialogProps>(
  function NewAggregationDialog(
    { onSave, isAdding, isSaving, close, onChange, values, ...props }: AddEditAggregationDialogProps,
    ref
  ) {
    const handleConfirm = () =>
      onSave?.({
        Name: values.Name,
        DisplayName: values.DisplayName,
        AggregationType: AggregationType.User,
        FairPriceProtection: values.FairPriceProtection,
        Accounts: values.Accounts,
      });
    const { marketAccountsByName } = useMarketAccountsContext();

    const tradingMarketAccountsByName = useMemo(() => {
      const tradingMarketAccounts = new Map(marketAccountsByName);
      for (const [name, marketAccount] of tradingMarketAccounts) {
        if (marketAccount.Type !== MarketAccountTypeEnum.Trading) {
          tradingMarketAccounts.delete(name);
        }
      }
      return tradingMarketAccounts;
    }, [marketAccountsByName]);

    const handleMarketAccountsChange = useCallback(
      (marketAccounts: string[]) => {
        const newValues: Map<string, AggregationMarket> = new Map();
        for (const account of marketAccounts) {
          const accountObject = tradingMarketAccountsByName.get(account);
          if (accountObject) {
            newValues.set(account, {
              Aggregation: values.Name,
              Market: accountObject.Market,
              Mode: ModeEnum.Enabled,
              MarketAccount: account,
            });
          }
        }
        for (const [name, currentAccount] of values.Accounts) {
          if (!marketAccounts.includes(currentAccount.MarketAccount || '')) {
            newValues.set(name, {
              ...currentAccount,
              Mode: ModeEnum.Disabled,
            });
          }
        }
        onChange({ Accounts: newValues });
      },
      [values, tradingMarketAccountsByName, onChange]
    );

    const availableMarketAccounts = useMemo(
      () => Array.from(tradingMarketAccountsByName.keys()),
      [tradingMarketAccountsByName]
    );
    const availableMarkets = useMemo(() => {
      return uniq(Array.from(tradingMarketAccountsByName.values()).map(account => account.Market));
    }, [tradingMarketAccountsByName]);
    const selectedAccounts = useMemo(() => {
      return Array.from(values.Accounts.values())
        .filter(a => a.Mode === ModeEnum.Enabled)
        .map(account => account.MarketAccount || '');
    }, [values]);
    const selectedMarkets = useMemo(() => {
      return uniq(
        Array.from(values.Accounts.values())
          .filter(a => a.Mode === ModeEnum.Enabled)
          .map(account => account.Market || '')
      );
    }, [values]);

    return (
      <Dialog
        {...props}
        ref={ref}
        onConfirm={handleConfirm}
        close={close}
        width={400}
        title={`${isAdding ? 'New' : 'Edit'} Aggregation`}
        confirmLabel="Save"
        alignContent="left"
        confirmDisabled={!values?.DisplayName || !values?.Name || isSaving}
        confirmLoading={isSaving}
        autoFocusFirstElement={false}
        showClose={true}
      >
        <VStack alignItems="stretch" gap="spacingMedium">
          <Box>
            <TitleRow>
              <Text>Name</Text>
            </TitleRow>
            <Input
              autoComplete="off"
              value={values?.Name ?? ''}
              onChange={e => onChange({ Name: e.target.value })}
              data-testid="aggregation-name-input"
              disabled={!isAdding} // can only modify the .Name field when we're adding a new aggregation. .Name is a key.
            />
          </Box>
          <Box>
            <TitleRow>
              <Text>Display Name</Text>
            </TitleRow>
            <Input
              autoComplete="off"
              value={values?.DisplayName ?? ''}
              onChange={e => onChange({ DisplayName: e.target.value })}
              data-testid="aggregation-display-name-input"
            />
          </Box>
          <Box h="300px">
            <TitleRow>
              <Text>Market Accounts</Text>
            </TitleRow>
            <Flex h="100%">
              <MarketAccountSelectionList
                availableMarketAccounts={availableMarketAccounts}
                availableMarkets={availableMarkets}
                selectedMarketAccounts={selectedAccounts}
                selectedMarkets={selectedMarkets}
                onChangeMarketAccounts={handleMarketAccountsChange}
                connectionType={ConnectionType.MarketData}
                showInactiveMarketAccounts={false}
                scrollContent={true}
                showBuyingPower={false}
                data-testid="aggregation-market-selector"
              />
            </Flex>
          </Box>
        </VStack>
      </Dialog>
    );
  }
);

export function useAddEditAggregationDialog({ isAdding }: { isAdding: boolean }): AddEditAggregationDialogProps {
  const dialog = useDisclosure();
  const { createAggregation, updateAggregation, upsertAggregationMarkets } = useAggregationRequests();
  const [isSaving, setIsSaving] = useState(false);
  const [startingAccounts, setStartingAccounts] = useState<Map<string, AggregationMarket>>(new Map());
  const { add: addToast } = useGlobalToasts();

  const [values, onChange] = useSetState<AggregationWithAccounts>({
    Name: '',
    DisplayName: '',
    AggregationType: AggregationType.User,
    FairPriceProtection: true,
    Accounts: new Map(),
  });

  const setAggregation = (
    name: string,
    account: AggregationMarket,
    marketsForUpdate: Map<
      string,
      {
        account: string;
        mode: ModeEnum;
      }
    >,
    accountsForUpdate: Map<string, Partial<AggregationMarket>>
  ) => {
    const marketName = account.Market || '';
    accountsForUpdate.set(name, {
      Mode: account.Mode,
      Market: name,
      Aggregation: account.Aggregation,
    });
    marketsForUpdate.set(marketName, {
      mode: account.Mode,
      account: name,
    });
  };

  const addToUpdate = useCallback(
    (
      name: string,
      account: AggregationMarket,
      marketsForUpdate: Map<
        string,
        {
          account: string;
          mode: ModeEnum;
        }
      >,
      accountsForUpdate: Map<string, Partial<AggregationMarket>>
    ) => {
      const marketName = account.Market || '';
      if (marketsForUpdate.has(marketName)) {
        if (marketsForUpdate.get(marketName)!.mode === ModeEnum.Disabled) {
          accountsForUpdate.delete(marketsForUpdate.get(marketName)!.account);
          setAggregation(name, account, marketsForUpdate, accountsForUpdate);
        }
      } else {
        setAggregation(name, account, marketsForUpdate, accountsForUpdate);
      }
    },
    []
  );

  const addOrUpdateMarkets = useCallback(async () => {
    const accountsForUpdate: Map<string, Partial<AggregationMarket>> = new Map();
    const marketsForUpdate: Map<
      string,
      {
        account: string;
        mode: ModeEnum;
      }
    > = new Map();
    for (const [name, account] of values.Accounts) {
      if (!startingAccounts.has(name)) {
        addToUpdate(name, account, marketsForUpdate, accountsForUpdate);
      } else {
        const startingAccount = startingAccounts.get(name)!;
        if (startingAccount.Mode !== account.Mode) {
          addToUpdate(name, account, marketsForUpdate, accountsForUpdate);
        }
      }
    }

    await upsertAggregationMarkets([...accountsForUpdate.values()]);
  }, [startingAccounts, upsertAggregationMarkets, values.Accounts, addToUpdate]);

  const { open } = dialog;
  const onSave = useCallback(async () => {
    setIsSaving(true);
    try {
      await (isAdding ? createAggregation(values) : updateAggregation(values));
      await addOrUpdateMarkets();
      addToast({
        text: `Aggregation ${isAdding ? 'added' : 'updated'}.`,
        variant: NotificationVariants.Positive,
      });
    } catch (e) {
      addToast({
        text: (e as Error).message,
        variant: NotificationVariants.Negative,
      });
      // Prevent dialog from auto-closing
      throw e;
    } finally {
      setIsSaving(false);
    }
  }, [addToast, values, createAggregation, updateAggregation, isAdding, addOrUpdateMarkets]);

  const onAdd = useCallback(() => {
    onChange({
      Name: '',
      DisplayName: '',
      AggregationType: AggregationType.User,
      FairPriceProtection: true,
      Accounts: new Map(),
    });
    setStartingAccounts(new Map());
    open();
  }, [open, onChange]);

  const onEdit = useCallback(
    (aggregation: AggregationWithAccounts) => {
      onChange(aggregation);
      setStartingAccounts(new Map(aggregation.Accounts));
      open();
    },
    [open, onChange]
  );

  return useMemo(
    () => ({
      ...dialog,
      values,
      onChange,
      onAdd,
      onEdit,
      onSave,
      isSaving,
      isAdding,
    }),
    [dialog, values, onChange, onAdd, onEdit, onSave, isAdding, isSaving]
  );
}

import {
  AllocationValueTypeEnum,
  runValidation,
  validateFilledSubAccountAllocationsFields,
  validatePositiveValues,
  validateSubAccountAllocations,
  type Allocation,
  type MapTo,
} from '@talos/kyoko';
import { isEqual } from 'lodash-es';
import { useTradingSettings } from 'providers/AppConfigProvider';
import { useSubAccounts } from 'providers/SubAccountsContext';
import { useEffect, useState } from 'react';
import { ensureFloat } from 'utils/order';
import { array, object, type AnySchema } from 'yup';
import type { AnyObject } from 'yup/lib/types';
import { useFeatureFlag } from './useFeatureFlag';

interface ErrorsSchema {
  subAccountAllocations?: AnySchema;
}

interface useModifyDialogValidationProps {
  orderOrTrade: OrderOrTrade;
  mode: 'order' | 'trade';
}

// This interface kinda sucks
interface OrderOrTrade {
  orderQty?: string;
  quantity?: string;
  subAccountAllocations?: Allocation[];
  allocationValueType?: AllocationValueTypeEnum;
}

export const useModifyDialogValidation = ({ orderOrTrade, mode }: useModifyDialogValidationProps) => {
  const { subAccountAllocations, allocationValueType } = orderOrTrade;
  const quantity = mode === 'order' ? orderOrTrade.orderQty : orderOrTrade.quantity;
  const [errors, setErrors] = useState<Partial<MapTo<ErrorsSchema, string>>>({});
  const { tradableSubAccounts } = useSubAccounts();
  const { useTradeAllocations } = useTradingSettings();
  const { enableAccountSegregation } = useFeatureFlag();
  const requireSubAccountSelection = enableAccountSegregation;

  useEffect(() => {
    const errorsSchema: ErrorsSchema = {};
    if (useTradeAllocations) {
      errorsSchema.subAccountAllocations = array<Allocation>()
        .test('non-zero and non-negative', `Percentages must be greater than 0`, arr => {
          return arr == null ? false : validatePositiveValues(arr.map(item => item.value));
        })
        .test('filled fields', `Please fill out all fields`, arr => validateFilledSubAccountAllocationsFields(arr))
        .required('Please select Sub Account Allocations');

      if (allocationValueType === AllocationValueTypeEnum.Percentage) {
        errorsSchema.subAccountAllocations = errorsSchema.subAccountAllocations.test(
          'allocations',
          `Percentage Total Must Equal 100`,
          arr => validateSubAccountAllocations(arr, 100)
        );
      } else if (allocationValueType === AllocationValueTypeEnum.Quantity) {
        errorsSchema.subAccountAllocations = errorsSchema.subAccountAllocations
          .test('allocations', `Allocation Total Must Equal Quantity`, arr =>
            validateSubAccountAllocations(arr, ensureFloat(quantity || 0))
          )
          .required('Please select Sub Account Allocations');
      }
    } else if (requireSubAccountSelection && tradableSubAccounts?.length) {
      errorsSchema.subAccountAllocations = array<Allocation>().required('Please select a Sub Account');
    }

    setErrors(prev => {
      const next = runValidation(object().shape(errorsSchema as AnyObject), orderOrTrade);
      return isEqual(prev, next) ? prev : next;
    });
  }, [
    tradableSubAccounts,
    subAccountAllocations,
    allocationValueType,
    quantity,
    orderOrTrade,
    useTradeAllocations,
    enableAccountSegregation,
    requireSubAccountSelection,
  ]);

  return { errors };
};

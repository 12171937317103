export * from './backendText';
export * from './card';
export * from './customerOrderStrategies';
export * from './ENV_COLORS';
export * from './heartbeats';
export * from './market';
export * from './mixpanel';
export * from './number';
export * from './requestTypes';
export * from './socket';

export const DEFAULT_AGGREGATION_DISPLAY_NAME = 'Order markets';

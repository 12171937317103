import { Box } from '@talos/kyoko';
import styled from 'styled-components';

export const DistributionWrapper = styled.div.attrs({ 'data-testid': 'distribution-table' })`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const LegendIndicator = styled(Box)`
  height: 10px;
  width: 10px;
  border-radius: 100%;
  display: inline-block;
`;

import type { UseComboboxStateChange } from 'downshift';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type FocusEvent,
  type KeyboardEvent,
  type MouseEvent,
  type RefObject,
} from 'react';
import { defineMessages } from 'react-intl';
import { useIntl } from '../../../../hooks';
import { useDynamicCallback } from '../../../../hooks/useDynamicCallback';
import { Box } from '../../../Core';
import { AutocompleteDropdown, Input, useDropdownPopper, useSearchSelect } from '../../../Form';
import { NoResults, Title } from '../../../Form/AutocompleteDropdown/styles';
import { FormattedMessage } from '../../../Intl';
import type { FilterableProperty, PropertyRefs, UseFilterBuilderRefsOutput } from '../types';
import { SelectionListButtons } from './SelectionListButtons';

const messages = defineMessages({
  selectLabel: {
    defaultMessage: 'Select "{label}"',
    id: 'Filters.FilterBuilder.RHS.selectLabel',
  },
  typeToSelect: {
    defaultMessage: 'Type to select...',
    id: 'Filters.FilterBuilder.RHS.typeToSelect',
  },
});

type PropertyTextListProps = {
  property: FilterableProperty;
  selections: string[];
  onSelectionsChange: (newSelections: string[]) => void;
  onDropdownTabOut?: (event: KeyboardEvent<HTMLElement>) => void;
  onRemove: () => void;
  refs: PropertyRefs;
} & Pick<UseFilterBuilderRefsOutput, 'updateSelectionRefs'>;

export const PropertyTextList = ({
  property,
  selections,
  onSelectionsChange,
  onRemove,
  refs,
  updateSelectionRefs,
  onDropdownTabOut,
}: PropertyTextListProps) => {
  const { formatMessage } = useIntl();
  // this reference element will be switched between the currently selected button. Its the dropdown's anchor point
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(refs.empty.current);

  // this useeffect keeps the reference element set correctly (as in, the anchor point for the dropdown)
  useEffect(() => {
    if (refs.selections.size === 0) {
      setReferenceElement(refs.empty.current);
    } else if (refs.selections.size === 1) {
      // this is a single select, so there will only be one element here
      const onlyRef = [...refs.selections.values()][0]!;
      setReferenceElement(onlyRef.current);
    }
  }, [refs, referenceElement]);

  useEffect(() => {
    updateSelectionRefs(property.key, selections);
  }, [selections, updateSelectionRefs, property.key]);

  const handleSelectionChange = useCallback(
    (newSelection: string | undefined) => {
      const newSelections = newSelection ? [newSelection] : [];
      onSelectionsChange(newSelections);
    },
    [onSelectionsChange]
  );

  const handleInputValueChange = useDynamicCallback((changes: UseComboboxStateChange<string>) => {
    setItems(changes.inputValue ? [changes.inputValue] : []);
  });

  const inputRef = useRef<HTMLInputElement>(null);

  // Tracks a single autocomplete item that is exactly what the user has typed in the input field
  const [items, setItems] = useState<[string] | []>([]);

  const getLabel = useDynamicCallback((item: string) => item);
  const searchSelect = useSearchSelect({
    selection: selections.length > 0 ? selections[0] : undefined,
    items,
    inputRef,
    onChange: handleSelectionChange,
    onInputValueChange: handleInputValueChange,
    getLabel,
    initialSortByLabel: false,
    initialIsOpen: selections.length === 0, // start open if we're brand new
    closeDropdownOnItemSelection: false,
    inputValueChangeOnItemSelection: 'clear',
    enableTabSelect: false,
  });
  const { getInputProps, isOpen, openMenu, closeMenu } = searchSelect;

  const dropdownPopper = useDropdownPopper({
    isOpen,
    referenceElement,
    dropdownWidth: '240px',
    dropdownPlacement: 'bottom-start',
  });

  const updatePopperPosition = dropdownPopper.popper.update;

  useEffect(() => {
    if (selections) {
      // every time the clauses change, we update the popper position
      updatePopperPosition && updatePopperPosition();
    }
  }, [selections, updatePopperPosition]);

  const handleOpenClick = useCallback(
    (e: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>, ref: RefObject<HTMLButtonElement>) => {
      e.preventDefault();
      setReferenceElement(ref.current);
      updatePopperPosition && updatePopperPosition();
      openMenu();
      setTimeout(() => inputRef.current?.focus(), 0);
    },
    [openMenu, updatePopperPosition, setReferenceElement]
  );

  const handleRemoveSelectionClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      onSelectionsChange([]);
      e.stopPropagation();
    },
    [onSelectionsChange]
  );

  const handleDropdownTabOut = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (onDropdownTabOut) {
        event.stopPropagation();
        event.preventDefault();
        closeMenu();
        onDropdownTabOut && onDropdownTabOut(event);
      }
    },
    [closeMenu, onDropdownTabOut]
  );

  return (
    <Box position="relative">
      <SelectionListButtons
        visibleSelections={selections}
        refs={refs}
        onOpenClick={handleOpenClick}
        onRemovePropertyClick={onRemove}
        onRemoveSelectionClick={handleRemoveSelectionClick}
        getOptionLabel={item => item}
        entirePropertyRemovable={property.alwaysPresent !== true}
      />
      <AutocompleteDropdown
        {...searchSelect}
        {...dropdownPopper}
        data-testid="selection-dropdown"
        onTabOut={handleDropdownTabOut}
        renderResult={item => formatMessage(messages.selectLabel, { label: item.item.label })}
        renderEmpty={() => (
          <NoResults justifyContent="flex-start">
            <Title>
              <FormattedMessage {...messages.typeToSelect} />
            </Title>
          </NoResults>
        )}
        childrenAboveResults={
          <>
            <Input {...getInputProps({ ref: inputRef })} placeholder="" />
          </>
        }
      />
    </Box>
  );
};

import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useSecuritiesContext } from '../../../../contexts';
import { useIntl } from '../../../../hooks';
import { isFuture, isOption, isPerpetualSwap } from '../../../../utils';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

const messages = defineMessages({
  instrument: {
    defaultMessage: 'Instrument',
    id: 'Filters.FilterBuilder.filters.instrument',
  },
});

/**
 * This hook creates the filter property definition for derivative symbols.
 * As in, securities which are Options, Futures or Perps.
 */

export function useDerivativesSymbolsFilter() {
  const { formatMessage } = useIntl();
  const { securitiesList, securitiesBySymbol } = useSecuritiesContext();
  return useMemo(
    () =>
      ({
        key: 'Symbols',
        label: formatMessage(messages.instrument),
        icon: IconName.CurrencyDollar,
        options: securitiesList
          .filter(sec => isOption(sec) || isFuture(sec) || isPerpetualSwap(sec))
          .map(sec => sec.Symbol),
        getOptionLabel: (option: string) => securitiesBySymbol.get(option)?.DisplaySymbol || '',
      } as const satisfies FilterableProperty),
    [formatMessage, securitiesList, securitiesBySymbol]
  );
}

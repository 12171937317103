import { sortBy } from 'lodash-es';
import { useMemo, type ReactNode } from 'react';
import { SearchSelect, type FormControlSizes } from '../components/Form';
import { EMPTY_ARRAY } from '../utils';
import type { SelectorField } from './SelectorField';

export interface SelectorFieldViewProps<T> {
  field: SelectorField<T>;
  handleChange: (value: T | undefined) => void;
  labelGetter: (value: T) => string;
  descriptionGetter?: (value: T) => string;
  suffix?: ReactNode;
  prefix?: ReactNode;
  testid?: string;
  showClear?: boolean;
  initialSortByLabel?: boolean;
  initialSortGroupByLabel?: boolean;
  size?: FormControlSizes;
  groupField?: keyof T | ((item: T) => string);
  id?: string;
}
export function SelectorFieldView<T>({
  field,
  handleChange,
  labelGetter,
  descriptionGetter,
  suffix,
  prefix,
  testid,
  showClear,
  initialSortByLabel,
  size,
  groupField,
  initialSortGroupByLabel,
  id,
  ...rest
}: SelectorFieldViewProps<T>) {
  const availableItems = useMemo(() => {
    if (!groupField) {
      return field?.availableItems ?? EMPTY_ARRAY;
    }
    const grouped = field?.availableItems.reduce((acc, item) => {
      const group = (typeof groupField === 'function' ? groupField(item) : item[groupField]) || '';
      const groupItems = acc.get(group) || [];
      return acc.set(group, [...groupItems, item]);
    }, new Map());

    const entries: [string, any][] = Array.from(grouped?.entries() ?? []);
    return (initialSortGroupByLabel ? sortBy(entries, '0') : entries).map(([group, items]) => ({ group, items }));
  }, [field?.availableItems, groupField, initialSortGroupByLabel]);

  return (
    <SearchSelect
      w="100%"
      id={id}
      selection={field?.value}
      placeholder={field?.placeholder || ''}
      options={availableItems}
      getLabel={labelGetter}
      getDescription={descriptionGetter}
      disabled={field?.isDisabled}
      onChange={handleChange}
      suffix={suffix}
      prefix={prefix}
      showClear={showClear}
      data-testid={testid}
      initialSortByLabel={initialSortByLabel}
      size={size}
      {...rest}
    />
  );
}

import { createSelector } from '@reduxjs/toolkit';
import type { AppState } from 'providers/AppStateProvider/types';

/** Retrieve the full stored AppConfig for subsequent storage/display */
export const selectFullAppConfig = createSelector(
  (state: AppState) => state.appConfig.nonReduxConfig.configState,
  (state: AppState) => state.appConfig.reduxConfig,
  (nonReduxConfig, reduxConfig) => {
    return {
      ...nonReduxConfig,
      ...reduxConfig,
    };
  }
);

/** Retrieve the AppConfig Blotters2 state by a given id */
export const selectBlotters2StateById = createSelector(
  [selectFullAppConfig, (_, blotterId: string) => blotterId],
  (fullAppConfig: ReturnType<typeof selectFullAppConfig>, blotterId: string) => {
    return fullAppConfig.blotters2[blotterId];
  }
);

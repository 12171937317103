import { combineSlices, createSelector } from '@reduxjs/toolkit';
import type { AppState } from 'providers/AppStateProvider/types';
import { apiKeysSlice, isApiKeysTabDirty } from './apiKeys/apiKeysSlice';
import { drawerSlice } from './drawer/drawerSlice';
import { isPermissionsTabDirty, permissionsSlice } from './permissions/permissionsSlice';
import { userDetailsSlice } from './userDetails/userDetailsSlice';

export const customerUserSlice = combineSlices(drawerSlice, userDetailsSlice, apiKeysSlice, permissionsSlice);

export interface TabDef {
  label: 'General' | 'Account Restrictions' | 'API Keys';
  isDirty: boolean;
}
export interface TabDefs {
  general: TabDef;
  permissions: TabDef;
  apiKeys: TabDef;
}

export const selectTabDefs = createSelector(
  (state: AppState) => state.customerUserDrawer,
  (state: AppState, enableCustomerAccountRestrictions: boolean) => enableCustomerAccountRestrictions,
  (customerUserDrawer, enableCustomerAccountRestrictions): TabDef[] => {
    const tabDefs: TabDef[] = [
      {
        label: 'General',
        isDirty: customerUserDrawer.userDetails.isDirty,
      },
    ];

    if (enableCustomerAccountRestrictions) {
      tabDefs.push({
        label: 'Account Restrictions',
        isDirty: isPermissionsTabDirty(customerUserDrawer.permissions),
      });
    }

    if (customerUserDrawer.userDetails.customerUserId) {
      tabDefs.push({
        label: 'API Keys',
        isDirty: isApiKeysTabDirty(customerUserDrawer.apiKeys),
      });
    }

    return tabDefs;
  }
);

export const selectIsDrawerDirty = (state: AppState, enableCustomerAccountRestrictions: boolean) => {
  return selectTabDefs(state, enableCustomerAccountRestrictions).some(tabDef => tabDef.isDirty);
};

import { get } from 'lodash-es';
import type { AppConfigState } from '../AppConfigProvider.types';

/** Newer PMS blotter persist ids follow the more common
 * 'principal/portfolio-management/...' prefix
 */
const LEGACY_BLOTTER_PERSIST_IDS = [
  `PortfolioManagement-PortfolioBreakdownBlotter`,
  `PortfolioManagement-PortfolioPerformance`,
  `PortfolioManagement-PortfolioRisk`,
];

/**
 * This function prunes PersistedBlotterTable state belonging to PMS blotters and views
 */
export function prunePersistedPortfolioManagementBlotterTableState(config: unknown) {
  const allBlotterTabs: AppConfigState['blotters2'] | undefined = get(config, 'blotters2');
  if (!allBlotterTabs) {
    return;
  }

  LEGACY_BLOTTER_PERSIST_IDS.forEach(key => {
    delete allBlotterTabs[key];
  });
}

import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
  PriceProtectionEnum,
  type Counterparty,
  type Customer,
  type CustomerConfiguration,
  type MarketAccount,
  type UnifiedLiquidityResponse,
} from '@talos/kyoko';
import { freeze } from 'immer';
import { OrderStrategiesEnum } from '../../tokens/orderStrategy';
import type {
  CurrenciesRefData,
  FixingIndicesReferenceData,
  MarketAccountReferenceData,
  MarketsReferenceData,
  OMSReferenceDataState,
  OMSSettingsState,
  OptionRefData,
  OrderPresetsRefData,
  SecuritiesReferenceData,
  StrategiesReferenceData,
} from './types';

export const initialRefDataState: OMSReferenceDataState = {
  settings: {
    showAllInPrices: null,
    defaultPriceProtection: PriceProtectionEnum.Error,
    lastStrategyUsed: OrderStrategiesEnum.Limit,
    useTradeAllocations: false,
    defaultOrderStrategy: null,
    defaultMultilegOrderStrategy: null,
    alwaysCheckPriceReasonability: true,
    enableDerivativeContractDefault: false,
    enableGroups: false,
    supportedStrategiesForPricingModes: ['Limit', 'Iceberg'],
    enableOptionTrading: false,
    allowSyntheticCcy: false,
    exclusivelyPrimePriceOnRePriming: false,
    enableReduceOnly: false,
    enableReduceFirst: false,
    ddhDefaultsByID: new Map(),
    enableTakerMarketplaceETFRFQFlow: false,
    defaultToEmptyOrderMarkets: false,
    defaultMarketAccounts: {},
    enableAccountSegregation: false,
    resetOrderAccountsOnSubAccountChange: false,
  },
  securities: {
    securitiesList: [],
    securitiesBySymbol: new Map(),
    futures: [],
    perps: [],
    perpsByCurrencyPair: new Map(),
    futuresByCurrencyPair: new Map(),
    futuresByMarketByCurrency: new Map(),
    isReady: false,
  },
  marketAccounts: {
    marketAccountsByMarket: new Map(),
    marketAccountsList: [],
    marketAccountsByName: new Map(),
    isReady: false,
  },
  customers: [],
  customerMarketAccounts: [],
  customerConfigurations: [],
  strategies: {
    strategiesByName: new Map(),
    strategiesList: [],
    ddhStrategiesList: [],
    isReady: false,
  },
  markets: {
    marketsList: [],
    marketsByName: new Map(),
    isReady: false,
  },
  currencies: {
    currenciesBySymbol: new Map(),
    isReady: false,
  },
  options: {
    options: [],
    expirationByMarketByCurrencyIdentity: new Map(),
    optionSecurityBySymbol: new Map(),
    strikesByOptionKey: new Map(),
    isReady: false,
  },
  orderPresets: {
    orderPresetsList: [],
    orderPresetsByID: new Map(),
    isReady: false,
  },
  unifiedLiquidityTokens: new Map(),
  fixingIndices: {
    fixingIndicesList: [],
    fixingIndicesByName: new Map(),
    fixingIndicesBySymbol: new Map(),
    marketsByFixingIndex: new Map(),
    isReady: false,
  },
  counterparties: [],
};

export const referenceDataSlice = createSlice({
  name: 'referenceData',
  initialState: initialRefDataState,
  reducers: {
    setDefaultSubAccount: (state, action: PayloadAction<string | undefined>) => {
      state.defaultSubAccount = action.payload;
    },
    setSecurities: (state, action: PayloadAction<SecuritiesReferenceData>) => {
      state.securities = freeze(action.payload);
    },
    setMarketAccounts: (state, action: PayloadAction<MarketAccountReferenceData>) => {
      state.marketAccounts = action.payload;
    },
    setStrategies: (state, action: PayloadAction<StrategiesReferenceData>) => {
      state.strategies = action.payload;
    },
    setMarkets: (state, action: PayloadAction<MarketsReferenceData>) => {
      state.markets = action.payload;
    },
    setOptions: (state, action: PayloadAction<OptionRefData>) => {
      state.options = freeze(action.payload);
    },
    setCurrencies: (state, action: PayloadAction<CurrenciesRefData>) => {
      state.currencies = freeze(action.payload);
    },
    setCustomers: (state, action: PayloadAction<Customer[]>) => {
      state.customers = action.payload;
    },
    setCounterparties: (state, action: PayloadAction<Counterparty[]>) => {
      state.counterparties = action.payload;
    },
    setCustomerMarketAccounts: (state, action: PayloadAction<MarketAccount[]>) => {
      state.customerMarketAccounts = action.payload;
    },
    setCustomerConfigurations: (state, action: PayloadAction<CustomerConfiguration[]>) => {
      state.customerConfigurations = action.payload;
    },
    setOMSSettings: (state, action: PayloadAction<OMSSettingsState>) => {
      state.settings = action.payload;
    },
    setOrderPresets: (state, action: PayloadAction<OrderPresetsRefData>) => {
      state.orderPresets = action.payload;
    },
    setUnifiedLiquidtyTokens: (state, action: PayloadAction<Map<string, UnifiedLiquidityResponse>>) => {
      state.unifiedLiquidityTokens = action.payload;
    },
    setFixingIndices: (state, action: PayloadAction<FixingIndicesReferenceData>) => {
      state.fixingIndices = action.payload;
    },
    setReferenceData: (state, action: PayloadAction<OMSReferenceDataState>) => {
      /**
       * You should not be using this other than from tests
       */
      return action.payload;
    },
  },
});

export const {
  setDefaultSubAccount,
  setSecurities,
  setCustomers,
  setCounterparties,
  setMarketAccounts,
  setCustomerMarketAccounts,
  setCustomerConfigurations,
  setStrategies,
  setMarkets,
  setOptions,
  setCurrencies,
  setOrderPresets,
  setReferenceData,
  setOMSSettings,
  setUnifiedLiquidtyTokens,
  setFixingIndices,
} = referenceDataSlice.actions;

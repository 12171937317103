import { createContext, type ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import type { ThemeName, ThemeType } from './themes/types.ts';

export interface ThemeContextType {
  type: ThemeType;
  theme: ThemeName;
  setType: (type: ThemeType) => void;
  setTheme: (mode: ThemeName) => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export function ThemeProvider({ children }: { children: ReactNode }) {
  const [type, setType] = useState<ThemeType>(() => {
    const savedMode = localStorage.getItem('themeType') as ThemeType;
    return savedMode ?? 'dark';
  });

  const [theme, setTheme] = useState<ThemeName>(() => {
    return (localStorage.getItem('theme') as ThemeName) || 'default';
  });

  useEffect(() => {
    document.documentElement.classList.toggle('dark', type === 'dark');
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('themeType', type);
    localStorage.setItem('theme', theme);
  }, [type, theme]);

  const value = useMemo(
    () => ({
      type,
      theme,
      setType,
      setTheme,
    }),
    [type, theme, setType, setTheme]
  );

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

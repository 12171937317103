import {
  MARGIN_PORTFOLIO_METRIC,
  MARGIN_POSITION_METRIC,
  ProductTypeEnum,
  toBigWithDefault,
  useObservableValue,
  useSubscription,
  useWSFilterPipe,
  wsScanToMap,
  wsSubscriptionCache,
  type CreditBlotterExposure,
} from '@talos/kyoko';
import Big from 'big.js';
import { createContext, useCallback, useContext, useMemo, type PropsWithChildren } from 'react';
import { asyncScheduler, combineLatestWith, map, shareReplay, startWith, throttleTime, type Observable } from 'rxjs';
import { useSubAccounts } from '../../../../providers';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { useCreditBlotterDataObs } from '../../../Blotters/Credit/useCreditBlotterDataObs';
import type { PositionsTableFilter } from '../../../Blotters/PositionsV3/types';
import type { UnifiedPosition } from '../../../Blotters/PositionsV3/Unified/UnifiedPosition';
import { useUnifiedPositionsBalancesObs } from '../../../Blotters/PositionsV3/Unified/useUnifiedPositionsBalancesObs';
import { usePortfolioViewStateSelector } from '../../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import { OpsAccountPosition, OpsPosition, type IMarginPortfolioMetric, type IMarginPositionMetric } from '../types';
import { useOperationsOverviewConfig } from './OperationsOverviewConfigProvider';

export const OperationsOverviewPositionsContext = createContext<OperationsOverviewPositionsContextProps | undefined>(
  undefined
);

export type OperationsOverviewPositionsContextProps = {
  /** Whether or not any derivatives exist in the output positionData data set */
  hasDerivs: boolean;
  positionsData: OpsPosition[] | undefined;
  positionsDataObs: Observable<OpsPosition[]>;
  marketAccountPositionsByAccountObs: Observable<Map<string, OpsAccountPosition>>;
  marketAccountPositionsDataObs: Observable<OpsAccountPosition[]>;
  marketAccountPositionsData: OpsAccountPosition[] | undefined;
  subAccountPositionsByAccountObs: Observable<Map<string, OpsAccountPosition>>;
};

export function useOperationsOverviewPositions() {
  const context = useContext(OperationsOverviewPositionsContext);
  if (context === undefined) {
    throw new Error(
      'Missing OperationsOverviewPositionsContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

function getPositionKey(p: OpsPosition) {
  return p.rowID;
}

function getPositionMetricKey(metric: IMarginPositionMetric): string {
  // IMarginPositionMetric (backend) will need to be extended to include a .MarginReportingType on each record to complete this ID.
  return `${metric.AccountName}-${metric.Asset}`;
}

function getExposureKey(account: string, currency?: string): string {
  return `${account}-${currency}`;
}

export const OperationsOverviewPositionsProvider = function OperationsOverviewPositionsProvider({
  children,
}: PropsWithChildren) {
  const { homeCurrency } = useDisplaySettings();
  const { mode } = useOperationsOverviewConfig();

  const { subAccountsByID } = useSubAccounts();
  const { showZeroBalances, selectedMarketAccountIds, selectedPortfolioId } = usePortfolioViewStateSelector();
  const { opsOverviewFilter } = useOperationsOverviewConfig();
  const selectedSubAccountName = selectedPortfolioId ? subAccountsByID.get(selectedPortfolioId)?.Name : undefined;

  const filter: PositionsTableFilter = useMemo(() => {
    return {
      ...opsOverviewFilter,
      MarketAccounts: selectedMarketAccountIds,
      SubAccounts: selectedSubAccountName ? [selectedSubAccountName] : undefined,
    };
  }, [opsOverviewFilter, selectedMarketAccountIds, selectedSubAccountName]);

  const backendFilter: PositionsTableFilter = useMemo(
    () => ({
      MarketAccounts: filter.MarketAccounts,
      SubAccounts: mode === 'SubAccount' ? filter.SubAccounts : undefined,
    }),
    [mode, filter.MarketAccounts, filter.SubAccounts]
  );

  const positionsObs = useUnifiedPositionsBalancesObs({
    tag: 'operations-overview-page',
    filter: backendFilter,
    showZeroBalances,
  });

  const { data: positionMetricsRawObs } = useSubscription<IMarginPositionMetric>({
    name: MARGIN_POSITION_METRIC,
    EquivalentCurrency: homeCurrency,
    tag: 'operations-overview-page',
    ...backendFilter,
  });

  const { data: subAccPositionMetricsObs } = useSubscription<IMarginPortfolioMetric>(
    mode === 'SubAccount'
      ? {
          name: MARGIN_PORTFOLIO_METRIC,
          EquivalentCurrency: homeCurrency,
          tag: 'operations-overview-page',
          AccountType: 'SubAccount',
          ...backendFilter,
        }
      : null
  );

  const subAccountPositionsByAccountObs = useMemo(
    () =>
      subAccPositionMetricsObs.pipe(
        wsScanToMap({
          getUniqueKey: metric => metric.AccountName,
          newMapEachUpdate: true,
          getInsertable: metric => new OpsAccountPosition(metric),
        }),
        throttleTime(2000, asyncScheduler, { leading: true, trailing: true }),
        shareReplay({ refCount: true, bufferSize: 1 })
      ),
    [subAccPositionMetricsObs]
  );

  // There's several users of this feed so we put a cache on it at the top so we dont get any late subscriber problems
  const positionMetricsObs = useMemo(
    () => positionMetricsRawObs.pipe(wsSubscriptionCache(getPositionMetricKey)),
    [positionMetricsRawObs]
  );

  const { data: portfolioMetricsObs } = useSubscription<IMarginPortfolioMetric>({
    name: MARGIN_PORTFOLIO_METRIC,
    EquivalentCurrency: homeCurrency,
    tag: 'operations-overview-page',
    ...backendFilter,
  });

  const { dataObservable: exposuresObs } = useCreditBlotterDataObs({
    showZeroBalances: true,
    tag: 'operations-overview-page',
    makeRequest: mode === 'MarketAccount',
  });

  const exposuresMapObs = useMemo(
    () =>
      exposuresObs.pipe(
        wsScanToMap({
          getUniqueKey: e => getExposureKey(e.MarketAccount, e.Currency),
          newMapEachUpdate: false, // this observable is only consumed by another observable, so dont need this to trigger react change detection etc
        }),
        throttleTime(5000, asyncScheduler, { leading: true, trailing: true }),
        shareReplay({ bufferSize: 1, refCount: true }),
        startWith(new Map<string, CreditBlotterExposure>()) // this is pure ref data, so we allow the page to move on and not wait for us
      ),
    [exposuresObs]
  );

  // We currently dont do any frontend filtering, but I would like to keep the infrastructure for adding it back in later
  // once the backend is updated to handle it
  const filterFunc = useCallback((item: OpsPosition) => {
    return true;
  }, []);

  const positionMetricsByKeyObs = useMemo(
    () =>
      positionMetricsObs.pipe(
        wsScanToMap({ getUniqueKey: getPositionMetricKey, newMapEachUpdate: true }),
        throttleTime(2000, asyncScheduler, { leading: true, trailing: true }),
        shareReplay({ refCount: true, bufferSize: 1 })
      ),
    [positionMetricsObs]
  );

  const wsFilterPipe = useWSFilterPipe({ getUniqueKey: getPositionKey, filterFunc });

  const basePositionsDataObs = useMemo(
    () =>
      positionsObs.pipe(
        wsScanToMap({ getUniqueKey: pos => pos.rowID, newMapEachUpdate: false }),
        throttleTime(2000, asyncScheduler, { leading: true, trailing: true }),
        map(mapping => {
          // Calculate totals for each market account level, then build OpsPositions using this information
          const opsPositions: OpsPosition[] = [];
          const positionsArr = [...mapping.values()];
          const totals = getTotalPositionPerMarketAccount(positionsArr);
          let anyDerivativeFound = false;
          for (const position of positionsArr) {
            if (position.AssetType !== ProductTypeEnum.Spot) {
              anyDerivativeFound = true;
            }

            const total = totals.get(position.MarketAccount);
            if (!total || !position.market) {
              continue;
            }

            opsPositions.push(new OpsPosition(position, total, position.market));
          }

          // In the SubAccount mode, we only ever show data if there are derivatives present in the data set.
          if (mode === 'SubAccount' && !anyDerivativeFound) {
            return [];
          }
          // Return an array of built OpsPositions here now including knowledge of their share of the total
          return opsPositions;
        })
      ),
    [positionsObs, mode]
  );

  // The only reason this pipe is split into two is because rxjs typing goes to <unknown> at some amount of operators attached
  // Typescript doesnt want to go that deep in its inference it seems? Something like that.
  const positionsDataObs: Observable<OpsPosition[]> = useMemo(
    () =>
      basePositionsDataObs.pipe(
        // Enrich our newly built OpsPositions with our metrics
        combineLatestWith(positionMetricsByKeyObs, exposuresMapObs),
        map(([opsPositions, positionMetricsByKey, exposuresMap]) => {
          opsPositions.forEach(pos => {
            pos.metric = positionMetricsByKey.get(`${pos.MarketAccount}-${pos.Asset}`);
            pos.exposure = exposuresMap.get(getExposureKey(pos.MarketAccount, pos.Asset));
          });
          return opsPositions;
        }),
        // Convert to a MinimalSubscriptionResponse so we can use the wsFilterPipe natively
        map(opsPositions => ({ initial: true, data: opsPositions })),
        wsFilterPipe,
        wsScanToMap({ getUniqueKey: pos => pos.rowID, newMapEachUpdate: false }),
        map(mapping => [...mapping.values()]),
        shareReplay({ bufferSize: 1, refCount: true }) // lastly, replay and multicast the output
      ),
    [basePositionsDataObs, wsFilterPipe, positionMetricsByKeyObs, exposuresMapObs]
  );

  const positionsData = useObservableValue(() => {
    return positionsDataObs;
  }, [positionsDataObs]);

  const marketAccountPositionsByAccountObs = useMemo(() => {
    return portfolioMetricsObs.pipe(
      wsScanToMap({
        getUniqueKey: item => item.AccountName,
        newMapEachUpdate: false,
        getInsertable: metric => new OpsAccountPosition(metric),
      }),
      combineLatestWith(exposuresMapObs),
      map(([positionsMap, exposuresMap]) => {
        for (const position of positionsMap.values()) {
          // For account-level positions, we are not "within" any one currency, so find an exposure specific to the whole account and not any one currency
          const exposure = exposuresMap.get(getExposureKey(position.metric.AccountName, undefined));
          if (exposure) {
            position.exposure = exposure;
          }
        }
        return positionsMap;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }, [portfolioMetricsObs, exposuresMapObs]);

  const marketAccountPositionsDataObs = useMemo(() => {
    return marketAccountPositionsByAccountObs.pipe(
      map(mapping => [...mapping.values()]),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }, [marketAccountPositionsByAccountObs]);

  const marketAccountPositionsData = useObservableValue(() => {
    return marketAccountPositionsDataObs;
  }, [marketAccountPositionsDataObs]);

  const hasDerivs = useMemo(
    () => positionsData?.some(p => p.AssetType !== ProductTypeEnum.Spot) ?? true, // nullish positionsData -> default this to true
    [positionsData]
  );

  const value = useMemo(() => {
    return {
      hasDerivs,
      positionsData,
      positionsDataObs,
      marketAccountPositionsByAccountObs,
      marketAccountPositionsDataObs,
      marketAccountPositionsData,
      subAccountPositionsByAccountObs,
    };
  }, [
    hasDerivs,
    positionsData,
    positionsDataObs,
    marketAccountPositionsByAccountObs,
    marketAccountPositionsDataObs,
    marketAccountPositionsData,
    subAccountPositionsByAccountObs,
  ]);

  return (
    <OperationsOverviewPositionsContext.Provider value={value}>{children}</OperationsOverviewPositionsContext.Provider>
  );
};

function getTotalPositionPerMarketAccount(positions: UnifiedPosition[]): Map<string, Big> {
  return positions.reduce((agg, position) => {
    const mktAccSumSoFar = agg.get(position.MarketAccount) ?? Big(0);
    agg.set(position.MarketAccount, mktAccSumSoFar.plus(toBigWithDefault(position.Equivalent?.Amount, 0).abs()));
    return agg;
  }, new Map<string, Big>());
}

import { useMemo } from 'react';
import { HStack } from '../../Core';
import { useModalContext } from '../../Modal/Modal';
import { Tab, TabList, TabPanel, Tabs } from '../../Tabs';
import { AutocompleteResults } from '../Autocomplete/AutocompleteResults';
import { Dropdown } from '../Dropdown/Dropdown';
import { AutocompleteResultsWrapper } from './styles';
import type { AutocompleteDropdownProps } from './types';

export const AutocompleteDropdown = <T,>({
  menuStyles,
  isOpen,
  size,
  portalize,
  referenceElement,
  dropdownWidth,
  dropdownHeight,
  setPopperElement,
  id,
  popper,
  onTabOut,
  onKeyDown,
  dropdownContentRef,
  childrenAboveResults,
  showGroupTabs,
  groupSorter,
  tabs,
  groupsPrefix,
  suffix,
  ...autocompleteResultsProps
}: AutocompleteDropdownProps<T>) => {
  const modalContext = useModalContext();
  const portalizeToUse = portalize == null ? modalContext?.portalize : portalize;

  const AutoCompleteResults = useMemo(
    () => (
      <>
        {childrenAboveResults}
        <AutocompleteResults isOpen={isOpen} {...autocompleteResultsProps} />
      </>
    ),
    [autocompleteResultsProps, childrenAboveResults, isOpen]
  );

  return (
    <Dropdown
      menuStyles={menuStyles}
      isOpen={isOpen}
      size={size}
      portalize={portalizeToUse}
      referenceElement={referenceElement}
      dropdownWidth={dropdownWidth}
      setPopperElement={setPopperElement}
      popper={popper}
      dropdownContentRef={dropdownContentRef}
      onTabOut={onTabOut}
      onKeyDown={onKeyDown}
      id={id}
    >
      <AutocompleteResultsWrapper
        data-testid="autocomplete-results-wrapper"
        {...autocompleteResultsProps.getMenuProps(
          {},
          {
            // If portalize is used, the ref error is suppressed since
            // the ref is not available in the same context
            suppressRefError: portalizeToUse,
          }
        )}
      >
        {showGroupTabs ? (
          <Tabs {...tabs}>
            <HStack>
              {groupsPrefix}
              <TabList
                isBordered
                aria-hidden={showGroupTabs}
                mx={showGroupTabs && groupsPrefix ? '0' : 'spacingComfortable'}
              >
                {tabs.items.map((tab, idx) => (
                  <Tab {...tab} label={tab.group} key={tab.group} />
                ))}
              </TabList>
            </HStack>
            <TabPanel>{AutoCompleteResults}</TabPanel>
          </Tabs>
        ) : (
          AutoCompleteResults
        )}
        {suffix ?? null}
      </AutocompleteResultsWrapper>
    </Dropdown>
  );
};

import {
  ConnectionType,
  EMPTY_ARRAY,
  ProductTypeEnum,
  SideEnum,
  useDynamicCallback,
  useMarketAccountsContext,
  type Security,
  type StringSelectItem,
} from '@talos/kyoko';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { compact } from 'lodash-es';
import { useMemo, useRef } from 'react';
import { useTradableMarketAccountNames } from '../../../hooks';
import { useMSSByAccountBySymbol } from '../../../providers/MarketSecurityStatusProvider';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';
import { useMarketConfiguredCondition } from '../../MarketSelector/conditions/useMarketConfiguredCondition';
import { useMarketOnlineCondition } from '../../MarketSelector/conditions/useMarketOnlineCondition';
import { useMSSCapabilityEnabledCondition } from '../../MarketSelector/conditions/useMSSCapabilityEnabledCondition';
import { useMSSEnabledCondition } from '../../MarketSelector/conditions/useMSSEnabledCondition';
import type { MarketSelectorValue } from '../../MarketSelector/types';
import { useBuildMarketSelectorItems } from '../../MarketSelector/utils/useBuildMarketSelectorItems';
import { useWrappedMarketSelectorValue } from '../../MarketSelector/utils/useWrappedMarketSelectorValue';
import {
  NewMultilegMarketSelector,
  OldMultilegMarketSelector,
} from '../../MultiLegSummary/MultilegMarketAccountSelector';

export interface AgMultilegMarketAccountSelectorProps {
  legIndex: number;
  security?: Security;
  side?: SideEnum;
}

export const AgMultilegMarketAccountSelector = ({
  security,
  eGridCell,
  api,
  value: unsanitizedValue,
  onValueChange,
  stopEditing,
  side,
  legIndex,
}: CustomCellEditorProps<unknown, StringSelectItem[]> & AgMultilegMarketAccountSelectorProps) => {
  const { enableNewMarketSelector } = useTradingSettings();

  const marketSelectorItems = useMultilegSetupMarketSelectorItems(security);

  const value = unsanitizedValue ?? EMPTY_ARRAY;
  const showAllMarketsToggle = useMemo(() => security?.ProductType === ProductTypeEnum.Spot, [security]);
  const selections = useMemo(() => value.map(item => item.value), [value]);

  const { marketAccountsByName } = useMarketAccountsContext();

  const { marketSelectorValue, onMarketSelectorValueChange } = useWrappedMarketSelectorValue(selections);

  const handleOldMarketSelectorChange = useDynamicCallback((selectedMarketAccounts: string[] = []) => {
    const newItems = selectedMarketAccounts.map(marketAccount => ({
      value: marketAccount,
      label: marketAccountsByName.get(marketAccount)?.DisplayName || marketAccount,
    }));

    onValueChange(newItems);
  });

  const handleNewMarketSelectorChange = useDynamicCallback((value: MarketSelectorValue) => {
    const newItems = value.selections.map(marketAccount => ({
      value: marketAccount,
      label: marketAccountsByName.get(marketAccount)?.DisplayName || marketAccount,
    }));

    onMarketSelectorValueChange(value);
    onValueChange(newItems);
  });

  const anchorRef = useRef<HTMLElement>(eGridCell);

  const close = useDynamicCallback(() => {
    stopEditing();
  });

  return enableNewMarketSelector ? (
    <NewMultilegMarketSelector
      legIndex={legIndex}
      isOpen={true}
      close={close}
      security={security}
      value={marketSelectorValue}
      marketSelectorItems={marketSelectorItems ?? EMPTY_ARRAY}
      showAllMarketsToggle={showAllMarketsToggle}
      onChange={handleNewMarketSelectorChange}
      anchorRef={anchorRef}
      side={side ?? SideEnum.Buy}
      panelPlacement="auto-end"
    />
  ) : (
    <OldMultilegMarketSelector
      isOpen={true}
      close={close}
      security={security}
      value={marketSelectorValue}
      marketSelectorItems={marketSelectorItems ?? EMPTY_ARRAY}
      showAllMarketsToggle={showAllMarketsToggle}
      onChange={handleOldMarketSelectorChange}
      dropdownPlacement="bottom"
      anchorRef={anchorRef}
    />
  );
};

// Special item building for use in multileg symbol editing / creation
const useMultilegSetupMarketSelectorItems = (security: Security | undefined) => {
  const { marketAccountsByName } = useMarketAccountsContext();

  const availableMarketAccountNames = useTradableMarketAccountNames({
    security,
    subAccountAllocations: -1,
    connectionType: ConnectionType.Orders,
  });

  const marketAccounts = useMemo(
    () => compact(availableMarketAccountNames.map(name => marketAccountsByName.get(name))),
    [availableMarketAccountNames, marketAccountsByName]
  );

  const symbols = useMemo(() => compact([security?.Symbol]), [security]);

  const mssByAccountBySymbol = useMSSByAccountBySymbol({ symbols, tag: `MarketSelector: Orders MSS Condition` });
  const marketConfiguredCondition = useMarketConfiguredCondition({ connectionType: ConnectionType.Orders });
  const marketOnlineCondition = useMarketOnlineCondition({ connectionType: ConnectionType.Orders });
  const mssMarketDataEnabledCondition = useMSSCapabilityEnabledCondition({
    capability: 'MarketData',
    symbols,
    mssByAccountBySymbol,
    justWarnOnFailure: true,
  });
  const mssEnabledCondition = useMSSEnabledCondition({ mssByAccountBySymbol, symbols, justWarnOnFailure: true });
  const conditions = useMemo(
    () => [marketConfiguredCondition, marketOnlineCondition, mssMarketDataEnabledCondition, mssEnabledCondition],
    [marketConfiguredCondition, marketOnlineCondition, mssMarketDataEnabledCondition, mssEnabledCondition]
  );

  return useBuildMarketSelectorItems({
    marketAccounts,
    conditions,
  });
};

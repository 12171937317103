import type Popper from '@popperjs/core';
import { easings, useTransition } from '@react-spring/web';
import { useTopLevelPortalElement } from 'hooks/useTopLevelPortalElement';
import { Children } from 'react';
import { Portal } from '../Portal';
import { PopoverContent } from './PopoverContent';
import { PopoverTarget } from './PopoverTarget';
import type { PopoverProps } from './types';

export const POPOVER_PORTAL_ID = 'popover-portal-id';

export const getTransition = (placement: Popper.Placement | undefined): Parameters<typeof useTransition>[1] => {
  const direction =
    placement === undefined || placement?.startsWith('top') || placement?.startsWith('bottom') ? 'Y' : 'X';
  const sign = placement === undefined || placement?.startsWith('top') || placement?.startsWith('left') ? '' : '-';
  return {
    overflow: 'hidden',
    from: { opacity: 0, transform: `translate${direction}(${sign}8px)` },
    enter: { opacity: 1, transform: `translate${direction}(0)` },
    leave: { opacity: 0, transform: `translate${direction}(${sign}8px)` },
    config: {
      duration: 300,
      easing: easings.easeOutBack,
    },
  };
};

export function Popover({
  children,
  isOpen,
  onMouseEnterTarget,
  onMouseLeave,
  onFocusTarget,
  onBlurTarget,
  onClickTarget,
  setContentRef,
  setTargetRef,
  isSmall,
  usePortal,
  portalElement,
  tabIndex = 0,
  styles,
  attributes,
  state,
  spacing,
  overflow,
  overflowY = 'auto',
  preventOverflow,
  targetStyle,
  variant,
  noPaddingAndBorder,
  zIndex,
  placement,
}: PopoverProps) {
  useTopLevelPortalElement(POPOVER_PORTAL_ID);
  const [targetChild, contentChild] = Children.toArray(children);

  const transitions = useTransition<boolean, Parameters<typeof useTransition>[1]>(isOpen, getTransition(placement));

  if (contentChild == null) {
    return <>{targetChild}</>;
  }

  const maybeMaxWidth = preventOverflow ? `calc(100% - ${spacing * 2}px)` : '';

  const popperContent = transitions(
    (transitionStyle, item, t) =>
      item && (
        <PopoverContent
          variant={variant}
          style={{ ...styles.popper, maxWidth: maybeMaxWidth }} // safeguard from overflowing by applying maxwidth
          ref={setContentRef}
          key={t.key}
          placement={(state && state.placement) ?? 'auto'}
          transitionStyle={transitionStyle}
          onMouseLeave={onMouseLeave}
          isSmall={isSmall}
          overflow={overflow}
          overflowY={overflowY}
          aria-busy={!t.ctrl.idle}
          noPaddingAndBorder={noPaddingAndBorder}
          data-testid="popover"
          zIndex={zIndex}
          {...attributes.popper}
        >
          {contentChild}
        </PopoverContent>
      )
  );

  return (
    <>
      <PopoverTarget
        onMouseEnter={onMouseEnterTarget}
        onMouseLeave={onMouseLeave}
        onFocus={onFocusTarget}
        onBlur={onBlurTarget}
        onClick={onClickTarget}
        ref={setTargetRef}
        tabIndex={tabIndex}
        style={targetStyle}
        data-testid="popover-target"
      >
        {targetChild}
      </PopoverTarget>
      {usePortal || portalElement ? (
        <>
          <Portal portalId={POPOVER_PORTAL_ID} portalElement={portalElement}>
            {popperContent}
          </Portal>
        </>
      ) : (
        popperContent
      )}
    </>
  );
}

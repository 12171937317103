import { OrganizationContext, request, useEndpointsContext, type Organization } from '@talos/kyoko';

import { useEffect, useMemo, useState, type PropsWithChildren } from 'react';

export function OrganizationProvider({ children }: PropsWithChildren) {
  const { apiEndpoint } = useEndpointsContext();
  const [isLoaded, setIsLoaded] = useState(false);
  const [organization, setOrganization] = useState<Organization | undefined>(undefined);

  useEffect(() => {
    request<Organization>('GET', `${apiEndpoint}/organization`)
      .then(data => {
        setOrganization(data);
        setIsLoaded(true);
        return data;
      })
      .catch(() => {
        setIsLoaded(false);
        return undefined;
      });
  }, [apiEndpoint]);

  const value = useMemo(
    () => ({
      isLoaded: isLoaded,
      users: organization?.Users ?? [],
      organization,
    }),
    [isLoaded, organization]
  );

  return <OrganizationContext.Provider value={value}>{children}</OrganizationContext.Provider>;
}

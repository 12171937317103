import { SideEnum, toBig, validatePrecision, type Security } from '@talos/kyoko';
import Papa from 'papaparse';
import { v4 as uuid } from 'uuid';
import type { ImportedCareOrder } from '../types';
import type { CareOrderImportContext, CareOrderImportParser } from './types';

const requiredHeaders = [
  'portfolio',
  'custodian',
  'dircpty',
  'transxtype',
  'quantity',
  'description',
  'principal',
  'secccy',
  'settledate',
  'orderid',
] as const;
export type AladdinCsvRow = Record<(typeof requiredHeaders)[number], string> & {
  tradingbenchmark: string;
  generalcomment: string;
};

/**
 * Parse a file coming from the Aladdin OMS, and create a ImportedCareOrder from each row.
 * @returns
 */
export const parse: CareOrderImportParser = async function parse(file, context) {
  const rows = await parseFile(file);
  return rows.map((row, index) => mapRow(row, { ...context, index }));
};

/**
 * Reads the file and parses it using PapaParse.
 */
function parseFile(file: File): Promise<AladdinCsvRow[]> {
  return new Promise((resolve, reject: (reason: string) => void) => {
    Papa.parse<AladdinCsvRow>(file, {
      delimiter: ',',
      header: true,
      skipEmptyLines: true,
      transformHeader: (header: string) => header.toLowerCase().replaceAll(' ', '').trim(),
      transform: (value: string) => value.trim(),
      complete: result => {
        if (!result.meta.fields) {
          return reject('Unable to parse header from file');
        }
        const missingHeader = requiredHeaders.find((key: string) => {
          return result.meta.fields?.indexOf(key.toLowerCase()) === -1;
        });
        if (missingHeader != null) {
          return reject(`Missing required header: ${missingHeader}`);
        }
        if (result.errors.length > 0) {
          return reject(result.errors.map(error => `Row ${error.row + 1} ${error.message} `).join(', '));
        }
        return resolve(result.data);
      },
    });
  });
}

/**
 * Maps the Aladdin CSV row to an ImportedCareOrder.
 */
export function mapRow(
  data: AladdinCsvRow,
  { securitiesBySymbol, careOrdersByGroup, index }: CareOrderImportContext & { index: number }
): ImportedCareOrder {
  let warning: string | undefined;
  let isSelectable = true;

  const side = data.transxtype === 'BUY' ? SideEnum.Buy : SideEnum.Sell;

  const security = parseAladdinCsvSymbol(data, securitiesBySymbol);
  if (security == null) {
    throw new Error(`Row ${index + 1}: Invalid symbol`);
  }

  const currency = security?.PositionCurrency;
  const initiatingFrom = data.generalcomment;

  const isCounterCurrency = data.secccy === security.PositionCurrency;

  let orderQty: string | undefined;
  if (isCounterCurrency) {
    orderQty = toBig(data.principal.replaceAll(/\s/g, ''))?.toFixed();
    warning = 'Counter currency orders are not yet supported.';
    isSelectable = false;
  } else {
    orderQty = toBig(data.quantity.replaceAll(/\s/g, ''))?.toFixed();
    if (!validatePrecision(security.MinSizeIncrement, orderQty)) {
      throw new Error(
        `Row ${index + 1}: Invalid order qty "${orderQty ?? ''}", min increment is ${security.MinSizeIncrement}`
      );
    }
  }

  // Note that we don't modify `isSelectable` here.
  // It's still OK to add the order since `Group` isn't a unique field.
  const externalOrderID = data.orderid?.trim() ?? '';
  if (externalOrderID === '') {
    throw new Error(`Row ${index + 1}: Missing required column "Order ID"`);
  }
  if (careOrdersByGroup?.has(externalOrderID)) {
    warning = `Group "${externalOrderID}" already exists`;
  }

  return {
    ClOrdID: uuid(),
    Symbol: security.Symbol,
    Side: side,
    OrderQty: orderQty,
    Currency: currency,
    Annotations: {
      ETP: {
        InitiatingFrom: initiatingFrom,
      },
    },
    Group: externalOrderID,
    Comments: '', // unused for Blk
    Counterparty: '', // unused for Blk
    warning,
    isSelectable,
  };
}

/**
 * Parse the symbol from the Aladdin CSV row.
 */
function parseAladdinCsvSymbol(data: AladdinCsvRow, securitiesBySymbol: Map<string, Security>): Security | undefined {
  try {
    const [pair] = data.description.split(' ');
    const [first, second] = pair.split('/');

    if (securitiesBySymbol.has(`${first}-${second}`)) {
      return securitiesBySymbol.get(`${first}-${second}`);
    }
    if (securitiesBySymbol.has(`${second}-${first}`)) {
      return securitiesBySymbol.get(`${second}-${first}`);
    }

    return undefined;
  } catch (e) {
    return undefined;
  }
}

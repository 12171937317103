import { useMatchMedia } from './useMatchMedia';

export type DeviceTypes = 'tablet' | 'mobile' | 'desktop';
export const useDeviceType = (): DeviceTypes => {
  // const isTouch = useMatchMedia('(pointer:coarse)');
  const isTablet = useMatchMedia('(max-width: 1200px)');
  const isMobile = useMatchMedia('(max-width: 768px)');
  const isTouch = useMatchMedia('(pointer:coarse)');

  if (navigator.userAgent.toLowerCase().includes('mobile')) {
    return 'mobile';
  }

  return isTouch ? (isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop') : 'desktop';
};

import {
  ButtonVariants,
  FormControlSizes,
  Icon,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  parseColor,
  useMixpanel,
} from '@talos/kyoko';
import { selectView } from 'components/OMS/OMSSlice';
import { OMSView } from 'components/OMS/OMSView';
import { appNotificationsSlice } from 'providers/AppNotifications/redux/AppNotificationsSlice';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { useCallback } from 'react';
import styled from 'styled-components';
import { HeaderButton } from './styles';

const { openInbox, closeInbox } = appNotificationsSlice.actions;

export function AppInboxHeaderButton(props: { view: OMSView | undefined }) {
  const mixpanel = useMixpanel();
  const view = useAppStateSelector(selectView);
  const dispatch = useAppStateDispatch();
  const appNotificationsState = useAppStateSelector(state => state.appNotifications);
  const activeCount = appNotificationsState.isReady
    ? Object.values(appNotificationsState.notificationMap).filter(toast => toast && toast.state !== 'closed').length
    : undefined;

  const handleClickInbox = useCallback(() => {
    const shouldClose = view === OMSView.NotificationsInbox;
    mixpanel.track(MixpanelEvent.ToggleShowNotificationsInbox, { [MixpanelEventProperty.Expanded]: !shouldClose });

    if (shouldClose) {
      dispatch(closeInbox());
      return;
    }
    dispatch(openInbox());
  }, [dispatch, mixpanel, view]);

  return (
    <HeaderButton
      border="left"
      onClick={handleClickInbox}
      size={FormControlSizes.Default}
      variant={props.view === OMSView.NotificationsInbox ? ButtonVariants.Priority : ButtonVariants.Default}
      data-testid="header-button-orders"
      title="Notifications Inbox"
    >
      <IconWrapper>
        <Icon icon={IconName.Inbox} />
        {activeCount != null && activeCount > 0 && <Badge>{activeCount}</Badge>}
      </IconWrapper>
    </HeaderButton>
  );
}

const IconWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Badge = styled.div<{ active?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: ${({ color, theme }) => (color ? parseColor(theme, color) : theme.colors.orange.lighten)};
  color: ${props => props.theme.colors.white.default};
  border-radius: 9px;
  padding: 1px 1px;
  min-width: 12px;
  height: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
`;

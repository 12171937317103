import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  DEFAULT_MAX_ROWS,
  FormControlSizes,
  HEDGE_ORDER_STATUS,
  HedgeOrderStatus,
  IconName,
  MixpanelEvent,
  WSBlotterTableMaxRecordsReachedWarning,
  columnToColumnState,
  createCSVFileName,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useDynamicCallback,
  useHedgeOrderStatusColumns,
  useMixpanel,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type BlotterColumnState,
  type BlotterTableFilter,
  type BlotterTableSort,
  type Column,
} from '@talos/kyoko';
import * as routes from 'containers/Routes/routes';
import type { GenerateOrderDetailsRoute } from 'containers/Trading/Markets/OrderDetails/types';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';
import { useMemo } from 'react';
import { useFeatureFlag } from '../../../hooks';

import {
  onlyServerFilterKeys,
  useHedgeOrderStatusFilter,
  type HedgeOrderStatusFilter,
} from './useHedgeOrderStatusFilter';

const DEFAULT_SORT: BlotterTableSort<HedgeOrderStatus> = ['-Timestamp', '-Revision'];

export interface HedgeOrderStatusBlotterTableProps {
  /** Unique id per blotter instance */
  blotterID: string;
  /** ID for common blotter type persistence; usually a subset of the blotterId to represent blotter area 'type' */
  blotterPersistID?: string;
  tabLabel?: string;
  orderID?: string;

  defaultColumns?: (Column | keyof HedgeOrderStatus)[];
  defaultFilter?: HedgeOrderStatusFilter;
  defaultSort?: BlotterTableSort<HedgeOrderStatus>;
  persistColumns?: boolean;
  persistFilter?: boolean;
  persistSort?: boolean;

  initialIsOpen?: boolean;

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab?: (filter: BlotterTableFilter, columns: BlotterColumnState[]) => void;
  generateOrderDetailsRoute?: GenerateOrderDetailsRoute;
}

export function HedgeOrderStatusBlotterTable({
  tabLabel,
  orderID,
  blotterID,
  blotterPersistID = blotterID,
  defaultColumns,
  defaultSort = DEFAULT_SORT,
  persistFilter,
  persistColumns,
  persistSort,
  initialIsOpen,
  onCloneTab,
  generateOrderDetailsRoute = routes.getOrderDetailsRoute,
}: HedgeOrderStatusBlotterTableProps) {
  const { getConfig } = useOrgConfiguration();
  const mixpanel = useMixpanel();

  const permanentFilters = useMemo<HedgeOrderStatusFilter>(
    () => ({
      InitiatingOrderID: orderID,
    }),
    [orderID]
  );

  const columnDefinitions = useHedgeOrderStatusColumns({ defaultColumns });

  const persistedBlotterTable = usePersistedBlotterTable<HedgeOrderStatus>(blotterPersistID, {
    columns: columnDefinitions,
    sort: defaultSort,
    persistColumns,
    persistFilter,
    persistSort,
  });

  const filterResults = useHedgeOrderStatusFilter({
    persistedBlotterTable,
    permanentFilters,
  });
  const { clientSideFilter: clientLocalFilter, filterBuilderProps } = filterResults;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });

  const { showBlotterPauseButton } = useFeatureFlag();

  const initialRequest = useMemo(() => {
    return {
      name: HEDGE_ORDER_STATUS,
      tag: blotterID,
      ...(orderID != null && { InitiatingOrderID: orderID }),
    };
  }, [blotterID, orderID]);

  const blotterTable = useWsBlotterTable({
    initialRequest,
    filter: onlyServerFilterKeys(filterResults.filter),
    rowID: HedgeOrderStatus.rowID,
    clientLocalFilter,
    startingRowLimit: getConfig(OrgConfigurationKey.BlotterRowsMax, DEFAULT_MAX_ROWS),
    columns: persistedBlotterTable.columns,
    persistence: persistedBlotterTable,
    pauseParams: {
      showPauseButton: showBlotterPauseButton,
    },
    gridOptions: {
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
    },
  });

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(filterResults.filter, blotterTable.getColumns().map(columnToColumnState));
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'HedgeOrderStatuses',
        tabLabel,
      }),
    });
    extrasMenuPopover.close();
  });
  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTable.blotterTableFiltersProps}
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>
            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
          </BlotterTableExtrasMenu>
        }
      />
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.Timestamp} />
      <BlotterTable {...blotterTable} />
    </>
  );
}

import { useTheme, type DefaultTheme } from 'styled-components';

import type { LoanSides } from '../../types/loanTypes';
import { LoanTransactionStatusEnum } from '../../types/types';
import { Icon, IconName, type IconProps } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { Wrapper } from './styles';

export enum LoanTransactionStatusText {
  Completed = 'Completed',
  Error = 'Error',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  PendingTransfer = 'Initiate',
  CptyToTransfer = 'Cpty to Initiate',
  Processing = 'Processing',
  CptyProcessing = 'Cpty Processing',
  TransferSubmitted = 'Transfer Submitted',
  WaitingForAuth = 'Waiting for External Authorization',
}

const getLoanTransactionStatusColor = (statusText: LoanTransactionStatusText | undefined, theme: DefaultTheme) => {
  const { colors, colorTextPositive, colorTextPrimary, colorTextWarning, colorTextDefault } = theme;
  switch (statusText) {
    case LoanTransactionStatusText.Completed:
      return colorTextPositive;
    case LoanTransactionStatusText.PendingTransfer:
    case LoanTransactionStatusText.Processing:
    case LoanTransactionStatusText.CptyProcessing:
    case LoanTransactionStatusText.CptyToTransfer:
    case LoanTransactionStatusText.TransferSubmitted:
      return colors.blue.lighten;
    case LoanTransactionStatusText.Rejected:
      return colorTextDefault;
    case LoanTransactionStatusText.Error:
      return colorTextWarning;
    default:
      return colorTextPrimary;
  }
};

export const getLoanTransactionStatusText = ({
  status,
  direction,
}: {
  status: LoanTransactionStatusEnum;
  direction: Direction;
}): LoanTransactionStatusText => {
  const inboundLoanTransactionStatusTextMapping: { readonly [key in LoanTransactionStatusEnum]: string } = {
    [LoanTransactionStatusEnum.RateRequestSent]: LoanTransactionStatusText.CptyToTransfer,
    [LoanTransactionStatusEnum.RateRequestAckd]: LoanTransactionStatusText.CptyToTransfer,
    [LoanTransactionStatusEnum.New]: LoanTransactionStatusText.CptyToTransfer,
    [LoanTransactionStatusEnum.TransferPending]: LoanTransactionStatusText.Processing,
    [LoanTransactionStatusEnum.TransferProcessing]: LoanTransactionStatusText.Processing,
    [LoanTransactionStatusEnum.TransferSubmitted]: LoanTransactionStatusText.Processing,
    [LoanTransactionStatusEnum.Completed]: LoanTransactionStatusText.Completed,
    [LoanTransactionStatusEnum.Canceled]: LoanTransactionStatusText.Canceled,
    [LoanTransactionStatusEnum.Rejected]: LoanTransactionStatusText.Rejected,
    [LoanTransactionStatusEnum.Error]: LoanTransactionStatusText.Error,
    [LoanTransactionStatusEnum.TransferWaitingForExternalAuth]: LoanTransactionStatusText.WaitingForAuth,
  };

  const outboundLoanTransactionStatusTextMapping: { readonly [key in LoanTransactionStatusEnum]: string } = {
    [LoanTransactionStatusEnum.RateRequestSent]: LoanTransactionStatusText.PendingTransfer,
    [LoanTransactionStatusEnum.RateRequestAckd]: LoanTransactionStatusText.PendingTransfer,
    [LoanTransactionStatusEnum.New]: LoanTransactionStatusText.PendingTransfer,
    [LoanTransactionStatusEnum.TransferPending]: LoanTransactionStatusText.Processing,
    [LoanTransactionStatusEnum.TransferProcessing]: LoanTransactionStatusText.Processing,
    [LoanTransactionStatusEnum.TransferSubmitted]: LoanTransactionStatusText.Processing,
    [LoanTransactionStatusEnum.Completed]: LoanTransactionStatusText.Completed,
    [LoanTransactionStatusEnum.Canceled]: LoanTransactionStatusText.Canceled,
    [LoanTransactionStatusEnum.Rejected]: LoanTransactionStatusText.Rejected,
    [LoanTransactionStatusEnum.Error]: LoanTransactionStatusText.Error,
    [LoanTransactionStatusEnum.TransferWaitingForExternalAuth]: LoanTransactionStatusText.WaitingForAuth,
  };

  let loanTransactionStatusText: string;
  if (direction === 'Inbound') {
    loanTransactionStatusText = inboundLoanTransactionStatusTextMapping[status];
  } else {
    loanTransactionStatusText = outboundLoanTransactionStatusTextMapping[status];
  }

  if (loanTransactionStatusText == null) {
    return LoanTransactionStatusText.Error;
  }

  return loanTransactionStatusText as LoanTransactionStatusText;
};

const getIcon = (status: LoanTransactionStatusEnum) => {
  switch (status) {
    case LoanTransactionStatusEnum.RateRequestSent:
    case LoanTransactionStatusEnum.RateRequestAckd:
    case LoanTransactionStatusEnum.New:
    case LoanTransactionStatusEnum.TransferPending:
    case LoanTransactionStatusEnum.TransferProcessing:
    case LoanTransactionStatusEnum.TransferSubmitted:
      return IconName.Clock;
    case LoanTransactionStatusEnum.Completed:
      return IconName.CheckCircleSolid;
    case LoanTransactionStatusEnum.Canceled:
    case LoanTransactionStatusEnum.Rejected:
      return IconName.CloseCircleSolid;
    case LoanTransactionStatusEnum.Error:
      return IconName.ExclamationCircleSolid;
    case LoanTransactionStatusEnum.TransferWaitingForExternalAuth:
      return IconName.KeyClock;
  }
};

const StatusIcon = ({
  loanTransactionStatusText,
  status,
  ...props
}: {
  theme: DefaultTheme;
  loanTransactionStatusText: LoanTransactionStatusText;
  status: LoanTransactionStatusEnum;
} & Omit<IconProps, 'icon' | 'color'>) => {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const color = getLoanTransactionStatusColor(loanTransactionStatusText, useTheme() ?? theme);
  const icon = getIcon(status);
  if (!icon) {
    return null;
  }
  return <Icon icon={icon} color={color} {...props} />;
};

type Direction = 'Inbound' | 'Outbound';
type LoanTransactionStatusProps = {
  status: LoanTransactionStatusEnum;
  side?: LoanSides;
  direction: Direction;
  align?: 'right' | 'left';
  iconPlacement?: 'right' | 'left';
  text?: string;
  theme?: DefaultTheme;
  showLabel?: boolean;
};

export function LoanTransactionStatus({
  status,
  side,
  align = 'right',
  iconPlacement = 'left',
  showLabel,
  text,
  direction,
  ...props
}: LoanTransactionStatusProps) {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const loanTransactionStatusText = getLoanTransactionStatusText({ status, direction });

  return (
    <Wrapper align={align} title={text} theme={theme} iconPlacement={iconPlacement} {...props}>
      {!showLabel ? loanTransactionStatusText : null}
      <IndicatorDotWrapper show={!!text} theme={theme} variant={IndicatorDotVariants.Warning}>
        <StatusIcon status={status} loanTransactionStatusText={loanTransactionStatusText} theme={theme} size={14} />
      </IndicatorDotWrapper>
    </Wrapper>
  );
}

import { Box, DistributionBar, Meter, type DistributionNamedSection } from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-community';
import { isCareOrderRow, type CareOrderBlotterEntity } from './types';
import type { CareOrderProgressBar } from './utils';

export function AgCareOrderProgress(params: ICellRendererParams<CareOrderBlotterEntity>) {
  if (isCareOrderRow(params.data)) {
    const bars: CareOrderProgressBar[] = params.value;
    const sections: DistributionNamedSection[] = bars
      .filter(bar => bar.valueNotional.gt(0))
      .map(bar => ({
        id: bar.id,
        header: null,
        parts: [
          {
            id: bar.id,
            color: bar.color,
            value: bar.valuePercent,
            appearance: bar.appearance,
          },
        ],
      }));

    return (
      <Box w="100%">
        <DistributionBar sections={sections} showHeaders={false} showRemainder={true} showLabel={true} size="small" />
      </Box>
    );
  }

  return <Meter label={params.valueFormatted!} {...params.value.meter} showInitialAnimation={false} animate={false} />;
}

import type { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { get } from 'lodash-es';
import { format, toBig } from '../../../utils';
import { InlineFormattedNumber } from '../../FormattedNumber';
import { sizedAvgAggFunc, type SizedAvgValue } from '../aggFuncs/sizedAvgAggFunc';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export interface SizedAvgColumnParams {
  sizeField: string;
  sizeCurrencyField: string;
  markField: string;
  markCurrencyField: string;
}

/**
 * The sizedAvg column is just a simple number + currency renderer, but importantly allows the implementer to aggregate
 * averages. This is done telling the column where both the average and size are. With this information,
 * the column can aggregate averages by re-computing total sizes and then re-computing the average based on that.
 *
 * An example use of this column could be for rendering and aggregating AvgCost values.
 *
 * If you do not need aggregation, you might as well use the price column for example.
 */
export const sizedAvg: ColDefFactory<Column<SizedAvgColumnParams>> = column => ({
  ...baseColumn(column),
  valueGetter: ({ data }: ValueGetterParams<unknown>): SizedAvgValue => {
    const { sizeField, markField, sizeCurrencyField, markCurrencyField } = column.params ?? {};
    if (!sizeField || !markField || !sizeCurrencyField || !markCurrencyField) {
      return undefined;
    }

    if (!data) {
      return undefined;
    }

    const size: string | undefined = get(data, sizeField);
    const sizeCurrency: string | undefined = get(data, sizeCurrencyField);
    const mark: string | undefined = get(data, markField);
    const markCurrency: string | undefined = get(data, markCurrencyField);

    const sizeBig = toBig(size);
    const markBig = toBig(mark);

    if (!sizeBig || !markBig || !sizeCurrency || !markCurrency) {
      return undefined;
    }

    return {
      size: sizeBig.abs(),
      sizeCurrency,
      mark: markBig.abs(),
      markCurrency,
      aggregate: true, // everything checks out, we are aggregatable
    };
  },
  aggFunc: column.aggregate ? sizedAvgAggFunc : undefined,
  cellRenderer: ({ value }: ICellRendererParams<unknown, SizedAvgValue>) => {
    if (!value || !value.aggregate) {
      return <></>;
    }

    return <InlineFormattedNumber number={value.mark} currency={value.markCurrency} />;
  },
  // for exporting:
  valueFormatter: ({ value, context }: ValueFormatterParams<unknown, SizedAvgValue>) => {
    if (!value || !value.aggregate) {
      return '';
    }

    const markCurrency = context.current.currenciesBySymbol?.get(value.markCurrency);

    const formattedMark = markCurrency
      ? format(value.mark, { pretty: false, spec: markCurrency.MinIncrement, round: true })
      : value.mark.toFixed();

    return formattedMark;
  },
});

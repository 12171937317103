import {
  ConnectionStatusEnum,
  ConnectionStatusIndicator,
  ConnectionType,
  ICON_SIZES,
  LoaderSizes,
  LoaderTalos,
  MarketLogo,
  Tbody,
  Td,
  Th,
  Tr,
  connectionStatusText,
  isConnectionRecovering,
  prettyName,
  useConnectionStatusContext,
  useMarketAccountsContext,
  useObservableValue,
  type MarketCredentialWithConnectionStatus,
  type MarketDataSnapshot,
  type WebsocketError,
} from '@talos/kyoko';
import { isEqual } from 'lodash-es';
import { useMarketsContext } from 'providers';
import type React from 'react';
import { useMemo } from 'react';
import type { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { useTheme } from 'styled-components';
import { isConnConfiguredAndNotOffline } from 'utils/status';
import { MarketDisplayName, Wrapper } from './styles';

/**
 * Table suitable for use in a tooltip, showing the status of all market credentials
 */
export const GlobalMarketStatusTable: React.FC = () => {
  const { marketCredentialsWithConnectionStatusByCredentialID } = useConnectionStatusContext();
  const marketCredentialsWithStatus = useObservableValue(
    () =>
      marketCredentialsWithConnectionStatusByCredentialID.pipe(
        map(connsWithStatus => [...connsWithStatus.values()].filter(isConnConfiguredAndNotOffline))
      ),
    [marketCredentialsWithConnectionStatusByCredentialID],
    []
  );
  return <StatusTable marketCredentialsWithStatus={marketCredentialsWithStatus} />;
};

const StatusTable: React.FC<{
  marketCredentialsWithStatus: MarketCredentialWithConnectionStatus[];
}> = ({ marketCredentialsWithStatus }) => {
  const { marketsWithConnTypesSupported } = useMarketsContext();
  const { fontSizeDefault } = useTheme();

  const markets = useObservableValue(
    () => marketsWithConnTypesSupported().pipe(map(markets => new Map(markets.map(m => [m.Name, m])))),
    [marketsWithConnTypesSupported]
  );

  const sortedMarketCredentialsWithStatus = useMemo(() => {
    return marketCredentialsWithStatus.sort((a, b) => {
      const aMarket = markets?.get(a.credential.Market)?.DisplayName ?? a.credential.Market;
      const bMarket = markets?.get(b.credential.Market)?.DisplayName ?? b.credential.Market;
      return aMarket.localeCompare(bMarket);
    });
  }, [markets, marketCredentialsWithStatus]);

  if (!markets || !marketCredentialsWithStatus?.length) {
    return <LoaderTalos size={LoaderSizes.SMALL} wrapperStyle={{ width: '200px' }} />;
  }

  return (
    <Wrapper width="200px">
      <Tbody>
        {sortedMarketCredentialsWithStatus.map(cred => {
          const market = markets.get(cred.credential.Market);
          const connectionStatus = cred.connectionStatus;
          const isRecovering = isConnectionRecovering(connectionStatus);
          const isOnline = connectionStatus?.Status === ConnectionStatusEnum.Online;
          const isError = connectionStatus?.Status === ConnectionStatusEnum.Error;
          const tooltip = isOnline ? (
            ''
          ) : (
            <Wrapper>
              <Tbody>
                <tr>
                  <Th colSpan={2}>
                    <strong>
                      {connectionStatusText(connectionStatus?.Status ?? ConnectionStatusEnum.Unavailable)}
                      {isError ? `- ${prettyName(connectionStatus?.ErrorCode ?? 'Connection status not found')}` : ''}
                    </strong>
                    {isError ? (
                      <>
                        <br />
                        <div style={{ whiteSpace: 'break-spaces' }}>({connectionStatus?.Text})</div>
                      </>
                    ) : null}
                  </Th>
                </tr>
              </Tbody>
            </Wrapper>
          );

          return (
            <Tr
              key={`${cred.credential.Name}-${cred.credential.CredentialID}-${
                cred.connectionStatus?.ConnectionID ?? 0
              }`}
            >
              <Td>
                <MarketLogo market={market?.Name} title={market?.DisplayName} size={`${fontSizeDefault}em`} />
              </Td>
              <MarketDisplayName>{cred.credential.Label}</MarketDisplayName>
              <Td>
                <ConnectionStatusIndicator
                  status={cred.connectionStatus?.Status ?? ConnectionStatusEnum.Unavailable}
                  text=""
                  iconRight
                  popoverText={isRecovering ? 'Recovering' : tooltip}
                  isLoading={isRecovering}
                />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Wrapper>
  );
};

export const MarketDataStatusTable: React.FC<{
  snapshots: Observable<MarketDataSnapshot>;
  websocketError?: WebsocketError;
}> = ({ snapshots, websocketError, ...props }) => {
  const { marketsByName, isMarketConfigured } = useMarketsContext();
  const { marketAccountsByName } = useMarketAccountsContext();
  const { marketCredentialsWithConnectionStatusByMarketAccountName } = useConnectionStatusContext();

  const statuses = useObservableValue(
    () =>
      snapshots.pipe(
        map(snapshot => {
          const ms = snapshot.Markets ?? {};
          return Object.keys(ms)
            .filter(m => isMarketConfigured(m, ConnectionType.MarketData))
            .map(m => ({ Market: m, ...ms[m] }));
        }),
        distinctUntilChanged((p, n) => isEqual(p, n))
      ),
    [snapshots, isMarketConfigured],
    []
  );

  if (statuses == null) {
    return null;
  }

  return (
    <Wrapper data-testid="market-data-status-table" {...props}>
      <Tbody>
        {websocketError ? (
          <tr key="error">
            <Td textAlign="left">{websocketError.msg}</Td>
          </tr>
        ) : (
          statuses.map((s, i) => {
            const displayName = (marketAccountsByName.get(s.MarketAccount!) ?? marketsByName.get(s.Market))
              ?.DisplayName;
            const marketConnectionStatus = marketCredentialsWithConnectionStatusByMarketAccountName.get(
              s.MarketAccount!
            );
            const isRecovering = isConnectionRecovering(marketConnectionStatus?.connectionStatus);
            const status = s.Status;

            if (!displayName) {
              return null;
            }
            return (
              <tr key={i} data-testid={`market/${displayName}`}>
                <MarketDisplayName>{displayName}</MarketDisplayName>
                <Td textAlign="right">
                  <ConnectionStatusIndicator
                    status={status}
                    text={connectionStatusText(status)}
                    popoverText={isRecovering ? 'Recovering' : s.Text}
                    isLoading={isRecovering}
                    size={ICON_SIZES.SMALL}
                    iconRight
                  />
                </Td>
              </tr>
            );
          })
        )}
      </Tbody>
    </Wrapper>
  );
};

import { addListener, type createListenerMiddleware, type ThunkDispatch, type UnknownAction } from '@reduxjs/toolkit';
import type { IWebSocketClient } from '@talos/kyoko';
import type { customerUserSlice } from 'components/DetailsDrawer/Customer/CustomerUserDetailsDrawer/state/slice';
import type { ManualRFQPricingState } from 'components/OMS/ManualRFQPricingView/types';
import type { PortfolioViewLayoutState } from 'containers/Portfolio/PortfolioManagement/stateManagement/portfolioViewLayoutSlice';
import type { filterSlice } from 'containers/Trading/Markets/Options/Filter/filterSlice';
import type { AppConfigReduxState } from 'providers/AppConfigRedux/AppConfigSlice';
import type {
  appNotificationsApi,
  appNotificationsStreamingApi,
} from 'providers/AppNotifications/redux/AppNotificationsApi';
import type { AppNotificationsState } from 'providers/AppNotifications/redux/AppNotificationsSlice';
import type { AppLayoutState } from '../../components/AppLayout/AppLayoutSlice';
import type { MultilegComboState } from '../../components/MultilegCombo/types';
import type { ActiveOrderCardsState } from '../../components/OMS/Cards/types';
import type { CareOrderState } from '../../components/OMS/CareOrderFormView/types';
import type { DetailsDrawerState } from '../../components/OMS/Details/DetailsDrawerSlice';
import type { ManualOrderFillState } from '../../components/OMS/ManualOrderFillView/ManualOrderFillSlice';
import type { ManualTradeEntryState } from '../../components/OMS/ManualTradeView/ManualTradeSlice';
import type { OrderState } from '../../components/OMS/NewOrder/types';
import type { RFQState } from '../../components/OMS/NewRFQ/types';
import type { OrderPresetState } from '../../components/OMS/Presets/types';
import type { SalesOrderState } from '../../components/OMS/SalesOrder/types';
import type { SalesRFQState } from '../../components/OMS/SalesRFQView/types';
import type { OMSReferenceDataState, OMSState } from '../../components/OMS/types';
import type { navigationSlice } from './navigationSlice';
import type { streamingDataSlice } from './streamingDataSlice';
import type { userInfoSlice } from './userInfoSlice';

export type AppState = {
  appConfig: AppConfigReduxState;
  appLayout: AppLayoutState;
  appNotifications: AppNotificationsState;
  appNotificationsApi: ReturnType<typeof appNotificationsApi.reducer>;
  appNotificationsStreamingApi: ReturnType<typeof appNotificationsStreamingApi.reducer>;
  cards: ActiveOrderCardsState;
  careOrder: CareOrderState;
  customerUserDrawer: ReturnType<typeof customerUserSlice>;
  detailsDrawer: DetailsDrawerState;
  manualOrderFill: ManualOrderFillState;
  manualRFQPricing: ManualRFQPricingState;
  manualTrade: ManualTradeEntryState;
  multilegCombo: MultilegComboState;
  navigation: ReturnType<typeof navigationSlice.reducer>;
  OMS: OMSState;
  optionsTab: ReturnType<typeof filterSlice.reducer>;
  order: OrderState;
  portfolioViewLayout: PortfolioViewLayoutState;
  presets: OrderPresetState;
  referenceData: OMSReferenceDataState;
  rfq: RFQState;
  salesOrder: SalesOrderState;
  salesRFQ: SalesRFQState;
  streamingData: ReturnType<typeof streamingDataSlice.reducer>;
  userInfo: ReturnType<typeof userInfoSlice.reducer>;
};

export interface AppExtraArgument {
  wsClient: IWebSocketClient<unknown>;
}

export type AppStateListenerStart = ReturnType<typeof buildAppStateListener>;
export const buildAppStateListener = (listenerMiddleware: ReturnType<typeof createListenerMiddleware>) => {
  return listenerMiddleware.startListening.withTypes<
    AppState,
    ThunkDispatch<AppState, AppExtraArgument, UnknownAction>,
    AppExtraArgument
  >();
};

export const addAppListener = addListener.withTypes<
  AppState,
  ThunkDispatch<AppState, AppExtraArgument, UnknownAction>
>();

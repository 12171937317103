import {
  ACTION,
  AggregationType,
  Button,
  ButtonVariants,
  EntityAdminREST,
  EntityAdminTabsWrapper,
  FormControlSizes,
  IconName,
  useAggregationsContext,
  useCurrenciesContext,
  useDisclosure,
  useFeeCurrenciesFilter,
  useMarketFeeModesFilter,
  useSecuritiesContext,
  useSymbolsFilter,
  type AllInTier,
  type FeeTier,
  type FilterableProperty,
  type Security,
} from '@talos/kyoko';
import { usePricingAggregationsFilter, usePricingTierFilters, useRoleAuth } from 'hooks';
import { filter } from 'lodash-es';
import { useCustomerPricingTierContext, useCustomerSecuritiesBySymbol } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EditDefaultPricingRuleDialog } from '../CustomerPricing/EditDefaultPricingRuleDialog';
import { useEditPricingRule } from '../CustomerPricing/useEditPricingRule';
import { DEALER_FEE_MODE_OPTIONS } from '../utils';
import {
  CUSTOMER_PRICING_TIERS_ADD_CHILD_ENTITY_BUTTON_PROPS,
  CUSTOMER_PRICING_TIERS_ALL_IN_TIER_COLUMNS,
  CUSTOMER_PRICING_TIERS_FEE_TIERS_COLUMNS,
  CUSTOMER_PRICING_TIERS_GROUP_COLUMN_DEF,
  CUSTOMER_PRICING_TIERS_TAB_ITEMS,
  getCustomerPricingTiersAllInTierDrawerOptions,
  getCustomerPricingTiersAllInTierOverridesDrawerOptions,
  getCustomerPricingTiersAllInTierPatchDeletePath,
  getCustomerPricingTiersAllInTierPostPath,
  getCustomerPricingTiersFeeTierDrawerOptions,
  getCustomerPricingTiersFeeTierOverridesDrawerOptions,
  getCustomerPricingTiersFeeTierPatchDeletePath,
  getCustomerPricingTiersFeeTierPostPath,
} from './utils';

export const CustomerPricingTiers = () => {
  const { isAuthorized } = useRoleAuth();
  const { securitiesBySymbol } = useSecuritiesContext();

  const [allInTiers, setAllInTiers] = useState<AllInTier[]>();
  const [feeTiers, setFeeTiers] = useState<FeeTier[]>();
  const { getAllInTiers, getFeeTiers } = useCustomerPricingTierContext();
  useEffect(() => {
    getAllInTiers(true).then(({ data }) => {
      setAllInTiers(data);
    });
    getFeeTiers(true).then(({ data }) => {
      setFeeTiers(data);
    });
  }, [getAllInTiers, getFeeTiers]);

  const { aggregationsByName } = useAggregationsContext();
  const { currenciesList } = useCurrenciesContext();
  const customerSecuritiesBySymbol = useCustomerSecuritiesBySymbol();

  const filteredSecurities = useMemo((): Security[] => {
    if (!customerSecuritiesBySymbol) {
      return [];
    }

    // Return an array of securities that exist in customerSecuritiesBySymbol
    return filter(Array.from(securitiesBySymbol.values()), security => customerSecuritiesBySymbol.has(security.Symbol));
  }, [customerSecuritiesBySymbol, securitiesBySymbol]);

  const { allInTierFilter, feeTierFilter } = usePricingTierFilters();
  const marketFeeModeFilter = useMarketFeeModesFilter();
  const feeCurrencyFilter = useFeeCurrenciesFilter(currenciesList.map(currency => currency.Symbol));
  const pricingAggregationFilter = usePricingAggregationsFilter();
  const symbolFilter = useSymbolsFilter(filteredSecurities);

  const customerAggregations = useMemo(() => {
    if (aggregationsByName != null) {
      return Array.from(aggregationsByName.values()).filter(
        aggregation => aggregation.AggregationType === AggregationType.Customer
      );
    }
  }, [aggregationsByName]);

  const allInTierDrawerOptions = useMemo(() => {
    if (customerAggregations != null) {
      return getCustomerPricingTiersAllInTierDrawerOptions(
        customerAggregations,
        DEALER_FEE_MODE_OPTIONS,
        currenciesList
      );
    }
  }, [currenciesList, customerAggregations]);

  const allInTierOverridesDrawerOptions = useMemo(() => {
    if (customerAggregations != null && allInTiers != null) {
      const allInTierGroups = allInTiers.filter(tier => tier.Symbol == null);
      return getCustomerPricingTiersAllInTierOverridesDrawerOptions(
        customerAggregations,
        DEALER_FEE_MODE_OPTIONS,
        currenciesList,
        allInTierGroups,
        filteredSecurities
      );
    }
  }, [allInTiers, currenciesList, customerAggregations, filteredSecurities]);

  const feeTierDrawerOptions = useMemo(() => {
    if (currenciesList != null) {
      return getCustomerPricingTiersFeeTierDrawerOptions(DEALER_FEE_MODE_OPTIONS, currenciesList);
    }
  }, [currenciesList]);

  const feeTierOverridesDrawerOptions = useMemo(() => {
    if (currenciesList != null && feeTiers != null) {
      const feeTierGroups = feeTiers.filter(tier => tier.Symbol == null);
      return getCustomerPricingTiersFeeTierOverridesDrawerOptions(
        DEALER_FEE_MODE_OPTIONS,
        currenciesList,
        feeTierGroups,
        filteredSecurities
      );
    }
  }, [currenciesList, filteredSecurities, feeTiers]);

  const getAllInTierDrawerOptions = useCallback(
    (entity?: AllInTier, addingChildEntity?: boolean) => {
      if (addingChildEntity) {
        return allInTierOverridesDrawerOptions;
      }
      const isGroupRow = entity?.Symbol == null;
      return isGroupRow ? allInTierDrawerOptions : allInTierOverridesDrawerOptions;
    },
    [allInTierDrawerOptions, allInTierOverridesDrawerOptions]
  );

  const getFeeTierDrawerOptions = useCallback(
    (entity?: FeeTier, addingChildEntity?: boolean) => {
      if (addingChildEntity) {
        return feeTierOverridesDrawerOptions;
      }
      const isGroupRow = entity?.Symbol == null;
      return isGroupRow ? feeTierDrawerOptions : feeTierOverridesDrawerOptions;
    },
    [feeTierDrawerOptions, feeTierOverridesDrawerOptions]
  );

  const getEditEntityName = useCallback(
    (entity: AllInTier | FeeTier) => {
      if (entity.Symbol == null) {
        // Editing Entity: Tier
        return entity.Tier;
      } else {
        // Editing Entity: Tier > Symbol
        const displaySymbol = customerSecuritiesBySymbol?.get(entity.Symbol)?.DisplaySymbol;
        return `${entity.Tier} > ${displaySymbol ?? entity.Symbol}`;
      }
    },
    [customerSecuritiesBySymbol]
  );

  const allInTierFilterableProperties: FilterableProperty[] | undefined = useMemo(() => {
    if (allInTierFilter == null) {
      return undefined;
    }
    return [
      // field "AllInTier" --> Represented as "Tier" in Overrides
      { ...allInTierFilter, field: 'Tier' },
      symbolFilter,
      marketFeeModeFilter,
      feeCurrencyFilter,
      pricingAggregationFilter,
    ];
  }, [allInTierFilter, feeCurrencyFilter, marketFeeModeFilter, pricingAggregationFilter, symbolFilter]);

  const feeTierFilterableProperties: FilterableProperty[] | undefined = useMemo(() => {
    if (feeTierFilter == null) {
      return undefined;
    }
    return [
      // field "FeeTier" --> Represented as "Tier" in Overrides
      { ...feeTierFilter, field: 'Tier' },
      symbolFilter,
      marketFeeModeFilter,
      feeCurrencyFilter,
    ];
  }, [feeTierFilter, feeCurrencyFilter, marketFeeModeFilter, symbolFilter]);

  const defaultPricingRuleDialog = useDisclosure();

  const defaultPricingRuleDialogComponent = useMemo(() => {
    return (
      <Button
        variant={ButtonVariants.Priority}
        startIcon={IconName.Pencil}
        size={FormControlSizes.Small}
        key="defaultPricingRuleDialog"
        onClick={() => defaultPricingRuleDialog.open()}
      >
        Default Pricing Rule
      </Button>
    );
  }, [defaultPricingRuleDialog]);

  const handleEditPricingRule = useEditPricingRule({ onSettled: undefined });

  return (
    <>
      <EditDefaultPricingRuleDialog {...defaultPricingRuleDialog} handleEditPricingRule={handleEditPricingRule} />
      <EntityAdminTabsWrapper tabItems={CUSTOMER_PRICING_TIERS_TAB_ITEMS} persistKey="dealer/tiers/tabs">
        <EntityAdminREST<AllInTier>
          title={CUSTOMER_PRICING_TIERS_TAB_ITEMS[0].label}
          subtitle="Set up your Base Tiers here."
          path="/all-in-tiers?expanded=true"
          getPostPath={getCustomerPricingTiersAllInTierPostPath}
          getPatchDeletePath={getCustomerPricingTiersAllInTierPatchDeletePath}
          columns={CUSTOMER_PRICING_TIERS_ALL_IN_TIER_COLUMNS}
          entityIDField="Tier"
          childIDField="Symbol"
          addChildEntityButtonProps={CUSTOMER_PRICING_TIERS_ADD_CHILD_ENTITY_BUTTON_PROPS}
          getEntityDrawerOptions={getAllInTierDrawerOptions}
          filterableProperties={allInTierFilterableProperties}
          entityName="Base Tier"
          allowAddEntity={isAuthorized(ACTION.EDIT_CUSTOMER_PRICING_TIERS)}
          allowEditEntity={isAuthorized(ACTION.EDIT_CUSTOMER_PRICING_TIERS)}
          allowDeleteEntity={isAuthorized(ACTION.EDIT_CUSTOMER_PRICING_TIERS)}
          getEditEntityName={getEditEntityName}
          groupColumnDef={CUSTOMER_PRICING_TIERS_GROUP_COLUMN_DEF}
          persistKey="all-in-tiers"
          panelActions={[defaultPricingRuleDialogComponent]}
          allowBulkEdit={true}
          bulkEditFields={[
            'BidSpread',
            'OfferSpread',
            'SalesCommission',
            'PricingAggregation',
            'Fee',
            'FeeMode',
            'FeeCurrency',
            'QuoteTTL',
            'AcceptPriceLeniency',
          ]}
        />
        <EntityAdminREST<FeeTier>
          title={CUSTOMER_PRICING_TIERS_TAB_ITEMS[1].label}
          subtitle="Set up your Fee Tiers here."
          path="/fee-tiers?expanded=true"
          getPostPath={getCustomerPricingTiersFeeTierPostPath}
          getPatchDeletePath={getCustomerPricingTiersFeeTierPatchDeletePath}
          columns={CUSTOMER_PRICING_TIERS_FEE_TIERS_COLUMNS}
          entityIDField="Tier"
          childIDField="Symbol"
          addChildEntityButtonProps={CUSTOMER_PRICING_TIERS_ADD_CHILD_ENTITY_BUTTON_PROPS}
          getEntityDrawerOptions={getFeeTierDrawerOptions}
          filterableProperties={feeTierFilterableProperties}
          entityName="Fee Tier"
          allowAddEntity={isAuthorized(ACTION.EDIT_CUSTOMER_PRICING_TIERS)}
          allowEditEntity={isAuthorized(ACTION.EDIT_CUSTOMER_PRICING_TIERS)}
          allowDeleteEntity={isAuthorized(ACTION.EDIT_CUSTOMER_PRICING_TIERS)}
          getEditEntityName={getEditEntityName}
          groupColumnDef={CUSTOMER_PRICING_TIERS_GROUP_COLUMN_DEF}
          persistKey="fee-tiers"
          panelActions={[defaultPricingRuleDialogComponent]}
          allowBulkEdit={true}
          bulkEditFields={['Fee', 'FeeMode', 'FeeCurrency']}
        />
      </EntityAdminTabsWrapper>
    </>
  );
};

import { InstrumentCompositionEnum, OptionTypeEnum, ProductTypeEnum, type OptionSecurity } from '@talos/kyoko';

export const options = [
  {
    ProductType: ProductTypeEnum.Option,
    Expiration: '2028-06-26T12:00:00.000000Z',
    Markets: ['coinbase'],
    Symbol: 'Option1',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'BTC',
    UnderlyingCode: 'BTC',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'BTC',
    NotionalMultiplier: '1',
    NormalSize: '1000',
    MinimumSize: '100',
    Composition: InstrumentCompositionEnum.Native,
    StrikePrice: '10000',
    OptionType: OptionTypeEnum.Call,
    SupportedPricingModes: {
      ImpliedVolatility: true,
    },
  },
  {
    ProductType: ProductTypeEnum.Option,
    Expiration: '2029-06-26T12:00:00.000000Z',
    Markets: ['coinbase'],
    Symbol: 'Option2',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'BTC',
    UnderlyingCode: 'BTC',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'BTC',
    NotionalMultiplier: '10',
    NormalSize: '1000',
    MinimumSize: '100',
    Composition: InstrumentCompositionEnum.Native,
    StrikePrice: '11000',
    OptionType: OptionTypeEnum.Call,
    SupportedPricingModes: {
      UnderlyingQuoteCurrency: true,
    },
  },
  {
    ProductType: ProductTypeEnum.Option,
    Expiration: '2029-06-26T12:00:00.000000Z',
    Markets: ['coinbase'],
    Symbol: 'Option3',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'ETH',
    UnderlyingCode: 'ETH',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'ETH',
    NotionalMultiplier: '1',
    NormalSize: '1000',
    MinimumSize: '100',
    Composition: InstrumentCompositionEnum.Native,
    StrikePrice: '10000',
    OptionType: OptionTypeEnum.Call,
    SupportedPricingModes: {
      ImpliedVolatility: true,
      UnderlyingQuoteCurrency: true,
    },
  },
  {
    ProductType: ProductTypeEnum.Option,
    Expiration: '2029-06-26T12:00:00.000000Z',
    Markets: ['cme'],
    Symbol: 'Option4',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'BTC',
    UnderlyingCode: 'MBT',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'BTC',
    NotionalMultiplier: '1',
    NormalSize: '1000',
    MinimumSize: '100',
    Composition: InstrumentCompositionEnum.Native,
    StrikePrice: '10000',
    OptionType: OptionTypeEnum.Call,
  },
  {
    ProductType: ProductTypeEnum.Option,
    Expiration: '2029-06-26T12:00:00.000000Z',
    Markets: ['cme'],
    Symbol: 'Option5',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'BTC',
    UnderlyingCode: 'BTC',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'BTC',
    NotionalMultiplier: '1',
    NormalSize: '1000',
    MinimumSize: '100',
    Composition: InstrumentCompositionEnum.Native,
    StrikePrice: '10000',
    OptionType: OptionTypeEnum.Call,
  },
  {
    ProductType: ProductTypeEnum.Option,
    Expiration: '2028-06-26T12:00:00.000000Z',
    Markets: ['coinbase'],
    Symbol: 'Option6',
    EndTime: undefined,
    Description: 'Everything same as Option1 but with different Strike so we can build up a Vertical Spread unit test',
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'BTC',
    UnderlyingCode: 'BTC',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'BTC',
    NotionalMultiplier: '1',
    NormalSize: '1000',
    MinimumSize: '100',
    Composition: InstrumentCompositionEnum.Native,
    StrikePrice: '11000',
    OptionType: OptionTypeEnum.Call,
    SupportedPricingModes: {
      ImpliedVolatility: true,
    },
  },
  {
    Timestamp: '2028-04-02T07:06:48.597468Z',
    Symbol: 'Option7',
    MinPriceIncrement: '1',
    MinSizeIncrement: '1',
    MinAmtIncrement: '0',
    MinimumSize: '1',
    MaximumSize: '0',
    QuoteCurrency: 'USDC',
    BaseCurrency: 'ETH',
    DefaultPriceIncrement: '0',
    DefaultSizeIncrement: '0',
    PriceDisplaySpec: 'M',
    SizeDisplaySpec: 'M',
    NormalSize: '1',
    ProductType: 'Option',
    SettlementCurrency: 'USDC',
    NotionalMultiplier: '1',
    Expiration: '2028-04-26T08:00:00.000000Z',
    OptionType: 'Call',
    StrikePrice: '3200',
    UnderlyingQuoteCurrency: 'USDC',
    DisplaySymbol: 'Deribit ETH_USDC-26APR28-3200-C',
    Description: 'Deribit ETH/USDC Call Option 26APR28 3200',
    SizeBuckets: ['1', '2', '5', '10', '50'],
    Markets: ['deribit'],
    SettleValueType: 'Regular',
    SupportedPricingModes: {
      ImpliedVolatility: true,
    },
    UnderlyingCode: 'ETH USDC',
  },
] as unknown as OptionSecurity[];

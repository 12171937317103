import type { OrdTypeEnum, PresenceEnum } from './types';

// These "Like" interfaces are used in Kyoko components that use both Principal+Whitelabel Strategy Params
export interface ParameterLike {
  DisplayName: string;
  Name: string;
  Type: ParameterTypeEnum;
  Presence: PresenceEnum;
  Description: string;
  DefaultValue?: string;
  EnumValues?: EnumParameter[];
}

export interface EnumParameter {
  Index: number;
  Name: string;
  Description: string;
}

export interface StrategyLike {
  Name: string;
  DisplayName: string;
  Parameters: ParameterLike[];
  Group?: string;
  Description?: string;
  OrdType?: OrdTypeEnum;
}

export enum ParameterTypeEnum {
  PriceOffset = 'PriceOffset',
  PriceProtection = 'PriceProtection',
  Qty = 'Qty',
  Date = 'Date',
  Duration = 'Duration',
  Interval = 'Interval',
  Integer = 'Integer',
  Percent = 'Percent',
  Price = 'Price',
  Bps = 'Bps',
  Aggregation = 'Aggregation',
  String = 'String',
  Enum = 'Enum',
  UnifiedLiquidity = 'UnifiedLiquidity',
  TargetParticipationRate = 'TargetParticipationRate',
  ReduceOnly = 'ReduceOnly',
  ReduceFirst = 'ReduceFirst',
  JSON = 'JSON',
}

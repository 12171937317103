import {
  abbreviateId,
  formattedDateForSubscription,
  parseDate,
  useDynamicCallback,
  useTabs,
  type UseTabs,
  type UseTabsProps,
} from '@talos/kyoko';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { v1 as uuid } from 'uuid';
import { getIdFromAnalyticsRoute } from '../containers/Routes/routes';

import {
  AggregationWindowEnum,
  ReportType,
  type IAnalyticsOrderDetailsTab,
  type IAnalyticsReportTab,
} from '../containers/Analytics/Reports/types';
import { getLastYear, startOfLastUTCMonth, startOfUTCMonth } from '../containers/Analytics/Reports/utils';

export const BLOTTER_PREFIX = 'analytics-blotters';

export const OVERVIEW = 'overview';
export const LATEST_MONTH = 'latestMonth';

export const ANALYTICS_REPORTS_TABS_ID = 'reports-tabs/v2';

const lastYear = getLastYear();
const lastMonth = startOfLastUTCMonth();
const thisMonth = startOfUTCMonth(parseDate());
export const DEFAULT_REPORT_TABS = [
  {
    id: OVERVIEW,
    label: 'Past Year',
    customLabel: true,
    filter: {
      StartDate: formattedDateForSubscription(lastYear),
      EndDate: formattedDateForSubscription(thisMonth),
    },
    type: ReportType.monthlyOverview,
  },
  {
    id: LATEST_MONTH,
    label: 'Latest Month',
    filter: {
      AggregationWindow: AggregationWindowEnum.Month,
      StartDate: formattedDateForSubscription(lastMonth),
      EndDate: formattedDateForSubscription(thisMonth),
    },
    type: ReportType.symbolsOverview,
  },
] satisfies IAnalyticsReportTab[];

export const ANALYTICS_TABS_EXCLUDED_IDS = [OVERVIEW];

function generateTabUrl(tab: IAnalyticsReportTab | IAnalyticsOrderDetailsTab): string {
  switch (tab.type) {
    case 'order-details':
      return '/analytics/order/' + tab.orderID;
    default:
      // return `/analytics/${tab.id}${tab.focus ? `?focus=${tab.focus}` : ''}`;
      return `/analytics/${tab.id}`;
  }
}

export const AnalyticsTabsContext = createContext<
  | {
      tabs: UseTabs<IAnalyticsReportTab | IAnalyticsOrderDetailsTab>;
      temporaryTab: IAnalyticsOrderDetailsTab | undefined;
      removeActiveTab: () => void;
      saveOrDiscardTemporaryTab: (value: boolean) => void;
      setTabLabeler: Dispatch<
        SetStateAction<UseTabsProps<IAnalyticsReportTab | IAnalyticsOrderDetailsTab>['tabLabeler']>
      >;
    }
  | undefined
>(undefined);
AnalyticsTabsContext.displayName = 'AnalyticsTabsContext';

export const useAnalyticsTabs = () => {
  const context = useContext(AnalyticsTabsContext);
  if (context == null) {
    throw new Error('Missing AnalyticsTabsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export interface UseAnalyticsTabsContextProps {
  initialTabs: (IAnalyticsReportTab | IAnalyticsOrderDetailsTab)[];
  initialSelectedIndex: number;
  saveTabs: (analyticsTabs: (IAnalyticsReportTab | IAnalyticsOrderDetailsTab)[]) => void;
}

/**
 * This hook is used by the app config provider.
 * The app config provider will provide the props for this hook, and in turn,
 * this hook will return the props to set on the `AnalyticsTabsContext`.
 * The logic here is kind of messy, because we are trying to avoid infinite recursion
 * caused by updating the URL to match the selected tab, *and* updating the selected
 * tab to match the URL.
 * The logic has been pulled up to this layer, because it needs to be accessed by both the OrderDetails
 * (for the "X" button in the header to close the tab)
 * and the Analytics components, and we need to ensure that they both have the same view of the tab state.
 * Specifically, we need *one* source of truth for the tab state, including any temporary tab (which isn't persisted),
 * so that OrderDetails can close the temporary tab if required.
 * `usePersistedTabs` does not give us this state shared between 2 separate components, so we need this context instead.
 */
export function useAnalyticsTabsContext({ initialTabs, initialSelectedIndex, saveTabs }: UseAnalyticsTabsContextProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabLabeler, setTabLabeler] =
    useState<UseTabsProps<IAnalyticsReportTab | IAnalyticsOrderDetailsTab>['tabLabeler']>();
  const handleSelectTab: UseTabsProps['onSelect'] = useDynamicCallback((index: number, items) => {
    const newSelectedTab = items[index];
    const tabUrl = generateTabUrl(newSelectedTab);

    if (!matchPath({ path: tabUrl, end: false }, location.pathname)) {
      navigate(tabUrl);
    }
  });

  const onTabsChanged = useDynamicCallback((items: (IAnalyticsReportTab | IAnalyticsOrderDetailsTab)[]) => {
    saveTabs(
      items.filter(tab => {
        if (ANALYTICS_TABS_EXCLUDED_IDS.includes(tab.id ?? '')) {
          return false;
        }
        if (tab.isTemporary) {
          return false;
        }
        return true;
      })
    );
  });

  const tabs = useTabs<IAnalyticsReportTab | IAnalyticsOrderDetailsTab>({
    onItemsChanged: onTabsChanged,
    initialItems: [
      // LATEST_MONTH is a default tab, but we also allow it to be saved, so don't add it if it has already been saved
      ...DEFAULT_REPORT_TABS.filter(t => !initialTabs.some(t2 => t.id === t2.id)),
      ...initialTabs.map<IAnalyticsReportTab | IAnalyticsOrderDetailsTab>(t => ({
        ...t,
        closable: isDefaultTab(t),
        reorderable: isDefaultTab(t),
        focus: undefined,
        filter: {
          ...t.filter,
          AggregationWindow: t.filter?.AggregationWindow ?? AggregationWindowEnum.Month,
        },
      })),
    ],
    initialSelectedIndex:
      initialSelectedIndex > DEFAULT_REPORT_TABS.length + initialTabs.length - 1 ? 0 : initialSelectedIndex,
    showAddTab: true,
    onSelect: handleSelectTab,
    tabLabeler,
  });
  const { items, onAdd: addTab, setItems, selectedIndex, setSelectedIndex } = tabs;

  // keep track to where to return after discarding a temporary tab
  const previousSelectedIndex = useRef<number | null>(null);

  const prepareOrderDetailsTab = useDynamicCallback((orderID: string) => {
    previousSelectedIndex.current = selectedIndex;
    const newTab = {
      id: uuid(),
      type: 'order-details',
      label: `#${abbreviateId(orderID)}`,
      orderID: orderID,
      isTemporary: true,
      closable: true,
      reorderable: true,
    } satisfies IAnalyticsOrderDetailsTab;
    addTab(newTab);
  });

  const saveOrDiscardTemporaryTab = useDynamicCallback((save: boolean) => {
    if (save) {
      setItems(items => {
        const newTabs = items.slice();
        const tempIndex = newTabs.findIndex(t => t.isTemporary);
        const { isTemporary, ...updated } = newTabs[tempIndex];
        newTabs[tempIndex] = updated;
        return newTabs;
      });
    } else {
      setItems(items => {
        const newTabs = items.filter(t => !t.isTemporary);
        if (newTabs.length !== items.length) {
          setSelectedIndex(previousSelectedIndex.current || 0);
          // handleSelectTab(previousSelectedIndex.current || 0, newTabs);
          return newTabs;
        }
        return items;
      });
    }
  });

  // Detect analytics tab index based on URL
  const selectTabFromURL = useDynamicCallback((locationPathName: string) => {
    // This useEffect sits quite high up in the providers chain (inside AppConfig), so
    // we need to ensure that we don't run this logic unless we're on a analytics page
    if (!locationPathName?.startsWith('/analytics')) {
      previousSelectedIndex.current = 0;
      return;
    }

    // Find the first tab whose URL matches the current location
    // Note that we are not looking for an exact match, because we want to match on all sub-tabs of order details.
    // e.g. For an order details tab, the generated URL might look like `/analytics/order/12345-67890`, but the
    // current URL in the browser might be `/analytics/order/12345-67890/trades`.
    const newSelectedIndex = items.findIndex(t => matchPath({ path: generateTabUrl(t), end: false }, locationPathName));
    // If `newSelectedIndex >= 0`, we found an existing tab that matches the current URL.
    if (newSelectedIndex >= 0) {
      if (!items[newSelectedIndex].isTemporary) {
        // The tab we're changing to is _not_ the temporary tab, so we'll close that temporary tab (if it exists).
        // We set the `previousSelectedIndex` here to the tab we want to switch to, because if we do close a temporary
        // tab, we're also going to update the URL to select the "previous selected index".
        previousSelectedIndex.current = newSelectedIndex;
        saveOrDiscardTemporaryTab(false);
      }
      // Set our new selected index.
      // This will trigger the `handleSelectTab`; if that logic was already called from `saveOrDiscardTemporaryTab`,
      // it will be a no-op (won't change the URL), but if we didn't close a temporary tab, `handleSelectTab` will
      // update the URL for us.
      setSelectedIndex(newSelectedIndex);
    } else {
      // No existing tab was found that matches the current URL
      // We need to create a new tab for this URL.
      const orderID = getIdFromAnalyticsRoute(locationPathName);
      // Check that `orderID` isn't `undefined` or `''` (sometimes happens in real weird edge cases? Maybe just broken while I was developing ...)
      if (orderID) {
        prepareOrderDetailsTab(orderID);
      } else {
        // URL doesn't contain a valid orderID, so we'll change the URL to a valid tab instead.
        handleSelectTab(
          (previousSelectedIndex.current ?? 0) > items.length - 1 ? 0 : previousSelectedIndex.current ?? 0,
          items
        );
      }
    }
  });

  useEffect(() => {
    // The implementation is in a separate, dynamic callback
    // so that we don't trigger this logic when the `items` array changes.
    // We only want this to trigger based on URL changes.
    selectTabFromURL(location.pathname);
  }, [selectTabFromURL, location.pathname]);

  const temporaryTab = useMemo(
    () => items.find((t): t is IAnalyticsOrderDetailsTab => t.isTemporary === true),
    [items]
  );
  const removeActiveTab = useDynamicCallback(() => {
    if (temporaryTab) {
      saveOrDiscardTemporaryTab(false);
    } else {
      tabs.onRemove(tabs.selectedIndex);
    }
  });

  const value = useMemo(
    () => ({
      tabs,
      temporaryTab,
      removeActiveTab,
      saveOrDiscardTemporaryTab,
      setTabLabeler,
    }),
    [removeActiveTab, saveOrDiscardTemporaryTab, tabs, temporaryTab]
  );
  return value;
}

/**
 * For analytics, we have 2 "default" tabs that are always present, but one of them (latest month / past 30 days)
 * needs to persist its state.  When we load the state of that tab, we need it to be marked as not closeable and
 * not reorderable, just as if it was a default tab.
 * @param t Tab to test
 * @returns true if `t` is a default tab and should not be closeable/reorderable
 */
function isDefaultTab(t: IAnalyticsReportTab | IAnalyticsOrderDetailsTab): boolean {
  return !DEFAULT_REPORT_TABS.some(t2 => t.id === t2.id);
}

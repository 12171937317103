import {
  type Allocation,
  type DataResponse,
  GET,
  POST,
  request,
  SubAccountReconMatchHistory,
  useEndpointsContext,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

export interface ResolveBreakParams {
  CheckpointID: string;
  MatchID?: string;
  AvgCost?: string;
  AvgCostCurrency?: string;
  /** If allocations are not passed, the Resolution is inferred by the backend to be "Ignore" */
  Allocation?: Allocation[];
  Comments?: string;
  TransactTime?: string;
}

interface ResolveBreakRequest {
  RequestID: string;
  CheckpointID: string;
  MatchID?: string;
  AvgCost?: string;
  AvgCostCurrency?: string;
  Allocation?: { SubAccount: string; Amount: string }[];
  Comments?: string;
  TransactTime?: string;
}

interface AddCommentToBreakRequest {
  RequestID: string;
  MatchID: string;
  Comments: string;
}

export const useSubAccountReconRequests = () => {
  const { orgApiEndpoint } = useEndpointsContext();

  const resolveBreak = useCallback(
    (params: ResolveBreakParams) => {
      const requestID = uuid();
      const requestAllocations = params.Allocation?.map(alloc => ({
        SubAccount: alloc.subAccount,
        Amount: alloc.value,
      }));
      const body: ResolveBreakRequest = {
        ...params,
        RequestID: requestID,
        Allocation: requestAllocations,
      };
      return request(POST, `${orgApiEndpoint}/subaccounts/recon/resolve`, body);
    },
    [orgApiEndpoint]
  );

  const addCommentToBreak = useCallback(
    (matchID: string, comments: string) => {
      const body: AddCommentToBreakRequest = {
        RequestID: uuid(),
        MatchID: matchID,
        Comments: comments,
      };
      return request(POST, `${orgApiEndpoint}/subaccounts/recon/comment`, body);
    },
    [orgApiEndpoint]
  );

  const getMatchHistory = useCallback(
    (matchID: string) => {
      return request<DataResponse<SubAccountReconMatchHistory>>(
        GET,
        `${orgApiEndpoint}/subaccounts/recon/history/${matchID}`
      ).then(res => {
        return { ...res, data: res.data.map(record => new SubAccountReconMatchHistory(record)) };
      });
    },
    [orgApiEndpoint]
  );

  return {
    resolveBreak,
    addCommentToBreak,
    getMatchHistory,
  };
};

import { createContext, type ReactNode, useContext, useMemo } from 'react';
import type { PortfolioViewLayoutState } from '../../PortfolioManagement/stateManagement/portfolioViewLayoutSlice';
import type { OpsBalancesChartDimension } from '../BalancesChart/types';
import type { OperationsOverviewShowBy, OpsOverviewFilter } from '../types';

export const OperationsOverviewConfigContext = createContext<OperationsOverviewConfigContextProps | undefined>(
  undefined
);

export type OpsOverviewMode = 'MarketAccount' | 'SubAccount';

export type OperationsOverviewConfigContextProps = {
  /** The page can be ran in either MarketAccount or SubAccount mode. In SubAccount mode, the market account data is wrapped with sub account trees. */
  mode: OpsOverviewMode;
} & Pick<
  PortfolioViewLayoutState['viewState'],
  | 'opsOverviewBalancesChartDimension'
  | 'opsOverviewBalancesChartShowByAsset'
  | 'opsOverviewFilter'
  | 'opsOverviewShowBy'
> & {
    updateOpsOverviewBalancesChartDimension: (dimension: OpsBalancesChartDimension) => void;
    updateOpsOverviewBalancesChartShowByAsset: (showByAsset: boolean) => void;
    updateOpsOverviewShowBy: (showBy: OperationsOverviewShowBy) => void;
    updateOpsOverviewFilter: (filter: OpsOverviewFilter) => void;
  };

export function useOperationsOverviewConfig() {
  const context = useContext(OperationsOverviewConfigContext);
  if (context === undefined) {
    throw new Error(
      'Missing OperationsOverviewConfigContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

export const OperationsOverviewConfigProvider = function OperationsOverviewConfigProvider({
  children,
  config,
}: {
  children: ReactNode;
  config: OperationsOverviewConfigContextProps;
}) {
  const value = useMemo(() => config, [config]);
  return <OperationsOverviewConfigContext.Provider value={value}>{children}</OperationsOverviewConfigContext.Provider>;
};

import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  DEFAULT_MAX_ROWS,
  EXECUTION_REPORT,
  ExecTypeEnum,
  ExecutionReport,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  WSBlotterTableMaxRecordsReachedWarning,
  columnToColumnState,
  createCSVFileName,
  filterByColumnMainMenuItems,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useDynamicCallback,
  useExecutionReportColumns,
  useMixpanel,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type BlotterColumnState,
  type BlotterTableFilter,
  type BlotterTableSort,
  type Column,
} from '@talos/kyoko';
import type { GetMainMenuItemsParams } from 'ag-grid-community';
import * as routes from 'containers/Routes/routes';
import type { GenerateOrderDetailsRoute } from 'containers/Trading/Markets/OrderDetails/types';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';
import { useMemo } from 'react';
import { useFeatureFlag } from '../../../hooks';
import { useExecutionReportMenu } from './ExecutionReportMenu';
import {
  colIDToFilterBuilderKey,
  onlyServerFilterKeys,
  useExecutionReportFilter,
  type ExecutionReportsTableFilter,
} from './useExecutionReportFilter';

const DEFAULT_SORT: BlotterTableSort<ExecutionReport> = ['-Timestamp', '-Revision'];

export interface ExecutionReportsBlotterTableProps {
  /** Unique id per blotter instance */
  blotterID: string;
  /** ID for common blotter type persistence; usually a subset of the blotterId to represent blotter area 'type' */
  blotterPersistID?: string;
  tabLabel?: string;
  orderID?: string;
  rfqID?: string;
  parentOrderID?: string;
  parentRFQID?: string;

  defaultColumns?: (Column | keyof ExecutionReport)[];
  defaultFilter?: ExecutionReportsTableFilter;
  defaultSort?: BlotterTableSort<ExecutionReport>;
  persistColumns?: boolean;
  persistFilter?: boolean;
  persistSort?: boolean;

  initialIsOpen?: boolean;

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab?: (filter: BlotterTableFilter, columns: BlotterColumnState[]) => void;
  generateOrderDetailsRoute?: GenerateOrderDetailsRoute;
}

export function ExecutionReportsBlotterTable({
  tabLabel,
  orderID,
  rfqID,
  parentOrderID,
  parentRFQID,
  blotterID,
  blotterPersistID = blotterID,
  defaultColumns,
  defaultFilter,
  defaultSort = DEFAULT_SORT,
  persistFilter,
  persistColumns,
  persistSort,
  initialIsOpen,
  onCloneTab,
  generateOrderDetailsRoute = routes.getOrderDetailsRoute,
}: ExecutionReportsBlotterTableProps) {
  const { getConfig } = useOrgConfiguration();
  const mixpanel = useMixpanel();

  const defaultColumnDefinitions = useExecutionReportColumns({ defaultColumns });

  const persistedBlotterTable = usePersistedBlotterTable<ExecutionReport>(blotterPersistID, {
    columns: defaultColumnDefinitions,
    filter: defaultFilter,
    sort: defaultSort,
    persistColumns,
    persistFilter,
    persistSort,
  });

  const filterResults = useExecutionReportFilter({
    persistedBlotterTable,
    includeStatusFilter: true,
    includeIDFilter: orderID == null && rfqID == null,
  });
  const { clientSideFilter: clientLocalFilter, blotterTableFilterProps, filterBuilderProps } = filterResults;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });

  const {
    getContextMenuItems,
    columns: extraColumns,
    dialogs,
  } = useExecutionReportMenu({
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterBuilderProps.properties,
    generateOrderDetailsRoute,
  });
  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    return filterByColumnMainMenuItems({
      params,
      colIDToFilterBuilderKey,
      openClause: filterBuilderAccordion.openClause,
      mixpanel,
    });
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...extraColumns],
    [persistedBlotterTable.columns, extraColumns]
  );

  const { showBlotterPauseButton } = useFeatureFlag();

  const blotterTable = useWsBlotterTable({
    initialRequest: {
      name: EXECUTION_REPORT,
      tag: blotterID,
      ...(orderID != null && { OrderID: orderID }),
      ...(rfqID != null && { RFQID: rfqID }),
      ...(parentOrderID != null && { ParentOrderID: parentOrderID }),
      ...(parentRFQID != null && { ParentRFQID: parentRFQID }),
      SendMarkets: true,
      CoalesceExecs: [ExecTypeEnum.Restated, ExecTypeEnum.Trade],
    },
    filter: onlyServerFilterKeys(filterResults.filter),
    persistence: persistedBlotterTable,
    rowID: ExecutionReport.rowID,
    clientLocalFilter,
    startingRowLimit: getConfig(OrgConfigurationKey.BlotterRowsMax, DEFAULT_MAX_ROWS),
    columns: columnsWithMenu,
    getExtraMainMenuItems,

    pauseParams: {
      showPauseButton: showBlotterPauseButton,
    },
    gridOptions: {
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems,
    },
  });

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(filterResults.filter, blotterTable.getColumns().map(columnToColumnState));
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'ExecutionReports',
        tabLabel,
      }),
    });
    extrasMenuPopover.close();
  });
  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTableFilterProps}
        {...blotterTable.blotterTableFiltersProps}
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>
            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
          </BlotterTableExtrasMenu>
        }
      />
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.Timestamp} />
      <BlotterTable {...blotterTable} />
      {dialogs}
    </>
  );
}

import type { ToastProps } from 'hooks/Toast/useToasts';
import styled from 'styled-components';
import { modeColor } from '../../styles';
import { Box, type BoxProps } from '../Core';
import { NotificationInternalToast } from '../Notification';

export function ToastInternal({
  onRemove,
  onToastClick,
  boxProps,
  ...toast
}: ToastProps & { onRemove: () => void; boxProps: BoxProps }) {
  return (
    <ToastWrapper {...boxProps} data-testid="toast-wrapper">
      <NotificationInternalToast variant={toast.variant} dismissable={toast.dismissable} onRemove={onRemove}>
        {toast.text}
      </NotificationInternalToast>
    </ToastWrapper>
  );
}

const ToastWrapper = styled(Box)`
  box-shadow: 0 5px 40px ${({ theme }) => modeColor(theme, 'hsla(0, 0%, 0%, 0.1)', theme.colors.black.dim)};
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  overflow: hidden;

  ${NotificationInternalToast} {
    &:not(:first-child) {
      ${({ theme }) => `margin-top: ${theme.spacing4}px;`}
    }
    &:not(:last-child) {
      ${({ theme }) => `margin-bottom: ${theme.spacing4}px;`}
    }
  }

  div {
    word-break: break-word;
  }
`;

import { useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  FeeModeEnum,
  Flex,
  FormControlSizes,
  HStack,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  MixpanelSourceContext,
  Toggle,
  useMixpanel,
} from '@talos/kyoko';
import { useDisplaySettings } from 'providers/AppConfigProvider';
import { FEES_TOGGLE, FIRM_LIQUIDITY_TOGGLE } from 'tokens/tooltips';
import { AppBreadcrumb } from './AppBreadcrumb';
import { HeaderButtons } from './HeaderButtons';
import { WatchlistButton } from './WatchlistButton';
import { HeaderWrapper } from './styles';

export const Header = () => {
  return (
    <MixpanelSourceContext.Provider value={MixpanelEventSource.Header}>
      <InternalHeader />
    </MixpanelSourceContext.Provider>
  );
};

const InternalHeader = () => {
  const mixpanel = useMixpanel();
  const { showAllInPrices, setShowAllInPrices, showFirmLiquidity, setShowFirmLiquidity } = useDisplaySettings();

  const handleToggleAllInPrices = useCallback(
    (showAllInPrices: boolean) => {
      const fees = showAllInPrices ? FeeModeEnum.Taker : null;
      mixpanel.track(MixpanelEvent.ToggleFees, { [MixpanelEventProperty.Enabled]: fees });
      setShowAllInPrices(fees);
    },
    [mixpanel, setShowAllInPrices]
  );

  const handleToggleFirmLiquidity = useCallback(
    (showFirmLiquidity: boolean) => {
      mixpanel.track(MixpanelEvent.ToggleFirmLiquidity, { [MixpanelEventProperty.Enabled]: showFirmLiquidity });
      setShowFirmLiquidity(showFirmLiquidity);
    },
    [mixpanel, setShowFirmLiquidity]
  );

  return (
    <HeaderWrapper>
      <Routes>
        <Route path="/trading/*" element={<WatchlistButton />} />
      </Routes>
      <HStack justifyContent="flex-start" flex="1" pl="spacingComfortable">
        <AppBreadcrumb />
      </HStack>
      <Flex>
        <Routes>
          <Route
            path="/trading/*"
            element={
              <Flex gap="spacingComfortable" pr="spacingComfortable">
                <Toggle
                  size={FormControlSizes.Small}
                  checked={!!showAllInPrices}
                  onChange={handleToggleAllInPrices}
                  tooltip={FEES_TOGGLE}
                  label="Fees"
                />
                <Toggle
                  size={FormControlSizes.Small}
                  checked={showFirmLiquidity}
                  onChange={handleToggleFirmLiquidity}
                  tooltip={FIRM_LIQUIDITY_TOGGLE}
                  label="Firm Liquidity"
                />
              </Flex>
            }
          />
        </Routes>
        <HeaderButtons />
      </Flex>
    </HeaderWrapper>
  );
};

import type { DockviewApi, IDockviewDefaultTabProps, IDockviewPanel } from 'dockview';
import { isFunction } from 'lodash-es';
import type { CollapsablePanelConfig, LayoutConstraints, PanelType } from '../../../types/LayoutConfig';

/** Apply min and max width constraints and default size until Dockview adds support in the next version */
export function applyLayoutConstraints(constraints: LayoutConstraints[], api: DockviewApi) {
  constraints.forEach(constraint => {
    const targettedPanels = api.panels.filter(
      p => p.group.id === constraint.groupId || constraint.panelIds.includes(p.id)
    );
    targettedPanels.forEach(panel => {
      if (constraint.maxWidth || constraint.minWidth) {
        panel.group.api.setConstraints({
          maximumWidth: constraint.maxWidth,
          minimumWidth: constraint.minWidth,
        });
      }
      if (constraint.defaultWidth || constraint.defaultHeight) {
        panel.group.api.setSize({
          width: constraint.defaultWidth,
          height: constraint.defaultHeight,
        });
      }
    });
  });
}

/** Set which panels should be hidden by default based on the user's stored configuration */
export function updatePanelVisibility(collapsablePanels: CollapsablePanelConfig[], api: DockviewApi) {
  collapsablePanels.forEach(panelSpec => {
    const targettedPanels = api.panels.filter(p => p.group.id === panelSpec.groupId);
    targettedPanels.forEach(panel => {
      if (panel.group.api.location.type === 'grid') {
        panel.group.api.setVisible(panelSpec.defaultVisible ?? true);
      }
    });
  });
}

/** Adds (1), (2) etc. to avoid duplicated titles */
export function getEnumeratedTitle(preferredTitle: string, currentPanels: IDockviewPanel[]): string {
  const matcher = new RegExp(`^${preferredTitle} \\(\\d+\\)$`);
  const duplicatePanel = currentPanels.filter(
    panel => panel.title && (panel.title === preferredTitle || matcher.test(panel.title))
  );
  if (duplicatePanel.length === 0) {
    return preferredTitle;
  } else {
    // find next panel with available number
    let nextNumber = 2;
    const isDuplicate = (panel: IDockviewPanel) => panel.title === `${preferredTitle} (${nextNumber})`;
    while (duplicatePanel.some(isDuplicate)) {
      nextNumber++;
    }
    return `${preferredTitle} (${nextNumber})`;
  }
}

const DEFAULT_REMOVE_TAB_MESSAGE = ({ api }: IDockviewDefaultTabProps) =>
  `You are about to remove the "${api.title}" tab.`;
const REMOVE_TAB_MESSAGE: Partial<Record<PanelType, (props: IDockviewDefaultTabProps) => string>> = {
  marketCards: ({ api }) => `You are about to remove the "${api.title}" tab and the associated market cards.`,
};

export const closeTabText = (panel: IDockviewDefaultTabProps): string => {
  const type = panel.api.component as keyof typeof REMOVE_TAB_MESSAGE;
  return isFunction(REMOVE_TAB_MESSAGE[type]) ? REMOVE_TAB_MESSAGE[type](panel) : DEFAULT_REMOVE_TAB_MESSAGE(panel);
};

import { MarketTypeEnum, type TabProps } from '@talos/kyoko';
import type { MarketSelectorItem } from '../types';

/**
 * Tab definition compatible with the MarketSelectorBlotter view.
 *
 * In the future, can be reused with other view implementations of the market selector where you want to divide items into different sections.
 */
export interface MarketSelectorTab extends TabProps {
  id: string;
  label: string;
  params: {
    filter?: (item: MarketSelectorItem) => boolean;
  };
}

export const ALL_TAB: MarketSelectorTab = {
  id: 'all',
  label: 'All',
  params: {
    filter: undefined,
  },
};

export const EXCHANGES_TAB: MarketSelectorTab = {
  id: 'exchanges',
  label: 'Exchanges',
  params: {
    filter: item => item.market.Type === MarketTypeEnum.Exchange,
  },
};

export const DEALERS_TAB: MarketSelectorTab = {
  id: 'dealers',
  label: 'Dealers',
  params: {
    filter: item => item.market.Type === MarketTypeEnum.Dealer,
  },
};

export const DARK_TAB: MarketSelectorTab = {
  id: 'dark',
  label: 'Dark',
  params: {
    filter: item => item.market.Type === MarketTypeEnum.Dark,
  },
};

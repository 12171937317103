import { useCallback, useRef } from 'react';

/**
 * Provides a stable function that will always call the latest version of the provided callback.
 * @param callback The callback to be used
 * @returns A callback that will always call the latest version of the input callback
 *
 * @deprecated use **useCallbackRef** instead (it follows idiomatic React rendering rules)
 * - NOTE that there are scenarios (e.g. TabList) which call the dynamic callback during the render which need rework to use it.  If we can confirm
 *   that all usages of this hook are no longer based on render usage, we can remove this and find/replace the remaining usages.
 */
export function useDynamicCallback<T extends (...args: never[]) => unknown>(callback?: T): T {
  const ref = useRef<T>();
  ref.current = callback;
  // @ts-expect-error Weird type-checking issue here, should probably find a way to fix that yet
  return useCallback<T>((...args) => ref.current?.apply(this, args), []);
}

import Big from 'big.js';
import { first, isEmpty, toNumber } from 'lodash-es';
import { v1 as uuid } from 'uuid';

import {
  ACTION,
  Box,
  Button,
  ButtonGroup,
  ButtonVariants,
  Checkbox,
  CurrencyTagEnum,
  CustomerBalance,
  CustomerLedgerUpdateTypeEnum,
  Dialog,
  Divider,
  Flex,
  FormControlSizes,
  FormGroup,
  HStack,
  Input,
  MarketAccountStatusEnum,
  NotificationInternalToast,
  NotificationVariants,
  NumberInput,
  SearchSelect,
  Text,
  VStack,
  useCurrenciesContext,
  useDisclosureWithContext,
  useGlobalToasts,
  useMarketAccountsContext,
  type DialogProps,
  type Transfer,
} from '@talos/kyoko';
import { useFeatureFlag, useRoleAuth } from 'hooks';
import { useCustomers } from 'hooks/useCustomer';
import { useCustomerBalance, useCustomerMarketAccounts, type UpdateCustomerBalanceProps } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BalanceTransactionPreview } from './BalanceTransactionPreview';
import type { CustomerTransactionForm } from './types';
import { useBalanceTransactionValidation } from './useBalanceTransactionValidation';

export interface NewBalanceTransactionDialogProps extends DialogProps {
  customerBalance?: CustomerBalance;
  entity?: CustomerBalance | Transfer;
  transactionType?: CustomerLedgerUpdateTypeEnum;
  transfer?: Transfer;
}

const TRANSACTION_TYPES = [CustomerLedgerUpdateTypeEnum.Deposit, CustomerLedgerUpdateTypeEnum.Withdraw];
const DEFAULT_CUSTOMER_MKT_ACC = 'default'; // TODO converge within Dealer container to one shared constant

export function NewBalanceTransactionDialog({
  entity,
  isOpen,
  transactionType,
  ...props
}: NewBalanceTransactionDialogProps) {
  const { customerBalance, transfer } = useMemo<{ customerBalance?: CustomerBalance; transfer?: Transfer }>(() => {
    if (entity instanceof CustomerBalance) {
      return { customerBalance: entity };
    } else if (entity?.TransferID) {
      return { transfer: entity };
    }

    return {};
  }, [entity]);
  const { add: addToast } = useGlobalToasts();
  const [form, setForm] = useState<CustomerTransactionForm>({});
  const { errors, touched, setTouched, setAllTouched } = useBalanceTransactionValidation(form);
  const customers = useCustomers();
  const customerBalanceService = useCustomerBalance();
  const { isAuthorized } = useRoleAuth();
  const { customerBalanceShowForceWithdraw } = useFeatureFlag();
  const { currenciesList, currenciesBySymbol } = useCurrenciesContext();

  const currencyInfo = form.Currency ? currenciesBySymbol.get(form.Currency) : undefined;
  const showTxHashField = !currencyInfo?.Tags?.includes(CurrencyTagEnum.Fiat);

  const [isForceWithdraw, setIsForceWithdraw] = useState(false);
  const showForceWithdrawSection = useMemo(() => {
    return (
      isAuthorized(ACTION.FORCE_WITHDRAWAL) &&
      customerBalanceShowForceWithdraw &&
      form.Type === CustomerLedgerUpdateTypeEnum.Withdraw
    );
  }, [customerBalanceShowForceWithdraw, form.Type, isAuthorized]);
  const [confirmText, setConfirmText] = useState('');

  const currencySymbols = useMemo(() => {
    return currenciesList ? currenciesList.map(currency => currency.Symbol) : [];
  }, [currenciesList]);
  const { marketAccountsByCounterparty } = useMarketAccountsContext();
  const { customerMarketAccountsByName } = useCustomerMarketAccounts();
  const customerMarketAccounts = useMemo(() => {
    if (!form.Counterparty) {
      return [];
    }

    return (
      marketAccountsByCounterparty
        .get(form.Counterparty)
        ?.filter(ma => ma.Status === MarketAccountStatusEnum.Active)
        .map(ma => ma.Name) || []
    );
  }, [form.Counterparty, marketAccountsByCounterparty]);

  useEffect(() => {
    if (customerMarketAccounts.length === 1) {
      // If there is only 1 active Market Account, select it
      // The Customer Market Account Selector will be disabled
      setForm(prev => ({ ...prev, MarketAccount: first(customerMarketAccounts) }));
    }
  }, [customerMarketAccounts]);

  useEffect(() => {
    if (isOpen) {
      const newForm: CustomerTransactionForm = { Type: transactionType };
      if (customerBalance) {
        newForm.Counterparty = customerBalance.Counterparty;
        newForm.MarketAccount = customerBalance.MarketAccount;
        newForm.Currency = customerBalance.Currency;
        newForm.OutstandingBuy = customerBalance.OutstandingBuy;
        newForm.OutstandingSell = customerBalance.OutstandingSell;
      } else if (transfer) {
        newForm.Amount = transfer.Amount;
        newForm.Currency = transfer.Currency;
        newForm.ExternalID = transfer.TransferID;
        newForm.TransferID = transfer.TransferID;
      }
      setTouched({}); // reset
      setForm(newForm);
      setIsForceWithdraw(false);
    }
  }, [customerBalance, isOpen, setTouched, transactionType, transfer]);

  const updateForm = useCallback(
    (target: keyof typeof form, value = '') => {
      if (target === 'Type') {
        // Reset Force Withdraw State when Type updates
        setIsForceWithdraw(false);
        setConfirmText('');
      }

      // Empty strings should store as undefined, do not send on request as empty string
      setForm(prev => ({ ...prev, [target]: value === '' ? undefined : value }));

      // set touched after the form and subsequent errors update, so push to back of queue
      setTimeout(() => setTouched(prev => ({ ...prev, [target]: true })), 0);
    },
    [setTouched]
  );

  const handleOnGenerateID = useCallback(() => {
    updateForm('ExternalID', uuid());
  }, [updateForm]);

  const handleCounterpartyUpdate = useCallback(
    (newCounterparty?: string) => {
      if (!newCounterparty) {
        setForm(prev => ({ ...prev, MarketAccount: undefined }));
        return;
      }

      // Set form.MarketAccount to default or first for the counterparty
      const marketAccounts = marketAccountsByCounterparty
        .get(newCounterparty)
        ?.filter(ma => ma.Status === MarketAccountStatusEnum.Active);
      const newMktAcc =
        marketAccounts?.find(ma => ma.SourceAccountID === DEFAULT_CUSTOMER_MKT_ACC)?.Name ??
        first(marketAccounts)?.Name;
      setForm(prev => ({ ...prev, MarketAccount: newMktAcc }));

      updateForm('Counterparty', newCounterparty);
    },
    [marketAccountsByCounterparty, updateForm]
  );

  const handleUpdateCustomerBalance = useCallback(
    ({
      ExternalID,
      Counterparty,
      Currency,
      Amount,
      Comments,
      TxHash,
      MarketAccount,
      ExternalComments,
    }: UpdateCustomerBalanceProps) => {
      return customerBalanceService
        .updateCustomerBalance({
          ExternalID,
          Counterparty,
          Currency,
          Amount,
          Comments,
          TxHash,
          MarketAccount,
          ExternalComments,
        })
        .then(() => {
          addToast({
            text: 'Successfully updated balance.',
            variant: NotificationVariants.Positive,
          });
        })
        .catch((e: ErrorEvent) => {
          addToast({
            text: e?.toString() || `Could not update balance.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [addToast, customerBalanceService]
  );

  const { close: closeModal } = props;

  const handleNewBalanceTransaction = useCallback(() => {
    if (form.Type === CustomerLedgerUpdateTypeEnum.Set) {
      // Error validation confirms non-undefined props in form
      return handleUpdateCustomerBalance(form as UpdateCustomerBalanceProps);
    }

    return customerBalanceService
      .createBalanceTransaction(form, isForceWithdraw)
      .then(() => {
        closeModal();
        addToast({
          text: 'Successfully created new balance transaction.',
          variant: NotificationVariants.Positive,
        });
      })
      .catch((e: ErrorEvent) => {
        addToast({
          text: e?.toString() || `Could not add new balance transaction.`,
          variant: NotificationVariants.Negative,
        });
      });
  }, [addToast, customerBalanceService, isForceWithdraw, form, handleUpdateCustomerBalance, closeModal]);

  const handleOnConfirmMouseOver = useCallback(() => setAllTouched(), [setAllTouched]);

  const title = useMemo(() => (transfer != null ? 'New Customer Deposit' : 'New Balance Transaction'), [transfer]);
  const confirmLabel = useMemo(() => (transfer != null ? 'Preview Deposit' : 'Preview Transaction'), [transfer]);
  const confirmDisabled = useMemo(
    () => !isEmpty(errors) || (isForceWithdraw && confirmText !== 'confirm'),
    [errors, isForceWithdraw, confirmText]
  );

  const previewDisclosure = useDisclosureWithContext<{ form: CustomerTransactionForm; isForceWithdraw: boolean }>();

  const onCloseDialog: typeof closeModal = useCallback(() => {
    previewDisclosure.close();
    closeModal();
  }, [closeModal, previewDisclosure]);

  const verifyPastedValueIsNumber = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedValue = e.clipboardData.getData('text');
      const numberValue = toNumber(pastedValue);

      // If pasted value is not a number, prevent the paste event and show a toast
      if (isNaN(numberValue)) {
        e.preventDefault();
        addToast({
          text: `Invalid Amount: ${pastedValue}`,
          variant: NotificationVariants.Negative,
        });
      }
    },
    [addToast]
  );

  if (customers == null) {
    return null;
  }
  return (
    <Dialog
      isOpen={isOpen}
      closeOnConfirm={false}
      confirmLabel={confirmLabel}
      title={title}
      width={480}
      confirmDisabled={confirmDisabled}
      autoFocusFirstElement={false}
      onConfirmMouseOver={handleOnConfirmMouseOver}
      onConfirm={() => previewDisclosure.open({ form, isForceWithdraw })}
      {...props}
      close={onCloseDialog}
      preview={
        <BalanceTransactionPreview
          previewDisclosure={previewDisclosure}
          onConfirmTransaction={handleNewBalanceTransaction}
        />
      }
    >
      {/* If setting balance as Talos User, show this warning */}
      {form.Type === CustomerLedgerUpdateTypeEnum.Set && (
        <Box mb="spacingBig">
          <Text color="colorTextWarning">
            Setting a customer balance can impact balances if the customer is actively trading. Confirm this customer
            has no open orders.
          </Text>
        </Box>
      )}

      <Box textAlign="left">
        <HStack gap="spacingMedium">
          <Box flexBasis="50%" textAlign="left">
            <FormGroup
              mb="spacingMedium"
              label="Customer*"
              error={touched.Counterparty && errors.Counterparty ? errors.Counterparty : undefined}
            >
              <SearchSelect
                options={customers}
                selection={customers.find(c => c?.Name === form.Counterparty)}
                getLabel={customer => customer.DisplayName}
                onChange={c => handleCounterpartyUpdate(c?.Name)}
                data-testid="balance-transaction-dialog-counterparty-select"
              />
            </FormGroup>
          </Box>
          <Box flexBasis="50%" textAlign="left">
            <FormGroup
              mb="spacingMedium"
              label="Customer Account*"
              error={touched.MarketAccount && errors.MarketAccount ? errors.MarketAccount : undefined}
            >
              <SearchSelect
                dropdownWidth={300}
                selection={form.MarketAccount}
                options={customerMarketAccounts}
                getLabel={ma => customerMarketAccountsByName.get(ma)?.SourceAccountID || ma}
                onChange={ma => updateForm('MarketAccount', ma)}
                disabled={customerMarketAccounts.length === 1 || form.Counterparty == null}
                data-testid="balance-transaction-dialog-market-account-select"
              />
            </FormGroup>
          </Box>
        </HStack>
        <FormGroup mb="spacingMedium" error={touched.Type && errors.Type ? errors.Type : undefined}>
          <ButtonGroup>
            {TRANSACTION_TYPES.map(type => {
              return (
                <Button
                  key={type}
                  variant={form.Type === type ? ButtonVariants.Priority : ButtonVariants.Default}
                  onClick={() => updateForm('Type', type)}
                  disabled={transfer != null}
                  data-testid={`balance-transaction-dialog-${type.toLowerCase()}-button`}
                >
                  {type}
                </Button>
              );
            })}
          </ButtonGroup>
        </FormGroup>

        <Divider mt="spacingMedium" mb="spacingMedium" />

        <HStack gap="spacingMedium">
          <Box flexBasis="50%">
            <FormGroup
              mb="spacingMedium"
              label="Currency*"
              error={touched.Currency && errors.Currency ? errors.Currency : undefined}
            >
              <SearchSelect
                data-testid="balance-transaction-dialog-currency-select"
                selection={form.Currency}
                options={currencySymbols}
                onChange={c => {
                  updateForm('Currency', c);
                  updateForm('TxHash', ''); // Target currency might not be FIAT, remove TxHash property
                }}
                getLabel={c => c}
              />
            </FormGroup>
          </Box>
          <Box flexBasis="50%">
            <FormGroup
              mb="spacingMedium"
              label="Amount*"
              error={touched.Amount && errors.Amount ? errors.Amount : undefined}
            >
              <NumberInput
                min="0"
                value={form.Amount ?? ''}
                suffix={form.Currency}
                onChange={value => updateForm('Amount', value)}
                autoFocus={!!form.Type}
                data-testid="balance-transaction-dialog-amount-input"
                onPaste={verifyPastedValueIsNumber}
              />
            </FormGroup>
          </Box>
        </HStack>

        <FormGroup mb="spacingMedium" label="Comments">
          <Input
            value={form.Comments ?? ''}
            autoComplete="off"
            onChange={e => updateForm('Comments', e.target.value)}
            placeholder="Optional"
          />
        </FormGroup>

        <FormGroup mb="spacingMedium" label="External Comments">
          <Input
            value={form.ExternalComments ?? ''}
            autoComplete="off"
            onChange={e => updateForm('ExternalComments', e.target.value)}
            placeholder="Optional"
          />
        </FormGroup>

        <FormGroup
          mb="spacingMedium"
          label="External ID*"
          error={touched.ExternalID && errors.ExternalID ? errors.ExternalID : undefined}
        >
          <Input
            value={form.ExternalID ?? ''}
            autoComplete="off"
            onChange={e => updateForm('ExternalID', e.target.value)}
            data-testid="balance-transaction-dialog-external-id-input"
            placeholder="Required"
            maxLength={36}
            suffix={
              <Button size={FormControlSizes.Small} variant={ButtonVariants.Default} onClick={handleOnGenerateID} ghost>
                Generate
              </Button>
            }
          />
        </FormGroup>

        {showTxHashField && (
          <FormGroup
            mb="spacingMedium"
            label="TxHash"
            error={touched.TxHash && errors.TxHash ? errors.TxHash : undefined}
          >
            <Input
              value={form.TxHash ?? ''}
              autoComplete="off"
              onChange={e => updateForm('TxHash', e.target.value)}
              data-testid="balance-transaction-dialog-tx-hash-input"
              placeholder="TxHash"
            />
          </FormGroup>
        )}

        {transfer != null && (
          <FormGroup mb="spacingMedium" label="Transfer">
            <Input value={transfer.Description} autoComplete="off" readOnly={true} placeholder="Optional" />
          </FormGroup>
        )}
      </Box>
      {/* If setting balance and either OutstandingBuy or OutstandingSell is not 0, show this warning */}
      {form.Type === CustomerLedgerUpdateTypeEnum.Set &&
        (!Big(form.OutstandingBuy || 0).eq(0) || !Big(form.OutstandingSell || 0).eq(0)) && (
          <Text color="colorTextWarning">This customer has an active order impacting this currency.</Text>
        )}

      {showForceWithdrawSection && (
        <Box data-testid="force-withdraw-section">
          <Divider mt="spacingMedium" mb="spacingMedium" />
          <Flex justifyContent="space-between">
            <Text>Force Withdraw</Text>
            <Checkbox
              checked={isForceWithdraw}
              onChange={e => setIsForceWithdraw(e.target.checked)}
              data-testid="balance-trancation-dialog-force-withdraw-checkbox"
            />
          </Flex>
          {isForceWithdraw && (
            <Box mt="spacingDefault">
              <NotificationInternalToast variant={NotificationVariants.Negative} textAlign="left">
                Initiating a forced withdrawal bypasses all validations. Please use this option with caution.
              </NotificationInternalToast>
              <VStack w="100%" mt="spacingDefault">
                <FormGroup label="To confirm, type “confirm” below:" w="100%">
                  <Input
                    value={confirmText}
                    autoComplete="off"
                    onChange={e => setConfirmText(e.target.value)}
                    data-testid="balance-transaction-dialog-confirm-input"
                  />
                </FormGroup>
              </VStack>
            </Box>
          )}
        </Box>
      )}
    </Dialog>
  );
}

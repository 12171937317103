import { Box, Checkbox, safeGridApi, useCallbackRef } from '@talos/kyoko';
import type { GridApi, IHeaderParams, RowDataUpdatedEvent } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { useMarketSelectorContext } from '../MarketSelectorContext';
import type { MarketSelectorItem } from '../types';
import { isMarketParentRowSelectable } from './MarketSelectorGroupColumn';
import type { MarketSelectorRow } from './types';

type AllCheckboxState = 'checked' | 'indeterminate' | 'unchecked';

/**
 * Resolves the checkbox state using the AgGrid GridApi. If there is one selection per market, we regard it as "all selected".
 */
function getCheckboxState(api: GridApi<MarketSelectorRow>): AllCheckboxState {
  const selectableMarkets: string[] = [];
  const selectedMarketsSet = new Set<string>();

  api.forEachNode(node => {
    if (node.group && node.key) {
      // We need to collect information on all the selectable group rows
      const marketRowSelectable = isMarketParentRowSelectable(node);

      if (marketRowSelectable) {
        selectableMarkets.push(node.key); // node.key is the market name since that's what we're grouping on
      }
    } else {
      // we are a leaf row. A leaf row can either represent an account or a market (...confusing)
      if (node.data?.selected) {
        selectedMarketsSet.add(node.data.item.market.Name);
      }
    }
  });

  if (selectedMarketsSet.size === 0) {
    return 'unchecked';
  }
  if (selectedMarketsSet.size === selectableMarkets.length) {
    // all checked
    return 'checked';
  }
  // somewhere inbetween
  return 'indeterminate';
}

export const MarketSelectorBlotterHeaderComponent = (params: IHeaderParams<MarketSelectorRow>) => {
  const { selectAll, unselectAll } = useMarketSelectorContext();
  const [checkboxState, setCheckboxState] = useState<AllCheckboxState>('unchecked');

  useEffect(() => {
    // Run "manually" on the first mount
    setCheckboxState(getCheckboxState(params.api));

    // Create a function and then register for future updates so we keep in sync
    // This means that on _every_ update to the data model, we iterate over all rows in the blotter in order to calculate
    // the top checkbox state. The future implementation of this is to instead connect straight to the Blotter's data observable
    // and resolve this state through delta messages. But not needed right now.
    const handleRowDataUpdated = (event: RowDataUpdatedEvent<MarketSelectorRow>) => {
      setCheckboxState(getCheckboxState(event.api));
    };
    params.api.addEventListener('rowDataUpdated', handleRowDataUpdated);
    return () => {
      const api = safeGridApi(params.api);
      api?.removeEventListener('rowDataUpdated', handleRowDataUpdated);
    };
  }, [params.api]);

  const handleCheckboxChange = useCallbackRef((checked: boolean) => {
    const itemsPresentInBlotter: MarketSelectorItem[] = [];
    params.api.forEachNode(node => {
      if (node.data) {
        itemsPresentInBlotter.push(node.data.item);
      }
    });

    checked ? selectAll(itemsPresentInBlotter) : unselectAll(itemsPresentInBlotter);
  });

  return (
    /* The blotter header component has padding disabled, so we add it back in here to be the exact amount we want for our use case */
    <Box pl="10px">
      <Checkbox
        data-testid="market-selector-blotter-header-checkbox"
        checked={checkboxState === 'checked'}
        indeterminate={checkboxState === 'indeterminate'}
        onChange={e => handleCheckboxChange(e.target.checked)}
      />
    </Box>
  );
};

import { map, shareReplay, throttleTime } from 'rxjs/operators';

import { useMemo, type PropsWithChildren } from 'react';
import { asyncScheduler } from 'rxjs';
import { getMACKey, MarketAccountCurrenciesContext } from '../contexts/MarketAccountCurrenciesContext';
import { useObservable, useStaticSubscription } from '../hooks';
import { wsScanToMap } from '../pipes/wsScanToMap';
import { MARKET_ACCOUNT_CURRENCY } from '../tokens';
import type { MarketAccountCurrency } from '../types/MarketAccountCurrency';

const THROTTLE_MS = 2000;

export const MarketAccountCurrenciesProvider = function MarketAccountCurrenciesProvider({
  children,
}: PropsWithChildren) {
  const { data: subscription } = useStaticSubscription<MarketAccountCurrency>(
    {
      name: MARKET_ACCOUNT_CURRENCY,
      tag: 'MarketAccountCurrenciesProvider',
    },
    { loadAll: true }
  );

  const marketAccountCurrenciesByKeyObs = useObservable(
    () =>
      subscription.pipe(
        wsScanToMap({ getUniqueKey: getMACKey, newMapEachUpdate: false }),
        throttleTime(THROTTLE_MS, asyncScheduler, { leading: true, trailing: true }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  // An internal observable which just stores all entries uniquely in a list and handles update actions
  const marketAccountCurrenciesListObs = useObservable(
    () =>
      marketAccountCurrenciesByKeyObs.pipe(
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketAccountCurrenciesByKeyObs]
  );

  /**
   * This observable does lookup table creation in one place for the sake of efficiency
   */
  const marketAccountCurrencyLookupsObs = useObservable(
    () =>
      marketAccountCurrenciesListObs.pipe(
        map(buildAcceptedCurrenciesMappings),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketAccountCurrenciesListObs]
  );

  const acceptedCurrenciesObs = useObservable(
    () => marketAccountCurrencyLookupsObs.pipe(map(({ acceptedCurrencies }) => acceptedCurrencies)),
    [marketAccountCurrencyLookupsObs]
  );

  const acceptedCurrenciesByMarketObs = useObservable(
    () => marketAccountCurrencyLookupsObs.pipe(map(({ acceptedCurrenciesByMarket }) => acceptedCurrenciesByMarket)),
    [marketAccountCurrencyLookupsObs]
  );

  const acceptedCurrenciesByMarketAccountIDObs = useObservable(
    () =>
      marketAccountCurrencyLookupsObs.pipe(
        map(({ acceptedCurrenciesByMarketAccountID }) => acceptedCurrenciesByMarketAccountID)
      ),
    [marketAccountCurrencyLookupsObs]
  );

  const value = useMemo(
    () => ({
      marketAccountCurrenciesByKeyObs,
      marketAccountCurrenciesListObs,
      acceptedCurrenciesObs,
      acceptedCurrenciesByMarketObs,
      acceptedCurrenciesByMarketAccountIDObs,
    }),
    [
      marketAccountCurrenciesByKeyObs,
      marketAccountCurrenciesListObs,
      acceptedCurrenciesObs,
      acceptedCurrenciesByMarketObs,
      acceptedCurrenciesByMarketAccountIDObs,
    ]
  );

  return <MarketAccountCurrenciesContext.Provider value={value}>{children}</MarketAccountCurrenciesContext.Provider>;
};

/**
 * Given a list of MarketAccountCurrencies, builds mappings to be used for more efficient lookups of MarketAccountCurrencies
 */
export function buildAcceptedCurrenciesMappings(marketAccountCurrencies: MarketAccountCurrency[]) {
  const acceptedCurrenciesByMarket = new Map<string, Set<string>>();
  const acceptedCurrenciesByMarketAccountID = new Map<number, Set<string>>();
  const acceptedCurrencies = new Set<string>();

  for (const item of marketAccountCurrencies) {
    // 1. Add all currencies to the acceptedCurrencies data set
    acceptedCurrencies.add(item.Currency);

    // 2. Check and place into the byMarket lookup map
    if (acceptedCurrenciesByMarket.has(item.Market)) {
      acceptedCurrenciesByMarket.get(item.Market)!.add(item.Currency);
    } else {
      acceptedCurrenciesByMarket.set(item.Market, new Set([item.Currency]));
    }

    // 3. Check and place into the byMarketAccountID lookup map
    if (acceptedCurrenciesByMarketAccountID.has(item.MarketAccountID)) {
      acceptedCurrenciesByMarketAccountID.get(item.MarketAccountID)!.add(item.Currency);
    } else {
      acceptedCurrenciesByMarketAccountID.set(item.MarketAccountID, new Set([item.Currency]));
    }
  }

  return {
    acceptedCurrencies,
    acceptedCurrenciesByMarket,
    acceptedCurrenciesByMarketAccountID,
  };
}

import { isMarketSupported, type ConnectionType } from '@talos/kyoko';
import { useMemo } from 'react';
import { AvailabilityEnum, type AvailabilityCondition } from '../types';

interface UseMarketSupportedConditionParams {
  connectionType: ConnectionType;
}

/**
 * A AvailabilityCondition asserting that the Market.[ConnectionType] is present (defined) and that Market.[ConnectionType].Status
 * is Supported
 */
export const useMarketSupportedCondition = ({
  connectionType,
}: UseMarketSupportedConditionParams): AvailabilityCondition => {
  return useMemo(
    () =>
      ({
        id: 'marketSupported',
        ready: true,
        condition: market => {
          const ok = isMarketSupported(market, connectionType);
          return ok
            ? { availability: AvailabilityEnum.Ok }
            : {
                availability: AvailabilityEnum.Excluded,
                infoNode: `Market is not supported for connection type ${connectionType}`,
              };
        },
      } satisfies AvailabilityCondition),
    [connectionType]
  );
};

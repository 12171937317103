const doc = window.document;
const getTextHeight = (text: string, styles: CSSStyleDeclaration) => {
  const tag = window.document.createElement('div');

  tag.innerText = text;

  for (const key in styles) {
    tag.style[key] = styles[key];
  }

  doc.body.appendChild(tag);
  const { height: texHeight } = tag.getBoundingClientRect();
  doc.body.removeChild(tag);

  return texHeight;
};

/** Evaluate if text is overflowing based on whether the text height changes
 * - this is more accurate than checking scrollWidth and clientWidth
 */
export const isTextEllipsized = (node: HTMLElement) => {
  const title = node.innerText;
  const { width: nodeWidth, height: nodeHeight } = node.getBoundingClientRect();
  const computedNodeStyles = getComputedStyle(node);
  const styles: Partial<CSSStyleDeclaration> = {
    width: `${nodeWidth}px`,
    padding: computedNodeStyles.getPropertyValue('padding'),
    fontSize: computedNodeStyles.getPropertyValue('font-size'),
    fontFamily: computedNodeStyles.getPropertyValue('font-family'),
    fontWeight: computedNodeStyles.getPropertyValue('font-weight'),
    fontStyle: computedNodeStyles.getPropertyValue('font-style'),
    fontVariant: computedNodeStyles.getPropertyValue('font-variant'),
    lineHeight: computedNodeStyles.getPropertyValue('line-height'),
    wordBreak: 'break-all',
  };

  const textHeight = getTextHeight(title, styles as CSSStyleDeclaration);

  // The above calc is a rough estimate, so we allow for a 2px margin of error
  // (actual new line diffs would be >10px)
  return textHeight - nodeHeight > 2;
};

import {
  AGGRID_AUTOCOLUMN_ID,
  autoGroupSortingDefaults,
  BlotterTable,
  Box,
  Button,
  ButtonVariants,
  createCSVFileName,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  Divider,
  EXPANDABLE_HEADER_HEIGHT,
  filterByCellValueMenuItem,
  filterByColumnMainMenuItems,
  FormControlSizes,
  getExpandCollapseMenus,
  getTreeRowBlotterGroupColDef,
  HStack,
  Icon,
  IconButton,
  IconName,
  Input,
  selectAll,
  treeRowAdditionalTotalRowData,
  useAggDeltaUpdatesPipe,
  useBlotterTable,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  VStack,
  type Leaves,
  type MinimalSubscriptionResponse,
  type RowGroupsOpenedState,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, GetMainMenuItemsParams, GridOptions } from 'ag-grid-community';
import { compact } from 'lodash-es';
import { useEffect, useMemo } from 'react';

import type { Observable } from 'rxjs';
import type { PositionsTableFilter } from '../../../Blotters/PositionsV3/types';
import { AsOfDateBadge } from '../../components/AsOfDateBadge';
import { usePortfolioViewStateSelector } from '../../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import { useOperationsOverviewConfig } from '../providers/OperationsOverviewConfigProvider';
import { useOperationsOverviewFilters } from '../providers/OperationsOverviewFiltersProvider';
import { useOperationsOverviewInteractions } from '../providers/OperationsOverviewInteractionsProvider';
import { useOperationsOverviewPositions } from '../providers/OperationsOverviewPositionsProvider';
import {
  useOperationsOverviewBlotterColumns,
  type OperationsOverviewBlotterColumnSpecification,
  type OpsOverviewBlotterColumnID,
} from './useOperationsOverviewBlotterColumns';
import { useOpsOverviewRows, type OpsOverviewBlotterRow } from './useOpsOverviewRows';

const ABOVE_BLOTTER_MENU_ROW_HEIGHT = '40px';

export const aggSpecs = [
  { valuePath: 'position.Equivalent.Amount', currencyPath: 'position.Equivalent.Currency' },
  { valuePath: 'position.balanceEquivalent', currencyPath: 'position.Equivalent.Currency' },
  { valuePath: 'position.Equivalent.UnrealizedPnL', currencyPath: 'position.Equivalent.Currency' },
] satisfies { valuePath: Leaves<OpsOverviewBlotterRow>; currencyPath: Leaves<OpsOverviewBlotterRow> }[];

function getPositionKey(position: OpsOverviewBlotterRow) {
  return position.rowID;
}

interface OperationsOverviewBlotterProps {
  blotterID: string;
  defaultColumns: OperationsOverviewBlotterColumnSpecification[];
  defaultRowGroupsOpened: RowGroupsOpenedState;
}

export const OperationsOverviewBlotter = ({
  blotterID,
  defaultColumns,
  defaultRowGroupsOpened,
}: OperationsOverviewBlotterProps) => {
  const mixpanel = useMixpanel();
  const { selectedPortfolioId } = usePortfolioViewStateSelector();
  const { opsOverviewShowBy } = useOperationsOverviewConfig();
  const { mode } = useOperationsOverviewConfig();
  const columns = useOperationsOverviewBlotterColumns(defaultColumns);

  const { filterableProperties } = useOperationsOverviewFilters();
  const { openClause, setGoToGroupRow } = useOperationsOverviewInteractions();

  const { positionsDataObs, marketAccountPositionsByAccountObs, subAccountPositionsByAccountObs } =
    useOperationsOverviewPositions();
  const dataObs: Observable<MinimalSubscriptionResponse<OpsOverviewBlotterRow>> = useOpsOverviewRows({
    positionsObs: positionsDataObs,
    marketAccountPositionsByAccountObs,
    subAccountPositionsByAccountObs: mode === 'SubAccount' ? subAccountPositionsByAccountObs : undefined,
    groupByMarket: opsOverviewShowBy === 'Market',
    rootSubAccountID: selectedPortfolioId,
  });

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams<OpsOverviewBlotterRow>) => {
    if (openClause) {
      return filterByColumnMainMenuItems({
        params,
        colIDToFilterBuilderKey: colIDToFilterBuilderKey,
        openClause,
        mixpanel,
      });
    }

    return [];
  });

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const { handleClickJson, jsonModal } = useJsonModal();

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams<OpsOverviewBlotterRow>) => {
    const data = params.node?.data;

    const items = [
      ...getExpandCollapseMenus(params),
      'separator',
      selectAll(params, mixpanel),
      'separator',
      data
        ? {
            action: () => handleClickJson(data),
            name: 'Show JSON',
            icon: `<i class="ag-icon ${IconName.Braces}"/>`,
          }
        : undefined,
      'separator',
      ...getDefaultContextMenuItems(params),
    ];

    if (openClause) {
      // In the case that we are right-clicking on the grouping column, we derive the associated column for this grouping level from the row, and use that.
      // TODO: TreeRow should probably enshrine this in some way.
      const maybeGroupColumnID =
        params.column?.getColId() === AGGRID_AUTOCOLUMN_ID ? params.node?.data?.groupColumnID : undefined;

      items.unshift(
        ...filterByCellValueMenuItem({
          params,
          filterableProperties,
          openClause,
          colIDToFilterBuilderKey: colIDToFilterBuilderKey,
          mixpanel,
          colID: maybeGroupColumnID,
        })
      );
    }

    return compact(items);
  });

  const persisted = usePersistedBlotterTable<OpsOverviewBlotterRow>(blotterID, {
    persistFilter: false, // this is handled elsewhere in operations overview
    columns,
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const pinnedRowDataPipe = useAggDeltaUpdatesPipe({
    getUniqueKey: getPositionKey,
    aggSpecs: aggSpecs,
    additionalTotalRowData: treeRowAdditionalTotalRowData,
  });

  const gridOptions: GridOptions<OpsOverviewBlotterRow> = useMemo(() => {
    return {
      ...persisted.gridOptionsOverlay,
      ...persistedRowGroupsOpened.gridOptionsOverlay,
      getContextMenuItems,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      groupDisplayType: 'singleColumn',
      treeData: true,
      getDataPath: row => row.dataPath,
      autoGroupColumnDef: {
        headerName: `${mode === 'SubAccount' ? 'Sub Account(s) > ' : ''}${
          opsOverviewShowBy === 'Market' ? 'Market > ' : ''
        }Account > Underlying > Instrument`,
        width: 400,
        editable: false,
        suppressColumnsToolPanel: false,
        cellRendererParams: {
          suppressCount: true,
        },
        sortable: true,
        pinned: 'left',
        ...autoGroupSortingDefaults,
        ...getTreeRowBlotterGroupColDef(),
      },
    };
  }, [
    mode,
    persistedRowGroupsOpened.gridOptionsOverlay,
    getContextMenuItems,
    persisted.gridOptionsOverlay,
    opsOverviewShowBy,
  ]);

  const blotter = useBlotterTable<OpsOverviewBlotterRow>({
    dataObservable: dataObs,
    pinnedRowDataPipe: pinnedRowDataPipe,
    showPinnedRows: true,
    rowID: 'rowID' satisfies keyof OpsOverviewBlotterRow,
    getExtraMainMenuItems,
    columns: persisted.columns,
    persistence: persisted,
    gridOptions,
  });

  const { expandAllGroups, collapseAllGroups, highlightGroupRow } = blotter;

  useEffect(() => {
    setGoToGroupRow(() => highlightGroupRow);
  }, [setGoToGroupRow, highlightGroupRow]);

  const handleExport = useDynamicCallback(() => {
    blotter.exportDataAsCSV({
      fileName: createCSVFileName({
        name: mode === 'MarketAccount' ? 'Operations - Overview' : 'Margin',
      }),
    });
  });

  return (
    <>
      <Box h={`calc(100% - ${EXPANDABLE_HEADER_HEIGHT}px)`}>
        <HStack
          data-testid="treasury-management-blotter-controls"
          justifyContent="space-between"
          gap="spacingComfortable"
          px="spacingDefault"
          h={ABOVE_BLOTTER_MENU_ROW_HEIGHT}
        >
          <AsOfDateBadge snapshotDate={null} /> {/* live */}
          <HStack justifyContent="flex-end" w="100%" gap="spacingSmall">
            <Input
              prefix={<Icon icon={IconName.Search} />}
              clearable={true}
              size={FormControlSizes.Small}
              width="120px"
              value={blotter.blotterTableFiltersProps.quickFilterText}
              onChange={e => blotter.blotterTableFiltersProps.onQuickFilterTextChanged(e.target.value)}
            />
            <Divider orientation="vertical" mx="spacingSmall" />
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
            <Divider orientation="vertical" mx="spacingSmall" />
            <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>
          </HStack>
        </HStack>
        <Divider />
        <VStack h={`calc(100% - ${ABOVE_BLOTTER_MENU_ROW_HEIGHT})`} w="100%">
          <Box px="spacingDefault" flex="1" w="100%">
            <BlotterTable {...blotter} />
          </Box>
        </VStack>
      </Box>
      {jsonModal}
    </>
  );
};

export function colIDToFilterBuilderKey(id: string): keyof PositionsTableFilter | undefined {
  switch (id as OpsOverviewBlotterColumnID) {
    case 'assetType':
      return 'AssetTypes';
    case 'asset':
      return 'Symbols';
    case 'marketAccount':
      return 'MarketAccounts';
    case 'market':
      return 'Markets';
  }
  return undefined;
}

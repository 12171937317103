import { BlotterTableStorageContext, useSyncedRef } from '@talos/kyoko';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { useMemo, type ContextType, type PropsWithChildren } from 'react';
import { getBlotters2SliceActions } from './Blotters2Slice';
import { selectFullAppConfig } from './selectors';

/** Redux-based column state storage for useBlotterTable */
export const BlotterTableStorageProvider = ({ children }: PropsWithChildren) => {
  const blotterStateMap = useAppStateSelector(state => selectFullAppConfig(state).blotters2);
  const dispatch = useAppStateDispatch();

  const blotterStateRef = useSyncedRef(blotterStateMap);

  const contextValue = useMemo<NonNullable<ContextType<typeof BlotterTableStorageContext>>>(
    () => ({
      getState: blotterId => blotterStateRef.current[blotterId],
      setColumnState: (blotterId, blotterColumnState) => {
        dispatch(getBlotters2SliceActions().setColumnState({ blotterId, blotterColumnState }));
      },
      setFilterState: (blotterId, blotterFilter) => {
        dispatch(getBlotters2SliceActions().setFilterState({ blotterId, blotterFilter }));
      },
      resetColumnState: blotterId => {
        dispatch(getBlotters2SliceActions().resetColumnState({ blotterId }));
      },
      setRowGroupsOpenedState: (blotterId, rowGroupsOpened) => {
        dispatch(getBlotters2SliceActions().setRowGroupsOpenedState({ blotterId, rowGroupsOpened }));
      },
      clearAllBlotterState: () => {
        dispatch(getBlotters2SliceActions().clearAllBlotterState());
      },
    }),
    [blotterStateRef, dispatch]
  );

  return <BlotterTableStorageContext.Provider value={contextValue}>{children}</BlotterTableStorageContext.Provider>;
};

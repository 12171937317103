import type { ValueGetterParams } from 'ag-grid-enterprise';
import { get } from 'lodash-es';
import { defineMessages } from 'react-intl';
import { isTalosUser } from '../../../hooks';
import type { WLUser } from '../../../providers/WLUsersContextProvider';
import type { User } from '../../../types/User';
import type { AgGridUserProps } from '../../AgGrid/types';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

const messages = defineMessages({
  user: {
    defaultMessage: 'User',
    id: 'BlotterTable.columns.user',
  },
});

/**
 * This function will resolve name of the user based on given value of `id` or `email` based on
 * column param valueFormatWithMap being 'id' or 'email'
 * @returns A user column definition that will lookup a user by either id or email
 */
export const user: ColDefFactory<Column<AgGridUserProps>> = column => ({
  width: 90,
  headerValueGetter: ({ context }) => context.current.intl.formatMessage(messages.user),
  valueGetter: (params: ValueGetterParams<User | WLUser, string>): string | undefined => {
    const value = params.colDef.field != null ? get(params.data, params.colDef.field) : null;
    let referencedUser;
    if (value && column.params?.valueFormatWithMap === 'email') {
      referencedUser = params.context.current.userByEmail?.get(value);
    } else if (value && column.params?.valueFormatWithMap === 'id') {
      referencedUser = params.context.current.userByID?.get(value);
    }
    if (!referencedUser) {
      return value ?? undefined;
    }
    const currentUser = params.context.current.currentUser;
    if (currentUser && 'Roles' in currentUser) {
      const isCurrentUserTalosUser = isTalosUser(currentUser);
      const isReferencedUserTalosUser = isTalosUser(referencedUser as User);
      if (!isCurrentUserTalosUser && isReferencedUserTalosUser) {
        return 'Talos User';
      }
    }
    return 'DisplayName' in referencedUser ? referencedUser.DisplayName : referencedUser.Name;
  },
  ...baseColumn(column),
});

import { getCreditUsage } from '../utils';
import { ExposureTypeEnum, type IUniformExposure } from './Exposure';
import type { ExposureLimitSideTypeEnum, ExposureStatusEnum, IMarketExposure } from './types';

export class MarketExposure implements IUniformExposure {
  Counterparty!: string;
  MarketAccount!: string;
  ExposureCurrency!: string;
  ExposureDefinition!: string;
  Status!: ExposureStatusEnum;
  Text?: string;
  Timestamp!: string;
  Exposure!: string;
  ExposureSide!: ExposureLimitSideTypeEnum;
  ExposureLimit!: string;
  Currency?: string;

  get type() {
    return ExposureTypeEnum.MarketExposure;
  }

  get exposure() {
    // We treat a nullish Exposure from the backend as 0
    return this.Exposure ?? '0';
  }

  get limit() {
    return this.ExposureLimit;
  }

  get counterpartyExposure() {
    return this.Exposure;
  }

  get counterpartyExposureLimit() {
    return this.ExposureLimit;
  }

  get talosExposure() {
    return undefined;
  }

  get talosExposureLimit() {
    return undefined;
  }

  get usage() {
    return getCreditUsage(this.exposure, this.limit, this.ExposureCurrency);
  }

  get currency() {
    return this.Currency;
  }

  get rowID() {
    return `$${this.ExposureSide}-${this.MarketAccount}-${this.ExposureDefinition}-${this.Currency}`;
  }

  constructor(data: IMarketExposure) {
    Object.assign(this, data);
  }
}

import {
  SideEnum,
  Text,
  isDelta1Multileg,
  isOption,
  percentToBps,
  toBigWithDefault,
  type OrderAnalytic,
  type Security,
} from '@talos/kyoko';
import Big from 'big.js';

export function computeSlippage(
  security: Security | undefined,
  side: SideEnum,
  versusPxField: keyof OrderAnalytic & ('ArrivalPx' | 'MarketMidPx' | 'ReferencePx' | 'CumulativeVWAP' | 'ExpectedPx'),
  analyticOrLegAnalytic: Pick<OrderAnalytic, 'AvgPx' | typeof versusPxField>,
  parentAnalytic?: Pick<OrderAnalytic, 'AvgPx' | typeof versusPxField>
): string | null {
  const avgPx = analyticOrLegAnalytic.AvgPx;
  const versusPx = analyticOrLegAnalytic[versusPxField];
  if (avgPx == null || avgPx === '0' || versusPx == null || versusPx === '0') {
    return null;
  }

  if (isDelta1Multileg(security)) {
    if (!parentAnalytic) {
      return null;
    }
    const parentAvgPx = parentAnalytic?.AvgPx;
    const parentVersusPxBig = toBigWithDefault(parentAnalytic[versusPxField], 0);
    // In this case avgPx and versusPx should be parent level values
    if (side === SideEnum.Buy) {
      return percentToBps(Big(parentAvgPx).sub(parentVersusPxBig).div(versusPx));
    }
    return percentToBps(Big(parentVersusPxBig).sub(parentAvgPx).div(versusPx));
  }

  // https://talostrading.atlassian.net/browse/UI-3891
  if (isOption(security) && security?.BaseCurrency === security?.QuoteCurrency) {
    if (side === SideEnum.Buy) {
      return percentToBps(Big(avgPx).minus(versusPx));
    }
    return percentToBps(Big(versusPx).minus(avgPx));
  }

  if (side === SideEnum.Buy) {
    return percentToBps(Big(avgPx).sub(versusPx).div(versusPx));
  }
  return percentToBps(Big(versusPx).sub(avgPx).div(versusPx));
}

export function SlippageText({
  security,
  side,
  versusPxField,
  analyticOrLegAnalytic,
  parentAnalytic,
}: {
  security: Security | undefined;
  side: SideEnum;
  versusPxField: keyof OrderAnalytic & ('ArrivalPx' | 'MarketMidPx' | 'ReferencePx' | 'CumulativeVWAP' | 'ExpectedPx');
  analyticOrLegAnalytic: Pick<OrderAnalytic, 'AvgPx' | typeof versusPxField>;
  parentAnalytic?: Pick<OrderAnalytic, 'AvgPx' | typeof versusPxField>;
}) {
  const bps = computeSlippage(security, side, versusPxField, analyticOrLegAnalytic, parentAnalytic);
  const negativeBps = Big(bps ?? 0).lt(0);
  const positiveBps = Big(bps ?? 0).gt(0);

  return <Text color={negativeBps ? 'green.lighten' : positiveBps ? 'red.lighten' : undefined}>{bps ?? 'N/A'}</Text>;
}

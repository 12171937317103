import {
  type Allocation,
  type AllocationValueTypeEnum,
  type CustomerOrder,
  type Order,
  type Security,
  type User,
  abbreviateId,
  ACTION,
  AutoHedgingStatus,
  Button,
  ButtonVariants,
  Divider,
  Flex,
  formattedDate,
  HStack,
  Icon,
  IconButton,
  IconName,
  IndicatorBadge,
  isCalendarSpread,
  isMultileg,
  isOrder,
  isTalosUser,
  Menu,
  MenuItem,
  MixpanelEvent,
  MixpanelEventProperty,
  OrderStatus,
  SmartSummary,
  Text,
  Tooltip,
  useDisclosure,
  useDynamicCallback,
  useMixpanel,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { modifyOrder } from '../../../../components/OMS/NewOrder/OrderSlice';
import { openView } from '../../../../components/OMS/OMSSlice';
import { OMSView } from '../../../../components/OMS/OMSView';
import { useAdminUtils, useFeatureFlag, useOrderAccess, useRoleAuth } from '../../../../hooks';
import { prettifySubAccount, useOrders, useSubAccounts } from '../../../../providers';
import { useAppStateDispatch } from '../../../../providers/AppStateProvider';
import { useTradingSettings } from '../../../../providers/TradingSettingsContext';
import { prettifyAllocations } from '../../../../utils/allocation';
import { CancelSelectedDialog, ForceCancelSelectedDialog } from '../../../Blotters/CancelSelectedDialog';
import { OrderSummaryItem } from './OrderSummaryItem';
import { OrderInfo, TopWrapper } from './styles';
import { Warnings } from './Warnings';

export function OrderDetailsHeader({
  order,
  security,
  width,
  customerOrder,
  subAccountAllocations,
  allocationValueType,
  user,
  onClose,
}: {
  order: Order;
  security: Security | undefined;
  width: number;
  customerOrder: CustomerOrder | undefined;
  subAccountAllocations: Allocation[] | undefined;
  allocationValueType: AllocationValueTypeEnum | undefined;
  user: User;
  onClose: () => void;
}) {
  const mixpanel = useMixpanel();
  const { isAuthorized } = useRoleAuth();
  const { archive, pause, resume, cancel, unarchive } = useOrders();
  const dispatch = useAppStateDispatch();
  const { subAccountsByName } = useSubAccounts();
  const { enableDynamicDeltaHedging, enableArchiveOrders } = useFeatureFlag();

  const subAccountString = subAccountAllocations?.length
    ? prettifyAllocations(subAccountAllocations, allocationValueType, order.Currency, subAccountsByName)
    : prettifySubAccount(order.SubAccount, subAccountsByName) || '-';

  const onModifyOrder = useDynamicCallback((order: Order) => {
    mixpanel.track(MixpanelEvent.ModifyOrder);
    dispatch(modifyOrder(order));
    dispatch(openView(OMSView.NewOrderForm));
  });

  const onPauseOrder: typeof pause = useDynamicCallback(orderID => {
    mixpanel.track(MixpanelEvent.PauseOrder, { [MixpanelEventProperty.ID]: orderID });
    pause(orderID);
  });
  const onResumeOrder: typeof resume = useDynamicCallback(orderID => {
    mixpanel.track(MixpanelEvent.ResumeOrder, { [MixpanelEventProperty.ID]: orderID });
    resume(orderID);
  });
  const onCancelOrder: typeof cancel = useDynamicCallback(orderID => {
    mixpanel.track(MixpanelEvent.CancelOrder, { [MixpanelEventProperty.ID]: orderID });
    cancel(orderID);
  });
  const onArchiveOrder: typeof archive = useDynamicCallback(orderID => {
    mixpanel.track(MixpanelEvent.ArchiveOrder, { [MixpanelEventProperty.ID]: orderID });
    archive(orderID);
  });
  const onUnarchiveOrder: typeof archive = useDynamicCallback(orderID => {
    mixpanel.track(MixpanelEvent.UnarchiveOrder, { [MixpanelEventProperty.ID]: orderID });
    unarchive(orderID);
  });

  const { confirmOrderCancels } = useTradingSettings();

  const { forceCancelOrder } = useAdminUtils();

  const checkOrderAccess = useOrderAccess();
  const canModifyOrder = checkOrderAccess(order);
  const cancelDialog = useDisclosure({ onOpen: () => mixpanel.track(MixpanelEvent.CancelAdmin) });
  const forceCancelDialog = useDisclosure({ onOpen: () => mixpanel.track(MixpanelEvent.CancelForce) });

  const orderTypeLabel = useMemo(() => {
    if (isMultileg(security)) {
      return 'Multi-Leg Order';
    } else if (isCalendarSpread(security)) {
      return 'Calendar Spread';
    } else {
      return 'Order';
    }
  }, [security]);

  return (
    <>
      <TopWrapper>
        <OrderInfo>
          <HStack gap="spacingDefault" justifyContent="flex-start">
            <Icon icon={IconName.Hashtag} size="fontSizeLarge" />
            <Flex flexDirection="column" alignItems="flex-start" gap="spacingSmall">
              <IndicatorBadge children="ID" />
              <Text color="colorTextImportant" size="fontSizeLarge">
                {abbreviateId(order.OrderID)}
              </Text>
            </Flex>
          </HStack>

          <Divider orientation="vertical" my="spacingSmall" />

          <OrderSummaryItem
            title={
              <Flex gap="spacingSmall" alignItems="center">
                <IndicatorBadge children={orderTypeLabel} />
                {formattedDate(order.SubmitTime)}
              </Flex>
            }
          >
            <SmartSummary
              entity={order}
              type={isOrder(order) ? 'order' : 'quote'}
              showSymbol={true}
              showStrategy={true}
              showPriceReference
            />
          </OrderSummaryItem>

          <Divider orientation="vertical" my="spacingSmall" />

          <OrderSummaryItem
            title={
              <Flex gap="spacingSmall" alignItems="center">
                <IndicatorBadge children="Filled" />
                {formattedDate(order.Timestamp)}
              </Flex>
            }
          >
            <SmartSummary
              entity={order}
              type={isOrder(order) ? 'order' : 'quote'}
              showSide={false}
              qtyField="CumQty"
              priceField="AvgPx"
            />
          </OrderSummaryItem>

          {width > 1000 && (
            <>
              <Divider orientation="vertical" my="spacingSmall" />
              <HStack>
                <OrderSummaryItem title={<IndicatorBadge children="User" />}>{order.User}</OrderSummaryItem>
              </HStack>

              {customerOrder != null && (
                <OrderSummaryItem title={<IndicatorBadge children="Customer" />}>
                  {customerOrder.Counterparty ?? '-'}
                </OrderSummaryItem>
              )}

              {order.Group && (
                <OrderSummaryItem title={<IndicatorBadge children="Group" />}>{order.Group ?? '-'}</OrderSummaryItem>
              )}

              <HStack>
                {(subAccountAllocations || order.SubAccount) && (
                  <Tooltip tooltip={subAccountString} placement="bottom">
                    <OrderSummaryItem title={<IndicatorBadge children="Sub Account(s)" />} maxWidth="100px">
                      {subAccountString}
                    </OrderSummaryItem>
                  </Tooltip>
                )}
              </HStack>
            </>
          )}

          <Divider orientation="vertical" my="spacingSmall" />

          <OrderSummaryItem title={<IndicatorBadge children="Status" />}>
            <OrderStatus
              ordStatus={order.OrdStatus}
              decisionStatus={order.DecisionStatus}
              cumQty={order.CumQty}
              orderQty={order.OrderQty}
              pricingMode={order.PricingMode}
            />
          </OrderSummaryItem>

          {enableDynamicDeltaHedging && order.HedgeOrderStatus && (
            <>
              <Divider orientation="vertical" my="spacingSmall" />
              <OrderSummaryItem title={<IndicatorBadge noWrap children="DDH Status" />}>
                <AutoHedgingStatus
                  status={order.HedgeOrderStatus.HedgeControlStatus}
                  text={order.HedgeOrderStatus.Text}
                />
              </OrderSummaryItem>
            </>
          )}
        </OrderInfo>
        <HStack gap="spacingLarge" p="spacingComfortable" justifyContent="flex-end">
          <Warnings order={order} />
          <Divider orientation="vertical" my="spacingSmall" />
          {canModifyOrder ? (
            <>
              <HStack gap="spacingDefault" justifyContent="flex-end">
                {width > 1400 && (
                  <HStack gap="spacingDefault" justifyContent="flex-end">
                    <Button
                      disabled={!order.isCancelable || !isAuthorized(ACTION.CANCEL_ORDER)}
                      onClick={() => {
                        if (confirmOrderCancels) {
                          cancelDialog.open();
                          return;
                        } else {
                          onCancelOrder(order?.OrderID);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!order.isModifiable || !isAuthorized(ACTION.SUBMIT_ORDER)}
                      onClick={() => onModifyOrder(order)}
                    >
                      Modify
                    </Button>
                    {order.isPausable && (
                      <IconButton
                        variant={ButtonVariants.Default}
                        icon={IconName.Pause}
                        disabled={!isAuthorized(ACTION.PAUSE_ORDER)}
                        onClick={() => onPauseOrder(order.OrderID)}
                        data-testid="ord-details-pause"
                      />
                    )}
                    {order.isResumable && (
                      <IconButton
                        variant={ButtonVariants.Primary}
                        icon={IconName.Play}
                        disabled={!isAuthorized(ACTION.RESUME_ORDER)}
                        onClick={() => onResumeOrder(order.OrderID)}
                        data-testid="ord-details-resume"
                      />
                    )}
                    {enableArchiveOrders && order.isArchivable && (
                      <Button
                        disabled={!isAuthorized(ACTION.ARCHIVE_ORDER)}
                        onClick={() => onArchiveOrder(order.OrderID)}
                        data-testid="ord-details-archive"
                      >
                        Archive
                      </Button>
                    )}
                    {enableArchiveOrders && order.isUnArchivable && (
                      <Button
                        disabled={!isAuthorized(ACTION.ARCHIVE_ORDER)}
                        onClick={() => onUnarchiveOrder(order.OrderID)}
                        data-testid="ord-details-unarchive"
                      >
                        Unarchive
                      </Button>
                    )}
                  </HStack>
                )}
                <Menu triggerRound={false}>
                  {isTalosUser(user) && (
                    <>
                      <MenuItem
                        variant={ButtonVariants.Negative}
                        disabled={order.isComplete || !isAuthorized(ACTION.CANCEL_ORDER)}
                        onClick={() => cancelDialog.open()}
                      >
                        Cancel (admin)
                      </MenuItem>
                      <MenuItem
                        variant={ButtonVariants.Negative}
                        disabled={!isAuthorized(ACTION.FORCE_CANCEL_ORDER)}
                        onClick={() => forceCancelDialog.open()}
                      >
                        Force Cancel (admin)
                      </MenuItem>
                    </>
                  )}
                  {width < 1400 && (
                    <>
                      <MenuItem
                        variant={ButtonVariants.Negative}
                        disabled={!order.isCancelable || !isAuthorized(ACTION.CANCEL_ORDER)}
                        onClick={() => {
                          if (confirmOrderCancels) {
                            cancelDialog.open();
                            return;
                          } else {
                            onCancelOrder(order?.OrderID);
                          }
                        }}
                      >
                        Cancel
                      </MenuItem>
                      <MenuItem
                        variant={ButtonVariants.Negative}
                        disabled={!order.isModifiable || !isAuthorized(ACTION.SUBMIT_ORDER)}
                        onClick={() => onModifyOrder(order)}
                      >
                        Modify
                      </MenuItem>
                      {order.isPausable && (
                        <MenuItem
                          onClick={() => onPauseOrder(order.OrderID)}
                          disabled={!isAuthorized(ACTION.SUBMIT_ORDER)}
                          data-testid="ord-details-pause"
                        >
                          <IconButton variant={ButtonVariants.Default} icon={IconName.Pause} />
                        </MenuItem>
                      )}
                      {order.isResumable && (
                        <MenuItem
                          onClick={() => onResumeOrder(order.OrderID)}
                          disabled={!isAuthorized(ACTION.SUBMIT_ORDER)}
                          data-testid="ord-details-resume"
                        >
                          <IconButton variant={ButtonVariants.Primary} icon={IconName.Play} />
                        </MenuItem>
                      )}
                      {enableArchiveOrders && order.isArchivable && (
                        <MenuItem
                          disabled={!isAuthorized(ACTION.ARCHIVE_ORDER)}
                          onClick={() => onArchiveOrder(order.OrderID)}
                          data-testid="ord-details-archive"
                        >
                          Archive
                        </MenuItem>
                      )}
                      {enableArchiveOrders && order.isUnArchivable && (
                        <MenuItem
                          disabled={!isAuthorized(ACTION.ARCHIVE_ORDER)}
                          onClick={() => onUnarchiveOrder(order.OrderID)}
                          data-testid="ord-details-unarchive"
                        >
                          Unarchive
                        </MenuItem>
                      )}
                    </>
                  )}
                </Menu>
                <Divider orientation="vertical" my="spacingSmall" />
                <IconButton icon={IconName.Close} ghost={true} onClick={onClose} />
              </HStack>
            </>
          ) : (
            <IconButton icon={IconName.Close} ghost={true} onClick={onClose} />
          )}
        </HStack>
      </TopWrapper>
      <CancelSelectedDialog
        key="cancel"
        selectedItems={order}
        cancelSelectedDialog={cancelDialog}
        onConfirm={orders => orders.forEach(order => onCancelOrder(order.OrderID))}
      />
      <ForceCancelSelectedDialog
        key="force-cancel"
        selectedItems={order}
        forceCancelSelectedDialog={forceCancelDialog}
        onConfirm={orders => orders.forEach(order => forceCancelOrder(order.OrderID))}
      />
    </>
  );
}

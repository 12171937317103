import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { User } from '@talos/kyoko';

/** Temporary user info slice until we create a user-api slice (separate PR) */
export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: {
    user: undefined as User | undefined,
  },
  reducers: {
    setUserInfo(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
  },
});

import { values } from 'lodash-es';
import { toBig } from '../utils';
import { SettlementInstructionDirectionEnum, type ISettlementBatch, type ISettlementInstruction } from './types';

export enum CustomerSettlementPerspectiveEnum {
  Dealer = 'Dealer',
  Customer = 'Customer',
}
export function perspectiveLabel(perspective: CustomerSettlementPerspectiveEnum): string {
  return perspective === CustomerSettlementPerspectiveEnum.Dealer ? 'Dealer' : 'Client';
}

export function flipCustomerSettlementPerspectiveEnum(perspective: CustomerSettlementPerspectiveEnum) {
  return perspective === CustomerSettlementPerspectiveEnum.Dealer
    ? CustomerSettlementPerspectiveEnum.Customer
    : CustomerSettlementPerspectiveEnum.Dealer;
}

export class SettlementPreview {
  readonly IncludingTrades!: string[];
  readonly Instructions!: SettlementInstruction[];
  readonly perspective: CustomerSettlementPerspectiveEnum;

  constructor(
    data: {
      IncludingTrades: string[];
      Instructions: Omit<ISettlementInstruction, 'EventIndicator'>[];
    },
    perspective?: CustomerSettlementPerspectiveEnum
  ) {
    this.IncludingTrades = data.IncludingTrades;
    this.Instructions = data.Instructions.map(instr => new SettlementInstruction(instr));
    // User doesnt have to pass perspective, can be annoying. Default (implied) is always Dealer (Principal UI user)
    this.perspective = perspective ?? CustomerSettlementPerspectiveEnum.Dealer;
  }

  getOtherPerspective(): SettlementPreview {
    return new SettlementPreview(
      {
        IncludingTrades: this.IncludingTrades,
        Instructions: this.Instructions.map(instr => instr.getOtherPerspective()),
      },
      flipCustomerSettlementPerspectiveEnum(this.perspective)
    );
  }
}

export interface SettlementResponse {
  IncludingTrades: string[];
  Instructions: ISettlementInstruction[];
  Settlement: ISettlementBatch;
}

export class SettlementInstruction {
  readonly Timestamp!: string;
  readonly InstructionID!: string;
  readonly ParentSettlementID!: string;
  readonly OnBehalfOfAccountID!: number;
  readonly Direction!: SettlementInstructionDirectionEnum;
  readonly Amount!: string;
  readonly Asset!: string;

  /** Returns Amount, but negative if Direction is outbound */
  get directionalAmount() {
    if (this.Direction === SettlementInstructionDirectionEnum.Outbound) {
      const amountBig = toBig(this.Amount);
      if (amountBig) {
        return amountBig.times(-1).toFixed();
      }
    }

    return this.Amount;
  }

  get rowID() {
    return `${this.OnBehalfOfAccountID}-${this.Asset}-${this.Direction}`;
  }

  clone(): SettlementInstruction {
    return new SettlementInstruction(this);
  }

  /** Returns a new instance of SettlementInstruction which represents the same instruction action but from the other perspective */
  getOtherPerspective() {
    return new SettlementInstruction({
      Timestamp: this.Timestamp,
      InstructionID: this.InstructionID,
      ParentSettlementID: this.ParentSettlementID,
      OnBehalfOfAccountID: this.OnBehalfOfAccountID,
      Asset: this.Asset,
      Amount: this.Amount,

      Direction:
        this.Direction === SettlementInstructionDirectionEnum.Inbound
          ? SettlementInstructionDirectionEnum.Outbound
          : SettlementInstructionDirectionEnum.Inbound,
    });
  }

  constructor(data: Omit<ISettlementInstruction, 'EventIndicator'>) {
    Object.assign(this, data);
  }
}

export function getSettlementInstructionDirectionLabel(direction: SettlementInstructionDirectionEnum): string {
  return direction === SettlementInstructionDirectionEnum.Inbound ? 'Receive' : 'Send';
}

export function isSettlementInstructionDirection(
  maybeDirection: string
): maybeDirection is SettlementInstructionDirectionEnum {
  return values<string>(SettlementInstructionDirectionEnum).includes(maybeDirection);
}

import {
  EMPTY_OBJECT,
  FilterClauseType,
  IconName,
  PricingModeEnum,
  QUOTE_STATUS_MAPPING,
  QuoteStatusEnum,
  SideEnum,
  TWO_WAY_SIDES_FILTER,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  removeEmptyFilters,
  useDateRangeFilter,
  useSymbolsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type CustomerQuote,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { useCustomerUsers } from 'hooks/useCustomer';
import { compact, isEqual, keys, startCase, uniq, values } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import {
  useCounterpartyFilter,
  useIDFilter,
  useSidesFilter,
  useSubAccountsFilter,
  useUsersFilter,
} from '../../../hooks';
import { useMarketAccountsFilter } from '../../../hooks/filters/useMarketAccountsFilter';

export interface CustomerQuotesTableFilter extends DateRangeFilter {
  Counterparty?: string[];
  MarketAccounts?: string[];
  Sides?: string[];
  Symbols?: string[];
  /** These are the quote status texts we are filtering by on the client side */
  _statuses?: string[];
  /** These are the quote statuses that we are filtering by on the server */
  Statuses?: QuoteStatusEnum[];
  Users?: string[];
  SubAccounts?: string[];
  TradedSide?: string[];
  CustomerUsers?: string[];
  RFQID?: string;
  PricingMode?: string[];
}

export interface UseCustomerQuotesFilterParams<TData extends CustomerQuote> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}
export function useCustomerQuotesFilter<TData extends CustomerQuote>({
  persistedBlotterTable,
}: UseCustomerQuotesFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [initialFilter] = useState(() => cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter));
  const [filter, setFilter] = useState<CustomerQuotesTableFilter>((initialFilter as CustomerQuotesTableFilter) || {});

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<CustomerQuote>>(
    row => {
      const { data } = row;
      if (filterExistsAndExcludes(filter, 'MarketAccounts', data, 'MarketAccount')) {
        return false;
      }
      if (
        filterExistsAndExcludes(
          {
            ...filter,
            Sides: filter?.Sides?.reduce((acc: string[], side: string) => {
              acc.push(side === TWO_WAY_SIDES_FILTER ? '' : side);
              return acc;
            }, []),
          },
          'Sides',
          data,
          'Side'
        )
      ) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'TradedSide', data, 'TradedSide')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'PricingMode', data, 'PricingMode')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'CustomerUsers', data, 'CustomerUser')) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const subAccountsFilter = useSubAccountsFilter(EMPTY_OBJECT);
  const usersFilter = useUsersFilter();
  const counterpartyFilter = useCounterpartyFilter();
  const symbolFilter = useSymbolsFilter();
  const marketAccountsFilter = useMarketAccountsFilter();
  const customerUsers = useCustomerUsers();
  const sidesFilter = useSidesFilter();
  const idFilter = useIDFilter();

  const filterableProperties = useMemo(
    () =>
      compact<FilterableProperty<keyof CustomerQuotesTableFilter>>([
        { ...counterpartyFilter, label: 'Customer' },
        marketAccountsFilter,
        { ...sidesFilter, options: [TWO_WAY_SIDES_FILTER, ...values(SideEnum)] },
        symbolFilter,
        {
          key: '_statuses',
          label: 'Status',
          icon: IconName.CheckCircle,
          options: [...keys(QUOTE_STATUS_MAPPING), ...keys(QuoteStatusEnum)],
          getOptionLabel: (option: string) => startCase(option),
        },
        usersFilter,
        subAccountsFilter,
        {
          key: 'TradedSide',
          label: 'Traded Side',
          icon: IconName.ArrowUpDown,
          options: values(SideEnum),
          getOptionLabel: (option: string) => option,
        },
        {
          key: 'CustomerUsers',
          label: 'Customer User',
          icon: IconName.User,
          options: customerUsers?.map(c => c.Email) ?? [],
          getOptionLabel: (option: string) => option,
        },
        {
          key: 'PricingMode',
          label: 'Pricing Mode',
          icon: IconName.CurrencyDollar,
          options: values(PricingModeEnum),
          getOptionLabel: startCase,
        },
        { ...idFilter, key: 'RFQID', label: 'RFQ ID' },
      ]),
    [
      counterpartyFilter,
      marketAccountsFilter,
      symbolFilter,
      usersFilter,
      subAccountsFilter,
      customerUsers,
      sidesFilter,
      idFilter,
    ]
  );
  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof CustomerQuotesTableFilter)[]).forEach((key: keyof CustomerQuotesTableFilter) => {
        switch (key) {
          case '_start':
          case 'StartDate':
          case 'EndDate':
          case 'TimestampField':
          case 'Statuses':
            return;
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: CustomerQuotesTableFilter = removeEmptyFilters<CustomerQuotesTableFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies CustomerQuotesTableFilter),
          RFQID: filterClausesByPropertyKey.get('RFQID')?.selections?.[0],
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
}

const colIDToFilterBuilderKeyMap = {
  Counterparty: 'Counterparty',
  MarketAccount: 'MarketAccounts',
  Side: 'Sides',
  Symbol: 'Symbols',
  QuoteStatus: '_statuses',
  User: 'Users',
  SubAccount: 'SubAccounts',
  TradedSide: 'TradedSide',
  CustomerUser: 'CustomerUsers',
  RFQID: 'RFQID',
} satisfies Partial<Record<keyof CustomerQuote, keyof CustomerQuotesTableFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof CustomerQuotesTableFilter | undefined {
  return colIDToFilterBuilderKeyMap[id as keyof typeof colIDToFilterBuilderKeyMap];
}

type CustomerQuotesServerFilter = Pick<
  CustomerQuotesTableFilter,
  'StartDate' | 'EndDate' | 'SubAccounts' | 'Users' | 'RFQID'
> & {
  Statuses?: string[];
  Counterparty?: string;
};

export function onlyServerFilterKeys(filter: CustomerQuotesTableFilter | undefined) {
  if (!filter) {
    return filter;
  }

  const serverFilter = {
    StartDate: filter.StartDate,
    EndDate: filter.EndDate,
    SubAccounts: filter.SubAccounts,
    Statuses: uniq(filter._statuses?.flatMap(s => (isQuoteStatusKey(s) ? QUOTE_STATUS_MAPPING[s] : s))),
    Users: filter.Users,
    RFQID: filter.RFQID,
    Counterparty: filter.Counterparty?.at(0),
  } satisfies CustomerQuotesServerFilter;

  return serverFilter;
}

const isQuoteStatusKey = (key: string): key is keyof typeof QUOTE_STATUS_MAPPING => key in QUOTE_STATUS_MAPPING;

import { type ReactNode, useCallback, useMemo } from 'react';
import { FormControlSizes } from '../Form';
import { Icon, IconName } from '../Icons';
import { QuickOptions } from './QuickOptions';

const PLUS_OPTION = {
  label: <Icon icon={IconName.Plus} />,
  value: '+',
};

interface Item {
  label: ReactNode;
  value: string;
}

interface FavoriteQuickOptionsParams {
  /** The count of buttons to show (spaced evenly) */
  count: number;
  /** A sorted list of favorites to show in the buttons. Should not be more than `count` */
  favorites: Item[];
  /** Whether or not to show the quick options bar if there are no favorites passed. Defaults to false */
  showBarIfNoFavorites: boolean;

  /** Called when a button representing a favorite item is clicked */
  onFavoriteClicked: (value: string) => void;
  /** Called when a button with the plus icon is clicked */
  onAddFavoriteClicked: () => void;
}

export const FavoriteQuickOptions = ({
  count,
  favorites,
  onFavoriteClicked,
  onAddFavoriteClicked,
  showBarIfNoFavorites,
}: FavoriteQuickOptionsParams) => {
  const options = useMemo(
    () => [...favorites, ...Array(count - favorites.length).fill(PLUS_OPTION)],
    [favorites, count]
  );

  const handleOptionClick = useCallback(
    (value: string) => {
      if (value === PLUS_OPTION.value) {
        onAddFavoriteClicked();
      } else {
        onFavoriteClicked(value);
      }
    },
    [onAddFavoriteClicked, onFavoriteClicked]
  );

  if (!showBarIfNoFavorites && favorites.length === 0) {
    return null;
  }

  return (
    /* As people need these props parameterized we can do that in the future */
    <QuickOptions
      options={options}
      onOptionClick={handleOptionClick}
      tabbable={false}
      containerProps={{ w: '100%' }}
      size={FormControlSizes.Tiny}
      dim
      equalWidths
    />
  );
};

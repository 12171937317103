import { MixpanelEvent, useMixpanel } from '@talos/kyoko';
import { useCallback, useState } from 'react';
import { useAppLayoutContext } from '../../providers/AppLayoutContextProvider/AppLayoutContextProvider';
import { useAppStateDispatch, useAppStateSelector } from '../../providers/AppStateProvider';
import { redoChange, resetToInitialLayout, setCurrentLayout, undoChange } from './AppLayoutSlice';

export const useEditLayoutControls = () => {
  const undoStack = useAppStateSelector(state => state.appLayout.undoStack);
  const redoStack = useAppStateSelector(state => state.appLayout.redoStack);
  const selectedLayout = useAppStateSelector(state => state.appLayout.currentLayout);
  const layoutSection = useAppStateSelector(state => state.appLayout.layoutSection);
  const layoutOptions = useAppStateSelector(state => state.appLayout.layoutOptions);
  const layoutHasChanged = useAppStateSelector(state => state.appLayout.layoutHasChanged);
  const [layoutNameError, setLayoutNameError] = useState(false);
  const dispatch = useAppStateDispatch();
  const mixpanel = useMixpanel();
  const { resetLayout } = useAppLayoutContext();

  const handleChangeLayout = useCallback(
    (layoutId: string) => {
      if (layoutOptions) {
        dispatch(setCurrentLayout({ layoutConfig: layoutOptions[layoutId] }));
      }
    },
    [dispatch, layoutOptions]
  );

  const handleUndo = useCallback(() => {
    dispatch(undoChange());
    mixpanel.track(MixpanelEvent.UndoLayoutChange);
  }, [dispatch, mixpanel]);

  const handleRedo = useCallback(() => {
    dispatch(redoChange());
    mixpanel.track(MixpanelEvent.RedoLayoutChange);
  }, [dispatch, mixpanel]);

  const handleResetToInitial = useCallback(() => {
    dispatch(resetToInitialLayout());
    mixpanel.track(MixpanelEvent.ReloadLayout);
  }, [mixpanel, dispatch]);

  const handleResetLayout = useCallback(() => {
    resetLayout();
    mixpanel.track(MixpanelEvent.ResetLayout);
  }, [mixpanel, resetLayout]);

  return {
    undoStack,
    redoStack,
    layoutNameError,
    setLayoutNameError,
    handleResetLayout,
    handleUndo,
    handleRedo,
    handleResetToInitial,
    handleChangeLayout,
    selectedLayout,
    layoutSection,
    layoutOptions,
    layoutHasChanged,
  };
};

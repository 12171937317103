import {
  type Allocation,
  Box,
  Button,
  Checkbox,
  ConnectionType,
  Dropdown,
  EMPTY_ARRAY,
  FormControlSizes,
  IconName,
  LoaderSizes,
  LoaderTalos,
  type MultilegMarketDetails,
  ProductTypeEnum,
  type Security,
  type SideEnum,
  stringToOrderFormSide,
  useDropdownPopper,
  type UseDropdownPopperProps,
  useDynamicCallback,
} from '@talos/kyoko';
import { memo, type RefObject, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useAppStateDispatch } from '../../providers/AppStateProvider';
import { useTradingSettings } from '../../providers/TradingSettingsContext';
import { MarketAccountSelectionList } from '../MarketAccountSelectionList';
import { useMarketSelectorBuyingPowerEnrichment } from '../MarketSelector/enrichments/useMarketSelectorBuyingPowerEnrichment';
import { MarketSelectorContextProvider } from '../MarketSelector/MarketSelectorContext';
import { MarketSelectorPanel, type MarketSelectorPanelProps } from '../MarketSelector/MarketSelectorPanel';
import { useOrderMarketSelectorItems } from '../MarketSelector/Orders/useOrderMarketSelectorItems';
import { useMarketSelectorAggregationPresets } from '../MarketSelector/presets/useMarketSelectorAggregationPresets';
import type {
  MarketSelectorCustomPresetItem,
  MarketSelectorItem,
  MarketSelectorValue,
  UseMarketSelectorPanelParams,
} from '../MarketSelector/types';
import { useMarketSelector } from '../MarketSelector/useMarketSelector';
import { useMarketSelectorPanel } from '../MarketSelector/useMarketSelectorPanel';
import { filterMarketSelectorItemsForOldMarketSelector } from '../MarketSelector/utils/items';
import { useWrappedMarketSelectorValue } from '../MarketSelector/utils/useWrappedMarketSelectorValue';
import { handleLegMarketSelectorItemsChange } from '../OMS/NewOrder/OrderSlice';
import { OverflowBox } from './OverflowBox';
import { useValueAndState } from './useValueAndState';

export interface MultilegMarketAccountSelectorProps {
  /** The index of the leg. Undefined means that it is the Unified Liquidity leg. */
  legIndex: number | undefined;
  onChange: (accounts: string[]) => void;
  /** The leg's security */
  security: Security;
  /** An array of the selected accounts */
  value: string[];
  /** If true, will disable editing of leg markets */
  readOnly?: boolean;
  /** Whether or not to show the changes (delta/diff) in market selection to the user */
  showChanges: boolean;
  /** The market details off of the multileg security. Used to compute the delta in market selection */
  marketDetails: MultilegMarketDetails[] | undefined;
  /** Whether or not to show the all markets toggle to the user */
  showAllMarketsToggle?: boolean;
  /** The side of the leg (buy or sell). This will be relative to the order's side */
  side: SideEnum;
  /** The element ref to position the _new_ market selector relative to */
  marketSelectorAnchorRef: RefObject<HTMLElement>;
  /** Sub account allocations used to determine which markets accounts can be traded. */
  subAccountAllocations: Allocation[];
}

export const MultilegMarketAccountSelector = memo(
  ({
    legIndex,
    onChange,
    security,
    readOnly,
    value = EMPTY_ARRAY,
    showChanges,
    marketDetails,
    showAllMarketsToggle: inputShowAllMarketsToggle = false,
    side,
    marketSelectorAnchorRef,
    subAccountAllocations,
  }: MultilegMarketAccountSelectorProps) => {
    const { enableNewMarketSelector } = useTradingSettings();
    const editMarketsButtonRef = useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const showAllMarketsToggle = useMemo(
      () => inputShowAllMarketsToggle && security?.ProductType === ProductTypeEnum.Spot,
      [security, inputShowAllMarketsToggle]
    );

    // The input value is just string[] so here we map / port it to a market selector value which can also wrap the selection with
    // the preset at least within the lifetime of this component for better UX. This'll be removed when the multileg account selection model is improved
    const { marketSelectorValue, onMarketSelectorValueChange } = useWrappedMarketSelectorValue(value);

    const handleNewMarketSelectorChange = useDynamicCallback((newValue: MarketSelectorValue) => {
      onChange(newValue.selections);
      onMarketSelectorValueChange(newValue);
    });

    const handleOldMarketSelectorChange = useDynamicCallback((selectedMarketAccounts: string[] = []) => {
      onChange(selectedMarketAccounts);
    });

    const marketSelectorItems = useOrderMarketSelectorItems({
      security,
      subAccountAllocations,
      isUnifiedLiquidityEnabled: false,
    });

    const dispatch = useAppStateDispatch();

    useEffect(() => {
      if (!marketSelectorItems) {
        return; // wait
      }
      dispatch(handleLegMarketSelectorItemsChange({ legIndex: legIndex ?? 'ul', items: marketSelectorItems }));
    }, [marketSelectorItems, dispatch, legIndex]);

    if (!marketSelectorItems) {
      return <LoaderTalos size={LoaderSizes.XS} />;
    }

    return (
      <>
        <EditMarketsButton
          selectedMarketAccounts={marketSelectorValue.selections}
          showChanges={showChanges}
          readOnly={readOnly}
          anchorRef={editMarketsButtonRef}
          onClick={() => setIsOpen(true)}
          showAllMarketsToggle={showAllMarketsToggle}
          marketDetails={marketDetails}
        />
        {enableNewMarketSelector ? (
          <NewMultilegMarketSelector
            legIndex={legIndex}
            isOpen={isOpen}
            close={() => setIsOpen(false)}
            security={security}
            value={marketSelectorValue}
            marketSelectorItems={marketSelectorItems}
            showAllMarketsToggle={showAllMarketsToggle}
            onChange={handleNewMarketSelectorChange}
            anchorRef={marketSelectorAnchorRef}
            side={side}
            panelPlacement="left-end"
          />
        ) : (
          <OldMultilegMarketSelector
            isOpen={isOpen}
            close={() => setIsOpen(false)}
            security={security}
            value={marketSelectorValue}
            marketSelectorItems={marketSelectorItems}
            showAllMarketsToggle={showAllMarketsToggle}
            onChange={handleOldMarketSelectorChange}
            anchorRef={editMarketsButtonRef}
          />
        )}
      </>
    );
  }
);

export const OldMultilegMarketSelector = ({
  isOpen,
  close,
  security,
  value,
  marketSelectorItems,
  showAllMarketsToggle,
  onChange,
  anchorRef,
  dropdownPlacement = 'top-end',
}: {
  isOpen: boolean;
  close: () => void;
  security: Security | undefined;
  value: MarketSelectorValue;
  marketSelectorItems: MarketSelectorItem[];
  showAllMarketsToggle: boolean;
  onChange: (newSelections: string[]) => void;
  anchorRef: RefObject<HTMLElement>;
  dropdownPlacement?: UseDropdownPopperProps['dropdownPlacement'];
}) => {
  const handleUseAllMarketsChanged = useDynamicCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked ? [] : value.selections);
  });

  const useAllMarkets = useMemo(() => value.selections.length === 0, [value]);

  const availableMarketAccounts = useMemo(
    () => filterMarketSelectorItemsForOldMarketSelector(marketSelectorItems),
    [marketSelectorItems]
  );

  const handleClickOutside = useDynamicCallback(() => {
    close();
  });

  const dropdown = useDropdownPopper({
    isOpen,
    referenceElement: anchorRef.current,
    dropdownWidth: '325px',
    onClickOutside: handleClickOutside,
    dropdownPlacement,
  });

  if (!isOpen) {
    return null;
  }

  return (
    <Dropdown {...dropdown} portalize={true}>
      {showAllMarketsToggle ? (
        <Box
          fontWeight={600}
          background="colors.gray.030"
          borderColor="colors.gray.050"
          borderBottom={useAllMarkets ? undefined : 'solid 1px'}
          p="spacingDefault"
          data-testid="market-selector-all-markets-row"
          data-selected={useAllMarkets}
        >
          <Checkbox checked={useAllMarkets} onChange={handleUseAllMarketsChanged} disabled={false}>
            All Markets
          </Checkbox>
        </Box>
      ) : null}

      <Wrapper>
        <MarketAccountSelectionList
          availableMarketAccounts={availableMarketAccounts}
          selectedMarketAccounts={value.selections}
          security={security}
          onChangeMarketAccounts={onChange}
          connectionType={ConnectionType.Orders}
          showBuyingPower={false}
          showInactiveMarketAccounts={false}
        />
      </Wrapper>
    </Dropdown>
  );
};

const ALL_MARKETS_CUSTOM_PRESET_ITEM: MarketSelectorCustomPresetItem = {
  label: 'All Markets',
  id: '__all-markets__',
  onClick: api => api.unselectAll(),
  isSelected: api => api.selections.length === 0,
};

const CUSTOM_PRESET_ITEMS = [ALL_MARKETS_CUSTOM_PRESET_ITEM];

export const NewMultilegMarketSelector = ({
  legIndex,
  isOpen,
  close,
  value,
  marketSelectorItems,
  showAllMarketsToggle,
  onChange,
  security,

  panelPlacement = 'auto',
  anchorRef,
  side,
}: {
  legIndex: number | undefined;
  isOpen: boolean;
  close: () => void;
  security: Security | undefined;
  value: MarketSelectorValue;
  marketSelectorItems: MarketSelectorItem[];
  showAllMarketsToggle: boolean;
  onChange: (newValue: MarketSelectorValue) => void;
  panelPlacement?: UseMarketSelectorPanelParams['panelPlacement'];
  anchorRef: RefObject<HTMLElement>;
  side: SideEnum;
}) => {
  const buyingPowerEnrichment = useMarketSelectorBuyingPowerEnrichment({
    symbol: security?.Symbol ?? '',
    currency: security?.BaseCurrency ?? '',
    threshold: '10s',
    quantity: undefined,
    tag: 'NewOrderForm',
    side: stringToOrderFormSide(side),
  });

  const enrichmentSpecs = useMemo(() => [buyingPowerEnrichment], [buyingPowerEnrichment]);

  const { aggregationPresets } = useMarketSelectorAggregationPresets();

  const marketSelectorAPI = useMarketSelector({
    value,
    onChange,
    presets: aggregationPresets,
    items: marketSelectorItems,
    enrichmentSpecs,
    customPresetItems: showAllMarketsToggle ? CUSTOM_PRESET_ITEMS : undefined,
  });

  return (
    <MarketSelectorContextProvider marketSelectorAPI={marketSelectorAPI}>
      <MarketSelectorPanelStandalone
        isOpen={isOpen}
        close={close}
        panelOffset={0}
        panelAnchorRef={anchorRef}
        panelPlacement={panelPlacement}
        testID={`market-selector-panel-${legIndex ?? 'ul'}`}
      />
    </MarketSelectorContextProvider>
  );
};

const MarketSelectorPanelStandalone = ({
  isOpen,
  close,
  panelAnchorRef,
  panelOffset,
  panelPlacement,
  testID,
}: UseMarketSelectorPanelParams & Pick<MarketSelectorPanelProps, 'testID'>) => {
  const dropdownProps = useMarketSelectorPanel({
    isOpen,
    close,
    panelAnchorRef,
    panelOffset,
    panelPlacement,
  });
  return <MarketSelectorPanel dropdownProps={dropdownProps} testID={testID} />;
};

const EditMarketsButton = ({
  selectedMarketAccounts,
  showChanges,
  marketDetails,
  readOnly,
  anchorRef,
  onClick,
  showAllMarketsToggle,
}: {
  selectedMarketAccounts: string[];
  showChanges?: boolean;
  marketDetails?: MultilegMarketDetails[];
  readOnly?: boolean;
  anchorRef?: RefObject<HTMLButtonElement>;
  onClick: () => void;
  showAllMarketsToggle?: boolean;
}) => {
  // This hook compares the multileg market details, compares to the current selection, and highlights the diff (additions, removals, etc)
  const items = useValueAndState(marketDetails, selectedMarketAccounts, showChanges);

  return (
    <Button
      endIcon={readOnly ? undefined : IconName.PencilField}
      size={FormControlSizes.Small}
      ref={anchorRef}
      onClick={onClick}
      justifyContent="space-between"
      data-testid="ml-market-edit-button"
      width="100%"
      disabled={readOnly}
    >
      <OverflowBox items={items} showAllMarketsToggle={showAllMarketsToggle} />
    </Button>
  );
};

const Wrapper = styled(Box)`
  scrollbar-gutter: stable both-edges;
`;
Wrapper.defaultProps = {
  maxHeight: '400px',
  overflow: 'auto',
  py: 'spacingDefault',
};

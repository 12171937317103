import {
  BlotterTable,
  Box,
  Button,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  Divider,
  EXPANDABLE_HEADER_HEIGHT,
  FormControlSizes,
  HStack,
  Icon,
  IconButton,
  IconName,
  Input,
  Toggle,
  VStack,
  columnTypes,
  createCSVFileName,
  filterByCellValueMenuItem,
  filterByColumnMainMenuItems,
  isGridApiReady,
  selectAll,
  useAggDeltaUpdatesPipe,
  useBlotterTable,
  useDefaultColumns,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useMixpanel,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  type Column,
  type RowGroupsOpenedState,
  type UsePersistedColumnsChangedParams,
} from '@talos/kyoko';
import type { GetMainMenuItemsParams, GridOptions, SelectionChangedEvent } from 'ag-grid-community';
import { CONTROL_TOOLTIPS } from 'containers/Blotters/tooltips';
import { compact } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useContextBlotterFilter } from '../../../../providers/ContextBlotterFilterProvider/useContextBlotterFilter';
import { getBalanceKey, type Balance } from '../../../../types';
import { balancesBlotterAggSpecs } from '../../../Blotters/BalancesV2/BalancesV2Blotter';
import { BalancesBlotterSummaryLine } from '../../../Blotters/BalancesV2/SummaryLine/BalancesBlotterSummaryLine';
import { balanceColIDToFilterBuilderKey } from '../../../Blotters/BalancesV2/useBalancesV2Filter';
import { AsOfDateBadge } from '../../components/AsOfDateBadge';
import { useShowZeroBalancesToggle } from '../../PortfolioManagement/useShowZeroBalancesContextualToggle';
import { useTreasuryManagementBalances } from '../providers/TreasuryManagementBalancesProvider';
import { useTreasuryManagementFilters } from '../providers/TreasuryManagementFiltersProvider';
import { useTreasuryManagementInteractions } from '../providers/TreasuryManagementInteractionsProvider';
import { useTreasuryManagementContext } from '../providers/TreasuryManagementStateAndTabsProvider';
import { TreasuryManagementActionType } from '../TreasuryManagementReducer';
import { useTreasuryColumnDefinitions, type TreasuryBlotterColumnSpecification } from './useTreasuryColumnDefinitions';

const ABOVE_BLOTTER_MENU_ROW_HEIGHT = '40px';

interface TreasuryManagementBlotterProps {
  blotterID: string;
  defaultColumns: TreasuryBlotterColumnSpecification[];
  defaultRowGroupsOpened: RowGroupsOpenedState;
}

export const TreasuryManagementBlotter = ({
  blotterID,
  defaultColumns: defaultBlotterColumns,
  defaultRowGroupsOpened,
}: TreasuryManagementBlotterProps) => {
  const mixpanel = useMixpanel();

  const columnDefinitions = useTreasuryColumnDefinitions();
  const defaultColumns = useDefaultColumns(defaultBlotterColumns, columnDefinitions);
  const { filterPropsMutator } = useContextBlotterFilter();

  const { filterableProperties } = useTreasuryManagementFilters();
  const { state, dispatch } = useTreasuryManagementContext();
  const [selectedRows, setSelectedRows] = useState<Balance[]>([]);

  const { setGoToGroupRow, openClause } = useTreasuryManagementInteractions();

  const { balancesObs } = useTreasuryManagementBalances();

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    if (openClause) {
      return filterByColumnMainMenuItems({
        params,
        colIDToFilterBuilderKey: balanceColIDToFilterBuilderKey,
        openClause,
        mixpanel,
      });
    }

    return [];
  });
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const resolvedfilterableProperties = filterPropsMutator({
    filterableProperties,
  }).filterableProperties;

  const getContextMenuItems: NonNullable<GridOptions['getContextMenuItems']> = useDynamicCallback(params => {
    const items = [selectAll(params, mixpanel), 'separator', ...getDefaultContextMenuItems(params)];

    if (openClause) {
      items.unshift(
        ...filterByCellValueMenuItem({
          params,
          filterableProperties: resolvedfilterableProperties ?? filterableProperties,
          openClause,
          colIDToFilterBuilderKey: balanceColIDToFilterBuilderKey,
          mixpanel,
        })
      );
    }

    return compact(items);
  });

  const persisted = usePersistedBlotterTable<Balance>(blotterID, {
    persistFilter: false, // this is handled elsewhere in treasury mgmt
    columns: defaultColumns,
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const [groupAccounts, setGroupAccounts] = useState<boolean>(columnsToGroupAccounts(persisted.columnsOnly));
  const handleColumnsChanged = useDynamicCallback((params: UsePersistedColumnsChangedParams) => {
    setGroupAccounts(columnsToGroupAccounts(params.columns));
    persisted.onColumnsChanged(params);
  });

  const pinnedRowDataPipe = useAggDeltaUpdatesPipe({
    getUniqueKey: getBalanceKey,
    aggSpecs: balancesBlotterAggSpecs,
  });

  const handleRowSelectionChanged = useCallback(({ api }: SelectionChangedEvent<Balance>) => {
    if (!isGridApiReady(api)) {
      return;
    }
    const rows = api.getSelectedRows();
    setSelectedRows(compact(rows));
  }, []);

  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        title: 'Grouping',
        editable: false,
        hide: false,
        suppressColumnsToolPanel: false,
        params: {
          suppressCount: true,
        },
      }),
    []
  );

  const persistence = useMemo(() => {
    return {
      ...persisted,
      onColumnsChanged: handleColumnsChanged,
    };
  }, [handleColumnsChanged, persisted]);

  const blotter = useBlotterTable<Balance>({
    dataObservable: balancesObs,
    pinnedRowDataPipe: pinnedRowDataPipe,
    showPinnedRows: true,
    rowID: 'rowID' satisfies keyof Balance,
    getExtraMainMenuItems,
    persistence,
    columns: persisted.columns,
    gridOptions: {
      ...persistedRowGroupsOpened.gridOptionsOverlay,
      onSelectionChanged: handleRowSelectionChanged,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems,
      groupDisplayType: 'singleColumn',
      groupRemoveSingleChildren: true,
      showOpenedGroup: true,
      autoGroupColumnDef,
    },
  });

  const { highlightGroupRow, expandAllGroups, collapseAllGroups, setRowGroupColumns, removeRowGroupColumns } = blotter;

  useEffect(() => {
    setGoToGroupRow(() => highlightGroupRow);
  }, [setGoToGroupRow, highlightGroupRow]);

  const handleGroupAccountsToggle = useDynamicCallback((checked: boolean) => {
    if (checked) {
      setRowGroupColumns(['marketAccountGroup', 'marketAccountName'] satisfies (keyof Balance)[]);
    } else {
      removeRowGroupColumns(['marketAccountGroup'] satisfies (keyof Balance)[]);
    }
  });

  const handleExport = useDynamicCallback(() => {
    blotter.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'TreasuryManagement',
      }),
    });
  });

  const handleShowZeroBalancesChange = useCallback(
    (showZeroBalances: boolean) => {
      dispatch({
        type: TreasuryManagementActionType.ShowZeroBalancesChange,
        payload: {
          showZeroBalances,
        },
      });
    },
    [dispatch]
  );

  const { showZeroBalancesToggle } = useShowZeroBalancesToggle(state.showZeroBalances, handleShowZeroBalancesChange);

  return (
    <Box h={`calc(100% - ${EXPANDABLE_HEADER_HEIGHT}px)`}>
      <HStack
        data-testid="treasury-management-blotter-controls"
        justifyContent="space-between"
        gap="spacingComfortable"
        px="spacingDefault"
        h={ABOVE_BLOTTER_MENU_ROW_HEIGHT}
      >
        <AsOfDateBadge snapshotDate={state.snapshotDate} />
        <HStack justifyContent="flex-end" w="100%" gap="spacingSmall">
          <Input
            prefix={<Icon icon={IconName.Search} />}
            clearable={true}
            size={FormControlSizes.Small}
            width="120px"
            value={blotter.blotterTableFiltersProps.quickFilterText}
            onChange={e => blotter.blotterTableFiltersProps.onQuickFilterTextChanged(e.target.value)}
          />
          <Divider orientation="vertical" mx="spacingSmall" />
          <IconButton
            icon={IconName.ListExpand}
            size={FormControlSizes.Small}
            variant={ButtonVariants.Default}
            onClick={expandAllGroups}
            data-testid="expand-all-button"
          />
          <IconButton
            icon={IconName.ListCollapse}
            size={FormControlSizes.Small}
            variant={ButtonVariants.Default}
            onClick={collapseAllGroups}
          />
          <Divider orientation="vertical" mx="spacingSmall" />
          {showZeroBalancesToggle && (
            <Toggle
              tooltip={CONTROL_TOOLTIPS.showZeroBalances}
              size={FormControlSizes.Small}
              checked={state.showZeroBalances}
              onChange={handleShowZeroBalancesChange}
              label="Show Zero Balances"
            />
          )}
          <Toggle
            disabled={state.showBy === 'asset'}
            size={FormControlSizes.Small}
            checked={groupAccounts}
            onChange={handleGroupAccountsToggle}
            label="Group Accounts"
          />
          <Divider orientation="vertical" mx="spacingSmall" />
          <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
            Export
          </Button>
        </HStack>
      </HStack>
      <Divider />
      <VStack h={`calc(100% - ${ABOVE_BLOTTER_MENU_ROW_HEIGHT})`} w="100%">
        <Box px="spacingDefault" flex="1" w="100%">
          <BlotterTable {...blotter} />
        </Box>
        <BalancesBlotterSummaryLine rows={selectedRows} />
      </VStack>
    </Box>
  );
};

function columnsToGroupAccounts(columns: Column<any>[]): boolean {
  const groupColumn = columns.find(c => c.field === ('marketAccountGroup' satisfies keyof Balance));
  return !!groupColumn?.rowGroup;
}

import {
  BALANCE_DELTA,
  POSITION,
  useMarketAccountsContext,
  useSecuritiesContext,
  useSubscription,
  useSyncedRef,
  wsMerge,
  type MinimalSubscriptionResponse,
  type Position,
  type WebsocketRequest,
} from '@talos/kyoko';
import { isEqual } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { map, type Observable } from 'rxjs';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import type { Balance } from '../../../../types';
import { useEnrichDeltaBalancesPipe } from '../../BalancesV2/useEnrichedDeltaBalancesObs';
import { POSITIONS_BLOTTER_CONVERSION_TOLERANCE } from '../tokens';
import type { PositionsTableFilter } from '../types';
import { UnifiedPosition } from './UnifiedPosition';

interface PositionsRequest extends WebsocketRequest, PositionsTableFilter {
  ShowZeroBalances: boolean;
  EquivalentCurrency: string;
  Tolerance: string;
}

interface BalanceRequest extends WebsocketRequest, PositionsTableFilter {
  ShowZeroBalances: boolean;
  EquivalentCurrency: string;
}

interface UseUnifiedPositionsBalancesObsParams {
  tag: string;
  showZeroBalances: boolean;
  filter: PositionsTableFilter;
}

/**
 * This hook makes two requests -- one for BalanceDelta, one for Position -- and then merges these
 * together into one outgoing stream of type Position. BalanceDelta messages are converted to "synthetic" Positions.
 */
export const useUnifiedPositionsBalancesObs = ({
  tag,
  showZeroBalances,
  filter,
}: UseUnifiedPositionsBalancesObsParams) => {
  const { homeCurrency } = useDisplaySettings();
  const { marketAccountsByID, marketAccountsByName } = useMarketAccountsContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const marketAccountsByIDRef = useSyncedRef(marketAccountsByID);
  const marketAccountsByNameRef = useSyncedRef(marketAccountsByName);
  const securitiesBySymbolRef = useSyncedRef(securitiesBySymbol);

  const [positionsRequest, setPositionsRequest] = useState<PositionsRequest>({
    name: POSITION,
    tag: tag,
    ShowZeroBalances: showZeroBalances,
    EquivalentCurrency: homeCurrency,
    Tolerance: POSITIONS_BLOTTER_CONVERSION_TOLERANCE,
    Throttle: '1s',
    ...onlyServerKeys(filter),
  });

  useEffect(() => {
    setPositionsRequest(currentRequest => {
      const maybeNewRequest: PositionsRequest = {
        name: POSITION,
        tag: tag,
        ShowZeroBalances: showZeroBalances,
        EquivalentCurrency: homeCurrency,
        Tolerance: POSITIONS_BLOTTER_CONVERSION_TOLERANCE,
        Throttle: '1s',
        ...onlyServerKeys(filter),
      };

      return isEqual(currentRequest, maybeNewRequest) ? currentRequest : maybeNewRequest;
    });
  }, [tag, showZeroBalances, homeCurrency, filter]);

  const [balancesRequest, setBalancesRequest] = useState<BalanceRequest>({
    name: BALANCE_DELTA,
    tag: tag,
    ShowZeroBalances: showZeroBalances,
    EquivalentCurrency: homeCurrency,
    Throttle: '1s',
    ...onlyServerKeys(filter),
  });

  useEffect(() => {
    setBalancesRequest(currentRequest => {
      const maybeNewRequest: BalanceRequest = {
        name: BALANCE_DELTA,
        tag: tag,
        ShowZeroBalances: showZeroBalances,
        EquivalentCurrency: homeCurrency,
        Throttle: '1s',
        ...onlyServerKeys(filter),
      };

      return isEqual(currentRequest, maybeNewRequest) ? currentRequest : maybeNewRequest;
    });
  }, [tag, showZeroBalances, homeCurrency, filter]);

  const { data: positionsObs } = useSubscription<Position>(positionsRequest);

  const enrichedPositionsObs = useMemo(
    () =>
      positionsObs.pipe(
        map(message => {
          const newData: UnifiedPosition[] = [];
          for (const position of message.data) {
            const marketAccount = marketAccountsByNameRef.current.get(position.MarketAccount);
            if (!marketAccount) {
              continue;
            }

            const underlying = securitiesBySymbolRef.current.get(position.Asset)?.BaseCurrency ?? 'Unknown';
            newData.push(
              new UnifiedPosition({
                position,
                metadata: {
                  marketAccountName: marketAccount.Name,
                  marketName: marketAccount.Market,
                  marketAccountGroup: marketAccount.Group,
                  underlying,
                },
              })
            );
          }

          return {
            ...message,
            data: newData,
          };
        })
      ),
    [positionsObs, marketAccountsByNameRef, securitiesBySymbolRef]
  );

  const { data: balancesObs } = useSubscription<Balance>(balancesRequest);

  const enrichBalancesPipe = useEnrichDeltaBalancesPipe();
  const enrichedBalancesObs = useMemo(() => balancesObs.pipe(enrichBalancesPipe), [balancesObs, enrichBalancesPipe]);

  // Need to turn received BalanceDelta into Position
  const balancesMappedToPositionsObs: Observable<MinimalSubscriptionResponse<UnifiedPosition, string>> = useMemo(
    () =>
      enrichedBalancesObs.pipe(
        map(message => {
          const newData: UnifiedPosition[] = [];
          for (const balance of message.data) {
            const marketAccount = marketAccountsByIDRef.current.get(balance.MarketAccountID);
            if (!marketAccount) {
              continue;
            }

            newData.push(
              new UnifiedPosition({
                balance,
                metadata: {
                  marketAccountName: marketAccount.Name,
                  marketName: marketAccount.Market,
                  marketAccountGroup: marketAccount.Group,
                  underlying: balance.Currency,
                },
              })
            );
          }

          return {
            ...message,
            data: newData,
          };
        })
      ),
    [enrichedBalancesObs, marketAccountsByIDRef]
  );

  const unifiedObs = useMemo(
    () => wsMerge({ sources: [enrichedPositionsObs, balancesMappedToPositionsObs], getUniqueKey: item => item.rowID }),
    [enrichedPositionsObs, balancesMappedToPositionsObs]
  );

  return unifiedObs;
};

function onlyServerKeys(filter: PositionsTableFilter | undefined): PositionsTableFilter {
  if (filter == null) {
    return {};
  }

  // Omit expiries and mkt acc groups, theyre frontend
  return { ...filter, Expiries: undefined, MarketAccountGroups: undefined };
}

import { API_PERMISSION_WILDCARD, type MarketAccountApiPermissionFilter } from '../types/ApiPermissionFilter';
import type { RollupMembership } from '../types/RollupMembership';

/**
 * Given a sub account id, returns the first path from given sub account to first-found parent.
 *
 * Can be conceptualised as just performing the initial search of a depth-first search algorithm, and once the end is reached on
 * that first search, the traversed path is returned.
 *
 * The returned path does not include the sub account id passed into this function.
 */
export function getFirstParentsPathForSubAccount(
  subAccountID: number,
  rollupMembershipsByChildParent: Map<number, Map<number, RollupMembership>>
): number[] {
  const path: number[] = [];

  function getParents(subAccountID: number) {
    const parents = rollupMembershipsByChildParent.get(subAccountID);
    if (!parents) {
      return;
    }

    const firstParent = [...parents.values()].at(0);
    if (!firstParent) {
      return;
    }

    path.push(firstParent.ParentID);
    getParents(firstParent.ParentID);
  }

  getParents(subAccountID);
  return path;
}

export function getAllParentsForSubAccount(
  subAccountID: number,
  rollupMembershipsByChildParent: Map<number, Map<number, RollupMembership>>
): Set<number> {
  const parents = new Set<number>();

  function getParents(subAccountID: number) {
    const myParents = rollupMembershipsByChildParent.get(subAccountID);
    if (!myParents) {
      return;
    }

    for (const [parentID] of myParents) {
      // If this parentID is already in the set of parents, we have already handled it completely
      if (parents.has(parentID)) {
        continue;
      }

      parents.add(parentID);
      // Continue the operation recursively on this new found parent
      getParents(parentID);
    }
  }

  getParents(subAccountID);
  return parents;
}

/** Due to the multi-parent nature of the subaccount tree, return the (potenitally multiple) parent trees */
export function getParentTreesForSubAccount(
  subAccountID: number,
  rollupMembershipsByChildParent: Map<number, Map<number, RollupMembership>>
): number[][] {
  const parents: number[][] = [];

  function getParents(subAccountID: number, path: number[]) {
    const myParents = rollupMembershipsByChildParent.get(subAccountID);
    if (!myParents) {
      parents.push(path);
      return;
    }

    for (const [parentID] of myParents) {
      // If this parentID is already in the path, we have already handled it completely
      if (path.includes(parentID)) {
        continue;
      }

      // Continue the operation recursively on this new found parent
      getParents(parentID, [...path, parentID]);
    }
  }

  getParents(subAccountID, []);
  return parents;
}

export function getAllChildrenOfSubAccount(
  subAccountID: number,
  rollupMembershipsByParentChild: Map<number, Map<number, RollupMembership>>
): Set<number> {
  const children = new Set<number>();

  function getChildren(subAccountID: number) {
    const myChildren = rollupMembershipsByParentChild.get(subAccountID);
    if (!myChildren) {
      return;
    }

    for (const [parentID] of myChildren) {
      // If this parentID is already in the set of children, we have already handled it completely
      if (children.has(parentID)) {
        continue;
      }

      children.add(parentID);
      // Continue the operation recursively on this new found parent
      getChildren(parentID);
    }
  }

  getChildren(subAccountID);
  return children;
}

/**
 * The sub account ID to start looking "from". Will return all unique child trees. For example, if there are multiple paths
 * from account 1 to account 5, all those paths will be returned. See the tests for some examples.
 */
export function getAllChildTreesFromSubAccount(
  root: number,
  rollupMembershipsByParentChild: Map<number, Map<number, RollupMembership>>
) {
  const pathsFound: number[][] = [];
  function depthFirstTraversal(node: number, path: number[]) {
    path = [...path, node];

    const children = rollupMembershipsByParentChild.get(node);
    if (!children) {
      pathsFound.push(path);
      return;
    }

    for (const child of children.keys()) {
      depthFirstTraversal(child, path);
    }
  }

  depthFirstTraversal(root, []);
  return pathsFound;
}

/** Get a set of all the sub accounts which map to the provided market account. This can be directly by name, or indirectly through a Wildcard "*". */
export function getSubAccountsMappingToMarketAccount(
  marketAccountName: string,
  permissions: MarketAccountApiPermissionFilter[]
) {
  const subAccountNames = new Set<string>();

  for (const permission of permissions) {
    if (
      permission.Filter.MarketAccount === API_PERMISSION_WILDCARD ||
      permission.Filter.MarketAccount === marketAccountName
    ) {
      subAccountNames.add(permission.Subject.SubAccount);
    }
  }

  return subAccountNames;
}

/** Test utility function to build rollup membership mapping */
export function buildRollupMembershipsByChildParentMap(
  memberships: RollupMembership[]
): Map<number, Map<number, RollupMembership>> {
  const outerChildMap = new Map<number, Map<number, RollupMembership>>();

  memberships.forEach(membership => {
    const innerParentMap = outerChildMap.get(membership.ChildID) ?? new Map<number, RollupMembership>();
    innerParentMap.set(membership.ParentID, membership);
    outerChildMap.set(membership.ChildID, innerParentMap);
  });

  return outerChildMap;
}

/** Test utility function to build rollup membership mapping */
export function buildRollupMembershipsByParentChildMap(
  memberships: RollupMembership[]
): Map<number, Map<number, RollupMembership>> {
  const outerParentMap = new Map<number, Map<number, RollupMembership>>();

  memberships.forEach(membership => {
    const innerChildMap = outerParentMap.get(membership.ParentID) ?? new Map<number, RollupMembership>();
    innerChildMap.set(membership.ChildID, membership);
    outerParentMap.set(membership.ParentID, innerChildMap);
  });

  return outerParentMap;
}

import { memo, useEffect, useState } from 'react';

import {
  Box,
  Flex,
  formattedDateForSubscription,
  Icon,
  IconName,
  MarketAccountStatusEnum,
  MarketAccountTypeEnum,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  useCurrencyConversionRateValue,
  useMarketAccountsContext,
} from '@talos/kyoko';

import { addDays } from 'date-fns';
import { compact } from 'lodash-es';
import { Link } from '../styles';
import { useOrderImportContext } from './OrderImportProvider';
import { ALL_KEYS_INFO } from './tokens';

export const OrderImportHelp = memo(() => {
  const { Rate: btcusdRate } = useCurrencyConversionRateValue('BTC', 'USD') ?? {};
  const { marketAccountsByName } = useMarketAccountsContext();
  const { requiredKeys, subAccount } = useOrderImportContext();
  const [limitExampleCSV, setLimitExampleCSV] = useState<string>('');

  useEffect(() => {
    // Get set of Active Trading MarketAccounts but with only one from each Market
    const markets: string[] = [];
    const marketAccounts: string[] = [];

    for (const [name, ma] of marketAccountsByName) {
      if (
        ma.Type === MarketAccountTypeEnum.Trading &&
        ma.Status === MarketAccountStatusEnum.Active &&
        SAMPLE_EXCHANGES.includes(ma.Market) &&
        !markets.includes(ma.Market)
      ) {
        markets.push(ma.Market);
        marketAccounts.push(name);
      }
    }

    const startTime = new Date(Date.now() + 1 * 3600 * 1000 /*1 hr in ms*/).toISOString();

    const rawOrder = ['BTC-USD', marketAccounts.join(';'), 'Buy', '0.2', 'BTC', 'Limit', btcusdRate, startTime];
    if (subAccount !== '') {
      rawOrder.push(subAccount);
    }

    // Only include the subaccount header if they are used in the system
    setLimitExampleCSV(
      encodeURI(`${CSV_START_TEXT}${requiredKeys.join(',')},Price,StartTime\n${rawOrder.join(',')}\n`)
    );
  }, [btcusdRate, marketAccountsByName, subAccount, requiredKeys]);

  const blankExampleCSV = encodeURI(`${CSV_START_TEXT}${requiredKeys.join(',')}\n`);

  const marketExampleCSV = encodeURI(
    `${CSV_START_TEXT}${getSampleData(requiredKeys, [
      {
        SubAccount: subAccount,
      },
    ])}`
  );

  const twapExampleCSV = encodeURI(
    `${CSV_START_TEXT}${getSampleData(
      compact([
        'Symbol',
        'Market',
        'Side',
        'OrderQty',
        'Currency',
        'Strategy',
        'Price',
        'EndTime',
        requiredKeys.includes('SubAccount') ? 'SubAccount' : undefined,
      ]),
      [
        {
          Strategy: 'TWAP',
          Price: '62000',
          EndTime: formattedDateForSubscription(addDays(new Date(), 1)),
          SubAccount: subAccount,
        },
      ]
    )}`
  );

  const unifiedExampleCSV = encodeURI(
    `${CSV_START_TEXT}${getSampleData(
      compact([
        'Symbol',
        'Market',
        'Side',
        'OrderQty',
        'Currency',
        'Strategy',
        'Price',
        'UnifiedLiquidity',
        requiredKeys.includes('SubAccount') ? 'SubAccount' : undefined,
      ]),
      [
        {
          Symbol: 'binance_futures:BTCUSD-PERP',
          Market: 'binance_futures/binance-futures;bitmex/bitmex-0;bybit/bybit;deribit/deribit',
          Strategy: 'Limit',
          Price: '62000',
          UnifiedLiquidity: 'Enabled',
          SubAccount: subAccount,
        },
      ]
    )}`
  );

  const stagedExampleCSV = encodeURI(
    `${CSV_START_TEXT}${getSampleData(
      compact([
        'Symbol',
        'Market',
        'Side',
        'OrderQty',
        'Currency',
        'Strategy',
        'Price',
        'InitialDecisionStatus',
        requiredKeys.includes('SubAccount') ? 'SubAccount' : undefined,
      ]),
      [
        {
          InitialDecisionStatus: 'Staged',
          Strategy: 'Limit',
          Price: '62000',
          SubAccount: subAccount,
        },
      ]
    )}`
  );

  const bulkExampleCSV = encodeURI(
    `${CSV_START_TEXT}${getSampleData(
      compact([
        'Symbol',
        'Market',
        'Side',
        'OrderQty',
        'Currency',
        'Strategy',
        'Price',
        'EndTime',
        'ShowQty',
        'UnifiedLiquidity',
        requiredKeys.includes('SubAccount') ? 'SubAccount' : undefined,
        'InitialDecisionStatus',
      ]),
      [
        {
          OrderQty: '5',
          Currency: 'BTC',
          Strategy: 'Iceberg',
          Price: '60000',
          ShowQty: '0.025',
          UnifiedLiquidity: 'Disabled',
          SubAccount: subAccount,
        },
        {
          Symbol: 'binance_futures:BTCUSDT-PERP',
          Market: 'binance_futures/binance-futures',
          OrderQty: '1.5',
          Currency: 'BTC',
          Strategy: 'Limit',
          Price: '60000',
          UnifiedLiquidity: 'Disabled',
          SubAccount: subAccount,
        },
        {
          Market: 'coinbase/coinbase-0;b2c2/b2c2;galaxy/galaxy-0;kraken/kraken-0',
          OrderQty: '1.5',
          Strategy: 'Market',
          UnifiedLiquidity: 'Disabled',
          SubAccount: subAccount,
        },
        {
          Symbol: 'BTC-EUR:USDT',
          Market: 'multileg',
          OrderQty: '1.5',
          Currency: 'BTC',
          Strategy: 'MultilegAggressiveLimit',
          Price: '-700',
          UnifiedLiquidity: 'Disabled',
          SubAccount: subAccount,
        },
        {
          Symbol: 'binance_futures:BTCUSD-PERP',
          Market: 'binance_futures/binance-futures;bitmex/bitmex-0;bybit/bybit;deribit/deribit',
          OrderQty: '1.5',
          Currency: 'BTC',
          Strategy: 'Limit',
          Price: '60000',
          UnifiedLiquidity: 'Disabled',
          SubAccount: subAccount,
        },
        {
          OrderQty: '1.5',
          Currency: 'BTC',
          Strategy: 'Limit',
          Price: '60000',
          InitialDecisionStatus: 'Staged',
          UnifiedLiquidity: 'Disabled',
          SubAccount: subAccount,
        },
        {
          Side: 'Sell',
          OrderQty: '1000000',
          Currency: 'USD',
          Strategy: 'TWAP',
          Price: '62000',
          UnifiedLiquidity: 'Disabled',
          SubAccount: subAccount,
          EndTime: '2024-08-15T16:17:32',
        },
      ]
    )}`
  );

  return (
    <Box ml="spacingLarge" mr="spacingLarge" justifyContent="center">
      <Flex flexDirection="column" justifyContent="center" flex={1}>
        <h3>How to import orders from a csv file</h3>
        <p>The first row of the file is a header with required keys</p>
        <Table w="100%">
          <Tbody>
            <Tr>
              <Th>Key</Th>
              <Th>Value</Th>
            </Tr>
            {requiredKeys.map(key => (
              <Tr key={key}>
                <Td>{key}</Td>
                <Td>{ALL_KEYS_INFO[key]}</Td>
              </Tr>
            ))}
            <Tr key="InitialDecisionStatus">
              <Td>InitialDecisionStatus</Td>
              <Td>{ALL_KEYS_INFO['InitialDecisionStatus']}</Td>
            </Tr>
          </Tbody>
        </Table>
        <p>
          Depending on the strategy, additional columns are used to specify required parameters, refer to{' '}
          <Link href="https://docs.talostrading.com/#order-strategy" target="_blank">
            https://docs.talostrading.com/#order-strategy
          </Link>{' '}
          for these parameters.
        </p>
        <p>
          <Icon icon={IconName.BookOpen} /> See the{' '}
          <Link href="https://kb.talostrading.com/principal-trading/order-import" target="_blank">
            Knowledge Base
          </Link>{' '}
          for further information on Order Import.
        </p>
        <h4>Sample Files:</h4>
        <Link href={blankExampleCSV} download="talos_import_blank_example.csv" data-testid="order-import-blank-example">
          blank-example
        </Link>
        <Link
          href={marketExampleCSV}
          download="talos_import_market_example.csv"
          data-testid="order-import-market-example"
        >
          market-order-example
        </Link>
        <Link href={limitExampleCSV} download="talos_import_limit_example.csv" data-testid="order-import-limit-example">
          limit-order-example
        </Link>
        <Link href={twapExampleCSV} download="talos_import_twap_example.csv" data-testid="order-import-twap-example">
          twap-order-example
        </Link>
        <Link
          href={unifiedExampleCSV}
          download="talos_import_unified_liqudity_perp_example.csv"
          data-testid="order-import-unified-liqudity-perp-example"
        >
          unified_liquidity_enabled_perp_example
        </Link>
        <Link
          href={stagedExampleCSV}
          download="talos_import_staged_order_example.csv"
          data-testid="order-import-staged-order-example"
        >
          staged_order_example
        </Link>
        <Link
          href={bulkExampleCSV}
          download="talos_import_bulk_order_example.csv"
          data-testid="order-import-bulk-order-example"
        >
          bulk_order_example
        </Link>
      </Flex>
    </Box>
  );
});

const CSV_START_TEXT = 'data:text/csv;charset=utf-8,';

const SAMPLE_MARKET: Map<keyof typeof ALL_KEYS_INFO, string> = new Map([
  ['Symbol', 'BTC-USD'],
  ['Market', 'coinbase/coinbase-0'],
  ['Side', 'Buy'],
  ['OrderQty', '1000'],
  ['Currency', 'USD'],
  ['Strategy', 'Market'],
]);

/**
 * Generates a CSV string from the provided headers and provided data. If no data is provided, the sample
 * data will be used if it exists for a given key.
 * @param {Array<keyof typeof ALL_KEYS_INFO>} headers - The headers for the CSV.
 * @param {Array<Partial<Record<keyof typeof ALL_KEYS_INFO, string>>>} rows - The data rows for the CSV.
 * @returns {string} - The generated CSV string.
 */
function getSampleData(
  headers: Array<keyof typeof ALL_KEYS_INFO>,
  rows: Array<Partial<Record<keyof typeof ALL_KEYS_INFO, string>>>
): string {
  const result: string[] = [];

  for (const row of rows) {
    const rowData: string[] = [];
    for (const header of headers) {
      rowData.push(row?.[header] ?? SAMPLE_MARKET.get(header) ?? '');
    }
    result.push(rowData.join(','));
  }
  return `${headers.join(',')}\n${result.join('\n')}\n`;
}

// TODO get rid of this once it is easer to get all the MarketAccounts that are valid and active from Security.Markets
const SAMPLE_EXCHANGES = ['coinbase', 'kraken', 'b2c2', 'galaxy'];

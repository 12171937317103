import { ACTION, IconName } from '@talos/kyoko';
import type { SidebarSubNavItem, SidebarSubTitle } from '@talos/kyoko/src/routed';
import { PEFrame } from './PEFrame';

const currentDomain = window.location.hostname;
export const PORTFOLIO_ENGINEERING_SUB_NAV_ITEMS: (SidebarSubNavItem<'/portfolio-engineering'> | SidebarSubTitle)[] = [
  {
    label: 'Rebalance',
    path: '/portfolio-engineering/rebalance',
    component: <PEFrame src={`//tpe.${currentDomain}/rebalance?trim=true`} title="Rebalance" />,
    icon: IconName.Scale,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Back-tester',
    path: '/portfolio-engineering/back-tester',
    component: <PEFrame src={`//tpe.${currentDomain}/backtest?trim=true`} title="Back-tester" />,
    icon: IconName.FastRewind,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Reporting',
    path: '/portfolio-engineering/reporting',
    component: <PEFrame src={`//tpe.${currentDomain}/reports?trim=true`} title="Reporting" />,
    icon: IconName.DocumentDownload,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Benchmarks',
    path: '/portfolio-engineering/benchmarks',
    component: <PEFrame src={`//tpe.${currentDomain}/benchmarks?trim=true`} title="Benchmarks" />,
    icon: IconName.ChartBarBox,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Universes',
    path: '/portfolio-engineering/universes',
    component: <PEFrame src={`//tpe.${currentDomain}/universes?trim=true`} title="Universes" />,
    icon: IconName.Star,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Timeseries',
    path: '/portfolio-engineering/ts',
    component: <PEFrame src={`//tpe.${currentDomain}/ts?trim=true`} title="Timeseries" />,
    icon: IconName.PresentationChartLine,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Models',
    path: '/portfolio-engineering/models',
    component: <PEFrame src={`//tpe.${currentDomain}/models?trim=true`} title="Models" />,
    icon: IconName.CubeTransparent,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Risk Models',
    path: '/portfolio-engineering/risk-models',
    component: <PEFrame src={`//tpe.${currentDomain}/risk-models?trim=true`} title="Risk Models" />,
    icon: IconName.Exclamation,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Setup',
    type: 'title',
  },
  {
    label: 'Jobs',
    path: '/portfolio-engineering/jobs',
    component: <PEFrame src={`//tpe.${currentDomain}/jobs?trim=true`} title="Jobs" />,
    icon: IconName.Timer,
    requiredPermission: ACTION.EDIT_PORTFOLIO_ENGINEERING,
  },
  {
    label: 'Permissions',
    path: '/portfolio-engineering/iam',
    component: <PEFrame src={`//tpe.${currentDomain}/iam?trim=true`} title="Permissions" />,
    icon: IconName.LockClosed,
    requiredPermission: ACTION.EDIT_USERS,
  },
];

import {
  ACTION,
  Panel,
  PanelContent,
  PanelHeader,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  useTabs,
} from '@talos/kyoko';
import { compact } from 'lodash-es';
import { useRoleAuth } from '../../../hooks';
import { CounterpartyLimits } from './CounterpartyLimits';
import { MaxOrderSize } from './MaxOrderSize';
import { SubAccountTradingLimits } from './SubAccountTradingLimits';
import { SubAccountWindowLimits } from './SubAccountWindowLimits';

export const TradingLimits = () => {
  const { isAuthorized } = useRoleAuth();
  const tabs = useTabs({
    initialItems: compact([
      isAuthorized(ACTION.EDIT_MAX_ORDER_SIZE_LIMITS) ? { label: 'Max Order Size Limits' } : undefined,
      isAuthorized(ACTION.EDIT_COUNTERPARTY_LIMITS) ? { label: 'Counterparty Trading Limits' } : undefined,
      { label: 'Sub Account Position Limits' },
      { label: 'Sub Account Window Limits' },
    ]),
  });

  return (
    <Panel>
      <PanelHeader>
        <h2>Trading Limits</h2>
      </PanelHeader>
      <PanelContent>
        <Tabs h="100%" {...tabs} size={TabSize.Large}>
          <TabList>
            {tabs.items.map((tab, index) => (
              <Tab key={index} {...tab} />
            ))}
          </TabList>
          <TabPanels overflow="hidden" h="100%">
            <MaxOrderSize />
            <CounterpartyLimits />
            <SubAccountTradingLimits />
            <SubAccountWindowLimits />
          </TabPanels>
        </Tabs>
      </PanelContent>
    </Panel>
  );
};

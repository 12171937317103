import { compact } from 'lodash-es';
import {
  DEFAULT_REPORT_TABS as ANALYTICS_DEFAULT_REPORT_TABS,
  ANALYTICS_REPORTS_TABS_ID,
} from 'providers/useAnalyticsTabs';
import type { useTabLabelResolver } from '../../hooks/useTabLabelResolver';
import type { OrderDetailsMarketTab } from '../../providers/MarketTabs.types';
import { DEALER_SUB_NAV_ITEMS } from '../Dealer/NavItems';
import { MONITORING_BLOTTER_PREFIX } from '../Monitoring/Blotters/types';
import { PORTFOLIO_ENGINEERING_SUB_NAV_ITEMS } from '../PortfolioEngineering/NavItems';
import { SETTINGS_SUB_NAV_ITEMS } from '../Settings/NavItems';
import {
  getDataFromDeepDiveRoute,
  getIdFromAnalyticsRoute,
  getIdFromMarketRoute,
  getIdFromMonitoringRoute,
  getIdFromOrderRoute,
  isCurrentRouteAnalytics,
  isCurrentRouteDealer,
  isCurrentRouteDeepDive,
  isCurrentRouteMarket,
  isCurrentRouteMonitoringBlotter,
  isCurrentRouteOrderDetails,
  isCurrentRoutePortfolioEngineering,
  isCurrentRouteSettings,
} from './routes';

export const mapPathToDisplayParts = (
  resolveTabLabel: ReturnType<typeof useTabLabelResolver>['resolveTabLabel'],
  path: string
): (string | undefined)[] => {
  if (isCurrentRouteOrderDetails(path)) {
    const orderID = getIdFromOrderRoute(path);
    const label = resolveTabLabel({
      tabSetID: 'marketTabs',
      tabID: orderID,
      tabIDKey: 'orderID' satisfies keyof OrderDetailsMarketTab,
    });
    return ['trading', label || `#${orderID?.substring(0, 4)}`];
  }

  if (isCurrentRouteDeepDive(path)) {
    const [symbol, id] = getDataFromDeepDiveRoute(path);
    const label = resolveTabLabel({ tabSetID: 'marketTabs', tabID: id });
    return ['trading', label || symbol];
  }

  if (isCurrentRouteMarket(path)) {
    const id = getIdFromMarketRoute(path);
    return ['trading', resolveTabLabel({ tabSetID: 'marketTabs', tabID: id }) || id];
  }

  if (isCurrentRouteSettings(path)) {
    // navItem.path below here will always be the ending of the main path
    const associatedNavItem = SETTINGS_SUB_NAV_ITEMS.find(navItem => path.endsWith(navItem.path));
    return compact(['settings', associatedNavItem?.label]);
  }

  if (isCurrentRoutePortfolioEngineering(path)) {
    // navItem.path below here will always be the ending of the main path
    const associatedNavItem = PORTFOLIO_ENGINEERING_SUB_NAV_ITEMS.find(
      navItem => 'path' in navItem && path.endsWith(navItem.path)
    );
    return compact(['Portfolio Engineering', associatedNavItem?.label]);
  }

  if (isCurrentRouteMonitoringBlotter(path)) {
    const tabId = getIdFromMonitoringRoute(path);
    const label = resolveTabLabel({ tabSetID: MONITORING_BLOTTER_PREFIX, tabID: tabId });
    return ['monitoring', 'blotters', label || tabId];
  }

  if (isCurrentRouteAnalytics(path)) {
    const tabID = getIdFromAnalyticsRoute(path);
    const label = resolveTabLabel({
      tabSetID: ANALYTICS_REPORTS_TABS_ID,
      tabID,
      nonPersistedTabs: ANALYTICS_DEFAULT_REPORT_TABS,
    });
    return compact(['analytics', label ?? tabID]);
  }

  if (isCurrentRouteDealer(path)) {
    const associatedNavItem = DEALER_SUB_NAV_ITEMS.filter(item => 'path' in item).find(navItem =>
      path.startsWith(navItem['path'])
    );
    return compact(['dealer', associatedNavItem?.label]);
  }

  return path.split('/').slice(1); // ignore initial trailing white space
};

export const mapDisplayPartToPath = (parts: string[], currentPath: string): string | undefined => {
  // handle cases where clicking on ['settings', 'user groups'] get's translated to 'settings/user-groups' instead
  const escaped = parts.map(p => p?.replace(/ /g, '-'));

  const isTrading =
    isCurrentRouteMarket(currentPath) || isCurrentRouteOrderDetails(currentPath) || isCurrentRouteDeepDive(currentPath);
  if (isTrading) {
    return escaped.length === 1 ? 'trading' : undefined;
  }
  return escaped.join('/');
};

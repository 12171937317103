import { startCase } from 'lodash-es';
import { useMemo } from 'react';
import { AddressRoutingTypeEnum } from '../../../../types';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

/**
 * This hook creates the filter property definition for the AddressRoutingTypeEnum.
 */
export function useAddressRoutingTypesFilter() {
  return useMemo(
    () =>
      ({
        key: 'AddressRoutingTypes',
        label: 'Address Routing Type',
        field: 'AddressRoutingType',
        icon: IconName.CubeTransparent,
        control: 'multi-select',
        options: Object.keys(AddressRoutingTypeEnum),
        getOptionLabel: (option: string) => startCase(option),
      } as const satisfies FilterableProperty),
    []
  );
}

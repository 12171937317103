import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  CustomerExecutionStrategyEnum,
  CustomerOrder,
  Divider,
  FormControlSizes,
  HStack,
  LookbackOption,
  MixpanelEvent,
  MixpanelEventProperty,
  Order,
  SearchSelect,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  Text,
  Trade,
  getTabLabelForType,
  isDetailsDrawerEntity,
  useDynamicCallback,
  useMixpanel,
  usePersistedTabs,
  usePortal,
  useTabs,
  type BlotterTableFilter,
  type Column,
  type CustomerQuote,
  type SearchSelectProps,
  type TabsProps,
} from '@talos/kyoko';
import type { RowDoubleClickedEvent } from 'ag-grid-community';
import { useDetailsDrawer } from 'components/DetailsDrawer/useDetailsDrawer';
import { prime } from 'components/OMS/ManualRFQPricingView/ManualRFQPricingSlice';
import { openView } from 'components/OMS/OMSSlice';
import { OMSView } from 'components/OMS/OMSView';
import { getDealerMonitoringOrderDetailsRoute } from 'containers/Routes/routes';
import { findIndex } from 'lodash-es';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SUB_HEADER_HEIGHT } from 'styles/dimensions';
import { v1 as uuid } from 'uuid';
import { AutoCompleteDropdownGroupHeader, NewTabEntitySelector } from '../../Monitoring/Blotters';
import { MonitoringBlotter } from '../../Monitoring/Blotters/MonitoringBlotter';
import type { MonitoringBlotterTableFilter } from '../../Monitoring/Blotters/types';
import {
  customerEntityDisplayNames,
  type CustomerEntityName,
  type DealerBlotterTableEntity,
  type DealerMonitoringBlotterTabProps,
} from './types';

const TABS_EXCLUDED_IDS = ['recent-orders', 'recent-quotes', 'recent-trades'];

export function Blotters() {
  const mixpanel = useMixpanel();
  const { openDetailsDrawer } = useDetailsDrawer();
  const [initialFiltersOpen, setInitialFiltersOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ id: string }>();

  const getDetailsHref = (entity: any) => {
    if (entity instanceof Order) {
      return getDealerMonitoringOrderDetailsRoute({ orderID: entity.OrderID, type: 'order' });
    }
    if (entity instanceof CustomerOrder && entity.ExecutionStrategy !== CustomerExecutionStrategyEnum.OrderAcceptance) {
      return getDealerMonitoringOrderDetailsRoute({ orderID: entity.OrderID, type: 'customer-order' });
    }
  };

  // Non-monitoring blotters use GridOptions.onRowDoubleClicked, but monitoring blotters use this prop.
  const handleDoubleClickRow = useCallback(
    ({ data }: RowDoubleClickedEvent<DealerBlotterTableEntity>) => {
      if (!data) {
        return;
      }
      if (data instanceof Order || data instanceof Trade) {
        const href = getDetailsHref(data);
        if (href) {
          navigate(href);
        }
      } else if (isDetailsDrawerEntity(data)) {
        openDetailsDrawer({ entity: data, getDetailsHref });
      }
    },
    [navigate, openDetailsDrawer]
  );

  const handleSelect: TabsProps['onSelect'] = useDynamicCallback(index => {
    setInitialFiltersOpen(false);
    const blotterUrl = '/dealer/monitoring/' + tabs.items[index].id;
    if (blotterUrl !== location.pathname) {
      navigate(blotterUrl);
    }
  });

  const defaultTabs = useMemo(
    () => [
      {
        label: 'Recent Orders',
        id: 'recent-orders',
        closable: false,
        editable: false,
        name: 'CustomerOrder',
        defaultFilter: {
          _start: LookbackOption.Past30Days,
          HideAPICalls: true,
        },
        defaultSort: '-SubmitTime',
      },
      {
        label: 'Recent Quotes',
        id: 'recent-quotes',
        closable: false,
        editable: false,
        name: 'CustomerQuote',
        defaultFilter: {
          _start: LookbackOption.Past30Days,
          HideAPICalls: true,
        },
        defaultSort: '-SubmitTime',
      },
      {
        label: 'Recent Trades',
        id: 'recent-trades',
        closable: false,
        editable: false,
        name: 'CustomerTrade',
        defaultFilter: {
          _start: LookbackOption.Past30Days,
          HideAPICalls: true,
        },
        defaultSort: '-TransactTime',
      },
    ],
    []
  ) satisfies DealerMonitoringBlotterTabProps[];
  const newTabDefaults = useMemo(() => {
    return {
      defaultColumns: [],
      defaultFilter: {
        _start: LookbackOption.Past24Hours,
        HideAPICalls: true,
      },
      closable: true,
      reorderable: true,
      editable: true,
    } satisfies Partial<DealerMonitoringBlotterTabProps>;
  }, []);

  const persistedTabs = usePersistedTabs<DealerMonitoringBlotterTabProps>('dealer/monitoring', {
    defaultInitialItems: defaultTabs,
    defaultInitialSelectedIndex: 0,
    onSelect: handleSelect,
  });
  const handleAdd = useDynamicCallback(() => {
    setInitialFiltersOpen(true);
  });

  const tabs = useTabs<DealerMonitoringBlotterTabProps>({
    ...persistedTabs,
    initialItems: persistedTabs.initialItems,
    showAddTab: true,
    tabLabeler: 'New Tab',
    onAdd: handleAdd,
  });
  const handleCloneTab = useDynamicCallback((filter: BlotterTableFilter, columns: Column[]) => {
    tabs.clone(tabs.selectedIndex, {
      defaultColumns: columns,
      defaultFilter: filter as MonitoringBlotterTableFilter,
      closable: true,
      reorderable: true,
      editable: true,
    });
  });

  const dispatch = useAppStateDispatch();
  const setSelectedTabFromUrl = useDynamicCallback(() => {
    /**
     * This is a small little hack to enable deep linking from notifications.
     * It's made specifically for the ETF RFQ flow!
     */
    const query = new URLSearchParams(location.search);
    const rfqID = query.get('send-quote');
    if (rfqID != null) {
      // TODO There's no validation here to ensure that the RFQID is valid, but it should be fine
      // since we'll generate these deep links programatically anyway.
      dispatch(prime({ RFQID: rfqID } as CustomerQuote));
      dispatch(openView(OMSView.ManualRFQPricingForm));
    }

    const tabIndex = findIndex(tabs.items, t => t.id === params.id);
    if (tabIndex >= 0 && tabIndex !== tabs.selectedIndex) {
      tabs.setSelectedIndex(tabIndex);
    }
  });

  useEffect(() => setSelectedTabFromUrl(), [location.pathname, setSelectedTabFromUrl]);

  const setSelectedEntity = useDynamicCallback((entityName?: CustomerEntityName) => {
    if (entityName) {
      tabs.setItems(currentTabs => {
        const newTabs = [...currentTabs];
        const selectedTab = newTabs[tabs.selectedIndex];
        if (selectedTab) {
          selectedTab.name = entityName;
          mixpanel.track(MixpanelEvent.ChangeMonitoringBlotterEntity, {
            [MixpanelEventProperty.TabLabel]: selectedTab.label,
            [MixpanelEventProperty.TabType]: entityName,
          });
        }
        return newTabs;
      });
    }
  });

  const entitySelectProps = useMemo(
    () =>
      ({
        options: Object.keys(customerEntityDisplayNames) as CustomerEntityName[],
        getLabel: name => customerEntityDisplayNames[name].name,
        onChange: setSelectedEntity,
        size: FormControlSizes.Small,
        getGroup: name => customerEntityDisplayNames[name].group,
        dropdownWidth: '240px',
        RenderGroupHeader: AutoCompleteDropdownGroupHeader,
        dropdownPlacement: 'right-end',
      } satisfies Partial<SearchSelectProps<CustomerEntityName>>),
    [setSelectedEntity]
  );

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  const [showNewTabDialog, setShowNewTabDialog] = useState(false);
  // This callback is used to create a new tab, rather than using the handler returned from `useTabs`.
  // We do this so that we can show the entity selector dialog before creating the tab.
  const addNewTab = useCallback(
    (selectedEntity?: CustomerEntityName) => {
      if (!selectedEntity) {
        setShowNewTabDialog(false);
        return;
      }
      tabs.setItems(currentTabs => {
        const label = getTabLabelForType({
          tabs: currentTabs,
          typeKey: 'name',
          newTabType: selectedEntity,
          typeToLabel: () => customerEntityDisplayNames[selectedEntity].name,
        });

        const newTabs: DealerMonitoringBlotterTabProps[] = [
          ...currentTabs,
          {
            name: selectedEntity,
            label,
            id: uuid(),
            closable: true,
            reorderable: true,
            editable: true,
            defaultFilter: undefined,
            defaultColumns: undefined,
          },
        ];
        return newTabs;
      });
      tabs.setSelectedIndex(tabs.items.length);
      setShowNewTabDialog(false);
      mixpanel.track(MixpanelEvent.AddMonitoringBlotterTab, {
        [MixpanelEventProperty.TabType]: selectedEntity,
      });
    },
    [tabs, mixpanel]
  );

  const selectedTab = tabs.items[tabs.selectedIndex];

  return (
    <HStack h="100%" w="100%" gap="spacingTiny" overflow="hidden" alignItems="flex-start">
      <Tabs
        {...tabs}
        onAdd={() => setShowNewTabDialog(true)}
        h={`calc(100% - ${SUB_HEADER_HEIGHT}px)`}
        size={TabSize.Large}
      >
        <TabList
          flex="0 0 auto"
          isBordered
          rightItems={
            selectedTab?.name && (
              <HStack justifyContent="flex-end" flex="1 1 auto">
                {!TABS_EXCLUDED_IDS.includes(selectedTab.id as string) && (
                  <>
                    <SearchSelect
                      selection={selectedTab.name}
                      {...entitySelectProps}
                      portalize={true}
                      dropdownPlacement="bottom"
                      prefix={
                        <Text color="colors.gray.090" fontWeight={500}>
                          Entity:{' '}
                        </Text>
                      }
                    />
                    <Divider orientation="vertical" m="spacingSmall" />
                  </>
                )}
                <Box ref={filtersContainerRef} />
              </HStack>
            )
          }
        >
          {tabs.items.map((tab, idx) => (
            <Tab key={idx} {...tab} />
          ))}
        </TabList>
        <TabPanels h="100%" w="100%" position="relative">
          {tabs.items.map(tab => (
            <AccordionGroup key={tab.id}>
              {tab.name && (
                <MonitoringBlotter
                  onRowDoubleClicked={handleDoubleClickRow}
                  blotterID={`dealer/monitoring/${tab.id}`}
                  tabLabel={tab.label}
                  selectedEntity={tab.name}
                  defaultColumns={tab.defaultColumns ?? newTabDefaults.defaultColumns}
                  defaultFilter={tab.defaultFilter ?? newTabDefaults.defaultFilter}
                  onCloneTab={handleCloneTab}
                  initialIsOpen={initialFiltersOpen}
                  generateOrderDetailsRoute={getDealerMonitoringOrderDetailsRoute}
                  source="dealer-monitoring"
                />
              )}
            </AccordionGroup>
          ))}
        </TabPanels>
      </Tabs>
      <NewTabEntitySelector<CustomerEntityName> setSelectedEntity={addNewTab} isOpen={showNewTabDialog} />
    </HStack>
  );
}

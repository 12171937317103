import { useCallback, type ReactNode } from 'react';
import { useTheme } from 'styled-components';
import { ConnectionStatusEnum } from '../../types';
import type { PropType, StyledComponentAllProps } from '../../utils';
import { connectionStatusText } from '../../utils';
import { BulletText } from '../Bullet';
import { Flex } from '../Core';
import { ICON_SIZES, IconName, type IconProps } from '../Icons';
import { Popover } from '../Popover/Popover';
import type { PopoverProps } from '../Popover/types';
import { usePopoverState } from '../Popover/usePopoverState';
import { BulletSpinner, BulletWithAnimation, StatusWrapper, Text } from './styles';

export { StatusWrapper } from './styles';

const BulletPopover = ({
  color,
  text,
  popoverPlacement,
  isLoading,
}: Pick<StatusIndicatorProps, 'color' | 'text' | 'popoverPlacement' | 'isLoading'>) => {
  const popover = usePopoverState({
    usePortal: true,
    trigger: 'hover',
    isSmall: true,
    placement: popoverPlacement ?? 'right',
    delay: undefined,
    preventOverflow: false,
  });
  return (
    <Popover {...popover}>
      <BulletWithAnimation color={color} isLoading={isLoading} />
      {text && <BulletText>{text}</BulletText>}
    </Popover>
  );
};

export interface StatusIndicatorProps extends StyledComponentAllProps<typeof StatusWrapper> {
  color: string;
  text?: ReactNode;
  popoverText?: string | JSX.Element;
  size?: IconProps['size'];
  iconRight?: boolean;
  popoverPlacement?: PropType<PopoverProps, 'placement'>;
  showIcon?: boolean;
  showText?: boolean;
  isLoading?: boolean;
}

export function StatusIndicator({
  color,
  text = '',
  popoverText = '',
  size = ICON_SIZES.SMALL,
  isLoading = false,
  iconRight = false,
  popoverPlacement = 'right',
  showIcon = true,
  showText = true,
  ...props
}: StatusIndicatorProps) {
  const Indicator = popoverText === '' ? BulletWithAnimation : BulletPopover;
  const IndicatorWithSpinner = useCallback(
    () => (
      <Flex position="relative" alignItems="center" justifyContent="center">
        {isLoading && <BulletSpinner icon={IconName.RefreshRecover} variant={color} size={size} />}
        <Indicator
          size={size}
          color={color}
          text={popoverText}
          popoverPlacement={popoverPlacement}
          isLoading={isLoading}
        />
      </Flex>
    ),
    [Indicator, size, color, popoverText, popoverPlacement, isLoading]
  );
  return (
    <StatusWrapper data-testid="status-indicator" iconRight={iconRight} {...props}>
      {showIcon && !iconRight && <IndicatorWithSpinner />}
      {text !== '' && <Text>{text}</Text>}
      {showIcon && iconRight && <IndicatorWithSpinner />}
    </StatusWrapper>
  );
}

export const ConnectionStatusIndicator = ({
  status,
  showText,
  ...props
}: Omit<StatusIndicatorProps, 'color'> & {
  status?: ConnectionStatusEnum;
}) => {
  const text = connectionStatusText(status);
  const theme = useTheme();
  let color: string;

  switch (status) {
    case ConnectionStatusEnum.Online:
      color = theme.colorTextPositive;
      break;
    case ConnectionStatusEnum.Stale:
    case ConnectionStatusEnum.Starting:
    case ConnectionStatusEnum.Stopping:
      color = theme.colorTextWarning;
      break;
    case ConnectionStatusEnum.Error:
      color = theme.colorTextWarning; // We want to ensure that we don't use `theme.colorTextNegative` here because we shouldn't show red on the screen
      break;
    case ConnectionStatusEnum.Offline:
    case ConnectionStatusEnum.Disabled:
    case ConnectionStatusEnum.Unavailable:
    case ConnectionStatusEnum.NotInSession:
    case ConnectionStatusEnum.Keepalive:
      color = theme.colorTextMuted;
      break;
    default:
      color = theme.colorTextDefault;
  }

  return <StatusIndicator color={color} text={showText ? text : undefined} iconRight {...props} />;
};

export const ANIMATION_DURATION = 200;

export const BRANCH = 'branch' as const;
export const LEAF = 'leaf' as const;

export const STYLE_TAG_ID = 'styles-tag-wrapper';

export const MAIN_GROUP_ID = 'main';

export const POPOUT_URL = '/popout';

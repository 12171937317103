import { useCurrency } from '../../hooks';
import { abbr } from '../../utils';
import { HStack } from '../Core';
import { Text } from '../Text';

export function AbbrLimit({ value, currency }: { value: string | undefined; currency?: string }) {
  const currencyIncrement = useCurrency(currency)?.DefaultIncrement;
  // turn increment into precision
  const precision = currencyIncrement ? currencyIncrement.toString().split('.')[1]?.length : 2;
  const { number: abbreviatedValue, suffix } = abbr(parseFloat(value ?? '0'), precision ?? 2);

  return (
    <HStack gap="spacingSmall">
      <Text>
        {abbreviatedValue}
        {suffix}
      </Text>
      {currency && <Text color="colorTextSubtle">{currency}</Text>}
    </HStack>
  );
}

import {
  AccordionGroup,
  Box,
  SubAccountReconMatchStatusEnum,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  TabSize,
  usePortal,
  useTabs,
} from '@talos/kyoko';

import { BreakDetailsBlotter } from './BreakDetailsBlotter';
import {
  RECON_MATCHES_BLOTTER_PORTAL_ID,
  type BreakDetailsBlotterTabProps,
  type OpenResolutionDrawerFunc,
} from './types';

const TABS: BreakDetailsBlotterTabProps[] = [
  {
    id: 'break-details-bottom-blotter-breaks',
    label: 'Breaks',
    defaultFilter: {
      Statuses: [
        SubAccountReconMatchStatusEnum.Unmatched,
        SubAccountReconMatchStatusEnum.UnmatchedMarketAccount,
        SubAccountReconMatchStatusEnum.UnmatchedSubAccount,
      ],
    },
  },
  {
    id: 'break-details-bottom-blotter-matched',
    label: 'Matched',
    defaultFilter: {
      Statuses: [SubAccountReconMatchStatusEnum.Matched],
    },
  },
  {
    id: 'break-details-bottom-blotter-resolved',
    label: 'Resolved',
    defaultFilter: {
      Statuses: [SubAccountReconMatchStatusEnum.Resolved, SubAccountReconMatchStatusEnum.Ignored],
    },
  },
  {
    id: 'break-details-bottom-blotter-all',
    label: 'All',
    defaultFilter: {},
  },
];

export const BreakDetailsBlotterWrapper = ({
  openResolutionDrawer,
  checkpointID,
  showAllDecimals,
}: {
  openResolutionDrawer: OpenResolutionDrawerFunc;
  checkpointID: string;
  showAllDecimals?: boolean;
}) => {
  const tabs = useTabs<BreakDetailsBlotterTabProps>({
    initialItems: TABS,
    showAddTab: false,
  });

  const { setPortalRef: filtersContainerRef } = usePortal(RECON_MATCHES_BLOTTER_PORTAL_ID);

  return (
    <Tabs {...tabs} h="100%" size={TabSize.Large}>
      <TabList isBordered rightItems={<Box ref={filtersContainerRef} />}>
        {tabs.items.map((tab, idx) => (
          <Tab key={idx} {...tab} />
        ))}
      </TabList>
      <TabPanels style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        {tabs.items.map(tab => (
          <AccordionGroup key={tab.id}>
            <BreakDetailsBlotter
              tab={tab}
              openResolutionDrawer={openResolutionDrawer}
              checkpointID={checkpointID}
              showAllDecimals={showAllDecimals}
            />
          </AccordionGroup>
        ))}
      </TabPanels>
    </Tabs>
  );
};

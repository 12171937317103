import { ControlPrefix, FormControlSizes, SearchSelect } from '@talos/kyoko';
import { capitalize } from 'lodash-es';
import { useCallback } from 'react';
import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformancePageProducts } from '../hooks/usePerformancePageProducts';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import type { Product } from '../types';

export const ProductControl = () => {
  const {
    state: { product },
    dispatch,
  } = usePerformanceContext();

  const productOptions = usePerformancePageProducts();

  const handleChange = useCallback(
    (product: Product | undefined) => {
      if (product) {
        dispatch({
          type: PerformanceActionType.ProductChange,
          payload: {
            product,
          },
        });
      }
    },
    [dispatch]
  );

  return (
    <SearchSelect
      options={productOptions}
      selection={product}
      getLabel={capitalize}
      onChange={handleChange}
      disabled={productOptions.length === 1}
      size={FormControlSizes.Small}
      prefix={<ControlPrefix>Product</ControlPrefix>}
    />
  );
};

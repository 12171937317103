import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  CUSTOMER_QUOTE,
  CustomerQuote,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  DEFAULT_MAX_ROWS,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  QuoteStatusEnum,
  WSBlotterTableMaxRecordsReachedWarning,
  columnToColumnState,
  createCSVFileName,
  filterByColumnMainMenuItems,
  isGridApiReady,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useCustomerQuoteColumns,
  useDisclosure,
  useDynamicCallback,
  useMixpanel,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type BlotterColumnState,
  type BlotterTableFilter,
  type Column,
} from '@talos/kyoko';
import { useCallback, useMemo, useState } from 'react';

import type {
  GetMainMenuItemsParams,
  GridOptions,
  IsRowSelectable,
  RowDoubleClickedEvent,
  RowSelectionOptions,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';
import { FixQuotePriceDialog } from '../../../components/FixQuotePriceDialog/FixQuotePriceDialog';
import { useFeatureFlag } from '../../../hooks';
import {
  colIDToFilterBuilderKey,
  onlyServerFilterKeys,
  useCustomerQuotesFilter,
  type CustomerQuotesTableFilter,
} from './useCustomerQuotesFilter';
import { useCustomerQuotesMenu } from './useCustomerQuotesMenu';

export interface FilteredCustomerQuotesParams {
  blotterID: string;
  tabLabel?: string;
  defaultColumns?: Column[];
  defaultFilter?: CustomerQuotesTableFilter;
  initialIsOpen?: boolean;

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab?: (filter: BlotterTableFilter, columns: BlotterColumnState[]) => void;
  onRowDoubleClicked?: (params: RowDoubleClickedEvent<CustomerQuote>) => void;
  source?: 'monitoring' | 'dealer-monitoring';
}

const isRowSelectable: IsRowSelectable<CustomerQuote> = row => row.data?.QuoteStatus === QuoteStatusEnum.PendingFix;

/** Row selection settings if the enableMakerMarketplaceETFRFQFlow flag is enabled. */
const SELECTION_MULTI_PARAMS_CHECKBOXES = {
  mode: 'multiRow',
  hideDisabledCheckboxes: true,
  checkboxes: true,
  headerCheckbox: true,
  enableClickSelection: false,
  isRowSelectable,
} as const satisfies RowSelectionOptions;

export function CustomerQuotesBlotterTable({
  blotterID,
  tabLabel,
  defaultColumns,
  defaultFilter,
  initialIsOpen,
  onCloneTab,
  onRowDoubleClicked,
  source = 'dealer-monitoring',
}: FilteredCustomerQuotesParams) {
  const { getConfig } = useOrgConfiguration();
  const mixpanel = useMixpanel();
  const { enableMakerMarketplaceETFRFQFlow } = useFeatureFlag();
  const defaultColumnDefinitions = useCustomerQuoteColumns({
    defaultColumns,
    source,
    enableMakerMarketplaceETFRFQFlow,
  });

  const persistedBlotterTable = usePersistedBlotterTable<CustomerQuote>(blotterID, {
    columns: defaultColumnDefinitions,
    filter: defaultFilter,
    sort: '-SubmitTime',
  });

  const [selectedRows, setSelectedRows] = useState<CustomerQuote[]>([]);
  const handleRowSelectionChanged = useCallback(({ api }: SelectionChangedEvent<CustomerQuote>) => {
    if (!isGridApiReady(api)) {
      return;
    }
    const rows = api.getSelectedRows();
    setSelectedRows(rows.filter(node => node.QuoteStatus === QuoteStatusEnum.PendingFix));
  }, []);

  const filterResults = useCustomerQuotesFilter({
    persistedBlotterTable,
  });
  const { clientSideFilter: clientLocalFilter, blotterTableFilterProps, filterBuilderProps } = filterResults;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });

  const CustomerQuotesMenu = useCustomerQuotesMenu({
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterBuilderProps.properties,
  });

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    return filterByColumnMainMenuItems({
      params,
      colIDToFilterBuilderKey,
      openClause: filterBuilderAccordion.openClause,
      mixpanel,
    });
  });

  const rowSelection = useMemo((): GridOptions['rowSelection'] => {
    const baseRowSelection = enableMakerMarketplaceETFRFQFlow
      ? SELECTION_MULTI_PARAMS_CHECKBOXES
      : DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS;
    return {
      ...baseRowSelection,
      enableClickSelection: false,
    };
  }, [enableMakerMarketplaceETFRFQFlow]);

  const columnsWithMenu = useMemo<Column[]>(
    () => [...persistedBlotterTable.columnsOnly, ...CustomerQuotesMenu.columns],
    [CustomerQuotesMenu.columns, persistedBlotterTable.columnsOnly]
  );

  const { showBlotterPauseButton } = useFeatureFlag();

  const blotterTable = useWsBlotterTable({
    initialRequest: {
      tag: blotterID,
      name: CUSTOMER_QUOTE,
      sort_by: '-SubmitTime',
    },
    rowID: CustomerQuote.rowID,
    filter: onlyServerFilterKeys(filterResults.filter),
    clientLocalFilter,
    persistence: persistedBlotterTable,
    columns: columnsWithMenu,
    getExtraMainMenuItems,
    startingRowLimit: getConfig(OrgConfigurationKey.BlotterRowsMax, DEFAULT_MAX_ROWS),
    pauseParams: {
      showPauseButton: showBlotterPauseButton,
    },
    gridOptions: {
      onSelectionChanged: handleRowSelectionChanged,
      onRowDoubleClicked,
      getContextMenuItems: CustomerQuotesMenu.getContextMenuItems,
      rowSelection,
    },
  });

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(filterResults.filter, blotterTable.getColumns().map(columnToColumnState));
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'CustomerQuotes',
        tabLabel,
      }),
    });
    extrasMenuPopover.close();
  });

  const fixQuotePriceDialog = useDisclosure();

  const applyFixingEnabled =
    selectedRows.length === 0 ||
    selectedRows.every(
      row =>
        row.QuoteStatus === QuoteStatusEnum.PendingFix &&
        row.FixingDetails?.Index === selectedRows[0].FixingDetails?.Index
    );

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTableFilterProps}
        {...blotterTable.blotterTableFiltersProps}
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>
            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
          </BlotterTableExtrasMenu>
        }
        prefix={
          enableMakerMarketplaceETFRFQFlow ? (
            <>
              <Button
                size={FormControlSizes.Small}
                variant={ButtonVariants.Primary}
                disabled={!applyFixingEnabled}
                onClick={() => fixQuotePriceDialog.open()}
                data-testid="apply-fixing-button"
              >
                Apply Fixing
              </Button>
            </>
          ) : undefined
        }
      />
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.SubmitTime} />
      <BlotterTable {...blotterTable} />
      {CustomerQuotesMenu.dialogs}
      {enableMakerMarketplaceETFRFQFlow && (
        <FixQuotePriceDialog selectedCustomerQuotes={selectedRows} {...fixQuotePriceDialog} />
      )}
    </>
  );
}

import { useCallback, useMemo } from 'react';
import { type RouteProps, useLocation, useNavigate } from 'react-router-dom';

/** Handle Query Parms using react-router-dom */
export function useRouterQueryParams<
  TRouteParams extends {
    [K in keyof TRouteParams]?: string;
  } = AnyObject,
  TQueryParams extends Record<string, string> = Record<string, string>
>(path: undefined | string | string[] | RouteProps = undefined) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search).entries()) as Partial<TQueryParams>,
    [location.search]
  );

  const setQueryParams = useCallback(
    (newQueryParams: TQueryParams) => {
      navigate({
        search: new URLSearchParams(newQueryParams).toString(),
      });
    },
    [navigate]
  );

  return {
    queryParams,
    setQueryParams,
  };
}

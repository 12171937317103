import Big from 'big.js';
import {
  BaseField,
  FieldValidationLevel,
  FieldValidationType,
  type FieldData,
  type FieldValidationResult,
  type FieldValidationRule,
} from './BaseField';
import { fieldsMessages } from './messages';

export interface IntegerFieldData extends FieldData<number> {
  userInput?: string; // keep track of user input to show in error message
  userInputValid?: boolean;
}

export class IntegerField extends BaseField<IntegerFieldData, number> {
  constructor(initial?: Partial<IntegerFieldData>) {
    super({
      name: 'IntegerField',
      value: undefined,
      isRequired: false,
      placeholder: 'Type here',
      isTouched: false,
      isDisabled: false,
      isVisible: true,
      errors: [],
      ...initial,
    });
  }

  public get bigValue(): Big | undefined {
    if (this.data.value == null) {
      return undefined;
    }
    try {
      return new Big(this.data.value);
    } catch (e) {
      console.error(`Error trying to get bigValue from ${this.data.value}`);
      return undefined;
    }
  }

  public get value(): number | undefined {
    return typeof this.data.value === 'string' ? parseInt(this.data.value) : this.data.value;
  }

  // Note: System override assumes values would already be in the right format
  public override updateValue(value: string | number | undefined, isSystemOverride = false): IntegerField {
    const updatedData = {
      value: typeof value === 'string' ? parseInt(value) : value,
      isTouched: isSystemOverride ? false : true,
    };

    const updated = this.updateData(updatedData);
    return updated.invariantCheck();
  }

  public setTouched(isTouched: boolean): IntegerField {
    const updated = this.updateData({ isTouched });
    return updated.invariantCheck();
  }

  public setIsRequired(isRequired: boolean): IntegerField {
    const updated = this.updateData({ isRequired });
    return updated.invariantCheck();
  }

  public setIsVisible(isVisible: boolean): IntegerField {
    const updated = this.updateData({ isVisible });
    return updated.invariantCheck();
  }

  public validate<C>(rules: FieldValidationRule<IntegerField, C, number>[] = [], context?: C): IntegerField {
    const checked = this.invariantCheck();
    const errors = checked.data.errors.filter(e => e.type !== FieldValidationType.Rule);

    rules.forEach(rule => {
      const result = rule(this, context);
      if (result) {
        errors.push({ ...result, type: FieldValidationType.Rule });
      }
    });

    return this.updateData({ errors });
  }

  public override setDisabled(isDisabled: boolean): IntegerField {
    return this.updateData({ isDisabled });
  }

  private invariantCheck(): IntegerField {
    const errors: FieldValidationResult[] = this.data.errors.filter(e => e.type === FieldValidationType.Rule);

    if (this.data.isRequired && this.data.value == null) {
      errors.push({
        message: fieldsMessages.dataNameIsRequired,
        values: { dataName: this.data.name },
        level: FieldValidationLevel.Error,
      });
    }
    if (!this.data.userInputValid && this.data.userInput) {
      errors.push({ message: `Invalid input`, level: FieldValidationLevel.Error });
    }

    return this.updateData({ errors });
  }

  private updateData(data: Partial<IntegerFieldData>): IntegerField {
    const newData = {
      ...this.data,
      ...data,
    };
    return new IntegerField(newData);
  }
}

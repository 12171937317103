import type { FilterableProperty, Security } from '@talos/kyoko';
import { ACTION, EntityAdminREST, useSecuritiesContext, useSymbolsFilter, type SymbolGroup } from '@talos/kyoko';
import { useRoleAuth } from 'hooks';
import { filter } from 'lodash-es';
import { useCustomerPricingTierContext, useCustomerSecuritiesBySymbol } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getSymbolGroupsDrawerOptions,
  getSymbolGroupsOverridesDrawerOptions,
  getSymbolGroupsPatchDeletePath,
  getSymbolGroupsPostPath,
} from './utils';

export const CustomerSymbolGroups = () => {
  const { isAuthorized } = useRoleAuth();

  const [symbolGroups, setSymbolGroups] = useState<SymbolGroup[]>([]);
  const { getSymbolGroups } = useCustomerPricingTierContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const customerSecuritiesBySymbol = useCustomerSecuritiesBySymbol();

  const filteredSecurities = useMemo((): Security[] => {
    if (!customerSecuritiesBySymbol) {
      return [];
    }

    // Return an array of securities that exist in customerSecuritiesBySymbol
    return filter(Array.from(securitiesBySymbol.values()), security => customerSecuritiesBySymbol.has(security.Symbol));
  }, [customerSecuritiesBySymbol, securitiesBySymbol]);

  useEffect(() => {
    getSymbolGroups().then(({ data }) => {
      setSymbolGroups(data);
    });
  }, [getSymbolGroups]);

  const symbolGroupDrawerOptions = useMemo(() => getSymbolGroupsDrawerOptions(), []);
  const symbolGroupOverridesDrawerOptions = useMemo(() => {
    if (filteredSecurities != null) {
      return getSymbolGroupsOverridesDrawerOptions(symbolGroups, filteredSecurities);
    }
  }, [symbolGroups, filteredSecurities]);

  const getEntityDrawerOptions = useCallback(
    (entity?: SymbolGroup, addingChildEntity?: boolean) => {
      if (addingChildEntity) {
        return symbolGroupOverridesDrawerOptions;
      }
      const isGroupRow = entity?.Symbol == null;
      return isGroupRow ? symbolGroupDrawerOptions : symbolGroupOverridesDrawerOptions;
    },
    [symbolGroupDrawerOptions, symbolGroupOverridesDrawerOptions]
  );

  const symbolFilter = useSymbolsFilter(filteredSecurities);
  const symbolGroupFilterableProperties: FilterableProperty[] = useMemo(() => [symbolFilter], [symbolFilter]);

  return (
    <EntityAdminREST<SymbolGroup>
      title="Symbol Groups"
      subtitle="Set up your Symbol Groups here."
      path="/symbol-groups?expanded=true"
      getPostPath={getSymbolGroupsPostPath}
      getPatchDeletePath={getSymbolGroupsPatchDeletePath}
      columns={[
        { field: 'Tier', type: 'text', hide: true, sort: '+' },
        { field: 'Symbol', type: 'security', hide: true, sort: '+' },
      ]}
      entityIDField="Tier"
      childIDField="Symbol"
      addChildEntityButtonProps={{
        text: 'Symbol',
        width: 90,
      }}
      getEntityDrawerOptions={getEntityDrawerOptions}
      entityName="Symbol Group"
      allowAddEntity={isAuthorized(ACTION.EDIT_CUSTOMER_SYMBOL_GROUPS)}
      allowEditEntity={false}
      allowDeleteEntity={isAuthorized(ACTION.EDIT_CUSTOMER_SYMBOL_GROUPS)}
      allowModeSwitch={true}
      groupColumnDef={{
        headerName: 'Name',
        width: 300,
        valueFormatter: ({ context, value }) => {
          const security = context.current.securitiesBySymbol?.get(value);
          return security?.DisplaySymbol ?? value;
        },
      }}
      filterableProperties={symbolGroupFilterableProperties}
      persistKey="symbol-groups"
      allowBulkEdit={true}
      bulkEditFields={['Mode']}
    />
  );
};

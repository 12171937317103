import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

interface CurrentDocumentProviderProps {
  currentDocument: Document;
}

const CurrentDocumentContext = createContext<Document>(document);

export const CurrentDocumentProvider = ({
  children,
  currentDocument,
}: PropsWithChildren<CurrentDocumentProviderProps>) => {
  return <CurrentDocumentContext.Provider value={currentDocument}>{children}</CurrentDocumentContext.Provider>;
};

/** Get the current document, useful for when content is rendered in a popup window,
 * which has a different document than the rest of the application */
export const useCurrentDocument = (): Document => {
  // Provider is not required since we have a useful default value
  return useContext(CurrentDocumentContext);
};

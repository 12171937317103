import {
  EMPTY_ARRAY,
  InstrumentCompositionEnum,
  LegDirectionEnum,
  OrdStatusEnum,
  OrdTypeEnum,
  SettleValueTypeEnum,
  SideEnum,
  getOppositeSide,
  isCounterCurrency,
  toBigWithDefault,
  useDynamicCallback,
  useObservable,
  useSecuritiesContext,
  useSecurity,
  useUnifiedLiquidity,
  type ExecutionReport,
} from '@talos/kyoko';

import Big from 'big.js';
import { flatten } from 'lodash-es';
import { useExecutionReports } from 'providers/ExecutionReportsContext';
import { MULTILEG_MARKET, MULTILEG_MARKET_ACCOUNT } from 'providers/OMSContext.types';
import { map } from 'rxjs/operators';
import { isMultiLegSecurity } from 'utils/security';

const DONE_ORDERS = [OrdStatusEnum.Canceled, OrdStatusEnum.Filled, OrdStatusEnum.Rejected, OrdStatusEnum.DoneForDay];

// The existence of this type does not make sense to me
export interface OwnOrderResult {
  orderID: string;
  currency?: string; // Currency will be undefined for contracts
  symbol: string;
  side: string;
  leavesQty?: string;
  price?: string;
  market: string;
  marketAccount: string;
  ordType: OrdTypeEnum;
}

export interface UseOwnOrderProps {
  symbol?: string;
  markets?: string[];
  marketAccounts?: string[];
  includeUnifiedLiquidity?: boolean;
}

export const useOwnOrders = ({
  symbol,
  markets = EMPTY_ARRAY,
  marketAccounts = EMPTY_ARRAY,
  includeUnifiedLiquidity,
}: UseOwnOrderProps) => {
  const { executionReportsByOrderID } = useExecutionReports();
  const unifiedToken = useUnifiedLiquidity(symbol);

  const { securitiesBySymbol } = useSecuritiesContext();
  const security = useSecurity(symbol);

  const mapMarketOrders = useDynamicCallback((order: ExecutionReport): OwnOrderResult[] => {
    const orderSecurity = securitiesBySymbol.get(order.Symbol);

    const mktOrders = order.Markets?.filter(
      marketOrder =>
        !DONE_ORDERS.includes(marketOrder.OrdStatus) &&
        // Avoid showing multileg orders on the own orders table, but include leg orders and UL orders
        marketOrder.Market !== MULTILEG_MARKET
    ).map(marketOrder => {
      // Due to unifiedLiquidity/multileg, marketOrder symbol + side is more accurate.
      let side = marketOrder.Side || order.Side;
      const isCcy = isCounterCurrency(order.Currency, security);
      // Actually multileg for now is giving the wrong side on these market order stubs so we are going to get it based on the Security definition
      // and the leg index. Ideally this will be removed when the backend is telling us the truth.
      if (isMultiLegSecurity(orderSecurity)) {
        side = order.Side;

        const legParity = orderSecurity.MultilegDetails.Legs[marketOrder.LegIndex]?.Direction;
        if (legParity === LegDirectionEnum.Opposite) {
          side = getOppositeSide(side);
        }
      }

      let qty = !order.Currency
        ? marketOrder.LeavesQty
        : toBigWithDefault(marketOrder.LeavesQty, 0).mul(toBigWithDefault(security?.NotionalMultiplier, 1)).toFixed();

      let currency = order.Currency
        ? security?.SettleValueType === SettleValueTypeEnum.Inverted
          ? security?.QuoteCurrency
          : security?.BaseCurrency
        : order.Currency;
      const orderPrice = toBigWithDefault(marketOrder.Price, 0);

      if (orderPrice.gt(0)) {
        // If order is in base and SettleValueType is Inverted change currency
        // to BaseCurrency and adjust qty calculation as LeavesQty is in Contracts
        if (currency && !isCcy && security?.SettleValueType === SettleValueTypeEnum.Inverted) {
          qty = toBigWithDefault(marketOrder.LeavesQty, 0)
            .mul(toBigWithDefault(security?.NotionalMultiplier, 1))
            .div(orderPrice)
            .toFixed();
          currency = security.BaseCurrency;
        }
      }

      return {
        orderID: order.OrderID,
        currency,
        symbol: marketOrder.Symbol || order.Symbol,
        side: side,
        leavesQty: qty,
        price: marketOrder.Price,
        market: marketOrder.Market,
        marketAccount: marketOrder.MarketAccount,
        ordType: marketOrder.OrdType,
      };
    });
    // On multileg orders add a synthetic MarketOrder for the parent level multileg market
    if (isMultiLegSecurity(orderSecurity)) {
      mktOrders.push({
        orderID: order.OrderID,
        currency: order.Currency,
        symbol: order.Symbol,
        side: order.Side,
        leavesQty: !order.Currency
          ? order.LeavesQty
          : toBigWithDefault(order.LeavesQty, 0).mul(toBigWithDefault(security?.NotionalMultiplier, 1)).toFixed(),
        price: order.Price,
        market: MULTILEG_MARKET,
        marketAccount: MULTILEG_MARKET_ACCOUNT,
        ordType: order.OrdType,
      });
    }
    return mktOrders;
  });

  const ownOrders = useObservable<OwnOrderResult[]>(
    () =>
      executionReportsByOrderID.pipe(
        map<Map<string, ExecutionReport>, OwnOrderResult[]>(executionReports => {
          const mappedMktOrders = [...executionReports.values()]
            .filter(order => Big(order.LeavesQty || 0).gt(0) && !DONE_ORDERS.includes(order.OrdStatus))
            .map(mapMarketOrders);
          return flatten(mappedMktOrders).filter(marketOrder => {
            const isMarketOrder = marketOrder.ordType === OrdTypeEnum.Market;
            const hasLeavesQty = Big(marketOrder.leavesQty || 0).gt(0);
            const isMatchingSymbol = marketOrder.symbol === symbol;

            if ((!unifiedToken && !isMatchingSymbol) || isMarketOrder || !hasLeavesQty) {
              return false;
            }

            const isMultiLeg = security?.Composition === InstrumentCompositionEnum.Synthetic;

            const isTradedOnSpecifiedMarkets =
              (!markets.length && !marketAccounts.length) ||
              markets.includes(marketOrder.market) ||
              marketAccounts.includes(marketOrder.marketAccount);

            // If unifiedToken exists, find matching market and symbol within unifiedTokens
            if (unifiedToken && includeUnifiedLiquidity) {
              const isMarketOrderUnifiedLiquidity = !!unifiedToken.Tokens.find(
                token => token.Market === marketOrder.market && token.Symbol === marketOrder.symbol
              );
              if (isMarketOrderUnifiedLiquidity && isTradedOnSpecifiedMarkets) {
                return true;
              }
            }

            return isMatchingSymbol && (isTradedOnSpecifiedMarkets || isMultiLeg);
          });
        })
      ),
    [
      executionReportsByOrderID,
      mapMarketOrders,
      symbol,
      security?.Composition,
      unifiedToken,
      markets,
      marketAccounts,
      includeUnifiedLiquidity,
    ]
  );

  const ownBids = useObservable(
    () =>
      ownOrders.pipe(
        map<OwnOrderResult[], OwnOrderResult[]>(orders => orders.filter(order => order.side === SideEnum.Buy))
      ),
    [ownOrders]
  );
  const ownOffers = useObservable(
    () =>
      ownOrders.pipe(
        map<OwnOrderResult[], OwnOrderResult[]>(orders => orders.filter(order => order.side === SideEnum.Sell))
      ),
    [ownOrders]
  );

  return {
    ownBids,
    ownOffers,
  };
};

import {
  ACTION,
  EMPTY_ARRAY,
  Flex,
  FormGroup,
  Input,
  MultiSelect,
  NotificationVariants,
  useDynamicCallback,
  useGlobalToasts,
  type User,
} from '@talos/kyoko';
import { difference, sortBy } from 'lodash-es';
import { useMemo, useState } from 'react';
import { prettifyRole } from 'tokens/roles';
import { useRoleAuth } from '../../../../hooks';
import type { EditUserForm, EditUserFormArg, IEditUserDrawerTab } from '../types';
import { useUserSettings } from '../useUserSettings';

const getRoleLabel = prettifyRole;
const getRoleDescription = (role: string | undefined) => role || ' ';

interface EditUserGeneralTabProps {
  updateForm: ({ key, value }: EditUserFormArg) => void;
  userForm: EditUserForm;
}

function EditUserGeneralTab({ updateForm, userForm }: EditUserGeneralTabProps) {
  const { editableRoles } = useUserSettings();
  const { isAuthorized } = useRoleAuth();
  const selectedRoles = useMemo(
    () => (userForm?.Roles ?? EMPTY_ARRAY).filter(role => editableRoles.includes(role)),
    [editableRoles, userForm.Roles]
  );

  return (
    <Flex flexDirection="column" w="100%">
      <FormGroup label="Name" controlId="name" disabled>
        <Input
          id="name"
          value={userForm.Name}
          onChange={e => updateForm({ key: 'Name', value: e.target.value })}
          disabled
        />
      </FormGroup>
      <FormGroup label="Email" controlId="email" disabled>
        <Input
          id="email"
          disabled
          value={userForm.Email}
          onChange={e => updateForm({ key: 'Email', value: e.target.value })}
          inputType="email"
        />
      </FormGroup>

      <FormGroup label="Roles" controlId="roles">
        <MultiSelect
          selections={selectedRoles}
          options={editableRoles}
          onChange={selections => updateForm({ key: 'Roles', value: selections })}
          getLabel={getRoleLabel}
          getDescription={getRoleDescription}
          disabled={!isAuthorized(ACTION.EDIT_USERS)}
          portalize
          data-testid="edit-user-roles-multiselect"
        />
      </FormGroup>
    </Flex>
  );
}

export function useEditUserGeneralTab({ user }: { user: User }): IEditUserDrawerTab {
  const { add: addToast } = useGlobalToasts();
  const { updateUser } = useUserSettings();
  const { editableRoles } = useUserSettings();

  const [userForm, setUserForm] = useState<EditUserForm>(user);
  const updateForm = useDynamicCallback(({ key, value }: EditUserFormArg) => {
    setUserForm(prev => ({ ...prev, [key]: value }));
  });

  const isDirty = useMemo(() => {
    if (userForm.Name !== user.Name) {
      return true;
    }
    if (userForm.Email !== user.Email) {
      return true;
    }
    if (JSON.stringify(sortBy(userForm.Roles)) !== JSON.stringify(sortBy(user.Roles))) {
      return true;
    }
    return false;
  }, [userForm, user.Name, user.Email, user.Roles]);

  const handleSaveChanges = useDynamicCallback((reason?: string) => {
    if (!isDirty && !reason) {
      return;
    }

    if (!userForm.ID) {
      return;
    }

    // We need to maintain all roles that current user do no see,
    // like "Admin" user do not see "Talos Admin" user role
    const hiddenRoles = difference(user.Roles, editableRoles);
    return updateUser({
      ...userForm,
      Roles: (userForm?.Roles ?? []).concat(hiddenRoles),
      Comment: reason,
    }).catch((e: Error) => {
      addToast({
        text: `Failed to update user: ${e.message}`,
        variant: NotificationVariants.Negative,
      });
    });
  });

  const component = useMemo(() => {
    return <EditUserGeneralTab updateForm={updateForm} userForm={userForm} />;
  }, [updateForm, userForm]);

  return {
    name: 'General',
    component,
    isDirty,
    viewable: true,
    save: handleSaveChanges,
    renderReasonInput: true,
  };
}

import {
  AGGRID_AUTOCOLUMN_ID,
  BlotterTable,
  BlotterTableFilters,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  FormControlSizes,
  IconButton,
  IconName,
  columnTypes,
  filterByCellValueMenuItem,
  getShowJSONContextItem,
  useBlotterTable,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  type DealerPosition,
} from '@talos/kyoko';
import { useMemo } from 'react';

import type { GetContextMenuItems, GetContextMenuItemsParams } from 'ag-grid-enterprise';
import { compact } from 'lodash-es';
import { useSettlementMonitoringFilters } from '../../providers/SettlementMonitoringFiltersProvider';
import { useSettlementMonitoringInteractions } from '../../providers/SettlementMonitoringInteractionsProvider';
import { POSITION_MONITORING_BLOTTER_BUTTONS_PORTAL_ID } from '../../tokens';
import type { SettlementMonitoringFilter } from '../../types';
import {
  defaultPositionMonitoringByAssetColumns,
  defaultPositionMonitoringFilter,
  defaultPositionMonitoringRowGroupsOpened,
} from '../defaults';
import type { PositionMonitoringBlotterConfig, PositionMonitoringColumn } from '../types';
import { usePositionMonitoringBaseObs } from './../usePositionMonitoringBaseObs';
import { usePositionMonitoringByAssetColumns } from './usePositionMonitoringByAssetColumns';

export interface PositionMonitoringByAssetBlotterParams {
  blotterID: string;
  tag?: string;
  config: PositionMonitoringBlotterConfig;
  updateConfig: (updatedConfig: PositionMonitoringBlotterConfig) => void;
}

export function PositionMonitoringByAssetBlotter({
  blotterID,
  config,
  updateConfig,
}: PositionMonitoringByAssetBlotterParams) {
  const mixpanel = useMixpanel();

  const defaultBlotterColumns = config.defaultColumns ?? defaultPositionMonitoringByAssetColumns;
  const defaultFilter = config.defaultFilter ?? defaultPositionMonitoringFilter;
  const defaultRowGroupsOpened = config.defaultRowGroupsOpened ?? defaultPositionMonitoringRowGroupsOpened;

  const defaultColumns = usePositionMonitoringByAssetColumns(defaultBlotterColumns);

  const persistedBlotterTable = usePersistedBlotterTable<DealerPosition>(blotterID, {
    columns: defaultColumns,
    sort: `+${AGGRID_AUTOCOLUMN_ID}`,
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const { handleClickJson, jsonModal } = useJsonModal();

  const { filterableProperties } = useSettlementMonitoringFilters();
  const { openClause } = useSettlementMonitoringInteractions();

  const getContextMenuItems: GetContextMenuItems<DealerPosition> = useDynamicCallback(
    (params: GetContextMenuItemsParams) => {
      const items = ['separator', ...getDefaultContextMenuItems(params)];

      const data = params.node?.data;
      if (data != null) {
        items.unshift(getShowJSONContextItem({ params, handleClickJson }));
      }

      if (openClause) {
        items.unshift(
          ...filterByCellValueMenuItem({
            params,
            filterableProperties,
            openClause,
            colIDToFilterBuilderKey,
            mixpanel,
          })
        );
      }

      return compact(items);
    }
  );

  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        editable: false,
        hide: false,
        suppressColumnsToolPanel: false,
        params: {
          suppressCount: true,
        },
      }),
    []
  );

  const { dataObs, totalsDataObs } = usePositionMonitoringBaseObs({ filter: defaultFilter });

  const blotterTable = useBlotterTable({
    dataObservable: dataObs,
    pinnedRowDataObs: totalsDataObs,
    rowID: 'rowID',
    persistence: persistedBlotterTable,
    columns: persistedBlotterTable.columns,
    gridOptions: {
      getContextMenuItems,
      ...persistedRowGroupsOpened.gridOptionsOverlay,
      showOpenedGroup: true,
      groupRemoveLowestSingleChildren: true,
      autoGroupColumnDef,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      groupDisplayType: 'singleColumn',
    },
  });

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  return (
    <>
      <BlotterTableFilters
        {...blotterTable.blotterTableFiltersProps}
        portalId={POSITION_MONITORING_BLOTTER_BUTTONS_PORTAL_ID}
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
          </>
        }
      />

      <BlotterTable {...blotterTable} />
      {jsonModal}
    </>
  );
}

function colIDToFilterBuilderKey(id: string): keyof SettlementMonitoringFilter | undefined {
  switch (id as PositionMonitoringColumn) {
    case 'Asset':
      return 'Symbols';
    case 'MarketAccount':
      return 'MarketAccounts';
    default:
      return undefined;
  }
}

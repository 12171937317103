import {
  ACTION,
  FormControlSizes,
  getShowJSONContextItem,
  IconName,
  LedgerAccountTypeEnum,
  SubAccountReconCheckpointEvalStatusEnum,
  SubAccountReconCheckpointStatusEnum,
  useAssetsContext,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMarketAccountsContext,
  type AgGridIconButtonProps,
  type Column,
  type ColumnDef,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, ICellRendererParams, MenuItemDef } from 'ag-grid-community';
import { compact } from 'lodash-es';
import { useCallback, useMemo, type ReactNode } from 'react';
import { useFeatureFlag, useRoleAuth } from '../../../../../hooks';
import { useSubAccounts } from '../../../../../providers';
import { usePositionDetailsModal } from '../../../../../providers/PositionDetailsModalProvider';
import { ReconAssetRow, ReconMarketAccountRow, type SubAccountReconOverviewRow } from './reconCheckpointRows';

function rowEligibleForDetailsButton(rowData: SubAccountReconOverviewRow | undefined) {
  return rowData instanceof ReconAssetRow;
}

function rowEligibleForResolveButton(rowData: SubAccountReconOverviewRow | undefined) {
  return rowData instanceof ReconAssetRow;
}

function enableResolveButton(rowData: SubAccountReconOverviewRow | undefined) {
  if (!rowEligibleForResolveButton(rowData)) {
    return false;
  }

  if (rowData.Status !== SubAccountReconCheckpointStatusEnum.Unreconciled) {
    return false;
  }

  if (rowData.EvalStatus !== SubAccountReconCheckpointEvalStatusEnum.Done) {
    return false;
  }

  if (rowData.hasBreak) {
    // We're on the checkpoint level here -- we only allow you to click the resolve button if there are no underlying breaks
    // The use case here is that this is like a catch-all. All the underlying matches are resolved, but something has probably gone wrong,
    // so in this case we allow the user to resolve the top-level checkpoint
    return false;
  }

  return true;
}

export function useSubAccountReconOverviewBlotterMenu({
  onDetailsClicked,
  onResolveClicked,
  isInModal,
}: {
  onDetailsClicked?: (assetRow: ReconAssetRow) => void;
  onResolveClicked?: (assetRow: ReconAssetRow) => void;
  /** Whether or not the blotter is being rendered within a modal. We do some special stuff. */
  isInModal?: boolean;
}): {
  columns: Column[];
  getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} {
  const { handleClickJson, jsonModal } = useJsonModal();
  const defaultContextMenuItems = useGetDefaultContextMenuItems();
  const { getPositionDetailItems } = useGetPositionDetailItems();

  const { isAuthorized } = useRoleAuth();
  const isAuthorizedToResolveBreaks = isAuthorized(ACTION.EDIT_PORTFOLIO_RECON);

  const showResolveActionColumn = isAuthorizedToResolveBreaks && onResolveClicked != null;
  const showDetailsActionColumn = onDetailsClicked != null;

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const data = params?.node?.data;

    const resolveBreakItem =
      showResolveActionColumn && enableResolveButton(data)
        ? {
            name: 'Adjust',
            action: () => onResolveClicked?.(data),
            icon: `<i class="ag-icon ${IconName.CheckCircle}"/>`,
          }
        : undefined;

    const detailsItem =
      rowEligibleForDetailsButton(data) && onDetailsClicked
        ? {
            name: 'Open Details',
            action: () => onDetailsClicked?.(data),
            icon: `<i class="ag-icon ${IconName.Deepdive}"/>`,
          }
        : undefined;

    const showJsonItem = data ? getShowJSONContextItem({ params, handleClickJson }) : undefined;

    // Position details is a modal itself and we dont wanna do double modal for now
    const positionDetailItems = !isInModal ? getPositionDetailItems(params) : [];

    const items = compact([
      detailsItem,
      resolveBreakItem,
      'separator',
      showJsonItem,
      'separator',
      ...defaultContextMenuItems(params),
      'separator',
      ...positionDetailItems,
    ]);
    return items;
  });

  const dialogs = useMemo(() => <>{jsonModal}</>, [jsonModal]);

  const columns = useMemo(
    () =>
      compact([
        showResolveActionColumn && {
          type: 'button',
          id: 'resolve-checkpoint-column',
          suppressColumnsToolPanel: true,
          pinned: 'right',
          frozen: true,
          width: 70,
          params: {
            hide: (data: SubAccountReconOverviewRow | undefined) => {
              return !rowEligibleForResolveButton(data);
            },
            onClick: (params: ICellRendererParams<SubAccountReconOverviewRow>) => {
              if (params.node.data instanceof ReconAssetRow) {
                onResolveClicked(params.node.data);
              }
            },
            disabled: params => {
              const data: SubAccountReconOverviewRow | undefined = params.node.data;
              return !enableResolveButton(data);
            },
            size: FormControlSizes.Small,
            children: 'Adjust',
          },
        },
        showDetailsActionColumn && {
          type: 'iconButton',
          id: 'open-details',
          frozen: true,
          pinned: 'right',
          suppressColumnsToolPanel: true,
          width: 45,
          params: {
            icon: IconName.Deepdive,
            hide: params => {
              const data: SubAccountReconOverviewRow | undefined = params.node.data;
              return !rowEligibleForDetailsButton(data);
            },
            onClick: params => {
              const data: ReconAssetRow | undefined = params.node.data;
              if (data) {
                onDetailsClicked(data);
              }
            },
          } satisfies AgGridIconButtonProps,
        },
      ]) satisfies ColumnDef<SubAccountReconOverviewRow>[],
    [showResolveActionColumn, showDetailsActionColumn, onResolveClicked, onDetailsClicked]
  );

  return {
    columns,
    getContextMenuItems,
    dialogs,
  };
}

/**
 * This hook pulls all the strings together to return a function which maps from a data row in the Checkpoints blotter to
 * an array of MenuItemDef which when clicked open a Position History modal.
 *
 * Any row in the blotter can be passed to the function, it'll handle it.
 */
const useGetPositionDetailItems = () => {
  const { subAccountsByName } = useSubAccounts();
  const { marketAccountsByName } = useMarketAccountsContext();
  const { getAssetDisplaySymbol } = useAssetsContext();
  const { open: openPositionDetailsModal } = usePositionDetailsModal();
  const { enableAccountLedgerEventsPage } = useFeatureFlag();

  const getSubAccountPositionDetailItems = useCallback(
    (row: SubAccountReconOverviewRow): MenuItemDef[] => {
      if (row instanceof ReconAssetRow) {
        return row.SubAccounts.map(sa => ({
          name: `Position history (${subAccountsByName.get(sa)?.DisplayName ?? sa} - ${getAssetDisplaySymbol(
            row.Asset
          )})`,
          action: () =>
            openPositionDetailsModal({ account: sa, asset: row.Asset, accountType: LedgerAccountTypeEnum.SubAccount }),
          icon: `<i class="ag-icon ${IconName.ViewListDrilldown}"/>`,
        }));
      }

      return [];
    },
    [subAccountsByName, getAssetDisplaySymbol, openPositionDetailsModal]
  );

  const getMarketAccountPositionDetailItem = useCallback(
    (mktAcc: string, asset: string): MenuItemDef => {
      return {
        name: `Position history (${marketAccountsByName.get(mktAcc)?.DisplayName ?? mktAcc} - ${getAssetDisplaySymbol(
          asset
        )})`,
        action: () =>
          openPositionDetailsModal({ account: mktAcc, asset, accountType: LedgerAccountTypeEnum.MarketAccount }),
        icon: `<i class="ag-icon ${IconName.ViewListDrilldown}"/>`,
      };
    },
    [marketAccountsByName, getAssetDisplaySymbol, openPositionDetailsModal]
  );

  const getPositionDetailItems = useCallback(
    (params: GetContextMenuItemsParams<SubAccountReconOverviewRow>): MenuItemDef[] => {
      if (!enableAccountLedgerEventsPage) {
        return [];
      }

      const row = params.node?.data;
      if (row == null) {
        return [];
      }

      // we only show the menu item defs if you clicked on a sub account amount cell or market account amount cell respectively,
      // and then we show the correct items respectively too of course
      // not perfect type checking on the column ids but good enough for me given that this'll have cypress tests too
      const rightClickedSubAccAmount = params.column?.getColId() === ('SubAccountAmount' satisfies keyof ReconAssetRow);
      const rightClickedMktAccAmount =
        params.column?.getColId() === ('MarketAccountAmount' satisfies keyof ReconMarketAccountRow);

      if (rightClickedSubAccAmount && row instanceof ReconAssetRow) {
        return getSubAccountPositionDetailItems(row);
      }

      if (rightClickedMktAccAmount) {
        if (row instanceof ReconAssetRow) {
          // In the asset row, there is one aggregated Market account position. Show one menu item for each underlying market account
          return (
            row.MarketAccountDetails?.MarketAccounts.flatMap(mktAcc =>
              getMarketAccountPositionDetailItem(mktAcc.MarketAccount, row.Asset)
            ) ?? []
          );
        }

        if (row instanceof ReconMarketAccountRow) {
          return [getMarketAccountPositionDetailItem(row.MarketAccount, row.Asset)];
        }
      }

      return [];
    },
    [enableAccountLedgerEventsPage, getSubAccountPositionDetailItems, getMarketAccountPositionDetailItem]
  );

  return { getPositionDetailItems };
};

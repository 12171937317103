import { useCallback, useInsertionEffect, useRef } from 'react';

/**
 * Provides a stable function that will always call the latest version of the provided callback.
 * @param callback The callback to be used
 * @returns A callback that will always call the latest version of the input callback
 *
 * IMPORTANT: Do not abuse the use of this hook; it's only intended for use in scenarios where you need a stable callback
 * and it could not be easily achieved with a standard `useCallback` .  Please include a comment
 * alongside the usage as to why it is necessary, for the benefit of future maintainers.
 *
 * Implementation is based on Chakra-UI: https://github.com/chakra-ui/chakra-ui/blob/14d022046ebd2e6acfb7996c1bdeae18d65a4330/packages/react/src/hooks/use-callback-ref.ts
 * - modified for TypeScript support similar to useCallback
 * - This is a more idiomatic React-ish way to do this than useDynamicCallback, as it uses `useInsertionEffect` to update the ref, which performs
 * the useEffect soon after the render phase, before any other effects can fire and use it
 * */
export function useCallbackRef<T extends (...args: never[]) => unknown>(callback?: T): T {
  const callbackRef = useRef<T | undefined | (() => never)>(() => {
    throw new Error('Cannot call an event handler while rendering.');
  });

  useInsertionEffect(() => {
    callbackRef.current = callback;
  });

  // @ts-expect-error Weird type-checking issue here, should probably find a way to fix that yet
  return useCallback<T>((...args) => callbackRef.current?.(...args), []);
}

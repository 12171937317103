// Based on addStyles from Dockview, packages/dockview-core/src/dom.ts
// Could be rewritten to use CSSOM with new CSSStyleSheet() and CSSStyleSheet.insertRule() methods

/**
 * Add all the styles from the provided styleSheetList to the document
 * @param targetDocument
 * @param styleSheetList
 * @param targetId
 */
export function addStyles(targetDocument: Document, styleSheetList: StyleSheetList, wrapperId: string | undefined) {
  const target = wrapperId
    ? targetDocument.getElementById(wrapperId) ?? targetDocument.createElement('div')
    : targetDocument.head;
  if (wrapperId) {
    target.id = wrapperId;
    targetDocument.body.appendChild(target);
  }
  const styleSheets = Array.from(styleSheetList).filter(
    styleSheet => wrapperId == null || styleSheet.ownerNode?.parentElement?.id === wrapperId
  );

  for (const styleSheet of styleSheets) {
    if (styleSheet.href) {
      const link = targetDocument.createElement('link');
      link.href = styleSheet.href;
      link.type = styleSheet.type;
      link.rel = 'stylesheet';
      target.appendChild(link);
    }

    let cssTexts: string[] = [];

    try {
      if (styleSheet.cssRules) {
        cssTexts = Array.from(styleSheet.cssRules).map(rule => rule.cssText);
      }
    } catch (err) {
      // security errors (lack of permissions), ignore
    }
    if (cssTexts.length > 0) {
      const style = targetDocument.createElement('style');
      for (const rule of cssTexts) {
        style.appendChild(targetDocument.createTextNode(rule));
      }
      target.appendChild(style);
    }
  }
}

import { isEqual } from 'lodash-es';
import { memo, type CSSProperties, type ReactNode } from 'react';
import styled from 'styled-components';
import * as PopoverStyles from '../../components/Popover/styles';
import { Tooltip, type TooltipProps } from '../../components/Tooltip';
import { parseIconSize } from '../../styles';
import { Icon } from './Icon';
import { IconName } from './IconNames';

export const HelpIconTrigger = styled(Icon)`
  cursor: help;
`;

export const HelpIconWrapper = styled.div<{ size?: string | number }>`
  display: inline-block;
  vertical-align: middle;
  font-size: ${({ size, theme }) => size && parseIconSize(theme, size)};

  ${PopoverStyles.Target} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${PopoverStyles.Content} {
    max-width: 25rem;
  }
`;

export const HelpIcon = memo<{
  tooltip?: ReactNode;
  style?: CSSProperties;
  size?: string | number;
  tabIndex?: number;
  usePortal?: boolean;
  onOpen?: () => void;
  placement?: TooltipProps['placement'];
}>(
  function HelpIcon({ tooltip, style, tabIndex = -1, usePortal, onOpen, placement = 'top', ...props }) {
    return (
      <HelpIconWrapper tabIndex={tabIndex} {...props}>
        <Tooltip onOpen={onOpen} tooltip={tooltip} preventOverflow={false} usePortal={usePortal} placement={placement}>
          <HelpIconTrigger style={style} icon={IconName.QuestionMarkCircle} />
        </Tooltip>
      </HelpIconWrapper>
    );
  },
  (p, n) => isEqual(p, n)
);

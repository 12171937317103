import Big from 'big.js';
import { compact } from 'lodash-es';
import { useMemo } from 'react';
import type { BuyingPower, SideEnum } from '../../types';
import { toBigWithDefault } from '../../utils';

export interface BuyingPowerWarning {
  account: string;
  excess: Big;
}

interface GetBuyingPowerWarningsParams {
  /** The list of accounts to check for warnings on */
  accounts: string[];
  /** A Map of account -> BuyingPower. This is what the useBuyingPower hook returns. */
  buyingPowerByAccount: Map<string, BuyingPower>;

  useMaxOrderSize?: boolean;
  side: SideEnum;
  quantity: Big;
}

/** Given a few parameters, produces some warnings for you to display */
export function getBuyingPowerWarnings({
  accounts,
  buyingPowerByAccount,
  useMaxOrderSize,
  side,
  quantity,
}: GetBuyingPowerWarningsParams) {
  const totalBuyingPower = accounts.reduce((total, account) => {
    const bp = buyingPowerByAccount?.get(account);
    return total.plus(toBigWithDefault(bp?.getMaxSize({ useMaxOrderSize, side }), 0));
  }, Big(0));

  const totalExcess = quantity.minus(totalBuyingPower);

  const warnings = compact(
    accounts.map(account => {
      const bp = toBigWithDefault(buyingPowerByAccount?.get(account)?.getMaxSize({ useMaxOrderSize, side }), 0);
      const showExcessWarning = quantity.gt(bp);
      if (!showExcessWarning) {
        return undefined;
      }

      const excess = quantity.minus(bp);
      return { account, excess };
    })
  );

  return {
    warnings,
    totalBuyingPower,
    totalWarning: totalExcess.lte(0) ? undefined : { account: 'Total', excess: totalExcess },
  };
}

/** Computes if the provided quantity is in breach of the provided BuyingPower and if so returns a BuyingPowerWarning object with some details */
export function getBuyingPowerWarning({
  buyingPower,
  useMaxOrderSize,
  side,
  quantity,
}: {
  /** The BuyingPower class to check for warnings within (relative to the quantity provided) */
  buyingPower: BuyingPower;
  useMaxOrderSize?: boolean;
  side: SideEnum;
  quantity: Big;
}): BuyingPowerWarning | undefined {
  const max = toBigWithDefault(buyingPower.getMaxSize({ useMaxOrderSize, side }), 0);
  const warn = quantity.gt(max);
  const excess = quantity.minus(max);
  return warn
    ? {
        account: buyingPower.MarketAccount,
        excess: excess,
      }
    : undefined;
}

type UseBuyingPowerWarningParams = GetBuyingPowerWarningsParams;

export const useBuyingPowerWarnings = ({
  accounts,
  buyingPowerByAccount,
  useMaxOrderSize,
  side,
  quantity,
}: UseBuyingPowerWarningParams) => {
  return useMemo(
    () => getBuyingPowerWarnings({ accounts, buyingPowerByAccount, useMaxOrderSize, side, quantity }),
    [accounts, buyingPowerByAccount, useMaxOrderSize, side, quantity]
  );
};

import { useState } from 'react';
import type { useDisclosureWithContext } from '../../../hooks';
import { VStack } from '../../Core';
import { Dialog } from '../../Dialog';
import { FormGroup, Input } from '../../Form';
import { Text } from '../../Text';

export interface EntityAdminConfirmationDialogContext {
  ConfirmReason: string;
  RequiresConfirmText: boolean;
  ConfirmAction: () => Promise<any>;
}

export const EntityAdminConfirmationDialog = ({
  context,
  ...dialog
}: Pick<
  ReturnType<typeof useDisclosureWithContext<EntityAdminConfirmationDialogContext>>,
  'context' | 'isOpen' | 'close'
>) => {
  const [confirmText, setConfirmText] = useState('');
  return (
    <Dialog
      {...dialog}
      confirmDisabled={context?.RequiresConfirmText && confirmText !== 'confirm'}
      dataTestId="entity-admin-confirmation-dialog"
      confirmLabel="Confirm"
      onConfirm={context?.ConfirmAction}
      onCancel={() => setConfirmText('')}
    >
      <VStack alignItems="flex-start" gap="spacingDefault">
        <Text color="colorTextImportant" mr="spacingHuge">
          {context?.ConfirmReason}
        </Text>
        {context?.RequiresConfirmText && (
          <FormGroup label={`To continue, type "confirm" below:`} mb="0" w="100%">
            <Input
              value={confirmText}
              onChange={e => setConfirmText(e.target.value)}
              data-testid="dialog-confirm-input"
            />
          </FormGroup>
        )}
      </VStack>
    </Dialog>
  );
};

import { useCallback, useRef } from 'react';
import { defineMessages } from 'react-intl';
import type { IntlWithFormatter } from '../../contexts/IntlContext';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { useDynamicCallback, useIntl } from '../../hooks';
import { MixpanelEvent } from '../../tokens';
import { getTypedKeys } from '../../utils';
import {
  BaseSelect,
  CheckboxFuseAutocompleteDropdown,
  FormControlSizes,
  Input,
  useDropdownPopper,
  useMultiSelectAutocomplete,
} from '../Form';
import { IndicatorBadge } from '../IndicatorBadge';
import { TimelineStatusEnum } from './types';

const messages = defineMessages({
  allEvents: {
    defaultMessage: 'All Events',
    id: 'ExecutionTimeline.allEvents',
  },
  filterOptions: {
    defaultMessage: 'Filter options',
    id: 'ExecutionTimeline.filterOptions',
  },
});

interface ExecutionTimelineFiltersProps {
  filters: string[];
  setTimelineFilters: (filters: string[]) => void;
}

const MAX_VISIBLE_FILTERS = 3;

const items = getTypedKeys(TimelineStatusEnum);
const getOptionLabel = (option: string) => TimelineStatusEnum[option as keyof typeof TimelineStatusEnum];

function getSelectionLabelWithIntl(selections: string[], intl: IntlWithFormatter) {
  if (selections.length === 0) {
    return intl.formatMessage(messages.allEvents);
  }
  return selections
    .slice(0, MAX_VISIBLE_FILTERS)
    .map(filter => getOptionLabel(filter))
    .join(', ');
}

export const ExecutionTimelineFilters = ({ filters, setTimelineFilters }: ExecutionTimelineFiltersProps) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const mixpanel = useMixpanel();
  const { formatMessage } = useIntl();

  const getSelectionLabel = useCallback(
    (selections: string[]) => {
      return getSelectionLabelWithIntl(selections, intl);
    },
    [intl]
  );

  const handleOnChange = useDynamicCallback((newSelections: string[]) => {
    mixpanel.track(MixpanelEvent.ChangeTimelineFilter);
    setTimelineFilters(newSelections);
  });

  const { autocompleteOutput, multipleSelectionOutput } = useMultiSelectAutocomplete({
    selections: filters,
    items,
    getLabel: getOptionLabel,
    initialSortByLabel: false,
    onChange: handleOnChange,
    clearInputAfterSelection: false,
    removeItemOnInputBackspace: false,
    highlightInputTextAfterSelection: true,
    inputRef,
  });

  const { isOpen, openMenu, getInputProps, closeMenu } = autocompleteOutput;
  const { getDropdownProps } = multipleSelectionOutput;

  const handleClickOutside = useCallback(() => closeMenu(), [closeMenu]);

  const dropdownRef = useRef<HTMLLabelElement>(null);
  const dropdownPopper = useDropdownPopper({
    isOpen,
    referenceElement: dropdownRef.current,
    dropdownWidth: '240px',
    dropdownPlacement: 'bottom-end',
    onClickOutside: handleClickOutside,
  });

  const handleSelectClick = useCallback(() => {
    openMenu();
    if (!isOpen) {
      setTimeout(() => inputRef.current?.focus());
    }
  }, [isOpen, openMenu]);

  return (
    <div>
      <BaseSelect
        isDropdownOpened={isOpen}
        wrapperRef={dropdownRef}
        size={FormControlSizes.Small}
        onClick={handleSelectClick}
        value={filters}
        getLabel={getSelectionLabel}
        suffix={
          filters.length > MAX_VISIBLE_FILTERS && (
            <IndicatorBadge ml="spacingSmall" children={`+${filters.length - MAX_VISIBLE_FILTERS}`} />
          )
        }
      />

      <CheckboxFuseAutocompleteDropdown
        {...autocompleteOutput}
        {...dropdownPopper}
        selectedItems={multipleSelectionOutput.selectedItems}
        addSelectedItem={multipleSelectionOutput.addSelectedItem}
        removeSelectedItem={multipleSelectionOutput.removeSelectedItem}
        portalize={true}
        childrenAboveResults={
          <Input
            {...getInputProps(getDropdownProps({ ref: inputRef }))}
            placeholder={formatMessage(messages.filterOptions)}
          />
        }
      />
    </div>
  );
};

import { prettyName } from '../../../utils';
import { text } from './text';
import type { ColDefFactory, Column, TextColumnParams } from './types';

/**
 * Creates a column definition for a text column with a pretty name formatter.
 * @param {Column<TextColumnParams>} column - The column configuration.
 * @returns {ColDefFactory<Column<TextColumnParams>>} - The column definition with a pretty name formatter.
 */
export const prettyText: ColDefFactory<Column<TextColumnParams>> = column => ({
  ...text(column),
  valueFormatter: params => prettyName(params.value),
});

import { isObject } from 'lodash-es';
import type { UpdateActionEnum } from './types';

export interface WebsocketError {
  code: number;
  msg: string;
}

export interface ErrorSubscriptionResponse {
  reqid?: number;
  type: 'error';
  ts: string;
  seq?: number;
  error?: WebsocketError;
}

export const isErrorSubscriptionResponse = (res: unknown): res is ErrorSubscriptionResponse => {
  return isObject(res) && 'type' in res && res.type === 'error' && 'ts' in res;
};

/** Standard websocket response message format used in our services */
export interface SubscriptionResponse<TData = any, TType extends string = string> {
  reqid?: number;
  type: TType;
  tag?: string;
  ts: string;
  action?: UpdateActionEnum;
  // note: data will be undefined if this subscription is not supported by an older backend!
  // we should change this to "data?" just as reminder to developers to think about and handle this possibility
  data: TData[];
  seq?: number;
  error?: WebsocketError;

  /** initial message of the requested stream
   * - initial messages (based on service) may support pagination, leveraging the **next** and **page** properties
   * - all subsequent messages past the initial (single message or paginated group) are considered delta messages and do not support pagination
   */
  initial?: boolean;
  /** indicates there's more to load on the initial stream message
   * - value indicates the next key pointer from the results; to the ui any non-null/undefined value represents 'true'
   * - if page is true and next is false, it indicates the last page of the initial stream message
   */
  next?: string;
  /** indicates a **non-starting** page of the initial stream message.
   */
  page?: boolean;
}

/**
 * A SubscriptionResponse type which only requires you to provide the minimal necessary fields; namely data.
 */
export type MinimalSubscriptionResponse<TData = any, TType extends string = string> = Partial<
  SubscriptionResponse<TData, TType>
> &
  Pick<SubscriptionResponse<TData, TType>, 'data'>;

import type { CreditBlotterExposure, Leaves, LedgerAccountTypeEnum } from '@talos/kyoko';
import { toBigWithDefault } from '@talos/kyoko';
import Big from 'big.js';
import { UnifiedPosition } from '../../Blotters/PositionsV3/Unified/UnifiedPosition';

export type OperationsOverviewShowBy = 'Market' | 'MarketAccount';

export interface OpsOverviewFilter {
  Symbols?: string[];
}

export const SHOW_BY_DICT = {
  Market: { showBy: 'Market', label: 'By Market', key: 'market' },
  MarketAccount: { showBy: 'MarketAccount', label: 'By Market Account', key: 'MarketAccount' },
} as const satisfies {
  [key in OperationsOverviewShowBy]: { showBy: key; label: string; key: Leaves<OpsPosition> };
};

export class OpsPosition extends UnifiedPosition {
  marketAccountTotal: Big;
  // override market to always be defined
  override market: string;

  get shareOfMarketAccountTotal(): Big | undefined {
    if (this.marketAccountTotal.eq(0)) {
      return undefined;
    }

    const positionBig = toBigWithDefault(this.Equivalent?.Amount, 0);
    if (positionBig.eq(0)) {
      return Big(0);
    }

    return positionBig.div(this.marketAccountTotal);
  }

  public metric?: IMarginPositionMetric;
  public exposure?: CreditBlotterExposure;

  constructor(up: UnifiedPosition, marketAccountTotal: Big, market: string) {
    super({
      position: up,
      metadata: {
        underlying: up.underlying,
        marketAccountName: up.MarketAccount,
        marketName: up.market,
        marketAccountGroup: up.marketAccountGroup,
      },
    });

    this.marketAccountTotal = marketAccountTotal;
    this.market = market;
    this.metric = undefined;
  }
}

export class OpsAccountPosition {
  metric: IMarginPortfolioMetric;
  exposure?: CreditBlotterExposure;

  constructor(metric: IMarginPortfolioMetric) {
    this.metric = metric;
  }
}

export interface IMarginPositionMetric {
  Timestamp: string;
  Revision: number;
  ID: string;

  AccountName: string;
  AccountType: LedgerAccountTypeEnum;
  Asset: string;
  EquivalentCurrency?: string;
  NetLiquidationValue?: string;
  DistanceToLiquidation?: string;
  DistanceToLiquidationCurrency?: string;
  DistanceToLiquidationPct?: string;
  LeverageRatio?: string;
  TransactTime?: string;

  EquityContribution?: IEquityContribution;
  Equivalent?: {
    Currency: string;
    NetLiquidationValue?: string;
    EquityContribution?: {
      Balance?: string;
      Equity?: string;
      Pnl?: string;
    };
  };
}

export interface IEquityContribution {
  Balance?: string;
  BalanceCurrency?: string;
  Equity?: string;
  EquityCurrency?: string;
  Pnl?: string;
  PnlCurrency?: string;
}

export interface IMarginPortfolioMetric {
  ID: string;
  TransactTime: string;
  AccountType: LedgerAccountTypeEnum;
  AccountName: string;

  ValueCurrency?: string;
  EquivalentCurrency?: string;
  InitialMargin?: string;
  NetLiquidationValue?: string;
  NetDeltaExposure?: string;
  GrossDeltaExposure?: string;
  EquityMarginRatio?: string;
  NetLeverageRatio?: string;
  GrossLeverageRatio?: string;
  PositionsAtRisk?: number;
  PositionValueAtRisk?: string;
  RiskScore?: string;

  EquityContribution?: IEquityContribution;
  Equivalent?: {
    InitialMargin?: string;
    NetLiquidationValue?: string;
    NetDeltaExposure?: string;
    Currency: string;
    EquityContribution?: {
      Balance?: string;
      Equity?: string;
      Pnl?: string;
    };
  };
}

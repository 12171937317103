import { PUT, request, useUserContext } from '@talos/kyoko';
import { useCallback } from 'react';

interface Balance {
  AccountID: string | number;
  Currency: string;
  Amount: string;
}

export const useBalancesRequests = () => {
  const { orgApiEndpoint } = useUserContext();
  const endpoint = `${orgApiEndpoint}/balances`;
  const updateBalance = useCallback((balance: Balance) => request(PUT, endpoint, balance), [endpoint]);
  return { updateBalance };
};

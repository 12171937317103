import {
  NotificationVariants,
  SUB_ACCOUNT_PERMISSION_FILTER,
  UpdateActionEnum,
  request,
  useEndpointsContext,
  useGlobalToasts,
  useMarketAccountsContext,
  useObservable,
  useObservableValue,
  useStaticSubscription,
  type ISubAccountApiPermissionFilter,
  type SubAccountApiPermissionFilter,
  type SubscriptionResponse,
  type User,
} from '@talos/kyoko';
import { createContext, useCallback, useContext, useMemo, useRef, type PropsWithChildren } from 'react';
import { Subject, merge, scan } from 'rxjs';

export const SubAccountPermissionFiltersContext = createContext<SubAccountPermissionFiltersContextProps | undefined>(
  undefined
);
SubAccountPermissionFiltersContext.displayName = 'SubAccountPermissionFiltersProvider';

export type SubAccountPermissionFiltersContextProps = {
  filterIDsBySubAccountName: Map<string, Set<string>> | undefined;
  filterIDsByUserName: Map<string, Set<string>> | undefined;
  subAccountPermissionFiltersByFilterID: Map<string, SubAccountApiPermissionFilter> | undefined;
  updateSubAccountPermissionFilters: (
    updatedPermissions: SubAccountApiPermissionFilter[],
    reason?: string
  ) => Promise<void>;
};

export type UserSubAccountPermissionRow = {
  User: Pick<User, 'Roles'>;
  FilterPermission: SubAccountApiPermissionFilter;
};

export function useSubAccountPermissionFilters() {
  const context = useContext(SubAccountPermissionFiltersContext);
  if (context === undefined) {
    throw new Error(
      'Missing SubAccountPermissionFiltersContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

export const SubAccountPermissionFiltersProvider = function ApiPermissionFilterProvider({
  children,
}: PropsWithChildren) {
  const { data: subAccountPermissionsFilterRawObs } = useStaticSubscription<ISubAccountApiPermissionFilter>({
    name: SUB_ACCOUNT_PERMISSION_FILTER,
    tag: 'SubAccountPermissionFiltersProvider',
  });

  const subAccountPermissionsFilterUpdatesSubject = useRef(
    new Subject<SubscriptionResponse<SubAccountApiPermissionFilter, string>>()
  );
  const subAccountPermissionsFilterUpdatesObs = useObservable<
    SubscriptionResponse<SubAccountApiPermissionFilter, string>
  >(() => subAccountPermissionsFilterUpdatesSubject.current.asObservable(), []);

  const { orgApiEndpoint } = useEndpointsContext();
  const { marketAccountDisplayNameByName } = useMarketAccountsContext();
  const { add: addToast } = useGlobalToasts();

  const updateSubAccountPermissionFilters = useCallback(
    async (updatedPermissions: SubAccountApiPermissionFilter[], reason?: string) => {
      if (orgApiEndpoint) {
        // The api is weird, updates are not really updates, rather they are additions with removals.
        // So for all updates that we do, we're not actually editing the filter, an update to filterPerm1 is:
        // DELETE filterPerm1
        // POST filterPerm1 (with your changes) and receive back filterPerm2
        const deletions = updatedPermissions
          .filter(uP => uP.UpdateAction === UpdateActionEnum.Remove || uP.UpdateAction === UpdateActionEnum.Update)
          .map(uP => ({ ...uP, UpdateAction: UpdateActionEnum.Remove }));
        const updates = updatedPermissions.filter(uP => uP.UpdateAction !== UpdateActionEnum.Remove);

        // Send deletions
        const attemptedDeletions = await Promise.all(
          deletions.map(async apiFilter =>
            request('DELETE', `${orgApiEndpoint}/permission-filters/subaccounts/${apiFilter.FilterID}`, {
              Comments: reason,
            })
              .then((res: '') => ({ status: 'fulfilled' as const, data: apiFilter }))
              .catch((r: undefined) => ({ status: 'rejected' as const, data: apiFilter }))
          )
        );

        const successfulDeletions = attemptedDeletions
          .filter(settledRes => settledRes.status === 'fulfilled')
          .map(settledRes => settledRes.data);
        const failedDeletions = attemptedDeletions
          .filter(settledRes => settledRes.status === 'rejected')
          .map(settledRes => settledRes.data);

        // Send updates
        const attemptedUpdates = await Promise.all(
          updates.map(async apiFilter =>
            request('POST', `${orgApiEndpoint}/permission-filters/subaccounts`, {
              Action: apiFilter.Action,
              Filter: apiFilter.Filter,
              Subject: apiFilter.Subject,
              Comments: reason,
            })
              .then((res: { data: SubAccountApiPermissionFilter[] }) => ({
                status: 'fulfilled' as const,
                data: res.data[0],
              }))
              .catch((r: undefined) => ({ status: 'rejected' as const, data: apiFilter }))
          )
        );

        const successfulUpdates = attemptedUpdates
          .filter(settledRes => settledRes.status === 'fulfilled')
          .map(settledRes => settledRes.data);
        const failedUpdates = attemptedUpdates
          .filter(settledRes => settledRes.status === 'rejected')
          .map(settledRes => settledRes.data);

        for (const failedDeletion of failedDeletions) {
          const subAccountName =
            marketAccountDisplayNameByName.get(failedDeletion.Filter.SubAccount) || failedDeletion.Filter.SubAccount;
          addToast({
            text: `Failed to delete permission for user: "${failedDeletion.Subject.User}" to Sub Account: "${subAccountName}"`,
            variant: NotificationVariants.Negative,
          });
        }

        for (const failedUpdate of failedUpdates) {
          const subAccountName =
            marketAccountDisplayNameByName.get(failedUpdate.Filter.SubAccount) || failedUpdate.Filter.SubAccount;
          addToast({
            text: `Failed to update permission for user: "${failedUpdate.Subject.User}" to Sub Account: "${subAccountName}"`,
            variant: NotificationVariants.Negative,
          });
        }

        const flatUpdates = successfulDeletions.concat(successfulUpdates);
        subAccountPermissionsFilterUpdatesSubject.current.next({
          type: '',
          tag: 'REST_RESPONSE',
          ts: '',
          data: flatUpdates,
        });
      }
    },
    [addToast, marketAccountDisplayNameByName, orgApiEndpoint]
  );

  const subAccountPermissionFilters = useObservableValue(
    () =>
      merge(subAccountPermissionsFilterRawObs, subAccountPermissionsFilterUpdatesObs).pipe(
        scan(
          ({ subAccountPermissionFiltersByFilterID, filterIDsBySubAccountName, filterIDsByUserName }, json) => {
            if (json.initial) {
              subAccountPermissionFiltersByFilterID.clear();
            }

            const updatedSubAccountsSet = new Set<string>();
            const updatedUserNameSet = new Set<string>();

            for (const data of json.data) {
              // per filterID
              const key = data.FilterID;
              if (key) {
                if (data.UpdateAction === UpdateActionEnum.Remove) {
                  subAccountPermissionFiltersByFilterID.delete(key);
                } else {
                  subAccountPermissionFiltersByFilterID.set(key, data);
                }
              }

              const subaccountFilter = data.Filter.SubAccount;
              if (subaccountFilter) {
                updatedSubAccountsSet.add(subaccountFilter);
                if (!filterIDsBySubAccountName.has(subaccountFilter)) {
                  filterIDsBySubAccountName.set(subaccountFilter, new Set());
                }
                const subAccountSet = filterIDsBySubAccountName.get(subaccountFilter)!;
                if (data.UpdateAction === UpdateActionEnum.Remove) {
                  subAccountSet.delete(data.FilterID);
                } else {
                  subAccountSet.add(data.FilterID);
                }
              }
              const user = data.Subject.User;
              if (user) {
                updatedUserNameSet.add(user);
                if (!filterIDsByUserName.has(user)) {
                  filterIDsByUserName.set(user, new Set());
                }
                const userSet = filterIDsByUserName.get(user)!;
                if (data.UpdateAction === UpdateActionEnum.Remove) {
                  userSet.delete(data.FilterID);
                } else {
                  userSet.add(data.FilterID);
                }
              }
            }

            // ensure new reference of affected sets
            for (const subaccount of Array.from(updatedSubAccountsSet)) {
              filterIDsBySubAccountName.set(subaccount, new Set(filterIDsBySubAccountName.get(subaccount)));
            }

            // ensure new reference of affected sets
            for (const user of Array.from(updatedUserNameSet)) {
              filterIDsByUserName.set(user, new Set(filterIDsByUserName.get(user)));
            }

            return {
              // This is stable, since we're only extracting data from it via dynamic sets.
              subAccountPermissionFiltersByFilterID,
              filterIDsBySubAccountName: new Map(filterIDsBySubAccountName),
              filterIDsByUserName: new Map(filterIDsByUserName),
            };
          },
          {
            subAccountPermissionFiltersByFilterID: new Map<string, SubAccountApiPermissionFilter>(),
            filterIDsBySubAccountName: new Map<string, Set<string>>(),
            filterIDsByUserName: new Map<string, Set<string>>(),
          }
        )
      ),
    [subAccountPermissionsFilterRawObs, subAccountPermissionsFilterUpdatesObs],
    {
      subAccountPermissionFiltersByFilterID: new Map<string, SubAccountApiPermissionFilter>(),
      filterIDsBySubAccountName: new Map<string, Set<string>>(),
      filterIDsByUserName: new Map<string, Set<string>>(),
    }
  );

  const { filterIDsBySubAccountName, filterIDsByUserName, subAccountPermissionFiltersByFilterID } =
    subAccountPermissionFilters;

  const value = useMemo(
    () => ({
      filterIDsBySubAccountName,
      filterIDsByUserName,
      subAccountPermissionFiltersByFilterID,
      updateSubAccountPermissionFilters,
    }),
    [
      filterIDsBySubAccountName,
      filterIDsByUserName,
      subAccountPermissionFiltersByFilterID,
      updateSubAccountPermissionFilters,
    ]
  );

  return (
    <SubAccountPermissionFiltersContext.Provider value={value}>{children}</SubAccountPermissionFiltersContext.Provider>
  );
};

import {
  Button,
  Divider,
  FormControlSizes,
  HStack,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  type PopperHeaderProps,
  Toggle,
  useCallbackRef,
  useDropdownPopper,
  useMixpanel,
} from '@talos/kyoko';
import { useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useMarketSelectorContext } from './MarketSelectorContext';
import type { MarketSelectorPanelProps } from './MarketSelectorPanel';
import { MarketSelectorSettingsButton } from './MarketSelectorSettingsButton';
import type { UseMarketSelectorPanelParams } from './types';
import { getAdditionalPanelWidthFromEnrichments } from './utils/enrichments';

/** This hook pulls together the parameters to pass into the `<MarketSelectorPanel />` component */
export const useMarketSelectorPanel = ({
  isOpen,
  close,
  panelPlacement,
  panelAnchorRef,
  panelOffset,
}: UseMarketSelectorPanelParams) => {
  const mixpanel = useMixpanel();
  const theme = useTheme();
  const navigate = useNavigate();
  const { advancedMode, setAdvancedMode, enrichments, blotterSearchRef } = useMarketSelectorContext();

  // we need to do some calculations to figure out a good width for our dropdown in the different modes
  const dropdownWidth = useMemo(() => {
    if (!advancedMode) {
      return 600; // taken from the designs
    }

    const widthAddedByExtraColumns = getAdditionalPanelWidthFromEnrichments(advancedMode, enrichments);
    return `${500 + widthAddedByExtraColumns}px`; // ui tries to arrive at a good width dynamically
  }, [advancedMode, enrichments]);

  const dropdown = useDropdownPopper({
    isOpen,
    offset: panelOffset,
    referenceElement: panelAnchorRef.current,
    dropdownPlacement: panelPlacement,
    dropdownWidth: dropdownWidth,
    dropdownHeight: '500px',
    onClickOutside: useCallbackRef(close),
  });

  // Whenever the panel is opened, we focus the blotterSearchRef (if any)
  // This lets the user instantly start typing without an additional click after opening the market selector.
  useEffect(() => {
    if (isOpen && blotterSearchRef.current) {
      blotterSearchRef.current.focus();
    }
  }, [isOpen, blotterSearchRef]);

  const showAdvancedModeToggle = enrichments.length > 0;

  const dropdownHeaderProps: PopperHeaderProps = useMemo(() => {
    return {
      icon: IconName.Scale,
      title: 'Market Selector',
      suffix: (
        <HStack gap="spacingDefault">
          {showAdvancedModeToggle && (
            <>
              <Toggle
                size={FormControlSizes.Small}
                checked={advancedMode}
                onChange={checked => {
                  setAdvancedMode(checked);
                  mixpanel.track(MixpanelEvent.ToggleAdvancedMode, { [MixpanelEventProperty.Enabled]: checked });
                }}
                label="Advanced Mode"
                data-testid="market-selector-advanced-mode-toggle"
              />
              <Divider orientation="vertical" color={theme.colors.gray['070']} />
            </>
          )}
          <HStack gap="spacingSmall">
            <Button
              startIcon={IconName.Exit}
              size={FormControlSizes.Tiny}
              onClick={() => navigate('/settings/trading-aggregations')}
            >
              Manage
            </Button>
            <MarketSelectorSettingsButton />
          </HStack>
        </HStack>
      ),
      suffixFlexProps: {
        gap: '0px',
      },
      onCloseClicked: close,
    };
  }, [navigate, advancedMode, setAdvancedMode, showAdvancedModeToggle, theme, close, mixpanel]);

  const dropdownProps: MarketSelectorPanelProps['dropdownProps'] = useMemo(
    () => ({
      ...dropdown,
      headerProps: dropdownHeaderProps,
    }),
    [dropdown, dropdownHeaderProps]
  );

  return dropdownProps;
};

import { useFeatureFlag } from 'hooks';
import { compact } from 'lodash-es';
import { useMemo } from 'react';
import type { Product } from '../types';

/**
 * Get the products the user is able to select in the Performance page.
 */
export const usePerformancePageProducts = () => {
  const { enablePerformancePageDerivatives } = useFeatureFlag();
  const options: Product[] = useMemo(() => {
    return compact([
      'spot',
      enablePerformancePageDerivatives && 'derivatives',
      enablePerformancePageDerivatives && 'all',
    ]);
  }, [enablePerformancePageDerivatives]);

  return options;
};

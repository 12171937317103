import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  primary: {
    lighten: '#008feb',
    default: '#0070b8',
    dim: 'rgba(0,112,184,0.4)',
    mute: 'rgba(0,112,184,0.1)',
  },
  gray: {
    '100': '#ffe',
    '000': '#030304',
    '010': '#0a0a0b',
    main: '#181A22',
    '020': '#25272e',
    '030': '#33353a',
    '040': '#414346',
    '050': '#484a4c',
    '060': '#515355',
    '070': '#616363',
    '080': '#8b8c88',
    '090': '#abaca4',
  },
  green: {
    lighten: '#00e7de',
    default: '#00b4ad',
    dim: 'rgba(0,180,173,0.4)',
    mute: 'rgba(0,180,173,0.1)',
  },
  red: {
    lighten: '#ea3180',
    default: '#d31566',
    dim: 'rgba(211,21,102,0.4)',
    mute: 'rgba(211,21,102,0.1)',
  },
  yellow: {
    lighten: '#fee4a7',
    default: '#fdd475',
    dim: 'rgba(253,212,117,0.4)',
    mute: 'rgba(253,212,117,0.1)',
  },
  orange: {
    lighten: '#ff6c1a',
    default: '#E65200',
    dim: 'rgba(230,82,0,0.4)',
    mute: 'rgba(230,82,0,0.1)',
  },
  blue: {
    lighten: '#61a8f0',
    default: '#338EEB',
    dim: 'rgba(51,142,235,0.4)',
    mute: 'rgba(51,142,235,0.1)',
  },
  purple: {
    lighten: '#a778e2',
    default: '#8B4ED9',
  },
  white: {
    default: '#ffffff',
    dim: 'rgba(255,255,255,0.4)',
    mute: 'rgba(255,255,255,0.1)',
  },
  black: {
    default: '#000000',
    dim: 'rgba(0,0,0,0.4)',
    mute: 'rgba(0,0,0,0.1)',
  },
};

export const DarkArchaxTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkAx',

  colorTextDefaultButton: colors.white.default,
  colorTextDefaultButtonHover: colors.white.default,
  colorTextDefaultButtonFocus: colors.white.default,
  colorTextDefaultButtonActive: colors.white.default,
  colorTextDefaultButtonDisabled: setAlpha(0.5, colors.white.default),

  colorTextPositiveButton: colors.black.default,
  colorTextPositiveButtonHover: colors.black.default,
  colorTextPositiveButtonFocus: colors.black.default,
  colorTextPositiveButtonActive: colors.black.default,
  colorTextPositiveButtonDisabled: setAlpha(0.5, colors.black.default),
  colorTextNegativeButton: colors.white.default,
  colorTextNegativeButtonHover: colors.white.default,
  colorTextNegativeButtonFocus: colors.white.default,
  colorTextNegativeButtonActive: colors.white.default,
  colorTextNegativeButtonDisabled: setAlpha(0.5, colors.white.default),
  colorTextWarningButton: colors.black.default,
  colorTextWarningButtonHover: colors.black.default,
  colorTextWarningButtonFocus: colors.black.default,
  colorTextWarningButtonActive: colors.black.default,
  colorTextWarningButtonDisabled: setAlpha(0.5, colors.black.default),
  colorTextPrimaryButton: colors.white.default,
  colorTextPrimaryButtonHover: colors.white.default,
  colorTextPrimaryButtonFocus: colors.white.default,
  colorTextPrimaryButtonActive: colors.white.default,
  colorTextPrimaryButtonDisabled: setAlpha(0.5, colors.white.default),
});

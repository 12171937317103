import {
  CONFIGURATIONS,
  DELETE,
  GET,
  type IConfiguration,
  logger,
  PUT,
  request,
  type SubscriptionResponse,
  useConstant,
  useDynamicCallback,
  useEndpointsContext,
  useObservableValue,
  useStaticSubscription,
  wsScanToMap,
} from '@talos/kyoko';
import { isBoolean, isNumber, toNumber } from 'lodash-es';
import { createContext, type PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { merge, shareReplay, Subject } from 'rxjs';

export interface OrgConfigurationContextProps {
  /**
   * Reads the current config.
   * @param path The `Key` of the config.
   * @param defaultValue Value to default to. Function provides returnType equal to defaultValue.
   */
  getConfig(path: OrgConfigurationKey, defaultValue: string): string;

  getConfig(path: OrgConfigurationKey, defaultValue: number): number;

  getConfig(path: OrgConfigurationKey, defaultValue: boolean): boolean;

  /** Raw configuration values */
  configurations?: Map<string, IConfiguration>;
  enableTrading: () => Promise<SubscriptionResponse<EmptyObject, 'Configuration'>>;
  disableTrading: () => Promise<SubscriptionResponse<EmptyObject, 'Configuration'>>;
  list: () => Promise<SubscriptionResponse<IConfiguration, 'Configuration'>>;
  upsert: (configuration: ConfigurationUpsert) => Promise<SubscriptionResponse<IConfiguration, 'Configuration'>>;
  remove: (key: string) => Promise<SubscriptionResponse<IConfiguration, 'Configuration'>>;
  /** Whether or not the provider is loaded and ready to be used. */
  isLoaded: boolean;
}

export const OrgConfigurationContext = createContext<OrgConfigurationContextProps | undefined>(undefined);
OrgConfigurationContext.displayName = 'OrgConfigurationContext';

export function useOrgConfiguration(): OrgConfigurationContextProps {
  const context = useContext(OrgConfigurationContext);
  if (context === undefined) {
    throw new Error('Missing OrgConfiguration.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/**
 * Provides Organization (per Talos Client) wide settings
 */
export const OrgConfigurationProvider = function OrgConfigurationProvider({ children }: PropsWithChildren) {
  const { orgApiEndpoint } = useEndpointsContext();
  const subject = useConstant(new Subject<SubscriptionResponse<IConfiguration, 'Configuration'>>());
  const { data: subscription } = useStaticSubscription<IConfiguration>({
    name: CONFIGURATIONS,
    tag: 'OrgConfigurationProvider',
  });

  const configurations = useObservableValue(
    () =>
      merge(subscription, subject).pipe(
        wsScanToMap({
          getUniqueKey: item => item.Key,
          newMapEachUpdate: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription, subject]
  );

  const isLoaded = useMemo(() => configurations !== undefined, [configurations]);

  const getConfig = useCallback<OrgConfigurationContextProps['getConfig']>(
    function <T extends string | number | boolean>(path: string, defaultValue: T): T {
      return getConfigOrDefault<T>(configurations, path, defaultValue);
    },
    [configurations]
  );

  const enableTrading = useDynamicCallback(() => {
    return request(PUT, `${orgApiEndpoint}/enable-trading`);
  });

  const disableTrading = useDynamicCallback(() => {
    return request(PUT, `${orgApiEndpoint}/disable-trading`);
  });

  const list = useDynamicCallback(() =>
    request(GET, `${orgApiEndpoint}/configurations`).then(json => {
      subject.next({ ...json, initial: true });
      return json;
    })
  );

  const upsert = useDynamicCallback((configuration: ConfigurationUpsert) => {
    return request(PUT, `${orgApiEndpoint}/configurations`, configuration).then(json => {
      subject.next(json);
      return json;
    });
  });

  const remove = useDynamicCallback((key: string) => {
    return request(DELETE, `${orgApiEndpoint}/configurations`, { Key: key } satisfies ConfigurationRemove).then(
      json => {
        subject.next(json);
        return json;
      }
    );
  });

  const value = useMemo(() => {
    return {
      getConfig,
      configurations,
      list,
      upsert,
      remove,
      enableTrading,
      disableTrading,
      isLoaded,
    };
  }, [getConfig, configurations, list, upsert, remove, enableTrading, disableTrading, isLoaded]);

  return <OrgConfigurationContext.Provider value={value}>{children}</OrgConfigurationContext.Provider>;
};

// The casting in this function should not be required but I can't figure it out
export function getConfigOrDefault<T extends string | number | boolean>(
  configurations: Map<string, IConfiguration> | undefined,
  path: string,
  defaultValue: T
): T {
  try {
    if (!configurations) {
      return defaultValue;
    }
    if (isNumber(defaultValue)) {
      const out = toNumber(configurations.get(path)?.Value);
      return out === undefined || isNaN(out) ? defaultValue : (out as T);
    }
    if (isBoolean(defaultValue)) {
      const str = String(configurations.get(path)?.Value).toLowerCase();
      if (['1', 't', 'true'].includes(str)) {
        return true as T;
      }
      if (['0', 'f', 'false'].includes(str)) {
        return false as T;
      }
      return defaultValue;
    }
    const out = configurations.get(path)?.Value;
    return out === undefined ? defaultValue : (out as T);
  } catch (e) {
    logger.error(e as Error);
  }
  return defaultValue;
}

export interface ConfigurationUpsert {
  Key: string;
  Value: string;
}

export interface ConfigurationRemove {
  Key: string;
}

export enum OrgConfigurationKey {
  // Add additional currencies to dealer customer credit screen limit dropdown. Expected as comma separated list of Currency.Name
  AdditionalCustomerCreditCurrencies = 'ui.AdditionalCustomerCreditCurrencies',
  // If true, allows authorized users to set customer balance in dealer customer balances screen
  AllowSetCustomerBalance = 'ui.AllowSetCustomerBalance',
  // Sets the default number of rows scrolled in most websocket blotters. Default is 10_000
  BlotterRowsMax = 'ui.BlotterRowsMax',
  // Sets the UI reference data cache version. Do this to force a refresh of the cache. Default is '1'. Tell UI team if this is ever used.
  CacheVersion = 'ui.CacheVersion',
  // Sets the TimeInForce parameter for the ClickToTrade feature. Default is 'GoodTillCancel'. No validation is done on this value
  ClickToTradeTimeInForce = 'ui.ClickToTradeTimeInForce',
  // Sets the currencies which should be regarded as "common" and thus be surfaced in the trade spot position selector dropdown. Expected as comma separated list of Currency.Name
  CommonTradeSpotPositionCurrencies = 'ui.CommonTradeSpotPositionCurrencies',
  // If true, allows users to "force withdraw" on dealer/balances New Balance Transaction. Defaults to true for Talos users.
  CustomerBalanceShowForceWithdraw = 'ui.CustomerBalanceShowForceWithdraw',
  // Sets the default number of rows to show in the customer configuration blotter. Default is 10_000.
  CustomerConfigurationRowsMax = 'ui.CustomerConfigurationRowsMax',
  // Sets the REST paging size for the customer configurations screen. Default is 500.
  CustomerConfigurationsPageSize = 'ui.CustomerConfigurationsPageSize',
  // Sets additional text to be added to the end of the customer pricing calculator's details when the copy button is pressed.
  CustomerPricingCopyDetailsString = 'ui.CustomerPricingCopyDetailsString',
  // Sets the default number of rows to show in the trade settlement blotter. Default is 5_000
  CustomerSettlementTradeRowsMax = 'ui.CustomerSettlementTradeRowsMax',
  // Sets the delay the UI will wait before asking the backend for Customer Deal Summaries. Use this to prevent too many failed lookups if BE is behind. Default is 0 (ms)
  CustomerSummaryDelayMs = 'ui.CustomerSummaryDelayMs',
  // Sets a delay for the UI to wait before freaking out about missing data when cloning a sub account. Default is 5_000 (ms)
  DuplicateSubAccountRelatedDataFailureTimeoutMs = 'ui.DuplicateSubAccountRelatedDataFailureTimeoutMs',
  // Enables the ability to archive orders
  EnableArchiveOrders = 'ui.EnableArchiveOrders',
  // Enables the ability to bulk close positions from the positions blotter
  EnableBulkClosePosition = 'ui.EnableBulkClosePosition',
  // Enables the monitoring buying power blotter
  EnableBuyingPowerMonitoringBlotter = 'ui.EnableBuyingPowerMonitoringBlotter',
  // Enables UI reference data caching. Default is true. If you need to set this down please contact the UI team
  EnableCache = 'ui.EnableCache',
  // Enables trading native calendar spreads in the UI. Defaults to false
  EnableCalendarSpreads = 'ui.EnableCalendarSpreads',
  // Enables trading CFDs in the UI. Defaults to false
  EnableCFDs = 'ui.EnableCFD',
  // Enables the ability to set credit limits per customer market account in dealer customer credit screen. Defaults to true.
  EnableCreditLimitsPerMarketAccount = 'ui.EnableCreditLimitsPerMarketAccount',
  // Adds a select all button for currencies in the balances blotter. Default is false
  EnableCurrenciesFilterSelectAll = 'ui.EnableCurrenciesFilterSelectAll',
  // If true, allows users to set customer account restrictions in dealer customer users screen
  EnableCustomerAccountRestrictions = 'ui.EnableCustomerAccountRestrictions',
  // If true, allows users to set customer trading limits per account in dealer customer users screen. Defaults to true.
  EnableCustomerTradingLimitsPerAccount = 'ui.EnableCustomerTradingLimitsPerAccount',
  // If true shows the advanced derivatives positions blotter in principal
  EnableDerivativesPositionsBlotterAdvanced = 'ui.EnableDerivativesPositionsBlotterAdvanced',
  // If true, allows users to see and use the monitoring blotters. Default is true for Talos users
  EnableMonitoringBlotters = 'ui.EnableMonitoringBlotters',
  // If true, shows the new credentials management screen
  EnableNewCredentials = 'ui.EnableNewCredentials',
  // If true, shows the portfolio settlement screen
  EnablePortfolioSettlement = 'ui.EnablePortfolioSettlement',
  // Of true, requests auxiliary data for orders. Required for DDH and archive orders features. Defaults false
  EnableOrderAuxData = 'ui.EnableOrderAuxData',
  /** Enables the new margin management related features that were released in 2.51.0 */
  EnableMarginManagementFeatures = 'ui.EnableMarginManagementFeatures',
  // If true, shows price grid to users. Defaults to true for Talos users
  EnablePriceGrid = 'ui.EnablePriceGrid',
  // If true, shows the recon mismatches blotter
  EnableReconMismatchesBlotter = 'ui.EnableReconMismatchesBlotter',
  // If true, shows the spot positions blotter
  EnableSpotPositionsBlotter = 'ui.EnableSpotPositionsBlotter',
  /** If true, will allow the user to select "Derivatives" in the Portfolio > Performance page product type selector. */
  EnablePerformancePageDerivatives = 'ui.EnablePerformancePageDerivatives',
  // If true, shows additional blotter for controlling Market Accounts Trading capabilities. Default is true
  EnableTradingControls = 'ui.EnableTradingControls',
  // If true, Default Settings button/modal in Trading Controls is shown. Default is false
  EnableTradingControlsDefaultSettings = 'ui.EnableTradingControlsDefaultSettings',
  // Additional currencies to be included when selecting a home currency for the UI. Expected as comma separated list of Currency.Name
  HomeCurrencyAdditionalCurrencies = 'ui.HomeCurrencyAdditionalCurrencies',
  // Sets the max number of rows the order blotter will load before showing warning. Default is 10_000
  OrderRowsMax = 'ui.OrderRowsMax',
  // Sets the amount of time the UI will wait between requests for a "bulk" operation in the org secmaster screen. Default is 100 (ms)
  OrgSecmasterEditDelay = 'ui.OrgSecmasterEditDelay',
  // Sets the amount of time the UI will wait between requests in the csv order upload screen. Default is 400 (ms)
  PlaceOrderThrottleMillis = 'ui.PlaceOrderThrottleMillis',
  // Sets the max number of subscriptions the price grid will allow. Default is 80
  PriceGridMaxActiveSubscriptions = 'ui.PriceGridMaxActiveSubscriptions',
  // If true, allows user to set pricing rules that allow fills with a synthetic cross. Default is false
  PricingRuleAllowedCrossMarkets = 'ui.PricingRuleAllowedCrossMarkets',
  // Sets the number of pricing rules loaded per page in the pricing rules screen. Default is 5000
  PricingRulesPageSize = 'ui.PricingRulesPageSize',
  // If true, shows the uptime chart in the advanced analytics screens
  ShowAnalyticsMarketUptimesChart = 'ui.analytics.ShowMarketUptimesChart',
  // If true, shows the pause subscription button in blotters
  ShowBlotterPauseButton = 'ui.ShowBlotterPauseButton',
  // If true allows customer addresses to be selectable in the portfolio addresses screen
  ShowCustomerDepositAddressSelection = 'ui.ShowCustomerDepositAddressSelection',
  // If true shows the source account column in the customer deposit address screen
  ShowCustomerDepositSourceAccount = 'ui.ShowCustomerDepositSourceAccount',
  // If true, shows the customer order acceptance rules screen in dealer
  ShowCustomerOrderAcceptanceRules = 'ui.ShowCustomerOrderAcceptanceRules',
  // If true, shows the customer configuration / pricing tiers / symbol groups blotters in dealer. Defaults to false.
  ShowCustomerTiering = 'ui.ShowCustomerTiering',
  // If true, shows the customer FIX connections screen in dealer. It still requires talos.support to edit. Defaults to true for Talos users.
  ShowCustomerFIXConnections = 'ui.ShowCustomerFIXConnections',
  // If true, shows the multileg secmaster screen. Will use the Market status of multileg by default but can be forced by setting to true
  ShowMultiLegSecmaster = 'ui.ShowMultiLegSecmaster',
  // If true, shows the warning column on the positions blotter
  ShowPositionsBlotterWarningColumn = 'ui.ShowPositionsBlotterWarningColumn',
  // If true, shows the security master unified liquidity column. Default to false.
  ShowSecurityMasterUnifiedLiquidityColumn = 'ui.ShowSecurityMasterUnifiedLiquidityColumn',
  // If true, shows the support chat (intercom) button in the UI. Defaults to true.
  ShowSupportChat = 'ui.ShowSupportChat',
  // Sets mode of sub accounts postition blotter. Valid modes are: "Spot", "Derivatives", "DerivativesAdvanced"
  SubAccountPositionsBlotterMode = 'ui.SubAccountPositionsBlotterMode',
  // Sets the allowed layout arrangements which can be selected in the dealer users screen valid arrangements are
  //   "SingleSymbolView", "MarketDataCards". Value should be comma separated list
  SupportedLayoutArrangements = 'ui.SupportedLayoutArrangements',
  // Sets the allowed strategies when using a specific limit price mode. For example if "Iceberg" is included in this list, then
  //   if Security.SupportedPricingModes.ImpliedVolatility is true, users will be able to place iceberg orders with limit
  //   prices specified in implied volatility. Value should be a comma separated list of Strategy.Name
  SupportedStrategiesForPricingModes = 'ui.SupportedStrategiesForPricingModes',
  // Sets the default number of rows to show in the trade blotter. Default is 10_000
  TradeRowsMax = 'ui.TradeRowsMax',
  /** The max amount of orders to load in for the UI's RECENT_ORDERS subscription. Default is 10 000. */
  RecentOrderRowsMax = 'ui.RecentOrderRowsMax',
  // If true it allows users to see the white label specific navigation items in the dealer menu. Defaults to true for Talos users.
  ViewWhitelabelMenuItems = 'ui.ViewWhitelabelMenuItems',
  /** If true allows users to view the Account Ledger Events page */
  EnableAccountLedgerEventsPage = 'ui.EnableAccountLedgerEventsPage',
  /** If true, allows the user to create and clone sub accounts from the sub account selector in the order form */
  EnableOrderFormSubAccountCreation = 'ui.EnableOrderFormSubAccountCreation',
  /** If true, allows the user to view the sub account positions blotter in hierarchical / tree mode. Defaults to true. */
  EnableHierarchicalSubAccountPositionsBlotter = 'ui.EnableHierarchicalSubAccountPositionsBlotter',
  /** If true, enables the Portfolio Management System layout mode */
  EnablePortfolioManagementSystem = 'ui.EnablePortfolioManagementSystem',
  // If set to true, the Sales Order and Sales RFQ customers filtering will ignore Customer Configurations. Defaults to false.
  DisableCustomerFilteringByConfigurations = 'ui.DisableCustomerFilteringByConfigurations',
  /* 2.50 release-specific feature flag to enable the Scope column in the Sub Account Position Limits CRUD page when the org's backend is updated. Remove post 2.50. */
  EnablePositionLimitScopeColumn = 'ui.EnablePositionLimitScopeColumn',

  // Non-UI-specific configurations
  AdminCredentialsBlacklist = 'Admin.CredentialsBlacklist',
  AdminUserAdminCanAddRoles = 'admin.UserAdmin.CanAddRoles',
  CustomerRequireBalanceTransactionApproval = 'customer.RequireBalanceTransactionApproval',
  // enable full Account Segregation functionality
  EnableAccountSegregation = 'trading.EnableAccountSegregation',
  // enable subset of Account Segregation functionality specifically for user/account configuration
  EnableAccountSegregationConfiguration = 'ui.EnableAccountSegregationConfiguration',
  // when Account Segregation is enabled, bypass the Read permission check on the MarketAccountProvider (safety measure for initial rollout in 2.50.3)
  EnableAccountSegregationShowAllMarketAccounts = 'ui.EnableAccountSegregationShowAllMarketAccounts',

  // If true, shows the option trading screen
  EnableOptionTrading = 'oms.OptionTrading.Enabled',
  OMSPriceProtectionDisabled = 'oms.PriceProtection.Disabled',
  TradingDisableAll = 'trading.DisableAll',
  // Allows editing of market credentials
  EnableMarketCredentials = 'trading.EnableMarketCredentials',
  /** Enable PnLTag related columns for Sub Account Position blotters */
  ShowSubAccountPositionPnLTagColumns = 'ui.ShowSubAccountPositionPnLTagColumns',
  // Enable the trade best execution feature
  EnableTradeBestExecution = 'ui.EnableTradeBestExecution',
  /** For the Settlement Report exporting, defines how many records we allow the frontend to paginate when fetching the trades for a settlement over REST */
  SettlementReportMaxTradeRecordsPaginated = 'ui.SettlementReportMaxTradeRecordsPaginated',
  // Disable validation for Equity to Margin Ratio check on orders, Default is 0, meaning it's enabled by default
  DisableEquityMarginRatioLimitCheck = 'oms.DisableEquityMarginRatioLimitCheck',

  // ETF-related flags
  EnableTakerMarketplaceETFRFQFlow = 'ui.EnableTakerMarketplaceETFRFQFlow',
  EnableMakerMarketplaceETFRFQFlow = 'ui.EnableMakerMarketplaceETFRFQFlow',
  /** @deprecated Use SpreadToFixingSymbolIndexesMap instead */
  DefaultSpreadToFixingRFQIndex = 'ui.DefaultSpreadToFixingRFQIndex',
  SpreadToFixingSymbolIndexesMap = 'rfq.SpreadToFixingSymbolIndexesMap',
  // Comma-separated list of markets to use (with useMarketDataSnapshot) to prime the fixing price dialog with a default price
  FixingPriceDialogMarkets = 'ui.FixingPriceDialogMarkets',
  // Enable viewing auto hedging controls and status updates.
  EnableAutoHedging = 'ui.EnableAutoHedging',
  // Enable Dynamic Delta Hedging
  EnableDynamicDeltaHedging = 'ui.EnableDynamicDeltaHedging',
  /** Enable stitching orders with hedge position status based on the HedgeRuleID */
  EnableOrderLinkInHedgeRule = 'ui.EnableOrderLinkInHedgeRule',
  /** The home currency of the autohedger. The asset cannot be selected to be this */
  PositionHedgerHomeCurrency = 'ui.PositionHedgerHomeCurrency',

  /** If true, will allow to file a JIRA ticket directly from the UI */
  EnableSupportModal = 'ui.EnableSupportModal',
  /** If true, allows marking an issue as high priority and sending a PagerDuty alert to the CS team */
  EnableSupportModalPagerDuty = 'ui.EnableSupportModalPagerDuty',

  EnablePortfolioSubAccountRecon = 'ui.EnablePortfolioSubAccountRecon',
  /** If true, will enable the Audit Log tab in the IBOR match resolution drawer */
  EnableIBORAuditTrail = 'ui.EnableIBORAuditTrail',
  // Disable loading of all customer market accounts. Defaults to false.
  DisableCustomerMarketAccounts = 'ui.DisableCustomerMarketAccounts',
  // Filter market accounts by permission action 'All'. Defaults to false.
  FilterMarketAccountsByPermissionActionAll = 'ui.FilterMarketAccountsByPermissionActionAll',
  /** Parser to use for the Care Order import */
  CareOrderImportParser = 'ui.CareOrderImportParser',
  /** Force Datadog session replay regardless of sample rate */
  ForceSessionReplay = 'ui.ForceSessionReplay',
  /** Ensures counterparty is a required field on the Customer Balances screen (needed for retail scale) */
  RequireCustomerBalancesCounterpartyFilter = 'ui.RequireCustomerBalancesCounterpartyFilter',

  /** By default, non-synthetic symbols are filtered out from the Customer Book and Modify Trade Dialog.
   *  Set this flag to true if all available options should be visible. */
  AllowSytheticSymbolsOnManualCustomerTrade = 'ui.AllowSytheticSymbolsOnManualCustomerTrade',
  /** By default, the customer user email is not editable after creation.
   *  [DEAL-4939] Set this flag to true to allow editing the email of the existing customer user.
   *  Defaults to true for Talos users. */
  AllowModifyCustomerUserEmail = 'ui.AllowModifyCustomerUserEmail',
  /** Checks that counterparty/marketaccount has balance to create trade, and will render confirm flow if not. */
  CheckCustomerBalanceOnNewCustomerTrade = 'ui.CheckCustomerBalanceOnNewCustomerTrade',
  /** Number of days before a credential expires to show an alert */
  CredentialAgeAlertDays = 'ui.CredentialAgeAlertDays',
  /** Number of days before a credential expires to show a warning */
  CredentialAgeWarningDays = 'ui.CredentialAgeWarningDays',
  /** Number of days to mute credential expires banner */
  CretentialAgeBannerMuteDays = 'ui.muteCredentialsAgeWarningDays',
  /** Enable Credential age banner */
  EnableCredentialAgeBanner = 'ui.enableCredentialAgeBanner',
  /** Lets the user select the new LedgerUpdateTypes introduced in 2.50 */
  ShowTwoFiftyLedgerUpdateTypes = 'ui.ShowTwoFiftyLedgerUpdateTypes',
  /** Enable Security Master required filtering */
  EnableSecurityMasterRequiredFiltering = 'ui.EnableSecurityMasterRequiredFiltering',
  /** Lets the user select the dark/light or auto adjusted theme */
  EnableThemeTypeSelection = 'EnableThemeTypeSelection',

  /** Enable flexible UI features */
  EnableFlexibleUI = 'ui.EnableFlexibleUI',
  /** Enable popout window feature */
  EnablePopoutWindows = 'ui.EnablePopoutWindows',

  // Toggling these flags to true will use the legacy component for each Entity Admin screen
  UseLegacyCustomerExecutionRules = 'ui.UseLegacyCustomerExecutionRules',
  UseLegacyCustomerFIXConnections = 'ui.UseLegacyCustomerFIXConnections',
  UseLegacyCustomerCredits = 'ui.UseLegacyCustomerCredits',
  UseLegacyCustomerAddresses = 'ui.UseLegacyCustomerAddresses',

  /** Enable In-app Notifications Displays */
  EnableNotifications = 'ui.EnableNotifications',
}

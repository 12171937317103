import Big from 'big.js';
import { compact } from 'lodash-es';
import { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { pipe, type Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { useCurrencyUtils } from '../contexts';
import { useObservable, useObservableValue, useStaticSubscription } from '../hooks';
import { wsScanToMap } from '../pipes';
import { EXPOSURE } from '../tokens';
import {
  EnrichedCreditExposure,
  ExposureLimitSideTypeEnum,
  MarketAccountStatusEnum,
  generateCreditExposureRowID,
  type CreditExposure,
  type CreditExposureWithSides,
} from '../types';
import { logger } from '../utils';
import { useWLCustomerMarketAccountContext, useWLSortedMarketAccounts } from './WLCustomerMarketAccountsProvider';
import { useWLDefaultMarketAccount } from './WLCustomerUserConfigContextProvider';

interface WLExposuresContextProps {
  activeExposures: EnrichedCreditExposure[] | undefined;
  currentExposure: EnrichedCreditExposure | undefined;
  currentExposurePercentage: number | undefined;
  exposuresObs: Observable<EnrichedCreditExposure[]>;
  exposureByMarketAccount: Map<string, EnrichedCreditExposure>;
  getExposurePercentage: (exposure: EnrichedCreditExposure | undefined) => number;
  isLoaded: boolean;
}

export const WLExposuresContext = createContext<WLExposuresContextProps | undefined>(undefined);

export function useWLExposuresContext() {
  const context = useContext(WLExposuresContext);
  if (context === undefined) {
    throw new Error('Missing WLExposuresContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const getExposurePercentage = (exposure: EnrichedCreditExposure | undefined): number => {
  if (exposure === undefined || exposure.Exposure === '0') {
    return 0;
  }

  try {
    return parseFloat(exposure.ExposureLimit) === 0
      ? NaN
      : Big(exposure.Exposure).div(exposure.ExposureLimit).times(100).toNumber();
  } catch {
    // We should **never** be here, but the back-end can be unpredictable.
    logger.error(new Error('Invalid Exposure Record Has Caused a Calculation Error:'), {
      extra: {
        Exposure: exposure.Exposure,
        ExposureLimit: exposure.ExposureLimit,
        MarketAccount: exposure.MarketAccount,
        Timestamp: exposure.Timestamp,
      },
    });
    return NaN;
  }
};

const padExposureZeroeValues = (amount: string) => ((amount || '0') === '0' ? '0.00' : amount);

export const WLBuildCreditExposureInstancesWithSides = pipe(
  map((json: { data: CreditExposure[] }) => ({
    ...json,
    data: json.data.reduce<Map<string, CreditExposureWithSides>>((acc, exposure) => {
      // In the case of separate records containing separate long and short ExposureSide
      // values, we manually merge them into a singular record here with a lookup Map.
      const newExposureRecord: CreditExposureWithSides = {
        ...exposure,
        // For now, we're receiving data from the back-end that has its values pre-formatted
        // to a certain number of decimal places, *except* for values of zero, and for WL
        // users, we don't have access to every currency for formatting purposes, only
        // those currencies we're carrying balances on, so exposure records may pull
        // undefined currency records and have no DefaultIncrement to use for precision
        // purposes. In these cases, we render the numbers as they're stored. So, for now,
        // any zero or empty value number, we're going to pad to "0.00".
        Exposure: padExposureZeroeValues(exposure.Exposure),
        ExposureLimit: exposure.ExposureLimit ? padExposureZeroeValues(exposure.ExposureLimit) : undefined,
      };
      const rowID = generateCreditExposureRowID(newExposureRecord);

      if (exposure.ExposureSide === ExposureLimitSideTypeEnum.Long) {
        newExposureRecord.longExposure = newExposureRecord.Exposure;
        newExposureRecord.longExposureLimit = newExposureRecord.ExposureLimit;
      } else if (newExposureRecord.ExposureSide === ExposureLimitSideTypeEnum.Short) {
        newExposureRecord.shortExposure = newExposureRecord.Exposure;
        newExposureRecord.shortExposureLimit = newExposureRecord.ExposureLimit;
      } else {
        newExposureRecord.longExposure = newExposureRecord.Exposure;
        newExposureRecord.longExposureLimit = newExposureRecord.ExposureLimit;
        newExposureRecord.shortExposure = newExposureRecord.Exposure;
        newExposureRecord.shortExposureLimit = newExposureRecord.ExposureLimit;
      }

      acc.set(rowID, {
        ...(acc.get(rowID) ?? {}),
        ...newExposureRecord,
      });

      return acc;
    }, new Map()),
  })),
  map(json => ({
    ...json,
    // We then dump out the values of the lookup Map and instantiate them with
    // explicitly-defined long and short exposure and exposureLimit values.
    data: [...json.data.values()].map(exposure => new EnrichedCreditExposure(exposure)),
  }))
);

export const WLExposuresProvider = memo(({ children }: React.PropsWithChildren<unknown>) => {
  const { customerMarketAccountsList, customerMarketAccountsBySourceAccountID } = useWLCustomerMarketAccountContext();
  const { defaultMarketAccount, setDefaultMarketAccount } = useWLDefaultMarketAccount();
  const sortedMarketAccounts = useWLSortedMarketAccounts(customerMarketAccountsList);
  const [isLoaded, setIsLoaded] = useState(false);

  const { data: subscription } = useStaticSubscription<CreditExposure>({
    name: EXPOSURE,
    tag: 'WLExposuresProvider',
  });

  const exposuresObs = useObservable(
    () =>
      subscription.pipe(
        map(json => ({
          ...json,
          data: json.data.map(exposure => {
            if (!exposure.ExposureSide || !Object.values(ExposureLimitSideTypeEnum).includes(exposure.ExposureSide)) {
              logger.warn('CreditExposure record received from server with invalid (required) ExposureLimit', {
                extra: {
                  ExposureSort: exposure.ExposureSide,
                  MarketAccount: exposure.MarketAccount,
                },
              });

              // Default to 'Both' if not present or not within defined enum range
              exposure.ExposureSide = ExposureLimitSideTypeEnum.Both;
            }

            if (typeof exposure.ExposureLimit !== 'string' || exposure.ExposureLimit === '') {
              logger.warn('CreditExposure record received from server with invalid (required) ExposureLimit', {
                extra: {
                  ExposureLimit: exposure.ExposureLimit,
                  MarketAccount: exposure.MarketAccount,
                },
              });

              exposure.ExposureLimit = '0';
            }

            return exposure;
          }),
        })),
        WLBuildCreditExposureInstancesWithSides,
        wsScanToMap<EnrichedCreditExposure, string>({
          getUniqueKey: generateCreditExposureRowID,
          newMapEachUpdate: false,
        }),
        map(exposures => [...exposures.values()]),
        tap(() => setIsLoaded(true)),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  const exposures = useObservableValue(() => exposuresObs, [exposuresObs]);

  const exposureByMarketAccount: Map<string, EnrichedCreditExposure> = useMemo(() => {
    if (!exposures) {
      return new Map();
    }

    return exposures.reduce((accMap, exposure) => {
      if (!exposure.Currency) {
        // [DEAL-1983] The list of limits to show should be limited to those that have an empty currency (ex. “Global Exposure Limits”)
        accMap.set(exposure.MarketAccount, exposure);
      }
      return accMap;
    }, new Map<string, EnrichedCreditExposure>());
  }, [exposures]);

  const activeExposures = useMemo(() => {
    if (!exposures || !sortedMarketAccounts || !customerMarketAccountsBySourceAccountID) {
      return undefined;
    }

    const { activeExposuresMap, hasExposures } = exposures.reduce(
      (acc, exposure) => {
        if (
          customerMarketAccountsBySourceAccountID.get(exposure.MarketAccount)?.Status === MarketAccountStatusEnum.Active
        ) {
          if (!exposure.Currency) {
            // [DEAL-1983] The list of limits to show should be limited to those that have an empty currency (ex. “Global Exposure Limits”)
            acc.activeExposuresMap.set(exposure.MarketAccount, exposure);
          }
          acc.hasExposures = acc.hasExposures || Big(exposure?.ExposureLimit || 0).gt(0);
        }

        return acc;
      },
      { activeExposuresMap: new Map<string, EnrichedCreditExposure>(), hasExposures: false }
    );

    // Only return exposures if any Exposure Limit > 0
    if (hasExposures) {
      // Return the list of exposure records in the same order as the sorted market accounts,
      // so that visually in the UI, they render 1:1 in the same positions as the market
      // accounts in the market account selector, if it's currently visible on the page.
      return compact(sortedMarketAccounts.map(marketAccount => activeExposuresMap.get(marketAccount.SourceAccountID)));
    }

    return undefined;
  }, [exposures, sortedMarketAccounts, customerMarketAccountsBySourceAccountID]);

  const currentExposure = activeExposures?.find(exposure => exposure.MarketAccount === defaultMarketAccount);
  const currentExposurePercentage = getExposurePercentage(currentExposure);

  useEffect(() => {
    // If our current default market account setting is invalid for whatever reason, re-default it
    if (activeExposures?.length && currentExposure == null) {
      setDefaultMarketAccount(activeExposures[0].MarketAccount);
    }
  }, [activeExposures, activeExposures?.length, currentExposure, exposures, setDefaultMarketAccount]);

  const value: WLExposuresContextProps = useMemo(
    () => ({
      activeExposures,
      currentExposure,
      currentExposurePercentage,
      exposuresObs,
      getExposurePercentage,
      exposureByMarketAccount,
      isLoaded,
    }),
    [activeExposures, currentExposure, currentExposurePercentage, exposuresObs, isLoaded, exposureByMarketAccount]
  );

  return <WLExposuresContext.Provider value={value}>{children}</WLExposuresContext.Provider>;
});

export function useWLExposuresUtils() {
  const { activeExposures } = useWLExposuresContext();
  const { abbreviateByCurrency } = useCurrencyUtils();

  const getAbbreviatedAmountByMarketAccount = useCallback(
    (marketAccount: string, fieldKey: 'Exposure' | 'ExposureLimit') => {
      const exposure = activeExposures?.find(exposure => exposure.MarketAccount === marketAccount);
      return abbreviateByCurrency(exposure?.ExposureCurrency, exposure?.[fieldKey]);
    },
    [abbreviateByCurrency, activeExposures]
  );

  return useMemo(
    () => ({
      getAbbreviatedAmountByMarketAccount,
    }),
    [getAbbreviatedAmountByMarketAccount]
  );
}

import { Box, HStack, IndicatorBadge, Tooltip, VStack } from '@talos/kyoko';

type ValueAndState = [string, ValueState];

enum ValueState {
  Added,
  Removed,
  Existing,
}

const LIMIT = 1;

export const OverflowBox = ({
  items,
  showAllMarketsToggle,
}: {
  items: ValueAndState[];
  showAllMarketsToggle?: boolean;
}) => {
  const firstItems = items.slice(0, LIMIT);

  return (
    <HStack gap="spacingTiny">
      {firstItems.length > 0 ? (
        firstItems.map(([item, state], index) => (
          <Box
            key={item + state}
            title={item}
            textAlign="right"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="fontSizeSmall"
            color={getStateColor(state)}
            textDecoration={state === ValueState.Removed ? 'line-through' : undefined}
          >
            {item}
            {index < firstItems.length - 1 && ', '}
          </Box>
        ))
      ) : showAllMarketsToggle ? (
        <Box fontSize="fontSizeSmall">All Markets</Box>
      ) : (
        <Box fontSize="fontSizeSmall" color="colorTextNegative">
          No Markets Selected
        </Box>
      )}

      {items.length > LIMIT && (
        <Tooltip
          tooltip={
            <VStack alignItems="flex-start" gap="spacingDefault" lineHeight={1}>
              {items.slice(LIMIT).map(([item, state]) => (
                <Box
                  key={item + state}
                  color={getStateColor(state)}
                  textDecoration={state === ValueState.Removed ? 'line-through' : undefined}
                >
                  {item}
                </Box>
              ))}
            </VStack>
          }
        >
          <IndicatorBadge
            ml="spacingSmall"
            my="-spacingTiny"
            children={`+${items.length - LIMIT} More`}
            color="gray.090"
          />
        </Tooltip>
      )}
    </HStack>
  );
};

function getStateColor(state: ValueState | undefined): string | undefined {
  switch (state) {
    case ValueState.Added:
      return 'green.lighten';
    case ValueState.Removed:
      return 'red.lighten';
  }
  return undefined;
}

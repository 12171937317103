import {
  FilterClauseType,
  removeEmptyFilters,
  useAddressRoutingTypesFilter,
  useCurrenciesFilter,
  useCustomerAddressStatusesFilter,
  useCustomerAddressTypesFilter,
  type FilterClause,
  type FilterableProperty,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { useCounterpartiesFilter } from 'hooks';
import { compact, isArray, isEqual, keys } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import type { CustomerAddressesFilter } from './types';

/**
 * @deprecated
 */
export interface UseCustomerAddressesFilterParams<ICustomerAddress> {
  persistedBlotterTable: UsePersistedBlotterTable<ICustomerAddress>;
}
/**
 * @deprecated
 */
export function useCustomerAddressesFilter<ICustomerAddress>({
  persistedBlotterTable,
}: UseCustomerAddressesFilterParams<ICustomerAddress>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [filter, setFilter] = useState<CustomerAddressesFilter>({});

  const changeFilter = useCallback(
    (action: SetStateAction<CustomerAddressesFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const statusFilter = useCustomerAddressStatusesFilter();
  const counterpartiesFilter = useCounterpartiesFilter();
  const currenciesFilter = useCurrenciesFilter();
  const addressTypesFilter = useCustomerAddressTypesFilter();
  const addressRoutingTypesFilter = useAddressRoutingTypesFilter();

  const filterableProperties: FilterableProperty<keyof CustomerAddressesFilter>[] = useMemo(
    () => [statusFilter, counterpartiesFilter, currenciesFilter, addressTypesFilter, addressRoutingTypesFilter],
    [addressRoutingTypesFilter, addressTypesFilter, counterpartiesFilter, currenciesFilter, statusFilter]
  );

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters<CustomerAddressesFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies CustomerAddressesFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof CustomerAddressesFilter)[]).forEach((key: keyof CustomerAddressesFilter) => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: (isArray(filter[key]) ? filter[key] : compact([filter[key]])) as string[],
        });
      });
    }
    return clauses;
  }, [filter]);

  const filterBuilderProps = useMemo(
    () =>
      ({
        initialFilterClauses,
        properties: filterableProperties,
        onFilterClausesChanged: handleFilterClausesChanged,
      } satisfies UseFilterBuilderProps),
    [initialFilterClauses, filterableProperties, handleFilterClausesChanged]
  );

  return {
    filter,
    filterBuilderProps,
  };
}

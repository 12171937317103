import {
  Button,
  ButtonVariants,
  Divider,
  FormControlSizes,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  type Column,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, ICellRendererParams } from 'ag-grid-enterprise';
import { compact } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type { AggregationWithAccounts } from 'types';

export function AggregationBlotterTableMenu({
  data: aggregation,
  onEditAggregation,
  onDeleteAggregation,
}: ICellRendererParams & {
  onDeleteAggregation(aggregation: AggregationWithAccounts): void;
  onEditAggregation(aggregation: AggregationWithAccounts): void;
}) {
  return (
    <>
      <Button size={FormControlSizes.Small} ghost={true} onClick={() => onEditAggregation(aggregation)}>
        Edit &quot;{aggregation.Name}&quot;
      </Button>
      <Divider orientation="horizontal" />
      {aggregation.Name != null && (
        <Button
          size={FormControlSizes.Small}
          variant={ButtonVariants.Negative}
          ghost={true}
          onClick={() => onDeleteAggregation(aggregation)}
        >
          Remove
        </Button>
      )}
    </>
  );
}

export function useAggregationBlotterTableMenu({ openDeleteDialog, onEditAggregation, setAggregation }) {
  const handleDeleteAggregation = useCallback(
    (aggregation: AggregationWithAccounts) => {
      setAggregation(aggregation);
      openDeleteDialog();
    },
    [openDeleteDialog, setAggregation]
  );

  const column = useMemo<Column>(
    () => ({
      id: 'menu',
      type: 'hamburgerMenu',
      suppressColumnsToolPanel: true,
      params: {
        renderItems: params => (
          <AggregationBlotterTableMenu
            {...params}
            onDeleteAggregation={handleDeleteAggregation}
            onEditAggregation={onEditAggregation}
          />
        ),
      },
    }),
    [handleDeleteAggregation, onEditAggregation]
  );
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    return compact([
      {
        name: `Edit "${params?.node?.data?.Name}"`,
        action: () => onEditAggregation(params?.node?.data),
      },
      {
        name: `Remove`,
        action: () => handleDeleteAggregation(params?.node?.data),
      },
      'separator',
      ...getDefaultContextMenuItems(params),
    ]);
  });

  return {
    column,
    getContextMenuItems,
  };
}

import {
  BlotterTable,
  Button,
  type ColumnDef,
  DrawerFooter,
  FormControlSizes,
  getShowJSONContextItem,
  IconName,
  LoaderTalos,
  NotificationVariants,
  Portal,
  type SubAccountReconMatchHistory,
  type SubscriptionResponse,
  useBehaviorSubject,
  useBlotterTable,
  useGetDefaultContextMenuItems,
  useGlobalToasts,
  useJsonModal,
  VStack,
} from '@talos/kyoko';
import type { GetContextMenuItems, GetContextMenuItemsParams } from 'ag-grid-community';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { map, shareReplay } from 'rxjs';
import { useSubAccountReconRequests } from '../useSubAccountReconRequests';
import { DRAWER_TABLIST_SUFFIX_PORTAL_ID, type ReconBreak } from './types';

// A MatchBreak is a ReconBreak of a Match specifically, and never a Checkpoint
type MatchBreak = Omit<ReconBreak, 'matchID'> & { matchID: string };

export function isMatchBreak(reconBreak: ReconBreak | undefined): reconBreak is MatchBreak {
  return reconBreak?.matchID != null;
}

interface MatchHistoryTabProps {
  matchBreak: MatchBreak;
  closeDrawer: () => void;
}

export const MatchHistoryTab = ({ matchBreak, closeDrawer }: MatchHistoryTabProps) => {
  const { add: addToast } = useGlobalToasts();
  const { getMatchHistory } = useSubAccountReconRequests();

  const [history, setHistory] = useState<SubAccountReconMatchHistory[] | undefined>(undefined);
  useEffect(() => {
    getMatchHistory(matchBreak.matchID)
      .then(res => setHistory(res.data))
      .catch((e: ErrorEvent) => {
        addToast({
          variant: NotificationVariants.Negative,
          text: `Unable to load Audit Log: ${e.message}`,
        });
      });
  }, [matchBreak.matchID, getMatchHistory, addToast]);

  const { observable: historyObservable } = useBehaviorSubject(() => history, [history]);
  const dataObs = useMemo(
    () =>
      historyObservable.pipe(
        map(history => {
          return {
            data: history ?? [],
            // Always initial because its based on absolute rest responses
            initial: true,
            type: '',
            ts: '',
          } satisfies SubscriptionResponse<SubAccountReconMatchHistory>;
        }),
        shareReplay(1)
      ),
    [historyObservable]
  );

  const { handleClickJson, jsonModal } = useJsonModal<SubAccountReconMatchHistory>();
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const getContextMenuItems: GetContextMenuItems<SubAccountReconMatchHistory> = useCallback(
    (params: GetContextMenuItemsParams<SubAccountReconMatchHistory>) => {
      return [getShowJSONContextItem({ params, handleClickJson }), ...getDefaultContextMenuItems(params)];
    },
    [handleClickJson, getDefaultContextMenuItems]
  );

  const blotterTable = useBlotterTable({
    dataObservable: dataObs,
    rowID: 'rowID' satisfies keyof SubAccountReconMatchHistory,
    columns,
    initialSort: '-LastUpdateTime',
    gridOptions: {
      getContextMenuItems,
    },
  });

  const { exportDataAsCSV } = blotterTable;

  const handleExport = useCallback(() => {
    exportDataAsCSV({
      fileName: `Audit Log ${matchBreak.matchID}`,
    });
  }, [exportDataAsCSV, matchBreak]);

  return (
    <>
      <VStack
        h="100%"
        w="100%"
        justifyContent="space-between"
        flex="1"
        data-testid="break-resolution-drawer-history-tab-content"
      >
        {history == null ? <LoaderTalos /> : <BlotterTable {...blotterTable} background="backgroundDrawer" />}
        <DrawerFooter w="100%">
          <Button onClick={closeDrawer}>Close</Button>
        </DrawerFooter>
      </VStack>
      <Portal portalId={DRAWER_TABLIST_SUFFIX_PORTAL_ID}>
        <Button onClick={handleExport} size={FormControlSizes.Small} startIcon={IconName.DocumentDownload}>
          Export
        </Button>
      </Portal>
      {jsonModal}
    </>
  );
};

const columns: ColumnDef<SubAccountReconMatchHistory>[] = [
  {
    type: 'number',
    field: 'Revision',
    title: 'Revision',
    width: 48, // 48 -> blotter cleanly says "REV..." (and not RE... or REVI...)
  },
  {
    type: 'text',
    field: 'HistoryAction',
    title: 'Action',
    width: 120,
  },
  {
    type: 'date',
    field: 'LastUpdateTime',
    title: 'Timestamp',
    width: 130,
  },
  {
    type: 'text',
    field: 'HistoryComment',
    title: 'Comment',
    tooltipField: 'HistoryComment',
  },
  {
    type: 'user',
    field: 'UpdateUser',
    title: 'Updated By',
    params: {
      valueFormatWithMap: 'id',
    },
  },
];

import { createContext, memo, useCallback, useContext, useMemo, type PropsWithChildren } from 'react';
import type { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import {
  DELETE,
  EXPOSURE,
  EXPOSURE_LIMIT,
  PATCH,
  POST,
  request,
  useObservable,
  useObservableValue,
  useStaticSubscription,
  useUserContext,
  wsScanToMap,
  type Exposure,
  type ExposureLimit,
} from '@talos/kyoko';
import { sortBy } from 'lodash-es';

export interface ExposuresContextProps {
  exposures?: Exposure[];
  exposuresObs: Observable<Exposure[]>;
  exposureLimits?: ExposureLimit[];
  createExposureLimit: (exposureLimit: Partial<ExposureLimit>) => Promise<any>;
  updateExposureLimit: (exposureLimit: ExposureLimit) => Promise<any>;
  deleteExposureLimit: (exposureLimitID: string) => Promise<any>;
}

export interface BuyingPowerRequest {
  name: string;
  Symbol: string;
  Counterparties: string[];
  Throttle?: string;
}

const ExposuresContext = createContext<ExposuresContextProps | null>(null);
ExposuresContext.displayName = 'ExposuresContext';

export function useExposures() {
  const context = useContext(ExposuresContext);
  if (context == null) {
    throw new Error('Missing ExposuresContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const ExposuresProvider = memo(function ExposuresProvider(props: PropsWithChildren<unknown>) {
  const { data: exposuresSubscription } = useStaticSubscription<Exposure>({
    name: EXPOSURE,
    tag: EXPOSURE,
  });

  const { data: exposureLimitsSubscription } = useStaticSubscription<ExposureLimit>({
    name: EXPOSURE_LIMIT,
    tag: EXPOSURE_LIMIT,
  });

  const exposuresObs = useObservable(
    () =>
      exposuresSubscription.pipe(
        wsScanToMap({ getUniqueKey: getExposureKey, newMapEachUpdate: false }),
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [exposuresSubscription]
  );

  const exposuresLimitsObs = useObservable(
    () =>
      exposureLimitsSubscription.pipe(
        wsScanToMap({ getUniqueKey: d => d.ExposureLimitID, newMapEachUpdate: false }),
        map(map => sortBy([...map.values()], item => item.Counterparty)),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [exposureLimitsSubscription]
  );

  const exposures = useObservableValue(() => exposuresObs, [exposuresObs]);
  const exposureLimits = useObservableValue(() => exposuresLimitsObs, [exposuresLimitsObs]);

  const { orgApiEndpoint } = useUserContext();
  const endpoint = `${orgApiEndpoint}/exposure`;

  const createExposureLimit = useCallback(
    exposureLimit => request(POST, `${endpoint}/limits`, exposureLimit),
    [endpoint]
  );
  const updateExposureLimit = useCallback(
    exposureLimit => request(PATCH, `${endpoint}/limits/${exposureLimit.ExposureLimitID}`, exposureLimit),
    [endpoint]
  );
  const deleteExposureLimit = useCallback(
    exposureLimitID => request(DELETE, `${endpoint}/limits/${exposureLimitID}`, null),
    [endpoint]
  );

  const value = useMemo(
    () => ({
      exposures,
      exposuresObs,
      exposureLimits,
      createExposureLimit,
      updateExposureLimit,
      deleteExposureLimit,
    }),
    [exposures, exposuresObs, exposureLimits, createExposureLimit, updateExposureLimit, deleteExposureLimit]
  ) satisfies ExposuresContextProps;

  return <ExposuresContext.Provider value={value}>{props.children}</ExposuresContext.Provider>;
});
export function getExposureKey(data: Exposure): string {
  return `${data.MarketAccount}-${data.ExposureCurrency}-${data.ExposureDefinition}`;
}

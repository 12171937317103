import type { CustomCellEditorProps } from 'ag-grid-react';
import { get, uniq } from 'lodash-es';
import type { AgGridSearchSelectDropdownProps } from '../../AgGrid/types';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export interface ThresholdAssetColumnParams {
  limitAssetsBySymbol: Map<string, { Symbol: string }>;
  /**
   * If a Match Asset is selected, we sometimes want to also allow the user to select that asset as the threshold denominator.
   * Setting this option to the key of the Match Asset on the limit will read the current set Match Asset
   * and add it to the set of selectable Threshold Assets.
   */
  keyOfMatchAssetToIncludeInOptions?: string;
}

/**
 * A column factory built for showing and setting threshold asset (currency or security) on limits
 */
export const thresholdAsset: ColDefFactory<Column<ThresholdAssetColumnParams>> = column => ({
  ...baseColumn(column),
  cellRenderer: 'securityColumn',
  cellEditor: 'searchSelectDropdown',
  cellEditorPopup: true,
  suppressKeyboardEvent: params => params.event.key === 'Enter',
  cellEditorParams: (params: CustomCellEditorProps) => {
    const assetsBySymbol = column?.params?.limitAssetsBySymbol ?? new Map<string, { Symbol: string }>();
    const options = [...assetsBySymbol.values()].map(asset => asset.Symbol);

    if (column.params?.keyOfMatchAssetToIncludeInOptions) {
      const maybeMatchAsset: string | undefined = get(params.data, column.params.keyOfMatchAssetToIncludeInOptions);
      if (maybeMatchAsset) {
        options.push(maybeMatchAsset);
      }
    }

    return {
      ...params,
      useSearchSelectParams: {
        items: uniq(options),
        getLabel: item => item,
      },
    } satisfies AgGridSearchSelectDropdownProps<string>;
  },
});

import { defineMessages } from 'react-intl';
import {
  UnifiedLiquidityEnum,
  type CustomerDealSummary,
  type DecisionStatusEnum,
  type OrdStatusEnum,
  type OrderStrategy,
  type PricingModeEnum,
  type Security,
} from '../../types';
import { abbreviateId, formattedDay, formattedTime } from '../../utils';
import { HStack } from '../Core';
import { InlineFormattedNumber } from '../FormattedNumber';
import { FormattedMessage } from '../Intl';
import { OrderStatus } from '../Status';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { UnifiedLiquidityTooltip } from '../UnifiedLiquidity';
import { Header, OrderNumber, ProfitWrapper, SubTitle, Time, Title } from './styles';

interface OrderCardHeaderProps {
  security: Security | undefined;
  selectedStrategy?: OrderStrategy;
  strategy?: string;
  ordStatus: OrdStatusEnum;
  startTime?: string;
  endTime?: string;
  decisionStatus: DecisionStatusEnum;
  cumQty: string;
  orderQty: string;
  pricingMode?: PricingModeEnum;
  text?: string;
  submitTime?: string;
  orderID: string;
  parentOrderID?: string;
  underlyingSymbols?: string[];
  unifiedLiquidity?: UnifiedLiquidityEnum;
  counterpartyName?: string;
  customerSummary?: CustomerDealSummary;
}

const messages = defineMessages({
  profit: {
    id: 'OrderCard.profit',
    defaultMessage: 'Profit:',
  },
  customer: {
    id: 'OrderCard.customer',
    defaultMessage: 'Customer',
  },
  id: {
    id: 'OrderCard.id',
    defaultMessage: 'ID',
  },
});

export const OrderCardHeader = ({
  security,
  selectedStrategy,
  strategy,
  ordStatus,
  startTime,
  endTime,
  decisionStatus,
  cumQty,
  orderQty,
  pricingMode,
  text,
  submitTime,
  orderID,
  parentOrderID,
  underlyingSymbols,
  unifiedLiquidity,
  counterpartyName,
  customerSummary,
}: OrderCardHeaderProps) => {
  const showUnderlyingSymbols = unifiedLiquidity === UnifiedLiquidityEnum.Enabled;

  return (
    <Header>
      <HStack justifyContent="space-between">
        <Title>{parentOrderID ? counterpartyName || <FormattedMessage {...messages.customer} /> : null}</Title>
        <ProfitWrapper>
          {customerSummary && (
            <HStack gap="spacingSmall">
              <FormattedMessage {...messages.profit} />
              <InlineFormattedNumber
                number={customerSummary?.PnL?.[0]?.Amount}
                currency={customerSummary?.PnL?.[0]?.Currency}
                align="right"
              />
            </HStack>
          )}
        </ProfitWrapper>
      </HStack>
      <div>
        <Title>
          <Tooltip
            disabled={!showUnderlyingSymbols || !underlyingSymbols || underlyingSymbols?.length === 0}
            tooltip={<UnifiedLiquidityTooltip security={security} symbols={underlyingSymbols} />}
          >
            <span data-testid="order-symbol">{security?.DisplaySymbol}</span>
            {showUnderlyingSymbols && '+'}
          </Tooltip>
          <SubTitle data-testid="order-strategy">{selectedStrategy?.DisplayName || strategy}</SubTitle>
        </Title>

        <OrderStatus
          data-testid="order-status"
          ordStatus={ordStatus}
          decisionStatus={decisionStatus}
          cumQty={cumQty}
          orderQty={orderQty}
          tooltipText={text}
          startTime={startTime}
          endTime={endTime}
          pricingMode={pricingMode}
        />
      </div>
      <div>
        <Time>{formattedDay(submitTime)}</Time>
        <Time>{formattedTime(submitTime)}</Time>
        <Text color="colorTextSubtle">
          <label>
            <FormattedMessage {...messages.id} />{' '}
          </label>
          <OrderNumber title={orderID} data-testid="order-number">
            {abbreviateId(orderID)}
          </OrderNumber>
        </Text>
      </div>
    </Header>
  );
};

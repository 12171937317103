import { IconName, type FilterableProperty } from '@talos/kyoko';
import { useUsers } from 'hooks/useUsers';
import { identity, uniq } from 'lodash-es';
import { useMemo } from 'react';
/**
 * This hook creates the filter property definition for Users properties.
 *
 * @returns Filter Property definition to use for Users filters
 */

export function useUsersFilter() {
  const users = useUsers();

  return useMemo(
    () => ({
      ...({
        key: 'Users',
        label: 'User',
        icon: IconName.User,
        options: uniq(users.map(u => u.Name) || []),
        getOptionLabel: identity,
      } as const satisfies FilterableProperty),
    }),
    [users]
  );
}

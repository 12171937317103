import {
  CareOrderStatusEnum,
  cleanupInitialFilterDateRange,
  FilterClauseType,
  filterExistsAndExcludes,
  getTypedKeys,
  IconName,
  parseDate,
  removeEmptyFilters,
  useSidesFilter,
  type BlotterTableClientLocalFilter,
  type DateRangeFilter,
  type FilterableProperty,
  type FilterClause,
  type UseFilterBuilderProps,
} from '@talos/kyoko';
import { isAfter, isBefore } from 'date-fns';
import { compact, isArray, isEqual, startCase, values } from 'lodash-es';
import { startTransition, useCallback, useMemo, useState, type SetStateAction } from 'react';
import { isCareOrderRow, type CareOrderBlotterEntity } from './types';

export interface CareOrderFilter extends DateRangeFilter {
  Statuses?: string[];
  Symbols?: string[];
  Groups?: string[];
  Sides?: string;
}

interface UseCareOrderFilterParams {
  initialFilter?: CareOrderFilter;
  saveFilter: (newFilter: any) => void;
}

export function useCareOrderFilter({ initialFilter, saveFilter }: UseCareOrderFilterParams) {
  const [filter, setFilter] = useState(() => cleanupInitialFilterDateRange(initialFilter));

  const changeFilter = useCallback(
    (action: SetStateAction<CareOrderFilter>) => {
      startTransition(() => {
        const priorFilter = filter;
        const newFilter = action instanceof Function ? action(filter) : action;
        if (!isEqual(priorFilter, newFilter)) {
          setFilter(newFilter);
          saveFilter(newFilter);
        }
      });
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<CareOrderBlotterEntity>>(
    row => {
      const data = row.data;
      if (!data) {
        return false;
      }
      if (!isCareOrderRow(data)) {
        // Only filter top level rows (care orders)
        return false;
      }
      const startDate = filter.StartDate == null ? undefined : parseDate(filter.StartDate);
      const endDate = filter.EndDate == null ? undefined : parseDate(filter.EndDate);
      const submitTime = parseDate(data.SubmitTime);
      const timestamp = parseDate(data.Timestamp);
      if (startDate != null) {
        if (isBefore(submitTime, startDate) && isBefore(timestamp, startDate)) {
          return false;
        }
      }
      if (endDate != null) {
        if (isAfter(submitTime, endDate) && isAfter(timestamp, endDate)) {
          return false;
        }
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Statuses', data, 'status')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Sides', data, 'Side')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Groups', data, 'Group')) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const sidesFilter = useSidesFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () => [
      sidesFilter,
      {
        key: 'Statuses',
        label: 'Status',
        icon: IconName.CheckCircle,
        options: values(CareOrderStatusEnum),
        getOptionLabel: startCase,
      },
    ],
    [sidesFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      getTypedKeys(filter).forEach(key => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: (isArray(filter[key]) ? filter[key] : compact([filter[key]])) as string[],
        });
      });
    }

    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: CareOrderFilter = removeEmptyFilters<CareOrderFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies CareOrderFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const filterBuilderProps = useMemo(
    () =>
      ({
        initialFilterClauses,
        properties: filterableProperties,
        onFilterClausesChanged: handleFilterClausesChanged,
      } satisfies UseFilterBuilderProps),
    [initialFilterClauses, filterableProperties, handleFilterClausesChanged]
  );

  return {
    filter,
    filterableProperties,
    initialFilterClauses,
    changeFilter,
    clientSideFilter,
    filterBuilderProps,
  };
}

const colIDToFilterBuilderKeyMap = {
  Symbol: 'Symbols',
  Group: 'Groups',
  Side: 'Sides',
} satisfies Partial<Record<keyof CareOrderBlotterEntity, keyof CareOrderFilter>>;

export function colIDToFilterBuilderKey(
  id: keyof typeof colIDToFilterBuilderKeyMap
): keyof CareOrderFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}

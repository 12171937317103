import type { GridApi, GridOptions, IRowNode } from 'ag-grid-enterprise';
import { get, isEmpty } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDynamicCallback } from '../../../hooks';
import { useConstant } from '../../../hooks/useConstant';
import { logger } from '../../../utils';
import type { BlotterTablePauseProps } from '../BlotterTablePauseButton.types';
import { getAgGridColId } from '../columns/getAgGridColId';
import type { Column } from '../columns/types';
import { compileTransactions } from '../helpers';
import { AGGRID_AUTOCOLUMN_ID, isColumn } from '../types';
import { useColumnDefs } from '../useColumnDefs';
import { getParamsFormatted, isGridApiReady } from '../utils';
import type { UseBlotterTable, UseBlotterTableProps } from './types';
import { useBlotterTableEventHandlers } from './useBlotterTableEventHandlers';
import { useBlotterTableGridOptions } from './useBlotterTableGridOptions';
import { useBlotterTableUtilities } from './useBlotterTableUtilities';

/**
 * Build the arguments for use with the Ava BlotterTable Component
 * @returns
 */
export function useBlotterTable<TRow>({
  dataObservable,
  pinnedRowDataPipe,
  pinnedRowDataObs,
  rowID,
  columns,
  flashRows: initialFlashRows,
  fitColumns = false,
  density,
  showPinnedRows = true,
  clientLocalFilter,
  initialSort,
  handleClickJson,
  context: customBlotterContext,
  quickSearchParams,
  pauseParams,
  customColumnUpdate,
  gridOptions: inputGridOptions,
  suppressGetColumns,
  suppressContextChangeRefreshForce,
  persistence,
}: UseBlotterTableProps<TRow>): UseBlotterTable<TRow> {
  const [paused, setPaused] = useState(false);
  const [api, setApi] = useState<GridApi>();
  const flashRows = useConstant(initialFlashRows);
  const onGridReady: NonNullable<GridOptions<TRow>['onGridReady']> = useCallback(params => {
    setApi(params.api);
  }, []);

  // Subscribe to data and add to blotter
  const dataState = useConstant(new Set<string>());
  useEffect(() => {
    if (isGridApiReady(api) && dataObservable && !paused) {
      const subscription = dataObservable.subscribe(next => {
        if (!isGridApiReady(api)) {
          return;
        }
        if ((isEmpty(next.data) && dataState.size === 0) || rowID == null) {
          api.applyTransactionAsync({ add: [] });
          api.showNoRowsOverlay();
          return;
        }
        const transactions = compileTransactions(dataState, next.data, rowID as string, !!next.initial);

        if (!isEmpty(transactions.add) || !isEmpty(transactions.update) || !isEmpty(transactions.remove)) {
          api.applyTransactionAsync(transactions, () => {
            if (next.initial || !flashRows) {
              return;
            }
            const rowNodeIdInner = rowID;
            if (!rowNodeIdInner) {
              logger.warn('rowID not set, cannot flash rows');
              return;
            }
            for (const flash of flashRows) {
              api.flashCells({
                rowNodes: transactions[flash]
                  .map((row: TRow) => api.getRowNode(get(row, rowNodeIdInner as string)))
                  .filter(row => row != null) as IRowNode[],
                flashDelay: 5000,
                fadeDelay: 2000,
              });
            }
          });
        }
      });

      return () => {
        // Clear out blotter and unsubscribe
        subscription.unsubscribe();
      };
    }
  }, [api, dataObservable, dataState, flashRows, paused, rowID]);

  // We allow the implementer to either pass a pipe in order to chain of for example an internal observable,
  // Or we allow the implementer to provide their own pinnedRowDataObs. But only one of these can be used
  const pinnedRowDataObsToUse = useMemo(
    () => (dataObservable && pinnedRowDataPipe ? dataObservable.pipe(pinnedRowDataPipe) : pinnedRowDataObs),
    [dataObservable, pinnedRowDataPipe, pinnedRowDataObs]
  );

  // Sub to pinnedRowDataObservable and update pinned top row data when it fires
  useEffect(() => {
    if (isGridApiReady(api) && pinnedRowDataObsToUse && !paused) {
      let timer: ReturnType<typeof setTimeout> | null = null;
      const subscription = pinnedRowDataObsToUse.subscribe(next => {
        // Always clear any previous timer before doing anything new
        if (timer != null) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          if (showPinnedRows) {
            // When updating the pinned top row data (for now we only support one pinned top row),
            // we grab the current row and update its internal data to have the row not "re-mount" on each update.
            // Otherwise, on each update, any open context menu stemming from the pinned top row will close
            const pinnedTopRow = api.getPinnedTopRow(0);
            if (pinnedTopRow) {
              pinnedTopRow.setData(next);
            } else {
              api.setGridOption('pinnedTopRowData', [next]);
            }
          }
        }, 0);
      }); // recommended by aggrid to do setTimeout here

      if (!showPinnedRows) {
        api.setGridOption('pinnedTopRowData', []);
      }

      return () => {
        timer != null && clearTimeout(timer);
        subscription.unsubscribe();
      };
    }
  }, [api, showPinnedRows, pinnedRowDataObsToUse, paused]);

  // Utility functions
  const utilities = useBlotterTableUtilities<TRow>(api);

  const columnDefs = useColumnDefs<TRow>(columns, {
    handleClickJson,
    exportDataAsCSV: utilities.exportDataAsCSV,
  });

  // Build GridOptions output
  const resolvedGridOptions = useBlotterTableGridOptions<TRow>({
    gridOptions: inputGridOptions,
    clientLocalFilter,
    columns,
    columnDefs,
    customBlotterContext,
    density,
    getParamsFormatted,
    onGridReady,
    rowID,
    initialSort,
    suppressContextChangeRefreshForce,
    persistence,
  });

  // Create AgGrid ColumnDefs from Columns

  // Mechanism for getting current state of columns in our internal definition
  // - (This is a deprecated mechanism to get column state, and should be avoided if possible)
  const getColumns = useCallback(() => {
    if (suppressGetColumns) {
      return [];
    }
    if (!isGridApiReady(api)) {
      // Throwing in this case as returning an empty array might cause us to overwrite our column definitions by accident
      const error = new Error('getColumns() called too early before grid was mounted and api (GridApi) was defined');
      logger.error(error);
      throw error;
    }
    const next: Column[] = [];
    const state = api.getColumnState();
    // AGGRID_AUTOCOLUMN_ID is a special column that we don't handle from getColumnState
    // TODO: Implement ability to restore state for AutoColumn (optionally)
    const columnsFromState = state.filter(columnState => columnState.colId !== AGGRID_AUTOCOLUMN_ID);
    for (const columnState of columnsFromState) {
      const column = columns.filter(isColumn).find(column => columnState.colId === getAgGridColId(column));
      if (column == null) {
        console.warn(`Could not find column for ${columnState.colId}`, columnState);
      } else {
        /**
         * See https://www.ag-grid.com/javascript-data-grid/column-properties/#reference-pinned
         *
         * - `true` means pin left
         * - `false` or `null` means do not pin (hence the `|| undefined` at the end).
         */
        const pinned = (columnState.pinned === true ? 'left' : columnState.pinned) || undefined;

        next.push({
          ...column,
          width: columnState.width,
          hide: columnState.hide === null ? undefined : columnState.hide,
          pinned,
          rowGroup: columnState.rowGroup ?? undefined,
          rowGroupIndex: columnState.rowGroupIndex ?? undefined,
        });
      }
    }
    return next;
  }, [api, columns, suppressGetColumns]);

  // Perform custom column updates if needed based on the current state of the blotter and data
  useEffect(() => {
    const cleanup = customColumnUpdate?.({
      dataObservable,
      autoGroupColumnDef: resolvedGridOptions.autoGroupColumnDef,
      columnDefs,
      sort: initialSort,
      api,
    });
    return cleanup;
  }, [
    api,
    columnDefs,
    customColumnUpdate,
    dataObservable,
    fitColumns,
    resolvedGridOptions.autoGroupColumnDef,
    initialSort,
  ]);

  // Event handlers
  useBlotterTableEventHandlers(
    {
      columns,
      onRowClicked: resolvedGridOptions.onRowClicked,
      getColumns,
      persistence,
    },
    api
  );

  const [quickFilterText, setQuickFilterText] = useState('');

  useEffect(() => {
    if (api) {
      // We allow the implementer to control the filterText if they want. Otherwise, we hold the state.
      api.setGridOption('quickFilterText', quickSearchParams?.filterText ?? quickFilterText);
    }
  }, [api, quickSearchParams?.filterText, quickFilterText]);

  // Whenever clientLocalFilter or the quickFilterText states change, we tell the blotter that filters have changed
  // onFilterChange called in timeout to allow updates to filter function to get picked up correctly by grid
  useEffect(() => {
    const timeout = setTimeout(() => {
      api?.onFilterChanged();
    });
    return () => {
      clearTimeout(timeout);
    };
  }, [api, clientLocalFilter]);

  const pause = useDynamicCallback(() => {
    setPaused(true);
  });

  const resume = useDynamicCallback(() => {
    api?.setGridOption('rowData', []);
    dataState.clear();
    setPaused(false);
  });

  const pauseProps: BlotterTablePauseProps = useMemo(
    () => ({
      pause,
      paused,
      resume,
      showPauseButton: pauseParams?.showPauseButton ?? false,
    }),
    [pause, paused, resume, pauseParams]
  );

  useEffect(() => {
    api?.resetRowHeights();
  }, [api, resolvedGridOptions?.rowHeight]);

  return {
    dataObservable,
    gridOptions: resolvedGridOptions,
    density,
    initialSort: initialSort,
    getColumns,
    blotterTableFiltersProps: {
      quickFilterText,
      onQuickFilterTextChanged: setQuickFilterText,
      ...pauseProps,
    },
    ...utilities,
  };
}

import { isEqual } from 'lodash-es';
import { getTypedEntries } from '../../../../utils';
import { FilterClauseType, type FilterClause } from '../types';

/**
 * A simple utility function to, given two filter clause selection arrays, returns if the move from the former to the latter
 * signifies a meaningfull filter change which should be applied to your state
 */
export function isMeaningfulFilterClauseSelectionsChange(
  prev: string[] | undefined,
  next: string[] | undefined
): boolean {
  if (isEqual(prev, next)) {
    return false;
  }

  // If both are either [], undefined, in whatever order, we don't care
  const arr = [prev, next];
  if (arr.includes(undefined) && arr.includes([])) {
    return false;
  }

  return true;
}

export function createFilterClausesFromQueryParams(
  queryParams: Partial<Record<string, string>>
): Map<string, FilterClause> {
  const filterClauses: Map<string, FilterClause> = new Map();
  for (const [key, param] of Object.entries(queryParams)) {
    if (!param) {
      continue;
    }
    if (param.includes('&&')) {
      filterClauses.set(key, {
        key: key,
        selections: param.split('&&'),
        type: FilterClauseType.INCLUSIVE,
      });
    } else if (param.includes('||')) {
      filterClauses.set(key, {
        key: key,
        selections: param.split('||'),
        type: FilterClauseType.EXCLUSIVE,
      });
    } else {
      filterClauses.set(key, {
        key: key,
        selections: [param],
        type: FilterClauseType.INCLUSIVE,
      });
    }
  }
  return filterClauses;
}

export function createQueryParamsFromFilterClauses(filterClauses: Map<string, FilterClause>): Record<string, string> {
  const queryParams: Record<string, string> = {};
  for (const [key, clause] of filterClauses.entries()) {
    if (clause.selections.length === 0) {
      continue;
    } else if (clause.type === FilterClauseType.INCLUSIVE) {
      queryParams[key] = clause.selections.join('&&');
    } else {
      queryParams[key] = clause.selections.join('||');
    }
  }
  return queryParams;
}

export function createPersistedFilterFromQueryParams(
  queryParams: Partial<Record<string, string>>
): Record<string, string[]> {
  const filter: Record<string, string[]> = {};
  for (const [key, param] of getTypedEntries(queryParams)) {
    if (!param) {
      continue;
    }
    if (param.includes('&&')) {
      filter[key] = param.split('&&');
    } else if (param.includes('||')) {
      filter[key] = param.split('||');
    } else {
      filter[key] = [param];
    }
  }
  return filter;
}

import type { ICellRendererParams } from 'ag-grid-community';
import type { BigSource } from 'big.js';
import { get } from 'lodash-es';
import { toBig } from '../../../utils';
import { InlineFormattedNumber, NumberVariants, type InlineFormattedNumberProps } from '../../FormattedNumber';
import { sumAggFunc } from '../aggFuncs';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';
import { numericColumnComparator } from './utils';

export interface NumberColumnParams extends Omit<InlineFormattedNumberProps, 'number'> {
  align?: 'left' | 'right';
  highlightNegative?: boolean;
  currencyField?: string;
  /**
   * The value will be multiplied with this number before being displayed.
   */
  multiplier?: number;
}

// Cast a wide net here so we're forced to program the column defensively below
type NumberColumnValue = BigSource | undefined | null;

export const number: ColDefFactory<Column<NumberColumnParams>> = column => ({
  ...baseColumn(column),
  type: 'numericColumn',
  cellEditor: 'amountInput',
  cellRenderer: ({ value, data }: ICellRendererParams<unknown, NumberColumnValue>) => {
    // This if statement is here so we do not render any undefined or empty-string value.
    // This takes effect when you for example are passing in a suffix (via the currency prop) to InlineFormattedNumber, eg "BPS".
    // If we were rendering InlineFormattedNumber with empty string, you'd just have a bunch of suffixes BPS, BPS, BPS... being
    // rendered for no reason. We'd rather have an empty cell if there's no value to render.
    if (value == null || value === '') {
      return '';
    }

    let currency: string | undefined = undefined;
    if (column.params?.currencyField) {
      currency = get(data, column.params.currencyField);
    } else if (column.params?.currency) {
      currency = column.params.currency;
    }

    const variant = column.params?.highlightNegative && toBig(value)?.lt(0) ? NumberVariants.Negative : undefined;
    const adjustedValue = column.params?.multiplier ? toBig(value)?.times(column.params.multiplier) : value;

    return <InlineFormattedNumber number={adjustedValue} variant={variant} {...column.params} currency={currency} />;
  },
  headerClass: () => {
    const columnGroupClass = column.columnGroup ? 'ag-custom-column-group' : '';
    return column.params?.align !== 'left' ? `ag-right-aligned-header ${columnGroupClass}` : `${columnGroupClass}`;
  },
  comparator: numericColumnComparator,
  aggFunc: column.aggregate ? sumAggFunc : undefined,
});

import { BLOTTER_TABLE_FILTERS_CONTAINER_ID, Box, Flex, HStack, Text, ToggleHorizontal, usePortal } from '@talos/kyoko';
import { useCallback } from 'react';
import { useFeatureFlag } from '../../../../../hooks';
import { useAppStateDispatch } from '../../../../../providers/AppStateProvider';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import { LongShortStackedChart } from '../LongShortStackedChart';
import { PortfolioBreakdownBlotterWrapper } from '../PortfolioBreakdownBlotter/PortfolioBreakdownBlotterWrapper';
import { PortfolioOverviewHUD } from './PortfolioOverviewHUD';

type ChartDisplayType = 'byUnderlying' | 'byInstrument';
const chartDisplayOptions: Array<{ value: ChartDisplayType; displayName: string }> = [
  {
    value: 'byInstrument',
    displayName: 'By Instrument',
  },
  {
    value: 'byUnderlying',
    displayName: 'By Underlying',
  },
];

const { updateOverviewChartDisplayType } = getPortfolioViewActions();
export const PortfolioOverview = () => {
  const { enableMarginManagementFeatures } = useFeatureFlag();
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  const { overviewChartDisplayType } = usePortfolioViewStateSelector();
  const dispatch = useAppStateDispatch();

  const onDisplayTypeChange = useCallback(
    (displayType: ChartDisplayType) => {
      dispatch(updateOverviewChartDisplayType(displayType));
    },
    [dispatch]
  );

  return (
    <Box w="100%" h="100%" data-testid="portfolio-management-overview-wrapper">
      <Flex flexDirection="column" gap="spacingTiny" w="100%" h="100%">
        <HStack px="spacingComfortable" justifyContent="space-between" background="backgroundContent">
          <HStack>
            <Text py="spacingComfortable" fontSize="fontSizeMd">
              Portfolio Breakdown
            </Text>
          </HStack>
          <HStack gap="spacingDefault">
            <ToggleHorizontal<ChartDisplayType>
              value={overviewChartDisplayType}
              options={chartDisplayOptions}
              onChange={onDisplayTypeChange}
            />
            <Box ref={filtersContainerRef} />
          </HStack>
        </HStack>
        {enableMarginManagementFeatures && (
          <HStack>
            <PortfolioOverviewHUD />
          </HStack>
        )}
        <Flex flex="none" h="220px" maxHeight="220px" data-testid="portfolio-overview-chart">
          <LongShortStackedChart />
        </Flex>
        <Flex justifyContent="stretch" flex="auto" data-testid="portfolio-overview-grid">
          <PortfolioBreakdownBlotterWrapper />
        </Flex>
      </Flex>
    </Box>
  );
};

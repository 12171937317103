import {
  AssetTransactionTypeEnum,
  FilterClauseType,
  IconName,
  cleanupInitialFilterDateRange,
  removeEmptyFilters,
  useAssetsFilter,
  useDateRangeFilter,
  type AssetTransaction,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { compact, identity, isEqual, keys, pick, values } from 'lodash-es';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useAllocationStatusFilter } from '../../../hooks';
import { useMarketAccountsFilter } from '../../../hooks/filters/useMarketAccountsFilter';
import { useMarketsFilter } from '../../../hooks/filters/useMarketsFilter';

export interface AssetTransactionsFilter extends DateRangeFilter {
  Markets?: string[];
  MarketAccounts?: string[];
  Symbols?: string[];
  PositionAssets?: string[];
  AssetTransactionTypes?: string[];
  AllocationStatuses?: string;
}

export interface UseAssetTransactionsFilterParams<TData extends AssetTransaction> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}
export function useAssetTransactionsFilter<TData extends AssetTransaction>({
  persistedBlotterTable,
}: UseAssetTransactionsFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [initialFilter] = useState(() => cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter));
  const [filter, setFilter] = useState<AssetTransactionsFilter>((initialFilter as AssetTransactionsFilter) || {});

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const marketsFilter = useMarketsFilter();
  const marketAccountsFilter = useMarketAccountsFilter();
  const assetsFilter = useAssetsFilter();
  const allocationStatusFilter = useAllocationStatusFilter();
  const filterableProperties = useMemo(
    () =>
      compact<FilterableProperty<keyof AssetTransactionsFilter>>([
        marketsFilter,
        marketAccountsFilter,
        assetsFilter,
        { ...assetsFilter, key: 'PositionAssets', label: 'Symbol' },
        {
          label: 'Transaction Type',
          key: 'AssetTransactionTypes',
          options: values(AssetTransactionTypeEnum),
          getOptionLabel: identity,
          icon: IconName.Puzzle,
        },
        allocationStatusFilter,
      ]),
    [marketsFilter, marketAccountsFilter, assetsFilter, allocationStatusFilter]
  );
  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof AssetTransactionsFilter)[]).forEach((key: keyof AssetTransactionsFilter) => {
        switch (key) {
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: AssetTransactionsFilter = removeEmptyFilters<AssetTransactionsFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies AssetTransactionsFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return {
    initialFilter,
    filter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
}

const colIDToFilterBuilderKeyMap = {
  MarketAccount: 'MarketAccounts',
  Market: 'Markets',
  Asset: 'Symbols',
  PositionAsset: 'PositionAssets',
  Type: 'AssetTransactionTypes',
  AllocationStatus: 'AllocationStatuses',
} satisfies Partial<Record<keyof AssetTransaction, keyof AssetTransactionsFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof AssetTransactionsFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}

export function onlyServerFilterKeys(filter: AssetTransactionsFilter | undefined) {
  if (!filter) {
    return filter;
  }
  const serverFilter = pick({ ...filter }, [
    'StartDate',
    'EndDate',
    'Markets',
    'MarketAccounts',
    'Symbols',
    'AssetTransactionTypes',
    'PositionAssets',
    'AllocationStatuses',
  ]);
  return serverFilter;
}

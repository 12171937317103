import { PORTFOLIO_OTC_EXPOSURE, useObservable, useObservableValue, useStaticSubscription } from '@talos/kyoko';
import type { PortfolioExposure } from 'containers/Portfolio/types';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import { map, scan, shareReplay } from 'rxjs';

export const PortfolioExposuresContext = createContext<PortfolioExposuresContextProps | undefined>(undefined);
PortfolioExposuresContext.displayName = 'PortfolioExposuresContext';

export interface PortfolioExposuresContextProps {
  exposuresList: PortfolioExposure[] | undefined;
  exposuresByMarketAccount: Map<string, PortfolioExposure> | undefined;
}

export function usePortfolioExposures() {
  const context = useContext(PortfolioExposuresContext);
  if (context === undefined) {
    throw new Error('Missing PortfolioExposuresContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const PortfolioExposuresProvider = function PortfolioExposuresProvider({ children }: PropsWithChildren) {
  const { data: exposuresRawObs } = useStaticSubscription<PortfolioExposure>({
    name: PORTFOLIO_OTC_EXPOSURE,
    tag: 'PortfolioExposuresProvider',
  });

  const exposuresListObs = useObservable(
    () =>
      exposuresRawObs.pipe(
        scan((map, json) => {
          if (json.initial) {
            map.clear();
          }
          for (const data of json.data) {
            const key = `${data.MarketAccount}`;

            // Let's filter out all empty exposure limits since we don't care in that case
            if (data.ExposureLimit === '') {
              map.delete(key);
            } else {
              map.set(key, data);
            }
          }
          return map;
        }, new Map<string, PortfolioExposure>()),
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [exposuresRawObs]
  );

  const exposuresByMarketAccountObs = useObservable(
    () =>
      exposuresListObs.pipe(
        map(list => new Map(list.map(exposure => [exposure.MarketAccount, exposure]))),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [exposuresListObs]
  );

  const exposuresList = useObservableValue(() => exposuresListObs, [exposuresListObs]);
  const exposuresByMarketAccount = useObservableValue(() => exposuresByMarketAccountObs, [exposuresByMarketAccountObs]);

  const value = useMemo(
    () => ({
      exposuresList,
      exposuresByMarketAccount,
    }),
    [exposuresList, exposuresByMarketAccount]
  );

  return <PortfolioExposuresContext.Provider value={value}>{children}</PortfolioExposuresContext.Provider>;
};

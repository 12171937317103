import styled from 'styled-components';
import { parseColor } from '../../styles';
import { Box } from '../Core';

export const Divider = styled(Box)<{ orientation?: 'horizontal' | 'vertical'; thickness?: number }>`
  ${({ theme, orientation, m, my, mt, mb, color, thickness }) =>
    orientation === 'vertical'
      ? `
      border-left: solid ${thickness ? thickness : 1}px ${color ? parseColor(theme, color) : theme.borderColorDivider};
      width: ${thickness ? thickness : 1}px;
      ${
        [m, my, mt, mb].some(x => x)
          ? ''
          : `margin-top: ${theme.spacingSmall}px; margin-bottom: ${theme.spacingSmall}px`
      }`
      : `
      border-top: solid ${thickness ? thickness : 1}px ${color ? parseColor(theme, color) : theme.borderColorDivider};
      height: ${thickness ? thickness : 1}px;`}
`;

Divider.defaultProps = {
  alignSelf: 'stretch',
};

import {
  TreasuryLinkSpecificness,
  TreasuryLinkTransactionType,
  TreasuryLinkTypeEnum,
  useMarketAccountsContext,
  type Market,
  type MarketAccount,
  type TreasuryLinkSourceOrDestinationField,
} from '@talos/kyoko';
import type { DestinationAccount } from 'containers/Portfolio/types';
import { useCustomerDisplayName } from 'hooks';
import { lowerCase } from 'lodash-es';

/**
 * Get the pretty display name by combining the market account and market display names
 * @param marketAccount Market Account Object
 * @param market Market Object
 * @returns a concatenated string to be used as the display name
 */
export const getTreasuryAccountDisplayValue = (marketAccount?: MarketAccount, market?: Market): string | undefined => {
  if (marketAccount == null || market == null) {
    return undefined;
  }

  const marketDisplayName = market.DisplayName || market.Name;
  const marketAccountDisplayName = marketAccount?.DisplayName ?? marketAccount?.Name;

  return `${marketDisplayName} - ${marketAccountDisplayName}`;
};

/**
 * Get the display label of the treasury link type
 * @param type TreasuryLinkTypeEnum
 * @returns 'Customer' | 'Exchange' | 'OTC' | undefined
 */
export function getTreasuryLinkTypeLabel(type: TreasuryLinkTypeEnum | undefined) {
  return type === TreasuryLinkTypeEnum.CustomerTransfer
    ? 'Customer'
    : type === TreasuryLinkTypeEnum.ExchangeRebalance
    ? 'Exchange'
    : type === TreasuryLinkTypeEnum.OTCSettlement
    ? 'OTC'
    : undefined;
}

export function getSourceOrDestinationFallbackLabel(
  specificness: TreasuryLinkSpecificness,
  linkTypeLabel: string | undefined
): string | undefined {
  switch (specificness) {
    case TreasuryLinkSpecificness.MarketAccount:
      return `${linkTypeLabel} Default`;
    case TreasuryLinkSpecificness.Type:
    case TreasuryLinkSpecificness.Currency:
      return 'Global Default';
    default:
      return undefined;
  }
}

/**
 * Get an array of summaries given a type, transactionType, and inform
 * @param type TreasuryLinkTypeEnum
 * @param transactionType TreasuryLinkTransactionType
 * @param counterparty Name of the counterparty being summarized
 * @param isHeaderSummary Helper to exclude summaries for header rows
 * @returns An array of TreasuryLinkSourceOrDestinationField's: 'SourceMarketAccount' | 'DestinationMarketAccount' | 'DestinationAddress'
 */
const getAddressSummaryFields = (
  type: TreasuryLinkTypeEnum,
  transactionType: TreasuryLinkTransactionType | undefined,
  counterparty: string | undefined,
  isHeaderSummary: boolean | undefined
): TreasuryLinkSourceOrDestinationField[] => {
  switch (type) {
    case TreasuryLinkTypeEnum.CustomerTransfer: {
      if (transactionType === TreasuryLinkTransactionType.Withdrawals) {
        if (counterparty == null) {
          return ['SourceMarketAccount'];
        } else {
          return ['SourceMarketAccount', 'DestinationMarketAccount'];
        }
      }
      if (counterparty == null || isHeaderSummary) {
        return ['DestinationMarketAccount'];
      } else {
        // Only return DestinationAddress if we're editing a specific Currency (not a header or customer defaults)
        return ['DestinationMarketAccount', 'DestinationAddress'];
      }
    }
    case TreasuryLinkTypeEnum.ExchangeRebalance:
      if (counterparty == null) {
        return ['SourceMarketAccount'];
      } else {
        return ['SourceMarketAccount', 'DestinationMarketAccount'];
      }
    case TreasuryLinkTypeEnum.OTCSettlement:
      if (counterparty == null) {
        return ['SourceMarketAccount'];
      } else {
        return ['SourceMarketAccount', 'DestinationMarketAccount'];
      }
    default:
      return [];
  }
};

const getDisplayTitle = (type: TreasuryLinkTypeEnum, hasMarketAccount: boolean) => {
  if (!hasMarketAccount) {
    switch (type) {
      case TreasuryLinkTypeEnum.CustomerTransfer: {
        return 'Global Customer Defaults';
      }
      case TreasuryLinkTypeEnum.ExchangeRebalance:
        return 'Global Exchange Defaults';
      case TreasuryLinkTypeEnum.OTCSettlement:
        return 'Global OTC Defaults';
      default:
        return '';
    }
  }
  return `Defaults`;
};

interface HeaderTooltipProps {
  sourceOrDestinationField: TreasuryLinkSourceOrDestinationField;
  type: TreasuryLinkTypeEnum;
  transactionType?: TreasuryLinkTransactionType;
  isDefaultEntity: boolean;
}

const getTooltips = ({
  sourceOrDestinationField,
  type,
  transactionType,
  isDefaultEntity,
}: HeaderTooltipProps): { headerTooltip: string; summaryTooltip: string } => {
  switch (type) {
    case TreasuryLinkTypeEnum.CustomerTransfer: {
      if (transactionType === TreasuryLinkTransactionType.Withdrawals) {
        if (isDefaultEntity) {
          // Editing for Customer Default
          if (sourceOrDestinationField === 'SourceMarketAccount') {
            return {
              summaryTooltip: 'Default account from which you send withdrawals to customers.',
              headerTooltip: 'Default account per currency from which you send withdrawals to customers.',
            };
          }
        } else {
          // Editing per Market Account
          if (sourceOrDestinationField === 'SourceMarketAccount') {
            return {
              summaryTooltip: 'Account from which you send withdrawals to this customer account.',
              headerTooltip: 'Account per currency from which you send withdrawals to this customer account.',
            };
          } else if (sourceOrDestinationField === 'DestinationMarketAccount') {
            return {
              summaryTooltip: 'Account where you send withdrawals to this customer account.',
              headerTooltip: 'Address per currency where you send withdrawals to this customer account.',
            };
          }
        }
      } else if (transactionType === TreasuryLinkTransactionType.Deposits) {
        if (isDefaultEntity) {
          // Editing for Customer Default
          if (sourceOrDestinationField === 'DestinationMarketAccount') {
            return {
              summaryTooltip: 'Default account where you receive deposits from this customer account.',
              headerTooltip: 'Default address per currency where you receive deposits from customers.',
            };
          }
        } else {
          // Editing per Market Account
          if (sourceOrDestinationField === 'SourceMarketAccount') {
            return {
              summaryTooltip: 'Account where you receive deposits from this customer account.',
              headerTooltip: 'Account per currency where you receive deposits from this customer account.',
            };
          } else if (sourceOrDestinationField === 'DestinationMarketAccount') {
            return {
              summaryTooltip: 'Account where you receive deposits from this customer account.',
              headerTooltip: 'Account per currency where you receive deposits from this customer account.',
            };
          } else if (sourceOrDestinationField === 'DestinationAddress') {
            return {
              summaryTooltip: 'Address where you receive deposits from this customer account.',
              headerTooltip: 'Address per currency where you receive deposits from this customer account.',
            };
          }
        }
      }
      break;
    }
    case TreasuryLinkTypeEnum.ExchangeRebalance: {
      if (isDefaultEntity) {
        // Editing for Exchange Default
        if (sourceOrDestinationField === 'SourceMarketAccount') {
          return {
            summaryTooltip: 'Default account from which you send assets to exchanges.',
            headerTooltip: 'Default account per currency from which you send assets to exchanges.',
          };
        }
      } else {
        // Editing per Market Account
        if (sourceOrDestinationField === 'SourceMarketAccount') {
          return {
            summaryTooltip: 'Account from which you send assets to this exchange account.',
            headerTooltip: 'Account per currency from which you send assets to this exchange account.',
          };
        } else if (sourceOrDestinationField === 'DestinationMarketAccount') {
          return {
            summaryTooltip: 'Account from which you send assets to this exchange account.',
            headerTooltip: 'Address per currency from which you send assets to this exchange account.',
          };
        }
      }
      break;
    }
    case TreasuryLinkTypeEnum.OTCSettlement: {
      if (isDefaultEntity) {
        // Editing for OTC Default
        if (sourceOrDestinationField === 'SourceMarketAccount') {
          return {
            summaryTooltip: 'Default account from which you send assets to OTCs.',
            headerTooltip: 'Default account per currency from from which you send withdrawals to OTCs.',
          };
        }
      } else {
        // Editing per Market Account
        if (sourceOrDestinationField === 'SourceMarketAccount') {
          return {
            summaryTooltip: 'Account from which you send assets to this OTC account.',
            headerTooltip: 'Account per currency from which you send assets to this OTC account.',
          };
        } else if (sourceOrDestinationField === 'DestinationMarketAccount') {
          return {
            summaryTooltip: 'Account from which you send assets to this OTC account.',
            headerTooltip: 'Address per currency from which you send assets to this OTC account.',
          };
        }
      }
      break;
    }
    default:
      break;
  }

  return { summaryTooltip: 'NOT RIGHT', headerTooltip: 'NOT RIGHT' };
};

interface DefaultAddressSummary {
  sourceOrDestinationField: TreasuryLinkSourceOrDestinationField;
  subtitle?: string;
  displayTitle?: string;
  displayNameLong?: string;
  description?: string;
  selectorTitle: string;
  summaryTooltip: string;
  headerTooltip: string;
}

/**
 * @param type TreasuryLinkTypeEnum
 * @param transactionType The TreasuryLinkTransactionType
 * @param entity The Market Account Object
 * @param isHeaderSummary Helper to exclude summaries for header rows
 * @returns An Array of { description, title, subtitle, icon, value, displayValue }
 */
export const useAddressSummary = (
  type: TreasuryLinkTypeEnum,
  transactionType?: TreasuryLinkTransactionType,
  entity?: MarketAccount | DestinationAccount,
  isHeaderSummary?: boolean
): DefaultAddressSummary[] => {
  const { Counterparty: counterpartyName, Name: marketAccountName } = entity ?? {};
  const { marketAccountsByName } = useMarketAccountsContext();
  const customerName = useCustomerDisplayName(counterpartyName);

  const fields = getAddressSummaryFields(type, transactionType, counterpartyName, isHeaderSummary);

  return fields.map<DefaultAddressSummary>(sourceOrDestinationField => {
    const displayTitle = getDisplayTitle(type, entity != null);
    const selectorTitle = `${counterpartyName == null ? 'Global' : ''} Default ${
      sourceOrDestinationField === 'SourceMarketAccount' ? 'Source' : 'Destination'
    }`;

    const suffixLabel = getTreasuryLinkTypeLabel(type) ?? 'Global';

    const displayLabel =
      type === TreasuryLinkTypeEnum.CustomerTransfer && counterpartyName
        ? customerName
        : type === TreasuryLinkTypeEnum.ExchangeRebalance
        ? 'Exchange'
        : type === TreasuryLinkTypeEnum.OTCSettlement
        ? 'OTC'
        : lowerCase(suffixLabel);

    const displayNameLong =
      marketAccountName && counterpartyName
        ? `${displayLabel ?? suffixLabel} - ${
            type === TreasuryLinkTypeEnum.CustomerTransfer
              ? // For customers, we wanna show the SourceAccountID
                marketAccountsByName.get(marketAccountName)?.SourceAccountID ?? marketAccountName
              : // And for non-customers, we prefer the normal DisplayName
                marketAccountsByName.get(marketAccountName)?.DisplayName ?? marketAccountName
          }`
        : `Global ${suffixLabel} Defaults`;

    const subtitle = 'Defaults apply to all currencies below unless a currency-specific default is configured.';
    let description = `Manage what addresses are used for ${lowerCase(transactionType)} for ${displayNameLong} `;

    const { headerTooltip, summaryTooltip } = getTooltips({
      sourceOrDestinationField,
      type,
      transactionType,
      isDefaultEntity: counterpartyName == null,
    });

    if (counterpartyName == null) {
      description = `Manage what addresses are used by default for ${displayLabel} withdrawals. Defaults are optional and can be overridden per ${displayLabel}.`;
      if (type === TreasuryLinkTypeEnum.CustomerTransfer) {
        switch (transactionType) {
          case TreasuryLinkTransactionType.Withdrawals:
            description = `Manage what addresses are used by default for ${displayLabel} withdrawals. Defaults are optional and can be overridden per ${displayLabel}.`;
            break;
          case TreasuryLinkTransactionType.Deposits:
            description = `Manage what addresses are used by default for ${displayLabel} deposits. Defaults are optional and can be overridden per ${displayLabel}.`;
            break;
        }
      }
    }

    return {
      sourceOrDestinationField,
      description,
      subtitle,
      displayNameLong,
      displayTitle,
      selectorTitle,
      summaryTooltip,
      headerTooltip,
    };
  });
};

import { InstrumentCompositionEnum, ProductTypeEnum, SettleValueTypeEnum } from '@talos/kyoko';

export const perps = [
  {
    ProductType: ProductTypeEnum.PerpetualSwap,
    SettleValueType: SettleValueTypeEnum.Inverted,
    Markets: ['okx'],
    Symbol: 'Perp1',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'BCH',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'BCH',
    PositionCurrency: undefined, // important - Futures don't have this it seems
    NotionalMultiplier: '10',
    NormalSize: '1000',
    MinimumSize: '1',
    Composition: InstrumentCompositionEnum.Native,
  },
  {
    ProductType: ProductTypeEnum.PerpetualSwap,
    SettleValueType: SettleValueTypeEnum.Regular,
    Markets: ['okx'],
    Symbol: 'Perp2',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'BCH',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'BCH',
    NotionalMultiplier: '10',
    NormalSize: '1000',
    MinimumSize: '1',
    Composition: InstrumentCompositionEnum.Native,
  },
  {
    ProductType: ProductTypeEnum.PerpetualSwap,
    SettleValueType: SettleValueTypeEnum.Regular,
    Markets: ['okx'],
    Symbol: 'Perp3',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'BCH',
    QuoteCurrency: 'ETH',
    SettlementCurrency: 'BCH',
    NotionalMultiplier: '10',
    NormalSize: '1000',
    MinimumSize: '1',
    Composition: InstrumentCompositionEnum.Native,
  },
  {
    ProductType: ProductTypeEnum.PerpetualSwap,
    SettleValueType: SettleValueTypeEnum.Regular,
    Markets: ['coinbase'],
    Symbol: 'Perp4-Quanto',
    EndTime: undefined, // not soft deleted
    MinPriceIncrement: '0.01',
    MinSizeIncrement: '1',
    BaseCurrency: 'ETH',
    QuoteCurrency: 'USD',
    SettlementCurrency: 'BCH', // This is a quanto since it's neither a Base/Quote ccy
    PositionCurrency: undefined,
    NotionalMultiplier: '10',
    NormalSize: '1000',
    MinimumSize: '1',
    Composition: InstrumentCompositionEnum.Native,
  },
];

import type { WritableDraft } from 'immer';
import { defineMessages } from 'react-intl';
import {
  FieldValidationLevel,
  numberIsPositive,
  validatePrecision,
  type DateField,
  type FieldValidationRule,
  type NumericField,
} from '../../../../fields';
import { format, getEffectiveMinIncrement, isCounterCurrency, toBigWithDefault } from '../../../../utils';
import type { OrderState } from './types';

const messages = defineMessages({
  minIncrementIsMinIncr: {
    id: 'providers.WLOrderForm.state.OrderSlice.minIncrementIsMinIncr',
    defaultMessage: 'Min increment is {minIncr} {currency}',
  },
  minQuantityIsMinSizeCurrency: {
    id: 'providers.WLOrderForm.state.OrderSlice.minQuantityIsMinSizeCurrency',
    defaultMessage: 'Min quantity is {minSize} {currency}',
  },
  quantityExceedsRejectionThreshold: {
    id: 'providers.WLOrderForm.state.OrderSlice.quantityExceedsRejectionThreshold',
    defaultMessage: 'Quantity exceeds rejection threshold {threshold} {currency}',
  },
  quantityExceedsWarningThreshold: {
    id: 'providers.WLOrderForm.state.OrderSlice.quantityExceedsWarningThreshold',
    defaultMessage: 'Quantity exceeds warning threshold {threshold} {currency}',
  },
  quantityIsRequired: {
    id: 'providers.WLOrderForm.state.OrderSlice.quantityIsRequired',
    defaultMessage: 'Quantity is required',
  },
  quantityShouldBeANumber: {
    id: 'providers.WLOrderForm.state.OrderSlice.quantityShouldBeANumber',
    defaultMessage: 'Quantity should be a number',
  },
  marketAccountIsRequired: {
    id: 'providers.WLOrderForm.state.OrderSlice.marketAccountIsRequired',
    defaultMessage: 'Market Account is required',
  },
  pleaseSelectAnOrderSide: {
    id: 'providers.WLOrderForm.state.OrderSlice.pleaseSelectAnOrderSide',
    defaultMessage: 'Please select an order side',
  },
  displayNameIsRequired: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameIsRequired',
    defaultMessage: '{displayName} is required',
  },
  displayNameShouldBeANumber: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameShouldBeANumber',
    defaultMessage: '{displayName} should be a number',
  },
  displayNameShouldBeGreaterThanZero: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameShouldBeGreaterThanZero',
    defaultMessage: '{displayName} should be greater than 0',
  },
  dateCannotBeInThePast: {
    id: 'providers.WLOrderForm.state.OrderSlice.dateCannotBeInThePast',
    defaultMessage: 'Date cannot be in the past',
  },
  displayNameCannotBeAfter: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameCannotBeAfter',
    defaultMessage: "{displayName} can't be after {prettyName}",
  },
  displayNameCannotBeBefore: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameCannotBeBefore',
    defaultMessage: "{displayName} can't be before {prettyName}",
  },
  displayNameShouldBeADate: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameShouldBeADate',
    defaultMessage: '{displayName} should be a date',
  },
  invalidPercentageValue: {
    id: 'providers.WLOrderForm.state.OrderSlice.invalidPercentageValue',
    defaultMessage: 'Invalid percentage value',
  },
  displayNameMustBeAValidInterval: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameMustBeAValidInterval',
    defaultMessage: '{displayName} must be a valid interval',
  },
  endTimeCannotBeBeforeStartTime: {
    id: 'providers.WLOrderForm.state.OrderSlice.endTimeCannotBeBeforeStartTime',
    defaultMessage: 'End Time cannot be before Start Time',
  },
  startTimeCannotBeAfterEndTime: {
    id: 'providers.WLOrderForm.state.OrderSlice.startTimeCannotBeAfterEndTime',
    defaultMessage: 'Start Time cannot be after End Time',
  },
  timeCannotBeInThePast: {
    id: 'providers.WLOrderForm.state.OrderSlice.timeCannotBeInThePast',
    defaultMessage: 'Time cannot be in the past',
  },
  quantityCannotBeLessThanAlreadyExecutedQuantity: {
    id: 'providers.WLOrderForm.state.OrderSlice.quantityCannotBeLessThanAlreadyExecutedQuantity',
    defaultMessage: 'Quantity cannot be less than already executed quantity: {CumQty}',
  },
  cantVerifyMinimumQuantity: {
    id: 'providers.WLOrderForm.state.OrderSlice.cantVerifyMinimumQuantity',
    defaultMessage: `Can’t verify minimum quantity: {quantity} {currency}`,
  },
});

export function validateQuantity(state: WritableDraft<OrderState>) {
  state.form.quantityField = state.form.quantityField.validate(
    [
      numberIsPositive,
      quantitySymbolValidation,
      quantityTradingLimitsValidation,
      quantityMoreThanMinSize,
      quantityNotLessThanModifiedOrderCumQtyValidation,
    ],
    state
  );
}

const quantitySymbolValidation: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (field, context) => {
  if (!context) {
    return null;
  }

  const form = context.form;
  const security = form.symbolField.value;
  if (!security) {
    return null;
  }
  const minIncr = getEffectiveMinIncrement(security, isCounterCurrency(form.orderCurrencyField.value, security));

  if (!validatePrecision(minIncr, field.value)) {
    return {
      message: context.intl.formatMessage(messages.minIncrementIsMinIncr, {
        minIncr,
        currency: form.orderCurrencyField.value,
      }),
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

const quantityMoreThanMinSize: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (field, context) => {
  if (!context) {
    return null;
  }

  const form = context.form;
  const security = form.symbolField.value;
  const ccConversionRate = context.dependencies.ccConversionRate;
  const isCcy = isCounterCurrency(form.orderCurrencyField.value, security);
  if (!security) {
    return null;
  }

  // [DEAL-4555] MinimumSize in BaseCurrency, MinimumAmount in QuoteCurrency
  const { MinimumSize, MinimumAmount } = security;
  const baseToQuoteConversionRate = ccConversionRate?.Rate;

  const minimumSizeInBaseCurrency = toBigWithDefault(MinimumSize, 0);
  const minimumSizeInQuoteCurrency = baseToQuoteConversionRate
    ? minimumSizeInBaseCurrency.times(baseToQuoteConversionRate)
    : null;
  const minimumAmountInQuoteCurrency = toBigWithDefault(MinimumAmount, 0);
  const minimumAmountInBaseCurrency = baseToQuoteConversionRate
    ? minimumAmountInQuoteCurrency.div(baseToQuoteConversionRate)
    : null;

  // [DEAL-4555] If the currency conversion is missing, we can't check min amount check for the !ccy (base),
  // so we will only check the min size for the base currency
  // In the opposite case where currency conversion is again missing in a ccy order (quote),
  // we will only check the min amount for the quote currency.
  // If currency conversion is missing in a !ccy order and there is only a min amount minimum in quote, show warning.
  // If currency conversion is missing in a ccy order and there is only a min size minimum in base, show error.
  const getEffectiveMinQuantity = () => {
    if (isCcy && minimumSizeInQuoteCurrency) {
      return toBigWithDefault(minimumSizeInQuoteCurrency, 0).gt(minimumAmountInQuoteCurrency)
        ? minimumSizeInQuoteCurrency
        : minimumAmountInQuoteCurrency;
    } else if (!isCcy && minimumAmountInBaseCurrency) {
      return toBigWithDefault(minimumAmountInBaseCurrency, 0).gt(minimumSizeInBaseCurrency)
        ? minimumAmountInBaseCurrency
        : minimumSizeInBaseCurrency;
    }

    return isCcy ? minimumAmountInQuoteCurrency : minimumSizeInBaseCurrency;
  };

  // [DEAL-4555] Min quantity errors rely on the conversion rate if using the opposite minimum.
  if (
    // [DEAL-4784] Only show this warning if the baseToQuoteConversionRate is missing
    !baseToQuoteConversionRate &&
    ((isCcy && !MinimumAmount && minimumSizeInBaseCurrency) || (!isCcy && !MinimumSize && minimumAmountInQuoteCurrency))
  ) {
    // We will show this error in the opposite currency if the conversion rate is missing
    const oppositeQuantity = format(isCcy ? MinimumSize : MinimumAmount, {
      spec:
        // Negate !isCcy when getting min increment, since we are showing the opposite minimum.
        getEffectiveMinIncrement(security, !isCcy),
    });
    const oppositeCurrency = isCcy ? security.BaseCurrency : security.QuoteCurrency;
    return {
      message: context.intl.formatMessage(messages.cantVerifyMinimumQuantity, {
        quantity: oppositeQuantity,
        currency: oppositeCurrency,
      }),
      level: FieldValidationLevel.Warning,
    };
  }

  const effectiveMinQuantity = getEffectiveMinQuantity();
  // [DEAL-4555] Show Min Quantity error if order size is smaller than max(MinimumSize, MinimumAmount / MIN_AMOUNT_TOLERANCE)
  if (toBigWithDefault(field.value, 0).lt(effectiveMinQuantity)) {
    return {
      message: context.intl.formatMessage(messages.minQuantityIsMinSizeCurrency, {
        minSize: format(effectiveMinQuantity, { spec: getEffectiveMinIncrement(security, isCcy) }),
        currency: form.orderCurrencyField.value,
      }),
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

const quantityNotLessThanModifiedOrderCumQtyValidation: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (
  field,
  context
) => {
  if (!context || !context.orderBeingModified) {
    return null;
  }

  // Ensure that the quantity is not less than the modified order's CumQty
  if (field.value && toBigWithDefault(field.value, 0).gte(toBigWithDefault(context.orderBeingModified.CumQty, 0))) {
    return null;
  } else {
    return {
      message: context.intl.formatMessage(messages.quantityCannotBeLessThanAlreadyExecutedQuantity, {
        CumQty: context.orderBeingModified.CumQty,
      }),
      level: FieldValidationLevel.Error,
    };
  }
};

const quantityTradingLimitsValidation: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (
  field,
  context
) => {
  if (!context?.dependencies.tradingLimitsValidation) {
    return null;
  }
  const { reject, warn, limit } = context.dependencies.tradingLimitsValidation;
  if (!limit) {
    return null;
  }

  if (reject) {
    return {
      message: context.intl.formatMessage(messages.quantityExceedsRejectionThreshold, {
        threshold: format(limit.RejectThreshold),
        currency: limit.ThresholdCurrency,
      }),
      level: FieldValidationLevel.Error,
    };
  }
  if (warn) {
    return {
      message: context.intl.formatMessage(messages.quantityExceedsWarningThreshold, {
        threshold: format(limit.WarnThreshold),
        currency: limit.ThresholdCurrency,
      }),
      level: FieldValidationLevel.Warning,
    };
  }
  return null;
};

export const notInPastValidation: FieldValidationRule<DateField, WritableDraft<OrderState>> = (field, context) => {
  if (!context || !field.hasValue) {
    return null;
  }

  const now = new Date();
  const time = field.value!;

  if (now.valueOf() - new Date(time).valueOf() > 0) {
    return {
      message: context.intl.formatMessage(messages.timeCannotBeInThePast),
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

export const endTimeValidation: FieldValidationRule<DateField, WritableDraft<OrderState>> = (field, context) => {
  if (!context || !context.form.parameters.StartTime?.hasValue || !field.value) {
    return null;
  }

  const startTime = context.form.parameters.StartTime.value;
  const endTime = context.form.parameters.EndTime.value;

  const comparingAgainstEndTime = field.name.toLocaleLowerCase().includes('start');

  if (new Date(startTime).valueOf() - new Date(endTime).valueOf() >= 0) {
    let message = context.intl.formatMessage(messages.endTimeCannotBeBeforeStartTime);
    if (comparingAgainstEndTime) {
      message = context.intl.formatMessage(messages.startTimeCannotBeAfterEndTime);
    }

    return {
      message,
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

import { useMemo } from 'react';
import type { DefaultTheme } from 'styled-components';
import { ThemeTypes, themes } from '../styles';
import { logger } from '../utils';
import { usePreferredColorScheme } from './usePreferredColorScheme';

/**
 * Gets the active theme based on user preferences and theme.
 */
export function useEffectiveThemeType(themeType: ThemeTypes): ThemeTypes.light | ThemeTypes.dark {
  const preferredType = usePreferredColorScheme();
  return themeType === ThemeTypes.auto ? preferredType : themeType;
}

export const usePreferredTheme = (themeName: string, themeType: ThemeTypes): DefaultTheme => {
  const preferredType = usePreferredColorScheme();
  const preferredThemeType = themeType === ThemeTypes.auto ? preferredType : themeType;
  return useThemeFromType(themeName, preferredThemeType);
};

export function useThemeFromType(themeName: string, themeType?: ThemeTypes) {
  return useMemo(() => {
    const preferredTheme =
      themeType != null
        ? themeType === ThemeTypes.light
          ? themeName.replace('Dark', 'Light')
          : themeName.replace('Light', 'Dark')
        : themeName;

    if (preferredTheme in themes) {
      return (themes as any)[preferredTheme];
    }
    if (themeName in themes) {
      return (themes as any)[themeName];
    }

    // Turns out, the theme is not in the themes object, lets default back to default theme and log error
    logger.error(new Error(`Theme: ${themeName} not found in themes object, defaulting to talos theme`));
    return themeType === ThemeTypes.light ? themes.LightTheme : themes.DarkTheme;
  }, [themeName, themeType]);
}

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface NavigationState {
  path: string | null;
}

const initialState: NavigationState = {
  path: null,
};

/** Navigation-related redux slice to support navigation calls from middleware */
export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    navigate: (state, action: PayloadAction<string>) => {
      state.path = action.payload;
    },
    clearNavigation: state => {
      state.path = null;
    },
  },
});

export const { navigate, clearNavigation } = navigationSlice.actions;

import type {
  AllInTier,
  ColumnDef,
  CustomerConfiguration,
  FeeTier,
  InputsAndDropdownsDrawerOption,
  SymbolGroup,
} from '@talos/kyoko';
import { getTierDrawerOptions } from '@talos/kyoko';

export const CUSTOMER_CONFIGURATIONS_COLUMNS: ColumnDef<CustomerConfiguration>[] = [
  { field: 'Counterparty', type: 'counterparty', sortable: true, sort: '+' },
  { field: 'AllInTier', type: 'text', title: 'Base Tier' },
  { field: 'FeeTier', type: 'text', title: 'Fee Tier' },
  { field: 'SymbolGroup', type: 'text', title: 'Symbol Group' },
  { field: 'UpdateUser', type: 'text', hide: true },
  { field: 'Revision', type: 'text', hide: true },
];

export function getCustomerConfigurationsDrawerOptions(
  allInTierOptions: AllInTier[],
  feeTierOptions: FeeTier[],
  symbolGroupOptions: SymbolGroup[]
): InputsAndDropdownsDrawerOption<CustomerConfiguration>[] {
  return [
    {
      field: 'AllInTier',
      type: 'dropdown',
      title: 'Base Tier',
      getDropdownOptions: () => getTierDrawerOptions(allInTierOptions),
    },
    {
      field: 'FeeTier',
      type: 'dropdown',
      title: 'Fee Tier',
      getDropdownOptions: () => getTierDrawerOptions(feeTierOptions),
    },
    {
      field: 'SymbolGroup',
      type: 'dropdown',
      title: 'Symbol Group',
      getDropdownOptions: () => getTierDrawerOptions(symbolGroupOptions),
    },
  ];
}

export function getCustomerConfigurationsPatchDeletePath(entity: CustomerConfiguration) {
  return `/customers/${encodeURIComponent(entity.Counterparty)}/configuration`;
}

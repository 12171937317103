import {
  BlotterTable,
  Box,
  FADED_ROW_CLASSNAME,
  UnifiedLiquidityEnum,
  useBlotterTable,
  type Column,
  type Order,
} from '@talos/kyoko';
import type { RowClassRules } from 'ag-grid-community';
import { compact } from 'lodash-es';
import { memo, useMemo } from 'react';
import { of } from 'rxjs';
import { MarketDistributionPriceColumn, MarketDistributionSymbolColumn } from '../columns';
import { DistributionWrapper } from '../styles';
import { REMAINDER, type MarketDistributionItem } from '../types';
import { useMarketDistribution } from '../useMarketDistribution';

export interface OpenMarketDistributionChartProps {
  legIndex?: 0 | 1;
  order: Order;
}

export const OpenMarketDistributionChart = memo(function OpenMarketDistributionChart({
  legIndex,
  order,
}: OpenMarketDistributionChartProps) {
  const isUnifiedLiquidity = order.unifiedLiquidity === UnifiedLiquidityEnum.Enabled;
  const { distribution } = useMarketDistribution({
    order,
    legIndex,
    includeZeroCumQty: true,
    variant: null,
  });
  const remainderFreeDistribution = useMemo(() => distribution.filter(d => d.id !== REMAINDER), [distribution]);

  const distributionObs = useMemo(
    () => of({ initial: true, data: remainderFreeDistribution }),
    [remainderFreeDistribution]
  );

  const columns = useMemo(
    () =>
      compact([
        {
          type: 'text',
          field: 'market',
          title: 'Market',
          pinned: 'left',
        },
        isUnifiedLiquidity ? MarketDistributionSymbolColumn({ showColor: false }) : null,
        MarketDistributionPriceColumn,
        {
          type: 'price',
          field: 'leavesQty',
          title: 'Quantity',
          params: {
            quoteCurrencyField: 'sizeCurrency',
            showDashIfZero: true,
          },
        },
      ]) satisfies Column[],
    [isUnifiedLiquidity]
  );

  const blotterTable = useBlotterTable({
    dataObservable: distributionObs,
    columns: columns,
    rowID: 'id',
    fitColumns: true,
    gridOptions: {
      domLayout: 'autoHeight',
      rowClassRules: {
        [FADED_ROW_CLASSNAME]: params => {
          return params.data?.leavesQty === '0';
        },
      } satisfies RowClassRules<MarketDistributionItem>,
    },
  });

  return (
    <DistributionWrapper>
      <Box minHeight="80px" w="100%">
        <BlotterTable background="gray.030" {...blotterTable} />
      </Box>
    </DistributionWrapper>
  );
});

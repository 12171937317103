import {
  GET,
  request,
  useUserContext,
  type AllInTier,
  type FeeTier,
  type SubscriptionResponse,
  type SymbolGroup,
} from '@talos/kyoko';
import { createContext, useCallback, useContext, type PropsWithChildren } from 'react';

export const ALL_IN_TIERS_REST = '/all-in-tiers';
export const FEE_TIERS_REST = '/fee-tiers';
export const SYMBOL_GROUPS_REST = '/symbol-groups';

interface PricingTieringProps {
  getAllInTiers: (expanded?: boolean) => Promise<SubscriptionResponse<AllInTier>>;
  getFeeTiers: (expanded?: boolean) => Promise<SubscriptionResponse<FeeTier>>;
  getSymbolGroups: (expanded?: boolean) => Promise<SubscriptionResponse<SymbolGroup>>;
}

export const PricingTieringContext = createContext<PricingTieringProps | null>(null);
PricingTieringContext.displayName = 'PricingTieringContext';

export function useCustomerPricingTierContext() {
  const context = useContext(PricingTieringContext);
  if (context == null) {
    throw new Error('Missing CustomersContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const CustomerPricingTiersProvider = ({ children }: PropsWithChildren) => {
  const { orgApiEndpoint } = useUserContext();

  const allInTiersEndpoint = `${orgApiEndpoint}${ALL_IN_TIERS_REST}`;
  const feeTiersEndpoint = `${orgApiEndpoint}${FEE_TIERS_REST}`;
  const symbolGroupsEndpoint = `${orgApiEndpoint}${SYMBOL_GROUPS_REST}`;

  // GET /v1/all-in-tiers - get list of all in tiers
  const getAllInTiers: PricingTieringProps['getAllInTiers'] = useCallback(
    (expanded = false) => request(GET, `${allInTiersEndpoint}?expanded=${expanded}`),
    [allInTiersEndpoint]
  );

  // GET /v1/fee-tiers
  const getFeeTiers: PricingTieringProps['getFeeTiers'] = useCallback(
    (expanded = false) => request(GET, `${feeTiersEndpoint}?expanded=${expanded}`),
    [feeTiersEndpoint]
  );

  // GET /v1/symbol-groups
  const getSymbolGroups: PricingTieringProps['getSymbolGroups'] = useCallback(
    (expanded = false) => request(GET, `${symbolGroupsEndpoint}?expanded=${expanded}`),
    [symbolGroupsEndpoint]
  );

  return (
    <PricingTieringContext.Provider value={{ getAllInTiers, getFeeTiers, getSymbolGroups }}>
      {children}
    </PricingTieringContext.Provider>
  );
};

import { EMPTY_OBJECT, useMarketAccountCurrenciesContext } from '@talos/kyoko';
import { uniq } from 'lodash-es';
import { useMemo } from 'react';
import { combineLatestWith, map, shareReplay } from 'rxjs';
import { useBalances } from './useBalances';

/**
 * This hook returns an observable of all "relevant currencies".
 *
 * A relevant currency is one that our system has a represented either in the Balances data set, or the
 * MarketAccountCurrency (MAC) data set.
 *
 * These currencies are flattened and uniqified into an array of currencies. This array of currencies is emitted through the
 * observable which this hook returns.
 */
export const useRelevantCurrenciesObs = () => {
  const { currenciesWithBalanceObs } = useBalances({
    filter: EMPTY_OBJECT,
    openStreamWithoutFilter: true,
    tag: 'useRelevantCurrencies',
  });
  const { acceptedCurrenciesObs } = useMarketAccountCurrenciesContext();

  return useMemo(() => {
    return currenciesWithBalanceObs.pipe(
      combineLatestWith(acceptedCurrenciesObs),
      map(([currenciesWithBalanceArray, acceptedCurrenciesSet]) =>
        uniq([...currenciesWithBalanceArray, ...acceptedCurrenciesSet])
      ),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
  }, [currenciesWithBalanceObs, acceptedCurrenciesObs]);
};

import { identity } from 'lodash-es';
import { useCallback, useState, type ChangeEvent } from 'react';
import { v1 as uuid } from 'uuid';
import { EMPTY_ARRAY, replaceTokens, type ReplaceTokens } from '../../utils';
import { IconButton } from '../Button';
import { Checkbox, FileInput, FormGroup, Input, SearchSelect, TextArea, type FormControlSizes } from '../Form';
import { IconName } from '../Icons';

function getLabel(label: string, tokens: ReplaceTokens | undefined): string {
  return replaceTokens(label, tokens);
}

export const OnboardingFormField = ({
  name,
  label,
  type,
  value,
  placeholder,
  setValue,
  error,
  size,
  tokensToReplace,
  required,
  ...props
}: OnboardingFormFieldProps) => {
  const [fieldId] = useState(`${name}_${uuid()}`);

  return (
    <FormGroup
      controlId={fieldId}
      error={error}
      label={type === 'checkbox' ? null : getLabel(label, tokensToReplace)}
      size={size}
      {...props}
    >
      <>
        {(() => {
          switch (type) {
            case 'textarea':
              return (
                <TextArea
                  data-testid={name}
                  name={name}
                  invalid={!!error}
                  id={fieldId}
                  placeholder={placeholder}
                  value={(value ?? '') as string}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)}
                />
              );

            case 'file':
              return (
                <FileInput
                  data-testid={name}
                  name={name}
                  invalid={!!error}
                  id={fieldId}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    // We don't have access to e.target.value asynchronously
                    const file = e.target?.files && e.target?.files[0];
                    setValue(file);
                  }}
                />
              );

            case 'password':
              return (
                <PasswordInput
                  name={name}
                  value={value}
                  placeholder={placeholder}
                  setValue={setValue}
                  error={error}
                  fieldId={fieldId}
                />
              );

            case 'checkbox':
              return (
                <Checkbox
                  data-testid={name}
                  name={name}
                  invalid={!!error}
                  id={fieldId}
                  checked={value === true || false}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.checked)}
                >
                  {getLabel(label, tokensToReplace)}
                </Checkbox>
              );

            case 'select':
              return (
                <SearchSelect
                  data-testid={name}
                  options={props.values ?? EMPTY_ARRAY}
                  selection={(value ?? '') as string}
                  getLabel={identity}
                  onChange={(value?: string) => setValue(value ?? null)}
                  invalid={!!error}
                  showClear={!required}
                  initialSortByLabel={false}
                />
              );

            default:
              return (
                <Input
                  data-testid={name}
                  autoComplete="off"
                  name={name}
                  invalid={!!error}
                  id={fieldId}
                  value={(value ?? '') as string}
                  placeholder={placeholder}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                />
              );
          }
        })()}
      </>
    </FormGroup>
  );
};

function PasswordInput({
  name,
  value,
  placeholder,
  setValue,
  error,
  fieldId,
}: Omit<OnboardingFormFieldProps, 'label' | 'size'> & {
  fieldId: string;
}) {
  const [inputType, setInputType] = useState<'text' | 'password'>('password');

  const handleInputTypeChange = useCallback(() => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  }, [inputType]);

  return (
    <Input
      data-testid={name}
      autoComplete="off"
      name={name}
      invalid={!!error}
      id={fieldId}
      value={(value ?? '') as string}
      placeholder={placeholder}
      inputType={inputType}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
      suffix={
        <IconButton
          onClick={handleInputTypeChange}
          icon={inputType === 'password' ? IconName.EyeShow : IconName.EyeHide}
          disabled={!value}
          ghost
        />
      }
    />
  );
}

type OnboardingFormFieldProps = {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  placeholder?: string;
  value?: string | boolean | File | null;
  setValue: (value: string | boolean | File | null) => void;
  error: string;
  size: FormControlSizes;
  tokensToReplace?: ReplaceTokens;
  values?: string[];
};

import {
  BALANCE_DELTA,
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  Divider,
  EMPTY_OBJECT,
  EXTRAS_MENU_TOOLTIP_PROPS,
  FormControlSizes,
  IconButton,
  IconName,
  LedgerAccountTypeEnum,
  MarketTypeEnum,
  MixpanelEvent,
  MixpanelEventSource,
  TabSize,
  Toggle,
  columnTypes,
  createCSVFileName,
  filterByCellValueMenuItem,
  filterByColumnMainMenuItems,
  getShowJSONContextItem,
  isGridApiReady,
  removeEmptyFilters,
  selectAll,
  useAccordionFilterBuilder,
  useAggDeltaUpdatesPipe,
  useBlotterTableExtrasMenu,
  useConstant,
  useDefaultColumns,
  useDisclosure,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMarketsContext,
  useMixpanel,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  useWsBlotterTable,
  type Column,
  type FilterClause,
  type Leaves,
  type RowGroupsOpenedState,
  type UsePersistedColumnsChangedParams,
  type WebsocketRequest,
} from '@talos/kyoko';
import { compact, isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v1 as uuid } from 'uuid';

import type {
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { useFeatureFlag } from '../../../hooks';
import { useDisplaySettings } from '../../../providers/DisplaySettingsProvider';
import { getBalanceKey, type Balance, type BalancesFilter } from '../../../types';
import { ShowByButtons } from '../../Portfolio/TreasuryManagement/components/ShowByButtons';

import { useGetOpenTransactionsMenuItems } from '../../Trading/Markets/AccountLedgerEvents/useGetOpenTransactionsMenuItems';
import { CONTROL_TOOLTIPS } from '../tooltips';
import { BalancesBlotterSummaryLine } from './SummaryLine/BalancesBlotterSummaryLine';
import { UpdateBalancesDialog } from './UpdateBalancesDialog';
import { balancesBlotterDefaultRowGroupsOpened, defaultByCounterpartyColumns } from './defaults';
import type { BalancesBlotterShowBy, BalancesV2TabProps } from './types';
import { useBalancesColumnDefinitions } from './useBalancesV2Columns';
import { BalanceFilterKey, balanceColIDToFilterBuilderKey, useBalancesV2Filter } from './useBalancesV2Filter';
import { useEnrichDeltaBalancesPipe } from './useEnrichedDeltaBalancesObs';

export const balancesBlotterAggSpecs = [
  { valuePath: 'Equivalent.Amount', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.AvailableAmount', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.OutstandingBuy', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.OutstandingSell', currencyPath: 'Equivalent.Currency' },
] satisfies { valuePath: Leaves<Balance>; currencyPath: Leaves<Balance> }[];

interface BalancesV2BlotterParams {
  blotterID: string;
  tab: BalancesV2TabProps;
  onCloneTab: (
    filter: BalancesFilter,
    columns: Column[],
    rowGroupsOpenedState: RowGroupsOpenedState | undefined,
    showZeroBalances: boolean,
    showTotals: boolean
  ) => void;
  onUpdateTab: (updatedTab: BalancesV2TabProps) => void;
  /** When mounting should the filter accordian be expanded */
  initialIsOpen?: boolean;
}

export const BalancesV2Blotter = ({
  blotterID,
  tab,
  onUpdateTab,
  onCloneTab,
  initialIsOpen,
}: BalancesV2BlotterParams) => {
  const mixpanel = useMixpanel();
  const { enableAccountLedgerEventsPage } = useFeatureFlag();

  const defaultBlotterColumns = tab.defaultColumns ?? defaultByCounterpartyColumns;
  const defaultFilter = tab.defaultFilter ?? EMPTY_OBJECT;
  const defaultRowGroupsOpened = tab.defaultRowGroupsOpened ?? balancesBlotterDefaultRowGroupsOpened;
  const showZeroBalances = tab.showZeroBalances ?? false;
  const showTotals = tab.showTotals ?? true;

  const { homeCurrency } = useDisplaySettings();
  const updateShowZeroBalances = useDynamicCallback((value: boolean) => {
    mixpanel.track(MixpanelEvent.ToggleShowZeroBalances);
    onUpdateTab({ ...tab, showZeroBalances: value });
  });
  const updateShowTotals = useDynamicCallback((value: boolean) => {
    mixpanel.track(MixpanelEvent.ToggleShowTotals);
    onUpdateTab({ ...tab, showTotals: value });
  });
  const { marketsList } = useMarketsContext();
  const [selectedRows, setSelectedRows] = useState<Balance[]>([]);

  const columnDefinitions = useBalancesColumnDefinitions();
  const defaultColumns = useDefaultColumns(defaultBlotterColumns, columnDefinitions);

  const persisted = usePersistedBlotterTable<Balance>(blotterID, {
    columns: defaultColumns,
    filter: defaultFilter,
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const filtering = useBalancesV2Filter({
    initialFilter: persisted.initialFilter,
    saveFilter: persisted.onFilterChanged,
  });

  const { changeFilter, filterableProperties, initialFilterClauses } = filtering;

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>) => {
      changeFilter(curr => {
        const newFilter: BalancesFilter = {
          Currencies: filterClausesByPropertyKey.get(BalanceFilterKey.Currencies)?.selections,
          Markets: filterClausesByPropertyKey.get(BalanceFilterKey.Markets)?.selections,
          MarketTypes: filterClausesByPropertyKey.get(BalanceFilterKey.MarketTypes)?.selections,
          Groups: filterClausesByPropertyKey.get(BalanceFilterKey.Groups)?.selections,
          MarketAccounts: filterClausesByPropertyKey.get(BalanceFilterKey.MarketAccounts)?.selections,
        };

        const proposed = removeEmptyFilters({
          ...curr,
          ...newFilter,
        });

        if (isEqual(curr, proposed)) {
          return curr;
        }

        return proposed;
      });
    },
    [changeFilter]
  );

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps: {
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    },
  });

  const { openClause } = filterBuilderAccordion;

  const [groupAccounts, setGroupAccounts] = useState<boolean>(
    columnsAreGroupingBy(persisted.columnsOnly, 'marketAccountGroup')
  );
  const [showBy, setShowBy] = useState<BalancesBlotterShowBy>(
    columnsAreGroupingBy(persisted.columnsOnly, 'Currency') ? 'asset' : 'counterparty'
  );
  const handleColumnsChanged = useDynamicCallback((params: UsePersistedColumnsChangedParams) => {
    const groupedColIds = getRowGroupColumnIds();
    setShowBy(groupedColIds.has('Currency') ? 'asset' : 'counterparty');
    setGroupAccounts(groupedColIds.has('marketAccountGroup'));
    persisted.onColumnsChanged(params);
  });

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    return filterByColumnMainMenuItems({
      params,
      colIDToFilterBuilderKey: balanceColIDToFilterBuilderKey,
      openClause,
      mixpanel,
    });
  });

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const getOpenTransactionsMenuItems = useGetOpenTransactionsMenuItems();

  const { handleClickJson, jsonModal } = useJsonModal();

  const getContextMenuItems: GetContextMenuItems<Balance> = useDynamicCallback(
    (params: GetContextMenuItemsParams<Balance>) => {
      const data = params.node?.data;

      const items = compact([
        ...filterByCellValueMenuItem({
          params,
          filterableProperties,
          openClause,
          colIDToFilterBuilderKey: balanceColIDToFilterBuilderKey,
          mixpanel,
        }),
        data ? getShowJSONContextItem({ params, handleClickJson }) : undefined,
        'separator',
        selectAll(params, mixpanel),
        'separator',
        ...getDefaultContextMenuItems(params),
      ]);

      if (enableAccountLedgerEventsPage) {
        const type = LedgerAccountTypeEnum.MarketAccount;
        const asset = data?.Currency;
        const account = data?.marketAccountName;

        const ledgerEventsItems = getOpenTransactionsMenuItems({
          account,
          asset,
          type,
          source: MixpanelEventSource.BalancesBlotter,
          showAccountOption: true,
        });

        items.push('separator');
        items.push(...ledgerEventsItems);
      }

      return compact(items);
    }
  );

  const handleRowSelectionChanged = useCallback(({ api }: SelectionChangedEvent<Balance>) => {
    if (!isGridApiReady(api)) {
      return;
    }
    const rows = api.getSelectedRows();
    return setSelectedRows(compact(rows));
  }, []);

  const blotterTag = useConstant(`BALANCES_V2_${uuid()}`);

  const pipe = useEnrichDeltaBalancesPipe();

  const aggPipe = useAggDeltaUpdatesPipe({
    getUniqueKey: getBalanceKey,
    aggSpecs: balancesBlotterAggSpecs,
  });

  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        title: 'Grouping',
        editable: false,
        hide: false,
        suppressColumnsToolPanel: false,
        params: {
          suppressCount: true,
        },
      }),
    []
  );

  const persistence = useMemo(() => {
    return {
      ...persisted,
      onColumnsChanged: handleColumnsChanged,
    };
  }, [handleColumnsChanged, persisted]);

  const blotterTable = useWsBlotterTable<WebsocketRequest, Balance>({
    initialRequest: {
      name: BALANCE_DELTA,
      tag: blotterTag,
      EquivalentCurrency: homeCurrency,
      ShowZeroBalances: showZeroBalances,
    },
    columns: persisted.columns,
    filter: filtering.filter,
    pipe,
    pinnedRowDataPipe: aggPipe,
    showPinnedRows: showTotals,
    rowID: 'rowID' satisfies keyof Balance,

    getExtraMainMenuItems,
    persistence,
    gridOptions: {
      ...persistedRowGroupsOpened.gridOptionsOverlay,
      groupRemoveSingleChildren: true,
      showOpenedGroup: true,
      autoGroupColumnDef,
      groupDisplayType: 'singleColumn',
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems,
      onSelectionChanged: handleRowSelectionChanged,
    },
  });

  /**
   * When the homeCurrency or showZeroBalances changes we emit a change to the blotter which in turn will
   * update its internal ws request to reflect these setting changes
   */
  useEffect(() => {
    blotterTable.onRequestChanged({
      name: BALANCE_DELTA,
      tag: blotterTag,
      EquivalentCurrency: homeCurrency,
      ShowZeroBalances: showZeroBalances,
    });
  }, [blotterTable, homeCurrency, showZeroBalances, blotterTag]);

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const { expandAllGroups, collapseAllGroups, setRowGroupColumns, removeRowGroupColumns, getRowGroupColumnIds } =
    blotterTable;

  const handleShowByChanged = useDynamicCallback((showBy: BalancesBlotterShowBy) => {
    const rowGroupColumns: (keyof Balance)[] = showBy === 'asset' ? ['Currency'] : ['marketAccountName'];
    setRowGroupColumns(rowGroupColumns);
  });

  const handleGroupAccountsToggle = useDynamicCallback((checked: boolean) => {
    mixpanel.track(MixpanelEvent.GroupAccounts);
    if (checked) {
      setRowGroupColumns(['marketAccountGroup', 'marketAccountName'] satisfies (keyof Balance)[]);
    } else {
      removeRowGroupColumns(['marketAccountGroup'] satisfies (keyof Balance)[]);
    }
  });

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab(
      filtering.filter,
      blotterTable.getColumns(),
      persistedRowGroupsOpened.getRowGroupsOpenedState(),
      showZeroBalances,
      showTotals
    );
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Balance',
        tabLabel: tab.label,
      }),
    });
    extrasMenuPopover.close();
  });

  const modifyBalancesDialog = useDisclosure();

  // This logic is copied from the old Balances blotter. We need to pass a default Market.Name into the dialog.
  // Future work: rework the Update balances dialog, type it properly, etc
  const defaultUpdateBalancesDialogValue = useMemo(() => {
    return { market: marketsList.find(market => market.Type === MarketTypeEnum.External)?.Name };
  }, [marketsList]);

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTable.blotterTableFiltersProps}
        accordionBodyPrefix={<ShowByButtons showBy={showBy} onClick={handleShowByChanged} size={TabSize.Default} />}
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
              data-testid="collapse-all-button"
            />
          </>
        }
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Toggle
              size={FormControlSizes.Small}
              checked={showTotals}
              onChange={updateShowTotals}
              label="Show Totals"
            />
            <Toggle
              tooltip={CONTROL_TOOLTIPS.showZeroBalances}
              tooltipProps={EXTRAS_MENU_TOOLTIP_PROPS}
              size={FormControlSizes.Small}
              checked={showZeroBalances}
              onChange={updateShowZeroBalances}
              label="Show Zero Balances"
            />
            <Toggle
              disabled={showBy === 'asset'}
              size={FormControlSizes.Small}
              checked={groupAccounts}
              onChange={handleGroupAccountsToggle}
              label="Group Accounts"
            />
            <Divider orientation="horizontal" />
            <Button
              startIcon={IconName.DocumentDownload}
              size={FormControlSizes.Small}
              onClick={handleExport}
              data-testid="export-button"
            >
              Export
            </Button>
            <Button
              startIcon={IconName.Duplicate}
              variant={ButtonVariants.Default}
              size={FormControlSizes.Small}
              onClick={handleCloneTab}
            >
              Clone Tab
            </Button>
            <Button
              startIcon={IconName.Pencil}
              variant={ButtonVariants.Default}
              size={FormControlSizes.Small}
              onClick={() => {
                mixpanel.track(MixpanelEvent.OpenUpdateBalancesModal);
                extrasMenuPopover.close();
                modifyBalancesDialog.open();
              }}
            >
              Update Balances
            </Button>
          </BlotterTableExtrasMenu>
        }
      />
      <BlotterTable {...blotterTable} />
      <BalancesBlotterSummaryLine rows={selectedRows} />
      {modifyBalancesDialog.isOpen && (
        <UpdateBalancesDialog
          {...modifyBalancesDialog}
          autoFocusFirstElement={false}
          initialBalanceValues={defaultUpdateBalancesDialogValue}
        />
      )}
      {jsonModal}
    </>
  );
};

function columnsAreGroupingBy(columns: Column<any>[], key: string): boolean {
  return !!columns.find(c => c.rowGroup && c.id === key);
}

import {
  ACTION,
  Button,
  ButtonVariants,
  FormControlSizes,
  HamburgerMenu,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  filterByCellValueMenuItem,
  getShowJSONContextItem,
  isOrderComplete,
  useDisclosure,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  type Column,
  type ColumnDef,
  type FilterableProperty,
  type MarketOrder,
  type MixpanelInstance,
  type UseDisclosureReturn,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, ICellRendererParams, MenuItemDef } from 'ag-grid-enterprise';
import { OMSView } from 'components/OMS/OMSView';
import { useAdminUtils, useRoleAuth } from 'hooks';
import { compact } from 'lodash-es';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useMemo, useRef, type ReactNode } from 'react';
import { prime } from '../../../components/OMS/ManualOrderFillView/ManualOrderFillSlice';
import { openView } from '../../../components/OMS/OMSSlice';
import { CancelSelectedDialog, ForceCancelSelectedDialog } from '../CancelSelectedDialog';
import { colIDToFilterBuilderKey } from './useMarketOrderFilter';

export function MarketOrderMenu({
  data: marketOrder,
  onCancel,
  onForceCancel,
  onManualFill,
  ...params
}: ICellRendererParams & {
  onShowJson(data: MarketOrder): void;
  onCancel(data: MarketOrder): void;
  onForceCancel(data: MarketOrder): void;
  onManualFill(data: MarketOrder): void;
}) {
  const { isAuthorized } = useRoleAuth();
  const cancellableMarketOrders = computeCancellableMarketOrders([marketOrder]);

  return (
    <>
      {isAuthorized(ACTION.FORCE_CANCEL_ORDER) && (
        <Button
          size={FormControlSizes.Small}
          width="100%"
          ghost={true}
          onClick={() => onForceCancel(marketOrder)}
          variant={ButtonVariants.Negative}
        >
          Force cancel
        </Button>
      )}
      {isAuthorized(ACTION.SUBMIT_ORDER) && cancellableMarketOrders.length > 0 && (
        <Button size={FormControlSizes.Small} width="100%" ghost={true} onClick={() => onCancel(marketOrder)}>
          Cancel Order
        </Button>
      )}
      {isAuthorized(ACTION.MANUAL_ORDER_FILL) && !isOrderComplete(marketOrder.OrdStatus) && (
        <Button size={FormControlSizes.Small} width="100%" ghost={true} onClick={() => onManualFill(marketOrder)}>
          Manual fill
        </Button>
      )}
      <HamburgerMenu {...params} data={marketOrder} />
    </>
  );
}

export const useMarketOrdersMenu = ({
  openClause,
  filterableProperties,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
}): {
  columns: Column[];
  getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} => {
  const { tryCancelMarketOrder, tryForceCancelMarketOrder } = useAdminUtils();

  const { handleClickJson, jsonModal } = useJsonModal<MarketOrder>();

  const selectedOrdersRef = useRef<MarketOrder[]>([]);

  const mixpanel = useMixpanel();
  const { isAuthorized } = useRoleAuth();

  const cancelDialog = useDisclosure();
  const { open: openCancelDialog } = cancelDialog;

  const forceCancelDialog = useDisclosure();
  const { open: openForceCancelDialog } = forceCancelDialog;

  const dispatch = useAppStateDispatch();

  const cancelItem = useDynamicCallback((orders: MarketOrder[]) =>
    cancelOrderMenuItem({ orders, cancelDialog, mixpanel })
  );

  const manualOrderFillItem = useDynamicCallback((orders: MarketOrder[]) =>
    manualOrderFillMenuItem({
      orders,
      openManualOrderFillForm: marketOrder => {
        dispatch(prime(marketOrder));
        dispatch(openView(OMSView.ManualOrderFillForm));
      },
      mixpanel,
    })
  );

  const forceCancelItem = useDynamicCallback((orders: MarketOrder[]) =>
    forceCancelOrderMenuItem({ orders, forceCancelDialog, mixpanel })
  );

  const getMenuItems = useDynamicCallback((orders: MarketOrder[]) => {
    const menuItems: any[] = [];

    if (isAuthorized(ACTION.FORCE_CANCEL_ORDER)) {
      menuItems.push(forceCancelItem(orders));
    }
    if (isAuthorized(ACTION.CANCEL_ORDER)) {
      menuItems.push(cancelItem(orders));
    }

    if (isAuthorized(ACTION.MANUAL_ORDER_FILL)) {
      menuItems.push(manualOrderFillItem(orders));
    }
    return menuItems;
  });
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[] = useDynamicCallback(
    (params: GetContextMenuItemsParams) => {
      const selectedOrder = params?.node?.data;
      const orders = compact(
        params.api
          .getSelectedNodes()
          .filter(node => node.displayed)
          .map(node => node.data)
      );
      if (selectedOrder && !orders.some(order => order.OrderID === selectedOrder.OrderID)) {
        // If the user right-clicked on a non-selected row, unselect all the other rows and select this row instead
        params.node?.setSelected(true, true);
      }
      selectedOrdersRef.current = orders;
      const menuItems = getMenuItems(selectedOrdersRef.current);

      return compact([
        ...filterByCellValueMenuItem({ params, filterableProperties, openClause, colIDToFilterBuilderKey, mixpanel }),
        ...menuItems,
        ...(selectedOrder ? [getShowJSONContextItem({ params, handleClickJson }), 'separator'] : []),
        ...getDefaultContextMenuItems(params),
      ]);
    }
  );

  const handleCancel = useDynamicCallback((marketOrder: MarketOrder) => {
    selectedOrdersRef.current = [marketOrder];
    openCancelDialog();
  });

  const handleForceCancel = useDynamicCallback((marketOrder: MarketOrder) => {
    selectedOrdersRef.current = [marketOrder];
    openForceCancelDialog();
  });

  const handleManualOrderFill = useDynamicCallback((marketOrder: MarketOrder) => {
    selectedOrdersRef.current = [marketOrder];
    dispatch(prime(marketOrder));
    dispatch(openView(OMSView.ManualOrderFillForm));
  });

  const columns = useMemo(
    () =>
      compact([
        {
          type: 'hamburgerMenu',
          id: 'rowMenu',
          params: {
            renderItems: params => (
              <MarketOrderMenu
                {...params}
                onShowJson={handleClickJson}
                onCancel={handleCancel}
                onForceCancel={handleForceCancel}
                onManualFill={handleManualOrderFill}
              />
            ),
          },
        },
      ] satisfies ColumnDef<MarketOrder>[]),
    [handleCancel, handleForceCancel, handleClickJson, handleManualOrderFill]
  );

  return {
    getContextMenuItems,
    columns,
    dialogs: (
      <>
        <CancelSelectedDialog
          key="cancel"
          selectedItems={computeCancellableMarketOrders(selectedOrdersRef.current)}
          cancelSelectedDialog={cancelDialog}
          onConfirm={orders =>
            orders.forEach(order => {
              mixpanel.track(MixpanelEvent.CancelOrder, { [MixpanelEventProperty.ID]: order.OrderID });
              tryCancelMarketOrder(order);
            })
          }
        />
        <ForceCancelSelectedDialog
          key="force-cancel"
          selectedItems={selectedOrdersRef.current}
          forceCancelSelectedDialog={forceCancelDialog}
          onConfirm={orders =>
            orders.forEach(order => {
              mixpanel.track(MixpanelEvent.CancelForce, { [MixpanelEventProperty.ID]: order.OrderID });
              tryForceCancelMarketOrder(order);
            })
          }
        />
        {jsonModal}
      </>
    ),
  };
};

interface OrderMenuItemProps {
  orders: MarketOrder[];
  mixpanel: MixpanelInstance;
}

const cancelOrderMenuItem = ({
  orders,
  cancelDialog,
  mixpanel,
}: OrderMenuItemProps & {
  cancelDialog: UseDisclosureReturn;
}) => {
  const ordersForCancel = computeCancellableMarketOrders(orders);
  if (ordersForCancel.length === 0) {
    return null;
  }
  const name = ordersForCancel.length === 1 ? 'Cancel Order' : `Cancel ${ordersForCancel.length} Orders`;
  return {
    name,
    action: () => {
      cancelDialog.open();
    },
    icon: `<i class="ag-icon ${IconName.Trash}"/>`,
  };
};

const forceCancelOrderMenuItem = ({
  orders,
  forceCancelDialog,
  mixpanel,
}: OrderMenuItemProps & {
  forceCancelDialog: UseDisclosureReturn;
}) => {
  if (orders.length === 0) {
    return null;
  }
  const name = orders.length === 1 ? 'Force Cancel Order' : `Force Cancel ${orders.length} Orders`;
  return {
    name,
    action: () => {
      forceCancelDialog.open();
    },
    icon: `<i class="ag-icon ${IconName.Trash}"/>`,
  };
};

const manualOrderFillMenuItem = ({
  orders,
  openManualOrderFillForm,
  mixpanel,
}: OrderMenuItemProps & {
  openManualOrderFillForm: (marketOrder: MarketOrder) => void;
}) => {
  if (orders.length !== 1 || isOrderComplete(orders[0].OrdStatus)) {
    return null;
  }
  return {
    name: 'Manual Fill',
    action: () => {
      mixpanel.track(MixpanelEvent.OpenManualFillForm);
      openManualOrderFillForm(orders[0]);
    },
    icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
  };
};

function computeCancellableMarketOrders(orders: MarketOrder[]): MarketOrder[] {
  return orders.filter(order => !isOrderComplete(order.OrdStatus));
}

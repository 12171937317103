import styled, { css } from 'styled-components';

import { LABEL_WIDTH } from 'components/Form';
import { OMS_WIDTH } from 'styles/dimensions';

import { Box, Flex, IconButton, Input, Text } from '@talos/kyoko';

export const ContentRow = styled.div<{ lastChildNoMargin?: boolean }>`
  ${({ lastChildNoMargin }) =>
    lastChildNoMargin &&
    css`
      & > :last-child {
        margin-bottom: 0;
      }
    `}
`;

export const OMSTabsWrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.backgroundBody};
  justify-content: space-between;
  background: ${({ theme }) => theme.backgroundFilledTabList};
`;

export const OMSTitle = styled(Flex)``;
OMSTitle.defaultProps = {
  fontSize: 'fontSizeSmall',
  px: 'spacingDefault',
  py: 'spacingSmall',
  color: 'colorTextImportant',
  background: 'backgroundFilledTab',
  alignItems: 'center',
};

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray['000']}`};
  border-right: 0;
  padding: ${({ theme }) => `${theme.spacingDefault}px ${theme.spacingMedium}px`};
  position: relative;
  height: 50px;

  & > * {
    flex: 1;
  }
`;

export const TopTitle = styled.div`
  span {
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.gray['080']};
    font-weight: ${({ theme }) => theme.fontWeightMedium};
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  }
`;

export const TopSymbol = styled.div`
  flex: 0 0 205px;
  max-width: 70%;
`;

export const TopClose = styled.div`
  display: flex;
  ${IconButton} {
    margin-left: auto;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.backgroundContent};
`;

export const Wrapper = styled.form`
  background: ${({ theme }) => theme.backgroundContent};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const QuoteResponse = styled.div`
  display: flex;
  justify-content: space-evenly;
  * {
    width: 100%;
    margin: ${({ theme }) => theme.spacingDefault}px;
  }
`;

export const Section = styled(Flex)`
  flex-direction: column;
`;

export const Container = styled(Flex)`
  height: 100%;
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  scrollbar-gutter: stable;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacingMedium}px;
  position: relative;
  padding-right: ${({ theme }) => theme.spacingMedium - theme.scrollbarSize}px;

  ${ContentRow}:last-child {
    margin-bottom: ${({ theme }) => theme.spacingHuge}px;
  }
`;
Container.defaultProps = {
  p: 'spacingMedium',
};

export const OmsToasts = styled.div`
  position: absolute;
  bottom: 45px;
  padding: ${({ theme }) => `0 ${theme.spacingMedium}px ${theme.spacingMedium}px`};
  width: ${OMS_WIDTH}px;
  z-index: 3;
`;

export const Actions = styled(Box)`
  z-index: 2;
  flex: 0 0 auto;
  display: flex;
  padding: ${({ theme }) => `${theme.spacingDefault}px ${theme.spacingMedium}px`};

  &:first-child {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray['000']}`};
  }

  &:last-child {
    border-top: ${({ theme }) => `1px solid ${theme.colors.gray['000']}`};
  }

  span button {
    width: 100%;
  }

  button,
  span {
    flex: 1 1 50%;

    & + button,
    & + span {
      margin-left: ${({ theme }) => theme.spacingDefault}px;
    }
  }
`;

export const TradeAllocationsCheckboxWrapper = styled.div<{ useTradeAllocations?: boolean }>`
  ${({ useTradeAllocations, theme }) =>
    useTradeAllocations ? `margin-top: ${theme.spacingDefault}px` : `margin-left: ${LABEL_WIDTH}px `}
`;

export const TotalInput = styled(Input)`
  text-align: right;
  font-style: italic;

  &:read-only {
    border: none;
  }
`;

export const ModifyWarningWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacingMedium}px;

  p {
    margin: 0 ${({ theme }) => theme.spacingSmall}px;
  }

  ${Text} {
    padding-right: ${({ theme }) => theme.spacingSmall}px;
  }
`;

export const CustomerRFQButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SideButtonsWrapper = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacingDefault}px;
  justify-content: space-around;
  p {
    font-weight: ${({ theme }) => theme.fontWeightMedium};
  }
`;

export const OrderImportContainer = styled(Container)<{ showFileDrop?: boolean }>`
  ${({ theme }) => `
  & > div:nth-child(2n) {
    background: ${theme.colors.gray['010']};
  }
  `};

  &:after {
    content: 'Drop CSV files here to import orders';
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    border: dashed 1px hsl(200, 10%, 25%);
    border-radius: ${({ theme }) => theme.borderRadiusHuge}px;
    color: hsl(200, 10%, 85%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 70px;
    text-align: center;
    backdrop-filter: blur(0px);
    background: hsla(200, 10%, 5%, 75%);
    margin: ${({ theme }) => theme.spacingMedium}px;
    transition: backdrop-filter 100ms ease-in, opacity 100ms ease-in;
    opacity: 0;
    pointer-events: none;
  }

  ${({ showFileDrop }) =>
    showFileDrop &&
    `&:after {
    opacity: 1;
    backdrop-filter: blur(5px);
  }`}
`;

export const BulkOrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BulkOrderErrors = styled.div`
  color: ${({ theme }) => theme.colors.yellow.lighten};
  padding-left: ${({ theme }) => theme.spacingDefault}px;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary.lighten};
  border-bottom: None;
`;

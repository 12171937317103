import {
  ACTION,
  DELETE,
  POST,
  PUT,
  SUB_ACCOUNT_POSITION_LIMIT,
  request,
  useDynamicCallback,
  useEndpointsContext,
  useObservable,
  useObservableValue,
  useSubscription,
  wsScanToMap,
  type DataResponse,
  type SubAccountPositionLimit,
} from '@talos/kyoko';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import { map, shareReplay, type Observable } from 'rxjs';
import { useRoleAuth } from '../hooks/useRoleAuth';

export const SubAccountPositionLimitsContext = createContext<SubAccountPositionLimitsContextProps | undefined>(
  undefined
);
SubAccountPositionLimitsContext.displayName = 'SubAccountPositionLimitsProvider';

export interface SubAccountPositionLimitsContextProps {
  subAccountPositionLimitsList: SubAccountPositionLimit[] | undefined;
  /** Limits by sub account name. Does not include wildcards. */
  subAccountPositionLimitsBySubAccountNameObs: Observable<Map<string, SubAccountPositionLimit[]>>;

  // crud
  createSubAccountPositionLimit: (
    limit: Partial<SubAccountPositionLimit>
  ) => Promise<DataResponse<SubAccountPositionLimit>>;
  updateSubAccountPositionLimit: (limit: Partial<SubAccountPositionLimit>) => Promise<SubAccountPositionLimit>;
  deleteSubAccountPositionLimit: (limit: Partial<SubAccountPositionLimit>) => Promise<unknown>;
}

export function useSubAccountPositionLimits() {
  const context = useContext(SubAccountPositionLimitsContext);
  if (context === undefined) {
    throw new Error(
      'Missing SubAccountPositionLimitsContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

export const SubAccountPositionLimitsProvider = function SubAccountPositionLimitsProvider({
  children,
}: PropsWithChildren) {
  const { orgApiEndpoint } = useEndpointsContext();
  const endpoint = `${orgApiEndpoint}/subaccounts/position-limits`;
  const { isAuthorized } = useRoleAuth();

  const createSubAccountPositionLimit = useDynamicCallback((limit: Partial<SubAccountPositionLimit>) => {
    return request(POST, endpoint, limit);
  });

  const updateSubAccountPositionLimit = useDynamicCallback((limit: Partial<SubAccountPositionLimit>) => {
    return request(PUT, `${endpoint}/${limit.LimitID}`, limit);
  });

  const deleteSubAccountPositionLimit = useDynamicCallback((limit: Partial<SubAccountPositionLimit>) => {
    return request(DELETE, `${endpoint}/${limit.LimitID}`);
  });

  const wsRequest = useMemo(
    () =>
      isAuthorized(ACTION.EDIT_LIMITS)
        ? { name: SUB_ACCOUNT_POSITION_LIMIT, tag: 'SubAccountPositionLimitsProvider' }
        : null,
    [isAuthorized]
  );
  const { data: subscription } = useSubscription<SubAccountPositionLimit>(wsRequest);

  const subAccountPositionLimitsByIDObs = useObservable(
    () => subscription.pipe(wsScanToMap({ getUniqueKey: l => l.LimitID, newMapEachUpdate: false }), shareReplay(1)),
    [subscription]
  );

  const subAccountPositionLimitsListObs = useObservable(
    () =>
      subAccountPositionLimitsByIDObs.pipe(
        map(map => [...map.values()]),
        shareReplay(1)
      ),
    [subAccountPositionLimitsByIDObs]
  );

  const subAccountPositionLimitsBySubAccountNameObs = useObservable(
    () =>
      subAccountPositionLimitsListObs.pipe(
        map(limits => {
          const map = new Map<string, SubAccountPositionLimit[]>();
          for (const limit of limits) {
            if (limit.SubAccount == null) {
              continue;
            }

            if (map.has(limit.SubAccount)) {
              map.get(limit.SubAccount)?.push(limit);
            } else {
              map.set(limit.SubAccount, [limit]);
            }
          }

          return map;
        })
      ),
    [subAccountPositionLimitsListObs]
  );

  const subAccountPositionLimitsList = useObservableValue(
    () => subAccountPositionLimitsListObs,
    [subAccountPositionLimitsListObs]
  );

  const value = useMemo(
    () => ({
      subAccountPositionLimitsList,
      subAccountPositionLimitsBySubAccountNameObs,
      createSubAccountPositionLimit,
      updateSubAccountPositionLimit,
      deleteSubAccountPositionLimit,
    }),
    [
      subAccountPositionLimitsList,
      subAccountPositionLimitsBySubAccountNameObs,
      createSubAccountPositionLimit,
      updateSubAccountPositionLimit,
      deleteSubAccountPositionLimit,
    ]
  );

  return <SubAccountPositionLimitsContext.Provider value={value}>{children}</SubAccountPositionLimitsContext.Provider>;
};

import {
  Alert,
  AlertVariants,
  Button,
  ButtonGroup,
  ButtonVariants,
  Divider,
  Flex,
  FormGroup,
  HedgeCommandEnum,
  Input,
  MixpanelEvent,
  ModeEnum,
  PreviewView,
  useMixpanel,
  type UseDisclosureWithContextReturn,
} from '@talos/kyoko';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHedgeRuleCommandMutation } from '../../containers/Trading/Markets/PositionAutoHedgingRules/queries';
import { LoadingOverlay } from '../AutoHedgingRulesDrawer/AutoHedgingRulesDrawer';

/**
 * Preview view for confirming the global activation or deactivation of position autohedging.
 */
export function ConfirmModePreview({ previewView }: { previewView: UseDisclosureWithContextReturn<ModeEnum> }) {
  const mixpanel = useMixpanel();
  const disabling = previewView.context === ModeEnum.Disabled;

  const [confirmInput, setConfirmInput] = useState<string>('');
  const sendHedgeCommandMutation = useHedgeRuleCommandMutation();
  const canSubmit = confirmInput.toLocaleLowerCase() === 'confirm';

  const handleOnSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      mixpanel.track(MixpanelEvent.ConfirmGlobalAutohedgingCommand, { type: previewView.context });

      sendHedgeCommandMutation
        .mutateAsync({
          Command: disabling ? HedgeCommandEnum.Kill : HedgeCommandEnum.Activate,
        })
        .then(() => {
          previewView.close();
        });
    },
    [disabling, mixpanel, previewView, sendHedgeCommandMutation]
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (previewView.isOpen) {
      inputRef.current?.focus({ preventScroll: true });
    }
  }, [previewView.isOpen]);

  return (
    <PreviewView disclosure={previewView}>
      <form onSubmit={handleOnSubmit}>
        <Flex flexDirection="column" pt="spacingDefault" px="spacingDefault" gap="spacingDefault" position="relative">
          {sendHedgeCommandMutation.isPending && <LoadingOverlay />}

          <Alert variant={AlertVariants.Negative} dismissable={false}>
            Position Autohedging will be{' '}
            {disabling
              ? 'disabled. All active orders will be canceled.'
              : 'activated. Hedge rules will be applied to your positions.'}
          </Alert>

          <FormGroup label="To confirm, type “confirm” below" style={{ marginBottom: 0 }}>
            <Input
              data-testid="confirm-input"
              ref={inputRef}
              value={confirmInput}
              onChange={e => setConfirmInput(e.target.value)}
            />
          </FormGroup>

          <Flex flexDirection="column">
            <Divider mb="spacingSmall" />
            <ButtonGroup gap="spacingSmall" py="spacingSmall">
              <Button
                variant={ButtonVariants.Default}
                onClick={() => {
                  previewView.close();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!canSubmit}
                flex={6}
                variant={disabling ? ButtonVariants.Negative : ButtonVariants.Positive}
                type="submit"
                data-testid="confirm-button"
              >
                {disabling ? 'Disable' : 'Activate'} Position Autohedging
              </Button>
            </ButtonGroup>
          </Flex>
        </Flex>
      </form>
    </PreviewView>
  );
}

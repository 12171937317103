import type { ColumnPinnedType, ColumnState, IRowNode } from 'ag-grid-community';
import { noop } from 'lodash-es';
import { createContext, useContext, useMemo } from 'react';
import { EMPTY_OBJECT } from '../../utils/empty';
import type { ValueOf } from '../../utils/types';
import { getAgGridColId, type Column } from './columns';
import type {
  BlotterColumnState,
  BlotterTableColumnSortItem,
  BlotterTableFilter,
  BlotterTableSort,
  RowGroupsOpenedState,
} from './types';
import { getAllParentsOfNodeInclusive } from './utils';

/** AgGrid Grid storage model used with BlotterTableStorageContextProps  */
export type BlotterTableState = {
  [blotterId: string]: {
    /** Talos' custom Blotter Column state model */
    columns?: BlotterColumnState[];
    /** Talos' custom Blotter Filter model */
    filter?: BlotterTableFilter;
    /** Talos' custom Blotter Row Groups Opened model */
    rowGroupsOpened?: RowGroupsOpenedState;
    /** Backward-compatibility setting to prior column format (sort is now stored in columnState) */
    sort?: BlotterTableSort;
  };
};

export const BlotterTableStorageContext = createContext<BlotterTableStorageContextProps | undefined>(undefined);
BlotterTableStorageContext.displayName = 'BlotterTableStorage';

export function useBlotterTableStorage() {
  const context = useContext(BlotterTableStorageContext);
  if (context === undefined) {
    throw new Error('Missing BlotterTableStorageContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export function useBlotterTableStorageById(blotterId: string | null) {
  const context = useBlotterTableStorage();
  const wrappedContext = useMemo(() => {
    if (blotterId == null) {
      return {
        getState: () => EMPTY_OBJECT as ValueOf<BlotterTableState>,
        blotterFilter: undefined,
        setColumnState: noop,
        setFilterState: noop,
        resetColumnState: noop,
      };
    }

    return {
      getState: () => context.getState(blotterId),
      setColumnState: (blotterColumnState: BlotterColumnState[]) =>
        context.setColumnState(blotterId, blotterColumnState),
      setFilterState: (filterState?: BlotterTableFilter) => context.setFilterState(blotterId, filterState),
      setRowGroupsOpenedState: (rowGroupsOpened: RowGroupsOpenedState) =>
        context.setRowGroupsOpenedState(blotterId, rowGroupsOpened),
      resetBlotterColumnState: () => context.resetColumnState(blotterId),
    };
  }, [blotterId, context]);
  return wrappedContext;
}

export function columnToColumnState(column: Column): BlotterColumnState {
  return {
    id: getAgGridColId(column),
    hide: column.hide,
    width: column.width,
    pinned: column.pinned,
    rowGroup: column.rowGroup,
    rowGroupIndex: column.rowGroupIndex,
  };
}

export function columnStateToBlotterColumnState(columnState: ColumnState): BlotterColumnState {
  function convertPinned(gridPinned: ColumnPinnedType): BlotterColumnState['pinned'] {
    if (gridPinned === true) {
      return 'left';
    }
    return gridPinned || undefined;
  }
  function convertSort(gridSort: ColumnState['sort']): BlotterColumnState['sort'] {
    switch (gridSort) {
      case 'asc':
        return '+';
      case 'desc':
        return '-';
      default:
        return undefined;
    }
  }
  return {
    id: columnState.colId,
    hide: columnState.hide ?? undefined,
    width: columnState.width,
    sort: convertSort(columnState.sort),
    sortIndex: columnState.sortIndex ?? undefined,
    pinned: convertPinned(columnState.pinned),
    rowGroup: columnState.rowGroup ?? undefined,
    rowGroupIndex: columnState.rowGroupIndex ?? undefined,
  };
}

export function blotterColumnStateToColumnState(columnState: BlotterColumnState): ColumnState {
  function convertPinned(gridPinned: BlotterColumnState['pinned']): ColumnState['pinned'] {
    // a column is either pinned or not pinned, so if it's not pinned it's considered unpinned
    return gridPinned ?? false;
  }
  function convertSort(gridSort: BlotterColumnState['sort']): ColumnState['sort'] {
    switch (gridSort) {
      case '+':
        return 'asc';
      case '-':
        return 'desc';
      default:
        return undefined;
    }
  }
  return {
    colId: columnState.id,
    hide: columnState.hide ?? null,
    width: columnState.width,
    sort: convertSort(columnState.sort) ?? null,
    sortIndex: columnState.sortIndex ?? null,
    pinned: convertPinned(columnState.pinned),
    rowGroup: columnState.rowGroup ?? null,
    rowGroupIndex: columnState.rowGroupIndex ?? null,
  };
}

export function nodeToRowGroupOpenedStateKey(node: IRowNode): string {
  return getAllParentsOfNodeInclusive(node)
    .reverse()
    .map(node => node.key)
    .join('__');
}

/** Utility function to get sort storage value based on BlotterColumnState */
export function mapBlotterColumnStateToBlotterTableSort(
  columnState: BlotterColumnState[]
): BlotterTableSort | undefined {
  return columnState
    .filter(column => column.sort != null && column.sortIndex != null)
    .sort((a, b) => a.sortIndex! - b.sortIndex!)
    .map((column): BlotterTableColumnSortItem => `${column.sort!}${column.id}`);
}

export interface BlotterTableStorageContextProps {
  getState(blotterID: string): ValueOf<BlotterTableState> | undefined;
  setColumnState(blotterID: string, columns: BlotterColumnState[]): void;
  resetColumnState(blotterID: string): void;
  setFilterState(blotterID: string, filter?: BlotterTableFilter): void;
  setRowGroupsOpenedState(blotterID: string, rowGroupsOpened: RowGroupsOpenedState): void;

  /** Extreme behavior: Clear storage state of all blotters - Only used in Principal cases,
   * TODO: Can we remove this now that 'Reset columns' has support */
  clearAllBlotterState(): void;
}

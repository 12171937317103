import { IconName, formattedDate, parseDate, useSecuritiesContext, type FilterableProperty } from '@talos/kyoko';
import { isBefore, startOfDay, startOfToday } from 'date-fns';
import { useMemo } from 'react';

const GROUP_FORMAT = '{Month} {yyyy}';

/**
 * Returns a FilterableProperty definition where the options are all the securityList expirations from today and forward.
 * Expiries are unique per day.
 */
export function useDerivativeExpiriesFilter() {
  const { securitiesList } = useSecuritiesContext();

  const expiryStrings = useMemo(() => {
    // Unix timestamps of start of days for each expiry
    const uniqueExpiryDays = new Set<number>();
    const today = startOfToday();
    for (const security of securitiesList) {
      if (!security.Expiration) {
        continue;
      }

      const date = startOfDay(new Date(security.Expiration));

      // If the expiry date is before the start of today, ignore it
      if (isBefore(date, today)) {
        continue;
      }

      if (uniqueExpiryDays.has(date.getTime())) {
        continue;
      }

      uniqueExpiryDays.add(date.getTime());
    }

    return [...uniqueExpiryDays].map(time => new Date(time).toISOString());
  }, [securitiesList]);

  return useMemo(
    () => ({
      ...({
        key: 'Expiries',
        label: 'Expiry',
        icon: IconName.Calendar,
        control: 'multi-select',
        options: expiryStrings,
        getOptionLabel: (expiry: string) => formattedDate(expiry, '{dd} {Month} {yyyy}'),
        getOptionGroup: (expiry: string) => formattedDate(expiry, GROUP_FORMAT),
        groupSorter: (a, b) => {
          // The group labels are dates formatted to strings using GROUP_FORMAT, parse back to dates and compare.
          return parseDate(a.group).getTime() - parseDate(b.group).getTime();
        },
        dropdownOptions: {
          dropdownWidth: '300px',
          maxHeight: 300,
        },
      } as const satisfies FilterableProperty),
    }),
    [expiryStrings]
  );
}

import { useEffect } from 'react';
import { usePortal } from './usePortal';

/** A unique identifier of our top-level portals */
export const PORTAL_PARENT_CLASS = '__portal-parent__';

export const useTopLevelPortalElement = (portalId: string) => {
  const { portalRef, setPortalRef } = usePortal(portalId);
  useEffect(() => {
    if (!portalRef) {
      let parentElem = document.querySelector<HTMLDivElement>(`#${portalId}`);

      if (!parentElem) {
        parentElem = document.createElement('div');
        parentElem.setAttribute('id', portalId);
        parentElem.setAttribute('data-testid', portalId);

        // Simple workaround for preventing custom date filter to close when selecting a date:
        // @see https://www.ag-grid.com/react-grid/component-filter/#custom-filters-containing-a-popup-element
        // Also, set a unique class attribute on the talos portals such that other elements can figure out if they're within a portal
        parentElem.setAttribute('class', `ag-custom-component-popup ${PORTAL_PARENT_CLASS}`);

        document.body.insertBefore(parentElem, document?.body?.lastElementChild?.nextElementSibling ?? null);
      }
      setPortalRef(parentElem);
    }
  }, [portalId, portalRef, setPortalRef]);
};

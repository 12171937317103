import type { ValueFormatterParams } from 'ag-grid-community';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export const customer: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueFormatter: ({ value, context }: ValueFormatterParams<unknown, any>) => {
    if (!value) {
      return '';
    }
    return context.current.customersByName?.get(value)?.DisplayName || value;
  },
});

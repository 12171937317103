import { cloneDeep, merge } from 'lodash-es';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { DarkTheme, mapDarkTheme } from './DarkTheme';

const customcolors: Partial<Colors> = {
  primary: {
    mute: setAlpha(0.1, '#1C5184'),
    dim: setAlpha(0.4, '#1C5184'),
    default: '#1C5184',
    lighten: '#2365A5',
  },

  green: {
    mute: setAlpha(0.1, '#1A8440'),
    dim: setAlpha(0.4, '#1A8440'),
    default: '#1A8440',
    lighten: '#29CE64',
  },
  red: {
    mute: setAlpha(0.1, '#C83636'),
    dim: setAlpha(0.4, '#C83636'),
    default: '#C83636',
    lighten: '#FA4444',
  },
  blue: {
    mute: setAlpha(0.1, '#2365A5'),
    dim: setAlpha(0.4, '#2365A5'),
    default: '#2365A5',
    lighten: '#3085D8',
  },
  yellow: {
    mute: setAlpha(0.1, '#CC8907'),
    dim: setAlpha(0.4, '#CC8907'),
    default: '#CC8907',
    lighten: '#FFAB09',
  },
  gray: {
    main: '#182740',
    // https://hihayk.github.io/scale/#8/3/40/95/0/0/0/0/182740/24/39/64/white
    // worked well for me

    '000': '#0E1726',
    '010': '#10192A',
    '020': '#111B2D',
    '030': '#121D30',
    '040': '#131F33',
    '050': '#142136',
    '060': '#16233A',
    '070': '#17253D',
    '080': '#616B7D',
    '090': '#AAB0B9',
    '100': '#F3F4F5',
  },
};

const colors: Colors = merge(cloneDeep(DarkTheme.colors), customcolors);

export const DarkCFTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkCF',

  backgroundSidebar: colors.gray.main,

  backgroundPrimaryButton: colors.white.default,
  backgroundPrimaryButtonHover: setAlpha(0.9, colors.white.default),
  backgroundPrimaryButtonActive: setAlpha(0.9, colors.white.default),
  backgroundPrimaryButtonFocus: setAlpha(0.8, colors.white.default),
  backgroundPrimaryButtonDisabled: setAlpha(0.6, colors.white.default),

  colorTextPrimary: colors.white.default,
  colorTextPrimaryButton: colors.black.default,
  colorTextPrimaryButtonHover: colors.black.default,
  colorTextPrimaryButtonFocus: colors.black.default,
  colorTextPrimaryButtonActive: colors.black.default,
  colorTextPrimaryButtonDisabled: colors.black.default,
});

import { EMPTY_OBJECT, useUserContext, type User } from '@talos/kyoko';
import { useAppInboxToasts } from '@talos/kyoko/src/hooks/Toast/useGlobalToasts';
import { compact } from 'lodash-es';
import { userInfoSlice } from 'providers/AppStateProvider/userInfoSlice';
import { useEffect, useRef } from 'react';
import { useAppStateDispatch, useAppStateSelector } from '../AppStateProvider';
import { useGetExistingNotificationsQuery, useGetNotificationDataQuery } from './redux/AppNotificationsApi';

export const useUserFromState = (): User | undefined => {
  const { user } = useUserContext();
  const dispatch = useAppStateDispatch();
  useEffect(() => {
    dispatch(userInfoSlice.actions.setUserInfo(user));
  }, [dispatch, user]);
  const userFromState = useAppStateSelector(state => state.userInfo.user);
  return userFromState;
};

export const AppNotificationToast = () => {
  /** Temporary setup until UserApi RTK Query setup is done */
  const user = useUserFromState();
  const dispatch = useAppStateDispatch();

  const { add, remove } = useAppInboxToasts();

  // Kick off notifications queries - processing into redux state happens in the slice reducer
  // All of these results will be processed directly to the inbox, since these are previously existing notifications
  const { data: restNotifications } = useGetExistingNotificationsQuery(undefined, {
    skip: !user,
  });
  // start WS query once initial rest one is handled
  useGetNotificationDataQuery(EMPTY_OBJECT, {
    skip: !user || !restNotifications,
  });

  const toastsInUse = useRef(new Set<string>());
  const appNotificationState = useAppStateSelector(state => state.appNotifications);

  useEffect(() => {
    const newToasts = compact(
      Object.values(appNotificationState.notificationMap).filter(notification => notification?.state === 'new')
    );
    const removedToastsId = compact(
      Object.values(appNotificationState.notificationMap)
        .filter(appNotification => appNotification?.state !== 'new')
        .map(item => item?.notification.NotificationID)
    );

    newToasts.forEach(({ toastInfo, notification }) => {
      if (toastsInUse.current.has(toastInfo.id)) {
        return;
      }

      add(toastInfo);
      toastsInUse.current.add(toastInfo.id);
    });

    const withdrawnToastIds = Array.from(toastsInUse.current).filter(toastId => removedToastsId.includes(toastId));
    withdrawnToastIds.forEach(toastId => {
      remove(toastId);
      toastsInUse.current.delete(toastId);
    });
  }, [add, appNotificationState.notificationMap, dispatch, remove]);

  return null;
};

import {
  Flex,
  FormControlSizes,
  HStack,
  Icon,
  IconButton,
  IconName,
  Panel,
  PanelContent,
  PanelHeader,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  Text,
  Tooltip,
  TreasuryLinkTransactionType,
  TreasuryLinkTypeEnum,
  VStack,
  getTreasuryLinkDirection,
  useTabs,
  type MarketAccount,
  type TabProps,
} from '@talos/kyoko';
import { first } from 'lodash-es';
import { useTheme } from 'styled-components';
import type { DestinationAccount } from '../types';
import {
  CURRENCY_COLUMN_WIDTH,
  EditableCurrencyRowsBlotter,
  type EditableCurrencyRowsBlotterProps,
} from './EditableCurrencyRowsBlotter';
import { NewLinkButton } from './NewLinkButton';
import { TreasuryMarketAccountSelector } from './TreasuryMarketAccountSelector/TreasuryMarketAccountSelectorAsWrapper';
import { useAddressSummary } from './hooks/useAddressSummary';
import { useEditableCurrencyRowsBlotterDataObs } from './useEditableCurrencyRowsBlotterDataObs';

interface ManageAddressesProps {
  /** The entity we're editing addresses for. Undefined signifies the "global" market account concept */
  entity: MarketAccount | DestinationAccount | undefined;
  onClickReturn: () => void;
  linkType: TreasuryLinkTypeEnum;
}

interface AddressTabProps extends TabProps {
  label: TreasuryLinkTransactionType;
}

const TABS: AddressTabProps[] = [
  { label: TreasuryLinkTransactionType.Withdrawals },
  { label: TreasuryLinkTransactionType.Deposits },
];

export const ManageAddresses = ({ entity, onClickReturn, linkType }: ManageAddressesProps) => {
  const tabs = useTabs({ initialItems: TABS });

  const transactionType =
    linkType === TreasuryLinkTypeEnum.CustomerTransfer ? TABS[tabs.selectedIndex].label : undefined;

  const useTransactionTypeSelection = transactionType != null;

  const summaries = useAddressSummary(linkType, transactionType, entity);
  const { description, displayNameLong } = first(summaries) ?? {};

  return (
    <Panel>
      <PanelHeader>
        <HStack gap="spacingDefault">
          <IconButton icon={IconName.ArrowLeft} size={FormControlSizes.Small} onClick={onClickReturn} />
          <Text size="fontSizeBig" color="colorTextAttention">
            Addresses: {displayNameLong}
          </Text>
        </HStack>
      </PanelHeader>
      <PanelContent>
        {useTransactionTypeSelection ? (
          <Tabs h="100%" gap="spacingMedium" {...tabs} size={TabSize.Large}>
            <TabList>
              {tabs.items.map((tab, index) => (
                <Tab key={index} {...tab} />
              ))}
            </TabList>
            <Text>{description}</Text>
            <Summaries type={linkType} entity={entity} transactionType={transactionType} />

            <TabPanels overflow="auto" flex="1">
              <CustomerEditableCurrencyRowsBlotter
                entity={entity}
                linkType={linkType}
                transactionType={TreasuryLinkTransactionType.Withdrawals}
              />
              <CustomerEditableCurrencyRowsBlotter
                entity={entity}
                linkType={linkType}
                transactionType={TreasuryLinkTransactionType.Deposits}
              />
            </TabPanels>
          </Tabs>
        ) : (
          <VStack h="100%" gap="spacingMedium">
            <Text w="100%">{description}</Text>
            <Summaries type={linkType} entity={entity} />
            <NonCustomerEditableCurrencyRowsBlotter entity={entity} linkType={linkType} />
          </VStack>
        )}
      </PanelContent>
    </Panel>
  );
};

const CustomerEditableCurrencyRowsBlotter = ({
  entity,
  linkType,
  transactionType,
}: {
  entity: EditableCurrencyRowsBlotterProps['entity'];
  linkType: TreasuryLinkTypeEnum;
  transactionType: TreasuryLinkTransactionType;
}) => {
  const treasuryLinksObs = useEditableCurrencyRowsBlotterDataObs({
    type: linkType,
    transactionType,
    marketAccountName: entity?.Name,
  });

  const viewIsMarketAccountSpecific = entity != null;

  return (
    <VStack h="100%" w="100%">
      {viewIsMarketAccountSpecific && (
        <HStack justifyContent="flex-end" w="100%">
          <NewLinkButton
            marketAccount={entity.Name}
            type={linkType}
            direction={getTreasuryLinkDirection(linkType, transactionType)}
            treasuryLinksObs={treasuryLinksObs}
          />
        </HStack>
      )}
      <EditableCurrencyRowsBlotter
        type={TreasuryLinkTypeEnum.CustomerTransfer}
        entity={entity}
        transactionType={transactionType}
        treasuryLinksObs={treasuryLinksObs}
      />
    </VStack>
  );
};

const NonCustomerEditableCurrencyRowsBlotter = ({
  entity,
  linkType,
}: {
  entity: EditableCurrencyRowsBlotterProps['entity'];
  linkType: TreasuryLinkTypeEnum;
}) => {
  const treasuryLinksObs = useEditableCurrencyRowsBlotterDataObs({
    type: linkType,
    marketAccountName: entity?.Name,
  });

  const viewIsMarketAccountSpecific = entity != null;

  return (
    <>
      {viewIsMarketAccountSpecific && (
        <HStack justifyContent="flex-end" w="100%">
          <NewLinkButton
            marketAccount={entity.Name}
            type={linkType}
            direction={getTreasuryLinkDirection(linkType, undefined)}
            treasuryLinksObs={treasuryLinksObs}
          />
        </HStack>
      )}
      <EditableCurrencyRowsBlotter type={linkType} entity={entity} treasuryLinksObs={treasuryLinksObs} />
    </>
  );
};

interface SummariesProps {
  type: TreasuryLinkTypeEnum;
  transactionType?: TreasuryLinkTransactionType;
  entity?: MarketAccount | DestinationAccount;
}

const Summaries = ({ type, transactionType, entity }: SummariesProps) => {
  const direction = getTreasuryLinkDirection(type, transactionType);
  // ManageAddresses summaries are only used to populate the header, so we can safely pass in true for the isHeaderSummary parameter
  const summaries = useAddressSummary(type, transactionType, entity, true);
  const { displayTitle, subtitle } = first(summaries) ?? {};
  const { spacingLarge, spacingMedium } = useTheme();

  return (
    <HStack
      gap="spacingLarge"
      w="100%"
      background="backgroundCard"
      borderRadius="borderRadiusSmall"
      p="spacingLarge"
      pr="spacingMedium"
    >
      <VStack
        alignItems="flex-start"
        gap="spacingDefault"
        w={`${CURRENCY_COLUMN_WIDTH - spacingLarge - spacingMedium}px`}
      >
        <HStack gap="spacingDefault" color="colorTextAttention" whiteSpace="nowrap">
          <Text size="fontSizeBig">{displayTitle} </Text>
        </HStack>
        <Text>{subtitle}</Text>
      </VStack>
      <Flex
        h="100%"
        flex="1"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap="spacingMedium"
      >
        {summaries.map(({ sourceOrDestinationField, selectorTitle, summaryTooltip }, i) => (
          <VStack minWidth="15rem" w="100%" key={i} alignItems="flex-start" gap="spacingDefault">
            <HStack w="100%" justifyContent="space-between">
              <Text color="colorTextSubtle">{selectorTitle}</Text>
              <Tooltip tooltip={summaryTooltip} placement={summaries.length === 1 ? 'left' : undefined}>
                <Icon icon={IconName.QuestionMarkCircle} />
              </Tooltip>
            </HStack>
            <TreasuryMarketAccountSelector
              type={type}
              allowReset={true}
              sourceOrDestinationField={sourceOrDestinationField}
              marketAccountName={entity?.Name}
              direction={direction}
            />
          </VStack>
        ))}
      </Flex>
    </HStack>
  );
};

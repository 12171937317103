import {
  PORTFOLIO_OTC_POSITIONS,
  formattedDateForSubscription,
  useObservable,
  useObservableValue,
  useSubscription,
  wsScanToMap,
} from '@talos/kyoko';
import type { MarketPositions, Position } from 'containers/Portfolio/types';
import { createContext, useContext, useEffect, useMemo, useState, type PropsWithChildren } from 'react';
import { map, shareReplay } from 'rxjs';

export const PortfolioPositionContext = createContext<PortfolioPositionContextProps | undefined>(undefined);
PortfolioPositionContext.displayName = 'PortfolioPositionContext';

export interface PortfolioPositionContextProps {
  OTCPositionsList: MarketPositions[] | undefined;
  OTCPositionsByMarketAccountCurrency: Map<string, Map<string, Position>> | undefined;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
}

export function usePortfolioPositions() {
  const context = useContext(PortfolioPositionContext);
  if (context === undefined) {
    throw new Error('Missing PortfolioPositionContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const PortfolioPositionProvider = function PortfolioPositionProvider({ children }: PropsWithChildren) {
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [positionsRequest, setPositionsRequest] = useState<{ name: string; tag: string; EndDate?: string }>({
    name: PORTFOLIO_OTC_POSITIONS,
    tag: 'PortfolioPositionProvider',
    EndDate: endDate ? formattedDateForSubscription(endDate) : undefined,
  });

  useEffect(() => {
    setPositionsRequest({
      name: PORTFOLIO_OTC_POSITIONS,
      tag: 'PortfolioPositionProvider',
      EndDate: endDate ? formattedDateForSubscription(endDate) : undefined,
    });
  }, [endDate]);

  const { data: OTCPositionsRawObs } = useSubscription<MarketPositions>(positionsRequest);

  const OTCPositionsListObs = useObservable(
    () =>
      OTCPositionsRawObs.pipe(
        wsScanToMap({ getUniqueKey: d => d.MarketAccount, newMapEachUpdate: false }),
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [OTCPositionsRawObs]
  );

  const OTCPositionsByMarketAccountCurrencyObs = useObservable(
    () =>
      OTCPositionsListObs.pipe(
        map(marketPositionsList => {
          const newMap = new Map<string, Map<string, Position>>();

          for (const mpos of marketPositionsList) {
            newMap.set(mpos.MarketAccount, new Map(mpos.Positions.map(pos => [pos.Currency, pos])));
          }

          return newMap;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [OTCPositionsListObs]
  );

  const OTCPositionsList = useObservableValue(() => OTCPositionsListObs, [OTCPositionsListObs]);
  const OTCPositionsByMarketAccountCurrency = useObservableValue(
    () => OTCPositionsByMarketAccountCurrencyObs,
    [OTCPositionsByMarketAccountCurrencyObs]
  );

  const value = useMemo(
    () => ({
      OTCPositionsList,
      OTCPositionsByMarketAccountCurrency,
      setEndDate,
      endDate,
    }),
    [OTCPositionsList, setEndDate, endDate, OTCPositionsByMarketAccountCurrency]
  );

  return <PortfolioPositionContext.Provider value={value}>{children}</PortfolioPositionContext.Provider>;
};

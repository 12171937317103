import {
  AGGRID_AUTOCOLUMN_ID,
  BlotterTable,
  BlotterTableFilters,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
  FormControlSizes,
  IconButton,
  IconName,
  PnlLookbackEnum,
  useBlotterTable,
  useObservable,
  usePersistedBlotterTable,
  type MinimalSubscriptionResponse,
  type Position,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { PerformanceActionType } from 'containers/Portfolio/Performance/PerformanceReducer';
import { usePerformanceContext } from 'containers/Portfolio/Performance/providers/PerformanceStateAndTabsProvider';
import { useEffect, useMemo } from 'react';
import { combineLatest, map, scan, shareReplay, startWith, type Observable } from 'rxjs';
import { usePortfolioManagementProvider } from '../../providers/PortfolioManagementProvider';
import { PortfolioManagementBlotterDataItem } from '../../types/PortfolioManagementBlotterDataItem';
import type { ContextSelectionBlotterParams } from '../../types/types';
import { BlotterIndent } from '../BlotterIndent';
import { usePortfolioManagementBlotterPositionRequestContext } from '../PortfolioManagementPositionRequestProvider';
import { portfolioManagementPositionRequestSlice } from '../portfolioManagementPositionRequestSlice';
import { useRollupTreeGridBuilders } from '../PortfolioRiskBlotter/useRollupTreeGridBuilders';
import { useGroupedDataExportContext } from '../RiskBlotterExportMenuItems';
import { RiskBlotterSuffixMenu } from '../RiskBlotterSuffixMenu';
import { getPositionMapKey } from '../types';
import { useRollupTreeMenu } from '../useRollupTreeMenu';
import { movePmsWarningsColumnToFirstColumn } from '../utils';
import { usePMSSubAccountPositionsSub } from './usePMSSubAccountPositionsSub';
import { usePortfolioPerformanceColumns } from './usePortfolioPerformanceColumns';

const { setColumnsForTags } = portfolioManagementPositionRequestSlice.actions;

export function PortfolioPerformanceBlotter({
  blotterID,
  wrapperContext,
  subAccountId,
}: ContextSelectionBlotterParams) {
  const { dispatch } = usePerformanceContext();
  useEffect(() => {
    dispatch({
      type: PerformanceActionType.PnlLookbacksChange,
      payload: {
        pnlLookbacks: [PnlLookbackEnum.H24],
      },
    });
  }, [dispatch]);

  const { riskSubAccountObs } = usePortfolioManagementProvider();

  const perfObs = usePMSSubAccountPositionsSub();

  const sharedObs = useObservable(() => riskSubAccountObs.pipe(shareReplay(1)), [riskSubAccountObs]);
  const gridObs = useMemo<Observable<MinimalSubscriptionResponse<PortfolioManagementBlotterDataItem>>>(() => {
    return combineLatest([
      riskSubAccountObs,
      perfObs.pipe(
        // this specific startWith immediately starts the observable so that combineLatest doesn't have to wait
        startWith({ data: [] }),
        scan((result, next) => {
          next.data.forEach(item => {
            result[getPositionMapKey(item)] = item;
          });
          return result;
        }, {} as Record<string, Position>)
      ),
    ]).pipe(
      map(data => {
        const [risk, posPnlLookbacks] = data;
        // combine risk and position data
        const outputData = risk.data.map(item => {
          const key = getPositionMapKey(item.gridData);
          const positionPnl = posPnlLookbacks[key];
          return new PortfolioManagementBlotterDataItem(item, positionPnl);
        });
        return {
          ...risk,
          data: outputData,
        };
      })
    );
  }, [perfObs, riskSubAccountObs]);

  const { defaultColumns } = usePortfolioPerformanceColumns();
  const rollupTreeGridBuilders = useRollupTreeGridBuilders();

  const persistedBlotterTable: UsePersistedBlotterTable<PortfolioManagementBlotterDataItem> = usePersistedBlotterTable(
    blotterID,
    {
      columns: defaultColumns,
      sort: `+${AGGRID_AUTOCOLUMN_ID}`,
      onApplyInitialState: movePmsWarningsColumnToFirstColumn,
    }
  );

  const { getContextMenuItems, dialogs } = useRollupTreeMenu();

  const groupedDataExportContext = useGroupedDataExportContext();

  const { dispatch: requestDispatch } = usePortfolioManagementBlotterPositionRequestContext();
  useEffect(() => {
    requestDispatch(setColumnsForTags(persistedBlotterTable.columnsOnly));
  }, [persistedBlotterTable.columns, persistedBlotterTable.columnsOnly, requestDispatch]);

  const blotterTable = useBlotterTable<PortfolioManagementBlotterDataItem>({
    dataObservable: gridObs,
    rowID: 'rowID',
    context: groupedDataExportContext,
    columns: persistedBlotterTable.columns,
    persistence: persistedBlotterTable,
    gridOptions: {
      ...rollupTreeGridBuilders.gridOptionsOverlay,
      rowSelection: DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
      getContextMenuItems,
      groupDefaultExpanded: -1,
    },
  });

  useEffect(() => {
    if (blotterTable.gridApi) {
      if (subAccountId) {
        blotterTable.gridApi.expandAll();
      } else {
        blotterTable.gridApi.collapseAll();
      }
    }
  }, [subAccountId, blotterTable.gridApi]);

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  return (
    <>
      <BlotterTableFilters
        {...blotterTable.blotterTableFiltersProps}
        showFilterBuilder={false}
        showQuickFilterOnly={false}
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
          </>
        }
        suffix={
          <RiskBlotterSuffixMenu
            riskObs={sharedObs}
            exportDataAsCSV={blotterTable.exportDataAsCSV}
            exportDataAsExcel={blotterTable.exportDataAsExcel}
            label={wrapperContext.label}
          />
        }
      />
      <BlotterIndent h="100%">
        <BlotterTable {...blotterTable} />
      </BlotterIndent>
      {dialogs}
    </>
  );
}

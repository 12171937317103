import { ConnectionType } from '@talos/kyoko';
import { compact } from 'lodash-es';
import { useMSSByAccountBySymbol } from 'providers/MarketSecurityStatusProvider';
import { useMemo } from 'react';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';
import { marketAccountActiveCondition } from '../conditions/marketAccountActiveCondition';
import { useMarketConfiguredCondition } from '../conditions/useMarketConfiguredCondition';
import { useMarketOnlineCondition } from '../conditions/useMarketOnlineCondition';
import { useMSSCapabilityEnabledCondition } from '../conditions/useMSSCapabilityEnabledCondition';
import { useMSSEnabledCondition } from '../conditions/useMSSEnabledCondition';
import type { AvailabilityCondition } from '../types';

interface UseOrderMarketSelectorConditionsParams {
  /** The symbols to check the MSS record of for each account. Will only be more than one item in the unified liquidity case. */
  symbols: string[];
  /** Whether or not unified liquidity is enabled */
  unifiedLiquidityEnabled?: boolean;
}

export const useOrderMarketSelectorConditions = ({
  symbols,
  unifiedLiquidityEnabled,
}: UseOrderMarketSelectorConditionsParams) => {
  const { enableNewMarketSelector } = useTradingSettings();

  const mssByAccountBySymbol = useMSSByAccountBySymbol({ symbols, tag: `MarketSelector: Orders MSS Condition` });
  const marketConfiguredCondition = useMarketConfiguredCondition({ connectionType: ConnectionType.Orders });
  const marketOnlineCondition = useMarketOnlineCondition({ connectionType: ConnectionType.Orders });
  const mssMarketDataEnabledCondition = useMSSCapabilityEnabledCondition({
    capability: 'MarketData',
    symbols,
    mssByAccountBySymbol,
    unifiedLiquidityEnabled,
  });
  const mssEnabledCondition = useMSSEnabledCondition({ mssByAccountBySymbol, symbols, unifiedLiquidityEnabled });

  const conditions: AvailabilityCondition[] = useMemo(
    () =>
      compact([
        marketConfiguredCondition,
        marketOnlineCondition,
        marketAccountActiveCondition,

        // We want to keep the old market selector behavior as close to how it was as possible for deployment risk reasons so we can
        // disable the new one and get "old behavior"
        enableNewMarketSelector && mssMarketDataEnabledCondition,
        enableNewMarketSelector && mssEnabledCondition,
      ]),
    [
      enableNewMarketSelector,
      marketConfiguredCondition,
      marketOnlineCondition,
      mssMarketDataEnabledCondition,
      mssEnabledCondition,
    ]
  );

  return { conditions, allConditionsReady: conditions.every(c => c.ready) };
};

import {
  AgGridCurrency,
  AgGridSecurity,
  assetIsCurrency,
  assetIsSecurity,
  getAssetDisplaySymbol,
  stringColumnComparator,
  useAssetsContext,
  useDynamicCallback,
  type AgGridSearchSelectDropdownProps,
  type Asset,
  type ColumnDef,
  type Currency,
  type Security,
} from '@talos/kyoko';
import type { ColDef, ICellRendererParams, ValueSetterFunc } from 'ag-grid-community';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { set } from 'lodash-es';
import { useMemo } from 'react';

interface AssetAutocompleteItem {
  label: string;
  value: Asset | undefined;
  description?: string;
}

interface UseAssetColumnParams<T> {
  assetKey: keyof T & string;
  comparator?: ColDef<T>['comparator'];
  /**
   * An optional custom data modifier. This callback is called in the valueSetter, after changes have been applied to the data object.
   * This callback allows the implementer to examine the new state of the data and perform any additional modifications to the object.
   */
  customDataModificationOnChange?: (data: Partial<T>) => void;

  /**
   * In some cases, you only want a subset of all the possible assets. To achieve that, pass in a filter function here
   * to filter out specific securities or currencies.
   *
   * In the callback, either security or currency will be defined.
   */
  assetFilter?: ({ security, currency }: { security?: Security; currency?: Currency }) => boolean;
}

export const useAssetColumn = <T extends object>({
  assetKey,
  comparator,
  customDataModificationOnChange,
  assetFilter,
}: UseAssetColumnParams<T>) => {
  const { assetsList, resolveSecurityOrCurrency } = useAssetsContext();

  // We allow the implementer to apply their own filtering operator to this base assetsList data set
  const filteredAssets = useMemo(() => {
    if (!assetFilter) {
      return assetsList;
    }

    return assetsList.filter(asset => assetFilter(resolveSecurityOrCurrency(asset.Symbol)));
  }, [assetFilter, assetsList, resolveSecurityOrCurrency]);

  const autocompleteItems = useMemo(() => {
    const items: AssetAutocompleteItem[] = filteredAssets.map(asset => ({
      value: asset,
      label: getAssetDisplaySymbol(asset),
      description: asset.Description,
    }));

    items.unshift({
      label: '*',
      value: undefined,
      description: 'All assets',
    });

    return items;
  }, [filteredAssets]);

  const cellEditorParams = useDynamicCallback((params: CustomCellEditorProps) => {
    return {
      ...params,
      useSearchSelectParams: {
        items: autocompleteItems,
        getLabel: (s: AssetAutocompleteItem) => s.label,
        getDescription: (s: AssetAutocompleteItem) => s.description ?? '',
      },
      searchPlaceholder: 'Symbol or currency',
    } satisfies AgGridSearchSelectDropdownProps<AssetAutocompleteItem>;
  });

  const valueGetter = useDynamicCallback(({ data }: { data: T }) => {
    const asset = data[assetKey];
    if (asset == null || typeof asset != 'string') {
      return asset;
    }

    const { currency, security } = resolveSecurityOrCurrency(asset);
    return currency ?? security;
  });

  const valueSetter: ValueSetterFunc = useDynamicCallback(
    ({ newValue, data }: { newValue: AssetAutocompleteItem; data: Partial<T> }) => {
      delete data[assetKey];

      if (newValue == null || newValue.value == null) {
        customDataModificationOnChange?.(data);
        return true;
      }

      set(data, assetKey, newValue.value.Symbol);
      customDataModificationOnChange?.(data);

      return true;
    }
  );

  const valueFormatter = useDynamicCallback((params: { value?: Asset }) => {
    if (!params.value) {
      return '*';
    }
    return getAssetDisplaySymbol(params.value);
  });

  const cellRenderer = useDynamicCallback((params: ICellRendererParams<T, Asset | undefined>) => {
    if (params.value != null && assetIsSecurity(params.value)) {
      return <AgGridSecurity {...params} value={params.value.Symbol} showDescription />;
    }

    if (params.value != null && assetIsCurrency(params.value)) {
      return <AgGridCurrency {...params} value={params.value.Symbol} showDescription />;
    }

    return <>*</>;
  });

  return useMemo(
    () =>
      ({
        type: 'custom',
        id: assetKey,
        sortable: true,
        sort: '+',
        title: 'Asset',
        params: {
          comparator: comparator ?? defaultSymbolComparator,
          headerTooltip:
            'Enforce the limit for a specific symbol or currency, or leave empty (*) to enforce the limit for all assets.',
          colId: assetKey,
          field: assetKey,
          width: 175,
          editable: true,
          suppressKeyboardEvent: () => true,
          cellEditor: 'searchSelectDropdown',
          cellEditorPopup: true,
          cellEditorParams,
          valueSetter,
          valueGetter,
          valueFormatter,
          cellRenderer,
        },
      } satisfies ColumnDef<T>),
    [cellEditorParams, cellRenderer, valueFormatter, valueGetter, valueSetter, assetKey, comparator]
  );
};

function defaultSymbolComparator(valueA: Asset | undefined, valueB: Asset | undefined) {
  return stringColumnComparator(valueA?.Symbol, valueB?.Symbol);
}

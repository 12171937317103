import styled from 'styled-components';
import { Box, type BoxProps } from './Box';

export interface FlexProps extends BoxProps {}

/** Simple flex div
 *
 * Set flex properties without creating a new component.
 */
export const Flex = styled(Box)<FlexProps>`
  display: flex;
`;

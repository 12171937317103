import {
  CUSTOMER_BALANCE,
  type CustomerBalance,
  InlineFormattedNumber,
  type Security,
  SideEnum,
  Text,
  toBigWithDefault,
  useObservableValue,
  useSubscription,
  type UseSubscriptionRequest,
  wsScanToDoubleMap,
} from '@talos/kyoko';
import { type ReactNode, useMemo } from 'react';
import { useFeatureFlag } from '../../../../hooks';
import type { CustomerTradeForm } from './types';

const EMPTY_RETURN_VALUE = { node: null, invalidField: null };

export function useManualTradeCustomerBalanceCheck({
  form,
  security,
}: {
  form: CustomerTradeForm;
  security: Security | undefined;
}): {
  node: ReactNode;
  invalidField: 'amount' | 'quantity' | null;
} {
  const { BaseCurrency, QuoteCurrency } = security || {};
  const { checkCustomerBalanceOnNewCustomerTrade } = useFeatureFlag();

  const customerBalanceRequest: UseSubscriptionRequest = useMemo(() => {
    if (!form.counterparty || !BaseCurrency || !QuoteCurrency || !checkCustomerBalanceOnNewCustomerTrade) {
      return null;
    }

    return {
      name: CUSTOMER_BALANCE,
      tag: 'BookAndModifyTradeDialog',
      Counterparties: [form.counterparty],
      Currencies: [BaseCurrency, QuoteCurrency],
    };
  }, [BaseCurrency, QuoteCurrency, form.counterparty, checkCustomerBalanceOnNewCustomerTrade]);

  const { data: customerBalanceObs } = useSubscription<CustomerBalance>(customerBalanceRequest);

  const customerBalanceMapByAccountByCurrency: Map<string, Map<string, CustomerBalance>> = useObservableValue(
    () =>
      customerBalanceObs.pipe(
        wsScanToDoubleMap({
          getKey1: cb => cb.MarketAccount,
          getKey2: cb => cb.Currency,
          newInnerMapsEachUpdate: true,
          newOuterMapEachUpdate: true,
        })
      ),
    [customerBalanceObs],
    new Map<string, Map<string, CustomerBalance>>()
  );
  const accountCustomerBalances = form.marketAccount
    ? customerBalanceMapByAccountByCurrency.get(form.marketAccount)
    : undefined;

  const availableBalanceWarning = useMemo(() => {
    if (checkCustomerBalanceOnNewCustomerTrade === false) {
      return EMPTY_RETURN_VALUE;
    }

    if (form.side === SideEnum.Buy && QuoteCurrency) {
      const availableAmount = accountCustomerBalances?.get(QuoteCurrency)?.AvailableAmount;
      if (toBigWithDefault(form.amount, 0).gt(toBigWithDefault(availableAmount, 0))) {
        const node = !availableAmount ? (
          'Unable to resolve customer available amount'
        ) : (
          <Text>
            The buying amount exceeds the customer&apos;s available amount{' '}
            <InlineFormattedNumber number={availableAmount} currency={QuoteCurrency} />
          </Text>
        );

        return {
          node,
          invalidField: 'amount' as const,
        };
      }
    } else if (form.side === SideEnum.Sell && BaseCurrency) {
      const availableAmount = accountCustomerBalances?.get(BaseCurrency)?.AvailableAmount;
      if (toBigWithDefault(form.quantity, 0).gt(toBigWithDefault(availableAmount, 0))) {
        const node = !availableAmount ? (
          'Unable to resolve customer available amount'
        ) : (
          <Text>
            The selling quantity exceeds the customer&apos;s available amount{' '}
            <InlineFormattedNumber number={availableAmount} currency={BaseCurrency} />
          </Text>
        );
        return {
          node,
          invalidField: 'quantity' as const,
        };
      }
    }
    return { node: null, invalidField: null };
  }, [
    checkCustomerBalanceOnNewCustomerTrade,
    QuoteCurrency,
    accountCustomerBalances,
    form.amount,
    BaseCurrency,
    form.quantity,
    form.side,
  ]);

  return availableBalanceWarning;
}

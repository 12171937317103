import { CustomerOrderStrategiesEnum } from '../../../../tokens';
import { OrdTypeEnum, PresenceEnum } from '../../../../types';
import { ParameterTypeEnum } from '../../../../types/OrderStrategy.types';

const LimitStrategy = {
  Name: CustomerOrderStrategiesEnum.Limit,
  Group: 'All',
  OrdType: OrdTypeEnum.Limit,
  Parameters: [
    {
      Name: 'LimitPrice',
      Type: ParameterTypeEnum.Price,
      Presence: PresenceEnum.Required,
      Description: 'Limit Price for the order.',
      DisplayName: 'Limit Price',
    },
    {
      Name: 'StartTime',
      Type: ParameterTypeEnum.Date,
      Presence: PresenceEnum.Optional,
      Description: 'Time at which order will be placed on the market.',
      DisplayName: 'Start Time',
    },
    {
      Name: 'EndTime',
      Type: ParameterTypeEnum.Date,
      Presence: PresenceEnum.Optional,
      Description: 'Expire time for the order.',
      DisplayName: 'End Time',
    },
  ],
  Description:
    'For marketable orders, the algorithm will sweep the available liquidity up to the limit price by routing to those market(s) offering the best prices. The remaining quantity will be split equally among the selected market(s) at the limit price.',
  DisplayName: 'Limit',
};
const MultilegAggressiveLimit = {
  ...LimitStrategy,
  Name: CustomerOrderStrategiesEnum.MultilegAggressiveLimit,
};

export const resolveDeprecatedStrategy = (strategy: string) => {
  switch (strategy) {
    case 'MultilegAggressiveLimit':
      return MultilegAggressiveLimit;
    default:
      return undefined;
  }
};

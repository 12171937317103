import { ACTION, IconName, MixpanelEvent, MixpanelEventSource, MixpanelSourceProvider } from '@talos/kyoko';
import { type SidebarSubNavItem, type SidebarSubTitle, isSidebarSubTitle } from '@talos/kyoko/src/routed';
import { Settlement } from 'containers/Portfolio/Settlement';
import { useFeatureFlag } from 'hooks';
import { useIsPMSActive } from 'providers/PortfolioSettingContext';
import { useMemo } from 'react';
import { CustomerAddresses } from './CustomerAddresses';
import { CustomerAddressesLEGACY } from './CustomerAddresses/legacy';
import { CustomerAggregations } from './CustomerAggregations';
import { CustomerBalances } from './CustomerBalances';
import { CustomerConfigurations } from './CustomerConfigurations';
import { CustomerCredits } from './CustomerCredits';
import { CustomerCreditsLEGACY } from './CustomerCredits/legacy';
import { CustomerCreditProviderLEGACY } from './CustomerCredits/legacy/CustomerCreditProvider';
import { CustomerExecutionRules } from './CustomerExecutionRules';
import { CustomerExecutionRulesLEGACY } from './CustomerExecutionRules/legacy';
import { CustomerFIXConnections } from './CustomerFIXConnections';
import { CustomerFIXConnectionsLEGACY } from './CustomerFIXConnections/legacy';
import { CustomerMarketAccounts } from './CustomerMarketAccounts';
import { CustomerPricing } from './CustomerPricing';
import { CustomerPricingTiers } from './CustomerPricingTiers';
import { CustomerSymbolGroups } from './CustomerSymbolGroups';
import { CustomerTradingLimits } from './CustomerTradingLimits';
import { CustomerTransactions } from './CustomerTransactions';
import { CustomerUsers } from './CustomerUsers';
import { Monitoring } from './Monitoring';
import { SecurityMaster } from './SecurityMaster';

const TITLES = {
  Setup: 'SETUP',
  BuyingPower: 'BUYING POWER',
  Admin: 'ADMIN',
};

export const DEALER_SUB_NAV_ITEMS: (SidebarSubNavItem<'/dealer/'> | SidebarSubTitle)[] = [
  {
    label: 'Monitoring',
    path: '/dealer/monitoring',
    component: <Monitoring />,
    icon: IconName.DeviceDesktop,
    hasSubNavItems: true,
  },
  {
    label: 'Configurations',
    path: '/dealer/configurations',
    component: <CustomerConfigurations />,
    icon: IconName.Cog,
    requiredPermission: ACTION.VIEW_CUSTOMER_CONFIGURATIONS,
    mixpanelEvent: MixpanelEvent.NavigateToDealerConfigurations,
  },
  {
    label: 'Pricing Tiers',
    path: '/dealer/tiers',
    component: <CustomerPricingTiers />,
    icon: IconName.CubeTransparent,
    requiredPermission: ACTION.VIEW_CUSTOMER_PRICING_TIERS,
    mixpanelEvent: MixpanelEvent.NavigateToDealerPricingTiers,
  },
  {
    label: 'Symbol Groups',
    path: '/dealer/symbol-groups',
    component: <CustomerSymbolGroups />,
    icon: IconName.Cube,
    requiredPermission: ACTION.VIEW_CUSTOMER_SYMBOL_GROUPS,
    mixpanelEvent: MixpanelEvent.NavigateToDealerSymbolGroups,
  },
  {
    label: TITLES.Setup,
    type: 'title',
  },
  {
    label: 'Security Master',
    path: '/dealer/secmaster',
    component: <SecurityMaster />,
    icon: IconName.Key,
  },
  {
    label: 'Customers and Pricing',
    path: '/dealer/pricing',
    component: <CustomerPricing />,
    icon: IconName.CurrencyDollar,
  },
  {
    label: 'Aggregations',
    path: '/dealer/aggregations',
    component: <CustomerAggregations />,
    icon: IconName.CubeTransparent,
  },
  {
    label: 'Execution Rules',
    path: '/dealer/execution-rules',
    component: <CustomerExecutionRules />,
    icon: IconName.LightningBolt,
  },
  {
    label: TITLES.BuyingPower,
    type: 'title',
  },
  {
    label: 'Balances',
    path: '/dealer/balances',
    component: <CustomerBalances />,
    icon: IconName.Scale,
  },
  {
    label: 'Transfers',
    path: '/dealer/transfers',
    component: <CustomerTransactions />,
    icon: IconName.ArrowLeftRight,
  },
  {
    label: 'Credit',
    path: '/dealer/credit',
    component: <CustomerCredits />,
    icon: IconName.Counterparty,
  },
  {
    label: 'Addresses',
    path: '/dealer/addresses',
    component: <CustomerAddresses />,
    icon: IconName.Link,
  },
  {
    label: 'Customer Settlement',
    path: '/dealer/settlement',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.PortfolioSettlement}>
        <Settlement />
      </MixpanelSourceProvider>
    ),
    icon: IconName.ArrowLeftRight,
  },
  {
    label: TITLES.Admin,
    type: 'title',
  },
  {
    label: 'Users',
    path: '/dealer/users',
    component: <CustomerUsers />,
    icon: IconName.Users,
  },
  {
    label: 'Trading Limits',
    path: '/dealer/customer-limits',
    component: <CustomerTradingLimits />,
    icon: IconName.Preferences,
  },
  {
    label: 'Customer Accounts',
    path: '/dealer/customer-accounts',
    component: <CustomerMarketAccounts />,
    icon: IconName.UserCircle,
  },
  {
    label: 'FIX Connections',
    path: '/dealer/fix-connections',
    component: <CustomerFIXConnections />,
    icon: IconName.LightningBolt,
  },
];

type DealerSubNavItemsArg = {
  viewDealerAdminAndBuyingPowerMenuItems: boolean;
  showCustomerExecutionRules: boolean;
  showCustomerFIXConnections: boolean;
  showCustomerTiering: boolean;
  showCustomerSettlement: boolean;
  legacyEntityAdminConversions?: Record<string, boolean>;
};

const DEFAULT_LEGACY_ENTITY_ADMIN_CONVERSIONS = {
  '/dealer/execution-rules': false,
  '/dealer/fix-connections': false,
  '/dealer/credit': false,
  '/dealer/addresses': false,
} as const;

const LEGACY_DEALER_PAGE_BY_PATH = {
  '/dealer/execution-rules': <CustomerExecutionRulesLEGACY />,
  '/dealer/fix-connections': <CustomerFIXConnectionsLEGACY />,
  '/dealer/credit': (
    <CustomerCreditProviderLEGACY>
      <CustomerCreditsLEGACY />
    </CustomerCreditProviderLEGACY>
  ),
  '/dealer/addresses': <CustomerAddressesLEGACY />,
} as const;

// Show only Monitoring and Setup by default
// Buying Power and Admin under feature flag viewDealerAdminAndBuyingPowerMenuItems
const getDealerSubNavItems = ({
  viewDealerAdminAndBuyingPowerMenuItems,
  showCustomerExecutionRules,
  showCustomerFIXConnections,
  showCustomerTiering,
  showCustomerSettlement,
  legacyEntityAdminConversions = DEFAULT_LEGACY_ENTITY_ADMIN_CONVERSIONS,
}: DealerSubNavItemsArg) => {
  let effectiveItems: (SidebarSubNavItem<'/dealer/'> | SidebarSubTitle)[] = DEALER_SUB_NAV_ITEMS.map(item => {
    if (isSidebarSubTitle(item)) {
      return item;
    }
    const legacyComponent =
      item.path in legacyEntityAdminConversions && legacyEntityAdminConversions[item.path] === true
        ? LEGACY_DEALER_PAGE_BY_PATH[item.path as keyof typeof LEGACY_DEALER_PAGE_BY_PATH]
        : null;

    return legacyComponent ? { ...item, component: legacyComponent } : item;
  });

  if (!showCustomerExecutionRules) {
    // Find and remove "Execution Rules"
    effectiveItems = effectiveItems.filter(item => item.label !== 'Execution Rules');
  }

  if (!showCustomerFIXConnections) {
    // Find and remove "FIX Connections"
    effectiveItems = effectiveItems.filter(item => item.label !== 'FIX Connections');
  }

  if (!showCustomerTiering) {
    // Find and remove "Configurations", "Pricing Tiers", and "Symbol Groups"
    effectiveItems = effectiveItems.filter(item => item.label !== 'Configurations');
    effectiveItems = effectiveItems.filter(item => item.label !== 'Pricing Tiers');
    effectiveItems = effectiveItems.filter(item => item.label !== 'Symbol Groups');
  }

  if (!showCustomerSettlement) {
    effectiveItems = effectiveItems.filter(item => item.label !== 'Customer Settlement');
  }

  if (viewDealerAdminAndBuyingPowerMenuItems) {
    // Return everything
    return effectiveItems;
  }

  // Return only Monitoring and Setup
  const buyingPowerIndex = effectiveItems.findIndex(
    item => isSidebarSubTitle(item) && item.label === TITLES.BuyingPower
  );
  return effectiveItems.slice(0, buyingPowerIndex);
};

export const useDealerSubNavItems = () => {
  const {
    viewDealerAdminAndBuyingPowerMenuItems,
    showCustomerOrderAcceptanceRules,
    showCustomerTiering,
    enablePortfolioSettlement,
    useLegacyCustomerExecutionRules,
    useLegacyCustomerFIXConnections,
    useLegacyCustomerCredits,
    useLegacyCustomerAddresses,
    showCustomerFIXConnections,
  } = useFeatureFlag();
  const isPMSActive = useIsPMSActive();

  const legacyEntityAdminConversions = useMemo(
    () => ({
      '/dealer/execution-rules': useLegacyCustomerExecutionRules,
      '/dealer/fix-connections': useLegacyCustomerFIXConnections,
      '/dealer/credit': useLegacyCustomerCredits,
      '/dealer/addresses': useLegacyCustomerAddresses,
    }),
    [
      useLegacyCustomerAddresses,
      useLegacyCustomerCredits,
      useLegacyCustomerExecutionRules,
      useLegacyCustomerFIXConnections,
    ]
  );

  return useMemo(
    () =>
      getDealerSubNavItems({
        viewDealerAdminAndBuyingPowerMenuItems,
        showCustomerExecutionRules: showCustomerOrderAcceptanceRules,
        showCustomerFIXConnections: showCustomerFIXConnections,
        showCustomerTiering,
        showCustomerSettlement: isPMSActive && enablePortfolioSettlement,
        legacyEntityAdminConversions,
      }),
    [
      viewDealerAdminAndBuyingPowerMenuItems,
      showCustomerOrderAcceptanceRules,
      showCustomerFIXConnections,
      showCustomerTiering,
      isPMSActive,
      enablePortfolioSettlement,
      legacyEntityAdminConversions,
    ]
  );
};

import type { ICellRendererParams } from 'ag-grid-community';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

type IdParams = { maxLength?: number };
/** Common display mechanism for shortened id's */
export const getDisplayIdFromValue = (
  value: string | number | undefined,
  maxLength?: number
): string | number | undefined => {
  return value != null && typeof value === 'string' ? value?.substring(0, maxLength || 4) ?? '' : value;
};

export const id: ColDefFactory<Column<IdParams>> = column => ({
  minWidth: 85,
  ...baseColumn(column),
  cellRenderer: ({ value }: ICellRendererParams) => <div>{getDisplayIdFromValue(value, column.params?.maxLength)}</div>,
});

import { ACTION, EMPTY_ARRAY, EntityAdminREST, type ICustomerFIXConnection } from '@talos/kyoko';
import { useCustomerUsers } from 'hooks/useCustomer';
import { useRoleAuth } from 'hooks/useRoleAuth';
import { useMemo } from 'react';
import {
  CUSTOMER_FIX_CONNECTIONS_COLUMNS,
  getCustomerFixConnectionDrawerOptions,
  useGetCustomerFixConnectionContextMenuItems,
} from './utils';

export function CustomerFIXConnections() {
  const { isAuthorized } = useRoleAuth();
  const customerUsers = useCustomerUsers();

  const drawerOptions = useMemo(() => {
    if (!customerUsers) {
      return undefined;
    }
    return getCustomerFixConnectionDrawerOptions({ customerUsers });
  }, [customerUsers]);

  const getContextMenuItems = useGetCustomerFixConnectionContextMenuItems();

  return (
    <EntityAdminREST<ICustomerFIXConnection>
      title="FIX Connections"
      subtitle="Customer FIX Connection Configuration"
      entityName="FIX Connection"
      path="/organization/customer-fix-connections"
      entityIDField="TargetCompID"
      columns={CUSTOMER_FIX_CONNECTIONS_COLUMNS}
      drawerOptions={drawerOptions}
      allowAddEntity={isAuthorized(ACTION.ADD_CUSTOMER_FIX_CONNECTIONS)}
      persistKey="dealer/customer-fix-connections"
      filterableProperties={EMPTY_ARRAY}
      getContextMenuItems={getContextMenuItems}
      postEntityInArray={true}
    />
  );
}

import { isTalosUser, useSessionStorage } from '@talos/kyoko';
import type { Dispatch } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useUser } from '../hooks/useUser';

export const AdminHeaderContext = createContext<AdminHeaderContextProps | undefined>(undefined);

export interface AdminHeaderContextProps {
  showAdminHeader: boolean;
  isAdminHeaderDismissed: boolean;
  setIsAdminHeaderDismissed: Dispatch<React.SetStateAction<boolean>>;
}

export function useAdminHeaderContext() {
  const context = useContext(AdminHeaderContext);
  if (!context) {
    throw new Error('useAdminHeaderContext must be used within a AdminHeaderContextProvider');
  }
  return context;
}

export function AdminHeaderContextProvider({ children }: { children: React.ReactNode }) {
  const [isAdminHeaderDismissed, setIsAdminHeaderDismissed] = useSessionStorage('dismissAdminHeader', false);
  const user = useUser();

  const showAdminHeader = !isAdminHeaderDismissed && (user.IsMorphed || isTalosUser(user));
  const adminHeaderContextValue: AdminHeaderContextProps = useMemo(
    () => ({ showAdminHeader, isAdminHeaderDismissed, setIsAdminHeaderDismissed }),
    [showAdminHeader, isAdminHeaderDismissed, setIsAdminHeaderDismissed]
  );

  return <AdminHeaderContext.Provider value={adminHeaderContextValue}>{children}</AdminHeaderContext.Provider>;
}

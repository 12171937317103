import type { ToastAppInboxProps, ToastProps } from '../../hooks/Toast/useToasts';
import { globalToastActions, globalToastsDispatch } from './GlobalToastsStore';

//
export class GlobalToastManager<T extends 'ui' | 'appInbox'> {
  constructor(private toastType: T, private onRemoveCallback?: (id: string) => void) {}

  /**
   * Adds a toast.
   * - For toastType 'ui', accepts ToastProps.
   * - For toastType 'appInbox', accepts ToastAppInboxProps.
   */
  public addToast(toast: T extends 'ui' ? ToastProps : ToastAppInboxProps): string {
    if (this.toastType === 'ui') {
      if (!toast.id) {
        toast.id = crypto.randomUUID();
      }
      globalToastsDispatch(
        globalToastActions.addToastWithTimeout({ type: 'ui', value: toast as ToastProps & { id: string } })
      );
    } else {
      globalToastsDispatch(
        globalToastActions.addToastWithTimeout({
          type: 'appInbox',
          value: toast as ToastAppInboxProps,
        })
      );
    }
    return toast.id!;
  }

  public removeToast(id: string): void {
    globalToastsDispatch(globalToastActions.remove({ type: this.toastType, id }));
  }

  public clearToasts(): void {
    globalToastsDispatch(globalToastActions.clear({ type: this.toastType }));
  }
}

import { useMarketAccountCurrenciesContext, useObservableValue } from '@talos/kyoko';
import { usePortfolioAccounts } from 'providers';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';

import type { SourceAccount } from '../types';

export const SourceAccountsContext = createContext<SourceAccountsContextProps | undefined>(undefined);
SourceAccountsContext.displayName = 'SourceAccountsContext';

export interface SourceAccountsContextProps {
  sourceAccountsByCurrency: Map<string, SourceAccount[]> | undefined;
  sourceAccountsByMarket: Map<string, SourceAccount[]> | undefined;
}

export function useSourceAccounts() {
  const context = useContext(SourceAccountsContext);
  if (context === undefined) {
    throw new Error('Missing SourceAccountsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/**
 * We need to maintain a mapping of Currency -> SourceAccount[], and we only want to calculate it
 * once really so we do that here and provide it to children.
 */
export const SourceAccountsProvider = function SourceAccountsProvider({ children }: PropsWithChildren) {
  const { sourceAccountsList } = usePortfolioAccounts();
  const { acceptedCurrenciesByMarketAccountIDObs } = useMarketAccountCurrenciesContext();
  const acceptedCurrenciesByMarketAccountID = useObservableValue(
    () => acceptedCurrenciesByMarketAccountIDObs,
    [acceptedCurrenciesByMarketAccountIDObs]
  );

  const sourceAccountsByCurrency: Map<string, SourceAccount[]> | undefined = useMemo(() => {
    if (!sourceAccountsList || !acceptedCurrenciesByMarketAccountID) {
      return undefined;
    }

    const newSourceAccountsByCurrency = new Map<string, SourceAccount[]>();

    // For each treasury acc we get its supported currencies.
    // -> For each supported currency, we add the treasury account to the currency's list in the map.
    for (const sourceAcc of sourceAccountsList) {
      const sourceAccCurrencies = [...(acceptedCurrenciesByMarketAccountID.get(sourceAcc.MarketAccountID) ?? [])];
      for (const currency of sourceAccCurrencies) {
        const mapValue = newSourceAccountsByCurrency.get(currency);
        if (mapValue) {
          mapValue.push(sourceAcc);
        } else {
          newSourceAccountsByCurrency.set(currency, [sourceAcc]);
        }
      }
    }

    return newSourceAccountsByCurrency;
  }, [sourceAccountsList, acceptedCurrenciesByMarketAccountID]);

  const sourceAccountsByMarket: Map<string, SourceAccount[]> | undefined = useMemo(() => {
    if (!sourceAccountsList) {
      return undefined;
    }

    const newsourceAccountsByMarket = new Map<string, SourceAccount[]>();

    for (const sourceAcc of sourceAccountsList) {
      const mapValue = newsourceAccountsByMarket.get(sourceAcc.Market);
      if (mapValue) {
        mapValue.push(sourceAcc);
      } else {
        newsourceAccountsByMarket.set(sourceAcc.Market, [sourceAcc]);
      }
    }

    return newsourceAccountsByMarket;
  }, [sourceAccountsList]);

  return (
    <SourceAccountsContext.Provider value={{ sourceAccountsByCurrency, sourceAccountsByMarket }}>
      {children}
    </SourceAccountsContext.Provider>
  );
};

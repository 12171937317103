import {
  LookbackOption,
  MixpanelEvent,
  MixpanelEventProperty,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  tabLabelerEnumerated,
  useMixpanel,
  usePersistedTabs,
  useTabs,
  type Column,
  type TabProps,
  type UseTabsProps,
} from '@talos/kyoko';
import { useCallback, useMemo, useState } from 'react';
import { FilteredTransfersWrapper } from './FilteredTransfersWrapper';
import { TRANSFERS_BLOTTER_PREFIX } from './tokens';
import { TransfersFilterRow } from './TransfersFilterRow';
import { useTransfersColumns } from './useTransfersColumns';
import type { TransfersBlotterFilter } from './useTransfersFilter';

export interface TransfersTabProps extends TabProps {
  defaultFilter: Partial<TransfersBlotterFilter>;
  defaultColumns: Column<any>[];
}
const RECENT_TRANSFERS_TAB_ID = 'recent-transfers';
const TABS_EXCLUDED_IDS = [RECENT_TRANSFERS_TAB_ID];

const tabLabeler = tabLabelerEnumerated('Transfers');

export function TransfersBlotter({ noHeader = false, showNewTransfer = true }) {
  const mixpanel = useMixpanel();

  const defaultColumns = useTransfersColumns();

  const [initialFiltersOpen, setInitialFiltersOpen] = useState(false);

  const handleSelect = useCallback(() => setInitialFiltersOpen(false), []);
  const handleAdd = useCallback(() => {
    mixpanel.track(MixpanelEvent.AddTab);
    setInitialFiltersOpen(true);
  }, [mixpanel]);

  const newTabDefaults = useMemo(() => {
    return {
      defaultFilter: {
        _start: LookbackOption.Past30Days,
      },
      defaultColumns,
    } satisfies TransfersTabProps;
  }, [defaultColumns]);

  const recentTransfersTab = useMemo(() => {
    return {
      label: 'Recent',
      id: RECENT_TRANSFERS_TAB_ID,
      closable: false,
      editable: false,
      defaultFilter: {
        _start: LookbackOption.Past30Days,
      },
      defaultColumns,
    } satisfies TransfersTabProps;
  }, [defaultColumns]);

  const defaultTransfersTabs = [recentTransfersTab];

  const persistedTabs = usePersistedTabs<TransfersTabProps>(TRANSFERS_BLOTTER_PREFIX, {
    defaultInitialItems: defaultTransfersTabs,
    defaultInitialSelectedIndex: 0,
    excludedIDs: TABS_EXCLUDED_IDS,
    onSelect: handleSelect,
  });

  const onTabSelected = useCallback<NonNullable<UseTabsProps<TransfersTabProps>['onSelect']>>(
    (index, tabs) => {
      mixpanel.track(MixpanelEvent.SetTransfersBlotterTab, {
        [MixpanelEventProperty.TabIndex]: index,
        [MixpanelEventProperty.TabLabel]: tabs?.[index]?.label ?? '',
      });
      persistedTabs.onSelect(index);
    },
    [mixpanel, persistedTabs]
  ) satisfies UseTabsProps['onSelect'];

  const tabs = useTabs<TransfersTabProps>({
    ...persistedTabs,
    initialItems: persistedTabs.initialItems,
    showAddTab: true,
    tabLabeler,
    onAdd: handleAdd,
    onSelect: onTabSelected,
  });
  const handleCloneTab = useCallback(
    (filter: TransfersBlotterFilter, columns: Column[]) => {
      mixpanel.track(MixpanelEvent.CloneTab);
      tabs.clone(tabs.selectedIndex, {
        defaultColumns: columns,
        defaultFilter: filter,
        closable: true,
        reorderable: true,
      });
    },
    [mixpanel, tabs]
  );

  return (
    <>
      <Tabs {...tabs} h="100%" size={TabSize.Large}>
        <TabList isBordered rightItems={<TransfersFilterRow showNewTransfer={showNewTransfer} />}>
          {tabs.items.map((tab, idx) => (
            <Tab key={idx} {...tab} reorderable={tab.id !== RECENT_TRANSFERS_TAB_ID} />
          ))}
        </TabList>
        <TabPanels style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
          {tabs.items.map(tab => (
            <FilteredTransfersWrapper
              key={tab.id}
              tabId={tab.id!}
              blotterID={`${TRANSFERS_BLOTTER_PREFIX}/${tab.id}`}
              initialIsOpen={initialFiltersOpen}
              defaultColumns={tab.defaultColumns ?? newTabDefaults.defaultColumns}
              defaultFilter={tab.defaultFilter ?? newTabDefaults.defaultFilter}
              onCloneTab={handleCloneTab}
            />
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
}

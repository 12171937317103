import {
  Box,
  HelpIcon,
  MixpanelEvent,
  MixpanelEventProperty,
  SearchSelect,
  Text,
  VStack,
  useMixpanel,
  type OrderStrategy,
} from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { useStrategies } from '../../../providers';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';

const getStrategyLabel = (strategy: OrderStrategy) => strategy.DisplayName ?? strategy.Name;

export const DefaultMultilegStrategy = () => {
  const { strategiesList: strategies } = useStrategies();
  const { defaultMultilegOrderStrategy, setDefaultMultilegOrderStrategy } = useTradingSettings();
  const mixpanel = useMixpanel();

  const availableStrategies = useMemo(
    () => strategies.filter(strategy => strategy.InstrumentScope === 'Synthetic'),
    [strategies]
  );

  const selectedStrategy = useMemo(
    () => availableStrategies.find(strategy => strategy.Name === defaultMultilegOrderStrategy),
    [availableStrategies, defaultMultilegOrderStrategy]
  );

  const handleStrategyChange = useCallback(
    (newStrategy: OrderStrategy | undefined) => {
      // Either pass in the name, or if we cleared the select (newStrategy is undefined) pass in empty string
      setDefaultMultilegOrderStrategy(newStrategy ? newStrategy.Name : '');
      mixpanel.track(MixpanelEvent.DefaultMultilegStrategy, {
        [MixpanelEventProperty.Strategy]: newStrategy ? newStrategy.Name : '',
      });
    },
    [setDefaultMultilegOrderStrategy, mixpanel]
  );

  return (
    <VStack gap="spacingDefault" alignItems="flex-start">
      <Text color="colorTextImportant">Default multileg strategy for new orders</Text>
      <Box ml="spacingLarge">
        <SearchSelect
          id="multileg-strategy"
          onChange={handleStrategyChange}
          selection={selectedStrategy}
          options={availableStrategies}
          getLabel={getStrategyLabel}
          showClear
          style={{ width: '200px' }}
          suffix={
            selectedStrategy?.Description && (
              <Text size="fontSizeSmall" style={{ lineHeight: 1.5 }}>
                <HelpIcon tooltip={selectedStrategy.Description} />
              </Text>
            )
          }
        />
      </Box>
    </VStack>
  );
};

import {
  Card,
  CurrencyRenderer,
  formattedUTCDate,
  getTypedKeys,
  HStack,
  InlineFormattedNumber,
  ProductTypeEnum,
  SettleValueTypeEnum,
  Text,
  toBigWithDefault,
  useCurrenciesContext,
  useHomeCurrencyRateValue,
  useSecuritiesContext,
  VStack,
  type Security,
} from '@talos/kyoko';
import Big from 'big.js';
import { GREEK_ORDER } from 'containers/Portfolio/PortfolioManagement/types/types';
import { compact } from 'lodash-es';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import type { ReactNode } from 'react';
import type { PortfolioRiskDataItem } from '../../../types/PortfolioRiskDataItem';

type DisplayOutputItem = {
  sectionTitle: string;
  fields: Array<{
    fieldName: string;
    fieldValue: ReactNode;
  }>;
};

function useCurrencyDetails(dataItem: PortfolioRiskDataItem, security: Security | undefined): DisplayOutputItem | null {
  const { currenciesBySymbol } = useCurrenciesContext();
  const { homeCurrency } = useDisplaySettings();

  const underlying = security?.BaseCurrency ? currenciesBySymbol.get(security?.BaseCurrency) : undefined;
  const homeCurrencyRateValue = useHomeCurrencyRateValue(security?.BaseCurrency);

  if (!dataItem || !security) {
    return null;
  }
  const settlementCurrency = currenciesBySymbol.get(security.SettlementCurrency);
  const quoteCurrency = currenciesBySymbol.get(security.QuoteCurrency);

  return {
    sectionTitle: 'Currency Details',
    fields: compact([
      underlying
        ? {
            fieldName: 'Underlying',
            fieldValue: <CurrencyRenderer currency={underlying} colorful />,
          }
        : undefined,
      underlying // note: only check for underlying: if the homeCurrencyRateValue value hasn't come thru yet, this still reserve the space for it, avoiding flicker
        ? {
            fieldName: 'Underlying Price',
            fieldValue: (
              <InlineFormattedNumber
                number={homeCurrencyRateValue?.Rate}
                align="right"
                trimTrailingZeroes
                currency={homeCurrency}
              />
            ),
          }
        : undefined,
      settlementCurrency
        ? {
            fieldName: 'Settlement Currency',
            fieldValue: <CurrencyRenderer currency={settlementCurrency} colorful />,
          }
        : undefined,
      quoteCurrency
        ? {
            fieldName: 'Quote Currency',
            fieldValue: <CurrencyRenderer currency={quoteCurrency} colorful />,
          }
        : undefined,
    ]),
  };
}

function useContextDetails(security: Security | undefined): DisplayOutputItem | null {
  if (!security) {
    return null;
  }

  const contractDetails: DisplayOutputItem = {
    sectionTitle: 'Contract Details',
    fields: [],
  };

  if (security.ProductType === ProductTypeEnum.Option) {
    contractDetails.fields.push(
      {
        fieldName: 'Option Type',
        fieldValue: security.OptionType,
      },
      {
        // Options Style purposefully hard-coded to European for clarity
        fieldName: 'Option Style',
        fieldValue: 'European',
      },
      {
        fieldName: 'Expiry',
        fieldValue: formattedUTCDate(security.Expiration) + ' UTC',
      },
      {
        fieldName: 'Strike Price',
        fieldValue: <InlineFormattedNumber number={security.StrikePrice} currency={security.UnderlyingQuoteCurrency} />,
      }
    );
  } else if (security.Expiration) {
    contractDetails.fields.push({
      fieldName: 'Expiry',
      fieldValue: formattedUTCDate(security.Expiration) + ' UTC',
    });
  }

  contractDetails.fields.push(
    ...[
      {
        fieldName: 'Contract Size',
        fieldValue: <InlineFormattedNumber number={toBigWithDefault(security.NotionalMultiplier, 1)} />,
      },
      {
        fieldName: 'Payoff Type',
        fieldValue: security.SettleValueType === SettleValueTypeEnum.Regular ? 'Linear' : security.SettleValueType,
      },
    ]
  );

  return contractDetails;
}

function usePricingDetails(dataItem: PortfolioRiskDataItem): DisplayOutputItem | null {
  const { homeCurrency } = useDisplaySettings();
  if (dataItem.gridData.AssetType !== ProductTypeEnum.Option) {
    return null;
  }

  const ivValue = dataItem.gridData.IV;
  let ivPctValue = '';
  if (ivValue) {
    ivPctValue = Big(ivValue).times(100).toFixed(2) + ' %';
  }

  const contractDetails: DisplayOutputItem = {
    sectionTitle: 'Pricing',
    fields: [
      {
        fieldName: `Mark Price (${homeCurrency})`,
        fieldValue: (
          <InlineFormattedNumber
            align="right"
            number={dataItem.gridData.Equivalent.MarkPrice}
            currency={dataItem.gridData.Equivalent.Currency}
          />
        ),
      },
      {
        fieldName: `PV (${homeCurrency})`,
        fieldValue: (
          <InlineFormattedNumber
            number={dataItem.gridData.Equivalent.optionPV}
            currency={dataItem.gridData.Equivalent.Currency}
            increment="0.01"
            round
          />
        ),
      },
      {
        fieldName: 'IV',
        fieldValue: ivPctValue,
      },
    ],
  };

  return contractDetails;
}

function useRawGreeks(dataItem: PortfolioRiskDataItem): DisplayOutputItem | null {
  const mappedGreeks = getTypedKeys(GREEK_ORDER)
    .map(greek => ({
      fieldName: greek,
      fieldValue: dataItem.gridData[greek],
    }))
    .filter(({ fieldValue }) => fieldValue != null && fieldValue !== '0')
    .map(({ fieldName, fieldValue }) => ({
      fieldName,
      fieldValue: <InlineFormattedNumber number={fieldValue} align="right" trimTrailingZeroes increment="0.0001" />,
    }));

  return mappedGreeks.length > 0 ? { sectionTitle: 'Raw Greeks', fields: mappedGreeks } : null;
}

type DisplayOutputs = Array<DisplayOutputItem>;

export const InspectorDataSections = ({ dataItem }: { dataItem: PortfolioRiskDataItem }) => {
  const displayOutputs: DisplayOutputs = [];
  const { securitiesBySymbol } = useSecuritiesContext();

  const security = securitiesBySymbol.get(dataItem.gridData.Asset);

  const currencyDetails = useCurrencyDetails(dataItem, security);
  if (currencyDetails) {
    displayOutputs.push(currencyDetails);
  }

  const contextDetails = useContextDetails(security);
  if (contextDetails) {
    displayOutputs.push(contextDetails);
  }

  const pricingDetails = usePricingDetails(dataItem);
  if (pricingDetails) {
    displayOutputs.push(pricingDetails);
  }

  const valuedRawGreeks = useRawGreeks(dataItem);
  if (valuedRawGreeks) {
    displayOutputs.push(valuedRawGreeks);
  }

  return displayOutputs.map(({ sectionTitle, fields }) => (
    <VStack
      data-testid={`inspector-section-${sectionTitle}`}
      key={sectionTitle}
      gap="spacingTiny"
      w="100%"
      alignItems="stretch"
    >
      <Card display="flex" flexDirection="column">
        <Text color="colorTextImportant" size="fontSizeMd">
          {sectionTitle}
        </Text>
      </Card>
      <Card data-testid="inspector-section-fields" key={sectionTitle} display="flex" flexDirection="column">
        <VStack gap="spacingDefault" w="100%" alignItems="stretch">
          {fields.map(({ fieldName, fieldValue }) => (
            <HStack key={fieldName} justifyContent="space-between">
              <Text data-testid="inspector-section-field-label">{fieldName}</Text>
              <Text data-testid="inspector-section-field-value" color="colorTextImportant" size="fontSizeMd">
                {fieldValue}
              </Text>
            </HStack>
          ))}
        </VStack>
      </Card>
    </VStack>
  ));
};

import type { SubAccountReconMatch, SubAccountReconMatchStatusEnum, TabProps } from '@talos/kyoko';
import type { BreakResolutionDrawerProps } from '../../BreakResolution/BreakResolutionDrawer';

export interface BreakDetailsBlotterFilter {
  Statuses?: SubAccountReconMatchStatusEnum[];
}

export interface BreakDetailsBlotterTabProps extends TabProps {
  defaultFilter: Partial<BreakDetailsBlotterFilter>;
}

export const RECON_MATCHES_BLOTTER_PORTAL_ID = 'ReconMatchesBlotterPortal';

export type OpenResolutionDrawerFunc = (
  match: SubAccountReconMatch,
  initialTab?: BreakResolutionDrawerProps['initialTab']
) => void;

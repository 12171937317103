import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { navigationSlice } from 'providers/AppStateProvider/navigationSlice';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/** React-Router navigation handler from redux
 * - supports routing behavior from Redux middleware  */
function NavigationListener() {
  const navigate = useNavigate();
  const dispatch = useAppStateDispatch();
  // Assume the navigation action updates a specific piece of state:
  const navigationCommand = useAppStateSelector(state => state.navigation);

  useEffect(() => {
    if (navigationCommand?.path) {
      navigate(navigationCommand.path);
      // Optionally, clear the navigation command after handling
      dispatch(navigationSlice.actions.clearNavigation());
    }
  }, [navigationCommand, navigate, dispatch]);

  return null;
}

export default NavigationListener;

import { NotificationVariants, useDynamicCallback, useGlobalToasts, type CustomerUserApiKey } from '@talos/kyoko';
import { useCustomersContext } from 'hooks/useCustomer';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { addApiKey, apiKeyDeleted, apiKeySaved, fetchingKeys, setApiKeys } from '../state/apiKeys/apiKeysSlice';

export function useCustomerApiKeys() {
  const { createCustomerUserAPIKey, updateCustomerUserAPIKey, deleteCustomerUserAPIKey, getCustomerUserAPIKeys } =
    useCustomersContext();

  const { add: addToast } = useGlobalToasts();
  const state = useAppStateSelector(state => state.customerUserDrawer);
  const dispatch = useAppStateDispatch();

  const saveApiKeys = useDynamicCallback(async () => {
    const requests: Promise<any>[] = [];
    const apiKeysState = state.apiKeys;
    const keys = apiKeysState.keys;
    if (keys) {
      keys.forEach(key => {
        if (apiKeysState.keysToDelete.includes(key.CustomerUserApiKeyID)) {
          state.userDetails.customerUserId &&
            requests.push(
              deleteCustomerUserAPIKey(state.userDetails.customerUserId, key.CustomerUserApiKeyID).then(() => {
                dispatch(apiKeyDeleted(key.CustomerUserApiKeyID));
              })
            );
        } else if (apiKeysState.dirtyKeys.includes(key.CustomerUserApiKeyID)) {
          requests.push(
            updateCustomerUserAPIKey(key).then(() => {
              dispatch(apiKeySaved(key.CustomerUserApiKeyID));
            })
          );
        }
      });

      try {
        await Promise.all(requests);
      } catch (e) {
        throw Error('Customer User API Key could not be saved.');
      }
    }
  });

  const createAPIKey = useDynamicCallback(() => {
    if (state.userDetails.customerUserId) {
      createCustomerUserAPIKey(state.userDetails.customerUserId).then(({ data }) => {
        dispatch(addApiKey(data[0]));
      });
    }
  });

  const fetchApiKeys = useDynamicCallback((customerUserID: string) => {
    if (!state.apiKeys.fetching && !state.apiKeys.keys) {
      dispatch(fetchingKeys());
      getCustomerUserAPIKeys(customerUserID)
        .then(({ data }: { data: CustomerUserApiKey[] }) => {
          dispatch(setApiKeys(data));
        })
        .catch(({ message }) => {
          addToast({
            text: message ?? 'Could not fetch API keys',
            variant: NotificationVariants.Negative,
          });
        });
    }
  });

  return {
    apiKeys: state.apiKeys.keys,
    dirtyKeys: state.apiKeys.dirtyKeys,
    deletedApiKeys: state.apiKeys.keysToDelete,
    saveApiKeys,
    fetchApiKeys,
    createAPIKey,
    fetching: state.apiKeys.fetching,
  };
}

export const NotificationVariants = {
  Default: 'DEFAULT',
  Primary: 'PRIMARY',
  Positive: 'POSITIVE',
  Negative: 'NEGATIVE',
  Warning: 'WARNING',
  Attention: 'ATTENTION',
} as const;
export type NotificationSizes = 'Small' | 'Default';

export const NotificationAppInboxVariants = {
  /** System-driven (Inbox-managed) Notifications */
  Default: 'DEFAULT',
  Confirmed: 'CONFIRMED',
  MediumPriority: 'MEDIUM_PRIORITY',
  HighPriority: 'HIGH_PRIORITY',
} as const;

/**
 * @deprecated Use CustomerCredit instead
 */
export class CustomerCreditLEGACY {
  Counterparty!: string;
  ExposureCurrency!: string;
  ExposureLimit!: string;
  Exposure!: string;
  MarketAccount!: string;
  CreditCurrency?: string;

  RowID?: string;

  constructor(data: CustomerCreditLEGACY) {
    this.CreditCurrency = data.ExposureCurrency;
    Object.assign(this, data);
  }
}

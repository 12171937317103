import { ACTION, Flex, IconName, MixpanelEvent, MixpanelEventSource, MixpanelSourceProvider } from '@talos/kyoko';
import { isSidebarSubTitle, type SidebarSubNavItem, type SidebarSubTitle } from '@talos/kyoko/src/routed';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { NoMatchPage } from 'containers/NoMatchPage';
import { useIsPMSActive } from 'providers/PortfolioSettingContext';
import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRoleAuth } from '../../hooks';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { Addresses } from './Addresses';
import { DealerSettlement } from './DealerSettlement';
import { Performance } from './Performance';
import { getPortfolioLayoutOptions } from './PortfolioManagement/layoutConfiguration/getPortfolioLayoutOptions';
import { PortfolioDashboard } from './PortfolioManagement/PortfolioDashboard';
import { PortfolioReconOldLayout } from './Reconciliation';
import { Settlement } from './Settlement';
import { TreasuryManagement } from './TreasuryManagement';

const PM_SUB_NAV_ITEMS: (SidebarSubNavItem<'/portfolio/'> | SidebarSubTitle)[] = getPortfolioLayoutOptions().reduce(
  (result, next) => {
    result.push({
      label: next.label,
      type: 'title',
      path: `/portfolio${next.route}`,
    });
    if (next.tabs) {
      next.tabs.forEach(tab => {
        result.push({
          label: tab.label,
          path: `/portfolio${next.route}${tab.route}`,
          menuIndent: true,
        });
      });
    }
    return result;
  },
  [] as (SidebarSubNavItem<'/portfolio/'> | SidebarSubTitle)[]
);

/** Nav items for legacy Portfolio view.
 *
 * - requiredPermission is omitted typewise as the process for the nav items doesn't acknowledge it at this time.
 * Roles logic for this area is in {@link usePortfolioSubNavItems} below
 */
type PortfolioNavItems = (Omit<SidebarSubNavItem, 'requiredPermission'> | SidebarSubTitle)[];
const PORTFOLIO_ORIG_SUB_NAV_ITEMS = [
  {
    label: 'Treasury Management',
    path: '/portfolio/treasury-management',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.TreasuryManagement}>
        <TreasuryManagement />
      </MixpanelSourceProvider>
    ),
    icon: IconName.ChartDonut,
    mixpanelEvent: MixpanelEvent.NavigateToTreasuryManagement,
  },
  {
    label: 'Performance',
    path: '/portfolio/performance',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.PortfolioPerformance}>
        <Performance />
      </MixpanelSourceProvider>
    ),
    icon: IconName.ChartLine,
    mixpanelEvent: MixpanelEvent.NavigateToPerformance,
  },
  {
    label: 'OTC Settlement',
    path: '/portfolio/otc-settlement',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.PortfolioCreditAndSettlement}>
        <DealerSettlement />
      </MixpanelSourceProvider>
    ),
    icon: IconName.CurrencyDollar,
    mixpanelEvent: MixpanelEvent.NavigateToCreditAndSettlement,
  },
  {
    label: 'Addresses',
    path: '/portfolio/addresses',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.PortfolioAddresses}>
        <Addresses />
      </MixpanelSourceProvider>
    ),
    icon: IconName.Cog,
    mixpanelEvent: MixpanelEvent.NavigateToPortfolioAddresses,
  },
  {
    label: 'Customer Settlement',
    path: '/portfolio/settlement',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.PortfolioSettlement}>
        <Settlement />
      </MixpanelSourceProvider>
    ),
    icon: IconName.ArrowLeftRight,
    mixpanelEvent: MixpanelEvent.NavigateToSettlement,
  },
  {
    label: 'Reconciliation',
    path: '/portfolio/reconciliation',
    component: (
      <MixpanelSourceProvider value={MixpanelEventSource.PortfolioRecon}>
        <PortfolioReconOldLayout />
      </MixpanelSourceProvider>
    ),
    icon: IconName.BookOpen,
    mixpanelEvent: MixpanelEvent.NavigateToPortfolioRecon,
  },
] as const satisfies PortfolioNavItems;

export const usePortfolioSubNavItems: () => PortfolioNavItems = () => {
  const { enablePortfolioSettlement, enablePortfolioSubAccountRecon } = useFeatureFlag();
  const { isAuthorized } = useRoleAuth();
  const enablePMS = useIsPMSActive();

  const canSeeRecon = enablePortfolioSubAccountRecon && isAuthorized(ACTION.VIEW_PORTFOLIO_RECON);
  const canSeePortfolioAddresses = isAuthorized(ACTION.EDIT_ADDRESSES);

  const items = useMemo(() => {
    if (enablePMS) {
      return PM_SUB_NAV_ITEMS;
    }

    return PORTFOLIO_ORIG_SUB_NAV_ITEMS.filter(item => {
      if (!('path' in item)) {
        return true;
      }

      if (item.path === '/portfolio/addresses') {
        return canSeePortfolioAddresses;
      }

      if (item.path === '/portfolio/settlement') {
        return enablePortfolioSettlement;
      }

      if (item.path === '/portfolio/reconciliation') {
        return canSeeRecon;
      }

      return true;
    });
  }, [enablePMS, canSeePortfolioAddresses, enablePortfolioSettlement, canSeeRecon]);

  return items;
};

export const PortfolioRouter = () => {
  const items = usePortfolioSubNavItems();
  const enablePMS = useIsPMSActive();

  return (
    <ErrorBoundary>
      <Flex gap="spacingTiny" flex="1" overflow="hidden" h="100%">
        <Routes>
          {items.map(
            tab =>
              !isSidebarSubTitle(tab) &&
              (enablePMS ? (
                <Route
                  path="*"
                  key={tab.path}
                  element={
                    <MixpanelSourceProvider value={MixpanelEventSource.PortfolioDashboard}>
                      <PortfolioDashboard />
                    </MixpanelSourceProvider>
                  }
                />
              ) : (
                /** In RR6, since we're already inside portfolio, we don't want routes here */
                <Route path={tab.path.replace(/^\/portfolio\//, '')} key={tab.path} element={tab.component} />
              ))
          )}
          <Route path="*" element={<NoMatchPage />} />
        </Routes>
      </Flex>
    </ErrorBoundary>
  );
};

import {
  useDefaultColumns,
  type AgGridPriceProps,
  type AssetColumnProps,
  type Column,
  type ColumnDef,
  type GreekColumnParams,
  type SizeColumnParams,
} from '@talos/kyoko';
import { useFeatureFlag, useRollupTreeRef } from 'hooks';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { useMemo } from 'react';
import type { PortfolioManagementBlotterDataItem } from '../../types/PortfolioManagementBlotterDataItem';
import { usePnlTagColumns } from '../usePnlTagColumns';
import { getPnLColumn } from '../utils';

export const usePortfolioPerformanceColumns = (): {
  defaultColumns: Column[];
} => {
  const { homeCurrency } = useDisplaySettings();
  const { mapping: baseMapping } = usePortfolioPerformanceColumnDefs(homeCurrency);

  const { extraColMapping: pnlTagMapping, visiblePnlTagColumns } = usePnlTagColumns('performance', homeCurrency);

  const fullMapping = useMemo(() => {
    const fullMappingInput = {
      ...baseMapping,
      ...pnlTagMapping,
    };
    return new Map<string, Column>(
      Object.entries(fullMappingInput).filter(([, value]) => value !== undefined) as [string, Column][]
    );
  }, [pnlTagMapping, baseMapping]);

  const fullVisibleColumns = useMemo(() => {
    const baseVisibleColumns = getBaseVisibleColumns();
    return [...baseVisibleColumns, ...visiblePnlTagColumns].filter(item => !!fullMapping.get(item));
  }, [fullMapping, visiblePnlTagColumns]);

  const defaultColumns = useDefaultColumns(fullVisibleColumns, fullMapping);
  return {
    defaultColumns,
  };
};

export function usePortfolioPerformanceColumnDefs(homeCurrency: string) {
  const rollupTreeRef = useRollupTreeRef();
  const { showSubAccountPositionPnLTagColumns } = useFeatureFlag();
  return useMemo(() => {
    const mapping = {
      pmsWarnings: {
        id: 'pmsWarnings',
        field: 'gridData.PMSWarningColumnValue',
        type: 'pmsWarnings',
        title: { intlKey: 'warning' },
        aggregate: true,
        pinned: 'left',
      },
      subAccount: {
        id: 'SubAccount',
        field: 'gridData.SubAccount',
        title: 'Sub Account',
        type: 'subAccountName',
        sortable: true,
        params: {
          rollupTree: rollupTreeRef,
        },
      },
      assetField: {
        id: 'Asset',
        field: 'gridData.Asset',
        type: 'asset',
        pinned: 'left',
        sortable: true,
        width: 150,
        title: 'Asset',
        params: {
          assetTypeField: 'gridData.AssetType',
          colorful: true,
        } satisfies AssetColumnProps<PortfolioManagementBlotterDataItem>,
      },
      weight: {
        id: 'Weight',
        field: 'gridData.Equivalent.Weight',
        title: 'Weight (%)',
        width: 80,
        type: 'percent',
        sortable: true,
        description: 'Weight of the position in the portfolio.',
      },
      position: {
        id: 'Position',
        field: 'gridData.Position',
        title: 'Position Qty',
        type: 'size',
        params: {
          showInTermsOfContracts: true,
          highlightNegative: true,
          currencyField: 'gridData.Asset',
          trimTrailingZeroes: true,
          showCurrency: false,
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        sortable: true,
        description: 'Position normalized in asset currency.',
      },
      positionHome: {
        id: 'PositionHome',
        type: 'size',
        field: 'gridData.Equivalent.Position',
        title: `Position (${homeCurrency})`,
        params: {
          currencyField: 'gridData.Equivalent.Currency',
          highlightNegative: true,
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        sortable: true,
        aggregate: true,
        description: 'Position normalized in home currency, by using mark and underlying prices.',
      },
      price: {
        id: 'MarkPrice',
        field: 'gridData.MarkPrice',
        title: 'Mark Price',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'gridData.MarkPriceCurrency',
        } satisfies AgGridPriceProps<PortfolioManagementBlotterDataItem>,
        description: 'Most recent mark price for the instrument.',
      },
      priceHome: {
        id: 'MarkPriceHome',
        type: 'size',
        field: 'gridData.Equivalent.MarkPrice',
        params: {
          currencyField: 'gridData.Equivalent.Currency',
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        title: { intlKey: 'markPriceHomeCurrency' },
        titleIntlKeyValues: { homeCurrency },
        description: "Most recent mark price for the instrument, converted to the user's home currency.",
      },
      deltaExpNet: {
        id: 'DeltaExpNet',
        field: 'gridData.Equivalent.CcyDeltaExposure.Net',
        type: 'size',
        params: {
          currencyField: 'gridData.Equivalent.Currency',
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        sortable: true,
        aggregate: true,
        title: `Net Delta Exp (${homeCurrency})`,
        width: 150,
        description: `Net Delta Exposure: Net equivalent delta notional in home currency (${homeCurrency}) from holding this position (the economic exposure driving performance)`,
      },
      deltaExpGross: {
        id: 'DeltaExpGross',
        field: 'gridData.Equivalent.CcyDeltaExposure.Gross',
        type: 'size',
        params: {
          currencyField: 'gridData.Equivalent.Currency',
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        sortable: true,
        aggregate: true,
        title: `Gross Delta Exp (${homeCurrency})`,
        width: 150,
        description: `Gross Delta Exposure: Gross equivalent delta notional in home currency (${homeCurrency}) from holding this position (the economic exposure driving performance)`,
      },
      avgCost: {
        id: 'AvgCost',
        title: 'Avg Cost',
        field: 'position.AvgCost',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'position.AvgCostCurrency',
        } satisfies AgGridPriceProps<PortfolioManagementBlotterDataItem>,
        description: 'Average cost for the instrument provided by the venue.',
      },
      avgCostHome: {
        id: 'AvgCostHome',
        title: `Avg Cost (${homeCurrency})`,
        field: 'position.Equivalent.AvgCost',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'gridData.Equivalent.Currency',
        } satisfies AgGridPriceProps<PortfolioManagementBlotterDataItem>,
        description: "Average cost for the instrument, converted to the user's home currency.",
      },
      fees: {
        id: 'Fees',
        field: 'position.CumFees',
        type: 'size',
        title: 'Fees',
        params: {
          currencyField: 'position.PnLCurrency',
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        aggregate: true,
        description: `Cumulative trading fees paid (or rebates received) (in settlement currency for derivatives, home currency otherwise)`,
      },
      feesHome: {
        id: 'FeesHome',
        field: `position.Equivalent.CumFees`,
        type: 'size',
        title: `Fees (${homeCurrency})`,
        params: {
          currencyField: 'gridData.Equivalent.Currency',
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        aggregate: true,
        description: `Cumulative trading fees paid (in home currency).`,
      },
      fundingPnL: getPnLColumn({
        id: 'FundingPnL',
        title: 'Funding PnL',
        field: 'position.FundingPnL',
        currencyField: 'position.FundingPnLCurrency',
        currencyType: 'quote',
        homeCurrency,
        description: 'Cumulative funding payment paid or received in settlement currency.',
      }),
      fundingPnlHome: getPnLColumn({
        id: 'FundingPnLHome',
        title: 'Funding PnL',
        field: 'position.Equivalent.FundingPnL',
        currencyType: 'home',
        homeCurrency,
        description: 'Cumulative funding payment paid or received in home currency.',
      }),
      realizedPnL: getPnLColumn({
        id: 'RealizedPnL',
        title: 'Realized PnL',
        field: 'position.RealizedPnL',
        currencyType: 'quote',
        homeCurrency,
        description: 'Cumulative realized P&L.',
      }),
      realizedPnLHome: getPnLColumn({
        id: 'RealizedPnLHome',
        title: 'Realized PnL',
        field: 'position.Equivalent.RealizedPnL',
        currencyType: 'home',
        homeCurrency,
        description: 'Cumulative realized P&L in home currency.',
      }),
      unrealizedPnL: getPnLColumn({
        id: 'UnrealizedPnL',
        title: 'Unrealized PnL',
        field: 'position.UnrealizedPnL',
        currencyType: 'quote',
        homeCurrency,
        description: 'Cumulative unrealized P&L.',
      }),
      unrealizedPnLHome: getPnLColumn({
        id: 'UnrealizedPnLHome',
        title: 'Unrealized PnL',
        field: 'position.Equivalent.UnrealizedPnL',
        currencyType: 'home',
        homeCurrency,
        description: 'Cumulative unrealized P&L in home currency.',
      }),
      totalPnL: getPnLColumn({
        id: 'TotalPnL',
        title: 'Total LTD PnL',
        field: 'position.TotalPnL',
        currencyType: 'quote',
        homeCurrency,
        description: 'Sum of Unrealized, Realized and Funding P&Ls, net of Fees.',
      }),
      totalPnLHome: getPnLColumn({
        id: 'TotalPnLHome',
        title: 'Total LTD PnL',
        field: 'position.Equivalent.TotalPnL',
        currencyType: 'home',
        homeCurrency,
        description: 'Sum of Unrealized, Realized and Funding P&Ls, net of Fees. In home currency.',
      }),
      pnl24H: getPnLColumn({
        id: 'PnL24H',
        title: '24H PnL',
        field: 'position.PnLLookbacks.H24.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 24 hours to the current price mark.',
      }),
      pnl7D: getPnLColumn({
        id: 'PnL7D',
        title: '7D PnL',
        field: 'position.PnLLookbacks.D7.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 7 days to the current price mark.',
      }),
      pnl30D: getPnLColumn({
        id: 'PnL30D',
        title: '30D PnL',
        field: 'position.PnLLookbacks.D30.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 30 days to the current price mark.',
      }),
      pnl365D: getPnLColumn({
        id: 'PnL365D',
        title: '365D PnL',
        field: 'position.PnLLookbacks.D365.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 365 days to the current price mark.',
      }),
      pnlIntraday: getPnLColumn({
        id: 'PnLIntraday',
        title: 'Intraday PnL',
        field: 'position.PnLLookbacks.Today.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: "P&L on asset from start of day to the current price mark (e.g. today's gain/loss).",
      }),
      pnlWTD: getPnLColumn({
        id: 'PnLWTD',
        title: 'WTD PnL',
        field: 'position.PnLLookbacks.WeekToDate.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the week to date to the current price mark.',
      }),
      pnlMTD: getPnLColumn({
        id: 'PnLMTD',
        title: 'MTD PnL',
        field: 'position.PnLLookbacks.MonthToDate.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the month to date to the current price mark.',
      }),
      pnlYTD: getPnLColumn({
        id: 'PnLYTD',
        title: 'YTD PnL',
        field: 'position.PnLLookbacks.YearToDate.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the year to date to the current price mark.',
      }),
      underlyingPrice: {
        id: 'UnderlyingPrice',
        field: 'position.UnderlyingPrice',
        title: 'Underlying Price',
        type: 'price',
        width: 130,
        params: {
          quoteCurrencyField: 'position.UnderlyingQuoteCurrency',
        } satisfies AgGridPriceProps<PortfolioManagementBlotterDataItem>,
        description: "Most recent fair price for the instrument's underlying provided by the venue.",
      },
      positionDelta: {
        id: 'Delta',
        field: 'position.Delta',
        title: 'Delta',
        type: 'delta',
        aggregate: true,
        params: {
          currencyField: 'position.DeltaCurrency',
        } satisfies GreekColumnParams<PortfolioManagementBlotterDataItem>,
        description:
          "Delta measures the rate of change of an instrument's price with respect to changes in its underlying asset's price. ",
      },
      positionEquivalentDelta: {
        id: 'DeltaHome',
        field: 'position.Equivalent.Delta',
        type: 'size',
        title: `Delta (${homeCurrency})`,
        aggregate: true,
        params: {
          currencyField: 'gridData.Equivalent.Currency',
          highlightNegative: true,
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        description: `Delta (${homeCurrency}) measures the rate of change of an instrument's price with respect to changes in its underlying asset's price, in home currency terms. `,
      },
    } as const satisfies Record<string, ColumnDef<PortfolioManagementBlotterDataItem> | undefined>;

    if (showSubAccountPositionPnLTagColumns) {
      const excludedColumns = [
        'underlyingPrice',
        'fees',
        'feesHome',
        'unrealizedPnL',
        'unrealizedPnLHome',
        'realizedPnL',
        'realizedPnLHome',
        'totalPnL',
        'totalPnLHome',
        'fundingPnL',
        'fundingPnlHome',
        'pnl24H',
        'pnl7D',
        'pnl30D',
        'pnl365D',
        'pnlIntraday',
        'pnlWTD',
        'pnlMTD',
        'pnlYTD',
      ] satisfies Array<keyof typeof mapping>;
      const mappingWithoutExcludedColumns = {
        ...mapping,
        ...Object.fromEntries(excludedColumns.map(column => [column, undefined])),
      };

      return {
        mapping: mappingWithoutExcludedColumns,
      };
    }

    return {
      mapping,
    };
  }, [homeCurrency, rollupTreeRef, showSubAccountPositionPnLTagColumns]);
}

function getBaseVisibleColumns() {
  const visibleColumns: Array<keyof ReturnType<typeof usePortfolioPerformanceColumnDefs>['mapping']> = [
    'pmsWarnings',
    'weight',
    'position',
    'positionHome',
    'priceHome',
    'deltaExpNet',
    'realizedPnLHome',
    'unrealizedPnLHome',
    'totalPnLHome',
    'avgCostHome',
    'feesHome',
    'pnl24H',
    'pnl7D',
    'pnl30D',
    'pnl365D',
    'pnlIntraday',
    'pnlWTD',
    'pnlMTD',
    'pnlYTD',
  ];
  return visibleColumns;
}

import {
  ACTION,
  Alert,
  AlertVariants,
  Button,
  ButtonGroup,
  ButtonVariants,
  Divider,
  FormControlSizes,
  FormGroup,
  getTypedKeys,
  Grid,
  IconName,
  Input,
  isKeyIn,
  MixpanelEvent,
  MixpanelEventSource,
  Modal,
  NotificationVariants,
  PopperHeader,
  promisesHaveResolved,
  Tag,
  TagSizes,
  Text,
  Toggle,
  Tooltip,
  useGlobalToasts,
  useMixpanel,
  VStack,
} from '@talos/kyoko';
import { isEqual } from 'lodash-es';
import {
  type FormEventHandler,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { MarketAccountNames } from '../../../../components/MarketAccountNames';
import { useRoleAuth } from '../../../../hooks';
import { TradingStatusWrapper } from './styles';
import type { EditTradingControlsFormArgs, TradingControlsDialogProps, TradingControlsForm } from './types';

export function TradingControlsDialog(props: TradingControlsDialogProps) {
  const {
    dialog,
    allIsEnabled,
    tradingEnabled,
    customerTradingEnabled,
    positionAutoHedgingEnabled,
    ddhEnabled,
    dialogInfo,
    updateControls,
    disabledMarketAccounts,
    showPositionAutoHedgingInfo,
    showCustomerTradingInfo,
    showDdhInfo,
  } = props;
  const { isOpen } = dialog;
  const navigate = useNavigate();
  const { add: addToast } = useGlobalToasts();
  const [loading, setLoading] = useState(false);
  const mixpanel = useMixpanel(MixpanelEventSource.TradingControls);

  const [form, setForm] = useState<TradingControlsForm>({
    tradingEnabled,
    positionAutoHedgingEnabled,
    ddhEnabled,
    customerTradingEnabled,
  });
  const initialFormValues = useRef<TradingControlsForm>(form);
  const [confirmInput, setConfirmInput] = useState<string>('');

  const someTradingControlsWillBeDisabled = useMemo(() => {
    return getTypedKeys(form).some(
      key => isKeyIn(key, initialFormValues) && initialFormValues[key] !== form[key] && form[key] === false
    );
  }, [initialFormValues, form]);

  const updateForm = useCallback(({ key, value }: EditTradingControlsFormArgs) => {
    setForm(prev => ({ ...prev, [key]: value }));
  }, []);

  const handleClose = useCallback(() => {
    dialog.close();
  }, [dialog]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async e => {
      e.preventDefault();
      setLoading(true);
      try {
        const result = await updateControls(initialFormValues.current, form);
        dialog.close();
        if (promisesHaveResolved(result)) {
          addToast({
            text: `Trading controls updated successfully`,
            variant: NotificationVariants.Positive,
          });
        } else {
          addToast({
            text: `Failed to update trading controls`,
            variant: NotificationVariants.Negative,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [dialog, addToast, form, initialFormValues, updateControls]
  );

  const handleNavigateToTradingControls = useCallback(() => {
    mixpanel.track(MixpanelEvent.TradingControlsOpenMarketAccounts);
    dialog.close();
    navigate(`/settings/trading-controls`);
  }, [dialog, mixpanel, navigate]);

  // We need to set the current form values when the dialog is opened
  useEffect(() => {
    if (isOpen) {
      const current = {
        tradingEnabled,
        positionAutoHedgingEnabled,
        ddhEnabled,
        customerTradingEnabled,
      };
      setForm(current);
      initialFormValues.current = current;
      setConfirmInput('');
    }
  }, [isOpen, tradingEnabled, positionAutoHedgingEnabled, ddhEnabled, customerTradingEnabled]);

  const isDirty = useMemo(() => !isEqual(initialFormValues.current, form), [initialFormValues, form]);
  const canSubmit = confirmInput.toLocaleLowerCase() === 'confirm';
  const { isAuthorized } = useRoleAuth();

  if (!dialog.isOpen) {
    return null;
  }

  return (
    <Modal {...dialog} width={640} background="backgroundDropdownResults">
      <form onSubmit={handleSubmit}>
        <PopperHeader
          icon={dialogInfo?.iconName}
          iconColor={dialogInfo?.iconColor}
          title={
            <>
              <Text color="colorTextImportant">Trading Controls</Text>
              {disabledMarketAccounts.length > 0 && (
                <Tag size={TagSizes.Small}>
                  <Tooltip tooltip={<MarketAccountNames marketAccounts={disabledMarketAccounts} />}>
                    <span>
                      {disabledMarketAccounts.length} Market Account
                      {disabledMarketAccounts.length !== 1 ? 's are' : ' is'} disabled
                    </span>
                  </Tooltip>
                </Tag>
              )}
            </>
          }
          suffix={
            <Button startIcon={IconName.Exit} size={FormControlSizes.Tiny} onClick={handleNavigateToTradingControls}>
              Settings
            </Button>
          }
          onCloseClicked={handleClose}
        />
        <Divider />
        <VStack gap="spacingDefault" p="spacingMedium" alignItems="flex-start">
          <Text color="colorTextImportant">Enable or disable Hedging, Dealer Trading or Trading in general.</Text>
          <Text>Trading Status:</Text>
          <TradingStatusWrapper>
            <FormGroup inline alignItems="center">
              <Text>Trading</Text>
              <ButtonGroup justifyContent="flex-end" flex="1">
                <Toggle
                  data-testid="trading-toggle"
                  size={FormControlSizes.Large}
                  checked={form.tradingEnabled}
                  onChange={(value: boolean) => updateForm({ key: 'tradingEnabled', value })}
                />
              </ButtonGroup>
            </FormGroup>
            <Divider mb="spacingMedium" />

            {showPositionAutoHedgingInfo && (
              <FormGroup inline alignItems="center">
                <Text>Position Auto-Hedging</Text>
                <ButtonGroup justifyContent="flex-end" flex="1">
                  <Toggle
                    data-testid="position-auto-hedging-toggle"
                    size={FormControlSizes.Large}
                    checked={form.positionAutoHedgingEnabled}
                    disabled={!form.tradingEnabled || !isAuthorized(ACTION.EDIT_AUTOHEDGING)}
                    onChange={(value: boolean) => updateForm({ key: 'positionAutoHedgingEnabled', value })}
                  />
                </ButtonGroup>
              </FormGroup>
            )}

            {showDdhInfo && (
              <FormGroup inline alignItems="center">
                <Text>Dynamic Delta Hedging</Text>
                <ButtonGroup justifyContent="flex-end" flex="1">
                  <Toggle
                    data-testid="ddh-toggle"
                    size={FormControlSizes.Large}
                    checked={form.ddhEnabled}
                    disabled={!form.tradingEnabled}
                    onChange={(value: boolean) => updateForm({ key: 'ddhEnabled', value })}
                  />
                </ButtonGroup>
              </FormGroup>
            )}

            {showCustomerTradingInfo && (
              <FormGroup inline alignItems="center">
                <Text>Customer Trading</Text>
                <ButtonGroup justifyContent="flex-end" flex="1">
                  <Toggle
                    data-testid="customer-trading-toggle"
                    size={FormControlSizes.Large}
                    checked={form.customerTradingEnabled}
                    disabled={!form.tradingEnabled}
                    onChange={(value: boolean) => updateForm({ key: 'customerTradingEnabled', value })}
                  />
                </ButtonGroup>
              </FormGroup>
            )}
          </TradingStatusWrapper>

          <TradingControlsDialogWarnings
            allIsEnabled={allIsEnabled}
            isDirty={isDirty}
            form={form}
            initialForm={initialFormValues.current}
            showPositionAutoHedgingInfo={showPositionAutoHedgingInfo}
            showCustomerTradingInfo={showCustomerTradingInfo}
            showDdhInfo={showDdhInfo}
          />

          {isDirty && (
            <FormGroup label="To confirm, type “confirm” below" style={{ marginBottom: 0 }} w="100%">
              <Input data-testid="confirm-input" value={confirmInput} onChange={e => setConfirmInput(e.target.value)} />
            </FormGroup>
          )}
        </VStack>

        <Divider />
        <Grid columns="0.25fr 1fr" rows={1} gap="spacingDefault" w="100%" p="spacingMedium">
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            disabled={!isDirty || !canSubmit}
            loading={loading}
            variant={someTradingControlsWillBeDisabled ? ButtonVariants.Negative : ButtonVariants.Positive}
          >
            Update Trading Controls
          </Button>
        </Grid>
      </form>
    </Modal>
  );
}

interface TradingControlsDialogWarningsProps {
  allIsEnabled: boolean;
  isDirty: boolean;
  form: TradingControlsForm;
  initialForm: TradingControlsForm;
  showPositionAutoHedgingInfo: boolean;
  showCustomerTradingInfo: boolean;
  showDdhInfo: boolean;
}

function TradingControlsDialogWarnings({
  allIsEnabled,
  isDirty,
  form,
  initialForm,
  showPositionAutoHedgingInfo,
  showCustomerTradingInfo,
  showDdhInfo,
}: TradingControlsDialogWarningsProps) {
  const is = (section: keyof TradingControlsForm, state: 'enabled' | 'disabled'): boolean => {
    return state === 'disabled' ? !initialForm[section] : initialForm[section];
  };

  const willBe = (section: keyof TradingControlsForm, state: 'enabled' | 'disabled'): boolean => {
    return initialForm[section] !== form[section] && (state === 'disabled' ? !form[section] : form[section]);
  };

  const tradingWillBeDisabled = willBe('tradingEnabled', 'disabled');
  const tradingWillBeEnabled = willBe('tradingEnabled', 'enabled');
  const positionAutoHedgingIsEnabled = is('positionAutoHedgingEnabled', 'enabled');
  const positionAutoHedgingWillBeDisabled = willBe('positionAutoHedgingEnabled', 'disabled');
  const positionAutoHedgingWillBeEnabled = willBe('positionAutoHedgingEnabled', 'enabled');
  const ddhIsEnabled = is('ddhEnabled', 'enabled');
  const ddhWillBeDisabled = willBe('ddhEnabled', 'disabled');
  const ddhWillBeEnabled = willBe('ddhEnabled', 'enabled');
  const customerTradingIsEnabled = is('customerTradingEnabled', 'enabled');
  const customerTradingWillBeDisabled = willBe('customerTradingEnabled', 'disabled');
  const customerTradingWillBeEnabled = willBe('customerTradingEnabled', 'enabled');

  const cancelledText = useMemo(() => {
    if (!tradingWillBeDisabled) {
      return null;
    }
    const result: string[] = [];
    if (positionAutoHedgingIsEnabled) {
      result.push('Position Auto-Hedging orders');
    }
    if (customerTradingIsEnabled) {
      result.push('Customer trading orders');
    }
    if (ddhIsEnabled) {
      result.push('DDH orders');
    }
    return result.length > 0 ? `, ${result.join(' and ')}` : null;
  }, [tradingWillBeDisabled, positionAutoHedgingIsEnabled, customerTradingIsEnabled, ddhIsEnabled]);

  return (
    <VStack w="100%" gap="spacingDefault">
      {!isDirty && allIsEnabled && (
        <TradingAlert variant={AlertVariants.Positive}>Trading is currently enabled.</TradingAlert>
      )}
      {!isDirty && !initialForm.tradingEnabled && (
        <TradingAlert variant={AlertVariants.Negative}>Trading is currently disabled.</TradingAlert>
      )}
      {showPositionAutoHedgingInfo &&
        !isDirty &&
        initialForm.tradingEnabled &&
        !initialForm.positionAutoHedgingEnabled && (
          <TradingAlert variant={AlertVariants.Negative}>Position Auto-Hedging is currently disabled.</TradingAlert>
        )}
      {showDdhInfo && !isDirty && initialForm.tradingEnabled && !initialForm.ddhEnabled && (
        <TradingAlert variant={AlertVariants.Negative}>Dynamic Delta Hedging is currently disabled.</TradingAlert>
      )}
      {showCustomerTradingInfo && !isDirty && initialForm.tradingEnabled && !initialForm.customerTradingEnabled && (
        <TradingAlert variant={AlertVariants.Negative}>Customer Trading is currently disabled.</TradingAlert>
      )}

      {/* Trading */}
      {tradingWillBeDisabled && (
        <TradingAlert variant={AlertVariants.Negative}>
          Trading will be disabled and all active orders{cancelledText} will be cancelled. Please confirm the action.
        </TradingAlert>
      )}
      {tradingWillBeEnabled && (
        <TradingAlert variant={AlertVariants.Positive}>
          Trading will be enabled. Please confirm the action.
        </TradingAlert>
      )}

      {/* Position Auto-Hedging */}
      {showPositionAutoHedgingInfo && (
        <>
          {!tradingWillBeDisabled && positionAutoHedgingWillBeDisabled && (
            <TradingAlert variant={AlertVariants.Negative}>
              Position Auto-Hedging will be disabled. Please confirm the action.
            </TradingAlert>
          )}
          {positionAutoHedgingWillBeEnabled && (
            <TradingAlert variant={AlertVariants.Positive}>
              Position Auto-Hedging will be enabled. Please confirm the action.
            </TradingAlert>
          )}
        </>
      )}

      {/* DDH, Dynamic Delta Hedging */}
      {showDdhInfo && (
        <>
          {!tradingWillBeDisabled && ddhWillBeDisabled && (
            <TradingAlert variant={AlertVariants.Negative}>
              Dynamic Delta Hedging will be disabled. All active DDH orders will be cancelled. Options orders with DDH
              enabled will be paused. New Options orders cannot be entered with DDH enabled. Please confirm the action.
            </TradingAlert>
          )}
          {ddhWillBeEnabled && (
            <TradingAlert variant={AlertVariants.Positive}>
              Dynamic Delta Hedging will be enabled. Please confirm the action.
            </TradingAlert>
          )}
        </>
      )}

      {/* Customer Trading */}
      {showCustomerTradingInfo && (
        <>
          {!tradingWillBeDisabled && customerTradingWillBeDisabled && (
            <TradingAlert variant={AlertVariants.Negative}>
              Customer Trading will be disabled. Active customer orders will remain active but new orders cannot be
              placed. Please confirm the action.
            </TradingAlert>
          )}
          {customerTradingWillBeEnabled && (
            <TradingAlert variant={AlertVariants.Positive}>
              Customer Trading will be enabled. Please confirm the action.
            </TradingAlert>
          )}
        </>
      )}
    </VStack>
  );
}

interface TradingAlertProps {
  variant: AlertVariants;
}

function TradingAlert({ variant, children }: PropsWithChildren<TradingAlertProps>) {
  return (
    <Alert w="100%" variant={variant} dismissable={false}>
      {children}
    </Alert>
  );
}

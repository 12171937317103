import {
  ACTION,
  Box,
  Divider,
  Drawer,
  type DrawerProps,
  Flex,
  HStack,
  readableDate,
  ReconCheckpointOrMatchStatus,
  Tab,
  TabList,
  type TabProps,
  Tabs,
  TabSize,
  Text,
  usePortal,
  useTabs,
} from '@talos/kyoko';
import { compact } from 'lodash-es';
import { useMemo } from 'react';
import { useFeatureFlag, useRoleAuth } from '../../../../hooks';
import { BreakResolutionTab } from './BreakResolutionTab';
import { isMatchBreak, MatchHistoryTab } from './MatchHistoryTab';
import { ReconBreakSummary } from './ReconBreakSummary';
import { DRAWER_TABLIST_SUFFIX_PORTAL_ID, type ReconBreak } from './types';

const BreakResolutionDrawerTabID = {
  ResolveBreak: 'ResolveBreak',
  MatchHistory: 'MatchHistory',
} as const;

export type BreakResolutionDrawerProps = {
  reconBreak: ReconBreak | undefined;
  initialTab?: keyof typeof BreakResolutionDrawerTabID;
} & DrawerProps;

export const BreakResolutionDrawer = ({
  reconBreak,
  initialTab = 'ResolveBreak',
  ...drawerProps
}: BreakResolutionDrawerProps) => {
  const { enableIBORAuditTrail } = useFeatureFlag();
  const { isAuthorized } = useRoleAuth();
  const isAuthorizedToEdit = isAuthorized(ACTION.EDIT_PORTFOLIO_RECON);
  const showAuditLogTab = enableIBORAuditTrail && isMatchBreak(reconBreak);

  const tabItems: TabProps[] = useMemo(
    () =>
      compact([
        isAuthorizedToEdit ? { label: 'Resolve Break', id: BreakResolutionDrawerTabID.ResolveBreak } : undefined,
        showAuditLogTab ? { label: 'Audit Log', id: BreakResolutionDrawerTabID.MatchHistory } : undefined,
      ]),
    [isAuthorizedToEdit, showAuditLogTab]
  );

  const initialSelectedIndex = useMemo(() => {
    const preferredIndex = tabItems.findIndex(item => item.id === initialTab);
    if (preferredIndex === -1) {
      return 0;
    }
    return preferredIndex;
  }, [tabItems, initialTab]);

  const tabs = useTabs({
    initialItems: tabItems,
    initialSelectedIndex,
  });

  const selectedTabId = tabs.items.at(tabs.selectedIndex)?.id;

  const { setPortalRef } = usePortal(DRAWER_TABLIST_SUFFIX_PORTAL_ID);

  return (
    <Drawer {...drawerProps}>
      {reconBreak == null ? null : (
        <Flex flexDirection="column" h="100%" w="100%">
          <Flex flexDirection="column" h="100%" justifyContent="flex-start" flex="0">
            <Flex flexDirection="column" gap="spacingDefault" p="spacingMedium">
              <HStack fontSize="fontSizeSm" gap="spacingDefault" justifyContent="space-between">
                <Text>{readableDate(reconBreak.timestamp, true)}</Text>
                <ReconCheckpointOrMatchStatus status={reconBreak.status} textColor="colorTextImportant" />
              </HStack>
              <ReconBreakSummary reconBreak={reconBreak} />
            </Flex>

            <Divider color="backgroundDivider" />
          </Flex>

          <Tabs h="100%" size={TabSize.Large} {...tabs}>
            <TabList isBordered flex="0 0 auto" rightItems={<Box ref={setPortalRef} />}>
              {tabs.items.map(tab => (
                <Tab label={tab.label} key={tab.label} data-testid={`resolution-drawer-tab-${tab.id}`} />
              ))}
            </TabList>

            {selectedTabId === BreakResolutionDrawerTabID.ResolveBreak && (
              <BreakResolutionTab reconBreak={reconBreak} closeDrawer={drawerProps.close} />
            )}
            {selectedTabId === BreakResolutionDrawerTabID.MatchHistory && isMatchBreak(reconBreak) && (
              <MatchHistoryTab matchBreak={reconBreak} closeDrawer={drawerProps.close} />
            )}
          </Tabs>
        </Flex>
      )}
    </Drawer>
  );
};

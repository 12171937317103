import { FormControlSizes, HStack, Text, Toggle, type ToggleProps } from '@talos/kyoko';
import { CONTROL_TOOLTIPS } from 'containers/Blotters/tooltips';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled, { type DefaultTheme } from 'styled-components';
import { PMSDateRangeFilter } from './contextSelection/PMSDateRangeFilter';
import { PMSMarketAccountSelector } from './contextSelection/PMSMarketAccountSelector';
import { PMSPortfolioSelector } from './contextSelection/PMSPortfolioSelector';
import { getPortfolioLayoutOptions } from './layoutConfiguration/getPortfolioLayoutOptions';
import type { LayoutOptionTabProps } from './portfolioDashboard.types';
import { PortfolioWorkspaceHeaderMenu } from './PortfolioWorkspaceHeaderMenu/PortfolioWorkspaceHeaderMenu';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from './stateManagement/portfolioViewLayoutSlice.hooks';

const PortfolioWorkspaceLabel = () => {
  const layoutRoutes = useMemo(() => {
    const layoutOptions = getPortfolioLayoutOptions();
    return (
      <Routes>
        {layoutOptions.map(layout => (
          <Route
            key={layout.route}
            path={`${layout.route.replace(/^\//, '')}/*`}
            element={
              <HStack gap="spacingTiny" data-testid="portfolio-dashboard-workspace-label">
                <Text color="colorTextImportant" lineHeight="20px" fontSize="fontSizeTitleH4">
                  {layout.label}
                </Text>
              </HStack>
            }
          />
        ))}
      </Routes>
    );
  }, []);

  return <HStack gap="spacingTiny">{layoutRoutes}</HStack>;
};

const FilterControls = ({ selectedTab }: { selectedTab: PortfolioWorkspaceTabProps }) => {
  const filterContext = selectedTab.activeFilters;

  const filterSet = useMemo(() => {
    return new Set(filterContext ?? []);
  }, [filterContext]);

  return (
    <HStack gap="spacingDefault">
      {filterSet.has('SubAccounts') && <PMSPortfolioSelector />}
      {filterSet.has('MarketAccounts') && <PMSMarketAccountSelector />}
      {filterSet.has('DateRange') && <PMSDateRangeFilter />}
    </HStack>
  );
};

const { updateShowRollupHierarchy } = getPortfolioViewActions();

const CONTROL_TOOLTIP_PROPS: ToggleProps['tooltipProps'] = { placement: 'bottom' };

export type PortfolioWorkspaceTabProps = Pick<LayoutOptionTabProps, 'activeControls' | 'activeFilters'>;
export const PortfolioWorkspaceHeader = ({ selectedTab }: { selectedTab: PortfolioWorkspaceTabProps }) => {
  const { showRollupHierarchy } = usePortfolioViewStateSelector();
  const dispatch = useAppStateDispatch();
  const isShowRollupHierarchyVisible = selectedTab.activeControls?.showRollupHierarchy ?? false;

  return (
    <HeaderHStack data-testid="portfolio-workspace-header">
      <HStack gap="spacingComfortable">
        <PortfolioWorkspaceLabel />
        <FilterControls selectedTab={selectedTab} />
      </HStack>
      <HStack gap="spacingDefault" data-testid="portfolio-dashboard-controls">
        {isShowRollupHierarchyVisible && (
          <Toggle
            size={FormControlSizes.Small}
            tooltip={CONTROL_TOOLTIPS.showRollupHierarchy}
            tooltipProps={CONTROL_TOOLTIP_PROPS}
            data-testid="rollup-hierarchy-toggle"
            label="Show Rollup Hierarchy"
            checked={showRollupHierarchy}
            onChange={value => {
              dispatch(updateShowRollupHierarchy(value));
            }}
          />
        )}
        <PortfolioWorkspaceHeaderMenu />
      </HStack>
    </HeaderHStack>
  );
};

const HeaderHStack = styled(HStack)<{ theme: DefaultTheme }>`
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundContent};
  height: ${({ theme }) => theme.spacingHuge + 'px'};
  padding: ${({ theme }) => theme.spacingComfortable + 'px'};
  gap: ${({ theme }) => theme.spacingDefault + 'px'};
`;

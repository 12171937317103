import { runValidation, type CustomerBalanceTransaction } from '@talos/kyoko';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { number, object, string } from 'yup';

export const useBalanceTransactionValidation = (form: Partial<CustomerBalanceTransaction>) => {
  const [errors, setErrors] = useState<Partial<CustomerBalanceTransaction>>({});
  const [touched, setTouched] = useState<Partial<Record<keyof CustomerBalanceTransaction, boolean>>>({});

  const setAllTouched = useCallback(() => {
    setTouched({
      ExternalID: true,
      Counterparty: true,
      MarketAccount: true,
      Currency: true,
      Type: true,
      Amount: true,
      TxHash: true,
    });
  }, []);

  useEffect(() => {
    const schema = {
      ExternalID: string().required('ExternalID is required'),
      Counterparty: string().required('Counterparty is required'),
      MarketAccount: string().required('Customer Account is required'),
      Currency: string().required('Currency is required'),
      Type: string().required('Type is required'),
      Amount: number().typeError('Amount must be a number').required('Amount is required'),
    };

    setErrors(prev => {
      const next = runValidation(object().shape(schema), form);
      return isEqual(prev, next) ? prev : next;
    });
  }, [form]);

  return { errors, touched, setTouched, setAllTouched };
};

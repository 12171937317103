import type { AnchorHTMLAttributes } from 'react';
import { Link, type LinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { Icon } from '../../Icons';
import { buttonStyle, type ButtonProps } from '../styles';

const NavButtonWrapper = styled(Link)<ButtonProps<AnchorHTMLAttributes<HTMLAnchorElement>>>`
  text-decoration: none;
  ${({ theme, size, variant, disabled, ghost, dim, prominent, width, height, justifyContent, flex, pill }) =>
    buttonStyle({
      theme,
      size,
      variant,
      disabled,
      ghost,
      prominent: dim,
      dim: prominent,
      width,
      height,
      justifyContent,
      flex,
      pill,
    })}
  text-decoration: none;
`;

export const NavButton = styled(
  ({
    children,
    startIcon,
    endIcon,
    to,
    ...props
  }: ButtonProps<AnchorHTMLAttributes<HTMLAnchorElement>> & { to?: LinkProps['to'] }) => (
    <NavButtonWrapper to={props.disabled ? '#' : (to as string)} {...props}>
      {!!startIcon && <Icon icon={startIcon} />}
      {children && <span>{children}</span>}
      {!!endIcon && <Icon icon={endIcon} />}
    </NavButtonWrapper>
  )
)``;

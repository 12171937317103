import { Panel } from 'components/Layout/Components/Panel';
import { DockviewDefaultTab, type IDockviewDefaultTabProps, type IDockviewPanelProps } from 'dockview';
import 'dockview/dist/styles/dockview.css';
import type { PanelComponentSpec } from 'types/LayoutConfig';

export function WrapComponentsForDockView(components: PanelComponentSpec[]): {
  [key: string]: (props: IDockviewPanelProps) => JSX.Element;
} {
  return components.reduce((res, component) => {
    res[component.type] = props => {
      return <Panel panelLocation={props.api.location}>{component.renderer?.(props)}</Panel>;
    };
    return res;
  }, {} as { [key: string]: (props: IDockviewPanelProps) => JSX.Element });
}

export const TAB_COMPONENTS = {
  // Replace close icon SVG with our own icon for this and all other tabs
  // Merge with default tab and control with prop
  readOnly: (props: IDockviewDefaultTabProps) => {
    return <DockviewDefaultTab hideClose={true} {...props} />;
  },
  // Replace close icon SVG with our own icon for this and all other tabs
  // Add close confirmation dialog
  default: (props: IDockviewDefaultTabProps) => {
    return <DockviewDefaultTab {...props} />;
  },
};

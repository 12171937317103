import type { ColDef, ValueFormatterParams } from 'ag-grid-community';
import Big from 'big.js';
import { get, isNil } from 'lodash-es';

import type { AmountObject } from '../../../utils/notional';
import { getCellDisplayValue } from '../../AgGrid/agGridGetCellValue';
import { getAgGridColId } from '../columns/getAgGridColId';
import type { Column } from '../columns/types';
import { getGridApiFromNode, isGridApiReady } from '../utils';

export function dateStringComparator(a: string | undefined, b: string | undefined) {
  if (!a && !b) {
    return 0;
  } else if (!a) {
    return -1;
  } else if (!b) {
    return 1;
  }
  return a.localeCompare(b);
}

export function amountObjectComparator(a: AmountObject | undefined, b: AmountObject | undefined) {
  return numericColumnComparator(a?.value, b?.value);
}

export function amountObjectValueFormatter({ value }: ValueFormatterParams<unknown, AmountObject>): string {
  if (value == null) {
    return '';
  }
  return value.value;
}

/**
 * Compares to numeric values. Regards a nullish value to be less than a non-nullish value.
 */
export function numericColumnComparator(a: any, b: any) {
  try {
    return Big(a).cmp(b);
  } catch {
    if (isNil(a) && isNil(b)) {
      return 0;
    }
    if (isNil(a)) {
      return -1;
    }
    if (isNil(b)) {
      return 1;
    }
    return a - b;
  }
}

/**
 * A basic string comparator where a null value is regarded as smaller than a non-null value.
 */
export function stringColumnComparator(a?: string, b?: string) {
  if (a == null && b == null) {
    return 0;
  }

  if (a == null) {
    return -1;
  }

  if (b == null) {
    return 1;
  }

  return a.localeCompare(b);
}

/**
 * Given a list of comparison keys to compare entities of type T on,
 * returns a comparator function, comparing on string values
 *
 * For example passing in comparisonKeys: ["MarketAccount", "Currency", "Destination"] will provide a sorting
 * function which sorts items T by first MarketAccount, secondly Currency and thirdly Destination.
 */
export function stringColumnComparatorMultiColumn<T>(comparisonKeys: (keyof T)[]) {
  return (a: T, b: T) => {
    const keyWithDiff = comparisonKeys.find(key => get(a, key) !== get(b, key));
    return keyWithDiff ? stringColumnComparator(get(a, keyWithDiff), get(b, keyWithDiff)) : 0;
  };
}

/**
 * A factory function for creating a comparator which will invoke your column's valueFormatter and then execute the
 * comparator function based on those formatted values (which is what is being displayed to the user).
 *
 * If there is no valueFormatter defined, the comparator fallbacks to the basic stringColumnComparator logic
 * @param column the talos Column config object. Just used to resolve the effective column id
 */
export function getStringFormattedColumnComparator(column: Column): ColDef['comparator'] {
  return function stringFormattedColumnComparator(valueA, valueB, nodeA, nodeB) {
    const api = getGridApiFromNode(nodeA);
    const agColumn = api?.getColumn(getAgGridColId(column));
    // we only need to compute valueFormatters if the column in question actually has one defined
    const columnHasValueFormatter = agColumn?.getColDef().valueFormatter != null;

    if (isGridApiReady(api) && agColumn && columnHasValueFormatter) {
      const nodeAFormattedValue = getCellDisplayValue({ node: nodeA, column: agColumn, value: valueA, api });
      const nodeBFormattedValue = getCellDisplayValue({ node: nodeB, column: agColumn, value: valueB, api });
      return stringColumnComparator(nodeAFormattedValue ?? valueA, nodeBFormattedValue ?? valueB);
    }

    // else just do the basics
    return stringColumnComparator(valueA, valueB);
  };
}

import {
  FormControlSizes,
  HStack,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  PortalContextProvider,
  Tooltip,
  useMixpanel,
  usePortal,
} from '@talos/kyoko';
import { DEFAULT_MIN_SIZE } from 'components/AppLayout/Layouts/shared';
import type { DockviewGroupPanel, IDockviewHeaderActionsProps } from 'dockview';
import { useCallback, useEffect, useState } from 'react';
import { useLayoutContext } from '../providers/LayoutContextProvider';
import { TabIconButton } from '../styles';
import { ANIMATION_DURATION, MAIN_GROUP_ID, POPOUT_URL } from '../tokens';
import type { MinimizedPanel } from '../types';
import { addStyles } from '../utils/addStyles';

/** Controls that appears at the top right corner above a panel.
 * Includes a portal for panels to render content specific controls into */
export const RightComponent = ({ api, containerApi, group, activePanel, ...props }: IDockviewHeaderActionsProps) => {
  const mixpanel = useMixpanel();
  const { minimizedPanels, onMaximizePanel, onMinimizePanel, onPopoutGroup, enablePopoutWindows, setIsAnimating } =
    useLayoutContext();
  const [maximized, setMaximized] = useState<boolean>(api.isMaximized());

  const minimized = minimizedPanels.find(panel => panel.groupId === group.id);
  const isPopout = api.location.type === 'popout';
  const canMaximize =
    group.maximumWidth === Number.MAX_SAFE_INTEGER && group.maximumHeight === Number.MAX_SAFE_INTEGER && !isPopout;
  const canMinimize =
    minimized || (group.minimumWidth <= DEFAULT_MIN_SIZE && group.minimumHeight <= DEFAULT_MIN_SIZE && !isPopout);
  const canPopout = enablePopoutWindows && group.id !== MAIN_GROUP_ID && !isPopout;

  useEffect(() => {
    const disposables = [
      containerApi.onDidMaximizedGroupChange(() => {
        setMaximized(api.isMaximized());
      }),
      api.onDidLocationChange(e => {
        if (api.location.type === 'popout') {
          const popoutWindow = api.getWindow();
          addStyles(popoutWindow.document, document.styleSheets, undefined);
          onPopoutGroup(api.id, popoutWindow, popoutWindow.name);
        } else if (api.location.type === 'grid') {
          onPopoutGroup(api.id, undefined);
        }
      }),
    ];

    return () => {
      disposables.forEach(disposable => disposable.dispose());
    };
  }, [containerApi, activePanel, api, onPopoutGroup]);

  const onMaximize = useCallback(() => {
    // Reset minimized panel if it's minimized
    const minimizedPanel = minimizedPanels.find(panel => panel.groupId === group.id);
    if (minimizedPanel) {
      resetMinimizedGroup(group, minimizedPanel);
      onMinimizePanel({ groupId: group.id, prevHeight: group.height, minimumHeight: group.minimumHeight }, false);
    }

    if (maximized) {
      api.exitMaximized();
    } else {
      api.setActive();
      setTimeout(() => {
        api.maximize();
      }, 0);
    }
    mixpanel.track(MixpanelEvent.MaximizeLayoutGroup, {
      [MixpanelEventProperty.PanelType]: activePanel?.api.component,
      [MixpanelEventProperty.Enabled]: !maximized,
    });
    onMaximizePanel(group.id, !maximized);
  }, [api, maximized, minimizedPanels, group, onMaximizePanel, activePanel, onMinimizePanel, mixpanel]);

  const onMinimize = useCallback(() => {
    // Reset maximized panel if it's maximized
    if (maximized) {
      api.exitMaximized();
      onMaximizePanel(group.id, false);
    }

    const minimizedPanel = minimizedPanels.find(panel => panel.groupId === group.id);
    if (minimizedPanel) {
      resetMinimizedGroup(group, minimizedPanel);
    }
    mixpanel.track(MixpanelEvent.MinimizeLayoutGroup, {
      [MixpanelEventProperty.PanelType]: activePanel?.api.component,
      [MixpanelEventProperty.Enabled]: !minimizedPanel,
    });
    onMinimizePanel(
      { groupId: group.id, prevHeight: group.height, minimumHeight: group.minimumHeight },
      !minimizedPanel
    );
    initAnimation(setIsAnimating);
  }, [api, group, maximized, minimizedPanels, onMinimizePanel, setIsAnimating, onMaximizePanel, mixpanel, activePanel]);

  const onPopout = useCallback(() => {
    containerApi.addPopoutGroup(group, {
      onDidOpen: ({ window: popoutWindow, id }) => {
        onPopoutGroup(group.api.id, popoutWindow, id);
        mixpanel.track(MixpanelEvent.PopoutLayoutGroup, {
          [MixpanelEventProperty.PanelType]: activePanel?.api.component,
        });
      },
      onWillClose: ({ id }) => {
        onPopoutGroup(group.api.id, undefined, id);
        mixpanel.track(MixpanelEvent.ClosePopout, {
          [MixpanelEventProperty.PanelType]: activePanel?.api.component,
        });
      },
      popoutUrl: POPOUT_URL,
    });
  }, [containerApi, group, onPopoutGroup, mixpanel, activePanel]);

  const rightComponentPortalId = `rightComponent_${activePanel?.id}`;
  const { setPortalRef } = usePortal(rightComponentPortalId);

  return (
    <PortalContextProvider>
      <HStack {...props}>
        {/* Portal target for panel content specific controls */}
        <HStack ref={setPortalRef} borderLeft="solid 1px" borderRight="solid 1px" borderColor="backgroundBody" />
        {/* Generic controls */}
        <HStack>
          {canMinimize && (
            <Tooltip tooltip="Minimize panel" placement="bottom">
              <TabIconButton
                data-testid="minimize-layout-group-button"
                datatype="minimize-panel"
                onClick={onMinimize}
                icon={minimized ? IconName.ChevronUp : IconName.Minus}
                size={FormControlSizes.Small}
              />
            </Tooltip>
          )}
          {canMaximize && (
            <Tooltip tooltip="Maximize panel" placement="bottom">
              <TabIconButton
                data-testid="maximize-layout-group-button"
                datatype="maximize-panel"
                onClick={onMaximize}
                icon={maximized ? IconName.ArrowsReduce : IconName.ArrowsExpand}
                size={FormControlSizes.Small}
              />
            </Tooltip>
          )}
          {canPopout && (
            <Tooltip tooltip="Open panel in a new window" placement="bottom">
              <TabIconButton
                data-testid="popout-layout-group-button"
                onClick={onPopout}
                icon={IconName.ExternalLink}
                size={FormControlSizes.Small}
              />
            </Tooltip>
          )}
        </HStack>
      </HStack>
    </PortalContextProvider>
  );
};

function resetMinimizedGroup(group: DockviewGroupPanel, minimizedPanel: MinimizedPanel) {
  group.api.setSize({
    height: minimizedPanel.prevHeight,
  });
  group.api.setConstraints({
    minimumHeight: minimizedPanel.minimumHeight,
  });
}

/** Methods to set and reset animating class when animating panels */
function initAnimation(setIsAnimating: (isAnimating: boolean) => void) {
  setIsAnimating(true);
  setTimeout(() => {
    setIsAnimating(false);
  }, ANIMATION_DURATION);
}

import {
  ACTION,
  type Column,
  type ColumnDef,
  type Expect,
  type Leaves,
  MixpanelEventSource,
  type NumberColumnParams,
  type PriceParams,
  useDefaultColumns,
  useDynamicCallback,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { useFeatureFlag, useRoleAuth, useTransfersDrawer } from '../../../../hooks';
import { useTransfers } from '../../../../providers';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { useTradePositionColumn } from '../../../../utils/columns/useTradePositionColumn';
import { useTransferButtonColumn } from '../../../../utils/columns/useTransferButtonColumn';
import { getCreditUsageColumn } from '../../../Blotters/Credit/creditUsageColumn';
import type { TransferForm } from '../../../Transfers/types';
import { useOperationsOverviewConfig } from '../providers/OperationsOverviewConfigProvider';
import { MarketAccountRow, OpsPositionRow } from './rows';
import type { OpsOverviewBlotterRow } from './useOpsOverviewRows';

function getPositionAssetType(p: OpsPositionRow) {
  return p.position.AssetType;
}

// The column def below is particularly complicated - the fields can be deeply nested properties. We want to keep the ids brief and legible,
// so each column is giving a column id explicitly from this list here
export type OpsOverviewBlotterColumnID = keyof ReturnType<typeof useOperationsOverviewColumnMapping>;

type OpsOverviewBlotterRowLeaves = Leaves<OpsOverviewBlotterRow>;
type OpsOverviewBlotterRowColumn = OpsOverviewBlotterColumnID | Partial<ColumnDef<OpsOverviewBlotterRow>>;

export type OperationsOverviewBlotterColumnSpecification = OpsOverviewBlotterRowColumn;

export const useOperationsOverviewBlotterColumns = (defaultColumns: OperationsOverviewBlotterColumnSpecification[]) => {
  const { showPositionsBlotterWarningColumn } = useFeatureFlag();
  const { mode } = useOperationsOverviewConfig();
  const { isAuthorized } = useRoleAuth();
  const authorizedToTrade = isAuthorized(ACTION.SUBMIT_ORDER);
  const authorizedToTransfer = isAuthorized(ACTION.SUBMIT_TRANSFER);
  const showPMMarginSpecificColumns = mode === 'SubAccount';
  const showOpsOverviewSpecificColumns = mode === 'MarketAccount';

  const mapping = useOperationsOverviewColumnMapping();
  const columnDefinitions = useMemo(() => {
    const conditionals: Map<string, boolean> = new Map([
      ['reconWarning', showPositionsBlotterWarningColumn],
      ['deltaExposureEquivalent', showPMMarginSpecificColumns],
      ['marginReportingType', showOpsOverviewSpecificColumns],
      ['creditUsage', showOpsOverviewSpecificColumns],
      ['buy', authorizedToTrade],
      ['sell', authorizedToTrade],
      ['withdraw', authorizedToTransfer],
      ['deposit', authorizedToTransfer],
    ]) satisfies Map<OpsOverviewBlotterColumnID, boolean>;

    const output: Map<string, Column> = new Map();
    Object.entries(mapping).forEach(([key, column]) => {
      const condition = conditionals.get(key);
      if (condition == null || condition === true) {
        output.set(key, column);
      }
    });

    return output;
  }, [
    mapping,
    authorizedToTrade,
    authorizedToTransfer,
    showPMMarginSpecificColumns,
    showOpsOverviewSpecificColumns,
    showPositionsBlotterWarningColumn,
  ]);

  const columns = useDefaultColumns(defaultColumns, columnDefinitions);
  return columns;
};

const useOperationsOverviewColumnMapping = () => {
  const { homeCurrency } = useDisplaySettings();

  const { getPrimeableTransfer } = useTransfers();
  const { openTransfersDrawer } = useTransfersDrawer();

  const handleOpenTransfersDrawer = useDynamicCallback((primedTransfer: TransferForm) => {
    openTransfersDrawer({ initialTransfer: primedTransfer, source: MixpanelEventSource.TreasuryBlotter });
  });

  const buyColumn = useTradePositionColumn<OpsPositionRow>({
    assetField: 'position.Asset',
    marketAccountField: 'position.MarketAccount',
    constrainSecuritiesToMarket: true,
    action: 'Buy',
    columnId: 'buy',
    getAssetType: getPositionAssetType,
    showTitle: true,
  });

  const sellColumn = useTradePositionColumn<OpsPositionRow>({
    assetField: 'position.Asset',
    marketAccountField: 'position.MarketAccount',
    constrainSecuritiesToMarket: true,
    action: 'Sell',
    columnId: 'sell',
    getAssetType: getPositionAssetType,
    showTitle: true,
  });

  const withdrawColumn = useTransferButtonColumn<OpsPositionRow>({
    currencyField: 'position.Asset',
    marketField: 'position.market',
    marketAccountField: 'position.MarketAccount',
    type: 'withdraw',
    getPrimeableTransfer,
    onClick: handleOpenTransfersDrawer,
    columnID: 'withdraw',
    tryWithLinkedAccount: true,
  });

  const depositColumn = useTransferButtonColumn<OpsPositionRow>({
    currencyField: 'position.Asset',
    marketField: 'position.market',
    marketAccountField: 'position.MarketAccount',
    type: 'deposit',
    getPrimeableTransfer,
    onClick: handleOpenTransfersDrawer,
    columnID: 'deposit',
    tryWithLinkedAccount: true,
  });

  return useMemo(() => {
    return {
      reconWarning: {
        id: 'reconWarning',
        type: 'reconWarning',
        title: 'Recon Warning',
        field: 'position.reconWarning',
        aggregate: true,
        sortable: true,
      },
      asset: {
        id: 'asset',
        field: 'position.Asset',
        type: 'asset',
        sortable: true,
        title: 'Instrument',
        params: {
          assetTypeField: 'position.AssetType' satisfies OpsOverviewBlotterRowLeaves,
          colorful: true,
        },
        description: 'The specific spot or derivatives asset held in the position.',
      },
      underlying: {
        id: 'underlying',
        field: 'position.underlying',
        type: 'currency',
        title: 'Underlying',
        params: {
          colorful: true,
          showDescription: false,
        },
        hide: true,
        description: 'For derivatives contracts, the underlying asset symbol.',
      },
      market: {
        id: 'market',
        field: 'position.market',
        type: 'market',
        title: 'Market',
        hide: true,
        sortable: true,
        description: 'Venue (exchange, OTC brokerage, custodian) where positions are held.',
      },
      marketAccount: {
        id: 'marketAccount',
        field: 'position.MarketAccount',
        type: 'account',
        title: 'Account',
        sortable: true,
        description: 'Specific venue account (a.k.a. market account) where positions are held.',
      },
      subAccount: {
        id: 'subAccount',
        field: 'subAccountName',
        type: 'subAccountName',
        title: 'Sub Account',
        hide: true,
        description: 'Sub Account',
      },
      assetType: {
        id: 'assetType',
        field: 'position.AssetType',
        title: 'Product Type',
        type: 'productTypeField',
        hide: true,
        width: 130,
        enableRowGroup: true,
        description: 'High-level asset class like spot, future or perpetual for the instrument.',
      },
      positionQty: {
        id: 'positionQty',
        field: 'position.Amount',
        type: 'size',
        title: 'Position Qty',
        params: {
          showInTermsOfContracts: true,
          highlightNegative: true,
          currencyField: 'position.Asset' satisfies OpsOverviewBlotterRowLeaves,
          trimTrailingZeroes: true,
        },
        description: 'Quantity of derivative contracts or size of spot position.',
      },
      position: {
        id: 'position',
        field: 'position.Amount',
        type: 'size',
        title: 'Position',
        sortable: true,
        aggregate: true,
        width: 150,
        params: {
          currencyField: 'position.Asset' satisfies OpsOverviewBlotterRowLeaves,
          highlightNegative: true,
          showCurrencyForNullPosition: false,
        },
        description: 'Exposure to spot assets and derivatives contracts, in quantity of assets.',
      },
      positionEquivalent: {
        id: 'positionEquivalent',
        type: 'size',
        field: 'position.Equivalent.Amount',
        title: `Position (${homeCurrency})`,
        params: {
          currencyField: 'position.Equivalent.Currency' satisfies OpsOverviewBlotterRowLeaves,
          highlightNegative: true,
        },
        sortable: true,
        aggregate: true,
        description: 'Exposure to spot assets and derivatives contracts, in home currency.',
      },
      balance: {
        id: 'balance',
        field: 'position.balance',
        type: 'size',
        title: `Balance`,
        params: {
          highlightNegative: true,
          currencyField: 'position.Asset' satisfies OpsOverviewBlotterRowLeaves,
        },
        hide: true,
        aggregate: true,
        description: 'Spot balances, expressed in quantity of assets held.',
      },
      balanceEquivalent: {
        id: 'balanceEquivalent',
        field: 'position.balanceEquivalent',
        aggregate: true,
        type: 'size',
        title: `Balance (${homeCurrency})`,
        params: {
          highlightNegative: true,
          currencyField: 'position.Equivalent.Currency' satisfies OpsOverviewBlotterRowLeaves,
        },
        hide: true,
        description: 'Spot balances, in home currency.',
      },
      avgCost: {
        id: 'avgCost',
        field: 'position.AvgCost',
        title: 'Avg Cost',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'position.AvgCostCurrency' satisfies OpsOverviewBlotterRowLeaves,
        },
        description: 'Average cost for the instrument provided by the venue.',
      },
      markPrice: {
        id: 'markPrice',
        title: 'Mark Price',
        field: 'position.markPriceUnified',
        type: 'price',
        width: 110,
        params: {
          quoteCurrencyField: 'position.markPriceCurrencyUnified' satisfies OpsOverviewBlotterRowLeaves,
        },
        description: 'Most recent mark price for the instrument provided by the venue.',
      },
      unrealizedPnLEquivalent: {
        id: 'unrealizedPnLEquivalent',
        field: 'position.Equivalent.UnrealizedPnL',
        type: 'size',
        title: `Unrealized PnL (${homeCurrency})`,
        aggregate: true,
        params: {
          currencyField: 'position.Equivalent.Currency' satisfies OpsOverviewBlotterRowLeaves,
          highlightNegative: true,
        },
        description: 'Unrealized P&L for derivatives positions, in home currency.',
      },
      equityEquivalent: {
        id: 'equityEquivalent',
        type: 'size',
        title: `Equity (${homeCurrency})`,
        field: 'accountPosition.metric.Equivalent.EquityContribution.Equity',
        aggregate: false,
        params: {
          currencyField: 'accountPosition.metric.Equivalent.Currency' satisfies OpsOverviewBlotterRowLeaves,
          highlightNegative: true,
        },
        sortable: true,
        description: 'Spot balances plus unrealized P&L for derivatives positions, in home currency.',
      },
      deltaExposureEquivalent: {
        id: 'deltaExposureEquivalent',
        type: 'size',
        title: `Delta Exposure (${homeCurrency})`,
        field: 'accountPosition.metric.Equivalent.NetDeltaExposure',
        aggregate: false,
        params: {
          currencyField: 'accountPosition.metric.Equivalent.Currency' satisfies OpsOverviewBlotterRowLeaves,
          highlightNegative: true,
        },
        sortable: true,
        description:
          'Net equivalent delta notional in home currency from holding this position (the economic exposure driving performance).',
      },
      emr: {
        id: 'emr',
        type: 'number',
        title: 'Equity Margin Ratio',
        field: 'accountPosition.metric.EquityMarginRatio',
        params: {
          increment: '0.01',
        } satisfies NumberColumnParams,
        description: 'EMR is the ratio of equity (collateral balances + unrealized P&L) to the initial margin posted.',
      },
      initialMarginEquivalent: {
        id: 'initialMarginEquivalent',
        type: 'size',
        title: `Initial Margin (${homeCurrency})`,
        field: 'accountPosition.metric.Equivalent.InitialMargin',
        aggregate: false,
        params: {
          currencyField: 'accountPosition.metric.Equivalent.Currency' satisfies OpsOverviewBlotterRowLeaves,
          highlightNegative: true,
        },
        sortable: true,
        description: 'Exchange published initial margin requirement for derivatives positions, in home currency.',
      },
      maintenanceMarginEquivalent: {
        id: 'maintenanceMarginEquivalent',
        type: 'size',
        title: `Maintenance Margin (${homeCurrency})`,
        field: 'position.Equivalent.maintenanceMargin',
        aggregate: false,
        params: {
          currencyField: 'position.Equivalent.Currency' satisfies OpsOverviewBlotterRowLeaves,
          highlightNegative: true,
        },
        sortable: true,
        width: 200, // dont wanna cut off the maintenance margin header label, looks bad
        description: 'Exchange-published maintenance margin requirement for derivatives positions, in home currency.',
      },
      leverageRatio: {
        id: 'leverageRatio',
        type: 'number',
        title: 'Leverage Ratio',
        field: 'leverageRatio',
        params: {
          increment: '0.01',
        },
        aggregate: false,
        description:
          'Degree of leverage for the position: 1.0 for spot; delta exposure / IM for futures and perps; delta notional / mark price for options.',
        sortable: true,
      },
      liquidationPrice: {
        id: 'liquidationPrice',
        type: 'price',
        field: 'position.LiquidationPrice',
        title: 'Liquidation Price',
        width: 130,
        params: {
          assetField: 'position.Asset' satisfies OpsOverviewBlotterRowLeaves,
        },
        description: 'An estimated price at which the venue would initiate the liquidation process.',
        sortable: true,
      },
      distanceToLiquidation: {
        id: 'distanceToLiquidation',
        type: 'price',
        field: 'position.metric.DistanceToLiquidation',
        title: 'Distance to Liquidation',
        params: {
          quoteCurrencyField: 'position.metric.DistanceToLiquidationCurrency' satisfies OpsOverviewBlotterRowLeaves,
        } satisfies PriceParams,
        description: 'How far the price needs to move before the venue auto-liquidates the position.',
      },
      distanceToLiquidationPct: {
        id: 'distanceToLiquidationPct',
        type: 'number',
        field: 'position.metric.DistanceToLiquidationPct',
        title: 'Distance to Liquidation (%)',
        params: {
          currency: '%',
          increment: '0.01',
          multiplier: 100,
        } satisfies NumberColumnParams,
        description: 'How far, in percentage terms, the price needs to move before the venue auto-liquidates.',
      },
      creditUsage: creditUsageColumn,
      marginReportingType: {
        id: 'marginReportingType',
        type: 'text',
        title: 'Margin Type',
        field: 'position.marginReportingType',
        hide: true,
        enableRowGroup: true,
        width: 100,
        description:
          'The margin classification reported by the venue, specifying if it applies at the position level (per currency) or the account level.',
      },
      outstandingBuy: {
        id: 'outstandingBuy',
        field: 'position.OutstandingBuy',
        title: 'Outstanding Buy',
        type: 'size',
        hide: true,
        params: {
          currencyField: 'position.Asset' satisfies OpsOverviewBlotterRowLeaves,
          showCurrencyForNullPosition: false,
        },
        description: 'Quantity of assets pending in open buy orders.',
      },
      outstandingSell: {
        id: 'outstandingSell',
        field: 'position.OutstandingSell',
        type: 'size',
        title: 'Outstanding Sell',
        hide: true,
        params: {
          currencyField: 'position.Asset' satisfies OpsOverviewBlotterRowLeaves,
          showCurrencyForNullPosition: false,
        },
        description: 'Quantity of assets pending in open sell orders.',
      },
      outstandingBuyEquivalent: {
        id: 'outstandingBuyEquivalent',
        type: 'size',
        field: 'position.Equivalent.OutstandingBuy',
        title: `Outstanding Buy (${homeCurrency})`,
        params: {
          currencyField: 'position.Equivalent.Currency' satisfies OpsOverviewBlotterRowLeaves,
          highlightNegative: true,
        },
        sortable: true,
        hide: true,
        aggregate: true,
        description: 'Value of assets pending in open buy orders, in home currency.',
      },
      outstandingSellEquivalent: {
        id: 'outstandingSellEquivalent',
        type: 'size',
        field: 'position.Equivalent.OutstandingSell',
        title: `Outstanding Sell (${homeCurrency})`,
        params: {
          currencyField: 'position.Equivalent.Currency' satisfies OpsOverviewBlotterRowLeaves,
          highlightNegative: true,
        },
        sortable: true,
        hide: true,
        aggregate: true,
        description: 'Value of assets pending in open sell orders, in home currency.',
      },
      buy: { ...buyColumn, id: 'buy' },
      sell: { ...sellColumn, id: 'sell' },
      withdraw: { ...withdrawColumn, id: 'withdraw' },
      deposit: { ...depositColumn, id: 'deposit' },
    } as const satisfies Record<string, ColumnDef<OpsOverviewBlotterRow>>;
  }, [homeCurrency, buyColumn, sellColumn, withdrawColumn, depositColumn]);
};

const creditUsageColumn = {
  ...getCreditUsageColumn<OpsOverviewBlotterRow>({
    colId: 'creditUsage',
    getCreditBlotterExposure: row => {
      if (row instanceof MarketAccountRow) {
        return row.accountPosition?.exposure;
      }

      if (row instanceof OpsPositionRow) {
        return row.position.exposure;
      }

      return undefined;
    },
  }),
  id: 'creditUsage',
  description: 'For OTC accounts offering deferred settlement, how much credit has been consumed.',
} as const satisfies ColumnDef<OpsOverviewBlotterRow>;

type OpsOverviewBlotterColumnKey = keyof ReturnType<typeof useOperationsOverviewColumnMapping>;
type OpsOverviewBlotterColumnId = ReturnType<typeof useOperationsOverviewColumnMapping>[keyof ReturnType<
  typeof useOperationsOverviewColumnMapping
>]['id'];

// Type check that the ids match the keys
type _EnsureColumnIDMatchKey = Expect<Exclude<OpsOverviewBlotterColumnId, OpsOverviewBlotterColumnKey>>;

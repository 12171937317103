import { combineSlices, createSlice, type ListenerEffectAPI, type PayloadAction } from '@reduxjs/toolkit';
import { getTypedKeys } from '@talos/kyoko';
import { castDraft } from 'immer';
import { debounce, isEqual, omit } from 'lodash-es';
import type { AppConfigState } from 'providers/AppConfigProvider/AppConfigProvider.types';
import { putAppConfig } from 'providers/AppConfigProvider/putAppConfig';
import type { AppState, AppStateListenerStart } from 'providers/AppStateProvider/types';
import { createBlotter2Slice } from './Blotters2Slice';
import { selectFullAppConfig } from './selectors';

// This represents the props used in the redux-upgraded part of the app config
const ReduxOnlyAppConfigProps = ['blotters2'] as const;
type ReduxConfigProps = (typeof ReduxOnlyAppConfigProps)[number];
export type AppConfigReduxState = {
  nonReduxConfig: {
    // config provided from AppConfigProvider
    // (fyi - all redux state needs to be put on properties inside the object (immer requirement))
    configState: Omit<AppConfigState, ReduxConfigProps>;
  };
  reduxConfig: Pick<AppConfigState, ReduxConfigProps>;
  /** Config Admin Tool details to detect when updated and how often */
  configDetails: {
    lastUpdated: number;
    changeCount: number;
  };
};

let appConfigActions: ReturnType<typeof createAppConfigSlice>['actions'] | undefined;

export const getAppConfigSliceActions = () => {
  if (!appConfigActions) {
    throw new Error('AppConfigSlice not initialized');
  }
  return appConfigActions;
};

export const DEFAULT_APP_CONFIG_DETAILS: AppConfigReduxState['configDetails'] = {
  lastUpdated: Date.now(),
  changeCount: 0,
};
export const createAppConfigSlice = (appConfig: AppConfigState) => {
  // manage the config coming in from the AppConfigProvider
  const nonReduxConfigSlice = createSlice({
    name: 'nonReduxAppConfig',
    initialState: {
      configState: omit(appConfig, ReduxOnlyAppConfigProps) as AppConfigReduxState['nonReduxConfig']['configState'],
    },
    reducers: {
      setConfig: (state, action: PayloadAction<AppConfigReduxState['nonReduxConfig']['configState']>) => {
        state.configState = castDraft(action.payload);
      },
    },
  });
  // manage all the redux (newer) parts of the app config
  const { reducer: blotters2Reducer, actions: blotters2Actions } = createBlotter2Slice(appConfig);
  const combinedSubAppConfigSlices = combineSlices({
    blotters2: blotters2Reducer,
  });

  // add a section for the app config details (to be shown in the inspector)
  const configDetailsSlice = createSlice({
    name: 'configDetails',
    initialState: DEFAULT_APP_CONFIG_DETAILS,
    reducers: {
      updateConfigDetails: state => {
        state.lastUpdated = Date.now();
        state.changeCount += 1;
      },
    },
  });

  // combine all of it together to put in the parent store
  const combinedSlice = combineSlices({
    nonReduxConfig: nonReduxConfigSlice.reducer,
    reduxConfig: combinedSubAppConfigSlices,
    configDetails: configDetailsSlice.reducer,
  });

  const combinedActions = {
    ...configDetailsSlice.actions,
    ...nonReduxConfigSlice.actions,
    ...blotters2Actions,
  };
  appConfigActions = combinedActions;
  return {
    reducer: combinedSlice,
    actions: combinedActions,
  };
};

const debouncedPutAppConfig = debounce((appConfig: AppConfigState, api: ListenerEffectAPI<AppState, any>) => {
  putAppConfig(appConfig);
  api.dispatch(getAppConfigSliceActions().updateConfigDetails());
}, 300);

export function setupListeners(startListening: AppStateListenerStart) {
  // ensure the appConfigSlice's actions is set before setting up listeners
  if (!appConfigActions) {
    throw new Error('AppConfigSlice must be initialized before setting up listeners');
  }
  const resolvedAppConfigActions = appConfigActions;

  // listen for changes to the app config and update the server

  const appConfigPutActions = getTypedKeys(resolvedAppConfigActions)
    .filter(item => ['updateConfigDetails'].includes(item) === false)
    .map(key => resolvedAppConfigActions[key].type);

  const unsubscribeAppConfigUpdater = startListening({
    predicate: (action, currentState, originalState) => {
      const isConfigChangeState = appConfigPutActions.includes(action.type as (typeof appConfigPutActions)[number]);
      if (isConfigChangeState && !isEqual(selectFullAppConfig(currentState), selectFullAppConfig(originalState))) {
        return true;
      }
      return false;
    },
    effect: (_, api) => {
      const fullState = selectFullAppConfig(api.getState());
      debouncedPutAppConfig(fullState, api);
    },
  });

  return () => {
    unsubscribeAppConfigUpdater();
  };
}

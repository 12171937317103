import type { ColDef } from 'ag-grid-enterprise';
import { useCallback, useMemo } from 'react';

import type { CellClickedEvent, ColGroupDef } from 'ag-grid-community';
import { upperFirst } from 'lodash-es';
import { useMixpanel } from '../../contexts';
import { MixpanelEvent } from '../../tokens';
import { ExportButton } from './ExportDialog';
import { columnTypes, getAgGridColId } from './columns';
import type { Column } from './columns/types';
import type { ColumnDefsOptions } from './types';
import { isColumnGroup, type ColumnOrColumnGroup } from './types';

export function useColumnDefs<R>(
  columns: ColumnOrColumnGroup[],
  { handleClickJson, exportDataAsCSV }: ColumnDefsOptions<R> = {}
): Array<ColDef<R> | ColGroupDef<R>> {
  const mixpanel = useMixpanel();

  const convertColumnToColDef = useCallback(
    (column: Column): ColDef => {
      // If there is no type, we error. All column definitions should have a type.
      if (!('type' in column && column.type in columnTypes)) {
        throw new Error(`No column type specified for column ${JSON.stringify(column)}`);
      }

      // Call the column factory
      const colDef: ColDef = columnTypes[column.type](column);

      // If the colDef headerName is undefined (and there's a type defined -- see above), set a prettified headerName derived from the type
      if (colDef.headerName === undefined) {
        // "LimitID" -> "Limit ID"
        colDef.headerName = getAgGridColId(colDef)
          .split(/(?<=[a-z])(?=[A-Z])/)
          .map(upperFirst)
          .join(' ');
      }

      // Below here, we perform any mutations on specific columns to attach certain event handlers, etc.

      if (column.type === 'hamburgerMenu' && column.params.csvExport && exportDataAsCSV) {
        // For hamburger columns, if csvExport is true, set exportDataAsCsv button as header component
        const wrappedExportDataAsCSV = () => {
          exportDataAsCSV({});
          mixpanel.track(MixpanelEvent.ExportRows);
        };

        colDef.width = 100;
        colDef.headerComponent = () => <ExportButton onExportAction={wrappedExportDataAsCSV} />;
      } else if (column.type === 'json' && handleClickJson) {
        // For json, attach the handleClickJson param passed to useBlotterTable to the onClick handler of the ColDef
        colDef.onCellClicked = ({ data }: CellClickedEvent<R>) => handleClickJson(data);
      }

      return colDef;
    },
    [exportDataAsCSV, handleClickJson, mixpanel]
  );

  const convertedColumnDefs = useMemo(() => {
    return columns.map(column => {
      return isColumnGroup(column)
        ? { ...column, children: column.children.map(column => convertColumnToColDef(column)) }
        : convertColumnToColDef(column);
    });
  }, [columns, convertColumnToColDef]);

  return convertedColumnDefs;
}

import { createSelector } from '@reduxjs/toolkit';
import { BaseField, type NumericField } from '../../../../fields';
import type { WLTradingLimitsProps } from '../../../../types';
import { getTypedKeys } from '../../../../utils';
import type { RootState } from '../../WLOrderFormStore';

export const selectIsFormValid = createSelector(
  (state: RootState) => state.order.form,
  form => {
    if (!form.symbolField.hasValue) {
      return false;
    }
    if (form.viewType.value === 'rfq') {
      // for an rfq, we need symbol, qty, side and market account
      return (
        form.symbolField.hasValue &&
        form.quantityField.hasValue &&
        form.sideField.hasValue &&
        form.marketAccountField.hasValue &&
        form.quantityField.hasError === false
      );
    } else if (form.viewType.value === 'order') {
      const parametersHasError = getTypedKeys(form.parameters).some(key => {
        const maybeBaseField = form.parameters[key];
        if (maybeBaseField instanceof BaseField) {
          return maybeBaseField.hasInvalidValue;
        }
        return false;
      });

      const principalHasError = getTypedKeys(form).some(k => {
        const maybeBaseField = form[k];
        if (maybeBaseField instanceof BaseField) {
          return maybeBaseField.hasInvalidValue;
        } else {
          return false;
        }
      });
      return !principalHasError && !parametersHasError;
    }
    return false;
  }
);

export const selectLimitPrice = createSelector(
  (state: RootState) => state.order.form,
  function (form): NumericField | undefined {
    if (form.viewType.value === 'order' && 'LimitPrice' in form.parameters) {
      return form.parameters.LimitPrice as NumericField;
    }
    return undefined;
  }
);

export const selectForm = (state: RootState) => state.order.form;
export const selectModifiedOrder = (state: RootState) => state.order.orderBeingModified;
export const selectTradingLimitsValidation = (state: RootState) => state.order.dependencies.tradingLimitsValidation;
export const selectOrderStep = (state: RootState) => state.order.orderStep;
export const selectQuoteReqID = (state: RootState) => state.order.quoteReqID;
export const selectQuote = (state: RootState) => state.order.quote;
export const selectError = (state: RootState) => state.order.error;
export const selectSecurity = (state: RootState) => state.order.form.symbolField.value;
export const selectSymbol = (state: RootState) => selectSecurity(state)?.Symbol;
export const selectIsFormOpen = (state: RootState) => state.order.isOpen;
export const selectFocusedOrderID = (state: RootState) => state.order.focusedOrderID;
export const selectOrderViewType = (state: RootState) => state.order.form.viewType;

export const selectTradingLimitsArguments = createSelector(
  (state: RootState) => state.order.form.quantityField.value,
  (state: RootState) => state.order.form.orderCurrencyField.value,
  (state: RootState) => state.order.form.symbolField.value?.Symbol,
  (state: RootState) => state.order.form.marketAccountField.value?.SourceAccountID,
  (quantity, currency, symbol, marketAccountName): WLTradingLimitsProps => ({
    quantity,
    currency,
    symbol,
    marketAccountName,
  })
);

export const selectBalances = createSelector(
  (state: RootState) => state.order.form.marketAccountField.value,
  (state: RootState) => state.order.form.symbolField.value,
  (state: RootState) => state.order.dependencies.balances,
  (marketAccount, symbol, balances) =>
    new Map(
      (balances || [])
        .filter(
          b =>
            (b.Currency === symbol?.BaseCurrency || b.Currency === symbol?.QuoteCurrency) &&
            // For 0 balances Back-end is sending down empty MarketAccount strings
            (b.MarketAccount === marketAccount?.SourceAccountID || b.MarketAccount === '')
        )
        .map(b => [b.Currency, b])
    )
);

import { MarketAccountStatusEnum } from '@talos/kyoko';
import { AvailabilityEnum, type AvailabilityCondition } from '../types';

/**
 * A AvailabilityCondition which checks if the item's market account status is Active.
 *
 * If there is no market account on the item, the condition passes.
 */
export const marketAccountActiveCondition: AvailabilityCondition = {
  id: 'isMarketAccountActive',
  ready: true,
  condition: (market, marketAccount) => {
    if (!marketAccount) {
      return { availability: AvailabilityEnum.Ok };
    }

    const ok = marketAccount.Status === MarketAccountStatusEnum.Active;
    return ok
      ? { availability: AvailabilityEnum.Ok }
      : { availability: AvailabilityEnum.Disabled, infoNode: 'Market Account status is not Active' };
  },
};

import {
  Button,
  ButtonVariants,
  CustomerExecutionStrategyEnum,
  Drawer,
  DrawerContent,
  DrawerFooter,
  Flex,
  FormControlSizes,
  FormGroup,
  HStack,
  IconButton,
  IconName,
  Input,
  ModeEnum,
  NotificationVariants,
  SearchSelect,
  Text,
  TimeInForceEnum,
  VStack,
  getStringLabel,
  getTimeDrawerOptions,
  useConstant,
  useGlobalToasts,
  type Customer,
  type CustomerSecurity,
  type DrawerProps,
  type ICustomerOrderExecutionRule,
  type SearchSelectProps,
} from '@talos/kyoko';
import { useCustomers, useCustomersContext } from 'hooks/useCustomer';
import { keysIn, toNumber } from 'lodash-es';
import { useCustomerSecurities } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';

const executionStrategyOptions = {
  [CustomerExecutionStrategyEnum.OrderAcceptance]: {
    name: 'OrderAcceptance',
    description: 'Execute Customer Order Unhedged',
  },
  [CustomerExecutionStrategyEnum.RisklessPrincipal]: {
    name: 'RisklessPrincipal',
    description: 'Execute with a Cover and Deal Strategy',
  },
} as const;

type CustomerExecutionRuleDrawerProps = {
  orderExecutionRule?: ICustomerOrderExecutionRule;
  onSave: (forceRefresh?: boolean) => void;
} & DrawerProps;

// Fields that are always included and enabled for editing
const enabledAndAlwaysIncludedFields: (keyof ICustomerOrderExecutionRule)[] = [
  'Counterparty',
  'Symbol',
  'ExecutionStrategy',
  'TimeInForce',
  'Priority',
  'StartTime',
  'EndTime',
  'OrdType',
  'MinQuantityThreshold',
  'MaxQuantityThreshold',
  'MinNotionalThreshold',
  'MaxNotionalThreshold',
];

// Required Fields need to be filled in with a non-empty value
const requiredFields: (keyof ICustomerOrderExecutionRule)[] = ['ExecutionStrategy'];

// Paired Fields need to appear together or not at all
const pairedFields: (keyof ICustomerOrderExecutionRule)[][] = [['StartTime', 'EndTime']];

// Fields that are always hidden
const hiddenFields: (keyof ICustomerOrderExecutionRule)[] = [
  'UpdateAction',
  'Revision',
  'Timestamp',
  'OnFallback',
  'Workflow',
];

/**
 * @deprecated
 */
export function CustomerExecutionRuleDrawer({
  orderExecutionRule,
  onSave,
  ...props
}: CustomerExecutionRuleDrawerProps) {
  const isNewExecutionRule = orderExecutionRule == null;
  const { add: addToast } = useGlobalToasts();

  const [form, setForm] = useState<Partial<ICustomerOrderExecutionRule>>({});

  useEffect(() => {
    if (isNewExecutionRule) {
      setForm({});
    } else if (orderExecutionRule.RuleID !== form.RuleID) {
      setForm(orderExecutionRule);
    }
  }, [form.RuleID, isNewExecutionRule, orderExecutionRule]);

  const { editCustomerExecutionRule, createCustomerExecutionRule, deleteCustomerExecutionRule } = useCustomersContext();

  const handleDelete = useCallback(() => {
    if (isNewExecutionRule) {
      return;
    }
    if (window.confirm(`Are you sure you want to delete this rule?`)) {
      deleteCustomerExecutionRule(orderExecutionRule.RuleID)
        .then(() => {
          props.close();
          addToast({
            text: 'Order Execution Rule deleted',
            variant: NotificationVariants.Positive,
          });
          onSave(true);
        })
        .catch(e => {
          addToast({
            text: e.message,
            variant: NotificationVariants.Negative,
          });
        });
    }
  }, [addToast, deleteCustomerExecutionRule, isNewExecutionRule, onSave, orderExecutionRule?.RuleID, props]);

  const handleSave = useCallback(() => {
    // Check for missing paired fields
    const missingPairedFields = pairedFields
      // Both fields are required or neither.
      .filter(([field1, field2]) => Boolean(form[field1]) !== Boolean(form[field2]))
      .map(([field1, field2]) => (form[field1] == null ? field1 : field2));

    const missingRequiredFields = requiredFields.filter(field => !form[field]);
    const missingFields = [...missingPairedFields, ...missingRequiredFields];

    if (missingFields.length > 0) {
      addToast({
        text: `Missing required fields: ${missingFields.join(', ')}`,
        variant: NotificationVariants.Negative,
      });
      return;
    }

    const saveCustomerExecutionRuleFn = isNewExecutionRule ? createCustomerExecutionRule : editCustomerExecutionRule;
    saveCustomerExecutionRuleFn({
      ...form,
      Priority: form.Priority != null ? toNumber(form.Priority) : undefined,
    } as ICustomerOrderExecutionRule)
      .then(res => {
        setForm(res.data.at(0) ?? {});
        props.close();
        addToast({
          text: 'Order Execution Rule saved',
          variant: NotificationVariants.Positive,
        });
        onSave();
      })
      .catch(e => {
        addToast({
          text: e.message,
          variant: NotificationVariants.Negative,
        });
      });
  }, [addToast, createCustomerExecutionRule, editCustomerExecutionRule, form, isNewExecutionRule, onSave, props]);

  const customers = useCustomers();
  const customerSecurities = useCustomerSecurities();
  const effectiveSecurities = useMemo(
    () => customerSecurities?.filter(sec => sec.Mode === ModeEnum.Enabled),
    [customerSecurities]
  );

  const keys = useMemo(() => {
    if (!isNewExecutionRule) {
      return (
        Array.from(
          new Set([...enabledAndAlwaysIncludedFields, ...Object.keys(orderExecutionRule)])
        ) as (keyof ICustomerOrderExecutionRule)[]
      ).filter(key => !hiddenFields.includes(key));
    } else {
      return enabledAndAlwaysIncludedFields;
    }
  }, [isNewExecutionRule, orderExecutionRule]);

  const timeOptions = useConstant(getTimeDrawerOptions());

  const updateForm = useCallback((key: string, value: any) => {
    setForm(prev => ({ ...prev, [key]: value || undefined }));
  }, []);

  const getCustomerSelection = useCallback(
    (key: string) => {
      return customers?.find(option => option.Name === form[key]);
    },
    [customers, form]
  );

  const getSymbolSelection = useCallback(
    (key: string) => {
      return effectiveSecurities?.find(sec => sec.Symbol === form[key]);
    },
    [effectiveSecurities, form]
  );

  const DrawerSearchSelect = ({
    field,
    ...props
  }: SearchSelectProps<any> & { field: keyof ICustomerOrderExecutionRule }) => {
    return (
      <SearchSelect
        disabled={!enabledAndAlwaysIncludedFields.includes(field)}
        showClear={!requiredFields.includes(field)}
        data-testid={`drawer-search-select-${field}`}
        {...props}
      />
    );
  };

  return (
    <Drawer {...props} data-testid="order-execution-rules-drawer">
      <HStack justifyContent="space-between" w="100%" flexDirection="row" p="spacingMedium">
        {isNewExecutionRule ? (
          <Text color="colorTextImportant">New Order Execution Rule</Text>
        ) : (
          <Text color="colorTextImportant">Modify Order Execution Rule</Text>
        )}
        <IconButton size={FormControlSizes.Small} icon={IconName.Close} onClick={() => props.close()} />
      </HStack>
      <DrawerContent rows="1fr">
        <VStack w="100%" justifyContent="flex-start" overflow="scroll">
          <Flex flexDirection="column" w="100%">
            <Flex flexDirection="column" w="100%">
              {keys.map(key => (
                <FormGroup key={key} label={`${key}${requiredFields.includes(key) ? '*' : ''}`}>
                  {key === 'Counterparty' && customers ? (
                    <DrawerSearchSelect
                      field={key}
                      selection={getCustomerSelection(key)}
                      options={customers}
                      getLabel={option => option.DisplayName}
                      onChange={(customer?: Customer) => updateForm(key, customer?.Name)}
                    />
                  ) : key === 'Symbol' && effectiveSecurities ? (
                    <DrawerSearchSelect
                      field={key}
                      options={effectiveSecurities}
                      selection={getSymbolSelection(key)}
                      getLabel={sec => sec.Symbol}
                      onChange={(sec?: CustomerSecurity) => updateForm(key, sec?.Symbol)}
                    />
                  ) : key === 'ExecutionStrategy' ? (
                    <DrawerSearchSelect
                      field={key}
                      options={keysIn(executionStrategyOptions)}
                      selection={form[key]}
                      getLabel={option => executionStrategyOptions[option]?.name}
                      getDescription={option => executionStrategyOptions[option]?.description}
                      onChange={selection => updateForm(key, selection)}
                    />
                  ) : key === 'TimeInForce' ? (
                    <DrawerSearchSelect
                      field={key}
                      options={[
                        TimeInForceEnum.FillAndKill,
                        TimeInForceEnum.FillOrKill,
                        TimeInForceEnum.GoodTillCancel,
                      ]}
                      selection={form[key]}
                      getLabel={getStringLabel}
                      onChange={selection => updateForm(key, selection)}
                    />
                  ) : key === 'StartTime' || key === 'EndTime' ? (
                    <DrawerSearchSelect
                      field={key}
                      options={timeOptions}
                      selection={form[key]}
                      getLabel={item => `${item} UTC`}
                      onChange={(selection?: string) => updateForm(key, selection)}
                    />
                  ) : key === 'OrdType' ? (
                    <DrawerSearchSelect
                      field={key}
                      options={['Limit', 'Market']}
                      selection={form[key]}
                      getLabel={getStringLabel}
                      onChange={(selection?: string) => updateForm(key, selection)}
                    />
                  ) : (
                    <Input
                      value={form[key] ?? ''}
                      readOnly={!enabledAndAlwaysIncludedFields.includes(key)}
                      onChange={e => updateForm(key, e.target.value)}
                      data-testid={`drawer-input-${key}`}
                    />
                  )}
                </FormGroup>
              ))}
            </Flex>
          </Flex>
        </VStack>
      </DrawerContent>
      <DrawerFooter>
        {!isNewExecutionRule && (
          <Button
            variant={ButtonVariants.Negative}
            onClick={handleDelete}
            data-testid="order-execution-rules-delete-button"
          >
            Delete
          </Button>
        )}
        <Button
          onClick={handleSave}
          variant={ButtonVariants.Primary}
          disabled={requiredFields.some(field => !form[field])}
          data-testid="order-execution-rules-save-button"
        >
          Save
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}

export const Logo = ({ fill }: React.SVGProps<SVGPathElement>) => (
  <>
    <polygon
      fill={fill}
      fillRule="nonzero"
      points="98.7379177 114.977076 127.5 86.8038331 156.273779 115 173 98.5980834 127.5 54 82 98.5866216"
    />
    <polygon fill={fill} fillRule="nonzero" points="53 127.505767 69.4942328 111 86 127.505767 69.4942328 144" />
    <polygon
      fill={fill}
      fillRule="nonzero"
      points="98.7357666 139.011466 127.494152 167.206767 156.264233 139 173 155.38515 127.505848 200 82 155.419549"
    />
    <polygon fill={fill} fillRule="nonzero" points="168 127.5 184.494233 111 201 127.48847 184.494233 144" />
    <polygon
      fill={fill}
      fillRule="nonzero"
      points="143 127.522759 126.5 111 114.298415 123.21 112.888353 124.609655 110 127.5 126.5 144 143 127.534138"
    />
  </>
);

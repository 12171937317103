import type { WritableDraft } from 'immer';
import type { OrderMarketsProperty } from '../types';

export function setOrderMarketProperty(
  state: WritableDraft<OrderMarketsProperty>,
  index: number | 'ul' | 'order',
  value: boolean
) {
  switch (index) {
    case 'order':
      state.order = value;
      break;
    case 0:
      state.leg0 = value;
      break;
    case 1:
      state.leg1 = value;
      break;
    case 'ul':
      state.legUL = value;
      break;
  }
}

export function getOrderMarketProperty(state: WritableDraft<OrderMarketsProperty>, index: number | 'ul' | 'order') {
  switch (index) {
    case 'order':
      return state.order;
    case 0:
      return state.leg0;
    case 1:
      return state.leg1;
    default:
      return state.legUL;
  }
}

export function setAllOrderMarketProperties(state: WritableDraft<OrderMarketsProperty>, value: boolean) {
  state.order = value;
  state.leg0 = value;
  state.leg1 = value;
  state.legUL = value;
}

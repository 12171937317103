import { type AggregationType, DELETE, GET, PATCH, POST, request, useEndpointsContext } from '@talos/kyoko';
import { type QueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import type { Aggregation, AggregationMarket, AggregationWithAccounts } from '../types';

interface AggregationResponse {
  data: Aggregation[];
}

interface AggregationMarketResponse {
  data: AggregationMarket[];
}

const AggregationsQueryKey = ['aggregations'] as const;

export function useAggregationRequests() {
  const { orgApiEndpoint } = useEndpointsContext();
  const endpoint = `${orgApiEndpoint}/aggregations`;
  const marketEndpoint = `${orgApiEndpoint}/aggregations/markets`;

  const listAggregations = useCallback(
    (aggregationType?: AggregationType) =>
      request<AggregationResponse>(
        GET,
        aggregationType == null ? endpoint : `${endpoint}?AggregationType=${aggregationType}`,
        null
      ),
    [endpoint]
  );

  const listAggregationsWithAccounts = useCallback(
    async (aggregationType?: AggregationType) => {
      return listAggregations(aggregationType).then(async response => {
        const { data } = response;
        const aggregationMap = new Map<string, AggregationWithAccounts>();
        for (const aggregation of data) {
          aggregationMap.set(aggregation.Name, {
            Accounts: new Map(),
            ...aggregation,
          });
        }

        return request<AggregationMarketResponse>(GET, marketEndpoint, null).then(response => {
          for (const marketAggregation of response.data) {
            if (aggregationMap.has(marketAggregation.Aggregation)) {
              const aggregation = aggregationMap.get(marketAggregation.Aggregation)!;
              marketAggregation.MarketAccount != null &&
                aggregation.Accounts.set(marketAggregation.MarketAccount, marketAggregation);
            }
          }
          return Array.from(aggregationMap.values());
        });
      });
    },
    [marketEndpoint, listAggregations]
  );

  const queryClient = useQueryClient();
  const createAggregation = useCallback(
    (aggregation: Partial<Aggregation>) => {
      return request<AggregationResponse>(POST, endpoint, aggregation).then(res => {
        // We have to invalidate after we receive a success response so we dont refresh the data before it even exists

        queryClient.invalidateQueries({ queryKey: AggregationsQueryKey, exact: true });

        return res;
      });
    },
    [endpoint, queryClient]
  );

  const updateAggregation = useCallback(
    (aggregation: Partial<Aggregation>) => {
      return request<AggregationResponse>(PATCH, endpoint, aggregation).then(res => {
        queryClient.invalidateQueries({ queryKey: AggregationsQueryKey, exact: true });
        return res;
      });
    },
    [endpoint, queryClient]
  );
  const deleteAggregation = useCallback(
    (name: string) => {
      return request<AggregationResponse>(DELETE, `${endpoint}/${encodeURIComponent(name)}`, null).then(res => {
        queryClient.invalidateQueries({ queryKey: AggregationsQueryKey, exact: true });
        return res;
      });
    },
    [endpoint, queryClient]
  );

  // Internal note: the only reason this exists is that we have the CustomerAggregations page which needs to handle every returned promise and do .then
  // to manually mutate the data entries in the FormTable rows. If that page was following our current patterns, it would not mutate, and thus not need to
  // handle individual .thens. When we update that page, we can remove this and just do one tidy Bulk operation. The bulk operation can of course also just take in 1 update.
  const upsertAggregationMarket = useCallback(
    (update: Partial<AggregationMarket>, invalidateCache?: boolean) => {
      return request<AggregationMarketResponse>(PATCH, `${marketEndpoint}`, update).then(res => {
        if (invalidateCache) {
          queryClient.invalidateQueries({ queryKey: AggregationsQueryKey, exact: true });
        }
        return res;
      });
    },
    [marketEndpoint, queryClient]
  );

  const upsertAggregationMarkets = useCallback(
    (updates: Partial<AggregationMarket>[]) => {
      const requests = updates.map(u => request<AggregationMarketResponse>(PATCH, `${marketEndpoint}`, u));
      return Promise.allSettled(requests).then(res => {
        queryClient.invalidateQueries({ queryKey: AggregationsQueryKey, exact: true });
        return res;
      });
    },
    [marketEndpoint, queryClient]
  );

  return {
    listAggregations,
    listAggregationsWithAccounts,
    createAggregation,
    updateAggregation,
    deleteAggregation,

    upsertAggregationMarket,
    upsertAggregationMarkets,
  };
}

export function useAggregationsQuery(params?: Omit<QueryOptions<AggregationWithAccounts[]>, 'queryKey' | 'queryFn'>) {
  const { listAggregationsWithAccounts } = useAggregationRequests();
  return useQuery({
    queryKey: AggregationsQueryKey,
    queryFn: () => listAggregationsWithAccounts(),
    staleTime: 60_000,
    refetchOnMount: true,
    ...params,
  });
}

/**
 * Given an aggregationName, will look through all the Aggregations and return the list of AggregationMarket records
 * for the Aggregation.
 *
 * Any changes that occurr due to CRUD requests being made are automagically reflected in the output of this hook.
 */
export const useAggregationMarkets = (aggregationName: string) => {
  const aggregationsQuery = useAggregationsQuery();
  return useMemo(() => {
    const aggAccounts =
      aggregationsQuery.data?.find(agg => agg.Name === aggregationName)?.Accounts ??
      new Map<string, AggregationMarket>();
    return [...aggAccounts.values()];
  }, [aggregationsQuery.data, aggregationName]);
};
